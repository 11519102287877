const whiteLabelProfileQuestionsToHide = () => {
  // return ['iAm'];
  return [];
}

const fillQuestionsWithSettings = (questions, settings, isRegularOneTable) => {
  const filteredQuestions = questions.map((question, index) => {
    const questionCopy = { ...question };
    // TEMPORARY because enough copy settings for this field dont exist yet //
    // there should be another way... //
    const isCampusOther = questionCopy.name === 'iAmCampusOther';

    if (questionCopy.choices !== undefined) {
      questionCopy.choices = Object.keys(question.choices).map((key) => {
        return {
          id: key,
          label: question.choices[key]
        }
      })
    }

    const editKey = `q_${index+1}_label`;
    const viewKey = `view_q_${index+1}_label`;

    const editLabel = settings.copy.profile ? settings.copy.profile[editKey] : '';
    const viewLabel = settings.copy.profile ? settings.copy.profile[viewKey] : '';

    // if (editLabel && viewLabel) {
    return {
      ...questionCopy,
      editLabel: isCampusOther ? 'Other Campus' : editLabel,
      viewLabel: isCampusOther ? 'Other Campus' : viewLabel
    }
    // } else {
    //   return null;
    // }
  }).filter(q => q)

  if (isRegularOneTable) {
    return filteredQuestions;
  } else {
    return filteredQuestions.filter(q => {
      const questionFound = whiteLabelProfileQuestionsToHide().find(questionToHideName => q.name === questionToHideName);
      return !questionFound;
    });
  }
}

const fillQuestionsWithAnswers = (questions, answers) => {
  return answers
    ? questions.map(question => {
        const data = answers[question.name];
        const hasChoices = question.type === 'checkbox' || question.type === 'radio';

        if (!data) {
          // add default values to set up control of the inputs
          if (hasChoices) question.choices = question.choices?.map(c => ({ ...c, value: false })) || [];
          else question.value = '';
          return question;
        }

        // add false value for undefined display to set up control of 'show-on-profile' inputs
        question.display = data.display || false;
        // add 'other answer'
        if (question.otherOptional) question.otherAnswer = data.otherAnswer || '';

        if (hasChoices) {
          // fill checkbox/radio `choices` with saved values
          // this is mostly to support checkbox values but its used for radios too
          question.choices = question.choices.map(c => {
            // legacy answer values that were saved during signup and never updated will exist as a string
            // all choice type answer values should now be saved in { a: true } format from the beginning            
            const value = (data.answer && typeof data.answer !== 'undefined') ? (typeof data.answer === 'string' ? data.answer : data.answer[c.id]) : false;
            return { ...c, value };
          });
          return question;
        }

        // add string type answer
        question.value = data.answer || '';
        return question;
      })
  : questions;
};

const buildAnswersJSONFromQuestions = (questions, answers) => {
  const newAnswers = Object.assign({}, answers);
  const getAnswer = q => {
    if (q.type === 'text' || q.type === 'textarea') {
      return q.value;
    } else if (q.type === 'radio' || q.type === 'checkbox') {
      const values = {};

      Object.keys(q.choices).forEach(key => {
        const choice = q.choices[key];
        values[choice.id] = choice.value;
      });

      return values;
    }
  };

  questions.forEach(q => {
    const question = newAnswers.hasOwnProperty(q.name) ? q : {};

    newAnswers[q.name] = {
      ...question,
      answer: getAnswer(q),
      display: q.display,
      required: q.required
    };
  });

  return newAnswers;
};

const checkAnswered = (answers = {}) => {
  const { answer } = answers;
  let isAnswered = false;

  if (typeof answer === 'string') return answer.length > 0;
  else for (const i in answer) {
    if (answer[i]) isAnswered = true;
  }

  return isAnswered;
};

export { fillQuestionsWithSettings, fillQuestionsWithAnswers, buildAnswersJSONFromQuestions, checkAnswered };
