import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../components';
import {
  getReservationStatus,
  needsManualApproval,
  hasReachedEndRsvpTime,
  generateAddToCalendarUrl,
  isVaccinated
} from '../../libs';
import { RsvpButtonDumb } from './RsvpButtonDumb';

import { EVENT_TYPE, RSVP_CAPABILITY } from '../../enums';
import { subscriptions } from '../../libs/subscriptions';
import ROUTES from '../../constants/routes';

class RsvpButton extends React.Component {

  state = {
    menuOpened: false
  }

  toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }

  cancelReservationButtonClicked = () => {
    this.setState({ menuOpened: false });
    this.props.handleCancelReservation();
  }

  inviteFriendsButtonClicked = () => {
    this.setState({ menuOpened: false });
    this.props.handleInviteFriends();
  }

  addToCalendarButtonClicked = calendar => {
    const { event, areas } = this.props;

    this.setState({ menuOpened: false });

    const url = generateAddToCalendarUrl(event, areas, calendar);
    window.open(url, '_blank');
  }

  goToRsvpPage = () => {
    const {
      event,
      history,
      session: { viewer },
      showVaccinationModal = () => {},
      showVaccinationErrorModal = () => {}
    } = this.props;
    const { privacySubtype } = event;
    if (privacySubtype && privacySubtype.vaccinated && !viewer.user?.profile?.vaccinationDate) {
      showVaccinationModal();
      return;
    } else if (
      privacySubtype && privacySubtype.vaccinated && viewer.user?.profile &&
      !isVaccinated(event, viewer.user.profile.vaccinationDate, true)
    ) {
      showVaccinationErrorModal();
      return;
    }
    history.push(`/${ROUTES.EVENTS}/${event.uuid}/rsvp`, { event });
  };

  handleOpenRsvpModal = (...args) => {
    const { openRsvpModal, event: { eventType }, session: { viewer }  } = this.props;

    window.dataLayer?.push({
      event: 'rsvp_button_click',
      rsvp_button_click_event_type: eventType.toLowerCase(),
      has_membership: subscriptions.has_membership(viewer)
    });

    openRsvpModal(...args);
  }

  render() {
    const {
      className,
      event,
      viewer,
      openDeclineInvitationModal,
      session: { theme },
      settings: { copy },
      fullWidth,
      themed
    } = this.props;

    if (!event.canSubscribe) return null;

    const reservationStatus = getReservationStatus(event, viewer);

    switch (reservationStatus) {
      case RSVP_CAPABILITY.CAN_RSVP:
      case RSVP_CAPABILITY.RSVP_CANCELED:
      case RSVP_CAPABILITY.INVITE_CANCELED:
        if (hasReachedEndRsvpTime(event)) {
          return (
            <RsvpButtonDumb
              buttonStyle="primary"
              mainButtonName={copy.event.deadline_reached}
              disabled={true}
              fullWidth={fullWidth}
              themed={themed}
            />
          );
        }
        if (needsManualApproval(event)) {
          return (
            <RsvpButtonDumb
              buttonStyle="primary"
              mainButtonName={copy.event.request_a_seat}
              mainButtonHandler={theme.isVersion(2) ? this.goToRsvpPage : this.handleOpenRsvpModal}
              fullWidth={fullWidth}
              themed={themed}
            />
          );
        }
        return (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName={copy.event.rsvp}
            mainButtonHandler={theme.isVersion(2) ? this.goToRsvpPage : this.handleOpenRsvpModal}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.HOST_VIEWER:
        return (
          <RsvpButtonDumb
            buttonStyle="rsvpGreen"
            mainButtonName={copy.event.host_dinner}
            mainButtonHandler={this.toggleMenu}
            showMenu={this.state.menuOpened}
            menuOptions={[
              { label: copy.event.invite_friends, handler: this.inviteFriendsButtonClicked }
            ]}
            disabled={event.eventType === EVENT_TYPE.SOLO}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.RSVP_PENDING:
        return (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName={copy.event.pending_host_approval}
            mainButtonHandler={this.toggleMenu}
            showMenu={this.state.menuOpened}
            menuOptions={[
              { label: copy.event.cancel_reservation, handler: this.cancelReservationButtonClicked }
            ]}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.RSVP_ACCEPTED:
      case RSVP_CAPABILITY.INVITE_ACCEPTED:
        return (
          <RsvpButtonDumb
            buttonStyle="rsvpGreen"
            mainButtonName={copy.event.youre_going}
            mainButtonHandler={this.toggleMenu}
            showMenu={this.state.menuOpened}
            menuOptions={[
              { label: copy.event.cancel_reservation, handler: this.cancelReservationButtonClicked },
              { label: copy.event.invite_friends, handler: this.inviteFriendsButtonClicked },
              { label: copy.event.add_to_calendar || 'Add to Google Calendar', handler: () => this.addToCalendarButtonClicked('google') },
              { label: copy.event.add_to_calendar || 'Add to iCal', handler: () => this.addToCalendarButtonClicked('ical') },
            ]}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.RSVP_REJECTED:
        return (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName={copy.event.rejected}
            disabled={true}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.EVENT_FULL:
        return (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName={copy.event.full_dinner}
            disabled={true}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      case RSVP_CAPABILITY.INVITE_PENDING:
        if (hasReachedEndRsvpTime(event)) {
          return (
            <RsvpButtonDumb
              buttonStyle="primary"
              mainButtonName={copy.event.deadline_reached}
              disabled={true}
              fullWidth={fullWidth}
              themed={themed}
            />
          );
        }
        return (
          <div className={className}>
            <div className="buttons-container">
              <RsvpButtonDumb
                className="accept-button"
                buttonStyle="primary"
                mainButtonName={copy.event.invitations_accept}
                mainButtonHandler={theme.isVersion(2) ? this.goToRsvpPage : this.handleOpenRsvpModal}
                fullWidth={!theme.isVersion(2)}
                themed={themed}
              />
              <RsvpButtonDumb
                className="decline-button"
                buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                mainButtonName={copy.event.invitations_decline}
                mainButtonHandler={openDeclineInvitationModal}
                fullWidth={!theme.isVersion(2)}
                themed={themed}
              />
            </div>
          </div>
        );
      case RSVP_CAPABILITY.INVITE_REJECTED:
        return (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName={copy.event.deadline_reached}
            disabled={true}
            fullWidth={fullWidth}
            themed={themed}
          />
        );
      default:
        return null;
    }
  }
}

const StyledRsvpButton = styled(RsvpButton)`
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accept-button {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      flex: 0 0 50%;
      margin-right: 5px;
      button { width: 100%; }
    ` : css`
      margin-right: 10px;
      flex: 0 0 70%;
      button { width: 100%; }
    `}
  }

  .decline-button {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      flex: 0 0 50%;
      margin-left: 5px;
    ` : css`
      margin-left: 10px;
      flex: 0 0 70%;
    `}
  }
`;

const StyledRsvpButtonWithSettings = withSettings(StyledRsvpButton);
const RsvpButtonWithSession = withSession(StyledRsvpButtonWithSettings);
const RsvpButtonWithRouter = withRouter(RsvpButtonWithSession);
export { RsvpButtonWithRouter as RsvpButton };
