import React from 'react';
import styled, { css } from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { TagsInput } from '../../components';
import { withSettings, withSession } from '../../components';

class TagsSelector extends React.Component {
  state = {
    valid: true,
    focused: false,
    inputValue: ''
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleAddTag = value => {
    const { onAddTag, onValidate, name } = this.props;
    onAddTag(value);
    this.setState({ valid: true });
    onValidate && onValidate(name, true);
  };

  handleOnInputChange = value => {
    const { inputValue } = this.state;
    const { onValidate, name } = this.props;

    this.setState({ inputValue: value }, () => {
      if (inputValue === '') {
        this.setState({ valid: true });
        onValidate && onValidate(name, true);
      }
    });
  };

  handleValidationReject = () => {
    const { onValidate, name } = this.props;
    onValidate && onValidate(name, false);
    this.setState({ valid: false });
  };

  handleOnFocus = () => {
    this.setState({ focused: true });
  };

  handleOnBlur = (value, addTag) => {
    if (value !== '') addTag(value);
    this.setState({ focused: false });
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  renderLayout = (tagComponents, inputComponent) => {
    return (
      <span>
        {inputComponent}
        {tagComponents}
      </span>
    );
  };

  autocompleteRenderInput = ({ addTag, onChange, value, ...props }, suggestions) => {
    const handleOnChange = (e, { method }) => method === 'enter' ? e.preventDefault() : onChange(e);

    const inputValue = (value && value.trim().toLowerCase()) || '';

    const suggestionsFiltered = suggestions
      .map(suggestion => suggestion.toLowerCase())
      .filter(suggestion => !(this.props.tags || []).includes(suggestion))
      .filter(suggestion => suggestion.includes(inputValue));

    return (
      <Autosuggest
        suggestions={suggestionsFiltered}
        shouldRenderSuggestions={() => true}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        onSuggestionSelected={(e, { suggestion }) => addTag(suggestion)}
        inputProps={{
          ...props,
          type: 'text',
          onChange: handleOnChange,
          value: value,
          onFocus: this.handleOnFocus,
          onBlur: () => this.handleOnBlur(value, addTag),
          placeholder: ''
        }}
        alwaysRenderSuggestions={this.state.focused}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    );
  };

  renderInput = props => {
    const { onChange, value, addTag, ...rest } = props;
    return (
      <input
        type='text'
        {...rest}
        onChange={onChange}
        value={value}
        onFocus={this.handleOnFocus}
        onBlur={() => this.handleOnBlur(value, addTag)}
        placeholder=""
      />
    );
  };

  render() {
    const { valid, focused, inputValue } = this.state;
    const { className, tags, suggestions } = this.props;

    return (
      <div className={`
          ${className}
          ${!tags.length ? 'empty' : ''}
          ${focused ? 'focused' : ''}
          ${!valid ? 'invalid' : ''}
      `}>
        <div className="input_wrapper">
          <TagsInput
            value={tags}
            inputValue={inputValue}
            onChange={this.handleAddTag}
            onChangeInput={this.handleOnInputChange}
            addKeys={[9, 13, 32, 188]}
            removeKeys={[]}
            onlyUnique={true}
            onValidationReject={this.handleValidationReject}
            renderInput={props => suggestions && suggestions.length > 0
              ? this.autocompleteRenderInput(props, suggestions)
              : this.renderInput(props)
            }
          />
        </div>
        {!valid && <div className="validation_error">{this.getErrorMessage()}</div>}
      </div>
    );
  }
}

const StyledTagsSelector = styled(TagsSelector)`
  padding-bottom: 6px;

  &.empty {
    .react-tagsinput-input {
      margin-top: 2px;
    }
  }

  &.focused {
    .input_wrapper {
      border-color: #8a8a8a !important;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15) !important;
    }
  }

  &.invalid {
    .input_wrapper {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 14px;
    }
  }

  .input_wrapper {
    margin: 0 0 14px;
    padding: 0;
    transition: border-color 250ms, box-shadow 250ms;
    position: relative;

    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      // border: 1px solid #ceccc7;
      // border-radius: 5px;
      // font-size: 15px;
      // line-height: 1.8
      // box-shadow: none !important;
    ` : css`

    `}
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
  }

  input[type="text"] {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    border: 0 !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 6px 12px 2px;

    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      // padding: 13px 20px 11px;
    ` : css`

    `}
    padding: 6px 12px 2px;

    &:focus {
      box-shadow: none !important;
    }
  }

  .react-tagsinput {
    padding: 0 6px 6px 0;
    min-height: 38px;

    &-tag {
      display: inline-block;
      vertical-align: middle;
      margin: 6px 0 0 6px;
      padding: 3px 8px;
      font-size: 12px;
      border: 1px solid ${props => props.settings.theme.css.global.colors.tagBorder};
      border-radius: 3px;
      background: ${props => props.settings.theme.css.global.colors.tagBackground};
    }

    &-remove {
      &::after {
        content: '\f00d';
        font-family: 'FontAwesome';
        font-size: 11px;
        display: inline-block;
        margin-left: 7px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        cursor: pointer;

      }
    }
  }

  .validation_error {
    font-size: 11px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }

  .react-autosuggest__container {
    display: inline-block;
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #eee;
    border-top: none;
    z-index: 999;
    max-height: 200px;
    overflow: auto;
    border-radius: 3px;
    background-color: #fff;
    margin: 1px 0 0;
    padding: 0;
    list-style-type: none;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.12);
    min-width: 100%;


    ul {
      list-style-type: none;
      padding: 8px 0;
      margin: 0;
    }

    li {
      cursor: pointer;
      transition: background-color 250ms;
      white-space: nowrap;
      text-decoration: none;
      color: #333;
      padding: 6px 20px;
      font-size: 14px;
      font-weight: 400;

      &:hover {
        background-color: rgba(141,27,61,0.1);
      }
    }
  }
`;

const StyledTagsSelectorWithSettings = withSettings(StyledTagsSelector);
const TagsSelectorWithSession = withSession(StyledTagsSelectorWithSettings);
export { TagsSelectorWithSession as TagsSelector };
