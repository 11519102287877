import React from 'react';
import styled from 'styled-components';

import { withSettings } from '../components';


const Sponsor = ({ className, sponsor, settings }) => (
  <li className={className}>
    {sponsor.photo && sponsor.photo.mediumUrl ? (
      <SponsorLogo
        style={{ backgroundImage: `url(${sponsor.photo.mediumUrl})` }}
      />
    ) : (
      <Title>{sponsor.name}</Title>
    )}
    <Description dangerouslySetInnerHTML={{ __html: sponsor.description }} />
    {sponsor.url && (
      <A href={sponsor.url} target="_blank" rel="noopener noreferrer">
        {settings.copy.event.nourished_read_more}
      </A>
    )}
  </li>
);


const StyledSponsor = styled(Sponsor)`
  display: flex;
  flex: 0 1 33%;
  -webkit-box-flex: 0;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
  padding: 15px 15px 30px;
  text-align: center;
`;

const SponsorLogo = styled.div`
  height: 80px;
  margin: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% center;
`;

const Title = styled.h4`
  font-size: 18px;
  margin: 15px 0 20px;
`;

const Description = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

const AWithoutSettings = styled.a`
  font-size: 14px;
  color: ${props => props.settings.theme.css.global.colors.primary};

  &:hover {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }
`;
const A = withSettings(AWithoutSettings);

const SponsorWithSettings = withSettings(StyledSponsor);
export { SponsorWithSettings as Sponsor };
