import React from 'react';
import styled from 'styled-components';

import {
  SidebarLayout,
  RsvpPageMessageAndGuests,
  RsvpPageReservationQuestions,
  RsvpPagePotluckItems,
  RsvpPageCheckout,
  EventDetailCard,
  MembershipSelectionModal,
  Loader
} from '../components';

import { useRsvpPageData, useCheckoutData } from '../../hooks';

import RSVP_PAGES from '../../constants/rsvpPages';


const RsvpPageBodyStep = ({ currentPage }) => {
  switch (currentPage) {
    case RSVP_PAGES.MESSAGE_AND_GUESTS:
      return <RsvpPageMessageAndGuests />;
    case RSVP_PAGES.RESERVATION_QUESTIONS:
      return <RsvpPageReservationQuestions />;
    case RSVP_PAGES.POTLUCK:
      return <RsvpPagePotluckItems />;
    case RSVP_PAGES.CHECKOUT:
      return <RsvpPageCheckout />;
    default:
      return null;
  }
};

export const RsvpPageBody = () => {
  const {
    currentPage,
    event,
    membershipModal: { show, toggle },
    getField,
    setField,
    pageLoading
  } = useRsvpPageData();
  const { checkoutData } = useCheckoutData();

  return pageLoading ? <Loader /> : (
    <>
      <SidebarLayout>
        <div><RsvpPageBodyStep currentPage={currentPage} /></div>
        <div>
          {event && (
            <EventDetailCard
              event={event}
              link={false}
              checkout={currentPage === RSVP_PAGES.CHECKOUT}
              checkoutData={checkoutData}
              stack
              showLocationHost
            />
          )}
        </div>
      </SidebarLayout>
      <MembershipSelectionModal
        show={show}
        toggle={toggle}
        eventId={event && event.id}
        getField={getField}
        setField={setField}
      />
    </>
  );
};

export const RsvpPageSectionSubHeading = styled.p`
  font-size: 16px;
  color: #222;
  margin: 0 0 15px;
`;
