import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  Button,
  withSettings
} from '../../components';


const RestrictedDinnerModal = props => {
  const { className, show, toggle } = props;
  const { type, settings: { copy } } = props;

  return (
    <Modal
      show={show}
      toggle={toggle}
      size="sm"
      noPadding
    >
      <div className={`${className} modal_content__simple`}>
        <h2>{copy.event[`${type.toLowerCase()}_dinner_title`]}</h2>
        <p>{copy.event[`${type.toLowerCase()}_dinner_message`]}</p>
        <Button fullWidth handleClick={toggle}>
          Close
        </Button>
      </div>
    </Modal>
  );
}

const StyledRestrictedDinnerModal = styled(RestrictedDinnerModal)`
  text-align: center;
  padding: 40px 30px 30px !important;

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
`;

const StyledRestrictedDinnerModalWithSettings = withSettings(StyledRestrictedDinnerModal);

export { StyledRestrictedDinnerModalWithSettings as RestrictedDinnerModal };
