import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { generators, userHelpers } from '../../libs';
import { useSession } from '../../hooks';
import { AttendeesModal, withSettings } from '../components';

const getAvatarAttributes = (profile, settings) => {
  if (!profile) {
    return {
      id: 0,
      backgroundStyle: 'color',
      background: generators.randomColorFromProfile(null, settings),
      initials: ''
    }
  }

  if (profile.avatar) {
    return {
      id: profile.id,
      backgroundStyle: 'avatar',
      background: profile.avatar.photo && profile.avatar.photo.mediumUrl,
      initials: null
    };
  } else {
    return {
      id: profile.id,
      backgroundStyle: 'color',
      background: generators.randomColorFromProfile(profile, settings),
      initials: userHelpers.getProfileInitials(profile)
    }
  }
};

const getAttendees = (reservationsList, settings, theme) => {
  const reservations = Array.isArray(reservationsList)
    ? reservationsList // event view //
    : reservationsList ? reservationsList.edges.map(e => e.node) : []; // event card //

  const acceptedReservations = reservations
    ? reservations
        .filter(res => res.state === 'ACCEPTED') // only show accepted reservations //
        .filter(res => res.profile && res.profile.showOnGuestList) // only show guest list consented reservations //
    : [];

  return acceptedReservations.reduce((list, res) => {
    const max = theme.isVersion(2) ? 5 : 4;
    if (list.length < max) return [...list, getAvatarAttributes(res.profile, settings)];
    return list;
  }, []);
};

const getBgStyle = attendee => {
  if (attendee.backgroundStyle === 'avatar') {
    return {
      backgroundImage: `url(${attendee.background})`
    };
  } else {
    return {
      backgroundColor: attendee.background
    };
  }
};

const Attendees = (props) => {
  const { className, event, reservations, size, history, totalGuestsAccepted, position = '', toggleLoginModal, showAttendeesModal } = props;
  const { settings: { copy, theme: { link_attendee_avatars_to_profile, show_full_attendee_modal } } } = props;
  const [showModal, setShowModal] = useState(false);
  const { viewer, theme } = useSession();
  const attendees = getAttendees(reservations, props.settings, theme);
  // const acceptedReservations = reservations.filter(res => res.state === 'ACCEPTED');
  const avatarSize = size ? size : 'sm';
  const enableAttendeeModal = show_full_attendee_modal; // && !event.hideGuestList;

  const handleAttendeeClick = (e, attendee) => {
    e.stopPropagation();

    if (enableAttendeeModal) {
      if (viewer.user) setShowModal(true); else toggleLoginModal();
    } else if (link_attendee_avatars_to_profile) {
      history.push(`/user_profile/${attendee.id}`);
    }
  };

  const handleMoreClick = () => {
    if (enableAttendeeModal) {
      if (viewer.user) setShowModal(true); else toggleLoginModal();
    }
  }

  useEffect(() => {
    if (showAttendeesModal) setShowModal(showAttendeesModal)
  }, [showAttendeesModal]);

  const classNames = [className, avatarSize, position];

  return (
    <div className={classNames.join(' ')}>
      {viewer ? (
        <Fragment>
          {attendees.map(attendee => (
            <div
              style={getBgStyle(attendee)}
              onClick={e => handleAttendeeClick(e, attendee)}
              key={attendee.id}
              className="attendee"
            >
              {attendee.initials && <span className="initials">{attendee.initials}</span>}
            </div>
          ))}
          {totalGuestsAccepted > attendees.length &&
            <span
              className="extra_count"
              onClick={handleMoreClick}
              style={{ cursor: enableAttendeeModal ? 'pointer' : 'text' }}
            >
              +{totalGuestsAccepted - attendees.length} more
            </span>
          }
        </Fragment>
      ) : (
        <span className="sign_in_to_view">{copy.event.sign_in_to_view_attendees}</span>
      )}
       <AttendeesModal
        show={showModal}
        toggle={() => setShowModal(prev => !prev)}
        event={event}
        linked={link_attendee_avatars_to_profile}
      />
    </div>
  );
};

const StyledAttendees = styled(Attendees)`
  text-align: center;
  margin: 20px 0 2px;

  &.left {
    text-align: left;
    margin: 0;

    .attendee {
      margin: 0 15px 0 0;
    }
  }

  &.sm {
    .attendee {
      width: 30px;
      height: 30px;

      .initials {
        font-size: 11px;
      }
    }
  }

  &.lg {
    .attendee {
      width: 44px;
      height: 44px;

      .initials {
        font-size: 16px;
      }
    }
  }

  &.xl {
    .attendee {
      width: 60px;
      height: 60px;

      .initials {
        font-size: 20px;
        line-height: 1;
      }
    }
    .extra_count {
      font-size: 14px;
    }
  }

  .attendee {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);
    z-index: 5;
    background-position: center;
    background-size: cover;
    ${props => {
      const { settings: { theme: { link_attendee_avatars_to_profile, show_full_attendee_modal = true } } } = props;
      return link_attendee_avatars_to_profile || show_full_attendee_modal ? 'cursor: pointer;' : 'cursor: default;';
    }}

    .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  .extra_count {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    color: ${({ settings, version }) => version === 2 ? '#83817b' : settings.theme.css.global.colors.primary};
    font-weight: bold;
    margin-left: 7px;
  }

  .sign_in_to_view {
    display: inline-block;
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 10px;
    color: #8c8c8c;
    font-style: italic;
    font-size: 12px;
  }
`;

const AttendeesWithRouter = withRouter(StyledAttendees);
const AttendeesWithRouterWithSettings = withSettings(AttendeesWithRouter);

export { AttendeesWithRouterWithSettings as Attendees };
