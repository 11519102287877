import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Query, Mutation } from 'react-apollo';

import { VIEWER_PROFILE_DATA } from '../../queries';
import { SIGN_UPLOAD, UPDATE_PROFILE_PICTURE } from '../../mutations';

import { uploadToS3 } from '../../libs/uploaders';
import { queryString } from '../../libs'
import {
  Avatar,
  ButtonTabs,
  Loader,
  ProfileEdit,
  ProfilePaymentMethods,
  ProfileSettingsMembership,
  withSettings,
  withSession
} from '../components';

// const profileTab = { name: 'My Profile', value: 'profile' };
// const paymentTab = { name: 'Payments Methods', value: 'payment' };

class ProfileEditPage extends React.Component {
  state = {
    viewTabs: [],
    currentView: 'profile',
    file: null,
    selectedBase64Image: undefined,
    uploadingPicture: false
  };

  componentDidMount() {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features }, session: { viewer }, location } = this.props;
    const { stripe_redirect } = queryString(location.search, ['stripe_redirect']);
    const paidEventsEnabled = features ? features.paid_events : undefined;
    const state = { viewTabs: [{ name: 'My Profile', value: 'profile' }] };
    if (paidEventsEnabled) state.viewTabs.push({ name: 'Payments Methods', value: 'payment' });
    if (viewer.subscriptions?.length) state.viewTabs.push({ name: 'Membership', value: 'membership' });
    if (stripe_redirect === 'billing_portal' && viewer.subscriptions?.length)
      state.currentView = 'membership';
    this.setState(state);
  }

  updateView = view => {
    this.setState({ currentView: view });
  };

  onCreateCardSuccess = (refetch) => {
    refetch();
  }

  onDeleteCardSuccess = (refetch) => {
    refetch();
  }

  onUpdateCardSuccess = (refetch) => {
    refetch();
  }

  handleFileSelected = async (file, signUpload, updateProfilePicture, refetch) => {
    this.setState({
      file: file,
      uploadingPicture: true
    });

    const reader = new FileReader();

    reader.onloadend = (event) => {
      const base64Image = event.target.result;
      this.setState({ selectedBase64Image: base64Image });
    }

    if (file) {
      reader.readAsDataURL(file);
    }

    const signUploadResponse = await signUpload();

    const s3Credentials = signUploadResponse.data.signUpload?.uploadSignature || null;
    if (!s3Credentials) {
      this.setState({ uploadingPicture: false });
      return;
    }

    const imageUrl = await uploadToS3(s3Credentials, file, null);
    if (!imageUrl) {
      this.setState({ uploadingPicture: false });
      return;
    }

    const res = await updateProfilePicture({ variables: { avatarRemoteUrl: imageUrl } });
    if (res.data.updateProfile && !res.data.updateProfile.errors && !res.errors) {
      let fetchTimes = 0;
      const poll = async () => {
        const session = await refetch();
        if (!session.data.viewer.user?.profile?.avatar?.photo && fetchTimes < 60) {
          fetchTimes++;
          setTimeout(poll, 1000);
          return;
        }
        this.setState({ uploadingPicture: false });
      };
      poll();
      return;
    }

    this.setState({ uploadingPicture: false });
  }

  render() {
    const { className, settings: { copy } } = this.props;
    const { currentView, uploadingPicture, viewTabs } = this.state;

    return (
      <Query query={VIEWER_PROFILE_DATA}>
        {({ data, loading, refetch }) => {
          if (loading) return <Loader />;

          if (!data || !data.viewer) { return null }
          // if (error) console.error(error);

          const { user, cards } = data.viewer;

          return (
            <div className={`${className} body_container med`}>
              <div className="profile_header">
                <h2>{copy.profile.title}</h2>
                <Mutation mutation={UPDATE_PROFILE_PICTURE}>
                  {(updateProfilePicture) => {
                    return (
                      <Mutation mutation={SIGN_UPLOAD}>
                        {(signUpload) => {
                          return (
                            <Avatar
                              size="lg"
                              layout="vertical"
                              base64Image={this.state.selectedBase64Image}
                              fullName
                              editable
                              disabled={uploadingPicture}
                              loading={uploadingPicture}
                              handleFileSelected={(f) => {
                                this.handleFileSelected(f, signUpload, updateProfilePicture, refetch);
                              }}
                              sessionProfile
                            />);
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
                <hr className="separator" />
                {viewTabs.length >= 2 && (
                  <div className={['tab_container', (viewTabs.length === 2 ? 'with_two' : '')].join(' ').trim()}>
                    <ButtonTabs tabs={viewTabs} activeTab={currentView} onSelectTab={this.updateView} />
                  </div>
                )}
              </div>
              <hr />
              {currentView === 'profile' && (
                <ProfileEdit user={user} refetch={refetch} />
              )}
              {currentView === 'payment' && (
                <ProfilePaymentMethods
                  cards={cards}
                  onCreateCardSuccess={() => { this.onCreateCardSuccess(refetch); }}
                  onDeleteCardSuccess={() => { this.onDeleteCardSuccess(refetch); }}
                  onUpdateCardSuccess={() => { this.onUpdateCardSuccess(refetch); }}
                />
              )}
              {currentView === 'membership' && (
                <ProfileSettingsMembership />
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

const StyledProfileEditPage = styled(ProfileEditPage)`
  padding-bottom: 0 !important;

  .profile_header {
    text-align: center;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .tab_container {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      &.with_two {
        max-width: 360px;
      }
    `}
    margin: 50px auto;
  }

  label {
    height: 42px;
    line-height: 18px;
    margin: 0;
    padding: 7px 0 0;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
  }

  label.input-selectable {
    padding: 0;
    line-height: 42px;
  }
`;

const StyledProfileEditPageWithSettings = withSettings(StyledProfileEditPage);
const ProfileEditPageWithSession = withSession(StyledProfileEditPageWithSettings);
const ProfileEditPageWithRouter = withRouter(ProfileEditPageWithSession);
export { ProfileEditPageWithRouter as ProfileEditPage };
