import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Mutation } from 'react-apollo';
import { UPDATE_PROFILE, UPDATE_PROFILE_PICTURE, SIGN_UPLOAD } from '../../mutations';

import {
  Modal,
  TextareaField,
  Textarea,
  Avatar,
  Button,
  alert,
  PageSubheader as Header,
  withSettings,
  withSession
} from '../components';

import {
  invalidateText
} from '../../libs';

import { uploadToS3 } from '../../libs/uploaders';

import { SessionContext } from '../../Context';


class RestrictionModal extends React.Component {
  state = { bio: '', loading: false, content: null };

  static getDerivedStateFromProps(props, state) {
    if (!state.content && props.content) return { content: props.content };
    return null;
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  goToProfile = () => {
    this.props.toggle();
    this.props.history.push('/profile');
  }

  updateBio = (updateProfile) => {
    const { onClose, toggle } = this.props;

    this.setState({ loading: true }, () => {
      updateProfile().then(async ({ data }) => {
        if (data && data.updateProfile && data.updateProfile.errors) {
          alert.error('Error updating profile');
          this.setState({ loading: false });
          return;
        }

        toggle();
        onClose && onClose();
      });
    });
  };

  updateAvatar = (updateProfile, session) => {
    const { onClose } = this.props;
    const refetch = session && session.refetch;

    this.setState({ loading: true }, async () => {
      let serverIsDoneProcessingNewProfilePicture = false;

      while (!serverIsDoneProcessingNewProfilePicture) {
        const getProfilePictureResponse = await refetch();
        const newPhoto = getProfilePictureResponse && getProfilePictureResponse.data.viewer.user.profile.avatar.photo;
        if (newPhoto) {
          serverIsDoneProcessingNewProfilePicture = true;
        }
      }

      onClose && onClose();
    });
  };

  updateAvatarBio = (updateProfile, session) => {
    const { onClose } = this.props;
    const refetch = session && session.refetch;

    this.setState({ loading: true }, async () => {
      await updateProfile();

      let serverIsDoneProcessingNewProfilePicture = false;

      while (!serverIsDoneProcessingNewProfilePicture) {
        const getProfilePictureResponse = await refetch();
        const newPhoto = getProfilePictureResponse && getProfilePictureResponse.data.viewer.user.profile.avatar.photo;
        if (newPhoto) {
          serverIsDoneProcessingNewProfilePicture = true;
        }
      }

      onClose && onClose();
    });
  };

  handleSubmit = (restriction) => {
    if (restriction) { return this[restriction.action]; }
  };

  submitIsDisabled = ({ action }) => {
    const { bio, selectedBase64Image, uploadingPicture } = this.state;

    switch (action) {
      case 'updateBio':
        return invalidateText(bio);
      case 'updateAvatar':
        return !selectedBase64Image || uploadingPicture;
      case 'updateAvatarBio':
        return !selectedBase64Image || uploadingPicture || invalidateText(bio);
      default:
    }

    return false;
  };

  handleFileSelected = async (file, signUpload, updateProfilePicture) => {
    this.setState({
      file: file,
      uploadingPicture: true,
      loading: true
    });

    const reader = new FileReader();

    reader.onloadend = (event) => {
      const base64Image = event.target.result;
      this.setState({ selectedBase64Image: base64Image });
    }

    if (file) {
      reader.readAsDataURL(file);
    }

    const signUploadResponse = await signUpload();

    const s3Credentials = signUploadResponse && signUploadResponse.data &&
      signUploadResponse.data.signUpload && signUploadResponse.data.signUpload.uploadSignature
        ? signUploadResponse.data.signUpload.uploadSignature
        : null;
    if (!s3Credentials) {
      this.setState({ uploadingPicture: false, loading: false });
      return;
    }

    const imageUrl = await uploadToS3(s3Credentials, file, null);
    if (!imageUrl) {
      this.setState({ uploadingPicture: false, loading: false });
      return;
    }

    await updateProfilePicture({ variables: { avatarRemoteUrl: imageUrl } });

    this.setState({ uploadingPicture: false, loading: false });
  }

  render() {
    const { className, show, toggle, session: { theme } } = this.props;
    const { bio, loading: stateLoading, content, uploadingPicture, selectedBase64Image } = this.state;
    const updateBio = content && content.action.includes('Bio');
    const updateAvatar = content && content.action.includes('Avatar');

    const ModalTitle = ({ children }) => theme.isVersion(2)
      ? <Header>{children}</Header>
      : <h2>{children}</h2>;

    const BioField = theme.isVersion(2) ? TextareaField : Textarea

    return (
      <Modal
        className="modal__restriction"
        show={show}
        toggle={toggle}
        size={theme.isVersion(2) ? 'md' : 'sm'}
        noPadding
      >
        <div className={`${className} restriction__modal_content`}>
          <ModalTitle>{content && content.header}</ModalTitle>
          <p>{content && content.text}</p>

          {(updateBio || updateAvatar) && (
            <SessionContext.Consumer>
              {({ session }) => (
                <Mutation mutation={UPDATE_PROFILE_PICTURE}>
                  {(updateProfilePicture) => (
                    <Mutation mutation={SIGN_UPLOAD}>
                      {(signUpload) => (
                        <Mutation mutation={UPDATE_PROFILE} variables={{ bio }}>
                          {(updateProfile, { loading }) => (
                            <Fragment>
                              {updateAvatar && (
                                <Avatar
                                  size="lg"
                                  layout="vertical"
                                  base64Image={selectedBase64Image}
                                  fullName
                                  editable
                                  disabled={uploadingPicture}
                                  loading={uploadingPicture}
                                  handleFileSelected={(f) => {
                                    this.handleFileSelected(f, signUpload, updateProfilePicture);
                                  }}
                                  sessionProfile
                                  noName
                                  addPic
                                />
                              )}

                              {updateBio && (
                                <BioField
                                  placeholder="Enter Bio"
                                  name="bio"
                                  value={bio}
                                  onInputChange={this.handleInputChange}
                                  onValidate={() => {}}
                                  required={true}
                                />
                              )}

                              <div className="retriction__modal_buttons">
                                <Button
                                  className="button__cancel"
                                  handleClick={toggle}
                                  buttonStyle={theme.isVersion(2) ? 'outline' : 'inverted'}
                                  themed={theme.isVersion(2)}
                                >
                                  {content && content.cancelLabel}
                                </Button>
                                <Button
                                  className="button__submit"
                                  buttonStyle="primary"
                                  handleClick={() => this.handleSubmit(content)(updateProfile, session)}
                                  disabled={stateLoading || loading || this.submitIsDisabled(content)}
                                  loading={stateLoading || loading}
                                  themed={theme.isVersion(2)}
                                >
                                  {content && content.submitLabel}
                                </Button>
                              </div>
                            </Fragment>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </SessionContext.Consumer>
          )}

          {!updateBio && !updateAvatar && (<div className="retriction__modal_buttons">
            <Button
              className="button__cancel"
              handleClick={toggle}
              buttonStyle={theme.isVersion(2) ? 'outline' : 'inverted'}
              themed={theme.isVersion(2)}
            >
              {content && content.cancelLabel}
            </Button>
            <Button
              className="button__submit"
              handleClick={this.handleSubmit(content)}
              buttonStyle="primary"
              themed={theme.isVersion(2)}
            >
              {content && content.submitLabel}
            </Button>
          </div>)}
        </div>
      </Modal>
    );
  }
}

const StyledRestrictionModal = styled(RestrictionModal)`
  padding: 30px 40px;
  text-align: center;

  .modal__restriction.modal-sm {
    @media (min-width: 992px) {
      max-width: 500px;
    }
  }

  h2 {
    ${({ session: { theme }, colors: { primaryDark } }) => !theme.isVersion(2) && css`
      font-size: 18px;
      color: ${primaryDark};
      margin: 0 0 20px;
    `}
  }

  p {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      color: #424242;
      font-size: 12px;
    `}

    text-align: center;
    margin: 0 0 30px;
  }
  .retriction__modal_buttons {
    margin-top: 30px;
    position: relative;
    padding-top: 30px;

    & > .inverted {
      border: 1px solid ${props => props.settings.theme.css.global.colors.primary};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #ccc;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .button {
    &__cancel {
      float: left;
    }

    &__submit {
      float: right;
    }

    &__cancel,
    &__submit {
      width: calc(50% - 10px);
    }
  }
`;

const RestrictionModalWithRouter = withRouter(StyledRestrictionModal);
const RestrictionModalWithRouterWithSettings = withSettings(RestrictionModalWithRouter);
const RestrictionModalWithRouterWithSession = withSession(RestrictionModalWithRouterWithSettings);

export { RestrictionModalWithRouterWithSession as RestrictionModal };
