import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../../components';

import {
  AttendeesReservationOptimized,
  EventCardRsvpDetails
} from '../../components';

import { formatPrice, isPublicEvent, imGoing, isSite, stringInsert } from '../../../libs';
import { EVENT_TYPE } from '../../../enums';
import whitelabel from '../../../libs/whitelabels';

const isMaxGuestReached = event => {
  const totalGuests = event.reservations.guestsAccepted + event.reservations.guestsPending;
  return totalGuests >= event.numberOfGuestsMax;
};

const renderCardFooter = (event, viewer, settings) => {
  const guestsAccepted = event.reservations && event.reservations.guestsAccepted;
  const checkPendingMaxSeats = settings && settings.features && settings.features.check_pending_max_seats;
  // viewer has accepted reservation //
  if (imGoing(event, viewer)) {
    return isSite(settings, 'jewishfoodfest') || event.hideGuestList
      ? <div className="spacer"></div>
      : <AttendeesReservationOptimized event={event} reservations={event.reservations} />
    // dinner isnt full //
  } else if (isMaxGuestReached(event) && checkPendingMaxSeats) {
    return <EventCardRsvpDetails event={event} viewer={viewer} maxReached />;
  } else if (guestsAccepted < event.numberOfGuestsMax || event.eventType === EVENT_TYPE.SOLO) {
    return <EventCardRsvpDetails event={event} viewer={viewer} />;
  }

  return <div className="spacer"></div>;
};

const handleHostClick = event => {
  event.stopPropagation();
};

const renderHostLink = (host = {}, viewer, settings) => {
  const { theme: { link_attendee_avatars_to_profile } } = settings;
  const { profile } = host;

  if (profile && viewer && link_attendee_avatars_to_profile) return (
    <div className="host_name">
      <Link to={`/user_profile/${profile.id}`} onClick={event => handleHostClick(event)}>
        {profile.firstName}
      </Link> is hosting
    </div>
  );
  else if (profile && (!viewer || !link_attendee_avatars_to_profile)) return (
    <div className="host_name">
      <span>
        {profile.firstName}
      </span> is hosting
    </div>
  );
  else return null;
};

const EventCardBody = ({ className, event, viewer, showArea, settings }) => {
  const { copy: { event: { pay_what_you_want_label } } } = settings;
  const eventHosts = event && event.eventHosts;
  const hosts = eventHosts && eventHosts.edges
    ? eventHosts.edges.map(host => host.node).filter(host => host.profile)
    : [];
  const host = hosts.length > 1
    ? hosts.find(h => isSite(settings, 'climatefest') ? !h.creator : h.creator)
    : hosts[0];

  const EventCardLocation = whitelabel.event.card.body.location(settings);

  return (
    <div className={[className, 'ot_event_card_body'].join(' ')}>
      <div className="host_name">
        {renderHostLink(host, viewer, settings)}
      </div>
      <h4 className="event_title">{event.title}</h4>

      <i className="divider" />

      <EventCardLocation
        event={event}
        showArea={showArea}
      />

    {isPublicEvent(event) &&
        <div className="event_price">
          {event.pwyw ? (
            <span className="pwyw">
              {stringInsert(
                pay_what_you_want_label || 'Pay What You Want (Min $%1$s)',
                formatPrice(event.pwywMinimum)
              )}
            </span>
          ) : (
            event.pricePerPerson
              ? <span>${formatPrice(event.pricePerPerson)}</span>
              : <span>Free</span>
          )}
        </div>
      }

      <StyledCardFooter>
        {renderCardFooter(event, viewer, settings)}
      </StyledCardFooter>
    </div>
  );
};

const StyledCardFooter = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
`;

const StyledEventCardBody = styled(EventCardBody)`
  // position: relative;
  z-index: 0;
  padding: 40px 20px 78px;

  .host_name {
    font-size: 12px;
    position: relative;

    span {
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-weight: bold;
    }

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-weight: bold;
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .event_title {
    font-size: 16px;
    margin-top: 10px;
    word-break: break-word;
    line-height: 1.4;
  }

  .event_price {
    font-size: 16px;
    margin-top: 15px;

    span {
      color: ${props => props.settings.theme.css.global.colors.primary};

      &.pwyw {
        font-size: 14px;
      }
    }
  }

  & > .spacer {
    height: 15px;
  }
`;

const StyledEventCardBodyWithSettings = withSettings(StyledEventCardBody);

export { StyledEventCardBodyWithSettings as EventCardBody };
