import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Modal, Logo, Button, withSettings } from '../components';

const EventCreationPopupsRecurrance = ({
  className,
  showModal,
  recurrenceWarning,
  onCancel,
  onContinue,
  settings
}) => {
  const { copy } = settings;
  const [displayModal, setDisplayModal] = useState(false);
  const [data, setData] = useState({ message: '' });

  const toggleModal = () => setDisplayModal((prev) => !prev);

  useEffect(() => {
    if (!displayModal && showModal) setDisplayModal(true);
  }, [showModal, displayModal]);

  useEffect(() => {
    if (recurrenceWarning) {
      setData({
        message:
          copy.create[`recurrance_${recurrenceWarning}_popup_message`] ||
          'Your potluck items will be removed if you add recurrances to this event.'
      });
    }
  }, [recurrenceWarning, copy.create]);

  const handleCancel = () => {
    onCancel();
    toggleModal();
  };

  const handleContinue = () => {
    onContinue();
    toggleModal();
  };

  const { message } = data;

  return (
    <Modal show={displayModal} toggle={handleCancel} backdrop='static' size='sm' noPadding>
      <div className={className}>
        <Logo layout='vertical' />
        <p>{message}</p>

        <div className='modal_buttons'>
          <Button className='button__cancel' handleClick={handleCancel} buttonStyle='inverted'>
            Cancel
          </Button>
          <Button className='button__submit' handleClick={handleContinue}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const StyledEventCreationPopupsRecurrance = styled(EventCreationPopupsRecurrance)`
  padding: 40px 30px 30px;

  .logo {
    display: block;
    max-width: 147px;
    margin: 0 auto 30px;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;

    svg {
      color: #8c8c8c;
      font-size: 12px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .modal_buttons {
    margin-top: 30px;
    position: relative;
    padding-top: 30px;

    & > .inverted {
      border: 1px solid ${(props) => props.settings.theme.css.global.colors.primary};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #ccc;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .button {
    &__cancel {
      float: left;
    }

    &__submit {
      float: right;
    }

    &__cancel,
    &__submit {
      width: calc(50% - 10px);
    }
  }
`;
const EventCreationPopupsRecurranceWithSettings = withSettings(StyledEventCreationPopupsRecurrance);
export { EventCreationPopupsRecurranceWithSettings as EventCreationPopupsRecurrance };
