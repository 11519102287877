import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { breakpoints } from '../../styles';

const EventHero = ({ id, title, headerImg, headerAltText, capitalize = false }) => {
  return (
    <Hero>
        <div id={id} className='header'>
            <h1 style={{
                textTransform: capitalize && 'capitalize'
            }}>{title}</h1>
            <img src={headerImg} alt={headerAltText} />
        </div>
    </Hero>
  );
};

const Hero = styled.div`

    .header {
        position: relative;
        overflow: hidden;
        height: 388px;

        h1 {
            color: #fff;
            font-size: 40px;
            text-align: center;
            position: relative;
            z-index: 2;
            margin-top: 170px;
            padding: 0 20px;
          }
      
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: cover;
            pointer-events: none;
          }
    }

  @media (${breakpoints.mobile}) {
    .header {
        min-height: 388px;
        h1 {
          top: 170px;
          margin-top: 0;
        }
    }
  }
`;

const EventHeroWithSettings = withSettings(EventHero);

export { EventHeroWithSettings as EventHero };
