import React from 'react';
import styled from 'styled-components';


export const Underlay = ({ styles = {}, onClick, stopPropagation }) => (
  <div
    onClick={e => {
      stopPropagation && e.stopPropagation();
      onClick(e);
    }}
  >
    <UnderlayEl style={styles} />
  </div>
);

const UnderlayEl = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: default;
`;
