import styled from 'styled-components';

import { withSettings } from '../../components';

const FieldErrorMessage = styled.span`
  display: block;
  font-size: 12px;
  color: ${props => props.settings.theme.css.global.colors.error};
  line-height: 1;
  margin: 4px 0 7px;
  padding-left: 4px;
`;

const FieldErrorMessageWithSettings = withSettings(FieldErrorMessage);
export { FieldErrorMessageWithSettings as FieldErrorMessage };
