import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SessionContext } from '../../../Context';
import { InfoTooltip } from '../../components';
import {
  getDaysLeftForNourishmentSelection,
  getNourishmentValue,
  nourishmentsDeadlineHasPassed,
  nourishmentDeadlineIsExtended,
  isSite
} from '../../../libs';
import { EVENT_STATE, EVENT_TYPE } from '../../../enums';
import { withSettings } from '../../components';

const EventNourishment = (props) => {

  const { className, event, settings } = props;
  const { copy } = settings;

  const nourishmentsDeadlinePassed = nourishmentsDeadlineHasPassed(event, event.area);
  const daysLeft = getDaysLeftForNourishmentSelection(event, event.area);

  const nourishmentUnavailableReasons = event.nourishmentUnavailableExplanation || [];
  const eventFinished = (new Date(event.scheduledAt)) < (new Date());

  const getTooltipContent = () => {
    const estimatedGuests = event.estimatedGuests ? `Estimated Guests: ${event.estimatedGuests}\n` : '';
    let nourishmentCredit = event.sponsorships.length ? 'Nourishment Credit:' : 'No Credits Available';
    for (const sponsorship of event.sponsorships) {
      if (sponsorship && sponsorship.sponsor)
        nourishmentCredit += `\n${sponsorship.sponsor.name}: $${sponsorship.amount / 100}`;
    }
    return estimatedGuests + nourishmentCredit;
  };

  const getTooltipContainer = () => {
    return (
      <span className="selected-container">
          <p className="selected">{copy.manage.nourish_allocated}</p>
          <InfoTooltip
            className="transaction_info"
            content={getTooltipContent()}
            position="top"
          />
      </span>
    );
  }; 

  const getAction = (session) => {
    const isSoloEvent = event.eventType === EVENT_TYPE.SOLO;

    const linkTo = {
      pathname: `/events/${event.uuid}/guests`,
      state: { isSoloEvent }
    };

    if (event.sponsorships.length) {
      return (
        <div>
          {getTooltipContainer()}
          {(!nourishmentsDeadlinePassed || nourishmentDeadlineIsExtended(event)) && (
            <p>
              <strong className="red">
                <Link to={linkTo}>{copy.manage.nourish_update}</Link>
              </strong>
            </p>
          )}
        </div>
      );
    }

    if (nourishmentsDeadlinePassed && !event.nourishable) {
      return <div>
        <span className="not_selected">{copy.manage.nourish_not_selected}</span>
      </div>;
    }

    return (
      <div>
        <div>
          <p>Up to ${getNourishmentValue(session, event)}</p>
        </div>
        <p>
          <strong className="red">
            <Link to={linkTo}>{copy.manage.nourish_spend_now}</Link>
          </strong>
        </p>
      </div>
    );
  };


  return (
    <div className={className}>
      <SessionContext.Consumer>
        {({ session }) => {
          if (eventFinished) {
            return <div className="not-selected">
              <p className="red">
                {copy.manage.nourish_not_selected}
              </p>
            </div>;
          }

          if (nourishmentsDeadlinePassed && !nourishmentDeadlineIsExtended(event)) {
            if (event.sponsorships.length > 0) {
              return <div>{getAction(session)}</div>;
            } else {
              return <div>
                <p className="days-left">
                  {copy.manage.nourish_deadline_passed}
                </p>
                <p className="red not-applicable">
                  {copy.manage.nourish_not_selected}
                </p>
              </div>;
            }
          }

          if (event.state === EVENT_STATE.PENDING_PUBLISH_APPROVAL) {
            return (
              <div className="not-applicable">
                <p className="red">
                  {copy.manage.nourish_not_yet_eligible || 'Not Yet Eligible'}
                </p>
                <InfoTooltip
                  className="transaction_info"
                  content={
                    copy.manage.nourish_not_yet_eligible_tooltip ||
                      `The event has to be approved before you can select ${isSite(settings, 'hillel') ? 'reimbursement' : 'nourishment'}.`
                  }
                  position="top"
                />
              </div>
            );
          }

          if (!event.nourishable || event.state === EVENT_STATE.DRAFT) {
            return <div className="not-applicable">
              <p className="red">
                {copy.manage.nourish_not_applicable}
              </p>
              {nourishmentUnavailableReasons ? (
                <InfoTooltip
                  className="transaction_info"
                  content={nourishmentUnavailableReasons.map(text => '- ' + text).join(' ')}
                  position="top"
                />
              ) : null }
            </div>;
          }

          return <div>
            <p className="days-left">
              {daysLeft} {copy.manage.nourish_days_left}
            </p>
            {getAction(session)}
          </div>;
        }}
      </SessionContext.Consumer>
    </div>
  );
}

const StyledEventNourishment = styled(EventNourishment)`
  text-align: center;

  .days-left {
      background: ${props => props.settings.theme.css.global.colors.bannerSmall};
      padding: 5px 6px 4px;
      letter-spacing: 1px;
      font-size: 8px;
      line-height: 8px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      max-width: 100px;
      margin: 0 auto 5px;
  }
  .red {
    margin-bottom: 5px;
      a {
          color: ${props => props.settings.theme.css.global.colors.primary};
      }
  }
  .not-applicable, .not-selected {
      font-size: 12px;
      min-width: 90px;
      text-align: center;
  }
  .selected-container {
      display: flex;
      justify-content: center;
  }
  .selected {
      color: #01e15d;
      margin-right: 5px;
  }
  .transaction_info {
      white-space: pre-line;
      font-weight: bold;
  }

  p {
    margin-bottom: 5px;
  }

  span {
    &.not_selected {
      display: block;
      margin-top: 7px;
      font-size: 12px;
    }
  }
`;

const StyledEventNourishmentWithSettings = withSettings(StyledEventNourishment);

export { StyledEventNourishmentWithSettings as EventNourishment };
