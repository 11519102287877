import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../components';
import Html from 'html-to-react';
import { withSettings } from '../components';

import { breakpoints } from '../../styles';

const htmlParser = new Html.Parser();

const EventCreationQuestionsAnswer = (props) => {
  const { className, answer, isSelected, onSelectQuestion } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => setShowTooltip(prev => !prev);


  return (
    <li
      className={`
        ${className}
        ${showTooltip ? 'show_tooltip' : ''}
        ${isSelected !== -1 ? 'active' : ''}
      `}
      onClick={() => onSelectQuestion(answer)}
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
    >
      <div>
        {answer.description && <Tooltip>{htmlParser.parse(answer.description)}</Tooltip>}
        <span>{htmlParser.parse(answer.title)}</span>
      </div>
    </li>
  );
}

const StyledEventCreationQuestionsAnswer = styled(EventCreationQuestionsAnswer)`
  display: inline-block;
  width: 150px;
  height: 90px;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin: 4px 6px;
  transition: border-color 250ms, box-shadow 250ms;

  @media (${breakpoints.mobile}) {
    width: 120px;
    height: 70px
  }

  &.active {
    background-color: ${props => props.settings.theme.css.global.colors.primary};

    span {
      color: #fff;
    }
  }

  &:hover {
    border-color: #d0d0d0;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  }

  & > div {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;

    & > span {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      padding: 5px 14px;
      font-size: 14px;
      line-height: 1.3;
      cursor: pointer;
    }
  }

  .ot_tooltip {
    visibility: hidden;
    opacity: 0;
    width: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 350ms;
    transition-delay: 280ms;
  }

  &.show_tooltip {
    .ot_tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const StyledEventCreationQuestionsAnswerWithSettings = withSettings(StyledEventCreationQuestionsAnswer);

export { StyledEventCreationQuestionsAnswerWithSettings as EventCreationQuestionsAnswer };
