import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { stripLeadingSlash } from '../libs';
import { withSettings, withSession } from '../components';
import ROUTES from '../constants/routes';

class LandingAreaRoute extends Route {
  render() {
    const { settings, session, location: { search } } = this.props;
    const { 
      theme: { site: { all_events_page_url, custom_landing_url } }, 
      event: { virtual: { load_on_virtual_landing_page } }, 
      features: { enable_area_user_connection }
    } = settings;
    const profile = session.viewer.user?.profile;
    const allEventsUrl = stripLeadingSlash(all_events_page_url) || ROUTES.ALL;
    const customUrl = stripLeadingSlash(custom_landing_url);
    let pathname;

    if (custom_landing_url) {
      pathname = `/${customUrl}`;

    } else if (load_on_virtual_landing_page) {
      pathname = `/${ROUTES.VIRTUAL}`

    } else if (enable_area_user_connection) {
      pathname = profile?.areaProfile?.length 
        ? `/${ROUTES.LANDING}/${profile.areaProfile[0].url}`
        : `/${allEventsUrl}`;

    } else if (session.theme.isVersion(2) && profile?.area?.url) {
      pathname = `/${ROUTES.LANDING}/${profile.area.url}`;

    } else {
      pathname = `/${allEventsUrl}`;
    }

    return <Redirect to={{ pathname, search }}/>;
  }
}

const LandingAreaWithSettings = withSettings(LandingAreaRoute);
const LandingAreaWithSession = withSession(LandingAreaWithSettings);
export { LandingAreaWithSession as LandingAreaRoute };
