import React from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

import {
  FAIcon,
  Icon,
  ShareFacebook,
  ShareTwitter,
  DropDownMenu,
  withSettings
} from '../components';

import { breakpoints } from '../../styles';
import { getCardEmbedHtml, copyToClipboard } from '../../libs';

class SocialShare extends React.Component {
  state = {
    displayUrl: false,
    urlCopied: false,
    cardCodeCopied: false
  };

  handleUrlToggle = () => {
    this.setState(prevState => ({ displayUrl: !prevState.displayUrl }));
  };

  handleFacebookClick = () => {
    const { event, settings } = this.props;
    ShareFacebook.openModal(event, settings);
  };

  handleTwitterClick = () => {
    const { event } = this.props;
    ShareTwitter.openModal(event.shortUrl);
  };

  handleCopyUrl = () => {
    const copyText = document.querySelector('input.url');
    copyToClipboard(copyText.value);

    this.setState({ urlCopied: true }, () => {
      setTimeout(() => this.setState({ urlCopied: false }), 1300);
    });
  };

  handleCopyCardCode = () => {
    const { event, settings } = this.props;
    const cardHTML = getCardEmbedHtml(settings, event);
    copyToClipboard(cardHTML);

    this.setState({ cardCodeCopied: true }, () => {
      setTimeout(() => this.setState({ cardCodeCopied: false }), 1300);
    });
  };

  render() {
    const { displayUrl, urlCopied, cardCodeCopied } = this.state;
    const { className, event, settings: { copy } } = this.props;

    return (
      <ul className={className}>
        <li className="share"><h4>{copy.manage.social_share}</h4></li>
        <li className="social">
          <button className="facebook" onClick={this.handleFacebookClick}>
            <FAIcon name={['fab', 'facebook-f']}/>
          </button>
        </li>
        <li className="social">
          <button className="twitter" onClick={this.handleTwitterClick}>
            <FAIcon name={['fab', 'twitter']}/>
          </button>
        </li>
        <li className="copy_link">
          <button className="short_url" onClick={this.handleUrlToggle}>
            {copy.manage.social_copy_link}
            <FAIcon name="chevron-down"/>
          </button>
          {displayUrl && (
            <DropDownMenu toggle={this.handleUrlToggle} backdrop>
              <div>
                <input className="url" defaultValue={event.shortUrl} readOnly={true}/>
                <button
                  className={urlCopied ? 'copied' : ''}
                  onClick={this.handleCopyUrl}
                >
                  <Icon name="copy-to-clipboard"/>
                  <FAIcon name={['fas', 'check']}/>
                </button>
              </div>
              <hr/>
              <div>
                <input className="url" value={copy.manage.embed_card_title || 'Code to embed card in a website'} readOnly={true}/>
                <button
                  className={cardCodeCopied ? 'copied' : ''}
                  onClick={this.handleCopyCardCode}
                >
                  <Icon name="copy-to-clipboard"/>
                  <FAIcon name={['fas', 'check']}/>
                </button>
              </div>
              <hr/>
              <div>
                <p>{copy.manage.qr_code_title || 'Scan the QR code'}</p>
                <QRCode className="qr_code" value={event.shortUrl} size={145} bgColor="#000" fgColor="#fff" level="H"  />
                <br/>
              </div>
            </DropDownMenu>
          )}
        </li>
      </ul>
    );
  }
}

const StyledSocialShare = styled(SocialShare)`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0 0 0 5px;
    position: relative;

    h4 {
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      color: ${props => props.settings.theme.css.global.colors.heading};
      margin-right: 10px;
    }

    & > button {
      appearance: none;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      background-color: #fff;
      padding: 0 10px;
      cursor: pointer;
      height: 36px;
      transition: color 250ms;
      position: relative;
      display: block;

      svg {
        font-size: 16px;
        transition: color 250ms;
      }

      &.facebook {
        width: 50px;

        svg {
          color: ${props => props.settings.theme.css.global.colors.facebook};
        }
      }

      &.twitter {
        width: 50px;

        svg {
          color: ${props => props.settings.theme.css.global.colors.twitter};
        }
      }

      &.short_url {
        font-size: 12px;
        font-weight: bold;
        color: ${props => props.settings.theme.css.global.colors.primary};

        svg {
          color: ${props => props.settings.theme.css.global.colors.primary};
          font-size: 12px;
          margin-left: 5px;
          display: inline-block;
        }

        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primaryHover};

          svg {
            color: ${props => props.settings.theme.css.global.colors.primaryHover};
          }
        }
      }
    }

    &.share {
      @media (${breakpoints.mobile}) {
        padding-left: 0;
        width: 100%;
        display: block;
      }
    }

    &.social {
      & > button {
        &:hover {
          svg {
            color: ${props => props.settings.theme.css.global.colors.primary};
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .dropdown_menu {
    left: auto;
    right: 0;
    top: calc(100% + 5px);
    padding: 10px;
    font-size: 14px;
    color: #4d4d4d;

    & > ul {
      padding-bottom: 15px;
      & > div {
        position: relative;
        width: 100%;
        text-align: center;
      }
    }

    .qr_code {
      position: static;
      transform: translateY(0);
      cursor: default;
    }

    & input {
      width: 300px;
      padding: 7px 40px 7px 10px;
      font-size: 14px;
      color: ${props => props.settings.theme.css.global.colors.heading} !important;
      line-height: 1;
      border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor} !important;
      border-radius: 4px;
      background-color: white !important;
    }

    & span {
      display: block;
      padding-left: 10px;
      text-align: left;
    }

    & button {
      border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
      border-radius: 0 4px 4px 0;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      height: auto;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin-top: -1px;
        transition: opacity 150ms;

        &.fa-check {
          color: ${props => props.settings.theme.css.global.colors.greenHoverBorder};
          opacity: 0;
        }
      }

      &.copied {
        svg {
          &.fa-check {
            opacity: 1;
          }

          &.copy_to_clipboard {
            opacity: 0;
          }
        }
      }
    }
  }
`;

const SocialShareWithSettings = withSettings(StyledSocialShare);

export { SocialShareWithSettings as SocialShare };
