import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DateTime } from 'luxon';

import { useSettings, useSession } from '../../hooks';
import { stringInsert } from '../../libs';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_STRIPE_BILLING_PORTAL_SESSION } from '../../mutations';

import {
  PageSubheader as Header,
  OutlineButton as Button
} from '../components';


export const ProfileSettingsSubscriptions = () => {
  const { viewer: { subscriptions: viewerSubscriptions }, theme } = useSession();
  const [loading, setLoading] = useState(false);
  const { copy, colors } = useSettings();

  const [createStripeBillingPortalSession] = useMutation(CREATE_STRIPE_BILLING_PORTAL_SESSION);

  const handleGoToBillingPortal = async () => {
    setLoading(true);

    const { data: { createStripeBillingPortalSession: result } } = await createStripeBillingPortalSession({});

    if (result && result.redirectUrl) window.location.href = result.redirectUrl;
    else setLoading(false);
  };

  const subscriptions = viewerSubscriptions && viewerSubscriptions.reduce((subs, sub) => {
    if (!sub.price?.active || !sub.price.product?.active) return subs;
    // if (subs[sub.status]) subs[sub.status].push(sub);
    // else subs[sub.status] = [sub];
    console.log('sub', sub);
    if (sub.status === 'ACTIVE') subs.active.push(sub);
    else if (sub.status === 'CANCELED') subs.canceled.push(sub);
    else subs.attention.push(sub);
    return subs;
  }, { active: [], canceled: [], attention: [] });

  return (
    <Container theme={theme}>
      <HeaderContainer>
        <Header small>{copy.profile.membership_level || 'Membership Level'}</Header>
        <Button small onClick={handleGoToBillingPortal} loading={loading} disabled={loading}>Edit</Button>
      </HeaderContainer>
      <div>
        {subscriptions.active.map(({ id, price, currentPeriodEnd }) => (
          <Settings key={id}>
            <Header small><span>{price.product.name}</span></Header>
            <Status colors={colors} active>Active</Status>
            {currentPeriodEnd && <P>{stringInsert(
              copy.membership?.next_billing_date || 'Next billing date: %1$s',
              DateTime.fromISO(currentPeriodEnd).toLocaleString()
            )}</P>}
          </Settings>
        ))}
        {subscriptions.canceled.map(({ id, price }) => (
          <Settings key={id}>
            <Header small><span>{price.product.name}</span></Header>
            <Status colors={colors} canceled >Canceled</Status>
          </Settings>
        ))}
        {subscriptions.attention.map(({ id, price }) => (
          <Settings key={id}>
            <Header small><span>{price.product.name}</span></Header>
            <Status colors={colors} attention>Needs Attention</Status>
          </Settings>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) => theme.isVersion(2) ? css`
    margin: -12px 0;
  ` : css`
    margin: 50px 0 8rem;
  `}

  @media (max-width: 600px) {
    flex-direction: column;
  }

  h2 {
    margin: 0 0 5px;
    display: flex;
    align-items: center;
  }

  .button {
    margin-top: 13px;
  }
`;

const HeaderContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Settings = styled.div`
  // width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    display: block;
    h2 {
      display: inline-block;
    }
    p {
      margin: 8px 0 0;
    }
  }

  h2 {
    margin-right: 20px;
  }
`;

const Status = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Avenir-Medium';
  padding: 3px 8px;
  border: 1px solid;
  line-height: 1;
  border-radius: 2px;

  ${({ active }) => active && css`
    border-color: green;
    color: green;
  `}

  ${({ canceled }) => canceled && css`
    border-color: #999;
    color: #999;
  `}

  ${({ attention, colors: { error } }) => attention && css`
    border-color: ${error};
    color: ${error};
  `}
`;

const P = styled.p`
  font-size: 14px;
  margin: 0 0 0 20px;
  white-space: nowrap;
`;
