import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { stringInsert } from '../../libs';

const EventGuestsSummary = (props) => {
  const { className, pendingCount, attendingCount, invitedCount, declinedCount, deniedCount } = props;
  const { settings: { copy } } = props;

  const pendingText = stringInsert(copy.manage.x_pending, pendingCount);
  const attendingText = stringInsert(copy.manage.x_attending, attendingCount);
  const invitedText = stringInsert(copy.manage.x_invited, invitedCount);
  const declinedText = stringInsert(copy.manage.x_declined, declinedCount);
  const deniedText = stringInsert(copy.manage.x_denied, deniedCount);

  return (
    <div className={className}>
      <span>{copy.manage.you_have}</span>
      <span className={pendingCount > 0 ? 'bold-font': ''}>{pendingText}</span>
      <span>, </span>
      <span className={attendingCount > 0 ? 'bold-font': ''}>{attendingText}</span>
      <span>, </span>
      <span className={invitedCount > 0 ? 'bold-font': ''}>{invitedText}</span>
      <span>, </span>
      <span className={declinedCount > 0 ? 'bold-font': ''}>{declinedText}</span>
      <span>{copy.manage.and}</span>
      <span className={deniedCount > 0 ? 'bold-font': ''}>{deniedText}</span>
      <span>{copy.manage.guests}</span>
    </div>
  );
}

const StyledEventGuestsSummary = styled(EventGuestsSummary)`
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;

  & .bold-font {
    font-weight: bold;
  }
`;

const StyledEventGuestsSummaryWithSettings = withSettings(StyledEventGuestsSummary);

export { StyledEventGuestsSummaryWithSettings as EventGuestsSummary };