import React from 'react';
import styled from 'styled-components';

import { useSettings, useEventPageData } from '../../hooks';

import {
  SidebarLayout,
  EventHeaderDetails
} from '../components';


export const EventHeader = () => {
  const { eventData } = useEventPageData();
  const { images } = useSettings();

  return eventData ? (
    <SidebarLayout>
      <div><CoverImage imageUrl={eventData.cover ? eventData.cover.originalUrl : images.default_cover} /></div>
      <div><EventHeaderDetails /></div>
    </SidebarLayout>
  ) : null;
};

const CoverImage = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 4rem;

  &::before {
    content: '';
    display: block;
    padding-top: 67%;
  }
`;
