import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  Avatar
} from '../../components';

import { withSettings } from '../../components';

import {
  getPotluckReservedItemsCount,
  getPotluckTotalItemsCount,
  stringInsert
} from '../../../libs';

const EventPotluckModal = (props) => {

  const { className, event, open, toggle } = props;
  const { settings: { copy } } = props;

  const potluckItems = event && event.potluckItems && event.potluckItems.edges
    ? event.potluckItems.edges.map(edge => edge.node)
    : [];

  const totalPotluckItems = getPotluckTotalItemsCount(event);
  const totalPotluckItemsReserved = getPotluckReservedItemsCount(event);

  const allPotluckItemsClaimed = potluckItems.reduce((accumulator, item) => {
    const claims = ((item && item.claims) || []).map(claim => {
      return { ...claim, 'name': item.name }
    });

    return [...accumulator, ...claims];
  }, []);

  return(
    <Modal size="sm" show={open} toggle={toggle} noPadding>
      <div className={className}>
        <header>
          <title>{copy.manage.potluck_totals_modal_title}</title>
          <p>{stringInsert(copy.manage.potluck_totals_modal_subtitle, [totalPotluckItemsReserved, totalPotluckItems])}</p>
        </header>
        <ul>
          {allPotluckItemsClaimed.map((itemClaimed) => {
            const profile = itemClaimed.reservation.profile;

            return (
              <li key={itemClaimed.id}>
                <div>
                  <span className="item_name">{itemClaimed.name}</span>
                  <span className="item_quantity">{`(${itemClaimed.quantity})`}</span>
                </div>
                <div>
                  <span className={`full-name ${profile ? 'has_profile' : ''}`}>
                  {profile && (
                    <Avatar
                      profile={profile}
                      linked
                      fullName
                    />
                  )}
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </Modal>
  );
}

const StyledEventPotluckModal = styled(EventPotluckModal)`
  padding: 40px 30px 30px;

  header {
    text-align: center;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 20px;
    }

    p {
      font-size: 14px;
      margin: 0 0 20px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;

      &:first-child {
        border-top: 1px solid #ccc;
      }

      .item_name {
        font-size: 14px;
      }

      .item_quantity {
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
      }

      .full-name {
        color: ${props => props.settings.theme.css.global.colors.primary};
        font-weight: bold;

        &.has_profile {
          margin: -8px 0 -6px;
        }

        .user_name {
          padding-top: 3px;
        }
      }

      .avatar {
        width: 38px !important;
        height: 38px !important;
      }
    }
  }
`;

const StyledEventPotluckModalWithSettings = withSettings(StyledEventPotluckModal);

export { StyledEventPotluckModalWithSettings as EventPotluckModal }
