import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from '../../styles';
import { Button, EmailSignUpExtraQuestions, Input, SelectBox, withSettings } from '../components';
import { getProfileQuestions } from '../../constants/profileQuestions';
import { registrationQuestions } from '../../constants/registrationQuestions';
import { CREATE_USER, UPDATE_PROFILE } from '../../mutations';
import {
  formatters,
  isRegularOneTable,
  isSite,
  whitelabel,
  getSiteNameFromSettings,
  getSiteSubNameFromSettings,
  addSignUpAnswersToProfile
} from '../../libs';
import { setToken } from '../../libs/token';
import * as userJourney from '../../libs/userJourney';
import { authViewStateValues } from '../../libs/auth';
import { OtpContext } from '../../OtpContext';

const initialState = {
  email: '',
  password: '',
  passwordConfirm: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  userAreaId: '',
  campus: '',
  dateOfBirthFormatted: '',
  zipCode: '',
  iAm: '',
  birthYear: '',
  emailValid: false,
  passwordValid: false,
  passwordConfirmValid: false,
  firstNameValid: false,
  lastNameValid: false,
  dateOfBirthValid: false,
  zipCodeValid: false,
  hasSignUpError: false,
  dobIsFocused: false,
  iAmValid: false,
  birthYearValid: false,
  userAreaIdValid: false,
  campusValid: false,
  acceptTerms: false,
  acceptValues: false,
  formErrorMessage: () => null
};

class EmailAndPasswordSignUp extends React.Component {
  state = { ...initialState, loading: false };
  static contextType = OtpContext;

  componentDidMount() {
    const { settings } = this.props;
    const siteName = getSiteNameFromSettings(settings);
    const nameRegex = new RegExp(`::${siteName}`);
    const extraQuestionsState = {};

    const userName = userJourney.getItem('name');
    if (userName) {
      this.setState({ firstName: userName, firstNameValid: true });
    }

    registrationQuestions.forEach(q => {
      if (nameRegex.test(q.name)) {
        const name = q.name.replace(`::${siteName}`, '');
        extraQuestionsState[name] = '';
        extraQuestionsState[`${name}Value`] = '';
        extraQuestionsState[`${name}Other`] = '';
        extraQuestionsState[`${name}Valid`] = false;
        extraQuestionsState[`${name}OtherValid`] = true;
      }
    });

    if (whitelabel.hasFeature(this.props.settings, 'hideZipCode')) {
      extraQuestionsState.zipCodeValid = true;
    }

    this.setState({ ...extraQuestionsState });
  }

  handleInputChange = (name, value) => {
    if (typeof name === 'object') {
      this.setState(name);
    } else {
      this.setState({ [name]: value });
    }
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  handleSelectChange = (name, value) => {
    const val = name === 'birthYear' ? value.toString() : value;
    this.handleInputChange(name, val);
    this.handleInputValidation(name, true);
  };

  // used to invalidate another field based on the answer selected //
  handleInputChangeWithFieldCheck = (name, value, fieldChecks = []) => {
    const validate = (checkName, checkValue, field) => {
      if (name === checkName && value === checkValue && !field) {
        this.handleInputValidation(checkName, false);
      }
    };

    if (Array.isArray(fieldChecks)) {
      fieldChecks.forEach(fieldCheck => {
          const field = this.state[fieldCheck.fieldName] || {};
          const { checkName, checkValue } = field;

          if (Array.isArray(checkName)) {
            checkName.forEach(c => validate(c, checkValue, field));
          } else {
            validate(checkName, checkValue, field);
          }
      });
    }
    this.handleInputChange(name, value);
    this.handleInputValidation(name, true);
  };

  handleDOBPlaceholderUpdate = () => {
    this.setState(prevState => ({
      dobIsFocused: !prevState.dobIsFocused
    }));
  };

  getiAmOptions = () => {
    const { settings } = this.props;
    const profileQuestions = getProfileQuestions(settings);
    const iAmQuestion = profileQuestions.find(question => question.name === 'iAm');
    if (!iAmQuestion) {
      return [];
    }

    const entries = Object.entries(iAmQuestion.choices);
    return entries.map(choice => {
      const key = choice[0];
      const value = choice[1];

      return {
        id: key,
        value: key,
        label: value
      };
    });
  };

  formIsInvalid = () => {
    const { settings } = this.props;

    let fields = [
      'emailValid',
      'passwordValid',
      'passwordConfirmValid',
      'firstNameValid',
      'lastNameValid',
      'zipCodeValid'
    ];

    if (isSite(settings, ['seder', 'jewishfoodfest'])) fields = fields.concat([
      'howDidYouHearValid',
      'howDidYouHearOtherValid',
      'birthYearValid'
    ]);

    if (isSite(settings, 'hillel')) {
      if (getSiteSubNameFromSettings(settings) === 'international') {
        fields = fields.concat([
          'iAmHillelValid',
          'iAmHillelOtherValid',
          'phoneValid',
          'are_you_jewishValid',
          'gradYearValid'
        ]);
      } else {
        fields = fields.concat([
          'iAmValid',
          'iAmAltValid',
          'iAmCampusValid',
          'iAmCampusOtherValid',
          'iAmOtherValid',
          'gradYearValid',
          'phoneValid'
        ]);

        if (getSiteSubNameFromSettings(settings) === 'cornell') {
          fields.push('are_you_jewishValid');
        } else {
          fields.push('jewishValid');
        }
      }
    }

    if (isSite(settings, 'dinner-party')) {
      const dinnerPartyRequiredFields = ['dateOfBirthValid'];
      // only show / require acceptance fields if copy exists //
      if (settings.copy.profile.accept_terms) dinnerPartyRequiredFields.push('acceptTerms');
      if (settings.copy.profile.accept_values) dinnerPartyRequiredFields.push('acceptValues');
      fields = fields.concat(dinnerPartyRequiredFields);
    }

    if (isRegularOneTable(settings)) fields = fields.concat(['iAmValid', 'dateOfBirthValid']);

    if (settings.features.enable_area_user_connection) fields = fields.concat(['userAreaIdValid', 'campusValid']);

    let isInvalid = false;

    fields.forEach(field => {
      if (!this.state[field]) isInvalid = true;
    });

    return isInvalid;
  };

  handleFormSubmit = async (event, createUserMutation, updateProfileMutation) => {
    event.preventDefault();

    const { settings, shouldRedirect, afterLogin } = this.props;
    const {
      password,
      passwordConfirm,
      dateOfBirth,
      birthYear,
    } = this.state;

    const { profile: { minimum_age, maximum_age } } = settings;
    const minimumAge = !isNaN(Number(minimum_age)) ? Number(minimum_age) : 0;
    const maximumAge = !isNaN(Number(maximum_age)) ? Number(maximum_age) : 0;

    // use null for dob if whitelabel hideDOB is set //
    // use new date for cases where whitelabel hideDOB may be set but there is an age restriction //
    // if min age is set, the user will get a minimum age warning, if max is set, dob is seet to today //
    const dob = whitelabel.hasFeature(settings, 'hideDOB') && minimumAge < 1 && maximumAge < 1
      ? null : (
        isSite(settings, ['seder', 'jewishfoodfest'])
          ? new Date().setFullYear(birthYear)
          : dateOfBirth || new Date()
      );

    if (password !== passwordConfirm) {
      this.setState({ hasSignUpError: true, formErrorMessage: this.getFormSubmitError('passwords_invalid') });
    } else if (password.length < 8) {
      this.setState({ hasSignUpError: true, formErrorMessage: this.getFormSubmitError('short_password') });
    } else {
      this.setState({
        loading: true,
        hasSignUpError: false,
        dateOfBirthFormatted: formatters.toISODate(dob)
      }, () => {
        createUserMutation()
          .then(async ({ data }) => {
            const { user, token, errors, status, userId, userMessage, userPhone } = data.createUser;

            if (user) {
              setToken(this.props.settings, token);

              addSignUpAnswersToProfile(settings, updateProfileMutation, this.state || {})
                .then(async () => {
                  await this.props.refetch();
                  if (shouldRedirect) this.props.history.push('/landing');
                  else if (afterLogin) afterLogin();
                });

            } else if (Array.isArray(errors) && errors.length) {
              // TODO: create BETTER various error messages //
              const field = errors[0].path[1];
              const message = errors[0].message;
              const em = `${field} ${message}`;
              const errorMessage = em.charAt(0).toUpperCase() + em.slice(1);
              let formErrorMessage = this.getFormSubmitError('email_taken');

              if (errorMessage !== 'has already been taken') formErrorMessage = () => (<span>{errorMessage}.</span>);

              this.setState({
                hasSignUpError: true,
                loading: false,
                formErrorMessage,
                [`${field}Valid`]: false
              });
            } else if (status === 'must_request_otp') {
              this.context.otpSettings.setOtpSettings({
                status,
                userId,
                userMessage,
                userPhone,
                signupAnswers: this.state
              });
              this.props.setViewState(authViewStateValues.OTP_VIEW)
            }
          });
      });
    }
  };

  getFormSubmitError = type => {
    switch (type) {
      case 'short_password':
        return () => (<span>Password has to be at least 8 characters. <br/> Please try again.</span>);
      case 'email_taken':
        return () => (<span>This email has already been registered. <br/> Please try again.</span>);
      default:
        return () => (<span>The passwords you entered do not match. <br/> Please try again.</span>);
    }
  };

  getIamDefaultOption = () => {
    return { id: 0, label: 'I am...', value: 'ALL' };
  };

  getIAmSelectedOption = () => {
    const options = this.getiAmOptions(this.props.settings);
    const selectedIamValue = this.state.iAm;

    const selectedIAmOption = options.find(item => item.value === selectedIamValue);

    if (selectedIAmOption) {
      return selectedIAmOption;
    } else {
      return this.getIamDefaultOption();
    }
  };

  render() {
    const { className, settings } = this.props;
    const { copy, profile: { minimum_age, maximum_age } } = settings;
    const minimumAge = !isNaN(Number(minimum_age)) ? Number(minimum_age) : false;
    const maximumAge = !isNaN(Number(maximum_age)) ? Number(maximum_age) : false;
    const {
      loading,
      email,
      password,
      passwordConfirm,
      dateOfBirthFormatted,
      firstName,
      lastName,
      dateOfBirth,
      zipCode,
      dobIsFocused,
      userAreaId,
      campus,
      birthYear,
      phone,
      formErrorMessage,
      hasSignUpError
    } = this.state;

    const variables = {
      email,
      password,
      firstName,
      lastName,
      zipCode,
      dateOfBirth: dateOfBirthFormatted,
      phone
    };

    if (settings.features.enable_area_user_connection) {
      variables.userAreaId = userAreaId;
      variables.neighborhoodId = campus?.id;
    }

    return (
      <Mutation
        mutation={CREATE_USER}
        variables={variables}
      >
        {(createUserMutation) => {
          return (
            <Mutation mutation={UPDATE_PROFILE}>
              {(updateProfileMutation) => {
                const iAmOptions = this.getiAmOptions();

                return (
                  <div className={className}>
                    <form
                      className="ot_form"
                      onSubmit={event => this.handleFormSubmit(event, createUserMutation, updateProfileMutation)}
                    >
                      {hasSignUpError && (
                        <div className="form_submit_error">
                          {formErrorMessage()}
                        </div>
                      )}
                      <div className="half_width">
                        <Input
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          value={firstName}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          errorMessage="Please enter a first name."
                          required
                        />
                        <Input
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          value={lastName}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          errorMessage="Please enter a last name."
                          required
                        />
                      </div>
                      <Input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        content="email"
                        required
                      />
                      {!whitelabel.hasFeature(this.props.settings, 'hideZipCode') && (
                        <Input
                          type="text"
                          placeholder={copy.profile.zip_code_placeholder}
                          name="zipCode"
                          value={zipCode}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          errorMessage={`Please enter a ${copy.profile.zip_code_placeholder.toLowerCase()}`}
                          required
                        />
                      )}
                      {isSite(settings, ['onetable', 'dinner-party']) && (
                        <Input
                          type="text"
                          placeholder={dobIsFocused ? 'MM/DD/YYYY' : 'Date Of Birth'}
                          name="dateOfBirth"
                          value={dateOfBirth}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          onInputFocus={this.handleDOBPlaceholderUpdate}
                          onInputBlur={this.handleDOBPlaceholderUpdate}
                          errorMessage="Please enter a date of birth."
                          content="dob"
                          minAgeErrorMessage={`You must be at least ${minimumAge} years old to sign up.`}
                          maxAgeErrorMessage={`You must be under ${maximumAge} years old to sign up.`}
                          minimumAge={minimumAge}
                          maximumAge={maximumAge}
                          required
                        />
                      )}
                      <div className="half_width">
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          required
                        />
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          name="passwordConfirm"
                          value={passwordConfirm}
                          onInputChange={this.handleInputChange}
                          onValidate={this.handleInputValidation}
                          errorMessage="Please enter a password confirmation."
                          required
                        />
                      </div>
                      {isRegularOneTable(settings) && (
                        <SelectBox
                          name="iAm"
                          options={iAmOptions}
                          onOptionSelect={option => this.handleSelectChange('iAm', option)}
                          placeholder={'I am...'}
                          defaultValue={this.getIAmSelectedOption()}
                        />
                      )}
                      {isSite(settings, ['seder', 'jewishfoodfest']) && (
                        <Fragment>
                          <Input
                            placeholder="Year of birth"
                            type="number"
                            name="birthYear"
                            value={birthYear}
                            onInputChange={this.handleInputChange}
                            onValidate={this.handleInputValidation}
                            errorMessage="Please enter a year of birth."
                            content="ageByYear"
                            minAgeErrorMessage={`You must be at least ${minimumAge} years old to sign up.`}
                            maxAgeErrorMessage={`You must be under ${maximumAge} years old to sign up.`}
                            minimumAge={minimumAge}
                            maximumAge={maximumAge}
                            required
                          />
                          <EmailSignUpExtraQuestions
                            values={this.state}
                            handleInputChange={this.handleInputChange}
                            handleInputValidation={this.handleInputValidation}
                          />
                        </Fragment>
                      )}
                      {isSite(settings, ['hillel']) && (
                        <EmailSignUpExtraQuestions
                          values={this.state}
                          handleInputChange={(name, value) => {
                            if (getSiteSubNameFromSettings(settings) === 'international') {
                              this.handleInputChange(name, value);
                              return;
                            }

                            this.handleInputChangeWithFieldCheck(name, value, [
                              {
                                checkName: 'iAm',
                                checkValue: 'a',
                                fieldName: 'gradYear'
                              },
                              {
                                checkName: 'iAmAlt',
                                checkValue: ['a', 'b'],
                                fieldName: 'iAmCampus'
                              }
                            ]);
                          }}
                          handleInputValidation={this.handleInputValidation}
                        />
                      )}
                      {isSite(settings, 'dinner-party') && (
                        <EmailSignUpExtraQuestions
                          values={this.state}
                          handleInputChange={(name, value) => this.handleInputChangeWithFieldCheck(name, value)}
                          handleInputValidation={this.handleInputValidation}
                        />
                      )}
                      {isRegularOneTable(settings) && (
                        <EmailSignUpExtraQuestions
                          values={this.state}
                          handleInputChange={this.handleInputChange}
                          handleInputValidation={this.handleInputValidation}
                        />
                      )}
                      <Button
                        type="submit"
                        disabled={this.formIsInvalid() || loading}
                        loading={loading}
                        fullWidth
                        capitalize
                      >{copy.login.email}</Button>
                    </form>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

const StyledEmailAndPasswordSignUp = styled(EmailAndPasswordSignUp)`
  @media (${breakpoints.mobile}) {
    .ot_form {
      .half_width {
        & > div {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

const EmailAndPasswordSignUpWithRouter = withRouter(StyledEmailAndPasswordSignUp);
const EmailAndPasswordSignUpWithSettings = withSettings(EmailAndPasswordSignUpWithRouter);

export { EmailAndPasswordSignUpWithSettings as EmailAndPasswordSignUp };
