import React from 'react';

import {
  Otp,
  EmailAndPasswordSignUp
} from '../';

import { OtpContext } from '../../OtpContext';
import { authViewStateValues } from '../../libs/auth';


const initialState = {
  email: '',
  password: '',
  emailValid: false,
  passwordValid: false,
  hasLoginError: false,
  authViewState: authViewStateValues.LOGIN_WITH_PASSWORD_VIEW,
  isLoading: false
};

class EmailSignUp extends React.Component {
  state = { ...initialState };
  static contextType = OtpContext;

  setAuthViewState = (viewState) => {
    this.setState({ authViewState: viewState });
  };

  render() {
    const { authViewState } = this.state;

    switch (authViewState) {
      case  authViewStateValues.SIGN_UP_VIEW:
        return <EmailAndPasswordSignUp setViewState={this.setAuthViewState} {...this.props}  />;
      case authViewStateValues.OTP_VIEW:
        return <Otp setViewState={this.setAuthViewState} {...this.props}  />;
      default:
        return <EmailAndPasswordSignUp setViewState={this.setAuthViewState} {...this.props}  />;
    }
  }
}

export { EmailSignUp };
