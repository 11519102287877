import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, withSettings } from '../components';

import {
  EventCard,
  EventListAreaSelector,
  EventListTotalCount,
  EventListLoader,
  PrivateEventsCard,
  SoloEventsCard
} from '../components';

import { setCardHeights } from '../../libs';

const EventList = ({
  className,
  events,
  eventsByWeek,
  collapsePrivateEvents,
  collapseSoloEvents,
  area,
  loading,
  loadingMore,
  onLoadMore,
  name,
  hasMoreEvents,
  settings: { theme, copy },
  showArea,
  showCount = true,
  totalEventsCount,
  archive = false
}) => {
  const [showPrivateEventsOn, setShowPrivateEventsOn] = useState([]);
  const [showSoloEventsOn, setShowSoloEventsOn] = useState([]);
  const images = theme.assets.images;

  const handleViewMoreClick = () => {
    onLoadMore();
    setTimeout(() => setCardHeights('.ot_event_card'), 10);
  };

  const handleShowWeeksPrivateEvents = weekDate => {
    if (!showPrivateEventsOn.includes(weekDate)) {
      setShowPrivateEventsOn([...showPrivateEventsOn, weekDate]);
    }
  };

  const handleShowWeeksSoloEvents = weekDate => {
    if (!showSoloEventsOn.includes(weekDate)) {
      setShowSoloEventsOn([...showSoloEventsOn, weekDate]);
    }
  }

  const handleHidePrivateEvents = () => setShowPrivateEventsOn([]);
  const handleHideSoloEvents = () => setShowSoloEventsOn([]);

  const getEventCardsFromEventsByWeek = () => {
    let cards = eventsByWeek.reduce((acc, week) => {
      const nonPrivateEventsCards = week.events.nonPrivate.map(event => (
        <EventCard
          event={event}
          key={event.id}
          showArea={showArea}
        />
      )) || [];

      const shouldShowWeeksPrivateEvents = showPrivateEventsOn.includes(week.date);
      const shouldShowWeeksSoloEvents = showSoloEventsOn.includes(week.date);
      let privateEventsCards = [];
      let soloEventsCards = [];

      // Private Events //
      if (collapsePrivateEvents && !shouldShowWeeksPrivateEvents && week.events.private.length > 1) {
        privateEventsCards = [(
          <PrivateEventsCard
            key={week.events.private[0].id}
            privateEventsNumber={week.events.private.length}
            weekDate={week.date}
            showWeeksPrivateEvents={handleShowWeeksPrivateEvents}
          />
        )];
      } else {
        privateEventsCards = week.events.private.map(event => (
          <EventCard
            event={event}
            key={event.id}
            showArea={showArea}
          />
        ));
      }

      // Solo Events //
      if (collapseSoloEvents && !shouldShowWeeksSoloEvents && week.events.solo.length > 1) {
        soloEventsCards = [(
          <SoloEventsCard
            key={week.events.solo[0].id}
            soloEventsNumber={week.events.solo.length}
            weekDate={week.date}
            showWeeksSoloEvents={handleShowWeeksSoloEvents}
          />
        )];
      } else {
        soloEventsCards = week.events.solo.map(event => (
          <EventCard
            event={event}
            key={event.id}
            showArea={showArea}
          />
        ));
      }

      return [...acc, ...nonPrivateEventsCards, ...privateEventsCards, ...soloEventsCards];
    }, []);
    if (hasMoreEvents) {
      const numberOfCardsToLeaveOutOfScreen = cards.length % 4;
      if (cards.length > 4 && numberOfCardsToLeaveOutOfScreen > 0) {
        cards = cards.slice(0, -numberOfCardsToLeaveOutOfScreen);
      }
    }

    return cards;
  }

  const getEventCardComponentFromEventObject = (event) => {
    return <EventCard event={event} key={event.id} showArea={showArea} />
  }

  setTimeout(() => setCardHeights('.ot_event_card'), 10);

  const eventsArray = events ? (events.nodes ? events.nodes : events) : [];
  const eventCards = eventsByWeek
    ? getEventCardsFromEventsByWeek(eventsByWeek, hasMoreEvents)
    : eventsArray
      .filter(event => event)
      .map(event => {
        const eventData = event.node || event;
        return getEventCardComponentFromEventObject(eventData);
      });

  return (
    <div className={className}>
      {showCount && (area
        ? (
          <EventListAreaSelector
            totalEventsCount={totalEventsCount}
            area={area}
            loading={loading}
            archive={archive}
          />
        ) : (
          <EventListTotalCount
            totalEventsCount={totalEventsCount}
            loading={loading}
            archive={archive}
          />
        )
      )}
      {!loading && eventCards.length > 0 && eventCards.map(card => { return card })}
      {(loading || loadingMore) && <EventListLoader />}
      <div className='view-more-button-container'>
        {hasMoreEvents && !loading && (
          <Button handleClick={() => handleViewMoreClick()} buttonStyle="clearBackground">
            View more {name}<span className="down-arrow" style={{ backgroundImage: `url(${images.arrow_down_red})` }}></span>
          </Button>
          )}
      </div>
      {showPrivateEventsOn.length > 0 && (
        <div
          className="hide_private_events_button"
          onClick={handleHidePrivateEvents}
        >
          {copy.private_card.hide_private_dinners_button_text}
        </div>
      )}
      {showSoloEventsOn.length > 0 && (
        <div
          className={`hide_solo_events_button ${showPrivateEventsOn.length > 0 ? 'with_private' : ''}`}
          onClick={handleHideSoloEvents}
        >
          {copy.solo_card ? copy.solo_card.hide_solo_dinners_button_text : 'Hide all solo dinners'}
        </div>
      )}
    </div>
  );
}

const StyledEventList = styled(EventList)`
  max-width: 1215px;
  margin: 40px auto;
  text-align: center;

  .view-more-button-container {
    height: 50px;
    width: 100%;
  }

  .down-arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .hide_solo_events_button,
  .hide_private_events_button {
    position: fixed;
    right: 40px;
    bottom: 40px;
    background-color: ${props => props.settings.theme.css.global.colors.primary};
    font-size: 12px;
    color: #fff;
    padding: 7px 5px;
    max-width: 70px;
    border-radius: 5px;
    box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
    z-index: 1000;
    cursor: pointer;
    transition: background-color 250ms;
    line-height: 1.3;

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }

  .hide_solo_events_button {
    &.with_private {
      right: 120px;
    }
  }
`;

const EventListWithSettings = withSettings(StyledEventList);

export { EventListWithSettings as EventList };
