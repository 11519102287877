import gql from 'graphql-tag';

import {
  viewerQuery,
  areasQuery,
  eventTypeSponsorshipSettingsQuery,
  socialDistancePolicyFragment,
  timezonesQuery
} from '../queries';

export const INIT = gql`
  ${socialDistancePolicyFragment}
  query Init {
    ${viewerQuery}
    ${areasQuery}
    ${eventTypeSponsorshipSettingsQuery}
    ${timezonesQuery}
  }
`;
