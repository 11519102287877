import gql from 'graphql-tag';

export const SIGN_UPLOAD = gql`
  mutation {
    signUpload(input: {}) {
      clientMutationId
      errors {
        message
      }
      uploadSignature {
        policy
        signature
        credential
        uri
        key
        acl
        date
        algorithm
      }
    }
  }
`;
