import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withSettings } from '../components';
import { Card } from '../components';

import { breakpoints } from '../../styles';

const cards = [1, 2, 3];

class ManageEventsHostingLoader extends React.Component {

  state = {
    sponsorshipsEnabled: null
  }

  componentDidMount = () => {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features } } = this.props;
    const sponsorshipsEnabled = (features && (features.sponsorships === true || features.sponsorships === 'true'));

    this.setState({
      sponsorshipsEnabled: sponsorshipsEnabled
    })
  }

  render() {
    const { className } = this.props;
    const { settings: { copy } } = this.props;
    const { sponsorshipsEnabled } = this.state;

    return (
      <div className={className}>
        {cards.map(i => (
          <Card noPadding key={i}>
            <div className="section title">
              <strong>{copy.manage.event_table_header}</strong>
              <div className="event_cover" />
              <span className="title" />
            </div>
            <div className="section details">
              <strong>{copy.manage.details_table_header}</strong>
              <div className="span__container">
                <span className="top" />
                <span className="middle" />
                <span className="bottom" />
              </div>
            </div>
            <div className="section type">
              <strong>{copy.manage.type_table_header}</strong>
              <span className="centered" />
            </div>
            <div className="section attendance">
              <strong>{copy.manage.attendance_table_header}</strong>
                <div className="span__container">
                  <span className="top" />
                  <span className="middle" />
                  <span className="bottom" />
                </div>
            </div>
            {sponsorshipsEnabled && (
              <div className="section nourishment">
                <strong>{copy.manage.nourishment_table_header}</strong>
                <span className="centered" />
              </div>
            )}
          </Card>
        ))}
      </div>
    );
  }

}

const load = keyframes`
  0% {
    opacity: 1;
    right: 100%;
  }
  40% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 0;
  }
`;

const StyledManageEventsHostingLoader = styled(ManageEventsHostingLoader)`
  .ot_card {
    height: 126px;
    position: relative;
    background-color: rgba(255,255,255,0.7);

    @media (${breakpoints.manageEventsListMobile}) {
      height: auto;
      padding: 40px 0;
    }

    &:first-of-type {
      margin-top: 0 !important;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .section {
    float: left;
    height: 100%;
    position: relative;

    &.title {
      width: 30%;

      strong {
        left: 11px;
      }
    }

    &.details {
      width: 22%;
    }

    &.type {
      width: 13%;
    }

    &.attendance {
      width: 12.5%;
    }

    &.nourishment {
      width: 10%;
    }

    @media (${breakpoints.manageEventsListMobile}) {
      float: none;
      // height: 120px;
      width: 100% !important;
      padding: 10px;
    }
  }

  .event_cover {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 90px;
    height: 82px;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0.7;

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      top: auto;
      left: auto;
      transform: translateY(0);
      margin: auto;
      width: 180px;
      height: 140px;
    }
  }

  span {
    display: block;
    height: 10px;
    position: absolute;
    opacity: 0.7;

    &.title {
      top: 50%;
      right: 25px;
      left: 125px;
      transform: translateY(-50%);

      @media (${breakpoints.manageEventsListMobile}) {
        margin: 30px auto 10px !important;
        width: 100%;
        max-width: 250px;
        height: 20px;
      }
    }

    &.centered {
      top: 50%;
      left: 10px;
      right: 30px;
      transform: translateY(-50%);

      @media (${breakpoints.manageEventsListMobile}) {
        width: 100%;
        max-width: 150px;
        height: 20px;
      }
    }

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      transform: translateY(0) !important;
      margin: 3px auto !important;
      width: 200px;
    }
  }

  .span__container {
    position: absolute;
    height: 60px;
    left: 10px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    span {
      left: 0;
      right: 0;

      &.top {
        top: 0;
      }

      &.middle {
        top: 50%;
        transform: translateY(-50%);
      }

      &.bottom {
        bottom: 0;
      }
    }

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      height: auto;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      transform: translateX(0);
    }
  }

  .event_cover,
  span {
    background-color: #f0f0f0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background-color: #ececec;
      animation: ${load} 1.4s infinite ease-in-out;
    }
  }

  strong {
    display: block;
    font-size: 13px;
    position: absolute;
    top: -28px;
    padding-left: 10px;

    @media (${breakpoints.manageEventsListMobile}) {
      display: none !important;
    }
  }
`;

const StyledManageEventsHostingLoaderWithSettings = withSettings(StyledManageEventsHostingLoader);

export { StyledManageEventsHostingLoaderWithSettings as ManageEventsHostingLoader };
