import React from 'react';
import { SessionContext } from '../../Context';

import { EventCreationPopups } from '../components';

const EventCreationPopupsContainer = props => (
  <SessionContext.Consumer>
    {({ session }) => (
      <EventCreationPopups
        {...props}
        session={session}
      />
    )}
  </SessionContext.Consumer>
);

export { EventCreationPopupsContainer };
