import React, { useState } from 'react';
import styled from 'styled-components';

import { useSettings, useSession, useEventPageData } from '../../hooks';
import { stringInsert } from '../../libs';

import {
  PageSubheader as Header,
  EventPageSubheader as Subheader,
  Avatar,
  FAIcon,
  OutlineButton as Button,
  Divider,
  MessageHostModal
} from '../components';


export const EventHosts = () => {
  const { eventData } = useEventPageData();
  const { copy } = useSettings();

  if (!eventData) return null;

  const hosts = eventData.eventHosts.edges.map(e => e.node)
    .sort(a => a.creator ? -1 : 1);
  const communityTitle = eventData.communityDinner && stringInsert(
    copy.event.host_organization_title, eventData.partnerOrganizationName || ''
  );
  const multipleTitle = hosts.length > 1 && copy.event.multiple_host_title;
  const singleTitle = copy.event.host_title;

  return hosts.reduce((acc, host) => host.profile || acc, false) ? (
    <>
      <Divider />
      <Header>{communityTitle || multipleTitle || singleTitle}</Header>
      {hosts.map(host => <EventHost key={host.id} host={host} />)}
    </>
  ) : null;
};

const EventHost = ({ host: { profile } }) => {
  const [showMessageModal, setShowMEssageModal] = useState();
  const { eventData } = useEventPageData();
  const hostedEvents = profile?.hostedEvents;
  const { copy } = useSettings();
  const { viewer: { user } } = useSession();
  return profile ? (
    <HostContainer>
      <Avatar profile={profile} size="md" linked noName />
      <HostDetails>
        <Subheader>{profile.firstName}</Subheader>
        {hostedEvents && hostedEvents.totalCount > 0 && (
          <DinnersHosted>
            <FAIcon name={[ 'fas', 'utensils' ]} />
            {stringInsert(
              copy.event.number_hosted || '%1$s dinners',
              hostedEvents.totalCount
            )}
          </DinnersHosted>
        )}
        <Bio>{profile.bio}</Bio>
        {user && (
          <Button small onClick={() => setShowMEssageModal(true)}>
            {copy.event.message_host}
          </Button>
        )}
      </HostDetails>
      <MessageHostModal
        event={eventData}
        host={profile}
        show={showMessageModal}
        toggle={() => setShowMEssageModal(prev => !prev)}
      />
    </HostContainer>
  ) : null;
};

const HostContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  .avatar_container {
    width: 80px;
  }
`;

const HostDetails = styled.div`
  position: relative;
  flex: 1;
  padding-left: 20px;

  button {
    position: absolute;
    top: -8px;
    right: 0;
  }
`;

const DinnersHosted = styled.span`
  display: block;
  font-size: 14px;
  color: #83817b;
  margin-top: 15px;

  svg {
    font-size: 15px;
    margin-right: 12px;
  }
`;

const Bio = styled.p`
  margin: 15px 0 0;
`;
