import React, { useContext } from 'react';
import styled from 'styled-components';

import { SessionContext } from '../../Context';

import { Logo, Closer, Hotdogs, withSettings } from '../components';


const HamburgerMainMenu = ({ className, classList, toggleMobileNav, menuItems, overHero }) => {
  const { theme } = useContext(SessionContext);
  return (
    <>
      <Hotdogs onClick={toggleMobileNav} side="left" color={overHero ? 'white' : ''} theme={theme} />
      <div className={[className, ...classList].join(' ')}>
        <Logo className="mobile" link="/" />
        <ul className="main_menu" onClick={toggleMobileNav}>{menuItems}</ul>
        <Closer side="left" desktop onClick={toggleMobileNav} />
      </div>
    </>
  );
}


const StyledHamburgerMainMenu = styled(HamburgerMainMenu)`
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  width: 100%;
  max-width: 320px;
  transform: translateX(-100%);
  background-color: #fff;
  padding: 60px 8px 40px;
  z-index: 100000;
  box-shadow: 0px 1px 19px 0px rgba(0,0,0,0.05);
  overflow: auto;
  transition: transform 350ms;

  // switch to normal layout for mobile devices //
  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    left: auto;
    right: 0;
    transform: translateX(100%);
  }

  &.open {
    transform: translateX(0);
  }

  .main_menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      .dropdown_link,
      .restricted_link,
      .mobile_nav_link,
      & > a {
        padding: 8px 15px;
        font-size: 16px;
        color: ${props => props.settings.theme.css.global.colors.navbarLinkColor || '#333'};
        transition: color 250ms;
        font-weight: 600;
        display: block;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      .fa-caret-down {
        display: none;
      }
    }
  }

  .dropdown_menu {
    ul {
      max-height: 100% !important;
    }
  }

  &.is_host {
    li.hide-when-host {
      display: none;
    }
  }
`;

const HamburgerMainMenuWithSettings = withSettings(StyledHamburgerMainMenu);
export { HamburgerMainMenuWithSettings as HamburgerMainMenu };
