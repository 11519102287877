import styled from 'styled-components';

export const SideTabLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  grid-gap: 4%;

  & > div {
    &:first-child {
      min-width: 200px;
    }
  }
`;
