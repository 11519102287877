const validations = {
  invalidateText: value => {
    if (typeof value !== 'string') return true;
    if (!value.length) return true;
    return false;
  },
  invalidateNumber: value => {
    const numberValue = parseInt(value, 10);
    return isNaN(numberValue) || !numberValue; // Doesn't validate number 0 //
  },
  invalidateDateTime: value => {
    const date = new Date(value);
    if (!value) return true;
    if (isNaN(date.getTime())) return true;
    return false;
  },
  invalidateDateTime30Hours: () => {
    return false;
    // const date = new Date(value);
    // if (!value) return true;
    // if (isNaN(date.getTime())) return true;
    // var diff =(value.getTime() - (new Date()).getTime()) / 1000;
    // diff /= (60 * 60);
    // if(diff < 30) return true;
    // return false;
  },
  invalidateNull: value => {
    return value === null;
  },
  invalidateArray: value => {
    if (!Array.isArray(value)) return true;
    return !value.length;
  },
  isValidEmail: value => {
    return value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  },
  isValidName: value => {
    return (typeof value === 'string') && value.length > 0;
  },
  isValidFullName: value => {
    return (typeof value === 'string') && value.length > 0;
  },
  isFacebookEmail: value => {
    return (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && (/@facebook\.com/i.test(value)));
  },

  validateProfileQuestionsAnswered: (questions, answers, { profile } = {}) => questions.reduce((list, q) => {
    const { value, location } = q;
    let answer, isAnswered;

    switch (location) {
      case 'profile':
        isAnswered = profile && profile[value]
        break;
      default:
        answer = answers && answers[value];
        isAnswered = answer && typeof answer === 'object'
          ? Object.values(answer.answer).some(a => a)
          : !!answer;
    }

    return isAnswered ? list : list.concat([q]);
  }, [])
};

const {
  invalidateText,
  invalidateNumber,
  invalidateDateTime,
  invalidateDateTime30Hours,
  invalidateNull,
  invalidateArray,
  isValidEmail,
  isFacebookEmail,
  isValidName,
  isValidFullName,
  validateProfileQuestionsAnswered
} = validations;

export {
  validations,
  invalidateText,
  invalidateNumber,
  invalidateDateTime,
  invalidateDateTime30Hours,
  invalidateNull,
  invalidateArray,
  isValidEmail,
  isFacebookEmail,
  isValidName,
  isValidFullName,
  validateProfileQuestionsAnswered
};
