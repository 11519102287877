import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useProfilePageData, useSettings } from '../../hooks';
import {
  PaymentMethodsNew,
  PaymentMethod,
  PageSubheader as Header,
  PrimaryButton,
  UnderlineButton,
  Divider
} from '../components';

const blankCard = { cardCVV: '', cardExp: '', cardNumber: '', fullName: '', favorite: true };


export const ProfileSettingsPaymentMethods = () => {
  const { cards } = useProfilePageData();
  const { copy } = useSettings();

  const [view, setView] = useState(false);
  const [newCard, setNewCard] = useState({ ...blankCard })
  const [validFullName, setValidFullName] = useState(true);
  const [validCardNumber, setValidCardNumber] = useState(true);
  const [validCardExp, setValidCardExp] = useState(true);
  const [validCardCVV, setValidCardCVV] = useState(true);

  useEffect(() => {
    if (cards && cards.length) setView('view_method');
    else setView('new_method');
  }, [cards]);

  return (
    <div>
      <Header>{copy.profile.card_pay_title}</Header>
      <Divider />
      {view === 'view_method' && (
        <>
          {cards && cards.map(card => (
            <MethodContainer key={card.id}>
              <PaymentMethod card={card} cards={cards} />
              <UnderlineButton onClick={() => {}}>Delete</UnderlineButton>
            </MethodContainer>
          ))}
          <ButtonContainer>
            <PrimaryButton onClick={() => setView('new_method')}>Add New</PrimaryButton>
          </ButtonContainer>
        </>
      )}
      {view === 'new_method' && (
        <>
          <Header small>{copy.profile.add_payment_method || 'Add payment method'}</Header>
          <PaymentMethodsNew
            data={{
              ...newCard,
              validFullName,
              validCardNumber,
              validCardExp,
              validCardCVV
            }}
            onUpdateField={(key, val) => setNewCard(prev => ({ ...prev, [key]: val }))}
            setValidFullName={setValidFullName}
            setValidCardNumber={setValidCardNumber}
            setValidCardExp={setValidCardExp}
            setValidCardCVV={setValidCardCVV}
          />
          <ButtonContainer>
            <PrimaryButton onClick={() => {}}>Save</PrimaryButton>
            {cards && cards.length > 0 && (
              <UnderlineButton onClick={() => setView('view_method')}>Cancel</UnderlineButton>
            )}
          </ButtonContainer>
        </>
      )}
      <Divider bottom />
    </div>
  );
};

const MethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  .underline_button { margin-left: 25px; }
`;
