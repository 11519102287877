import React from 'react';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../../components';

class Checkbox extends React.Component {
  state = {
    valid: true
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleChange = event => {
    const { onInputChange, name, required, onValidate } = this.props;
    // const { valid } = this.state;
    const { target } = event;
    onInputChange && onInputChange(name, target.checked);

    if (!required) {
      this.setState({ valid: true });
      onValidate && onValidate(name, true);
    } else {
      const isValidInput = target.checked;
      this.setState({ valid: isValidInput });
      onValidate && onValidate(name, isValidInput);
    }
  };

  render() {
    const { className, options, layout, name, float, required } = this.props;
    const { valid } = this.state;
    const checkboxes = options ? options : [{}];

    let floatClassName = '';
    if (float === 'right') { floatClassName = 'float-right'; }
    if (float === 'left') { floatClassName = 'float-left'; }

    const classNames = [
      'checkbox',
      className,
      layout && layout !== 'flex' ? layout : '',
      floatClassName ? floatClassName : '',
      required && !valid ? 'error' : ''
    ];


    return (
      <div className={classNames.join(' ')}>
        {checkboxes.map((box, i) => (
          <div className="checkbox__container" key={i}>
            <label className="checkbox__label">
              <input
                type="checkbox"
                name={name}
                onChange={this.handleChange}
                checked={box.checked}
              />
              <span dangerouslySetInnerHTML={{ __html: box.label }}/>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

const StyledCheckbox = styled(Checkbox)`
  position: relative;

  .checkbox {
    &__container {
      display: inline-block;
      margin-right: 5px;
    }

    &__label {
      display: ${({ layout }) => layout === 'flex' ? 'flex' : 'block'};
      font-weight: normal !important;
      cursor: pointer;

      ${({ session: { theme }, small }) => theme.isVersion(2) ? css`
        padding-left: ${small ? '30px' : '40px'} !important;
      ` : css`
        padding-left: 20px !important;
      `}

      span {
        ${({ session: { theme }, small }) => theme.isVersion(2) ? css`
          font-size: 16px;
          color: #222;
          ${small && css`
            font-size: 14px;
          `}
        ` : css`
          font-size: 14px;
        `}
        text-align: ${({ layout }) => layout === 'flex' ? 'left' : ''};
        a {
          color: ${props => props.settings.theme.css.global.colors.primary};

          &:hover {
            color: ${props => props.settings.theme.css.global.colors.primaryHover};
          }
        }
      }
    }
  }

  &.vertical {
    .checkbox {
      &__container {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  input {
    position: absolute;
    left: 0;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 250ms;
    margin-top: ${({ layout }) => layout === 'flex' ? '2px' : 0};
    flex-shrink: 0;

    ${({ session: { theme }, small }) => theme.isVersion(2) ? css`
      width: 23px;
      height: 23px;
      top: 0;
      background-color: #fff;

      ${small && css`
        transform: scale(0.9);
      `}
    ` : css`
      width: 14px;
      height: 14px;
      top: 2px;
    `}

    &:hover {
      &:not(:checked) {
        ${props => props.session.theme.isVersion(2) ? css`
          border-color: #000;
        ` : css`
          background-color: ${props.settings.theme.css.global.colors.secondaryHover};
        `}
      }
    }

    &:checked {
      border-color: ${props => props.settings.theme.css.global.colors.primary};

      &::after {
        content: '';
        position: absolute;
        transform: rotate(-45deg);
      }

      ${props => props.session.theme.isVersion(2) ? css`
        &::after {
          border: solid ${props.settings.theme.css.global.colors.primary};
          border-width: 0 0 2px 2px;
          top: 5px;
          left: 3px;
          width: 15px;
          height: 7px;
        }
      ` : css`
        background-color: ${props.settings.theme.css.global.colors.primary};

        &::after {
          border: solid #fff;
          border-width: 0 0 2px 2px;
          top: 2px;
          left: 1px;
          width: 10px;
          height: 5px;
        }
      `}
    }
  }

  &.error {
    input {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }
  }
`;

const StyledCheckboxWithSettings = withSettings(StyledCheckbox);
const CheckboxWithSession = withSession(StyledCheckboxWithSettings);
export { CheckboxWithSession as Checkbox };
