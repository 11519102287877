import React from 'react';
import styled from 'styled-components';
import MDSpinner from 'react-md-spinner';
import { withSettings } from '../components';

const Spinner = ({ className, size, border, color1, color2, color3, color4, settings }) => (
  <div className={`spinner ${className}`}>
    <MDSpinner
      size={size || 19}
      borderSize={border || 2}
      color1={color1 || settings.theme.css.global.colors.primary}
      color2={color2 || settings.theme.css.global.colors.primaryHover}
      color3={color3 || settings.theme.css.global.colors.primary}
      color4={color4 || settings.theme.css.global.colors.primaryHover}
    />
  </div>
);

const StyledSpinner = styled(Spinner)`
  display: inline-block;
`;

const SpinnerWithSettings = withSettings(StyledSpinner);

export { SpinnerWithSettings as Spinner };
