import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
// import { ApolloConsumer } from '@apollo/client';
import { withSettings } from '../components';
import { SessionContext } from '../../Context';

import { removeToken } from '../../libs/token';
import { isWordpress } from '../../libs';
import * as userJourney from '../../libs/userJourney';
import * as otp from '../../libs/otp';

// const clearViewer = (client) => {
//   const emptyViewer = {
//     cards: null,
//     draftEvents: null,
//     hostedEvents: null,
//     invitations: null,
//     reservations: null,
//     user: null,
//     __typename: 'Viewer'
//   };
//
//   const data = client.readQuery({ query: INIT });
//   data.viewer = emptyViewer;
//   client.writeQuery({ query: INIT, data });
//   userJourney.resetAll();
// }

const resetWordpressNav = () => {
  if (isWordpress()) {
    const elsToHide = Array.from(document.querySelectorAll('.hide-when-not-host'));
    const elsToShow = Array.from(document.querySelectorAll('.hide-when-host'));
    elsToHide.forEach(el => { el.style.display = 'none'; });
    elsToShow.forEach(el => { el.style.display = 'inline-block'; });
  }
};

const handleLogOut = async (refetch, history, settings ) => {
  removeToken(settings);
  resetWordpressNav();
  userJourney.resetAll();
  otp.resetAll();
  await refetch();
  history.push('/landing');
};

const LogOutLink = ({ history, settings }) => {
  const { refetch } = useContext(SessionContext);
  return (
    <div
      className="log_out_link"
      onClick={() => handleLogOut(refetch, history, settings)}
    >{settings.copy.menu.logout}</div>
  );
}

const LogOutLinkWithRouter = withRouter(LogOutLink);
const LogOutWithSettings = withSettings(LogOutLinkWithRouter);

export { LogOutWithSettings as LogOutLink };
