import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { RECURRENCE_UPDATE_TYPE } from '../constants/recurrances';
import { EVENT_PUBLISH, SUBMIT_PENDING_APPROVAL, UPDATE_EVENT } from '../mutations';
import { GET_EVENT_CANCEL_REASONS } from '../queries';
import { GET_RESERVATION_CANCEL_REASONS } from '../queries/Reservations';
import asyncForEach from './asyncForEach';

export function useUpdateRecurringEventMutation() {
  return useMutation(UPDATE_EVENT);
}

export function usePublishRecurringEventMutation() {
  return useMutation(EVENT_PUBLISH);
}
export function useSubmitPendingApprovalEventMutation() {
  return useMutation(SUBMIT_PENDING_APPROVAL);
}

export const withEventMutationHooksHOC = (Component: any) => {
  return (props: any) => {
    const [updateMutation] = useUpdateRecurringEventMutation();
    const [publishMutation] = usePublishRecurringEventMutation();
    const [submitPendingMutation] = useSubmitPendingApprovalEventMutation();

    return (
      <Component
        updateEventMutation={updateMutation}
        publishEventMutation={publishMutation}
        submitPendingEventMutation={submitPendingMutation}
        {...props}
      />
    );
  };
};

export function useGetCancelEventReasonQuery() {
  return useQuery(GET_EVENT_CANCEL_REASONS);
}
export const withCancelEventQueryHooksHOC = (Component: any) => {
  return (props: any) => {
    const { loading, data, error } = useGetCancelEventReasonQuery();

    return (
      <Component
        getCancelEventError={error}
        getCancelEventLoading={loading}
        getCancelEventData={data}
        {...props}
      />
    );
  };
};
export function useGetCancelReservationReasonQuery() {
  return useQuery(GET_RESERVATION_CANCEL_REASONS);
}
export const withCancelReservationQueryHooksHOC = (Component: any) => {
  return (props: any) => {
    const { loading, data, error } = useGetCancelReservationReasonQuery();

    return (
      <Component
        getCancelReservationError={error}
        getCancelReservationLoading={loading}
        getCancelReservationData={data}
        {...props}
      />
    );
  };
};

export const getRecurrencesToModify = (eventData, eventUpdateType, isPublish) => {
  let recurrences = [];
  switch (eventUpdateType) {
    case RECURRENCE_UPDATE_TYPE.THIS_EVENT_AND_FOLLOWING:
      recurrences = eventData.recurrences.filter((f) => f.id > eventData.id);
      break;
    case RECURRENCE_UPDATE_TYPE.ALL_EVENTS:
      recurrences = eventData.recurrences.filter((f) => f.id !== eventData.id);
      break;
    default:
      break;
  }
  return isPublish ? recurrences.filter((f) => f.state !== 'PUBLISHED') : recurrences;
};

export const getEventAction = (actionType, eventData) => {
  if (!eventData.recurrences) {
    return `${actionType}_event`;
  }

  const action =
    eventData.recurrences.length > 0 ? `${actionType}_recurring_event` : `${actionType}_event`;

  return action;
};

export const publishRecurringEvents = async (
  eventUpdateType,
  publishEvent,
  eventData,
  publishEventMutation,
  handleActionClick
) => {
  const recurrences = getRecurrencesToModify(eventData, eventUpdateType, true);
  // publish recurrences
  await asyncForEach(recurrences, async (recurrence) => {
    await publishEventMutation({
      variables: { eventId: recurrence.id }
    });
  });
  // publish event
  await handleActionClick(publishEvent);
};
