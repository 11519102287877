import React from 'react';
import styled, { css } from 'styled-components';

import { withSettings } from '../../components';


export const SearchFilter = ({ children, active, primaryActive, icon, inline, hover = true, fullWidth, styles = {}, onClick }) => (
  <SearchFilterContainer
    active={active}
    primaryActive={primaryActive}
    icon={icon}
    inline={inline}
    hover={hover}
    fullWidth={fullWidth}
    style={styles}
    onClick={onClick}
  >
    <SearchFilterValue className="value" primaryActive={primaryActive}>{children}</SearchFilterValue>
  </SearchFilterContainer>
);


const SearchFilterContainerWithoutSettings = styled.div`
  border: 1px solid #ceccc7;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: border-color 250ms;

  ${props => props.inline && css`margin-right: 12px;`}
  ${props => props.active && css`border-color: #000;`}
  ${props => {
    if (props.icon === 'left') return css`padding-left: 20px;`
    else if (props.icon) return css`padding-right: 20px;`
  }}
  ${props => props.hover && css`
    &:hover {
      border-color: #000;
    }
  `}
  ${props => props.fullWidth ? css`
    display: block;
  ` : css`
    display: inline-block;
  `}
  ${props => props.primaryActive && css`
    border-color: ${props.settings.theme.css.global.colors.primary} !important;

    // border-color: #000;
    background-color: ${props.settings.theme.css.global.colors.primary};
  `}
`;
export const SearchFilterContainer = withSettings(SearchFilterContainerWithoutSettings);

const SearchFilterValueWithoutSettings = styled.span`
  color: #222;
  font-size: 15px;
  padding: 12px 18px;
  display: block;
  font-family: 'Avenir-Medium' !important;
  transition: color 250ms, padding 200ms;
  white-space: nowrap;
  user-select: none;

  ${props => props.notSelected && css`
    color: #81807c;

    &:hover {
      color: #222;
    }
  `}

  ${props => props.withButton && css`
    min-width: 200px;
    margin-right: 45px;
  `}

  ${props => props.scrolled && css`
    padding: 10px 14px;
  `}

  ${props => props.primaryActive && css`
    span {
      // color: ${props.settings.theme.css.global.colors.primary};
      color: #fff;
    }
  `}

  @media (max-height: 840px) {
    padding: 10px 14px;
  }
`;
export const SearchFilterValue = withSettings(SearchFilterValueWithoutSettings);
