import React from 'react';
import { SelectBox, withSettings } from '../../components';
import { defaultSelectValue } from '../Filters';

import stateLocationOptions from '../../../constants/stateLocationOptions';
import canadianStateLocationOptions from '../../../constants/canadianStateLocationOptions';

const StateSelect = ({
  name,
  handleFilterChange,
  reset,
  settings: { global: { enable_canadian_provinces } }
}) => {
  const stateOptions = [
    ...stateLocationOptions,
    ...(enable_canadian_provinces ? canadianStateLocationOptions : [])
  ];

  return (
    <SelectBox
      defaultValue={defaultSelectValue}
      options={[
        defaultSelectValue,
        ...stateOptions.map(state => ({
          id: state[0],
          label: state[1],
          value: state[0],
        }))
      ]}
      onOptionSelect={value => handleFilterChange(name, value)}
      refreshOnChange={[reset.area, reset.shouldReset]}
    />
  );
};

const StateSelectWithSettings = withSettings(StateSelect);
export { StateSelectWithSettings as StateSelect };
