import React from 'react';
import styled from 'styled-components';
import { Input } from '../';

const ProfileEditSocialAccount = ( { name, label, value, handleInputChange, handleInputValidation } ) => {
  return (
    <div className="form_group">
      <div className="one_third">
        <label>{label}</label>
      </div>
      <div className="two_thirds">
        <Input
          type="text"
          name={name}
          placeholder={`Add your ${label} account`}
          value={value}
          onInputChange={handleInputChange}
          onValidate={handleInputValidation}
        />
      </div>
    </div>
  );
}

const StyledProfileEditSocialAccount = styled(ProfileEditSocialAccount)`

`;

export { StyledProfileEditSocialAccount as ProfileEditSocialAccount }