import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import moment from 'moment-timezone';

import {
  Modal,
  PageSubheader as Header,
  DatePicker,
  Button,
  withSettings,
  withSession
} from '../components';

import { UPDATE_PROFILE } from '../../mutations';

const VaccinationDetailsModal = ({ className, show, toggle, action, settings, session, size = 'sm' }) => {
  const { copy } = settings;
  const { refetch, theme } = session;
  const [vaccinationDate, setVaccinationDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const handleUpdateProfile = async () => {
    setLoading(true);

    await updateProfile({ variables: { vaccinationDate } });
    await refetch();

    setLoading(false);
    toggle(true, vaccinationDate);
  };

  const dateIsInvalid = () => {
    if (!vaccinationDate) return true;
    return moment(vaccinationDate) > moment();
  };

  const getTitle = () => {
    switch (action) {
      case 'create':
        return copy.create.host_requires_vaccination_title;
      case 'rsvp':
        return copy.rsvp.guest_requires_vaccination_title;
      default:
        return '';
    }
  };

  const getMessage = () => {
    switch (action) {
      case 'create':
        return copy.create.host_requires_vaccination_message;
      case 'rsvp':
        return copy.rsvp.guest_requires_vaccination_message;
      default:
        return '';
    }
  };

  const Heading = ({ children }) => theme.isVersion(2)
    ? <Header>{children}</Header>
    : <h2>{children}</h2>;

  return (
    <Modal
      className="modal__vaccination"
      show={show}
      toggle={toggle}
      size={size}
      backdrop="static"
      noPadding
    >
      <div className={`${className} vaccination__modal_content`}>
        <Heading>{getTitle()}</Heading>
        <p dangerouslySetInnerHTML={{ __html: getMessage() }} />

        <div>
          <DatePicker
            value={vaccinationDate}
            max={new Date()}
            onDateChange={value => setVaccinationDate(value)}
            themed={theme.isVersion(2)}
          />
          <div className="vaccination__modal_buttons">
            <Button
              className="button__cancel"
              handleClick={() => toggle()}
              buttonStyle={theme.isVersion(2) ? 'outline' : 'inverted'}
              themed={theme.isVersion(2)}
            >Cancel</Button>
            <Button
              className="button__submit"
              handleClick={handleUpdateProfile}
              disabled={loading || dateIsInvalid()}
              loading={loading}
              themed={theme.isVersion(2)}
            >Submit</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const StyledVaccinationDetailsModal = styled(VaccinationDetailsModal)`
  ${({ session: { theme } }) => theme.isVersion(2) ? css`
    padding: 3rem 3.5rem;
  ` : css`
    padding: 30px;
  `}
  text-align: center;

  .modal__vaccination.modal-sm {
    @media (min-width: 992px) {
      max-width: 500px;
    }
  }

  h2 {
    margin: 0 0 20px;

    ${({ session: { theme }, colors: { primaryDark } }) => theme.isVersion(2) ? css`

    ` : css`
      font-size: 18px;
      color: ${primaryDark};
    `}
  }

  p {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      font-size: 15px;
      margin: 0 0 20px;
    ` : css`
      font-size: 12px;
      color: #424242;
      margin: 0 0 30px;
    `}
    text-align: center;
  }

  .vaccination__modal_buttons {
    position: relative;
    padding-top: 10px;

    & > .inverted {
      border: 1px solid ${props => props.settings.theme.css.global.colors.primary};
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .button {
    &__cancel {
      float: left;
    }

    &__submit {
      float: right;
    }

    &__cancel,
    &__submit {
      width: calc(50% - 10px);
    }
  }
`;
const VaccinationDetailsModalWithSettings = withSettings(StyledVaccinationDetailsModal);
const VaccinationDetailsModalWithSession = withSession(VaccinationDetailsModalWithSettings);
export { VaccinationDetailsModalWithSession as VaccinationDetailsModal };
