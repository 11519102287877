import React from 'react';
import styled, { css } from 'styled-components';

import {
  Modal,
  Logo,
  withSettings,
  withSession
} from '../components';

const ErrorModal = ({ className, children, show, toggle }) => (
  <Modal
    show={show}
    toggle={toggle}
    size="sm"
    noPadding
  >
    <div className={className}>
      <Logo layout="vertical" />
      {children}
    </div>
  </Modal>
);


const StyledErrorModal = styled(ErrorModal)`
  ${({ session: { theme } }) => theme.isVersion(2) ? css`
    padding: 30px;
  ` : css`
    padding: 30px 20px;
  `}

  .logo {
    max-width: 140px;
    margin: 0 auto 30px;
  }

  h2 {
    font-size: 18px;
    text-align: center;
    margin: 0 0 20px;
  }

  p {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      font-size: 15px;
    ` : css`
      font-size: 14px;
    `}
    text-align: center;
    margin: 0;
  }

  a {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }
`;

const ErrorModalWithSettings = withSettings(StyledErrorModal);
const ErrorModalWithSession = withSession(ErrorModalWithSettings);
export { ErrorModalWithSession as ErrorModal };
