const ShareFacebook = {
  openModal: (event, settings) => {
    const eventURL = event.shortUrl;
    let currentEventPhotoURL = settings ? settings.theme.assets.images.bannerless : null;
    if (event.cover) currentEventPhotoURL = event.cover.originalUrl;

    if (window.FB.initialized) {
      try {
        window.FB.ui({
          display: 'popup',
          method: 'feed',
          link: eventURL,
          quote: ''
        }, response => console.log(`response: ${response}`));
      } catch(err) {
        ShareFacebook.backupFBShare(event, currentEventPhotoURL);
      }
    } else {
      ShareFacebook.backupFBShare(event, currentEventPhotoURL);
    }
  },

  backupFBShare: (event) => {
    let url = `https://www.facebook.com/sharer/sharer.php?u=${event.shortUrl}`;
    if (event.description) url += `&quote=${event.description}`;
    window.open(url, 'pop', 'width=600, height=400, scrollbars=no');
  }
};

export { ShareFacebook };
