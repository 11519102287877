import gql from 'graphql-tag';


// TODO: why are hosted events listed twice?? //
export const viewerQuery = `
  viewer {
    user {
      id
      email
      state
      canCopyEvents
      hostApprovalAt
      mustChangeEmail
      profile {
        id
        firstName
        lastName
        avatar {
          id
          photo {
            originalUrl
            smallUrl
            mediumUrl
            largeUrl
          }
        }
        bio
        dateOfBirth
        emailStatus
        address
        secondaryAddress
        city
        cityState
        zipCode
        country
        phone
        facebook
        twitter
        instagram
        snapchat
        answers
        eventHostedVisible
        eventAttendedVisible
        showLanguageOnEvents
        vaccinationDate
        reservationMembershipRequirementExempt
        area {
          id
          code
          url
          nourishmentLockDaysBeforeEvent
          newHostNourishmentDelayDays
          maxPaidAdditionalGuests
          maxUnpaidAdditionalGuests
        }
        areaProfile {
          id
          url
        }
      }
      communityDinnerCreator
    }
    hostedEvents: events(filter: HOSTED) {
      edges {
        node {
          id
          uuid
          scheduledAt
          sponsorshipDeadline
          area {
            id
            code
            nourishmentLockDaysBeforeEvent
            newHostNourishmentDelayDays
            maxPaidAdditionalGuests
            maxUnpaidAdditionalGuests
          }
          state
          scheduledAt
          sponsorshipDeadline
        }
      }
    }
    draftEvents: events(filter: DRAFT) {
      edges {
        node {
          id
          scheduledAt
          sponsorshipDeadline
          area {
            id
            code
            nourishmentLockDaysBeforeEvent
            newHostNourishmentDelayDays
            maxPaidAdditionalGuests
            maxUnpaidAdditionalGuests
          }
        }
      }
    }
    allEvents: events(state: [DRAFT, PENDING_PUBLISH_APPROVAL, PUBLISHED], relation: HOST) {
      totalCount
    }
    reservations (includePast: true) {
      totalCount
      nodes {
        id
        event {
          id
          uuid
        }
        state
      }
    }
    currentReservations: reservations {
      totalCount
      nodes {
        id
        event {
          id
          uuid
          eventDate
        }
        state
      }
    }
    invitations {
      nodes {
        id
        event {
          id
          uuid
        }
        status
      }
    }
    cards {
      id
      brand
      expiration
      favorite
      owner
      number
      image
    }
    subscriptions {
      id
      price {
        id
        active
        product {
          id
          active
          name
          description
          hint
        }
      }
      status
      cancelAt
      currentPeriodEnd
      # currentPeriodStart
      endedAt
    }
    memberships {
      id
    }
  }
`;

export const VIEWER = gql`
    query viewer {
        ${viewerQuery}
    }
`;

export const VIEWER_PROFILE_DATA = gql`
    query viewerProfileData {
        viewer {
            user {
                id
                email
                state
                profile {
                    id
                    firstName
                    lastName
                    avatar {
                        id
                        photo {
                            originalUrl
                            smallUrl
                            mediumUrl
                            largeUrl
                        }
                    }
                    bio
                    dateOfBirth
                    emailStatus
                    address
                    secondaryAddress
                    city
                    cityState
                    zipCode
                    country
                    phone
                    facebook
                    twitter
                    instagram
                    snapchat
                    answers
                    showAge
                    eventHostedVisible
                    eventAttendedVisible
                    showOnGuestList
                    showLanguageOnEvents
                    vaccinationDate
                    area {
                        id
                        code
                        nourishmentLockDaysBeforeEvent
                        newHostNourishmentDelayDays
                        maxPaidAdditionalGuests
                        maxUnpaidAdditionalGuests
                    }
                }
            }
            cards {
                id
                brand
                expiration
                favorite
                owner
                number
                image
            }
            memberships {
              id
              name
              header
              content
            }
        }
    }
`;

export const VIEWER_HOSTED_AND_DRAFT_EVENTS = gql`
    query viewerHostAndDraftEvents {
        viewer {
            id
            hostedEvents: events(filter: HOSTED) {
                edges {
                    node {
                        area {
                            code
                            nourishmentLockDaysBeforeEvent
                            newHostNourishmentDelayDays
                            maxPaidAdditionalGuests
                            maxUnpaidAdditionalGuests
                        }
                    }
                }
            }
            draftEvents: events(filter: DRAFT) {
                edges {
                    node {
                        area {
                            code
                            nourishmentLockDaysBeforeEvent
                            newHostNourishmentDelayDays
                            maxPaidAdditionalGuests
                            maxUnpaidAdditionalGuests
                        }
                    }
                }
            }
        }
    }
`;

export const VIEWER_HOST_FORM = gql`
    query ViewerHostForm {
        viewer {
            hostRequestForm {
                head
                body
            }
        }
    }
`;

export const PROFILE = gql`
    query profile($id: Int!) {
        profile(id: $id) {
            id
            firstName
            lastName
            avatar {
                id
                photo {
                    originalUrl
                    smallUrl
                    mediumUrl
                    largeUrl
                }
            }
            showAge
            dateOfBirth
            bio
            answers
            facebook
            twitter
            instagram
            snapchat
            eventHostedVisible
            eventAttendedVisible
            hostedEvents(first:1) {
                totalCount
            }
            attendedEvents(first:1) {
                totalCount
            }
        }
    }
`;

export const VIEWER_EVENTS = gql`
    query ViewerEvents ($filter: ViewerEventFilter!, $isHost: Boolean!, $orderDirection: Direction){
        viewer {
            events(filter: $filter, orderDirection: $orderDirection) {
                nodes {
                    id
                    uuid
                    title
                    state
                    cover {
                        smallUrl
                        mediumUrl
                        largeUrl
                        originalUrl
                    }
                    scheduledAt
                    sponsorshipDeadline
                    duration
                    area {
                        id
                        label
                        nourishmentLockDaysBeforeEvent
                        newHostNourishmentDelayDays
                        maxPaidAdditionalGuests
                        maxUnpaidAdditionalGuests
                    }
                    neighborhood
                    subneighborhood
                    eventType
                    numberOfGuestsMax
                    reservations {
                        totalCount
                        guestsAccepted
                        guestsPending
                        reservationsAccepted
                        nodes {
                            id
                            state
                            profile {
                                id
                                firstName
                                lastName
                                user @include(if: $isHost) {
                                    id
                                    email
                                }
                            }
                            potluckItems {
                                edges {
                                    node {
                                        id
                                        name
                                        quantity
                                        claimedQuantity
                                        freeQuantity
                                        position
                                        claims {
                                            id
                                            quantity
                                            reservation {
                                                profile {
                                                    id
                                                    firstName
                                                    lastName
                                                    avatar {
                                                        id
                                                        photo {
                                                            originalUrl
                                                            smallUrl
                                                            mediumUrl
                                                            largeUrl
                                                            originalUrl
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    reservationsAccepted: reservations(filterByState: ACCEPTED) {
                        reservationsAccepted
                        nodes {
                            id
                            profile {
                                id
                                firstName
                                lastName
                                avatar {
                                    id
                                    photo {
                                        largeUrl
                                    }
                                }
                            }
                        }
                    }
                    pricePerPerson
                    nourishable
                    nourishmentRestrictionExceptionExpiresAt
                    sponsorships {
                        id
                        amount
                        sponsor {
                            id
                            name
                        }
                    }
                    eventHosts(creator: true) {
                        edges {
                            node {
                                id
                                effectiveEmail
                                profile {
                                  id
                                  firstName
                                  lastName
                                  avatar {
                                      id
                                      photo {
                                          smallUrl
                                          mediumUrl
                                          largeUrl
                                          originalUrl
                                      }
                                  }
                                }
                            }
                        }
                    }
                    expectedGuests
                    virtual
                    potluck
                    potluckItems {
                        totalCount
                        edges {
                            node {
                                id
                                name
                                quantity
                                claimedQuantity
                                freeQuantity
                                position
                                claims {
                                    id
                                    quantity
                                    reservation {
                                        profile {
                                            id
                                            firstName
                                            lastName
                                            avatar {
                                                id
                                                photo {
                                                    originalUrl
                                                    smallUrl
                                                    mediumUrl
                                                    largeUrl
                                                    originalUrl
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const VIEWER_EVENTS_COUNT = gql`
    query ViewerEventsCount {
        viewer {
            HOSTED: events(filter: HOSTED) {
                totalCount
            }
            DRAFT: events(filter: DRAFT) {
                totalCount
            }
            PAST: events(filter: PAST) {
                totalCount
            }
            RESERVED: events(filter: RESERVED) {
                totalCount
            }
            ATTENDED: events(filter:ATTENDED) {
                totalCount
            }
            PENDING_PUBLISH_APPROVAL: events(filter: PENDING_PUBLISH_APPROVAL){
                totalCount
            }
        }
    }
`;

export const USER_JOURNEY_RESOURCES = gql`
  query userJourneyResources {
      viewer {
          journeyResponses(last: 1) {
              nodes {
                  resources {
                      nodes {
                          id
                          description
                          title
                          url
                          image {
                              originalUrl
                              smallUrl
                              mediumUrl
                              largeUrl
                          }
                      }
                  }
              }
          }
      }
    }
`;
