import React from 'react';
import styled from 'styled-components';
import jQuery from 'jquery';
import { formAssemblyStyle } from '../../styles';

class FormAssemblyComponent extends React.Component {

    componentDidMount() {
       const { form: faFrom, afterSubmit, css } = this.props;
       const { head } = faFrom;
       let { body } = faFrom;
       const formCss = css ? css + formAssemblyStyle : formAssemblyStyle;

       const iframe = document.getElementById('assembly');
       let iframedoc = iframe.document;

        if (iframe.contentDocument) {
            iframedoc = iframe.contentDocument;
        } else {
            iframedoc = iframe.contentWindow.document;
        }

        body = body.replace(/!important/g,'');
        body += `<style type="text/css">${formCss}</style>`;

        iframedoc.open();
        iframedoc.writeln(head + body);
        iframedoc.close();

        function onElementHeightChange(elm, callback){
            var lastHeight = elm.clientHeight, newHeight;
            (function run(){
                newHeight = elm.clientHeight;
                if (lastHeight !== newHeight) {
                    callback();
                }
                lastHeight = newHeight;
                if(elm.onElementHeightChangeTimer) {
                    clearTimeout(elm.onElementHeightChangeTimer);
                }
                elm.onElementHeightChangeTimer = setTimeout(run, 200);
            })();
        }

        onElementHeightChange(iframedoc.documentElement, function(){
            iframe.setAttribute('height', iframedoc.documentElement.clientHeight);
            iframe.style.opacity = 1;
        });

        const validateForm = this.validateForm;

        const timer = setInterval(function() {
            const form = jQuery(iframedoc.documentElement).find('form');
            if (form && form.length > 0) {
                form.on('submit', function(e){
                    e.preventDefault();
                    setTimeout(function () {
                        var valid = validateForm(form);
                        if (valid) {
                            const action = form.attr('action');
                            jQuery.ajax({
                                method: 'POST',
                                url: action,
                                data: form.serialize()
                            });
                            afterSubmit();
                        }
                    }, 2000);
                })
                clearInterval(timer);
            }
        }, 200);
    }

    validateForm = (form) => {
        const required = form.find('.errFld');
        const valid = required.length === 0;
        return valid;
    }

    render() {
        const { className } = this.props;
        return (
            <div className={className}>
                <iframe id="assembly" title="assembly" />
            </div>
        );
    }
}

const StyledFormAssemblyComponent = styled(FormAssemblyComponent)`
    iframe {
        width: 100%;
        overflow-y: hidden;
        border: none;
        opacity: 0;
    }
`;

const FormAssembly = ({ css, ...props }) => {
  // to be continued //
  return (
    <StyledFormAssemblyComponent css={css} {...props} />
  );
};

export { FormAssembly };
