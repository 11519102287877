import React from 'react';
import styled from 'styled-components';
import { Icon } from '../components';
import { withSettings } from '../components';
import { breakpoints } from '../../styles';

const numberOfCourses = menu => {
  const hasApps = menu.first.length > 0;
  const hasCourses = menu.second.length > 0;
  const hasDeserts = menu.third.length > 0;

  if (hasApps && hasCourses && hasDeserts) {
    return 'three';
  } else if ((hasApps && hasCourses) || (hasApps && hasDeserts) || (hasCourses && hasDeserts)) {
    return 'two';
  } else {
    return 'one';
  }
};

const getCourseMealName = course => {
  return course.name || course;
}

const getCourseListComponent = (course, index) => {
  const mealName = getCourseMealName(course);

  if (!mealName) {
    return null;
  }

  return (<li key={index}>{mealName}</li>);
}

const EventViewMealProviding = props => {
  const { className, menu } = props;
  const { settings: { copy } } = props;

  return (
    <div className={`${className} ${numberOfCourses(menu)}`}>
      {menu.first.length > 0 && (
        <div className="course">
          <Icon name={'APPETIZERS'} />
          <h4>{copy.event.menu_first_course}</h4>
          <i className="divider" />
          <ul>
            {menu.first.map((course) => getCourseListComponent(course))}
          </ul>
        </div>
      )}

      {menu.second.length > 0 && (
        <div className="course">
          <Icon name={'MAIN_COURSE'} />
          <h4>{copy.event.menu_second_course}</h4>
          <i className="divider" />
            <ul>
              {menu.second.map((course) => getCourseListComponent(course))}
            </ul>
        </div>
      )}

      {menu.third.length > 0 && (
        <div className="course">
          <Icon name={'DESSERTS'} />
          <h4>{copy.event.menu_dessert}</h4>
          <i className="divider" />
          <ul>
            {menu.third.map((course) => getCourseListComponent(course))}
          </ul>
        </div>
      )}
    </div>
  );
}

const StyledEventViewMealProviding = styled(EventViewMealProviding)`
  text-align: center;

  // &::after {
  //   content: '';
  //   display: block;
  //   clear: both;
  // }

  & > .course {
    display: inline-block;
    vertical-align: top;
    // float: left;
    width: 30%;
    text-align: center;

    @media (${breakpoints.mobile}) {
      width: 100%;
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    & > .icon {
      width: 100px;
      height: 100px;
      display: inline-block;
    }

    & > h4 {
      color: ${props => props.settings.theme.css.global.colors.primary};
      padding: 0;
      margin: 10px 0 0;
      font-size: 18px;
      font-weight: normal !important;
    }

    & > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 13px;
      font-weight: bold;
    }
  }

  &.one {

  }

  &.two {
    .course {
      min-width: 250px;

      @media (${breakpoints.tabletSmall}) {
        width: 40%;
        min-width: 0;
      }

      @media (${breakpoints.mobile}) {
        width: 100%;
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  &.three {
    .course {

    }
  }
`;

const StyledEventViewMealProvidingWithSettings = withSettings(StyledEventViewMealProviding);

export { StyledEventViewMealProvidingWithSettings as EventViewMealProviding };
