import { getSiteNameFromSettings } from './';

const whitelabel = {
  hasFeature: (settings, feature) => {
    const namespace = getSiteNameFromSettings(settings);// site.includes('.') ? site.split('.')[0] : site;
    const siteSettings = whitelabel.siteSettings.find(s => s.site === namespace);

    if (siteSettings && siteSettings.settings.hasOwnProperty(feature)) {
      return siteSettings.settings[feature];
    }

    return false;
  },

  // available site settings //
  /*
  TODO need explanation
  {
    hideZipCode
    hideDOB
    addPhoneToSignup
    showAreaSubtypeLabel
    hidePotluckIcons
    neighborhoodOnly - shows only neighborhood on event card / details label
    hideTimezone - hides timesone on event card / details
    hideNeighborhood - hides neighborhood on event card / details
    showEventSubtype - show event subtype on event card / details
    showAreaLabel - show area label on event details
    showPrivacySubtype - show privacySubtype on event card
    useAddressAreaLabel - uses event address instead of area/neighborhood in event management pages
    hideExtraVirtualIcons - hide closedCaptioningAvailable, videosOnAsDefault, and bathroomAccessible
  }
  */

  siteSettings: [
    {
      site: 'hillel',
      settings: {
        hideZipCode: true,
        hideDOB: true,
        addPhoneToSignup: true,
        showAreaSubtypeLabel: true,
        hidePotluckIcons: true
      }
    },
    {
      site: 'thegathering',
      settings: {
        neighborhoodOnly: true,
        hideTimezone: true,
        showEventSubtype: true,
        showAreaLabel: true
      }
    },
    {
      site: 'signaturefd',
      settings: {
        showEventSubtype: true,
        hideZipCode: true,
        hideTimezone: true,
        showPrivacySubtype: true
      }
    },
    {
      site: 'dinner-party',
      settings: {
        showEventSubtype: true
      }
    },
    {
      site: 'careerpassportonwardisrael',
      settings: {
        hideTimezone: true,
        hideNeighborhood: true,
        useAddressAreaLabel: true
      }
    },
    {
      site: 'haggadot',
      settings: {
        showEventSubtype: true,
        hideExtraVirtualIcons: true
      }
    },
    {
      site: 'centralsynagogue',
      settings: {
        hideTimezone: true,
        showEventSubtype: true,
        showAreaSubtypeLabel: true,
        hideNeighborhood: true
      }
    }
  ]
};

const {
  hasFeature
} = whitelabel;

export {
  whitelabel,
  hasFeature
};
