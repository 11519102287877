import React from 'react';
import styled, { css } from 'styled-components';
import { useSession } from '../../../hooks';

export const ButtonVersion = ({ children, styles = {}, small, fullWidth, themed }) => {
  const { theme } = useSession();
  
  if (!themed) return children;

  switch (theme.version) {
    case 2:
      return (
        <Container small={small} fullWidth={fullWidth} style={styles}>
          {children}
        </Container>
      );
    default:
      return children;
  }
};

const Container = styled.div`
  button {
    .button_content {
      font-family: 'Avenir-Medium';
    }

    ${({ small }) => small ? css`
      padding: 13px 17px 12px !important;
      min-width: 120px;

      .button_content {
        font-size: 14px;
        font-family: 'Avenir-Demi';
      }
    ` : css`
      padding: 16px 20px 14px !important;
      min-width: 150px;

      .button_content {
        font-size: 16px;
      }
    `}
  }

  ${({ fullWidth }) => fullWidth && css`
    div {
      max-width: 100%;
    }
  `}

  .accept-button,
  .decline-button {
    flex: 0 0 50% !important;
    margin: 0 !important;
    button {
      width: 100%;
    }
  }

  .accept-button {
    padding-right: 5px;
  }

  .decline-button {
    padding-left: 5px;
  }
`;
