import styled, { css } from 'styled-components';
import { withSettings } from '../components';

const Closer = styled.span`
  position: absolute;
  top: 20px;
  width: 22px;
  height: 22px;
  cursor: pointer;

  ${props => !props.desktop && (
    css`
      display: none;
  `)}

  ${props => props.side === 'left' ? (
    css`
      left: 20px;

      // switch to normal layout for mobile devices //
      @media (max-width: 700px) {
        left: auto;
        right: 20px;
      }
  `) : (
    css`
      right: 20px;
  `)}

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${props => props.settings.theme.css.global.colors.primaryDark};
    position: absolute;
    transform-origin: center;
    top: 50%;
    margin-top: -1px;
    transition: background-color 250ms;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {
    display: block;
  }
`;

const CloserWithSettings = withSettings(Closer);

export { CloserWithSettings as Closer };
