import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonLoader, Modal } from '../';
import PropTypes from 'prop-types';
import {
  withSettings,
  withSession,
  PageSubheader as Header,
  PrimaryButton
} from '../components';
import { Mutation } from 'react-apollo';
import { alert, WYSIWYGEditor } from '../Global';

import { REJECT_INVITATION } from '../../mutations';

const EventGuestsDeclineInvitationModal = (props) => {
  const { className, show, toggle, invitationId, settings: { copy }, session: { theme } } = props;
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const declineInvitationButtonClicked = async (rejectInvitationMutation) => {
    setLoading(true);

    const response = await rejectInvitationMutation({ variables: { invitationId, message }, errorPolicy: 'all' });

    setLoading(false);
    setMessage('');

    if (!response.errors) {
      toggle();
    } else {
      alert.error('Invitation could not be declined.');
    }
  };

  const ModalTitle = ({ children }) => theme.isVersion(2)
    ? <Header>{children}</Header>
    : <h3>{children}</h3>;

  const AcceptButton = theme.isVersion(2) ? Button : PrimaryButton;

  return (
    <Mutation mutation={REJECT_INVITATION}>
      {(rejectInvitationMutation) => (
        <Modal show={show} toggle={toggle} size={theme.isVersion(2) ? 'md' : 'sm'} noPadding>
          <div className={className}>
            <ModalTitle>{copy.rsvp.declined_title}</ModalTitle>
            <WYSIWYGEditor
              name="message"
              value={message}
              onInputChange={(name, value) => setMessage(value)}
              placeholder={copy.rsvp.declined_message}
            />
            <AcceptButton
              buttonStyle="primary"
              handleClick={() => declineInvitationButtonClicked(rejectInvitationMutation) }
              fullWidth={!theme.isVersion(2)}
              themed={theme.isVersion(2)}
            >
              {loading ? <ButtonLoader/> : copy.rsvp.declined_button}
            </AcceptButton>
          </div>
        </Modal>
      )}
    </Mutation>
  );
}

EventGuestsDeclineInvitationModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledEventGuestsDeclineInvitationModal = styled(EventGuestsDeclineInvitationModal)`
  text-align: center;
  padding: 40px 30px 30px;

  & .row {
    justify-content: center;
    align-items: center;
  }

  & h3 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 0;
    margin-bottom: 20px;
  }

  .wysiwyg-editor {
    margin-bottom: 14px;

    .trix-button-group--history-tools {
      display: none;
    }
  }
`;

const StyledEventGuestsDeclineInvitationModalWithSettings = withSettings(StyledEventGuestsDeclineInvitationModal);
const EventGuestsDeclineInvitationModalWithSession = withSession(StyledEventGuestsDeclineInvitationModalWithSettings);
export { EventGuestsDeclineInvitationModalWithSession as EventGuestsDeclineInvitationModal };
