import { Enumerated } from './Enumerated';

class RESERVATION_FILTER extends Enumerated {
    static ALL = 'ALL';
    static PENDING = 'PENDING';
    static ATTENDING = 'ATTENDING';
    static CANCELED = 'CANCELED';
    static INVITED = 'INVITED';
    static INVITATION_DECLINED = 'INVITATION_DECLINED';
    static RESERVATION_DECLINED = 'RESERVATION_DECLINED';
    static FIELDS = {
        LABEL: 'LABEL',
        PAST_EVENT_LABEL: 'PAST_EVENT_LABEL'
    };
    static INFO = {
        ALL: {
            LABEL: 'All',
            PAST_EVENT_LABEL: 'All'
        },
        PENDING: {
            LABEL: 'Pending',
            PAST_EVENT_LABEL: 'Left Pending'
        },
        ATTENDING: {
            LABEL: 'Attending',
            PAST_EVENT_LABEL: 'Reservations'
        },
        CANCELED: {
            LABEL: 'Canceled',
            PAST_EVENT_LABEL: 'Canceled'
        },
        INVITED: {
            LABEL: 'Invited',
            PAST_EVENT_LABEL: 'Invited'
        },
        INVITATION_DECLINED: {
            LABEL: 'Invitation Declined',
            PAST_EVENT_LABEL: 'Invitation Declined'
        },
        RESERVATION_DECLINED: {
          LABEL: 'Reservation Declined',
          PAST_EVENT_LABEL: 'Reservation Declined'
        }
    };
}

export { RESERVATION_FILTER };
