import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { EVENT_SPONSORSHIPS_SETTINGS, EVENT_BY_UUID_FOR_SPONSORSHIP_SELECTION } from '../../queries';
import { UPDATE_EVENT_EXPECTED_GUESTS } from '../../mutations';
import { getEventIdFromPath, getNourishmentCutoffDateFromEvent, getNourishmentDeadlineStringFromDate } from '../../libs';
import { withRouter } from 'react-router-dom';
import { EventSponsorshipsGuestsNumberSelector } from './';
import { Loader } from '../components';
import { Redirect } from 'react-router';
import { withSettings } from '../components';
import { imEventHost, stripLeadingSlash } from '../../libs';
import { SessionContext } from '../../Context';

class EventSponsorshipsGuestsNumberSelectorPage extends React.Component {

  state = {
    settingsFetched: false,
    sponsorshipsEnabled: null
  }

  componentDidMount = () => {
    const { history, location } = this.props;
    const eventUuid = getEventIdFromPath(location.pathname);
    const isAfterCreate = location.state && location.state.isAfterCreate;
    const isSoloEvent = location.state && location.state.isSoloEvent;

    if (isSoloEvent) {
      history.push({
        pathname: `/events/${eventUuid}/nourished`,
        state: { isAfterCreate, isSoloEvent }
      });
    } else {
      this.setupFeatures();
    }
  }

  setupFeatures = () => {
    const { settings: { features } } = this.props;
    const sponsorshipsEnabled = (features && (features.sponsorships === true || features.sponsorships === 'true'));

    this.setState({
      settingsFetched: true,
      sponsorshipsEnabled: sponsorshipsEnabled
    })
  }

  render() {
    const { location, settings, history } = this.props;
    const { theme: { site } } = settings;
    const eventId = getEventIdFromPath(location.pathname);
    const isAfterCreate = location.state && location.state.isAfterCreate;
    const isSoloEvent = location.state && location.state.isSoloEvent;
    const { settingsFetched, sponsorshipsEnabled } = this.state;
    const sponsorshipsMaxGuestLimit = typeof settings.env_vars.sponsorships_max_guest_limit !== 'undefined'
      ? settings.env_vars.sponsorships_max_guest_limit
      : 4;
    const minGuestNumberRequiredLimit = parseInt(sponsorshipsMaxGuestLimit, 10);
    const homepageUrl = `/${stripLeadingSlash(site.homepage)}`;

    if (settingsFetched && !sponsorshipsEnabled) {
      if (isAfterCreate) {
        const shouldShowResourcesPage = settings && settings.features && settings.features.event_creation_questions;
        const pathName = shouldShowResourcesPage ? `/events/${eventId}/resources` : `/events/${eventId}`;

        history.push({
          pathname: pathName,
          state: { isAfterCreate: true, isSoloEvent }
        });
      } else {
        return <Redirect to={homepageUrl}/>;
      }
    }

    return (
      <SessionContext.Consumer>
        {({ session }) => {

          if (!session || !session.viewer) {
            return <Redirect to={homepageUrl}/>;
          }

          return (
            <Query query={EVENT_SPONSORSHIPS_SETTINGS}>
              {(sponsorshipsSettingsResponse) => (
                <Query query={EVENT_BY_UUID_FOR_SPONSORSHIP_SELECTION} variables={{ uuid: eventId }}>
                  {(eventResponse) => (
                    <Mutation mutation={UPDATE_EVENT_EXPECTED_GUESTS}>
                      {(updateEventMutation) => {
                        if (sponsorshipsSettingsResponse.loading || eventResponse.loading) {
                          return <Loader />;
                        } else {
                          if (!eventResponse || !eventResponse.data || !eventResponse.data.eventByUuid || !eventResponse.data.eventByUuid.eventType) {
                            return <Redirect to={homepageUrl}/>;
                          }

                          if (!sponsorshipsSettingsResponse || !sponsorshipsSettingsResponse.data || !sponsorshipsSettingsResponse.data.eventTypeSponsorshipSettings) {
                            return <Redirect to={homepageUrl}/>;
                          }

                          const event = eventResponse.data.eventByUuid;

                          if (!event.nourishable || !imEventHost(event, session.viewer)) {
                            return <Redirect to='/manage/hosted'/>;
                          }

                          const eventType = event.eventType;
                          const sponsorshipsSettings = sponsorshipsSettingsResponse.data.eventTypeSponsorshipSettings.find(
                            sponsorshipSettings => (sponsorshipSettings.eventType === eventType)
                          );

                          if (!sponsorshipsSettings || !sponsorshipsSettings.pricePerHead || !sponsorshipsSettings.priceMax) {
                            return <Redirect to={homepageUrl}/>;
                          }

                          const pricePerPerson = sponsorshipsSettings.pricePerHead / 100;
                          const totalSponsorshipsLimit = sponsorshipsSettings.priceMax / 100;

                          const nourishmentDeadlineDate = getNourishmentCutoffDateFromEvent(event, event.area);
                          const dateLimitToSelectSponsorships = getNourishmentDeadlineStringFromDate(nourishmentDeadlineDate);

                          return (
                            <EventSponsorshipsGuestsNumberSelector
                              event={event}
                              eventId={event.id}
                              eventNourishableDeadlineDate={dateLimitToSelectSponsorships}
                              eventTitle={event.title}
                              minGuestsNumberToQualifyForSponsorships={minGuestNumberRequiredLimit}
                              numberOfGuestsMax={event.numberOfGuestsMax}
                              expectedGuests={event.expectedGuests}
                              perPersonPrice={pricePerPerson}
                              totalSponsorshipsLimit={totalSponsorshipsLimit}
                              dateLimitToSelectSponsorships={dateLimitToSelectSponsorships}
                              updateEventMutation={updateEventMutation}
                              isAfterCreate={isAfterCreate}
                              isSoloEvent={isSoloEvent}
                            />
                          );
                        }
                      }}
                    </Mutation>
                  )}
                </Query>
              )}
            </Query>
          );
        }}
    </SessionContext.Consumer>
    );
  }
}

const EventSponsorshipsGuestsNumberSelectorPageRouter = withRouter(EventSponsorshipsGuestsNumberSelectorPage);
const EventSponsorshipsGuestsNumberSelectorPageRouterWithSettings = withSettings(EventSponsorshipsGuestsNumberSelectorPageRouter);

export { EventSponsorshipsGuestsNumberSelectorPageRouterWithSettings as EventSponsorshipsGuestsNumberSelectorPage };
