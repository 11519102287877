import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import moment from 'moment-timezone';

import {
  Card,
  EventCreationCoverSelector,
  Input,
  DatePicker,
  TimePicker,
  ButtonToggle,
  UserEmailSelector,
  InfoTooltip,
  DollarInput,
  Checkbox,
  Textarea,
  LogoUploader,
  SelectBox,
  withSettings,
  withSession
} from '../../components';

import {
  stringInsert,
  invalidateText,
  invalidateNumber,
  invalidateDateTime,
  invalidateDateTime30Hours,
  shuffleArray,
  sort,
  isSite,
  appendTimezoneOffset
} from '../../../libs';

import { EVENT_TYPE } from '../../../enums';
import { EVENT_COVERS } from '../../../queries';
import { breakpoints } from '../../../styles';
import { RECURRANCE } from '../../../constants/recurrances';

class EventCreationFormHeader extends React.Component {
  setRsvpDeadlineMax = (eventData, field) => {
    if (field !== 'time') return eventData.date;

    const eventDate = eventData.date
      ? moment(eventData.date).format('yy-mm-dd')
      : null;
    const deadlineDate = eventData.rsvpDeadlineDate
      ? moment(eventData.rsvpDeadlineDate).format('yy-mm-dd')
      : null;

    if (!eventDate || !deadlineDate || eventDate !== deadlineDate) return null;

    return eventData.endTime;
  };

  getCohosts = (cohosts, viewer) => {
    return Array.isArray(cohosts)
      ? cohosts.filter((host) => host !== viewer.user.email)
      : [];
  };

  getFeeValues = (eventData) => {
    return [
      '5%',
      eventData.price ? eventData.price : 10,
      eventData.price ? (eventData.price * 0.95).toFixed(2) : (9.5).toFixed(2),
    ];
  };

  // getMaxSeatsLimit = () => {
  //   const { eventData, session: { areas, viewer }, settings } = this.props;
  //   const { event: { virtual } } = settings;
  //   const eventType = eventData.eventType;
  //   const viewerArea = areas.find(area => viewer.profile && viewer.profile.area ? area.code === viewer.profile.area.code : false);
  //   const eventArea = eventData.area ? eventData.area : viewerArea;
  //
  //   if (!eventType || eventData.virtual) {
  //     return undefined;
  //   }
  //
  //   // const maxGuestCap = parseInt(virtual[`${eventType.toLowerCase()}_non_virtual_max_seats_cap`], 10);
  //   const maxGuestCap = eventArea && eventArea.socialDistancePolicy
  //     ? eventArea.socialDistancePolicy[
  //       `${eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : eventType.toLowerCase()}NonVirtualMaxSeatsCap`
  //     ] : null;
  //
  //   const globalSeatCap = virtual[
  //     `${eventType === 'MANUAL_APPROVAL' ? 'manual_approval' : eventType.toLowerCase()}_non_virtual_max_seats_cap`
  //   ];
  //
  //   if (maxGuestCap === null) return parseInt(globalSeatCap) || undefined;
  //
  //   return maxGuestCap;
  // };

  getVirtualEventLabelTooltip = () => {
    const {
      eventData,
      settings: { copy },
    } = this.props;
    const eventType = eventData.eventType
      ? eventData.eventType.toLowerCase()
      : false;
    return eventType && copy.event[`virtual_label_tooltip_${eventType}`];
  };

  invalidateMaxSeatLimit = (seats) => {
    const { maxSeatLimit } = this.props;
    if (!maxSeatLimit) return false;
    return seats > maxSeatLimit;
  };

  handleVirtualToggle = (value) => {
    const { onInputChange, eventData } = this.props;

    const props = { virtual: value };

    if (props.virtual === true) {
      if (!eventData.tags.includes('virtual'))
        props['tags'] = eventData.tags.concat(['virtual']);
      props['tags'] = eventData.tags.filter((tag) => tag !== 'outdoors');
      props['outdoors'] = '';
    } else {
      props['tags'] = eventData.tags.filter((tag) => tag !== 'virtual');
    }

    onInputChange(props);
  };

  handleCohostsUpdate = (value, viewer) => {
    const { onInputChange, eventData } = this.props;
    // remove viewer from cohost list //
    const filteredValue = Array.isArray(value)
      ? value.filter((v) => v !== viewer.user.email)
      : [];
    const isCreator =
      eventData.creator && viewer.user.profile
        ? eventData.creator.id === viewer.user.profile.id
        : true;

    // add viewer email back if edited by cohost, NOT THE CREATOR //
    if (!isCreator /*&& value.includes(viewer.user.email)*/)
      filteredValue.push(viewer.user.email);
    onInputChange('cohosts', filteredValue);
  };

  invalidateVirtualConfirmation = () => {
    const {
      eventData,
      settings: {
        event: { virtual },
      },
    } = this.props;
    const { virtualConfirmation, virtual: isVirtual } = eventData;

    return !!(
      virtual.display_non_virtual_confirmation_checkbox &&
      !isVirtual &&
      !virtualConfirmation
    );
  };

  getNonVirtualDisclaimerText = () => {
    const {
      settings: { copy },
      eventData: { area, eventType },
    } = this.props;
    const socialDistancePolicy = area && area.socialDistancePolicy;

    return socialDistancePolicy && socialDistancePolicy.nonVirtualDisclaimer
      ? socialDistancePolicy.nonVirtualDisclaimer
      : copy.event[`non_virtual_${eventType.toLowerCase()}_confirmation`] ||
          copy.event.non_virtual_confirmation;
  };

  getTimezones = (timezones) => {
    const { settings: { global: { display_timezone_offsets } } } = this.props;

    return timezones.map((zone) => ({
      id: zone.name,
      label: appendTimezoneOffset(zone, display_timezone_offsets),
      value: zone.name,
    }));
  };

  getGuestListTooltip = () => {
    const { settings } = this.props;
    let eventLabel = 'dinner';

    if (isSite(settings, ['signaturefd', 'homecoming'])) {
      eventLabel = 'gathering';
    } else if (isSite(settings, ['hillel', 'dinner-party'])) {
      eventLabel = 'experience';
    }

    return `Hide the full guest list from users viewing your ${eventLabel}.`;
  };

  getRecurranceOptions = () => {
    const options = [
      {
        id: 'none',
        label: RECURRANCE.NONE,
        value: RECURRANCE.NONE,
      },
      {
        id: 'weekly',
        label: RECURRANCE.WEEKLY,
        value: RECURRANCE.WEEKLY,
      },
      {
        id: 'monthly',
        label: RECURRANCE.MONTHLY,
        value: RECURRANCE.MONTHLY,
      },
      {
        id: 'annually',
        label: RECURRANCE.ANNUALLY,
        value: RECURRANCE.ANNUALLY,
      },
    ];

    return options;
  };

  render() {
    const {
      className,
      settings,
      eventData,
      onInputChange,
      onValidate,
      invalidate,
      isCommunity,
      isUpdate,
      paidEventsEnabled,
      stockCoversShufflerNumber,
      wasPaidEventBeforeEditing,
      maxSeatLimit,
      session
    } = this.props;

    const {
      copy,
      types,
      theme,
      event: { virtual, pwyw_minimum, enable_hide_guest_list = true },
      features: { pwyw: enablePwyw },
    } = settings;
    const pwywMinimum = pwyw_minimum ? pwyw_minimum / 100 : 0;
    const isNotSolo = eventData.eventType !== EVENT_TYPE.SOLO;
    const isVaccinated = eventData.privacySubtype && eventData.privacySubtype.vaccinated;
    const { timezones, viewer } = session;

    const PriceField = () => (
      <>
        <label>{copy.create.price}</label>
        <DollarInput
          type="number"
          name="price"
          value={eventData.price}
          min={0}
          onInputChange={onInputChange}
          onValidate={onValidate}
          invalidate={() =>
            invalidate && eventData.paid && invalidateNumber(eventData.price)
          }
          errorMessage="Enter a price."
          required={eventData.paid && !eventData.pwyw}
        />
      </>
    );

    const TransactionFeeField = () => (
      <div className="host_absorbs">
        <label>
          {copy.create.transaction_fee}
          <InfoTooltip
            className="transaction_info"
            content={copy.create.transaction_fee_tooltip}
            position="right"
          />
        </label>
        <Checkbox
          name="hostAbsorbsTransactionFee"
          options={[
            {
              label: copy.create.transaction_fee_text,
              checked: eventData.hostAbsorbsTransactionFee,
            },
          ]}
          onInputChange={onInputChange}
        />
      </div>
    );

    const EventTimeFields = () => (
      <>
        <div className={isSite(settings, 'hineni') ? 'one_half' : 'one_third'}>
          {/* Begin Time */}
          <label>{copy.create.begin}</label>
          <TimePicker
            name="startTime"
            value={eventData.startTime}
            max={new Date(eventData.endTime.getTime() - 1800000)}
            onTimeChange={(value) => onInputChange('startTime', value)}
            onValidate={onValidate}
            invalidate={() =>
              invalidate && (
                invalidateDateTime(eventData.startTime) ||
                  eventData.startTime > eventData.endTime
              )
            }
            errorMessage="Enter a valid start time."
            required
          />
        </div>
        <div className={isSite(settings, 'hineni') ? 'one_half' : 'one_third'}>
          {/* End Time */}
          <label>{copy.create.end}</label>
          <TimePicker
            name="endTime"
            value={eventData.endTime}
            min={new Date(eventData.startTime.getTime() + 1800000)}
            onTimeChange={(value) => onInputChange('endTime', value)}
            onValidate={onValidate}
            invalidate={() =>
              invalidate && (
                invalidateDateTime(eventData.endTime) || 
                  eventData.endTime < eventData.startTime
              )
            }
            errorMessage="Enter a valid end time."
            required
          />
        </div>
      </>
    );

    const EventRecurranceField = () => (
      <>
        <div
          className={
            eventData.recurrance && eventData.recurrance !== RECURRANCE.NONE
              ? 'one_half'
              : ''
          }
        >
          <label>{copy.create.recurrence || 'Dinner Recurrence'}</label>
          <SelectBox
            name="recurrance"
            options={this.getRecurranceOptions()}
            onOptionSelect={(value) => onInputChange('recurrance', value)}
            placeholder="---"
            defaultValue={eventData.recurrance}
            onValidate={onValidate}
            invalidate={() =>
              invalidate && invalidateText(eventData.recurrance)
            }
            required={!eventData.potluck && !isCommunity}
          />
        </div>
        {eventData.recurrance && eventData.recurrance !== RECURRANCE.NONE && (
          <div className="one_half">
            <label>
              {copy.create.recurrance_number || 'Number Of Recurrences'}
            </label>
            <Input
              type="number"
              content="integer"
              name="recurranceNumber"
              min={2}
              value={eventData.recurranceNumber}
              onInputChange={onInputChange}
              onValidate={onValidate}
              invalidate={() => invalidate && !eventData.recurranceNumber}
              errorMessage="Enter a valid number of recurrences."
              required={eventData.recurrance !== RECURRANCE.NONE}
            />
          </div>
        )}
      </>
    );

    const canCopyEvents = () =>
      viewer.user &&
      viewer.user.hostApprovalAt !== null &&
      viewer.user.canCopyEvents;

    return (
      <Card className={className} noPadding>
        <div className="cover_photo">
          <div className="mobile_spacer" />
          <Query query={EVENT_COVERS}>
            {({ data, loading }) => {
              // if (error) console.error('eventCovers: ', error);

              const stockCovers =
                (data && data.eventCovers && data.eventCovers.nodes) ||
                [];
              let sortedStockCovers = [];

              if (theme.randomize_cover_images) {
                sortedStockCovers = shuffleArray(
                  stockCovers,
                  stockCoversShufflerNumber
                );
              } else {
                sortedStockCovers = sort.byObjKey(
                  stockCovers,
                  'position'
                );
              }

              return (
                <EventCreationCoverSelector
                  stockCovers={sortedStockCovers}
                  loading={loading}
                  eventCover={eventData.cover}
                  onInputChange={onInputChange}
                  isUpdate={isUpdate}
                  journeyCoverImage={eventData.journeyCoverImage}
                />
              );
            }}
          </Query>
        </div>
        <div className="header_details">
          <div className="form_group break_on_mobile">
            <div
              className={
                (isCommunity && !isSite(settings, 'jewishfoodfest')) ||
                eventData.hideSeats
                  ? 'full'
                  : 'two_thirds'
              }
            >
              {/* Title */}
              <label>{copy.create.name}</label>
              <Input
                type="text"
                name="title"
                value={eventData.title}
                onInputChange={onInputChange}
                onValidate={onValidate}
                invalidate={() =>
                  invalidate && invalidateText(eventData.title)
                }
                errorMessage="Enter a title."
                maxLength={settings.event.title_max_length}
                minLength={settings.event.title_min_length}
                required
              />
            </div>
            {!isCommunity &&
              !isSite(settings, 'jewishfoodfest') &&
              !eventData.hideSeats && (
                <div className="one_third">
                  {/* Max Seats */}
                  <label>{copy.create.max_seats}</label>
                  <Input
                    type="number"
                    content="integer"
                    name="numberOfGuestsMax"
                    max={maxSeatLimit}
                    min={1}
                    value={eventData.numberOfGuestsMax}
                    onInputChange={onInputChange}
                    onValidate={onValidate}
                    invalidate={() =>
                      invalidate &&
                      this.invalidateMaxSeatLimit(
                        eventData.numberOfGuestsMax
                      )
                    }
                    errorMessage="Enter a valid number of guests."
                    required={!isCommunity}
                  />
                </div>
              )}
            {isSite(settings, 'jewishfoodfest') && (
              <div className="one_third">
                {/* Event Date */}
                <label>{copy.create.date}</label>
                <DatePicker
                  name="date"
                  value={eventData.date}
                  min={new Date()}
                  onDateChange={(value) => onInputChange('date', value)}
                  onValidate={onValidate}
                  dayRestrictions={settings.event.date_restrictions}
                  dayExceptions={settings.event.date_exceptions}
                  invalidate={() =>
                    invalidate &&
                    invalidateDateTime30Hours(eventData.date)
                  }
                  errorMessage="Enter a valid date (30+ hours in advance)."
                  required
                />
              </div>
            )}
          </div>

          {isCommunity && (
            <div className="form_group">
              <div className="two_thirds">
                {/* Organization Name */}
                <label>{copy.create.organization_name}</label>
                <Input
                  type="text"
                  name="partnerOrganizationName"
                  value={eventData.partnerOrganizationName}
                  onInputChange={onInputChange}
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate &&
                    invalidateText(eventData.partnerOrganizationName)
                  }
                  errorMessage="Enter an organization name."
                  required={isCommunity}
                />
              </div>
              <div className="one_third">
                {/* Organization Logo */}
                <LogoUploader
                  label={copy.create.upload_logo}
                  onInputChange={onInputChange}
                  logo={eventData.partnerOrganizationLogo}
                  isUpdate={isUpdate}
                />
              </div>
            </div>
          )}

          <div className="form_group break_on_mobile">
            {!isSite(settings, 'jewishfoodfest') && (
              <div
                className={
                  isSite(settings, 'hineni') ? 'one_half' : 'one_third'
                }
              >
                {/* Event Date */}
                <label>{copy.create.date}</label>
                <DatePicker
                  name="date"
                  value={eventData.date}
                  min={new Date()}
                  onDateChange={(value) => onInputChange('date', value)}
                  onValidate={onValidate}
                  dayRestrictions={settings.event.date_restrictions}
                  dayExceptions={settings.event.date_exceptions}
                  invalidate={() =>
                    invalidate &&
                    invalidateDateTime30Hours(eventData.date)
                  }
                  errorMessage="Enter a valid date (30+ hours in advance)."
                  required
                />
              </div>
            )}
            {isSite(settings, 'hineni') ? (
              <div className="one_half">
                {/* Timezone */}
                <label>{copy.create.timezone}</label>
                <SelectBox
                  name="timezone"
                  options={
                    !timezones
                      ? null
                      : this.getTimezones(timezones)
                  }
                  onOptionSelect={(value) =>
                    onInputChange('timezone', value)
                  }
                  placeholder="---"
                  defaultValue={eventData.timezone || ''}
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate && invalidateText(eventData.timezone)
                  }
                  disabled={!timezones}
                  required
                />
              </div>
            ) : (
              EventTimeFields()
            )}
            {isSite(settings, 'jewishfoodfest') && (
              <div className="one_third">
                {/* Timezone */}
                <label>{copy.create.timezone}</label>
                <Input
                  placeholder="Eastern Time (US & Canada)"
                  value="Eastern Time (US & Canada)"
                  disabled
                />
              </div>
            )}
          </div>

          {isSite(settings, 'hineni') && (
            <div className="form_group break_on_mobile">
              {EventTimeFields()}
            </div>
          )}

          {canCopyEvents() && !isUpdate && (
            <div className="form_group break_on_mobile">
              {EventRecurranceField()}
            </div>
          )}

          {!eventData.hideRSVP && (
            <div className="form_group break_on_mobile table">
              <div className="one_third toggle_buttons">
                {/* RSVP Deadline */}
                <label>{copy.create.rsvp_deadline_switcher}</label>
                <ButtonToggle
                  name="rsvpDeadline"
                  activeButton={eventData.rsvpDeadline}
                  onToggleButton={(value) =>
                    onInputChange('rsvpDeadline', value)
                  }
                  halfWidth
                />
              </div>
              <div className="one_third">
                {/* RSVP Deadline Date */}
                <label>{copy.create.rsvp_deadline}</label>
                <DatePicker
                  name="rsvpDeadlineDate"
                  value={eventData.rsvpDeadlineDate}
                  min={new Date()}
                  max={this.setRsvpDeadlineMax(eventData, 'date')}
                  onDateChange={(value) =>
                    onInputChange('rsvpDeadlineDate', value)
                  }
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate &&
                    eventData.rsvpDeadline &&
                    invalidateDateTime(eventData.rsvpDeadlineDate)
                  }
                  disabled={!eventData.rsvpDeadline}
                  errorMessage="Enter a valid deadline date."
                  required={eventData.rsvpDeadline}
                />
              </div>
              <div className="one_third">
                {/* RSVP Deadline Time */}
                <label>{copy.create.rsvp_deadline1}</label>
                <TimePicker
                  name="rsvpDeadlineTime"
                  value={eventData.rsvpDeadlineTime}
                  max={this.setRsvpDeadlineMax(eventData, 'time')}
                  onTimeChange={(value) =>
                    onInputChange('rsvpDeadlineTime', value)
                  }
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate &&
                    eventData.rsvpDeadline &&
                    invalidateDateTime(eventData.rsvpDeadlineTime)
                  }
                  disabled={!eventData.rsvpDeadline}
                  errorMessage="Enter a valid deadline time."
                  required={eventData.rsvpDeadline}
                />
              </div>
            </div>
          )}

          <div className="form_group break_on_mobile">
            {theme.display_create_cohosts && !eventData.hideCohost && (!isVaccinated || isSite(settings, 'hillel.michigan', true)) && (
              <div className={`${enable_hide_guest_list || isNotSolo ? 'two_thirds' : ''}`}>
                {/* Cohosts */}
                <label>{copy.create.add_cohost}</label>
                <UserEmailSelector
                  name="cohosts"
                  allowMultipleEmails
                  emails={this.getCohosts(eventData.cohosts, viewer)}
                  onAddEmail={(value) =>
                    this.handleCohostsUpdate(value, viewer)
                  }
                  onValidate={onValidate}
                  invalidate={() =>
                    false &&
                    invalidate &&
                    invalidateText(eventData.add_cohost)
                  }
                  errorMessage="Enter a valid email."
                />
              </div>
            )}
            {false && !eventData.virtualHide && (
              <div className="one_third toggle_buttons">
                {/* Virtual Event */}
                <label className="with_tooltip">
                  {copy.create.virtual_label}
                  <InfoTooltip
                    content={this.getVirtualEventLabelTooltip()}
                  />
                </label>
                <ButtonToggle
                  name="rsvpDeadline"
                  activeButton={eventData.virtual}
                  onToggleButton={(value) =>
                    this.handleVirtualToggle(value)
                  }
                  disabled={eventData.virtualDisabled}
                  halfWidth
                />
              </div>
            )}
            {enable_hide_guest_list && isNotSolo && (
              <div className="one_third toggle_buttons">
                {/* Hide Guest List */}
                <label className="with_tooltip">
                  {copy.create.hide_guest_list_label || 'Hide Guest List'}
                  {/* some whitelabel sites are still using getGuestListTooltip()  */}
                  <InfoTooltip
                    content={copy.create.hide_guest_list_tooltip || this.getGuestListTooltip()}
                  />
                </label>
                <ButtonToggle
                  name="hideGuestList"
                  activeButton={eventData.hideGuestList}
                  onToggleButton={(value) =>
                    onInputChange('hideGuestList', value)
                  }
                  halfWidth
                />
              </div>
            )}
          </div>

          {isSite(settings, 'jewishfoodfest') && !eventData.hideSeats && (
            <div className="form_group break_on_mobile">
              <div className="one_third">
                {/* Max Seats */}
                <label>{copy.create.max_seats}</label>
                <Input
                  type="number"
                  content="integer"
                  name="numberOfGuestsMax"
                  max={maxSeatLimit}
                  min={1}
                  value={eventData.numberOfGuestsMax}
                  onInputChange={onInputChange}
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate &&
                    this.invalidateMaxSeatLimit(
                      eventData.numberOfGuestsMax
                    )
                  }
                  errorMessage="Enter a valid number of guests."
                  required={!isCommunity}
                />
              </div>
              {/* hiding so we can enable donations */}
              {false && paidEventsEnabled && (
                <Fragment>
                  <div
                    className={`one_third ${
                      eventData.paid ? 'toggle_buttons' : ''
                    }`}
                  >
                    {/* Cost */}
                    <label>{copy.create.cost}</label>
                    <ButtonToggle
                      name="paid"
                      activeButton={eventData.paid}
                      labels={[
                        { label: types.cost.FREE, value: false },
                        { label: types.cost.PAID, value: true },
                      ]}
                      onToggleButton={(value) =>
                        onInputChange('paid', value)
                      }
                      halfWidth
                    />
                  </div>
                  <div className="one_third">
                    {/* Event Price */}
                    {eventData.paid && (
                      <Fragment>
                        <label>{copy.create.price}</label>
                        <DollarInput
                          type="text"
                          name="price"
                          value={eventData.price}
                          min={0}
                          onInputChange={onInputChange}
                          onValidate={onValidate}
                          invalidate={() =>
                            invalidate &&
                            eventData.paid &&
                            invalidateNumber(eventData.price)
                          }
                          errorMessage="Enter a price."
                          required={eventData.paid}
                        />
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}

          {paidEventsEnabled && !eventData.hideCost && !isSite(settings, ['climatefest', 'dinner-party']) && (
            <>
              <div className="form_group break_on_mobile table">
                {!isSite(settings, 'jewishfoodfest') && (
                  <Fragment>
                    <div className={`one_third ${eventData.paid ? 'toggle_buttons' : ''}`}>
                      {/* Cost */}
                      <label>{copy.create.cost}</label>
                      <ButtonToggle
                        name="paid"
                        activeButton={eventData.paid}
                        labels={[
                          { label: types.cost.FREE, value: false },
                          { label: types.cost.PAID, value: true }
                        ]}
                        onToggleButton={value => onInputChange('paid', value)}
                        halfWidth
                      />
                    </div>
                    {enablePwyw ? (
                      <>
                        <div className={`one_third ${eventData.paid ? 'toggle_buttons' : ''}`}>
                          {/* Pay What You Want */}
                          {eventData.paid && (
                            <Fragment>
                              <label>
                                {copy.create.pay_what_you_want || 'Pay What You Want'}
                                <InfoTooltip
                                  className="transaction_info"
                                  content={copy.create.pay_what_you_want_tooltip || 'Allow guests to choose the amount they pay.'}
                                  position="right"
                                />
                              </label>
                              <ButtonToggle
                                name="pwyw"
                                activeButton={eventData.pwyw}
                                labels={[
                                  { label: 'No', value: false },
                                  { label: 'Yes', value: true }
                                ]}
                                onToggleButton={value => onInputChange('pwyw', value)}
                                halfWidth
                              />
                            </Fragment>
                          )}
                        </div>
                        <div className="one_third price">
                          {/* Event Price */}
                          {eventData.paid && !eventData.pwyw && PriceField()}
                          {/* Pay What You Want Minimum */}
                          {eventData.paid && eventData.pwyw && (
                            <Fragment>
                              <label className="highlight">{copy.create.pay_what_you_want_minimum || 'Minimum Cost'}</label>
                              <DollarInput
                                type="number"
                                name="pwywMinimum"
                                value={eventData.pwywMinimum}
                                min={pwywMinimum}
                                onInputChange={onInputChange}
                                onValidate={onValidate}
                                invalidate={() => invalidate && eventData.paid && invalidateNumber(eventData.pwywMinimum)}
                                errorMessage="Enter a price."
                                required={eventData.paid && eventData.pwyw}
                              />
                            </Fragment>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="two_thirds">
                          {eventData.paid && PriceField()}
                        </div>
                      </>
                    )}
                  </Fragment>
                )}
              </div>
              {eventData.paid && (
                <div className="form_group">
                  {/* Host Absorbs Transaction Fee */}
                  {TransactionFeeField()}
                </div>
              )}
            </>
          )}

          {paidEventsEnabled &&
          eventData.paid &&
          eventData.explainPayment !== false &&
          (!wasPaidEventBeforeEditing || !isUpdate) ? (
            <div className="form_group">
              <div className="payment_explanation">
                {/* Paid Explanation */}
                <Textarea
                  name="explainPayment"
                  placeholder={copy.create.explain_payment_placeholder}
                  value={eventData.explainPayment}
                  onInputChange={onInputChange}
                  onValidate={onValidate}
                  invalidate={() =>
                    invalidate &&
                    eventData.paid &&
                    invalidateText(eventData.explainPayment)
                  }
                  errorMessage="Enter a payment explanation."
                  required={eventData.paid}
                  rows={2}
                />
              </div>
            </div>
          ) : eventData.eventType === EVENT_TYPE.SOLO ? null : (
            <div className="explain_payment_spacer" />
          )}

          {paidEventsEnabled && eventData.paid && (
            <div className="form_group">
              <div>
                <p className="withheld_fees">
                  {stringInsert(
                    copy.create.withheld_fees,
                    this.getFeeValues(eventData)
                  )}
                </p>
              </div>
            </div>
          )}

          {!eventData.virtual &&
            virtual.display_non_virtual_confirmation_checkbox &&
            !eventData.hideNonVirtualConfirmationCheckbox && (
              <div className="form_group">
                <div className="virtual_confirmation">
                  {/* Virtual Confirmation */}
                  <Checkbox
                    name="virtualConfirmation"
                    options={[
                      {
                        label: this.getNonVirtualDisclaimerText(),
                        checked: eventData.virtualConfirmation,
                      }
                    ]}
                    onInputChange={onInputChange}
                    onValidate={onValidate}
                    invalidate={() =>
                      invalidate && this.invalidateVirtualConfirmation()
                    }
                    errorMessage=""
                    required
                  />
                </div>
              </div>
            )}
        </div>
      </Card>
    );
  }
}

const StyledEventCreationFormHeader = styled(EventCreationFormHeader)`
  min-height: 480px;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .cover_photo {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;

    .mobile_spacer {
      display: none;

      @media (${breakpoints.tablet}) {
        display: block;
        padding-top: 70%;
      }

      @media (${breakpoints.mobile}) {
        display: block;
        padding-top: 85%;
      }
    }

    @media (${breakpoints.tablet}) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  .header_details {
    float: right;
    width: 50%;
    padding: 30px 25px;

    .host_absorbs {
      margin-bottom: 15px;

      & > label {
        padding-botom: 0;
      }

      .checkbox__label {
        padding-top: 0;

        input[type="checkbox"] {
          top: 6px;
        }
      }
    }

    @media (${breakpoints.tablet}) {
      float: none;
      width: 100%;
    }

    @media (${breakpoints.mobile}) {
      .toggle_buttons {
        margin-bottom: 14px;
      }
    }
  }

  .transaction_info {
    font-weight: normal;
    text-align: center;
    margin-left: 5px;
  }

  .checkbox {
    .checkbox__label {
      font-size: 12px;
    }
  }

  input[type="checkbox"] {
    top: 12px;
  }

  .payment_explanation {
    margin-top: 10px;
  }

  .withheld_fees {
    ${(props) =>
      props.settings.event.virtual.display_non_virtual_confirmation_checkbox
        ? 'margin: 0 0 15px'
        : 'margin: 0'};
    font-size: 12px;
  }

  .payment_explanation {
    textarea {
      margin-bottom: 6px;
    }
  }

  .explain_payment_spacer {
    height: 15px;
  }

  // .price_container {
  //   width: 20% !important;
  // }
  //
  // .transaction_fee_container {
  //   width: 46.66666% !important;
  // }

  label.highlight {
    color: ${(props) => props.settings.theme.css.global.colors.primary};
  }

  div.price input {
    margin-bottom: 0 !important;
  }
`;

const StyledEventCreationFormHeaderWithSettings = withSettings(
  StyledEventCreationFormHeader
);
const EventCreationFormHeaderWithSession = withSession(StyledEventCreationFormHeaderWithSettings);
export { EventCreationFormHeaderWithSession as EventCreationFormHeader };
