import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

import {
  SelectBox,
  Input
} from '../../components';

import { breakpoints } from '../../../styles';


class EventCreationRsvpQuestion extends React.Component {
  state = { customValue: '' };

  customQuestionDefault = {
    id: null,
    label: this.props.settings.copy.create.custom_question_label,
    value: null
  };

  componentDidMount() {
    const { isUpdate, question } = this.props;

    if (isUpdate && question.baseQuestionId === null) {
      this.setState({ customValue: question.text });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { question } = this.props;

    if (question.text !== nextProps.question.text) {
      this.setState({ customValue: nextProps.question.text });
    }
  }

  getQuestionOptions = suggestions => {
    const formattedQuestions = suggestions
      .filter(s => s.presenceType !== 'ALWAYS' && !s.disabled)
      .map((s) => ({
        id: s.id,
        label: s.text,
        value: s.id
      }));

    formattedQuestions.push(Object.assign({}, this.customQuestionDefault));

    return formattedQuestions;
  };

  getAnswerOptions = () => {
    const { settings: { copy } } = this.props;

    return [
      { id: 0, label: copy.create.single_line_answer, value: 'STRING' },
      { id: 1, label: copy.create.text_area_answer, value: 'TEXT' }
    ];
  };

  getDefaultQuestionValue = () => {
    const { question, settings: { copy } } = this.props;

    if (question.customQuestion) {
      return Object.assign({}, this.customQuestionDefault);
    } else if (question.text === '') {
      return { label: copy.create.select_a_question };
    } else {
      return { id: question.id, label: question.text, value: question.baseQuestionId };
    }
  };

  getDefaultAnswerValue = () => {
    const { question, settings: { copy } } = this.props;
    const answerOptions = this.getAnswerOptions();
    const answer = answerOptions.find(option => option.value === question.answerType);

    if (answer) {
      return { id: answer.id, label: answer.label, value: answer.value };
    } else {
      return { label: copy.create.select_answer_type };
    }
  };

  handleSelectQuestion = (value, suggestions) => {
    const { onSelectQuestion, index } = this.props;
    const selectedSuggestion = suggestions.find(s => s.id === value);

    if (selectedSuggestion) onSelectQuestion(index, selectedSuggestion.text, selectedSuggestion.id);
    else onSelectQuestion(index, '', null);
  };

  handleSelectAnswer = value => {
    const { onSelectAnswer, index } = this.props;
    onSelectAnswer(value, index);
  };

  handleCustomQuestionChange = (name, value) => {
    const { onCreateCustomQuestion, index } = this.props;
    this.setState({ customValue: value }, () => {
      onCreateCustomQuestion(this.state.customValue, index);
    });
  }

  handleCustomQuestionValidate = value => {
    console.log('handleCustomQuestionValidate', value);
  }

  handleInvalidate = field => {
    const { question, index, onRemoveQuestion } = this.props;

    if (question.id === null && question.text === '' && question.answerType === '') {
      // remove empty questions when invalidated //
      onRemoveQuestion(index);
      return false;
    }

    if (field === 'question' && question.customQuestion) return false;
    else if (field === 'question' || field === 'custom') return question.text === '';
    else return question.answerType === '';
  }

  render() {
    const { customValue } = this.state;
    const { className, question, index, onRemoveQuestion, invalidate, suggestions } = this.props;

    return (
      <div className={className}>
        <div className="title_container">
          <h5>Question {index + 1}</h5>
          <span
            className="remove_question"
            onClick={() => onRemoveQuestion(index)}
          >Remove</span>
        </div>
        <div className="form_group q_and_a">
          <div className="two_thirds">
            <SelectBox
              defaultValue={this.getDefaultQuestionValue()}
              options={this.getQuestionOptions(suggestions)}
              onOptionSelect={value => this.handleSelectQuestion(value, suggestions)}
              invalidate={() => invalidate && this.handleInvalidate('question')}
              errorMessage="Select a question."
            />
          </div>
          <div className="one_third">
            <SelectBox
              defaultValue={this.getDefaultAnswerValue()}
              options={this.getAnswerOptions()}
              onOptionSelect={this.handleSelectAnswer}
              invalidate={() => invalidate && this.handleInvalidate('answer')}
              errorMessage="Enter an answer type."
            />
          </div>
        </div>
        {question.customQuestion && (
          <div className="form_group">
            <div className="full">
              <Input
                name={question.id}
                value={customValue}
                onInputChange={this.handleCustomQuestionChange}
                onValidate={value => this.handleCustomQuestionValidate(value)}
                invalidate={() => invalidate && this.handleInvalidate('custom')}
                errorMessage="Enter a question."
                placeholder="Write question..."
                required={question.customQuestion}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const StyledEventCreationRsvpQuestion = styled(EventCreationRsvpQuestion)`
  margin-bottom: 5px;

  @media (${breakpoints.tabletSmall}) {
    margin-bottom: 10px;
  }

  .title_container {
    padding: 0 0 10px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    h5 {
      float: left;
      font-size: 14px;
      margin: 0;
      padding-top: 3px;
    }

    .remove_question {
      float: right;
      display: block;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .q_and_a {
    @media (${breakpoints.tabletSmall}) {
      .two_thirds,
      .one_third {
        width: 100%;
        padding: 0;
      }
    }
  }
`;

const StyledEventCreationRsvpQuestionWithSettings = withSettings(StyledEventCreationRsvpQuestion);

export { StyledEventCreationRsvpQuestionWithSettings as EventCreationRsvpQuestion };
