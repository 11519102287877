import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../';
import styled from 'styled-components';
import { withSettings } from '../components';
import { breakpoints } from '../../styles';
import { SessionContext } from '../../Context';
import notificationMessage from './NotificationMessage';

class Notification extends React.Component {
  state = {
    isPersistent: false
  };

  render() {
    const { className } = this.props;
    const { settings } = this.props;

    return (
      <SessionContext.Consumer>
        {({ session, notification: { show, setNotification } }) => {
          if (!session || !session.viewer || !session.viewer.user) return;
          const notification = notificationMessage(session, settings, show);
          const isPersistent = this.state.isPersistent || notification.isPersistent;
          const shouldBeHidden = !isPersistent && !show;

          return (!shouldBeHidden && notification.hasNotification) && (
            <Fragment>
              <div className={className}>
                <div className="notification__container"></div>
                <span className="notification__message">
                  {notification.message} <Link to={'/profile'}>{notification.clickHere}</Link> {notification.update}
                </span>
                <div className="notification__container">
                 {!isPersistent && (
                  <Button handleClick={() => setNotification(false)} buttonStyle="inverted">
                    {notification.dismiss}
                  </Button>)
                 }
                </div>
              </div>
            </Fragment>
          );
        }}
      </SessionContext.Consumer>
    );
  }
}

const StyledNotification= styled(Notification)`
  @media (${breakpoints.tabletSmall}) {
    height: 100px;
  }
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.settings.theme.css.global.colors.notificationBarBackground};
  align-items: center;
  height: 50px;
  position: fixed;
  z-index: 999;
  width: 100%;
  > .notification__message {
    color: ${props => props.settings.theme.css.global.colors.notificationBarTextColor};
    font-size: 13px;
    a {
      text-decoration: underline;
      cursor: pointer;
      color: ${props => props.settings.theme.css.global.colors.notificationBarTextColor};
    }
  }
  > .notification__container {
    width: 100px;
    flex: 0 0 auto;
    button {
      border-color: ${props => props.settings.theme.css.global.colors.primary};
      font-size: 10px !important;
      min-height: 33px;
      padding: 0px 12px !important;
      letter-spacing: 1.5px;
      color: ${props => props.settings.theme.css.global.colors.notificationBarBackground} !important;
    }
  }
`;

const StyledNotificationWithSettings = withSettings(StyledNotification);

export { StyledNotificationWithSettings as Notification };
