export default [
  ['ON', 'Ontario'],
  ['QC', 'Quebec'],
  ['NS', 'Nova Scotia'],
  ['NB', 'New Brunswick'],
  ['MB', 'Manitoba'],
  ['BC', 'British Columbia'],
  ['PE', 'Prince Edward Island'],
  ['SK', 'Saskatchewan'],
  ['AB', 'Alberta'],
  ['NL', 'Newfoundland and Labrador']
];
