import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Query, Mutation, ApolloConsumer } from 'react-apollo';
import { EVENT_SPONSORSHIPS_BY_UUID, EVENT_SPONSORSHIPS_SETTINGS } from '../../queries';
import { UPDATE_EVENT_SPONSORSHIPS, UPDATE_EVENT_SPONSOR_FORM_SUBMITTED } from '../../mutations';
import { getEventIdFromPath, imEventHost, stripLeadingSlash } from '../../libs';
import { EVENT_TYPE } from '../../enums';
import { Loader, EventSponsorshipsSelector, withSettings } from '../components';
import { SessionContext } from '../../Context';
import ROUTES from '../../constants/routes';

class EventSponsorshipsSelectorPage extends React.Component {

  state = {
    settingsFetched: false,
    sponsorshipsEnabled: null,
    minimumSponsorshipLimit: 0
  };

  componentDidMount = () => {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features, event } } = this.props;
    const sponsorshipsEnabled = (features && (features.sponsorships === true || features.sponsorships === 'true'));
    const minimumSponsorshipLimit = parseInt(event.minimum_sponsorship_limit || 25);

    this.setState({
      settingsFetched: true,
      sponsorshipsEnabled: sponsorshipsEnabled,
      minimumSponsorshipLimit
    });
  }

  render() {
    const { location, settings: { theme: { site } } } = this.props;
    const eventId = getEventIdFromPath(location.pathname);
    const isAfterCreate = location.state && location.state.isAfterCreate;
    const isSoloEvent = location.state && location.state.isSoloEvent;
    const { settingsFetched, sponsorshipsEnabled, minimumSponsorshipLimit } = this.state;
    const homepageUrl = `/${stripLeadingSlash(site.homepage)}`;

    if (settingsFetched && !sponsorshipsEnabled) {
      return <Redirect to={homepageUrl}/>;
    }

    return (
      <SessionContext.Consumer>
        {({ session }) => {
          if (!session || !session.viewer) {
            return <Redirect to={homepageUrl}/>;
          }

          return (
            <Query query={EVENT_SPONSORSHIPS_SETTINGS}>
              {(sponsorshipsSettingsResponse) => (
                <Query query={EVENT_SPONSORSHIPS_BY_UUID} variables={{ uuid: eventId }}>
                  {(eventResponse) => (
                    <Mutation mutation={UPDATE_EVENT_SPONSORSHIPS}>
                      {(updateEventSponsorships) => (
                        <Mutation mutation={UPDATE_EVENT_SPONSOR_FORM_SUBMITTED}>
                          {(updateFormSubmittedBooleanMutation) => {
                            if (sponsorshipsSettingsResponse.loading || eventResponse.loading) {
                              return <Loader />;
                            }

                            if (!eventResponse || !eventResponse.data || !eventResponse.data.eventByUuid || !eventResponse.data.eventByUuid.eventType) {
                              return <Redirect to={homepageUrl}/>;
                            }

                            if (!sponsorshipsSettingsResponse || !sponsorshipsSettingsResponse.data || !sponsorshipsSettingsResponse.data.eventTypeSponsorshipSettings) {
                              return <Redirect to={homepageUrl}/>;
                            }

                            const event = eventResponse.data.eventByUuid;
                            const eventType = event.eventType;
                            const sponsorSettings = sponsorshipsSettingsResponse.data.eventTypeSponsorshipSettings.find(
                              sSettings => (sSettings.eventType === eventType)
                            );

                            if (!sponsorSettings || !sponsorSettings.pricePerHead || !sponsorSettings.priceMax) {
                              return <Redirect to={homepageUrl}/>;
                            }

                            // event with only 1 guest, $25 flat total //
                            // solo events need to get their propper PPH and PMAX //
                            const isLimitedEvent = (!isSoloEvent || eventType !== EVENT_TYPE.SOLO) && event.expectedGuests < 2;
                            const pricePerPerson = isLimitedEvent ? minimumSponsorshipLimit : sponsorSettings.pricePerHead / 100;
                            const totalSponsorshipsLimit = isLimitedEvent ? minimumSponsorshipLimit : sponsorSettings.priceMax / 100;

                            if (eventResponse.error || !eventResponse.data || !eventResponse.data.eventByUuid) {
                              return <Redirect to={homepageUrl}/>;
                            }

                            if (!event.nourishable || !imEventHost(event, session.viewer)) {
                              return <Redirect to={`/${ROUTES.MANAGE}/hosted`}/>;
                            }

                            return (
                              <ApolloConsumer>
                                {client => (
                                  <EventSponsorshipsSelector
                                    event={event}
                                    updateEventSponsorshipsMutation={updateEventSponsorships}
                                    updateFormSubmittedBooleanMutation={updateFormSubmittedBooleanMutation}
                                    expectedGuests={event.expectedGuests ? event.expectedGuests : 0}
                                    settingsPricePerHead={pricePerPerson}
                                    settingsPriceMax={totalSponsorshipsLimit}
                                    client={client}
                                    formAlreadySubmitted={event.sponsorFormSubmitted}
                                    isAfterCreate={isAfterCreate}
                                    isSoloEvent={isSoloEvent}
                                  />
                                )}
                              </ApolloConsumer>
                            );
                          }}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Query>
              )}
            </Query>
          );
        }}
      </SessionContext.Consumer>
    );
  }
}

const EventSponsorshipsSelectorPageWithRouter = withRouter(EventSponsorshipsSelectorPage);
const EventSponsorshipsSelectorPageWithRouterSettings = withSettings(EventSponsorshipsSelectorPageWithRouter);

export { EventSponsorshipsSelectorPageWithRouterSettings as EventSponsorshipsSelectorPage };
