import React from 'react';
import styled from 'styled-components';

import { UnderlineButton as Button } from '../components';

export const PaymentMethod = ({ card, cards, onChange, onNew, onSelect }) => (
  <Card>
    {card.image && <img src={card.image} />}
    <div>
      <Details>
        {card.brand}
        <P>**** **** **** {card.number} <span>Exp {card.expiration}</span> </P>
      </Details>
      {card.favorite && <Favorite>Primary card</Favorite>}
      <Buttons>
        {onChange && cards.length > 1 && <Button onClick={onChange}>Change</Button>}
        {onNew && <Button onClick={onNew}>New</Button>}
        {onSelect && <Button onClick={onSelect}>Select</Button>}
      </Buttons>
    </div>
  </Card>
);

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  img { margin-top: 3px; }
  & > div { padding-left: 15px; }
`;

const P = styled.p`
  display: inline-block;
  margin-left: 25px;
  margin-bottom: 0;
  font-size: 16px;

  span {
    font-size: 13px;
    display: inline-block;
    margin-left: 25px;
  }
`

const Details = styled.div`
  font-size: 18px;
  font-family: 'Avenir-Medium';
  margin-bottom: 3px;
`;

const Favorite = styled.span`
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-family: 'Avenir-Demi';
  padding-right: 22px;

  &::after {
    content: '';
    position: absolute;
    border: solid green;
    border-width: 0 0 2px 2px;
    top: 2px;
    right: 0;
    width: 13px;
    height: 7px;
    transform: rotate(-45deg);
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  .underline_button { margin-right: 15px; }
`;
