import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

const Input = (props) => {
  const [valid, setValid] = useState(true);
  const [lastMax, setLastMax] = useState(undefined);

  const {
    className,
    type,
    placeholder,
    name,
    value: fieldValue,
    errorMessage,
    errorPlacement,
    onInputFocus,
    onInputBlur,
    onInputChange,
    onValidate,
    onEnter,
    min,
    max,
    maxLength,
    minLength,
    maximumAge,
    minimumAge,
    required,
    readOnly,
    checked,
    forceShowErrorMessage,
    minAgeErrorMessage,
    maxAgeErrorMessage,
    disabled,
    pattern,
    content,
    autoComplete,
    hint,
    removeValidation = false,
    valid: validProp = true
  } = props;

  const validateTextInput = v => {
    if (v.length === 0) return false;
    if (maxLength && v.length > maxLength) return false;
    if (minLength && v.length < minLength) return false;
    return true;
  };

  const validateEmailInput = v => {
    const regex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/, 'i');
    return regex.test(v);
  };

  const validatePasswordInput = v => {
    return (v.length >= 4 && v.length <= 32);
  };

  const validateZipCodeInput = v => {
    const regex = new RegExp(/^\d{5}(-\d{4})?$/);
    return regex.test(v);
  };

  const validateDateInput = v => {
    const regex = new RegExp(/^([0-9]{2})\/[0-9]{2}\/[0-9]{4}$/);
    return regex.test(v);
  };

  const validateMaxAge = v => {
    if (!v) return false;
    if (!maximumAge) return true;

    const currentYear = (new Date()).getFullYear();
    const age = currentYear - v;
    return age <= maximumAge;
  };

  const validateMinAge = v => {
    if (!v) return false;
    if (!minimumAge) return true;

    const currentYear = (new Date()).getFullYear();
    const age = currentYear - v;
    return age >= minimumAge;
  };

  const validateAgeInput = v => {
    return validateMaxAge(v) && validateMinAge(v);
  };

  const validateDobInput = v => {
    if (!validateDateInput(v)) {
      return false;
    }

    const dobYear = v.split('/').pop();
    return validateAgeInput(dobYear);
  };

  const validateIntegerInput = v => {
    const number = parseInt(v);

    if (!Number.isInteger(number)) return false;
    if (removeValidation) return true;

    if (min !== undefined && number < min) return false;
    if (max !== undefined && number > max) return false;

    return true;
  };

  const inputIsValid = v => {
    switch (content) {
      case 'email':
        return validateEmailInput(v);
      case 'password':
        return validatePasswordInput(v);
      case 'zipCode':
        return validateZipCodeInput(v);
      case 'date':
        return validateDateInput(v);
      case 'dob':
        return validateDobInput(v);
      case 'integer':
        return validateIntegerInput(v);
      case 'ageByYear':
        return validateAgeInput(v);
      default:
        return validateTextInput(v);
    }
  };

  const handleChange = event => {
    const { name: eName } = event.target;
    let { value: eValue } = event.target;

    if (type === 'number') eValue = eValue !== '' ? parseInt(eValue, 10) : null;
    if (type === 'checkbox') eValue = eValue === 'true';

    onInputChange(eName, eValue, event);

    if (!required && (eValue === undefined || eValue === null || eValue === '')) {
      setValid(true);
      onValidate && onValidate(eName, true);
    } else {
      const isValidInput = inputIsValid(eValue);
      setValid(isValidInput);
      onValidate && onValidate(eName, isValidInput);
    }
  };

  const handleValidation = event => {
    const inputValue = event ? event.target.value : fieldValue;

    if (!required && (inputValue === undefined || inputValue === null || inputValue === '')) {
      setValid(true);
      onValidate && onValidate(name, true);
    } else {
      const isValid = inputIsValid(inputValue);
      setValid(isValid);
      onValidate && onValidate(name, isValid);
    }
  };

  const handleKeyDown = event => {
    const { keyCode, target } = event;

    if (onEnter && keyCode === 13) {
      event.preventDefault();
      if (target.value !== '') onEnter(name, target.value);
    }
  };

  const getErrorMessage = () => {
    const generalErrorMessage = errorMessage ? errorMessage : `Please enter a valid ${name.replace('_', ' ')}.`;

    if (type === 'number' && max && fieldValue > max) {
      return `Value must be ${max} or below.`;
    }

    if (content === 'dob' && !validateDateInput(fieldValue)) {
      return generalErrorMessage;
    }

    if (['ageByYear', 'dob'].includes(content) && fieldValue && (minimumAge || maximumAge)) {
      const dobYear = content === 'ageByYear' ? fieldValue : fieldValue.split('/').pop();

      if (minimumAge && !validateMinAge(dobYear)) {
        return minAgeErrorMessage;
      }

      if (maximumAge && !validateMaxAge(dobYear)) {
        return maxAgeErrorMessage;
      }
    }

    if (type === 'text' && maxLength && fieldValue.length > maxLength) {
      return `Cannot be more than ${maxLength} characters long.`;
    }
    if (type === 'text' && minLength && fieldValue.length < minLength) {
      return `Must be more than ${minLength} characters long.`;
    }

    return generalErrorMessage;
  };

  const getErrorClass = () => {
    return !valid || forceShowErrorMessage
      ? (errorPlacement === 'top' ? 'error_top' : 'error_bottom')
      : '';
  };

  useEffect(() => {
    // set `valid` state to false if the field isnt empty but
    // the `valid` prop (`validProp`) is set to false from elsewhere
    fieldValue && !validProp && setValid(false);
  }, [fieldValue, validProp]);

  useEffect(() => {
    if (max !== lastMax) {
      setLastMax(max);

      if (fieldValue && !inputIsValid(fieldValue)) setValid(false);
      else setValid(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max]);

  return (
    <div className={`
      ${className}
      ${getErrorClass()}
      ${disabled ? 'disabled' : ''}
    `}>
      {(!valid || forceShowErrorMessage) && !readOnly && errorPlacement === 'top' && (
        <div className="validation_error">{getErrorMessage()}</div>
      )}
      {hint && <div className="hint">{hint}</div>}
      <input
      content={content}
      autoComplete={autoComplete}
        readOnly={readOnly}
        type={type ? type : 'text'}
        disabled={disabled ? disabled : false}
        placeholder={placeholder ? placeholder : ''}
        min={min ? min : ''}
        max={max ? max : ''}
        name={name ? name : ''}
        value={fieldValue !== undefined && fieldValue !== null ? fieldValue : ''}
        className={!valid ? 'invalid' : ''}
        onChange={handleChange}
        onFocus={onInputFocus}
        checked={checked}
        onBlur={event => {
          handleValidation(event);
          onInputBlur && onInputBlur();
        }}
        onKeyDown={handleKeyDown}
        pattern={pattern ? pattern : null}
      />
      {(!valid || forceShowErrorMessage) && !readOnly && errorPlacement !== 'top' && (
        <div className="validation_error bottom">{getErrorMessage()}</div>
      )}
    </div>
  );
};


const StyledInput = styled(Input)`
  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-bottom: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }

  .hint {
    font-size: 11px;
    text-align: left;
    margin-bottom: 3px;
  }

  .invalid {
    border-color: ${props => props.settings.theme.css.global.colors.error} !important;

    &:focus {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
    }
  }

  &.error_bottom {
    input {
      margin-bottom: 0 !important;
    }

    .validation_error {
      margin-bottom: 5px;
    }
  }

  &.disabled {
    input {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
      cursor: default;
    }
  }
`;

const StyledInputWithSettings = withSettings(StyledInput);

export { StyledInputWithSettings as Input };
