import React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { withSettings } from '../components';
const COOKIE_NAME = 'cookie_acceptance';

const CookieBanner = ({ className, closeBanner, settings: { copy } }) => {
  if (Cookies.get(COOKIE_NAME)) return null;

  const handleCloseBanner = () => {
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 1 });
    closeBanner();
  };

  return (
    <div className={className}>
      <div className="banner_container">
        <p dangerouslySetInnerHTML={{ __html: copy.app.cookie_notification }} />
        <span
          className="accept_button"
          onClick={handleCloseBanner}
        >Accept</span>
      </div>
    </div>
  );
}


const StyledCookieBanner = styled(CookieBanner)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: ${props => props.settings.theme.css.global.colors.primary};
  z-index: 10000;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.04), 0 -2px 10px rgba(0,0,0,0.05);

  .banner_container {
    max-width: 1220px;
    margin: auto;
    padding-right: 150px;
    position: relative;

    @media (max-width: 575px) {
      padding-right: 0;
      padding-bottom: 40px;
    }
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;
      transition: opacity 250ms;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .accept_button {
    width: 130px;
    text-align: center;
    padding: 7px 14px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    transition: opacity 250ms;
    cursor: pointer;

    @media (max-width: 575px) {
      right: 50%;
      bottom: 0;
      top: auto;
      transform: translateX(50%);
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

const CookieBannerWithSettings = withSettings(StyledCookieBanner);
export { CookieBannerWithSettings as CookieBanner };
