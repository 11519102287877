import { useState, useEffect } from 'react';
import { useSession } from '../hooks';
import { useMutation } from '@apollo/react-hooks';
import { SIGN_UPLOAD, UPDATE_PROFILE_PICTURE } from '../mutations';
import { uploadToS3 } from '../libs/uploaders';

export const useImageUpload = () => {
  // const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [signUpload] = useMutation(SIGN_UPLOAD);
  const [updateProfilePicture] = useMutation(UPDATE_PROFILE_PICTURE);

  const { setViewerAvatarImage } = useSession();

  useEffect(() => {
    if (image) {
      setViewerAvatarImage(image);
      setLoading(false);
    }
  }, [image]);

  const selectFile = async (file) => {
    setLoading(true);
    // setFile(f);

    const reader = new FileReader();

    reader.onloadend = ({ target }) => setImage(target.result);
    if (file) reader.readAsDataURL(file);

    const signUploadResponse = await signUpload();
    const s3Credentials = signUploadResponse?.data?.signUpload?.uploadSignature || null;
    if (!s3Credentials) {
      setLoading(false);
      return;
    }

    const imageUrl = await uploadToS3(s3Credentials, file, null);
    if (!imageUrl) {
      setLoading(false);
      return;
    }

    const res = await updateProfilePicture({ variables: { avatarRemoteUrl: imageUrl } });
    if (!res?.data?.updateProfile || res.data.updateProfile.errors || res.errors) {
      // let serverIsDoneProcessingNewProfilePicture = false;
      //
      // while (!serverIsDoneProcessingNewProfilePicture) {
      //   const getProfilePictureResponse = await refetch();
      //   const newPhoto = getProfilePictureResponse.data.viewer?.user?.profile?.avatar?.photo;
      //   if (newPhoto) serverIsDoneProcessingNewProfilePicture = true;
      // }
      setLoading(false);
    }
  };

  return {
    selectFile,
    loading,
    image
  }
};
