import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles';
import { Button, withSettings } from '../components';

const JourneyRadioButtons = ({ className, options, value, onChange }) => {
  return (
    <span className={className}>
      {options.map((option, i) => {
        const optionValue = option.value ? option.value.string : '';
        const isSelected = value === optionValue;
        return (
          <Button
            key={i}
            handleClick={() => !isSelected && onChange(optionValue, option.label)}
            buttonStyle={isSelected ? 'primary' : 'outline'}
          >
            {option.label}
          </Button>
        );
      })}
    </span>
  );
};

const StyledJourneyRadioButtons = styled(JourneyRadioButtons)`
  button {
    margin: 15px;
    min-height: 48px;
    min-width: 180px;
    padding: 11px 28px 17px;

    ${media.sm`
      margin: 5px;
      min-height: 40px;
      min-width: 160px;
      padding: 9px 28px 15px;
    `}

    .button_content {
      color: #fff;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 0.18em;
      font-size: 12px;
    }

    &.outline {
      background-color: #ffffff20;

      &:hover {
        border-color: ${props => props.settings.theme.css.global.colors.primary};
        background-color: ${props => props.settings.theme.css.global.colors.primary};

        .button_content {
          color: #fff;
        }
      }
    }

    &.primary {
      &:hover {
        background-color: ${props => props.settings.theme.css.global.colors.primary};
        border-color: ${props => props.settings.theme.css.global.colors.primary};
        cursor: default;
      }
    }
  }
`;
const JourneyRadioButtonsWithSettings = withSettings(StyledJourneyRadioButtons);
export { JourneyRadioButtonsWithSettings as JourneyRadioButtons };
