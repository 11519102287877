import React from 'react';
import styled, { css } from 'styled-components';
import { useSettings } from '../../hooks';
import { PrimaryButton as Button } from '../components';


export const RsvpPageFooterButtons = ({
  continueText = 'Continue',
  onContinue,
  onBack,
  canContinue = true,
  terms,
  loading
}) => {
  const { colors } = useSettings();
  return (
    <Container>
      {onBack && <BackButton onClick={onBack}>Back</BackButton>}
      {terms && <Terms dangerouslySetInnerHTML={{ __html: terms }} colors={colors} />}
      {onContinue && (
        <Button
          onClick={onContinue}
          disabled={!canContinue || loading}
          loading={loading}
        >{continueText}</Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BackButton = styled.span`
  color: #83817b;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Terms = styled.p`
  margin 0 20px 0 0;
  font-size: 13px;
  max-width: 480px;
  color: #83817b;
  line-height: 1.35;

  a {
    ${({ colors: { primary, primaryHover } }) => css`
      color: ${primary};

      &:hover {
        color: ${primaryHover};
      }
    `}
  }
`;
