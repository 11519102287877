import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Checkbox } from '../components';
import { withSettings } from '../components';

class RsvpPaymentModalCardsManagementNew extends React.Component {

  state = {
    fullName: '',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    useAsDefaultPayment: false
  }

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  isFormDataValid = () => {
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key) && (this.state[`${key}Valid`] === false)) {
         return false;
      }
    }

    return true;
  }

  isCCError = type => {
    const { ccError, ccErrorObj } = this.props;
    return ccError && ccErrorObj.param === type;
  };

  render() {
    const { className, closeButtonClicked, newCardInputChanged, newCard } = this.props;
    const { settings: { copy } } = this.props;
    const { fullName, cardNumber, expirationMonth, expirationYear, securityCode, useAsDefaultPayment } = newCard;

    return (
      <div className={className}>
        <div className="row title-row">
          <div className="ot-twothirds-container">
            <h2>{copy.rsvp.card_pay_title}</h2>
          </div>
          <div className="ot-third-container">
            <Button
              buttonStyle="clearBackground"
              className="close-button"
              handleClick={closeButtonClicked}>
                {copy.profile.card_close_button}
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="ot-full-container">
            <label>{copy.profile.card_name_label}</label>
            <Input
              readOnly={false}
              type="text"
              name="fullName"
              placeholder={copy.profile.full_name_placeholder}
              value={fullName}
              onInputChange={newCardInputChanged}
              onValidate={this.handleInputValidation}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="ot-quarter-container">
            <label>{copy.profile.card_number_label}</label>
          </div>
          <div className={`ot-threequarter-container ${this.isCCError('number') ? 'has_error' : ''}`}>
            <label>
            <Input
              readOnly={false}
              type="text"
              name="cardNumber"
              placeholder={copy.profile.card_number_label}
              value={cardNumber}
              onInputChange={newCardInputChanged}
              onValidate={this.handleInputValidation}
              required
            />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="ot-quarter-container">
            <label>{copy.profile.card_expiration}</label>
          </div>
          <div className="ot-threequarter-container">
            <div className={`ot-half-container expiration-container ${this.isCCError('exp_month') ? 'has_error' : ''}`}>
              <Input
                readOnly={false}
                type="text"
                name="expirationMonth"
                placeholder={copy.profile.card_month_placeholder}
                value={expirationMonth}
                onInputChange={newCardInputChanged}
                onValidate={this.handleInputValidation}
                required
              />
            </div>
            <div className={`ot-half-container expiration-container ${this.isCCError('exp_year') ? 'has_error' : ''}`}>
              <Input
                readOnly={false}
                type="text"
                name="expirationYear"
                placeholder={copy.profile.card_year_placeholder}
                value={expirationYear}
                onInputChange={newCardInputChanged}
                onValidate={this.handleInputValidation}
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="ot-quarter-container">
            <label>{copy.profile.card_cvv}</label>
          </div>
          <div className={`ot-quarter-container ${this.isCCError('cvc') ? 'has_error' : ''}`}>
            <label>
            <Input
              readOnly={false}
              type="text"
              name="securityCode"
              placeholder={copy.profile.card_cvv_placeholder}
              value={securityCode}
              onInputChange={newCardInputChanged}
              onValidate={this.handleInputValidation}
              required
            />
            </label>
          </div>
          <div className="ot-half-container">
            <label className="default-payment-container">
              <Checkbox
                name="useAsDefaultPayment"
                float="right"
                options={[
                  {
                    label: copy.profile.card_use_default,
                    checked: useAsDefaultPayment
                  }
                ]}
                onInputChange={newCardInputChanged}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

RsvpPaymentModalCardsManagementNew.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCardsManagementNew = styled(RsvpPaymentModalCardsManagementNew)`
  text-align: left;

  & .title-row {
    align-items: center;
  }

  & h2 {
    text-align: left;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & .ot-third-container {
    padding: 0;
  }

  & .close-button {
    font-weight: bold;
    float: right;
  }

  & label {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    display: block;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  & .row {
    margin-bottom: 0;
  }

  & .expiration-container {
    margin: 0;
    padding: 0;
  }

  & .expiration-container:nth-child(2) {
    margin-left: -1px;
  }

  & .default-payment-container input {
    // top: unset;
    // bottom: 4px;
  }

  & .default-payment-container span {
    font-size: 12px;
  }

  & .checkbox__label {
    margin: 0;
  }

  .has_error {
    z-index: 1;

    input {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
    }
  }
`;

const StyledRsvpPaymentModalCardsManagementNewWithSettings = withSettings(StyledRsvpPaymentModalCardsManagementNew);

export { StyledRsvpPaymentModalCardsManagementNewWithSettings as RsvpPaymentModalCardsManagementNew };
