import React from 'react';
import styled from 'styled-components';
import { Input } from '../../components';
import { invalidateText, invalidateNumber } from '../../../libs';
import { withSettings } from '../../components';

const EventCreationCommunityTable = (props) => {
  const {
    className,
    index,
    eventData,
    onRemoveTable,
    onTableInputChange,
    onValidate,
    invalidate
  } = props;

  const { settings: { copy } } = props;

  return (
    <div className={className}>
      <div className="title_container">
        <h5>Table {index + 1}</h5>
        {index > 0 && (
          <span
            className="remove_table"
            onClick={() => onRemoveTable(index)}
          >Remove</span>
        )}
      </div>
      <div className="form_group">
        <div className="two_thirds">
          <Input
            name="name"
            value={eventData.tables[index].name}
            placeholder={copy.create.table_name}
            onInputChange={(name, value) => onTableInputChange(index, name, value)}
            onValidate={(name, value) => onValidate(index, name, value)}
            invalidate={() => invalidate && invalidateText(eventData.tables[index].name)}
            errorMessage="Enter a table name."
            required
          />
        </div>
        <div className="one_third">
          <Input
            type="number"
            name="maxSeats"
            value={eventData.tables[index].maxSeats}
            placeholder={copy.create.table_max_seats}
            onInputChange={(name, value) => onTableInputChange(index, name, value)}
            onValidate={(name, value) => onValidate(index, name, value)}
            invalidate={() => invalidate && invalidateNumber(eventData.tables[index].maxSeats)}
            errorMessage="Enter maximum seats."
            required
          />
        </div>
      </div>
    </div>
  );
}

const StyledEventCreationCommunityTable = styled(EventCreationCommunityTable)`
  .title_container {
    padding: 0 0 10px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    h5 {
      float: left;
      font-size: 14px;
      margin: 0;
      padding-top: 3px;
    }

    .remove_table {
      float: right;
      display: block;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }
`;

const StyledEventCreationCommunityTableWithSettings = withSettings(StyledEventCreationCommunityTable);

export { StyledEventCreationCommunityTableWithSettings as EventCreationCommunityTable };
