import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  withSettings,
  EventCardFooter,
  EventCardStack
} from '../../components';

import {
  stringInsert
} from '../../../libs';

class PrivateEventsCard extends React.Component {
  showModal = () => {
    this.setState({ showModal: true });
  }

  handleToggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  handleCardClick = () => {
    const { showWeeksPrivateEvents, weekDate } = this.props;
    showWeeksPrivateEvents(weekDate);
  };

  render() {
    const { className, settings: { theme, copy }, privateEventsNumber, weekDate } = this.props;
    const images = theme.assets.images;
    const bgImageUrl = images.private_card_header || images.default_cover;

    return (
      <Fragment>
        <div
          className={`${className} ot_event_card`}
          onClick={this.handleCardClick}
        >
          <div
            className="card_header"
            style={{ backgroundImage: `url(${bgImageUrl})` }}
          />
          <div className="card_body">
            <h4 className="event_title">{copy.private_card.title}</h4>
            <p>{stringInsert(copy.private_card.body_text, [privateEventsNumber, weekDate])}</p>
          </div>
          <EventCardFooter weekDate={weekDate} type="private" />
          <EventCardStack eventsHiddenNumber={privateEventsNumber}/>
        </div>
      </Fragment>
    );
  }
}

const StyledPrivateEventsCard = styled(PrivateEventsCard)`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  cursor: pointer;
  transition: box-shadow 250ms;
  background-color: #fff;
  opacity: 0;

  &:hover {
    box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
  }

  .card_header {
    position: relative;
    z-index: 1;
    border-radius: 5px 5px 0 0;
    height: 170px;
    padding: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card_body {
    padding: 40px 20px 60px;

    .event_title {
      font-size: 16px;
      margin: 0 0 10px;
    }

    p {
      font-size: 12px;
      color: ${props => props.settings.theme.css.global.colors.heading};
    }
  }
`;


const PrivateEventsCardWithSettings = withSettings(StyledPrivateEventsCard);

export { PrivateEventsCardWithSettings as PrivateEventsCard };
