import { useState, useEffect, useRef, useCallback } from 'react';

import { youTubeRegex, spotifyRegex } from '../libs';

export const useJourneyState = data => {
  const hasLoaded = useRef(false);
  const [pages, setPages] = useState([]);
  const [pagesTitles, setPageTitles] = useState([]);
  const [initialPageId, setInitialPageId] = useState(null);
  // const [currentPage, setCurrentPage] = useState(null);
  const [currentConditions, setCurrenConditions] = useState([]);
  const [journeyResources, setResources] = useState([]);

  const buildConditionData = useCallback(() => {
    if (!pages.length) return;

    const { results: { nodes: results } } = pages[pages.length - 1];
    const conditionData = [];

    results.forEach(({
      conditions,
      nextUrl,
      journeyPageResultResources: { nodes: resources },
      nextJourneyPage: { renderTemplate, id: nextPageId }
    }) => {
      const resultCondition = {
        conditions: [],
        resources: [],
        videos: [],
        playlists: [],
        nextUrl,
        renderTemplate,
        nextPageId
      };

      if (conditions.length) {
        conditions.forEach(({ answer, questionId, responseAnswer, responseQuestionId }) => {
          const condition = { questionId, answers: [], responseQuestionId, responseAnswers: [] };

          answer.forEach(({ string }) => condition.answers.push(string));
          responseAnswer && responseAnswer.forEach(({ string }) => condition.responseAnswers.push(string));

          resultCondition.conditions.push(condition);
        });

        resources.forEach(({ position, resource, disabled }) => {
          if (disabled || resource.url === 'disabled' || resource.disabled) return;

          if (resource.url && resource.url.match(youTubeRegex)) {
            resultCondition.videos.push({ position, resource });
          } else if (resource.url && resource.url.match(spotifyRegex)) {
            resultCondition.playlists.push({ position, resource });
          } else {
            resultCondition.resources.push({ position, resource });
          }
        });

        conditionData.push(resultCondition);
      }
    });

    setCurrenConditions(conditionData);
  }, [pages]);

  const getNextJourneyPageId = ({ results: { nodes } }) => {
    if (nodes && nodes.length) {
      const { nextJourneyPage } = nodes[0] || {};
      return nextJourneyPage && nextJourneyPage.id;
    }
    return false;
  };

  const findNextNode = (nodes, nextNodeId) => {
    return nodes.find(n => n.id === nextNodeId);
  };

  const buildPageTitleData = useCallback((nodes, initialId) => {
    const immersiveNodes = nodes.filter(n => n.id !== initialId && n.renderTemplate.toLowerCase() === 'immersive');
    const resourcesNode = nodes.find(n => n.id !== initialId && n.renderTemplate.toLowerCase() === 'resources');
    const initialNode = nodes.find(n => n.id === initialId);
    const titles = [initialNode.name];
    let nextNodeId = initialNode && getNextJourneyPageId(initialNode);
    let nextNode;

    while (nextNodeId) {
      nextNode = findNextNode(immersiveNodes, nextNodeId);
      nextNode && titles.push(nextNode.name);
      nextNodeId = nextNode && getNextJourneyPageId(nextNode);
    }

    titles.push(resourcesNode.name);
    return titles;
  }, []);

  useEffect(() => {
    if (data && data.userJourney && !hasLoaded.current) {
      const { userJourney: { initialJourneyPage, journeyPages: { nodes: pageNodes } } } = data;
      const landingPage = pageNodes.find(p => p.renderTemplate && p.renderTemplate.toLowerCase() === 'landing');
      const madlibLandingPage = pageNodes.find(p => p.renderTemplate && p.renderTemplate.toLowerCase() === 'madlib-landing');
      const initialPage = pageNodes.find(p => p.id === initialJourneyPage.id);

      setPages(prev => prev.concat([((data.fromMadlib && madlibLandingPage) ? madlibLandingPage : landingPage) || initialPage]));
      setPageTitles(buildPageTitleData(pageNodes, initialJourneyPage.id));
      setInitialPageId(initialJourneyPage.id);
      hasLoaded.current = true;
    }
  }, [data, buildPageTitleData]);

  useEffect(() => {
    buildConditionData();
  }, [pages, buildConditionData]);

  const addPage = id => {
    const { userJourney: { journeyPages: { nodes: pageNodes } } } = data;
    const nextPage = pageNodes.find(p => p.id === id);
    setPages(prev => prev.concat([nextPage]))
  };

  const removePage = () => {
    setPages(pages.slice(0, pages.length - 1));
  };

  const saveResources = resources => {
    setResources(prev => prev.concat(resources));
  };

  const startJourney = () => {
    if (!data) return;

    const { userJourney: { initialJourneyPage, journeyPages: { nodes: pageNodes } } } = data;
    const initialPage = pageNodes.find(p => p.id === initialJourneyPage.id);

    setPages(prev => [...prev, initialPage]);
  };

  const updatePageTitles = (title, index) => {
    const newPageTitles = pagesTitles.slice();

    if (title && Number.isInteger(index)) {
      newPageTitles[index] = title;
      setPageTitles(newPageTitles);
    }
  };

  return {
    journeyId: data && data.userJourney && data.userJourney.id,
    initialPageId,
    currentPage: pages[pages.length - 1],
    pages,
    pagesTitles,
    addPage,
    currentConditions,
    journeyResources,
    saveResources,
    removePage,
    startJourney,
    updatePageTitles
  };
};
