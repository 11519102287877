import React from 'react';
import { NavLink } from 'react-router-dom';


const CustomMenuItems = ({ items, className = null, onToggleMenu }) => {
  if (!Array.isArray(items)) return null;

  return items.map((item, i) => {
    const linkTo = item.route ? { pathname: item.route } : item.url;
    if (item.route && item.title) linkTo.state = { pageTitle: item.title };

    return (
      <li onClick={onToggleMenu} key={i} className={className}>
        {item.route
          ? (<NavLink to={linkTo}>{item.label}</NavLink>)
          : (<a href={linkTo} target="_blank" rel="noopener noreferrer">{item.label}</a>)}
      </li>
    );
  });
};


export { CustomMenuItems };
