import React from 'react';
import styled from 'styled-components';
import { useCheckoutData } from '../../hooks';

import { PaymentMethod } from '../components';

export const RsvpPageChoosePaymentMethod = ({ onSelect }) => {
  const { cardData: { cards } } = useCheckoutData();

  return (
    <Container>
      {cards && cards.length
        ? cards.map((card, i) =>
          <div key={i}><PaymentMethod card={card} onSelect={() => onSelect(card)} /></div>)
        : null}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  & > div {
    &:not(:last-child) {
      padding-bottom: 30px;
    }
  }
`;
