export default {
  ROOT: '',
  ALL: 'all',
  LANDING: 'landing',
  SEARCH: 'search',
  JOURNEY: 'journey',
  ARCHIVE: 'archive',
  TAGS: 'tags',
  VIRTUAL: 'virtual',
  CATEGORIES: 'categories',
  EVENTS: 'events',
  COMMUNITY: 'community',
  NOURISHMENT: 'nourishment',
  MANAGE: 'manage',
  PROFILE: 'profile',
  USER_PROFILE: 'user_profile',
  HOST_PROMOTE: 'host/promote',
  RSVP: 'rsvp'
};
