import React from 'react';
import styled from 'styled-components';

import { withSettings } from '../../components';

class InputWithButton extends React.Component {
  state = {
    valid: true
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleChange = event => {
    const { type, onInputChange, onValidate, required } = this.props;
    const { name } = event.target;

    let { value } = event.target;

    if (type === 'number') value = value !== '' ? parseInt(value, 10) : null;
    if (type === 'checkbox') value = value === 'true';

    onInputChange(name, value);

    if (!required && (value === undefined || value === null || value === '')) {
      this.setState({ valid: true });
      onValidate && onValidate(name, true);
    } else {
      const isValidInput = this.inputIsValid(value);
      this.setState({ valid: isValidInput });
      onValidate && onValidate(name, isValidInput);
    }
  };

  handleValidation = event => {
    const { name, value } = event.target;
    const { onValidate, required } = this.props;

    if (!required && (value === undefined || value === null || value === '')) {
      this.setState({ valid: true });
      onValidate && onValidate(name, true);
    } else {
      const valid = this.inputIsValid(value);
      this.setState({ valid });
      onValidate && onValidate(name, valid);
    }
  };

  handleKeyDown = event => {
    const { onEnter, name } = this.props;
    const { keyCode, target } = event;

    if (onEnter && keyCode === 13) {
      event.preventDefault();
      if (target.value !== '') onEnter(name, target.value);
    }
  };

  inputIsValid = value => {
    switch (this.props.content) {
      case 'email':
        return this.validateEmailInput(value);
      case 'password':
        return this.validatePasswordInput(value);
      case 'zipCode':
        return this.validateZipCodeInput(value);
      case 'date':
        return this.validateDateInput(value);
      case 'dob':
        return this.validateDobInput(value);
      case 'integer':
        return this.validateIntegerInput(value);
      case 'ageByYear':
        return this.validateAgeInput(value);
      default:
        return this.validateTextInput(value);
    }
  };

  validateTextInput = value => {
    const { maxLength, minLength } = this.props;

    if (value.length === 0) {
      return false;
    }

    if (maxLength && value.length > maxLength) {
      return false;
    }

    if (minLength && value.length < minLength) {
      return false;
    }

    return true;
  };

  validateEmailInput = value => {
    const regex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/, 'i');
    return regex.test(value);
  };

  validatePasswordInput = value => {
    return (value.length >= 4 && value.length <= 32);
  };

  validateZipCodeInput = value => {
    const regex = new RegExp(/^\d{5}(-\d{4})?$/);
    return regex.test(value);
  };

  validateDateInput = value => {
    const regex = new RegExp(/^([0-9]{2})\/[0-9]{2}\/[0-9]{4}$/);
    return regex.test(value);
  };

  validateDobInput = value => {
    if (!this.validateDateInput(value)) {
      return false;
    }

    const dobYear = value.split('/').pop();
    return this.validateAgeInput(dobYear);
  };

  validateIntegerInput = value => {
    const { min, max, removeValidation = false } = this.props;
    const number = parseInt(value);

    if (!Number.isInteger(number)) {
      return false;
    }

    if (removeValidation) return true;

    if (min !== undefined && number < min) {
      return false;
    }
    if (max !== undefined && number > max) {
      return false;
    }

    return true;
  };

  validateAgeInput = value => {
    return this.validateMaxAge(value) && this.validateMinAge(value);
  };

  validateMaxAge = value => {
    const { maximumAge } = this.props;

    if (!value) {
      return false;
    }

    if (!maximumAge) {
      return true;
    }

    const currentYear = (new Date()).getFullYear();
    const age = currentYear - value;

    return age <= maximumAge;
  };

  validateMinAge = value => {
    const { minimumAge } = this.props;

    if (!value) {
      return false;
    }

    if (!minimumAge) {
      return true;
    }

    const currentYear = (new Date()).getFullYear();
    const age = currentYear - value;

    return age >= minimumAge;
  };

  getErrorMessage = () => {
    const { errorMessage, name, type, value, max, content, minimumAge, maximumAge, minAgeErrorMessage, maxAgeErrorMessage, maxLength, minLength } = this.props;
    const generalErrorMessage = errorMessage ? errorMessage : `Please enter a valid ${name.replace('_', ' ')}.`;

    if (type === 'number' && max && value > max) {
      return `Value must be ${max} or below.`;
    }

    if (content === 'dob' && !this.validateDateInput(value)) {
      return generalErrorMessage;
    }

    if (['ageByYear', 'dob'].includes(content) && value && (minimumAge || maximumAge)) {
      const dobYear = content === 'ageByYear' ? value : value.split('/').pop();

      if (minimumAge && !this.validateMinAge(dobYear)) {
        return minAgeErrorMessage;
      }

      if (maximumAge && !this.validateMaxAge(dobYear)) {
        return maxAgeErrorMessage;
      }
    }

    if (type === 'text' && maxLength && value.length > maxLength) {
      return `Cannot be more than ${maxLength} characters long.`;
    }
    if (type === 'text' && minLength && value.length < minLength) {
      return `Must be more than ${minLength} characters long.`;
    }

    return generalErrorMessage;
  };

  getErrorClass = () => {
    const { errorPlacement, forceShowErrorMessage } = this.props;
    const { valid } = this.state;
    return !valid || forceShowErrorMessage
      ? (errorPlacement === 'top' ? 'error_top' : 'error_bottom')
      : '';
  };

  render() {
    const {
      className,
      type,
      placeholder,
      name,
      value,
      errorPlacement,
      onInputFocus,
      onInputBlur,
      min,
      max,
      readOnly,
      checked,
      forceShowErrorMessage,
      disabled,
      pattern,
      content,
      autoComplete,
      children
    } = this.props;

    return (
      <div className={`
        ${className}
        ${this.getErrorClass()}
        ${disabled ? 'disabled' : ''}
      `}>
        {(!this.state.valid || forceShowErrorMessage) && !readOnly && errorPlacement === 'top' && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
        <div className="input_group">
          <input
            content={content}
            autoComplete={autoComplete}
            readOnly={readOnly}
            type={type ? type : 'text'}
            disabled={disabled ? disabled : false}
            placeholder={placeholder ? placeholder : ''}
            min={min ? min : ''}
            max={max ? max : ''}
            name={name ? name : ''}
            value={value !== undefined && value !== null ? value : ''}
            className={!this.state.valid ? 'invalid' : ''}
            onChange={this.handleChange}
            onFocus={onInputFocus}
            checked={checked}
            onBlur={event => {
              this.handleValidation(event);
              onInputBlur && onInputBlur();
            }}
            onKeyDown={this.handleKeyDown}
            pattern={pattern ? pattern : null}
          />
        <div className="input_group_append">
          {children}
        </div>
        </div>
        {(!this.state.valid || forceShowErrorMessage) && !readOnly && errorPlacement !== 'top' && (
          <div className="validation_error bottom">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}

const StyledInputWithButton = styled(InputWithButton)`
  .input_group {
    // position: relative;
    display: flex;
    width: 100%;
    // max-width: 400px;    
  }
  .input_group input {
    // width: 1%;
    // flex: 1 1 auto;
    flex: 4;
    // position: relative;
    // margin-bottom: 0px !important;
  }
  .input_group_append {
    display: flex;
    justify-content: stre
    margin-left: -1px;
    max-height: 38px;
    
    // margin-bottom: 14px;
  }
  .input_group_append button {   
     
      border-radius: 0 3px 3px 0;    
  }
  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-bottom: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
  .invalid {
    border-color: ${props => props.settings.theme.css.global.colors.error} !important;
    &:focus {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
    }
  }
  &.error_bottom {
    input {
      margin-bottom: 0 !important;
    }
    .validation_error {
      margin-bottom: 5px;
    }
  }
  &.disabled {
    input {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
      cursor: default;
    }
  }
  
`;

const StyledInputWithButtonWithSettings = withSettings(StyledInputWithButton);

export { StyledInputWithButtonWithSettings as InputWithButton };