import React from 'react';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { withSettings } from '../components';
import { SessionContext } from '../Context';
import ROUTES from '../constants/routes';

import * as userJourney from '../libs/userJourney';
const OAUTH_TOKEN_PARAM_KEY = 't';

const AuthRoute = ({ component: ComponentToRender, location, settings, ...rest }) => {
  const showJourneyResources = userJourney.shouldShow(location, settings);

  // Call the URLSearchParams function to get the oauth token and redirect to the auth route
  // after the useGlobalUrlParams hook setup the login token for the user
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const urlToken = urlParams.get(OAUTH_TOKEN_PARAM_KEY)

  return (
    <SessionContext.Consumer>
      {({ session }) => {
        const { viewer } = session;
        const user = viewer?.user || null;
        const hostedEvents = viewer?.hostedEvents || null;
        const { host, notHost, noHostRedirect } = rest;
        // cohosts prop doesnt currently do anything, checked in page component //

        if (showJourneyResources) return <Redirect to="/journey" />;

        // if !viewer there was a graphql error //
        if (viewer && !user) {
          if(urlToken) {
            return <Redirect to={`/${ROUTES.EVENTS}`} /> // if the token is present all request will be redirect to events page
        }

          return <Redirect to={{
            pathname: '/landing',
            search: '?login=true&redirectAfterLoginTo=' + window.location.href,
            state: { redirectAfterLoginTo: window.location.href }
          }}/>;
        }

        if ((!host && !notHost) || (host && user.state === 'host') || (notHost && user.state !== 'host')) {
          return (
            <Route
              {...rest}
              render={
                props =>
                  <ComponentToRender {...rest} {...props} loggedUser={{ ...user, hostedEvents }}/>}
            />
          );
        } else {
          // user logged in but shouldn't access route //
          return <Redirect to={noHostRedirect || '/landing'}/>;
        }
      }}
    </SessionContext.Consumer>
  );
};

const AuthRouteWithRouter = withRouter(AuthRoute);
const AuthRouteWithSettings = withSettings(AuthRouteWithRouter);
export { AuthRouteWithSettings as AuthRoute };
