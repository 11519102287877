import { useEffect, useState } from 'react';
import { sortBy } from 'underscore';

import fetchWordpressMenu from '../../libs/fetchWordpressMenu';

// perform conversions based on key CSS classes added to Wordpress menu items
const convertMenuItem = ({ title, url: wpUrl, target, type, classes = [] }) => {
  if (type === 'custom') {
    // areas subtree
    if (classes.includes('menu-app-areas')) {
      return { type: 'areas', title, classes };
    }
    // subtype subtree
    if (classes.includes('menu-app-subtypes')) {
      return { type: 'subtypes', title, classes };
    }
    // subtype subtree
    if (classes.includes('menu-app-area-subtypes')) {
      return { type: 'area-subtypes', title, classes };
    }
    // route menu item
    if (classes.includes('menu-app-route')) {
      // remove protocol, host, and port to get the route path
      const url = new URL(wpUrl);
      return {
        type: 'route',
        title,
        url: wpUrl.replace(url.origin, ''),
        target,
        classes
      };
    }
  }
  return { type: 'link', title, url: wpUrl, target, classes };
};

// build an ordered menu tree from the flat Wordpress menu json
const buildMenu = (flatMenu, parentId = 0) => {
  const children = flatMenu.items
    .filter(m => parseInt(m.menu_item_parent, 10) === parentId)
    .filter(m => !m.classes.includes('wp-menu-only'));
  const sortedChildren = sortBy(children, m => m.menu_order).map(c => ({
    ...convertMenuItem(c),
    children: c.child_items ? c.child_items.map(ci => convertMenuItem(ci)) : []
  }));
  return sortedChildren;
};

const useWordpressMenu = settings => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!settings) return;
    let cancelled = false;

    const fetchData = async () => {
      setLoading(true);

      try {
        const fetchResult = await fetchWordpressMenu(settings);
        if (!cancelled) {
          setLoading(false);
          const newMenu = buildMenu(fetchResult);
          setMenu(newMenu);
        }
      } catch (err) {
        if (!cancelled) {
          setError(err);
        }
      }
    };

    fetchData();

    return () => {
      // stop any in-flight fetch from setting state on a unmounted node
      cancelled = true;
    };
  }, [settings]);

  return { loading, error, menu };
};

export default useWordpressMenu;
