import React from 'react';
import styled from 'styled-components';

import { FAIcon, Tooltip } from '../../components';

class InfoTooltip extends React.Component {
  state = { displayTooltip: false };

  showTooltip = () => {
    this.setState({ displayTooltip: true });
  };

  hideTooltip = () => {
    this.setState({ displayTooltip: false });
  };

  getContent = content => {
    if (typeof content === 'string') {
      return content;
    } else {
      return content();
    }
  };

  render() {
    const { displayTooltip } = this.state;
    const { className, content, position, icon } = this.props;
    const iconName = Array.isArray(icon) ? icon : (
      ['fas', icon ? icon : 'question-circle']
    );

    if (!content) return null;

    return (
      <div
        className={`info_tooltip ${className} ${position ? position : ''}`}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {displayTooltip && <Tooltip>{this.getContent(content)}</Tooltip>}
        <FAIcon name={iconName}/>
      </div>
    );
  }
}

const StyledInfoTooltip = styled(InfoTooltip)`
  display: inline-block;
  position: relative;

  &.right {
    .ot_tooltip {
      transform: translateX(-75%);

      &::before,
      &::after {
        left: 75%;
      }
    }
  }

  &.left {
    .ot_tooltip {
      transform: translateX(-25%);

      &::before,
      &::after {
        left: 25%;
      }
    }
  }

  .ot_tooltip {
    left: 50%;
    transform: translateX(-50%);
    // border-color: green;
    top: auto;
    bottom: calc(100% + 10px);
    min-width: 150px;
    z-index: 900;
  }

  svg {
    font-size: 13px;
    color: #bbb;
  }
`;

export { StyledInfoTooltip as InfoTooltip };
