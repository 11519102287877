import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  EventSearchFilterModalFilters,
  withSettings
} from '../components';


const EventSearchFilterModal = props => {
  const { className, show, toggle, settings } = props;
  const { copy } = settings;

  return (
    <Modal
      show={show}
      toggle={toggle}
      size="xl"
      noPadding
    >
      <div className={className}>
        <h2>{copy.search.filter_modal_title || 'Narrow results by'}</h2>
        <EventSearchFilterModalFilters />
      </div>
    </Modal>
  );
};

const StyledEventSearchFilterModal = styled(EventSearchFilterModal)`
  padding: 4rem 3rem;

  h2 {
    font-size: 24px;
    margin: 0 0 30px;
    text-align: center;
  }
`;

const EventSearchFilterModalWithSettings = withSettings(StyledEventSearchFilterModal);
export { EventSearchFilterModalWithSettings as EventSearchFilterModal };
