import React from 'react';
import styled from 'styled-components';
import Html from 'html-to-react';

import { withSettings, Card } from '../components';

const htmlParser = new Html.Parser();

const EventCreationResource = ({ className, resource, newDesign = false }) => {
  return (
    <li className={`${className} resource_card ${newDesign ? 'new-design' : ''}`}>
      <Card noPadding>
        {!newDesign && <div
          className="resource__image"
          style={{ backgroundImage: `url(${resource.image && resource.image.mediumUrl})` }}
        />}
        <div className="resource__details">
          <div>
            <h3>{htmlParser.parse(resource.title)}</h3>
            {!newDesign && (
              <>
                <p>{htmlParser.parse(resource.description)}</p>
                <span>View Resource</span>
              </>
            )}
          </div>
        </div>
        {(resource.url || resource.attachment) && (
          // eslint-disable-next-line
          <a className="resource__link"
             href={resource.url || resource.attachment}
             target="_blank"
             rel="noopener noreferrer"
          />
        )}
      </Card>
    </li>
  );
};

const StyledEventCreationResource = styled(EventCreationResource)`
  display: inline-block;
  vertical-align: top;
  margin: 15px;

  &.new-design {
    .resource {
      &__details {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        min-height: 180px;
        background-color: rgba(255,255,255,0.1);
        transition: background-color 250ms;

        h3 {
          color: white;
          margin: 15px 0;
        }
        p {
          color: white;
          margin: 15px 0;
        }
      }
    }

    .ot_card {
      background-color: transparent;
      border-radius: 0;
      margin: 0;

      &:hover {
        .resource__details {
          background-color: rgba(255,255,255,0.2);
        }
      }
    }
  }

  .ot_card {
    position: relative;
    width: 270px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: box-shadow 250ms;

    &:hover {
      box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
    }
  }

  .resource {
    &__image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: 100%;
      border-radius: 5px 5px 0 0;
    }

    // &__video {
    //   height: 226px;
    //   padding: 0;
    // }

    &__details {
      padding: 25px 20px 45px;
      position: relative;

      h3 {
        font-size: 16px;
        margin: 0 0 15px;
        line-height: 1.4;
      }

      p {
        font-size: 13.5px;
        margin: 20px 0;
      }

      span {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 13px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        transition: color 250ms;
      }
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &:hover {
    .resource {
      &__details {
        span {
          color: ${props => props.settings.theme.css.global.colors.primaryHover};
          text-decoration: underline;
        }
      }
    }
  }
`;

const StyledEventCreationResourceWithSettings = withSettings(StyledEventCreationResource);

export { StyledEventCreationResourceWithSettings as EventCreationResource };
