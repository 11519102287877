import React from 'react';
import styled from 'styled-components';

import {
    ManageEventsDetailsList,
    SelectBox,
    FAIcon,
    EventPotluck,
    ManageEventsDetailsCohosts
} from '../components';

import { RESERVATION_FILTER } from '../../enums';
import { withSettings } from '../components';
import { stringInsert, isPast } from '../../libs';
import { breakpoints } from '../../styles';

class ManageEventsDetailsListBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            show: RESERVATION_FILTER.ALL
        }
    }

    getItem = (element) => {
        const { event } = this.props;

        // console.log(element);

        if (element.node && element.node.invitee) {
            const {
                email,
                id: invitationId,
                invitee: { id, firstName, lastName },
                status
            } = element.node;

            return {
                invitationId,
                id,
                firstName,
                lastName,
                email,
                status
            };
        } else if (element.node && element.node.profile) {
            const {
              id: reservationId,
              profile: { id, firstName, lastName, user: { email } },
              potluckItems,
              additionalGuests,
              answers: { edges: answers },
              tables,
              participated
            } = element.node;

            const itemData = {
              id,
              firstName,
              lastName,
              email,
              additionalGuests,
              answers,
              participated,
              reservationId
            };

            if (potluckItems && potluckItems.totalCount > 0) {
                const itemsArray = potluckItems.edges ? potluckItems.edges.map(edge => edge.node) : [];
                const items = itemsArray.map(item => item.name);
                itemData.potluckItems = items;
            }

            if (event.communityDinner) {
                const table = tables.edges && tables.edges.length ? tables.edges[0].node : null;
                if (table) itemData.table = table.name;
            }

            return itemData;
        } else {
            const { id, email, status } = element.node;
            return {
                invitationId: id,
                firstName: email,
                lastName: '',
                email: email,
                status: status
            }
        }
    }

    isRed = (amount) => {
        return amount ? 'red' : null;
    }

    handleFilter = (e) => {
        this.setState({
            filter: e.target.value
        });
    }


    handleShow = (value) => {
        this.setState({
            show: value
        });
    }

    getListShowDefault = () => {
        return this.state.show;
    }

    getListShow = () => {
        const SHOW = RESERVATION_FILTER;
        const { FIELDS: { LABEL } } = RESERVATION_FILTER;
        return [
            {
                id: 1,
                label: SHOW.getField(LABEL, SHOW.ALL),
                value: SHOW.ALL
            },
            {
                id: 2,
                label: SHOW.getField(LABEL, SHOW.PENDING),
                value: SHOW.PENDING
            },
            {
                id: 3,
                label: SHOW.getField(LABEL, SHOW.ATTENDING),
                value: SHOW.ATTENDING
            },
            {
                id: 4,
                label: SHOW.getField(LABEL, SHOW.INVITED),
                value: SHOW.INVITED
            },
            {
                id: 5,
                label: SHOW.getField(LABEL, SHOW.CANCELED),
                value: SHOW.CANCELED
            },
            {
                id: 6,
                label: SHOW.getField(LABEL, SHOW.INVITATION_DECLINED),
                value: SHOW.INVITATION_DECLINED
            },
            {
                id: 7,
                label: SHOW.getField(LABEL, SHOW.RESERVATION_DECLINED),
                value: SHOW.RESERVATION_DECLINED
            }

        ]
    };

    getDefaultFilterValue = () => {
      const { show } = this.state;
      const SHOW = RESERVATION_FILTER;
      const { FIELDS: { LABEL } } = SHOW;

      return {
        label: SHOW.getField(LABEL, SHOW[show]),
        value: show
      };
    };

    render() {
        const { className, event, showInviteBar, settings: { copy } } = this.props;
        const { reservations, invitations, pagination, refetch, refetchEvent, loading, fetchMoreLoading } = this.props;
        const { isRed } = this;
        const { filter, show } = this.state;
        const {
          reservations: { guestsPending, guestsAccepted, reservationsRejected },
          canceledReservations: { totalCount: reservationsCanceled },
          eventHosts,
          id: eventId
        } = event;
        const { invitationsPending, invitationsDeclined } = event.invitations;
        const SHOW = RESERVATION_FILTER;
        const hasPassed = isPast(event);

        const classNames = [
          className,
          hasPassed ? 'has_passed' : '',
          showInviteBar ? 'has_invite_bar' : ''
        ];

        return (
            <div className={classNames.join(' ').trim()}>
                <header>
                    <div className="counts__container">
                        <div className="counts">
                            <p>{copy.manage.you_have}</p>
                            <p className={isRed(guestsPending)}>{stringInsert(copy.manage.x_pending, guestsPending)}</p>{', '}
                            <p className={isRed(guestsAccepted)}>{stringInsert(copy.manage.x_attending, guestsAccepted)}</p>{', '}
                            <p className={isRed(invitationsPending)}>{stringInsert(copy.manage.x_invited, invitationsPending)}</p>{', '}
                            <p className={isRed(reservationsRejected + invitationsDeclined)}>{stringInsert(copy.manage.x_declined, reservationsRejected + invitationsDeclined)}</p>{' and '}
                            <p className={isRed(reservationsCanceled)}>{stringInsert(copy.manage.x_denied, reservationsCanceled)}</p>
                            {copy.manage.guests}
                        </div>
                        { event.potluckItems.totalCount > 0 ?
                            <div className="potluck-counts">
                                <EventPotluck event={event}/>
                            </div>
                        :
                            null
                        }
                    </div>
                    <div className="filter-container">
                        <FAIcon name={[ 'fal', 'search' ]} />
                        <input type="text" value={filter} onChange={this.handleFilter}/>
                    </div>
                    <div className="show-container" >
                        <strong>{copy.event_details.show_by}</strong>
                        <SelectBox
                            options={this.getListShow()}
                            onOptionSelect={value => this.handleShow(value)}
                            defaultValue={this.getDefaultFilterValue()}
                        />
                    </div>
                </header>
                {eventHosts.edges.length > 1 && (
                  <ManageEventsDetailsCohosts
                    eventId={eventId}
                    cohosts={eventHosts.edges}
                    refetchEvent={refetchEvent}
                  />
                )}
                <ManageEventsDetailsList
                  enumerated={SHOW.ATTENDING}
                  hasList={event.reservations.guestsAccepted > 0}
                  list={reservations.accepted.map(r => this.getItem(r))}
                  listState="accepted"
                  pagination={pagination.reservation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  refetchEvent={refetchEvent}
                  fetchMoreLoading={fetchMoreLoading.reservation.accepted}
                  loading={loading.reservation.accepted}
                />
                <ManageEventsDetailsList
                  enumerated={SHOW.PENDING}
                  hasList={event.reservations.guestsPending > 0}
                  list={reservations.pending.map(r => this.getItem(r))}
                  listState="pending"
                  pagination={pagination.reservation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  fetchMoreLoading={fetchMoreLoading.reservation.pending}
                  loading={loading.reservation.pending}
                />
                <ManageEventsDetailsList
                  enumerated={SHOW.RESERVATION_DECLINED}
                  hasList={event.reservations.reservationsRejected > 0}
                  list={reservations.rejected.map(r => this.getItem(r))}
                  listState="rejected"
                  pagination={pagination.reservation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  fetchMoreLoading={fetchMoreLoading.reservation.rejected}
                  loading={loading.reservation.rejected}
                />
                <ManageEventsDetailsList
                  enumerated={SHOW.CANCELED}
                  hasList={event.canceledReservations.totalCount > 0}
                  list={reservations.canceled.map(r => this.getItem(r))}
                  listState="canceled"
                  pagination={pagination.reservation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  fetchMoreLoading={fetchMoreLoading.reservation.canceled}
                  loading={loading.reservation.canceled}
                />
                <ManageEventsDetailsList
                  enumerated={SHOW.INVITED}
                  hasList={event.invitations.invitationsPending > 0}
                  list={invitations.pending.map(i => this.getItem(i))}
                  listState="pending"
                  pagination={pagination.invitation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  fetchMoreLoading={fetchMoreLoading.invitation.pending}
                  loading={loading.invitation.pending}
                />
                <ManageEventsDetailsList
                  enumerated={SHOW.INVITATION_DECLINED}
                  hasList={event.invitations.invitationsDeclined > 0}
                  list={invitations.rejected.map(i => this.getItem(i))}
                  listState="rejected"
                  pagination={pagination.invitation}
                  show={show}
                  filter={filter}
                  event={event}
                  refetch={refetch}
                  fetchMoreLoading={fetchMoreLoading.invitation.rejected}
                  loading={loading.invitation.rejected}
                />
            </div>
        );
    }
}

const StyledManageEventsDetailsListBox = styled(ManageEventsDetailsListBox)`
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: ${props => props.settings.theme.css.global.card.shadow};

    &.has_invite_bar {
      margin-top: 30px;
    }

    &.has_passed {
      margin-top: 0;
    }

    header {
        display: flex;

        @media (${breakpoints.manageEventDetailsTablet}) {
          display: block;
          text-align: center;
        }

        > * {
            flex: 1;
        }
        .counts {
            // display: flex;
            // flex-wrap: wrap;
            // white-space: pre;
            font-size: 13px;
            line-height: 1.3;
            margin-bottom: 15px;

            @media (${breakpoints.manageEventDetailsTablet}) {
              text-align: center;
            }

            &__container {
              @media (${breakpoints.manageEventDetailsTablet}) {
                display: block;
              }
            }

            p {
              display: inline;
              margin-bottom: 0px;
            }
            .red {
                font-weight: bold;
                color: ${props => props.settings.theme.css.global.colors.primary};
            }
        }
        .potluck-counts {
          margin-top: -5px;

            @media (${breakpoints.manageEventDetailsTablet}) {
              text-align: center;
              margin-bottom: 15px;
            }
        }
        .filter-container {
            padding-left: 30px;
            padding-right: 30px;
            position: relative;

            @media (${breakpoints.manageEventDetailsTablet}) {
              padding: 0;
              display: inline-block;
              vertical-align: top;
              padding-top: 24px;
              text-align: left;
              margin-right: 10px;

              svg {
                top: auto !important;
                bottom: 24px;
                right: 12px !important;
              }
            }

            @media (max-width: 790px) {
              width: 100%;
              margin-right: 0;
              padding: 0;

              input {
                margin-bottom: 5px;
              }

              svg {
                bottom: 15px;
              }
            }

            svg {
              position: absolute;
              z-index: 1;
              color: #ccc;
              top: 10px;
              right: 42px;
              font-size: 18px;
            }

            input {
              padding-right: 35px;
            }
        }
        .show-container {
            padding-left: 10px;
            width: 180px;

            @media (${breakpoints.manageEventDetailsTablet}) {
              width: 197px;
              padding: 0;
              display: inline-block;
              vertical-align: top;
              text-align: left;
            }

            @media (max-width: 790px) {
              width: 100%;
            }

            display: flex;
            strong {
                margin-right: 10px;
                padding-top: 7px;
                font-size: 14px;
            }
            & > div {
                flex: 1;
                ul {
                    top: 42px !important;
                }
            }

            .dropdown_menu {
              top: 38px !important;
            }
        }
    }
`;

const StyledManageEventsDetailsListBoxWithSettings = withSettings(StyledManageEventsDetailsListBox);

export { StyledManageEventsDetailsListBoxWithSettings as ManageEventsDetailsListBox };
