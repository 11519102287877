import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  InviteFriendsByEmail,
  SocialShareEvent,
  DonationLink
} from '../../components';

import { isNourishable } from '../../../libs';

import { withSettings } from '../../components';

import Html from 'html-to-react';

const htmlParser = new Html.Parser();


const EventCreationSuccessModal = (props) => {
  const { className, show, toggle, event, areas, onInviteFriendsSuccess, viewer } = props;
  const { settings: { copy, theme } } = props;

  const getSubTitle = () => {
    if (!event) return null;

    switch (event.eventType) {
      case 'PUBLIC':
        return copy.success.subtitle_public;
      case 'MANUAL_APPROVAL':
        return copy.success.subtitle_manual;
      case 'PRIVATE':
        return copy.success.subtitle_private;
      case 'SOLO':
        return copy.success.subtitle_solo;
      default:
        return null;
    }
  };

  const getPostSuccessMessage = () => {
    const eventArea = event ? event.area : null;
    if (!eventArea) {
      return null;
    }
    
    const area = areas.find(a => a.id === eventArea.id);
    if (!area) {
      return null;
    }

    const sponsorshipAlreadySelected = (event.sponsorships.length > 0);
    const eventIsNourishable = isNourishable(event, areas, viewer);
    const areaHasSponsorsAvailable = (area.sponsors && area.sponsors.length > 0);

    if (sponsorshipAlreadySelected) {
      return area.eventPostSuccessMessageWithNourishment;
    }

    if (!sponsorshipAlreadySelected && !eventIsNourishable) {
      return area.eventPostSuccessMessageWithoutSponsors;
    }

    if (!sponsorshipAlreadySelected && eventIsNourishable && areaHasSponsorsAvailable) {
      return area.eventPostSuccessMessageWithoutNourishment;
    }

    if (!sponsorshipAlreadySelected && eventIsNourishable && !areaHasSponsorsAvailable) {
      return area.eventPostSuccessMessageWithoutSponsors;
    }
  };

  return (
    <Modal
      show={show}
      toggle={toggle}
      noPadding
    >
      <div className={className}>
        <div className="modal_text">
          <h2>{copy.success.title}</h2>
          <p className="subtitle">{getSubTitle()}</p>
        </div>

        {theme.display_create_donation && (
          <DonationLink
            text={copy.create.donation_text}
            url={copy.create.donation_button_url}
            buttonText={copy.create.donation_button_text}
          />
        )}

        {getPostSuccessMessage() && (
          <div className="success_message">
            {event && htmlParser.parse(getPostSuccessMessage())}
          </div>
        )}

        {event && event.eventType !== 'SOLO' && (
          <>
            <i className="divider"/>
            <InviteFriendsByEmail
              className="invite_friends"
              eventId={event && event.id}
              onSuccess={onInviteFriendsSuccess}
            />
            {event && <SocialShareEvent event={event}/>}
          </>)
        }
      </div>
    </Modal>
  );
};

const StyledEventCreationSuccessModal = styled(EventCreationSuccessModal)`
  padding: 40px 30px 30px;
  text-align: center;

  h2 {
    font-size: 18px;
    margin: 0 0 30px;
  }

  .subtitle {
    margin: 0;
    font-size: 13px;
  }

  .success_message {
    margin: 20px 0 0;
    font-size: 13px;
  }

  .invite_friends {
    margin-bottom: 30px;
  }

  .modal_text {
    border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
`;

const StyledEventCreationSuccessModalWithSettings = withSettings(StyledEventCreationSuccessModal);

export { StyledEventCreationSuccessModalWithSettings as EventCreationSuccessModal };
