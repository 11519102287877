import { useContext } from 'react';
import { SettingsContext } from '../SettingsContext';

export const useSettings = () => {
  const { settings } = useContext(SettingsContext);
  return {
    settings,
    ...settings,
    colors: settings.theme.css.global.colors,
    images: settings.theme.assets.images
  };
};
