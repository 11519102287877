import React from 'react';
import styled from 'styled-components';

import { withSettings } from '../components';

import { stringInsert } from '../../libs';


const EventSearchUpcomingCount = ({ count, settings: { copy }, archive }) => (
  archive
    ? <Span>{stringInsert(copy.search.archive_event_count || '%1$s archived dinners', count)}</Span>
    : <Span>{stringInsert(copy.search.event_count || '%1$s upcoming dinners', count)}</Span>
);

const Span = styled.span`
  color: #83817b;
  font-size: 14px;
  display: inline-block;
`;


const EventSearchUpcomingCountWithSettings = withSettings(EventSearchUpcomingCount);
export { EventSearchUpcomingCountWithSettings as EventSearchUpcomingCount };
