export const getImageUrlFromPhotoObject = (photoObject, preferableSize, defaultImageUrl) => {
  if (!photoObject) { return defaultImageUrl }

  const preferableSizeKey = `${preferableSize}Url`;

  if (photoObject[preferableSizeKey]) {
    return photoObject[preferableSizeKey];
  } else if (photoObject.originalUrl) {
    return photoObject.originalUrl;
  } else {
    return defaultImageUrl;
  }
}