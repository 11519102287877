import styled, { css } from 'styled-components';
import { withSettings } from '../components';

const Hotdogs = styled.span`
  position: absolute;
  right: ${props => props.side === 'left' ? 'auto' : '30px'};
  left: ${props => props.side === 'left' ? '60px' : 'auto'};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${props => props.theme.isVersion(2) ? (
    css`
      width: 40px;
      height: 17px;
      border-top: 2px solid ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primary};
      border-bottom: 2px solid ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primary};
      margin-top: 2px;
    `
  ) : (
    css`
      width: 30px;
      height: 20px;
      border-top: 2px solid ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primaryDark};
      border-bottom: 2px solid ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primaryDark};
    `
  )}

  ${props => props.color === 'white' && (
    css`
      opacity: 0.6;
      transition: opacity 250ms;
  `)}

  ${props => props.side === 'left' ? (
    css`
      display: block;

      /* @media (max-width: ${props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
        left: 40px;
      } */

      /* @media (max-width: ${props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {
        left: 30px;
      } */

      // switch to normal layout for mobile devices //
      @media (max-width: 700px) {
        left: auto;
        right: 30px;
      }
  `) : (
    css`
      display: none;
  `)}

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    margin-top: -1px;

    ${props => props.theme.isVersion(2) ? (
      css`
        background-color: ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primary};
      `
    ) : (
      css`
        background-color: ${props.color === 'white' ? '#fff' : props.settings.theme.css.global.colors.primaryDark};
      `
    )}
  }

  &:hover {
    ${props => props.color === 'white' ? (
      css`
        opacity: 1;
    `) : (
      css`
        border-color: ${props.settings.theme.css.global.colors[props.theme.isVersion(2) ? 'primaryHover' : 'primary']};

        &::after {
          background-color: ${props.settings.theme.css.global.colors[props.theme.isVersion(2) ? 'primaryHover' : 'primary']};
        }
    `)}
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {
    display: block;
  }
`;

const HotdogsWithSettings = withSettings(Hotdogs);

export { HotdogsWithSettings as Hotdogs };
