import styled, { css } from 'styled-components';

export const PageSubheader = styled.h2`
  font-family: 'Avenir-Medium' !important;
  font-weight: 600;

  ${({ small }) => small ? css`
    font-size: 18px;
    margin: 0 0 20px;
  ` : css `
    font-size: 24px;
    margin: 0 0 30px;
  `}
`;
