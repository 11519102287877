import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../../components';
import { FAIcon } from '../../../components';
import {
  getScheduledAtDate,
  getEventTimeSpan,
  getLocationWithArea
} from '../../../../libs';
import stateLocationOptions from '../stateLocationOptions';

const EventCardLocation = ({ className, event }) => {
  let location = '';
  let stateLocation = null;
  if (event.stateLocation) {
    stateLocation = stateLocationOptions.find(s => s[0] === event.stateLocation);
    stateLocation = stateLocation ? stateLocation[1] : null;
  }

  location = (event.areaOther || event.stateLocation) ?
    (event.areaOther || '') + (stateLocation ? (event.areaOther ? ', ' : '') + stateLocation : '')
    : getLocationWithArea(event);

  return (
  <div className={className}>
    <div><FAIcon name={['far', 'calendar-alt']}/> {getScheduledAtDate(event)}</div>
    <div><FAIcon name={['far', 'clock']}/> {getEventTimeSpan(event)}</div>
    <div><FAIcon name={['fas', 'globe-americas']}/> {event.timezone}</div>
    {false && <div><FAIcon name={['fas', 'map-marker-alt']}/> {location}</div>}
    {event.area && <div><FAIcon name={['fas', 'users']}/> {event.area.label}</div>}
    {event.eventSubtype && <div><FAIcon name={['fas', 'users']}/> {event.eventSubtype.name}</div>}
  </div>
  );
};

const StyledEventCardLocation = styled(EventCardLocation)`
  font-size: 12px;
  color: ${props => props.settings.theme.css.global.colors.heading};

  svg {
    color: #bbb;
    margin-right: 3px;
    font-size: 13px;

    &.fa-globe-americas {
      margin-right: 5px;
    }
  }

  & > div {
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledEventCardLocationWithSettings = withSettings(StyledEventCardLocation);

export { StyledEventCardLocationWithSettings as EventCardLocation };
