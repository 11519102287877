import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Card, withSettings } from '../components';

import { breakpoints } from '../../styles';


const EventViewLoader = ({ className }) => (
  <Card className={className} noPadding>
    <div className="cover_photo" />
    <div className="header_details">
      <span className="hosted_by" />
      <div className="avatar" />
      <span className="title" />
      <span className="location one" />
      <span className="location two" />
      <span className="button" />
    </div>
  </Card>
);


const load = keyframes`
  0% {
    opacity: 1;
    right: 100%;
  }
  40% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 0;
  }
`;

const StyledEventViewLoader = styled(EventViewLoader)`
  min-height: 480px;
  opacity: 0.7;
  box-shadow: ${props => props.settings.theme.css.global.card.shadowLight} !important;

  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .cover_photo {
    left: 0;
    right: 50%;
  }

  .header_details {
    right: 0;
    left: 50%;
    padding: 30px;
    text-align: center;
    background-color: #f6f6f6;

    .avatar {
      width: 44px;
      height: 44px;
      display: inline-block;
      margin: 30px 0 50px;
      border-radius: 50% !important;
      overflow: hidden !important;
      position: relative;
    }

    span {
      position: relative;
      display: block;
      height: 12px;
      margin: 0 auto;

      &.hosted_by {
        width: 120px;
      }

      &.title {
        height: 23px;
        width: 240px;
        margin-bottom: 40px;
      }

      &.location {
        margin-bottom: 17px;

        &.one {
          width: 70%;
        }
        &.two {
          width: 50%;
        }
      }

      &.button {
        margin-top: 60px;
        height: 40px;
        width: 160px;
      }
    }
  }

  .cover_photo,
  .avatar,
  .header_details span {
    background-color: #f0f0f0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background-color: #ececec;
      animation: ${load} 1.4s infinite ease-in-out;
    }
  }

  @media (${breakpoints.tablet}) {
    .cover_photo {
      position: relative;
      width: 100%;
      padding-top: 65%;
      max-height: 420px;
      overflow: hidden;
    }
    .header_details {
      position: relative;
      width: 100%;
      right: 0;
      left: 0;
    }
  }

  @media (${breakpoints.mobile}) {
    .cover_photo {
      padding-top: 80%;
    }
  }
`;

const EventViewLoaderWithSettings = withSettings(StyledEventViewLoader);

export { EventViewLoaderWithSettings as EventViewLoader };
