import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  EVENT_TYPE
} from '../../enums';

import {
  EventLocation,
  EventNourishment,
  withSettings
} from '../components';

import { breakpoints } from '../../styles';


const ManageEventsDetailsCard = ({ className, event, settings, showArea }) => {
  const { LABEL } = EVENT_TYPE.FIELDS;
  const { copy } = settings;
  const images = settings.theme.assets.images;
  const shouldShowSponsorships = settings.features.sponsorships;

  return (
    <div className={className}>
      <Link to={`/events/${event.uuid}`}>
        <img src={event.cover ? event.cover.originalUrl : images.default_cover} alt={event.title} />
      </Link>
      <div className="middle">
        {settings && settings.features && settings.features.event_creation_questions && (
          <Link to={`/events/${event.uuid}/resources`}>
            {copy.manage_event.dinner_resources_link}
          </Link>
        )}
        <p>
          {copy.manage_event.event_type_is_before}
          <strong>{EVENT_TYPE.getField(LABEL, event.eventType)} {event.virtual ? ' - Virtual' : ''}</strong>
        </p>
        <div className="location-container">
          <EventLocation event={event} showArea={showArea} showCatered={event.catered} />
        </div>
      </div>
      {shouldShowSponsorships && (
        <div className="bottom">
          <span>
            <strong>Nourishment</strong>
          </span>
          <EventNourishment event={event} />
        </div>
      )}
    </div>
  );
};


const StyledManageEventsDetailsCard = styled(ManageEventsDetailsCard)`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  max-width: 280px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 50px;
  width: 30%;
  align-self: flex-start;

  @media (${breakpoints.manageEventDetailsTablet}) {
    max-width: 100%;
    width: 35%;
  }

  @media (${breakpoints.tabletSmall}) {
    width: 100%;
    margin-bottom: 0;
  }

  img {
    width: 100%;
    margin-bottom: 15px;
  }
  .middle {
    margin-top: 10px;

    & > * {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-size: 13px;
    }
    .location-container {
      margin-bottom: 25px;

      span {
        justify-content: center;
      }
    }
  }
  .bottom {
    background-color: ${props => props.settings.theme.css.global.colors.backgroundDark};
    padding: 20px 15px 15px;
    border-top: 1px solid #e5e5e5;
    text-align: center;

    & > div {
      margin-top: 8px;
    }
  }
`;

const ManageEventsDetailsCardWithSettings = withSettings(StyledManageEventsDetailsCard);

export { ManageEventsDetailsCardWithSettings as ManageEventsDetailsCard };
