const SOLO_ON_CONFIG = {
  hideSeats: true,
  numberOfGuestsMin: 0,
  numberOfGuestsMax: 0,
  hideRSVP: true,
  rsvpDeadline: false,
  rsvpDeadlineDate: null,
  rsvpDeadlineDateValid: true,
  rsvpDeadlineTime: null,
  rsvpDeadlineTimeValid: true,
  hideCohost: true,
  cohosts: [],
  cohostValid: true,
  virtualHide: true,
  virtual: false,
  virtualConfirmation: true,
  hideCost: true,
  paid: false,
  price: null,
  priceValid: true,
  hideNonVirtualConfirmationCheckbox: true,
  hideLocation: true,
  hideExtraDetails: true,
  hideAlcoholType: true,
  hideDietType: true,
  hideDressCode: true,
  hideMeal: true,
  hideGuestQuestions: true,
  minDescriptionLength: 50,
  tags: ['solo'],
  privacySubtype: null
};
const SOLO_OFF_CONFIG = {
  hideSeats: false,
  hideRSVP: false,
  hideCohost: false,
  hideVirtual: false,
  hideCost: false,
  hideNonVirtualConfirmationCheckbox: false,
  hideLocation: false,
  hideExtraDetails: false,
  hideAlcoholType: false,
  hideDietType: false,
  hideDressCode: false,
  hideMeal: false,
  hideGuestQuestions: false,
  minDescriptionLength: 150
};

const VIRTUAL_ON_CONFIG = {
  fullAddress: '',
  secondaryDress: '',
  fullDress: null,
  parkingLots: '',
  accessible: '',
  alcoholPolicy: null,
  dietaryRestrictions: null,
  notes_first: [],
  notes_second: [],
  notes_third: [],
  potluck: false,
  potluckItems: [],
  virtualConfirmation: false
};
const VIRTUAL_OFF_CONFIG = {
  virtualLocation: '',
  virtualResourceUrl: ''
};

const DATE_CHANGE_CONFIG = {
  rsvpDeadlineDate: null,
  rsvpDeadlineTime: null
};

const RSVP_DEADLINE_ON_CONFIG = {
  rsvpDeadlineDateValid: false,
  rsvpDeadlineTimeValid: false
};
const RSVP_DEADLINE_OFF_CONFIG = {
  rsvpDeadlineDate: null,
  rsvpDeadlineTime: null,
  rsvpDeadlineDateValid: true,
  rsvpDeadlineTimeValid: true
};

const RSVP_DEADLINE_DATE_CHANGE_CONFIG = {
  rsvpDeadlineTime: null
};

const PAID_ON_CONFIG = {
  priceValid: true,
  explainPaymentValid: true
};
const PAID_OFF_CONFIG = {
  priceValid: false,
  explainPaymentValid: false,
  price: null,
  explainPayment: null,
  hostAbsorbsTransactionFee: false,
  pwyw: false
};

const PAY_WHAT_YOU_WANT_ON_CONFIG = {
  priceValid: false
};
const PAY_WHAT_YOU_WANT_OFF_CONFIG = {
  priceValid: true,
  price: null
};

export const getForcedFieldFor = (field, value) => {
  switch (field) {
    case 'virtual':
      return value ? VIRTUAL_ON_CONFIG : VIRTUAL_OFF_CONFIG;

    case 'rsvpDeadline':
      return value ? RSVP_DEADLINE_ON_CONFIG : RSVP_DEADLINE_OFF_CONFIG;

    case 'paid':
      return value ? PAID_ON_CONFIG : PAID_OFF_CONFIG;

    case 'pwyw':
      return value ? PAY_WHAT_YOU_WANT_ON_CONFIG : PAY_WHAT_YOU_WANT_OFF_CONFIG;

    case 'date':
      return DATE_CHANGE_CONFIG;

    case 'rsvpDeadlineDate':
      return RSVP_DEADLINE_DATE_CHANGE_CONFIG;

    default:
      return {};
  }
};

export default {
  SOLO: {
    enabled: SOLO_ON_CONFIG,
    disabled: SOLO_OFF_CONFIG
  },

  virtual: {
    enabled: VIRTUAL_ON_CONFIG,
    disabled: VIRTUAL_OFF_CONFIG
  },

  date: {
    changed: DATE_CHANGE_CONFIG
  },

  RSVPDeadline: {
    enabled: RSVP_DEADLINE_ON_CONFIG,
    disabled: RSVP_DEADLINE_OFF_CONFIG
  },

  RSVPDeadlineDate: {
    changed: RSVP_DEADLINE_DATE_CHANGE_CONFIG
  },

  paid: {
    enabled: PAID_ON_CONFIG,
    disabled: PAID_OFF_CONFIG
  }
};
