import React from 'react';
import styled from 'styled-components';

const WPLoader = ({ className }) => <img className={className} src="https://onetable.org/wp-content/uploads/2019/08/loading.gif" alt="loading" />;

const StyledWPLoader = styled(WPLoader)`
  width: 35px;
  height: 35px;
`;

export { StyledWPLoader as WPLoader };
