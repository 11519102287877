import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ManageEventsDetailsFilter } from './ManageEventsDetailsFilter';
import { Button, FAIcon, RestrictionModal, RestrictionModalStatus, withSettings, withSession } from '../components';
import { SessionContext } from '../../Context';
import { usePageScroll } from '../../hooks';
import {
  EVENT_FILTER,
  USER_STATE
} from '../../enums';
import { NOT_SCROLLED_NAV_HEIGHT, SCROLLED_NAV_HEIGHT } from '../../styles/index';
import { breakpoints } from '../../styles';

const ManageEventsDetailsFilters = props => {
  const { className, counts, event, settings, history } = props;
  const { copy, event: { require_publish_approval } } = settings;
  const { HOSTED, PENDING_PUBLISH_APPROVAL, DRAFT, PAST, RESERVED, ATTENDED } = EVENT_FILTER;
  const requireApproval = !!require_publish_approval;

  const [isOpen, setIsOpen] = useState(false);
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);

  const handleMobileToggle = () => setIsOpen(prev => !prev);
  const handleRestrictionModalToggle = () => setShowRestrictionModal(prev => !prev);

  const handleCreateEventClick = restriction => {
    restriction
      ? setShowRestrictionModal(true)
      : history.push('/events');
  };

  const { scrolled } = usePageScroll();
  const { session } = useContext(SessionContext);
  const user = session.viewer ? session.viewer.user : null;
  const isHost = user && user.state === USER_STATE.HOST;

  const restriction = user && RestrictionModalStatus(copy, session, event, settings, 'creation');

  const classNames = [
    className,
    ...(isOpen ? ['open'] : []),
    ...(scrolled ? ['scrolled'] : [])
  ];

  return (
    <div className={classNames.join(' ')}>
      <div className="container">
        <div className="filters">
          <ManageEventsDetailsFilter filter={HOSTED} count={counts[HOSTED]}/>
          {requireApproval && (
            <ManageEventsDetailsFilter filter={PENDING_PUBLISH_APPROVAL} count={counts[PENDING_PUBLISH_APPROVAL]}/>
          )}
          <ManageEventsDetailsFilter filter={DRAFT} count={counts[DRAFT]}/>
          <ManageEventsDetailsFilter filter={PAST} count={counts[PAST]}/>
          <ManageEventsDetailsFilter filter={RESERVED} count={counts[RESERVED]}/>
          <ManageEventsDetailsFilter filter={ATTENDED} count={counts[ATTENDED]}/>
        </div>
        {isHost && (
          <div className="actions">
            <Button handleClick={() => handleCreateEventClick(restriction)}>{copy.manage.create}</Button>
          </div>
        )}
        <RestrictionModal
          show={showRestrictionModal}
          toggle={handleRestrictionModalToggle}
          content={restriction}
          onClose={() => history.push('/events')}
        />
        <div
          className="mobile_toggle"
          onClick={handleMobileToggle}
        >
          <FAIcon name={[ 'far', 'chevron-down' ]} />
        </div>
      </div>
    </div>
  );
};

const StyledManageEventsDetailsFilters = styled(ManageEventsDetailsFilters)`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 900;
  background-color: white;
  width: 100%;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};

  ${({ session: { theme, layout } }) => theme.isVersion(2) ? css`
    top: ${NOT_SCROLLED_NAV_HEIGHT}px;
    transition: top 300ms;
    &.scrolled {
      top: ${SCROLLED_NAV_HEIGHT}px;
    }
    @media (max-height: 840px) {
      top: ${SCROLLED_NAV_HEIGHT}px;
    }
  ` : css`
    top: ${layout.removeHeaderFooter ? '0' : '85px'};
  `}

  .container {
    max-width: 1192px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;

    @media (${breakpoints.tabletSmall}) {
      display: block;
    }

    .filters {
      display: flex;

      @media (${breakpoints.tabletSmall}) {
        display: block;
        padding-top: 60px;
        text-align: center;
        height: 60px;
      }
    }

    .actions {
      align-self: center;

      @media (${breakpoints.tabletSmall}) {
        position: absolute;
        top: 11px;
        right: 20px;
      }
    }

    .mobile_toggle {
      display: none;
      position: absolute;
      top: 18px;
      left: 30px;
      font-size: 16px;
      transition: transform 150ms;
      transform-origin: middle;
      cursor: pointer;

      @media (${breakpoints.tabletSmall}) {
        display: block;
      }
    }
  }

  &.open {
    .filters {
      height: auto;
      padding-bottom: 10px;
    }
    .mobile_toggle {
      transform: rotate(180deg);
    }
  }
`;

const ManageEventsDetailsFiltersWithSettings = withSettings(StyledManageEventsDetailsFilters);
const ManageEventsDetailsFiltersWithSession = withSession(ManageEventsDetailsFiltersWithSettings);
const ManageEventsDetailsFiltersWithRouter = withRouter(ManageEventsDetailsFiltersWithSession);
export { ManageEventsDetailsFiltersWithRouter as ManageEventsDetailsFilters }
