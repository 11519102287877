import React from 'react';
import styled, { css } from 'styled-components';

import { Logo, Closer, withSettings } from '../components';
import { isSite } from '../../libs';


const DefaultMainMenu = ({ className, classList, toggleMobileNav, menuItems, }) => (
  <div className={[className, ...classList].join(' ')}>
    <Logo className="mobile" link="/" />
    <ul className="main_menu" onClick={toggleMobileNav}>{menuItems}</ul>
    <Closer onClick={toggleMobileNav} />
  </div>
);


const StyledDefaultMainMenu = styled(DefaultMainMenu)`
  position: absolute;
  left: 250px;
  top: 50%;
  transform: translateY(-50%);

  .main_menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;

      .dropdown_link,
      .restricted_link,
      .mobile_nav_link,
      & > a {
        padding: 8px 15px;
        font-size: 16px;
        color: ${({ colors }) => colors.navbarLinkColor || '#333'};
        transition: color 250ms;
        font-weight: 600;
        display: block;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: ${({ colors }) => colors.primary};
        }
      }

      .fa-caret-down {
        display: none;
      }
    }
  }

  .dropdown_menu {
    li {
      &.hide_on_desktop {
        display: none;
      }
    }
  }

  .logo.mobile {
    display: none;
  }

  &.is_host {
    li.hide-when-host {
      display: none;
    }
  }

  @media (max-width: ${({ settings }) => settings.theme.css.breakpoints.nav.tablet || 1300}px) {
    left: 200px;

    .main_menu {
      & > li {
        .dropdown_link,
        .restricted_link,
        .mobile_nav_link,
        & > a {
          padding: 8px 10px;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: ${({ settings }) => settings.theme.css.breakpoints.nav.mobile || 1075}px) {
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 320px;
    transform: translateX(100%);
    background-color: #fff;
    padding: 60px 8px 40px;
    z-index: 100000;
    box-shadow: 0px 1px 19px 0px rgba(0,0,0,0.05);
    overflow: auto;
    transition: transform 350ms;

    &.open {
      transform: translateX(0);
    }

    .logo.mobile {
      display: block;
      width: 170px;
      height: 24px;
      margin: 10px auto 40px;

      ${({ settings }) => isSite(settings, 'hillel') && (
        css`
          height: 95px !important;
      `)}
    }

    .main_menu {
      & > li {
        display: block;
        padding: 5px 0;

        .dropdown_link,
        .restricted_link,
        .mobile_nav_link,
        & > a {
          display: block;
          text-align: center;
        }

        .fa-caret-down {
          display: block;
          position: absolute;
          right: 20px;
          top: 10px;
          transition: transform 250ms;
        }

        .dropdown_menu_wrapper {
          position: relative;
        }
      }
    }

    .display {
      & > .dropdown_menu {
        display: block !important;
      }

      .fa-caret-down {
        transform: rotate(180deg);
      }
    }

    .dropdown_menu {
      position: static !important;
      box-shadow: none !important;
      max-height: 100% !important;
      border: none !important;
      padding: 0 !important;
      text-align: center;
      width: 100%;
      margin-top: 5px;
      background-color: #f6f6f6 !important;

      a {
        &:hover {
          background-color: transparent !important;
          color: ${({ settings }) => settings.theme.css.global.colors.primary};
        }
      }

      ul {
        max-height: 100% !important;
      }

      li {
        &.hide_on_desktop {
          display: block;
        }
      }
    }
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`;

const DefaultMainMenuWithSettings = withSettings(StyledDefaultMainMenu);
export { DefaultMainMenuWithSettings as DefaultMainMenu };
