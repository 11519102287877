import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media, isJourneyMobile as isMobile } from '../../styles';
import { withSettings } from '../components';


const JourneyHoverOptions = ({
  className,
  options,
  value,
  onChange,
  goToNextPage,
  pageAction,
  hasAnswered, // has hovered selection //
  hasConfirmed, // has confirmed (clicked) selection //
  data: { playlistUrl, quoteText }
}) => {
  const [chosen, setChosen] = useState(false);

  useEffect(() => {
    if (hasConfirmed) {
      // delay setting 'fixed' on iframe on mobile until after page transition //
      if (isMobile()) setTimeout(() => setChosen(true), 1500);
      else setChosen(true);
    }
  }, [hasConfirmed]);

  // const [chosenMobile, setChosenMobile] = useState(false);

  const chooseOption = (val, label) => {
    if (isMobile()) {
      onChange(val, label, true);
    } else {
      // setChosen(true);
      goToNextPage(label);
    }
  };

  const handleHoverOption = (val, option) => {
    !hasConfirmed && !isMobile() && onChange(val, option.label, true);
  };

  return (
    <div className={className}>
      {options.map((option, i) => {
        const optionValue = option.value ? option.value.string : '';
        const isSelected = value === optionValue;

        const classList = ['hover_option', pageAction];
        isSelected && classList.push('selected');

        return (
          <div key={i}>
            <h1
              className={classList.join(' ')}
              onMouseEnter={() => !hasConfirmed && handleHoverOption(optionValue, option.label)}
              onClick={() => hasAnswered && chooseOption(optionValue, option.label)}
            >
              <span className="spacer">{option.label}</span>
              <span className="visible">{option.label}</span>
            </h1>
            {isSelected && playlistUrl && (
              <iframe
                className={['playlist', chosen ? 'fixed' : ''].join(' ').trim()}
                src={playlistUrl}
                width="300"
                height="80"
                frameBorder="0"
                allow="encrypted-media"
                title={playlistUrl}
              />
            )}
            {isSelected && quoteText && (
              <span
                className="selected_quote"
                dangerouslySetInnerHTML={{ __html: quoteText }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const StyledJourneyHoverOptions = styled(JourneyHoverOptions)`
  margin-top: 30px;

  & > div {
    position: relative;
  }

  h1 {
    margin-bottom: 25px;
    display: inline-block;
  }

  .hover_option {
    position: relative;
    cursor: pointer;

    span {
      color: transparent;
      -webkit-text-stroke: 1px #fff;
      font-family: 'Avenir-Bold' !important;
      letter-spacing: -0.025em;
      line-height: 1;
      text-transform: uppercase;
      display: block;
      padding: 0;
      margin: 0;
      transition: transform 250ms, color 250ms;
      cursor: pointer;
      white-space: nowrap;

      ${media.journeyMd`
        // -webkit-text-stroke: 1px transparent;
        // color: #ffffff70;
      `}

      ${media.sm`
        // text-transform: none;
      `}

      &.spacer {
        visibility: hidden;
        position: relative;
        z-index: -1;
      }

      &.visible {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // transform: translateY(-50%);
        // -webkit-backface-visibility: hidden !important;
        backface-visibility: hidden !important;
        // -webkit-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0) !important;
        transform: translateZ(0) scale(1.0, 1.0) !important;
        transform-origin: left;

        ${media.journeyMd`
          top: 0;
          // transform: translateY(0) translateZ(0) scale(1.0, 1.0) !important;
        `}
      }
    }

    &.music {
      span {
        font-size: 65px;

        ${media.journeyLg`
          font-size: 50px;
        `}

        ${media.journeyMd`
          font-size: 40px;
        `}

        ${media.journeySm`
          font-size: 32px;
        `}

        ${media.sm`
          font-size: 27px;
        `}
      }
    }

    &.image {
      span {
        // font-size: 75px;

        // ${media.journeyLg`
        //   font-size: 55px;
        // `}
        //
        // ${media.journeyMd`
        //   font-size: 40px;
        // `}
        //
        // ${media.journeySm`
        //   font-size: 32px;
        // `}
        //
        // ${media.sm`
        //   font-size: 27px;
        // `}

        font-size: 65px;

        ${media.journeyLg`
          font-size: 50px;
        `}

        ${media.journeyMd`
          font-size: 40px;
        `}

        ${media.journeySm`
          font-size: 32px;
        `}

        ${media.sm`
          font-size: 27px;
        `}
      }
    }

    &.quote {
      font-size: 60px;

      span {
        ${media.journeyLg`
          font-size: 50px;
        `}

        ${media.journeyMd`
          font-size: 40px;
        `}

        ${media.journeySm`
          font-size: 32px;
        `}

        ${media.sm`
          font-size: 27px;
        `}
      }
    }

    &.selected {
      span {
        &.visible {
          color: #fff !important;
          transform: translateZ(0) scale(1.07, 1.07) !important;
        }
      }

      // &.music {
      //   span {
      //     &.visible {
      //       font-size: 68px;
      //
      //       ${media.journeyLg`
      //         font-size: 53px;
      //       `}
      //
      //       ${media.journeyMd`
      //         font-size: 43px;
      //       `}
      //
      //       ${media.journeySm`
      //         font-size: 35px;
      //       `}
      //
      //       ${media.sm`
      //         font-size: 30px;
      //       `}
      //     }
      //   }
      // }
      //
      // &.image {
      //   span {
      //     &.visible {
      //       // font-size: 78px;
      //       //
      //       // ${media.journeyLg`
      //       //   font-size: 58px;
      //       // `}
      //       //
      //       // ${media.journeyMd`
      //       //   font-size: 44px;
      //       // `}
      //       //
      //       // ${media.journeySm`
      //       //   font-size: 35px;
      //       // `}
      //       //
      //       // ${media.sm`
      //       //   font-size: 30px;
      //       // `}
      //
      //       font-size: 68px;
      //
      //       ${media.journeyLg`
      //         font-size: 53px;
      //       `}
      //
      //       ${media.journeyMd`
      //         font-size: 43px;
      //       `}
      //
      //       ${media.journeySm`
      //         font-size: 35px;
      //       `}
      //
      //       ${media.sm`
      //         font-size: 30px;
      //       `}
      //     }
      //   }
      // }
      //
      // &.quote {
      //   span {
      //     &.visible {
      //       font-size: 63px;
      //
      //       ${media.journeyLg`
      //         font-size: 53px;
      //       `}
      //
      //       ${media.journeyMd`
      //         font-size: 43px;
      //       `}
      //
      //       ${media.journeySm`
      //         font-size: 35px;
      //       `}
      //
      //       ${media.sm`
      //         font-size: 30px;
      //       `}
      //     }
      //   }
      // }
    }
  }

  iframe {
    position: absolute;
    right: 0;
    top: -10px;

    &.fixed {
      position: fixed;
      top: 150px;
      right: 60px;
      width: 80px;
      z-index: 10000;

      ${media.journeyMd`
        // right: -1000px;
        // margin-top: -40px;
        display: none !important;
      `}
    }

    ${media.md`
      width: 80px;
    `}

    ${media.journeyMd`
      position: relative;
      top: auto;
      width: 300px;
      margin: -15px auto 25px;
    `}
  }

  .selected_quote {
    display: none;
    opacity: 1;
    margin-top: 10px;

    ${media.journeyMd`
      display: block;
      margin: -10px 0 25px;
    `}

    p {
      color: #fff;
      margin: 0;

      &.quote {
        font-family: 'IM FELL English PRO Italic', serif !important;
        letter-spacing: -0.025em;
        font-size: 18px;
        line-height: 1.2;
        font-weight: normal;
        font-style: italic;
        text-transform: none;
      }

      &.cite {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 11px;
        color: #fff;
        display: block;
        margin-top: 10px;
      }
    }
  }
`;
const JourneyHoverOptionsWithSettings = withSettings(StyledJourneyHoverOptions);
export { JourneyHoverOptionsWithSettings as JourneyHoverOptions };
