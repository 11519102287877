import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';

const EventViewMealPotluck = (props) => {
  const { className, event } = props;
  const { settings: { copy } } = props;
  const potluckItems = event && event.potluckItems && event.potluckItems.edges ? event.potluckItems.edges.map(edge => edge.node) : [];

  return (
    <div className={className}>
      <p>{copy.event.potluck_view_caption}</p>
      <ul className="potluck_items">
        {potluckItems.map(item => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
}

const StyledEventViewMealPotluck = styled(EventViewMealPotluck)`
  & > p {
    font-size: 14px;
    text-align: center;
    margin: 0 0 20px;
  }

  .potluck_items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    & > li {
      display: inline-block;
      background-color: ${props => props.settings.theme.css.global.colors.primaryLight};
      border: 1px solid ${props => props.settings.theme.css.global.colors.primaryLightBorder};
      border-radius: 3px;
      margin: 10px 5px 0;
      font-size: 13px;
      // font-weight: 700;
      padding: 11px 17px 10px;
      color: ${props => props.settings.theme.css.global.colors.primaryDark};
    }
  }
`;

const StyledEventViewMealPotluckWithSettings = withSettings(StyledEventViewMealPotluck);

export { StyledEventViewMealPotluckWithSettings as EventViewMealPotluck };
