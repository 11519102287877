import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { TweenMax, Power3 } from 'gsap';

const JourneyPageBgCarousel = ({ className, images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const currentImage = useRef(null);
  const nextImage = useRef(null);
  const loaded = useRef(false);
  const interval = useRef(null);
  const getNext = val => val < images.length - 1 ? val + 1 : 0;
  const getPrev = useCallback(val => val > 0 ? val - 1 : images.length - 1, [images]);

  const rotate = () => {
    setImageIndex(index => getNext(index));
  };

  useEffect(() => {
    setTimeout(() => {
      interval.current = setInterval(rotate, 4500);
    }, 2000);

    return () => {
      clearInterval(interval.current);
    };
  // eslint complaining about empty dependancy array :( //
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const current = images[getPrev(imageIndex)];
    const next = images[imageIndex];
    const currentRef = currentImage.current;
    const nextRef = nextImage.current;


    if (!loaded.current) {
      currentRef.style.backgroundImage = `url(${next.url})`;
      loaded.current = true;
    } else {
      currentRef.style.cssText = `
        background-image: url(${current.url});
        opacity: 1;
      `;
      nextRef.style.cssText = `
        background-image: url(${next.url});
        opacity: 0;
      `;

      TweenMax.to(currentImage.current, .7, {
        opacity: 0,
        ease: Power3.easeIn,
      });

      TweenMax.to(nextImage.current, .7, {
        opacity: 1,
        delay: .1,
        ease: Power3.easeIn,
      });
    }
  }, [images, imageIndex, getPrev]);

  return (
    <div className={className}>
      <div ref={currentImage} className="current" />
      <div ref={nextImage} className="next" />
    </div>
  )
};

const StyledJourneyPageBgCarousel = styled(JourneyPageBgCarousel)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;

    &.current {
      z-index: 2;
    }

    &.next {
      z-index: 1;
    }
  }
`;
export { StyledJourneyPageBgCarousel as JourneyPageBgCarousel };
