import { breakpoints } from './';

// const getFormStyle = theme => `
const getFormStyle = () => `
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
.rw-widget-picker,
.wysiwyg-editor {
  display: block;
  width: 100%;
  margin: 0 0 14px;
  padding: 7px 12px 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  color: #000;
  transition: border-color 250ms, box-shadow 250ms;
}

.wysiwyg-editor {
  &.invalid {
    margin-bottom: 0;
  }
}

.public-DraftEditor-content span {
  color: #000;
  font-size: 14px;
}

.public-DraftEditorPlaceholder-inner {
  font-size: 14px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus,
.rw-datetime-picker.rw-state-focus .rw-widget-picker,
.wysiwyg-editor.focused {
  border-color: #8a8a8a !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15) !important;
}

button {
  cursor: pointer;
}

.ot_form {
  width: 100%;
}

.ot_form .form_submit_error,
.ot_form .form_submit_error span {
  font-size: 11px;
  color: #8d1b37;
  text-align: center;
  margin-bottom: 15px;
}

.ot_form .form_submit_sucess,
.ot_form .form_submit_success span {
  font-size: 11px;
  color: green;
  text-align: center;
  margin-bottom: 15px;
}

.ot_form .half_width::after {
  content: '';
  display: block;
  clear: both;
}

.ot_form .half_width > div {
  width: calc(50% - 7px);
  float: left;
}

.ot_form .half_width > div:first-of-type {
  margin-right: 14px;
}

.ot_form .form_group::after {
  content: '';
  display: block;
  clear: both;
}

.ot_form .form_group .one_quarter,
.ot_form .form_group .one_third,
.ot_form .form_group .two_thirds,
.ot_form .form_group .one_half {
  float: left;
  padding: 0 7px;
}

.ot_form .form_group .one_quarter {
  width: 25%;
}

.ot_form .form_group .one_third {
  width: 33.3333%;
}

.ot_form .form_group .two_thirds {
  width: 66.6666%;
}

.ot_form .form_group .one_half {
  width: 50%;
}

.ot_form .form_group .one_quarter:first-child,
.ot_form .form_group .one_third:first-child,
.ot_form .form_group .two_thirds:first-child,
.ot_form .form_group .one_half:first-child {
  padding-left: 0;
}

.ot_form .form_group .one_quarter:last-child,
.ot_form .form_group .one_third:last-child,
.ot_form .form_group .two_thirds:last-child,
.ot_form .form_group .one_half:last-child {
  padding-right: 0;
}

.ot_form .form_group label:not(.radio_label) {
  font-weight: 600;
  font-size: 14px;
  padding-top: 8px;
  margin: 0;
}

.clear-float::after, .float_clear::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  clear: both;
}

.form-control[disabled], [disabled_element], fieldset[disabled] .btn, fieldset[disabled] .form-control, input[readonly] {
  background-color: #f4f4f4!important;
  border-color: #ccc!important;
  box-shadow: none;
  color: #999!important;
  opacity: 1!important;
  font-weight: 400!important;
}

.ot_form .form_group label.has_info {
  width: 100%;
}

.ot_form .form_group label.has_info::after {
  content: '';
  display: block;
  clear: both;
}

.ot_form .form_group label.has_info span.info {
  float: right;
  font-size: 11px;
  color: #8c8c8c;
  font-weight: normal;
  padding-top: 2px;
}

label.with_tooltip .info_tooltip {
  margin-left: 5px;
}

label.with_tooltip .info_tooltip .ot_tooltip {
  text-align: center;
  font-weight: normal;
  line-height: 1.4;
}

.ot_form .form_group .toggle_buttons {
  padding-bottom: 14px;
}

.ot_form .form_group.table {
  display: table;
}

.ot_form .form_group.table > div {
  display: table-cell;
  height: 100%;
  float: none;
  padding-bottom: 38px;
  position: relative;
  vertical-align: top;
}

.ot_form .form_group.table > div.no_padding {
  padding-bottom: 0;
}

.ot_form .form_group.table > div > div {
  position: absolute;
  left: 7px;
  right: 7px;
  bottom: 0;
  margin-bottom: 0;
}

.ot_form .form_group.table > div > div.invalid {
  bottom: -16px;
}

.ot_form .form_group.table > div > div.error_bottom {
  bottom: -30px;
}

.ot_form .form_group.table > div > div input[type="number"] {
  margin-bottom: 0;
}

.ot_form .form_group.table > div:first-child > div {
  left: 0;
}

.ot_form .form_group.table > div:last-child > div {
  right: 0;
}

@media (${breakpoints.tablet}) {
  .form_group.break_on_tablet .one_third,
  .form_group.break_on_tablet .two_thirds,
  .form_group.break_on_tablet .one_half {
    width: 100%;
    padding: 0;
  }
}

@media (${breakpoints.tabletSmall}) {
  .form_group.break_on_tablet_small .one_third,
  .form_group.break_on_tablet_small .two_thirds,
  .form_group.break_on_tablet_small .one_half {
    width: 100%;
    padding: 0;
  }
}

@media (${breakpoints.mobile}) {
  .form_group.break_on_mobile .one_third,
  .form_group.break_on_mobile .two_thirds,
  .form_group.break_on_mobile .one_half {
    width: 100%;
    padding: 0;
  }

  .ot_form .form_group.table > div {
    display: block;
    width: 100%;
  }

  .ot_form .form_group.table > div > div {
    left: 0;
    right: 0;
  }

  // .ot_form .form_group.table > div > div > div {
  //   margin-bottom: 14px;
  // }
  //
  // .ot_form .form_group.table > div > div.invalid > div {
  //   margin-bottom: 7px;
  // }
}
`;

export default getFormStyle;
