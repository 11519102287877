import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../components';
import { withSettings } from '../../components';

const EventTypeIcon = props => {
  const { className, type, name } = props;
  const { settings: { types } } = props;

  const getIconLabel = () => {
    if (type && type === 'dress_codes') return name;
    return types[type][name];
  };

  const getIconName = () => {
    if (type && type === 'dress_codes') {
      switch (name) {
        case 'PJ\'s':
          return 'PJS';
        case 'Casual (Jeans OK)':
          return 'CASUAL';
        case 'Dressy (No Jeans)':
          return 'DRESSY';
        case 'Black Tie':
          return 'BLACK_TIE';
        case 'Themed':
          return 'THEMED';
        default:
          return null;
      }
    }
    return name;
  };

  return (
    <div className={`${className} type_icon`}>
      <Icon name={getIconName()} />
      <span>{getIconLabel()}</span>
    </div>
  );
}

const StyledEventTypeIcon = styled(EventTypeIcon)`
  display: inline-block;
  vertical-align: top;
  width: 70px;
  margin: 3px 5px 0;

  img {
    display: block;
    margin: 0 auto 8px;
    width: 40px;
    height: 40px;
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
`;

const StyledEventTypeIconWithSettings = withSettings(StyledEventTypeIcon);

export { StyledEventTypeIconWithSettings as EventTypeIcon };
