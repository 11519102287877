import React from 'react';
import styled from 'styled-components';

import { Sponsor, SponsorsListByArea } from '../components';

export const NATIONAL_SPONSORS_ID = 'national_sponsors';


export const SponsorsList = ({ sponsors, sponsorAreas }) => (
  <SponsorsListUl>
    {sponsors.DONATION.length > 0 && (
      <SponsorsListCategory sponsors={sponsors.DONATION} title="OneTable" />
    )}
    {sponsors.NATIONALSPONSORS.length > 0 && (
      <>
        <hr className="separator" />
        <SponsorsListCategory sponsors={sponsors.NATIONALSPONSORS} title="National" id={NATIONAL_SPONSORS_ID} />
      </>
    )}
    {sponsors.BOOKS.length > 0 && (
      <>
        <hr className="separator" />
        <SponsorsListCategory sponsors={sponsors.BOOKS} title="Book" />
      </>
    )}
    {sponsors.BY_AREA && (
      <>
        <hr className="separator" />
        <SponsorsListCategory sponsors={sponsors.BY_AREA} sponsorAreas={sponsorAreas} title="Local" />
      </>
    )}
  </SponsorsListUl>
);

const SponsorsListCategory = ({ title, sponsors, sponsorAreas, id = '' }) => (
  <li id={id}>
    <SponsorsListTitle>{title}</SponsorsListTitle>
    {Array.isArray(sponsors) ? (
      <SponsorsListCategoryUl>
        {sponsors.map(s => <Sponsor key={s.id} sponsor={s} />)}
      </SponsorsListCategoryUl>
    ) : (
      <SponsorsListByArea sponsors={sponsors} sponsorAreas={sponsorAreas} />
    )}
  </li>
);

export const SponsorsListUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

// const SponsorsListLi = styled.ul`
//
// `;

const SponsorsListTitle = styled.h3`
  font-size: 20px;
`;

export const SponsorsListCategoryUl = styled.ul`
  list-style-type: none;
  margin: 40px 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
`;
