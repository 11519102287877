class Enumerated {
    static getField = function (field, enumerated) {
        // console.log('getField', field, enumerated);
        return this.INFO[enumerated][field];
    }

    static getEnumByField = function (field, value) {
        for (const key in this.INFO) {
            if (this.INFO[key][field] === value) {
                return this[key];
            }
        }
    }
}

export { Enumerated };
