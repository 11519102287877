import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSession } from '../../hooks';
import { BackHelper, Alert, Notification, HamburgerNav, Navbar } from '../components';

export const Header = () => {
  const { theme, layout } = useSession();
  const { pathname } = useLocation();

  return (
    <>
      <Alert/>
       {
         !layout.removeHeaderFooter && !layout.hideHeaderFooter(pathname) ? (
            <>
                {theme.isVersion(2) ? <HamburgerNav /> : <Navbar />}
                <Notification/>
            </>
         ) : (
          <>
            {!layout.hideHeaderFooter(pathname) && <BackHelper />}
            <style>{'body { padding-top: 0 !important; }'}</style>
          </>
         )
       }
    </>
  )
};