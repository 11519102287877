import React from 'react';
import styled, { css } from 'styled-components';
import { DropDownMenu, Button, withSession } from '../components';

class RsvpButtonDumb extends React.Component {

  render() {
    const {
      className,
      disabled,
      buttonStyle,
      mainButtonName,
      mainButtonHandler,
      showMenu,
      menuOptions,
      fullWidth = true,
      themed,
      session: { theme }
    } = this.props;

    const menu = (!menuOptions || menuOptions.length === 0) ? null : (
      <DropDownMenu toggle={mainButtonHandler} backdrop>
        {menuOptions.map(menuOption => {
          return (
            <li key={menuOption.label} onClick={menuOption.handler}>
              <span>{menuOption.label}</span>
            </li>
          );
        })}
      </DropDownMenu>
    );

    return (
      <div className={className}>
        <Button
          fullWidth={fullWidth}
          themed={themed}
          disabled={disabled}
          buttonStyle={buttonStyle}
          handleClick={mainButtonHandler}
          capitalizeWords={!theme.isVersion(2)}
        >
          {mainButtonName}
        </Button>
        {showMenu ? menu : null}
      </div>
    );
  }
}

const StyledRsvpButtonDumb = styled(RsvpButtonDumb)`
  width: 100%;
  margin: 0 auto;
  position: relative;

  ${({ session: { theme } }) => !theme.isVersion(2) && css`
    max-width: 250px;
  `}
`;

const RsvpButtonDumbWithSession = withSession(StyledRsvpButtonDumb);
export { RsvpButtonDumbWithSession as RsvpButtonDumb };
