import React from 'react';
import { Button } from './';

export const PrimaryButton = props => (
  <Button
    {...props}
    handleClick={props.onClick || props.handleClick}
    buttonStyle="primary"
    small={props.small}
    themed
  />
);
