import React from 'react';
import { ApolloConsumer } from '@apollo/react-common';

const withClient = Component => props => (
  <ApolloConsumer>
    {(client) => (
      <Component {...props} client={client} />
    )}
  </ApolloConsumer>
);

export { withClient };
