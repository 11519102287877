import React, { useState } from 'react';
import styled from 'styled-components';

import {
  SearchFilterValue,
  DatePicker,
  FAIcon,
  Underlay,
  withSettings
} from '../../components';

import moment from 'moment-timezone';


const DatesSearchFilter = ({ className, dates, onSearch, settings, onSelectDates }) => {
  const [selectedDate, setSelectedDate] = useState(dates && dates.length ? dates[0] : null);
  const [showPicker, setShowPicker] = useState(null);

  const { event: { date_restrictions, date_exceptions } } = settings;

  const handleDateSelect = (value) => {
    setShowPicker(prev => !prev);
    onSelectDates(value);
    setSelectedDate(value);
  };

  return (
    <div className={className}>
      <div onClick={() => setShowPicker(true)}>
        {!dates || !dates.length ? (
          <SearchFilterValue notSelected withButton>Add dates</SearchFilterValue>
        ) : (
          <SearchFilterValue withButton>
            {dates && dates.map(date => moment(date).format('ddd MMM Do')).join(', ')}
          </SearchFilterValue>
        )}
      </div>
      {showPicker && <Underlay onClick={() => setShowPicker(false)} />}
      <DatePicker
        name="date"
        value={selectedDate}
        open={showPicker}
        onDateChange={handleDateSelect}
        dayRestrictions={date_restrictions}
        dayExceptions={date_exceptions}
        style={{ margin: 0 }}
        stopPropagation
        noInput
      />
      <SearchButton onClick={onSearch}><FAIcon name={['far', 'search']} /></SearchButton>
    </div>
  );
};


const SearchButtonWithoutSettings = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 5px

  &:hover {
    .fa-search {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }
`;
const SearchButton = withSettings(SearchButtonWithoutSettings);

const StyledDatesSearchFilter = styled(DatesSearchFilter)`
  .fa-search {
    font-size: 20px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    transition: color 250ms;
    cursor: pointer;
  }

  .dropdown_menu {
    width: 120%;
  }
`;

const DatesSearchFilterWithSettings = withSettings(StyledDatesSearchFilter);
export { DatesSearchFilterWithSettings as DatesSearchFilter };
