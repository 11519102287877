import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  PageSubheader as Header,
  PrimaryButton as Button
} from '../components';

export const ReservationCanceledModal = ({ show, toggle, title, content }) => (
  <Modal
    show={show}
    toggle={toggle}
    size="md"
  >
    <ModalContainer>
      <Header>{title}</Header>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <Button onClick={toggle}>Close</Button>
    </ModalContainer>
  </Modal>
);

const ModalContainer = styled.div`
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
  }
`;
