import styled, { css } from 'styled-components';

export const PageContentWrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  position: relative;
  padding-right: 60px;
  padding-left: 60px;

  ${({ fullHeight }) => fullHeight && css`
    min-height: 100%;
  `}

  @media (max-width: 700px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
