import React from 'react';
import styled from 'styled-components';

import { FAIcon, Spinner, withSettings } from '../components';

const PaginationButtons = ({ className, hasPrevPage, hasNextPage, goToPrevPage, goToNextPage, loading }) => (
  <div className={`${className} pagination`}>
    {hasPrevPage && (
      <span onClick={goToPrevPage} className="prev button">
        <FAIcon name={['fas', 'chevron-left']}/> Prev
      </span>
    )}
    {hasNextPage && (
      <span onClick={goToNextPage} className={`next button ${loading ? 'loading' : ''}`}>
        Next <FAIcon name={['fas', 'chevron-right']}/>
    </span>
    )}
    {loading && <Spinner/>}
  </div>
);

const StyledPaginationButtons = styled(PaginationButtons)`
  .button {
    color: ${props => props.settings.theme.css.global.colors.primary};
    width: auto;
    display: inline-block;
    padding: 10px 15px;
    font-size: 13.5px;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    border-radius: 3px;
    transition: color 250ms;
    background-color: #fff;

    &.prev {
      margin-right: 6px;

      svg {
        margin: 0 3px 0 0 !important;
      }
    }
    &.next {
      margin-right: 12px;

      svg {
        margin: 0 0 0 3px !important;
      }
    }

    svg {
      display: inline-block;
      // vertical-align: middle;
    }

    &:hover,
    &.loading {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }

  .spinner {
    padding-top: 5px;
  }
`;
const PaginationButtonsWithSettings = withSettings(StyledPaginationButtons);
export { PaginationButtonsWithSettings as PaginationButtons };
