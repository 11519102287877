import gql from 'graphql-tag';
import { singleEventFragment, reservationsFragment, invitationsFragment, journeyResponseFragment } from './optimized';

export const MANAGE_EVENTS = gql`
  query ViewerEvents ($filter: ViewerEventFilter!, $first: Int, $after: String){
    viewer {
      events(filter: $filter, first: $first, after: $after) {
        edges {
          cursor
          node {
            __typename
            id
            uuid
            title
            timezone
            timezoneOther
            state
            cover {
              mediumUrl
              originalUrl
            }
            scheduledAt
            duration
            area {
              id
              code
              label
              neighborhoods {
                code
                label
                subneighborhoods {
                  code
                  label
                }
              }
            }
            neighborhood
            campus {
              id
              label
              subneighborhoods {
                code
                label
              }
            }
            subneighborhood
            fullAddress
            eventType
            eventSubtype {
              id
              name
            }
            eventSubtypeOther
            numberOfGuestsMax
            reservations {
              totalCount
              guestsAccepted
              guestsPending
              reservationsAccepted
            }
            reservationsAccepted: reservations(filterByState: ACCEPTED, first: 2) {
              reservationsAccepted
              nodes {
                id
                profile {
                  id
                  firstName
                  lastName
                  avatar {
                    id
                    photo {
                      largeUrl
                    }
                  }
                }
              }
            }
            reservationsPending: reservations(filterByState: PENDING) {
                reservationsPending
                nodes {
                    id
                    profile {
                        id
                        firstName
                        lastName
                        avatar {
                            id
                            photo {
                                largeUrl
                            }
                        }
                    }
                }
            }
            pricePerPerson
            nourishable
            nourishmentUnavailableExplanation
            nourishmentRestrictionExceptionExpiresAt
            sponsorships {
              id
              amount
              sponsor {
                id
                name
              }
            }
            sponsorshipDeadline
            eventHosts(creator: true) {
              edges {
                node {
                  id
                  creator
                  effectiveEmail
                  profile {
                    id
                    firstName
                    lastName
                    avatar {
                      id
                      photo {
                        smallUrl
                        mediumUrl
                        largeUrl
                        originalUrl
                      }
                    }
                  }
                }
              }
            }
            expectedGuests
            virtual
            catered
            potluck
            potluckItems {
              totalCount
              edges {
                node {
                  id
                  name
                  quantity
                  claimedQuantity
                  freeQuantity
                  position
                  claims {
                    id
                    quantity
                    reservation {
                      id
                      profile {
                        id
                        firstName
                        lastName
                        avatar {
                          id
                          photo {
                            originalUrl
                            smallUrl
                            mediumUrl
                            largeUrl
                            originalUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;


export const MANAGE_EVENT_BY_UUID = gql`
  ${singleEventFragment}
  ${journeyResponseFragment}
  query eventByUuid($uuid: String!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      ...SingleEvent
      reservations {
        guestsAccepted
        guestsPending
        guestsRejected
        guestsTotal
        reservationsAccepted
        reservationsPending
        reservationsRejected
        reservationsTotal
      }
      canceledReservations: reservations(filterByState: CANCELED) {
        totalCount
      }
      invitations {
        invitationsAccepted
        invitationsPending
        invitationsDeclined
      }
      ...JourneyResponse
    }
  }
`;

export const MANAGE_EVENT_RESERVATIONS_PENDING = gql`
  ${reservationsFragment}
  query pendingReservations($uuid: String!, $after: String, $isHost: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      pendingRes: reservations(filterByState: PENDING, first: 10, after: $after) {
        ...Reservations
      }
    }
  }
`;

export const MANAGE_EVENT_RESERVATIONS_ACCEPTED = gql`
  ${reservationsFragment}
  query acceptedReservations($uuid: String!, $after: String, $isHost: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      acceptedRes: reservations(filterByState: ACCEPTED, first: 10, after: $after) {
        ...Reservations
      }
    }
  }
`;

export const MANAGE_EVENT_RESERVATIONS_CANCELED = gql`
  ${reservationsFragment}
  query canceledReservations($uuid: String!, $after: String, $isHost: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      canceledRes: reservations(filterByState: CANCELED, first: 10, after: $after) {
        ...Reservations
      }
    }
  }
`;

export const MANAGE_EVENT_RESERVATIONS_REJECTED = gql`
  ${reservationsFragment}
  query rejectedReservations($uuid: String!, $after: String, $isHost: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      rejectedRes: reservations(filterByState: REJECTED, first: 10, after: $after) {
        ...Reservations
      }
    }
  }
`;


export const MANAGE_EVENT_INVITATIONS_PENDING = gql`
  ${invitationsFragment}
  query pendingInvitations($uuid: String!, $after: String) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      pendingInv: invitations(filterByStatus: PENDING, first: 10, after: $after) {
        ...Invitations
      }
    }
  }
`;

export const MANAGE_EVENT_INVITATIONS_DECLINED = gql`
  ${invitationsFragment}
  query rejectedInvitations($uuid: String!, $after: String) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      rejectedInv: invitations(filterByStatus: REJECTED, first: 20, after: $after) {
        ...Invitations
      }
    }
  }
`;
