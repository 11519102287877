import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSettings } from '../../hooks';


export const ProfileSettingsNav = ({ view, setView }) => {
  const [tabs, setTabs] = useState([]);
  const { features: { paid_events }, colors } = useSettings();

  useEffect(() => {
    const viewTabs = [{ name: 'My Profile', value: 'profile' }];
    if (paid_events) viewTabs.push({ name: 'Payments Methods', value: 'payment' });
    viewTabs.push({ name: 'Membership', value: 'membership' });
    setTabs(viewTabs);
  }, []);

  return (
    <div>
      <Ul>
        {tabs.map(tab => (
          <Li
            key={tab.value}
            active={tab.value === view}
            colors={colors}
            onClick={() => setView(tab.value)}
          >{tab.name}</Li>
        ))}
      </Ul>
    </div>
  );
};

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  font-size: 17px;
  font-family: 'Avenir-Medium';
  cursor: pointer;
  transition: color 250ms;

  ${({ colors: { primary } }) => css`
    &:hover {
      color: ${primary};
    }
  `}

  ${({ active, colors: { primary } }) => active && css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: ${primary};
    }
  `}
`;
