import React from 'react';
import styled from 'styled-components';

import { Modal, Logo, Button, withSettings } from '../components';


const SubmitWarningModal = ({ className, show, toggle, action, onContinue, settings }) => {
  const { copy } = settings;

  const handleContinue = () => {
    onContinue();
    toggle();
  };

  const getMessage = () => {
    switch (action) {
      case 'numberOfGuestsMax_decrease':
        return copy.create.nourishment_decrease_warning || 'Looks like your eligible amount for nourishment has changed. Please return to manage your event to select your nourishment.';
      case 'numberOfGuestsMax_increase':
        return copy.create.nourishment_increase_warning || 'Looks like your eligible amount for nourishment has changed. Please return to manage your event to select your nourishment.';
      default:
        return '';
    }
  };

  return (
    <Modal
      className="modal__submit_error"
      show={show}
      toggle={toggle}
      size="sm"
      backdrop="static"
      noPadding
    >
      <div className={`${className} vaccination__modal_content`}>
        <Logo layout="vertical" />
        <p dangerouslySetInnerHTML={{ __html: getMessage() }} />

        <div className="modal__submit_error__buttons">
          <Button
            className="button__cancel"
            handleClick={() => toggle()}
            buttonStyle="inverted"
          >Cancel</Button>
          <Button
            className="button__continue"
            handleClick={handleContinue}
          >Continue</Button>
        </div>
      </div>
    </Modal>
  );
};

const StyledSubmitWarningModal = styled(SubmitWarningModal)`
  padding: 30px;
  text-align: center;

  .modal__submit_error.modal-sm {
    @media (min-width: 992px) {
      max-width: 500px;
    }
  }

  .logo {
    max-width: 140px;
    margin: 0 auto 30px;
  }

  p {
    text-align: center;
    margin: 0;
    color: #424242;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .modal__submit_error__buttons {
    position: relative;
    padding-top: 10px;

    & > .inverted {
      border: 1px solid ${props => props.settings.theme.css.global.colors.primary};
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .button {
    &__cancel {
      float: left;
    }

    &__continue {
      float: right;
    }

    &__cancel,
    &__continue {
      width: calc(50% - 10px);
    }
  }
`;
const SubmitWarningModalWithSettings = withSettings(StyledSubmitWarningModal);
export { SubmitWarningModalWithSettings as SubmitWarningModal };
