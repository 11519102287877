import React from 'react';
import styled from 'styled-components';

import { Avatar, withSettings } from '../components';

import { EVENT_FILTER } from '../../enums';

import { stringInsert } from '../../libs';

const ManageEventsHeader = props => {
  const {
    className,
    viewer,
    active,
    totalCount,
    settings
  } = props;

  const { copy, theme: { site } } = settings;
  const managerLink = site.hub_managers_url;
  const activeFilter = active ? active.toLowerCase().split('_')[0] : ''; // pending_publish_approval => pending //

  const user = viewer && viewer.user;
  const profile = user && user.profile;
  const firstName = profile && profile.firstName;

  return (
    <div className={className}>
      <div className="principal">
        <Avatar size="lg" layout="vertical fullName" sessionProfile />
        <h3>Hello, {firstName}.</h3>
      </div>
      <hr/>
      <div className="secondary">
        <p
          dangerouslySetInnerHTML={{
            __html: stringInsert(
              copy.manage[`you_have_x_${activeFilter}_dinners`],
              typeof totalCount === 'undefined' ? '..' : totalCount
            )
          }}
        />
        {active === EVENT_FILTER.HOSTED && (
          <p>
            {copy.manage.please_email_to_change_nourishment}
            {managerLink && <span>&nbsp;<a href={managerLink}>{copy.manage.hub_manager_link_text}</a>.</span>}
          </p>
        )}
      </div>
    </div>
  );
};

const StyledManageEventsHeader = styled(ManageEventsHeader)`
  margin: 0 0 60px 0;
  padding-top: 50px;

  .principal {
    display: flex;
    justify-content: center;
    h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }

  hr {
    width: 50px
  }

  .secondary {
    font-size: 15px;
    text-align: center;
    margin: 25px 0 80px;

    p {
      margin-bottom: 0
    }
  }
`;

const ManageEventsHeaderWithSettings = withSettings(StyledManageEventsHeader);

export { ManageEventsHeaderWithSettings as ManageEventsHeader };