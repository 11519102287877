import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  MANAGE_EVENT_RESERVATIONS_PENDING,
  MANAGE_EVENT_RESERVATIONS_ACCEPTED,
  MANAGE_EVENT_RESERVATIONS_CANCELED,
  MANAGE_EVENT_RESERVATIONS_REJECTED
} from '../queries';

export const RESERVATIONS_PER_PAGE = 10;

export const RESERVATION_ACTIONS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  REJECTED: 'rejected'
};

export const usePaginatedReservations = (event, queryVars) => {
  const pendingReservations = useQuery(MANAGE_EVENT_RESERVATIONS_PENDING, queryVars);
  const acceptedReservations = useQuery(MANAGE_EVENT_RESERVATIONS_ACCEPTED, queryVars);
  const canceledReservations = useQuery(MANAGE_EVENT_RESERVATIONS_CANCELED, queryVars);
  const rejectedReservations = useQuery(MANAGE_EVENT_RESERVATIONS_REJECTED, queryVars);

  const [reservations, setReservations] = useState({
    pending: [],
    accepted: [],
    canceled: [],
    rejected: []
  });

  useEffect(() => {
    setReservations(prevState => ({
      pending: pendingReservations.data ? pendingReservations.data.event.pendingRes.edges : prevState.pending,
      accepted: acceptedReservations.data ? acceptedReservations.data.event.acceptedRes.edges : prevState.accepted,
      canceled: canceledReservations.data ? canceledReservations.data.event.canceledRes.edges : prevState.canceled,
      rejected: rejectedReservations.data ? rejectedReservations.data.event.rejectedRes.edges : prevState.rejected
    }));
  }, [pendingReservations, acceptedReservations, canceledReservations, rejectedReservations]);

  const [totalCounts, setTotalCounts] = useState({
    pending: 0,
    accepted: 0,
    canceled: 0,
    rejected: 0
  });

  useEffect(() => {
    setTotalCounts(prevState => ({
      pending: event ? event.reservations.reservationsPending : prevState.pending,
      accepted: event ? event.reservations.reservationsAccepted : prevState.accepted,
      canceled: event ? event.canceledReservations.totalCount : prevState.canceled,
      rejected: event ? event.reservations.reservationsRejected : prevState.rejected
    }));
  }, [event]);

  const [pageIndexes, setPageIndexes] = useState({
    pending: 0,
    accepted: 0,
    canceled: 0,
    rejected: 0
  });

  const [fetchMoreLoading, setFetchMoreLoading] = useState({
    pending: false,
    accepted: false,
    canceled: false,
    rejected: false
  });

  const updateLoading = (state, value) => {
    setFetchMoreLoading(prevLoading => ({
      ...prevLoading,
      [state]: value
    }))
  };

  const fetchMore = (state, fetch) => {
    const res = reservations[state];
    const lastRes = res && res[res.length - 1];
    const currentIndex = pageIndexes[state];

    if (lastRes) {
      updateLoading(state, true);

      fetch({
        variables: {
          uuid: queryVars.variables.uuid,
          isHost: true,
          after: lastRes.cursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          updateLoading(state, false);

          if (fetchMoreResult) {
            const newEdges = fetchMoreResult.event[`${state}Res`].edges;
            const prevEdges = prev.event[`${state}Res`].edges;

            fetchMoreResult.event[`${state}Res`].edges = [...prevEdges, ...newEdges];

            setPageIndexes(prevIndexes => ({
              ...prevIndexes,
              [state]: currentIndex + RESERVATIONS_PER_PAGE
            }));

            return fetchMoreResult;
          }
          return prev;
        },
      })
    }
  };

  const fetchReservations = {
    pending: pendingReservations ? () => fetchMore(RESERVATION_ACTIONS.PENDING, pendingReservations.fetchMore) : null,
    accepted: acceptedReservations ? () => fetchMore(RESERVATION_ACTIONS.ACCEPTED, acceptedReservations.fetchMore) : null,
    canceled: canceledReservations ? () => fetchMore(RESERVATION_ACTIONS.CANCELED, canceledReservations.fetchMore) : null,
    rejected: rejectedReservations ? () => fetchMore(RESERVATION_ACTIONS.REJECTED, rejectedReservations.fetchMore) : null,
  };

  const hasNextPage = state => {
    const currentIndex = pageIndexes[state];
    const totalCount = totalCounts[state];
    const currentReservations = reservations[state];
    const currentCount = Array.isArray(currentReservations) ? currentReservations.length : 0;
    return totalCount > currentCount || currentIndex + RESERVATIONS_PER_PAGE < totalCount;
  };

  const hasPrevPage = state => {
    const currentIndex = pageIndexes[state];
    return currentIndex > 0;
  };

  const handleGoToNextPage = state => {
    const res = reservations[state];
    const currentIndex = pageIndexes[state];
    const fetch = fetchReservations[state];
    const totalCount = totalCounts[state];

    if (fetchMoreLoading[state]) return;

    if (currentIndex + RESERVATIONS_PER_PAGE === res.length && res.length < totalCount) {
      fetch && fetch();
    } else {
      setPageIndexes(prevIndexes => ({
        ...prevIndexes,
        [state]: currentIndex + RESERVATIONS_PER_PAGE
      }));
    }
  };

  const handleGoToPrevPage = state => {
    const newIndex = pageIndexes[state] - RESERVATIONS_PER_PAGE;

    if (fetchMoreLoading[state]) return;

    setPageIndexes(prevIndexes => ({
      ...prevIndexes,
      [state]: newIndex >= 0 ? newIndex : 0
    }));
  };

  const refreshReservations = data => {
    const { id, action } = data;
    const reservation = reservations.pending.find(r => r.node.id === id);
    const pendingRes = reservations.pending.slice().filter(r => r.node.id !== id);
    const pendingTotal = totalCounts.pending - 1;
    const acceptedRes = reservations.accepted.slice();
    const rejectedRes = reservations.rejected.slice();
    let acceptedTotal = totalCounts.accepted;
    let rejectedTotal = totalCounts.rejected;

    switch (action) {
      case RESERVATION_ACTIONS.ACCEPTED:
        acceptedRes.unshift(reservation);
        acceptedTotal++;
        break;
      case RESERVATION_ACTIONS.REJECTED:
        rejectedRes.unshift(reservation);
        rejectedTotal++;
        break;
      default:
    }

    if (!pendingRes.length && pendingTotal > 0) {
      fetchReservations.pending();
    }

    setReservations({
      pending: pendingRes,
      accepted: acceptedRes,
      canceled: reservations.canceled,
      rejected: rejectedRes,
    });

    setTotalCounts({
      pending: pendingTotal,
      accepted: acceptedTotal,
      canceled: totalCounts.canceled,
      rejected: rejectedTotal
    });

    setPageIndexes({
      pending: 0,
      accepted: 0,
      canceled: 0,
      rejected: 0
    });
  };

  const getReservationPages = () => ({
    pending: reservations.pending.slice(pageIndexes.pending, pageIndexes.pending + RESERVATIONS_PER_PAGE),
    accepted: reservations.accepted.slice(pageIndexes.accepted, pageIndexes.accepted + RESERVATIONS_PER_PAGE),
    canceled: reservations.canceled.slice(pageIndexes.canceled, pageIndexes.canceled + RESERVATIONS_PER_PAGE),
    rejected: reservations.rejected.slice(pageIndexes.rejected, pageIndexes.rejected + RESERVATIONS_PER_PAGE)
  });

  return {
    reservations: getReservationPages(),
    pagination: {
      hasNextPage,
      hasPrevPage,
      goToNextPage: handleGoToNextPage,
      goToPrevPage: handleGoToPrevPage
    },
    loading: {
      pending: pendingReservations.loading,
      accepted: acceptedReservations.loading,
      canceled: canceledReservations.loading,
      rejected: rejectedReservations.loading
    },
    fetchMoreLoading,
    refreshReservations
  };
};
