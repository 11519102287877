import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useSettings, useSession } from '../../hooks';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_STRIPE_CHECKOUT_SESSION } from '../../mutations';
import { localStorage } from '../../libs';

import {
  Modal,
  PageSubheader as Header,
  MembershipSelectionOptions,
  Checkbox,
  PrimaryButton as Button,
  Input,
  InfoTooltip
} from '../components';


export const MembershipSelectionModal = ({
  show,
  toggle,
  eventId,
  getField,
  setField,
  saveDataToStorage,
  onExemptFromMembership
}) => {
  const [loading, setLoading] = useState(null);
  const { copy } = useSettings();
  const { theme } = useSession();
  const membership = getField('membership');
  const selection = getField('priceSelection');
  const inHonor = getField('inHonor');
  const inHonorOf = getField('inHonorOf');

  const [createStripeCheckoutSession] = useMutation(CREATE_STRIPE_CHECKOUT_SESSION);

  const handleGoToCheckout = async () => {
    if (!selection || !eventId) return;
    setLoading(true);

    const variables = { eventId, stripePriceId: selection.stripeId };
    if (inHonor && inHonorOf) variables.customMetadata1 = inHonorOf;

    const { data: { createStripeCheckoutSession: result } } = await createStripeCheckoutSession({ variables });

    if (result && result.redirectUrl) {
      saveDataToStorage && saveDataToStorage();

      localStorage.store({ membershipPaymentAmount: selection.unitAmount }, 'reservation_data');
      window.dataLayer?.push({
        event: 'rsvp_modal_membership_selection',
        rsvp_modal_selected_membership: membership.name,
        rsvp_modal_selected_membership_amount: selection.unitAmount
      });

      window.location.href = result.redirectUrl;
    }
    else setLoading(false);
  };

  const handleClickFreeMembership = async () => {
    setLoading(true);
    window.dataLayer?.push({
      event: 'rsvp_modal_membership_selection',
      rsvp_modal_selected_membership: 'free',
      rsvp_modal_selected_membership_amount: 0
    });
    await onExemptFromMembership();
    setLoading(false);
  };

  return (
    <Modal show={show} toggle={() => !loading && toggle()} size="md">
      <ModalContainer theme={theme}>
        <Header small={!theme.isVersion(2)}>{membership ? membership.header : (copy.membership?.selection_modal_title || 'Become a member')}</Header>
        {membership?.content ? (
          <p dangerouslySetInnerHTML={{ __html: membership.content }} />
        ) : (
          <p>{copy.membership?.selection_modal_copy || 'Joining this dinner requires a donation-based membership. Select what level you would like to donate:'}</p>
        )}
        <OptionsContainer isLoading={loading}>
          <MembershipSelectionOptions
            membership={membership}
            selection={selection}
            onSelectPrice={(p) => setField('priceSelection', p)}
          />
          <FreeButton onClick={handleClickFreeMembership}>
            {copy.membership?.request_free || 'Or request free membership'}
            <InfoTooltip
              content={copy.membership?.free_membership_tooltip || 'Can\'t swing it? We get it. The most important thing is getting you to a table.'}
              position="top"
            />
          </FreeButton>
          <CheckboxContainer>
            <Checkbox
              name="inHonor"
              options={[
                {
                  label: copy.membership?.in_honor_checkbox || 'I\'d like to make my membership in honor of a loved one',
                  checked: inHonor,
                },
              ]}
              onInputChange={(n, v) => setField('inHonor', v)}
              small
            />
            {inHonor && (
              <Input
                name="inHonorOf"
                value={getField('inHonorOf')}
                placeholder={copy.membership?.in_honor_placeholder || 'In honor of...'}
                onInputChange={(n, v) => setField('inHonorOf', v)}
              />
            )}
          </CheckboxContainer>
        </OptionsContainer>
        <Button
          onClick={handleGoToCheckout}
          disabled={!selection || loading}
          loading={loading}
        >Continue</Button>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  h2 {
    margin-bottom: 20px;
  }

  .info_tooltip {
    margin-left: 8px;
  }

  ${({ theme }) => !theme.isVersion(2) && css`
    text-align: center;

    p {
      font-size: 14px;
    }
  `}
`;

const CheckboxContainer = styled.div`
  position: relative;
  margin: 20px 0;

  .checkbox__container {
    position: relative;
  }

  label {
    padding-left: 25px !important;
  }

  input {
    &[type="checkbox"] {
      top: 6px;
    }

    &[type="text"] {
      max-width: 370px;
      margin: 5px auto 0 !important;
    }
  }
`;

const FreeButton = styled.span`
  display: inline-block;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    z-index: 5;
    display: none;

    ${({ isLoading }) => isLoading && css`
      display: block;
    `}
  }
`;
