import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { SessionContext } from '../../Context';
import {
  UserJourneyNav,
  EventCreationSuccessModal,
  JourneyNode,
  JourneyResourcesPage,
  Loader,
  alert
} from '../components';

import { useQuery } from '@apollo/react-hooks';
import { EVENT_CREATION_RESOURCES, UJ } from '../../queries';

import { getEventIdFromPath, getTemplate, imHost } from '../../libs';

import * as userJourney from '../../libs/userJourney';


const EventCreationResourcesPage = ({ className, location, computedMatch }) => {
  const { session } = useContext(SessionContext);
  const { viewer } = session;

  const uuid = (computedMatch && computedMatch.params && computedMatch.params.uuid) || getEventIdFromPath(location.pathname);
  const variables = { uuid };

  const { data: eventData, loading: eventLoading } = useQuery(EVENT_CREATION_RESOURCES, { variables, errorPolicy: 'all' });
  const { data: journeyData, loading: journeyLoading } = useQuery(UJ, { fetchPolicy: 'no-cache' });

  const resources = eventData && userJourney.buildResourcesFromEvent(eventData);
  const hosts = eventData && eventData.eventByUuid && eventData.eventByUuid.eventHosts.edges;

  const journey = journeyData ? journeyData.journeys.nodes.find(n => n.id === 2) : null;
  const pageData = journey && journey.journeyPages.nodes.find(n => n.renderTemplate.toLowerCase() === 'resources');
  const imagePageData = journey && journey.journeyPages.nodes.find(n => n.action === 'IMAGE');
  const template = pageData && getTemplate(pageData);
  const nodes = template && template.nodes;
  const bgImage = userJourney.getBgImageFromAnswers(imagePageData, eventData && eventData.eventByUuid);

  const isAfterCreate = location.state && location.state.isAfterCreate;
  const isSoloEvent = location.state && location.state.isSoloEvent;

  const [showSuccessModal, setShowSuccessModal] = useState(isAfterCreate && !isSoloEvent);

  const toggleSuccessModal = () => setShowSuccessModal(prev => !prev);

  const handleInviteFriendsSuccess = () => {
    alert.success('Your friends have been successfully invited.');
    toggleSuccessModal();
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.paddingTop = '0';

    return () => {
      body.style.paddingTop = null;
    };
  }, []);

  if (eventLoading || journeyLoading) return <Loader />;

  return (
    <div className={className}>
      <UserJourneyNav />
      <JourneyResourcesPage
        resources={resources}
        choices={{ quote: 'not currently used' }}
        bgImage={bgImage || (pageData && pageData.backgroundImage && pageData.backgroundImage.url)}
        journeyAction={imHost(hosts, viewer) ? 'host' : 'share'}
        eventData={eventData}
        eventUuid={uuid}
        eventId={eventData && eventData.eventByUuid && eventData.eventByUuid.id}
      >
        {nodes && nodes.map((node, i) => (
          <JourneyNode type={node.type} key={i} className={node.className}>
            {node.text && node.text.map((text, k) => (
              <span
                key={k}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            ))}
          </JourneyNode>
        ))}
      </JourneyResourcesPage>
      <EventCreationSuccessModal
        show={showSuccessModal}
        toggle={toggleSuccessModal}
        event={eventData && eventData.eventByUuid}
        viewer={session.viewer}
        onInviteFriendsSuccess={handleInviteFriendsSuccess}
        areas={session.areas}
      />
      <style>{'#new_banner_hack { display: none; };'}</style>
    </div>
  );
};
const StyledEventCreationResourcesPage = styled(EventCreationResourcesPage)`
  min-height: 100%;

  & > div {
    min-height: 100vh;
  }
`;
export { StyledEventCreationResourcesPage as EventCreationResourcesPage };
