import { breakpoints } from './';

const formAssemblyStyle = `
  .wFormContainer {
    max-width: 100%;
  }

  form .wfCurrentPage,
  form .wfPage {
    position: relative;
    max-width: 1192px;
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06);
    padding: 60px 100px;
  }

  .wFormContainer .wForm input[type="button"],
  .wFormContainer .wForm input[type="submit"] {
    appearance: none;
    box-shadow: none;
    background: none;
    outline: none !important;
    border: 1px solid #8d1b37;
    background-color: #8d1b37;
    color: #fff;
    font-size: 14px;
    padding: 8px 18px;
    line-height: 20px;
    min-height: 38px;
    border-radius: 3px;
    transition: all 250ms;
    cursor: pointer;
  }

  .wFormContainer .wForm input[type="button"]:hover,
  .wFormContainer .wForm input[type="submit"]:hover {
    background-color: #5a1b27;
    border-color: #5a1b27;
  }

  .wForm fieldset.section {
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .wForm div.field-container-D {
    display: inline-block;
    vertical-align: top;
    width: 32% !important;
    text-align: left;
    margin-top: 20px;
    padding: 0 10px;
    // border: 1px solid pink;
  }

  .wForm div.field-container-D label.preField {
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 2px;
  }

  .wForm div.field-container-D label.postField {
    cursor: pointer;
  }

  .wForm div.field-container-D .inputWrapper {
    display: block;
  }

  .wForm div.field-container-D input[type="text"],
  .wForm div.field-container-D input[type="tel"],
  .wForm div.field-container-D input[type="number"],
  .wForm div.field-container-D input[type="email"],
  .wForm div.field-container-D select,
  .wForm div.field-container-D textarea {
    width: 100% !important;
    background-color: transparent;
    display: block;
    // margin: 0 0 14px;
    font-size: 16px;
    padding: 7px 12px 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    transition: border-color 250ms, box-shadow 250ms;
    outline: none !important;
    color: #333;
  }

  .wForm div.field-container-D input[type="text"]:focus,
  .wForm div.field-container-D input[type="tel"]:focus,
  .wForm div.field-container-D input[type="number"]:focus,
  .wForm div.field-container-D input[type="email"]:focus,
  .wForm div.field-container-D select:focus,
  .wForm div.field-container-D textarea:focus {
    border-color: #8a8a8a !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15) !important;
  }

  .wForm div.field-container-D input[type="radio"],
  .wForm div.field-container-D input[type="checkbox"] {
    position: relative;
    width: 14px !important;
    height: 14px !important;
    border-radius: 3px;
    border: 1px solid #ccc !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    font-size: 2em;
    color: white;
    vertical-align: middle;
    margin-right: 4px;
    outline: none !important;
    cursor: pointer;
  }

  .wForm div.field-container-D input[type="radio"]:hover,
  .wForm div.field-container-D input[type="checkbox"]:hover {
    background-color: #f4f4f4 !important;
  }

  .wForm div.field-container-D input[type="radio"] {
    border-radius: 50%;
  }

  .wForm div.field-container-D input[type="radio"]:after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -2px;
    border-radius: 50%;
  }

  .wForm div.field-container-D input[type="radio"]:checked,
  .wForm div.field-container-D input[type="checkbox"]:checked {
    background-color: #8d1b37 !important;
    border-color: #8d1b37 !important;
  }

  .wForm div.field-container-D input[type="radio"]:checked:after {
    background-color: #fff;
  }

  .wForm div.field-container-D input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 1px;
    width: 8px;
    height: 2px;
    border: solid #fff;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
  }

  .wForm form .vertical > .oneChoice {
    margin: 0 0 8px 0;
  }

  .wForm form .vertical > .oneChoice:first-of-type {
    margin-top: 6px;
  }

  h2.section-title {
    text-align: center;
  }

  h4 {
    margin-bottom: 30px;
  }

  .section.inline.group {
    text-align: left;
  }

  .section .htmlContent {
    text-align: center;
  }

  .wForm div.popupalert {
    padding: 10px;
    margin: 15px auto;
  }

  .wForm form .errFld {
    border: none !important;
  }

  .wFormContainer .errMsg,
  .wForm form .errMsg {
    font-size: 10px;
    outline: none !important;
    padding-top: 2px;
    color: #c72727;
  }

  .wForm .label.reqMark:after,
  .wForm .reqMark:after {
    color: #c72727;
  }

  .wForm hr {
    margin: 60px 0;
  }

  a {
    color: #5a1b27;
    transition: color 250ms;
  }

  a:hover {
    color: #5a1b27;
  }

  @media (${breakpoints.tablet}) {
    form .wfCurrentPage,
    form .wfPage {
      padding: 60px;
    }

    .wForm div.field-container-D {
      display: block;
      width: 100% !important;
    }
  }

  @media (${breakpoints.mobile}) {
    form .wfCurrentPage,
    form .wfPage {
      padding: 0px 30px;
    }
  }
`;

export { formAssemblyStyle };


/*

<style type="text/css">
  .wFormTitle{
   display:none;
  }
.wForm form .wfPage, .wForm form .wfHideSubmit {
  display: inline-block !important;
}
.wForm form{text-align: left;}

.wFormContainer {
    border: none;
    border-color: #ffffff;
    border-width: 0px;
  font-family:'Open Sans';
}
.wForm fieldset legend {
  font-weight:bold !important;
  font-size:18px !important;
  padding:0 !important;
  color:#901235;
  border-bottom:1px solid #901235;
  width:100%;
}
.wForm fieldset {
  border:0 !important;
}
.wFormContainer .wForm input[type="button"], .wFormContainer .wForm input[type="submit"] {
  background-color: #8c1e2e;
  color:white;
}
#tfa_86 {
  margin-top:15px;
}
  .wForm .oneField {
   text-align:center;
  }
.wForm .oneField input {
  background: #eee none repeat scroll 0 0 !important;
  border:0 !important;
  height: 40px;
  color: rgba(102,102,102,.75);
  box-shadow: none;
  transition: all .2s;
  border-radius: 3px;
}
  .label {
   font-weight:normal !important;
  }
  .wForm form .oneChoice label, .wForm form .oneChoice .label {
    font-size:12px;
  }
.wForm .oneField input[type="radio"],.wForm .oneField input[type="checkbox"] {
  height:auto !important;
}
.wForm select {
  height: 40px;
  color: rgba(102,102,102,.75);
  box-shadow: none;
  transition: all .2s;
  border-radius: 3px;
}
.oneField  label.preField   {
  font-size:14px;
}
.wForm .pageSection > h4 {
  color: black !important;
  font-weight:100;
}
.wFormContainer {
  border: 0 none !important;
  width: 100% !important;
}
#tfa_0-T {
  display:none !important;
}
.wForm .section {
  text-align:center;
  }


.page-id-183 .site-header {
  position:absolute;
}
.jobs-wrapper ul {
  list-style-type: none;
  margin: 0 auto 27px !important;
  padding: 0 !important;
  text-align: center;
  width: 100%;
}
.jobs-wrapper ul li{
  display: inline-block;
  background:none !important;
  margin: 0 0 18px !important;
  padding: 0 20px 18px !important;
  text-align: center;
  vertical-align: top !important;
  width: 400px;
}

.jobs-wrapper ul li .the-button {
  width:200px;
  border:1px solid #901235;
  background:#901235;
  padding:5px;
  text-align:center;
  color:white;
  margin: 0 auto;
}

.jobs-wrapper ul li .the-button a{
  color:white;
  text-decoration:none;
  width:100%
}

.jobs-wrapper ul li .the-button:hover{
  color:white;
  text-decoration:none;
  background:#443135 ;
}


.wForm form .oneChoice label, .wForm form .oneChoice .label{
  color:#000000;
}

.bt-hover:hover{
  background-color: #ffffff;
}
  .popupalert {
    background: white !important;
    border: 2px solid #8d1b3d;
    padding: 10px !important;
    font-weight: bold;
    width:85%;
    max-width:600px;
    margin:0 auto !important;

}
  .wForm div.section > .preField {
   font-size:14px;
  }
.wForm form .wfPagingButtons {
 display: block;
    margin: 0 auto;
    float: none;
    text-align: center;

  }
  .wForm .wfPagePreviousButton, .wForm .wfPageNextButton {
margin-right:0;
  }
.wForm .actions {
text-align:center;
  }
.supportInfo {
  display:none !important;
}
.wFormContainer .wForm input[type="button"], .wFormContainer .wForm input[type="submit"] {
	width:135px;
    padding:8px;
  }
h2.section-title{
text-transform:uppercase;
color:#8d1b3d;
font-size:16px;
  }
  #tfa_155-T{
display:none;
  }
  .wForm .secondaryAction, .wForm .primaryAction, .wForm .wfPagePreviousButton, .wForm .wfPageNextButton {
    background: none;
}
</style>
<!-- <TL3 -->

*/
