import React, { useState } from 'react';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import moment from 'moment';

import {
  Button,
  FAIcon,
  withSettings
} from '../components';

import { toCSV } from '../../libs/csv';
import { RESERVATION_FILTER } from '../../enums';


export const EVENT_RESERVATION_EXPORT = gql`
  query eventReservationExport($uuid: String!, $after: String) {
    event: eventByUuid(uuid: $uuid) {
      id
      eventHosts {
        edges {
          node {
            id
            creator
            effectiveEmail
            profile {
              id
              firstName
              lastName
            }
          }
        }
      }
      reservations(filterByState: ACCEPTED, first: 300, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            reservationId: id
            profile {
              id
              firstName
              lastName
              user {
                id
                email
              }
            }
            createdAt
            participated
            additionalGuests {
              firstName
              lastName
              email
            }
            answers {
              edges {
                node {
                  id
                  response
                  reservationQuestion {
                    id
                    text
                  }
                }
              }
            }
            tables {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;


const ManageEventsDetailsCSVButton = ({ settings: { copy }, enumerated, event, client }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAll = async () => {
    let hasNextPage = true;
    let cursor = null;
    let allResults = null;
    setLoading(true);

    while (hasNextPage) {
      const { data: moreData } = await client.query({
        query: EVENT_RESERVATION_EXPORT,
        variables: { uuid: event.uuid, after: cursor },
        errorPolicy: 'all'
      });

      const { event: { reservations: { pageInfo: morePageInfo, edges } } } = moreData;

      if (allResults === null) {
        allResults = moreData;
      } else {
        const newResults =  Object.assign({}, allResults);
        newResults.event.reservations.edges = [
          ...allResults.event.reservations.edges,
          ...edges
        ];
        allResults = newResults;
      }

      hasNextPage = morePageInfo.hasNextPage;
      cursor = morePageInfo.endCursor;
    }

    setLoading(false);
    setData(allResults);
  };

  const loadReservations = () => {
    fetchAll();
  };

  if (data) {
    const SHOW = RESERVATION_FILTER;
    const { FIELDS: { LABEL } } = RESERVATION_FILTER;

    const hosts = data.event ? data.event.eventHosts.edges : [];
    const reservations = data.event ? data.event.reservations.edges : [];

    const rowTitles = ['First Name', 'Last Name', 'Email', 'Guest Of', 'Status', 'Reservation Date'];

    if (event.communityDinner) {
      rowTitles.push('Table');
    }

    const questions = event.reservationQuestions.edges.map(edge => edge.node);

    questions.forEach(question => {
      rowTitles.push(question.text);
    });

    const hostRows = Array.isArray(hosts)
      ? hosts
        .sort((a) => a.node.creator ? -1 : 1)
        .reduce((acc, host) => {
          const { creator, effectiveEmail, profile } = host.node;
          const firstName = profile ? profile.firstName : '';
          const lastName = profile ? profile.lastName : '';

          const hostRow = [
            firstName,
            lastName,
            effectiveEmail,
            '',
            creator ? 'Host' : 'Cohost'
          ];

          return [...acc, hostRow];
        }, [])
      : [];

    const itemRows = reservations.reduce((acc, guest) => {
      const { profile, tables, answers, additionalGuests, createdAt: unformattedCreatedAt } = guest.node;
      const createdAt = moment(unformattedCreatedAt).format('MMM Do YYYY, h:mm a');

      const guestRow = [
        (profile && profile.firstName) || '',
        (profile && profile.lastName) || '',
        (profile && profile.user && profile.user.email) || '',
        '',
        SHOW.getField(LABEL, enumerated),
        createdAt
      ];

      let table = null;

      if (event.communityDinner) {
          table = tables.edges && tables.edges.length ? tables.edges[0].node : null;
          if (table) guestRow.push(table.name);
      }

      const guestAnswers = answers.edges.map(a => a.node);

      questions.forEach(question => {
        const answer = guestAnswers.find(a => a.reservationQuestion.id === question.id);
        if (answer) {
          const answerWithoutLineBreaks = answer.response.replace(/\n/g, ' ');
          guestRow.push(answerWithoutLineBreaks);
        } else {
          guestRow.push('');
        }
      });

      let additionalGuestsRows = [];

      if (additionalGuests && additionalGuests.length) {
        additionalGuestsRows = additionalGuests.map(additionalGuest => {
          let additionalGuestRow = [];

          if (additionalGuest.firstName) {
            additionalGuestRow = [
              additionalGuest.firstName,
              additionalGuest.lastName,
              additionalGuest.email,
              (profile.user && profile.user.email) || ''
            ];
          } else {
            additionalGuestRow = [
              additionalGuest.name,
              '',
              additionalGuest.email,
              (profile.user && profile.user.email) || ''
            ];
          }

          additionalGuestRow.push(SHOW.getField(LABEL, enumerated));
          additionalGuestRow.push(createdAt);

          if (event.communityDinner && table) {
            additionalGuestRow.push(table.name);
          }

          return additionalGuestRow;
        });
      }

      return [...acc, guestRow, ...additionalGuestsRows];
    }, []);

    const rows = [
      rowTitles,
      ...hostRows,
      ...itemRows
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + toCSV(rows);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'guest_list.csv');
    document.body.appendChild(link);
    link.click();
  }

  // className="download_guest_list_button"
  // button is hidden with this class.... i cant find where this is happening or why //

  return (
    <Button inverted handleClick={() => loadReservations()} loading={loading} disabled={loading}>
      <FAIcon name="file" />
      {copy.manage.action_download_draft}
    </Button>
  );
};

const ManageEventsDetailsCSVButtonWithSettings = withSettings(ManageEventsDetailsCSVButton);
const ManageEventsDetailsCSVButtonWithApollo = withApollo(ManageEventsDetailsCSVButtonWithSettings);
export { ManageEventsDetailsCSVButtonWithApollo as ManageEventsDetailsCSVButton };
