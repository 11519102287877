import moment from 'moment';

const formatters = {
  toISODate: (value, inputFormat) => {
    if (!value) return null;
    let isoDate = null;
    let isoDateString = null;

    switch (inputFormat) {
      case ('date'):
        isoDate = new Date(value);
        return !isNaN(isoDate.getTime()) ? isoDate.toISOString().split('T')[0] : ''; // remove time
      default: // YYY-MM-DDTtime
        isoDateString = new Date(value);
        return !isNaN(isoDateString.getTime()) ? isoDateString.toISOString() : '';
    }
  },

  stringInsert: (str, inserts) => {
    if (!str) { return ''; }

    let string = str;

    if (Array.isArray(inserts)) {
      inserts.forEach((insert, i) => {
        const regex = new RegExp(`(%${i + 1}\\$s)`, 'g');
        string = string.replace(regex, insert);
      });
    } else {
      const regex = new RegExp('(%1\\$s)', 'g');
      string = string.replace(regex, inserts);
    }

    return string;
  },

  formatPrice: rawPrice => {
    return (parseInt(rawPrice) / 100).toFixed(0);
  },

  formatPriceWithCents: rawPrice => {
    return '$' + (parseInt(rawPrice) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },

  dateFromDOBString: (dobString) => {
    if (typeof dobString !== 'string') {
      return '';
    }

    const dobComponents = dobString.split('-');
    const year = dobComponents[0];
    const month = dobComponents[1] - 1;
    const day = dobComponents[2];
    return new Date(year, month, day);
  },

  stringFromDOBDate: (dobDate) => {
    return moment(dobDate).format('YYYY-MM-DD');
  },

  replaceUrlsWithAnchor: text => {
    const anchorRegex = /<\/?a[^>]*>/g;
    const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;

    return text.replace(anchorRegex, '').replace(urlRegex, url => (
      '<a href="' + url + '" target="_blank" rel="noopener noreferrer nofollow">' + url + '</a>'
    ));
  }
};

const {
  toISODate,
  stringInsert,
  formatPrice,
  formatPriceWithCents,
  dateFromDOBString,
  stringFromDOBDate,
  replaceUrlsWithAnchor
} = formatters;

export {
  formatters,
  toISODate,
  stringInsert,
  formatPrice,
  formatPriceWithCents,
  dateFromDOBString,
  stringFromDOBDate,
  replaceUrlsWithAnchor
};
