import React from 'react';
import styled from 'styled-components';
import { InfoTooltip } from '../../components';
import { breakpoints } from '../../../styles';
import { withSettings } from '../../components';

const defaultFields = [
  { id: 'yes', label: 'Yes', value: 'true' },
  { id: 'no', label: 'No', value: 'false' }
];

class RadioButtons extends React.Component {
  state = { showTooltip: false, valid: true };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate } = this.props;
    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { onInputChange, onValidate, required } = this.props;

    onInputChange(name, value);

    if (required && this.inputIsValid(value)) {
      this.setState({ valid: true });
      onValidate(name, true);
    }
  };

  inputIsValid = value => {
    return typeof value === 'string' && value.length > 0;
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  render() {
    const { valid } = this.state;
    const {
      className,
      fields,
      disabledFields = [],
      name,
      selected,
      layout, // "vertical" / "horizontal"; defaults to "horizontal" //
      align // "left" / "right"; defaults to "center" //
    } = this.props;

    const radioFields = fields && Array.isArray(fields) ? fields : defaultFields;

    return (
      <div className={`
        ${className}
        ${layout ? layout : 'horizontal'}
        ${align ? align : ''}
        ${!valid ? 'invalid' : ''}
      `}>
        {radioFields.map((field, i) => (
          <span key={i} className={`radio_wrapper${disabledFields.length > 0 && disabledFields.includes(field.value) ? ' disabled' : ''}`}>
            <label className="radio_label">
              <input
                type="radio"
                value={field.value}
                name={name}
                onChange={this.handleChange}
                checked={selected && selected === field.value}
                disabled={disabledFields.length > 0 && disabledFields.includes(field.value)}
              />
              {field.label}
            </label>
            {field.tooltip && <InfoTooltip content={field.tooltip}/>}
          </span>
        ))}
        {!valid && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}

const StyledRadioButtons = styled(RadioButtons)`
  .radio_wrapper {
    margin: 0 15px;

    @media (${breakpoints.tabletSmall}) {
      display: block;
    }

    &.disabled {
      // opacity: 0.7;
      .radio_label {
        color: ${props => props.settings.theme.css.global.colors.textDisabled};
      }

      * {
        cursor: default !important;
      }

      input {
        &:not(:checked) {
          background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
        }
      }
    }
  }

  &.vertical {
    .radio_wrapper {
      display: block;
    }
  }

  &.left {
    .radio_wrapper {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &.right {
    .radio_wrapper {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  label {
    margin: 0 7px 0 0;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      input {
        &:not(:checked) {
          background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
        }
      }
    }
  }

  input[type="radio"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-1px);
    width: 14px;
    height: 14px;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 7px;
    cursor: pointer;

    &:checked {
      background-color: ${props => props.settings.theme.css.global.colors.primary};
      border-color: ${props => props.settings.theme.css.global.colors.primary};

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -2px 0 0 -2px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  &.invalid {
    input[type="radio"] {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }

    .validation_error {
      margin-bottom: 5px;
    }

    label {
      padding-bottom: 0 !important;
    }
  }

  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-botton: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
`;

const StyledRadioButtonsWithSettings = withSettings(StyledRadioButtons);

export { StyledRadioButtonsWithSettings as RadioButtons };
