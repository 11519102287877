const dom = {
  setCardHeights: selector => {
    const cards = document.querySelectorAll(selector);
    let maxheight = 0;

    cards.forEach(card => {
      const height = card.offsetHeight;
      if (height > maxheight) maxheight = height;
    });

    if (maxheight) {
      cards.forEach(card => {
        card.style.height = `${maxheight}px`;
        card.style.opacity = 1;
      });
    }
  },

  isMobile: settings => {
    const windowWidth = window.innerWidth;
    const mobileWidth = settings.theme.css.breakpoints ? parseInt(settings.theme.css.breakpoints.nav.mobile, 10) : 1075;
    return windowWidth <= mobileWidth;
  },

  isMobileHeight: () => {
    return window.innerHeight < 841;
  },

  select: selector => {
    return document.querySelector(selector);
  },

  selectAll: selector => {
    const elements = document.querySelectorAll(selector);
    return Array.prototype.slice.call(elements);
  },

  stripHtml: html => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    const onlyText = tmp.textContent || tmp.innerText || '';

    return onlyText
      .trim()
      .replace(/\s+/gmi, ' ')
      .replace(/[\n\r]/gmi, '');

    // return html.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '') // remove script //
    //   .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '') // remove html elements //
    //   .replace(/&([a-z]*|#\d*);/gmi, '') // remove html entities //
    //   .replace(/\n|\r|<br>/gmi, '') // remove line breaks //
    //   .trim();
  },

  minifyHTML: (parts, ...values) => {
    const out = [];

    for (var i = 0; i < parts.length; i++) {
      if (parts[i]) out.push(parts[i]);
      if (values[i]) out.push(String(values[i]));
    }

    return out.join('').replace(/\r\n|\n|\r/gm, ' ').replace(/ {2,}/g, ' ');
  },

  getScrollPos: () => {
    if (window.pageYOffset !== undefined) {
      return { x: window.pageXOffset, y: window.pageYOffset };
    } else {
      return {
        x: document.documentElement.scrollLeft || document.body.scrollLeft || 0,
        y: document.documentElement.scrollTop || document.body.scrollTop || 0
      };
    }
  }
};

const {
  setCardHeights,
  isMobile,
  isMobileHeight,
  select,
  selectAll,
  stripHtml,
  minifyHTML,
  getScrollPos
} = dom;

export {
  dom,
  setCardHeights,
  isMobile,
  isMobileHeight,
  select,
  selectAll,
  stripHtml,
  minifyHTML,
  getScrollPos
};
