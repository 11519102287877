import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, withSettings } from '../components';

import ReactPlayer from 'react-player';
import * as userJourney from '../../libs/userJourney';

const H2 = styled.h2`
  margin: 0 0 30px;
  letter-spacing: 0.02em;
  line-height: 1.6;
  font-size: 36px;
  text-align: center;
  color: #fff;
`;

const P = styled.p`
  margin: 0 auto !important;
  font-size: 18px;
  line-height: 1.7;
  max-width: 700px;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 6px rgba(0,0,0,0.8);
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 30px 0 50px;
  padding: 0;
  text-align: center;
`;

const Li = styled.li`
  display: inline-block;
  margin: 20px;
  background-color: rgba(255,255,255,0.1);
  vertical-align: middle;
  transition: background-color 50ms;

  &:hover {
    background-color: rgba(255,255,255,0.15);
    transition: background-color 300ms;
  }
`;

const A = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 40px;
  min-height: 140px;
  width: 190px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  span {
    color: #fff;
    font-size: 18px;
    line-height: 1.4;
    text-decoration: none;
  }
`;

const JourneyResources = ({
  settings,
  resources: journeyResources,
  // choices: { playlistUrl, bgImage, quote },
  journeyAction,
  handleRedirect,
  handleCreationRedirect
}) => {
  const [resourceHeight, setResourceHeight] = useState(null);
  const loaded = useRef(false);

  const { copy } = settings;
  const userName = userJourney.getItem('name');
  const resources = journeyResources.resources;
  const videos = journeyResources.videos;

  useEffect(() => {
    const resourceEls = [ ...document.querySelectorAll('.resource_link') ];
    let maxHeight = 0;

    if (resources && !loaded.current) {
      maxHeight = resourceEls.reduce((acc, cur) => {
        const curHeight = cur.offsetHeight || 0;
        return curHeight > acc ? curHeight : acc;
      }, 0);
      setResourceHeight(maxHeight);
      loaded.current = true;
    }
  }, [resources]);

  //// redirectType ////

  const getButtonText = () => {
    switch (journeyAction) {
      case 'landing':
        return 'Find a Dinner Near Me';
      default:
        return 'Find a Dinner Near Me';
    }
  }

  const defaultSelectionCopy =
    'Here are some resources curated just for you — to help you pause, let go, (re)connect, and elevate your Shabbat experience.';

  return (
    <div style={{ textAlign: 'center', padding: '0 20px' }}>
      <H2>{userName ? `Thanks, ${userName}!` : 'Thanks!'}</H2>
      <P
        dangerouslySetInnerHTML={{
          __html: (copy.user_journey && copy.user_journey.your_selection) || defaultSelectionCopy
        }}
      />
      {videos.length > 0 && videos.map((v, i) => (
        <ReactPlayer
          key={i}
          style={{ 'margin': '40px auto 30px', 'max-width': '400px' }}
          url={v.url}
          controls
          width="100%"
          height={226}
        />
      ))}
      {resources.length > 0 && (
        <Ul>
          {resources.map((r, i) => (
            <Li key={i}>
              <A
                className="resource_link"
                href={r.url}
                style={{ height: `${resourceHeight}px` }}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span>{r.title}</span>
              </A>
            </Li>
          ))}
        </Ul>
      )}
      <Button
        handleClick={handleRedirect}
        style={{ padding: '13px 28px', margin: '0 15px 15px' }}
      >
        {getButtonText()}
      </Button>
      <Button
        handleClick={handleCreationRedirect}
        style={{ padding: '13px 28px', margin: '0 15px 15px' }}
      >
        Create a Dinner
      </Button>
      {false && !userName && (
        <Button
          handleClick={handleCreationRedirect}
          style={{ padding: '13px 28px', margin: '0 15px 15px' }}
        >
          Create a Dinner
        </Button>
      )}
      <div style={{ marginTop: '40px' }}>
        <Icon name="ot-emblem" color="#fff" border />
      </div>
    </div>
  );
};

const JourneyResourcesWithSettings = withSettings(JourneyResources);
export { JourneyResourcesWithSettings as JourneyResources };
