import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { SessionContext } from '../../Context';

import {
  ManageEventsHeader,
  ManageEventsFilters,
  ManageEventsList,
  PaginationButtons,
  withSettings
} from '../components';

import { EVENT_FILTER } from '../../enums';
import { usePaginatedViewerEvents } from '../../hooks';
import { breakpoints } from '../../styles';


const ManageEvents = ({ className, match, history }) => {
  const { session } = useContext(SessionContext);
  const { URL } = EVENT_FILTER.FIELDS;

  const [activeFilter, setActiveFilter] = useState(EVENT_FILTER.getEnumByField(URL, match.params.filter));
  const [eventCounts, setEventCounts] = useState({ [activeFilter]: undefined });

  const paginatedEvents = usePaginatedViewerEvents(activeFilter);
  const { currentPage, loading, totalCount, pagination, fetchMoreLoading } = paginatedEvents;
  const { hasPrevPage, hasNextPage, goToPrevPage, goToNextPage } = pagination;

  useEffect(() => {
    history.push(EVENT_FILTER.getField(URL, activeFilter));
  }, [activeFilter, URL, history]);

  useEffect(() => {
    setEventCounts(prev => ({ ...prev, [activeFilter]: totalCount }));
  }, [activeFilter, totalCount]);

  const handleFilterChange = filter => setActiveFilter(filter);

  return (
    <div className={className}>
      <ManageEventsHeader
        active={activeFilter}
        totalCount={loading ? '..' : eventCounts[activeFilter]}
        viewer={session.viewer}
      />
      <div className="content">
        <ManageEventsFilters
          active={activeFilter}
          handleChange={handleFilterChange}
          session={session}
        />
        <div style={{ width: '100%' }}>
          <ManageEventsList
            active={activeFilter}
            list={currentPage || []}
            viewer={session.viewer}
            loading={loading}
            showArea={session.areas && session.areas.length > 1}
          />
          {(hasPrevPage || hasNextPage) && (
            <PaginationContainer>
              <PaginationButtons
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                loading={fetchMoreLoading}
              />
            </PaginationContainer>
          )}
        </div>
      </div>
    </div>
  );
};

const PaginationContainer = styled.div`
  margin-left: 30px;
  margin-bottom: 20px;

  @media (${breakpoints.manageEventsListTablet}) {
    margin-left: 0;
  }
`;

const StyledManageEvents = styled(ManageEvents)`
  width: 100%;
  max-width: 1192px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 15px 20px;

  .content {
    display: flex;

    @media (${breakpoints.manageEventsListTablet}) {
      display: block;
    }
  }

  a {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }
`;

const ManageEventsWithRouter = withRouter(StyledManageEvents);
const ManageEventsWithSettings = withSettings(ManageEventsWithRouter);
export { ManageEventsWithSettings as ManageEvents };
