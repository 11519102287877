import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { Button, Modal } from '../';
import PropTypes from 'prop-types';

class ProfileConfirmUpdateModal extends React.Component {
  render() {
    const { className, show, toggle, updateClicked, cancelClicked, loading } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <Modal show={show} toggle={toggle} size="sm" noPadding>
        <div className={className}>
          <h2>{copy.profile.confirm_title}</h2>
          <h3>{copy.profile.confirm_subtitle}</h3>
          <div className="button__container">
            <Button className="button__cancel" handleClick={cancelClicked} buttonStyle="primary_outline" type="submit">{copy.profile.confirm_cancel}</Button>
            <Button className="button__update" handleClick={updateClicked} buttonStyle="primary" type="submit" loading={loading} disabled={loading}>{copy.profile.confirm_submit}</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ProfileConfirmUpdateModal.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  updateClicked: PropTypes.func,
  cancelClicked: PropTypes.func
};

const StyledProfileConfirmUpdateModal = styled(ProfileConfirmUpdateModal)`
  position: relative;
  text-align: center;
  padding: 30px 40px;

  & > h2 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin: 0 0 20px;
  }

  & > h3 {
    color: ${props => props.settings.theme.css.global.colors.heading};
    font-size: 12px;
    line-height: 1.6em;
    margin: 0 0 30px;
    font-weight: normal !important;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > button {
    margin-top: 1em;
  }

  & > .separator_line {
    height: 1px;
    background-color: ${props => props.settings.theme.css.global.colors.borderColor};
    width: 33.33333%;
    margin: 10px auto;
    flex-grow: 1;
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    & > .logo {
      width: 140px;
      margin: 0 auto;
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;
  }

  .button {
    &__container {
      border-top: 1px solid #ccc;
      padding-top: 30px;

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &__cancel {
      float: left;
    }

    &__update {
      float: right;
    }

    &__cancel,
    &__update {
      width: calc(50% - 10px);
    }
  }
`;

const StyledProfileConfirmUpdateModalWithSettings = withSettings(StyledProfileConfirmUpdateModal);

export { StyledProfileConfirmUpdateModalWithSettings as ProfileConfirmUpdateModal };
