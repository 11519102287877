import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useSettings, useSession } from '../../../hooks';

// import { TweenMax, Power3 } from 'gsap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const EventCreationFormHero = () => {
  const settings = useSettings();
  const session = useSession();

  gsap.registerPlugin(ScrollTrigger);

  const heroRef = useRef(null);

  useEffect(() => {
    if (heroRef) {
      gsap.to(
        heroRef.current,
        {
          y: '35%',
          scrollTrigger: {
            trigger: document.body,
            start: 'top top',
            scrub: true
          }
        }
      );
    }
  }, [heroRef]);

  const images = settings.theme.assets.images;

  return (
    <Hero settings={settings} session={session}>
      <div style={{ backgroundImage: `url(${images.event_creation_header || images.tag_default_header})` }} ref={heroRef} />
    </Hero>
  );
};

const Hero = styled.div`
  position: absolute;
  top: ${({ session: { layout } }) => layout.removeHeaderFooter ? '0' : '155px'};
  left: 0;
  width: 100%;
  overflow: hidden;

  div {
    height: 50vh;
    min-height: 520px;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.settings.theme.css.global.colors.primary};
    opacity: 0.1;
  }
`;
