import { css } from 'styled-components';

export const SCREEN_SIZES = {
  xs: 340,
  sm: 576,
  md: 1075,

  // user journey //
  journeySm: 640,
  journeyMd: 850,
  journeyLg: 1400,

  resourcesMd: 1180,
};

export const media = Object.keys(SCREEN_SIZES).reduce((accumulator, label) => {
  const emSize = SCREEN_SIZES[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {});


export const isJourneyMobile = () => window.innerWidth < SCREEN_SIZES.journeyMd;
