import React from 'react';
import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';

import {
  Button,
  FAIcon,
  withSettings,
  alert
} from '../components';
import { setToken } from '../../libs/token'

import { LOG_IN_WITH_SOCIAL_AUTH } from '../../mutations';
import { FACEBOOK_STATUS } from '../../enums';

import { SessionContext } from '../../Context';
import validators from '../Notification/StatusValidators';
import { Otp } from '..';
import { authViewStateValues, withOtpMutationHooksHOC } from '../../libs/auth';
import { OtpContext } from '../../OtpContext';


class FacebookLogIn extends React.Component {

  state = { isLoading: false, showOtp: false };
  static contextType = OtpContext;

  loginFb = (logInWithSocialAuth, setNotification) => {
    const { refetch, history, shouldRedirect, afterLogin, settings, toggleEmailLogin } = this.props;
    this.setState({ isLoading: true });

    try {
      window.FB.login(response => {
        if (response.status === FACEBOOK_STATUS.CONNECTED) {
          const { userID, accessToken } = response.authResponse;

          logInWithSocialAuth({ variables: {
            uid: userID,
            token: accessToken,
            provider: 'facebook'
          } }).then(async ({ data }) => {
            const { user, token, newUser, status } = data.logInWithSocialAuth;

            if (user) {
              setToken(settings, token);
              if (validators.possibleEmailChange(user.mustChangeEmail, newUser)) setNotification(true);
              await refetch();
              if (shouldRedirect) {
                history.push('/');
              }
              if (afterLogin) {
                afterLogin();
              }
            } else if (status === 'must_request_otp') {
              this.context.otpSettings.setOtpSettings({
                status: data.logInWithSocialAuth.status,
                userId: data.logInWithSocialAuth.userId,
                userMessage: data.logInWithSocialAuth.userMessage,
                userPhone: data.logInWithSocialAuth.userPhone,
              });
              this.setState({ showOtp: true });
            }
          })
          .catch(error => {
            this.handleFbLoginError(error);
          });
        } else {
          this.setState({ isLoading: false });
          toggleEmailLogin && toggleEmailLogin(true);
        }
      }, { scope: 'public_profile,email' });
    } catch (error) {
      this.handleFbLoginError(error);
    }
  };

  handleFbLoginError = () => {
    const { settings: { copy } } = this.props;
    // console.error(error);
    alert.error(copy.login.facebook_error);
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading, showOtp } = this.state;
    const { toggleEmailLogin } = this.props;

    if (showOtp) return <Otp setViewState={authViewStateValues.OTP_VIEW} {...this.props}  />

    return(
      <SessionContext.Consumer>
        {({ notification: { setNotification } }) => (
          <Mutation mutation={LOG_IN_WITH_SOCIAL_AUTH}>
            {(logInWithSocialAuth) => {
              return (
                <Button
                  buttonStyle="facebook"
                  fullWidth
                  capitalize
                  handleClick={() => {
                    toggleEmailLogin && toggleEmailLogin();
                    this.loginFb(logInWithSocialAuth, setNotification)
                  }}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <FAIcon name={['fab', 'facebook-f']} />
                  <span>Connect With Facebook</span>
                </Button>
              );
            }}
          </Mutation>
        )}
      </SessionContext.Consumer>
    );
  }
}

const LogInWithSettings = withSettings(FacebookLogIn);
const LogInWithRouter = withRouter(LogInWithSettings);
const LogInWithRouterWithSettingsWithOptHooks = withOtpMutationHooksHOC(
  LogInWithRouter
);
export { LogInWithRouterWithSettingsWithOptHooks as FacebookLogIn };
