const defaultColors = '#8D1B3D, #443135, #857363, #50C9B5, #FCD450';

const getColorsFromSettings = settings => {
  const colorsString = settings ? settings.theme.random_colors : defaultColors;
  return colorsString.split(/\s*,\s*/);
};

const generators = {
  randomColor: settings => {
    const colors = getColorsFromSettings(settings);
    const randomNumber = Math.random();
    const randomIndex = (Math.floor(randomNumber * 10)) % colors.length;
    const randomColor = colors[randomIndex];

    return randomColor;
  },
  randomColorFromProfile: (profile, settings) => {
    const colors = getColorsFromSettings(settings);
    const baseNumber = profile ? `${profile.firstName}${profile.lastName}`.length : 0;
    const index = (baseNumber % colors.length);
    const randomColor = colors[index];
    return randomColor;
  }
};

const {
  randomColor,
  randomColorFromProfile
} = generators;

export {
  generators,
  randomColor,
  randomColorFromProfile
};
