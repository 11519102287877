import styled, { css } from 'styled-components';

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  background-color: #ceccc7;

  ${({ margin }) => margin ? css`
    margin: ${margin};
  ` : css `
    margin: 50px 0;
  `}

  ${({ bottom }) => bottom && css`
    margin-bottom: 0;
  `}
`;
