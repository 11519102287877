import React from 'react';
import styled, { css } from 'styled-components';

import { useSettings } from '../../hooks';

import { ToggleButton, InfoTooltip } from '../components';

export const MembershipSelectionOptions = ({ membership, selection, onSelectPrice }) => {
  const { colors } = useSettings();

  return membership?.products?.length ? (
    <OptionsContainer colors={colors}>
      {membership.products.map(({ prices, name, description, hint = 'hello' }) =>
          prices.map(price => {
            const selected = selection && selection.id === price.id;
            const notSelected = selection !== null && selection.id !== price.id;
            return (
              <ToggleButton
                key={price.id}
                className={selected ? 'toggle_button active' : 'toggle_button'}
                onClick={() => onSelectPrice(price)}
                fullWidth
              >
                <Price notSelected={notSelected}>{name}</Price>
                <Description notSelected={notSelected}>
                  <span className="description">{description}</span>
                  {hint && (
                    <InfoTooltip
                      content={hint}
                      position="top"
                    />
                  )}
                </Description>
              </ToggleButton>
            )}))}
    </OptionsContainer>
  ) : null;
};

const OptionsContainer = styled.div`
  max-width: 420px;
  margin: 25px auto 20px;

  .toggle_button {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 40px;

    @media (max-width: 575px) {
      flex-direction: column;

      h3 {
        margin-bottom: 5px;
      }
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:not(:last-of-type) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &::before {
      content: '';
      position: absolute;
      border: solid #ceccc7;
      border-width: 0 0 2px 2px;
      top: 15px;
      left: 13px;
      width: 15px;
      height: 7px;
      transform: rotate(-45deg);
      transition: border-color 250ms;

      @media (max-width: 575px) {
        top: 50%;
        margin-top: -8px;
      }
    }

    &.active {
      border-color: ${({ colors: { primary } }) => primary};
      &::before {
        border-color: ${({ colors: { primary } }) => primary};
      }
    }

    &:hover {
      h3 {
        opacity: 1;
      }
      span.description {
        opacity: 0.8;
      }
    }
  }
`;

const Price = styled.h3`
  margin: 0 7px 0 0;
  font-size: 18px;
  line-height: 1;
  transition: opacity 250ms;

  ${({ notSelected }) => notSelected && css`
    opacity: 0.5;
  `}
`;

const Description = styled.span`
  display: inline-block;
  margin: 0;
  line-height: 1;
  font-size: 13px;

  span {
    opacity: 0.8;
    transition: opacity 250ms;

    ${({ notSelected }) => notSelected && css`
      opacity: 0.5;
    `}
  }

  @media (max-width: 575px) {
    line-height: 1.3;
  }
`;
