import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Card } from '../components';

import { breakpoints } from '../../styles';

const cards = [1, 2];

const ManageEventsAttendingLoader = ({ className }) => (
  <div className={className}>
    {cards.map(i => (
      <Card noPadding key={i}>
        <div className="event_cover" />
        <div className="content">
          <span className="title" />
          <div className="details__container">
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
          </div>
          <div className="avatar__container top">
            <span className="name" />
            <div className="avatar" />
          </div>
          <div className="avatar__container bottom">
            <span className="name" />
            <div className="avatar" />
          </div>
        </div>
      </Card>
    ))}
  </div>
);

const load = keyframes`
  0% {
    opacity: 1;
    right: 100%;
  }
  40% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 0;
  }
`;

const StyledManageEventsAttendingLoader = styled(ManageEventsAttendingLoader)`
  .ot_card {
    height: 194px;
    position: relative;
    opacity: 0.7;

    @media (${breakpoints.manageEventsListMobile}) {
      height: auto;
      padding: 40px 20px;
    }

    &:first-of-type {
      margin-top: 0 !important;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .event_cover {
    width: 256px;
    height: 100%;
    position: relative;
    float: left;

    @media (${breakpoints.manageEventsListMobile}) {
      float: none;
      // width: 100%;
      width: 200px;
      height: 150px;
      margin: auto;
    }
  }

  .content {
    float: left;
    width: calc(100% - 256px);
    position: relative;
    height: 100%;

    @media (${breakpoints.manageEventsListMobile}) {
      float: none;
      width: 100%;
      text-align: center;
      margin: auto;
    }
  }

  .details__container {
    position: absolute;
    top: 85px;
    left: 50px;
    height: 70px;
    width: 30%;

    span {
      left: 0;
      right: 0;

      &.top {
        top: 0;
      }
      &.middle {
        top: 50%;
        transform: translateY(-50%);
      }
      &.bottom {
        bottom: 0;
      }

      @media (${breakpoints.manageEventsListMobile}) {
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        margin: 3px 0;
        transform: translateY(0) !important;
      }
    }

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      top: auto;
      left: auto;
      text-align: center;
      margin: auto;
      height: auto;
    }
  }

  .avatar__container {
    position: absolute;
    right: 30px;
    width: 200px;
    text-align: right;

    &.top {
      top: 35px;
    }

    &.bottom {
      bottom: 35px;
    }

    .name {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-right: 15px;
      width: 100px;
    }

    .avatar {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      right: auto;
      top: auto !important;
      bottom: auto !important;
      text-align: center;
      margin: 20px auto 0;
    }
  }

  span {
    display: block;
    height: 10px;
    position: absolute;

    &.title {
      top: 40px;
      left: 30px;
      height: 20px;
      width: 140px;

      @media (${breakpoints.manageEventsListMobile}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 25px auto 15px;
      }
    }

    @media (${breakpoints.manageEventsListMobile}) {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 5px 0;
    }
  }

  .event_cover,
  .avatar,
  span {
    background-color: #f0f0f0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background-color: #ececec;
      animation: ${load} 1.4s infinite ease-in-out;
    }
  }
`;

export { StyledManageEventsAttendingLoader as ManageEventsAttendingLoader };
