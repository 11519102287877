import React from 'react';
import styled, { css } from 'styled-components';

import { withSettings } from '../../components';


const UnderlineButton = ({ className, children, onClick, icon, disabled }) => (
  <span className={['underline_button', className].join(' ')} onClick={(e) => !disabled && onClick(e)}>
    {icon && <i />}
    {children}
  </span>
);

const StyledUnderlineButton = styled(UnderlineButton)`
  position: relative;
  font-size: 14px;
  display: inline-block;
  color: ${({ colors: { primary } }) => primary};
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;
  transition: color 250ms;

  ${({ icon }) => icon === 'add' && css`
    padding-left: 13px;
  `}

  ${({ icon }) => icon === 'remove' && css`
    padding-left: 17px;
  `}

  &::after {
    content: '';
    position: absolute;
    background-color: ${({ colors: { primary } }) => primary};
    height: 1px;
    width: 100%;
    bottom: 1px;
    left: 0;
    transition: background-color 250ms;
  }

  i {
    position: absolute;
    left: 0;

    ${({ icon }) => icon === 'add' && css`
      width: 7px;
      height: 7px;
      top: 5px;
    `}

    ${({ icon }) => icon === 'remove' && css`
      width: 12px;
      height: 12px;
      top: 3px;
    `}

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      background-color: ${({ colors: { primary } }) => primary};
      transform-origin: center;
      transition: background-color 250ms;
    }

    ${({ icon }) => icon === 'add' && css`
      &::before {
        transform: rotate(90deg);
      }
    `}

    ${({ icon }) => icon === 'remove' && css`
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    `}
  }

  ${({ disabled, colors: { primaryHover } }) => !disabled && css`
    cursor: pointer;

    &:hover {
      color: ${primaryHover};

      &::after {
        background-color: ${primaryHover};
      }

      i {
        &::after {
          background-color: ${primaryHover};
        }
      }
    }
  `}
`;
const UnderlineButtonWithSettings = withSettings(StyledUnderlineButton);
export { UnderlineButtonWithSettings as UnderlineButton };
