import React from 'react';
import styled from 'styled-components';

import {
  ManageEventsHostingCard,
  ManageEventsAttendingCard,
  ManageEventsHostingLoader,
  ManageEventsAttendingLoader
} from '../components';

import {
  EVENT_FILTER
} from '../../enums';

import { breakpoints } from '../../styles';


class ManageEventsList extends React.Component {
  render() {
    const { className, list, active, viewer, loading, showArea } = this.props;

    const showHostedCards = active === EVENT_FILTER.HOSTED ||
      active === EVENT_FILTER.PENDING_PUBLISH_APPROVAL ||
      active === EVENT_FILTER.DRAFT ||
      active === EVENT_FILTER.PAST;

    return (
      <div className={className}>
        {showHostedCards ? (
          loading ? <ManageEventsHostingLoader />
            : list.map((event, i) => (
              <ManageEventsHostingCard
                key={i}
                event={event}
                active={active}
                showArea={showArea}
                showEventYear={active === EVENT_FILTER.PAST}
              />
            ))
          ) : (
            loading ? <ManageEventsAttendingLoader />
              : list.map((event, i) => <ManageEventsAttendingCard event={event} viewer={viewer} key={i} showArea={showArea} />)
          )
        }
      </div>
    );
  }
}


const StyledManageEventsList = styled(ManageEventsList)`
  width: 100%;
  margin-left: 30px;
  position: relative;

  @media (${breakpoints.manageEventsListTablet}) {
    margin-left: 0;
  }

  div:first-child > div > strong {
    position: absolute;
    display: block;
    top: -28px;

    @media (${breakpoints.manageEventsListMobile}) {
      position: static;
      top: 0;
    }
  }

  div > div > strong {
    display: none;

    @media (${breakpoints.manageEventsListMobile}) {
      display: block;
      font-size: 20px;
      margin: 0 0 10px;
    }
  }
`;

export { StyledManageEventsList as ManageEventsList };
