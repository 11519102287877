import React from 'react';

import { useSettings, useRsvpPageData } from '../../hooks';

import {
  PageSubheader as Heading,
  RsvpPageSectionSubHeading as SubHeading,
  RsvpPagePotluckItem as PotluckItem,
  Divider,
  RsvpPageFooterButtons as FooterButtons
} from '../components';


export const RsvpPagePotluckItems = () => {
  const { changePage, event, readyToRsvp, loading } = useRsvpPageData();
  const { copy } = useSettings();
  const items = event.potluckItems.edges.map(({ node }) => node).filter(i => i);

  return (
    <>
      <Heading>{copy.rsvp.potluck_selection_title}</Heading>
      <SubHeading>
        {copy.rsvp.potluck_selection_instructions} <strong>{copy.rsvp.potluck_selection_instructions_bold}</strong>
      </SubHeading>
      {items && items.map((item, i) => (
        <PotluckItem key={item.id} item={item} first={i === 0} last={i === items.length - 1} />
      ))}
      <Divider />
      <FooterButtons
        onBack={() => changePage(false)}
        onContinue={() => changePage()}
        continueText={readyToRsvp ? 'RSVP' : 'Continue'}
        loading={loading}
      />
    </>
  );
};
