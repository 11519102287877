import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  SEND_INVITATIONS,
  ACCEPT_RESERVATION,
  DECLINE_RESERVATION
} from '../../mutations';

import {
  Button,
  Modal,
  SocialShareEvent,
  EventGuestsSummary,
  EventGuestsReservations,
  EventGuestsAddByEmail,
  EventInvitationMessage,
  PageSubheader as Header,
  withSettings,
  withSession,
  alert
} from '../components';

import { getAcceptedAndPendingReservations, imEventHost } from '../../libs';

const EventGuestsModal = (props) => {
  const { className, show, toggle, event, session: { viewer, theme }, settings: { copy } } = props;
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendInvitations = async (sendInvitationsMutation) => {
    const sendInvitationsInput = {
      eventId: event.id,
      emails: emails,
      text: message
    };

    setLoading(true);

    const response = await sendInvitationsMutation({ variables: sendInvitationsInput, errorPolicy: 'all' });

    setLoading(false);

    if (!response || !response.data || !response.data.sendInvitations || response.errors) {
      alert.error('Some invitations could not be sent.');
    } else {
      alert.success(`Inviation${emails.length > 1 ? 's have' : ' has'} been sent.`);
      setEmails([]);
      setMessage('');
      toggle();
    }
  };

  const { guestsPending, guestsAccepted, reservationsRejected, guestsRejected } = event.reservations;
  const { invitationsPending } = event.invitations ? event.invitations : { invitationsPending: 0 } ;
  const isHosted = imEventHost(event, viewer);
  const acceptedAndPendingReservations = getAcceptedAndPendingReservations(event);

  const ModalTitle = ({ children, small }) => theme.isVersion(2)
    ? <Header small={small}>{children}</Header>
    : small ? <h3>{children}</h3> : <h2>{children}</h2>;

  return (
    <Mutation mutation={SEND_INVITATIONS}>
      {(sendInvitationsMutation) => {
        return (
          <Mutation mutation={ACCEPT_RESERVATION}>
            {(acceptReservationMutation) => {
              return (
                <Mutation mutation={DECLINE_RESERVATION}>
                  {(declineReservationMutation) => {
                    return (
                      <Modal show={show} toggle={toggle} size="md" noPadding>
                        <div className={className}>
                          <div>
                            <ModalTitle>{isHosted ? copy.manage.guests_title : copy.manage.invite_friends}</ModalTitle>
                            {isHosted && (
                              <EventGuestsSummary
                                pendingCount={guestsPending}
                                attendingCount={guestsAccepted}
                                invitedCount={invitationsPending}
                                declinedCount={reservationsRejected}
                                deniedCount={guestsRejected}
                              />
                            )}
                            {isHosted && acceptedAndPendingReservations.length > 0 && (
                              <div className="row">
                                <div className="ot-full-container">
                                  <EventGuestsReservations
                                    reservations={acceptedAndPendingReservations}
                                    acceptReservationMutation={acceptReservationMutation}
                                    declineReservationMutation={declineReservationMutation}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row invite-friends-row">
                            <div className="ot-full-container">
                              <ModalTitle small>{copy.manage.invite_friends_email}</ModalTitle>
                            </div>
                          </div>
                          <div className="row">
                            <div className="ot-full-container">
                              <EventGuestsAddByEmail
                                emails={emails}
                                emailsChanged={(name, value) => setEmails(value)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="ot-full-container">
                              <EventInvitationMessage
                                message={message}
                                onInputChange={(name, value) => setMessage(value)}
                                placeholder={copy.guest.description_placeholder}
                              />
                            </div>
                          </div>
                          <Button
                            fullWidth={!theme.isVersion(2)}
                            small={theme.isVersion(2)}
                            themed={theme.isVersion(2)}
                            disabled={(emails.length === 0) || loading}
                            handleClick={() => sendInvitations(sendInvitationsMutation) }
                            loading={loading}
                          >Send Invite
                          </Button>
                          <SocialShareEvent event={event} />
                        </div>
                      </Modal>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
}


EventGuestsModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledEventGuestsModal = styled(EventGuestsModal)`
  text-align: center;
  padding: 40px 30px 30px;

  .row {
    margin: 0;
  }

  h2 {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      font-size: 18px;
      margin-bottom: 30px;
    `}
  }

  h3 {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      font-size: 14px;
      margin-bottom: 15px;
    `}
  }

  .ot-full-container {
    padding: 0;
  }

  .ot_social_share {
    margin-top: 30px;
  }

  .invite-friends-row {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      margin-top: 30px;
    ` : css`
      margin-top: 45px;
    `}
  }

  .ot-full-container {
    width: 100%;
  }
`;

const StyledEventGuestsModalWithSettings = withSettings(StyledEventGuestsModal);
const StyledEventGuestsModalWithSession = withSession(StyledEventGuestsModalWithSettings);
export { StyledEventGuestsModalWithSession as EventGuestsModal };
