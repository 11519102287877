import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { withSettings, withSession, LogInModal } from '../components';

import {
  EventViewLoader,
  EventViewSubNav,
  EventViewHeader,
  EventViewDetails,
  EventViewTables,
  EventViewMeal,
  EventViewHost,
  EventViewOrganization,
  EventViewSuggested,
  RsvpModalContainerPage,
  EventGuestsModal,
  EventGuestsDeclineInvitationModal,
  EventViewCancelReservationModal,
  EventCreationSuccessModal,
  VaccinationDetailsModal,
  ErrorModal,
  Modal,
  PageSubheader as Header,
  Button
} from '../components';

import { EVENT_BY_ID, EVENT_BY_UUID } from '../../queries';

import {
  hasDescription,
  hasTypes,
  hasTags,
  hasReservations,
  isProviding,
  getEventReservation,
  getEventInvitation,
  imHost,
  imReserved,
  isHostById,
  isRegisteredById,
  isVaccinated,
  addToHeapData
} from '../../libs';
import { subscriptions } from '../../libs/subscriptions';

import { alert } from '../Global';
import { EVENT_TYPE } from '../../enums';

import { RestrictionModal, RestrictionModalStatus } from '../Notification';

const defaultState = {
  showRsvpModal: false,
  showInviteFriendsModal: false,
  shouldShowCancelReservationModal: false,
  showDeclineInvitationModal: false,
  rsvpModalSelected: -1,
  showRestrictionModal: false,
  showLogInModal: false,
  modalContent: 'login',
  isAfterLogin: false,
  showSuccessModal: false,
  showAttendeesModal: false,
  rsvpModals: []
};

class EventViewPage extends React.Component {
  state = { ...defaultState };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { location, history } = this.props;

    this.setState({
      ...(location.state || {}),
      showRsvpModal: location.state?.reservationSuccessRedirect || location.state?.shouldShowPaymentModal
    }, () => {
      const { stripeCancelRedirect, reservationFailedRedirect, reservationSuccessRedirect, shouldShowPaymentModal } = this.state;
      if (stripeCancelRedirect || reservationFailedRedirect || reservationSuccessRedirect || shouldShowPaymentModal)
        history.replace(location.pathname, {});
    });
  }

  displayDetails = event => {
    return hasDescription(event) || hasTypes(event) || hasTags(event) || hasReservations(event);
  };

  displayTables = event => {
    const tables = event.tables;
    const hasTables = Array.isArray(tables) && tables.length > 0;
    return event.communityDinner && hasTables;
  };

  displayMenu = event => {
    const providedMenu = JSON.parse(event.notes);
    return event.potluck || isProviding(providedMenu);
  };

  showRestriction = ({ isAfterLogin }) => {
    const newState = { showRestrictionModal: true };
    this.setState(isAfterLogin ? this.turnAfterLoginOff(newState) : newState);
  }

  showInviteFriendsModal = () => {
    this.setState({ showInviteFriendsModal: true });
  }

  showCancelReservationModal = () => {
    this.setState({ shouldShowCancelReservationModal: true });
  }

  closeCancelReservationModal = () => {
    this.setState({ shouldShowCancelReservationModal: false });
  }

  showRsvpModal = ({ isAfterLogin, shouldShowPaymentModal, showSuccessModal }, event) => {
    const { session: { viewer } } = this.props;
    const { rsvpModals } = this.state;
    const privacySubtype = event && event.privacySubtype;

    window.dataLayer?.push({
      event: 'rsvp_modal_page_view',
      rsvp_modal_viewed_page: rsvpModals[0],
      has_membership: viewer.user?.profile.reservationMembershipRequirementExempt
        ? 'exempt'
        : !!viewer.subscriptions.length
    });

    if (shouldShowPaymentModal || showSuccessModal) {
      this.setState({
        rsvpModalSelected: 0,
        shouldShowPaymentModal: false,
        reservationSuccessRedirect: false
      });
    } else if (
      privacySubtype && privacySubtype.vaccinated &&
      viewer && viewer.user && viewer.user.profile && !viewer.user.profile.vaccinationDate
    ) {
      this.setState({ showVaccinationDetailsModal: true, isAfterLogin: false });
    } else if (
      privacySubtype && privacySubtype.vaccinated &&
      viewer && viewer.user && viewer.user.profile && !isVaccinated(event, viewer.user.profile.vaccinationDate)
    ) {
      this.setState({ showVaccinationErrorModal: true, isAfterLogin: false });
    } else {
      const newState = { rsvpModalSelected: 0 };
      this.setState(isAfterLogin ? this.turnAfterLoginOff(newState) : newState);
    }
  }

  closeRsvpModal = () => {
    this.setState({ rsvpModalSelected: -1 });
  }

  openDeclineInvitationModal = () => {
    this.setState({ showDeclineInvitationModal: true });
  }

  closeDeclineInvitationModal = () => {
    this.setState({ showDeclineInvitationModal: false });
  }

  closeEventGuestsModal = () => {
    this.setState({ showInviteFriendsModal: false });
  }

  changeRsvpModalSelected = (modalSelected) => {
    const { session: { viewer } } = this.props;
    const { rsvpModals } = this.state;
    window.dataLayer?.push({
      event: 'rsvp_modal_page_view',
      rsvp_modal_viewed_page: rsvpModals[modalSelected],
      has_membership: subscriptions.has_membership(viewer)
    });
    this.setState({ rsvpModalSelected: modalSelected });
  }

  reservationCancelled = async (refetch) => {
    const { settings: { copy } } = this.props;
    await refetch(false);
    this.setState({ shouldShowCancelReservationModal: false });
    alert.success(copy.event.reservation_cancelled);
    return true;
  }

  reservationCreated = () => {
    this.setState({ rsvpModalSelected: -1 });
  }

  handleRestrictionModalClose = () => {
    this.setState({ isAfterLogin: true });
  }

  handleRestrictionModalToggle = () => {
    this.setState(prevState => ({
      showRestrictionModal: !prevState.showRestrictionModal
    }));
  };

  handleLogInModalToggle = () => {
    this.setState({
      showLogInModal: !this.state.showLogInModal
    });
  }

  updateLoginModalContent = (content, openModal) => {
    const newState = { modalContent: content };

    if (openModal) {
      newState.showLogInModal = true;
    }

    this.setState(newState);
  };

  handleAfterLogin = () => {
    this.setState({
      showLogInModal: false,
      isAfterLogin: true
    });
  }

  turnAfterLoginOff = (state) => {
    return { ...state, isAfterLogin: false };
  }

  toggleSuccessModal = () => {
    this.setState({ showSuccessModal: !this.state.showSuccessModal });
  };

  handleInviteFriendsSuccess = () => {
    alert.success('Your friends have been successfully invited.');
    this.toggleSuccessModal();
  };

  checkHostOrReserved = event => {
    const {
      session: { viewer },
      settings: { event: { hide_extra_details_until_reservation_approval } }
    } = this.props;
    const viewerReservations = viewer && viewer.reservations ? viewer.reservations.nodes : [];
    const hosts = event.eventHosts.edges;
    return (
      !hide_extra_details_until_reservation_approval ||
      imHost(hosts, viewer) ||
      imReserved(event, viewerReservations, 'ACCEPTED')
    );
  };

  // temporary. need to hold state of rsvpModals modals here because query refreshes after rsvp //
  // for public dinners only (?) this resets rsvpModals, making the success modal not show //
  setRsvpModals = rsvpModals => this.setState({ rsvpModals });

  handleToggleVaccinationModal = (shouldContinue, event) => {
    const { session: { viewer } } = this.props;

    const state = { showVaccinationDetailsModal: false };

    if (shouldContinue && viewer.user && viewer.user.profile) {
      if (!isVaccinated(event, viewer.user.profile.vaccinationDate)) {
        state.showVaccinationErrorModal = true;
      } else {
        state.rsvpModalSelected = 0;
      }
    }
    this.setState(state);
  };

  handleLogInModalToggleForAttendees = () => {
    this.setState({
      showLogInModal: !this.state.showLogInModal,
      showAttendeesModal: true
    });
  }

  toggleRsvpCancelModal = key => this.setState({ [key]: false });

  render() {
    const { className, match, session, settings } = this.props;
    const { copy } = settings;
    const {
      showRestrictionModal,
      rsvpModalSelected,
      showRsvpModal,
      shouldShowCancelReservationModal,
      showLogInModal,
      modalContent,
      isAfterLogin,
      showSuccessModal,
      rsvpModals,
      showVaccinationDetailsModal,
      showVaccinationErrorModal,
      showAttendeesModal,
      shouldShowPaymentModal,
      stripeCancelRedirect,
      reservationFailedRedirect,
      afterMembershipSuccess,
      reservationSuccessRedirect
    } = this.state;
    const { showDeclineInvitationModal, showInviteFriendsModal } = this.state;
    const { viewer, refetch: refetchViewer } = session;
    const reservationNodes = viewer && viewer.reservations ? viewer.reservations.nodes : [];
    const eventEdges = viewer && viewer.hostedEvents ? viewer.hostedEvents.edges : [];

    const restriction = { content: null, showRestriction: this.showRestriction };

    let query;
    const queryVars = { isLogged: !!(viewer?.user) };

    if (match.params.id) {
      query = EVENT_BY_ID;
      queryVars.id = parseInt(match.params.id);
      queryVars.isHost = isHostById(match.params.id, eventEdges, false);
      queryVars.isRegistered = isRegisteredById(match.params.id, reservationNodes, false);
    } else {
      query = EVENT_BY_UUID;
      queryVars.uuid = match.params.uuid;
      queryVars.isHost = isHostById(match.params.uuid, eventEdges, true);
      queryVars.isRegistered = isRegisteredById(match.params.uuid, reservationNodes, true);
    }

    return (
      <div className={className} id="event_view">
        <Query query={query} variables={queryVars} errorPolicy="all">
          {({ data = {}, loading, refetch }) => {
            if (!data.event && loading) return <EventViewLoader />;

            // if (error) console.error('single event query: ', error);

            const event = data.event;
            const areas = session.areas;
            const eventInvitation = getEventInvitation(event, viewer);
            const isntSoloHost = event && event.eventType === EVENT_TYPE.SOLO && !queryVars.isHost;

            restriction.content = session.viewer && RestrictionModalStatus(copy, session, event, this.props.settings, 'rsvp');

            if (isAfterLogin && !showAttendeesModal) {
              if (restriction.content) {
                this.showRestriction({ isAfterLogin: true });
              } else {
                this.showRsvpModal({ isAfterLogin: true }, event);
              }
            }

            if (shouldShowPaymentModal) {
              this.showRsvpModal({ shouldShowPaymentModal: true }, event);
            }

            if (reservationSuccessRedirect) {
              this.showRsvpModal({ showSuccessModal: true }, event);
            }

            if (!event || isntSoloHost) return <Redirect to="/" />;

            const reservation = getEventReservation(event, viewer);

            const refetchData = (isReserved = true) => {
              queryVars.isRegistered = isReserved;

              return Promise.all([
                refetch(queryVars),
                refetchViewer()
              ]);
            };

            if (event) {
              addToHeapData(event);
            }

            return (
              <div>
                <RsvpModalContainerPage
                  show={showRsvpModal}
                  modalSelected={rsvpModalSelected}
                  changeRsvpModalSelected={this.changeRsvpModalSelected}
                  closeRsvpModalContainer={this.closeRsvpModal}
                  reservationCreated={() => this.reservationCreated()}
                  event={event}
                  areas={areas}
                  viewer={viewer}
                  refetchData={refetchData}
                  rsvpModals={rsvpModals}
                  setRsvpModals={this.setRsvpModals}
                  showPaymentModal={shouldShowPaymentModal}
                  showSuccessModal={reservationSuccessRedirect}
                  afterMembershipSuccess={afterMembershipSuccess}
                />
                <EventViewCancelReservationModal
                  show={shouldShowCancelReservationModal}
                  toggle={this.closeCancelReservationModal}
                  reservation={reservation}
                  reservationCancelled={() => this.reservationCancelled(refetchData)}
                />
                <EventGuestsModal
                  show={showInviteFriendsModal}
                  toggle={this.closeEventGuestsModal}
                  event={event}
                  viewer={viewer}
                />
                <ReservationCanceledModal
                  show={stripeCancelRedirect}
                  toggle={() => this.toggleRsvpCancelModal('stripeCancelRedirect')}
                  title={copy.membership?.cancel_modal_title || 'Reservation Canceled'}
                  content={copy.membership?.cancel_modal_message || 'Some info about your recently canceled reservation/membership.'}
                />
                <ReservationCanceledModal
                  show={reservationFailedRedirect}
                  toggle={() => this.toggleRsvpCancelModal('reservationFailedRedirect')}
                  title={copy.rsvp.no_membership_modal_title || 'Reservation Canceled'}
                  content={copy.rsvp.no_membership_modal_message || 'A membership is required to RSVP to this event.'}
                />
                <EventGuestsDeclineInvitationModal
                  show={showDeclineInvitationModal}
                  toggle={this.closeDeclineInvitationModal}
                  invitationId={eventInvitation ? eventInvitation.id : null}
                />
                {session.viewer && restriction.content && (<RestrictionModal
                  show={showRestrictionModal}
                  toggle={this.handleRestrictionModalToggle}
                  content={restriction.content}
                  onClose={this.handleRestrictionModalClose}
                />)}
                <EventViewSubNav
                  event={event}
                  session={session}
                  restriction={restriction}
                  displayDetails={this.displayDetails(event)}
                  displayTables={this.displayTables(event)}
                  displayMenu={this.displayMenu(event)}
                  showRsvpModal={this.showRsvpModal}
                  showInviteFriendsModal={this.showInviteFriendsModal}
                  showCancelReservationModal={this.showCancelReservationModal}
                  openDeclineInvitationModal={this.openDeclineInvitationModal}
                  toggleLoginModal={this.handleLogInModalToggle}
                />
                <EventViewHeader
                  event={event}
                  areas={areas}
                  viewer={viewer}
                  restriction={restriction}
                  showRsvpModal={props => this.showRsvpModal(props, event)}
                  showInviteFriendsModal={this.showInviteFriendsModal}
                  showCancelReservationModal={this.showCancelReservationModal}
                  openDeclineInvitationModal={this.openDeclineInvitationModal}
                  toggleLoginModal={this.handleLogInModalToggle}
                  isHostOrReservedGuest={this.checkHostOrReserved(event)}
                />
                <EventViewDetails
                  event={event}
                  display={this.displayDetails(event)}
                  viewer={viewer}
                  isHostOrReservedGuest={this.checkHostOrReserved(event)}
                  toggleLoginModal={this.handleLogInModalToggleForAttendees}
                  showAttendeesModal={isAfterLogin && showAttendeesModal}
                />
                <EventViewTables event={event} display={this.displayTables(event)} />
                <EventViewMeal event={event} display={this.displayMenu(event)} />
                <EventViewHost event={event} />
                <EventViewOrganization event={event} />
                {false && !!(event.suggestedEvents && event.suggestedEvents.nodes && event.suggestedEvents.nodes.length) &&
                  <EventViewSuggested suggestedEvents={event.suggestedEvents} />
                }
                <LogInModal
                  show={showLogInModal}
                  toggle={this.handleLogInModalToggle}
                  updateModalContent={this.updateLoginModalContent}
                  content={modalContent}
                  shouldRedirect={false}
                  afterLogin={this.handleAfterLogin}
                />
                <EventCreationSuccessModal
                  show={showSuccessModal}
                  toggle={this.toggleSuccessModal}
                  event={event}
                  viewer={viewer}
                  onInviteFriendsSuccess={this.handleInviteFriendsSuccess}
                  areas={session.areas}
                />

                <VaccinationDetailsModal
                  show={showVaccinationDetailsModal}
                  toggle={val => this.handleToggleVaccinationModal(val, event)}
                  action="rsvp"
                />
                <ErrorModal
                  show={showVaccinationErrorModal}
                  toggle={() => this.setState(prev => ({ showVaccinationErrorModal: !prev.showVaccinationErrorModal }))}
                >
                  <p>{copy.rsvp.guest_requires_vaccination_error_message}</p>
                </ErrorModal>
              </div>
            );
        }}
        </Query>
      </div>
    );
  }
}

const StyledEventViewPage = styled(EventViewPage)`
  padding: 10px 20px;

  h2 {
    &.card_title {
      color: ${props => props.settings.theme.css.global.colors.primary};
      text-align: center;
      font-size: 21px;
      font-weight: normal !important;
      margin: 0;
    }
  }

  h4 {
    &.sub_heading {
      font-size: 14px;
      text-align: center;
      margin: 30px 0 15px;
    }
  }
`;

const ReservationCanceledModal = ({ show, toggle, title, content }) => (
  <Modal
    show={show}
    toggle={toggle}
    size="sm"
    noPadding
  >
    <ModalContainer>
      <Header small>{title}</Header>
      <p dangerouslySetInnerHTML={{ __html: content }} />
      <Button handleClick={toggle} fullWidth>Close</Button>
    </ModalContainer>
  </Modal>
);

const ModalContainer = styled.div`
  position: relative;
  text-align: center;
  padding: 40px 30px 30px;

  p {
    font-size: 14px;
    margin-bottom: 30px;
  }
`;


const EventViewPageWithRouter = withRouter(StyledEventViewPage);
const EventViewPageWithRouterWithSettings = withSettings(EventViewPageWithRouter);
const EventViewPageWithSession = withSession(EventViewPageWithRouterWithSettings);

export { EventViewPageWithSession as EventViewPage };
