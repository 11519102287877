import React from 'react';
import styled from 'styled-components';
import { imCreator, imReserved, isPast } from '../../../libs';
import { EVENT_TYPE } from '../../../enums';
import { withSettings } from '../../components';

const EventCardNotifications = (props) => {
  const { className, event, viewer, viewerReservations } = props;
  const { settings: { copy, event: { virtual } } } = props;

  const isUserLoggedIn = viewer && viewer.user;
  const hosts = event.eventHosts && event.eventHosts.edges;

  if (!hosts || !hosts.length) return null;
  if (!isUserLoggedIn) { return null; }
  if (isPast(event)) { return null; }

  const isSolo = event.eventType === EVENT_TYPE.SOLO
  const isPublic = event.eventType !== EVENT_TYPE.PRIVATE && !isSolo;
  const reservations = event.reservations;

  let hasBanner = true;
  let bannerStyle = '';
  let bannerCopy = '';

  if (imCreator(hosts, viewer)) {
    // im host
    bannerStyle = 'success';
    bannerCopy = copy.event.host_dinner;

  } else if (imReserved(event, viewerReservations)) {
    // im going
    bannerStyle = 'success';
    bannerCopy = copy.event.youre_going;

  } else if (isPublic && imReserved(event, viewerReservations, 'PENDING')) {
    // pending host approval
    bannerStyle = 'warning';
    bannerCopy = copy.event.pending_host_approval;

  } else if (isPublic && imReserved(event, viewerReservations, 'REJECTED')) {
    // reservation rejected
    bannerStyle = 'important';
    bannerCopy = copy.event.rejected;

  } else if (isPublic && reservations && reservations.guestsAccepted === (event.numberOfGuestsMax - 1)) {
    // one seat left
    bannerStyle = 'warning';
    bannerCopy = copy.event.last_seat;

  } else if (isPublic && reservations && reservations.guestsAccepted >= event.numberOfGuestsMax) {
    // dinner is full
    bannerStyle = 'important';
    bannerCopy = copy.event.full_dinner;

  } else if (isPublic && !event.canSubscribe) {
    // registration is closed
    bannerStyle = 'important';
    bannerCopy = copy.event.registration_closed;

  } else if (isSolo) {
    // solo event
    bannerStyle = 'success';
    bannerCopy = copy.event.solo_banner_text || copy.event.solo_dinner_title;

  } else if (event.virtual && !virtual.hide_virtual_card_banners) {
    // virtual dinner
    bannerStyle = 'success';
    bannerCopy = copy.event.virtual_banner_text;

  } else {
    hasBanner = false;
  }

  if (hasBanner) return <div className={`${className} ${bannerStyle} card_notification`}>{bannerCopy}</div>;

  return null;
};

const StyledEventCardNotifications = styled(EventCardNotifications)`
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 3px 0;

  &.success {
    background-color: ${props => props.settings.theme.css.global.colors.successTransparent};
  }

  &.warning {
    background-color: ${props => props.settings.theme.css.global.colors.warningTransparent};
  }

  &.important {
    background-color: ${props => props.settings.theme.css.global.colors.importantTransparent};
  }
`;

const StyledEventCardNotificationsWithSettings = withSettings(StyledEventCardNotifications);

export { StyledEventCardNotificationsWithSettings as EventCardNotifications };
