import React from 'react';
import styled from 'styled-components';

import {
  Card,
  EventViewMealPotluck,
  EventViewMealProviding
} from '../components';

import { withSettings } from '../components';

import { breakpoints } from '../../styles';

const EventViewMeal = (props) => {
  const { className, display, event } = props;
  const { settings: { copy } } = props;
  const providedMenu = JSON.parse(event.notes);

  return display
    ? (
      <Card className={className} id="menu">
        <h2 className="card_title">{copy.event.menu_title}</h2>
        <i className="divider" />
        {event.potluck
          ? <EventViewMealPotluck event={event} />
          : <EventViewMealProviding event={event} menu={providedMenu} />
        }
      </Card>
    ) : null;
};


const StyledEventViewMeal = styled(EventViewMeal)`
  &.ot_card {
    ${props => !props.event.potluck
      ? `@media (${breakpoints.tablet}) {
          padding: 60px 40px;
        }`
      : ''
    }
  }
`;

const EventViewMealWithSettings = withSettings(StyledEventViewMeal);

export { EventViewMealWithSettings as EventViewMeal };
