import gql from 'graphql-tag';

export const LOG_IN_WITH_PASSWORD = gql`
  mutation ($email: String!, $password: String!) {
    logInWithPassword(input: { email: $email, password: $password }) {
      token
      user {
        id
        profile {
          id
          area {
            id
            url
            nourishmentLockDaysBeforeEvent
          }
        }
      }
      status
      userId
      userMessage
      userPhone
      errors { message }
    }
  }
`;

export const LOG_IN_WITH_SOCIAL_AUTH = gql`
  mutation ($provider: String!, $uid: String!, $token: String!) {
    logInWithSocialAuth(input: {
      provider: $provider,
      uid: $uid,
      token: $token
    }) {
      token
      user {
        id
        profile {
          id
          area {
            id
            url
            nourishmentLockDaysBeforeEvent
          }
        }
      }
      status
      userId
      userMessage
      userPhone
      errors { message, path }
      newUser
    }
  }
`;

export const SEND_USER_OTP = gql`
  mutation ($userMessage: String!, $userPhone: String, $userId: Int!) {
    sendUserOtp(input: { userMessage: $userMessage, userPhone: $userPhone, userId: $userId }) {
      status
      errors { message }
    }
  }
`;

export const LOG_IN_WITH_OTP = gql`
  mutation ($userMessage: String!, $otp: String!) {
    logInWithOtp(input: { userMessage: $userMessage, otp: $otp }) {
      token
      user {
        id
        profile {
          id
          area {
            id
            url
            nourishmentLockDaysBeforeEvent
          }
        }
      }
      status
      userId
      userMessage
      userPhone
      errors { message }
    }
  }
`;

export const CREATE_USER = gql`
  mutation (
    $email: String!,
    $password: String!,
    $firstName: String!,
    $lastName: String!,
    $zipCode: String,
    $dateOfBirth: ISO8601DateTime,
    $userAreaId: Int,
    $neighborhoodId: Int,
    $phone: String
  ) {
    createUser(input: {
      email: $email,
      password: $password,
      firstName: $firstName,
      lastName: $lastName,
      zipCode: $zipCode,
      dateOfBirth: $dateOfBirth,
      userAreaId: $userAreaId,
      neighborhoodId: $neighborhoodId,
      phone: $phone
    }) {
      token
      user { id }
      status
      userId
      userMessage
      userPhone
      errors { message, path }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation (
    $email: String,
    $password: String
  ) {
    updateUser(input: {
      email: $email,
      password: $password
    }) {
      user { id }
      errors { message }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation (
    $firstName: String,
    $lastName: String,
    $bio: String,
    $dateOfBirth: ISO8601DateTime,
    $address: String,
    $secondaryAddress: String,
    $city: String,
    $cityState: String,
    $zipCode: String,
    $country: String,
    $phone: String,
    $facebook: String,
    $instagram: String,
    $twitter: String,
    $snapchat: String,
    $showAge: Boolean,
    $eventHostedVisible: Boolean,
    $eventAttendedVisible: Boolean,
    $showOnGuestList: Boolean,
    $showLanguageOnEvents: Boolean,
    $answers: JSON,
    $vaccinationDate: ISO8601Date,
    $reservationMembershipRequirementExempt: Boolean
  ) {
    updateProfile(input: {
      firstName: $firstName,
      lastName: $lastName,
      bio: $bio,
      dateOfBirth: $dateOfBirth,
      address: $address,
      secondaryAddress: $secondaryAddress,
      city: $city,
      cityState: $cityState,
      zipCode: $zipCode,
      country: $country,
      phone: $phone,
      facebook: $facebook,
      instagram: $instagram,
      twitter: $twitter,
      snapchat: $snapchat,
      showAge: $showAge,
      eventHostedVisible: $eventHostedVisible,
      eventAttendedVisible: $eventAttendedVisible,
      showOnGuestList: $showOnGuestList,
      showLanguageOnEvents: $showLanguageOnEvents,
      answers: $answers,
      vaccinationDate: $vaccinationDate,
      reservationMembershipRequirementExempt: $reservationMembershipRequirementExempt
    }) {
      profile {
        id
        firstName
        lastName
        bio
        dateOfBirth
        address
        secondaryAddress
        city
        cityState
        zipCode
        country
        phone
        facebook
        instagram
        twitter
        snapchat
        showAge
        eventHostedVisible
        eventAttendedVisible
        showOnGuestList
        answers
        vaccinationDate
        reservationMembershipRequirementExempt
        avatar {
          id
          photo {
            originalUrl
            smallUrl
            mediumUrl
            largeUrl
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_PROFILE_PICTURE = gql`
  mutation (
    $avatarRemoteUrl: String!,
  ) {
    updateProfile(input: {
      avatarRemoteUrl: $avatarRemoteUrl
    }) {
      profile {
        id
        bio
      }
      errors {
        message
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation (
    $id: Int!,
    $message: Html!
  ) {
    messageProfile(input: {
      id: $id,
      message: $message
    }) {
      errors {
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($email: String!) {
    resetPassword(input: { email: $email }) {
      errors { message }
    }
  }
`;

export const RESET_PASSWORD_WITH_TOKEN = gql`
  mutation ($password: String!, $resetPasswordToken: String!) {
    resetPasswordWithToken(input: {
      password: $password,
      resetPasswordToken: $resetPasswordToken
    }) {
      token
      user { id }
      status
      userId
      userMessage
      userPhone
      errors { message }
    }
  }
`;

export const HOST_REQUEST = gql`
  mutation {
    hostRequest(input: {}) {
      clientMutationId
    }
  }
`;

export const LOGIN_WITH_OAUTH_TOKEN = gql`
  mutation ($oauthToken: String!) {
    logInWithOauthToken(input: { oauthToken: $oauthToken }) {
      token
      user {
          id
          profile {
            id
            area {
              id
              url
              nourishmentLockDaysBeforeEvent
            }
          }
        }
      status
      userId
      userMessage
      userPhone
      errors { message }
    }
  }
`
