import React, { useState, useEffect } from 'react';
import { isSite } from './libs';

const SessionContext = React.createContext();

const SessionContextProvider = ({ children, session, settings }) => {
  const [viwerAvatarImage, setViewerAvatarImage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [removeHeaderFooter, setRemoveHeaderFooter] = useState(false);

  useEffect(() => {
    if (session?.viewer?.user?.profile?.avatar?.photo?.mediumUrl && !viwerAvatarImage) {
      setViewerAvatarImage(session.viewer.user.profile.avatar.photo.mediumUrl);
    }
  }, [session]);

  const setNotification = val => setShowNotification(val);
  const notification = { show: showNotification, setNotification };

  const hideHeaderFooter = (pathname) => {
    const embedRegex = new RegExp(/^\/events\/(\w|-)*\/embed$/);
    const resourcesRegex = new RegExp(/^\/events\/(\w|-)*\/resources$/);
    const isOTResources = isSite(settings, 'onetable') && resourcesRegex.test(pathname);
    return embedRegex.test(pathname) || isOTResources || pathname === '/journey';
  };

  const value = {
    ...session,
    session,
    notification,
    viwerAvatarImage,
    setViewerAvatarImage,
    layout: {
      removeHeaderFooter,
      setRemoveHeaderFooter,
      hideHeaderFooter
    }
  };

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionContextProvider };
