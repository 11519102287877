import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  LandingPageContext,
  LandingHeroSearchAreaSelector,
  LandingHeroSearchDateSelector,
  withSettings
} from '../components';

import ROUTES from '../../constants/routes';


const LandingHeroSearchField = ({ className, history }) => {
  const { area } = useContext(LandingPageContext);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if (!selectedArea && area) setSelectedArea(area);
  }, [area, selectedArea, setSelectedArea]);

  const handleSearchRedirect = () => {
    console.log('search: ', selectedArea, selectedDates);
    selectedArea && history.push(`/${ROUTES.SEARCH}/${selectedArea.url}`, {
      selectedArea,
      selectedDates
    });
  };

  return (
    <div className={className}>
      <LandingHeroSearchAreaSelector
        area={selectedArea}
        onSelectArea={a => setSelectedArea(a)}
      />
      <LandingHeroSearchDateSelector
        dates={selectedDates}
        onSelectDates={d => setSelectedDates([d])}
        onSearch={handleSearchRedirect}
      />
    </div>
  );
};

export const FieldLabel = styled.label`
  color: #63615995;
  font-size: 13px;
  display: block;
  line-height: 1;
  margin: 0 0 7px;
  cursor: pointer;
`;

export const FieldValue = styled.span`
  display: block;
  line-height: 1;
  font-family: 'Avenir-Medium' !important;

  ${props => props.notSelected && css`
    color: #81807c;
    transition: color 250ms;
  `}
`;

const StyledLandingHeroSearchField = styled(LandingHeroSearchField)`
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 0;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: 1fr 1fr;

  & > div {
    position: relative;
    padding: 0 20px;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    grid-template-columns: 1fr;

    label {
      margin: 0 0 8px;
    }
  }
`;
const LandingHeroSearchFieldWithSettings = withSettings(StyledLandingHeroSearchField);
const LandingHeroSearchFieldWithRouter = withRouter(LandingHeroSearchFieldWithSettings);
export { LandingHeroSearchFieldWithRouter as LandingHeroSearchField };
