import { useState, useEffect } from 'react';
import { getScrollPos } from '../libs';

export const usePageScroll = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleSetScrolled = () => {
    const boundary = 100;

    if (!scrolled && getScrollPos().y > boundary) {
      setScrolled(true);
    } else if (scrolled && getScrollPos().y <= boundary) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSetScrolled);
    return () => {
      window.removeEventListener('scroll', handleSetScrolled);
    }
  }, [scrolled]);

  return { scrolled };
};
