import { useEffect, useState } from 'react';
import { useSettings } from '../';
import { trigger } from '../../libs/customEvent';


export const useReservationQuestions = (event) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const { event: { global_reservation_question_answers = '[]' } } = useSettings();

  useEffect(() => {
    if (event && !questions.length) {
      const { reservationQuestions = {} } = event;
      if (!reservationQuestions.edges) return;

      const resQuestions = reservationQuestions.edges.map(q => q.node).map(q => {
        const { baseQuestion } = q;
        if (baseQuestion && baseQuestion.presenceType && baseQuestion.presenceType === 'ALWAYS') {
          const alwaysAnswers = JSON.parse(global_reservation_question_answers);
          const ans = alwaysAnswers && alwaysAnswers.find(a => a.id === baseQuestion.id);
          q.always = true;
          q.options = ans ? ans.options : [];
        }
        return q;
      });

      const resAnswers = resQuestions && resQuestions.reduce((acc, q) => (
        { ...acc, [q.id]: '' }
      ), {});

      if (resQuestions && resQuestions.length) {
        setQuestions(resQuestions);
        setAnswers(resAnswers);
      }
    }
  }, [event, questions, setQuestions, answers, setAnswers]);

  const validateAnswers = (triggerEvent = true) => {
    let valid = true;

    for (const id in answers) {
      if (!answers[id]) {
        valid = false;
        triggerEvent && trigger('rsvpPage:validateQuestions', { id });
      }
    }

    return valid;
  };

  const sortFunction = (q1, q2) => {
    if (q1.position === q2.position) return 0;
    if (q1.position < q2.position) return -1;
    if (q1.position > q2.position) return 1;
  };

  const sort = qs => [
    ...(qs.filter(({ always }) => always)).sort(sortFunction),
    ...(qs.filter(({ always }) => !always)).sort(sortFunction)
  ];

  return {
    questions: sort(questions.filter(q => !q.disabled)),
    answers,
    setAnswers: ([key, val]) => setAnswers(ans => ({ ...ans, [key]: val })),
    setAnswersAll: val => setAnswers(val),
    validateAnswers
  };
};
