import React, { Fragment, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SessionContext } from '../../Context';
import { RestrictionModalContext } from '../../RestrictionModalContext';

import useWordpressMenu from './useWordpressMenu';
import {
  DefaultMainMenu,
  HamburgerMainMenu,
  AreaMenu,
  SubtypeMenu,
  DropDownMenu,
  HoverMenu,
  FAIcon,
  withSettings
} from '../components';
import { dom } from '../../libs';


const WordpressLink = ({ type, title, url, target, useMobileMenu, className = '' }) => {
  const decodedTitle = document.createElement('textarea');
  decodedTitle.innerHTML = title;

  // SubtypeMenu
  switch (type) {
    case 'areas':
      return <AreaMenu title={title} useMobileMenu={useMobileMenu} />;
    case 'subtypes':
    case 'area-subtypes':
      return <SubtypeMenu title={title} useMobileMenu={useMobileMenu} byArea={type === 'area-subtypes'} />;
    case 'link':
      return target === '_blank'
        ? <a href={url} target="_blank" rel="noopener noreferrer" className={className}>{decodedTitle.value}</a>
        : <a href={url} className={className}>{decodedTitle.value}</a>;
    default:
      return <NavLink to={url}>{decodedTitle.value}</NavLink>;
  }
};

// given a wordpress menu item, render a top level menu item
const TopLevelMenuItemWithoutSettings = ({ children, className, classes, settings, hamburger, hostPending, ...props }) => {
  const [displayOnMobile, setDisplayOnMobile] = useState(false);
  const isMobile = dom.isMobile(settings) || hamburger;
  let item;

  if (props.url === '/host/promote' && hostPending) return null;

  const toggleMobileMenu = (e, propagateEvent) => {
    if (!propagateEvent) e.stopPropagation();
    if (isMobile) setDisplayOnMobile(!displayOnMobile);
  };

  if (props.type === 'areas' || !children || children.length === 0) {
    item = <WordpressLink useMobileMenu={hamburger} {...props} />;
  } else {
    item = (
      <HoverMenu useMobileMenu={hamburger}>
        <div className={`dropdown_menu_wrapper ${displayOnMobile ? 'display' : ''}`}>
          {isMobile
            ? <span className="mobile_nav_link" onClick={toggleMobileMenu}>{props.title}</span>
            : (props.type === 'link'
              ? (
                <WordpressLink
                  type={props.type}
                  title={props.title}
                  url={props.url}
                  className="dropdown_link"
                  useMobileMenu={hamburger}
                />
              ) : <NavLink to={props.url} className="dropdown_link">{props.title}</NavLink>)
          }
          <FAIcon name={[ 'fas', 'caret-down' ]} />
        </div>
        <DropDownMenu className={displayOnMobile ? 'display' : ''} fullHeight>
          {children.map((child, i) => (
            <li key={i} className={(child.classes || []).join(' ')} onClick={e => toggleMobileMenu(e, true)}>
              <WordpressLink useMobileMenu={hamburger} {...child} />
            </li>
          ))}
        </DropDownMenu>
      </HoverMenu>
    );
  }
  const combinedClassName = [
    ...(className ? [className] : []),
    ...(classes || [])
  ].join(' ');
  return (
    <li className={combinedClassName}>
      {item}
    </li>
  );
};

const TopLevelMenuItem = withSettings(TopLevelMenuItemWithoutSettings);

const RestrictedMenuItem = ({ title, restriction, classes }) => (
  <li className={(classes || []).join(' ')}>
    <span className="restricted_link" onClick={restriction.showRestriction}>
      {title}
    </span>
  </li>
);
const MainMenu = ({ openMobileNav, toggleMobileNav, overHero, hamburger, settings }) => {
  const { error, menu } = useWordpressMenu(settings);
  const { session } = useContext(SessionContext);
  const restriction = useContext(RestrictionModalContext)
  const user = session.viewer ? session.viewer.user : null;
  const hasRestriction = restriction && restriction.content !== null;
  const { copy } = settings;

  // menu to display if there's an error fetching the menu from wordpress
  const errorMenu = [
    { type: 'areas' },
    { type: 'route', url: '/host/promote', title: copy.menu.become_host }
  ];

  const host = user && user.state === 'host';
  const hostPending = user && user.state === 'awaiting_for_host';
  const hasCreateEventMenuItem = () =>
    menu.find(({ type, url }) => type === 'route' && url === '/events');

  const menuItems = (error ? errorMenu : menu)
    .map((item, i) => {
      if (item.type === 'route') {
        if (item.url === '/events' && host && hasRestriction) {
          return (
            <RestrictedMenuItem key={i} {...item} restriction={restriction} />
          );
        }
        if (item.url === '/host/promote' && !hostPending) {
          if (host) {
            if (hasCreateEventMenuItem()) {
              return null;
            }
            // if it's a host and there's no /events menu item,
            // replace /host/promote with one
            return (
              <TopLevelMenuItem
                key={i}
                type="route"
                url="/events"
                title={copy.menu.create_dinner}
                hamburger={hamburger}
              />
            );
          }
          if (hasRestriction) {
            return <Fragment key={i}><RestrictedMenuItem {...item} restriction={restriction} /></Fragment>;
          }
        } else if (item.url === '/events' && !host) {
          return null;
        }
      }
      return <TopLevelMenuItem {...item} key={i} hamburger={hamburger} hostPending={hostPending} />;
    }, [])
    .filter(item => item); // remove any items not displayed

  const classList = ['main_menu_container'];
  if (openMobileNav) classList.push('open');
  if (host) classList.push('is_host');

  if (hamburger) {
    return (
      <HamburgerMainMenu
        classList={classList}
        menuItems={menuItems}
        toggleMobileNav={toggleMobileNav}
        overHero={overHero}
      />
    );
  } else {
    return (
      <DefaultMainMenu
        classList={classList}
        menuItems={menuItems}
        toggleMobileNav={toggleMobileNav}
      />
    );
  }
};

const MainMenuWithSettings = withSettings(MainMenu);
export { MainMenuWithSettings as MainMenu };
