import React, { useState, useEffect } from 'react';
import { Input } from '../../components';


export const NumberInput = ({ name, handleFilterChange, reset, min, max }) => {
  const [value, setValue] = useState('');
  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    if (reset.shouldReset !== shouldReset) {
      setValue('');
      setShouldReset(reset.shouldReset);
      handleFilterChange(name, null);
    }
  }, [reset, name, handleFilterChange, shouldReset]);

  const handleInputChange = (inputName, inputValue) => {
    setValue(inputValue);
    handleFilterChange(name, inputValue);
  };

  return (
    <Input
      type="number"
      name={name}
      min={min || '0'}
      max={max}
      value={value}
      onInputChange={handleInputChange}
    />
  );
};
