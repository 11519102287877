import React from 'react';
import styled, { css } from 'styled-components';

import { withSettings } from '../components';

export const TagsList = ({ tags, onTagClick, style = {} }) => (
  <List style={style}>
    {tags.map((tag, i) => (
      <Tag
        key={i}
        withClick={!!onTagClick}
        onClick={() => onTagClick && onTagClick(tag)}
      >{tag.toLowerCase()}</Tag>
    ))}
  </List>
);

const List = styled.ul`
  list-style-type: none;
  margin: 0 0 25px;
  padding: 0;
`;

const TagWithoutSettings = styled.li`
  display: inline-block;
  border: 1px solid #ceccc7;
  border-radius: 5px;
  margin: 0 15px 15px 0;
  font-size: 14px;
  font-family: 'Avenir-Medium' !important;
  font-weight: 600;
  text-transform: capitalize;
  padding: 16px 22px;
  background-color: #fff;
  line-height: 1;

  ${({ withClick }) => withClick && css`
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${props => props.settings.theme.css.global.colors.primary};
    }
  `}
`;
const Tag = withSettings(TagWithoutSettings);
