import styled from 'styled-components';

export const LocationDetail = styled.span`
  display: block;
  font-size: 15px;
  padding-bottom: 13px;
  padding-left: 40px;
  position: relative;

  svg {
    position: absolute;
    top: 9px;
    left: 12px;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #97958f;
  }
`;
