import React from 'react';
import styled from 'styled-components';

const Tooltip = ({ className, children, inverted }) => (
  <div className={[className, 'ot_tooltip', inverted ? 'inverted' : ''].join(' ').trim()}>{children}</div>
);

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  bottom: 100%;
  margin-bottom: -5px;
  font-size: 10px;
  background-color: #fff;
  padding: 7px 5px;
  border: 1px solid #dadada;
  border-radius: 3px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.13);

  &::before,
  &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
  }

  &::before {
      border: 8px solid;
      border-color: #fff transparent transparent;
      z-index: 1;
  }
  &::after {
      border: 9px solid;
      border-color: #dadada transparent transparent;
      z-index: -1;
  }

  &.inverted {
    &::before,
    &::after {
      top: auto;
      bottom: 100%;
    }

    &::before {
        border-color: transparent transparent #fff;
    }
    &::after {
        border-color: transparent transparent #dadada;
    }
  }
`;

export { StyledTooltip as Tooltip };
