import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// toast.configure({
//   position: 'top-right',
//   autoClose: 100000,
//   draggable: false,
//   hideProgressBar: true,
//   closeOnClick: true,
//   pauseOnHover: false
// });

const AlertWrapper = () => <ToastContainer />;

export { AlertWrapper as Alert, toast as alert };
