import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSession, useSettings } from '../hooks';

import { setToken } from '../libs/token';

import { useMutation } from '@apollo/react-hooks';
import { LOGIN_WITH_OAUTH_TOKEN } from '../mutations';

import * as userJourney from '../libs/userJourney';

const OAUTH_TOKEN_PARAM_KEY = 't';
const HEADER_FOOTER_PARAM_KEY = 'urlhide';
const ERROR_ROUTE_REDIRECT = '/events';


export const useGlobalUrlParams = () => {
  const [isLoadingOauthTokenLogin, setIsLoadingOauthTokenLogin] = useState(false);
  const [userLogged, setUserLogged] = useState(null)
  const settings = useSettings();
  const { session, layout } = useSession();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const [logInWithOauthToken] = useMutation(LOGIN_WITH_OAUTH_TOKEN);

  const authUserWithToken = (oauthToken) => {
    setIsLoadingOauthTokenLogin(true);
    if (oauthToken) {
      const variables = { oauthToken };
      logInWithOauthToken({ variables }).then(async ({ data: loginData }) => {
        setUserLogged(loginData.logInWithOauthToken.user)
        const { user: userLogin, token } = loginData.logInWithOauthToken
        if (userLogin) {
          setToken(settings, token);
          await session.refetch();
          setIsLoadingOauthTokenLogin(false);
        } else {
          if (Array.isArray(loginData.errors) && loginData.errors.length) {
            history.push(ERROR_ROUTE_REDIRECT);
            setIsLoadingOauthTokenLogin(false);
          }
        }
      })
      .catch(() => {
        history.push(ERROR_ROUTE_REDIRECT);
        setIsLoadingOauthTokenLogin(false);
      });
    }
  };

  useEffect(() => {
    const urlToken = urlParams.get(OAUTH_TOKEN_PARAM_KEY);
    const urlRemoveHeaderFooter = urlParams.get(HEADER_FOOTER_PARAM_KEY);
    if (urlToken) authUserWithToken(urlToken);
    if (urlRemoveHeaderFooter !== null) layout.setRemoveHeaderFooter(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const journeyData = {};

    if (urlParams.has('name')) journeyData.name = urlParams.get('name');
    if (urlParams.has('city')) journeyData.city = urlParams.get('city');
    if (urlParams.has('action')) journeyData.action = urlParams.get('action');
    if (urlParams.has('resourceToken')) journeyData.resourceToken = urlParams.get('resourceToken');
    if (urlParams.has('showJourneyResources')) journeyData.showJourneyResources = urlParams.get('showJourneyResources');

    userJourney.store(journeyData);
  }, [pathname, search]);

  return { isLoadingOauthTokenLogin, userLogged }
};
