import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../styles';

import { TweenMax, Power3 } from 'gsap';


const JourneyLanding = ({ className, startJourney, children, answered }) => {
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const arrowRef = useRef(null);
  const started = useRef(false);

  useEffect(() => {
    const h1 = contentRef.current.querySelectorAll('p.h1');
    const h2 = contentRef.current.querySelectorAll('p.h2');
    const h3 = contentRef.current.querySelectorAll('p.h3');
    const spans = [];

    [].slice.call(h1).forEach(h => {
      const span = h.querySelector('.span');
      if (span) spans.push(span);
    });

    const hasTitleSpans = spans.length > 0;

    TweenMax.to(h1, 1.5, {
      opacity: 1,
      delay: .8,
      ease: Power3.easeOut
    });

    if (hasTitleSpans) {
      TweenMax.to(spans, .8, {
        opacity: 1,
        y: -10,
        delay: 1.3,
        ease: Power3.easeOut
      });
    }

    TweenMax.to(h2, 1.2, {
      css: { scale: 1, opacity: 1 },
      delay: hasTitleSpans ? 2.3 : 1.8,
      ease: Power3.easeOut
    });

    TweenMax.to(h3, 1.2, {
      css: { scale: 1, opacity: 1 },
      delay: hasTitleSpans ? 3.3 : 2.8,
      ease: Power3.easeOut
    });

    TweenMax.to(buttonRef.current, 1.2, {
      opacity: 1,
      delay: hasTitleSpans ? 4.4 : 3.9,
      ease: Power3.easeOut
    });
  }, [contentRef]);

  const handleStartJourney = () => {
    if (started.current) return;

    TweenMax.to(arrowRef.current, .2, {
      x: 25,
      ease: Power3.easeOut
    });

    TweenMax.to(buttonRef.current, .8, {
      opacity: 0,
      delay: .1,
      ease: Power3.easeOut
    });

    started.current = true;
    setTimeout(startJourney, 400);
  };

  const classNames = [
    className,
    answered ? 'answered' : 'not_answered'
  ];

  return (
    <div className={classNames.join(' ').trim()} ref={contentRef}>
      {children}
      <div className="button_container" ref={buttonRef}>
        <span className="get_started_button" onClick={handleStartJourney}>
          {'Let\'s Get Started'}
          <i ref={arrowRef} />
        </span>
      </div>
    </div>
  );
};

const StyledJourneyLanding = styled(JourneyLanding)`
  position: relative;
  display: block;
  width: calc(100% - 60px);
  width: 80%;
  max-width: 1600px;
  z-index: 2;
  margin: auto;
  // text-align: center;
  // opacity: 0;
  // top: 5px;
  color: #fff;

  ${media.md`
    width: 90%;
  `}

  ${media.sm`
    width: 100%;
    padding: 0 30px;
  `}

  @media (max-width: 840px) {
    margin-bottom: 100px;
  }

  &.not_answered {
    ${media.sm`
      margin-bottom: 40px;
    `}
  }

  p {
    opacity: 0;

    span {
      color #fff;
      text-shadow: 0 0 6px rgba(0,0,0,0.8);
      font-family: 'Avenir-Light' !important;
    }

    .span {
      display: inline-block;
      opacity: 0;
      position: relative;
      top: 10px;
      letter-spacing: 0.04em;
      // font-style: italic;
      font-family: 'Avenir-Bold' !important;
    }

    &.h1 {
      font-size: 80px;
      margin: 0;
      line-height: 0.9;
      opacity: 0;
      color: #fff !important;
      font-weight: 300;
      letter-spacing: -0.025em;

      span {
        // font-family: 'Avenir-Bold' !important;
      }

      ${media.md`
        font-size: 60px;
      `}

      ${media.sm`
        font-size: 60px;
      `}

      ${media.xs`
        font-size: 50px;
      `}

      &.margin_bottom {
        margin-bottom: 55px;

        ${media.md`
          margin-bottom: 45px;
        `}

        ${media.sm`
          margin-bottom: 30px;
        `}
      }
    }

    &.h2 {
      letter-spacing: 0.02em;
      // max-width: 800px;
      margin: auto;
      line-height: 1.4;
      font-size: 26px;
      opacity: 0;
      transform: scale(0.99);
      color: #fff !important;
      font-weight: 300;

      strong {
        font-family: 'Avenir-Bold' !important;
        font-weight: normal;
      }


      ${media.md`
        font-size: 20px;
      `}

      ${media.sm`
        font-size: 20px;
        margin: 40px auto;
        line-height: 1.2;

        br {
          display: none;
        }
      `}

      &.margin_bottom {
        margin-bottom: 55px;
      }
    }

    &.h3 {
      font-weight: 400;
      letter-spacing: 0.02em;
      margin: 75px auto 0;
      line-height: 1.4;
      font-size: 18px;
      opacity: 0;
      transform: scale(0.99);
      color: #fff !important;

      span {
        font-family: 'Avenir-Regular' !important;
      }

      ${media.md`
        font-size: 16px;
        margin: 45px auto 0;
      `}

      ${media.sm`
        font-size: 16px;
        margin: 30px auto 0;
        line-height: 1.2;

        br {
          display: none;
        }
      `}
    }
  }

  .button_container {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;

    @media (max-width: 840px) {
      bottom: -110px;
    }

    ${media.sm`
      right: 30px;
      bottom: -80px;
    `}
  }

  .get_started_button {
    font-family: 'Avenir-Light';
    font-size: 26px;
    color: #fff;
    cursor: pointer;

    ${media.md`
      font-size: 22px;
    `}

    &:hover {
      i {
        transform: translateX(7px);
      }
    }

    i {
      width: 30px;
      height: 1px;
      background-color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      transition: transform 250ms;
      margin-bottom: 2px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: 0;
        transform-origin: right;
        width: 15px;
        height: 1px;
        background-color: #fff;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
`;
export { StyledJourneyLanding as JourneyLanding };
