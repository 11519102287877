import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Card,
  Avatar,
  Button,
  MessageHostModal,
  withSession
} from '../components';

import { withSettings } from '../components';
import { stringInsert } from '../../libs';
import { breakpoints } from '../../styles';
import { RestrictionModal, RestrictionModalStatus } from '../Notification';

class EventViewHost extends React.Component {
  state = {
    showMessageHostModal: false,
    showMessagePartnerOrganizationModal: false,
    showRestrictionModal: false,
    hostSelected: null,
    hasRestriction: null,
    restrictionContent: null
  };

  componentDidMount() {
    const { session, settings, event } = this.props;
    const { copy } = settings;

    const restrictionContent = RestrictionModalStatus(copy, session, event, this.props.settings);

    if (restrictionContent) {
      this.setState({ hasRestriction: true, restrictionContent });
    }
  }

  openMessageHostModal = host => {
    if (this.shouldShowRestrictionModal()) {
      this.showRestrictionModal();
      return
    }

    this.setState({ showMessageHostModal: true, showMessagePartnerOrganizationModal: false, hostSelected: host });
  }

  openMessagePartnerOrganizationModal = () => {
    if (this.shouldShowRestrictionModal()) {
      this.showRestrictionModal();
      return
    }

    this.setState({ showMessagePartnerOrganizationModal: true, showMessageHostModal: false, hostSelected: null });
  };

  shouldShowRestrictionModal = () => {
    return (this.state.hasRestriction === true);
  }

  showRestrictionModal = () => {
    this.setState({ showRestrictionModal: true });
  }

  handleRestrictionModalToggle = () => {
    this.setState({ showRestrictionModal: !this.state.showRestrictionModal })
  }

  closeMessageHostModal = () => {
    this.setState({ showMessageHostModal: false });
  };

  closeMessagePartnerOrganizationModal = () => {
    this.setState({ showMessagePartnerOrganizationModal: false });
  };

  getTitle = (event, hosts) => {
    const { settings: { copy } } = this.props;
    // Community dinner
    if (event.communityDinner) {
      return stringInsert(copy.event.host_organization_title, event.partnerOrganizationName);
    // With co-hosts
    } else if (Array.isArray(hosts) && hosts.length > 1) {
      return copy.event.multiple_host_title;
    // Single host
    } else {
      return copy.event.host_title;
    }
  };

  getBio = (hosts, profile) => {
      if (!profile || (!profile.bio && hosts.length < 2)) {
          return (<div />);
      } else if (!profile.bio) {
          return (<p>&nbsp;</p>);
      } else {
          return (<p>{profile.bio}</p>);
      }
  };

  getHostContainerClass = (hosts, community) => {
    const number = hosts.length + (community ? 1 : 0);
    switch (number) {
      case 1:
        return 'full';
      case 2:
        return 'half';
      case 3:
        return 'third';
      case 4:
        return 'quarter';
      default:
        return '';
    }
  }

  renderCommunityDinner = (event, hosts) => {
    const { settings: { copy } } = this.props;

    return (
      event.partnerOrganizationDescription
        ? (
          <div className={`hosts ${this.getHostContainerClass(hosts, true)}`}>
            <div className="host">
              {event.partnerOrganizationLogo && (
                <div
                  className="partnership_logo"
                  style={{ backgroundImage: `url(${event.partnerOrganizationLogo.originalUrl})` }}
                />
              )}
              <p>{event.partnerOrganizationDescription}</p>
              <Button handleClick={() => this.openMessagePartnerOrganizationModal()}>
                {copy.event.message_host}
              </Button>
            </div>
            {hosts.map((edge, i) => {
              const { node: { profile } } = edge;
              return profile ? (
                <div className="host" key={i}>
                  <Avatar
                    profile={profile}
                    size="md"
                    layout="vertical"
                    className="host_avatar"
                    linked
                  />
                  {profile.bio && <p>{profile.bio}</p>}
                  <Button handleClick={() => this.openMessageHostModal(profile)}>
                    {copy.event.message_host}
                  </Button>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <div className={`hosts ${this.getHostContainerClass(hosts)}`}>
            {hosts.map((edge, i) => {
              const { node: { profile } } = edge;
              return profile ? (
                <div className="host" key={i}>
                  <Avatar
                    profile={profile}
                    size="md"
                    layout="vertical"
                    className="host_avatar"
                    linked
                  />
                  {profile.bio && <p>{profile.bio}</p>}
                  <Button handleClick={() => this.openMessageHostModal(profile)}>{copy.event.message_host}</Button>
                </div>
              ) : null;
            })}
          </div>
        )
    );
  }

  renderHost = (event, hosts) => {
    const { settings: { copy } } = this.props;

    return (
      <div className={`hosts ${this.getHostContainerClass(hosts)}`}>
        {hosts.map((edge, i) => {
          const { node: { profile } } = edge;
          return profile ? (
            <div className="host" key={i}>
              <Avatar
                profile={profile}
                size="md"
                layout="vertical"
                className="host_avatar"
                linked
              />
              {this.getBio(hosts, profile)}
              <Button handleClick={() => this.openMessageHostModal(profile)}>{copy.event.message_host}</Button>
            </div>
          ) : null;
        })}
      </div>
    );
  }

  render() {
    const { showMessageHostModal, showMessagePartnerOrganizationModal, hostSelected, restrictionContent } = this.state;
    const { className, event } = this.props;
    const hosts = event.eventHosts.edges;

    return (
      <Fragment>
        <RestrictionModal
          show={this.state.showRestrictionModal}
          toggle={this.handleRestrictionModalToggle}
          content={restrictionContent}
        />
        <Card className={className} id="host">
          <h2 className="card_title">{this.getTitle(event, hosts)}</h2>
          <i className="divider" />
          {event.communityDinner
            ? this.renderCommunityDinner(event, hosts)
            : this.renderHost(event, hosts)
          }
          <MessageHostModal
            event={event}
            messagePartnerOrganization={true}
            show={showMessagePartnerOrganizationModal}
            toggle={this.closeMessagePartnerOrganizationModal}
          />
          <MessageHostModal
            event={event}
            host={hostSelected}
            messagePartnerOrganization={false}
            show={showMessageHostModal}
            toggle={this.closeMessageHostModal}
          />
        </Card>
      </Fragment>
    );
  }
}

const StyledEventViewHost = styled(EventViewHost)`
  & > .hosts {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.full {
      .host {
        width: 100%;
        // max-width: 700px;
        margin: 0 auto;
      }
    }

    &.half {
      .host {
        width: 30%;

        @media (${breakpoints.tablet}) {
          width: 40%;
        }
      }
    }

    &.third {
      .host {
        width: 30%;

        @media (max-width: 1059px) {
          width: 40%;
          margin-bottom: 20px;

          &:nth-of-type(3) {
            margin-bottom: 0;
          }
        }
      }
    }

    &.quarter {
      .host {
        width: 21%;

        @media (${breakpoints.tablet}) {
          width: 40%;
          margin-bottom: 20px;

          &:nth-of-type(3),
          &:nth-of-type(4) {
            margin-bottom: 0;
          }
        }
      }
    }

    &.half,
    &.third,
    &.quarter {
      @media (${breakpoints.tabletSmall}) {
        float: none;

        .host {
          width: 100% !important;
          margin-bottom: 20px !important;
          margin: 0 0 20px !important;

          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .host {
      display: inline-block;
      vertical-align: top;
      margin: 0 15px;
      // max-width: 250px;
      margin-bottom: 45px;

      & > div {
        margin-bottom: 25px;
      }
    }

    .host_avatar {
      // display: inline-block;
      margin: 0 15px;
    }

    .partnership_logo {
      width: 60px;
      height: 60px;
      margin: 0 auto 48.5px;
      border-radius: 50%;
      box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);
      background-size: cover;
      background-position: center;
      background-color: #fff;
    }

    p {
      font-size: 14px;
      margin: -10px 0 25px;
    }
  }
`;

const StyledEventViewHostWithSettings = withSettings(StyledEventViewHost);
const EventViewHostWithSession = withSession(StyledEventViewHostWithSettings);
export { EventViewHostWithSession as EventViewHost };
