import React from 'react';
import styled, { css } from 'styled-components';
import { Mutation } from 'react-apollo';
import { Button, Modal } from '../';
import PropTypes from 'prop-types';
import { withSettings, withSession, PageSubheader as Header } from '../components';
import { alert, SelectBox, Textarea } from '../Global';
import { CANCEL_RESERVATION } from '../../mutations';

import { withCancelReservationQueryHooksHOC } from '../../libs/events';

class EventViewCancelReservationModal extends React.Component {
  state = {
    loading: false,
    cancelReason: '',
    cancelReasonText: '',
    cancelReasons: null,
    requireText: false
  };

  componentDidUpdate() {
    if (!this.props.getCancelReservationLoading && !this.state.cancelReasons && this.props.getCancelReservationData) {
      this.setState({
        cancelReasons: this.props.getCancelReservationData.reservationCancelReasons
      });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
    if (name === 'cancelReason') {
      const reason = this.state.cancelReasons.find((r) => r.cancelReason === value);
      this.setState({ requireText: reason.requireText, cancelReasonText: '' });
    }
  };

  formValid = () => {
    const { settings: { event: { reservation_cancel_reason_enabled } } } = this.props;
    if (!reservation_cancel_reason_enabled) return true;
    if (!this.state.cancelReasons) return false;
    const reason = this.state.cancelReasons.find((r) => r.cancelReason === this.state.cancelReason);
    if (!reason) return false;
    return reason.requireText ? this.state.cancelReasonText : true;
  };

  formatSelectData = (options) => {
    if (!options) return [];

    const data = options.map((key, i) => ({
      id: i + 1,
      label: key.cancelReason,
      value: key.cancelReason
    }));

    return data;
  };
  cancelReservationButtonClicked = async (cancelReservationMutation) => {
    const { reservation, reservationCancelled } = this.props;

    const mutationInput = {
      id: reservation.id,
      cancelReason: this.state.cancelReason,
      cancelReasonText: this.state.cancelReasonText
    };

    this.setState({ loading: true });

    const response = await cancelReservationMutation({
      variables: mutationInput,
      errorPolicy: 'all'
    });

    if (response && response.data && response.data.cancelReservation) {
      this.setState({ loading: false }, () => reservationCancelled());
    } else {
      alert.error('Reservation could not be cancelled.');
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { className, show, toggle, settings, session: { theme } } = this.props;
    const { copy, event: { reservation_cancel_reason_enabled } } = settings;

    const ModalTitle = ({ children }) => theme.isVersion(2)
      ? <Header>{children}</Header>
      : <h3>{children}</h3>;

    const ModalSubTitle = ({ children }) => theme.isVersion(2)
      ? <p>{children}</p>
      : <h2>{children}</h2>;

    return (
      <Mutation mutation={CANCEL_RESERVATION}>
        {(cancelReservationMutation) => (
          <Modal show={show} toggle={toggle} size='sm' noPadding>
            <div className={className}>
              <ModalTitle>{copy.event.confirm_delete_reservation_title}</ModalTitle>
              <ModalSubTitle>{copy.event.confirm_delete_reservation_title2}</ModalSubTitle>
              <div className='reason_container'>
                {reservation_cancel_reason_enabled && (
                  <div>
                    <label>{copy.event.confirm_delete_cancel_reservation_reason}</label>
                    <SelectBox
                      name='cancelReason'
                      options={this.formatSelectData(this.state.cancelReasons)}
                      onOptionSelect={(value) => this.handleChange('cancelReason', value)}
                    />
                  </div>
                )}
                {this.state.requireText && (
                  <div className=''>
                    <label>{copy.event.confirm_delete_cancel_reservation_reason_text}</label>
                    <Textarea
                      name='cancelReasonText'
                      value={this.state.cancelReasonText}
                      onInputChange={this.handleChange}
                      placeholder={
                        copy.event.confirm_delete_cancel_reservation_reason_text_placeholder
                      }
                    />
                  </div>
                )}
              </div>
              <div className='button_container'>
                <Button
                  className='submit'
                  handleClick={() => this.cancelReservationButtonClicked(cancelReservationMutation)}
                  buttonStyle='primary'
                  type='submit'
                  loading={loading}
                  disabled={loading || !this.formValid()}
                  halfWidth
                  themed={theme.isVersion(2)}
                >
                  {copy.event.confirm_delete_submit_reservation}
                </Button>
                <Button
                  className='cancel'
                  handleClick={() => {
                    this.setState({ cancelReason: null });
                    toggle();
                  }}
                  buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                  type='submit'
                  disabled={loading}
                  halfWidth
                  themed={theme.isVersion(2)}
                >
                  {copy.event.confirm_delete_cancel_reservation}
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </Mutation>
    );
  }
}

EventViewCancelReservationModal.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  updateClicked: PropTypes.func,
  cancelClicked: PropTypes.func
};

const StyledEventViewCancelReservationModal = styled(EventViewCancelReservationModal)`
  position: relative;
  text-align: center;
  padding: 40px 30px 30px;

  & > .logo {
    height: 53px;
    width: 147px;
    margin: 1em auto;
  }

  & > h2 {
    ${({ session: { theme }, settings }) => !theme.isVersion(2) && css`
      font-size: 16px;
      // color: ${settings.theme.css.global.colors.primaryDark};
      margin-top: 0;
      margin-bottom: 20px;
      // font-weight: normal !important;
    `}
  }

  & > h3 {
    ${({ session: { theme }, settings }) => !theme.isVersion(2) && css`
      // color: ${settings.theme.css.global.colors.heading};
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.6em;
      font-weight: normal !important;
    `}
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > button {
    margin-top: 1em;
  }

  & > .separator_line {
    height: 1px;
    background-color: ${(props) => props.settings.theme.css.global.colors.borderColor};
    width: 33.33333%;
    margin: 10px auto;
    flex-grow: 1;
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    & > .logo {
      width: 140px;
      margin: 0 auto;
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;
  }

  .button_container {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    button {
      width: calc(50% - 10px);
    }

    .submit {
      float: right;
    }

    .cancel {
      float: left;
    }
  }

  .reason_container {
    text-align: left;

    label {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const StyledEventViewCancelReservationModalWithSettings = withSettings(
  StyledEventViewCancelReservationModal
);
const EventViewCancelReservationModalWithSession = withSession(
  StyledEventViewCancelReservationModalWithSettings
);

const StyledEventViewCancelReservationModalReservationQueryHooks =
  withCancelReservationQueryHooksHOC(EventViewCancelReservationModalWithSession);

export { StyledEventViewCancelReservationModalReservationQueryHooks as EventViewCancelReservationModal };
