import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import {
  EVENT_FILTER,
  USER_STATE
} from '../../enums';

import { Button, RestrictionModal, RestrictionModalStatus, withSettings } from '../components';

import { breakpoints } from '../../styles';


class ManageEventsFilters extends React.Component {
  state = {
    showRestrictionModal: false,
    restrictionContent: null
  };

  isActive = filter => {
    const { active } = this.props
    return active === filter  ? 'active' : null
  }

  changeFilter = filter => {
    this.props.handleChange(filter);
  }

  createDinner = (user, restriction) => {
    const { history } = this.props;

    restriction
      ? this.setState({ showRestrictionModal: !this.state.showRestrictionModal })
      : history.push('/events');
  }

  handleRestrictionModalToggle = () => {
    this.setState({ showRestrictionModal: !this.state.showRestrictionModal })
  }

  renderHostFilters() {
    const { settings: { copy, event: { require_publish_approval } } } = this.props;
    const { changeFilter, isActive } = this;
    const { LABEL } = EVENT_FILTER.FIELDS;
    const requireApproval = !!require_publish_approval;

    return (
      <div className="manage-event-filter-section host">
        <title>{copy.manage.hosting}</title>
        <ul>
          <li
            className={isActive(EVENT_FILTER.HOSTED)}
            onClick={() => changeFilter(EVENT_FILTER.HOSTED)}
          >
            {EVENT_FILTER.getField(LABEL, EVENT_FILTER.HOSTED)}
          </li>
          {requireApproval && (
            <li
              className={isActive(EVENT_FILTER.PENDING_PUBLISH_APPROVAL)}
              onClick={() => changeFilter(EVENT_FILTER.PENDING_PUBLISH_APPROVAL)}
            >
              {EVENT_FILTER.getField(LABEL, EVENT_FILTER.PENDING_PUBLISH_APPROVAL)}
            </li>
          )}
          <li
            className={isActive(EVENT_FILTER.DRAFT)}
            onClick={() => changeFilter(EVENT_FILTER.DRAFT)}
          >
            {EVENT_FILTER.getField(LABEL, EVENT_FILTER.DRAFT)}
          </li>
          <li
            className={isActive(EVENT_FILTER.PAST)}
            onClick={() => changeFilter(EVENT_FILTER.PAST)}
          >
            {EVENT_FILTER.getField(LABEL, EVENT_FILTER.PAST)}
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { className, session, settings: { copy }, history } = this.props;
    const { changeFilter, isActive, createDinner } = this;
    const { LABEL } = EVENT_FILTER.FIELDS;
    const viewer = session.viewer;
    const user = viewer ? viewer.user : null;
    const isHost = user && user.state === USER_STATE.HOST;

    const restriction = viewer && RestrictionModalStatus(copy, session, null, this.props.settings, 'creation');

    return (
      <div className={className}>
        <div className="filter_container">
          {(isHost || (viewer && viewer.allEvents.totalCount > 0)) && this.renderHostFilters()}
          <title>{copy.manage.attending}</title>
          <ul>
            <li
              className={isActive(EVENT_FILTER.RESERVED)}
              onClick={() => changeFilter(EVENT_FILTER.RESERVED)}
            >
              {EVENT_FILTER.getField(LABEL, EVENT_FILTER.RESERVED)}
            </li>
            <li
              className={isActive(EVENT_FILTER.ATTENDED)}
              onClick={() => changeFilter(EVENT_FILTER.ATTENDED)}
            >
              {EVENT_FILTER.getField(LABEL, EVENT_FILTER.ATTENDED)}
            </li>
          </ul>
        </div>

        <RestrictionModal
          show={this.state.showRestrictionModal}
          toggle={this.handleRestrictionModalToggle}
          content={restriction}
          onClose={() => history.push('/events')}
        />

        {isHost && (
          <Button className="create_dinner_button" handleClick={() => createDinner(user, restriction)}>
            {copy.manage.create}
          </Button>
        )}
      </div>
    );
  }
}

const StyledManageEventsFilters = styled(ManageEventsFilters)`
  width: 25%;

  @media (${breakpoints.manageEventsListTablet}) {
    width: 100%;
    max-width: 340px;
    margin: 0 auto 80px;
  }

  @media (${breakpoints.manageEventsListMobile}) {
    margin: 0 auto 60px;
  }

  .filter_container {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.05);
    border: 1px solid #e5e5e5;
    padding-top: 25px;
    font-size: 14px;
    // max-height: 280px;
  }

  title {
    display: block;
    font-weight: bold;
    padding-left: 15px;
    margin-bottom: 3px;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 6px 27px;
      cursor: pointer;
    }
    li:hover,
    .active {
      background-color: #f1f1f1;
    }
  }
  .create_dinner_button {
    display:  block;
    margin-top: 30px;
    text-align: center;
  }
`;

const StyledManageEventsFiltersWithRouter = withRouter(StyledManageEventsFilters);
const ManageEventFiltersWithSettings = withSettings(StyledManageEventsFiltersWithRouter);

export { ManageEventFiltersWithSettings as ManageEventsFilters }
