import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { SEND_INVITATIONS } from '../../mutations'
import { UserEmailSelector, Button, withSettings } from '../components';
import { WYSIWYGEditor } from './Forms';

class InviteFriendsByEmail extends React.Component {
  state = {
    emails: [],
    emailsValid: false,
    message: '',
    loading: false
  };

  onValidate = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  }

  handleAddEmail = emails => {
    this.setState({ emails });
  };

  handleAddMessage = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInviteFriends = sendInvitations => {
    const { onSuccess } = this.props;

    this.setState({ loading: true });

    sendInvitations()
      .then(() => {
        this.setState({ loading: false, message: '', emails: [] }, () => {
          if (onSuccess) onSuccess();
        });
      })
      .catch(() => {
        // console.error('sendInvitations', error);
        this.setState({ loading: false, message: '', emails: [] });
      });
  };

  render() {
    const { emails, message, loading } = this.state;
    const { className, eventId } = this.props;
    const { settings: { copy } } = this.props;

    const variables = {
      eventId,
      emails,
      text: message
    };

    return (
      <div className={className}>
        <h3>{copy.manage.invite_friends_email}</h3>
        <Mutation mutation={SEND_INVITATIONS} variables={variables}>
          {(sendInvitations) => (
            <Fragment>
              <UserEmailSelector
                name="emails"
                emails={emails}
                allowMultipleEmails={true}
                onAddEmail={this.handleAddEmail}
                onValidate={this.onValidate}
                errorMessage="Enter a valid email."
                placeholder={copy.guest.email_placeholder}
              />
              <WYSIWYGEditor
                name="message"
                value={message}
                onInputChange={this.handleAddMessage}
                placeholder={copy.guest.description_placeholder}
              />
              <Button
                handleClick={() => this.handleInviteFriends(sendInvitations)}
                disabled={!Array.isArray(emails) || emails.length < 1 || loading}
                loading={loading}
                fullWidth
              >
                Send Invite
              </Button>
            </Fragment>
          )}
        </Mutation>
      </div>
    );
  }
}

const StyledInviteFriendsByEmail = styled(InviteFriendsByEmail)`
  h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .wysiwyg-editor {
    margin-bottom: 14px;
  }
`;

const StyledInviteFriendsByEmailWithSettings = withSettings(StyledInviteFriendsByEmail);

export { StyledInviteFriendsByEmailWithSettings as InviteFriendsByEmail };
