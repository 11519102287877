const styles = {
  colors: {
    backgroundColor: '#fbfaf9',
    backgroundLight: '#f9f7f5',
    backgroundDark: '#f8f7f5',
    backgroundDisabled: '#f0f0f0',

    primary: '#8d1b37',
    // primary: '#60d693',
    // primaryHover: '#3da268',
    primaryHover: '#5a1b27',
    primaryLight: '#faf1f3',
    primaryLightBorder: '#e0c4cc',
    primaryDark: '#443035',
    primaryDisabled: '#a2576a',

    secondaryHover: '#f4f4f4',

    green: '#76d276',
    greenBorder: '#63cc63',
    greenHover: '#3ebd3e',
    greenHoverBorder: '#38aa38',

    important: 'rgb(237, 118, 102)',
    importantTransparent: 'rgba(237, 118, 102, 0.7)',
    warning: 'rgb(238, 130, 2)',
    warningTransparent: 'rgba(238, 130, 2, 0.9)',
    success: 'rgb(118, 210, 118)',
    successTransparent: 'rgba(118, 210, 118, 0.9)',

    text: '#333',
    textLight: '#8c8c8c',
    textDisabled: '#a59f99',

    menuHover: 'rgba(141,27,61,0.1)',

    tagBackground:'rgba(142, 27, 55, 0.1)',
    tagBorder:'rgba(142, 27, 55, 0.4)',

    bannerSmall: '#f5b15f',

    facebook: '#485093',
    facebookHover: '#373d71',
    facebookDisabled: '#6871b9',
    twitter: '#81cbf0',
    twitterHover: '#53a2c9',

    error: '#b12020',

    heading: '#4d4d4d',

    borderColor: '#e5e5e5',

    notificationBarBackground: '#ff7877',
    notificationBarTextColor: '#fbfaf9'
  },

  card: {
    shadow: '0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06)',
    shadowHover: '-2px 3px 9px rgba(0,0,0,.08), 2px 4px 15px rgba(0,0,0,.09)',
    shadowLight: '0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.02)'
  },

  breakpoints: {
    desktop: 'min-width: 871px',
    tablet: 'max-width: 870px',
    tabletSmall: 'max-width: 670px',
    mobile: 'max-width: 520px',
    modal: 'max-width: 991px',
    bootstrap: 'max-width: 991px',
    manageEventsListTablet: 'max-width: 1030px',
    manageEventsListMobile: 'max-width: 770px',
    manageEventDetailsTablet: 'max-width: 1000px'
  },

  containersWidths: {
    default: '1192px'
  }
};

const SCROLLED_NAV_HEIGHT = 80;
const NOT_SCROLLED_NAV_HEIGHT = 120;

const {
  colors,
  card,
  breakpoints,
  fonts,
  containersWidths
} = styles;

export {
  styles,
  colors,
  card,
  breakpoints,
  fonts,
  containersWidths,
  SCROLLED_NAV_HEIGHT,
  NOT_SCROLLED_NAV_HEIGHT
};
