import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

class DollarInput extends React.Component {
  state = {
    valid: true,
    tooLow: false,
    tooHigh: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;
    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleChange = event => {
    const { name, value: inputValue, min, max } = event.target;
    const { onInputChange, onValidate, required } = this.props;
    const value = parseInt(inputValue, 10);

    onInputChange(name, value);

    if (required && this.inputIsValid(value)) {
      this.setState({ valid: true });
      onValidate && onValidate(name, true);
    }

    if (min && value < min) {
      this.setState({ valid: false, tooLow: true, tooHigh: false });
      onValidate && onValidate(name, false);
    } else if (max && value > max) {
      this.setState({ valid: false, tooLow: false, tooHigh: true });
      onValidate && onValidate(name, false);
    } else {
      this.setState({ valid: true, tooLow: false, tooHigh: false });
      onValidate && onValidate(name, true);
    }
  };

  handleValidation = event => {
    const { name, value } = event.target;
    const { onValidate, required } = this.props;

    if (required && !this.inputIsValid(value)) {
      this.setState({ valid: false });
      onValidate(name, false);
    }
  };

  inputIsValid = value => {
    return value !== '' && value > 0;
  };

  getErrorMessage = () => {
    const { errorMessage, tooLowError, tooHighError, name, min, max } = this.props;
    const { tooLow, tooHigh } = this.state;
    const tooLowMessage = `Must be at least $${min}.`;
    const tooHighMessage = `Must be no more than $${max}.`;

    if (tooLow) return tooLowError || tooLowMessage;
    if (tooHigh) return tooHighError || tooHighMessage;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  getErrorClass = () => {
    const { errorPlacement } = this.props;
    const { valid } = this.state;
    return !valid
      ? (errorPlacement === 'top' ? 'error_top' : 'error_bottom')
      : '';
  };

  handleKeyDown = e => {
    const { keyCode } = e;

    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault();
    }
  };

  render() {
    const {
      className,
      placeholder,
      name,
      value,
      errorPlacement,
      onInputFocus,
      onInputBlur,
      halfWidth,
      disabled,
      min,
      max
    } = this.props;

    return (
      <div
        className={`
          ${className}
          ${this.getErrorClass()}
          ${halfWidth ? 'halfWidth' : ''}
          ${disabled ? 'disabled' : ''}
        `}
      >
        {!this.state.valid && errorPlacement === 'top' && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
        <input
          type="text"
          pattern="[0-9]*"
          min={min}
          max={max}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ''}
          name={name ? name : ''}
          value={value ? value : ''}
          className={!this.state.valid ? 'invalid' : ''}
          onChange={this.handleChange}
          onFocus={onInputFocus}
          onBlur={event => { this.handleValidation(event); onInputBlur && onInputBlur(); }}
          onKeyDown={this.handleKeyDown}
        />
        {!this.state.valid && errorPlacement !== 'top' && (
          <div className="validation_error bottom">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}

const StyledDollarInput = styled(DollarInput)`
  position: relative;

  input[type="text"] {
    padding-left: 20px !important;
  }

  &::before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 8px;
    font-size: 15px;
    z-index: 1;
  }

  &.halfWidth {
    input {
      width: 50%;
    }
  }

  .validation_error {
    font-size: 11px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }

  .invalid {
    border-color: ${props => props.settings.theme.css.global.colors.error};

    &:focus {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }
  }

  &.error_bottom {
    input {
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 14px;
    }
  }

  &.disabled {
    input {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
      cursor: default;
    }
  }
`;

const StyledDollarInputWithSettings = withSettings(StyledDollarInput);

export { StyledDollarInputWithSettings as DollarInput };
