import React, { useState } from 'react';
import styled from 'styled-components';
// import { useProfilePageData } from '../../hooks';
import { useSettings } from '../../hooks';

import {
  PageSubheader as Header,
  Checkbox,
  UnderlineButton as Button
} from '../components';


export const ProfileSettingsSubscriptionNotifications = () => {
  // const { notifications } = useProfilePageData();
  const [webNotification, setWebNotification] = useState(true);
  const [emailNotification, setEmailNotification] = useState(false);
  const { copy } = useSettings();

  return (
    <Container>
      <div>
        <Header small>{copy.profile.membership_notifications || 'Membership Notifications'}</Header>
        <P>{copy.profile.membership_notifications_info || 'Notify of membership changes or renewals'}</P>
      </div>
      <Settings>
        <Checkbox
          name="webNotification"
          options={[{
            label: 'Web',
            checked: webNotification
          }]}
          onInputChange={(n, v) => setWebNotification(v)}
        />
        <Checkbox
          name="emailNotification"
          options={[{
            label: 'Email',
            checked: emailNotification
          }]}
          onInputChange={(n, v) => setEmailNotification(v)}
        />
        <Button onClick={() => {}}>Save Changes</Button>
      </Settings>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const Settings = styled.div`
  width: 105px;

  .checkbox__label {
    margin-bottom: 10px;

    span {
      font-family: 'Avenir-Medium';
    }
  }
  .underline_button {
    margin-top: 5px;
  }
`;

const P = styled.p`
  font-size: 14px;
  margin-top: 13px;
`;
