import React from 'react';
import styled from 'styled-components';

import {
  SponsorsListUl,
  SponsorsListCategoryUl,
  Sponsor,
  withSettings
} from '../components';

export const SponsorsListByArea = ({ sponsors, sponsorAreas }) => (
  <>
    {sponsorAreas.length > 0 && (
      <SponsorsListUl>
        {sponsorAreas.map((title, i) => {
          const areaSponsors = Array.isArray(sponsors[title]) ? sponsors[title] : [];

          if (areaSponsors.length) {
            return (
              <SponsorsListLi key={i} id={title.toLowerCase().replace(' ', '_')}>
                <Title>{title}</Title>
                <SponsorsListCategoryUl>
                  {areaSponsors.map(sponsor => <Sponsor key={sponsor.id} sponsor={sponsor} />)}
                </SponsorsListCategoryUl>
              </SponsorsListLi>
            );
          }

          return null;
        })}
      </SponsorsListUl>
    )}
  </>
);

const SponsorsListLi = styled.ul`
  margin: 0 0 60px;
  padding: 0;
`;

const TitleWithoutSettings = styled.h4`
  color: ${props => props.settings.theme.css.global.colors.primary};
  margin: 30px 0;
  font-size: 22px;
  font-weight: normal !important;
`;
const Title = withSettings(TitleWithoutSettings);
