import React from 'react';
import styled from 'styled-components';

import { Button } from './';

const ButtonTabs = ({ className, tabs, activeTab, onSelectTab, capitalize }) => {
  const handleSelectTab = value => onSelectTab(value);

  return (
    <div className={className}>
      {tabs.map(tab => (
        <Button
          key={tab.value}
          buttonStyle={tab.value === activeTab ? 'primary' : 'secondary'}
          handleClick={() => handleSelectTab(tab.value)}
          halfWidth={tabs.length === 2}
          capitalize={capitalize}
        >{tab.name}</Button>
      ))}
    </div>
  );
};

const StyledButtonTabs = styled(ButtonTabs)`
  & > .button {
    border-radius: 0;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
`;

export { StyledButtonTabs as ButtonTabs };
