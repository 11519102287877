import React from 'react';
import styled from 'styled-components';

import {
  Card,
  EventCreationQuestionsAnswer,
  FAIcon,
  withSettings
} from '../components';

import { indexOfObj } from '../../libs';
import { breakpoints } from '../../styles';
import { EVENT_TYPE } from '../../enums';
import Html from 'html-to-react';

const htmlParser = new Html.Parser();


const EventCreationQuestionsCard = props => {
  const {
    className,
    question,
    answers,
    selectedAnswers,
    currentStep,
    questionsCount,
    canSkipToForm,
    skipToForm,
    goToQuestion,
    selectQuestion,
    settings,
    isCommunity
  } = props;
  const { copy, theme: { assets: { images } } } = settings;

  const getBackgroundImage = () => {
    return question.background ? question.background.originalUrl : images.default_creation_question_bg;
  };

  const handleSelectQuestion = answer => {
    const lastQuestion = currentStep === questionsCount;
    if (lastQuestion && canSkipToForm) {
      selectQuestion(answer, true);
    } else {
      selectQuestion(answer);
    }
  };

  const renderProgressBar = () => {
    const steps = [];

    for (let i = 0; i < questionsCount; i++) {
      steps.push(
        <li
          key={i}
          className={i + 1 === currentStep ? 'active' : ''}
          onClick={() => goToQuestion(i + 1)}
        >
          Question {i + 1}
        </li>
      );
    }

    if (steps.length < 1) return null;
    return <ul className="progress_bar">{steps.map(step => step)}</ul>;
  };

  return (
    <div
      className={`${className} slide`}
      style={{ backgroundImage: `url(${getBackgroundImage()})` }}
    >
      <Card noPadding>
        {renderProgressBar()}

        <div className="question__content">
          {question.description &&
            <p className="question__description">{htmlParser.parse(question.description)}</p>
          }
          <h3>{htmlParser.parse(question.title)}</h3>

          <ul className={`question__answers ${answers && answers.length === 4 ? 'four_by' : ''}`}>
            {answers && answers.map(answer => (
              isCommunity && answer.eventType === EVENT_TYPE.SOLO ? null : (
                <EventCreationQuestionsAnswer
                  key={answer.id}
                  answer={answer}
                  isSelected={indexOfObj(selectedAnswers, 'id', answer.id)}
                  onSelectQuestion={handleSelectQuestion}
                />
              )))}
          </ul>

          {canSkipToForm && (
            <p
              className="skip_questions"
              onClick={skipToForm}
            >
              <span>{copy.create.continue_to_dinner}</span>
              <FAIcon name={[ 'fal', 'chevron-right' ]} />
            </p>
          )}
        </div>
      </Card>
    </div>
  );
};

const StyledEventCreationQuestionsCard = styled(EventCreationQuestionsCard)`
  .progress_bar {
    margin: 0;
    padding: 10px 0 12px;
    border-radius: 5px 5px 0 0;
    background-color: ${props => props.settings.theme.css.global.colors.backgroundLight};
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: middle;
      color: ${props => props.settings.theme.css.global.colors.textDisabled};
      font-size: 11px;
      margin: 0;
      padding: 0;
      width: 95px;
      text-align: center;
      position: relative;
      cursor: pointer;

      @media (${breakpoints.mobile}) {
        width: 80px;
      }

      &.active {
        font-weight: bold;
        color: #000;
        cursor: default;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -6px;
        height: 12px;
        width: 1px;
        background-color: #a59f99;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .question {
    &__content {
      padding: 60px 30px;
      text-align: center;
    }

    &__description {
      font-size: 13px;
      margin: 0 0 30px;
    }

    &__answers {
      margin: 0;
      padding: 0;
      list-style-type: none;

      &.four_by {
        margin: 0 auto;
        max-width: 400px;
      }
    }
  }

  h3 {
    font-size: 28px;
    margin: 0 auto 40px;
    max-width: 430px;
    line-height: 1.3;
    font-weight: normal !important;
  }


  .skip_questions {
    display: inline-block;
    margin: 30px 0 0;
    span {
      text-decoration: underline;
      font-size: 13px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      cursor: pointer;
      transition: color 250ms;
    }
    svg {
      font-size: 10px;
      margin-left: 6px;
      display: inline-block;
      transform: translateY(-1px);
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;
    }
    &:hover {
      span,
      svg {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }
`;

const EventCreationQuestionsCardWithSettings = withSettings(StyledEventCreationQuestionsCard);

export { EventCreationQuestionsCardWithSettings as EventCreationQuestionsCard };
