import React from 'react';
import styled from 'styled-components';

import {
  Button,
  Card,
  Logo,
  withSettings
} from '../components';

import { isRegularOneTable } from '../../libs';


const EventCreationQuestionsIntro = ({ className, loading, goToNextSlide, settings }) => {
  const { copy } = settings;
  const images = settings.theme.assets.images;

  return (
    <div
      className={`${className} slide intro`}
      style={{ backgroundImage: `url(${images.default_creation_question_bg})` }}
    >
      <Card>
        <Logo layout="emblem" />
        <h2>{copy.event.creation_q_intro_title}</h2>
        <p dangerouslySetInnerHTML={{ __html: copy.event.creation_q_intro_description }} />
        <Button disabled={loading} loading={loading} handleClick={goToNextSlide}>
          {copy.event.creation_q_intro_button}
        </Button>
      </Card>
    </div>
  );
}

const StyledEventCreationQuestionsIntro = styled(EventCreationQuestionsIntro)`
  .ot_card {
    padding: 80px 30px !important;
    text-align: center;
  }

  .logo {
    width: ${props => isRegularOneTable(props.settings) ? '80px' : '180px'};
    height: ${props => isRegularOneTable(props.settings) ? '40px' : 'auto'};
    margin: auto;
  }

  h2 {
    font-size: 30px;
    margin: 30px 0 0;
    font-weight: normal !important;
  }

  p {
    font-size: 14px;
    margin: 30px auto;
    width: 100%;
    max-width: 520px;
    line-height: 1.6;
  }
`;

const EventCreationQuestionsIntroWithSettings = withSettings(StyledEventCreationQuestionsIntro);

export { EventCreationQuestionsIntroWithSettings as EventCreationQuestionsIntro };
