export const getNeighborhoodLabel = event => {
  const { neighborhood, area: { neighborhoods } } = event;
  const hood = neighborhoods && neighborhoods.find(n => n.code === neighborhood);
  return hood ? hood.label : neighborhood;
};

export const getSubNeighborhoodLabel = event => {
  const { subneighborhood, neighborhood, area: { neighborhoods } } = event;
  const hood = neighborhoods && neighborhoods.find(n => n.code === neighborhood);
  const subhood = hood && hood.subneighborhoods.find(s => s.code === subneighborhood);
  return subhood ? subhood.label : subneighborhood;
};
