import React from 'react';
import { UPDATE_RESERVATION_PARTICIPATION } from '../../mutations';
import { SelectBox, withSettings } from '../components';
import { Mutation } from 'react-apollo';


const ManageEventsDetailsChangeParticipation = props => {
  const {
    reservation,
    updateReservationParticipation,
    additionalGuestEmail,
    participated,
    settings: { copy }
  } = props;

  const options = [
    { id: 0, label: copy.manage.past_event_attended, value: true },
    { id: 1, label: copy.manage.past_event_didnt_attend, value: false },
  ];

  const selectedOption = participated ? options[0] : options[1];
  return (
    <Mutation mutation={UPDATE_RESERVATION_PARTICIPATION}>
      {(updateReservationParticipationMutation) => (
        <SelectBox
          name="attended"
          options={options}
          placeholder="---"
          onOptionSelect={(value) => {
            const reservationId = reservation.reservationId;
            const mainGuestParticipation = !additionalGuestEmail ? value : null;
            const additionalGuests = additionalGuestEmail ? [{ email: additionalGuestEmail, participated: value }] : null;
            updateReservationParticipation(updateReservationParticipationMutation, reservationId, mainGuestParticipation, additionalGuests);
          }}
          defaultValue={selectedOption}
        />
      )}
    </Mutation>
  );
};


const ManageEventsDetailsChangeParticipationWithSettings = withSettings(ManageEventsDetailsChangeParticipation);

export { ManageEventsDetailsChangeParticipationWithSettings as ManageEventsDetailsChangeParticipation };
