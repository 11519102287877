import React from 'react';
import styled from 'styled-components';

export const DropDownMenuFooter = ({ onButtonClick, buttonLabel = 'Save' }) => (
  <MenuFooter>
    {onButtonClick && <FooterButton onClick={onButtonClick}>{buttonLabel}</FooterButton>}
  </MenuFooter>
);

const MenuFooter = styled.div`
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  margin: 0;
  padding: 0 20px;
  border-top: 1px solid #e3e3e1;
  cursor: default;
`;

const FooterButton = styled.span`
  font-size: 12px;
  cursor: pointer;
  font-family: 'Avenir-Medium' !important;
  padding: 3px 0;
`;
