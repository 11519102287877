import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { SEND_MESSAGE } from '../../mutations';
import { alert, WYSIWYGEditor } from '../Global';

import {
  Avatar,
  Button,
  Modal,
  withSettings
} from '../components';

const initialState = {
  message: '',
  messageValid: false,
  sendMessageError: '',
};


class SendMessageModal extends React.Component {
  state = { ...initialState };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  isFormInvalid = () => {
    const { messageValid } = this.state;
    return !messageValid;
  };

  handleFormSubmit = (event, sendMessage) => {
    event.preventDefault();

    sendMessage().then(async ({ data }) => {
      if (data && data.updateUser && data.updateUser.errors) {
        alert.error('Error sending message');
      } else {
        alert.success('Message sent');

        this.setState({ message: '' }, () => {
          this.props.toggle();
        });
      }
    });
  };

  render() {
    const { className, show, toggle, firstName, lastName, profile, settings: { copy } } = this.props;
    const { message } = this.state;
    const profileId = parseInt(profile.id);

    return (
      <Mutation mutation={SEND_MESSAGE} variables={{ id: profileId, message: message }} >
        {(sendMessage, { loading }) => {
          return (
            <Modal show={show} toggle={toggle} size="md">
              <form className="ot_form" onSubmit={event => this.handleFormSubmit(event, sendMessage)}>
                <div className={className}>
                  <Avatar size="lg" layout="vertical" noName profile={profile}/>
                  <h4>{copy.manage.host_contact} {firstName} {lastName}</h4>
                  {this.state.hasSendMessageError && (<div className="form_submit_error">The</div>)}
                  <WYSIWYGEditor
                    name="message"
                    value={message}
                    onInputChange={this.handleInputChange}
                    validation={value => value.length > 0}
                    onValidate={this.handleInputValidation}
                    placeholder={copy.manage.host_contact_placeholder}
                    dontShowInvalid
                  />
                  <Button
                    buttonStyle="primary"
                    type="submit"
                    disabled={loading || this.isFormInvalid()}
                    loading={loading}
                    fullWidth
                  >
                    Send Message
                  </Button>
                </div>
              </form>
            </Modal>
          )
        }}
      </Mutation>
    );
  }
}

SendMessageModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileId: PropTypes.number
};

const StyledSendMessageModal = styled(SendMessageModal)`
  position: relative;
  text-align: center;

  p {
    // font-size: 1.8rem;
  }

  & .wysiwyg-editor {
    // margin: 50px 0;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > .logo_background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-image: url(${props => props.settings.theme.assets.images.login_modal_bg});
    background-size: cover;
    background-position: top;
    border-radius: 4px 0 0 4px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51,30,40,0.3);
      z-index: 1;
    }
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    & > .logo {
      width: 140px;
      margin: 0 auto;
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;
  }
`;

const SendMessageModalWithSettings = withSettings(StyledSendMessageModal);

export { SendMessageModalWithSettings as SendMessageModal };
