import { useState } from 'react';
import { isValidEmail } from '../../libs';
import { trigger } from '../../libs/customEvent';


export const useAdditionalGuests = () => {
  const [guests, setGuests] = useState([]);

  const validateAdditionalGuests = () => {
    let valid = true;

    guests.forEach(({ firstName, lastName, email }, index) => {
      const data = { index, name: 'additionalGuests' };

      if (!firstName) {
        valid = false;
        trigger('rsvpPage:validateGuest', {
          ...data,
          key: 'firstName'
        });
      }
      if (!lastName) {
        valid = false;
        trigger('rsvpPage:validateGuest', {
          ...data,
          key: 'lastName'
        });
      }
      if (!isValidEmail(email)) {
        valid = false;
        trigger('rsvpPage:validateGuest', {
          ...data,
          key: 'email'
        });
      }
    });

    return valid;
  };

  return {
    additionalGuests: guests,
    setAdditionalGuests: val => setGuests(val),
    validateAdditionalGuests
  }
};
