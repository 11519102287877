import React from 'react';
import styled, { keyframes } from 'styled-components';

import { withSettings } from '../components';

const loaderCards = [1,2,3,4];

const EventListLoader = ({ className }) => (
  <div className={className}>
    {loaderCards.map(key => (
      <div className="loader_card ot_event_card" key={key}>
        <div className="loader_header" />
        <div className="loader_body">
          <span className="host" />
          <span className="title" />
          <i className="divider" />
          <span className="location one" />
          <span className="location two" />
          <span className="location three" />
        </div>
      </div>
    ))}
  </div>
);

const load = keyframes`
  0% {
    opacity: 1;
    right: 100%;
  }
  40% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 0;
  }
`;

const StyledEventListLoader = styled(EventListLoader)`
  max-width: 1215px;
  margin: 0 auto;
  text-align: center;

  .loader_card {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 270px;
    margin: 15px;
    border-radius: 5px;
    box-shadow: ${props => props.settings.theme.css.global.card.shadowLight};
    cursor: default;
    background-color: #f6f6f6;
    opacity: 0.7;
  }

  .loader_header {
    height: 170px;
    background-color: #f0f0f0;
    position: relative;
  }

  .loader_body {
    padding: 40px 30px 30px;

    span {
      position: relative;
      display: block;
      background-color: #f0f0f0;
      height: 9px;
      width: 160px;

      &.host {
        width: 110px;
      }

      &.host,
      &.location {
        margin: 0 auto 17px;
      }

      &.title {
        margin: 0 auto;
      }

      &.location {
        &.one {
          width: 70px;
        }
        &.two {
          width: 110px;
        }
        &.three {
          width: 80px;
        }
      }
    }

    .divider {
      margin: 30px auto;
    }
  }

  .loader_header,
  .loader_body span {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background-color: #ececec;
      animation: ${load} 1.4s infinite ease-in-out;
    }
  }
`;

const EventListLoaderWithSettings = withSettings(StyledEventListLoader);

export { EventListLoaderWithSettings as EventListLoader };
