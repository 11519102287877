import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useSettings, useRsvpPageData, useCheckoutData } from '../../hooks';

import {
  PageSubheader as Heading,
  RsvpPageNewPaymentMethod,
  RsvpPageViewPaymentMethod,
  RsvpPageChoosePaymentMethod,
  RsvpPaymentModalCoupon,
  Divider,
  RsvpPageFooterButtons as FooterButtons
} from '../components';

import RSVP_PAGES from '../../constants/rsvpPages';


export const RsvpPageCheckout = () => {
  const { changePage, pages, loading } = useRsvpPageData();
  const { copy } = useSettings();
  const {
    checkoutData: { totalPrice },
    cardData: { card, cards },
    setCardData,
    setCoupon,
    submitPayment
  } = useCheckoutData();

  const [view, setView] = useState(false);

  const setDefaultView = () => {
    if (!card && cards.length) setView('choose_method');
    else if (card) setView('view_method');
    else setView('new_method');
  };

  useEffect(() => {
    if (view) return;
    setDefaultView();
  }, [view, card, cards]);

  const handleSelectCard = (c) => {
    setCardData('card', c);
    setView('view_method');
  };

  return (
    <>
      <Heading>{copy.rsvp.payment_title}</Heading>
      {totalPrice !== 0 && (
        <Container>
          <H3>{copy.rsvp.payment_method || 'Payment method'}</H3>
          {view === 'choose_method' &&
            <RsvpPageChoosePaymentMethod onSelect={handleSelectCard} />}
          {view === 'view_method' &&
            <RsvpPageViewPaymentMethod setChoose={() => setView('choose_method')} onNew={() => setView('new_method_with_cancel')} />}
          {view === 'new_method_with_cancel' &&
            <RsvpPageNewPaymentMethod onCancel={() => setDefaultView()} />}
          {view === 'new_method' &&
            <RsvpPageNewPaymentMethod />}
        </Container>
      )}
      <RsvpPaymentModalCoupon couponSelected={setCoupon} isFree={totalPrice === 0} />
      <Divider />
      <FooterButtons
        onBack={!pages.includes(RSVP_PAGES.MEMBERSHIP) && (() => changePage(false))}
        onContinue={() => submitPayment(view)}
        continueText={totalPrice === 0 ? 'RSVP' : 'Submit Payment'}
        terms={copy.rsvp.payment_terms || 'By purchasing you\'re agreeing to the <a href="https://onetable.org/t_and_c/" target="_blank">Terms and Cancelation Policies</a>.You will receive a refund for cancelations made 48 hours or more before the event. Cancelations made less than 48 hours before the event will not be refunded.'}
        loading={loading}
      />
    </>
  );
};

const Container = styled.div`padding-top: 15px;`;

const H3 = styled.h3`
  font-family: 'Avenir-Demi' !important;
  font-size: 16px;
  margin: 0 0 15px;
`;
