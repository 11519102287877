import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import defaultLogo from '../../assets/images/logo-horizontal-white.svg';
import defaultEmblem from '../../assets/images/logo-emblem-white.svg';


const Logo = ({ className, layout, color, link, url, settings }) => {
  const hasLink = link || url;

  const getLogoUrl = () => {
    const images = settings.theme.assets.images;

    switch (layout) {
      case 'vertical':
        if (color === 'white') {
          return images.logo_vertical_white;
        }
        return images.logo_vertical;
      case 'emblem':
        if (color === 'white') {
          // this image setting doesnt exist yet so using static assets temporarily //
          // this is for UJ which is currently OT only //
          return images.logo_emblem_white || defaultEmblem;
        }
        return images.logo_emblem;
      default:
        if (color === 'white') {
          // this image setting doesnt exist yet so using static assets temporarily //
          // this is for UJ which is currently OT only //
          return images.logo_horizontal_white || defaultLogo;
        }
        return images.logo_horizontal;
    }
  };

  return (
    <div className={`logo ${color ? color : 'primary'} ${className}`}>
      {hasLink
        ? (
          link ? (
            <Link to={link}>
              <img src={getLogoUrl()} alt={settings.theme.site.title} />
            </Link>
          ) : (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img src={getLogoUrl()} alt={settings.theme.site.title} />
            </a>
          )
        ) : (
          <img src={getLogoUrl()} alt={settings.theme.site.title} />
        )}
    </div>
  );
}

const StyledLogo = styled(Logo)`
  // &.primary {
  //   path {
  //     fill: ${props => props.settings.theme.css.global.colors.primary};
  //   }
  // }
  //
  // &.white {
  //   path {
  //     fill: #fff;
  //   }
  // }

  img {
    width: 100%;
  }
`;

const LogoWithSettings = withSettings(StyledLogo);

export { LogoWithSettings as Logo };
