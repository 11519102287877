import moment from 'moment-timezone';
import {
  sort,
  getNeighborhoodLabel,
  getSubNeighborhoodLabel ,
  stripHtml
} from '../libs';

import { EVENT_TYPE, RSVP_CAPABILITY } from '../enums';

const eventHelpers = {
  isPast: event => {
    const eventDate = moment(event.scheduledAt);
    const eventEnd = moment(eventDate).add(event.duration, 's');
    const today = moment();
    return eventEnd < today;
  },

  isScheduledAtSameMonth: (event, date) => {
    const eventDate = moment(event.scheduledAt);
    return eventDate.isSame(date, 'month');
  },

  isScheduledAtSameWeek: (event, date) => {
    const eventDate = moment(event.scheduledAt);
    return eventDate.isSame(date, 'week');
  },

  imCreator: (hosts, viewer = {}) => {
    const { user } = viewer;
    const profile = user && user.profile;
    return profile && Array.isArray(hosts) && hosts.reduce((isCreator, { node }) => (
      node && node.creator && node.profile && node.profile.id === profile.id ? true : isCreator
    ), false);
  },

  imHost: (hosts, viewer) => {
    const user = viewer ? viewer.user : null;
    if (!user || !hosts) return false;
    if (Array.isArray(hosts)) {
      let isHost = false;
      hosts.forEach(edge => {
        const { node: { profile } } = edge;
        if (profile && profile.id === user.profile.id)
          isHost = true;
       });
      return isHost;
    } else {
      return hosts.profile && hosts.profile.id === user.profile.id;
    }
  },

  isPublicEvent: event => {
    return event.eventType !== EVENT_TYPE.PRIVATE;
  },

  isApprovalEvent: event => {
    return event.eventType === EVENT_TYPE.MANUAL_APPROVAL;
  },

  isSoloEvent: event => {
    return event.eventType === EVENT_TYPE.SOLO;
  },

  imGoing: (event, viewer) => {
    const viewerReservation = eventHelpers.getEventReservation(event, viewer);
    return (viewerReservation && viewerReservation.state === 'ACCEPTED');
  },

  isVaccinated: (event, vaccDate) => {
    const eventDate = moment(event.scheduledAt || event.date).startOf('day');
    const eventDateLimit = eventDate.clone().subtract(13, 'day').startOf('day');
    const vaccinationDate = moment(vaccDate).startOf('day');
    return eventDateLimit.isAfter(vaccinationDate);
  },

  pendingHostApproval: (event, viewer) => {
    const isPublic = event.eventType !== EVENT_TYPE.PRIVATE;
    const viewerReservation = eventHelpers.getEventReservation(event, viewer);
    return (isPublic && viewerReservation && viewerReservation.state === 'PENDING');
  },

  imEventHost: (event, viewer) => {
    const hosts = event && event.eventHosts && event.eventHosts.edges ? event.eventHosts.edges : [];
    return eventHelpers.imHost(hosts, viewer);
  },

  imReserved: (event, reservations, state) => {
    const reservationState = state ? state : 'ACCEPTED';
    const reservation = reservations && reservations.filter(res => res.event && res.event.id === event.id);
    const reserved = Array.isArray(reservation) && reservation.length > 0;
    return reserved && reservation[reservation.length - 1].state === reservationState;
  },

  imInvited: (event, invitations) => {
    const invitation = invitations && invitations.filter(inv => inv && inv.event && inv.event.id === event.id);
    return Array.isArray(invitation) && invitation.length < 0;
  },

  getInvitations: (viewer) => {
    const invitations = viewer && viewer.invitations && viewer.invitations.nodes ? viewer.invitations.nodes : [];
    return invitations;
  },

  getReservations: (event) => {
    const reservations = event.reservations && event.reservations.edges ? event.reservations.edges.map(edge => edge.node) : [];
    return reservations;
  },

  getAcceptedAndPendingReservations: (event) => {
    const reservations = eventHelpers.getReservations(event);
    return reservations.filter(reservation => reservation.state === 'ACCEPTED' || reservation.state === 'PENDING');
  },

  getEventReservation: (event, viewer) => {
    const eventReservations = viewer && viewer.currentReservations && viewer.currentReservations.nodes &&
      viewer.currentReservations.nodes.filter(node => node.event && node.event.id === event.id);

    if (!eventReservations || eventReservations.length === 0) {
      return null;
    } else {
      return sort.byObjKey(eventReservations, 'id', 'desc')[0];
    }
  },

  getEventInvitation: (event, viewer) => {
    const invitations = eventHelpers.getInvitations(viewer);
    const eventId = event ? event.id : null;
    const eventInvitations = invitations.filter(invitation =>
      (invitation && invitation.event && invitation.event.id === eventId)
    );
    const eventInvitation = (eventInvitations && eventInvitations.length > 0) ? eventInvitations[0] : null;
    return eventInvitation;
  },

  getLocationWithArea: (event) => {
    if (!event) return '';

    const areaLabel = (event.area && event.area.label) ? event.area.label : '';
    const locationLabel = eventHelpers.getLocationWithoutArea(event);

    if (areaLabel && locationLabel) return `${locationLabel}, ${areaLabel}`;
    if (areaLabel) return areaLabel;
    if (locationLabel) return locationLabel;

    return '';
  },

  getLocationWithoutArea: event => {
    const neighborhood = getNeighborhoodLabel(event);
    const subneighborhood = getSubNeighborhoodLabel(event);
    const neighborhoodLabel = eventHelpers.getFormattedNeighborhood(neighborhood);
    const subneighborhoodLabel = eventHelpers.getFormattedSubneighborhood(subneighborhood);

    if (neighborhoodLabel && subneighborhoodLabel) {
      return `${neighborhoodLabel}, ${subneighborhoodLabel}`;
    }

    if (neighborhoodLabel) return neighborhoodLabel;
    if (subneighborhoodLabel) return subneighborhoodLabel;
    return '';
  },

  getLocationNeighborhood: event => {
    const neighborhood = getNeighborhoodLabel(event);
    return eventHelpers.getFormattedNeighborhood(neighborhood);
  },

  getFormattedNeighborhood: (neighborhood) => {
    if (!neighborhood) return '';

    const lowercased = neighborhood.toLowerCase();
    const formattedNeighborhood = lowercased.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });

    return formattedNeighborhood;
  },

  getFormattedSubneighborhood: (subneighborhood) => {
    if (!subneighborhood) return '';

    const lowercased = subneighborhood.toLowerCase();
    const formattedSubneighborhood = lowercased.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });

    return formattedSubneighborhood;
  },

  getEventFormattedAreaAndNeighborhood: (event) => {
    if (!event) return '';

    if (event.areaOther) {
      return event.areaOther;
    } else {
      if (event.area) {
        return eventHelpers.getLocationWithArea(event);
      } else {
        return eventHelpers.getLocationWithoutArea(event);
      }
    }
  },

  getEventLocationWithSubtype: (event, showNeighborhood, settings, showArea = true) => {
    if (!event) return '';
    const { area, areaOther, neighborhood, campus, eventSubtype } = event;
    const { features: { enable_area_user_connection } } = settings;
    const neighborhoodValue = enable_area_user_connection ? campus?.label : neighborhood;
    let location = '';

    if (areaOther) location = areaOther;
    else if (showArea && area?.label) location = area.label;

    if (showNeighborhood && neighborhoodValue) {
      location += `${location.length ? ', ' : ''}${neighborhoodValue}`;
    }

    if (eventSubtype) {
      location += `${location.length ? ', ' : ''}${eventSubtype.name}`;
    }

    return location;
  },

  getEventReservations: event => {
    return (event && event.reservations && event.reservations.edges && event.reservations.edges.map(edge => edge.node)) || [];
  },

  getPendingReservations: event => {
    return eventHelpers.getEventReservations(event).filter(res => res.state === 'PENDING');
  },

  getAcceptedReservations: event => {
    return eventHelpers.getEventReservations(event).filter(res => res.state === 'ACCEPTED');
  },

  getRejectedReservations: event => {
    return eventHelpers.getEventReservations(event).filter(res => res.state === 'REJECTED');
  },

  getCanceledReservations: event => {
    return eventHelpers.getEventReservations(event).filter(res => res.state === 'CANCELED');
  },

  getEventInvitations: event => {
    return (event && event.invitations && event.invitations.edges && event.invitations.edges.map(edge => edge.node)) || [];
  },

  getPendingInvitations: event => {
    return eventHelpers.getEventInvitations(event).filter(inv => inv.status === 'PENDING');
  },

  getRejectedInvitations: event => {
    return eventHelpers.getEventInvitations(event).filter(inv => inv.status === 'REJECTED');
  },

  needsManualApproval: (event) => {
    return (event && event.eventType === EVENT_TYPE.MANUAL_APPROVAL);
  },

  renderAddressLabel: (event, canViewAddress) => {
    if (!event || !event.area || !event.area.label) {
      return null;
    }

    const area = event.area.label;
    const neighborhood = event.neighborhood === 'OTHER' ? event.areaOther : getNeighborhoodLabel(event);
    const subneighborhood = getSubNeighborhoodLabel(event);
    const fullAddress = event.fullAddress;

    const labelParts = [area];
    if (neighborhood) labelParts.push(neighborhood);
    if (subneighborhood) labelParts.push(subneighborhood);

    return `${labelParts.join(', ')}${canViewAddress && fullAddress ? ` | ${fullAddress}` : ''}`;
  },

  renderSecondaryAddressLabel: (event, canViewAddress) => {
    if (!event || !event.secondaryAddress || !canViewAddress) {
      return null;
    }

    const secondaryAddress = event.secondaryAddress;
    return canViewAddress && secondaryAddress ? secondaryAddress : null;
  },

  hasDescription: event => {
    return !!(event.description !== null && event.description.trim() !== '');
  },

  hasExtraDetails: event => {
    const details = event.extraDetails !== null
      ? stripHtml(event.extraDetails)
      : false;
    return !!(details && details.length > 0);
  },

  hasTypes: event => {
    return !!(
      event.alcoholPolicy || event.dressCodeName ||
      (Array.isArray(event.dietaryRestrictions) && event.dietaryRestrictions.length)
    );
  },

  hasTags: event => {
    return !!(Array.isArray(event.tags) && event.tags.length);
  },

  hasReservations: event => {
    const hasReservations = !!(
      event.reservations &&
      Array.isArray(event.reservations.edges) &&
      event.reservations.edges.length
    );

    if (hasReservations) {
      const reservations = event.reservations.edges.map(edge => edge.node);
      return reservations.filter(res => res.state === 'ACCEPTED').length > 0;
    }

    return false;
  },

  hasTables: event => {
    return event.communityDinner && Array.isArray(event.tables) && event.tables.length;
  },

  hasSponsorships: event => {
    return !!(event.sponsorships && event.sponsorships.length);
  },

  isProviding: menu => {
    /*
      TODO:
      hopfully changing how these courses are stored.
    */
    if (!menu) return false;
    return (
      (Array.isArray(menu.first) && !!menu.first.length) ||
      (Array.isArray(menu.second) && !!menu.second.length) ||
      (Array.isArray(menu.third) && !!menu.third.length)
    );
  },

  isPotluck: (event) => {
    return event && event.potluck && event.potluckItems && event.potluckItems && event.potluckItems.edges && event.potluckItems.edges.length > 0;
  },

  getTablesOptions: (event) => {
    const tables = event && event.tables ? event.tables : [];
    return tables.map(table => {
      return { id: table.id, label: table.name, value: table.id }
    });
  },

  getEventMaxAdditionalGuests: (event) => {
    if (event.pricePerPerson <= 0) {
      if (event.area.maxUnpaidAdditionalGuests != null) {
        return event.area.maxUnpaidAdditionalGuests;
      } else {
        return 1;
      }
    }

    if (event.area.maxPaidAdditionalGuests != null) {
      return event.area.maxPaidAdditionalGuests;
    } else {
      return 1;
    }
  },

  getGuestSelectorEligibility: (event, checkPendingSeats) => {
    const guestCount = event.reservations.guestsAccepted + (
      checkPendingSeats ? event.reservations.guestsPending : 0
    );

    return (guestCount < (event.numberOfGuestsMax - 1)) &&
    (
      (event.paid && event.area?.maxPaidAdditionalGuests > 0) ||
      (!event.paid && event.area?.maxUnpaidAdditionalGuests > 0)
    );
  },

  isVaccinatedEvent: (event) => {
    return event.privacySubtype && event.privacySubtype.vaccinated;
  },

  isRestrictedDay: (value, restrictedDays, exceptionDays) => {
    const day = moment(value).weekday();
    let isRestricted = true;
    let isException = false;

    if (typeof restrictedDays === 'undefined') return false;

    const restrictions = restrictedDays ? restrictedDays.split(',') : [];

    restrictions.forEach(restriction => {
      if (eventHelpers.getDayOfWeek(restriction) === day) isRestricted = false;
    });

    if (isRestricted && exceptionDays) {
      const exceptions = exceptionDays.split(',');

      exceptions.forEach(exception => {
        if (moment(exception).isSame(moment(value), 'day')) isException = true;
      });

      return !isException;
    } else {
      return isRestricted;
    }
  },

  getDayOfWeek: day => {
    switch (day) {
      case 'Sun':
        return 0;
      case 'Mon':
        return 1;
      case 'Tue':
        return 2;
      case 'Wed':
        return 3;
      case 'Thu':
        return 4;
      case 'Fri':
        return 5;
      case 'Sat':
        return 6;
      default:
        return null;
    }
  },

  // getDayExceptionClass: (exceptionsSting, dow) => {
  //   if (!exceptionsSting) return '';
  //   const dayNumber = eventHelpers.getDayOfWeek(dow);
  //   const exceptions = exceptionsSting.split(',');
  //   let isDayOfWeek = false;
  //   let exceptionDate = '';
  //
  //   exceptions.forEach(ex => {
  //     const dayOfWeek = moment(ex).day();
  //     if (dayOfWeek === dayNumber) {
  //       isDayOfWeek = true;
  //       exceptionDate = moment(ex).format('MMMM, D YYYY')
  //     }
  //   });
  //
  //   if (isDayOfWeek) {
  //     console.log(`:not([title="${exceptionDate}"])`);
  //     return `:not([title="${exceptionDate}"])`;
  //   } else {
  //     return '';
  //   }
  // },

  getExceptionSelectors: exceptionsSting => {
    if (!exceptionsSting) return '.bogus_class_name_that_hopefully_wont_select_anything';
    const exceptions = exceptionsSting.split(',');
    let selectors = '';

    exceptions.forEach((ex, i) => {
      selectors += `&[title="${moment(ex).format('MMMM D, YYYY')}"]`;
      if (i < exceptions.length - 1) selectors += ',';
    });

    return selectors;
  },

  formatDate: date => {
    var dateFormatted;

    if (date) {
      dateFormatted = date;
      const year = dateFormatted.getFullYear();
      let month = dateFormatted.getMonth() + 1;

      if (month.toString().length === 1) {
        month = '0' + month;
      }

      let day = dateFormatted.getDate();
      if (day.toString().length === 1) {
        day  = '0' + day;
      }
      dateFormatted = year + '-' + month + '-' + day;
    }

    return dateFormatted;
  },

  formatTime(time) {
    let timeFormatted;

    if (time) {
      timeFormatted = time;
      let hours = timeFormatted.getHours();
      let minutes = timeFormatted.getMinutes();

      if (hours.toString().length === 1) {
        hours  = '0' + hours;
      }

      if (minutes.toString().length === 1) {
        minutes  = '0' + minutes;
      }

      timeFormatted = hours + ':' + minutes;
    }

    return timeFormatted;
  },

  getScheduledAtDate: (event, showYear) => {
    const eventTime = moment.parseZone(event.scheduledAt);
    const eventDay = eventTime.startOf('day');
    const today = moment().startOf('day');
    const difference = Math.round(today.diff(eventTime, 'days', true));

    if (difference === 0) {
      return 'Today';
    } else if (difference === -1) {
      return 'Tomorrow';
    } else {
      return eventDay.format(showYear ? 'ddd, MMM D YYYY' : 'dddd, MMMM D')
    }
  },

  getDeadlineAtDate: event => {
    const eventTime = moment.parseZone(event.effectiveRsvpDeadlineAt?event.effectiveRsvpDeadlineAt:event.scheduledAt);
    const eventDay = eventTime.startOf('day');
    const today = moment().startOf('day');
    const difference = Math.round(today.diff(eventTime, 'days', true));
    if (difference === 0) {
      return 'Today';
    } else if (difference === -1) {
      return 'Tomorrow';
    } else {
      return eventDay.format('dddd, MMMM D');
    }
  },

  getEventTimeSpan: (event, abbreviated) => {
    const scheduledAt = moment.parseZone(event.scheduledAt);
    const startTime = scheduledAt.format(abbreviated && scheduledAt.minutes() === 0 ? 'h' : 'h:mma');
    const endTime = scheduledAt.add(event.duration, 's').format(abbreviated ? 'ha' : 'h:mma');
    return `${startTime} - ${endTime}`;
  },

  getEventEtPtTimes: event => {
    // have to create a second instance or the timezones overwrite each other //
    const scheduledAtPt = moment.parseZone(event.scheduledAt).tz('America/Los_Angeles');
    const scheduledAtEt = moment.parseZone(event.scheduledAt).tz('America/New_York');

    return `${scheduledAtPt.format('h:mma')} PT | ${scheduledAtEt.format('h:mma')} ET`;
  },

  getDeadlineAtTime: event => {
    const time = event.effectiveRsvpDeadlineAt;
    return moment.parseZone(time).format('h:mma');
  },

  getDaysLeft: dateStr => {
    const date = new Date(Date.parse(dateStr));
    const today = new Date();
    const diffTime = date.getTime() - today.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  },

  nourishmentsDeadlineHasPassed: event => {
    if (!event || !event.sponsorshipDeadline) return true;

    const now = moment();
    const deadline = eventHelpers.getNourishmentCutoffDateFromEvent(event);

    return now > deadline;
  },

  nourishmentDeadlineIsExtended: event => {
    const { nourishmentRestrictionExceptionExpiresAt } = event;

    if (!nourishmentRestrictionExceptionExpiresAt) return false;

    const today = moment();
    const newExpiration = moment(nourishmentRestrictionExceptionExpiresAt);

    return newExpiration > today;
  },

  getDaysLeftForNourishmentSelection: event => {
    if (!event) {
      return -1;
    }

    const { nourishmentRestrictionExceptionExpiresAt } = event;
    const deadline = eventHelpers.getNourishmentCutoffDateFromEvent(event);
    const now = moment();
    let dateToCheck = deadline;

    if (nourishmentRestrictionExceptionExpiresAt) {
      dateToCheck = moment(nourishmentRestrictionExceptionExpiresAt);
    }

    return Math.round(dateToCheck.diff(now, 'days', true));
  },

  getNourishmentCutoffDateFromEvent: (event) => {
    return moment(event.sponsorshipDeadline);
  },

  getNourishmentDeadlineStringFromDate: date => {
    return moment(date).format('MMMM Do, YYYY');
  },

  getEventWrittenDate: event => {
    if (!event || !event.scheduledAt) return '';
    return moment(event.scheduledAt).format('MMMM Do, YYYY');
  },

  getPotluckReservedItemsCount: event => {
    if (!event || !event.potluckItems || !event.potluckItems.edges) {
      return 0;
    }

    return event.potluckItems.edges.reduce((accumulator, item) => {
      if (!item.node || !item.node.claimedQuantity) {
        return accumulator;
      }

      return accumulator + item.node.claimedQuantity;
    }, 0);
  },

  getPotluckTotalItemsCount: event => {
    if (!event || !event.potluckItems || !event.potluckItems.edges) {
      return 0;
    }

    return event.potluckItems.edges.reduce((accumulator, item) => {
      if (!item.node || !item.node.quantity) {
        return accumulator;
      }

      return accumulator + item.node.quantity;
    }, 0);
  },

  getEventIdFromPath: path => {
    if (!path || Array.isArray(path)) return '';
    const pathComponents = path.split('/');
    return pathComponents[2];
  },

  getNourishmentValue: (session, event) => {
    const { eventTypeSponsorshipSettings } = session;
    for (const item of eventTypeSponsorshipSettings) {
      if (item.eventType === event.eventType) {
        if (event.expectedGuests) {
          // host needs to be added to total count //
          return Math.min((event.expectedGuests + 1) * item.pricePerHead, item.priceMax) / 100;
        } else {
          return item.priceMax / 100;
        }
      }
    }
  },

  getEventDuration: (date, startTime, endTime) => {
    const eventDate = moment(date);
    const startDateAndTime = moment(eventDate).set({ 'hour': moment(startTime).get('hour'), 'minute': moment(startTime).get('minute') });
    const endDateAndTime = moment(eventDate).set({ 'hour': moment(endTime).get('hour'), 'minute': moment(endTime).get('minute') });

    if (startDateAndTime > endDateAndTime) {
      endDateAndTime.add(1, 'days');
    }

    const begin = moment(startDateAndTime);
    const end = moment(endDateAndTime);
    const duration = moment.duration(end.diff(begin));
    const durationInSeconds = duration.asSeconds();

    return durationInSeconds;
  },

  getEventEndTime: (scheduledAt, duration) => {
    const date = new Date(scheduledAt);
    date.setSeconds(date.getSeconds() + duration);
    return date;
  },

  getEventDeadlineAt: (deadlineDate, deadlineTime) => {
    const date = moment(deadlineDate).format('YYYY-MM-DD');
    const time = moment(deadlineTime).format('HH:mm:ss');

    const fullDate = `${date}T${time}`;
    return fullDate;
  },

  getEventEndingTime: (deadlineDate, deadlineTime) => eventHelpers.getEventDeadlineAt(deadlineDate, deadlineTime),

  getEventPotluckEdges: (event) => {
    const potluckEdges = (event && event.potluckItems && event.potluckItems.edges) ? event.potluckItems.edges : [];
    return potluckEdges;
  },

  getBackgroundStyle: (event, defaultImage, settings) => {
    const images = settings.theme.assets.images;

    if (event.cover) {
      return { backgroundImage: `url(${event.cover.originalUrl})` };
    } else {
      return { backgroundImage: `url(${defaultImage ? defaultImage : images.default_cover})` }
    }
  },

  getReservationQuestions: (questions, toDelete) => {
    if (Array.isArray(questions) && questions.length > 0) {
      const formattedQuestions = questions.map(q => ({
        position: q.position,
        text: q.text,
        answerType: q.answerType,
        disabled: false,
        baseQuestionId: q.baseQuestionId
      }));

      if (Array.isArray(toDelete) && toDelete.length > 0) {
        toDelete.forEach(id => {
          formattedQuestions.push({
              id,
              _destroy: true
          });
        })
      }

      return formattedQuestions;
    } else {
      return [];
    }
  },

  getReservationStatus: (event, viewer) => {
    if (!event) return RSVP_CAPABILITY.CAN_RSVP;

    if (eventHelpers.imEventHost(event, viewer)) return RSVP_CAPABILITY.HOST_VIEWER;

    const viewerEventReservation = eventHelpers.getEventReservation(event, viewer);
    const viewerEventInvitation = eventHelpers.getEventInvitation(event, viewer);

    if (viewerEventReservation) return RSVP_CAPABILITY[`RSVP_${viewerEventReservation.state}`];
    if (viewerEventInvitation) return RSVP_CAPABILITY[`INVITE_${viewerEventInvitation.status}`];

    if (event.reservations.guestsAccepted >= event.numberOfGuestsMax)
      return RSVP_CAPABILITY.EVENT_FULL;

    return RSVP_CAPABILITY.CAN_RSVP;
  },

  hasReachedEndRsvpTime: (event) => {
    const canSubscribe = (event && event.canSubscribe);
    return !canSubscribe;
  },

  isRsvp: (event) => {
    return (event && event.eventType !== 'MANUAL_APPROVAL');
  },

  monthHasNourishmentsAvailable: (event, area, viewer) => {
    if (!area) { return false; }
    if (!viewer) { return false; }

    if (!area.limitOneNourishmentPerMonth) {
      return true;
    }

    const eventMonth = moment(event.scheduledAt).format('YYYY-MM');

    const viewerHostedEvents = viewer.hostedEvents && viewer.hostedEvents.edges ? viewer.hostedEvents.edges.map(e => e.node) : [];
    const viewerDraftEvents = viewer.draftEvents && viewer.draftEvents.edges ? viewer.draftEvents.edges.map(e => e.node) : [];

    let nourishmentsAvailable = true;

    viewerHostedEvents.forEach(e => {
      const sameMonth = (eventMonth === moment(e.scheduledAt).format('YYYY-MM'));
      const sameArea = (area.id === e.area.id);
      const nourishmentSelectedForMonthAndArea = (sameMonth && sameArea);

      if (nourishmentSelectedForMonthAndArea) {
        nourishmentsAvailable = false;
      }
    });

    if (!nourishmentsAvailable) {
      return false;
    }

    viewerDraftEvents.forEach(e => {
      const sameMonth = (eventMonth === moment(e.scheduledAt).format('YYYY-MM'));
      const sameArea = (area.id === e.area.id);
      const nourishmentSelectedForMonthAndArea = (sameMonth && sameArea);

      if (nourishmentSelectedForMonthAndArea) {
        nourishmentsAvailable = false;
      }
    });

    return (nourishmentsAvailable);
  },

  nourishmentsHaveExpired: (event, area) => {
    return (eventHelpers.getDaysLeftForNourishmentSelection(event, area) < 0);
  },

  areaHasReachedNourishmentsWeeklyLimit: (event, eventArea) => {
    if (!event) { return false; }
    if (!eventArea) { return false; }
    if (!eventArea.maxedWeeklySponsorshipLimits) { return false; }
    if (!eventArea.maxedWeeklySponsorshipLimits.edges) { return false; }

    const edges = eventArea.maxedWeeklySponsorshipLimits.edges;
    const areaHasReachedNourishmentsMaxWeeklyLimit = edges.filter(edge => {
      if (!edge.node) { return false; }
      return (edge.node.beginningOn === moment(event.scheduledAt).day(0).format('YYYY-MM-DD'));
    }).length > 0;

    return (!areaHasReachedNourishmentsMaxWeeklyLimit);
  },

  getEventArea: (event, areas) => {
    if (!event || !event.area) {
      return null;
    }

    return areas.find(a => a.id === event.area.id);
  },

  hostCanSelectNourishmentsForEvent: (event, eventArea, viewer) => {
    if (!viewer) { return false; }
    if (!viewer.user) { return false; }
    if (!viewer.user.hostApprovalAt) { return false; }

    const hostApprovedOn = viewer.user.hostApprovalAt;
    const areaNewHostNourishmentsDelayDays = eventArea.newHostNourishmentDelayDays;
    const hostSponsorshipsInAreaAvailableSinceDate = event.scheduledAt ? moment.utc(hostApprovedOn).startOf('day').add(areaNewHostNourishmentsDelayDays, 'days').format() : null;
    const eventDate = event.scheduledAt ? moment.utc(event.scheduledAt).startOf('day').format() : null;

    if (moment(hostSponsorshipsInAreaAvailableSinceDate).isAfter(eventDate)) {
      return false;
    } else {
      return true;
    }
  },

  isNourishable: (event, areas, viewer) => {
    const eventArea = eventHelpers.getEventArea(event, areas);

    if (!eventArea) {
      return false;
    }

    const nourishmentsHaveExpired = eventHelpers.nourishmentsHaveExpired(event);

    if (nourishmentsHaveExpired) {
      return false;
    }

    const monthHasNourishmentsAvailable = eventHelpers.monthHasNourishmentsAvailable(event, eventArea, viewer);

    if (!monthHasNourishmentsAvailable) {
      return false;
    }

    const areaHasReachedNourishmentsWeeklyLimit = eventHelpers.areaHasReachedNourishmentsWeeklyLimit(event, eventArea, areas);

    if (areaHasReachedNourishmentsWeeklyLimit) {
      return false;
    }

    const hostIsNourishable = eventHelpers.hostCanSelectNourishmentsForEvent(event, eventArea, viewer);

    return (hostIsNourishable);
  },

  getAreaNeighborhoods: (area) => {
    if (!area || !area.neighborhoods || !Array.isArray(area.neighborhoods)) {
      return [];
    } else {
      return area.neighborhoods;
    }
  },

  getEventCoverImageUrl: (event, preferableSize, defaultCover) => {
    if (!event || !event.cover) { return defaultCover }

    const preferableSizeKey = `${preferableSize}Url`;

    if (event.cover[preferableSizeKey]) {
      return event.cover[preferableSizeKey];
    } else if (event.cover.originalUrl) {
      return event.cover.originalUrl;
    } else {
      return defaultCover;
    }
  },

  getEventWeekNumber: event => {
    return moment.parseZone(event.scheduledAt).week();
  },

  getWeekDateAsString: event => {
    return event ? moment.parseZone(event.scheduledAt).format('MMMM D') : '';
  },

  getSavedEventResponseField: (response, field) => {
    return response && response.data && response.data.createEvent &&
      response.data.createEvent.event && response.data.createEvent.event[field];
  },

  getMaxSeatLimit: (event, areas, settings, viewer, defaultType, isVirtual) => {
    const { event: { virtual } } = settings;

    if (event && (!event.eventType || event.virtual || isVirtual)) return undefined;
    if (!event && areas.length === 1) return !isVirtual && areas[0].socialDistancePolicy
      ? areas[0].socialDistancePolicy[
        `${defaultType === 'MANUAL_APPROVAL' ? 'manualApproval' : defaultType.toLowerCase()}NonVirtualMaxSeatsCap`
      ]
      : undefined;

    const policyEventType = event.eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : event.eventType.toLowerCase();
    const policyName = `${policyEventType}NonVirtualMaxSeatsCap`;
    const maxGuestCap = event && event.privacySubtype && event.privacySubtype.socialDistancePolicy
      ? event.privacySubtype.socialDistancePolicy[policyName]
      : (event && event.area && event.area.socialDistancePolicy
        ? event.area.socialDistancePolicy[policyName]
        : null);

    const globalSeatCap = event && event.eventType && virtual[
      `${event.eventType === 'MANUAL_APPROVAL' ? 'manual_approval' : event.eventType.toLowerCase()}_non_virtual_max_seats_cap`
    ];

    if (!maxGuestCap) return globalSeatCap ? parseInt(globalSeatCap) : undefined;

    return maxGuestCap;
  },

  formatPrivacySubtypes: data => {
    const subtypes = {};

    Array.isArray(data) && data.forEach(({ eventType, disabled, ...rest }) => {
      if (!subtypes.hasOwnProperty(eventType)) subtypes[eventType] = [];
      if (!disabled) subtypes[eventType].push({ ...rest });
    });

    for (const i in subtypes) {
      subtypes[i].sort((a, b) => (a.position > b.position) ? 1 : -1)
    }

    return subtypes;
  },

  generateAddToCalendarUrl: (currentEvent, areas, calendarToShare) => {
    let locationLabel;
    let area = null;

    if (currentEvent.area) {
      area = areas.find(item => item.id === currentEvent.area.id);
      if (area) locationLabel = currentEvent.area.code === 'OTHER' ? currentEvent.areaOther : area.label;
    }

    if (currentEvent.neighborhood) {
      const neighborhoods = (area && area.neighborhoods) ? area.neighborhoods : [];
      const neighborhood = neighborhoods.find(item => item.code === currentEvent.neighborhood);

      if (neighborhood) locationLabel += ', ' + neighborhood.label;
      else locationLabel += ', ' + currentEvent.neighborhood;
    }

    if (currentEvent.fullAddress) locationLabel += ', ' + currentEvent.fullAddress;

    const scheduledAtDate = new Date(currentEvent.scheduledAt);
    const dateBegin = new Date(scheduledAtDate.getTime());

    const dateEnd = new Date(scheduledAtDate.getTime());
    dateEnd.setSeconds(dateBegin.getSeconds() + currentEvent.duration);

    const startDateFormatted = moment(dateBegin).format('YYYYMMDDTHHmmss');
    const endDateFormatted = moment(dateEnd).format('YYYYMMDDTHHmmss');

    if (calendarToShare === 'google') {
      return 'https://www.google.com/calendar/render?action=TEMPLATE&text=' + currentEvent.title
        + '&dates=' + startDateFormatted + '/' + endDateFormatted
        + '&location=' + ((currentEvent.virtual ? currentEvent.virtualLocation : locationLabel) || '')
        + '&details=' + encodeURIComponent(currentEvent.description) + ' -- ' + document.URL
        + '&source.url=' + document.URL;
    } else if (calendarToShare === 'ical') {
      return 'data:text/calendar;charset=utf8,' + [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + (startDateFormatted || ''),
        'DTEND:' + (endDateFormatted || ''),
        'SUMMARY:' + (currentEvent.title || ''),
        'DESCRIPTION:' + (currentEvent.description || '').replace(/(?:\r\n|\r|\n)/g, '\\n'),
        'LOCATION:' + ((currentEvent.virtual ? currentEvent.virtualLocation : locationLabel) || ''),
        'END:VEVENT',
        'END:VCALENDAR'].join('\n');
    }
  },

  addToHeapData: (event) => {
    if (window.heap) {
      window.heap.addUserProperties({
        eventId: event.uuid,

      })
    }
  }
};

const {
  isPast,
  isScheduledAtSameMonth,
  isScheduledAtSameWeek,
  imHost,
  imCreator,
  imEventHost,
  imReserved,
  imInvited,
  isVaccinated,
  needsManualApproval,
  renderAddressLabel,
  renderSecondaryAddressLabel,
  hasDescription,
  hasExtraDetails,
  hasTypes,
  hasTags,
  hasReservations,
  hasTables,
  hasSponsorships,
  isProviding,
  isPotluck,
  getTablesOptions,
  getEventMaxAdditionalGuests,
  getGuestSelectorEligibility,
  isVaccinatedEvent,
  isRestrictedDay,
  getDayOfWeek,
  getExceptionSelectors,
  getScheduledAtDate,
  getDeadlineAtDate,
  getEventTimeSpan,
  getEventEtPtTimes,
  getDeadlineAtTime,
  getDaysLeft,
  nourishmentsDeadlineHasPassed,
  nourishmentDeadlineIsExtended,
  getDaysLeftForNourishmentSelection,
  getEventWrittenDate,
  getPotluckReservedItemsCount,
  getPotluckTotalItemsCount,
  getEventIdFromPath,
  getNourishmentCutoffDateFromEvent,
  getNourishmentDeadlineStringFromDate,
  getNourishmentValue,
  getEventDuration,
  getEventEndTime,
  getEventDeadlineAt,
  getEventEndingTime,
  getBackgroundStyle,
  getReservationQuestions,
  getInvitations,
  getReservations,
  getAcceptedAndPendingReservations,
  getPendingReservations,
  getAcceptedReservations,
  getRejectedReservations,
  getCanceledReservations,
  getEventReservation,
  getEventInvitation,
  isPublicEvent,
  isApprovalEvent,
  isSoloEvent,
  formatDate,
  imGoing,
  pendingHostApproval,
  getEventPotluckEdges,
  getReservationStatus,
  hasReachedEndRsvpTime,
  isRsvp,
  isNourishable,
  getAreaNeighborhoods,
  getLocationWithArea,
  getLocationWithoutArea,
  getLocationNeighborhood,
  getEventLocationWithSubtype,
  getFormattedNeighborhood,
  getFormattedSubneighborhood,
  getEventFormattedAreaAndNeighborhood,
  getEventCoverImageUrl,
  getEventWeekNumber,
  getWeekDateAsString,
  getEventInvitations,
  getPendingInvitations,
  getRejectedInvitations,
  getSavedEventResponseField,
  getMaxSeatLimit,
  formatPrivacySubtypes,
  generateAddToCalendarUrl,
  addToHeapData
} = eventHelpers;

export {
  eventHelpers,
  isPast,
  isScheduledAtSameMonth,
  isScheduledAtSameWeek,
  imHost,
  imCreator,
  imEventHost,
  imReserved,
  imInvited,
  isVaccinated,
  needsManualApproval,
  renderAddressLabel,
  renderSecondaryAddressLabel,
  hasDescription,
  hasExtraDetails,
  hasTypes,
  hasTags,
  hasReservations,
  hasTables,
  hasSponsorships,
  isProviding,
  isPotluck,
  getTablesOptions,
  getEventMaxAdditionalGuests,
  getGuestSelectorEligibility,
  isVaccinatedEvent,
  isRestrictedDay,
  getDayOfWeek,
  getExceptionSelectors,
  getScheduledAtDate,
  getDeadlineAtDate,
  getEventTimeSpan,
  getEventEtPtTimes,
  getDeadlineAtTime,
  getDaysLeft,
  nourishmentsDeadlineHasPassed,
  nourishmentDeadlineIsExtended,
  getDaysLeftForNourishmentSelection,
  getEventWrittenDate,
  getPotluckReservedItemsCount,
  getPotluckTotalItemsCount,
  getEventIdFromPath,
  getNourishmentCutoffDateFromEvent,
  getNourishmentDeadlineStringFromDate,
  getNourishmentValue,
  getEventDuration,
  getEventEndTime,
  getEventDeadlineAt,
  getEventEndingTime,
  getBackgroundStyle,
  getReservationQuestions,
  getInvitations,
  getReservations,
  getAcceptedAndPendingReservations,
  getPendingReservations,
  getAcceptedReservations,
  getRejectedReservations,
  getCanceledReservations,
  getEventReservation,
  getEventInvitation,
  isPublicEvent,
  isApprovalEvent,
  isSoloEvent,
  formatDate,
  imGoing,
  pendingHostApproval,
  getEventPotluckEdges,
  getReservationStatus,
  hasReachedEndRsvpTime,
  isRsvp,
  isNourishable,
  getAreaNeighborhoods,
  getLocationWithArea,
  getLocationWithoutArea,
  getLocationNeighborhood,
  getEventLocationWithSubtype,
  getFormattedNeighborhood,
  getFormattedSubneighborhood,
  getEventFormattedAreaAndNeighborhood,
  getEventCoverImageUrl,
  getEventWeekNumber,
  getWeekDateAsString,
  getEventInvitations,
  getPendingInvitations,
  getRejectedInvitations,
  getSavedEventResponseField,
  getMaxSeatLimit,
  formatPrivacySubtypes,
  generateAddToCalendarUrl,
  addToHeapData
};
