import gql from 'graphql-tag';

const fragments = `
  fragment stringBoxed on StringBoxed {
    string
  }

  fragment booleanBoxed on BooleanBoxed {
    boolean
  }

  fragment scalarBoxed on ScalarBoxed {
    ...stringBoxed
    ...booleanBoxed
  }

  fragment allUnnestedBoxed on AllUnnestedBoxed {
    ...scalarBoxed
    ... on UnnestedArrayBoxed {
      array {
        ...stringBoxed
        ...booleanBoxed
      }
    }
  }
`;

export const UJ = gql`
  ${fragments}
  fragment widgetOptions on WidgetOption {
    label
    value {
      ...scalarBoxed
    }
  }

  query journeys {
    journeys {
      nodes {
        id
        initialJourneyPage {
          id
        }
        journeyPages {
          nodes {
            __typename
            id
            renderTemplate
            name
            action
            backgroundImage {
              id
              url
            }
            backgroundImages {
              id
              url
            }
            results {
              nodes {
                id
                conditions {
                  ... on JourneyPageResultConditionQuestion {
                    questionId
                    answer {
                      ...scalarBoxed
                    }
                    responseQuestionId
                    responseAnswer {
                      ...scalarBoxed
                    }
                  }
                }
                nextUrl
                nextJourneyPage {
                  id
                  renderTemplate
                }
                journeyPageResultResources {
                  nodes {
                    id
                    position
                    disabled
                    resource {
                      id
                      title
                      description
                      disabled
                      image {
                        smallUrl
                        originalUrl
                      }
                      horizontalMobileImagePosition
                      verticalMobileImagePosition
                      url
                    }
                  }
                }
              }
            }
            ... on TemplateJourneyPage {
              template
              widgets {
                nodes {
                  __typename
                  id
                  label
                  size
                  actions {
                    nodes {
                      value {
                        ...allUnnestedBoxed
                      }
                      backgroundImage {
                        id
                        url
                      }
                      songUrl
                      quote
                    }
                  }
                  ... on TextWidget {
                    required
                    default {
                      ...stringBoxed
                    }
                    placeholder
                  }
                  ... on SelectWidget {
                    required
                    default {
                      ...allUnnestedBoxed
                    }
                    placeholder
                    multiple
                    options {
                      ...widgetOptions
                    }
                  }
                  ... on RadioWidget {
                    required
                    default {
                      ...scalarBoxed
                    }
                    options {
                      ...widgetOptions
                    }
                  }
                  question {
                    id
                    multiple
                    answerType
                    prefill
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UJ_BACKGROUNDS = gql`
  query journeys {
    journeys {
      nodes {
        id
        journeyPages {
          nodes {
            __typename
            id
            renderTemplate
            backgroundImage {
              id
              url
            }
            backgroundImages {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const UJ_RESPONSE_BY_CLAIM_TOKEN = gql`
  ${fragments}
  query (
    $claimToken: String!
  ) {
    journeyResponseByClaimToken(
      claimToken: $claimToken
    ) {
      id
      answers {
        nodes {
          id
          question {
            id
          }
          response {
            ...allUnnestedBoxed
          }
        }
      }
      resources {
        edges {
          node {
            id
            description
            image {
              mediumUrl
            }
            title
            url
          }
        }
      }
    }
  }
`;
