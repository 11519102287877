import React from 'react';
import styled, { css } from 'styled-components';

import { withSettings } from '../components';
import { stringInsert } from '../../libs';


const EventDetailCardFooter = (props) => {
  const {
    settings: { copy },
    event: { pricePerPerson, eventHosts: { edges: hosts } },
    hideHost
  } = props;
  const host = hosts && hosts.find(({ node }) => node.creator);
  const hostName = host && host.node && host.node.profile && host.node.profile.firstName;

  return (
    <Footer>
      {hostName && !hideHost && stringInsert(copy.search.is_hosting_label || '%1$s is hosting', hostName)}
      <Price hideHost={hideHost}>{pricePerPerson ? `$${pricePerPerson / 100}` : 'Free'}</Price>
    </Footer>
  );
};

const Footer = styled.footer`
  position: absolute;
  left: 30px;
  bottom: 30px;
  right: 30px;
  border-top: 1px solid #deddda;
  font-size: 14px;
  color: #6d6e71;
  padding: 20px 0 0;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const Price = styled.span`
  color: #221f20;
  font-family: 'Avenir-Medium';

  ${({ hideHost }) => hideHost ? css`
    font-size: 26px;
    display: block;
    text-align: center;
  ` : css`
    float: right;
    font-size: 16px;
  `}
`;

const EventDetailCardFooterWithSettings = withSettings(EventDetailCardFooter);
export { EventDetailCardFooterWithSettings as EventDetailCardFooter };
