import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input, withSettings } from '../components';

import { getSiteSubNameFromSettings } from '../../libs';

const ProfileEditQuestions = (props) => {
  const {
    className,
    questions,
    handleInputChange,
    handleQuestionDisplayToggleChange,
    settings,
    useMyProfile = false
  } = props;
  const { copy } = settings;
  const siteSubName = getSiteSubNameFromSettings(settings);

  const renderQuestionOptions = (question) => {
    const onInputChange = (e) => handleInputChange(e, false, useMyProfile);
    const onOtherInputChange = (n, v, e) => handleInputChange(e, true, useMyProfile);

    switch (question.type) {
      case 'radio':
      case 'checkbox':
        return question.choices.map(choice => {
          if (choice.id === 'freeChoice') {
            return (
              <div className='fill-my-own-container' key={choice.id}>
                <span>Fill in my own</span>
                <input type="text" name={question.name} value={choice.value || ''} onChange={onInputChange} placeholder='Other' />
              </div>
            );
          }

          return (
            <div key={choice.id}>
              <label className="input-selectable">
                <input type={question.type} name={question.name} value={choice.id} checked={choice.value} onChange={onInputChange} />
                <span>{choice.label}</span>
              </label>
              {question.otherOptional && choice.id === 'other' && choice.value && (
                <Input
                  type="text"
                  name={`${question.name}Other`}
                  value={question.otherAnswer}
                  onInputChange={onOtherInputChange}
                />
              )}
            </div>
          )
        });

      case 'text':
        return <input type="text" name={question.name} value={question.value} onChange={onInputChange} />;

      case 'textarea':
        return <textarea name={question.name} value={question.value} onChange={onInputChange} rows="6" />;

      default:
        return null;
    }
  };

  return (
    <div className={className}>
      {questions.map((question, index) => {
        const { restrictToSite, restrictFromSite } = question;

        if (
          (restrictToSite && !restrictToSite.includes(siteSubName)) ||
          (restrictFromSite && restrictFromSite.includes(siteSubName))
        ) return null;

        return (
          <div className="row" key={`${question.name}_row_${index}`}>
            <div className="question ot-third-container">
              <label>{question.editLabel}</label>
              <label className="show-on-profile">
                <input type="checkbox" checked={question.display} name={question.name} onChange={handleQuestionDisplayToggleChange}></input>
                <span>{copy.profile.show_on_profile}</span>
              </label>
            </div>
            <div className="ot-twothirds-container question-options">
              {renderQuestionOptions(question)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

ProfileEditQuestions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.array,
  handleInputChange: PropTypes.func,
  handleQuestionDisplayToggleChange: PropTypes.func
};

const StyledProfileEditQuestions = styled(ProfileEditQuestions)`
  .fill-my-own-container {
    display: flex;
    align-items: center;

    & span {
      margin: 0;
    }

    & input {
      flex: 1;
      margin: 0 0 0 10px;
    }

  }


  .question-options {
    padding-left: 15px;
    padding-right: 15px;
  }

  .input-selectable {
    position: relative;
    display: block;
    height: auto!important;
    line-height: 1.3!important;
    cursor: pointer;
    margin-bottom: 5px;
  }

  & input[type=checkbox]:checked, input[type=radio]:checked {
    background-color: ${({ colors }) => colors.primary};
    border-color: ${({ colors }) => colors.primary} !important;
  }

  & .input-selectable input[type=checkbox], input[type=radio] {
    position: absolute;
    left: 0;
    top: 2px;
    height: 14px!important;
  }

  & input[type=checkbox], input[type=radio] {
    width: 14px !important;
    height: 14px !important;
    border-radius: 3px;
    border: 1px solid #ccc!important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance:none;
    box-shadow: none;
    font-size: 2em;
    color: white;
    vertical-align: middle;
  }

  & input[type=radio] {
    border-radius: 50%;
  }

  & input[type=checkbox]:after, input[type=checkbox]:after {
    top: 2px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid transparent;
    border-width: 0 0 2px 2px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  & input[type=checkbox]:after, input[type=radio]:after {
    content: "";
    display: block;
    position: relative;
  }

  & input[type=checkbox]:checked:after {
    border-color: #fff;
  }

  & input[type=radio]:after {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -2px;
    border-radius: 50%;
}

  & input[type=radio]:checked:after {
    background-color: #fff;
  }

  & label {
    height: auto;
    line-height: 1.5;
  }

  & span {
    font-size: 14px;
    display: inline-block;
    margin-left: 23px;
  }

  & .show-on-profile span {
    display: inline-block;
    margin-left: 5px;
    font-size: 11px;
    color: ${({ colors }) => colors.textLight};
    padding-top: 3px;
  }

  & .show-on-profile {
    margin: 15px 0 5px;
    display: block;
    cursor: pointer;
  }
`;

const StyledProfileEditQuestionsWithSettings = withSettings(StyledProfileEditQuestions);

export { StyledProfileEditQuestionsWithSettings as ProfileEditQuestions };
