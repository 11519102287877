import React from 'react';
const SettingsContext = React.createContext();

const SettingsContextProvider = ({ settings, children }) => {
  // settings.theme.css.global.colors = {
  //   backgroundColor: '#fbfaf9',
  //   backgroundLight: '#f9f7f5',
  //   backgroundDisabled: '#f0f0f0',

  //   primary: '#8d1b37',
  //   // primary: '#60d693',
  //   // primaryHover: '#3da268',
  //   primaryHover: '#5a1b27',
  //   primaryLight: '#faf1f3',
  //   primaryLightBorder: '#e0c4cc',
  //   primaryDark: '#443035',
  //   primaryDisabled: '#a2576a',

  //   secondaryHover: '#f4f4f4',

  //   green: '#76d276',
  //   greenBorder: '#63cc63',
  //   greenHover: '#3ebd3e',
  //   greenHoverBorder: '#38aa38',

  //   important: 'rgb(237, 118, 102)',
  //   importantTransparent: 'rgba(237, 118, 102, 0.7)',
  //   warning: 'rgb(238, 130, 2)',
  //   warningTransparent: 'rgba(238, 130, 2, 0.9)',
  //   success: 'rgb(118, 210, 118)',
  //   successTransparent: 'rgba(118, 210, 118, 0.9)',

  //   text: '#333',
  //   textLight: '#8c8c8c',
  //   textDisabled: '#a59f99',

  //   menuHover: 'rgba(141,27,61,0.1)',

  //   tagBackground:'rgba(142, 27, 55, 0.1)',
  //   tagBorder:'rgba(142, 27, 55, 0.4)',

  //   bannerSmall: '#f5b15f',

  //   facebook: '#485093',
  //   facebookHover: '#373d71',
  //   facebookDisabled: '#6871b9',
  //   twitter: '#81cbf0',
  //   twitterHover: '#53a2c9',

  //   error: '#8d1b37',

  //   heading: '#4d4d4d',

  //   borderColor: '#e5e5e5',

  //   notificationBarBackground: '#ff7877',
  //   notificationBarTextColor: '#fbfaf9'
  // }

  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsContextProvider };