import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';

import {
  DropDownMenu,
  HoverMenu,
  FAIcon,
  withSettings
} from '../components';

import { EVENT_SUBTYPES } from '../../queries';
import { dom } from '../../libs';


const SubtypeMenu = ({ className, title = 'Find A Dinner', settings, useMobileMenu, byArea = false }) => {
  const { pathname } = useLocation();
  const { data: subtypesData } = useQuery(EVENT_SUBTYPES, { errorPolicy: 'all' });
  const [displayOnMobile, setDisplayOnMobile] = useState(false);
  const isMobile = dom.isMobile(settings) || useMobileMenu;
  const isAreaLanding = pathname.includes('/landing/');

  const toggleMobileMenu = (e, propagateEvent) => {
    if (!propagateEvent) e.stopPropagation();
    if (isMobile) setDisplayOnMobile(!displayOnMobile);
  };

  const subtypes = subtypesData && subtypesData.eventSubtypes;

  return (
    <div className={className}>
      <HoverMenu useMobileMenu={useMobileMenu}>
        <div className={`dropdown_menu_wrapper ${displayOnMobile ? 'display' : ''}`}>
          {isMobile
            ? <span className="mobile_nav_link" onClick={toggleMobileMenu}>{title}</span>
            : <NavLink to="/" className="dropdown_link">{title}</NavLink>
          }
          <FAIcon name={[ 'fas', 'caret-down' ]} />
        </div>
        {subtypes && subtypes.length > 0 && (
          <DropDownMenu className={displayOnMobile ? 'display' : ''} fullHeight>
            {/* subtype links */}
            {subtypes.map(subtype => {
              const path = byArea && isAreaLanding
                ? `${pathname}?event_category=${subtype.slug}`
                : `/categories/${subtype.slug}`;
              return (
                <li key={subtype.id} onClick={e => toggleMobileMenu(e, true)} className="area">
                  <NavLink to={path}>{subtype.name}</NavLink>
                </li>
              );
            })}
          </DropDownMenu>
        )}
      </HoverMenu>
    </div>
  );
}


const StyledSubtypeMenu = styled(SubtypeMenu)`
  position: relative;

  .dropdown_menu {
    top: 100%;
    left: 0;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {
    .display {
      & > .dropdown_menu {
        display: block !important;
      }

      .fa-caret-down {
        transform: rotate(180deg);
      }
    }
  }
`;

const SubtypeMenuSettings = withSettings(StyledSubtypeMenu);
export { SubtypeMenuSettings as SubtypeMenu };
