import React, { useState } from 'react';

const FilterContext = React.createContext();


const FilterContextProvider = ({ children }) => {
  const [values, setValues] = useState({});

  const getValue = key => values[key];

  const setValue = (key, value) => {
    const newValues = Object.assign({}, values);

    if (value === null && newValues.hasOwnProperty(key)) delete newValues[key];
    else newValues[key] = value;

    // console.log('newValues', newValues);

    setValues(newValues);
  };

  const value = {
    currentModalFilters: ['event_type', 'cost', 'virtual', 'potluck', 'timezone', 'dress_code', 'alcohol_policy'],
    values,
    getValue,
    setValue
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export { FilterContext, FilterContextProvider };
