import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CounterBadge } from '../';
import { Query } from 'react-apollo';
import { withSettings } from '../components';
import { EventList } from '../components';

class ProfileEvents extends React.Component {
  render() {
    const { className, profileId, name, totalNumberOfItems, itemsPerPage, initialQuery } = this.props;
    const { fetchMoreQuery, getEventsFromResponse, getCursorFromResponse, getHasMoreEventsFromResponse, settings } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <div className={className}>
        <div className="header">
          <h3>{copy.profile.dinners_paginated} {name}</h3>
          <CounterBadge className="counter" number={totalNumberOfItems || 0} color={settings.theme.css.global.colors.eventsCounterBadge}/>
        </div>
        <hr className="separator" />
        <div>
          <Query query={initialQuery} variables={{ id: profileId, first: 4 }} errorPolicy="all">
            {({ data, error, loading, fetchMore }) => {
              const cursor = getCursorFromResponse(data);
              const events = getEventsFromResponse(data);
              const hasMoreEvents = getHasMoreEventsFromResponse(data);

              if (error) console.error('error', error);

              return (
                <EventList
                  name={name}
                  events={events || []}
                  loading={loading}
                  hasMoreEvents={hasMoreEvents}
                  showArea={true}
                  showCount={false}
                  onLoadMore={() =>
                    fetchMore({
                      query: fetchMoreQuery,
                      variables: { id: profileId, cursor: cursor, first: itemsPerPage },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        const previousEvents = getEventsFromResponse(previousResult);
                        const newEvents = getEventsFromResponse(fetchMoreResult);

                        const allEvents = [...previousEvents, ...newEvents];
                        const newProfile = { ...previousResult.profile }

                        if (name === 'attended') {
                          newProfile.attendedEvents.edges = allEvents;
                          newProfile.attendedEvents.pageInfo = fetchMoreResult.profile.attendedEvents.pageInfo;
                        } else if (name === 'hosted') {
                          newProfile.hostedEvents.edges = allEvents;
                          newProfile.hostedEvents.pageInfo = fetchMoreResult.profile.hostedEvents.pageInfo;
                        }

                        const mergedResult = {
                          profile: newProfile,
                        }

                        return newEvents.length ? mergedResult : previousResult;
                      }
                    })
                  }
                />
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

ProfileEvents.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string
};

const StyledProfileEvents = styled(ProfileEvents)`
  text-align: center;
  margin-top: 5em;

  & .details-container {
    padding: 40px 10px 0;
  }

  & .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .counter {
    margin-left: 10px;
    bottom: 2px;
  }

  & h3 {
    text-align: center;
    font-size: 20px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    margin: 0;
  }
`;

const StyledProfileEventsWithSettings = withSettings(StyledProfileEvents);

export default StyledProfileEventsWithSettings;
