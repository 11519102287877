import React from 'react';
import styled from 'styled-components';

import {
  Card,
  TagsSelector,
  InfoTooltip,
  SelectBox,
  Input,
  EventCreationFormDetailsOptions,
  EventCreationFormDetailsOptionsVirtual,
  withSettings
} from '../../components';

import { breakpoints } from '../../../styles';
import { parseTypesSettings, stringInsert, isSite } from '../../../libs';
import { EVENT_TYPE } from '../../../enums';
import { WYSIWYGEditor } from '../../Global';

const defaultSelectValue = { id: 0, label: 'All', value: 'ALL' };

class EventCreationFormDetails extends React.Component {
  state = { types: {} };

  componentDidMount() {
    this.setupTypes();
  }

  setupTypes = () => {
    const { settings: { types } } = this.props;
    const parsedTypes = parseTypesSettings(types);
    this.setState({
      types: {
        dietaryRestrictions: parsedTypes.dietary_restrictions,
        alcoholPolicy: parsedTypes.alcohol_policy,
        dressCodes: parsedTypes.dress_codes
      }
    });
  };

  handleSelectChange = (name, value) => {
    const { onInputChange } = this.props;

    if (Array.isArray(value) && value.indexOf('ALL') >= 0) {
      onInputChange(name, null);
    } else if (value === 'ALL') {
      onInputChange(name, null);
    } else {
      onInputChange(name, value);
    }
  };

  formatSelectData = options => {
    if (!options) return [];
    const keys = Object.keys(options);
    const data = keys.map((key, i) => ({
      id: i + 1,
      label: options[key],
      value: key
    }));

    return new Array(defaultSelectValue).concat(data);
  };

  formatDressCodes = options => {
    if (!options) return [];
    const data = options.map((key, i) => ({
      id: i + 1,
      label: key,
      value: key
    }));

    return new Array(defaultSelectValue).concat(data);
  };

  getDefaultDressCodeValue = () => {
    const { isUpdate, isDuplicate, eventData } = this.props;
    const code = eventData.dressCode;

    if ((isUpdate || isDuplicate) && code) {
      return { label: code, value: code };
    } else {
      // new event or no selection //
      return defaultSelectValue;
    }
  };


  getDefaultValue = field => {
    const { isUpdate, eventData } = this.props;
    const { types } = this.state;
    const fieldData = eventData[field];

    if (isUpdate && !!fieldData) {
      // multi select //
      if (Array.isArray(fieldData)) {
        const defaultList = [];

        fieldData.forEach(f => {
          defaultList.push({
            label: types[field][f],
            value: f
          });
        });

        return defaultList.length ? defaultList : defaultSelectValue;
      } else {
        // single select //
        return {
          label: types[field][fieldData],
          value: eventData[field]
        };
      }
    } else {
      // new event or no selection //
      return defaultSelectValue;
    }
  };

  validateDescriptionLength = length => {
    const { minDescriptionLength, maxDescriptionLength } = this.props;

    if (maxDescriptionLength) {
      return length >= minDescriptionLength && length <= maxDescriptionLength;
    } else {
        return length >= minDescriptionLength
    }
  };


  render() {
    const {
      className,
      eventData,
      onInputChange,
      onValidate,
      minDescriptionLength,
      maxDescriptionLength,
      isUpdate,
      invalidate,
      settings
    } = this.props;
    const { copy, event: eventSettings } = settings;
    const { types } = this.state;

    const eventDataFetched = (!isUpdate || eventData.id != null);

    return (
      <Card className={className}>
        <h2 className="card_title">{copy.event.about_title}</h2>
        <i className="divider"/>

        <p className="description_instructions">
          {stringInsert(copy.create.description_info_text, minDescriptionLength)}
        </p>

        <div className="form_group">
          <div className="full">
            {/* Event Description */}
            <label>{copy.create.description}</label>
            <div className="description-editor">
              {eventDataFetched && (
                <WYSIWYGEditor
                  name="description"
                  value={eventData.description}
                  onInputChange={onInputChange}
                  placeholder={copy.create.description_placeholder}
                  charCountMin={minDescriptionLength}
                  charCountMax={maxDescriptionLength}
                  validation={(value, length) => this.validateDescriptionLength(length)}
                  onValidate={onValidate}
                  errorMessage={`Description must have at least ${minDescriptionLength} characters.`}
                  showValidationMessage
                  fullToolbar
                />
              )}
            </div>
          </div>
        </div>

        {eventSettings.extra_details_enabled && !eventData.hideExtraDetails && (
          <div className="form_group">
            <div className="full">
              {/* Event Extra Details */}
              <label className="with_tooltip">
                {copy.create.extra_details}
                <InfoTooltip content={copy.create.extra_details_tooltip}/>
              </label>
              <WYSIWYGEditor
                name="extraDetails"
                value={eventData.extraDetails}
                onInputChange={onInputChange}
                placeholder={copy.create.extra_details_placeholder}
                fullToolbar
              />
            </div>
          </div>
        )}

        {(eventSettings.always_show_resource_url_field || eventData.virtual) && (
          <div className="form_group virtual_resource">
            <div>
              {/* Virtual Resources  */}
              <label className="with_tooltip">
                {copy.create.virtual_resource}
                <InfoTooltip content={copy.event.virtual_resource_tooltip}/>
              </label>
              <Input
                type="text"
                name="virtualResourceUrl"
                value={eventData.virtualResourceUrl}
                onInputChange={onInputChange}
              />
            </div>
          </div>
        )}

        <div className="form_group">
          <div className="full">
            {/* Event Tags */}
            <label className="with_tooltip">
              {copy.event.tag_input_title}
              <InfoTooltip content={copy.event.tag_tooltip}/>
            </label>
            <TagsSelector
              name="tags"
              tags={eventData.tags}
              onAddTag={value => onInputChange('tags', value)}
              onValidate={onValidate}
              errorMessage="Enter a valid tag."
              suggestions={eventSettings.default_tags ? eventSettings.default_tags.split(',').map(t => t.trim()) : []}
            />
          </div>
        </div>

        {!isSite(settings, 'climatefest') && (
          <div className="form_group event_options break_on_tablet_small">
            {!eventData.virtual && !eventData.hideAlcoholType && !isSite(settings, ['hillel', 'thegathering']) && (
              <div className="one_third">
                {/* Alcohol Policy */}
                <label>{copy.create.alcohol_type}</label>
                <SelectBox
                  name="alcohol"
                  defaultValue={this.getDefaultValue('alcoholPolicy')}
                  options={this.formatSelectData(types.alcoholPolicy)}
                  onOptionSelect={value => this.handleSelectChange('alcoholPolicy', value)}
                />
              </div>
            )}
            {!eventData.virtual && !eventData.hideDietType && (
              <div className="one_third">
                {/* Dietary Restrictions */}
                <label>{copy.create.diet_type}</label>
                <SelectBox
                  defaultValue={this.getDefaultValue('dietaryRestrictions')}
                  options={this.formatSelectData(types.dietaryRestrictions)}
                  onOptionSelect={value => this.handleSelectChange('dietaryRestrictions', value)}
                  returnValue="array"
                  multi
                />
              </div>
            )}
            {!isSite(settings, 'hillel.international', true) && !eventData.hideDressCode && (
              <div className="one_third">
                <label>{copy.create.dress_code}</label>
                <SelectBox
                  defaultValue={this.getDefaultDressCodeValue()}
                  options={this.formatDressCodes(types.dressCodes)}
                  onOptionSelect={value => this.handleSelectChange('dressCode', value)}
                />
              </div>
            )}
          </div>
        )}

        {eventData.eventType !== EVENT_TYPE.SOLO && !eventData.virtual && (
          <EventCreationFormDetailsOptions
            eventData={eventData}
            onInputChange={onInputChange}
            onValidate={onValidate}
            invalidate={invalidate}
          />
        )}

        {eventData.virtual && (
          <EventCreationFormDetailsOptionsVirtual
            eventData={eventData}
            onInputChange={onInputChange}
          />
        )}
      </Card>
    );
  }
}

const StyledEventCreationFormDetails = styled(EventCreationFormDetails)`
  .description-editor {
    .wysiwyg-editor {
      padding: 10px;
    }
  }

  .description_instructions {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .event_options {
    .one_third {
      @media (${breakpoints.tabletSmall}) {
        &:last-of-type {
          padding: 0;
        }
      }
    }
  }
`;

const StyledEventCreationFormDetailsWithSettings = withSettings(StyledEventCreationFormDetails);

export { StyledEventCreationFormDetailsWithSettings as EventCreationFormDetails };
