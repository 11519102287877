import React from 'react';
import styled from 'styled-components';

import { useSettings, useEventPageData } from '../../hooks';

import {
  PageSubheader as Header,
  TagsList,
  Icon,
  EventPageSubheader as Label,
  Divider
} from '../components';


export const EventMenu = () => {
  const { copy } = useSettings();
  const { eventData = {} } = useEventPageData();
  const { potluck, potluckItems, notes } = eventData;
  const { first, second, third } = notes ? JSON.parse(notes) : {};
  const items = potluckItems && potluckItems.edges
    ? potluckItems.edges.map(edge => edge.node.name)
    : [];

  // hide section if no menu items exist //
  if (
    !eventData ||
    (potluck && !items) ||
    (!potluck && !first.length && !second.length && !third.length)
  ) return null;

  return (
    <>
      <Divider />
      <Header>{copy.event.menu_title}</Header>
      {potluck ? (
        <>
          <P>{copy.event.potluck_view_caption}</P>
          <TagsList tags={items} style={{ marginBottom: '0' }} />
        </>
      ) : (
        <>
          {first.length > 0 && (
            <MenuItem icon="APPETIZERS" label={copy.event.menu_first_course} list={first} />
          )}
          {second.length > 0 && (
            <MenuItem icon="MAIN_COURSE" label={copy.event.menu_second_course} list={second} />
          )}
          {third.length > 0 && (
            <MenuItem icon="DESSERTS" label={copy.event.menu_dessert} list={third} />
          )}
        </>
      )}
    </>
  );
};

const P = styled.p`margin-bottom: 30px`;

const MenuItem = ({ icon, label, list }) => (
  <MenuItemContainer>
    <Icon name={icon} />
    <Label>{label}</Label>
    <MenuList>
      {list.map(({ name }, i) => <li key={i}>{name}</li>)}
    </MenuList>
  </MenuItemContainer>
);

const MenuItemContainer = styled.div`
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 15%;
  }

  img {
    max-width: 90px;
    margin-bottom: 10px;
  }
`;

const MenuList = styled.ul`
  margin: 5px 0 0;
  padding-left: 20px;
`;
