import { useEffect } from 'react';
import { useSettings, useSession } from '../';

export const useHeapInit = () => {
  const { features: { enable_heap_usage }, env_vars: { heap_app_id } } = useSettings();
  const { viewer: { user } } = useSession();

  useEffect(() => {
    if (enable_heap_usage && heap_app_id) {
      // load script
      window.heap=window.heap||[],window.heap.load=function(id,t){window.heap.appid=id,window.heap.config=t||{};var r=document.createElement('script');r.type='text/javascript',r.async=!0,r.src='https://cdn.heapanalytics.com/js/heap-'+id+'.js';var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){window.heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=['addEventProperties','addUserProperties','clearEventProperties','identify','resetIdentity','removeEventProperty','setEventProperties','track','unsetEventProperty'],o=0;o<p.length;o++)window.heap[p[o]]=n(p[o])};
      window.heap.load(heap_app_id);
    }
  }, []);

  useEffect(() => {
    const identity = user?.email;
    // identify current user
    identity && window.heap?.identify(identity);
  }, [user, window.heap]);
};
