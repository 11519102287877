import React from 'react';
import styled from 'styled-components';
import 'react-multi-email/style.css';
import { Mutation } from 'react-apollo';

import { SEND_INVITATIONS } from '../../mutations';

import {
  ManageEventsDetailsInviteModal,
  ManageEventsDetailsInvitePastModal,
  FAIcon,
  SocialShare,
  withSettings
} from '../components';

import { breakpoints } from '../../styles';


class ManageEventsDetailsInvite extends React.Component {
  state = {
    openInvite: false,
    openPast: false
  };

  refetch = isRefetch => {
    if (isRefetch) this.props.refetch();
  };

  handleToggleInviteModal = isRefetch => {
    this.setState(prevState => ({ openInvite: !prevState.openInvite }));
    this.refetch(typeof (isRefetch) === 'boolean' && isRefetch);
  };

  handleTogglePastModal = (isRefetch) => {
    this.setState(prevState => ({ openPast: !prevState.openPast }));
    this.refetch(typeof (isRefetch) === 'boolean' && isRefetch);
  };

  render() {
    const { className, event, refetchPendingInvitations, invitations } = this.props;
    const { settings: { copy } } = this.props;
    const { openInvite, openPast } = this.state;

    return (
      <Mutation mutation={SEND_INVITATIONS}>
        {(sendInvitationsMutation) => {
          return (
            <ul className={className}>
              <li className="invite_friends">
                <div onClick={() => this.handleToggleInviteModal(false)}>
                  <FAIcon name="envelope"/>
                  <label>{copy.manage_event.invite_friends_title}</label>
                </div>
                <ManageEventsDetailsInviteModal
                  open={openInvite}
                  toggle={this.handleToggleInviteModal}
                  event={event}
                  invitations={invitations}
                  sendInvitations={sendInvitationsMutation}
                  refetchPendingInvitations={refetchPendingInvitations}
                />
              </li>

              <li className="spacer"></li>

              <li className="past_dinners">
                <div onClick={() => this.handleTogglePastModal(false)}>
                  <FAIcon name="history"/>
                  <label>{copy.manage_event.invite_past_confirm_title}</label>
                </div>
                <ManageEventsDetailsInvitePastModal
                  open={openPast}
                  toggle={this.handleTogglePastModal}
                  event={event}
                  sendInvitations={sendInvitationsMutation}
                  refetchPendingInvitations={refetchPendingInvitations}
                />
              </li>

              <li className="spacer last"></li>

              <li className="event_share">
                <SocialShare event={event}/>
              </li>
            </ul>
          );
        }}
      </Mutation>
    );
  }
}


const StyledManageEventsDetailsInvite = styled(ManageEventsDetailsInvite)`
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  background-color: ${props => props.settings.theme.css.global.colors.backgroundDark};
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 20px;

  li {
    display: inline-block;
    vertical-align: middle;

    &.invite_friends {
      text-align: left;
      width: 20%;

      @media (${breakpoints.manageEventDetailsTablet}) {
        width: auto;
        padding-right: 20px;
      }

      @media (max-width: 750px) {
        padding-right: 0;
      }
    }

    &.past_dinners {
      width: 35%;

      @media (${breakpoints.manageEventDetailsTablet}) {
        width: auto;
        padding-left: 25px;
      }

      @media (max-width: 750px) {
        padding-left: 0;
      }
    }

    &.event_share {
      text-align: right;
      width: 38%;
      min-width: 291px;

      @media (${breakpoints.manageEventDetailsTablet}) {
        width: 100%;
        display: block;
        text-align: center;
      }

      @media (${breakpoints.mobile}) {
        min-width: 0;
        position: relative;

        .copy_link {
          position: static;
        }

        .dropdown_menu {
          right: 50%;
          transform: translateX(50%);
        }
      }
    }

    &.spacer {
      height: 32px;
      width: 1px;
      background-color: #e5e5e5;
      margin: 0;

      @media (max-width: 750px) {
        display: block;
        width: 100%;
        height: 15px;
        background-color: transparent;
      }

      &.last {
        @media (${breakpoints.manageEventDetailsTablet}) {
          display: block;
          width: 100%;
          height: 20px;
          background-color: transparent;
        }
        @media (${breakpoints.mobile}) {
          height: 15px;
        }
      }
    }

    div {
      display: inline-block;

      label {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        font-weight: bold;
        margin: 0 7px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        transition: color 250ms;
        cursor: pointer;
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        transition: color 250ms;
        cursor: pointer;
      }

      &:hover {
        label,
        svg {
          color: ${props => props.settings.theme.css.global.colors.primaryHover};
        }
      }
    }
  }
`;

const ManageEventsDetailsInviteWithSettings = withSettings(StyledManageEventsDetailsInvite);

export { ManageEventsDetailsInviteWithSettings as ManageEventsDetailsInvite };
