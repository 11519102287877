import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { media } from '../../styles';

import { SessionContext } from '../../Context';

import * as userJourney from '../../libs/userJourney';

const Td = ({ className, children }) => {
  const [width, setWidth] = useState(undefined);
  const label = useRef(null);

  useEffect(() => {
    if (label.current) {
      setWidth(label.current.offsetWidth);
    }
  }, [label]);

  return (
    <td className={className} style={{ width: width ? `${width}px` : undefined }}>
      {children && <span ref={label} style={{ whiteSpace: 'nowrap' }}>{children}</span>}
    </td>
  );
};


const UserJourneyProgress = ({ className, currentTitleIndex, pagesTitles = [] }) => {
  const { session: { viewer: { user } } } = useContext(SessionContext);
  const name = user
    ? user.profile && user.profile.firstName
    : userJourney.getItem('name');

  const getCellClass = i => i < currentTitleIndex
    ? 'complete'
    : (i === currentTitleIndex ? 'current' : '');

  return (
    <table className={className}>
      <tbody>
        <tr>
          {name && (
            <>
              <Td className="complete">{name}</Td>
              <td className={['line', getCellClass(currentTitleIndex - 1)].join(' ').trim()} />
            </>
          )}
          {pagesTitles.map((title, i) => (
            <Fragment key={i}>
              <Td className={getCellClass(i)}>
                {title}
              </Td>
              {i < pagesTitles.length - 1 && (
                <td className={['line', getCellClass(i)].join(' ').trim()} />
              )}
            </Fragment>
          ))}
        </tr>
      </tbody>
    </table>
  );
};


const StyedUserJourneyProgress = styled(UserJourneyProgress)`
  position: absolute;
  bottom: 50px;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  width: calc(100% - 60px);
  max-width: 80%;

  ${media.journeyMd`
    width: 160px;
    // position: static;
    // transform: none;
    margin: 0 auto;
    bottom: auto;
  `}

  ${media.sm`
    // bottom: 30px;
  `}

  td {
    opacity: 0.7;
    position: relative;
    text-align: center;

    span {
      font-size: 15px;
      color: #fff;

      ${media.journeyMd`
        display: none;
      `}
    }

    &.current {
      opacity: 1;
    }

    &.complete {
      opacity: 1;

      span {
        color: #46B7ad;
        font-family: 'Avenir-Medium' !important;
      }
    }

    &:not(.line) {
      ${media.journeyMd`
        width: 10px !important;
        height: 10px !important;
        background-color: #ffffff70;
        border-radius: 50%;

        &.complete,
        &.current {
          background-color: #fff;
        }
      `}
    }

    &.line {
      ${media.journeyMd`

      `}

      &::before {
        opacity: 0.5;
        content: '';
        position: absolute;
        left: 30px;
        right: 30px;
        top: 50%;
        height: 1px;
        background-color: #fff;

        ${media.md`
          left: 10px;
          right: 10px;
        `}

        ${media.journeyMd`
          display: none;
        `}
      }

      &.complete {
        opacity: 1;

        &::before {
          background-color: #46B7ad;
        }
      }
    }
  }
`;

export { StyedUserJourneyProgress as UserJourneyProgress };
