import React, { useState, useEffect } from 'react';
// import RichTextEditor from 'react-rte';
import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../../components';
import { stripHtml } from '../../../libs';
import { usePrevious } from '../../../hooks';

import 'react-quill/dist/quill.snow.css';

// const toolbarConfig = {
//   display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', /*'BLOCK_TYPE_DROPDOWN',*/ 'HISTORY_BUTTONS'],
//   INLINE_STYLE_BUTTONS: [
//     { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
//     { label: 'Italic', style: 'ITALIC' },
//     { label: 'Underline', style: 'UNDERLINE' },
//     { label: 'Strikethrough', style: 'STRIKETHROUGH' }
//   ],
//   BLOCK_TYPE_BUTTONS: [
//     { label: 'UL', style: 'unordered-list-item' },
//     { label: 'OL', style: 'ordered-list-item' }
//   ]
// };

const WYSIWYGEditor = ({
  className,
  value,
  placeholder,
  onInputChange,
  onValidate,
  validation,
  errorMessage,
  name,
  showValidationMessage,
  charCountMin = null,
  charCountMax = null,
  dontShowInvalid = false,
  fullToolbar = false
}) => {
  const initialValue = value;
  // const initialValue = value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue();

  const [editorValue, setEditorValue] = useState(initialValue);
  const [valid, setValid] = useState(true);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [charCount, setCharCount] = useState(0);
  const [publishChanges, setPublishChanges] = useState(undefined);
  const prevVal = usePrevious(value);

  useEffect(() => {
    if (loading && value && !prevVal) {
      const text = stripHtml(value);
      setCharCount(text.length);
      // setEditorValue(editor => editor.setContentFromString(value, 'html'));
      setEditorValue(value);
    }
  }, [loading, value, prevVal]);

  const validate = (text, processedTextLength) => {
    if (validation) {
      const validContent = validation(text, processedTextLength);
      setValid(validContent);
      onValidate && onValidate(name, validContent);
    } else if (charCountMin) {
      setValid(charCount >= charCountMin);
    } else if (charCountMax) {
      setValid(charCount <= charCountMax);
    }
  };

  const handleInputChange = (html, delta, source, editor) => {
    setEditorValue(html);
    loading && setLoading(false);

    const originalText = editor.getText();
    const processedText = originalText.trim().replace(/\s+/gmi, ' ');
    const processedTextLength = processedText.length;

    setCharCount(processedTextLength);

    clearTimeout(publishChanges);
    setPublishChanges(setTimeout(() => {
      validate(originalText, processedTextLength);
      onInputChange(name, html);
      onInputChange(`${name}_text`, originalText);
    }, 10));
  };

  const getErrorMessage = () => {
    if (!errorMessage) return false;

    if (charCountMax && charCount > charCountMax) {
      return `Description must be less than ${charCountMax} characters.`
    }

    return errorMessage;
  };

  const classNames = [
    'wysiwyg-editor',
    ...(focused ? ['focused'] : []),
    ...(!valid && !dontShowInvalid ? ['invalid'] : [])
  ];

  return (
    <div className={className}>
      <div className={classNames.join(' ')}>
        <ReactQuill
          theme="snow"
          value={editorValue}
          onChange={handleInputChange}
          placeholder={placeholder || ''}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike', ...(fullToolbar ? ['link'] : [])],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }]
            ]
          }}
        />
      </div>
      {!valid && showValidationMessage && <span className="validation_error">{getErrorMessage() || `Invalid ${name}`}</span>}
      {charCountMin !== null && (
        <span className={`char_count ${!valid ? 'invalid' : ''}`.trim()}>{charCount}/{charCountMin} minimum</span>
      )}
    </div>
  );
};

const StyledWYSIWYGEditor = styled(WYSIWYGEditor)`
  position: relative;

  .wysiwyg-editor {
    text-align: left;
    ${props => props.centered && `
      text-align: center;
    `}
    ${({ compact, session: { theme } }) => !compact && `
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: ${theme.isVersion(2) ? '3px' : '5px'};
    `}

    &:hover {
      ${({ session: { theme } }) => theme.isVersion(2) && css`
        border-color: #000 !important;
      `}
    }

    &.focused {
      ${({ session: { theme } }) => theme.isVersion(2) ? css`
        border-color: #000 !important;
      ` : css`
        border-color: #8a8a8a !important;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15) !important;
      `}
    }

    &.invalid {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      margin-bottom: 0;
    }

    .ql-snow {
      &.ql-toolbar button {
        &.ql-active,
        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primary} !important;

          .ql-stroke {
            stroke: ${props => props.settings.theme.css.global.colors.primary} !important;
          }

          .ql-fill {
            fill: ${props => props.settings.theme.css.global.colors.primary} !important;
          }
        }
      }

      a {
        color: ${props => props.settings.theme.css.global.colors.primary} !important;
      }
    }

    .ql-container {
      ${({ session: { theme } }) => theme.isVersion(2) && css`
        font-size: 15px !important;

        .ql-editor {
          min-height: 120px;
        }
      `}
    }
  }

  input[type="text"] {
    margin-bottom: 0;
  }

  a,
  a span {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  .char_count {
    position: absolute;
    bottom: -21px;
    right: 0;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    font-size: 13px;
    text-align: right;
    margin: 0;

    &.invalid {
      bottom: -5px;
    }
  }

  .validation_error {
    display: block;
    font-size: 11px;
    text-align: left;
    color: ${props => props.settings.theme.css.global.colors.error};
  }

`;

const StyledWYSIWYGEditorWithSettings = withSettings(StyledWYSIWYGEditor);
const WYSIWYGEditorWithSession = withSession(StyledWYSIWYGEditorWithSettings);
export { WYSIWYGEditorWithSession as WYSIWYGEditor };
