import moment from 'moment';
import { isRegularOneTable, isSite, getSiteSubNameFromSettings } from './';

const userHelpers = {
  getProfileInitials: (profile) => {
    if (!profile) { return ''; }
    const firstNameInitial = profile.firstName && profile.firstName.length > 0 ? profile.firstName.charAt(0) : '';
    const lastNameInitial = profile.lastName && profile.lastName.length > 0 ? profile.lastName.charAt(0) : '';
    const initials = `${firstNameInitial}${lastNameInitial}`;
    return initials.toUpperCase();
  },

  getAgeFromDOB: (dateOfBirth) => {
    return Math.abs(moment(dateOfBirth).diff(moment(), 'years'));
  },

  getProfileLanguages: profile => {
    const answers = profile && profile.answers && JSON.parse(profile.answers);
    const languages = answers && answers.languages;
    const languageChoices = languages && languages.choices;
    const languageAnswers = languages && languages.answer;
    const profileLanguages = [];

    if (languageChoices && languageAnswers) {
      for (const i in languageAnswers) {
        const answer = languageChoices.find(c => c.id === i);
        languageAnswers[i] && answer && profileLanguages.push(answer.label);
      }
    }

    return profileLanguages;
  },

  addSignUpAnswersToProfile: async (settings, updateProfileMutation, data) => {
    const variables = {};
    let answers;

    // radio/checkbox answers are formatted into an object of its options
    // this is mainly to support checkbox fields
    // answer: { a: false, b: false, c: true, d: false }
    // select boxes should be saved as a string!
    // `display` is a flag that lets the user show/hide the answer on their profile page
    const choiceValue = value => ({ display: false, answer: { [value]: true } });
    const stringValue = value => ({ display: false, answer: value });

    if (isRegularOneTable(settings)) {
      answers = {
        iAm: choiceValue(data.iAm),
        howDidYouHear: stringValue(data.howDidYouHear),
        howDidYouHearFriend: stringValue(data.howDidYouHearFriend),
        howDidYouHearEvent: stringValue(data.howDidYouHearEvent),
        howDidYouHearOrganization: stringValue(data.howDidYouHearOrganization)
      };

    } else if (isSite(settings, ['seder', 'jewishfoodfest'])) {
      answers = {
        howDidYouHear: stringValue(data.howDidYouHear),
        howDidYouHearValue: stringValue(data.howDidYouHearValue),
        howDidYouHearOther: stringValue(data.howDidYouHearOther),
        birthYear: stringValue(data.birthYear)
      };

    } else if (isSite(settings, ['hillel'])) {
      answers = {
        are_you_jewish: choiceValue(data.are_you_jewish),
        gradYear: stringValue(data.gradYear),
      };

      if (getSiteSubNameFromSettings(settings) === 'international') {
        answers = {
          ...answers,
          iAmHillel: stringValue(data.iAmHillel),
          iAmHillelOther: stringValue(data.iAmHillelOther)
        };
      } else {
        answers = {
          ...answers,
          ...(data.iAmAlt ?
            {
              iAmAlt: stringValue(data.iAmAlt),
              iAmCampus: stringValue(data.iAmCampus),
              iAmCampusOther: stringValue(data.iAmCampusOther)
            }
            : { iAm: stringValue(data.iAm) }
          ),
          iAmOther: stringValue(data.iAmOther),
          gradYear: stringValue(data.gradYear),
          jewish: choiceValue(data.jewish)
        };
      }

    } else if (isSite(settings, ['dinner-party'])) {
      answers = {
        acceptTerms: { display: false, answer: data.acceptTerms },
        acceptValues: { display: false, answer: data.acceptValues },
      };
    }

    variables.answers = JSON.stringify(answers);
    return await updateProfileMutation({ variables });
  }
};

const {
  getProfileLanguages,
  addSignUpAnswersToProfile
} = userHelpers;

export {
  userHelpers,
  getProfileLanguages,
  addSignUpAnswersToProfile
}
