import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ButtonLoader, Tooltip, ButtonVersion, withSettings, withSession } from '../../components';

const Button = props => {
  const {
    name,
    value,
    children,
    className,
    type,
    buttonStyle,
    size,
    small,
    fullWidth,
    halfWidth,
    capitalize,
    capitalizeWords,
    standardCapitalized,
    disabled,
    style,
    link,
    handleClick,
    inverted,
    loading,
    themed = false
  } = props;

  const buttonRef = useRef(null);

  const buttonClass = `
    button
    ${className}
    ${buttonStyle ? buttonStyle : 'primary'}
    ${size ? size : 'default'}
    ${fullWidth ? 'full_width' : ''}
    ${halfWidth ? 'half_width' : ''}
    ${capitalize ? 'capitalize' : ''}
    ${capitalizeWords ? 'capitalizeWords' : ''}
    ${standardCapitalized ? 'standardCapitalized' : ''}
    ${inverted ? 'inverted' : ''}
    ${loading ? 'loading' : ''}
  `;

  return link ? (
    <Link
      to={link}
      className={buttonClass}
      style={style}
      disabled={disabled}
    >
      <span className="button_content">{children}</span>
      {loading && <ButtonLoader />}
    </Link>
  ) : (
    <ButtonVersion fullWidth={fullWidth} small={small} themed={themed}>
      <button
        name={name}
        value={value || null}
        type={type ? type : 'button'}
        className={buttonClass}
        onClick={event => handleClick && handleClick(event, buttonRef.current)}
        style={style}
        disabled={disabled}
        ref={button => { buttonRef.current = button }}
      >
        <span className="button_content">{children}</span>
        {loading && <ButtonLoader />}
      </button>
    </ButtonVersion>
  );
};

const ButtonContainer = props => {
  const { tooltip = false } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  if (tooltip) {
    return (
      <span
        className="button_wrapper"
        onMouseEnter={() => tooltip && setShowTooltip(true)}
        onMouseLeave={() => tooltip && setShowTooltip(false)}
        style={{ position: 'relative' }}
      >
        <Button {...props} />
        {tooltip && showTooltip && (
          <Tooltip inverted>{tooltip}</Tooltip>
        )}
      </span>
    );
  } else {
    return <Button {...props} />
  }
};

const StyledButton = styled(ButtonContainer)`
  cursor: pointer;
  position: relative;
  appearance: none;
  border-radius: 3px;
  transition: background-color 250ms, border-color 250ms;
  min-height: 38px;
  margin: 0 ${props => (props.horizontalMargin ? props.horizontalMargin : 0)};
  font-size: 14px;

  &.button {
    background-image: none !important;
  }

  .button_content {
    transition: color 250ms;
    white-space: nowrap;

    svg {
      // transition: color 250ms;
    }
  }

  &.inverted {
    background-color: #fff !important;

    .button_content {
      color: ${props => props.settings.theme.css.global.colors.primary} !important;
    }

    &.loading {
      background-color: ${props => props.settings.theme.css.global.colors.primaryDisabled} !important;
    }
  }
  &.inverted:hover {
    background-color: ${props => props.settings.theme.css.global.colors.primary} !important;
    border-color: ${props => props.settings.theme.css.global.colors.primary} !important;

    &.loading {
      background-color: ${props => props.settings.theme.css.global.colors.primaryDisabled} !important;
    }

    .button_content {
      color: #fff !important;
    }

    svg {
      color: white;
    }
  }

  &.loading {
    .button_content {
      visibility: hidden;
      position: relative;
      z-index: -1;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.primary {
    background-color: ${props => props.settings.theme.css.global.colors.primary};
    border: 1px solid ${props => props.settings.theme.css.global.colors.primary};

    .button_content,
    .button_content span {
      color: #fff;
    }

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
      border-color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }

    &[disabled] {
      cursor: default;
      background-color: ${props => props.settings.theme.css.global.colors.primaryDisabled};
      border-color: ${props => props.settings.theme.css.global.colors.primaryDisabled};

      &:hover {
        background-color: ${props => props.settings.theme.css.global.colors.primaryDisabled};
        border-color: ${props => props.settings.theme.css.global.colors.primaryDisabled};
      }
    }

    .icon {
      path {
        fill: #fff;
      }
    }
  }

  &.primary_outline {
    background-color: #fff;
    border: 1px solid ${props => props.settings.theme.css.global.colors.primary};

    .button_content {
      color: ${props => props.settings.theme.css.global.colors.primary};
    }

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.primary};
      color: #fff;

      .button_content {
        color: #fff;
      }

      .icon {
        path {
          fill: #fff;
        }
      }
    }

    .icon {
      path {
        fill: ${props => props.settings.theme.css.global.colors.primary};
      }
    }

    &[disabled] {
      cursor: default;

      &:hover {
        background-color: #fff;

        span {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }
    }
  }

  &.secondary {
    background-color: #fff;
    border: 1px solid #ccc;

    .button_content {
      color: #000;
    }

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
    }

    &[disabled] {
      background-color: #eee;
      cursor: default;

      .button_content {
        color: #000;
      }
    }
  }

  &.clearBackground {
    background-color: transparent;
    border: 0;

    .button_content {
      color: ${props => props.settings.theme.css.global.colors.primary};
    }

    &:hover {
      .button_content {
        text-decoration: underline;
      }
    }
  }

  &.outline {
    background-color: transparent;

    ${({ session: { theme }, colors: { primary } }) => theme.isVersion(2) ? css`
      border: 1px solid #000;
      .button_content {
        color: #222;
      }
      &:hover {
        border-color: ${primary};
      }
      .button_loader {
        i {
          background-color: ${primary};
        }
      }
      &:hover {
        background-color: #ffffff20;
      }
    ` : css`
      // border: 1px solid #fff;
      // .button_content {
      //   color: #fff;
      // }

      background-color: #fff;
      border: 1px solid ${props => props.settings.theme.css.global.colors.primary};

      .button_content {
        color: ${props => props.settings.theme.css.global.colors.primary};
      }

      &:hover,
      &.loading {
        background-color: ${props => props.settings.theme.css.global.colors.primary};
        color: #fff;

        .button_content {
          color: #fff;
        }

        .icon {
          path {
            fill: #fff;
          }
        }
      }
    `}

    .icon {
      path {
        fill: #fff;
      }
    }
  }

  &.facebook {
    background-color: ${props => props.settings.theme.css.global.colors.facebook};
    border: 1px solid ${props => props.settings.theme.css.global.colors.facebook};

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.facebookHover};
      border-color: ${props => props.settings.theme.css.global.colors.facebookHover};
    }

    &.default {

    }

    svg {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      margin-right: 10px;
      color: #fff;
    }

    span:not(.button_content) {
      display: inline-block;
      vertical-align: middle;
      color: #fff;
    }

    &.capitalize {
      padding: 9px 18px !important;
    }

    &[disabled] {
      cursor: default;
      background-color: ${props => props.settings.theme.css.global.colors.facebookDisabled};
      border-color: ${props => props.settings.theme.css.global.colors.facebookDisabled};

      &:hover {
        background-color: ${props => props.settings.theme.css.global.colors.facebookDisabled};
        border-color: ${props => props.settings.theme.css.global.colors.facebookDisabled};
      }
    }
  }

  &.default {
    &:not(.capitalize) {
      padding: 8px 18px;
      line-height: 20px;
    }

    &.capitalize {
      padding: 11px 18px 10px;
      font-size: 10px;
    }
  }

  &.half_width {
    width: 50%;
  }

  &.full_width {
    width: 100%;
  }

  &.capitalize {
    text-transform: uppercase;
    font-weight: bold;

    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      letter-spacing: 0.18em;
    `}
  }

  &.capitalizeWords {
    text-transform: capitalize;
  }

  &.standardCapitalized {
    text-transform: uppercase;
  }

  &.facebookShare {
    width: 77.5px;
    height: 42.5px;
    background: ${props => `url(${props.settings.theme.assets.images.social.share_fb})`} 50%;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 0;
  }

  &.twitterShare {
    width: 77.5px;
    height: 42.5px;
    background: ${props => `url(${props.settings.theme.assets.images.social.share_tw})`} 50%;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 0;
  }

  &.rsvpGreen {
    background-color: ${props => props.settings.theme.css.global.colors.green};
    border: 1px solid ${props => props.settings.theme.css.global.colors.greenBorder};

    .button_content {
      color: #fff;
    }

    &:not([disabled]) {
      &:hover {
        background-color: ${props => props.settings.theme.css.global.colors.greenHover};
        border: 1px solid ${props => props.settings.theme.css.global.colors.greenHoverBorder};
        color: #fff;
      }
    }

    &[disabled] {
      cursor: default;
    }
  }

  .icon {
    display: inline-block;
    margin-bottom: 4px;
    margin-left: 6px;
  }
`;

const ButtonWithSettings = withSettings(StyledButton);
const ButtonWithSession = withSession(ButtonWithSettings);
export { ButtonWithSession as Button };
