import gql from 'graphql-tag';

export const CREATE_CREDIT_CARD = gql`
  mutation (
    $stripeToken: String!,
    $favorite: Boolean!
  ) {
    createCard(input: {
      stripeToken: $stripeToken,
      favorite: $favorite
    }) {
      card {
        id
        brand
        expiration
        favorite
        image
        number
      }
      errors {
        message
      }
    }
  }
`;

export const DESTROY_CREDIT_CARD = gql`
  mutation (
    $id: Int!
  ) {
    destroyCard(input: {
      id: $id
    }) {
      errors {
        message
      }
    }
  }
`;

export const UPDATE_CREDIT_CARD = gql`
  mutation (
    $id: Int!,
    $favorite: Boolean!
  ) {
    updateCard(input: {
      id: $id,
      favorite: $favorite
    }) {
      card {
        id
        favorite
      }
      errors {
        message
      }
    }
  }
`;
