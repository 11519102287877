import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { MANAGE_EVENTS } from '../queries';

export const EVENTS_PER_PAGE = 15;


export const usePaginatedViewerEvents = filter => {
  const [currentFilter, setCurrentFilter] = useState(filter);
  const [cursors, setCursors] = useState({});
  const [events, setEvents] = useState({ [filter]: [] });
  const [totalCount, setTotalCount] = useState({ [filter]: 0 });
  const [currentIndex, setCurrentIndex] = useState({ [filter]: 0 });
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const variables = { filter, first: EVENTS_PER_PAGE };
  const { data: queryData, error, loading, fetchMore } = useQuery(MANAGE_EVENTS, { variables, errorPolicy: 'all', fetchPolicy: 'network-only' });
  const data = Object.assign({}, queryData);

  useEffect(() => {
    if (data && data.viewer && data.viewer.events) {
      if (data.viewer.events.edges && data.viewer.events.edges.length) {
        const { pageInfo: { endCursor } } = data.viewer.events;

        if (!loading && (!cursors[filter] || cursors[filter] !== endCursor)) {
          // cursor //
          setCursors(prev => ({ ...prev, [filter]: endCursor }));
          // total count //
          setTotalCount(prev => ({ ...prev, [filter]: data.viewer.events.totalCount }));
          // events //
          setEvents(prev => ({ ...prev, [filter]: data.viewer.events.edges }));
          // index //
          if (!currentIndex[filter]) setCurrentIndex(prev => ({ ...prev, [filter]: 0 }))
        }
      } else if (!totalCount.hasOwnProperty(filter)) {
        setTotalCount(prev => ({ ...prev, [filter]: 0 }));
      }
    }

    setCurrentFilter(filter);

  }, [data, filter, setCurrentFilter, currentIndex, cursors, loading, totalCount]);

  const fetch = () => {
    setFetchMoreLoading(true);

    fetchMore({
      variables: { ...variables, after: cursors[filter] },
      fetchPolicy: 'cache-and-network',
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchMoreLoading(false);

        if (fetchMoreResult) {
          const newEdges = fetchMoreResult.viewer.events.edges;
          const prevEdges = prev.viewer.events.edges;

          fetchMoreResult.viewer.events.edges = [...prevEdges, ...newEdges];

          setCurrentIndex(prevIndexes => ({
            ...prevIndexes,
            [filter]: prevIndexes[filter] + EVENTS_PER_PAGE
          }));

          return fetchMoreResult;
        }
        return prev;
      },
    });
  };

  const hasNextPage = () => {
    const eventCount = events && Array.isArray(events[filter]) ? events[filter].length : 0;
    const index = currentIndex[filter];
    const totalEventCount = totalCount[filter];
    return eventCount < totalEventCount || index + EVENTS_PER_PAGE < totalEventCount;
  };

  const hasPrevPage = () => currentIndex[filter] > 0;

  const handleGoToNextPage = () => {
    const eventCount = events && Array.isArray(events[filter]) ? events[filter].length : 0;
    const index = currentIndex[filter];
    const totalEventCount = totalCount[filter];

    if (fetchMoreLoading) return;

    if (index + EVENTS_PER_PAGE === eventCount && index + EVENTS_PER_PAGE < totalEventCount) {
      fetch();
    } else {
      setCurrentIndex(prev => ({
        ...prev,
        [filter]: prev[filter] + EVENTS_PER_PAGE
      }));
    }
  };

  const handleGoToPrevPage = () => {
    const newIndex = currentIndex[filter] - EVENTS_PER_PAGE;

    if (fetchMoreLoading) return;

    setCurrentIndex(prevIndexes => ({
      ...prevIndexes,
      [filter]: newIndex >= 0 ? newIndex : 0
    }));
  };

  return {
    events,
    currentPage: events[filter]
      ? events[filter].slice(
          currentIndex[filter],
          currentIndex[filter] + EVENTS_PER_PAGE
        )
      : [],
    error,
    loading,
    fetchMoreLoading,
    totalCount: totalCount[filter],
    currentFilter,
    pagination: {
      hasNextPage: hasNextPage(),
      hasPrevPage: hasPrevPage(),
      goToNextPage: handleGoToNextPage,
      goToPrevPage: handleGoToPrevPage
    },
  };
};
