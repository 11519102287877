import gql from 'graphql-tag';

export const SHARE_EVENT_RESOURCES_WITH_GUESTS = gql`
  mutation shareEventResourcesWithGuests (
    $eventId: Int!,
    $message: Html
  ) {
    shareEventResourcesWithGuests (input: {
      eventId: $eventId,
      message: $message
    }) {
      errors {
        message
        path
      }
    }
  }
`;

export const SHARE_EVENT_RESOURCES = gql`
  mutation shareEventResources (
    $eventId: Int!,
    $emailAddresses: [String!]!,
    $message: Html
  ) {
    shareEventResources (input: {
      eventId: $eventId,
      emailAddresses: $emailAddresses,
      message: $message
    }) {
      errors {
        message
        path
      }
    }
  }
`;
