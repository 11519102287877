import gql from 'graphql-tag';

export const AREA_POPUPS = gql`
  query areaPopups {
    areaPopups {
      id
      area
      content
      label
      url
    }
  }
`;
