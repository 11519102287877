import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Mutation } from 'react-apollo';
import { Button, Modal } from '..';
import PropTypes from 'prop-types';
import { withSettings, withSession, PageSubheader as Header, Logo } from '../components';
import { alert, SelectBox, Textarea } from '../Global';
import { CANCEL_RESERVATION } from '../../mutations';

import { withCancelReservationQueryHooksHOC } from '../../libs/events';

const CANCEL_REASON = 'cancelReason';
const ERROR_MESSAGE = 'Reservations could not be cancelled.';

const CancelReservationsModal = ({
  getCancelReservationLoading, 
  getCancelReservationData, 
  className, 
  show, 
  toggle, 
  settings, 
  session: { theme },
  reservations,
  currentEvent,
  createReservation
}) => {
  const [loading, setLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonText, setCancelReasonText] = useState('');
  const [cancelReasons, setCancelReasons] = useState(null);
  const [requireText, setRequireText] = useState(false);

  const { copy, event: { reservation_cancel_reason_enabled } } = settings;
  const ModalTitle = ({ children }) => theme.isVersion(2) ? <Header>{children}</Header> : <p>{children}</p>;

  const requiresCancelReason = reservation_cancel_reason_enabled && (
    !cancelReason || (requireText && !cancelReasonText)
  );

  useEffect(() => {
    if (!getCancelReservationLoading && !cancelReasons && getCancelReservationData) {
      setCancelReasons(getCancelReservationData.reservationCancelReasons)
    }
  }, [getCancelReservationLoading, getCancelReservationData, cancelReasons])

  const formatSelectData = (options) => {
    if (!options) return [];

    const data = options.map((key, i) => ({
      id: i + 1,
      label: key.cancelReason,
      value: key.cancelReason
    }));

    return data;
  };
  const handleCancelReservations = async (cancelReservationMutation) => {

    const mutationInputs = reservations.filter(reservation => reservation.event.id !== currentEvent.id).map(item => {
      return {
        id: item.id,
        cancelReason: cancelReason,
        cancelReasonText: cancelReasonText
      }
    });

    setLoading(true);

    const response = await Promise.all(mutationInputs.map(mutationInput => {
      return cancelReservationMutation({
        variables: mutationInput,
        errorPolicy: 'all'
      })
    }));

    const checkErrors = response.some(res => res.data.cancelReservation && res.data.cancelReservation.errors);
    if (checkErrors) {
      alert.error(ERROR_MESSAGE);
      setLoading(false);
    } else {
      await createReservation(); // Reservation needs to be created after cancel the others
      setLoading(false);
      toggle();
    }
  };

  return (
    <Mutation mutation={CANCEL_RESERVATION}>
      {(cancelReservationMutation) => (
        <Modal show={show} toggle={toggle} size='sm' noPadding>
          <div className={className}>
            <Logo layout="vertical" />
            <ModalTitle>{copy.rsvp.duplicate_entry_error_modal_title}</ModalTitle>
            <div className='reason_container'>
              {reservation_cancel_reason_enabled && (
                <div>
                  <label>{copy.event.confirm_delete_cancel_reservation_reason}</label>
                  <SelectBox
                    name={CANCEL_REASON}
                    options={formatSelectData(cancelReasons)}
                    onOptionSelect={(value) => {
                      const reason = cancelReasons.find((r) => r.cancelReason === value);
                      setCancelReason(reason.cancelReason);
                      setRequireText(reason.requireText);
                    }}
                  />
                </div>
              )}
              {requireText && (
                <div className=''>
                  <label>{copy.event.confirm_delete_cancel_reservation_reason_text}</label>
                  <Textarea
                    name='cancelReasonText'
                    value={cancelReasonText}
                    onInputChange={(_, value) => setCancelReasonText(value)}
                    placeholder={
                      copy.event.confirm_delete_cancel_reservation_reason_text_placeholder
                    }
                  />
                </div>
              )}
            </div>
            <div className='button_container'>
              <Button
                className='submit'
                handleClick={() => handleCancelReservations(cancelReservationMutation)}
                buttonStyle='primary'
                type='submit'
                loading={loading}
                disabled={loading || requiresCancelReason}
                halfWidth
                themed={theme.isVersion(2)}
              >
                {copy.event.confirm_delete_submit_reservation}
              </Button>
              <Button
                className='cancel'
                handleClick={() => {
                  setCancelReason(null);
                  toggle();
                }}
                buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                type='submit'
                disabled={loading}
                halfWidth
                themed={theme.isVersion(2)}
              >
                {copy.event.confirm_delete_cancel_reservation}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </Mutation>
  );
}

CancelReservationsModal.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  updateClicked: PropTypes.func,
  cancelClicked: PropTypes.func
};

const StyledDuplicateEntryErrorModal = styled(CancelReservationsModal)`
  position: relative;
  text-align: center;
  padding: 30px 20px;

  & > .logo {
    height: 53px;
    width: 147px;
    margin: 0 auto 1em;
  }

  & > h2 {
    ${({ session: { theme }, settings }) => !theme.isVersion(2) && css`
      font-size: 16px;
      // color: ${settings.theme.css.global.colors.primaryDark};
      margin-top: 0;
      margin-bottom: 20px;
      // font-weight: normal !important;
    `}
  }

  & > p {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.6em;
    `}
  }

  & > h4 {
    ${({ session: { theme }, settings }) => !theme.isVersion(2) && css`
      // color: ${settings.theme.css.global.colors.heading};
      font-size: 12px;
      margin-bottom: 20px;
      line-height: 1.4em;
      font-weight: normal !important;
    `}
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > button {
    margin-top: 1em;
  }

  & > .separator_line {
    height: 1px;
    background-color: ${(props) => props.settings.theme.css.global.colors.borderColor};
    width: 33.33333%;
    margin: 10px auto;
    flex-grow: 1;
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    & > .logo {
      width: 140px;
      margin: 0 auto;
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;
  }

  .button_container {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    button {
      width: calc(50% - 10px);
    }

    .submit {
      float: right;
    }

    .cancel {
      float: left;
    }
  }

  .reason_container {
    text-align: left;

    label {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const StyledDuplicateEntryErrorModalWithSettings = withSettings(
  StyledDuplicateEntryErrorModal
);
const DuplicateEntryErrorModalWithSession = withSession(
  StyledDuplicateEntryErrorModalWithSettings
);

const StyledDuplicateEntryErrorModalReservationQueryHooks =
  withCancelReservationQueryHooksHOC(DuplicateEntryErrorModalWithSession);

export { StyledDuplicateEntryErrorModalReservationQueryHooks as CancelReservationsModal };
