import React from 'react';
import styled from 'styled-components';

import {
  withSettings,
  EventCardFooter,
  EventCardStack
} from '../../components';

import {
  stringInsert
} from '../../../libs';

const SoloEventsCard = ({ className, settings: { theme, copy }, showWeeksSoloEvents, soloEventsNumber, weekDate }) => {
  const handleCardClick = () => showWeeksSoloEvents(weekDate);

  const images = theme.assets.images;
  const bgImageUrl = images.solo_card_header || images.default_cover;

  return (
    <div
      className={`${className} ot_event_card`}
      onClick={handleCardClick}
    >
      <div
        className="card_header"
        style={{ backgroundImage: `url(${bgImageUrl})` }}
      />
      <div className="card_body">
        <h4 className="event_title">{copy.solo_card ? copy.solo_card.title : 'Solo Dinners'}</h4>
        <p>{stringInsert(
          copy.solo_card ? copy.solo_card.body_text : 'There are %1$s solo dinners on %2$s.',
          [soloEventsNumber, weekDate]
        )}</p>
      </div>
      <EventCardFooter weekDate={weekDate} type="solo" />
      <EventCardStack eventsHiddenNumber={soloEventsNumber}/>
    </div>
  );
};


const StyledSoloEventsCard = styled(SoloEventsCard)`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  cursor: pointer;
  transition: box-shadow 250ms;
  background-color: #fff;
  opacity: 0;

  &:hover {
    box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
  }

  .card_header {
    position: relative;
    z-index: 1;
    border-radius: 5px 5px 0 0;
    height: 170px;
    padding: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card_body {
    padding: 40px 20px 60px;

    .event_title {
      font-size: 16px;
      margin: 0 0 10px;
    }

    p {
      font-size: 12px;
      color: ${props => props.settings.theme.css.global.colors.heading};
    }
  }
`;


const SoloEventsCardWithSettings = withSettings(StyledSoloEventsCard);

export { SoloEventsCardWithSettings as SoloEventsCard };
