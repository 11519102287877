import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import introspectionQueryResultData from './fragmentTypes.json';

import { Query, ApolloProvider } from 'react-apollo';
import { /*VIEWER,*/ INIT } from '../src/queries';

import {
  fetchSettings,
  // errorMgt,
  addFavicon,
  generators,
  isSite,
  domEval
} from './libs';
import { isWordpress, wordpressUserNavDomRoot } from './libs/wordpress';
import { getToken, refreshTokenExpiration } from './libs/token';
import indexStyle from './styles/index.style';
import './styles/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import './styles/semantic-ui-css/semantic.min.scss';

import App from './App';
import WordPressApp from './WordPressApp';
import { SessionContextProvider } from './Context';
import { SettingsContextProvider } from './SettingsContext';
import {
  Loader,
  WPLoader
} from './components';

import * as Sentry from '@sentry/browser';

import TagManager from 'react-gtm-module';
import { OtpContextProvider } from './OtpContext';

const DEFAULT_GOOGLE_TAG_NAME = 'GTM-NJLRLHS';

fetchSettings(settings => {
  TagManager.initialize({
    gtmId: settings.google_analytics.site_tag || DEFAULT_GOOGLE_TAG_NAME
  });
});

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'ot-fe@' + process.env.REACT_APP_VERSION,
  dsn: 'https://345650150e9f46d7a811dafb9365a410@sentry.io/1873155'
});

const uri = window.useHostName === true ? '/graphql' : process.env.REACT_APP_API_URI;

// this causes `FragmentMatcher.match() was called before FragmentMatcher.init()` error //
// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   possibleTypes: introspectionQueryResultData.possibleTypes
// });

const cache = new InMemoryCache({ possibleTypes: introspectionQueryResultData.possibleTypes });
let settings = {};

const request = async (operation) => {
  operation.setContext({
    headers: {
      authorization: getToken(settings)
    }
  });
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error('[graphQL error]: ', graphQLErrors);
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    new HttpLink({
      uri,
      credentials: 'omit'
    })
  ]),
  cache,
  resolvers: {
    Mutation: {
      updateNetworkStatus: (_, { isConnected }, { cache: mutationCache }) => {
        mutationCache.writeData({ data: { isConnected } });
        return null;
      }
    }
  },
});

cache.writeData({
  data: {
    isConnected: true
  }
});


class Root extends React.Component {
  state = {
    settings: null,
    otpSettings: null,
    theme: { randomProfileColor: '', version: 1, isVersion: () => {} }
  };

  componentDidMount() {
    fetchSettings(s => {
      // save settings //
      this.setState({ settings: s }, () => {
        refreshTokenExpiration(this.state.settings);
        settings = { ...s };
      });

      // eval custom js //
      if (settings.theme.js.custom.trim() !== '')
        setTimeout(() => domEval(settings.theme.js.custom), 1000);

      // init sentry //
      Sentry.configureScope((scope) => {
        scope.setTag('site', settings.env_vars.site);
      });

      // init stripe //
      const site = settings && settings.theme && settings.theme.site;
      const { Stripe } = window;

      if (Stripe && site && site.stripe_publishable_key) Stripe.setPublishableKey(site.stripe_publishable_key);
      if (site && site.title) window.title = settings.theme.site.title;

      // add favicon //
      addFavicon(settings);

      // init theme //
      const theme = {};
      if (s) {
        theme.randomProfileColor = generators.randomColor(s);
        theme.version = s.theme.enable_version_two ? 2 : 1;
        theme.isVersion = val => val === theme.version;
        theme.isCore = isSite(s, 'onetable');
      }
      this.setState({ theme });
    });
  }

  render() {
    const { theme } = this.state;
    let content;
    let loader;

    if (isWordpress()) {
      content = () => <WordPressApp />;
      loader = <WPLoader />;
    } else {
      content = () => (
        <Fragment>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: `${indexStyle(settings.theme.css, settings, theme)} ${settings.theme.css.custom}`
            }}
          />
          <App settings={this.state.settings} />
        </Fragment>
      );
      loader = <Loader />;
    }

    if (!this.state.settings) {
      return (<div>{loader}</div>);
    }

    return (
      <Query query={INIT} errorPolicy="all">
        {({ data, loading, error, refetch }) => {
          if (loading) return isWordpress() ? null : <Loader />;
          if (error) {
            // viewerError && errorMgt.logError('query_errors', 'Viewer query failed', { error: viewerError });
            // error && errorMgt.logError('query_errors', 'INIT query failed', { error });
          }
          const { viewer } = data;

          if (viewer && viewer.user) {
            Sentry.configureScope(scope => scope.setUser({ id: viewer.user.id }));
          } else {
            Sentry.configureScope(scope => scope.setUser({}));
          }

          const session = { ...data, timezones: data.timezonesFull, refetch, theme };

          return (
            <SessionContextProvider session={session} settings={settings}>
              <SettingsContextProvider settings={settings}>
                <OtpContextProvider>
                  {content()}
                </OtpContextProvider>
              </SettingsContextProvider>
            </SessionContextProvider>
          );
        }}
      </Query>
    );
  }
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Root />
  </ApolloProvider>,
  isWordpress() ?
    wordpressUserNavDomRoot() :
    document.getElementById('root')
);
