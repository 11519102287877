import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Input,
  Button,
  withSettings
} from '../components';
import { setToken } from '../../libs/token'
import { authViewStateValues, withOtpMutationHooksHOC } from '../../libs/auth';
import { OtpContext } from '../../OtpContext';


class ResetPassword extends React.Component {
  state = {
    password: '',
    passwordConfirm: '',
    passwordValid: false,
    passwordConfirmValid: false,
    hasSubmitError: false,
    showConfirmation: false,
    loading: false,
    errorMessage: ''
  };
  static contextType = OtpContext;

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  formIsInvalid = () => {
    const { passwordValid, passwordConfirmValid } = this.state;
    return !passwordValid || !passwordConfirmValid;
  };

  handleFormSubmit = (event, resetPasswordWithToken) => {
    event.preventDefault();
    const { password, passwordConfirm } = this.state;
    const { resetPasswordToken, toggle } = this.props;

    if (password !== passwordConfirm) {
      this.setState({ errorMessage: 'The passwords you entered do not match. \n Please try again.' });
    } else {
      this.setState({ loading: true });

      resetPasswordWithToken({ variables: { resetPasswordToken, password } })
        .then(async ({ data }) => {
          const { user, token, errors, status, userId, userMessage, userPhone } = data.resetPasswordWithToken;

          if (user) {
            setToken(this.props.settings, token);

            await this.props.refetch();

            this.setState({ showConfirmation: true, loading: false }, () => toggle());

          } else if (Array.isArray(errors) && errors.length) {
            this.setState({ errorMessage: 'Token is invalid', loading: false });
            // console.error(errors);
          } else if (status === 'must_request_otp') {
            this.context.otpSettings.setOtpSettings({
              status,
              userId,
              userMessage,
              userPhone,
            });
            this.props.setViewState(authViewStateValues.OTP_VIEW);
          } else if (status === 'failure') {
            this.setState({ errorMessage: 'Token is invalid', loading: false });
          }
        });
    }
  };

  render() {
    const { password, passwordConfirm, errorMessage, loading } = this.state;
    const { resetPasswordWithTokenMutation } = this.props;

    return (
            <Fragment>
                <h2>Create Your New Password</h2>
                <p>Please enter a new password for your account.</p>
                <form
                className="ot_form"
                onSubmit={event => this.handleFormSubmit(event, resetPasswordWithTokenMutation)}
                >
                {errorMessage && (
                    <div className="form_submit_error">
                      {errorMessage.split('\n').map((value, index) => {
                        return (
                          <Fragment key={index}>
                            {value}
                            <br />
                          </Fragment>
                        );
                      })}
                    </div>
                )}
                <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onInputChange={this.handleInputChange}
                    onValidate={this.handleInputValidation}
                    required
                />
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    onInputChange={this.handleInputChange}
                    onValidate={this.handleInputValidation}
                    errorMessage="Please enter a valid password confirmation."
                    required
                />
                <Button
                    type="submit"
                    disabled={this.formIsInvalid() || loading}
                    fullWidth
                    capitalize
                    loading={loading}
                >Update Password</Button>
                </form>
            </Fragment>
        )

    }
}

const StyledResetPassword = styled(ResetPassword)`
  padding: 30px 20px;
  .logo {
    max-width: 140px;
    margin: 0 auto 30px;
  }
  h2 {
    font-size: 18px;
    text-align: center;
    margin: 0 0 20px;
  }
  p {
    font-size: 14px;
    text-align: center;
    margin: 0 0 20px;
    &.confirmation {
      margin-bottom: 0;
    }
  }
`;

const ResetPasswordWithSettings = withSettings(
  StyledResetPassword
);
const ResetPasswordWithSettingsWithOptHooks = withOtpMutationHooksHOC(
    ResetPasswordWithSettings
);
export { ResetPasswordWithSettingsWithOptHooks as ResetPassword };
