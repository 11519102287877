import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSettings, useProfilePageData } from '../../hooks';
import { queryString } from '../../libs';

import {
  PageContent,
  SideTabLayout,
  PageHeader,
  ProfileSettingsNav,
  ProfileSettingsBody,
  Loader
} from '../../components';

import { ProfileSettingsContextProvider } from './ProfileSettingsContext';

const ProfileSettingsPage = () => {
  const [view, setView] = useState('profile');
  const { loading } = useProfilePageData();
  const { copy } = useSettings();

  const { search } = useLocation();
  const { stripe_redirect } = queryString(search, 'stripe_redirect');

  useEffect(() => {
    if (stripe_redirect === 'billing_portal') setView('membership');
  }, [stripe_redirect]);

  if (loading) return <Loader />;
  return (
    <PageContent>
      <PageHeader heading={copy.profile.title} arrow={false} />
      <SideTabLayout>
        <ProfileSettingsNav view={view} setView={setView} />
        <ProfileSettingsBody view={view} />
      </SideTabLayout>
    </PageContent>
  );
};

const ProfileSettingsPageWithContext = props => (
  <ProfileSettingsContextProvider>
    <ProfileSettingsPage {...props} />
  </ProfileSettingsContextProvider>
)
export { ProfileSettingsPageWithContext as ProfileSettingsPage };
