import React, { useContext } from 'react';
import styled from 'styled-components';
import { FAIcon } from '../components';
import { withSettings } from '../components';

import { SessionContext } from '../../Context';

import {
  getScheduledAtDate,
  getDeadlineAtDate,
  getEventTimeSpan,
  getDeadlineAtTime,
  isSite,
  whitelabel,
  getEventLocationWithSubtype,
  getNeighborhoodLabel,
  getSubNeighborhoodLabel,
  getFormattedNeighborhood,
  getFormattedSubneighborhood,
  getProfileLanguages,
  appendTimezoneOffset
} from '../../libs';

const EventViewHeaderDetails = (props) => {
  const { className, event, isHostOrReservedGuest, areas, viewer, settings } = props;
  const { copy, global: { display_timezone_offsets } } = settings;
  const { timezones } = useContext(SessionContext);

  const isPotluck = event.potluck && event.potluckItems && event.potluckItems.edges && event.potluckItems.edges.length > 0;
  const isCatered = event.catered;
  const privacySubtype = event.privacySubtype;
  const eventSubtype = event.eventSubtype;
  const shouldHidePotluck = whitelabel.hasFeature(settings, 'hidePotluckIcons');

  const profile = viewer.user && viewer.user.profile;
  const showLanguageOnEvents = true; // profile && profile.showLanguageOnEvents;
  const languages = profile ? getProfileLanguages(profile) : [];

  const renderNeighborhoodLabel = () => {
    const shouldShowAreaSubtype = whitelabel.hasFeature(settings, 'showAreaSubtypeLabel') && !isSite(settings, 'hillel.michigan', true);
    const neighborhoodOnly = whitelabel.hasFeature(settings, 'neighborhoodOnly');
    let label = '';

    if (shouldShowAreaSubtype) {
      label = getEventLocationWithSubtype(event, isSite(settings, 'hillel.international', true), settings);
    } else {
      if (isSite(settings, 'hillel.michigan', true)) {
        label = event.neighborhood ? `RSVPs ${event.neighborhood}` : false;
      } else if (event.areaOther) {
        label = event.areaOther;
      } else {
        const shouldShowArea = areas && areas.length > 1;
        const areaLabel = event.area.label;
        const neighborhood = event.neighborhood === 'OTHER' ? event.areaOther : getNeighborhoodLabel(event);
        const neighborhoodLabel = getFormattedNeighborhood(neighborhood);
        const subneighborhood = getSubNeighborhoodLabel(event);
        const subneighborhoodLabel = getFormattedSubneighborhood(subneighborhood);

        if (shouldShowArea && areaLabel && !neighborhoodOnly) {
          label = areaLabel;
        }

        if (neighborhoodLabel) {
          if (label.length > 0) {
            label = label + ', ';
          }
          label = label + neighborhoodLabel;
        }

        if (subneighborhoodLabel && !neighborhoodOnly) {
          if (label.length > 0) {
            label = label + ', ';
          }
          label = label + subneighborhoodLabel;
        }
      }
    }

    if (isHostOrReservedGuest && event.fullAddress && !neighborhoodOnly && !isSite(settings, 'hillel.michigan', true)) {
      label += ' | ' + event.fullAddress;
    }

    return label;
  };

  const renderAddressLabel = () => {
    const fullAddress = event.fullAddress;
    const secondaryAddress = event.secondaryAddress;
    return fullAddress && (fullAddress + (secondaryAddress ? `, ${secondaryAddress}` : ''));
  };

  const getTimezoneLabel = () => {
    const zone = Array.isArray(timezones) && timezones.find(tz => tz.name === event.timezone);
    return zone ? appendTimezoneOffset(zone, display_timezone_offsets) : event.timezone;
  }

  const getAddressIcon = () => {
    if (isSite(settings, 'hillel.michigan', true)) {
      return [ 'far', 'calendar-alt' ];
    } else if (isSite(settings, ['hillel'])) {
      return [ 'fas', 'users' ];
    } else {
      return [ 'fas', 'map-marker-alt' ];
    }
  };

  return (
    <div className={className}>
      <span><FAIcon name={[ 'far', 'calendar-alt' ]} />{getScheduledAtDate(event)}</span>
      <span><FAIcon name={[ 'far', 'clock' ]} />{getEventTimeSpan(event)}</span>
      <span>
        <FAIcon name={[ 'far', 'calendar-times' ]} />
        {copy.event.rsvp_deadline}
        {getDeadlineAtDate(event)} - {getDeadlineAtTime(event)}
      </span>
      {!whitelabel.hasFeature(settings, 'hideNeighborhood') && renderNeighborhoodLabel() && (
        <span><FAIcon name={getAddressIcon()} /> {renderNeighborhoodLabel()}</span>
      )}
      {isHostOrReservedGuest && renderAddressLabel() && (
        <span><FAIcon name={[ 'fas', 'map-marker-alt' ]} /> {renderAddressLabel()}</span>
      )}
      {!whitelabel.hasFeature(settings, 'hideTimezone') && !isSite(settings, 'hillel.michigan', true) && (
        <span><FAIcon name={[ 'fas', 'globe-americas' ]} />{getTimezoneLabel()}</span>
      )}
      {!event.virtual && (
        <span><FAIcon name={[ 'far', 'car-alt' ]} />{event.parkingLots ? copy.event.parking : copy.event.no_parking}</span>
      )}
      {event.accessible && <span><FAIcon name={[ 'far', 'wheelchair' ]} />{copy.event.wheelchair}</span>}

      {privacySubtype && (
        <span>
          <FAIcon name={[ 'fas', privacySubtype.virtual ? 'phone-laptop' : 'users' ]} />{privacySubtype.name}
        </span>
      )}
      {isPotluck && !shouldHidePotluck && <span><FAIcon name={[ 'far', 'utensils' ]} />Potluck</span>}
      {isCatered && <span><FAIcon name={[ 'far', 'utensils' ]} />Catered</span>}
      {/* legacy events? */}
      {!privacySubtype && event.virtual && !isSite(props.settings, 'jewishfoodfest') && (
        <span><FAIcon name={[ 'far', 'phone-laptop' ]} />Virtual</span>
      )}

      {whitelabel.hasFeature(settings, 'showAreaLabel') && event.area && (
        <span><FAIcon name={[ 'fas', 'users' ]} /> {event.area.label}</span>
      )}

      {whitelabel.hasFeature(settings, 'showEventSubtype') && eventSubtype && (
        <span>
          <FAIcon name={[ 'fas', 'users' ]} /> {eventSubtype.name}
        </span>
      )}

      {event.closedCaptioningAvailable && (
        <span><FAIcon name={[ 'far', 'closed-captioning' ]} />{copy.create.closed_captioning}</span>
      )}
      {event.videosOnAsDefault && (
        <span><FAIcon name={[ 'far', 'video' ]} />{copy.event.videos_on || 'Videos On By Default'}</span>
      )}
      {event.bathroomAccessible && (
        <span><FAIcon name={[ 'fas', 'restroom' ]} />{copy.create.bathroom_accessible}</span>
      )}
      {showLanguageOnEvents && languages.length > 0 && (
        <span><FAIcon name={[ 'far', 'comment-dots' ]} />{languages.join(', ')}</span>
      )}
    </div>
  );
};

const StyledEventViewHeaderDetails = styled(EventViewHeaderDetails)`
  font-size: 12px;

  span {
    display: inline-block;
    margin: 4px 10px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
  }

  svg {
    margin-right: 8px;
    font-size: 13px;

    &.fa-car-alt {
      margin-right: 10px;
    }

    &.fa-map-marker-alt {
      margin-right: 5px;
    }
  }
`;

const StyledEventViewHeaderDetailsWithSettings = withSettings(StyledEventViewHeaderDetails);
export { StyledEventViewHeaderDetailsWithSettings as EventViewHeaderDetails };
