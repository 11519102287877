import React from 'react';
import styled, { css } from 'styled-components';
import {
  PrimaryButton as Button,
  withSettings,
  withSession
} from '../components';

const DonationLink = ({ className, text, url, buttonText = 'Donate', session: { theme } }) => (
  <div className={className}>
    {text && (<p className="donation_text">{text}</p>)}
    {theme.isVersion(2) ? (
      url && <Button onClick={() => window.open(url, '_blank')} small>{buttonText}</Button>
    ) : (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="donation_button"
      >{buttonText}</a>
    )}
  </div>
);

const StyledDonationLink = styled(DonationLink)`
  border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};

  ${({ session: { theme } }) => theme.isVersion(2) ? css`
    margin: 30px 0;
    padding: 0 0 30px;
  ` : css`
    margin: -10px 0 30px;
    padding: 0 0 20px;
  `}

  p.donation_text {
    margin-bottom: 20px;

    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      font-size: 14px;
    ` : css`
      font-size: 13px;
    `}
  }

  .donation_button {
    display: inline-block;
    line-height: 20px;
    background-color: ${props => props.settings.theme.css.global.colors.primary};
    border: 1px solid ${props => props.settings.theme.css.global.colors.primary};
    color: #fff;
    font-size: 14px;
    min-width: 200px;
    border-radius: 3px;
    transition: border-color 250ms, background-color 250ms;

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
      border-color: ${props => props.settings.theme.css.global.colors.primaryHover};
      text-decoration: none;
      color: #fff;
    }
  }
`;

const DonationLinkWithSettings = withSettings(StyledDonationLink);
const DonationLinkWithSession = withSession(DonationLinkWithSettings);
export { DonationLinkWithSession as DonationLink };
