import { useState } from 'react';
import { trigger } from '../../libs/customEvent';
import { useSettings } from '../../hooks';

export const useClaimedPotluckItems = (event) => {
  const [claimedPotluckItems, setClaimedPotluckItems] = useState({});
  const items = event ? event.potluckItems.edges.map(({ node }) => node).filter(i => i) : [];
  const { event: { max_reservation_potluck_items } } = useSettings();
  const maxClaimable = max_reservation_potluck_items && parseInt(max_reservation_potluck_items);

  const maxItems = ({ id, freeQuantity }) => {
    const max = maxClaimable
      ? Math.min(maxClaimable, freeQuantity)
      : freeQuantity;
    let claimedOther = 0;
    let claimed = 0;

    if (!claimedPotluckItems) return max;

    for (const i in claimedPotluckItems) {
      if (parseInt(i) !== id) claimedOther = claimedOther + claimedPotluckItems[i];
      else claimed = claimed + claimedPotluckItems[i];
    }

    return !claimed || !maxClaimable ? max : Math.min(Math.min(claimed, max), max - claimedOther);
  };

  const validateClaimedPotluckItems = () => {
    let valid = true;

    for (const i in claimedPotluckItems) {
      const val = claimedPotluckItems[i];
      const item = items.find(itm => itm.id === parseInt(i));

      if (!val || !item) continue;

      if (
        (maxClaimable && (maxItems(item) > 0) && val > maxItems(item)) ||
        val > item.freeQuantity
      ) {
        valid = false;
        trigger('rsvpPage:validatePotluck', { id: item.id });
      }
    }

    return valid;
  };

  return {
    claimedPotluckItems,
    setClaimedPotluckItems: ([id, val]) => setClaimedPotluckItems(itms => ({ ...itms, [id]: parseInt(val) })),
    setClaimedPotluckItemsAll: val => setClaimedPotluckItems(val),
    validateClaimedPotluckItems
  }
};
