import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { withSettings } from '../components';


export const LandingImageLinks = ({ links }) => {
  if (Array.isArray(links)) {
    return (
      <LinkList>
        {links.map(l => <LandingImageLink key={l.id} link={l} />)}
      </LinkList>
    );
  }
  return null;
};

const LinkList = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 1.5rem 0 0;
  padding: 0;

  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

const LandingImageLinkComponent = ({ link, history, settings: { copy } }) => {
  return (
    <Link onClick={() => history.push(link.url)}>
      {link.virtual && <Banner>{copy.landing.event_virtual_banner || 'Virtual Dinner'}</Banner>}
      <LinkImage image={link.backgroundImageUrl} />
      <label>{link.name}{link.host && (<span>{`with ${link.host}`}</span>)}</label>
    </Link>
  );
};
const LandingImgeLinkWithSettings = withSettings(LandingImageLinkComponent);
const LandingImageLink = withRouter(LandingImgeLinkWithSettings);

const Link = styled.li`
  position: relative;
  cursor: pointer;

  label {
    margin: .5rem 0 0;
    cursor: pointer;
    font-family: 'Avenir-Medium' !important;

    span {
      display: inline-block;
      font-family: 'Avenir-Regular' !important;
      color: #83817b;
      font-size: 14px;
      margin-left: 10px;
    }
  }
`;

const LinkImage = styled.div`
  position: relative;
  padding-top: 65%;
  border-radius: 6px;
  background-color: #545454;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
  transition: box-shadow 350ms, opacity 350ms;

  ${({ image }) => image && css`
    background-image: url(${image});
    background-position: center;
    background-size: cover;
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000030;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 350ms;
  }

  li:hover > & {
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.18);

    &::after {
      opacity: 1;
    }
  }
`;

const Banner = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  text-transform: uppercase;
  z-index: 1;
  font-family: 'Avenir-Medium' !important;
  font-size: 11px;
  letter-spacing: 0.16em;
  padding: 10px 12px 9px;
  background-color: #7fbc74;
  line-height: 1;
  border-radius: 6px 0 6px 0;
`;
