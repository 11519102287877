import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';

import { FAIcon, DropDownMenu, DropDownMenuFooter, SearchFilter } from '../../components';

import { FilterContext } from '../FilterContext';


export const DropdownSearchFilter = ({ name, label = 'Select', options = [], fullWidth }) => {
  const [open, setOpen] = useState(false);

  const { getValue, setValue } = useContext(FilterContext);
  const value = getValue(name);
  const valueLabel = value && options.reduce((lab, o) => o.value === value ? o.label : lab, false);

  const handleOptionClick = option => {
    if (getValue(name) === option) setValue(name, null);
    else setValue(name, option);
    setOpen(false);
  };

  const handClearFilter = () => {
    setValue(name, null);
    setOpen(false);
  };

  return (
    <SearchFilter
      active={open || value}
      icon
      inline
      onClick={() => setOpen(true)}
      className="dropdown_search_filter"
      fullWidth={fullWidth}
    >
      <DropdownContainer fullWidth={fullWidth}>
        {valueLabel || label}
        {open && (
          <DropDownMenu
            toggle={() => setOpen(false)}
            stopPropagation
            backdrop
            footer={!!value}
          >
            {Array.isArray(options) && options.map((o, i) => (
              <li onClick={() => handleOptionClick(o.value)} key={i}>
                <span>{o.label}</span>
                {value === o.value && <FAIcon name={['fas', 'check']} />}
              </li>
            ))}
            {value && <DropDownMenuFooter onButtonClick={handClearFilter} buttonLabel="Clear" />}
          </DropDownMenu>
        )}
        <FAIcon name={[ 'fas', 'caret-down' ]} />
      </DropdownContainer>
    </SearchFilter>
  );
};


const DropdownContainer = styled.span`
  .fa-caret-down {
    position: absolute;
    top: 50%;
    right: 15px;
    color: #97958f;
    font-size: 17px;
    margin-top: -9px;
  }

  .dropdown_menu {
    top: 100%;
    left: -2px;
    min-width: 200px;

    ${props => props.fullWidth && css`
      width: 100%;
    `}
  }

`;
