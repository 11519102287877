import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Modal, Button, FAIcon } from '../components';
import { withSettings } from '../components';

class HostApplicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    handleRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    render() {
        const { className } = this.props;
        const { redirect } = this.state;
        const { settings: { copy } } = this.props;

        return (
            <Modal size="sm" show={true} toggle={this.handleRedirect}>
                <div className={className}>
                    <div className="submitted">
                        <FAIcon name="check-circle" />
                        <p>Your application has been submitted.</p>
                    </div>
                    <Button handleClick={() => { this.handleRedirect() }}>{copy.host_promotion.find_dinner}</Button>
                    { redirect ?
                        <Redirect to="/"/>
                    :
                        null
                    }
                </div>
            </Modal>
        );
    }
}

const StyledHostApplicationModal = styled(HostApplicationModal)`
    padding: 0px;
    width: 120%;
    margin-left:-10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > * {
        align-self: center;
    }
    .submitted {
        text-align: center;
        margin-top: 30px;
        svg {
            font-size: 35px;
            color: green;
            margin-bottom: 25px;
        }
    }
    .button {
        margin-top: 30px;
    }
`;

const StyledHostApplicationModalWithSettings = withSettings(StyledHostApplicationModal);

export { StyledHostApplicationModalWithSettings as HostApplicationModal }
