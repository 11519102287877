import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  ForgotPassword,
  LogInWithEmailAndPassword,
  Otp
} from '../';

import { OtpContext } from '../../OtpContext';
import { authViewStateValues } from '../../libs/auth';

const initialState = {
  email: '',
  password: '',
  emailValid: false,
  passwordValid: false,
  hasLoginError: false,
  authViewState: authViewStateValues.LOGIN_WITH_PASSWORD_VIEW,
  isLoading: false
};

class EmailLogIn extends React.Component {
  state = { ...initialState };
  static contextType = OtpContext;

  setAuthViewState = (viewState) => {
    this.setState({ authViewState: viewState });
  };

  render() {
    const { authViewState } = this.state;

    switch (authViewState) {
      case  authViewStateValues.LOGIN_WITH_PASSWORD_VIEW:
        return <LogInWithEmailAndPassword setViewState={this.setAuthViewState} {...this.props}  />;
      case authViewStateValues.FORGOT_PASSWORD_VIEW:
        return <ForgotPassword setViewState={this.setAuthViewState} {...this.props} />;
      case authViewStateValues.OTP_VIEW:
        return <Otp setViewState={this.setAuthViewState} {...this.props} />;
      default:
          return <LogInWithEmailAndPassword setViewState={this.setAuthViewState} {...this.props} />;

    }
  }
}

const StyledEmailLogIn = styled(EmailLogIn)`
`;

const LogInWithRouter = withRouter(StyledEmailLogIn);
const LogInWithRouterWithSettings = withSettings(LogInWithRouter);

export { LogInWithRouterWithSettings as EmailLogIn };
