import moment from 'moment-timezone';
import {
  getEventDeadlineAt,
  getEventDuration,
  getEventEndTime,
  getVirtualEventFeildsByPolicy,
  isSite,
  getMaxSeatLimit
} from '../libs';

import { EVENT_TYPE } from '../enums';

import eventFormValidator from '../libs/eventFormValidator';
import fieldsConfig from '../components/EventCreation/const/FieldsConfiguration';
import { getUpdatedFields } from '../libs';

import { RECURRANCE } from '../constants/recurrances';

const startTime = new Date();
startTime.setHours(19, 0, 0);

const endTime = new Date();
endTime.setHours(21, 0, 0);

const eventDataDefaultState = {
  id: null,
  state: '',

  shouldRemoveSponsorships: false,
  cachedDate: null,
  shouldSkipSponsorshipLimits: false,

  eventCreationResponses: [],
  communityDinner: false,

  eventType: '',
  eventTypeValid: true,
  eventSubtype: '',
  eventSubtypeValid: true,
  eventSubtypeHasOther: false,
  eventSubtypeOther: '',
  eventSubtypeOtherValid: true,
  privacySubtype: null,
  privacySubtypeValid: true,
  privacySubtypeDisabled: false,

  description: '',
  descriptionValid: true,

  cover: null,
  uploadCover: true,

  title: '',
  titleValid: true,
  numberOfGuestsMax: null,
  numberOfGuestsMaxValid: true,
  date: null,
  dateValid: true,

  startTime,
  startTimeValid: true,
  endTime,
  endTimeValid: true,

  rsvpDeadline: false,
  rsvpDeadlineDate: null,
  rsvpDeadlineDateValid: true,
  rsvpDeadlineTime: null,
  rsvpDeadlineTimeValid: true,

  cohosts: [],
  cohostsValid: true,

  pwyw: false,
  pwywMinimum: 0,
  paid: false,
  price: null,
  priceValid: true,
  hostAbsorbsTransactionFee: false,
  explainPayment: '',
  explainPaymentValid: true,

  area: null,
  areaValid: false,
  areaOther: null,
  areaOtherValid: true,
  stateLocation: null,
  timezone: null,
  neighborhood: null,
  neighborhoodValid: true,
  campus: null,
  subneighborhood: null,
  fullDress: null,
  secondaryDress: null,
  parkingLots: '',
  accessible: '',
  outdoors: '',

  tags: [],

  alcoholPolicy: null,
  dietaryRestrictions: null,
  dressCode: null,

  notes_first: [],
  notes_second: [],
  notes_third: [],

  potluck: false,
  potluckItems: [],
  potluckItemsToDestroy: [],

  catered: false,

  reservationQuestions: [],
  reservationQuestionsToDelete: [],

  partnerOrganizationName: '',
  partnerOrganizationNameValid: true,
  partnerOrganizationLogo: null,
  uploadPartnerOrganizationLogo: true,
  partnerOrganizationDescription: '',
  hasTables: false,
  tables: [],
  tablesToDestroy: [],

  maxSeats: null,
  maxSeatsValid: true,

  virtual: false,
  virtualLocation: '',
  virtualDisabled: false,
  virtualHide: false,
  virtualConfirmation: false,
  virtualResourceUrl: '',

  extraDetails: '',

  hideGuestList: false,

  closedCaptioningAvailable: '',
  videosOnAsDefault: '',

  bathroomAccessible: '',

  recurrance: RECURRANCE.NONE,
  recurranceNumber: 2
};

const buildEventDataDefaultState = (settings, args, viewer, areas, eventPrivacySubtypes) => {
  const {
    event: { pwyw_minimum, virtual }
  } = settings;
  const {
    defaultType,
    defaultDescription,
    creationAnswers,
    isCommunity,
    minDescriptionLength,
    maxDescriptionLength,
    creator,
    defaultArea = null,
    cover
  } = args;

  let eventData = Object.assign({}, eventDataDefaultState);

  eventData.eventType = defaultType;
  eventData.eventTypeValid = !!defaultType;
  eventData.description = defaultDescription || '';
  eventData.descriptionValid = !!(defaultDescription && defaultDescription > minDescriptionLength);
  eventData.eventCreationResponses = Array.isArray(creationAnswers) ? creationAnswers : [];
  eventData.communityDinner = !!isCommunity;
  eventData.creator = creator;
  eventData.pwywMinimum = pwyw_minimum / 100;
  eventData.area = defaultArea;
  eventData.minDescriptionLength = minDescriptionLength;
  eventData.maxDescriptionLength = maxDescriptionLength;
  eventData.cover = cover;

  if (isSite(settings, 'dinner-party')) eventData.hideGuestList = true;

  if (defaultType && !eventData.communityDinner) {
    const eventArea =
      defaultArea ||
      areas.find(a =>
        viewer.profile && viewer.profile.area ? a.code === viewer.profile.area.code : false
      );

    // vaccination policy //
    const vaccinationPolicy = eventArea && eventArea.socialDistancePolicy
      ? eventArea.socialDistancePolicy[
          `${
            defaultType === 'MANUAL_APPROVAL' ? 'manualApproval' : defaultType.toLowerCase()
          }VaccinatedCreationPolicy`
        ]
      : false;

    let virtualPolicy = '';
    let virtualFields = {};

    // check for mandatory vaccination policy first. this will override the virtual policy //
    if (vaccinationPolicy !== 'mandatory') {
      const eventVirtualPolicy = eventArea && eventArea.socialDistancePolicy
        ? eventArea.socialDistancePolicy[
            `${
              defaultType === 'MANUAL_APPROVAL' ? 'manualApproval' : defaultType.toLowerCase()
            }VirtualCreationPolicy`
          ]
        : false;

      const globalVirtualPolicy =
        virtual[
          `${
            defaultType === 'MANUAL_APPROVAL' ? 'manual_approval' : defaultType.toLowerCase()
          }_creation_policy`
        ];
      virtualPolicy = eventVirtualPolicy || globalVirtualPolicy;
      virtualFields = getVirtualEventFeildsByPolicy(virtualPolicy, settings);
    }

    const soloFields = defaultType === EVENT_TYPE.SOLO
      ? Object.assign({}, fieldsConfig.SOLO.enabled)
      : {};

    // set the correct virtual privacy subtype if virtual policy is mandatory //
    const policyFields = {};

    if (vaccinationPolicy === 'mandatory' || virtualPolicy === 'mandatory') {
      const eventTypePrivacySubtype = eventPrivacySubtypes.filter(
        type => type.eventType === defaultType
      );

      if (eventTypePrivacySubtype.length) {
        policyFields.privacySubtype = eventTypePrivacySubtype.find(type => (
          vaccinationPolicy === 'mandatory' ? type.vaccinated : type.virtual
        )) || null;
        policyFields.privacySubtypeDisabled = true;
      }
    }

    eventData = {
      ...eventData,
      ...virtualFields,
      ...soloFields,
      ...policyFields
    };
  }

  if (eventData.communityDinner) {
    eventData.virtual = false;
    eventData.virtualHide = false;
    eventData.disabledEventTypes = ['SOLO'];
  }

  eventData.numberOfGuestsMaxLimit = getMaxSeatLimit(
    eventData,
    areas,
    settings,
    viewer,
    defaultType,
    eventData.virtual
  );

  return eventData;
};

const validateEventData = (eventData, settings, event, viewer, isUpdate) => {
  return eventFormValidator.validateData(eventData, settings, event, viewer, isUpdate);
};

const formatEventDataForSubmit = (data, savedEventData, isUpdate, viewer, settings) => {
  // let cohosts = Array.isArray(data.cohosts) ? data.cohosts.map(cohost => ({ email: cohost, visible: true })) : [];
  // this was causing a bug where a cohost (not the creator) is removed if updating an event
  // the cohost field component already filters out the viewer

  const scheduledAt = getEventDeadlineAt(data.date, data.startTime);
  const deadlineAt =
    data.rsvpDeadline && data.rsvpDeadlineDate && data.rsvpDeadlineTime
      ? getEventDeadlineAt(data.rsvpDeadlineDate, data.rsvpDeadlineTime)
      : null;

  const eventData = {
    accessible: data.accessible === 'true',
    alcoholPolicy: data.alcoholPolicy,
    areaId: data.area ? data.area.id : null,
    areaOther: data.areaOther,
    stateLocation: data.stateLocation,
    cohosts: Array.isArray(data.cohosts)
      ? data.cohosts.map((cohost) => ({ email: cohost, visible: true }))
      : [],
    communityDinner: data.communityDinner,
    coverUrl: data.cover,
    deadlineAt: deadlineAt,
    description: data.description ? data.description.trim() : null,
    dietaryRestrictions: Array.isArray(data.dietaryRestrictions) ? data.dietaryRestrictions : [],
    dressCode:
      data.dressCode && typeof data.dressCode === 'object' ? data.dressCode.name : data.dressCode,
    duration: getEventDuration(data.date, data.startTime, data.endTime),
    eventCreationResponses: data.eventCreationResponses,
    eventType: data.eventType,
    eventSubtypeId: data.eventSubtype ? data.eventSubtype : null,
    eventSubtypeOther: data.eventSubtypeOther,
    fullAddress: data.fullDress ? data.fullDress.trim() : null,
    hostAbsorbsTransactionFee: data.paid ? data.hostAbsorbsTransactionFee : false,
    neighborhood: data.neighborhood,
    campus: data.campus,
    notes: JSON.stringify({
      version: '1',
      first: data.notes_first,
      second: data.notes_second,
      third: data.notes_third
    }),
    numberOfGuestsMax: !data.communityDinner
      ? data.numberOfGuestsMax
      : !data.hasTables
      ? data.maxSeats
      : data.tables.reduce((guests, table) => table.maxSeats + guests, 0),
    pricePerPerson: data.paid ? (data.pwyw ? null : data.price * 100) : 0,
    pwyw: data.pwyw,
    pwywMinimum: data.pwyw ? data.pwywMinimum * 100 : null,
    parkingLots: data.parkingLots === 'true',
    partnerOrganizationDescription: data.partnerOrganizationDescription
      ? data.partnerOrganizationDescription.trim()
      : null,
    partnerOrganizationLogoUrl: data.partnerOrganizationLogo,
    partnerOrganizationName: data.partnerOrganizationName
      ? data.partnerOrganizationName.trim()
      : null,
    potluck: data.potluck,
    catered: settings.event.catered && !data.virtual ? data.catered : false,
    secondaryAddress: data.secondaryDress ? data.secondaryDress.trim() : null,
    scheduledAt: scheduledAt,
    subneighborhood: data.subneighborhood,
    tags: data.tags,
    timezoneOther: data.area && !data.area.timezone ? data.timezone : null,
    title: data.title ? data.title.trim() : null,
    virtual: data.virtual,
    virtualLocation: data.virtualLocation,
    virtualResourceUrl: data.virtualResourceUrl,
    extraDetails: data.extraDetails,
    journeyResponseIds: data.journeyResponseIds,
    hideGuestList: data.hideGuestList,
    privacySubtypeId: data.privacySubtype && data.privacySubtype.id,
    closedCaptioningAvailable: data.closedCaptioningAvailable === 'true',
    videosOnAsDefault: data.videosOnAsDefault === 'true',
    bathroomAccessible: data.bathroomAccessible === 'true'
  };

  if (data.newCover) {
    eventData.coverUrl = data.newCover;
  }

  if (data.paid && data.explainPayment) {
    eventData.explainPayment = data.explainPayment.trim();
  }

  if (data.recurrance && data.recurranceNumber && scheduledAt) {
    const date = moment(scheduledAt);
    const recurrenceDates = [];
    let recurrance = 1;
    const recurranceType = 'w';
    let addRecurrances = true;
    let recurrenceAdd;

    switch (data.recurrance) {
      case RECURRANCE.WEEKLY:
        // recurranceType = 'w';
        recurrenceAdd = 1;
        break;
      case RECURRANCE.MONTHLY:
        // recurranceType = 'M';
        recurrenceAdd = 4;
        break;
      case RECURRANCE.ANNUALLY:
        // recurranceType = 'y';
        recurrenceAdd = 52;
        break;
      default:
        addRecurrances = false;
    }

    if (addRecurrances) {
      while (recurrance < data.recurranceNumber) {
        recurrenceDates.push(date.add(recurrenceAdd, recurranceType).startOf('day').toISOString());
        recurrance++;
      }
    }
    eventData.recurrenceDates = recurrenceDates;
  }

  const potluckItems = data.potluckItems.map((item, index) => {
    if (typeof item === 'string') {
      return {
        id: null,
        name: item,
        quantity: 1,
        position: index + 1
      };
    } else {
      return {
        id: item.id,
        name: item.name,
        quantity: parseInt(item.quantity) || 1,
        position: index + 1
      };
    }
  });

  const potluckItemsToCreate = potluckItems
    .filter((item) => item.id == null)
    .map((item) => ({
      name: item.name,
      quantity: item.quantity,
      position: item.position
    }));

  const potluckItemsToUpdate = potluckItems.filter((item) => item.id != null);

  const potluckItemsToDestroy = data.potluckItemsToDestroy;

  if (isUpdate) {
    // create / update / destroy potluck items //
    if (data.potluck && Array.isArray(data.potluckItems)) {
      eventData.createPotluckItemsObj = potluckItemsToCreate;
      eventData.updatePotluckItems = potluckItemsToUpdate;
      eventData.destroyPotluckItems = potluckItemsToDestroy;
    }

    // create / update / destroy tables //
    if (data.communityDinner && Array.isArray(data.tables)) {
      eventData.createTables = data.tables
        .filter((table) => table.id === null)
        .map((table) => ({
          name: table.name,
          maxSeats: table.maxSeats
        }));
      eventData.updateTables = data.tables
        .filter((table) => table.id !== null)
        .map((table) => ({
          id: table.id,
          name: table.name,
          maxSeats: table.maxSeats
        }));
      eventData.destroyTables = data.tablesToDestroy;
    }

    // create / update / destroy rsvp questions //
    if (Array.isArray(data.reservationQuestions)) {
      eventData.createReservationQuestions = data.reservationQuestions
        .filter((q) => q.id === null && q.text !== '' && q.answerType !== '')
        .map((q) => ({
          position: q.position,
          text: q.text,
          answerType: q.answerType,
          disabled: false,
          baseQuestionId: q.baseQuestionId
        }));
      eventData.updateReservationQuestions = data.reservationQuestions
        .filter((q) => q.id !== null)
        .map((q) => ({
          id: q.id,
          position: q.position,
          text: q.text,
          answerType: q.answerType,
          disabled: false,
          baseQuestionId: q.baseQuestionId
        }));
      eventData.destroyReservationQuestions = data.reservationQuestionsToDelete;
    }

    // Check Sponsorship Limit Override //
    eventData.shouldSkipSponsorshipLimits = data.nourishmentRestrictionExceptionEnabled;

    // reset expectedGuests if sponsorships were removed //
    if (data.shouldRemoveSponsorships) {
      eventData.expectedGuests = 0;
    }
  } else {
    // create potluck items //
    eventData.potluckItemsObj = data.potluck ? potluckItemsToCreate : [];

    // create tables //
    eventData.tables = Array.isArray(data.tables)
      ? data.tables.map((table) => ({
          name: table.name,
          maxSeats: table.maxSeats
        }))
      : [];

    // create rsvp questions //
    eventData.reservationQuestions =
      Array.isArray(data.reservationQuestions) && data.reservationQuestions.length > 0
        ? data.reservationQuestions
            .filter((q) => q.text !== '' && q.answerType !== '')
            .map((q) => ({
              position: q.position,
              text: q.text,
              answerType: q.answerType,
              disabled: false,
              baseQuestionId: q.baseQuestionId
            }))
        : [];
  }

  if (!data.uploadPartnerOrganizationLogo) delete eventData.partnerOrganizationLogo;

  // only return updated fields when updating an event //
  return isUpdate ? getUpdatedFields(savedEventData, eventData) : eventData;
};

const buildEventUpdateState = (settings, data, areas, eventPrivacySubtypes) => {
  // const { event: { virtual } } = settings;
  const eventData = Object.assign({}, eventDataDefaultState);
  // const virtualPolicy = virtual[`${data.eventType.toLowerCase()}_creation_policy`];
  const eventArea = areas.find((area) => area.code === data.area.code);
  const virtualPolicy =
    eventArea && eventArea.socialDistancePolicy
      ? eventArea.socialDistancePolicy[
          `${
            data.eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : data.eventType.toLowerCase()
          }VirtualCreationPolicy`
        ]
      : '';
  const hideVirtualFields = isSite(settings, 'jewishfoodfest');

  const scheduledAtComponents = data.scheduledAt.split(/[^0-9]/);
  const startDate = new Date(
    scheduledAtComponents[0],
    scheduledAtComponents[1] - 1,
    scheduledAtComponents[2],
    scheduledAtComponents[3],
    scheduledAtComponents[4],
    scheduledAtComponents[5]
  );
  const eventStartTime = new Date(startDate);
  const eventEndTime = getEventEndTime(eventStartTime, data.duration);

  const deadlineAtComponents = data.effectiveRsvpDeadlineAt.split(/[^0-9]/);

  const rsvpDeadlineDateTime =
    data.rsvpDeadlineAt !== null
      ? new Date(
          deadlineAtComponents[0],
          deadlineAtComponents[1] - 1,
          deadlineAtComponents[2],
          deadlineAtComponents[3],
          deadlineAtComponents[4],
          deadlineAtComponents[5]
        )
      : null;

  delete eventData.eventCreationResponses;
  delete eventData.explainPayment;

  eventData.id = data.id;
  eventData.state = data.state;
  eventData.communityDinner = data.communityDinner;
  eventData.eventType = data.eventType;
  eventData.privacySubtype =
    data.virtual && !data.privacySubtype
      ? eventPrivacySubtypes
          .filter((st) => st.eventType === data.eventType)
          .find((st) => st.virtual)
      : data.privacySubtype;
  eventData.eventSubtype = data.eventSubtype ? data.eventSubtype.id : null;
  eventData.eventSubtypeOther = data.eventSubtypeOther;
  eventData.eventSubtypeHasOther = !!data.eventSubtypeOther;
  eventData.description = data.description;
  eventData.cover = data.cover ? data.cover.originalUrl : null;
  eventData.originalCover = data.cover;
  eventData.title = data.title;
  eventData.numberOfGuestsMax = data.numberOfGuestsMax;
  eventData.date = startDate;
  eventData.startTime = eventStartTime;
  eventData.endTime = eventEndTime;
  eventData.rsvpDeadline = data.rsvpDeadlineAt !== null;
  eventData.rsvpDeadlineDate = rsvpDeadlineDateTime;
  eventData.rsvpDeadlineTime = rsvpDeadlineDateTime;
  eventData.cohosts = Array.isArray(data.eventHosts.edges)
    ? data.eventHosts.edges.map((host) => host.node.effectiveEmail || '')
    : [];
  let creator = null;
  if (Array.isArray(data.eventHosts.edges))
    data.eventHosts.edges.forEach((edge) => {
      if (edge.node && edge.node.creator) creator = edge.node;
    });
  eventData.creator = creator;

  eventData.paid = !!data.pricePerPerson || data.pwyw;
  eventData.price = eventData.paid ? (data.pricePerPerson / 100).toFixed(0) : null;
  eventData.pwyw = data.pwyw;
  eventData.pwywMinimum = data.pwywMinimum / 100;
  eventData.hostAbsorbsTransactionFee = eventData.paid ? data.hostAbsorbsTransactionFee : false;
  eventData.area = data.area;
  eventData.areaValid = !!eventData.area;
  eventData.areaOther = data.areaOther;
  eventData.stateLocation = data.stateLocation;
  eventData.timezone = data.timezone;
  eventData.neighborhood = data.neighborhood;
  eventData.campus = data.campus;
  eventData.subneighborhood = data.subneighborhood;
  eventData.fullDress = data.fullAddress;
  eventData.secondaryDress = data.secondaryAddress;
  eventData.parkingLots = data.parkingLots.toString();
  eventData.accessible = data.accessible.toString();
  eventData.tags = data.tags;
  eventData.alcoholPolicy = data.alcoholPolicy;
  eventData.dietaryRestrictions = data.dietaryRestrictions;
  eventData.dressCode = data.dressCodeName;
  const notes = JSON.parse(data.notes);
  eventData.notes_first = notes.first;
  eventData.notes_second = notes.second;
  eventData.notes_third = notes.third;
  eventData.catered = data.catered;
  eventData.potluck = data.potluck;
  eventData.potluckItems =
    eventData.potluck && data.potluckItems.edges.length
      ? data.potluckItems.edges.map((item) => item.node)
      : [];
  eventData.reservationQuestions = data.reservationQuestions.edges
    .map((q) => {
      const question = q.node;
      return { ...question };
    })
    .filter((q) => !q.baseQuestion || q.baseQuestion.presenceType !== 'ALWAYS');
  eventData.partnerOrganizationName = eventData.communityDinner ? data.partnerOrganizationName : '';
  eventData.partnerOrganizationLogo = eventData.communityDinner
    ? data.partnerOrganizationLogo
      ? data.partnerOrganizationLogo.smallUrl
      : null
    : null;
  eventData.partnerOrganizationDescription = eventData.communityDinner
    ? data.partnerOrganizationDescription
    : '';
  eventData.hasTables = !!(
    eventData.communityDinner &&
    Array.isArray(data.tables) &&
    data.tables.length
  );
  eventData.tables = eventData.communityDinner ? data.tables : [];
  eventData.tablesToDestroy = [];
  eventData.maxSeats =
    eventData.communityDinner && !eventData.hasTables ? data.numberOfGuestsMax : null;
  eventData.numberOfGuestsMaxLimit = getMaxSeatLimit(data, areas, settings, null);
  eventData.virtual = data.virtual;
  eventData.virtualLocation = data.virtualLocation;
  eventData.virtualConfirmation = !data.virtual;
  eventData.virtualResourceUrl = data.virtualResourceUrl;
  eventData.extraDetails = data.extraDetails;
  eventData.outdoors = data.privacySubtype && data.privacySubtype.outdoors ? true : '';
  eventData.hideGuestList = data.hideGuestList;
  eventData.closedCaptioningAvailable = data.closedCaptioningAvailable ? 'true' : 'false';
  eventData.videosOnAsDefault = data.videosOnAsDefault ? 'true' : 'false';
  eventData.bathroomAccessible = data.bathroomAccessible ? 'true' : 'false';

  if (!eventData.communityDinner) {
    switch (virtualPolicy) {
      case 'mandatory':
        eventData.virtualDisabled = true;
        eventData.virtualHide = false;
        break;
      // case 'default-true':
      //   eventData.virtualDisabled = false;
      //   eventData.virtualHide = false;
      //   break;
      // case 'default-false':
      //   eventData.virtualDisabled = false;
      //   eventData.virtualHide = false;
      //   break;
      case 'disabled':
        eventData.virtualHide = true;
        break;
      default:
    }
  } else {
    eventData.virtual = false;
    eventData.virtualHide = true;
  }

  if (hideVirtualFields) {
    eventData.virtualHide = true;
  }

  eventData.disabledEventTypes =
    eventData.eventType === 'SOLO' ? ['PUBLIC', 'MANUAL_APPROVAL', 'PRIVATE'] : ['SOLO'];

  if (eventData.eventType === 'SOLO') {
    Object.assign(eventData, fieldsConfig.SOLO.enabled);
  }
  eventData.recurrences = data.recurrences;
  return eventData;
};

export {
  eventDataDefaultState,
  buildEventDataDefaultState,
  validateEventData,
  formatEventDataForSubmit,
  buildEventUpdateState
};
