// const testTemplate = `
//     <p class="h2">How do you want your physical space to feel?</p>
//     [[IF q924="traditional"]]<p class="h1">Traditional</p><p>I wanna play this straight and make my parents proud.</p>[[/IF]]
//     [[IF q924="relaxed"]]<p class="h1">Relaxed</p><p>You just want to take it easy, shabbat and chill.</p>[[/IF]]
//     [[IF q924="lively"]]<p class="h1">Lively</p><p>Excepteur sint occaecat non proident sunt in culpa qui officia.</p>[[/IF]]
//     <div>{{q924}}</div>
// `;

const templates = {
  getTemplate: pageData => {
    const nodes = [];
    const questions = [];
    if (!pageData) return { nodes, questions };

    const { template: pageTemplate, backgroundImages, widgets } = pageData;
    const template = pageTemplate.replace(/ {2,}|\n|\r/gmi, '');
    // const template = testTemplate.replace(/ {2,}|\n|\r/gmi, '');

    const conditionExpressionRegex = new RegExp(/\[\[IF(.*?)\/IF\]\]/);
    const conditionRegex = new RegExp(/q\d{1,}\s{0,}=\s{0,}"(.*?)"/gm);
    const conditionals = [];
    let templateWithoutConditionals = template;
    let conditionalIndex = 0;

    const getNodes = (children) => {
      const childNodes = [];

      children.forEach(child => {
        const leftTextParts = child && child.innerHTML.toString().split('{{q');
        const qs = [];

        const text = leftTextParts ? leftTextParts.map((part, i) => {
          if (!i) return part;
          const rightTextParts = part.split('}}');
          qs.push(parseInt(rightTextParts[0], 10));
          return rightTextParts[1];
        }) : '';

        /*eslint no-use-before-define: ["error", { "variables": false }]*/
        childNodes.push({
          type: child.nodeName.toLowerCase(),
          text,
          questions: qs,
          conditional: getConditional(child),
          className: child.className
        })
      });

      return childNodes;
    };

    const getConditional = child => {
      if (typeof child.dataset.condition === 'undefined') return false;

      const conditionHtml = conditionals[conditionalIndex];
      const condition = conditionHtml.match(conditionRegex);
      const conditionQuestion = condition ? condition[0].match(/q\d{1,}/)[0] : false;
      const conditionMatches = condition ? condition[0].match(/"(.*?)"/)[0] : false;

      conditionalIndex++;

      const element = document.createElement('div');
      element.innerHTML = conditionHtml;
      const children = Array.prototype.slice.call(element.children);
      const childNodes = getNodes(children);

      return {
        nodes: childNodes,
        condition: {
          question: conditionQuestion,
          matches: conditionMatches ? conditionMatches.replace(/"/g, '') : false,
        },
        elements: children
      };
    };

    // save / replace conditionals with an element that can be matched later //
    while (conditionExpressionRegex.test(templateWithoutConditionals)) {
      const conditional = templateWithoutConditionals.match(conditionExpressionRegex);
      conditionals.push(conditional[0]);
      templateWithoutConditionals = templateWithoutConditionals.replace(
        conditionExpressionRegex, '<div data-condition></div>'
      );
    }

    const div = document.createElement('div');
    div.innerHTML = templateWithoutConditionals;
    const children = Array.prototype.slice.call(div.children);

    return {
      nodes: getNodes(children),
      widgets: widgets.nodes,
      backgroundImages: backgroundImages,
      pageId: pageData.id,
      renderTemplate: pageData.renderTemplate,
      action: pageData.action
    };
  },

  getCurrentQuestionIds: (nodes, shouldBeShown) => {
    let questionIds = [];

    const assembleQuestionIds = questionNodes => {
      questionNodes.forEach(node => {
        const { questions, conditional } = node;

        if (!conditional) {
          questionIds = questionIds.concat(questions)
        } else if (shouldBeShown(node)) {
          assembleQuestionIds(conditional.nodes);
        }
      });
    };

    assembleQuestionIds(nodes);

    return questionIds;
  }
};

const {
  getTemplate,
  getCurrentQuestionIds
} = templates;

export {
  templates,
  getTemplate,
  getCurrentQuestionIds
};
