import React from 'react';

import { InputField } from './';

export const CreditCardField = ({ onChange, mask, ...rest }) => {
  const handleNumberMask = (name, value) => {
    const cardValue = value
      .replace(/\D/g, '')
      .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
    const inputValue = !cardValue[2]
      ? cardValue[1]
      : `${cardValue[1]} ${cardValue[2]}${`${
          cardValue[3] ? ` ${cardValue[3]}` : ''
        }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ''}`}`;
    // const numbers = inputValue.replace(/(\D)/g, '');
    onChange(name, inputValue);
  };

  const handleExpMask = (name, value) => {
    const expValue = value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,2})/);
      const inputValue = !expValue[2]
        ? (expValue[1].length === 2 ? (
          rest.value.length > 2 ? expValue[1] : `${expValue[1]}/`
          ) : expValue[1])
        : `${expValue[1]}/${expValue[2]}`;
    onChange(name, inputValue);
  };

  const handleCVVMask = (name, value) => {
    const cvvValue = value
      .replace(/\D/g, '')
      .match(/(\d{0,3})/);
    onChange(name, cvvValue[0]);
  };

  let handleChange;

  switch (mask) {
    case 'exp':
      handleChange = handleExpMask;
      break;
    case 'cvv':
      handleChange = handleCVVMask;
      break;
    default:
      handleChange = handleNumberMask;
  }

  return (
    <InputField
      {...rest}
      onChange={handleChange}
      type="text"
    />
  );
};
