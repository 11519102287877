import React, { useEffect, useRef, useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../styles';

import { SessionContext } from '../../Context';

import {
  JourneyResourcesButtons,
  JourneyResourcesList,
  ShareResourcesModal,
  withSettings
} from '../components';

import * as userJourney from '../../libs/userJourney';

// import { TweenMax, Power3 } from 'gsap';


const defaultResources = [
  {
    id: 1,
    image: { originalUrl: 'https://d3193cnaskkq8i.cloudfront.net/stock/resource/photo/13/Resource_Photos_for_UJ__3_.png' },
    title: 'OneTable Shabbat Guide',
    url: 'https://issuu.com/onetableshabbat/docs/ultimate_shabbat_guide?e=33133766/60166643'
  }, {
    id: 2,
    image: { originalUrl: 'https://d3193cnaskkq8i.cloudfront.net/stock/resource/photo/28/Black_Voices.png' },
    title: 'Black Voices Around the Shabbat Table',
    url: 'https://bit.ly/31rPDvF'
  }
];

const defaultVideos = [
  {
    id: 3,
    image: { originalUrl: 'https://d3193cnaskkq8i.cloudfront.net/stock/resource/photo/5/Screen_Shot_2021-02-07_at_8.44.12_PM.png' },
    title: 'PAUSE | Breathing in 2021',
    url: 'https://www.youtube.com/embed/gZUu-1qKCP8'
  }
];


const JourneyResourcesPage = ({
  children,
  className,
  resources: journeyResources,
  choices: { /*playlistUrl,*/ quote },
  bgImage,
  journeyAction,
  handleRedirect,
  handleCreationRedirect,
  eventUuid,
  eventId,
  eventData
}) => {
  const { session: { viewer } } = useContext(SessionContext);
  const userName = viewer && viewer.user && viewer.user.profile && viewer.user.profile.firstName;
  const madlibUserName = userJourney.getItem('name');
  const name = userName || madlibUserName;
  const contentRef = useRef(null);
  const appButtonContainerRef = useRef(null);
  const resourcesContainerRef = useRef(null);
  const videoContainerRef = useRef(null);

  const resources = journeyResources.resources;
  const videos = journeyResources.videos;
  const playlists = journeyResources.playlists;

  const [showShareModal, setShowShareModal] = useState(false);
  const toggleShareModal = () => setShowShareModal(prev => !prev);

  useEffect(() => {
    const iframe = document.querySelector('iframe.fixed');

    if (contentRef.current) {
      // TweenMax.to(contentRef.current, .8, {
      //   opacity: 1,
      //   top: -5,
      //   delay: 1.4,
      //   ease: Power3.easeOut
      // });

      appButtonContainerRef.current = contentRef.current.querySelector('.app_buttons');
      resourcesContainerRef.current = contentRef.current.querySelector('.resources');

      if (iframe) {
        setTimeout(() => {
          iframe.style.right = 'auto';
          iframe.style.top = 'auto';
          iframe.style.left = '7%';
          iframe.style.bottom = '50px';
        }, 1300);
      }
    }
  }, [contentRef, appButtonContainerRef, resourcesContainerRef, videoContainerRef]);

  const classNames = [className];
  if (journeyAction) classNames.push(journeyAction.replace(/landing/, 'find'));
  else classNames.push('landing');

  // temp fallback for events already created //
  const resourceList = !resources.length && !videos.length && !playlists.length
    ? defaultResources
    : resources;
  const videoList = !resources.length && !videos.length && !playlists.length
    ? defaultVideos
    : videos;

  return (
    <div className={classNames.join(' ')} ref={contentRef}>
      <div className="main_panel" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="main_panel__container">
          {name && <p className="h1">{name},</p>}
          {children}
          <JourneyResourcesButtons
            handleRedirect={handleRedirect}
            handleCreationRedirect={handleCreationRedirect}
            journeyAction={journeyAction}
            eventUuid={eventUuid}
            eventId={eventId}
            toggleShareModal={toggleShareModal}
          />
        </div>
        <i className="arrow" />
      </div>
      <div className="resource_panel">
        <JourneyResourcesList
          quote={quote}
          resources={resourceList}
          videos={videoList}
          playlists={playlists}
        />
      </div>
      <ShareResourcesModal
        show={showShareModal}
        toggle={toggleShareModal}
        event={eventData && eventData.eventByUuid}
        hasReservations={eventData && eventData.eventByUuid && eventData.eventByUuid.reservations.guestsAccepted > 0}
      />
    </div>
  );
};

const bounce = keyframes`
  0%, 14%, 28%, 100% {
    transform: translateY(0);
  }
  7% {
    transform: translateY(-25px);
  }
  21% {
    transform: translateY(-13px);
  }
`;

const StyledJourneyResourcesPage = styled(JourneyResourcesPage)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 0;
  margin: 0;
  // opacity: 0;
  // top: 5px;
  color: #fff;

  ${media.journeySm`
    max-width: 100%;
  `}

  .main_panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 66.6666%;
    background-size: cover;
    background-position: center;
    padding: 60px 20px 20px;

    ${media.journeyMd`
      position: relative;
      width: 100%;
      padding: 130px 20px 50px;
    `}

    &__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 80%;

      @media (max-height: 750px) {
        top: 47%;
      }

      ${media.journeyMd`
        position: static;
        transform: translate(0, 0);
        max-width: 100%;
      `}
    }

    .arrow {
      position: fixed;
      right: 50px;
      bottom: 30px;
      width: 2px;
      height: 55px;
      background-color: #fff;
      z-index: 100;
      animation: ${bounce} 3.5s infinite;

      ${media.journeyMd`
        position: absolute;
        bottom: 25px;
        right: 40px;
        height: 40px;
      `}

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 33px;
        height: 2px;
        background-color: #fff;
        transform-origin: left;

        ${media.journeyMd`
          width: 25px;
        `}
      }

      &::before {
        transform: rotate(-45deg);
        left: 0;
      }
      &::after {
        transform: rotate(225deg);
        left: 2px;
      }
    }
  }

  .resource_panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 33.3333%;
    overflow-y: scroll;

    ${media.journeyMd`
      position: static;
      width: 100%;
      overflow-y: visible;
      height: auto;
    `}
  }


  p {
    // font-size: 17px;
    span {
      color #fff;
      font-family: 'Avenir-Light' !important;
      letter-spacing: -0.025em;

      strong {
        font-family: 'Avenir-Bold' !important;
      }
    }

    &.h1 {
      font-family: 'Avenir-Bold' !important;
      letter-spacing: -0.025em;
      color: #fff;
      font-size: 85px;
      margin: 0;

      ${media.journeyLg`
        font-size: 65px;
      `}

      ${media.journeyMd`
        font-size: 50px;
      `}
    }

    &.h2 {
      font-size: 32px;
      margin: 40px 0 0;
      display: none;

      @media (max-height: 750px) {
        margin: 30px 0 0;
      }

      ${media.journeyLg`
        font-size: 28px;
      `}

      ${media.journeyMd`
        font-size: 26px;
      `}
    }

    &.h3 {
      font-size: 24px;
      margin: 30px 0 0;
      display: none;

      ${media.journeyLg`
        font-size: 20px;
      `}

      ${media.journeyMd`
        font-size: 18px;
      `}
    }
  }

  &.host {
    p.h2.host,
    p.h3.host {
      display: block;
    }
  }

  &.share {
    p.h2.share,
    p.h3.share {
      display: block;
    }
  }

  &.find {
    p.h2.find,
    p.h3.find {
      display: block;
    }
  }

  &.landing {
    p.h2.landing,
    p.h3.landing {
      display: block;
    }
  }

  &.non-demo {
    p.h2.non-demo,
    p.h3.non-demo {
      display: block;
    }
  }
`;
const JourneyResourcesPageWithSettings = withSettings(StyledJourneyResourcesPage);
export { JourneyResourcesPageWithSettings as JourneyResourcesPage };
