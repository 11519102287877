import React, { useState } from 'react';
import styled from 'styled-components';

import {
  InputField,
  FieldErrorMessage as ErrorMessage,
  withSettings
} from '../../components';


const FirstLastNameField = ({
  fieldNames = { first: 'firstName', last: 'lastName' },
  value = { firstName: '', lastName: '' },
  placeholders = { first: 'First name', last: 'Last name' },
  valid = { firstName: true, lastName: true },
  errorMessages = { firstName: 'Enter your first name.', lastName: 'Enter your last name.' },
  onChange,
  className
}) => {
  const [focused, setFocused] = useState(false);

  const classNames = [className];
  if (!valid.firstName || !valid.lastName) classNames.push('invalid');

  const borderClassNames = ['input_border'];
  focused && borderClassNames.push('focused');

  return (
    <div className={classNames.join(' ')}>
      <div className={borderClassNames.join(' ')}>
        <InputField
          name={fieldNames.first}
          value={value.firstName}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={placeholders.first}
        />
        <InputField
          name={fieldNames.last}
          value={value.lastName}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={placeholders.last}
        />
      </div>
      <ErrorMessages>
        <div>{!valid.firstName && <ErrorMessage>{errorMessages.firstName}</ErrorMessage>}</div>
        <div>{!valid.lastName && <ErrorMessage>{errorMessages.lastName}</ErrorMessage>}</div>
      </ErrorMessages>
    </div>
  );
};

const ErrorMessages = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StyledFirstLastNameField = styled(FirstLastNameField)`
  .input_border {
    display: flex;
    flex-direction: row;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 12px;
      bottom: 12px;
      left: 50%;
      width: 1px;
      background-color: #deddda;
      z-index: 1;
    }

    & > div {
      width: 50%;
    }

    input {
      border: none !important;
    }
  }

  &.invalid {
    .input_border,
    .input_border:hover,
    .input_border.focus {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      margin-bottom: 0;
    }
  }
`;
const FirstLastNameFieldWithSettings = withSettings(StyledFirstLastNameField);
export { FirstLastNameFieldWithSettings as FirstLastNameField };
