import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  LandingPageContainer
} from '../components';

const Button = styled.button`
  margin: 0.8rem 0 0 0;
  font-family: 'Avenir-Medium' !important;
  font-size: 15px;
  appearance: none;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 15px 14px 14px;
  color: #fff;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 250ms;

  &:hover {
    background-color: #ffffff20;
  }
`;

const Link = styled.span`
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  margin: 0.8rem 0 0 20px;
  line-height: 1;
  color: #fff;
  cursor: pointer;
  transition: opacity 250ms;

  &:hover {
    /* text-decoration: underline; */
    opacity: 0.7;
  }
`;

const LandingBanner = ({
  title,
  content,
  backgroundImageUrl,
  links = [],
  history
}) => (
  <LandingPageContainer>
    <Banner image={backgroundImageUrl}>
      <div>
        {title && <H2>{title}</H2>}
        {content && <P dangerouslySetInnerHTML={{ __html: content }} />}
        <div>
          {links.map(({ id, url, label, style }) => {
            const ActionLink = style === 'LINK' ? Link : Button;
            return <ActionLink key={id} onClick={() => history.push(url)}>{label}</ActionLink>
          })}
        </div>
      </div>
    </Banner>
  </LandingPageContainer>
);

const Banner = styled.div`
  background-color: #545454;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);

  ${({ image }) => image && css`
    background-image: url(${image});
    background-position: center;
    background-size: cover;
  `}

  &::before {
    content: '';
    display: block;
    padding-top: 23%;
    min-height: 275px;

    @media (max-width: 700px) {
      display: none;
    }
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 3rem;

    @media (max-width: 700px) {
      position: static;
      padding: 3rem;
      min-height: 275px;
    }

    @media (max-width: 650px) {
      padding: 4rem 20px;
    }
  }
`;

const H2 = styled.h2`
  font-family: 'Avenir-Regular' !important;
  font-weight: 400 !important;
  font-size: 28px;
  margin: 0 0 0.8rem;
  color: #fff;

  @media (max-width: 650px) {
    font-size: 26px;
  }
`;

const P = styled.p`
  color: #fff;
  margin: 0.5rem 0;
  font-size: 15px;
  opacity: 0.8;
  max-width: 400px;
`;

const LandingBannerWithRouter = withRouter(LandingBanner);
export { LandingBannerWithRouter as LandingBanner };
