import {
  invalidateDateTime30Hours,
  getEventDeadlineAt,
  getEventEndingTime,
  stringInsert,
  formatters,
  isSite,
  stripHtml,
  isVaccinated
} from '../libs';

import { isValidEmail } from './validations';
import { EVENT_TYPE } from '../enums';
import { RECURRANCE } from '../constants/recurrances';

import moment from 'moment-timezone';

const validations = [
  {
    key: 'eventType',
    validationFunction: (value, copy) => {
      const validEventTypes = ['PUBLIC', 'MANUAL_APPROVAL', 'PRIVATE', 'SOLO'];
      if (!validEventTypes.includes(value)) {
        return { name: 'eventType', error: copy.create.invalid_dinner_type || 'Invalid dinner type' };
      }
    }
  }, {
    key: 'eventSubtype',
    needsCustomValidation: true,
    validationFunction: (value, copy, event, settings) => {
      if (isSite(settings, 'hillel') && value.eventSubtype === '') {
        return { name: 'eventSubtype', error: copy.create.invalid_subtype || 'Invalid sub type' };
      }
    }
  }, {
    key: 'eventSubtypeOther',
    needsCustomValidation: true,
    validationFunction: (value, copy, event, settings) => {
      if (isSite(settings, 'hillel') && value.eventSubtypeHasOther && value.eventSubtypeOther === '') {
        return { name: 'eventSubtypeOther', error: copy.create.invalid_subtype_other || 'Invalid other sub type' };
      }
    }
  }, {
    key: 'privacySubtype',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings, viewer, isUpdate) => {
      if (data.eventType === EVENT_TYPE.SOLO || !data.date) return;

      if (!data.privacySubtype && settings.features.privacy_subtypes) {
        return { name: 'privacySubtype', error: copy.create.invalid_privacy_subtype || 'Invalid gathering type' };
      }

      // validate host has vaccination date //
      if (
        data.privacySubtype && data.privacySubtype.vaccinated &&
        viewer && viewer.profile && !viewer.profile.vaccinationDate
      ) {
        return { name: 'privacySubtype', error: 'Need to add vaccination date.', requiresProfileUpdate: true };
      }

      // validate hosts vaccination date is at least 2 weeks before event date //
      if (
        data.privacySubtype && data.privacySubtype.vaccinated &&
        viewer && viewer.profile && !isVaccinated(data, viewer.profile.vaccinationDate)
      ) {
        return { name: 'privacySubtype', error: 'date', showVaccinationError: true };
      }

      // dont turn non-vaccinated events into vaccinated events //
      if (
        isUpdate && event.privacySubtype && !event.privacySubtype.vaccinated &&
        data.privacySubtype && data.privacySubtype.vaccinated
      ) {
        return { name: 'privacySubtype', error: 'change', showVaccinationError: true };
      }
    }
  }, {
    key: 'title',
    needsCustomValidation: true,
    validationFunction: (value = {}, copy, event, settings) => {
      const { event: { title_max_length, title_min_length } } = settings;
      const { title } = value;

      if (!title || typeof title !== 'string' || title.trim().length === 0) {
        return { name: 'title', error: copy.create.invalid_dinner };
      }
      if (title.length > title_max_length) {
        return { name: 'title', error: `Title must be ${title_max_length} characters or less.` };
      }
      if (title.length < title_min_length) {
        return { name: 'title', error: `Title must be at least ${title_min_length} characters.` };
      }
    }
  }, {
    key: 'numberOfGuestsMax',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { virtual } } = settings;
      const isCommunityDinner = data.communityDinner && data.communityDinner === true;
      const isSolo = data.eventType === EVENT_TYPE.SOLO;
      const { eventType, privacySubtype, area, numberOfGuestsMax } = data;
      const value = Number(numberOfGuestsMax);

        const policyEventType = eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : eventType.toLowerCase();
        const policyName = `${policyEventType}NonVirtualMaxSeatsCap`;
        const maxGuestCap = privacySubtype && privacySubtype.socialDistancePolicy
          ? privacySubtype.socialDistancePolicy[policyName]
          : (area && area.socialDistancePolicy
            ? area.socialDistancePolicy[policyName]
            : (
              eventType && parseInt(virtual[`${eventType.toLowerCase()}_non_virtual_max_seats_cap`], 10)
            ));

      const reservationCount = event && event.reservations && event.reservations.guestsAccepted;

      if (data && event && parseInt(data.numberOfGuestsMax) < parseInt(event.numberOfGuestsMax)) {
        return { name: 'numberOfGuestsMax', error: 'numberOfGuestsMax_decrease', showSubmitWarning: true  };
      } else if (data && event && parseInt(data.numberOfGuestsMax) > parseInt(event.numberOfGuestsMax)) {
        return { name: 'numberOfGuestsMax', error: 'numberOfGuestsMax_increase', showSubmitWarning: true  };
      }

      if (
        (value === null || value < 0) ||
        (!isCommunityDinner && !isSolo && value < 1)
      ) {
        return { name: 'numberOfGuestsMax', error: copy.create.invalid_maximum_seats };

      } else if (!data.virtual && numberOfGuestsMax && maxGuestCap) {

        // max guest field is over guest cap //
        if (numberOfGuestsMax > maxGuestCap) {
          return {
            name: 'numberOfGuestsMax',
            error: stringInsert(copy.create.guest_count_too_high, maxGuestCap)
          };
          // reservation count is over guest cap //
        } else if (reservationCount && reservationCount > maxGuestCap) {
          return {
            name: 'numberOfGuestsMax',
            error: stringInsert(copy.create.reservation_count_too_high, maxGuestCap)
          };
        }
      }
    }
  }, {
    key: 'pwywMinimum',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { pwyw_minimum } } = settings;
      const pwywMinimum = pwyw_minimum ? pwyw_minimum / 100 : 0;

      if (data.pwyw && data.pwywMinimum < pwywMinimum) {
        return { name: 'pwywMinimum', error: copy.create.invalid_pwyw_minimum || `Minimum cost must be greater than $${pwywMinimum}.` };
      }
    }
  }, {
    key: 'partnerOrganizationName',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const isCommunityDinner = data.communityDinner && data.communityDinner === true;
      const value = data.partnerOrganizationName;

      if (isCommunityDinner) {
        if (!value || typeof value !== 'string' || value.trim().length === 0) {
          return { name: 'partnerOrganizationName', error: copy.create.invalid_partner_organization };
        }
      }
    }
  }, {
    key: 'date',
    validationFunction: (value, copy) => {
      const isDateBefore30Hours = invalidateDateTime30Hours(value);
      if (!value || isDateBefore30Hours) {
        return { name: 'date', error: copy.create.invalid_date };
      }
    }
  }, {
    key: 'startTime',
    needsCustomValidation: true,
    validationFunction: ({ startTime, endTime }, copy) => {
      if (!startTime) return { name: 'startTime', error: copy.create.invalid_start_time };
      if (startTime > endTime) return { name: 'startTime', error: 'Start time must be before end time.' };
    }
  }, {
    key: 'endTime',
    needsCustomValidation: true,
    validationFunction: ({ startTime, endTime }, copy) => {
      if (!endTime) return { name: 'endTime', error: copy.create.invalid_end_time };
      if (endTime < startTime) return { name: 'endTime', error: 'End time must be after start time.' };
    }
  }, {
    key: 'rsvpDeadline',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const { rsvpDeadline, rsvpDeadlineDate, rsvpDeadlineTime, date, endTime } = data;
      const deadline = rsvpDeadline
        ? getEventDeadlineAt(rsvpDeadlineDate, rsvpDeadlineTime)
        : false;
      const eventEndTime = getEventEndingTime(date, endTime);

      if (rsvpDeadline && eventEndTime < deadline) {
        return { name: 'rsvpDeadline', error: copy.create.invalid_rsvp_deadline };
      }
    }
  }, {
    key: 'rsvpDeadlineDate',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const { rsvpDeadline, rsvpDeadlineDate, date } = data;
      const isRsvpDeadlineOn = rsvpDeadline && rsvpDeadline === true;

      const deadlineDate = moment(rsvpDeadlineDate).format('YYYY-MM-DD');
      const eventDate = moment(date).format('YYYY-MM-DD');

      if (isRsvpDeadlineOn && (!deadlineDate || eventDate < deadlineDate)) {
        return { name: 'rsvpDeadlineDate', error: copy.create.invalid_rsvp_deadline_date };
      }
    }
  }, {
    key: 'rsvpDeadlineTime',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const { rsvpDeadline, rsvpDeadlineDate, rsvpDeadlineTime, date, endTime } = data;
      const isRsvpDeadlineOn = rsvpDeadline && rsvpDeadline === true;

      if (isRsvpDeadlineOn) {

        if (!data.rsvpDeadlineDate || !data.rsvpDeadlineTime) {
          return { name: 'rsvpDeadlineTime', error: copy.create.invalid_rsvp_deadline_time };
        }

        const deadline = rsvpDeadline
          ? getEventDeadlineAt(rsvpDeadlineDate, rsvpDeadlineTime)
          : false;
        const eventEndTime = getEventEndingTime(date, endTime);

        if (eventEndTime < deadline) {
          return { name: 'rsvpDeadlineTime', error: copy.create.invalid_rsvp_deadline_time };
        }
      }
    }
  }, {
    key: 'cohosts',
    validationFunction: (value, copy) => {
      if (!Array.isArray(value)) {
        return { name: 'cohosts', error: copy.create.invalid_cohosts };
      }

      const invalidCohosts = value.filter(cohost => {
        return !cohost || !isValidEmail(cohost);
      });

      if (invalidCohosts.length > 0) {
        return { name: 'cohosts', error: copy.create.invalid_cohost };
      }
    }
  }, {
    key: 'paid',
    validationFunction: (value, copy) => {
      if (value !== true && value !== false) {
        return { name: 'paid', error: copy.create.invalid_paid_value };
      }
    }
  }, {
    key: 'price',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { features: { pwyw } } = settings;
      const value = data.price;

      if (data.paid && !pwyw && (isNaN(value) || Number(value) <= 0)) {
        return { name: 'price', error: copy.create.invalid_dinner_cost };
      }
    }
  }, {
    key: 'hostAbsorbsTransactionFee',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const isPaidDinner = data.paid && data.paid === true;
      const value = data.hostAbsorbsTransactionFee;

      if (isPaidDinner && (value !== true && value !== false)) {
        return { name: 'hostAbsorbsTransactionFee', error: copy.create.invalid_transaction_fee };
      }
    }
  }, {
    key: 'area',
    validationFunction: (value, copy) => {
      if (!value || value.id == null) {
        return { name: 'area', error: copy.create.invalid_city };
      }
    }
  }, {
    key: 'areaOther',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      if(data?.area?.code !== 'OTHER'){
        return;
      }

      const { areaOther } = data;
      if (!areaOther?.trim()) {
        return { name: 'areaOther', error: copy.create.invalid_other_city ?? copy.create.invalid_city };
      }
    }
  }, {
    key: 'stateLocation',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { require_state_location_for_other_areas } } = settings;
      if (
        require_state_location_for_other_areas && data.area && !data.area.timezone && !data.stateLocation
      ) {
        return { name: 'stateLocation', error: copy.create.invalid_state_location || 'You must select a state.' };
      }
    }
  }, {
    key: 'neighborhood',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { mandatory_neighborhood }, features: { enable_area_user_connection } } = settings;
      const isSiteWithoutSubNeighborhood = isSite(settings, ['hillel.sandbox', 'hillel.michigan', 'hillel.international'], true);
      const errorMessage = isSite(settings, 'hineni') ? 'You must choose a gathering type.' : `You must choose a ${isSiteWithoutSubNeighborhood ? 'campus': 'neighborhood'}.`;
      if (
        data.area && data.area.neighborhoods && data.area.neighborhoods.length && !data.neighborhood &&
        !isSite(settings, 'signaturefd') &&
        mandatory_neighborhood &&
        !enable_area_user_connection
      ) {
        return { name: 'neighborhood', error: errorMessage };
      }
    }
  }, {
    key: 'campus',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { mandatory_neighborhood }, features: { enable_area_user_connection } } = settings;
      const isSiteWithoutSubNeighborhood = isSite(settings, ['hillel.sandbox', 'hillel.michigan', 'hillel.international'], true);
      const errorMessage = isSite(settings, 'hineni') ? 'You must choose a gathering type.' : `You must choose a ${isSiteWithoutSubNeighborhood ? 'campus': 'neighborhood'}.`;
      if (
        data.area?.campuses && data.area.campuses.length && !data.campus &&
        !isSite(settings, 'signaturefd') &&
        mandatory_neighborhood &&
        enable_area_user_connection
      ) {
        return { name: 'campus', error: errorMessage };
      }
    }
  }, {
    key: 'description',
    needsCustomValidation: true,
    validationFunction: (value, copy) => {
      const minLength = (value && value.minDescriptionLength) ? value.minDescriptionLength : 150;
      const maxLength = (value && value.maxDescriptionLength) ? value.maxDescriptionLength : null;

      const descripion = stripHtml(value.description.trim());

      if (!value.description || typeof value.description !== 'string') {
        return {
          name: 'description',
          error: formatters.stringInsert(copy.create.invalid_description, minLength)
        };
      } else {
        if (maxLength && descripion.length > maxLength) {
          return {
            name: 'description',
            error: `Description must be less than ${maxLength} characters`
          };
        } else if (descripion.length < minLength) {
          return {
            name: 'description',
            error: formatters.stringInsert(copy.create.invalid_description, minLength)
          };
        }
      }
    }
  }, {
    key: 'tables',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const isCommunityDinner = data.communityDinner && data.communityDinner === true;
      const hasTables = data.hasTables && data.hasTables === true;

      if (isCommunityDinner && hasTables) {
        if (!Array.isArray(data.tables) || data.tables.length === 0) {
          return { name: 'tables', error: copy.create.invalid_tables };
        }

        const invalidTables = data.tables.filter(table => (!table.name || !table.maxSeats || table.maxSeats <= 0));

        if (invalidTables.length > 0) {
          return { name: 'tables', error: copy.create.invalid_table };
        }
      }
    }
  }, {
    key: 'maxSeats',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      const isCommunityDinner = data.communityDinner && data.communityDinner === true;
      const hasTables = data.hasTables && data.hasTables === true;
      const maxSeats = Number(data.maxSeats);

      if (isCommunityDinner && !hasTables && (!maxSeats || maxSeats <= 0)) {
        return { name: 'numberOfGuestsMax', error: copy.create.invalid_maximum_seats };
      }
    }
  }, {
    key: 'potluckItems',
    needsCustomValidation: true,
    validationFunction: ({ potluck, potluckItems: items = [] }, copy, _, settings ) => {
      const { event: { potluck_no_item_error_message, potluck_invaid_item_error_message } } = copy;
      const { event: { require_potluck_items } } = settings;
      const hasItems = items.length;
      const hasInvalidItems = hasItems && !items.every(item => item.quantity);

      if (!potluck) return;

      if ((require_potluck_items && !hasItems) || hasInvalidItems) {
        return {
          name: 'potluckItems',
          error: items.length ? potluck_invaid_item_error_message : potluck_no_item_error_message
        };
      }
    }
  }, {
    key: 'reservationQuestions',
    needsCustomValidation: true,
    validationFunction: (data, copy) => {
      if (Array.isArray(data.reservationQuestions) && data.reservationQuestions.length) {
        const invalidQuestions = data.reservationQuestions.filter(q => {
          return !q ||
            // saved answer with either empty field //
            (q.id && (q.text === '' || q.answerType === '')) ||
            // unsaved question with only 1 empty field (both empty will be removed) //
            (!q.id && ((q.text === '' && q.answerType !== '') || (q.text !== '' && q.answerType === '')));
        });

        if (invalidQuestions.length > 0) {
          return { name: 'reservationQuestions', error: copy.create.invalid_reservation_question };
        }
      }
    }
  }, {
    key: 'virtualConfirmation',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      const { event: { virtual } } = settings;
      if (virtual.display_non_virtual_confirmation_checkbox && !data.virtual && !data.virtualConfirmation) {
        return {
          name: 'virtualConfirmation',
          error: `You must accept the non-virtual ${isSite(settings, 'thegathering') ? 'event' : 'dinner'} acknowledgement.`
        };
      }
    }
  }, {
    key: 'timezone',
    needsCustomValidation: true,
    validationFunction: (data) => {
      if (data.area && !data.area.timezone && !data.timezone && data.area.code !== 'OTHER') {
        return { name: 'timezone', error: 'You must choose a timezone.' };
      }
    }
  }, {
    key: 'areaOther',
    needsCustomValidation: true,
    validationFunction: (data, copy, event, settings) => {
      if (isSite(settings, ['hineni', 'whitelabel', 'limitedwhitelabel'])) {
        if (!data.virtual && !data.areaOther) {
          return { name: 'areaOther', error: 'You must choose a city.' };
        }
      } else if (!isSite(settings, ['climatefest', 'signaturefd'])) { // no (other) areas in climate fest, not required for signaturefd //
        if (data.area && !data.area.timezone && !data.virtual && !data.areaOther) {
          return { name: 'areaOther', error: 'You must choose a city.' };
        }
      }
    }
  }, {
    key: 'outdoors',
    needsCustomValidation: true,
    validationFunction: () => {
      // const { event: { outdoor_tag_as_checkbox } } = settings;
      // const isSolo = data.eventType === EVENT_TYPE.SOLO;
      // if (outdoor_tag_as_checkbox && !isSolo && !data.virtual && data.outdoors === '') {
      //   return { name: 'outdoors', error: copy.create.invalid_outdoors || 'Please indicate if your dinner will be outdoors.' };
      // }
    }
  }, {
    key: 'recurranceNumber',
    needsCustomValidation: true,
    validationFunction: (data) => {
      if (data.recurrance !== RECURRANCE.NONE && !data.recurranceNumber) {
        return { name: 'recurranceNumber', error: 'Enter a valid number of recurrences.' };
      }
    }
  }
];

export default {
  validateData: (data, settings, event, viewer, isUpdate) => {
    const { copy } = settings;
    let requiresProfileUpdate = null;
    let showVaccinationError = null;
    let showSubmitWarning = null;
    let action = '';

    const errors = validations.map(field => field.needsCustomValidation
      ? field.validationFunction(data, copy, event, settings, viewer, isUpdate)
      : field.validationFunction(data[field.key], copy)
    )
      .filter(e => e != null)
      .filter(e => {
        if (e.requiresProfileUpdate) {
          requiresProfileUpdate = e.requiresProfileUpdate;
          return false;
        }
        if (e.showVaccinationError) {
          showVaccinationError = e.showVaccinationError;
          action = e.error;
          return false;
        }
        if (e.showSubmitWarning) {
          showSubmitWarning = e.showSubmitWarning;
          action = e.error;
          return false;
        }
        return true;
      });

    return { errors, action, requiresProfileUpdate, showVaccinationError, showSubmitWarning };
  }
};
