import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const PageHeader = ({ shouldLink = true, onBackClick, heading, arrow = true, children }) => {
  const history = useHistory();

  const handleBackClick = () => {
    if (typeof onBackClick === 'function') onBackClick();
    else history.goBack();
  };
  return (
    <Header>
      {arrow && <BackButton onClick={() => shouldLink && handleBackClick()} shouldLink={shouldLink} />}
      <H1 hasArrow={arrow}>{heading}</H1>
      {children}
    </Header>
  );
}

const Header = styled.div`
  margin: 0 0 50px;
`;

const BackButton = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border: solid #97958f;
  border-width: 0 0 2px 2px;
  transform: rotate(45deg);

  ${({ shouldLink }) => shouldLink && css`
    cursor: pointer;
    transition: border-color 250ms;

    &:hover {
      border-color: #000;
    }
  `}
`;

const H1 = styled.h1`
  display: inline-block;
  vertical-align: middle;
  color: #221f20;
  font-size: 36px;
  line-height: 1;
  margin: 0 30px 0 0;

  ${({ hasArrow }) => hasArrow && css`
    margin-left: 25px;
  `}
`;
