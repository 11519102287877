import React, { useState, useEffect } from 'react';
import { useCheckoutData } from '../../hooks';
import { on, off } from '../../libs/customEvent';
import { PaymentMethodsNew } from '../components';


export const RsvpPageNewPaymentMethod = ({ onCancel }) => {
  const { cardData: { newCard }, setCardData } = useCheckoutData();
  const [validFullName, setValidFullName] = useState(true);
  const [validCardNumber, setValidCardNumber] = useState(true);
  const [validCardExp, setValidCardExp] = useState(true);
  const [validCardCVV, setValidCardCVV] = useState(true);

  const invalidateField = ({ detail = {} }) => {
    switch (detail.field) {
      case 'fullName':
        setValidFullName(false)
        break;
      case 'cardNumber':
        setValidCardNumber(false);
        break;
      case 'cardExp':
        setValidCardExp(false);
        break;
      case 'cardCVV':
        setValidCardCVV(false);
        break;
      default:
    }
  };

  useEffect(() => {
    on('rsvpPage:validateNewCard', invalidateField);
    return () => off('rsvpPage:validateNewCard', invalidateField);
  }, []);

  // const updateNewCardField = (key, val) => setCardData('newCard', key, val);

  return (
    <PaymentMethodsNew
      data={{
        ...newCard,
        validFullName,
        validCardNumber,
        validCardExp,
        validCardCVV
      }}
      onUpdateField={(key, val) => setCardData('newCard', key, val)}
      onCancel={onCancel}
      setValidFullName={setValidFullName}
      setValidCardNumber={setValidCardNumber}
      setValidCardExp={setValidCardExp}
      setValidCardCVV={setValidCardCVV}
    />
  );
};

// <Container>
//   <InputField
//     type="text"
//     name="fullName"
//     value={newCard.fullName || ''}
//     placeholder={copy.profile.card_name_label}
//     onChange={(n, v) => {
//       updateNewCardField(n, v);
//       setValidFullName(true);
//     }}
//     icon={['far', 'user']}
//     valid={validFullName}
//     errorMessage={`Enter ${copy.profile.card_name_label.toLowerCase()}.`}
//   />
//   <CreditCardField
//     name="cardNumber"
//     value={newCard.cardNumber || ''}
//     placeholder={copy.profile.card_number_label}
//     onChange={(n, v) => {
//       updateNewCardField(n, v);
//       setValidCardNumber(true);
//     }}
//     icon={['far', 'credit-card']}
//     mask="card"
//     valid={validCardNumber}
//     errorMessage={`Enter valid ${copy.profile.card_number_label.toLowerCase()}.`}
//   />
//   <HalfContainer>
//     <CreditCardField
//       name="cardExp"
//       value={newCard.cardExp || ''}
//       placeholder={copy.profile.card_exp_placeholder || 'MM/YY'}
//       onChange={(n, v) => {
//         updateNewCardField(n, v);
//         setValidCardExp(true);
//       }}
//       icon={['far', 'calendar']}
//       mask="exp"
//       valid={validCardExp}
//       errorMessage="Enter valid expiration date."
//     />
//     <CreditCardField
//       name="cardCVV"
//       value={newCard.cardCVV || ''}
//       placeholder={copy.profile.card_cvv_placeholder}
//       onChange={(n, v) => {
//         updateNewCardField(n, v);
//         setValidCardCVV(true);
//       }}
//       icon={['far', 'lock']}
//       mask="cvv"
//       valid={validCardCVV}
//       errorMessage="Enter valid CVV."
//     />
//   </HalfContainer>
//   {onCancel && (
//     <Checkbox
//       name="favorite"
//       options={[
//         {
//           label: copy.profile.card_use_default,
//           checked: newCard.favorite || false
//         }
//       ]}
//       onInputChange={updateNewCardField}
//       small
//     />
//   )}
//   {onCancel && <Button onClick={onCancel}>Cancel</Button>}
// </Container>
