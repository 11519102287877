import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmail } from 'react-multi-email';

import {
  Modal,
  Button,
  UserEmailSelector,
  WYSIWYGEditor,
  alert
} from '../components';
import { withSettings } from '../components';

import { stringInsert } from '../../libs';

const ManageEventsDetailsInviteModal = ({
  className,
  event,
  invitations,
  settings: { copy },
  open,
  toggle,
  sendInvitations,
  refetchPendingInvitations
}) => {
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState('');
  const [sendingInvitations, setSendingInvitations] = useState(false);

  const handleEmailsChange = newEmails => setEmails(newEmails);

  const handleMessageChange = (name, value) => {
    if (name === 'message') { setMessage(value); }
  };

  const resetFields = () => {
    setEmails([]);
    setMessage('');
    setSendingInvitations(false);
  };

  const catchInvitationErrors = (graphQLError) => {
    const codeErrorMessages = {
      INVITED_GUEST_SAME_DAY: copy.rsvp.invited_guests_same_day
    }
    const codeErrorExtensions = graphQLError.graphQLErrors.map(error => error.extensions.code)
    if(!codeErrorExtensions.length) return alert.error(copy.manage.invite_failed_message);
    alert.error(codeErrorMessages[codeErrorExtensions]);
  }


  const handleSendInvitation = () => {
    const { rejected = [] } = invitations;
    const rejectedEmails = rejected.reduce((list, invite) => {
      const email = invite.node && invite.node.email;
      if (emails.includes(email)) return [...list, email];
      return list;
    }, []);
    const variables = {
      eventId: event.id,
      emails,
      text: message
    };

    if (rejectedEmails.length) {
      alert.error(stringInsert(copy.manage.invitation_already_sent_message, rejectedEmails[0]));
    } else {
      setSendingInvitations(true);

      sendInvitations({ variables })
        .then(() => {
          refetchPendingInvitations()
            .then(() => {
              resetFields();
              toggle(true);
              alert.success('Invitations sent');
            })
            .catch(err => {
              alert.error(err)
              resetFields();
              toggle(true);
            });
        })
        .catch(e => {
          setSendingInvitations(false);
          catchInvitationErrors(e)
        });
    }
  }

  return (
    <Modal size="md" show={open} toggle={toggle} noPadding>
      <div className={className}>
        <header>
          <title>{copy.manage.invite_guests}</title>
        </header>
        <UserEmailSelector
          name="emails"
          allowMultipleEmails={true}
          emails={emails}
          onAddEmail={value => handleEmailsChange(value)}
          onValidate={isEmail}
          errorMessage="Enter a valid email."
          placeholder="friend@domain.com"
        />
        <WYSIWYGEditor
          name="message"
          value={message}
          onInputChange={handleMessageChange}
          placeholder={copy.guest.description_placeholder}
        />
        <div className="actions">
          <Button
            disabled={emails.length < 1}
            loading={sendingInvitations}
            handleClick={() => { handleSendInvitation() }}
            fullWidth
          >Send Invite</Button>
        </div>
      </div>
    </Modal>
  );
};

const StyledManageEventsDetailsInviteModal = styled(ManageEventsDetailsInviteModal)`
  padding: 40px 30px 30px;

  header {
    text-align: center;
    margin-bottom: 30px !important;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
    }
  }

  label {
    margin-top: 20px;
  }

  .react-multi-email.focused {
    border-color: #8a8a8a;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledManageEventsDetailsInviteModalWithSettings = withSettings(StyledManageEventsDetailsInviteModal);

export { StyledManageEventsDetailsInviteModalWithSettings as ManageEventsDetailsInviteModal }
