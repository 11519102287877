import React from 'react';
import styled, { css } from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import { isWordpress } from '../../libs/wordpress';
import { withSession } from '../components';


const ModalWrapper = ({ className, children, show, toggle, size, backdrop, noPadding, closer = true }) => (
  <Modal
    className={className}
    isOpen={show}
    toggle={toggle}
    size={size}
    fade={!isWordpress()}
    backdrop={backdrop ? backdrop : true}
  >
    {closer && <i className="closer" onClick={toggle} />}
    <ModalBody className={noPadding ? 'no_padding' : ''}>
      {children}
    </ModalBody>
  </Modal>
);

ModalWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
  backdrop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['static'])
  ]),
  noPadding: PropTypes.bool
};

const StyledModal = styled(ModalWrapper)`
  transition: transform 150ms ease-out !important;

  .modal-body {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      padding: 3rem 4rem;
      text-align: center;
    ` : css`
      padding: 2rem 4rem 3rem 4rem;
    `}

    &.no_padding {
      padding: 0 !important;
    }
  }

  &.modal-xs {
    @media (min-width: 992px) {
      max-width: 280px;
    }
  }

  &.modal-sm {
    @media (min-width: 992px) {
      max-width: 400px;
    }
  }

  &.modal-md {
    @media (min-width: 992px) {
      max-width: 650px;
    }
  }

  .closer {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #666;
      transform-origin: center;
      position: absolute;
      top: 50%;
      left: 0;
      transition: background-color 250ms;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }
`;
const ModalWithSession = withSession(StyledModal);
export { ModalWithSession as Modal };
