import React from 'react';
import styled, { css } from 'styled-components';
import { TagsInput } from '../../components';
import { /*InputElement,*/ withSettings, withSession } from '../../components';
import { isValidEmail } from '../../../libs/validations';

class UserEmailSelector extends React.Component {
  state = {
    valid: true,
    focused: false,
    inputValue: ''
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleAddEmail = value => {
    const { onAddEmail, onValidate, name, allowMultipleEmails } = this.props;

    const validEmails = value.reduce((acc, tag) => {
      let tagEmails = tag.split(/,| /).map(d => d.trim());

      tagEmails = tagEmails.filter(email => email && isValidEmail(email));
      return [ ...acc, ...tagEmails];
    }, []);

    if (validEmails.length === 0) {
      onAddEmail([]);
      // this.setState({ valid: false });
      // onValidate(name, false);
    } else {
      const uniqueEmails = allowMultipleEmails ? Array.from(new Set(validEmails)) : [validEmails[0]];
      onAddEmail(uniqueEmails);
      this.setState({ valid: true });
      onValidate(name, true);
    }
  }

  handleOnInputChange = value => {
    const { inputValue } = this.state;
    const { onValidate, name } = this.props;

    this.setState({ inputValue: value }, () => {
      if (inputValue === '') {
        this.setState({ valid: true });
        onValidate(name, true);
      }
    })
  };

  handleValidationReject = () => {
    const { onValidate, name } = this.props;
    onValidate(name, false);
    this.setState({ valid: false });
  };

  handleOnFocus = () => {
    this.setState({ focused: true });
  };

  handleOnBlur = (value, addTag) => {
    if (value !== '') addTag(value);
    this.setState({ focused: false });
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  renderLayout = (tagComponents, inputComponent) => {
    return (
      <span>
        {inputComponent}
        {tagComponents}
      </span>
    );
  };

  renderInput = ({ onChange, value, addTag, ...rest }) => {
    const { placeholder } = this.props;

    // breaks el ref
    // const Input = theme.isVersion(2) ? InputElement : props => <input {...props} />;

    return (
      <input
        type='text'
        {...rest}
        onChange={onChange}
        value={value}
        onFocus={this.handleOnFocus}
        onBlur={() => this.handleOnBlur(value, addTag)}
        placeholder={placeholder ? placeholder : 'co-host@domain.com'}
      />
    );
  };

  render() {
    const { valid, focused, inputValue } = this.state;
    const { className, emails } = this.props;

    return (
      <div className={`
          ${className}
          ${!valid ? 'invalid' : ''}
          ${focused ? 'focused' : ''}
      `}>
        <div className="input_wrapper">
          <TagsInput
            value={emails}
            inputValue={inputValue}
            onChange={this.handleAddEmail}
            onChangeInput={this.handleOnInputChange}
            addKeys={[9, 13, 32, 188]}
            removeKeys={[]}
            onlyUnique={true}
            renderLayout={this.renderLayout}
            renderInput={this.renderInput}
          />
        </div>
        {!valid && <div className="validation_error">{this.getErrorMessage()}</div>}
      </div>
    );
  }
}

const StyledUserEmailSelector = styled(UserEmailSelector)`
  text-align: left;

  &.focused {
    .input_wrapper {
      ${({ session: { theme } }) => theme.isVersion(2) ? css`
        border-color: #000 !important;
        box-shadow: none !important;
      ` : css`
        border-color: #8a8a8a !important;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15) !important;
      `}
    }
  }

  input[type="text"] {
    &:focus {
      ${({ session: { theme } }) => theme.isVersion(2) && css`
        box-shadow: none !important;
      `}
    }
  }

  &.invalid {
    .input_wrapper {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 14px;
    }
  }

  .input_wrapper {
    margin: 0 0 14px;
    padding: 0;
    transition: border-color 250ms, box-shadow 250ms;

    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      border: 1px solid #ceccc7;
      border-radius: 5px;
      font-size: 15px;
      line-height: 1.8
      box-shadow: none !important;

      &:hover {
        border-color: #000;
      }
    ` : css`
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 14px;
    `}
  }

  input[type="text"] {
    border: 0 !important;
    box-shadow: none !important;
    margin: 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .react-tagsinput {
    &-tag {
      display: inline-block;
      margin: 0 0 6px 6px;
      border: 1px solid ${props => props.settings.theme.css.global.colors.tagBorder};
      border-radius: 3px;
      background: ${props => props.settings.theme.css.global.colors.tagBackground};

      ${({ session: { theme } }) => theme.isVersion(2) ? css`
        padding: 5px 10px;
        font-size: 13px;
      ` : css`
        padding: 3px 8px;
        font-size: 12px;
      `}
    }

    &-remove {
      &::after {
        content: '\f00d';
        font-family: 'FontAwesome';
        font-size: 11px;
        display: inline-block;
        margin-left: 7px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        cursor: pointer;

      }
    }
  }

  .validation_error {
    font-size: 11px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
`;

const StyledUserEmailSelectorWithSettings = withSettings(StyledUserEmailSelector);
const UserEmailSelectorWithSession = withSession(StyledUserEmailSelectorWithSettings);
export { UserEmailSelectorWithSession as UserEmailSelector };
