const getSettingsCookieDomain = settings => {
  return (settings && settings.env_vars && settings.env_vars.cookie_domain && settings.env_vars.cookie_domain.trim() !== '')
    ? settings.env_vars.cookie_domain.trim()
    : '';
  // TEMP : for accessing production locally //
  // return '';
}

const appendSettingsDomainToCookieParts = (settings, cookieParts) => {
  const settingsDomain = getSettingsCookieDomain(settings);

  // if (false && settingsDomain) {
  if (settingsDomain) {
    return [ ...cookieParts, `domain=${settingsDomain}` ];
  } else {
    return cookieParts;
  }
}

const getCookiesPrefix = settings => {
  if (!settings || !settings.env_vars || !settings.env_vars.cookie_prefix) {
    return '';
  }

  return settings.env_vars.cookie_prefix.trim();
}

const getAuthTokenCookieName = settings => {
  const cookiesPrefix = getCookiesPrefix(settings);
  return `${cookiesPrefix}auth_token`;
}

const tokenCookies = settings => {
  const authTokenCookieName = getAuthTokenCookieName(settings);
  const cookies = document.cookie.split(';').filter(item => item.trim().startsWith(`${authTokenCookieName}=`));
  return (cookies ? cookies : []);
}

const getToken = settings => {
  const authTokenCookies = tokenCookies(settings);

  if (authTokenCookies.length === 0) {
    return null;
  } else {
    return authTokenCookies[0].split('=')[1].trim();
  }
};

const setToken = (settings, token) => {
  const expDate = new Date();
  expDate.setDate(expDate.getDate() + 120);

  const authTokenCookieName = getAuthTokenCookieName(settings);

  let parts = [
    `${authTokenCookieName}=${encodeURIComponent(token)}`,
    'path=/',
    `expires=${expDate.toUTCString()}`,
    'SameSite=None',
    'Secure'
  ];

  parts = appendSettingsDomainToCookieParts(settings, parts);
  document.cookie = parts.join(';');
};

const refreshTokenExpiration = settings => {
  const token = getToken(settings);
  if (token) setToken(settings, token);
};

const getRelatedDomains = settingsDomain => {
  const settingsDomainWithoutInitialDot = settingsDomain.startsWith('.') ? settingsDomain.substring(1) : settingsDomain;

  if (settingsDomainWithoutInitialDot === 'onetable.org') {
    return [settingsDomainWithoutInitialDot, `.${settingsDomainWithoutInitialDot}`, `dinners.${settingsDomainWithoutInitialDot}`, ''];
  } else {
    return [settingsDomain, ''];
  }
}

const removeToken = settings => {
  const settingsDomain = getSettingsCookieDomain(settings);
  const relatedDomains = getRelatedDomains(settingsDomain);
  const paths = ['/', ''];
  const authTokenCookies = tokenCookies(settings);

  authTokenCookies.forEach(authCookie => {
    relatedDomains.forEach(domain => {
      paths.forEach(path => {
        let parts = [authCookie];

        if (path) { parts = [...parts, `path=${path}`]; }
        if (domain) { parts = [...parts, `domain=${domain}`]; }

        parts = [...parts, 'expires=Thu, 01 Jan 1970 00:00:01 GMT', 'SameSite=None', 'Secure'];
        document.cookie = parts.join(';');
      })
    });
  })
};

export { getToken, setToken, refreshTokenExpiration, removeToken };
