import React from 'react';
import { ApolloConsumer } from '@apollo/react-common';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { RsvpModalContainer } from './';
import { CREATE_RESERVATION, CREATE_CREDIT_CARD, UPDATE_PROFILE } from '../../mutations';

const RsvpModalContainerPage = (props) => {
  const {
    show,
    modalSelected,
    changeRsvpModalSelected,
    closeRsvpModalContainer,
    reservationCreated,
    event,
    areas,
    viewer,
    rsvpModals,
    setRsvpModals,
    refetchData,
    ...rest
  } = props;

  return (
    <ApolloConsumer>
      {client => (
        <Mutation mutation={UPDATE_PROFILE}>
          {(updateProfileMutation) => (
            <Mutation mutation={CREATE_CREDIT_CARD}>
              {(createCreditCardMutation) => (
                <Mutation mutation={CREATE_RESERVATION}>
                  {(createReservationMutation) => (
                    <RsvpModalContainer
                      show={show}
                      modalSelected={modalSelected}
                      changeRsvpModalSelected={changeRsvpModalSelected}
                      closeRsvpModalContainer={closeRsvpModalContainer}
                      reservationCreated={reservationCreated}
                      event={event}
                      areas={areas}
                      viewer={viewer}
                      createReservationMutation={createReservationMutation}
                      createCreditCardMutation={createCreditCardMutation}
                      updateProfileMutation={updateProfileMutation}
                      client={client}
                      rsvpModals={rsvpModals}
                      setRsvpModals={setRsvpModals}
                      refetchData={refetchData}
                      {...rest}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
}

const RsvpModalContainerPageWithRouter = withRouter(RsvpModalContainerPage);

export { RsvpModalContainerPageWithRouter as RsvpModalContainerPage };
