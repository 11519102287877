import React from 'react';
import styled from 'styled-components';
import { SwitchButtons } from '../Global/SwitchButtons';
import { InfoTooltip, withSettings } from '../components';
import { isRegularOneTable } from '../../libs';

const ProfileEditPrivacy = (props) => {
  const { className, handleSwitchChange, showAge, eventHostedVisible, eventAttendedVisible, showOnGuestList, showLanguageOnEvents } = props;
  const { settings: { copy } } = props;

  return (
    <div className={className}>
      {isRegularOneTable(props.settings) && (
        <div className="row">
          <div className="ot-third-container">
            <label className="ng-binding">{copy.profile.show_age}</label>
          </div>
          <div className="ot-twothirds-container">
            <SwitchButtons name="showAge" handleSwitchChange={handleSwitchChange} selectedOption={!!showAge}/>
          </div>
        </div>
      )}
      <div className="row">
        <div className="ot-third-container">
          <label className="ng-binding">{copy.profile.show_hosted}</label>
        </div>
        <div className="ot-twothirds-container">
          <SwitchButtons name="eventHostedVisible" handleSwitchChange={handleSwitchChange} selectedOption={!!eventHostedVisible}/>
        </div>
      </div>
      <div className="row">
        <div className="ot-third-container">
          <label className="ng-binding">{copy.profile.show_attended}</label>
        </div>
        <div className="ot-twothirds-container">
          <SwitchButtons name="eventAttendedVisible" handleSwitchChange={handleSwitchChange} selectedOption={!!eventAttendedVisible}/>
        </div>
      </div>
      <div className="row">
        <div className="ot-third-container">
          <label className="ng-binding">
            {copy.profile.show_on_guest_lists || 'Show profile in guest lists?'}
            <InfoTooltip
              className="transaction_info"
              content={copy.profile.show_on_guest_lists_tooltip}
              position="right"
            />
          </label>
        </div>
        <div className="ot-twothirds-container">
          <SwitchButtons name="showOnGuestList" handleSwitchChange={handleSwitchChange} selectedOption={!!showOnGuestList}/>
        </div>
      </div>

      {isRegularOneTable(props.settings) && (
        <div className="row">
          <div className="ot-third-container">
            <label className="ng-binding">
              {copy.profile.show_language_on_events || 'Show the language(s) I speak on my events?'}
              <InfoTooltip
                className="transaction_info"
                content={copy.profile.show_language_on_events_tooltip}
                position="right"
              />
            </label>
          </div>
          <div className="ot-twothirds-container">
            <SwitchButtons name="showLanguageOnEvents" handleSwitchChange={handleSwitchChange} selectedOption={!!showLanguageOnEvents}/>
          </div>
        </div>
      )}
    </div>
  );
}

const StyledProfileEditPrivacy = styled(ProfileEditPrivacy)`
  .transaction_info {
    margin-left: 8px;
  }

  .ot_tooltip {
    line-height: 1.3;
    text-align: center;
  }
`;

const StyledProfileEditPrivacyWithSettings = withSettings(StyledProfileEditPrivacy);

export { StyledProfileEditPrivacyWithSettings as ProfileEditPrivacy };
