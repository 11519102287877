import React from 'react';
import styled from 'styled-components';
import { Input, Button } from '../';

const CreditCardsList = (props) => {
  const { className, cards, changeCardClicked } = props;

  return (
    <div className={className}>
      {(cards ? cards : []).map((card) => {
        return (
          <div className="row" key={card.id}>
            <div className="ot-quarter-container">
              <label>{card.brand}</label>
              {card.favorite &&
                <label className="sub-label">Primary Card</label>
              }
            </div>
            <div className="ot-half-container">
              <Input type="text" name="cardNumber" placeholder="Card Number" value={`**** **** **** ${card.number}`} onInputChange={() => {}} onValidate={() => {}} readOnly/>
            </div>
            <div className="ot-quarter-container">
              <Button className="float-right change-button" handleClick={() => { changeCardClicked(card); }} buttonStyle="clearBackground">
                Change
              </Button>
            </div>
          </div>
        )}
      )}
    </div>
  );
}

const StyledCreditCardsList = styled(CreditCardsList)`
  text-align: left;

  label {
    height: 10px;
    line-height: 10px;
  }

  label.sub-label {
    font-size: 10px;
    width: 100%;
  }

  .change-button {
    font-weight: bold;
  }
`;

export { StyledCreditCardsList as CreditCardsList };
