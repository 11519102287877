import React, { useEffect, useRef, createContext, useContext, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { UserJourneyNav, UserJourneyPage, Loader, LogInModal, withSettings } from '../components';
import { SessionContext } from '../../Context';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { CREATE_JOURNEY_RESPONSE } from '../../mutations';
import { UJ, UJ_RESPONSE_BY_CLAIM_TOKEN } from '../../queries';

import { useJourneyState, useJourneyAnswerState } from '../../hooks';
import { getTemplate } from '../../libs';
import * as userJourney from '../../libs/userJourney';

import { TweenMax, Power3 } from 'gsap';

export const JourneyContext = createContext();
const { Provider } = JourneyContext;


const UserJourney = ({ className, history, location, settings }) => {
  const { session } = useContext(SessionContext);
  const getScreenWidth = useCallback(() => window.innerWidth, []); //document.documentElement.clientWidth;
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const claimToken = userJourney.getItem('resourceToken') || '';
  const journeyAction = userJourney.getItem('action');
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [journeyStart, setJourneyStart] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [animating, isAnimating] = useState(false);
  const [choices, setChoices] = useState();
  const fromCreation = location.state && location.state.returnToCreation;
  const fromMadlib = location.state && location.state.fromMadlib;
  // const [chosenBgImage, setChosenBgImage] = useState(null);

  const [showLogInModal, setShowLogInModal] = useState(false);
  const [modalContent, setModalContent] = useState('login');
  const toggleLogInModal = () => setShowLogInModal(prev => !prev);
  const updateModalContent = content => setModalContent(content);

  const { data, loading, error } = useQuery(UJ, { fetchPolicy: 'no-cache' });
  const { data: claimData } = useQuery(
    UJ_RESPONSE_BY_CLAIM_TOKEN, {
      variables: { claimToken },
      fetchPolicy: 'no-cache'
    }
  );
  const [createJourneyResponse, { data: journeyResponseData }] = useMutation(CREATE_JOURNEY_RESPONSE);
  const journeyResponse = journeyResponseData ? journeyResponseData.createJourneyResponse.journeyResponse : null;
  const journey = data ? data.journeys.nodes.find(n => n.id === 2) : null;
  const journeyState = useJourneyState(journey
    ? { userJourney: journey, fromMadlib }
    : null
  );

  const madlibResponseId = claimData && claimData.journeyResponseByClaimToken && claimData.journeyResponseByClaimToken.id;

  // console.log('resourceToken', resourceToken);
  // console.log('claimData', claimData);

  // console.log('data', data);

  if (error) console.error(error);

  const pageRef = useRef(null);
  const pageContentRef = useRef(null);
  const loaderRef = useRef(null);
  const hasStarted = useRef(false);

  const answerState = useJourneyAnswerState(journeyState, claimData);

  const getPagePosition = useCallback(resize => {
    const offset = resize ? 1 : 0;
    return -(getScreenWidth() * (numberOfPages - offset));
  }, [numberOfPages, getScreenWidth]);

  const setWindowHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  const lastNumberOfPages = useRef(0);

  useEffect(() => {
    if (journeyState && numberOfPages !== lastNumberOfPages.current) {
      const { currentPage } = journeyState;
      const pageName = currentPage && currentPage.name;

      if (pageName && window.dataLayer) {
        window.dataLayer.push({
          event: 'uj_page_view',
          uj_page: pageName
        });
      }

      lastNumberOfPages.current = numberOfPages;
    }
  }, [numberOfPages, journeyState, lastNumberOfPages]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.paddingTop = '0';

    return () => {
      body.style.paddingTop = null;
      userJourney.resetAll();
    };
  }, []);

  useEffect(() => {
    setWindowHeight();

    const setWindowDimensions = () => {
      setScreenWidth(getScreenWidth());
      setWindowHeight();

      TweenMax.to(pageRef.current, 0, {
        left: getPagePosition(true),
        ease: Power3.easeInOut,
      });
    };
    window.addEventListener('resize', setWindowDimensions);

    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    }
  });

  useEffect(() => {
    if (loaderRef && !loading) {
      TweenMax.to(loaderRef.current, .6, {
        opacity: 0,
        ease: Power3.easeOut,
      });
    }
  }, [loaderRef, loading]);

  useEffect(() => {
    if (journeyResponse && journeyResponse.claimToken) {
      userJourney.store({ resourceToken: journeyResponse.claimToken, showJourneyResources: true });
    }
  }, [journeyResponse]);

  const animatePage = useCallback(() => {
    const duration = 1.5;

    TweenMax.to(pageRef.current, duration, {
      left: getPagePosition(),
      ease: Power3.easeInOut,
    });

    isAnimating(true);
    setTimeout(() => isAnimating(false), parseInt(duration * 1000, 10));
  }, [pageRef, getPagePosition]);

  const saveResourcesAndGoToCreation = (afterLogin = false) => {
    const resourceList = answerState && answerState.saveResources();
    const isCommunity = location.state && location.state.isCommunity;

    setTimeout(async () => {
      if (afterLogin || (session && session.viewer && session.viewer.user)) {
        const responseVars = userJourney.getJourneyResponseVars(
          journeyState.journeyId,
          answerState.answers,
          [...resourceList.resources, ...resourceList.videos, ...resourceList.playlists]
        );

        // fade page content out //
        TweenMax.to(pageContentRef.current, 1, {
          css: { opacity: 0 },
          ease: Power3.easeInOut,
        });

        const response = await createJourneyResponse({ variables: responseVars });
        const { data: { createJourneyResponse: { journeyResponse: newJourneyResponse } } } = response;
        const responseId = newJourneyResponse && newJourneyResponse.id;

        history.push({
         pathname: '/events',
         state: {
           immersive: true,
           isCommunity,
           choices,
           journeyResponseIds: [
             ...(responseId ? [responseId] : []),
             ...(madlibResponseId ? [madlibResponseId] : [])
           ]
         }
        });
      } else {
        toggleLogInModal(true);
      }
    }, afterLogin ? 0 : 1000);
  }

  const goToNextPage = nextPage => {
    const { nextPageId, renderTemplate } = nextPage;
    const willGoToResources = renderTemplate && renderTemplate.toLowerCase() === 'resources';

    if (willGoToResources) {
      setRedirecting(true);

      if (journeyAction === 'creation' || fromCreation) {
        saveResourcesAndGoToCreation();
      } else {
        answerState && answerState.saveResources();
        // wait until after journey has been saved to add the resources page //
        // in order to have the page content fade in in time //
        journeyState.addPage(nextPageId);
        animatePage();
      }
    } else {
      answerState && answerState.saveResources();
      journeyState.addPage(nextPageId);
      animatePage();
    }

    setNumberOfPages(prev => prev + 1);
  };

  const goToPrevPage = () => {
    if (journeyState.pages.length < 2) return;

    TweenMax.to(pageRef.current, 1.5, {
      left: -(getScreenWidth() * (numberOfPages - 2)),
      ease: Power3.easeInOut,
    });

    setTimeout(() => {
      journeyState.removePage();
      answerState.removeResources();
      setNumberOfPages(prev => prev - 1);
    }, 1500);
  };

  // const handleCreationRedirect = () => {
  //   TweenMax.to(pageContentRef.current, 1, {
  //     css: { opacity: 0 },
  //     ease: Power3.easeInOut,
  //   });
  //
  //   setTimeout(() => {
  //     if (session && session.viewer && session.viewer.user) {
  //       history.push({
  //        pathname: '/events',
  //        state: { immersive: true }
  //       });
  //     } else {
  //       toggleLogInModal(true);
  //     }
  //   }, 1000);
  // };

  const handleStartJourney = () => {
    journeyState.startJourney();
    setJourneyStart(true);
  };

  useEffect(() => {
    if (journeyStart && !hasStarted.current) {
      animatePage();
      setNumberOfPages(prev => prev + 1);
      hasStarted.current = true;
    }
  }, [journeyStart, animatePage]);

  // console.log('journeyState', journeyState);

  return (
    <div className={[className, 'uj_page'].join(' ')}>
      {!loading && <UserJourneyNav />}
      <div
        style={{ width: `calc(${numberOfPages * 100}vw)` }}
        ref={pageRef}
      >
        <div ref={loaderRef}><Loader style={{ 'z-index': 0 }} /></div>
        <Provider value={{ journeyState, answerState }}>
          {journeyState.pages.length > 0 && journeyState.pages.map((page, i) => (
            <div
              className="page_wrapper"
              key={i}
              style={{ left: `${i * screenWidth}px` }}
            >
              <UserJourneyPage
                ref={pageContentRef}
                settings={settings}
                data={getTemplate(page)}
                progress={{ startJourney: handleStartJourney, goToNextPage, goToPrevPage }}
                redirecting={redirecting}
                animating={animating}
                handleCreationRedirect={saveResourcesAndGoToCreation}
                currentTitleIndex={
                  journeyState.pages.filter(p => p.renderTemplate === 'Immersive' || p.renderTemplate === 'Resources').length - 1
                }
                titles={{ pagesTitles: journeyState.pagesTitles, updatePageTitles: journeyState.updatePageTitles }}
                chosen={{ choices, setChoices }}
                analytics={{ fromCreation, fromMadlib }}
              />
            </div>
          ))}
        </Provider>
      </div>

      <LogInModal
        afterLogin={() => saveResourcesAndGoToCreation(true)}
        show={showLogInModal}
        toggle={toggleLogInModal}
        updateModalContent={updateModalContent}
        content={modalContent}
      />
      <style>{'#new_banner_hack { display: none; };'}</style>
    </div>
  );
};

const StyledUserJourney = styled(UserJourney)`
  overflow-x: hidden;
  height: 100%;

  & > div {
    // min-height: calc(100vh - 80px);
    height: 100%;
    // min-height: calc(var(--vh, 1vh) * 100);
    // min-height: -webkit-fill-available;
    // min-height: calc(var(--vh, 1vh) * 100);
    background-color: #0d0d0d;
    position: relative;
  }

  .page_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    color: #fff;
    // min-height: calc(100vh - 80px);
    min-height: 100vh;
    display: table;
    // z-index: 1;
  }
`;
const UserJourneyWithRouter = withRouter(StyledUserJourney);
const UserJourneyWithSettings = withSettings(UserJourneyWithRouter);
export { UserJourneyWithSettings as UserJourney };
