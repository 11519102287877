import React from 'react';
import styled from 'styled-components';
import { isEmail } from 'react-multi-email';
import { UserEmailSelector } from '../components';

const EventGuestsAddByEmail = (props) => {

  const { className, emails, emailsChanged } = props;

  return (
    <div className={className}>
      <UserEmailSelector
        name="emails"
        allowMultipleEmails={true}
        emails={emails}
        onAddEmail={value => emailsChanged('emails', value)}
        onValidate={isEmail}
        placeholder="friend@domain.com"
        errorMessage="Enter a valid email."
      />
    </div>
  );
}

const StyledEventGuestsAddByEmail = styled(EventGuestsAddByEmail)`
  text-align: left;

  & p {
    font-size: 14px;
    margin-bottom: 2px;
  }
`;

export { StyledEventGuestsAddByEmail as EventGuestsAddByEmail };
