import { Enumerated } from './Enumerated';

export class EVENT_FILTER extends Enumerated {
  static HOSTED = 'HOSTED';
  static DRAFT = 'DRAFT';
  static PAST = 'PAST';
  static RESERVED ='RESERVED';
  static ATTENDED = 'ATTENDED';
  // static ATTENDED_HOSTED = 'ATTENDED_HOSTED';
  static PENDING_PUBLISH_APPROVAL = 'PENDING_PUBLISH_APPROVAL';

  static FIELDS = {
    LABEL: 'LABEL',
    URL: 'URL'
  };

  static INFO = {
    HOSTED: {
      LABEL: 'Active',
      URL: 'hosted'
    },
    DRAFT: {
      LABEL: 'Draft',
      URL: 'saved'
    },
    PENDING_PUBLISH_APPROVAL: {
      LABEL: 'Pending Approval',
      URL: 'pending'
    },
    PAST: {
      LABEL: 'Past',
      URL: 'past'
    },
    RESERVED: {
      LABEL: 'Upcoming',
      URL: 'upcoming'
    },
    ATTENDED: {
      LABEL: 'Attended',
      URL: 'attended'
    },
    // ATTENDED_HOSTED: {
    //   LABEL: 'Attended & Hosted',
    //   URL: 'attendedhosted'
    // }
  };
}
