import { getSiteNameFromSettings, getSiteSubNameFromSettings } from '../libs';
const currentYear = 2020; // new Date().getFullYear();

export const ProfileQuestions = {
  // ONETABLE //

  onetable: [
  // hillel: [
    {
      type: 'radio',
      name: 'residence',
      other: false,
      choices: {
        a: 'I\'ve lived here all my life',
        b: 'I used to live here, and moved back in the last few years',
        c: 'More than five years',
        d: 'One to five years',
        e: 'Under a year'
      }
    },
    {
      type: 'text',
      name: 'gender',
    },
    {
      type: 'radio',
      name: 'relationship',
      other: false,
      choices: {
        a: 'Single',
        b: 'In a relationship',
        c: 'Married',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'diet',
      other: true,
      choices: {
        a: 'Gluten-free',
        b: 'Dairy-free',
        c: 'Vegetarian',
        d: 'Kosher',
        e: 'Kosher style',
        f: 'Pescatarian',
        g: 'Vegan',
        other: 'Other'
      }
    },
    {
      type: 'text',
      name: 'favoriteDinner',
    },
    {
      type: 'checkbox',
      name: 'excited',
      other: true,
      choices: {
        a: 'Meeting more people who share a love for Friday night wind downs around the dinner table',
        b: 'Becoming part of a community',
        c: 'Spending meaningful time with my friends',
        d: 'Doing something fun with friends on a Friday night',
        e: 'Doing something that feels Jewish',
        f: 'Trying something new and different',
        g: 'Feeling like I\'m part of a movement, or something bigger than myself',
        h: 'Unplugging and recharging at the end of the week',
        i: 'Adding something that feels spiritual to my week',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'involvement',
      other: true,
      choices: {
        a: 'Fitness (e.g. Cross Fit, SoulCycle)',
        b: 'Spiritual Wellbeing (e.g. yoga, mindfulness)',
        c: 'Jewish (e.g. Moishe House, synagogue)',
        d: 'Social Justice (e.g. CTZNWELL, Big Brothers Big Sisters of America)',
        e: 'Political Activism (e.g. Black Lives Matter, MeToo)',
        f: 'Food (e.g Food52, supper club)',
        g: 'Arts (e.g. Burning Man, StoryCorps)',
        h: 'Outdoors (e.g. Camp Grounded, Sierra Club)',
        i: 'None',
        other: 'Other'
      }
    },
    {
      type: 'text',
      name: 'busy',
    },
    {
      type: 'radio',
      name: 'iAm',
      other: false,
      choices: {
        a: '21-39 looking to build community with others in their 20s/30s',
        b: '21-39 looking to build intergenerational community',
        c: '40+ joining a dinner/dinner community',
        d: 'A parent of a OneTable host attending my child\'s dinner(s)',
        e: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'languages',
      other: false,
      freeChoice: true,
      choices: {
        a: 'American Sign Language',
        b: 'English',
        c: 'Farsi',
        d: 'French',
        e: 'Hebrew',
        f: 'Ladino',
        g: 'Russian',
        h: 'Spanish',
        i: 'Yiddish',
        freeChoice: ''
      }
    },
    {
      type: 'checkbox',
      name: 'ethnicity',
      other: false,
      freeChoice: true,
      choices: {
        a: 'American Indian or Alaska Native—For example, Navajo Nation, Blackfeet Tribe, Mayan, Aztec, Native Village of Barrow Inupiat Traditional Government, Nome Eskimo Community',
        b: 'Asian—For example, Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese',
        c: 'Black or African American—For example, Jamaican, Haitian, Nigerian, Ethiopian, Somalian',
        d: 'Hispanic, Latino or Spanish Origin—For example, Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Columbian',
        e: 'Middle Eastern or North African—For example, Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian',
        f: 'Native Hawaiian or Other Pacific Islander—For example, Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, Marshallese',
        g: 'White—For example, German, Irish, English, Italian, Polish, French',
        h: 'Another race, ethnicity, or origin',
        i: 'I prefer not to answer',
      }
    },

    {
      type: 'select',
      name: 'howDidYouHear',
      other: true,
      myProfile: true,
      choices: {
        a: 'A friend',
        b: 'An event I attended',
        c: 'Another organization',
        d: 'OneTable staff member',
        e: 'Online',
        f: 'Glowing Up podcast',
        g: 'The Study podcast',
        h: 'PAUSE episode',
        other: 'Other'
      }
    },
    {
      type: 'text',
      name: 'howDidYouHearFriend',
      myProfile: true,
    },
    {
      type: 'text',
      name: 'howDidYouHearEvent',
      myProfile: true,
    },
    {
      type: 'select',
      name: 'howDidYouHearOrganization',
      myProfile: true,
      choices: {
        a: '18Doors',
        b: 'AEPi',
        c: 'Avodah',
        d: 'BBYO',
        e: 'Birthright',
        f: 'BRI Excel',
        g: 'Bring Israel Home',
        h: 'Challah for Hunger',
        i: 'Combined Jewish Philanthropies (CJP Boston)',
        j: 'Congregation Ahavas Israel, Grand Rapids',
        k: 'Congregation Beth Israel, Houston',
        l: 'Conversation Shabbat',
        m: 'EDCJCC Shabbat Clusters',
        n: 'FIDF',
        o: 'Honeymoon Israel',
        p: 'IKAR',
        q: 'JDC Entwine',
        r: 'Jewish Federation of Grand Rapids',
        s: 'Jewish Federation of Greater Los Angeles/NuRoots',
        t: 'Jewish Federation of Greater Seattle',
        u: 'Jewish Federation of Metropolitan Detroit',
        v: 'Jewish Federation of Orange County',
        w: 'Jewish Federation of San Antonio',
        x: 'Masa Israel',
        y: 'Mishkan',
        z: 'Moishe House',
        aa: 'My Camp/Foundation for Jewish Camp',
        ab: 'NEXTGen Detroit',
        ac: 'Onward',
        ad: 'OU NextDor',
        ae: 'REALITY',
        af: 'Reboot',
        ag: 'Repair the World',
        ah: 'Revolve',
        ai: 'ROI',
        aj: 'The Network at the Greater Miami Jewish Federation',
        ak: 'The Well',
        al: 'Union for Reform Judaism (URJ)',
        am: 'Urban Adamah',
        other: 'Other'
      }
    },
  ],

  // HILLEL //

  hillel: [
    {
      type: 'radio',
      name: 'gender',
      other: false,
      restrictFromSite: ['international'],
      choices: {
        a: 'She/Her',
        b: 'He/Him',
        c: 'They/Them',
        d: 'Prefer to self identify',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'gender',
      other: false,
      restrictToSite: ['international'],
      choices: {
        a: 'He/Him/His',
        b: 'He/She',
        c: 'He/They',
        d: 'She/He',
        e: 'She/Her/Hers',
        f: 'She/They',
        g: 'They/He',
        h: 'They/She',
        i: 'They/Them/Theirs',
        j: 'Ey/Em/Eir',
        k: 'Xe/Xem/Xyr',
        l: 'Ze/Hir/Hirs',
        m: 'Not Listed',
      }
    },
    {
      type: 'checkbox',
      name: 'diet',
      other: true,
      choices: {
        a: 'Celiac',
        b: 'Kosher',
        c: 'Lactose Intolerant',
        d: 'Peanut Allergy',
        e: 'Pescatarian',
        f: 'Tree Nut Allergy',
        g: 'Vegan',
        h: 'Vegetarian',
        i: 'Wheat Allergy',
        other: 'Other'
      }
    },
    {
      type: 'radio',
      name: 'birthright',
      choices: {
        a: 'Yes',
        b: 'No',
        c: 'Unknown',
        d: 'I already went'
      }
    },
    {
      type: 'radio',
      name: 'jewish',
      other: true,
      restrictFromSite: ['cornell', 'international'],
      choices: {
        a: 'Reform',
        b: 'Conservative',
        c: 'Orthodox',
        d: 'Traditional Egalitarian',
        e: 'Reconstructionist',
        f: 'Renewal',
        g: 'Just Jewish',
        h: 'Not Jewish',
        other: 'Other'
      }
    },
    {
      type: 'radio',
      name: 'are_you_jewish',
      restrictToSite: ['cornell', 'international'],
      choices: {
        a: 'Yes',
        b: 'No',
        c: 'It\'s Complicated',
        d: 'Prefer Not to Answer'
      }
    },
    {
      type: 'checkbox',
      name: 'identification',
      restrictToSite: ['international'],
      choices: {
        a: 'Ashkenazi',
        b: 'Asian American',
        c: 'Atheist',
        d: 'Beta Israel',
        e: 'BIPOC',
        f: 'Bnei Anousi',
        g: 'Bukharian',
        h: 'Conservative',
        i: 'Considering Conversion',
        j: 'Converted to Judaism',
        k: 'Cultural',
        l: 'Former Soviet Union',
        m: 'Gorsky/Kafkazi',
        n: 'Interfaith',
        o: 'Israeli',
        p: 'Just Jewish',
        q: 'Latinx',
        r: 'Mizrachi',
        s: 'Modern Orthodox',
        t: 'Multi-Religion Household',
        u: 'Orthodox',
        v: 'Persian',
        w: 'Reconstructionist',
        x: 'Reform',
        y: 'Russian-Speaking',
        z: 'Secular',
        aa: 'Sephardi',
        ab: 'Syrian',
        ac: 'Traditional'
      }
    },
    {
      type: 'checkbox',
      name: 'growing_up',
      other: true,
      restrictToSite: ['international'],
      choices: {
        a: 'Bar/Bat Mitzvah',
        b: 'BBYO',
        c: 'Conservative High School',
        d: 'Gap Year',
        e: 'Hebrew School',
        f: 'Israeli High School',
        g: 'Jewish Camp',
        h: 'Jewish Day School',
        i: 'Jewish Pre-School',
        j: 'Jewish Youth Group',
        k: 'March of the Living',
        l: 'NCSY',
        m: 'NFTY',
        n: 'Non-Orthodox Jewish High School',
        o: 'Orthodox High School',
        p: 'Pluralistic High School',
        q: 'Sunday School',
        r: 'Traveled to Israel',
        s: 'USY',
        t: 'Young Judaea',
        other: 'Other'
      }
    },
    {
      type: 'text',
      name: 'busy',
      restrictFromSite: ['international'],
    },
    {
      type: 'select',
      name: 'iAm',
      restrictFromSite: ['hga', 'international'],
      other: true,
      myProfile: true,
      choices: {
        a: '{{siteLabel}} Undergrad Student',
        b: '{{siteLabel}} Graduate Student',
        c: '{{siteLabel}} Alumni',
        other: 'Other'
      }
    },
    {
      type: 'select',
      name: 'iAmAlt',
      restrictToSite: ['hga'],
      other: true,
      myProfile: true,
      choices: {
        a: 'Undergrad Student',
        b: 'Graduate Student',
        c: 'Alumni',
        other: 'Other'
      }
    },
    {
      type: 'select',
      name: 'iAmCampus',
      restrictToSite: ['hga'],
      myProfile: true,
      choices: {
        h: 'Agnes Scott',
        a: 'Emory University',
        f: 'Georgia College',
        c: 'Georgia State',
        g: 'Georgia Southern',
        b: 'Georgia Tech',
        d: 'Kennesaw State',
        i: 'Oglethorpe',
        e: 'University of Georgia',
        other: 'Other'
      }
    },
    {
      type: 'text',
      restrictFromSite: ['international'],
      name: 'iAmOther',
      myProfile: true
    },
    {
      type: 'select',
      name: 'iAmHillel',
      restrictToSite: ['international'],
      myProfile: true,
      choices: {
        a: 'Undergraduate Student',
        b: 'Graduate Student',
        c: 'Young Adult',
        d: 'Hillel Staff',
        e: 'Prospective Student',
        f: 'Community Member',
        g: 'Parent',
        other: 'Other'
      }
    },
    {
      type: 'text',
      restrictToSite: ['international'],
      name: 'iAmHillelOther',
      myProfile: true
    },
    {
      type: 'select',
      name: 'gradYear',
      other: true,
      myProfile: true,
      display: false,
      choices: {
        // leaving currentYear as is to keep the the shown years up to date but not change the keys
        // for existing gradYears
        // a: String(currentYear),
        // b: String(currentYear + 1),
        c: String(currentYear + 2),
        d: String(currentYear + 3),
        e: String(currentYear + 4),
        f: String(currentYear + 5),
        g: String(currentYear + 6),
        h: String(currentYear + 7),
        i: String(currentYear + 8),
        j: String(currentYear + 9),
        k: String(currentYear + 10),
        l: String(currentYear + 11),
        m: String(currentYear + 12)
      }
    },
    {
      type: 'text',
      name: 'iAmCampusOther',
      restrictToSite: ['hga'],
      myProfile: true
    },
  ],

  // DINNER PARTY //

  'dinner-party': [
    {
      type: 'checkbox',
      name: 'pronouns',
      otherOptional: true,
      required: true,
      choices: {
        a: 'She/her',
        b: 'He/him',
        c: 'They/them',
        d: 'I prefer not to say',
        other: 'Let me type...'
      }
    },
    {
      type: 'checkbox',
      name: 'sexualOrientation',
      otherOptional: true,
      required: true,
      choices: {
        a: 'LGBTQ+',
        b: 'Heterosexual',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'ethnicity',
      otherOptional: true,
      required: true,
      choices: {
        a: 'African American',
        b: 'American Indian',
        c: 'Alaskan Native',
        d: 'Asian',
        e: 'Black',
        f: 'Hispanic',
        g: 'Latino',
        h: 'Native Hawaiian',
        i: 'Pacific Islander',
        j: 'White',
        other: 'A race/ethnicity not listed here'
      }
    },
    {
      type: 'checkbox',
      name: 'religiousIdentification',
      otherOptional: true,
      required: true,
      choices: {
        a: 'Christian',
        b: 'Catholic',
        c: 'Jewish',
        d: 'Muslim',
        e: 'Hindu',
        f: 'Athiest or Agnostic',
        g: 'Spiritual but not religious',
        h: 'Prefer not to say',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'whoBringsYou',
      otherOptional: true,
      required: true,
      choices: {
        a: 'Mother or mother figure',
        b: 'Father or father figure',
        c: 'Sibling',
        d: 'Partner',
        e: 'Child',
        f: 'Friend',
        other: 'Other'
      }
    },
    {
      type: 'checkbox',
      name: 'lossExperiences',
      otherOptional: true,
      choices: {
        a: 'Suicide',
        b: 'Homicide',
        c: 'Sudden loss',
        f: 'Caregiving',
        e: 'COVID-19',
        d: 'Recent loss',
        other: 'Other'
      }
    },
    {
      type: 'textarea',
      name: 'tellUsMore',
    },
    {
      type: 'checkbox',
      name: 'howDidYouHear',
      otherOptional: true,
      choices: {
        a: 'TDP participant',
        b: 'TDP host',
        c: 'A friend, coworker, or acquaintance who hasn\'t participated in TDP',
        d: 'A therapist, counselor, or other grief support person/agency',
        other: 'Other'
      }
    },
  ]
};

export const getProfileQuestions = settings => {
  const siteName = getSiteNameFromSettings(settings);
  const siteSubName = getSiteSubNameFromSettings(settings);

  return ProfileQuestions[siteName]?.filter(q =>
    // if restrictFromSite exists make sure site isnt included
    (q.restrictFromSite ? !q.restrictFromSite.includes(siteSubName) : true) &&
    // if restrictToSite exists make sure site is included
    (q.restrictToSite ? q.restrictToSite.includes(siteSubName) : true)
  ) || [];
};
