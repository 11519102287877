import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { useSession } from '../../hooks';

import { EVENT_BY_ID, EVENT_BY_UUID } from '../../queries';

import {
  isHostById,
  isRegisteredById,
  getEventReservation
} from '../../libs';

const EventPageContext = React.createContext();


const EventPageContextProvider = ({ children }) => {
  const { viewer } = useSession();
  const { uuid, id } = useParams();
  const eventId = uuid || parseInt(id);

  const [eventData, setEventData] = useState(null);
  const [reservation, setReservation] = useState(null);

  const isHost = isHostById(eventId, viewer && viewer.hostedEvents ? viewer.hostedEvents.edges : [], !!uuid);
  const isRegistered = isRegisteredById(eventId, viewer && viewer.reservations ? viewer.reservations.nodes : [], !!uuid);
  const variables = { isHost, isRegistered, isLogged: !!(viewer?.user) };

  if (uuid) variables.uuid = eventId;
  else variables.id = eventId;

  const { data, loading } = useQuery(uuid ? EVENT_BY_UUID : EVENT_BY_ID, {
    errorPolicy: 'all',
    variables
  });
  const queriedEventData = data && data.event;

  useEffect(() => {
    if (queriedEventData) {
      const res = getEventReservation(queriedEventData, viewer);
      res && setReservation(res);
      setEventData(queriedEventData);
    }
  }, [queriedEventData]);

  const value = {
    eventData,
    loading,
    error: !eventData && !queriedEventData,
    isHost,
    isRegistered,
    reservation
  };

  return <EventPageContext.Provider value={value}>{children}</EventPageContext.Provider>;
};

export { EventPageContext, EventPageContextProvider };
