import { useEffect } from 'react';
import { useSettings, useSession } from '../';

export const useAppcuesInit = () => {
  const { features: { enable_appcues_usage }, env_vars: { appcues_app_id } } = useSettings();
  const { viewer: { user } } = useSession();

  useEffect(() => {
    if (enable_appcues_usage && appcues_app_id) {
      // install script
      window.AppcuesSettings = { enableURLDetection: true };
      const script = document.createElement('script'); 
      script.type = 'text/javascript', script.src = `https://fast.appcues.com/${appcues_app_id}.js`; 
      const n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(script, n);
    }
  }, []);

  const getProfileAnswer = (answers, a) => {
    const answer = answers[a];
    return answer?.choices?.filter(c => c.value).map(c => c.label).join(', ') ?? null;
  };

  useEffect(() => {
    if (!user || !window.Appcues) return;
    // identify current user
    const { id, profile, email, state, hostApprovalAt } = user;
    const answers = JSON.parse(profile?.answers ?? '{}');
    window.Appcues.identify(id, {
      firstName: profile?.firstName || null,
      lastName: profile?.lastName || null,
      email,
      location: profile?.zipCode || null,
      hostState: state || null,
      hostApprovalAt: hostApprovalAt || null,
      bio: profile?.bio || null,
      dateOfBirth: profile?.dateOfBirth || null,
      residence: getProfileAnswer(answers, 'residence'),
      relationship: getProfileAnswer(answers, 'relationship'),
      excitedAboutShabbat: getProfileAnswer(answers, 'excited'),
      communityInvolvement: getProfileAnswer(answers, 'involvement'),
      iAm: getProfileAnswer(answers, 'iAm'),
      languages: getProfileAnswer(answers, 'languages'),
      ethnicity: getProfileAnswer(answers, 'ethnicity'),
      howDidYouHear: getProfileAnswer(answers, 'howDidYouHear'),
      howDidYouHearOrganization: getProfileAnswer(answers, 'howDidYouHearOrganization'),
    });
  }, [user, window.Appcues]);
};
