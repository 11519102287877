import React from 'react';
import styled from 'styled-components';
import { withSettings, withSession } from '../../components';

import {
  FAIcon,
  DropDownMenu,
  SelectBoxOption
} from '../../../components';

import {
  arraysOfObjAreEqual,
  arraysAreEqual,
  getObjArrayIndex,
  appendTimezoneOffset
} from '../../../libs';

const defaultState = {
  selection: null,
  isOpen: false,
  multiSelect: [],
  valid: true
};

const defaultSelectValue = {
  id: 0,
  label: '---',
  value: 'ALL'
};

// optionType = {
//   id?: number,
//   label: string,
//   value: string
// }

class SelectBox extends React.Component {
  state = { ...defaultState };

  componentDidMount() {
    this.setDefaultValue();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { required } = this.props;
    const { selection } = this.state;

    if (typeof nextProps.invalidate === 'function' && nextProps.invalidate) {
      if (required && nextProps.invalidate(selection && selection.id ? selection.id : null)) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { selection } = this.state;
    const { defaultValue, options, refreshOnChange } = this.props;
    const defaultSelection = defaultValue || defaultValue === null ? defaultValue : defaultSelectValue;

    // Refresh if dropdown options change //
    if (
      prevProps.options !== options &&
      // No change to options list //
      !arraysOfObjAreEqual(prevProps.options, options)
    ) {
      if (options) {
        this.setState({ selection: defaultSelection });
      } else {
        this.setState({ selection: null });
      }
    }

    // Refresh if refreshOnChange value(s) changes //
    if (
      // refreshOnChange === Array
      (Array.isArray(refreshOnChange) && !arraysAreEqual(prevProps.refreshOnChange, refreshOnChange)) ||
      // refreshOnChange !== Array
      (!Array.isArray(refreshOnChange) && prevProps.refreshOnChange !== refreshOnChange)
    ) {
      this.reset();
    }

    if (defaultValue !== prevProps.defaultValue) {
      this.setState({ selection: defaultValue });
    } else if (options && !defaultValue && !selection) {
      this.setState({ selection: defaultSelectValue });
    }
  }

  reset = leaveOpen => {
    const { onOptionSelect, onSelectionChange } = this.props;
    const newState = Object.assign({}, defaultState);

    if (leaveOpen) newState.isOpen = true;

    this.setState({ ...newState }, () => {
      onOptionSelect(null);
      if (onSelectionChange) onSelectionChange(null);
      this.setDefaultValue();
    });
  };

  setDefaultValue = () => {
    const { defaultValue, isOpen, multiSelect } = this.props;
    const newState = Object.assign({}, this.state);
    const defaultSelection = defaultValue || defaultValue === null ? defaultValue : defaultSelectValue;

    if (isOpen) newState.isOpen = isOpen;
    if (multiSelect) newState.multiSelect = multiSelect;

    this.setState({ ...newState, selection: defaultSelection });
  };

  getLabelText = () => {
    const { selection } = this.state;
    const {
      placeholder,
      multi,
      name,
      settings: { global: { display_timezone_offsets } },
      session: { timezones }
    } = this.props;

    if (selection) {
      // MultiSelect //
      if (Array.isArray(selection) && multi) {
        const labels = selection.map(s => s.label ? s.label : false);
        return labels.join(', ');

        // Array Return Value //
      } else if (Array.isArray(selection) && selection[0] && selection[0].label) {
        return selection[0].label;

        // String Return Value //
      } else if (typeof selection === 'string') {
        const timezone = Array.isArray(timezones) && timezones.find(tz => tz.name === selection);
        if (name === 'timezone' && timezone) return appendTimezoneOffset(timezone, display_timezone_offsets);
        else return selection;

        // Default //
      } else if (selection.label) {
        return selection.label;
      }
    }

    if (typeof placeholder === 'string' || typeof placeholder === 'number') return placeholder;
    return '---';
  };

  handleSelectClick = () => {
    const { selection } = this.state;
    const { multi, onOptionSelect, onSelectionChange, disabled } = this.props;

    if (disabled) {
      return;
    }

    // Set selection state to null to disable SelectBox //
    if (selection !== null) {
      this.setState(prevState => ({
          isOpen: !prevState.isOpen
        }),
        // Set Selection state on MultiSelect close //
        () => {
          if (multi && Array.isArray(selection)) {
            const selections = selection.map(s => s.value);
            onOptionSelect(selections);

            if (onSelectionChange) {
              onSelectionChange(selections);
            }
          }
        });
    }
  };

  handleOptionClick = option => {
    const { multi, onValidate, name } = this.props;

    if (multi) {
      this.handleMultiOptionClick(option);
    } else {
      this.handleSingleOptionClick(option);
    }

    this.setState({ valid: true });
    onValidate && onValidate(name, true);
  };

  handleMultiOptionClick = option => {
    const multiSelect = this.state.multiSelect.slice();
    const index = getObjArrayIndex(multiSelect, option);

    if (option.value === 'ALL') {
      this.reset();
      return;
    }

    if (index !== false) {
      multiSelect.splice(index, 1);
    } else {
      multiSelect.push(option);
    }

    if (!multiSelect.length) {
      this.reset(true);
    } else {
      this.updateMultipleSelection({ multiSelect, selection: multiSelect });
    }
  };

  updateMultipleSelection = newSelection => {
    this.setState(newSelection, () => {
      const { onOptionSelect, onSelectionChange, multi } = this.props;
      const { selection } = this.state;

      if (multi && Array.isArray(selection)) {
        const selections = selection.map(s => s.value);
        onOptionSelect(selections);

        if (onSelectionChange) {
          onSelectionChange(selections);
        }
      }
    });
  };

  handleSingleOptionClick = option => {
    const { onOptionSelect, onSelectionChange } = this.props;
    const { value } = option;

    const selected = onOptionSelect(this.getReturnValue(value));
    const newState = { isOpen: false };

    // return false in onOptionSelect call to stop selection //
    if (selected !== false) {
      newState.selection = option;
      onOptionSelect(this.getReturnValue(value));
      if (onSelectionChange) onSelectionChange(value);
    }

    this.setState(newState);
  };

  getReturnValue = value => {
    const { returnValue } = this.props;

    switch (returnValue) {
      case 'array':
        return new Array(value);
      default:
        return value;
    }
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  render() {
    const { isOpen, selection, valid } = this.state;
    const { className, options, multi, disabled, placeholder } = this.props;

    const isPlaceholder = !selection || (selection.label === placeholder);

    return (
      <div
        className={`
          select_box
          ${className}
          ${!valid ? 'invalid' : ''}
        `}
      >
        <div
          className={`
            label
            ${isOpen ? 'open' : ''}
            ${selection === null ? 'disabled' : ''}
            ${disabled ? 'disabled' : ''}
            ${isPlaceholder ? 'isPlaceholder' : ''}
          `}
          onClick={this.handleSelectClick}
        >
          <span>{this.getLabelText()}</span>
          <FAIcon name={['fas', 'caret-down']}/>
        </div>
        {isOpen && (
          <DropDownMenu toggle={this.handleSelectClick} backdrop>
            {options && options.map((option, index) => (
              <SelectBoxOption
                key={option.id ? option.id : index}
                label={option.label}
                value={option.value}
                selection={selection}
                onOptionClick={() => this.handleOptionClick(option)}
                multi={multi}
              />
            ))}
          </DropDownMenu>
        )}
        {!valid && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}

const StyledSelectBox = styled(SelectBox)`
  position: relative;
  text-align: left;

  & > .label {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 14px;
    padding: 7px 25px 8px 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 250ms, box-shadow 250ms;
    text-transform: capitalize;

    & > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${props => props.leftLabels ? 'text-align: left' : null};
    }

    svg {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.open {
      border-color: #8a8a8a;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,.15);
    }

    &.disabled {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
      cursor: default;
    }

    &.isPlaceholder > span {
      color: #747474;
    }
  }

  .dropdown_menu {
    top: 100%;
    left: 0;
  }

  &.invalid {
    .label {
      border-color: ${props => props.settings.theme.css.global.colors.error};
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 5px;
    }

    .dropdown_menu {
      top: calc(100% - 16px);
    }
  }

  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-botton: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
`;

const StyledSelectBoxWithSettings = withSettings(StyledSelectBox);
const StyledSelectBoxWithSession = withSession(StyledSelectBoxWithSettings);
export { StyledSelectBoxWithSession as SelectBox };
