import React from 'react';
import styled from 'styled-components';

import { withSettings, LandingHeroSearchField, PageContentWrapper } from '../components';


const LandingHeroSearch = ({ className, settings: { copy } }) => (
  <div className={className}>
    <PageContentWrapper>
      <H1>{copy.landing.hero_search_heading}</H1>
      <LandingHeroSearchField />
    </PageContentWrapper>
  </div>
);

const H1 = styled.h1`
  color: #fff;
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;
  margin: 0 0 10px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
`;

const StyledLandingHeroSearch = styled(LandingHeroSearch)`
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 770px;
  font-family: 'Avenir-Regular' !important;
`;

const LandingHeroSearchWithSettings = withSettings(StyledLandingHeroSearch);
export { LandingHeroSearchWithSettings as LandingHeroSearch };
