import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSession, useRsvpPageData } from '../../hooks';
import {
  getDiscountPrice,
  getTransactionFee,
} from '../../libs';
import { trigger } from '../../libs/customEvent';
import { CREATE_CREDIT_CARD } from '../../mutations';

const RsvpPageCheckoutContext = React.createContext();

const blankCard = { cardCVV: '', cardExp: '', cardNumber: '', fullName: '', favorite: true };


const RsvpPageCheckoutContextProvider = ({ children, eventData }) => {
  const { viewer: { cards: viewerCards } } = useSession();
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(null);
  const [newCard, setNewCard] = useState({ ...blankCard });
  const [coupon, setCoupon] = useState(null);

  const { getField, createReservation, setLoading } = useRsvpPageData();
  const additionalGuests = getField('additionalGuests');
  const { pricePerPerson } = eventData || {};
  const couponCode = coupon?.code || '';
  const numberOfGuests = 1 + additionalGuests.length;
  const guestsPrice = numberOfGuests * pricePerPerson;
  const discountAmount = getDiscountPrice(guestsPrice, coupon);
  const totalPriceWithoutTransactionFee = guestsPrice - discountAmount;
  const transactionFee = getTransactionFee(eventData, totalPriceWithoutTransactionFee);
  const donation = 0;
  const donationAmount = donation < 0 ? 0 : donation;
  const totalPrice = totalPriceWithoutTransactionFee + transactionFee + donationAmount;

  const [createCreditCardMutation] = useMutation(CREATE_CREDIT_CARD);

  useEffect(() => {
    if (!card && viewerCards && viewerCards.length) {
      setCards(viewerCards);
      setCard(viewerCards.find(c => c.favorite));
    }
  }, [viewerCards, card]);

  const triggerError = error => {
    switch (error.code) {
      case 'invalid_number':
        trigger('rsvpPage:validateNewCard', { field: 'cardNumber' });
        break;
      case 'invalid_expiry_year':
        trigger('rsvpPage:validateNewCard', { field: 'cardExp' });
        break;
      case 'invalid_cvc':
        trigger('rsvpPage:validateNewCard', { field: 'cardCVV' });
        break;
      default:
    }
  };

  const createNewCard = () => {
    const { fullName, cardNumber, cardExp, cardCVV, favorite } = newCard;
    const cardData = {
      number: cardNumber,
      name: fullName,
      cvc: cardCVV,
      exp_month: cardExp.split('/')[0],
      exp_year: cardExp.split('/')[1] || ''
    };
    setLoading(true);

    window.Stripe.card.createToken(cardData, async (status, { error, id: stripeToken }) => {
      if (error) {
        triggerError(error);
        setLoading(false);
      } else {
        const variables = { stripeToken, favorite };
        const { data: { createCard }, error: cardError } = await createCreditCardMutation({ variables, errorPolicy: 'all' });
        if (cardError || !createCard?.card) {
          console.error(cardError);
          setLoading(false);
        }
        else createReservation({ cardId: createCard.card.id, coupon, totalPrice });
      }
    });
  };

  const validateNewCard = () => {
    let valid = true;
    for (const i in newCard) {
      if (i !== 'favorite' && !newCard[i]) {
        trigger('rsvpPage:validateNewCard', { field: i });
        valid = false;
      }
    }
    return valid;
  };

  const submitPayment = (view) => {
    switch (view) {
      case 'new_method':
      case 'new_method_with_cancel':
        validateNewCard() && createNewCard()
        break;
      case 'view_method':
        card && createReservation({ cardId: card.id, coupon, totalPrice });
        break;
      default:
    }
  };

  const value = {
    cardData: {
      cards,
      card,
      newCard
    },
    setCardData: (field, key, val) => {
      switch (field) {
        case 'card':
          setCard(key);
          break;
        case 'newCard':
          setNewCard(prev => ({ ...prev, [key]: val }));
          break;
        default:
      }
    },
    checkoutData: {
      coupon,
      discountAmount,
      transactionFee,
      donation,
      numberOfGuests,
      guestsPrice,
      totalPrice,
      couponCode
    },
    setCoupon,
    submitPayment
  };

  return <RsvpPageCheckoutContext.Provider value={value}>{children}</RsvpPageCheckoutContext.Provider>;
};

export { RsvpPageCheckoutContext, RsvpPageCheckoutContextProvider };
