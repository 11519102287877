import { useContext, useEffect } from 'react';
import { SettingsContext } from '../SettingsContext';
import { isSite } from '../libs';

export const useFacebookInit = () => {
  const { settings } = useContext(SettingsContext);
  useEffect(() => {
    if (!settings) return;

    const facebookLoginEnabled = settings.features.facebook_login === 'true' || settings.features.facebook_login === true;

    // update to remove site specifics //
    if ((facebookLoginEnabled || isSite(settings, 'jewishfoodfest')) && window.FB && !window.FB.initialized) {
      window.FB.init({
        appId: settings.theme.site.facebook_app_id,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0'
      });

      window.FB.initialized = true;
    }
  }, [settings]);
}
