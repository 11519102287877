import getFormStyle from './forms.style';
import modalsStyle from './modals.style';

import { NOT_SCROLLED_NAV_HEIGHT, SCROLLED_NAV_HEIGHT } from './index';


const defaults = {
  global: {
    font_family: '\'Open Sans\', sans-serif',
    body_background_color: '#fbfaf9',
    pspanh1h2h3h4h5_color: '#443035'
  }
};

function getValueByPath(obj, path) {
  return path.split('.').reduce(function (a, b) {
    return a && a[b];
  }, obj);
}

export default (css, settings, theme) => {
  const parseCssStyle = property => {
    const defaultValue = getValueByPath(defaults, property);
    const parsed = (css && getValueByPath(css, property)) || defaultValue;
    return parsed;
  }

  const globalFontFamily = parseCssStyle('global.font_family');
  const bodyBlackgroundColor = parseCssStyle('global.body_background_color');
  const pSpanH1H2H3H4H5Color = parseCssStyle('global.pspanh1h2h3h4h5_color');

return `
    * {
      box-sizing: border-box;
      font-family: ${theme.isVersion(2) ? 'Avenir-Regular' : globalFontFamily};
    }

    *:active,
    *:focus {
      outline: none !important;
    }

    html {
      height: 100%;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      // min-height: -webkit-fill-available;
    }

    body {
      height: 100%;
      margin: 0;
      padding: 0;
      background-color: ${theme.isVersion(2) ? '#fff' : bodyBlackgroundColor} !important;
      min-height: 100vh;
      padding-top: ${theme.isVersion(2) ? '0' : '85'}px;
      overflow-x: hidden;
    }

    ${theme.isVersion(2) ? `
      body {
        padding-top: ${NOT_SCROLLED_NAV_HEIGHT}px;
      }

      @media (max-height: 840px) {
        body {
          padding-top: ${SCROLLED_NAV_HEIGHT}px;
        }
      }

    ` : ''}

    #root {
      height: 100%;
    }

    .body_container {
      max-width: 1192px;
      padding: 60px 20px;
      margin: 0 auto;
    }

    .body_container.med {
      max-width: 780px;
    }

    a {
      transition: color 250ms;
    }

    hr {
      border: 0 !important;
      height: 1px !important;
      background-color: #e5e5e5;
    }

    hr.separator {
      border: 0;
      height: 1px;
      background-color: #e5e5e5;
      width: 60px;
      margin: 40px auto;
    }

    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${theme.isVersion(2) ? '#222' : pSpanH1H2H3H4H5Color};
    }

    strong {
      ${theme.isVersion(2) ? 'font-family: \'Avenir-Demi\'' : ''}
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      // font-weight: bold !important;
      font-family: 'Avenir-Demi' !important;
      font-weight: normal;
    }

    .row {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 20px;
    }

    .modal-backdrop.show {
      opacity: 0.7 !important;
    }

    .ot-third-container {
      width: 100%;
    }

    .ot-half-container {
      width: 100%;
    }

    .ot-quarter-container {
      width: 100%;
    }

    .ot-twothirds-container {
      width: 100%;
    }

    @media (min-width: 1200px) {
      .ot-third-container {
        width: 33.33333%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-half-container {
        width: 50%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-twothirds-container {
        width: 66.666667%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-quarter-container {
        width: 25%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-third-container {
        width: 33.33333%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-half-container {
        width: 50%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-twothirds-container {
        width: 66.666667%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-quarter-container {
        width: 25%;
        float: left;
      }
    }

    i.divider {
      display: block;
      width: 50px;
      margin: 25px auto;
      height: 1px;
      background-color: #e5e5e5;
    }

    i.divider.with_text {
      width: 100%;
      position: relative;
    }

    i.divider.with_text span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fbfaf9;
      color: #8c8c8c;
      font-style: normal;
      text-transform: uppercase;
      font-size: 12px;
      padding: 5px 25px;
      letter-spacing: 0.02em;
      font-weight: bold;
      white-space: nowrap;
    }

    .card {
      background-color: white;
      box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.05);
      padding: 25px 15px;
      border: 1px solid #e5e5e5;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      font-size: 13px;
    }

    .bold-font {
      font-weight: bold;
    }

    .centered_text {
      text-align: center;
    }

    .page_container {
      max-width: 1192px;
      margin: auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    .ot-third-container {
      width: 100%;
    }

    .ot-half-container {
      width: 100%;
    }

    .ot-quarter-container {
      width: 100%;
    }

    .ot-twothirds-container {
      width: 100%;
    }

    @media (min-width: 1200px) {
      .ot-third-container {
        width: 33.33333%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-half-container {
        width: 50%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-twothirds-container {
        width: 66.666667%;
        float: left;
      }
    }

    @media (min-width: 1200px) {
      .ot-quarter-container {
        width: 25%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-third-container {
        width: 33.33333%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-half-container {
        width: 50%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-twothirds-container {
        width: 66.666667%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .ot-quarter-container {
        width: 25%;
        float: left;
      }
    }

    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .ot-3-sm, .ot-9-sm, .ot-full-container, .ot-half-container, .ot-half-container-payments, .ot-half-right-container, .ot-modal-left-container, .ot-modal-right-container, .ot-quarter-container, .ot-quarter-container-payments, .ot-responsive-full-container-large, .ot-responsive-full-container-medium, .ot-sixth-container, .ot-third-container, .ot-third-right-container, .ot-threequarter-container, .ot-threequarter-container-payments, .ot-twothirds-container {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .ot-fixed-sub-header {
      position: fixed;
      top: 85px;
      display: block;
      z-index: 1001;
      visibility: visible;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      -webkit-transform: translate(0);
      transform: translate(0);
    }

    .float-right {
      float: right;
    }

    ${getFormStyle(theme)}
    ${modalsStyle}
  `
};
