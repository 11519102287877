import React, { useState } from 'react';

import {
  DropdownSearchFilter,
  ToggleButtonSearchFilter,
  MoreFiltersButton,
  EventSearchFilterModal
} from '../../components';


export const EventSearchHeaderFilters = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <DropdownSearchFilter
        name="neighborhood"
        label="Neighborhood"
        options={[
          { label: 'Adair Park', value: 'Adair Park' },
          { label: 'Alpharetta', value: 'Alpharetta' },
          { label: 'Athens', value: 'Athens' },
          { label: 'Austell', value: 'Austell' }
        ]}
      />
      <ToggleButtonSearchFilter
        name="virtual"
        label="Virtual"
      />
      <DropdownSearchFilter
        name="cost"
        label="Cost"
        options={[
          { label: 'Free', value: 'Free' },
          { label: 'Paid', value: 'Paid' },
        ]}
      />
      <ToggleButtonSearchFilter
        name="potluck"
        label="Potluck"
      />
      <MoreFiltersButton
        onClick={() => setModalOpen(true)}
      />

      <EventSearchFilterModal
        show={modalOpen}
        toggle={() => setModalOpen(prev => !prev)}
      />
    </div>
  );
};


// #ceccc7
