import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { EventDetailCard } from '../components';

import { NOT_SCROLLED_NAV_HEIGHT } from '../../styles/index';
import { NOT_SCROLLED_HEADER_HEIGHT, SCROLLED_HEADER_OFFSET } from './EventSearchHeader';


const EventSearchList = ({ className, setListEl, paginatedEvents, fullWidth }) => {
  const listRef = useCallback(node => node !== null && setListEl(node), [setListEl]);
  const { data = {} } = paginatedEvents;
  const { events } = data;

  return (
    <main className={className} ref={listRef}>
      <List fullWidth={fullWidth}>
        {events && events.edges && events.edges.map(({ node }) => (
          <EventDetailCard key={node.id} event={node} />
        ))}
      </List>
    </main>
  );
};


const List = styled.ul`
  margin: 0;
  padding: 5% 7%;
  list-style-type: none;
  display: grid;
  gap: 35px;

  ${props => props.fullWidth ? css`
    grid-template-columns: 1fr 1fr 1fr;
  ` : css `
    grid-template-columns: 1fr 1fr;
  `}
`;

const StyledEventSearchList = styled(EventSearchList)`
  padding-top: ${NOT_SCROLLED_NAV_HEIGHT + NOT_SCROLLED_HEADER_HEIGHT - SCROLLED_HEADER_OFFSET}px;

  ${props => !props.fullWidth && css`
    padding-right: 35vw;
  `}
`;
export { StyledEventSearchList as EventSearchList };
