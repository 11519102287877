import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  PageSubheader as Heading,
  ToggleButton as Option,
  InputField,
  TextareaField,
  Divider,
  RsvpPageFooterButtons as FooterButtons
} from '../components';

import { useSettings, useRsvpPageData } from '../../hooks';


export const RsvpPageReservationQuestions = () => {
  const [canContinue, setCanContinue] = useState(false);
  const { getField, changePage, validateAnswers, loading } = useRsvpPageData();
  const questions = getField('questions');
  const answers = getField('answers');
  const { copy } = useSettings();

  useEffect(() => {
    if (validateAnswers(false)) setCanContinue(true);
    else setCanContinue(false);
  }, [answers]);

  return (
    <>
      <Heading>{copy.rsvp.reservation_questions_title || 'The host would like you to answer the following questions:'}</Heading>
      <Questions>
        {questions && questions.map(({ text, ...question }) => (
          <li key={question.id}>
            <Label className={question.always ? 'always' : null}>{text}</Label>
            <Question data={question} />
          </li>
        ))}
      </Questions>
      <Divider />
      <FooterButtons
        canContinue={canContinue}
        onContinue={() => changePage()}
        onBack={() => changePage(false)}
        loading={loading}
      />
    </>
  );
};

const Question = ({ data: { id, options, answerType, always } }) => {
  // const [valid, setValid] = useState(true);
  const { getField, setField } = useRsvpPageData();
  const answers = getField('answers');
  const setAnswers = (key, val) => setField('answers', [key, val]);

  if (always && options) return options.map(({ name, tooltip }, i) => (
    <Option
      key={i}
      tooltip={tooltip}
      onClick={() => setAnswers(id, name)}
      active={answers[id] === name}
    >{name}</Option>
  ));

  switch (answerType) {
    case 'STRING':
      return (
        <InputField
          name={id}
          value={answers[id]}
          onChange={setAnswers}
        />
      );
    case 'TEXT':
      return (
        <TextareaField
          name={id}
          value={answers[id]}
          onChange={setAnswers}
        />
      );
    default:
      return null;
  }
};

const Questions = styled.ul`
  padding: 0;
  list-style-type: none;

  li:not(:last-child) {
    margin-bottom: 30px;
  }

  .toggle_button  {
    margin: 12px 12px 0 0;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #222;
  margin: 0 0 15px;

  &.always {
    margin: 0 0 3px;
  }
`;
