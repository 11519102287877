import qs from 'qs';

const itemName = 'user-journey';
const storage = window.localStorage;

const defaultConfig = {
  showJourneyResources: false,
  skipResources: false,
  name: false,
  city: false,
  resourceToken: false,
  action: 'landing'
};

const load = () => {
  return JSON.parse(storage.getItem(itemName));
};

const store = (config) => {
  const current = load();
  if (Object.keys(config).length > 0) {
    storage.setItem(itemName, JSON.stringify({
      ...defaultConfig,
      ...current,
      ...config
    }));
  }
};

const getItem = (item) => {
  const config = load();
  return config && config[item];
};

const setItem = (item, value) => {
  const config = load();
  config[item] = value;
  store(config);
};

const resetAll = () => {
  storage.removeItem(itemName);
};

const resetItem = (item) => {
  setItem(item, defaultConfig[item] || null);
};

const getNextAction = (val, widgets) => {
  let action = {};

  Array.isArray(widgets) && widgets.forEach(w => {
    const { actions } = w;

    Array.isArray(actions.nodes) && actions.nodes.forEach(n => {
      const { value } = n;

      if (value.string === val) {
        action = n;
      }
    });
  });

  return action;
};

const shouldShow = (location, settings) => {
  const { theme: { load_on_user_journey } } = settings;
  // const load_on_user_journey = true;
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  return load_on_user_journey &&
    query.hasOwnProperty('showJourneyResources') &&
    query.showJourneyResources === 'true';
};

const getResourceBackground = data => {
  const journeys = data.nodes ? data.nodes : [];
  const immersiveJourney = journeys.find(j => j.id === 2);
  const page = immersiveJourney && immersiveJourney.journeyPages && immersiveJourney.journeyPages.nodes
    ? immersiveJourney.journeyPages.nodes.find(p => p.renderTemplate.toLowerCase() === 'resources')
    : false;
  return page && page.backgroundImage ? page.backgroundImage.url : '';
};

const getJourneyResponseVars = (journeyId, answers = [], resources = []) => {
  const input = {
    journeyId,
    stringAnswers: [],
    stringArrayAnswers: [],
    booleanAnswers: [],
    resources: []
  };

  answers.forEach(a => {
    const answer = {
      questionId: a.id,
      value: a.value
    };

    if (a.multiple) {
      input.stringArrayAnswers.push(answer);
    } else {
      input.stringAnswers.push(answer);
    }
  });

  // dont include a resource id more than once //
  resources.forEach(r => !input.resources.includes(r.id) && input.resources.push(r.id));

  console.log(answers, resources);

  return input;
};

const filterResources = resources => {
  const list = [];

  resources.forEach(r => {
    let included = false;

    list.forEach(l => {
      if (l.id === r.id) included = true;
    });

    if (!included) list.push(r);
  });

  return list;
};

const youTubeRegex = new RegExp(/^https:\/\/www.youtube.com/);
const spotifyRegex = new RegExp(/^(https:\/\/open.spotify.com|https:\/\/spoti.fi)/);


const buildResourcesFromEvent = ({ eventByUuid } = {}) => {
  const responses = eventByUuid && eventByUuid.journeyResponses;

  // assuming madlib resources have lower ids because they are created first //
  // madlib resources should go after journey resources //
  if (responses) responses.sort((a, b) => (a.id < b.id) ? 1 : -1);

  const resources = [];
  const playlists = [];
  const videos = [];

  responses && responses.forEach(({ resources: responseResources } = {}) => {
    responseResources && responseResources.edges.forEach(({ node }) => {
      if (node.url && node.url.match(youTubeRegex)) {
        videos.push(node);
      } else if (node.url && node.url.match(spotifyRegex)) {
        playlists.push(node);
      } else {
        resources.push(node);
      }
    });
  });

  return { resources, playlists, videos };
};

const getBgImageFromAnswers = (pageData, eventData) => {
  const widgets = pageData && pageData.widgets && pageData.widgets.nodes;
  const responses = eventData && eventData.journeyResponses;

  // grab the second (immersive) of the two responses //
  if (responses) responses.sort((a, b) => (a.id < b.id) ? 1 : -1);
  const immersiveResponse = responses && responses[0];
  const answers = immersiveResponse && immersiveResponse.answers;

  let imageResult = '';
  let answerData = '';
  let response = '';

  widgets && widgets.forEach(({ actions, question }) => {
    const nodes = actions && actions.nodes;

    if (question) {
      answerData = answers && answers.nodes && answers.nodes.find(({ question: q }) => q && q.id === question.id);
      response = answerData && answerData.response && answerData.response.string;

      answerData && nodes && nodes.forEach(({ value, backgroundImage }) => {
        if (value && value.string === response && backgroundImage && backgroundImage.url)
          imageResult = backgroundImage.url;
      });
    }
  });

  return imageResult;
};

const getAnsweredQuestion = (currentPage, questionId, answerValue) => {
  const widgets = currentPage && currentPage.widgets && currentPage.widgets.nodes;
  const widget = widgets && widgets.find(w => w.question.id === questionId);
  
  if (widget) {
    return {
      name: widget.label,
      answer: widget.options.reduce((label, option) => (
        option.value.string === answerValue ? option.label : label
      ), '')
    };
  }
  
  return false;
};

export {
  store,
  load,
  resetAll,
  resetItem,
  getItem,
  setItem,
  getNextAction,
  shouldShow,
  getResourceBackground,
  getJourneyResponseVars,
  filterResources,
  buildResourcesFromEvent,
  getBgImageFromAnswers,
  youTubeRegex,
  spotifyRegex,
  getAnsweredQuestion
};
