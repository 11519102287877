import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Modal,
  Logo,
  FAIcon,
  withSettings
} from '../components';

import { isScheduledAtSameMonth, stringInsert, isScheduledAtSameWeek } from '../../libs';

class EventCreationPopups extends React.Component {
  state = {
    areas: null,
    area: null,
    selectedDate: null,
    displayModal: false,
    limitMessage: '',
    specialDateMessage: '',
    specialDateLinkText: '',
    specialDateLinkUrl: ''
  };

  componentDidMount() {
    const { session: { areas, viewer } } = this.props;
    const userArea = viewer.user && viewer.user.profile ? viewer.user.profile.area : null;
    const areaState = { areas };

    if (userArea) {
      areaState.area = userArea;
    } else {
      areaState.area = areas && areas.find(area => area.code === 'OTHER');
    }

    this.setState({ ...areaState });
  }

  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const {
      area,
      date,
      isUpdate,
      // isDuplicate,
      shouldSkipSponsorshipLimits: skipLimits,
      tracking,
      sponsorshipTrackingId,
      hostHasRechosenAfterLimit,
      virtual,
      eventData,
      settings: { copy, event: { virtual: virtualData } },
      onRemoveSponsorships
    } = this.props;

    const areaChanged = area !== nextProps.area;
    const dateChanged = nextProps.date !== null && !moment(date).isSame(moment(nextProps.date));
    const shouldRemoveSponsorships = virtualData.virtual_prevents_sponsorship && eventData && eventData.sponsorships.length;

    // check virtual has changed and sponsorships arent eligible //
    if (virtual === false && nextProps.virtual === true && shouldRemoveSponsorships) {
      this.setState({
        displayModal: true,
        limitMessage: copy.event.virtual_deleting_sponsorship_warning
      });
      onRemoveSponsorships(true, true);
      return;
    }

    // otherwise reset //
    else if (virtual === true && nextProps.virtual === false) {
      onRemoveSponsorships(false, true);
    }

    let hasReachedLimit = false;
    let hasSpecialDate = false;
    let changedValue = null;

    // skip checks when initializing component on update //
    // (props are passed in on init, dont want to trigger message modal) //
    // const loading = (isUpdate || isDuplicate) && (area === null || date === null);
    const loading = isUpdate && (area === null || date === null);

    // check limits when area is updated //
    if (areaChanged) {
      await this.setState({ area: nextProps.area }, () => {
        if (loading) return;
        if (!skipLimits) {
          hasReachedLimit = this.checkLimits();
        }
        changedValue = 'area';

        hasSpecialDate = this.checkSpecialAreas();
      });
    }

    // check limits / special dates when date is updated //
    if (dateChanged) {
      await this.setState({ selectedDate: nextProps.date }, () => {
        if (loading) return;
        if (!skipLimits) {
          hasReachedLimit = this.checkLimits();
        }
        changedValue = 'date';
        hasSpecialDate = this.checkSpecialDates();
      });
    }

    if (hasReachedLimit || hasSpecialDate) this.setState({ displayModal: true });

    if ((dateChanged || areaChanged) && sponsorshipTrackingId && !hasReachedLimit) {
      tracking.update({
        id: sponsorshipTrackingId,
        nextActionTaken: `Selected new ${changedValue} with nourishment available.`,
        nextDateSelected: this.state.selectedDate
      });

      if (!hostHasRechosenAfterLimit) tracking.setRechosenState(true);
    }
  };

  checkSpecialAreas = () => {
    const { area } = this.state;
    const { specialAreas } = this.props;

    let hasSpecialArea = false;

    if (Array.isArray(specialAreas) && area && area.code) {
      const foundArea = specialAreas.find((specialArea) => specialArea.area === area.code);
      const foundAreaPopup = foundArea && foundArea.popups.length ? foundArea.popups[0] : false;

      hasSpecialArea = !!foundAreaPopup;

      if (hasSpecialArea) {
        this.setState({
          specialDateMessage: foundAreaPopup.content,
          specialDateLinkText: foundAreaPopup.label,
          specialDateLinkUrl: foundAreaPopup.url
        })
      }
    }

    if (!hasSpecialArea) this.setState({ specialDateMessage: '', specialDateLinkText: '', specialDateLinkUrl: '' });

    return hasSpecialArea;
  }

  checkSpecialDates = () => {
    const { selectedDate } = this.state;
    const { specialDates } = this.props;
    let hasSpecialDate = false;

    if (Array.isArray(specialDates)) {
      specialDates.forEach(async specialDate => {
        const { date } = specialDate;

        if (moment(date).isSame(moment(selectedDate), 'day')) {
          hasSpecialDate = true;
          await this.setState({
            specialDateMessage: specialDate.content,
            specialDateLinkText: specialDate.label,
            specialDateLinkUrl: specialDate.url
          });
        }
      });
    }

    if (!hasSpecialDate) this.setState({ specialDateMessage: '', specialDateLinkText: '', specialDateLinkUrl: '' });
    return hasSpecialDate;
  };

  checkLimits = () => {
    const { selectedDate, area } = this.state;
    const { eventData, settings, onRemoveSponsorships, isSponsored, tracking, sponsorshipTrackingId } = this.props;
    const { session: { viewer } } = this.props;

    const hostApprovedAt = viewer && viewer.user ? viewer.user.hostApprovalAt : null;

    const copy = settings && settings.copy;
    let hasReachedLimit = true;
    let limitMessage = '';
    let trackingEventType = '';

    const numberOfDays = (area && area.newHostNourishmentDelayDays) ? area.newHostNourishmentDelayDays : 0;
    const newHostNourishmentDelayDays = this.newHostCanCreateEventsFromDateOn(hostApprovedAt, numberOfDays);

    if (this.checkNourishmentHasExpired()) {
      // Passed Nourishment Date Cutoff //
      onRemoveSponsorships(true);

      limitMessage = copy.nourishment_expired.so_close;
      trackingEventType = 'NOURISHMENT_DEADLINE_PASSED';

    } else if (!this.newHostCutoffReached()) {
      // New Host Cutoff Not Reached //
      onRemoveSponsorships(true);

      const dateFormat = 'MM/DD/YYYY';
      const dateAsString = newHostNourishmentDelayDays.format(dateFormat);

      limitMessage = isSponsored
        ? stringInsert(copy.nourishment_limit.new_host_deleting_sponsorships_warning, dateAsString)
        : stringInsert(copy.nourishment_limit.new_host, dateAsString);

      trackingEventType = 'NEW_HOST_NOURISHMENT_DELAY';

    } else if (this.checkOneEventPerMonth()) {
      // Once A Month Limit Reached //
      onRemoveSponsorships(true);

      limitMessage = isSponsored
        ? copy.nourishment_limit.once_per_month_deleting_sponsorships_warning
        : copy.nourishment_limit.once_per_month;
      trackingEventType = 'ONE_NOURISHMENT_PER_WEEK_LIMIT';

    } else if (this.checkOneEventPerWeek()) {
      onRemoveSponsorships(true);

      limitMessage = isSponsored
        ? copy.nourishment_limit.once_per_week
        : copy.nourishment_limit.area_date;

      trackingEventType = 'WEEKLY_NOURISHMENT_LIMIT';
    } else {
      onRemoveSponsorships(false);
      hasReachedLimit = false;
    }

    if (hasReachedLimit) {
      // Update provious tracking if new date with limits is chosen //
      if (sponsorshipTrackingId) {
        tracking.update({
          id: sponsorshipTrackingId,
          nextActionTaken: 'Chose another date with limits.',
          nextDateSelected: selectedDate
        });
      }

      // Start tracking if limit is reached //
      const trackingProps = {
        trackingEventType,
        dateSelected: selectedDate
      };

      if (eventData && eventData.id) trackingProps.eventId = eventData.id;

      tracking.start('sponsorship', trackingProps);
    }

    this.setState({ limitMessage });
    return hasReachedLimit;
  };

  checkNourishmentHasExpired = () => {
    const { selectedDate, areas, area } = this.state;
    let lockedLimit = null;

    if (!selectedDate || !areas || !area) {
      return false;
    }

    areas.forEach(a => {
      if (a.code === area.code) lockedLimit = a.nourishmentLockDaysBeforeEvent;
    });

    if (!lockedLimit) {
      return false;
    }
    return moment(selectedDate).subtract(lockedLimit, 'days').endOf('day').isBefore(moment().endOf('day'));
  };

  newHostCanCreateEventsFromDateOn = (hostApprovedAt, delayDaysUntilNewHostCanCreateEvents) => {
    if (delayDaysUntilNewHostCanCreateEvents) {
      return moment(hostApprovedAt).add(delayDaysUntilNewHostCanCreateEvents, 'days').startOf('day');
    } else {
      return null;
    }
  };

  newHostCutoffReached = () => {
    const { selectedDate, areas, area } = this.state;
    const { session: { viewer } } = this.props;
    const hostApprovedAt = viewer && viewer.user ? viewer.user.hostApprovalAt : null;

    if (!areas || !area) {
      return true;
    }

    const selectedAreaInfo = areas.find(a => a.code === area.code);
    const delayDaysUntilNewHostCanCreateEvents = selectedAreaInfo.newHostNourishmentDelayDays;

    const limitDateToBeAbleToCreateEvents = this.newHostCanCreateEventsFromDateOn(hostApprovedAt, delayDaysUntilNewHostCanCreateEvents);

    let cutoffReached = false;

    if (limitDateToBeAbleToCreateEvents) {
      cutoffReached = !(moment(selectedDate).startOf('day').isBefore(limitDateToBeAbleToCreateEvents));
    } else {
      cutoffReached = true;
    }

    return cutoffReached;
  };

  checkOneEventPerWeek = () => {
    const { selectedDate } = this.state;
    const { session: { viewer } } = this.props;
    const viewerEvents = viewer.hostedEvents && viewer.hostedEvents.edges ? viewer.hostedEvents.edges : [];
    let limitReached = false;
    if (selectedDate && viewerEvents.length) {
      viewerEvents.forEach(eventObj => {
        const event = eventObj.node;
        limitReached = isScheduledAtSameWeek(event, selectedDate);
      });
    }
    return limitReached;
  };

  checkOneEventPerMonth = () => {
    const { selectedDate, area } = this.state;

    const { session: { viewer } } = this.props;
    const isLimited = this.isLimitedToOnceAMonth();
    const viewerEvents = viewer.hostedEvents && viewer.hostedEvents.edges ? viewer.hostedEvents.edges : [];
    let limitReached = false;

    if (isLimited && selectedDate && area && viewerEvents.length) {
      viewerEvents.forEach(eventObj => {
        const event = eventObj.node;
        if (isScheduledAtSameMonth(event, selectedDate)) limitReached = true;
      });
      return limitReached;
    } else {
      return limitReached;
    }
  };

  isLimitedToOnceAMonth = () => {
    const { areas, area } = this.state;
    let isLimited = false;

    if (areas && area) areas.forEach(a => {
      if (a.code === area.code) isLimited = a.limitOneNourishmentPerMonth;
    });

    return isLimited;
  };

  toggleModal = () => {
    this.setState(prevState => ({
      displayModal: !prevState.displayModal
    }));
  };

  getSpecialDateClass = () => {
    const {
      specialDateMessage,
      specialDateLinkText,
      specialDateLinkUrl
    } = this.state;

    if (specialDateMessage && specialDateLinkText && specialDateLinkUrl) return 'message_and_link';
  };

  render() {
    const {
      displayModal,
      limitMessage,
      specialDateMessage,
      specialDateLinkText,
      specialDateLinkUrl
    } = this.state;
    const { className } = this.props;

    const hasSpecialDateLink = specialDateLinkText && specialDateLinkUrl;
    const hasSpecialDate = specialDateMessage || hasSpecialDateLink;

    return (
      <Modal
        show={displayModal}
        toggle={this.toggleModal}
        backdrop="static"
        size="sm"
        noPadding
      >
        <div className={className}>
          <Logo layout="vertical"/>
          {hasSpecialDate && (
            <div className={`special_date ${this.getSpecialDateClass()}`}>
              {specialDateMessage && <p>{specialDateMessage}</p>}
              {hasSpecialDateLink && <a href={specialDateLinkUrl} target="_blank" rel="noopener noreferrer">{specialDateLinkText}</a>}
            </div>
          )}
          {hasSpecialDate && limitMessage && (
            <hr className="message_divider"/>
          )}
          {limitMessage && (
            <p>
              <FAIcon name={['far', 'exclamation-circle']}/>
              {limitMessage}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}

const StyledEventCreationPopups = styled(EventCreationPopups)`
  padding: 40px 30px 30px;

  .logo {
    display: block;
    max-width: 147px;
    margin: 0 auto 30px;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;

    svg {
      color: #8c8c8c;
      font-size: 12px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .special_date {
    text-align: center;

    a {
      font-size: 12px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-weight: bold;
      margin: 0;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }

    &.message_and_link {
      p {
        margin-bottom: 7px;
      }
    }
  }

  hr {
    margin: 25px 0;
  }
`;

const EventCreationPopupsWithSettings = withSettings(StyledEventCreationPopups);

export { EventCreationPopupsWithSettings as EventCreationPopups };
