import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  EventList,
  EventHero,
  SearchAndFilter,
  withSettings,
  withClient
} from '../components';

import { usePaginatedEvents } from '../../hooks';
import { isSite } from '../../libs';

import { SessionContext } from '../../Context';

import { EVENT_SUBTYPES } from '../../queries';

import Html from 'html-to-react';
const htmlParser = new Html.Parser();


const EventListAllPage = ({ className, settings, client, archive = false }) => {
  const { copy } = settings;
  const [filters, setFilters] = useState({});
  const { areas } = useContext(SessionContext);
  const initialSelectedArea = isSite(settings, ['thegathering', 'whitelabel', 'limitedwhitelabel'])
    ? areas.find(a => a.neighborhoods && a.neighborhoods.length)
    : null;
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedArea, setSelectedArea] = useState(initialSelectedArea);
  const variables = { scheduledAtRelativeToNow: archive ? 'PAST' : 'UPCOMING', ...filters };

  const { loading: eventsLoading, /*error,*/ fetchMore, pagination } = usePaginatedEvents(client, { variables });
  const { events, pageInfo, totalEventsCount, hasMoreEvents, numberOfItems } = pagination;

  const { data: subtypesData } = useQuery(EVENT_SUBTYPES);
  const subtypes = subtypesData && subtypesData.eventSubtypes;

  // if (error) console.error(error);

  const images = settings.theme.assets.images;

  const handleLoadMoreEvents = () => {
    setLoadingMore(true);

    return fetchMore({
      variables: { ...variables, numberOfItems, cursor: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEvents = fetchMoreResult.events;
        const prevEvents = previousResult.events;
        pageInfo.endCursor = newEvents.pageInfo.endCursor;

        return {
          events: {
            pageInfo,
            edges: [...prevEvents.edges, ...newEvents.edges],
            __typename: prevEvents.__typename,
          }
        };
      }
    }).then(() => setLoadingMore(false));
  };
  
  const heroTitle = archive
    ? copy.landing.archive_all_event_list_title || 'All Archived Dinners'
    : copy.landing.all_event_list_title;

  const altText = settings.theme.site.all_events_page_hero_alt || heroTitle;

  useEffect(() => {
    window.dataLayer?.push({ event: 'all_events_page_view' });
  }, []);

  return (
    <div className={className}>
      <EventHero 
        id='all_event_view_header' 
        title={heroTitle} 
        headerImg={images.all_events_header || images.tag_default_header} 
        headerAltText={altText}
      />
      <SearchAndFilter
        addFiltersToQuery={f => setFilters(f)}
        area={selectedArea}
        subtypes={subtypes}
        selectArea={a => setSelectedArea(a)}
        allEventsPage
      />
      {copy.landing.all_event_list_description ? (
        <div className="area_banner__container">
          {htmlParser.parse(copy.landing.all_event_list_description)}
          <i className="divider" />
        </div>
      ) : <i className="divider" />}
      <div>
        <EventList
          events={events}
          loading={eventsLoading}
          loadingMore={loadingMore}
          showArea={true}
          hasMoreEvents={hasMoreEvents}
          totalEventsCount={totalEventsCount}
          onLoadMore={() => handleLoadMoreEvents()}
          archive={archive}
        />
      </div>
    </div>
  );
};

const StyledEventListAllPage = styled(EventListAllPage)`
  & > .header {
    position: relative;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    padding: 170px 0;

    h1 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      margin: 0;
      text-transform: capitalize;
    }
  }
  & > p {
    max-width: 1100px;
    padding: 0 30px;
    text-align: center;
    margin: 60px auto 40px;
    font-size: 14px;

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
        text-decoration: none;
      }
    }
  }
  .no-items {
    display: flex;
    justify-content: center;
  }

  .divider {
    margin-top: 50px;
  }

  .area_banner__container {
    margin: 50px 0 40px;

    p {
      max-width: 1100px;
      padding: 0px 30px;
      text-align: center;
      margin: 0 auto 20px;
      font-size: 14px;
    }

    .divider {
      margin: 40px auto;
    }
  }
`;

const EventListAllPageWithRouter = withRouter(StyledEventListAllPage);
const EventListAllPageWithSettings = withSettings(EventListAllPageWithRouter);
const EventListAllPageWithClient = withClient(EventListAllPageWithSettings);

export { EventListAllPageWithClient as EventListAllPage };
