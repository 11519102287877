import { usePaginatedReservations, usePaginatedInvitations } from '../hooks';

export const usePaginatedGuests = (event, queryVars) => {
  const {
    reservations,
    pagination: resPagination,
    fetchMoreLoading: resFetchMoreLoading,
    loading: resLoading,
    refreshReservations
  } = usePaginatedReservations(event, queryVars);

  const {
    invitations,
    pagination: invPagination,
    fetchMoreLoading: invFetchMoreLoading,
    loading: invLoading,
    refreshInvitations,
    refetchPendingInvitations
  } = usePaginatedInvitations(event, queryVars);

  const pagination = {
    reservation: resPagination,
    invitation: invPagination
  };

  const fetchMoreLoading = {
    reservation: resFetchMoreLoading,
    invitation: invFetchMoreLoading
  };

  const loading = {
    reservation: resLoading,
    invitation: invLoading
  };

  return {
    reservations,
    invitations,
    refreshReservations,
    refreshInvitations,
    pagination,
    fetchMoreLoading,
    loading,
    refetchPendingInvitations
  };
};
