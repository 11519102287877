import React, { useContext } from 'react';

import styled from 'styled-components';

import {
  LandingPageContext,
  LandingHeroSearch,
  LandingHeroCount
} from '../components';


const LandingHero = ({ className }) => {
  const { heroImage } = useContext(LandingPageContext);

  return (
    <div className={className}>
      {heroImage && <BackgroundImage {...heroImage} />}
      <LandingHeroSearch />
      <LandingHeroCount />
    </div>
  );
};

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 0;
`;

const StyledLandingHero = styled(LandingHero)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  min-height: 500px;
  position: relative;
  transition: min-height 150ms;

  @media (max-width: 500px) {
    min-height: 600px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #00000040;
  }
`;
export { StyledLandingHero as LandingHero };
