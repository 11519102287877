import React from 'react';
import styled from 'styled-components';

import { useSession } from '../../hooks';

import {
  FAIcon,
  ShareFacebook,
  ShareTwitter,
  PageSubheader as Header,
  withSettings
} from '../components';

const SocialShareEvent = ({ className, event, settings }) => {
  const { theme } = useSession();
  const { copy } = settings;

  const handleFacebookClick = () => {
    ShareFacebook.openModal(event, settings);
  };

  const handleTwitterClick = () => {
    ShareTwitter.openModal(event.shortUrl);
  };

  const ModalTitle = ({ children }) => theme.isVersion(2)
    ? <Header small>{children}</Header>
    : <h3>{children}</h3>;

  return (
    <div className={`${className} ot_social_share`}>
      <ModalTitle>{copy.manage.social_share}</ModalTitle>
      <div className="share_container">
        <button className="facebook" onClick={handleFacebookClick}>
          <FAIcon name={['fab', 'facebook-f']} />
        </button>
        <button className="twitter" onClick={handleTwitterClick}>
          <FAIcon name={['fab', 'twitter']} />
        </button>
        <a href={event.shortUrl} target="_blank" rel="noopener noreferrer">{event.shortUrl}</a>
      </div>
    </div>
  );
}

const StyledSocialShareEvent = styled(SocialShareEvent)`
  h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .share_container {
    &::after {
      content: '';
      display: block;
      clear: both;
    }

    button {
      appearance: none;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      padding: 0 10px;
      height: 36px;
      width: 50px;
      margin-right: 10px;
      float: left;
      transition: color 250ms;

      &.facebook {
        color: ${props => props.settings.theme.css.global.colors.facebook};
      }
      &.twitter {
        color: ${props => props.settings.theme.css.global.colors.twitter};
      }

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primary} !important;
      }
    }

    a {
      font-size: 14px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      float: right;
      max-width: calc(100% - 140px);
      word-break: break-all;
      text-align: right;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
        text-decoration: none;
      }
    }
  }
`;

const StyledSocialShareEventWithSettings = withSettings(StyledSocialShareEvent);

export { StyledSocialShareEventWithSettings as SocialShareEvent };
