import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { withSettings } from '../components';

import {
  EVENT_FILTER
} from '../../enums';

import { breakpoints } from '../../styles';


class ManageEventsDetailsFilter extends React.Component {
  render() {
    const { className, filter, count } = this.props;
    const { LABEL, URL } = EVENT_FILTER.FIELDS;

    return(
      <div className={className}>
        <Link to={`/manage/${EVENT_FILTER.getField(URL, filter)}`}>
          <p>{EVENT_FILTER.getField(LABEL, filter)}</p>
          <span>{count && count.totalCount}</span>
        </Link>
      </div>
    );
  }
}


const StyledManageEventsDetailsFilter = styled(ManageEventsDetailsFilter)`
  @media (${breakpoints.tabletSmall}) {
    // display: inline-block;
    text-align: center;
     cursor: pointer;
  }

  a {
    display: flex;
    padding: 22px 17px;
    cursor: pointer;
    transition: 0.2s;
    text-decoration: none;

    @media (${breakpoints.tablet}) {
      padding: 22px 25px 22px 0;
    }

    @media (${breakpoints.tabletSmall}) {
      padding: 20px 10px;
      display: inline-block;
      text-align: center;
    }
  }

  p {
    align-self: center;
    margin-bottom: 0px;
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    transition: 0.2s;

    @media (${breakpoints.tablet}) {
      font-size: 12px;
      margin-right: 7px;
    }

    @media (${breakpoints.tabletSmall}) {
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
    }
  }

  span {
    align-self: center;
    background-color: #d4cdc7;
    padding: 2px 6px;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.03em;
    transition: 0.2s;

    @media (${breakpoints.tablet}) {
      font-size: 10px;
    }

    @media (${breakpoints.mobile}) {
      font-size: 12px;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: #fafafa;

    @media (${breakpoints.tablet}) {
      background-color: #fff;
    }

    p {
      color: ${props => props.settings.theme.css.global.colors.primary};
    }

    span {
      background-color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }
`;

const ManageEventsDetailsFilterWithSettings = withSettings(StyledManageEventsDetailsFilter);

export { ManageEventsDetailsFilterWithSettings as ManageEventsDetailsFilter };
