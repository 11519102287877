import React from 'react';
import { Query } from 'react-apollo';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { EVENT_CREATION_QUESTIONS } from '../../../queries';
import { imEventHost } from '../../../libs';

import {
  EventCreationQuestions,
  EventCreationForm,
  Loader,
  withClient,
  withSettings,
  withSession
} from '../../components';

import {
  EVENT_BY_UUID_FOR_UPDATE,
  DATE_POPUPS,
  STATE_SOCIAL_DISTANCE_POLICIES,
  EVENT_PRIVACY_SUBTYPES,
  EVENT_CREATION_JOURNEY_RESPONSE_IDS
} from '../../../queries';
import {
  CREATE_EVENT_CREATION_TRACKING,
  UPDATE_EVENT_CREATION_TRACKING,
  DESTROY_EVENT_CREATION_TRACKING
} from '../../../mutations';

const pageTransition = 500;

class EventCreationPage extends React.Component {
  state = {
    view: 'questions',
    fadeQuestions: false,
    fadeForm: false,
    creationAnswers: [],
    defaultType: '',
    defaultDescription: null,
    specialDates: [],
    specialAreas: [],
    creationTrackingId: null,
    sponsorshipTrackingId: null,
    hostHasRechosenAfterLimit: false,
    stateSocialDistancePolicies: null,
    communityFromImmersive: false,
    journeyResponseIds: null,
    choices: [],
    eventPrivacySubtypes: []
    // createEventQuestionsFetched: false
  };

  componentDidMount() {
    const { update, settings, location, history, isCommunity } = this.props;
    const {
      theme: { load_on_user_journey },
      features: { event_creation_questions }
    } = settings;
    // const load_on_user_journey = true;

    const questionsEnabled = !!event_creation_questions;
    const fromImmersive = location.state && location.state.immersive;
    const communityFromImmersive = location.state && location.state.isCommunity;
    const journeyResponseIds = location.state && location.state.journeyResponseIds;
    const choices = location.state && location.state.choices;
    const duplicateEventUuid = location.state && location.state.duplicateEventUuid;

    if (load_on_user_journey && !fromImmersive && !update && !duplicateEventUuid)
      history.push({
        pathname: '/journey',
        state: { returnToCreation: true, isCommunity }
      });

    const newState = {};

    if (communityFromImmersive) newState.communityFromImmersive = communityFromImmersive;
    if (journeyResponseIds) newState.journeyResponseIds = journeyResponseIds;
    if (choices) newState.choices = choices;
    // load_on_user_journey used here so it skips intention setter //
    if (update || duplicateEventUuid || load_on_user_journey || !questionsEnabled)
      newState.view = 'form';

    Promise.all([
      this.loadSpecialDates(),
      this.loadSpecialAreas(),
      this.loadStateSocialDistancePolicies(),
      this.loadEventPrivacySubtypes(),
      this.maybeLoadEventResources()
    ]).then(() => this.setState({ ...newState, loaded: true }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { update } = this.props;

    if (update !== nextProps.update && !nextProps.update) {
      this.setState({ view: 'questions' });
    }
  }

  loadSpecialDates = async () => {
    const { client } = this.props;
    const { data } = await client.query({ query: DATE_POPUPS });
    if (data.datePopups) this.setState({ specialDates: data.datePopups });
  };

  loadSpecialAreas = async () => {
    const {
      session: { areas }
    } = this.props;
    const specialAreas = areas.reduce((areaPopups, area) => {
      if (area.areaPopups.nodes && area.areaPopups.nodes.length) {
        areaPopups.push({ area: area.code, popups: area.areaPopups.nodes });
      }
      return areaPopups;
    }, []);
    if (specialAreas.length) this.setState({ specialAreas });
  };

  loadEventPrivacySubtypes = async () => {
    const { client } = this.props;
    const { data } = await client.query({ query: EVENT_PRIVACY_SUBTYPES });
    if (data.privacySubtypes) this.setState({ eventPrivacySubtypes: data.privacySubtypes });
  };

  loadStateSocialDistancePolicies = async () => {
    const { client } = this.props;
    const { data } = await client.query({
      query: STATE_SOCIAL_DISTANCE_POLICIES
    });
    if (data.stateSocialDistancePolicies)
      this.setState({
        stateSocialDistancePolicies: data.stateSocialDistancePolicies
      });
  };

  maybeLoadEventResources = async () => {
    const { client, location } = this.props;
    const uuid = location.state && location.state.duplicateEventUuid;

    // uuid will exist when duplicating events //
    if (!uuid) return;

    // get resources from old event to save with the new //
    const { data } = await client.query({
      query: EVENT_CREATION_JOURNEY_RESPONSE_IDS,
      variables: { uuid },
      errorPolicy: 'all'
    });

    const journeyResponses = data && data.eventByUuid && data.eventByUuid.journeyResponses;

    if (journeyResponses)
      this.setState({
        journeyResponseIdsToDuplicate: journeyResponses.map((r) => r.id)
      });
  };

  handleSetDefaults = (name, value) => {
    this.setState({ [name]: value });
  };

  handleGoToForm = (creationAnswers) => {
    const { history, location } = this.props;

    this.setState({ creationAnswers, fadeQuestions: true }, () => {
      setTimeout(() => {
        this.setState({ view: 'form' }, () => {
          history.push(location.pathname);
          setTimeout(() => this.setState({ fadeForm: true }), 10);
        });
      }, pageTransition + 10);
    });
  };

  startTracking = async (type, vars) => {
    const { client } = this.props;
    const trackingState = type === 'creation' ? 'creationTrackingId' : 'sponsorshipTrackingId';

    const response = await client
      .mutate({
        mutation: CREATE_EVENT_CREATION_TRACKING,
        variables: { ...vars }
      })
      .catch((err) => console.error('startTracking', err));

    if (response && response.data) {
      const trackingId =
        response.data.createEventCreationTrackingEvent &&
        response.data.createEventCreationTrackingEvent.id;
      if (trackingId)
        this.setState({
          [trackingState]: trackingId,
          hostHasRechosenAfterLimit: false
        });
    }
  };

  updateTracking = (vars) => {
    const { client } = this.props;

    client
      .mutate({
        mutation: UPDATE_EVENT_CREATION_TRACKING,
        variables: { ...vars }
      })
      .catch((err) => console.error('updateTracking', err));
  };

  destroyTracking = (id) => {
    const { client } = this.props;

    client
      .mutate({
        mutation: DESTROY_EVENT_CREATION_TRACKING,
        variables: { id }
      })
      .catch((err) => console.error('destroyTracking', err));
  };

  setHostRechosenState = (value) => {
    this.setState({ hostHasRechosenAfterLimit: value });
  };

  // see render function
  // handleQuestionsFetched = questions => {
  //   const {  update } = this.props;
  //   const view = ((questions.length === 0) || update) ? 'form' : 'questions';
  //
  //   this.setState({
  //     createEventQuestionsFetched: true,
  //     view: view
  //   });
  // }

  renderComponent = (loadingQuestions, questions) => {
    const {
      className,
      isCommunity,
      update,
      computedMatch,
      loggedUser,
      location,
      session: { viewer, areas }
    } = this.props;

    const duplicateEventUuid = location.state && location.state.duplicateEventUuid;

    const {
      view,
      fadeQuestions,
      fadeForm,
      creationAnswers,
      defaultType,
      defaultDescription,
      specialDates,
      specialAreas,
      creationTrackingId,
      sponsorshipTrackingId,
      hostHasRechosenAfterLimit,
      stateSocialDistancePolicies,
      communityFromImmersive,
      journeyResponseIds,
      choices,
      eventPrivacySubtypes,
      journeyResponseIdsToDuplicate,
      loaded
    } = this.state;

    const tracking = {
      start: this.startTracking,
      update: this.updateTracking,
      destroy: this.destroyTracking,
      setRechosenState: this.setHostRechosenState
    };

    if (!loaded) return <Loader />;

    return (
      <div className={className} id='event_creation_page'>
        {view === 'questions' && (
          <EventCreationQuestions
            loading={loadingQuestions}
            questions={questions}
            className={`questions_page ${fadeQuestions ? 'fade_questions' : ''}`}
            setDefaults={this.handleSetDefaults}
            goToForm={(answers) => this.handleGoToForm(answers)}
            tracking={tracking}
            trackingId={creationTrackingId}
            isCommunity={isCommunity || communityFromImmersive}
          />
        )}
        {!update && !duplicateEventUuid && view === 'form' && (
          <EventCreationForm
            className={`form_page ${fadeForm ? 'fade_form' : ''}`}
            defaults={{ defaultType, defaultDescription }}
            creationAnswers={creationAnswers}
            isCommunity={isCommunity || communityFromImmersive}
            specialDates={specialDates}
            specialAreas={specialAreas}
            tracking={tracking}
            creationTrackingId={creationTrackingId}
            sponsorshipTrackingId={sponsorshipTrackingId}
            hostHasRechosenAfterLimit={hostHasRechosenAfterLimit}
            viewer={loggedUser}
            areas={areas}
            stateSocialDistancePolicies={stateSocialDistancePolicies}
            journey={{ journeyResponseIds, choices }}
            eventPrivacySubtypes={eventPrivacySubtypes}
          />
        )}
        {(update || duplicateEventUuid) && view === 'form' && (
          <Query
            query={EVENT_BY_UUID_FOR_UPDATE}
            partialRefetch={true}
            variables={{
              uuid: duplicateEventUuid || computedMatch.params.uuid,
              isHost: true,
              isRegistered: false,
              isLogged: true
            }}
          >
            {({ data, loading }) => {
              if (loading) return <Loader />;

              const eventData = data && data.event;

              if (!eventData || !imEventHost(eventData, viewer)) return <Redirect to='/events' />;

              return (
                <EventCreationForm
                  className='form_page'
                  eventData={eventData}
                  isCommunity={isCommunity || communityFromImmersive}
                  specialDates={specialDates}
                  specialAreas={specialAreas}
                  tracking={tracking}
                  sponsorshipTrackingId={sponsorshipTrackingId}
                  hostHasRechosenAfterLimit={hostHasRechosenAfterLimit}
                  viewer={loggedUser}
                  areas={areas}
                  wasPaidEventBeforeEditing={data.event.pricePerPerson > 0}
                  eventPrivacySubtypes={eventPrivacySubtypes}
                  journey={{
                    journeyResponseIds: journeyResponseIdsToDuplicate
                  }}
                  isUpdate={!duplicateEventUuid}
                  isDuplicate={!!duplicateEventUuid}
                />
              );
            }}
          </Query>
        )}
      </div>
    );
  };

  render() {
    const { settings } = this.props;

    const questionsEnabled =
      settings && settings.features && settings.features.event_creation_questions;

    if (!questionsEnabled) {
      return this.renderComponent(false);
    } else {
      return (
        <Query query={EVENT_CREATION_QUESTIONS}>
          {({ data, loading, error }) => {
            // const { eventCreationQuestions: q } = data;
            const q = data ? data.eventCreationQuestions : null;
            const questions = q ? q : [];
            if (error) console.error(error);

            /* shouldn't set state within render..
             i don't currently see the point in this check
             removing to clear react error
             if (!this.state.createEventQuestionsFetched) {
               this.handleQuestionsFetched(questions);
               return null;
             } */

            return this.renderComponent(loading, questions);
          }}
        </Query>
      );
    }
  }
}

const StyledEventCreationPage = styled(EventCreationPage)`
  & > .questions_page,
  & > .form_page {
    transition: opacity ${pageTransition}ms;
  }

  & > .questions_page {
    &.fade_questions {
      opacity: 0;
    }
  }

  & > .form_page {
    // opacity: 0;

    &.fade_form {
      opacity: 1;
    }
  }
`;

const EventCreationPageWithRouter = withRouter(StyledEventCreationPage);
const EventCreationPageWithRouterAndClient = withClient(EventCreationPageWithRouter);
const EventCreationPageWithRouterClientAndSettings = withSettings(
  EventCreationPageWithRouterAndClient
);
const EventCreationPageWithSession = withSession(EventCreationPageWithRouterClientAndSettings);

export { EventCreationPageWithSession as EventCreationPage };
