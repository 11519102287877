import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { SessionContext } from '../../../Context';

import {
  EventCardHeader,
  EventCardBody,
  RestrictedDinnerModal,
  withSettings
} from '../../components';

import {
  imHost,
  imReserved,
  imInvited
} from '../../../libs';

import { EVENT_TYPE } from '../../../enums';

const EventCard = (props) => {
  const { className, event, history, showArea } = props;
  const [state, setState] = useState({ showModal: false, restrictedDinnerType: EVENT_TYPE.PRIVATE });

  const displayModal = restrictedDinnerType => {
    setState({ showModal: true, restrictedDinnerType });
  }

  const handleToggle = () => {
    setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  const handleCardClick = (viewer, viewerReservations, viewerInvitations) => {
    const hosts = event.eventHosts.edges;
    if (
      (event.eventType === EVENT_TYPE.PRIVATE || event.eventType === EVENT_TYPE.SOLO) && // private/solo events
      !imHost(hosts, viewer) && // viewer is not host
      !imReserved(event, viewerReservations) && // no reservation or viewer's reservation is not accepted
      !imInvited(event, viewerInvitations) // viewer is not invited
    ) {
      displayModal(event.eventType);
    } else {
      history.push(`/events/${event.uuid}`);
    }
  };

  return (
    <SessionContext.Consumer>
      {({ session }) => {
        const { viewer } = session;
        const viewerReservations = viewer && viewer.reservations ? viewer.reservations.nodes : [];
        const viewerInvitations = viewer && viewer.invitations ? viewer.invitations.nodes : [];

        return (
          <>
            <div
              className={`${className} ot_event_card`}
              onClick={() => handleCardClick(viewer, viewerReservations, viewerInvitations)}
            >
              <EventCardHeader
                event={event}
                viewer={viewer}
                viewerReservations={viewerReservations}
              />
              <EventCardBody
                event={event}
                viewer={viewer}
                showArea={showArea}
              />
            </div>
            {state.showModal && <RestrictedDinnerModal show={state.showModal} toggle={handleToggle} type={state.restrictedDinnerType} />}
          </>
        );
      }}
    </SessionContext.Consumer>
  );
}


const StyledEventCard = styled(EventCard)`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 270px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: ${props => props.settings.theme.css.global.card.shadow};
  cursor: pointer;
  transition: box-shadow 250ms;
  background-color: #fff;
  opacity: 0;

  &:hover {
    box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
  }
`;

const EventCardWithRouter = withRouter(StyledEventCard);
const EventCardWithSettings = withSettings(EventCardWithRouter);

export { EventCardWithSettings as EventCard };
