const itemName = 'user-otp';
const storage = window.localStorage;

const defaultConfig = {
  requiresOtp: false
};

const load = () => {
  return JSON.parse(storage.getItem(itemName)) || {};
};

const store = (config) => {
  const current = load();
  if (Object.keys(config).length > 0) {
    storage.setItem(itemName, JSON.stringify({
      ...defaultConfig,
      ...current,
      ...config
    }));
  }
};

const getItem = (item) => {
  const config = load();
  return config && config[item];
};

const setItem = (item, value) => {
  const config = load();    
  config[item] = value;
  store(config);
};

const resetAll = () => {
  storage.removeItem(itemName);
};

const resetItem = (item) => {
  setItem(item, defaultConfig[item] || null);
};


export {
  store,
  load,
  resetAll,
  resetItem,
  getItem,
  setItem  
};