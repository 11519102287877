import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Card,
  Attendees,
  withSettings
} from '../components';

import { generators } from '../../libs';

const EventViewTables = ({ className, display, event, settings }) => {
  const { copy } = settings;
  const images = settings.theme.assets.images;
  const tables = event.tables;

  return display
    ? (
      <Card className={className} id="tables">
        <h2 className="card_title">{copy.event.tables}</h2>
        <i className="divider" />
        <div className="tables">
          {tables.map(table => {
            const reservations = table.reservations.nodes;
            const hasReservations = Array.isArray(reservations) && reservations.length > 0;

            return (
              <Card className="table" key={table.id} noPadding>
                <div className="table_head">
                  <div className="table_head__texture" style={{ backgroundImage: `url(${images.pattern})` }} />
                </div>
                <div className="table_body">
                  <h4>{table.name}</h4>
                  {hasReservations
                    ? (
                      <Fragment>
                        <h5>{copy.event.attendants_table_title}</h5>
                        <Attendees reservations={reservations} size="sm" />
                      </Fragment>
                    ) : (
                      <div className="be_the_first">{copy.event.empty_table}</div>
                    )
                  }
                </div>
              </Card>
            );
          })}
        </div>
      </Card>
    ) : null;
};

const StyledEventViewTables = styled(EventViewTables)`
  padding-bottom: 45px !important;

  & > .tables {
    margin: -5px 0 0;
    text-align: center;

    & > .table {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      max-width: 250px !important;
      border-radius: 5px;
      overflow: hidden !important;
      margin: 15px !important;
    }
  }

  .table_head {
    background-color: ${props => generators.randomColor(props.settings)};
    position: relative;
    min-height: 80px;

    &__texture {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: 50px 50px;
      background-repeat: repeat;
      background-position: center;
      opacity: 0.12;
      z-index: 1;
    }
  }

  .table_body {
    padding: 20px;

    h4 {
      color: ${props => props.settings.theme.css.global.colors.primaryDark};
      font-size: 15px;
    }
  }

  h5 {
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    font-weight: normal !important;
    margin: 25px 0 -5px;
  }

  .be_the_first {
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    font-style: italic;
    margin-top: 20px;
    padding: 7px;
  }
`;

const EventViewTablesWithSettings = withSettings(StyledEventViewTables);

export { EventViewTablesWithSettings as EventViewTables };
