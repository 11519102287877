import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';
import { imEventHost } from '../../../libs/eventHelpers';
import { EVENT_TYPE } from '../../../enums';

const getDetailsBanner = (type, copy, eventIsEmpty, canSubscribe, maxReached) => {
  switch (type) {
    case EVENT_TYPE.PUBLIC:
      return canSubscribe ? (
        <div className={`details_banner ${maxReached ? 'no_seats' : ''} button`}>
          {eventIsEmpty && !maxReached ? copy.event.be_the_first_to_rsvp : maxReached ? copy.event.no_available_seats : copy.event.rsvp}
        </div>
      ) : null;
    case EVENT_TYPE.PRIVATE:
      return (
        <div className="details_banner">
          {copy.event.private_dinner_notice}
        </div>
      );
    case EVENT_TYPE.MANUAL_APPROVAL:
      return canSubscribe ? (
        <div className={`details_banner ${maxReached ? 'no_seats' : ''} button`}>
          {maxReached ? copy.event.no_available_seats : copy.event.manual_approval_notice}
        </div>
      ) : null;
    case EVENT_TYPE.SOLO:
      return (
        <div className="details_banner">
          {copy.event.solo_dinner_notice || 'This is a solo dinner.'}
        </div>
      );
    default:
      return null;
  }
};

const EventCardRsvpDetails = props => {
  const { className, event, viewer, maxReached, settings: { copy } } = props;
  const isHost = imEventHost(event, viewer);
  const eventIsEmpty = !event.reservations.guestsAccepted;

  if (isHost) return null;

  return (
    <div className={className}>
      {getDetailsBanner(event.eventType, copy, eventIsEmpty, event.canSubscribe, maxReached)}
    </div>
  );
}

const StyledEventCardRsvpDetails = styled(EventCardRsvpDetails)`
  .details_banner {
    margin-top: 18px;
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 10px;
    color: #8c8c8c;
    font-style: italic;
    font-size: 12px;

    &.button {
      font-style: normal;
      background-color: ${props => props.settings.theme.css.global.colors.primary};
      border-color: ${props => props.settings.theme.css.global.colors.primary};
      color: #fff;
      transition: background-color 250ms;

      &:hover {
        background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
        border-color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }

    &.no_seats {
      background-color: #878787;
      border-color: #878787;
      &.button {
        background-color: #878787;
        color: #DDDDDD;

        &:hover {
          background-color: #878787;
          border-color: #878787;
        }
      }
    }
  }
`;

const StyledEventCardRsvpDetailsWithSettings = withSettings(StyledEventCardRsvpDetails);

export { StyledEventCardRsvpDetailsWithSettings as EventCardRsvpDetails };
