import React, { useState } from 'react';
import { useSession } from '../../hooks';
import { getEventInvitation } from '../../libs';

import { EVENT_STATE } from '../../enums';

import {
  PrimaryButton,
  EventGuestsModal,
  EventViewCancelReservationModal,
  EventGuestsDeclineInvitationModal,
  RsvpButton
} from '../components';


export const RsvpActionsButton = ({ eventData, restriction = {}, reservation, showLoginModal, ...rest }) => {
  const { viewer, areas } = useSession();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const eventIsFull = eventData.reservations.guestsAccepted >= eventData.numberOfGuestsMax;
  const loggedIn = !!(viewer && viewer.user);
  const hasRestriction = !!restriction.content;
  const eventInvitation = getEventInvitation(eventData, viewer);

  if (eventData.canSubscribe && eventData.state === EVENT_STATE.DRAFT)
    return <PrimaryButton {...rest} disabled>DRAFT</PrimaryButton>;

  if (eventData.canSubscribe && eventData.state === EVENT_STATE.PENDING_PUBLISH_APPROVAL)
    return <PrimaryButton {...rest} disabled>PENDING APPROVAL</PrimaryButton>;

  if (!eventData.canSubscribe || eventData.state !== EVENT_STATE.PUBLISHED) return null;

  if (loggedIn && hasRestriction && !eventIsFull)
    return <PrimaryButton {...rest} onClick={restriction.showRestriction}>RSVP</PrimaryButton>;

  if (!loggedIn && !eventIsFull)
    return <PrimaryButton {...rest} onClick={showLoginModal}>RSVP</PrimaryButton>;

  return (
    <>
      <RsvpButton
        event={eventData}
        viewer={viewer || null}
        areas={areas}
        openDeclineInvitationModal={() => setShowInvitationModal(true)}
        handleCancelReservation={() => setShowCancelModal(true)}
        handleInviteFriends={() => setShowInviteModal(true)}
        {...rest}
        themed
      />
      <EventGuestsModal
        show={showInviteModal}
        toggle={() => setShowInviteModal(prev => !prev)}
        event={eventData}
      />
      <EventViewCancelReservationModal
        show={showCancelModal}
        toggle={() => setShowCancelModal(prev => !prev)}
        reservation={reservation}
        reservationCancelled={() => setShowCancelModal(false)}
      />
      <EventGuestsDeclineInvitationModal
        show={showInvitationModal}
        toggle={() => setShowInvitationModal(prev => !prev)}
        invitationId={eventInvitation ? eventInvitation.id : null}
      />
    </>
  );
};
