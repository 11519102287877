import React, { useState } from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  Modal,
  DollarInput,
  Button
} from '../components';

import { stringInsert } from '../../libs';


const EventSponsorshipsDonationModal = ({
  show,
  toggle,
  className,
  settings,
  availableAmount,
  donationSponsorId,
  onUpdateDonationSponsor
}) => {
  const { copy } = settings;
  const [donationAmount, setDonationAmount] = useState(0);

  const handleInputChange = (name, value) => {
    setDonationAmount(value);
  };

  const handleClickDonateButton = () => {
    onUpdateDonationSponsor(donationSponsorId, donationAmount);
    toggle();
  };

  return (
    <Modal
      show={show}
      toggle={toggle}
      className={className}
      size="sm"
      noPadding
    >
      <div>
        <h3>{copy.event.nourishment_donation_modal_title || 'Donate Your Nourishment'}</h3>
        <p>
          {stringInsert(copy.event.nourishment_donation_modal_description, availableAmount)}
        </p>
        <DollarInput
          type="number"
          name="donation_amount"
          value={donationAmount}
          max={availableAmount}
          min={0}
          onInputChange={handleInputChange}
        />
        <Button
          handleClick={handleClickDonateButton}
          disabled={!donationAmount || donationAmount > availableAmount}
        >{copy.event.nourishment_donation_modal_button || 'Donate Now'}</Button>
        <div className="no_thanks">
          <span onClick={toggle}>No Thanks</span>
        </div>
      </div>
    </Modal>
  );
}


const StyledEventSponsorshipsDonationModal = styled(EventSponsorshipsDonationModal)`
  text-align: center;

  .modal-content {
    text-align: center;
    padding: 40px 30px 30px;
    border: 0;
  }

  h3 {
    font-size: 16px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 0;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
  }

  input {
    &.invalid {
      border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      margin-bottom: 0 !important;

      &:focus {
        border-color: ${props => props.settings.theme.css.global.colors.error} !important;
      }
    }
  }

  .no_thanks {
    padding-top: 10px;

    span {
      cursor: pointer;
      font-size: 13px;
      opacity: 0.8;
      transition: color 250ms, opacity 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primary} !important;
        opacity: 1;
      }
    }
  }

  .validation_error {
    padding-top: 2px;
  }
`;

const StyledEventSponsorshipsDonationModalWithSettings = withSettings(StyledEventSponsorshipsDonationModal);

export { StyledEventSponsorshipsDonationModalWithSettings as EventSponsorshipsDonationModal };
