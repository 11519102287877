import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  CREATE_USER,
  LOG_IN_WITH_OTP,
  LOG_IN_WITH_PASSWORD,
  RESET_PASSWORD_WITH_TOKEN,
  SEND_USER_OTP,
  UPDATE_USER,
  UPDATE_PROFILE
} from '../mutations';

export function useLoginWithPasswordMutation() {
  return useMutation(LOG_IN_WITH_PASSWORD);
}
export function useLoginWithOneTimePasswordMutation() {
  return useMutation(LOG_IN_WITH_OTP);
}
export function useSendUserOtpMutation() {
  return useMutation(SEND_USER_OTP);
}
export function useCreateUserMutation() {
  return useMutation(CREATE_USER);
}
export function useResetPasswordWithTokenMutation() {
  return useMutation(RESET_PASSWORD_WITH_TOKEN);
}
export function useUpdateUserMutation() {
  return useMutation(UPDATE_USER);
}
export function useUpdateProfileMutation() {
  return useMutation(UPDATE_PROFILE);
}

export function useLoginWithOauthToken () {
  return useMutation(LOG_IN_WITH_PASSWORD);
}


export const authViewStateValues = {
  LOGIN_WITH_PASSWORD_VIEW: 'LOGIN_WITH_PASSWORD_VIEW',
  FORGOT_PASSWORD_VIEW: 'FORGOT_PASSWORD_VIEW',
  OTP_VIEW: 'OTP_VIEW',
  SIGN_UP_VIEW: 'SIGN_UP_VIEW',
  RESET_PASSWORD_VIEW: 'RESET_PASSWORD_VIEW',
  RESET_PASSWORD_CONFIRMATION_VIEW: 'RESET_PASSWORD_CONFIRMATION_VIEW',
  CHANGE_PASSWORD_VIEW: 'CHANGE_PASSWORD_VIEW',
};

export const withOtpMutationHooksHOC = (Component: any) => {
  return (props: any) => {
    const [loginWithPassword] = useLoginWithPasswordMutation();
    const [logInWithOtp] = useLoginWithOneTimePasswordMutation();
    const [sendUserOtp] = useSendUserOtpMutation();
    const [createUser] = useCreateUserMutation();
    const [resetPasswordWithToken] = useResetPasswordWithTokenMutation();
    const [updateUser] = useUpdateUserMutation();
    const [updateProfile] = useUpdateProfileMutation();

    return (
      <Component
        loginWithPasswordMutation={loginWithPassword}
        logInWithOtpMutation={logInWithOtp}
        sendUserOtpMutation={sendUserOtp}
        createUserMutation={createUser}
        resetPasswordWithTokenMutation={resetPasswordWithToken}
        updateUserMutation={updateUser}
        updateProfileMutation={updateProfile}
        {...props}
      />
    );
  };
};
