import React from 'react';
import styled from 'styled-components';

import { useSettings } from '../../hooks';
import {
  InputField,
  CreditCardField,
  UnderlineButton as Button,
  Checkbox
} from '../components';


export const PaymentMethodsNew = ({
  data,
  onUpdateField,
  onCancel,
  setValidFullName = () => {},
  setValidCardNumber = () => {},
  setValidCardExp = () => {},
  setValidCardCVV = () => {}
}) => {
  const { copy } = useSettings();
  return (
    <Container>
      <InputField
        type="text"
        name="fullName"
        value={data.fullName || ''}
        placeholder={copy.profile.card_name_label}
        onChange={(n, v) => {
          onUpdateField(n, v);
          setValidFullName(true);
        }}
        icon={['far', 'user']}
        valid={data.validFullName}
        errorMessage={`Enter ${copy.profile.card_name_label.toLowerCase()}.`}
      />
      <CreditCardField
        name="cardNumber"
        value={data.cardNumber || ''}
        placeholder={copy.profile.card_number_label}
        onChange={(n, v) => {
          onUpdateField(n, v);
          setValidCardNumber(true);
        }}
        icon={['far', 'credit-card']}
        mask="card"
        valid={data.validCardNumber}
        errorMessage={`Enter valid ${copy.profile.card_number_label.toLowerCase()}.`}
      />
      <HalfContainer>
        <CreditCardField
          name="cardExp"
          value={data.cardExp || ''}
          placeholder={copy.profile.card_exp_placeholder || 'MM/YY'}
          onChange={(n, v) => {
            onUpdateField(n, v);
            setValidCardExp(true);
          }}
          icon={['far', 'calendar']}
          mask="exp"
          valid={data.validCardExp}
          errorMessage="Enter valid expiration date."
        />
        <CreditCardField
          name="cardCVV"
          value={data.cardCVV || ''}
          placeholder={copy.profile.card_cvv_placeholder}
          onChange={(n, v) => {
            onUpdateField(n, v);
            setValidCardCVV(true);
          }}
          icon={['far', 'lock']}
          mask="cvv"
          valid={data.validCardCVV}
          errorMessage="Enter valid CVV."
        />
      </HalfContainer>
      <Checkbox
        name="favorite"
        options={[
          {
            label: copy.profile.card_use_default,
            checked: data.favorite || false
          }
        ]}
        onInputChange={onUpdateField}
        small
      />
      {onCancel && <Button onClick={onCancel}>Cancel</Button>}
    </Container>
  );
};

const Container = styled.div`
  max-width: 500px;

  .underline_button { margin-top: 15px; }
`;

const HalfContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
`;
