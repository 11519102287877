import React from 'react';
import { Mutation } from 'react-apollo';

import { EVENT_FILTER } from '../../../enums';

import {
  CREATE_EVENT,
  EVENT_PUBLISH,
  UPDATE_EVENT,
  SUBMIT_PENDING_APPROVAL
} from '../../../mutations';

import {
  INIT,
  MANAGE_EVENTS,
  EVENT_SPONSORSHIPS_BY_UUID,
  VIEWER_EVENTS_COUNT
} from '../../../queries';

import { Button, alert } from '../../components';
import { withSettings, withSession, withClient } from '../../components';

import { formatEventDataForSubmit, errorMgt, getSavedEventResponseField } from '../../../libs';
import { EventRecurrenceModal } from '../views/EventRecurrenceModal';
import { RECURRENCE_ACTION_TYPE, RECURRENCE_UPDATE_TYPE } from '../../../constants/recurrances';
import {
  getRecurrencesToModify,
  publishRecurringEvents,
  withEventMutationHooksHOC
} from '../../../libs/events';
import asyncForEach from '../../../libs/asyncForEach';

class EventActionButton extends React.Component {
  state = { loading: false, recurrenceModalOpen: false, shouldSaveAsDraft: false };

  componentDidMount() {
    const { settings, session: { viewer } } = this.props;
    const { event: { max_upcoming_published_events_per_user } } = settings;
    const maxPublishedEventsPerHost = !viewer.user.canCopyEvents && parseInt(max_upcoming_published_events_per_user);
    const hostedEventsCount = viewer?.hostedEvents?.edges.length || 0;

    if (maxPublishedEventsPerHost && hostedEventsCount >= maxPublishedEventsPerHost) {
      this.setState({ shouldSaveAsDraft: true });
    }
  }

  refetchQueries = async (eventData) => {
    const {
      client,
      settings: {
        event: { require_publish_approval }
      }
    } = this.props;
    const requireApproval = !!require_publish_approval;

    let queries = [
      client.query({
        query: INIT,
        variables: {},
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }),
      client.query({
        query: VIEWER_EVENTS_COUNT,
        variables: {},
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }),
      client.query({
        query: MANAGE_EVENTS,
        variables: {
          filter: requireApproval ? EVENT_FILTER.PENDING_PUBLISH_APPROVAL : EVENT_FILTER.HOSTED
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }),
      client.query({
        query: MANAGE_EVENTS,
        variables: { filter: EVENT_FILTER.DRAFT },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
    ];

    if (eventData && eventData.uuid) {
      queries = [
        ...queries,
        client.query({
          query: EVENT_SPONSORSHIPS_BY_UUID,
          variables: { uuid: eventData.uuid },
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
      ];
    }

    return await Promise.all(queries);
  };

  handleActionClick = async (buttonAction, submitPendingRequest, buttonRef) => {
    const {
      action,
      handleValidation = () => ({}),
      eventData,
      secondaryAction,
      secondaryActionCondition,
      preValidationCondition,
      setGroupDisabled,
      shouldContinue
    } = this.props;

    const validationResult = handleValidation(action, buttonRef);
    const validateVaccination = action !== 'save_draft' && action !== 'update_event';

    if (
      !validationResult ||
      validationResult.requiresProfileUpdate ||
      (validateVaccination && validationResult.showVaccinationError) ||
      (validationResult.showSubmitWarning && validationResult.isSponsored && !shouldContinue) ||
      validationResult.hasMetMaxPublishedEvents
    ) return;

    if (validationResult.errors && validationResult.errors.length) {
      alert.error(validationResult.errors[0].error);
    } else {
      this.setState({ loading: true }, () => setGroupDisabled(true));

      try {
        // Remove Sponsorships if virtual ineligibility //
        if (preValidationCondition && secondaryAction) {
          await secondaryAction();
        }

        const response = await buttonAction();
        const eventId = getSavedEventResponseField(response, 'id');

        // Remove Sponsorships if limit is reached //
        // dont need to remove twice if virtual already did //
        if (!preValidationCondition && secondaryAction && secondaryActionCondition) {
          await secondaryAction();
        }

        if (action === 'submit_pending' && eventId) {
          await submitPendingRequest({ variables: { eventId } });
          const recurrences = [];
          const recurringEvents = response && response.data && response.data.createEvent &&
            response.data.createEvent.event && response.data.createEvent.event.recurrences;

          Array.isArray(recurringEvents) && recurringEvents.forEach(e => {
            recurrences.push(submitPendingRequest({ variables: { eventId: e.id } }))
          });

          await Promise.all(recurrences);
        }

        await this.refetchQueries(eventData);

        this.handleComplete(response);
      } catch (error) {
        this.setState({ loading: false }, () => setGroupDisabled(false));
        this.handleErrors(error.graphQLErrors);
      }
    }
  };

  handleComplete = (response) => {
    const { onComplete, setGroupDisabled } = this.props;
    const { shouldSaveAsDraft } = this.state;
    this.setState({ loading: false }, () => setGroupDisabled(false));
    onComplete(response, shouldSaveAsDraft);
  };

  handleErrors = (gqlErrors) => {
    const { eventData, isUpdate, viewer, savedEventData, settings } = this.props;
    const { copy } = settings;
    const { isGeneric, userMessage: message } = errorMgt.getErrorFromServerResponse(gqlErrors, copy);
    const errorCode = errorMgt.getErrorCode(gqlErrors);
    const errorMessage = errorCode === 'INVALID_FORM' 
      ? gqlErrors[0]?.extensions.errors[0]?.message
      : copy.rsvp[errorCode?.toLowerCase()];
    const userMessage = errorMessage || (isGeneric ? copy.create.general_error : message);
    const actionName = `event_creation_${isGeneric && !errorMessage ? 'generic' : 'custom'}_error`;
    const logMessage = `${isGeneric && !errorMessage ? 'Generic' : 'Custom'} Error When Creating Event`;
    const variables = formatEventDataForSubmit(eventData, savedEventData, isUpdate, viewer, settings);

    alert.error(userMessage);
    errorMgt.logError(actionName, logMessage, { eventData, variables, gqlErrors, userMessage });
  };

  toggleRecurringModal = () => {
    this.setState({ recurrenceModalOpen: !this.state.recurrenceModalOpen });
  };

  updateRecurringEvents = async (eventUpdateType, updateEvent) => {
    const recurrences = getRecurrencesToModify(this.props.eventData, eventUpdateType);

    const variables = formatEventDataForSubmit(
      this.props.eventData,
      this.props.savedEventData,
      true,
      this.props.viewer,
      this.props.settings
    );

    // save recurrences
    await asyncForEach(recurrences, async (recurrence) => {
      variables.id = recurrence.id;

      try {
        await this.props.updateEventMutation({ variables });
      } catch (error) {
        // console.error('caught', response, error);
      }
    });

    // save event
    await this.handleActionClick(updateEvent);
  };

  render() {
    const { loading, shouldSaveAsDraft } = this.state;
    const {
      action,
      children,
      eventData,
      isUpdate,
      viewer,
      disabled = false,
      savedEventData,
      settings: {
        event: { require_publish_approval },
        features: { enable_area_user_connection }
      },
      onRestrictedClick = () => {},
      ...rest
    } = this.props;

    const requireApproval = !!require_publish_approval;
    const eventId = eventData ? eventData.id : null;

    const variables = formatEventDataForSubmit(
      eventData,
      savedEventData,
      isUpdate,
      viewer,
      this.props.settings
    );

    if (action === 'save_event' && !shouldSaveAsDraft) variables.publish = true;
    if (
      action === RECURRENCE_ACTION_TYPE.UPDATE ||
      action === RECURRENCE_ACTION_TYPE.UPDATE_RECURRING
    )
      variables.id = eventId;

    if (enable_area_user_connection) variables.neighborhoodId = eventData.campus?.id

    switch (action) {
      case 'save_draft':
      case 'save_event':
      case 'submit_pending':
        return (
          <Mutation mutation={CREATE_EVENT} variables={variables} errorPolicy='all'>
            {(createEvent) => (
              <Mutation mutation={SUBMIT_PENDING_APPROVAL} errorPolicy='all'>
                {(requestEventPublishApproval) => (
                  <Button
                    handleClick={(e, buttonRef) => onRestrictedClick(buttonRef, () => (
                      this.handleActionClick(createEvent, requestEventPublishApproval, buttonRef)
                    ))}
                    disabled={loading || disabled}
                    loading={loading}
                    {...rest}
                  >
                    {children}
                  </Button>
                )}
              </Mutation>
            )}
          </Mutation>
        );
      case 'submit_draft_pending':
        return (
          <Mutation mutation={SUBMIT_PENDING_APPROVAL} variables={{ eventId }} errorPolicy='all'>
            {(requestEventPublishApproval) => (
              <Button
                handleClick={(e, buttonRef) => onRestrictedClick(buttonRef, () => (
                  this.handleActionClick(requestEventPublishApproval, null, buttonRef)
                ))}
                disabled={loading || disabled}
                loading={loading}
                {...rest}
              >
                {children}
              </Button>
            )}
          </Mutation>
        );
      case RECURRENCE_ACTION_TYPE.PUBLISH:
      case RECURRENCE_ACTION_TYPE.PUBLISH_RECURRING:
        return (
          <Mutation mutation={EVENT_PUBLISH} variables={{ eventId }} errorPolicy='all'>
            {(publishEvent) => (
              <Mutation mutation={SUBMIT_PENDING_APPROVAL}>
                {(requestEventPublishApproval) => {
                  const submitAction = requireApproval ? requestEventPublishApproval : publishEvent;
                  return (
                    <>
                      <Button
                        handleClick={(e, buttonRef) => onRestrictedClick(buttonRef, () => {
                          if (action === RECURRENCE_ACTION_TYPE.PUBLISH_RECURRING) {
                            this.toggleRecurringModal();
                          } else {
                            return this.handleActionClick(submitAction, null, buttonRef);
                          }
                        })}
                        disabled={loading || disabled}
                        loading={loading}
                        {...rest}
                      >
                        {children}
                      </Button>
                      <EventRecurrenceModal
                        isPublish={true}
                        open={this.state.recurrenceModalOpen}
                        toggle={this.toggleRecurringModal}
                        save={() => {
                          this.toggleRecurringModal();
                          publishRecurringEvents(
                            RECURRENCE_UPDATE_TYPE.ALL_EVENTS,
                            submitAction,
                            this.props.eventData,
                            this.props.publishEventMutation,
                            this.handleActionClick
                          );
                        }}
                      />
                    </>
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        );
      case RECURRENCE_ACTION_TYPE.UPDATE:
      case RECURRENCE_ACTION_TYPE.UPDATE_RECURRING:
        return (
          <Mutation mutation={UPDATE_EVENT} variables={variables} errorPolicy='all'>
            {(updateEvent) => (
              <>
                <Button
                  handleClick={(e, buttonRef) => onRestrictedClick(buttonRef, () => {
                    if (action === RECURRENCE_ACTION_TYPE.UPDATE_RECURRING) {
                      this.toggleRecurringModal();
                    } else {
                      return this.handleActionClick(updateEvent, null, buttonRef);
                    }
                  })}
                  disabled={loading || disabled}
                  loading={loading}
                  {...rest}
                >
                  {children}
                </Button>
                <EventRecurrenceModal
                  open={this.state.recurrenceModalOpen}
                  toggle={this.toggleRecurringModal}
                  save={(eventUpdateType) => {
                    this.toggleRecurringModal();
                    this.updateRecurringEvents(eventUpdateType, updateEvent);
                  }}
                />
              </>
            )}
          </Mutation>
        );

      default:
        console.error('Invalid action given.');
        return null;
    }
  }
}

const EventActionButtonWithSettings = withSettings(EventActionButton);
const EventActionButtonWithSession = withSession(EventActionButtonWithSettings);
const EventActionButtonWithClient = withClient(EventActionButtonWithSession);
const EventActionButtonWithMutationHook = withEventMutationHooksHOC(EventActionButtonWithClient);

export { EventActionButtonWithMutationHook as EventActionButton };
