import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Checkbox } from '../components';
import { withSettings } from '../components';

class RsvpPaymentModalCreditCard extends React.Component {

  render() {
    const { className, creditCard, changeButtonClicked, editing, selected, creditCardSelected } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <div className={`${className} row`}>
        <div className="ot-quarter-container">
          {editing && (
            <Checkbox
              name="selected"
              options={[
                {
                  label: `<img class="credit-card-icon" src="${creditCard.image}" alt="${creditCard.brand}">`,
                  checked: selected
                }
              ]}
              onInputChange={() => { creditCardSelected(creditCard.id); }}
            />
          )}
          {!editing && (
            <label>
              <img className="credit-card-icon" src={creditCard.image} alt='credit-card-icon'></img>
            </label>
          )}
        </div>
        <div className="ot-half-container-payments">
          <label>
            <span>{creditCard.brand}</span><br/>
            <span>{`**** **** **** ${creditCard.number}`}</span><br/>
            {creditCard.favorite && (
              <span className="primary-card-label">{copy.profile.card_default}</span>
            )}
          </label>
        </div>
        <div className="ot-quarter-container">
          {editing && (
            <label>
              <span>{creditCard.expiration}</span><br/>
            </label>
          )}
          {!editing && (
            <Button
              className="change-button"
              buttonStyle="clearBackground"
              handleClick={changeButtonClicked}>
                Change
            </Button>
          )}
        </div>
      </div>
    );
  }
}

RsvpPaymentModalCreditCard.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCreditCard = styled(RsvpPaymentModalCreditCard)`
  text-align: left;

  & h2 {
    text-align: left;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & .credit-card-icon {
    width: 30px;
    top: -2px;
    position: relative;
    margin: 3px 0 0 5%;
  }

  & label {
    font-size: 13px;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    display: block;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  & label span {
    padding: 2px 0 0;
  }

  & .primary-card-label {
    font-size: 12px;
    padding-top: 3px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & .change-button {
    font-weight: bold;
    font-size: 13px;
  }

  & input {
    margin-top: 17px;
  }
`;

const StyledRsvpPaymentModalCreditCardWithSettings = withSettings(StyledRsvpPaymentModalCreditCard);

export { StyledRsvpPaymentModalCreditCardWithSettings as RsvpPaymentModalCreditCard };
