import React from 'react';
import styled from 'styled-components';

import {
  JourneyResource
} from '../components';


const JourneyResourcesList = ({ className, resources, videos, playlists }) => (
  <div className={className}>
    <ul>
      {Array.isArray(resources) && resources.map(({ url, title, ...rest }, i) => (url && title ? (
        <JourneyResource
          key={i}
          url={url}
          title={title}
          image={{
            image: rest.image,
            horizontalMobilePosition: rest.horizontalMobileImagePosition,
            verticalMobilePosition: rest.verticalMobileImagePosition
          }}
          type="resource"
        />
      ) : null))}
      {Array.isArray(playlists) && playlists.map(({ url, title, ...rest }, i) => (url && title ? (
        <JourneyResource
          key={i}
          url={url}
          title={title}
          image={{
            image: rest.image,
            horizontalMobilePosition: rest.horizontalMobileImagePosition,
            verticalMobilePosition: rest.verticalMobileImagePosition
          }}
          type="playlist"
        />
      ) : null))}
      {Array.isArray(videos) && videos.map(({ url, title, ...rest }, i) => (url && title ? (
        <JourneyResource
          key={i}
          url={url}
          title={title}
          image={{
            image: rest.image,
            horizontalMobilePosition: rest.horizontalMobileImagePosition,
            verticalMobilePosition: rest.verticalMobileImagePosition
          }}
          type="video"
        />
      ) : null))}
    </ul>
  </div>
);

const StyledJourneyResourcesList = styled(JourneyResourcesList)`
  height: 100%;

  ul {
    display: block;
    width: 100%;
    min-height: 100%;
    // margin: 40px 0 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;
export { StyledJourneyResourcesList as JourneyResourcesList };
