import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import { Logo, Modal, Otp, ResetPassword } from '../';

import { OtpContext } from '../../OtpContext';
import { authViewStateValues } from '../../libs/auth';

const initialState = {
  email: '',
  password: '',
  emailValid: false,
  passwordValid: false,
  hasLoginError: false,
  authViewState: authViewStateValues.RESET_PASSWORD_VIEW,
  isLoading: false
};

class PasswordResetModal extends React.Component {
  state = { ...initialState };
  static contextType =  OtpContext;

  setAuthViewState = (viewState) => {
    this.setState({ authViewState: viewState });
  };

  renderContent = (authViewState, props) => {
    switch (authViewState) {
      case authViewStateValues.RESET_PASSWORD_VIEW:
        return <ResetPassword setViewState={this.setAuthViewState} {...props} />;
      case authViewStateValues.RESET_PASSWORD_CONFIRMATION_VIEW:
        return <p className='confirmation'>Your password has been updated.</p>;
      case authViewStateValues.OTP_VIEW:
        return <><h2>Two-Factor Authentication</h2><Otp setViewState={this.setAuthViewState} {...props} /></>;
      default:
        return <ResetPassword setViewState={this.setAuthViewState} {...props} />;
    }
  };

  render() {
    const { authViewState } = this.state;
    const { className, ...props } = this.props;
    return (
      <Modal show={props.show} toggle={props.toggle} size='sm' noPadding>
        <div className={className}>
          <Logo layout='vertical' />
          {this.renderContent(authViewState, { ...props })}
        </div>
      </Modal>
    );
  }
}

const StyledPasswordResetModal = styled(PasswordResetModal)`
  padding: 40px 30px 30px;

  .logo {
    display: block;
    max-width: 147px;
    margin: 0px auto 30px;
  }

  h2 {
    font-size: 16px;
    text-align: center;
  }

  p {
    font-size: 12px;
    text-align: center;
  }
`;

const PasswordResetWithRouter = withRouter(StyledPasswordResetModal);
const PasswordResetWithRouterWithSettings = withSettings(PasswordResetWithRouter);

export { PasswordResetWithRouterWithSettings as PasswordResetModal };
