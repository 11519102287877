import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  DropDownMenu,
  LogOutLink,
  Avatar,
  withSettings
} from '../components';

import { isSite } from '../../libs';
import { isWordpress, appHost } from '../../libs/wordpress';

const WpCompatibleNavLink = ({ to, children, ...props }) => {
  if (isWordpress()) {
    return <a href={`${appHost()}${to}`}>{children}</a>;
  } else {
    return <NavLink to={to} {...props}>{children}</NavLink>;
  }
}

class UserMenu extends React.Component {
  state = { showMenu: false };

  componentDidMount() {
    if (isWordpress() && this.isHost()) {
      const elsToHide = Array.from(document.querySelectorAll('.hide-when-host'));
      const elsToShow = Array.from(document.querySelectorAll('.hide-when-not-host'));
      elsToHide.forEach(el => { el.style.display = 'none'; });
      elsToShow.forEach(el => { el.style.display = 'inline-block'; });
    }
  }

  handleToggleMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }));
  };

  isHost = checkForCohost => {
    const { session: { viewer: { user, allEvents } } } = this.props;
    return (user && user.state === 'host') || (checkForCohost && allEvents.totalCount > 0);
  };

  isCommunityHost = () => {
    const { session: { viewer: { user } } } = this.props;
    return this.isHost() && user.communityDinnerCreator;
  };

  getCreateEventMenuItem = (hasRestriction, copy) => {
    const { settings, session } = this.props;
    const user = session && session.viewer && session.viewer.user;
    const hostPending = user && user.state === 'awaiting_for_host';
    const becomeAHostUrl = isSite(settings, 'dinner-party') ? '/landing/host-trainings' : '/host/promote';

    if (this.isHost()) {
      return hasRestriction
        ? this.restrictedMenuItem(copy.menu.create_dinner)
        : <WpCompatibleNavLink to="/events">{copy.menu.create_dinner}</WpCompatibleNavLink>;
    }

    if (!isSite(settings, ['jewishfoodfest', 'climatefest', 'signaturefd']) && !hostPending) {
      return hasRestriction
        ? this.restrictedMenuItem(copy.menu.become_host)
        : <WpCompatibleNavLink isActive={this.hasRestrictions} to={becomeAHostUrl}>{copy.menu.become_host}</WpCompatibleNavLink>;
    }
  };

  restrictedMenuItem = (label) =>
    (<span onClick={this.props.restriction.showRestriction}>{label}</span>)

  render() {
    const { showMenu } = this.state;
    const { className, restriction, settings, overHero } = this.props;
    const hasRestriction = restriction && restriction.content !== null;
    const { copy } = settings;

    const classList = [className];
    overHero && classList.push('over_hero');

    return (
      <Fragment>
        <div className={classList.join(' ')}>
          <div onClick={this.handleToggleMenu}>
            <Avatar sessionProfile />
          </div>
          {showMenu && (
            <DropDownMenu onListClick={this.handleToggleMenu} toggle={this.handleToggleMenu} backdrop>
              <li>
                {this.isHost(true)
                  ? <WpCompatibleNavLink to="/manage/hosted">{copy.menu.manage_events}</WpCompatibleNavLink>
                  : <WpCompatibleNavLink to="/manage/upcoming">{copy.menu.manage_events}</WpCompatibleNavLink>
                }
              </li>
              <li>
                {this.getCreateEventMenuItem(hasRestriction, copy)}
              </li>
              {this.isCommunityHost() && (
                <li>
                  {hasRestriction
                    ? this.restrictedMenuItem(copy.menu.create_community_dinner)
                    : <WpCompatibleNavLink to="/community">{copy.menu.create_community_dinner}</WpCompatibleNavLink>}
                </li>
              )}
              <li>
                <WpCompatibleNavLink to="/profile">{copy.menu.settings}</WpCompatibleNavLink>
              </li>
              <li>
                <LogOutLink settings={settings} />
              </li>
            </DropDownMenu>
          )}
        </div>
      </Fragment>
    );
  }
}

const StyledUserMenu = styled(UserMenu)`
  position: absolute;
  right: 60px;
  height: 56px;
  margin-top: -28px;
  top: 50%;
  // transform: translateY(-50%);
  padding: 5px 0;
  cursor: default;

  & > div:first-child {
    cursor: pointer;
    position: relative;
    z-index: 3;
  }

  .dropdown_menu {
    top: 100%;
    right: 0;
  }

  ${props => props.overHero && (
    css`
      .user_name {
        color: #fff !important;
      }
  `)}

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
    right: 40px;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {

    &.over_hero {
      right: 30px;
    }

    &:not(.over_hero) {
      right: 90px;
    }
  }

  &.over_hero {
    @media (max-width: 700px) {
      right: 90px;
    }
  }
`;

const UserMenuWithSettings = withSettings(StyledUserMenu);

export { UserMenuWithSettings as UserMenu };
