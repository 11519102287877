import { useState, useEffect } from 'react';
import { useSettings } from './';

export const useCookieBanner = () => {
  const { theme } = useSettings();
  const [show, setShow] = useState(false);
  const close = () => setShow(false);

  useEffect(() => {
    if (theme.display_cookie_notification) setShow(true);
  }, []);

  return { show, close };
};
