import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  Spinner,
  Avatar,
  PaginationButtons,
  withSettings
 } from '../components';

 import { usePaginatedAttendees } from '../../hooks';


const AttendeesModal = ({ className, show, toggle, linked, event = {}, settings: { copy } }) => {
  const { id } = event;
  const { reservations, loading, pagination } = usePaginatedAttendees(id);
  const { hasNextPage, hasPrevPage, goToNextPage, goToPrevPage } = pagination;

  return (
    <Modal
      show={show}
      toggle={toggle}
      size="sm"
      noPadding
    >
      <div className={className}>
        <header>
          <title>{copy.event.attendants_title}</title>
        </header>
        {loading ? <div className="spinner_container"><Spinner /></div> : (
          <ul>
            {reservations.map(r => {
              const { node: { profile, id: rid, additionalGuests } } = r;

              if (!profile || !profile.showOnGuestList) return null;

              return (
                <li key={rid}>
                  <span>{`${profile.firstName || ''} ${profile.lastName || ''}`.trim()}</span>
                  {additionalGuests.length > 0 && additionalGuests.map((guest, i) => (
                    <span className="additional_guest" key={i}>
                      <i>+</i> {`${guest.firstName || ''} ${guest.lastName || ''}`.trim()}
                    </span>
                  ))}
                  <Avatar
                    profile={profile}
                    linked={linked}
                    noName
                  />
                </li>
              );
            })}
          </ul>
        )}
        {(hasPrevPage() || hasNextPage()) && (
          <PaginationButtons
            hasPrevPage={hasPrevPage()}
            hasNextPage={hasNextPage()}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            loading={pagination.loading}
          />
        )}
      </div>
    </Modal>
  );
};

const StyledAttendeesModal = styled(AttendeesModal)`
  padding: 30px 30px 40px;

  header {
    text-align: center;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 25px;
    }
  }

  .spinner_container {
    text-align: center;
    margin-bottom: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid #ccc;
  }

  li {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 20px 70px 20px 0;

    span {
      font-size: 15px;
      display: block;
      font-weight: bold;

      &:first-child {
        padding-bottom: 3px;
      }

      &.additional_guest {
        font-size: 14px;
        padding-left: 3px;
        font-weight: normal;

        i {
          font-size: 10px;
        }
      }
    }
  }

  .avatar_container {
    position: absolute;
    right: 0;
    top: 9px;
  }

  .pagination {
    margin: 15px 0 -15px;
  }
}
`;
const AttendeesModalWithSettings = withSettings(StyledAttendeesModal);
export { AttendeesModalWithSettings as AttendeesModal };
