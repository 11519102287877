import React, { Fragment } from 'react';
import styled from 'styled-components';
import { EVENT_SPONSORS_QUESTIONS_FORMS } from '../../queries';
import { withRouter } from 'react-router-dom';
import { Button } from '../Global';
import { withSettings } from '../components';
import { stringInsert, isSite, isRegularOneTable } from '../../libs';
import {
  EventSponsorshipAmountSelector,
  EventSponsorsFormModal,
  EventSponsorshipsSkipModal,
  EventSponsorshipsDonationModal
} from './';
import { breakpoints, containersWidths } from '../../styles';
import { alert } from '../Global'

const maxSponsorshipsToSelect = 2;
const defaultMaxSponsorshipsAmount = 150;

class EventSponsorshipsSelector extends React.Component {
  constructor (props) {
    super(props);

    const availableSponsors = this.getAvailableSponsorsArrayFromEvent(props.event);
    const sponsorships = this.loadSponsorshipsAmounts(availableSponsors, props.event.sponsorships);
    const sponsorshipsAmountInDollarsAllocated = this.calculateSponsorshipsAmountInDollarsAllocated(sponsorships);
    const shouldDisableSponsorships = this.shouldSponsorshipsInputsBeDisabled(sponsorships);

    this.state = {
      event: props.event,
      sponsorships: sponsorships,
      sponsorshipsAmountInDollarsAllocated: sponsorshipsAmountInDollarsAllocated,
      shouldDisableSponsorships: shouldDisableSponsorships,
      showSkipModal: false,
      showSponsorsFormsModal: false,
      formHtml: null,
      submitNavButtonLoading: false,
      submitBottomButtonLoading: false,
      showDonationModal: false
    };
  }

  componentDidMount() {
    const { event } = this.state;
    const { settingsPriceMax, settingsPricePerHead, settings } = this.props;

    this.setupFeatures(settingsPriceMax, settingsPricePerHead);

    if (event && event.sponsorships && !event.sponsorships.length && isRegularOneTable(settings))
      this.setState({ showDonationModal: true });
  }

  setupFeatures = (priceMax, pricePerHead) => {
    const { settings, expectedGuests } = this.props;
    const sponsorshipsMaxGuestLimit = typeof settings.env_vars.sponsorships_max_guest_limit !== 'undefined'
      ? settings.env_vars.sponsorships_max_guest_limit
      : 4;
    const minGuestNumberRequiredLimit = parseInt(sponsorshipsMaxGuestLimit, 10);

    let sponsorshipsAmountInDollarsAvailable = defaultMaxSponsorshipsAmount;

    if (!Number.isInteger(expectedGuests) || expectedGuests < minGuestNumberRequiredLimit) {
      sponsorshipsAmountInDollarsAvailable = 0;
    } else {
      if (Number.isInteger(expectedGuests) && priceMax && pricePerHead) {
        // add cohosts //
        sponsorshipsAmountInDollarsAvailable = Math.min((expectedGuests + 1) * pricePerHead, priceMax);
      }
    }

    this.setState({ sponsorshipsAmountInDollarsAvailable });
  };

  calculateSponsorshipsAmountInDollarsAllocated = (sponsorships) => {
    const sponsorshipsWithPositiveAmount = sponsorships.filter(sponsorship => {
      return (sponsorship.amount > 0);
    })

    return sponsorshipsWithPositiveAmount.reduce((accumulator, sponsorship) => {
      return accumulator + sponsorship.amount;
    }, 0);
  }

  shouldSponsorshipsInputsBeDisabled = (sponsorships, sponsorshipsAmountInDollarsAvailable) => {
    const sponsorshipsWithPositiveAmount = sponsorships.filter(sponsorship => sponsorship.amount > 0);

    const sponsorshipsAmountInDollarsAllocated = sponsorshipsWithPositiveAmount.reduce((accumulator, sponsorship) => {
      return accumulator + sponsorship.amount;
    }, 0);

    const numberOfSponsorshipsWithPositiveAmount = sponsorshipsWithPositiveAmount.length;

    return (numberOfSponsorshipsWithPositiveAmount >= maxSponsorshipsToSelect) || (sponsorshipsAmountInDollarsAllocated >= sponsorshipsAmountInDollarsAvailable);
  }

  getAvailableSponsorsArrayFromEvent = (event) => {
    if (!event || !event.availableSponsors || !event.availableSponsors.edges) {
      return [];
    }

    return event.availableSponsors.edges.map(sponsorship => {
      if (sponsorship && sponsorship.node) {
        return { ...sponsorship.node, available: true };
      } else {
        return null;
      }
    });
  }

  loadSponsorshipsAmounts = (availableSponsors, sponsorships) => {
    const sponsors = availableSponsors.map(availableSponsor => {
      const sponsorship = sponsorships.find(s => s.sponsor.id === availableSponsor.id);

      if (sponsorship) {
        return { ...availableSponsor, amount: sponsorship.amount / 100 };
      } else {
        return { ...availableSponsor, amount: 0 };
      }
    })

    return sponsorships.reduce((accumulatedSponsors, sponsorship) => {
      const availableSponsor = accumulatedSponsors.find(accumulatedSponsor => accumulatedSponsor.id === sponsorship.sponsor.id);

      if (availableSponsor) {
        return accumulatedSponsors;
      } else {
        const unavailableSponsorship = {
          ...sponsorship,
          amount: sponsorship.amount / 100,
          available: false
        }

        return [...accumulatedSponsors, unavailableSponsorship];
      }
    }, sponsors);
  }

  skipThisStepButtonClicked = () => {
    this.setState({ showSkipModal: true });
  };

  selectNourishmentsButtonClicked = (buttonName) => {
    const { event, sponsorshipsAmountInDollarsAvailable, sponsorshipsAmountInDollarsAllocated } = this.state;

    if (sponsorshipsAmountInDollarsAllocated > sponsorshipsAmountInDollarsAvailable) {
      console.log('Invalid sponsorships amount selected');
      return;
    }

    const sponsorshipsSelected = this.state.sponsorships.filter(sponsorship => { return sponsorship.amount > 0 });
    const sponsorshipsInput = sponsorshipsSelected.map(sponsor => {
      return {
        sponsorId: sponsor.id,
        amount: sponsor.amount ? sponsor.amount * 100 : 0
      }
    });

    const mutationInput = {
      eventId: event.id,
      sponsorships: sponsorshipsInput ? sponsorshipsInput : [],
      notifyCreator: (event.state === 'published' && sponsorshipsInput.length > 0)
    }

    if (buttonName === 'bottom-button') {
      this.setState({ submitBottomButtonLoading: true });
    } else if (buttonName === 'nav-button') {
      this.setState({ submitNavButtonLoading: true });
    }

    this.props.updateEventSponsorshipsMutation({ variables: mutationInput });

    const sponsorsWithForms = sponsorshipsSelected.filter(sponsor => sponsor.displayFormAssemblyQuestionsForm);

    if (sponsorsWithForms.length > 0) {
      this.showModalWithSponsorsForms(sponsorsWithForms);
    } else {
      this.handleSubmitSuccess();
    }
  }

  handleSubmitSuccess = () => {
    const { history, isAfterCreate, isSoloEvent, event, settings } = this.props;
    const { settings: { copy } } = this.props;

    this.setState({
      submitBottomButtonLoading: false,
      submitNavButtonLoading: false
    });

    if (isAfterCreate) {
      const shouldShowResourcesPage = settings && settings.features && settings.features.event_creation_questions;
      const pathName = shouldShowResourcesPage ? `/events/${event.uuid}/resources` : `/events/${event.uuid}`;

      history.push({
        pathname: pathName,
        state: { isAfterCreate: true, isSoloEvent }
      });
    } else {
      alert.success(copy.event.nourishment_updated_success);
      history.push(`/manage/hosted/${event.uuid}`);
    }
  };

  formSubmitted = () => {
    const { event } = this.state;

    const mutationInput = {
      eventId: event.id,
      sponsorFormSubmitted: true
    }

    this.props.updateEventSponsorsFormSubmittedMutation({ variables: mutationInput });
  }

  showModalWithSponsorsForms = async (sponsors) => {
    const { client, event } = this.props;
    const sponsorIds = sponsors.map(sponsor => sponsor.id);

    const { data } = await client.query({
      query: EVENT_SPONSORS_QUESTIONS_FORMS,
      variables: { uuid: event.uuid, sponsorIds: sponsorIds }
    })

    let formHtml = null;
    if (data && data.eventByUuid && data.eventByUuid.sponsorsQuestionForm) {
      formHtml = data.eventByUuid.sponsorsQuestionForm;
    }

    this.setState({
      submitBottomButtonLoading: false,
      submitNavButtonLoading: false,
      showSponsorsFormsModal: true,
      formHtml: formHtml
    });
  }

  closeSponsorsFormModal = () => {
    this.setState({ showSponsorsFormsModal: false });
  }

  redirectToHostedEventsPage = () => {

  }

  publishEvent = () => {
    console.log('publish event');
  }

  getSponsorshipWithIdIndex = (sponsorships, sponsorshipId) => {
    return sponsorships.findIndex(sponsorship => {
      return (sponsorship.id === sponsorshipId);
    });
  }

  onAmountChanged = (sponsorshipId, amount) => {
    const sponsorshipIndex = this.getSponsorshipWithIdIndex(this.state.sponsorships, sponsorshipId);
    const sponsorship = this.state.sponsorships[sponsorshipIndex];
    const newSponsorship = { ...sponsorship, amount: amount };
    const actualSponsorships = this.state.sponsorships;
    const newSponsorships = [
      ...actualSponsorships.slice(0, sponsorshipIndex),
      newSponsorship,
      ...actualSponsorships.slice(sponsorshipIndex + 1, actualSponsorships.length),
    ];

    const sponsorshipsAmountInDollarsAllocated = this.calculateSponsorshipsAmountInDollarsAllocated(newSponsorships);
    const shouldDisableSponsorships = this.shouldSponsorshipsInputsBeDisabled(newSponsorships, this.state.sponsorshipsAmountInDollarsAvailable);

    this.setState({
      sponsorships: newSponsorships,
      sponsorshipsAmountInDollarsAllocated: sponsorshipsAmountInDollarsAllocated,
      shouldDisableSponsorships: shouldDisableSponsorships
    });
  }

  closeSkipModal = () => {
    this.setState({ showSkipModal: false });
  };

  closeDonationModal = () => {
    this.setState({ showDonationModal: false });
  };

  render() {
    const {
      settings,
      className,
      event,
      expectedGuests,
      formAlreadySubmitted,
      updateEventSponsorshipsMutation,
      updateFormSubmittedBooleanMutation
    } = this.props;

    const {
      showSponsorsFormsModal,
      formHtml,
      showSkipModal,
      sponsorships,
      sponsorshipsAmountInDollarsAvailable,
      sponsorshipsAmountInDollarsAllocated,
      submitNavButtonLoading,
      submitBottomButtonLoading,
      showDonationModal
    } = this.state;

    let donationSponsorId = null;

    const { settings: { copy } } = this.props;

    const eventId = event.id;

    const invalidAmount = sponsorshipsAmountInDollarsAvailable < sponsorshipsAmountInDollarsAllocated;

    const getSponsorshipElement = (sponsorship) => {
      if (!sponsorship) { return null }

      const shouldDisableSponsor = this.state.shouldDisableSponsorships && (!sponsorship.amount || sponsorship.amount <= 0);

      return (
        <EventSponsorshipAmountSelector
          className="ot-sponsor flex-33 flex-xs-100 ng-scope"
          key={sponsorship.id}
          id={sponsorship.id}
          name={sponsorship.name}
          description={sponsorship.description}
          photoUrl={sponsorship.photo && sponsorship.photo.mediumUrl}
          url={sponsorship.url}
          allowedAmounts={sponsorship.allowedAmounts}
          amount={sponsorship.amount}
          disabled={shouldDisableSponsor}
          onAmountChanged={this.onAmountChanged}
        />
      );
    };

    // console.log(sponsorships);

    const categorizedSponsors = sponsorships.reduce((result, sponsor) => {
      const newSponsor = getSponsorshipElement(sponsor);

      if (sponsor.category === 'DONATION' && sponsor.name && sponsor.name.toLowerCase().includes('donate'))
        donationSponsorId = sponsor.id;

      if (newSponsor && result[sponsor.category || 'NONE']) {
        result[sponsor.category || 'NONE'].sponsorshipElements.push(newSponsor);
      }

      return result;
    }, {
      DONATION: {
        sponsorshipElements: [],
        order: 0,
        title: isSite(settings, 'hillel.sandbox', true) ? 'Give Back' : 'OneTable'
      },

      NATIONALSPONSORS: {
        sponsorshipElements: [],
        order: 1,
        title: 'National'
      },

      LOCALSPONSORS: {
        sponsorshipElements: [],
        order: 2,
        title: 'Local'
      },

      BOOKS: {
        sponsorshipElements: [],
        order: 3,
        title: 'Book'
      },

      NONE: {
        sponsorshipElements: [],
        order: 4,
        title: 'Other'
      },
    });

    const description1String = stringInsert(copy.event.nourished_description_1, [`$$${sponsorshipsAmountInDollarsAvailable}`]);
    const description3html = { __html: copy.event.nourished_description_3 };
    const description4html = { __html: copy.event.nourished_description_4 };

    return (
      <Fragment>
        <EventSponsorsFormModal
          event={event}
          eventId={eventId}
          show={showSponsorsFormsModal}
          toggle={this.closeSponsorsFormModal}
          updateFormSubmittedBooleanMutation={updateFormSubmittedBooleanMutation}
          updateEventSponsorshipsMutation={updateEventSponsorshipsMutation}
          formHtml={formHtml}
          formAlreadySubmitted={formAlreadySubmitted}
          onSubmitSuccess={this.handleSubmitSuccess}
        />
        <EventSponsorshipsSkipModal
          show={showSkipModal}
          toggle={this.closeSkipModal}
          chooseLaterButtonClicked={this.handleSubmitSuccess}
        />
        <EventSponsorshipsDonationModal
          show={showDonationModal}
          toggle={this.closeDonationModal}
          availableAmount={sponsorshipsAmountInDollarsAvailable}
          donationSponsorId={donationSponsorId}
          onUpdateDonationSponsor={this.onAmountChanged}
        />
        <div className={className}>
          <div className="section-header box-with-shadow ot-fixed-subheader">
            <div className="section-header__body">
              <h2>{copy.create.get_nourished}</h2>
              <div className="section-header__button-container">
                <Button
                  className="skip-button"
                  handleClick={this.skipThisStepButtonClicked}
                  disabled={submitNavButtonLoading || submitBottomButtonLoading}
                  buttonStyle="primary_outline"
                >
                  {copy.create.skip_nourished}
                </Button>
                <Button
                  handleClick={() => this.selectNourishmentsButtonClicked('nav-button')}
                  type="submit" buttonStyle="primary"
                  disabled={invalidAmount || submitNavButtonLoading || submitBottomButtonLoading}
                  loading={submitNavButtonLoading}
                >
                  {copy.create.submit_nourished}
                </Button>
              </div>
            </div>
          </div>
          <div className="box-with-shadow section-body nourishment-card page_container">
            <h3 className="event_title">{event && event.title ? event.title : ''}</h3>
            <hr className="separator" />
            <h3 className="get_nourished">{copy.event.nourished_title}</h3>

            <p className="description_text">{description1String}</p>
            <p className="description_text">{copy.event.nourished_description_2}</p>
            <p className="description_text" dangerouslySetInnerHTML={description3html}></p>

            <div className="amounts">
              <div>
                <div className="amount-container">
                  <h2>{expectedGuests}</h2>
                  <p>guests</p>
                </div>
              </div>
              <div className="center">
                <div className="amount-container">
                  <h2>${sponsorshipsAmountInDollarsAvailable}</h2>
                  <p>available</p>
                </div>
              </div>
              <div>
                <div className={`amount-container ${invalidAmount ? 'invalid-amount' : null}`}>
                  <h2>${sponsorshipsAmountInDollarsAllocated}</h2>
                  <p>allocated</p>
                </div>
              </div>
            </div>

            {
              Object.keys(categorizedSponsors)
                .sort((a, b) => categorizedSponsors[a].order - categorizedSponsors[b].order)
                .map((key, i) => {
                const sponsorCategory = categorizedSponsors[key];

                if(!sponsorCategory.sponsorshipElements.length) {
                  return null;
                }

                return (
                  <div className="sponsorship-wrapper" key={i}>
                    <h3 className="sponsorship-section-title">{ sponsorCategory.title }</h3>

                    <div className="row container">
                      { sponsorCategory.sponsorshipElements }
                    </div>

                    <hr className="separator" />
                  </div>
                )
              })
            }

            <Button
              handleClick={() => this.selectNourishmentsButtonClicked('bottom-button')}
              type="submit"
              disabled={invalidAmount || submitNavButtonLoading || submitBottomButtonLoading}
              loading={submitBottomButtonLoading}
            >
              {copy.event.nourished_submit}
            </Button>
            <div className="row nourishment-questions">
              <p dangerouslySetInnerHTML={description4html}></p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const StyledEventSponsorshipsSelector = styled(EventSponsorshipsSelector)`
  padding: 0 20px;

  .row {
    margin: 0 !important;
  }

  a {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  .box-with-shadow {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.05);
    border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    margin-bottom: 20px;
  }

  .section-header {
    width: 100%;
    font-size: 13px;
    height: 66px;
    position: fixed;
    top: 85px;
    left: 0;
    right: 0;
    display: block;
    z-index: 999;
    visibility: visible;
    transition: all 400ms ease;
    transform: translate(0);

    @media (${breakpoints.tablet}) {
      height: auto;
    }
  }

  .ot-title-card {
    margin-top: 150px;
    text-align: center;
    min-height: 90px;
    max-width: ${containersWidths.default};
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
  }

  .section-header__body {
    height: 100%;
    max-width: ${containersWidths.default};
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${breakpoints.tablet}) {
      display: block;
      height: 95px;

      h2 {
        text-align: center;
        margin-top: 18px;
        font-size: 18px;
      }
    }
  }

  h2 {
    margin: 0;
  }

  .section-header__button-container {
    position: absolute;
    height: 66px;
    right: 15px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${breakpoints.tablet}) {
      top: auto;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }

  .skip-button {
    margin-right: 15px;
  }

  .section-body {
    text-align: center;
    margin: 120px auto 40px;
    padding: 60px 5% 0;
  }

  .nourishment-card {
    h3 {
      &.event_title {
        font-size: 20px;
      }

      &.get_nourished {
        font-weight: normal !important;
        font-size: 18px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        text-align: center;
        margin: 0 0 30px;
      }
    }

    .description_text {
      font-size: 14px;
    }
  }

  .bold-text {
    font-weight: bold;
  }

  .amounts {
    text-align: center;
    margin: 40px 0 10px;

    & > div {
      display: inline-block;
      vertical-align: middle;
      width: 150px;
      margin: 0 10px;

      @media (${breakpoints.tablet}) {
        display: block;
        margin: 10px 0;
        width: 100% !important;
      }

      &.center {
        position: relative;
        width: 148px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: ${props => props.settings.theme.css.global.colors.borderColor};

          @media (${breakpoints.tablet}) {
            width: 100px;
            height: 1px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &::before {
          left: -10px;

          @media (${breakpoints.tablet}) {
            top: -5px;
            bottom: auto;
            left: 50%;
          }
        }

        &::after {
          right: -10px;

          @media (${breakpoints.tablet}) {
            top: auto;
            bottom: -5px;
            right: auto;
          }
        }
      }
    }
  }

  .ot-sponsor {
    padding: 15px 15px 30px;
    // margin-bottom: 20px;
    text-align: center;

    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .dollar-input {
      input {
        margin: 0 !important;
      }
    }

    .select_box {
      // margin-top: 15px;

      .label {
        margin-bottom: 0;
      }
    }
  }

  .flex-33 {
    flex: 0 1 33%;
    -webkit-box-flex: 0;
  }

  @media (${breakpoints.tablet}) {
    .flex-xs-100 {
      flex: 0 1 100%;
    }
  }

  .nourishment-questions {
    justify-content: center;
    margin: 25px auto 35px;
    font-size: 14px;
  }

  .amount-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
  }

  .amount-container h2 {
    font-size: 18px !important;
    margin: 0 0 5px 0;
  }

  .invalid-amount {
    border: solid 1px ${props => props.settings.theme.css.global.colors.primary};
  }

  .amount-container p {
    margin: 5px 0;
  }

  .sponsorship-wrapper {
    margin-top: 40px;

    .sponsorship-section-title {
      margin-bottom: 20px;
      font-size: 20px;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 1rem;

    }
  }

  .invalid-amount h2, .invalid-amount p {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  .center-flex {
    align-items: center;
    justify-content: center;
  }
`;

const StyledEventSponsorshipsSelectorWithRouter = withRouter(StyledEventSponsorshipsSelector);
const StyledEventSponsorshipsSelectorWithRouterWithSettings = withSettings(StyledEventSponsorshipsSelectorWithRouter);

export { StyledEventSponsorshipsSelectorWithRouterWithSettings as EventSponsorshipsSelector };
