import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings, FAIcon } from '../../components';

import {
  getScheduledAtDate,
  getEventTimeSpan,
  getLocationWithArea,
  getLocationWithoutArea,
  getLocationNeighborhood,
  getEventLocationWithSubtype,
  isSite,
  whitelabel,
  appendTimezoneOffset,
  stripLeadingSlash
} from '../../../libs';

import { SessionContext } from '../../../Context';

import ROUTES from '../../../constants/routes';


const EventCardLocation = ({ className, event, showArea, settings, location: routerLocation }) => {
  const { copy, theme: { site: { all_events_page_url } }, global: { display_timezone_offsets } } = settings;
  const { timezones } = useContext(SessionContext);
  const allEventsLandingPageUrl = `/${stripLeadingSlash(all_events_page_url) || ROUTES.ALL}`;
  const isAllEventsPage = routerLocation.pathname === allEventsLandingPageUrl;
  const eventSubtype = event.eventSubtype;
  const privacySubtype = event.privacySubtype;
  const eventNeighborhood = event.neighborhood;

  let location = '';

  const shouldShowAreaSubtype = whitelabel.hasFeature(settings, 'showAreaSubtypeLabel');

  const gitTimezoneLabel = () => {
    const zone = Array.isArray(timezones) && timezones.find(tz => tz.name === event.timezone);
    return zone ? appendTimezoneOffset(zone, display_timezone_offsets) : event.timezone;
  };

  const getCityStateLabel = () => {
    let label = '';
    if (event.areaOther) label = event.areaOther;
    if (event.areaOther && event.stateLocation) label += ', ';
    if (event.stateLocation) label += event.stateLocation;
    return label;
  };

  if (whitelabel.hasFeature(settings, 'hideNeighborhood')) {
    location = false;
  } else if (shouldShowAreaSubtype) {
    location = getEventLocationWithSubtype(
      event,
      isSite(settings, 'hillel.international', true),
      settings,
      showArea
    );
  } else {
    if (event.areaOther || isSite(settings, 'haggadot')) {
      location = event.areaOther;
    } else {
      if (whitelabel.hasFeature(settings, 'neighborhoodOnly')) {
        location = getLocationNeighborhood(event);
      } else if (showArea) {
        location = getLocationWithArea(event);
      } else {
        location = getLocationWithoutArea(event);
      }
    }
  }

  const addressIcon = isSite(settings, ['hillel'])
    ? [ 'fas', 'users' ]
    : [ 'fas', 'map-marker-alt' ];

  return (
    <div className={className}>
      <div><FAIcon name={[ 'far', 'calendar-alt' ]} /> {getScheduledAtDate(event)}</div>
      <div><FAIcon name={[ 'far', 'clock' ]} /> {getEventTimeSpan(event)}</div>

      {isAllEventsPage && isSite(settings, [
          'whitelabel',
          'limitedwhitelabel',
          'thegathering',
          'haggadot'
        ]) && event.area && (
          <div><FAIcon name={[ 'fas', 'users' ]} /> {event.area.label}</div>
      )}

      {whitelabel.hasFeature(settings, 'showEventSubtype') && eventSubtype && (
        <div>
          <FAIcon name={[ 'fas', 'users' ]} /> {eventSubtype.name}
        </div>
      )}

      {isSite(settings, ['whitelabel', 'limitedwhitelabel']) ? (
        event.virtual ? (
          <div><FAIcon name={[ 'far', 'phone-laptop' ]} /> Virtual</div>
        ) : (
          (event.areaOther || event.stateLocation) && (
            <div>
              <FAIcon name={[ 'far', 'map-marker-alt' ]} /> {getCityStateLabel()}
            </div>
          )
      )) : (isSite(settings, ['thegathering']) && event.virtual ? (
        <div>
          <FAIcon name={[ 'far', 'phone-laptop' ]} /> Virtual Gathering
        </div>
        ) : (
          location && <div><FAIcon name={addressIcon} /> {location}</div>
      ))}

      {isSite(settings, 'hillel.michigan', true) && eventNeighborhood && (
        <div>
          <FAIcon name={[ 'far', 'calendar-alt' ]} /> RSVPs {eventNeighborhood}
        </div>
      )}

      {!whitelabel.hasFeature(settings, 'hideTimezone') && !isSite(settings, 'hillel.michigan', true) && (
        <div><FAIcon name={[ 'fas', 'globe-americas' ]} /> {gitTimezoneLabel()}</div>
      )}

      {privacySubtype && whitelabel.hasFeature(settings, 'showPrivacySubtype') && (
        <div>
          <FAIcon name={[ 'fas', privacySubtype.virtual ? 'phone-laptop' : 'users' ]} /> {privacySubtype.name}
        </div>
      )}

      {isSite(settings, 'climatefest') && event.virtual && (
        <div><FAIcon name={[ 'far', 'phone-laptop' ]} /> Virtual</div>
      )}
      {!whitelabel.hasFeature(settings, 'hideExtraVirtualIcons') && (
        <>
          {event.closedCaptioningAvailable && (
            <div><FAIcon name={[ 'far', 'closed-captioning' ]} />{copy.create.closed_captioning}</div>
          )}
          {event.videosOnAsDefault && (
            <div><FAIcon name={[ 'far', 'video' ]} />{copy.event.videos_on || 'Videos On By Default'}</div>
          )}
          {event.bathroomAccessible && (
            <div><FAIcon name={[ 'fas', 'restroom' ]} />{copy.create.bathroom_accessible}</div>
          )}
        </>
      )}
    </div>
  );
}

const StyledEventCardLocation = styled(EventCardLocation)`
  font-size: 12px;
  color: ${props => props.settings.theme.css.global.colors.heading};
  text-transform: capitalize;

  svg {
    color: #bbb;
    margin-right: 3px;
    font-size: 13px;

    &.fa-globe-americas {
      margin-right: 5px;
    }

    &.fa-closed-captioning,
    &.fa-video,
    &.fa-restroom {
      margin-right: 8px;
    }
  }

  & > div {
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledEventCardLocationWithSettings = withSettings(StyledEventCardLocation);
const EventCardLocationWithRouter = withRouter(StyledEventCardLocationWithSettings);
export { EventCardLocationWithRouter as EventCardLocation };
