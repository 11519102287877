import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../components';
import {
  Input as LegacyInput,
  Button as LegacyButton,
  InputField,
  UnderlineButton
} from '../components';
import { VALIDATE_COUPON_CODE } from '../../queries';
import { ApolloConsumer } from 'react-apollo';
import { alert } from '../Global';

class RsvpPaymentModalCoupon extends React.Component {

  state = {
    couponCode: '',
    applyingCouponCode: false
  }

  applyCouponCode = async (client) => {
    const { couponCode } = this.state;
    const { couponSelected, session: { theme } } = this.props;

    if (!couponCode) {
      this.setState({ couponCode: '' });
      couponSelected(null);
      return;
    }

    this.setState({
      applyingCouponCode: true
    });

    const { data } = await client.query({
      query: VALIDATE_COUPON_CODE,
      variables: { couponCode: couponCode },
      errorPolicy: 'all'
    });

    const coupon = data && data.viewer && data.viewer.coupon ? data.viewer.coupon : null;

    if (!coupon) {
      alert.error('Your coupon isn\'t valid');
      this.setState({
        applyingCouponCode: false
      });
      couponSelected(null);
    } else {
      this.setState({
        couponCode: theme.isVersion(2) ? couponCode : '',
        applyingCouponCode: false
      });
      couponSelected(coupon);
    }
  }

  removeCouponCode = () => {
    const { couponSelected } = this.props;
    couponSelected(null);
    this.setState({ couponCode: '' });
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { className, settings: { copy }, session: { theme }, isFree } = this.props;
    const { applyingCouponCode, couponCode } = this.state;

    const Heading = ({ children }) => theme.isVersion(2)
      ? <H3>{children}</H3>
      : <h2>{children}</h2>

    const Input = theme.isVersion(2)
      ? InputField
      : LegacyInput;

    const Button = theme.isVersion(2)
      ? UnderlineButton
      : LegacyButton;

    return (
      <ApolloConsumer>
        {client => (
          <div className={[className, 'coupon_section'].join(' ')}>
            {!theme.isVersion(2) && <hr/>}
            <div className="row">
              <Heading>{copy.rsvp.card_coupon_title}</Heading>
            </div>
            {!theme.isVersion(2) && (
              <div className="row insert-coupon-label-row">
                <label>{copy.rsvp.card_coupon_label}</label>
              </div>
            )}
            <div className="row input_row">
              <div className={theme.isVersion(2) ? 'coupon_container' : 'ot-twothirds-container'}>
                <Input
                  name="couponCode"
                  type="text"
                  value={couponCode}
                  placeholder={copy.rsvp.card_coupon_label}
                  onInputChange={this.handleInputChange}
                  onChange={this.handleInputChange}
                  icon={['far', 'tag']}
                  disabled={isFree}
                />
              </div>
              <div className={theme.isVersion(2) ? 'apply_container' : 'ot-twothirds-container'}>
                {theme.isVersion(2) && isFree ? (
                  <Button
                    buttonStyle="primary_outline"
                    onClick={() => { this.removeCouponCode()}}
                  >Remove</Button>
                ) : (
                  <Button
                    buttonStyle="primary_outline"
                    handleClick={() => { this.applyCouponCode(client)}}
                    onClick={() => { this.applyCouponCode(client)}}
                    disabled={applyingCouponCode}
                  >Apply</Button>
                )}
              </div>
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

RsvpPaymentModalCoupon.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCoupon = styled(RsvpPaymentModalCoupon)`
  text-align: left;

  ${({ session: { theme }, isFree }) => theme.isVersion(2) && css`
    max-width: 500px;
    margin-top: ${isFree ? '45px' : '50px'};

    .input_row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 14px;
    }

    .row {
      margin: 0 !important;
    }

    .apply_container {
      padding-top: 13px;
    }
  `}

  & .row {
    margin-left: 0;
    margin-bottom: 10px;
  }

  & .ot-twothirds-container {
    padding-left: 0;
  }

  & p {
    font-size: 14px;
    margin-bottom: 2px;
  }

  & h2 {
    text-align: left;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    margin: 0;
  }

  & label {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }

  & .insert-coupon-label-row {
    margin-bottom: 0.5em;
  }
`;

const StyledRsvpPaymentModalCouponWithSettings = withSettings(StyledRsvpPaymentModalCoupon);
const RsvpPaymentModalCouponWithSession = withSession(StyledRsvpPaymentModalCouponWithSettings);
export { RsvpPaymentModalCouponWithSession as RsvpPaymentModalCoupon };


const H3 = styled.h3`
  font-family: 'Avenir-Demi' !important;
  font-size: 16px;
  margin: 0 0 15px;
`;
