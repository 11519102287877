import React from 'react';

import {
  RadioButtons,
  withSettings
} from '../../components';

import { invalidateText } from '../../../libs';


const EventCreationFormDetailsOptions = ({ settings, eventData, onInputChange, onValidate, invalidate }) => {
  const { copy /*, event: eventSettings */ } = settings;  
  const isOutdoors = eventData.outdoors;
  
  // removed.. should be replaced with privacy subtypes //
  const shouldShowOutdoors = false; //eventSettings.outdoor_tag_as_checkbox;
  
  return (
    <div className="form_group radios break_on_mobile">
      <div className={isOutdoors ? 'one_third' : 'one_half'}>
        {/* Parking */}
        <label>{copy.create.parking}</label>
        <RadioButtons
          name="parkingLots"
          value={eventData.parkingLots}
          selected={eventData.parkingLots}
          onInputChange={onInputChange}
          align="left"
        />
      </div>
      <div className={isOutdoors ? 'one_third' : 'one_half'}>
        {/* Wheelchair Accessibility */}
        <label>{copy.create.wheelchair}</label>
        <RadioButtons
          name="accessible"
          value={eventData.accessible}
          selected={eventData.accessible}
          onInputChange={onInputChange}
          align="left"
        />
      </div>
      {isOutdoors && (
        <div className="one_third">
          {/* Bathroom Accessible */}
          <label>{copy.create.bathroom_accessible}</label>
          <RadioButtons
            name="bathroomAccessible"
            value={eventData.bathroomAccessible}
            selected={eventData.bathroomAccessible}
            onInputChange={onInputChange}
            align="left"
          />
        </div>
      )}
      
      {false && shouldShowOutdoors && (
        <div className="one_third">
          {/* Outdoors event */}
          <label>{copy.create.outdoors || 'Outdoors, Socially Distant'}</label>
          <RadioButtons
            name="outdoors"
            value={eventData.outdoors.toString()}
            selected={eventData.outdoors.toString()}
            onInputChange={(name, value) => {
              const outdoorProps = {
                outdoors: value === 'true',
                tags: eventData.tags,
              };

              if (value === 'true') {
                if (!eventData.tags.includes('outdoors')) outdoorProps.tags = eventData.tags.concat(['outdoors']);
              } else {
                outdoorProps.tags = eventData.tags.filter(tag => tag !== 'outdoors');
              }

              onInputChange(outdoorProps);
            }}
            align="left"
            onValidate={onValidate}
            invalidate={() => invalidate && invalidateText(eventData.outdoors)}
            errorMessage="Select an option."
            required
          />
        </div>
      )}
    </div>
  );
};

const EventCreationFormDetailsOptionsWithSettings = withSettings(EventCreationFormDetailsOptions);
export { EventCreationFormDetailsOptionsWithSettings as EventCreationFormDetailsOptions };