import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { withSettings, withSession, LogInModal, MembershipSelectionModal, ErrorModal, CancelReservationsModal } from '../components';
import { alert } from '../Global';
import { getEventPotluckEdges, isApprovalEvent, localStorage, isSite } from '../../libs';
import {
  RsvpPotluckModal,
  RsvpQuestionsModal,
  RsvpMainModal,
  RsvpPaymentModal,
  RsvpSuccessModal
} from './';

const { Stripe } = window;

// Keys to open the validation modal according to the BE response's code
const validationKeys = {
  HOST_HOSTING_SAME_DAY: 'host_hosting',
  USER_ATTENDING_SAME_DAY: 'user_attending',
  ATTENDING_AS_ADDITIONAL_GUEST_SAME_DAY: 'attending_as_additional_guest',
  ADDITIONAL_GUEST_SAME_DAY: 'additional_guests'
}

const reservation_states = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED'
}
class RsvpModalContainer extends React.Component {

  constructor(props) {
    super(props);

    const {
      event,
      rsvpModals,
      setRsvpModals,
      session: { viewer },
      showPaymentModal,
      showSuccessModal
    } = props;

    const reservationQuestions = this.getReservationQuestionsFromEvent(event);
    const reservationQuestionsModalAvailable = (reservationQuestions.length > 0);

    const potluckEdges = getEventPotluckEdges(event);
    const potluckModalAvailable = (event.potluck && potluckEdges.length > 0);

    const eventAreaMembership = event.area.membership;
    const areaMembershipProducts = eventAreaMembership?.products?.filter(({ active, prices }) =>
        active && prices.filter(({ active: activePrice }) => activePrice)
    );

    let newRsvpModals = [];

    if (reservationQuestionsModalAvailable) {
      newRsvpModals = [...newRsvpModals, 'questions'];
    }

    if (potluckModalAvailable) {
      newRsvpModals = [...newRsvpModals, 'potluck'];
    }

    newRsvpModals = [...newRsvpModals, 'main'];

    if (
      areaMembershipProducts?.length && !viewer.subscriptions.length &&
      !viewer.user?.profile?.reservationMembershipRequirementExempt
    ) {
      newRsvpModals = [...newRsvpModals, 'membership'];
    }

    let dataFromStorage = {};

    if (showPaymentModal) {
      dataFromStorage = this.loadDataFromStorage();
      setRsvpModals(['payment', 'success']);
    } else if (showSuccessModal) {
      setRsvpModals(['success']);
    } else if (!rsvpModals.length) setRsvpModals(newRsvpModals);

    this.state = {
      claimedPotluckItems: [],
      answers: [],
      additionalGuests: [],
      donation: 0,
      hostMessage: '',
      tables: [],
      membership: areaMembershipProducts?.length ? event.area.membership : null,
      priceSelection: null,
      inHonor: false,
      isPaying: false,
      isRsvping: false,
      showLogInModal: false,
      modalContent: 'login',
      ccError: false,
      ccErrorObj: null,
      validationKey: null,
      tempSavedData: null,
      ...dataFromStorage
    }
  }

  componentDidMount() {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features } } = this.props;
    const paidEventsEnabled = (features && (features.paid_events === true || features.paid_events === 'true'));

    this.setState({ paidEventsEnabled });
  }

  saveDataToStorage = () => {
    const { hostMessage, donation, additionalGuests, answers, claimedPotluckItems, inHonor, inHonorOf } = this.state;
    localStorage.store({
      hostMessage,
      donation,
      additionalGuests,
      answers,
      claimedPotluckItems,
      inHonor,
      inHonorOf,
      afterMembershipSuccess: true
    }, 'reservation_data');
  };

  loadDataFromStorage = () => {
    const data = localStorage.load('reservation_data') || {};
    localStorage.resetAll('reservation_data');
    return data;
  };

  getReservationQuestionsFromEvent = (event) => {
    const { settings: { copy } } = this.props;
    let questions = event.reservationQuestions.edges.map(question => question.node);
    questions = questions.filter(question => !question.disabled);

    return questions.map(question => {
      if (question.baseQuestion && question.baseQuestion.presenceType && question.baseQuestion.presenceType === 'ALWAYS') {
        const optionsIds = ['1', '2', '3', '4', '5'];

        const options = optionsIds.map(id => {
          return { id: id, name: copy.rsvp[`default_question_label_${id}`], tooltip: copy.rsvp[`default_question_hover_${id}`] }
        })

        question.options = options;
      }

      return question;
    });
  }

  buildReservationQuestionsAnswersFromQuestions = (questions) => {
    const answers = questions.reduce((accumulator, question) => {
      const tempAnswers = { ...accumulator };
      tempAnswers[question.id] = '';
      return tempAnswers;
    }, {});

    if (questions.length > 0) {
      return answers;
    } else {
      return {};
    }
  }

  showPreviousModal = () => {
    const { modalSelected, changeRsvpModalSelected } = this.props;
    changeRsvpModalSelected(modalSelected - 1);
  }

  showNextModal = () => {
    const { modalSelected, changeRsvpModalSelected } = this.props;
    changeRsvpModalSelected(modalSelected + 1);
  }

  closeRsvpModalContainer = () => {
    const { closeRsvpModalContainer } = this.props;
    closeRsvpModalContainer();
  }

  potluckModalNextButtonClicked = (potluckItems) => {
    const { modalSelected, changeRsvpModalSelected } = this.props;

    const claimedPotluckItems = Object.keys(potluckItems).map(key => {
      const potluckItemId = parseInt(key);

      if (!potluckItemId) {
        return null;
      }

      if (!potluckItems[key]) {
        return null;
      }

      return {
        potluckItemId: potluckItemId,
        quantity: potluckItems[key]
      }
    }).filter(potluckItem => potluckItem != null);

    this.setState({ claimedPotluckItems });

    changeRsvpModalSelected(modalSelected + 1);
  }

  questionsModalNextButtonClicked = (answers) => {
    const { modalSelected, changeRsvpModalSelected } = this.props;

    this.setState({
      answers: answers
    });

    changeRsvpModalSelected(modalSelected + 1);
  }

  createReservationInput = ({ guests, tables, hostMessage }) => {
    const { event } = this.props;
    return {
      eventId: event.id,
      additionalGuests: guests,
      rsvpNote: hostMessage,
      donation: 0,
      tables: tables,
      claimedPotluckItems: this.state.claimedPotluckItems,
      answers: this.state.answers
    };
  }

  createReservation = async (input) => {
    const { createReservationMutation, refetchData } = this.props;
    const response = await createReservationMutation({ variables: input, errorPolicy: 'all' });
    if (response && !response.errors) await refetchData();
    return response;
  }

  reservationCreated = () => {
    const { reservationCreated } = this.props;
    reservationCreated();
  }

  closeDuplicateEntryErrorModal = () => {
    const { changeRsvpModalSelected } = this.props;
    changeRsvpModalSelected(-1);
    this.setState({ showCancelReservationModal: false })
  }

  rsvpButtonClicked = (data, membershipExempt) => {
    const rsvpData = data || this.state.savedData || {};
    const { donation, guests, tables, hostMessage } = rsvpData;
    const {
      event,
      modalSelected,
      changeRsvpModalSelected,
      rsvpModals,
      setRsvpModals,
      session: { viewer }
    } = this.props;
    const { paidEventsEnabled, membership } = this.state;

    let newModals = [...rsvpModals];
    const paymentModal = rsvpModals.find(item => item === 'payment');
    const successModal = rsvpModals.find(item => item === 'success');
    const shouldShowPaymentModal = (donation > 0 || event.pricePerPerson > 0 || event.pwyw);
    const shouldShowMembershipModal = membership && !viewer.subscriptions.length &&
      !membershipExempt && !viewer.user?.profile?.reservationMembershipRequirementExempt;

    if (paidEventsEnabled && shouldShowPaymentModal) {
      if (!paymentModal) {
        newModals = [...newModals, 'payment'];
      }
    } else {
      if (paymentModal) {
        newModals = newModals.filter(item => item !== 'payment');
      }
    }

    if (!successModal) {
      newModals = [...newModals, 'success'];
    }

    const newState = {
      claimedPotluckItems: this.state.claimedPotluckItems,
      answers: this.state.answers,
      additionalGuests: guests,
      donation: donation * 100,
      tables: tables,
      hostMessage: hostMessage,
      isRsvping: false,
      savedData: data
    };

    const input = this.createReservationInput(rsvpData);

    setRsvpModals(newModals);

    if (shouldShowMembershipModal || shouldShowPaymentModal) {
      this.setState(newState);
      changeRsvpModalSelected(modalSelected + 1);
    } else {
      this.setState({ ...newState, isRsvping: true }, async () => {
        const res = await this.createReservation(input);
        const responseState = { isRsvping: false };

        if (res.errors) {
          const validationKey = validationKeys[res.errors[0].extensions.code];

          // close RSVP modal unless +1 validation was hit
          if (validationKey !== validationKeys.ADDITIONAL_GUEST_SAME_DAY) this.closeRsvpModalContainer();

          if (validationKey === validationKeys.USER_ATTENDING_SAME_DAY) {
            // data for cancel reservation modal
            responseState.tempSavedData = { data };
            responseState.showCancelReservationModal = true;
          } else {
            // key for other validation messages
            // modal will show when `validationKey` !== null
            responseState.validationKey = validationKey;
          }
        } else {
          changeRsvpModalSelected(modalSelected + 1);
        }

        this.setState(responseState);
      });
    }
  }

  payButtonClicked = ({ couponId, totalPrice, cardId, newCard, customAmount }) => {
    const input = { couponId, totalPrice, customAmount };

    if (totalPrice === 0) {
      this.setState({ isPaying: true, ccError: false, ccErrorObj: null }, () => {
        this.pay(input);
      });
    } else {
      this.setState({ isPaying: true, ccError: false, ccErrorObj: null });

      if (!cardId) {
        this.createCreditCard(newCard, (response) => {
          if (response && response.creditCard && response.creditCard.id) {
            this.pay({ ...input, cardId: response.creditCard.id });
          } else {
            this.setState({ isPaying: false, ccError: true, ccErrorObj: response.error });
          }
        });
      } else {
        this.pay({ ...input, cardId });
      }
    }
  }

  createCreditCard = async (newCard, callback) => {
    if (!newCard) { callback({ error: 'Card information mising ' }); }

    const { fullName, cardNumber, securityCode, expirationMonth, expirationYear, useAsDefaultPayment } = newCard;

    const card = {
      number: cardNumber,
      name: fullName,
      cvc: securityCode,
      exp_month: expirationMonth,
      exp_year: expirationYear
    }

    Stripe.card.createToken(card, (status, stripeResponse) => {
      if (stripeResponse.error) {
        callback({ error: stripeResponse && stripeResponse.error ? stripeResponse.error : 'Card could not be created' });
      } else {
        const variables = { stripeToken: stripeResponse.id, favorite: useAsDefaultPayment };

        this.props.createCreditCardMutation({ variables, errorPolicy: 'all' }).then((response) => {
          if (response && response.data && response.data.createCard && response.data.createCard.card) {
            callback({ creditCard: response.data.createCard.card });
          } else {
            callback({ error: response && response.error ? response.error : 'Card could not be created' });
          }
        });
      }
    });
  }

  pay = async ({ couponId, totalPrice, cardId, customAmount }) => {
    const { event, changeRsvpModalSelected, modalSelected } = this.props;
    const { donation, additionalGuests, tables, hostMessage, claimedPotluckItems, answers } = this.state;

    const input = {
      eventId: event.id,
      couponId,
      donation,
      additionalGuests,
      tables,
      rsvpNote: hostMessage,
      claimedPotluckItems,
      answers
    };

    if (totalPrice) input.payment = { cardId, totalPrice };
    if (event.pwyw) input.pwywAmount = customAmount * 100;

    const response = await this.createReservation(input);
    if (response.errors) {
      this.showResponseError(response);
    } else {
      this.setState({ isPaying: false });
      changeRsvpModalSelected(modalSelected + 1);
    }
  }

  showResponseError = (response) => {
    const { settings: { copy } } = this.props;

    let errorMessage = '';
    let isUnauthenticated = false;

    if (response && response.errors && response.errors.length > 0) {
      const e = response.errors[0];
      if (e.extensions && e.extensions.errors && e.extensions.errors.length > 0) {
        isUnauthenticated = e && e.extensions.code === 'UNAUTHENTICATED';

        if (isUnauthenticated) {
          this.toggleLogInModal();
        } else {
          errorMessage = e.extensions.errors[0].message;
        }
      }
    }

    alert.error(errorMessage || copy.manage.message_error);

    this.setState({ isPaying: false, isRsvping: false });
  }

  handleChangeIsRsvpingState = value => {
    this.setState({ isRsvping: value });
  }

  toggleLogInModal = () => {
    this.setState({
      showLogInModal: !this.state.showLogInModal
    });
  }

  updateLoginModalContent = (content, openModal) => {
    const newState = { modalContent: content };

    if (openModal) {
      newState['showLogInModal'] = true;
    }

    this.setState(newState);
  };

  handleAfterLogin = () => {
    this.toggleLogInModal();
  }

  handleSetExemptFromMembership = async () => {
    const { updateProfileMutation } = this.props;
    const { data } = await updateProfileMutation({ variables: { reservationMembershipRequirementExempt: true } });
    // need to handle errors //
    this.rsvpButtonClicked(false, data.updateProfile && !data.updateProfile.errors);
    this.setState({ freeMembershipSuccess: true });
  };

  render() {
    const { event, areas, viewer, modalSelected, rsvpModals, afterMembershipSuccess, settings } = this.props;
    const { copy } = settings;
    const {
      donation,
      additionalGuests,
      isPaying,
      isRsvping,
      showLogInModal,
      modalContent,
      ccError,
      ccErrorObj,
      freeMembershipSuccess,
      validationKey
    } = this.state;

    const successMessage = freeMembershipSuccess
      ? copy.membership?.free_membership_success_message || (
        isSite(settings, 'dinner-party')
          ? 'Welcome to The Dinner Party community – we know what it\'s like to lose someone and we aren\'t afraid to talk about it.'
          : copy.event.success_content_public
      )
      : isApprovalEvent(event) ? copy.event.success_content_pending : copy.event.success_content_public;

    const allUserReservations = viewer.currentReservations?.nodes;
    let previousReservations = null;

    if (Array.isArray(allUserReservations)) {
      const eventDate = moment(event.scheduledAt);

      previousReservations = allUserReservations.filter(userReservation => {
        return (userReservation.state === reservation_states.PENDING || userReservation.state === reservation_states.ACCEPTED) && moment(userReservation.event?.eventDate).isSame(eventDate, 'day')
      });
    }
    return (
      <>
        <RsvpPotluckModal
          show={rsvpModals[modalSelected] === 'potluck'}
          toggle={this.closeRsvpModalContainer}
          event={event}
          backButtonClicked={this.showPreviousModal}
          nextButtonClicked={this.potluckModalNextButtonClicked}
        />
        <RsvpQuestionsModal
          show={rsvpModals[modalSelected] === 'questions'}
          toggle={this.closeRsvpModalContainer}
          event={event}
          backButtonClicked={this.showPreviousModal}
          nextButtonClicked={this.questionsModalNextButtonClicked}
        />
        <RsvpMainModal
          show={rsvpModals[modalSelected] === 'main'}
          toggle={this.closeRsvpModalContainer}
          event={event}
          rsvpButtonClicked={this.rsvpButtonClicked}
          onChangeIsRsvpingState={this.handleChangeIsRsvpingState}
          isRsvping={isRsvping}
          hasMembership={rsvpModals.includes('membership')}
        />
        <MembershipSelectionModal
          show={rsvpModals[modalSelected] === 'membership'}
          toggle={this.closeRsvpModalContainer}
          eventId={event.id}
          getField={key => this.state[key]}
          setField={(key, val) => this.setState({ [key]: val })}
          saveDataToStorage={this.saveDataToStorage}
          onExemptFromMembership={this.handleSetExemptFromMembership}
        />
        <RsvpPaymentModal
          show={rsvpModals[modalSelected] === 'payment'}
          toggle={this.closeRsvpModalContainer}
          event={event}
          areas={areas}
          viewer={viewer}
          additionalGuests={additionalGuests}
          donation={donation > 0 ? donation : 0}
          isPaying={isPaying}
          payButtonClicked={this.payButtonClicked}
          ccError={ccError}
          ccErrorObj={ccErrorObj}
        />
        <RsvpSuccessModal
          show={rsvpModals[modalSelected] === 'success'}
          successMessage={successMessage}
          toggle={this.reservationCreated}
          event={event}
          areas={areas}
          afterMembershipSuccess={afterMembershipSuccess}
        />
        <LogInModal
          show={showLogInModal}
          toggle={this.toggleLogInModal}
          updateModalContent={this.updateLoginModalContent}
          content={modalContent}
          shouldRedirect={false}
          afterLogin={this.handleAfterLogin}
        />
        {
          previousReservations &&
          <CancelReservationsModal
            show={this.state.showCancelReservationModal}
            toggle={this.closeDuplicateEntryErrorModal}
            reservations={previousReservations}
            currentEvent={event}
            createReservation={() => this.createReservation(
              this.createReservationInput(this.state.tempSavedData.data)
            )}
          />
        }
        <ErrorModal
          show={validationKey !== null}
          toggle={() => this.setState({ validationKey: null })}
        >
          <p>{copy.rsvp[`${validationKey}_same_day`]}</p>
        </ErrorModal>
      </>
    );
  }
}

RsvpModalContainer.propTypes = {
  className: PropTypes.string
};

const StyledRsvpModalContainer = styled(RsvpModalContainer)`

`;

const StyledRsvpModalContainerWithSettings = withSettings(StyledRsvpModalContainer);
const RsvpModalContainerWithSettings = withSession(StyledRsvpModalContainerWithSettings);

export { RsvpModalContainerWithSettings as RsvpModalContainer };
