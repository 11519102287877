import gql from 'graphql-tag';

export const GET_RESERVATION_CANCEL_REASONS = gql`
  query reservationCancelReasons {
    reservationCancelReasons {
      cancelReason
      requireText
    }
  }
`;

export const ALL_USER_RESERVATIONS = gql`
  query allUserReservations($profileId: Int!) {
    allUserReservations(profileId: $profileId) {
      id
      event {
        id
        title
        eventDate
      }
      state
    }
  }
`;
