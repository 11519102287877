import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  LogIn,
  SignUp,
  Logo,
  ButtonTabs,
  withSettings
} from '../components';

import { breakpoints } from '../../styles';

const viewTabs = [
  { name: 'Sign Up', value: 'signup' },
  { name: 'Log In', value: 'login' }

];

class LogInModal extends React.Component {
  contentView = (content, shouldRedirect, afterLogin, allowEditPhoneNumber) => {
    switch (content) {
      case 'login':
        return (<LogIn
          afterLogin={afterLogin}
          shouldRedirect={shouldRedirect}
          allowEditPhoneNumber={allowEditPhoneNumber}
        />)
      case 'signup':
        return (<SignUp
          afterLogin={afterLogin}
          shouldRedirect={shouldRedirect}
          allowEditPhoneNumber={allowEditPhoneNumber}
        />)
      default:
        return (<LogIn
          afterLogin={afterLogin}
          shouldRedirect={shouldRedirect}
          allowEditPhoneNumber={allowEditPhoneNumber}
        />)
    }
  }
  render() {
    const {
      className,
      show,
      toggle,
      content,
      updateModalContent,
      settings,
      shouldRedirect,
      afterLogin,
      allowEditPhoneNumber
    } = this.props;
    const { copy } = settings;

    return (
      <Modal
        show={show}
        toggle={toggle}
        size="lg"
        noPadding
      >
        <div className={className}>
          <div className="logo_background" />
          <div className="logo_content">
            <Logo layout="vertical" color="white" />
            <p dangerouslySetInnerHTML={{ __html: copy.login.description2 }}/>
          </div>
          <div className="form_section">
            <ButtonTabs
              tabs={viewTabs}
              activeTab={content}
              onSelectTab={updateModalContent}
              capitalize
            />
            {this.contentView(content, shouldRedirect, afterLogin, allowEditPhoneNumber)}
          </div>
        </div>
      </Modal>
    );
  }
}

const StyledLogInModal = styled(LogInModal)`
  position: relative;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > .logo_background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-image: url(${props => props.settings.theme.assets.images.login_modal_bg});
    background-size: cover;
    background-position: top;
    border-radius: 4px 0 0 4px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51,30,40,0.3);
      z-index: 1;
    }

    @media (${breakpoints.modal}) {
      display: none;
    }
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    @media (${breakpoints.modal}) {
      background-image: url(${props => props.settings.theme.assets.images.login_modal_bg});
      background-size: cover;
      background-position: center;
      float: none;
      width: 100%;
    }

    &::after {
      display: none;
      content: '';

      @media (${breakpoints.modal}) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(51,30,40,0.3);
        z-index: 1;
      }
    }

    & > .logo {
      width: 140px;
      margin: 0 auto;

      @media (${breakpoints.modal}) {
        position: relative;
        z-index: 2;
      }
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;

      @media (${breakpoints.modal}) {
        position: relative;
        z-index: 2;
      }
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;

    @media (${breakpoints.modal}) {
      float: none;
      width: 100%;
    }
  }
`;

const LogInModalWithSettings = withSettings(StyledLogInModal);

export { LogInModalWithSettings as LogInModal };
