import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { stringInsert } from '../../libs';
import { breakpoints } from '../../styles';

import {
  Modal,
  Button,
  LoaderModal,
  FAIcon,
  Checkbox,
  WYSIWYGEditor,
  alert
} from '../components';

import {
  VIEWER_EVENTS
} from '../../queries';

import {
  EVENT_FILTER
} from '../../enums';

import {
  getEventWrittenDate
} from '../../libs';

import { withSettings } from '../components';

class ManageEventsDetailsInvitePastModal extends React.Component {
  state = {
    emails: [],
    selectedItem: 0,
    search: '',
    message: '',
    sendingInvitations: false
  };

  handleChangeEmail = email => {
    let list = this.state.emails;

    if (list.includes(email)) {
      list = list.filter(elem => { return elem !== email });
    } else {
      list.push(email);
    }

    this.setState({ emails: list });
  }

    handleMessageChange = (name, value) => {
      this.setState({ message: value });
    }

    setSelectedItem = index => {
      this.setState({
        search: '',
        selectedItem: index
      });
    }

    handleChangeSearch = e => {
      this.setState({ search: e.target.value });
    }

  handleSendInvitation = () => {
    const { sendInvitations, event, toggle, refetchPendingInvitations, settings: { copy } } = this.props;
    const { emails, message } = this.state;

    const variables = {
      eventId: event.id,
      emails,
      text: message
    };

    this.setState({ sendingInvitations: true });
    sendInvitations({ variables })
      .then(() => {
        refetchPendingInvitations()
          .then(() => {
            this.setState({ sendingInvitations: false });
            toggle(true);
            alert.success('Invitations sent');
          })
          .catch(err => console.error(err));
      })
      .catch(() => {
        this.setState({ sendingInvitations: false });
        alert.error(copy.manage.invite_failed_message);
      })
  }

  render() {
  const { className, open, toggle } = this.props;
  const { settings: { copy } } = this.props;
  const { emails, selectedItem, search, message, sendingInvitations }  = this.state;

  return (
    <Modal size="md" show={open} toggle={toggle} noPadding>
      <Query query={VIEWER_EVENTS} variables={{ filter: EVENT_FILTER.PAST, isHost: true, orderDirection: 'DESC' }}>
        {({ data, loading, error }) => {
          if (error) {
            console.log('ERROR: ', error)
          } else if (!loading) {
            const viewerEvents = data.viewer.events;
            const selectedEvent = viewerEvents && viewerEvents.nodes[selectedItem];
            const selectedEventReservations = (selectedEvent && selectedEvent.reservations && selectedEvent.reservations.nodes) || [];

            return (
              <div className={className}>
                <header>
                  <title>{copy.invite_past.invite_guests}</title>
                  <p>{copy.invite_past.invite_others}</p>
                </header>
                <div className="lists">
                  <ul className="events">
                    {data.viewer.events.nodes.map((item, index) => (
                      <li
                        className={index === selectedItem ? 'active' : null}
                        onClick={() => this.setSelectedItem(index)}
                        key={index}
                      >
                        <p className="title">{item.title}</p>
                        <p className="date">{getEventWrittenDate(item)}</p>
                        <FAIcon name="chevron-right" />
                      </li>
                    ))}
                  </ul>
                  {selectedEventReservations.length > 0 && (
                    <div className="search_box">
                      <input
                        type="text"
                        value={search}
                        onChange={this.handleChangeSearch}
                        placeholder="Search"
                      />
                    </div>
                  )}
                  {selectedEventReservations.length > 0 ? (
                      <ul className="attendees">
                        {selectedEventReservations.map((item, index) => {
                          if (item.state === 'ACCEPTED' && item.profile &&
                            (search === ''
                              || item.profile.firstName.toLowerCase().startsWith(search.toLowerCase())
                              || item.profile.lastName.toLowerCase().startsWith(search.toLowerCase()))) {

                            return (
                              <li key={index} onClick={() => this.handleChangeEmail(item.profile.user.email)}>
                                <span className="past-user">
                                  <p>{`${item.profile.firstName} ${item.profile.lastName}`}</p>
                                  <Checkbox
                                    name="email"
                                    options={[{ checked: emails.includes(item.profile.user.email) }]}
                                  />
                                </span>
                              </li>
                            );
                          } else return null;
                        })}
                      </ul>
                  ) : (
                    <div className="not-found">
                      <span>{copy.manage.past_guests_not_found ? copy.manage.past_guests_not_found : 'No attendees for this dinner'}</span>
                    </div>
                  )}
                </div>
                <div className="amount">
                  {stringInsert(copy.invite_past.selected, emails.length)}
                </div>
                <div>
                  <WYSIWYGEditor
                    name='message'
                    value={message}
                    onInputChange={this.handleMessageChange}
                    placeholder='Write a Message'
                  />
                </div>
                <div className="actions">
                  <Button inverted handleClick={() => { toggle() }}>
                    Cancel
                  </Button>
                  <Button
                    loading={sendingInvitations}
                    disabled={emails.length > 0 && message.length > 0 ? false : true}
                    handleClick={() => this.handleSendInvitation()}
                  >
                  Invite
                  </Button>
                </div>
              </div>
            );
          } else {
            return (
              <div className={className}>
                <LoaderModal/>
              </div>
            );
          }
        }}
      </Query>
    </Modal>
    );
  }
}

const StyledManageEventsDetailsInvitePastModal= styled(ManageEventsDetailsInvitePastModal)`
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;

  header {
    text-align: center;
    margin-bottom: 30px;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }

  label {
    margin-top: 20px;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .events {
    width: 50%;
    border-right: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};

    @media (${breakpoints.modal}) {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    }

    li {
      position: relative;
      padding-left: 15px;

      &.active {
        p {
          &.title,
          &.date {
            color: #fff;
          }
        }
      }

      p {
        &.title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 2px;
        }

        &.date {
          font-size: 12px;
        }
      }

      .fa-chevron-right {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .lists {
    position: relative;
    display: flex;
    border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    border-radius: 6px;

    @media (${breakpoints.modal}) {
      display: block;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
  .not-found {
    display: flex;
    justify-content: center;
    flex: 1;
    min-height: 100px;

    * {
      align-self: center;
    }

    span {
      font-size: 13px;
    }
  }
  ul {
    list-style: none;
    height: 250px;
    overflow-y: auto;
    padding-left: 0;
    margin-bottom: 0;

    li {
      // display: flex;
      // justify-content: space-between;
      // background-color: #fbfaf9;
      border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
      padding: 10px;
      cursor: pointer;
    }
    .active {
      background-color: ${props => props.settings.theme.css.global.colors.primary};
      color: white;

      span {
        color: white
      }
    }
  }

  .search_box {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    padding: 7px;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    z-index: 600;

    @media (${breakpoints.modal}) {
      top: 250px;
      left: 0;
    }

    input {
      margin: 0;
    }
  }

  .attendees {
    flex: 1;
    padding-top: 52px;
    margin-left: 7px;
    margin-right: 7px;

    .past-user {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // padding: 5px 10px;

      p {
        align-self: center;
        margin-bottom: 0px;
      }

      & > div {
        top: 3px
      }
    }

    li {
      background-color: white;
      padding-left: 15px !important;

      .checkbox__container {
        margin-right: 0;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  .amount {
    text-align: center;
    color: ${props => props.settings.theme.css.global.colors.primary};
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .invite_message {
    font-size: 14px;
  }
`;

const StyledManageEventsDetailsInvitePastModalWithSettings = withSettings(StyledManageEventsDetailsInvitePastModal);

export { StyledManageEventsDetailsInvitePastModalWithSettings as ManageEventsDetailsInvitePastModal }
