import gql from 'graphql-tag';

export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
  mutation ($eventId: Int!, $stripePriceId: String!, $customMetadata1: String) {
    createStripeCheckoutSession(input: {
      eventId: $eventId,
      stripePriceId: $stripePriceId,
      customMetadata1: $customMetadata1
    }) {
      redirectUrl
      errors {
        message
        path
      }
    }
  }
`;

export const CREATE_STRIPE_BILLING_PORTAL_SESSION = gql`
  mutation {
    createStripeBillingPortalSession(input: {}) {
      clientMutationId
      redirectUrl
    }
  }
`;
