/* from https://stackoverflow.com/a/47340986 */
/**
 * Takes an array of arrays and returns a `,` sparated csv file.
 * @param {string[][]} table
 * @returns {string}
 */
export function toCSV(table) {
  return table
    .map(row =>
      row
        .map(cell => {
          if (cell == null) return '';
          // We remove blanks and check if the column contains
          // other whitespace,`,` or `"`.
          // In that case, we need to quote the column.
          if (cell.replace(/ /g, '').match(/[\s,"]/)) {
            return '"' + cell.replace(/"/g, '""') + '"';
          }
          return cell;
        })
        .join(',')
    )
    .join('\n');
}
