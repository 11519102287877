import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSettings } from '../../hooks';

import { LANDING_PAGE } from '../../queries';

const LandingPageContext = React.createContext();


const LandingPageContextProvider = ({ url, children }) => {
  const [page, setPage] = useState();
  const [area, setArea] = useState();

  const { data, loading, refetch } = useQuery(LANDING_PAGE, { variables: { url } });

  useEffect(() => {
    const landingPage = data?.landingPage;
    const landingArea = landingPage?.areas.find(a => a.url === url);
    landingPage && setPage(landingPage);
    landingArea && setArea(landingArea);
  }, [data, loading]);

  const { theme: { assets: { images }, site } } = useSettings();
  const { all_events_page_hero_alt, title } = site;
  const isAllEvents = !area && !loading;

  const heroImage = {
    src: (isAllEvents ? images.all_events_header : area?.photo?.largeUrl) || '',
    alt: (isAllEvents ? all_events_page_hero_alt : area?.label) || title
  };

  const value = {
    page,
    area,
    heroImage,
    loading,
    refetch
  };

  return <LandingPageContext.Provider value={value}>{children}</LandingPageContext.Provider>;
};

export { LandingPageContext, LandingPageContextProvider };
