import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
  Card,
  ButtonTabs,
  EventCreationProvidingSelector,
  EventCreationPotluckSelector,
  withSettings
} from '../../components';

import { breakpoints } from '../../../styles';

const VIEWS = {
  PROVIDING: 'providing',
  POTLUCK: 'potluck',
  CATERED: 'catered'
};

const EventCreationFormMeal = ({ 
  className,
  eventData, 
  onInputChange, 
  invalidate, 
  isCommunity, 
  isUpdate, 
  isDraft,
  hidePotluck,
  settings: { copy, event: { catered } } 
}) => {
  const [currentView, setCurrentView] = useState(VIEWS.PROVIDING);
  
  useEffect(() => {
    eventData?.potluck && !hidePotluck && setCurrentView(VIEWS.POTLUCK);
    eventData?.catered && setCurrentView(VIEWS.CATERED);
  }, [eventData, hidePotluck, setCurrentView]);

  useEffect(() => {
    currentView === VIEWS.POTLUCK && hidePotluck && setCurrentView(VIEWS.PROVIDING);
  }, [currentView, hidePotluck, setCurrentView]);
  
  const handleUpdateView = view => {
    setCurrentView(view);
    onInputChange('potluck', view === VIEWS.POTLUCK);
    onInputChange('catered', view === VIEWS.CATERED);
  };
  
  const renderMealView = () => {
    switch (currentView) {
      case VIEWS.CATERED:
        return (
          <div
            className="catered_container"
            dangerouslySetInnerHTML={{
              __html: copy.create.catered_instructions || 'This event is catered.'
            }}
          />
        );
      case VIEWS.POTLUCK:
        return (
          <EventCreationPotluckSelector
            eventData={eventData}
            onInputChange={onInputChange}
            invalidate={invalidate}
          />
        );
      default:
        return (
          <EventCreationProvidingSelector
            eventData={eventData}
            onInputChange={onInputChange}
          />
        );
    }
  };
  
  const menuTabs = [{ name: copy.event.meal_type_providing_button, value: VIEWS.PROVIDING }];
  
  if (!hidePotluck) menuTabs.push({ 
    name: copy.event.meal_type_potluck_button, 
    value: VIEWS.POTLUCK
  });

  if (catered) menuTabs.push({
    name: copy.event.meal_type_catered_button,
    value: VIEWS.CATERED
  });
  
  return (
    <Card className={[className, 'meal_card'].join(' ')}>
      <h2 className="card_title">{copy.event.menu_title}</h2>
      <i className="divider" />

      {(!isUpdate || isDraft) && !isCommunity && (
        <div className="tab_container">
          <ButtonTabs
            tabs={menuTabs}
            activeTab={currentView}
            onSelectTab={handleUpdateView}
          />
        </div>
      )}
      {renderMealView()}
    </Card>
  );
};


const StyledEventCreationFormMeal = styled(EventCreationFormMeal)`
  .tab_container {
    margin: 0 auto 30px;
    text-align: center;

    ${props => props.settings.event.catered ? (
      css`
        max-width: 700px;

        .button {
          width: 33.3333%;
        }

        @media (${breakpoints.tabletSmall}) {
          .button {
            width: 100%;
          }
        }
      `
    ) : (
      css`
        max-width: 420px;
      `
    )};

    @media (${breakpoints.tabletSmall}) {
      .button {
        &.half_width {
          width: 100%;
        }

        border-radius: 0 !important;

        &:first-of-type {
          border-radius: 3px 3px 0 0 !important;
        }
        &:last-of-type {
          border-radius: 0 0 3px 3px !important;
        }
      }
    }
  }

  .catered_container {
    text-align: center;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.text};
  }

  .error_bottom {
    input {
      margin-left: auto;
    }
    .validation_error {
      text-align: right;
    }
  }
`;

const StyledEventCreationFormMealWithSettings = withSettings(StyledEventCreationFormMeal);

export { StyledEventCreationFormMealWithSettings as EventCreationFormMeal };
