import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled, { css } from 'styled-components';

import {
  AREA_BY_URL
} from '../../queries';

import {
  EventSearchUpcomingCount,
  EventSearchHeaderSearch,
  PageContainer,
  Loader,
  withSettings
} from '../components';

import { stringInsert } from '../../libs';

import { SCROLLED_NAV_HEIGHT, NOT_SCROLLED_NAV_HEIGHT } from '../../styles/index';
const NOT_SCROLLED_HEADER_HEIGHT = 190;
const SCROLLED_HEADER_HEIGHT = 150;
const SCROLLED_HEADER_OFFSET = 30;


const EventSearchHeader = ({
  className,
  settings: { copy },
  areaCode,
  selectedArea,
  selectedDates,
  eventCount,
  scrolled
}) => {
  const [area, setArea] = useState(null);

  const {
    data: areaData,
    // loading: areaLoading,
    // error: areaError
  } = useQuery(AREA_BY_URL, { variables: { url: areaCode } });

  useEffect(() => {
    // use area selected on landing page if it exists //
    // or update area if the selected area changes //
    if (
      !area && selectedArea ||
      (area && selectedArea && area.id !== selectedArea.id)
    ) setArea(selectedArea);
    // use queried area //
    else if (!area && areaData && areaData.areaByUrl) setArea(areaData.areaByUrl);
  }, [selectedArea, area, setArea, areaData, areaCode]);


  if (!area) return <Loader />;
  else return (
    <header
      className={['page_header', className, (scrolled ? 'scrolled' : '')].join(' ').trim()}
      style={{ top: `${scrolled ? SCROLLED_NAV_HEIGHT - SCROLLED_HEADER_OFFSET : NOT_SCROLLED_NAV_HEIGHT}px` }}
    >
      <PageContainer>
        <EventSearchUpcomingCount count={eventCount || 0} />
        <PageTitle scrolled={scrolled}>{stringInsert(copy.landing.search_page_title || 'Nearby Dinners in %1$s', area.label)}</PageTitle>
        <EventSearchHeaderSearch area={area} dates={selectedDates} />
      </PageContainer>
    </header>
  );
};

const PageTitle = styled.h1`
  font-size: 34px;
  margin: 1.5rem 0;
  transition: all 200ms;

  @media (max-height: 840px) {
    font-size: 28px;
    margin: 0.7rem 0;
  }

  ${props => props.scrolled && css`
    font-size: 28px;
    margin: 0.7rem 0;
  `}
`;

const StyledEventSearchHeader = styled(EventSearchHeader)`
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 900;
  padding-bottom: 2rem;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  transition: all 200ms;
  height: ${NOT_SCROLLED_HEADER_HEIGHT}px;

  @media (max-height: 840px) {
    padding-bottom: 1rem;
  }

  &.scrolled {
    height: ${SCROLLED_HEADER_HEIGHT}px;
    padding-bottom: 1rem;
  }
`;
const EventSearchHeaderWithSettings = withSettings(StyledEventSearchHeader);
export {
  EventSearchHeaderWithSettings as EventSearchHeader,
  NOT_SCROLLED_HEADER_HEIGHT,
  SCROLLED_HEADER_HEIGHT,
  SCROLLED_HEADER_OFFSET
};
