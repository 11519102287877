import React from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  Input,
  Button,
  ButtonLoader
} from '../components';

import { RESET_PASSWORD } from '../../mutations';
import { authViewStateValues } from '../../libs/auth';

class ForgotPassword extends React.Component {
  state = {
    email: '',
    emailValid: false,
    showConfirmation: false
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  formIsInvalid = () => {
    const { emailValid } = this.state;
    return !emailValid;
  };

  handleFormSubmit = (event, resetPassword) => {
    event.preventDefault();

    resetPassword()
      .then(() => this.setState({ showConfirmation: true }));
  };

  render() {
    const { className, setViewState } = this.props;
    const { showConfirmation, email } = this.state;

    return (
      <div className={className}>
        {showConfirmation
          ? (<p className="text">A password reset link has been sent to your email.</p>)
          : (
            <Mutation mutation={RESET_PASSWORD} variables={{ email }}>
              {(resetPassword, { loading }) => {
                return (
                  <div>
                    <p className="text">{'Enter your email address below and we\'ll send you a link to reset your password.'}</p>

                    <form
                      className="ot_form"
                      onSubmit={event => this.handleFormSubmit(event, resetPassword)}
                    >
                      <Input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        content="email"
                        required
                      />
                      <Button
                        type="submit"
                        disabled={this.formIsInvalid()}
                        fullWidth
                        capitalize
                      >{loading ? <ButtonLoader /> : 'Reset Password'}</Button>
                    </form>
                  </div>
                );
              }}
            </Mutation>
          )}
        <p className="back">Back to <span onClick={() => setViewState(authViewStateValues.LOGIN_WITH_PASSWORD_VIEW)}>Log In</span>.</p>
      </div>
    );
  }
}

const StyledForgotPassword = styled(ForgotPassword)`
  .text {
    margin: 0 0 20px;
    font-size: 13px;
    text-align: center;
  }

  .back {
    color: ${props => props.settings.theme.css.global.colors.textLight};
    font-size: 12px;
    text-align: center;
    margin: 20px 0 0;

    span {
      color: ${props => props.settings.theme.css.global.colors.primary};
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }
`;

const StyledForgotPasswordWithSettings = withSettings(StyledForgotPassword);

export { StyledForgotPasswordWithSettings as ForgotPassword };
