import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  EventCreationQuestionsIntro,
  EventCreationQuestionsCard
} from '../components';

import { indexOfObj } from '../../libs';
import { breakpoints } from '../../styles';

const transitions = {
  slide: 1300,
  card: 450
};

const shouldTrackCreation = true;


class EventCreationQuestions extends React.Component {
  state = {
    xPosition: 0,
    isIntro: true,
    fadeCards: false,
    hideCards: false,
    canSkipToForm: false,
    answers: [],
    transition: transitions.slide,
    transitionDelay: transitions.card
  };

  componentDidMount() {
    const { history, location, tracking, questions } = this.props;
    // Clear search params on component mount //
    history.replace(location.pathname);

    // Start tracking on creation questions load //
    if (shouldTrackCreation) tracking.start('creation', { trackingEventType: 'PRESENTED_RESOURCE_CREATION_QUESTIONS' });

    if (questions && questions.length === 1 && !questions[0].required) this.setState({ canSkipToForm: true });

    window.addEventListener('resize', this.handleUpdatePositionOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleUpdatePositionOnResize);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { search } = location;
    const searchQ = search === '' ? null : parseInt(search.slice(3));

    // Watch for search param change to update slide position //
    if (prevProps.location !== location) this.performSlideTransition(searchQ);
  }

  handleUpdatePositionOnResize = () => {
    const { location: { search } } = this.props;
    const searchQ = search === '' ? null : parseInt(search.slice(3));
    this.setState({ transition: 0, transitionDelay: 50, xPosition: this.getSlidePosition(searchQ) }, () => {
      setTimeout(() => {
        this.setState({ transition: transitions.slide, transitionDelay: transitions.card });
      }, 10);
    });
  };

  goToSlide = (index, shouldTrack, currentStep) => {
    const { history, location, tracking, trackingId } = this.props;
    const { pathname, search } = location;
    // Go to next slide if slide index is not defined //
    const searchQ = index === undefined
      ? (search === '' ? 0 : parseInt(search.slice(3))) + 1
      : index;

    // Update tracking if answering a question //
    if (trackingId && shouldTrack) tracking.update({ id: trackingId, nextActionTaken: `Last Answered Question: ${currentStep}` });

    history.push(`${pathname}?q=${searchQ}`);
  };

  performSlideTransition = q => {
    const { isIntro } = this.state;
    const newState = { xPosition: this.getSlidePosition(q) };

    // Don't fade cards on component mount //
    if (isIntro) newState['isIntro'] = false;
    else newState['fadeCards'] = true;

    this.setState(({ ...newState }), () => {
      if (!isIntro) {
        setTimeout(() => {
          this.setState({ hideCards: true });
        }, transitions.card);

        setTimeout(() => {
          this.setState({ fadeCards: false, hideCards: false });
        }, transitions.slide + transitions.card);
      }
    });
  };

  handleSelectQuestion = (answer, goToFormPage, questions, currentStep) => {
    const { setDefaults, goToForm, tracking, trackingId } = this.props;
    const { answers: answersState } = this.state;
    const answers = answersState.slice();
    const answerIndex = indexOfObj(answers, 'questionId', answer.eventCreationQuestion.id);

    // Set event defaults if defined //
    if (answer.eventDescription) setDefaults('defaultDescription', answer.eventDescription);
    if (answer.eventType) setDefaults('defaultType', answer.eventType);

    // Add or replace question answer //
    if (answerIndex === -1) answers.push(this.formatAnswerData(answer));
    else answers[answerIndex] = this.formatAnswerData(answer);

    this.setState({ answers }, () => {
      if (goToFormPage || answers.length === questions.length) {
        if (trackingId) tracking.update({ id: trackingId, nextActionTaken: 'Completed Questions' });

        goToForm(this.flattenAnswerData(answers));
      } else {
        const requiredQuestions = questions
          .filter(q => q.required === true)
          .map(q => q.id);
        const answersIds = answers.map(ans => ans.questionId);
        const [requiredQuestionId] = requiredQuestions.filter(q => !answersIds.includes(q));
        const questionIndex = requiredQuestionId !== undefined
          ? indexOfObj(questions, 'id', requiredQuestionId) + 1
          : undefined;

        this.goToSlide(questionIndex, true, currentStep);
      }

      setTimeout(() => {
        this.checkRequiredQuestions(questions, answers);
      }, transitions.card + 10);
    });
  };

  handleSkipToForm = () => {
    const { goToForm, tracking, trackingId } = this.props;
    const { answers } = this.state;

    if (trackingId) tracking.update({ id: trackingId, nextActionTaken: 'Completed Questions' });

    goToForm(this.flattenAnswerData(answers))
  };

  getSlidePosition = index => {
    const windowWidth = window.innerWidth;
    // Index is null if no query param (on intro), set in componentDidUpdate //
    if (index === null) return 0;
    return -(index * windowWidth);
  };

  getSliderWidth = questions => {
    if (!questions || !questions.length) return 100;
    return 100 * (questions.length + 1);
  };

  checkRequiredQuestions = (questions, answers) => {
    let needsRequired = false;

    questions.forEach(q => {
      // Question is not answered and is required //
      if (indexOfObj(answers, 'questionId', q.id) < 0 && q.required) needsRequired = true;
    });

    if (!needsRequired) this.setState({ canSkipToForm: true });
  };

  formatAnswerData = answer => {
    // Format for use in EventCreationQuestions component //
    return {
      id: answer.id,
      questionId: answer.eventCreationQuestion.id
    };
  };

  flattenAnswerData = answers => {
    // Flatten array for saving event //
    return Array.isArray(answers) ? answers.map(a => a.id) : [];
  };

  introActionButtonClicked = (questions) => {
    const { goToForm } = this.props;

    if (!questions || questions.length === 0) {
      goToForm(this.flattenAnswerData([]));
    } else {
      this.goToSlide();
    }
  }

  render() {
    const { xPosition, fadeCards, hideCards, answers, canSkipToForm, transition, transitionDelay } = this.state;
    const { className, loading, questions, isCommunity } = this.props;

    return (
      <div className={`
          ${className}
          ${fadeCards ? 'fade_cards' : ''}
          ${hideCards ? 'hide_cards' : ''}
      `}>
        <div
          className="slide__wrapper"
          style={{
            transform: `translateX(${xPosition}px)`,
            width: `calc(${this.getSliderWidth(questions)}vw)`,
            transition: `transform ${transition}ms`,
            transitionDelay: `${transitionDelay - 50}ms`
          }}
        >
          <EventCreationQuestionsIntro loading={loading} goToNextSlide={() => this.introActionButtonClicked(questions)} />

          {(questions && questions.length > 0) && questions.map((question, i) => (
              <EventCreationQuestionsCard
                key={question.id}
                question={question}
                answers={question.answers}
                selectedAnswers={answers}
                currentStep={i + 1}
                questionsCount={questions.length}
                canSkipToForm={canSkipToForm}
                skipToForm={this.handleSkipToForm}
                goToQuestion={index => this.goToSlide(index)}
                selectQuestion={(answer, goToFormPage) => this.handleSelectQuestion(answer, goToFormPage, questions, i + 1)}
                isCommunity={isCommunity}
              />
            ))
          }
        </div>
      </div>
    );
  }
}


const StyledEventCreationQuestions = styled(EventCreationQuestions)`
  position: relative;
  height: 100%;
  min-height: calc(100vh - 85px);
  overflow-x: hidden;

  &.fade_cards {
    .ot_card {
      opacity: 0;
    }
  }

  &.hide_cards {
    .ot_card {
      visibility: hidden;
    }
  }

  .slide__wrapper {
    height: 100%;
    min-height: calc(100vh - 85px);
  }

  .slide {
    background-image: url();
    background-position: center;
    background-size: cover;
    height: 100%;
    min-height: calc(100vh - 85px);
    width: 100vw;
    float: left;
    padding: 130px 30px 150px;

    @media (${breakpoints.tablet}) {
      padding: 30px 20px;
    }
  }

  .ot_card {
    width: 100%;
    max-width: 700px;
    margin: auto;
    border-radius: 5px;
    transition: opacity ${transitions.card}ms;
  }
`;

const StyledEventCreationQuestionsWithSettings = withSettings(StyledEventCreationQuestions);
const EventCreationQuestionsWithSettingsAndRouter = withRouter(StyledEventCreationQuestionsWithSettings);

export { EventCreationQuestionsWithSettingsAndRouter as EventCreationQuestions };
