import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Mutation } from 'react-apollo';

import { MESSAGE_PROFILE } from '../../mutations';

import {
  Modal,
  PageSubheader as Header,
  Avatar,
  CommunityAvatar,
  Button,
  WYSIWYGEditor,
  alert
} from '../components';

import { withSettings, withSession } from '../components';

class MessageHostModal extends React.Component {
  state = { messageToHost: '', messageToHostValid: false };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  isFormInvalid = () => {
    const { messageToHostValid } = this.state;
    return !messageToHostValid;
  };

  handleSendMessage = messageProfile => {
    const { toggle } = this.props;

    messageProfile()
      .then(({ data }) => {
        const { errors } = data.messageProfile;
        if (errors) console.error(errors);
        else alert.success('Your message has been sent.');
        /*
          TODO:
          Error Notification
        */
        this.setState({ messageToHost: '' }, () => {
          toggle();
        });
      });
  };

  getPartnerOrganizationName = event => {
    return (event && event.partnerOrganizationName) || '';
  };

  getHostId = host => {
    return (host && host.id) || null;
  };

  getHostName = host => {
    return (host && host.firstName) || '';
  };

  getMainHost = (event = {}) => {
    const { eventHosts } = event;
    return eventHosts && eventHosts.edges && eventHosts.edges
      .map(({ node }) => node)
      .find(({ creator }) => creator);
  };

  getMainHostId = event => {
    const mainHost = this.getMainHost(event);
    return (mainHost && mainHost.id) || null;
  };

  getPartnerOrganizationAvatar = event => {
    const partnerOrganizationLogo = (event && event.partnerOrganizationLogo && event.partnerOrganizationLogo.mediumUrl) || null;
    return <CommunityAvatar logo={partnerOrganizationLogo}/>;
  };

  getHostAvatar = host => {
    if (!host) {
      return null;
    }

    return <Avatar className="host_avatar" profile={host} size="md" layout="vertical" linked noName/>;
  };

  render() {
    const { messageToHost } = this.state;
    const { className, show, event, host, toggle, messagePartnerOrganization } = this.props;
    const { settings: { copy }, session: { theme } } = this.props;

    const Heading = ({ children }) => theme.isVersion(2)
    ? <Header>{children}</Header>
    : <h2>{children}</h2>;

    const avatar = messagePartnerOrganization ? this.getPartnerOrganizationAvatar(event) : this.getHostAvatar(host);
    const contactName = messagePartnerOrganization ? this.getPartnerOrganizationName(event) : this.getHostName(host);
    const profileReceivingMessageId = messagePartnerOrganization ? this.getMainHostId(event) : this.getHostId(host);

    const variables = {
      id: profileReceivingMessageId,
      message: messageToHost,
      eventId: event.id
    };

    return (
      <Modal
        show={show}
        toggle={toggle}
        size="md"
        noPadding
      >
        <div className={[className, 'message_host_modal'].join(' ')}>
          {avatar}
          <Heading>{`${copy.manage.host_contact} ${contactName}`}</Heading>
          <i className="divider"/>
          <Mutation mutation={MESSAGE_PROFILE} variables={variables}>
            {(messageProfile, { loading }) => (
              <Fragment>
                <WYSIWYGEditor
                  name="messageToHost"
                  value={messageToHost}
                  onInputChange={this.handleInputChange}
                  validation={(text, length) => length > 0}
                  onValidate={this.handleInputValidation}
                  placeholder={copy.manage.description_host_placeholder}
                  dontShowInvalid
                />
                <Button
                  handleClick={() => this.handleSendMessage(messageProfile)}
                  disabled={loading || this.isFormInvalid()}
                  loading={loading}
                  fullWidth={!theme.isVersion(2)}
                  themed={theme.isVersion(2)}
                >
                  {copy.manage.host_message_button}
                </Button>
              </Fragment>
            )}
          </Mutation>
        </div>
      </Modal>
    );
  }
}

const StyledMessageHostModal = styled(MessageHostModal)`
  padding: 40px 30px 30px;

  .host_avatar {
    width: 60px;
    margin: auto;
  }

  .avatar {
    text-align: center;
  }

  h2 {
    ${({ session: { theme } }) => !theme.isVersion(2) && css`
    font-size: 22px;
    `}

    margin: 0 0 20px;
    text-align: center;
  }

  .wysiwyg-editor {
    margin-bottom: 14px;
  }
`;

const StyledMessageHostModalWithSettings = withSettings(StyledMessageHostModal);
const MessageHostModalWithSession = withSession(StyledMessageHostModalWithSettings);
export { MessageHostModalWithSession as MessageHostModal };
