import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import {
  SearchBar,
  Filters
} from '../components';

import { formatters } from '../../libs';

import { Collapse } from 'reactstrap';

class SearchAndFilter extends React.Component {
  state = {
    showFilters: false,
    filters: {},
    search: ''
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { search } = prevState;
    const { location } = nextProps;

    if (location && search !== location.search) {
      // close search filters when url filters change //
      return {
        search: location.search,
        showFilters: false
      };
    }

    return null;
  }

  toggleFilters = () => {
    this.setState(prevState => ({
      showFilters: !prevState.showFilters
    }));
  };

  setFilters = filters => {
    const filtersCopy = Object.assign({}, filters);
    const keys = Object.keys(filtersCopy);

    // Remove `ALL` selection from filter query list //
    keys.forEach(key => {
      const filter = filtersCopy[key];
      if (Array.isArray(filter) && filter[0] === 'ALL') filtersCopy[key] = null;
      else if (filter === 'ALL') filtersCopy[key] = null;
    });

    this.setState({ filters: filtersCopy });
  };

  queryFilters = query => {
    const { filters: filtersState } = this.state;
    const { addFiltersToQuery } = this.props;
    const filters = Object.assign({}, filtersState);

    if (filters.dateTo) filters.dateTo = formatters.toISODate(filters.dateTo, 'date');
    if (filters.dateFrom) filters.dateFrom = formatters.toISODate(filters.dateFrom, 'date');
    filters.query = query;

    addFiltersToQuery(filters);
  };

  updateFilters = (newFilters) => {
    this.setFilters(newFilters);
  }

  resetFilters = (defaultFilters) => {
    this.props.addFiltersToQuery(defaultFilters);
  }

  render() {
    const { showFilters } = this.state;
    const {
      className,
      area,
      selectArea,
      virtualPage = false,
      allEventsPage = false,
      subTypePage = false,
      urlFilters,
      subtypes,
      location
    } = this.props;

    return (
      <div className={className}>
        <SearchBar
          addQuery={query => this.queryFilters(query)}
          toggleFilters={this.toggleFilters}
          filtersOpen={showFilters}
          area={area}
        />
        <Collapse isOpen={showFilters}>
          <Filters
            area={area}
            resetFilters={this.resetFilters}
            addFiltersToParentState={this.updateFilters}
            selectArea={selectArea}
            virtualPage={virtualPage}
            allEventsPage={allEventsPage}
            subTypePage={subTypePage}
            urlFilters={urlFilters}
            subtypes={subtypes}
            location={location}
          />
        </Collapse>
      </div>
    );
  }
}

const StyledSearchAndFilter = styled(SearchAndFilter)`
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
`;

const SearchAndFilterWithRouter = withRouter(StyledSearchAndFilter);
export { SearchAndFilterWithRouter as SearchAndFilter };
