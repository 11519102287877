import React from 'react';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../components';

const DropDownMenu = ({
  className,
  children,
  backdrop,
  toggle,
  onListClick,
  stopPropagation,
  style: userStyle = {} }
) => (
  <span
    className={className}
    style={userStyle}
    onClick={stopPropagation ? e => e.stopPropagation() : null}
  >
    <div className="dropdown_menu">
      <ul onClick={onListClick || null}>
        {children}
      </ul>
    </div>
    {backdrop && (<div className="backdrop" onClick={() => toggle && toggle()}></div>)}
  </span>
);

const StyledDropDownMenu = styled(DropDownMenu)`
  .dropdown_menu {
    position: absolute;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.12);
    width: 100%;
    min-width: 220px;
    z-index: 2;

    ul {
      width: 100%;
      margin: 0;
      padding: 8px 0;
      list-style-type: none;
      overflow-y: auto;
      cursor: default;

      ${({ fullHeight }) => fullHeight ? css`
        max-height: 620px;
        @media (max-height: 800px) {
          max-height: 385px;
        }
      ` : css`
        max-height: 385px;
      `}

      & > li {
        position: relative;
        transition: background-color 250ms;

        &:hover {
          background-color: ${({ colors }) => colors.menuHover};
        }
      }

      & > li {
        & > a,
        & > span,
        & > .log_out_link {
          white-space: nowrap;
          text-decoration: none;
          color: #333;
          display: block;
          margin-right: 15px;
          font-weight: 400;
          cursor: pointer;

          ${({ session: { theme } }) => theme.isVersion(2) ? css`
            font-family: 'Avenir-Medium';
            padding: 8px 20px;
            font-size: 15px;
          ` : css`
            padding: 6px 20px;
            font-size: 14px;
          `}
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: default;
  }

  svg {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  ${props => props.footer && css`
    ul {
      padding-bottom: 43px !important;
    }
  `}
`;

const StyledDropDownMenuWithSettings = withSettings(StyledDropDownMenu);
const DropDownMenuWithSession = withSession(StyledDropDownMenuWithSettings);
export { DropDownMenuWithSession as DropDownMenu };
