import React from 'react';
import styled, { css } from 'styled-components';
import { useSession, useSettings } from '../../hooks';

// temporary styling of profile page, the page should be rewritten //

export const ProfileSettingsVersion = ({ children }) => {
  const { theme } = useSession();
  const { colors } = useSettings();

  switch (theme.version) {
    case 2:
      return (
        <Container colors={colors}>
          {children}
        </Container>
      );
    default:
      return children;
  }
};

const Container = styled.div`
  // .profile_fields {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //
  //   & > .row {
  //     width: 100%;
  //
  //     &:nth-child(odd):not(.span_two) {
  //       padding-left: 7px;
  //     }
  //     &:nth-child(even) {
  //       padding-right: 7px;
  //     }
  //
  //     &.span_two {
  //       grid-column-start: 1;
  //       grid-column-end: 3;
  //       display: grid;
  //       grid-template-columns: 1fr 1fr;
  //
  //       .ot-third-container.label {
  //         grid-column-start: 1;
  //         grid-column-end: 3;
  //       }
  //     }
  //   }
  // }

  // .extra_questions {
  //   & > .row {
  //     width: 100%;
  //
  //     &:nth-child(odd):not(.span_two) {
  //       padding-left: 7px;
  //     }
  //     &:nth-child(even) {
  //       padding-right: 7px;
  //     }
  //   }
  // }

  input,
  textarea,
  .select_box .label,
  .rw-widget-picker {
    padding: 13px 20px 11px !important;
    border: 1px solid #ceccc7 !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    line-height: 1.8 !important;
    transition: border-color 250ms !important;
    box-shadow: none !important;

    &:not([disabled]):hover,
    &:focus {
      border-color: #000 !important;

      &.invalid {
        ${({ colors: { error } }) => css`
          border-color: ${error} !important;
        `}
      }
    }

    ${({ valid, colors: { error } }) => !valid && css`
      /* border-color: ${error} !important;
      margin-bottom: 0 !important; */
    `}
  }

  .rw-widget-picker input {
    border: none !important;
    padding: 0 !important;
  }

  .row {
    margin: 0 !important;

    .ot-third-container {
      padding: 0;
      // width: 300px;
      // width: 100%;

      & > div {
        max-width: 500px;
      }

      &.label {
        padding-top: 7px;

        label {
          font-family: 'Avenir-Medium';
        }

        .info_tooltip {
          margin-left: 10px;
        }
      }

      &:nth-of-type(2) {
        padding-right: 7px;
      }
      &:nth-of-type(3) {
        padding-left: 7px;
      }

      // &.half_width_padding {
      //   // width: calc(50% - 150px);
      //   width: 100%;
      //   &:nth-of-type(2) {
      //     padding-right: 7px;
      //   }
      //   &:nth-of-type(3) {
      //     padding-left: 7px;
      //   }
      // }
    }

    .ot-twothirds-container {
      padding: 0;
      // width: calc(100% - 300px);
      // width: 100%;

      & > div {
        // max-width: 500px;
      }

      &.password_button_container {
        padding-top: 11px;
        height: 53px;
        margin-bottom: 14px;
      }
    }
  }
`;
