import React from 'react';
import { SelectBox } from '../../components';
import { defaultSelectValue } from '../Filters';

const yesOption = { id: 1, label: 'Yes', value: true };
const noOption = { id: 2, label: 'No', value: false };


export const YesNoSelect = ({ name, handleFilterChange, reset, filter, defaultValue }) => {
  const fieldValue = filter !== undefined 
    // filter selected by user //
    ? (filter ? yesOption : noOption)
    : (defaultValue && defaultValue !== undefined 
      // filter loaded by url param //
      ? (defaultValue ? yesOption : noOption)
      : defaultSelectValue);

  return (
    <SelectBox
      defaultValue={fieldValue}
      options={[
        defaultSelectValue,
        yesOption,
        noOption
      ]}
      onOptionSelect={value => handleFilterChange(name, value)}
      refreshOnChange={[reset.area, reset.shouldReset]}
    />
  );
}
