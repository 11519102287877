import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { withSettings, withSession } from '../../components';

import { generators, isSite } from '../../../libs';
import { getImageUrlFromPhotoObject } from '../../../libs/imageHelpers';


const getUserInitials = host => (
  host && host.profile
    ? host.profile.firstName.charAt(0).toUpperCase() + host.profile.lastName.charAt(0).toUpperCase()
    : null
);

const HostAvatar = ({
  className,
  settings,
  session: { viwerAvatarImage },
  event,
  host: profileHost,
  size = 'md',
  position = 'centerbottom'
}) => {
  const [randomBgColor, setRandomBgColor] = useState('');

  useEffect(() => {
    setRandomBgColor(generators.randomColor(settings));
  }, [settings]);

  // const hosts = event.eventHosts.edges.sort((a, b) => { return a.node.creator ? -1 : 1 });
  // const creator = hosts.length && hosts[0];
  const eventHosts = event && event.eventHosts;
  const hosts = eventHosts && eventHosts.edges
    ? eventHosts.edges.map(host => host.node).filter(host => host.profile)
    : [];
  const host = profileHost || (hosts.length > 1
    ? hosts.find(h => isSite(settings, 'climatefest') ? !h.creator : h.creator)
    : hosts[0]);
  const classNames = [className, 'card_avatar'].join(' ');

  // Community Dinner //
  if (event && event.communityDinner && event.partnerOrganizationLogo) {
    const imageUrl = getImageUrlFromPhotoObject(event.partnerOrganizationLogo, 'medium', '');

    return (
      <div
        className={[classNames, size, position].join(' ').trim()}
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
    );

  // Host Avatar //
} else if (host?.profile?.avatar?.photo) {
    const imageUrl = getImageUrlFromPhotoObject(host.profile.avatar.photo, 'medium', '') || viwerAvatarImage;

    return (
      <div
        className={[classNames, size, position].join(' ').trim()}
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
    );

  // Initials //
  } else {
    return (
      <div
        className={[classNames, size, position, 'initials'].join(' ').trim()}
        style={{ background: randomBgColor, borderColor: randomBgColor }}
      >
        <span className="initials">
          {getUserInitials(host)}
        </span>
      </div>
    );
  }
};

const StyledHostAvatar = styled(HostAvatar)`
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  border: 1px solid #fff;

  ${({ session: { theme } }) => !theme.isVersion(2) && css`
    box-shadow: 0 0 15px rgba(2,3,4,0.15);
  `}

  &.centerbottom {
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.lefttop {
    left: 30px;
    top: -50px;
  }

  &.inline {
    position: relative;
  }

  .initials {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.xs {
    width: 55px;
    height: 55px;

    .initials {
      font-size: 17px;
    }
  }

  &.sm {
    width: 73px;
    height: 73px;

    .initials {
      font-size: 18px;
    }
  }

  &.md {
    width: 80px;
    height: 80px;

    .initials {
      font-size: 20px;
    }
  }

  &.lg {
    width: 110px;
    height: 110px;

    .initials {
      font-size: 32px;
      letter-spacing: 0.2rem;
      line-height: 1;
      margin-top: 4px;
      margin-left: 1px;
    }
  }
`;

const HostAvatarWithSettings = withSettings(StyledHostAvatar);
const HostAvatarWithSession = withSession(HostAvatarWithSettings);
export { HostAvatarWithSession as HostAvatar };
