import React from 'react';
import styled from 'styled-components';

import { SelectBox, Input } from '../components';

const ProfileFieldsExtra = ({ className, questions, settings, data: { answers }, handleInputChange: onInputChange }) => {
  const { copy } = settings;
  const howDidYouHearQuestion = questions.find(q => q.name === 'howDidYouHear');
  const howDidYouHearOrganizationQuestion = questions.find(q => q.name === 'howDidYouHearOrganization');

  const getOptions = name => {
    let question;

    switch (name) {
      case 'howDidYouHear':
        question = howDidYouHearQuestion;
        break;
      case 'howDidYouHearOrganization':
        question = howDidYouHearOrganizationQuestion;
        break;
      default:
    }

    if (question) {
      return question.choices.map(choice => ({
        id: choice.id,
        value: choice.id,
        label: choice.label
      }));
    }
    return [];
  };

  const getDefaultValue = (q, name) => {
    const options = q ? q.choices : [];
    const answer = answers && answers[name];
    const choice = answer && options ? options.find(o => o.id === answer.answer) : false;

    return answer && choice
      ? { id: answer.answer, value: answer.answer, label: choice.label }
      : false;
  };

  const handleInputChange = (name, value) => {
    onInputChange(name, value);

    if (name === 'howDidYouHear') {
      if (value !== 'a') onInputChange('howDidYouHearFriend', '');
      if (value !== 'b') onInputChange('howDidYouHearEvent', '');
      if (value !== 'c') onInputChange('howDidYouHearOrganization', null);
    }
  };


  return (
    <div className={[className, 'extra_questions'].join(' ')}>
      <div className="row">
        <div className="ot-third-container label">
          <label>How did you hear about us?</label>
        </div>
        <div className="ot-twothirds-container">
          <SelectBox
            name="howDidYouHear"
            options={getOptions('howDidYouHear')}
            onOptionSelect={value => handleInputChange('howDidYouHear', value)}
            placeholder="---"
            defaultValue={getDefaultValue(howDidYouHearQuestion, 'howDidYouHear')}
          />
        </div>
      </div>
      {answers && answers.howDidYouHear && answers.howDidYouHear.answer === 'a' && (
        <div className="row">
          <div className="ot-third-container">
            <label>{copy.profile.how_did_you_hear_friend_placeholder || 'Which friend?'}</label>
          </div>
          <div className="ot-twothirds-container">
            <Input
              type="text"
              name="howDidYouHearFriend"
              value={answers.howDidYouHearFriend ? answers.howDidYouHearFriend.answer : ''}
              onInputChange={handleInputChange}
            />
          </div>
        </div>
      )}
      {answers && answers.howDidYouHear && answers.howDidYouHear.answer === 'b' && (
        <div className="row">
          <div className="ot-third-container">
            <label>{copy.profile.how_did_you_hear_event_placeholder || 'Which event?'}</label>
          </div>
          <div className="ot-twothirds-container">
            <Input
              type="text"
              name="howDidYouHearEvent"
              value={answers.howDidYouHearEvent ? answers.howDidYouHearEvent.answer : ''}
              onInputChange={handleInputChange}
            />
          </div>
        </div>
      )}
      {answers && answers.howDidYouHear && answers.howDidYouHear.answer === 'c' && (
        <div className="row">
          <div className="ot-third-container">
            <label>{copy.profile.how_did_you_hear_organization_placeholder || 'Which Organization?'}</label>
          </div>
          <div className="ot-twothirds-container">
            <SelectBox
              name="howDidYouHearOrganization"
              options={getOptions('howDidYouHearOrganization')}
              onOptionSelect={value => handleInputChange('howDidYouHearOrganization', value)}
              placeholder="---"
              defaultValue={getDefaultValue(howDidYouHearOrganizationQuestion, 'howDidYouHearOrganization')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const StyledProfileFieldsExtra = styled(ProfileFieldsExtra)`
  .select_box {
    background-color: #fff;
  }
`;
export { StyledProfileFieldsExtra as ProfileFieldsExtra };
