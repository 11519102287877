import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { PageContent, RsvpPageHeader, RsvpPageBody, Loader } from '../components';
import { RsvpPageContextProvider } from './RsvpPageContext';
import { RsvpPageCheckoutContextProvider } from './RsvpPageCheckoutContext';

import { EVENT_BY_UUID_FOR_RSVP } from '../../queries';
import ROUTES from '../../constants/routes';


const RsvpPage = () => (
  <PageContent>
    <RsvpPageHeader />
    <RsvpPageBody />
  </PageContent>
);

const RsvpPageWithContext = props => {
  const { state } = useLocation();
  const { uuid } = useParams();
  const history = useHistory();

  const { data, loading } = useQuery(EVENT_BY_UUID_FOR_RSVP, {
    skip: state && state.event,
    errorPolicy: 'all',
    variables: { uuid }
  });

  if (!state && loading) return <Loader />;

  const eventData = state ? state.event : data.event;
  if (!eventData.canSubscribe) history.push(`/${ROUTES.EVENTS}/${uuid}`);

  return (
    <div>
      <RsvpPageContextProvider eventData={eventData}>
        <RsvpPageCheckoutContextProvider eventData={eventData}>
          <RsvpPage {...props} />
        </RsvpPageCheckoutContextProvider>
      </RsvpPageContextProvider>
    </div>
  );
}

export { RsvpPageWithContext as RsvpPage };
