import React from 'react';
import styled from 'styled-components';

import {
  DatePicker,
  SelectBox,
  AreaSelector,
  withSettings,
  CustomFilters,
} from '../components';

import { SessionContext } from '../../Context';

import { breakpoints } from '../../styles';
import { parseTypesSettings, getAreaNeighborhoods, isSite, sort } from '../../libs';


export const defaultSelectValue = { id: 0, label: 'All', value: 'ALL' };

// const defaultState = {
//   dateFrom: null,
//   dateTo: null,
//   eventType: null,
//   neighborhood: null,
//   subneighborhood: null,
//   alcoholPolicy: null,
//   dietaryRestrictions: null,
//   dressCode: null,
//   cost: null,
//   subneighborhoodOptions: null,
//   shouldReset: null,
//   eventSubtypeId: null
// };


class Filters extends React.Component {
  state = {
    showEventSubtype: false
  };

  componentDidMount() {
    const { allEventsPage, settings } = this.props;
    if (allEventsPage) this.setState({ areaCode: null });

    // const filterParams = getUrlParamFilters(location.search, settings.custom_filters);

    // temp until the setting can be pushed //
    const showEventSubtype = isSite(settings, ['hillel', 'climatefest', 'thegathering']);
    this.setState({ showEventSubtype });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { search } = prevState;
    const { location } = nextProps;

    // hide event subtype filter if the filter exists in the url //
    if (location && search !== location.search && !location.search) {
      return { showEventSubtype: true };
    }

    return { showEventSubtype: false };
  }

  componentDidUpdate(prevProps) {
    const { area } = this.props;

    if (prevProps.area && prevProps.area.code !== area.code) {
      this.handleClearFilters();
    }
  }

  hasFiltersSelected = () => {
    const filters = this.state;
    let selected = false;

    for (const i in filters) {
      const filter = Array.isArray(filters[i]) ? filters[i][0] : filters[i];
      if (i === 'shouldReset' || i === 'subneighborhoodOptions') continue;
      if (filter !== 'ALL' && filter !== null) selected = true;
    }

    return selected;
  };

  handleClearFilters = () => {
    this.setState({}, () => {
      this.props.resetFilters(this.state);
      this.setState({ shouldReset: new Date().getTime() });
    });
  };

  handleFilterChange = (key, value, sortedAreas = []) => {
    const { addFiltersToParentState, selectArea, allEventsPage } = this.props;

    this.setState({ [key]: value }, () => {
      addFiltersToParentState(this.state);

      if (allEventsPage && key === 'areaCode') {
        selectArea(sortedAreas.find(a => a.code === value));
      }
    });
  };

  handleUpdateFilterSettings = () => {
    // this.setState({ [setting]: value });
  };

  setDateMin = () => {
    const { dateFrom } = this.state;
    if (dateFrom) return dateFrom;
    return null;
  };

  setDateMax = () => {
    const { dateTo } = this.state;
    if (dateTo) return dateTo;
    return null;
  };

  formatFilterData = options => {
    if (!options) return [];
    const keys = Object.keys(options);
    const data = keys.map((key, i) => ({
      id: i + 1,
      label: options[key],
      value: key
    }));

    return new Array(defaultSelectValue).concat(data);
  };

  formatDressCodesFilterData = options => {
    if (!options) return [];
    const data = options.map((key, i) => ({
      id: i + 1,
      label: key,
      value: key
    }));

    return new Array(defaultSelectValue).concat(data);
  };

  formatAreaFilterData = () => {
    const { area } = this.props;
    const neighborhoods = getAreaNeighborhoods(area);
    const data = neighborhoods.map((neighborhood, i) => ({
      id: i + 1,
      label: neighborhood.label,
      value: neighborhood.code
    }));

    if (data) return new Array(defaultSelectValue).concat(data);
    return null;
  };

  handleNeighborhoodSelect = neighborhood => {
    const { area } = this.props;

    const neighborhoods = getAreaNeighborhoods(area);
    const selectedNeighborhood = neighborhoods.filter(n => n.code === neighborhood);
    let subneighborhoodSelection = null;

    if (
      selectedNeighborhood &&
      selectedNeighborhood.length &&
      Array.isArray(selectedNeighborhood[0].subneighborhoods) &&
      selectedNeighborhood[0].subneighborhoods.length
    ) {
      const data = selectedNeighborhood[0].subneighborhoods.map((sn, i) => ({
        id: i + 1,
        label: sn.label,
        value: sn.code
      }));

      subneighborhoodSelection = new Array(defaultSelectValue).concat(data);
    }

    this.setState({
      subneighborhoodOptions: subneighborhoodSelection,
      subneighborhood: null
    });
  };

  getEventSubtypes = eventSubtypes => {
    return [
      defaultSelectValue,
      ...eventSubtypes.filter(type => !type.disabled).map(type => ({
        id: type.id,
        label: type.name,
        value: type.id
      }))
    ];
  };

  getEventDefaultSubtypesValue = () => {
    const { subtypes, urlFilters } = this.props;
    const { eventSubtypeId } = this.state;
    const currentSubtype = eventSubtypeId && subtypes && subtypes.find(st => st.id === eventSubtypeId);
    const urlSubtype = urlFilters && 'event_subtype' in urlFilters && subtypes
      ? subtypes.find(st => st.slug === urlFilters.event_subtype)
      : null;

    if (eventSubtypeId === 'ALL') return defaultSelectValue;
    if (currentSubtype) return { id: 0, label: currentSubtype.name, value: currentSubtype.id };
    if (urlSubtype) return { id: 0, label: urlSubtype.name, value: urlSubtype.id };

    return defaultSelectValue;
  };

  getSubneighborhoodOptions = () => {
    return this.state.subneighborhoodOptions;
  };

  render() {
    const { dateFrom, dateTo, shouldReset, showEventSubtype } = this.state;
    const {
      className,
      area,
      settings: { types, copy, features, custom_filters },
      virtualPage,
      allEventsPage,
      subTypePage,
      urlFilters,
      subtypes
    } = this.props;

    const paidEventsEnabled = features.paid_events === true || features.paid_events === 'true';
    const parsedTypes = parseTypesSettings(types);

    const DateFields = () => (custom_filters.date_from || custom_filters.date_to) ? (
      <div className={`form_group date_container ${custom_filters.date_from && custom_filters.date_to ? '' : 'single'}`}>
        <label className="form_label">{copy.filter.date_range}</label>

        {custom_filters.date_from && (
          <DatePicker
            className="date_from"
            value={dateFrom}
            max={this.setDateMax()}
            onDateChange={value => this.handleFilterChange('dateFrom', value)}
          />
        )}

        {custom_filters.date_from && custom_filters.date_to && (
          <span>{copy.filter.to}</span>
        )}

        {custom_filters.date_to && (
          <DatePicker
            className="date_to"
            value={dateTo}
            min={this.setDateMin()}
            onDateChange={value => this.handleFilterChange('dateTo', value)}
          />
        )}
      </div>
    ) : null;

    const EventTypeField = () => custom_filters.event_type ? (
      <div className="form_group">
        <label className="form_label">{copy.filter.event_type}</label>
        <SelectBox
          defaultValue={defaultSelectValue}
          options={this.formatFilterData(types.event)}
          onOptionSelect={value => this.handleFilterChange('eventType', value)}
          refreshOnChange={[area, shouldReset]}
          returnValue="array"
        />
      </div>
    ) : null;

    const EventSubTypeField = () => custom_filters.event_subtype ? (
      <div className="form_group">
        <label className="form_label">{copy.filter.event_subtype || 'Sub Type'}</label>
        <SelectBox
          name="eventSubtype"
          defaultValue={this.getEventDefaultSubtypesValue()}
          options={!subtypes ? null : this.getEventSubtypes(subtypes)}
          onOptionSelect={value => this.handleFilterChange('eventSubtypeId', value)}
          refreshOnChange={[shouldReset]}
        />
      </div>
    ) : null;

    const AreaField = () => {
      const { session: { areas } } = this.props;
      const sortedAreas = sort.byObjKey(areas, 'position');

      return custom_filters.area_code ? (
        <div className="form_group">
          <label className="form_label">{copy.filter.areas || 'Areas'}</label>
          <AreaSelector
            name="areaCode"
            areas={sortedAreas}
            onInputChange={(name, value) => this.handleFilterChange(name, value, sortedAreas)}
            returnKey="code"
            addAllDefault
            defaultValue={defaultSelectValue}
            refreshOnChange={[shouldReset]}
          />
        </div>
      ) : null;
    };

    const NeighborhoodField = () => custom_filters.neighborhood ? (
      <div className="form_group">
        <label className="form_label">{copy.filter.neighborhood}</label>
        <SelectBox
          defaultValue={getAreaNeighborhoods(area).length > 0 ? defaultSelectValue : null}
          options={this.formatAreaFilterData()}
          onOptionSelect={value => this.handleFilterChange('neighborhood', value)}
          onSelectionChange={this.handleNeighborhoodSelect}
          refreshOnChange={[area, shouldReset]}
        />
      </div>
    ) : null;

    const SubNeighborhoodField = () => custom_filters.subneighborhood ? (
      <div className="form_group">
        <label className="form_label">{copy.filter.sub_neighborhood}</label>
        <SelectBox
          defaultValue={this.getSubneighborhoodOptions() ? defaultSelectValue : null}
          options={this.getSubneighborhoodOptions()}
          onOptionSelect={value => this.handleFilterChange('subneighborhood', value)}
          refreshOnChange={[area, shouldReset]}
        />
      </div>
    ) : null;

    const AlcoholTypeField = () => custom_filters.alcohol_policy ? (
      <div className="form_group">
        <label className="form_label">{copy.create.alcohol_type}</label>
        <SelectBox
          defaultValue={defaultSelectValue}
          options={this.formatFilterData(types.alcohol_policy)}
          onOptionSelect={value => this.handleFilterChange('alcoholPolicy', value)}
          refreshOnChange={[area, shouldReset]}
          returnValue="array"
        />
      </div>
    ) : null;

    const DietTypeField = () => custom_filters.dietary_restrictions ? (
      <div className="form_group">
        <label className="form_label">{copy.create.diet_type}</label>
        <SelectBox
          defaultValue={defaultSelectValue}
          options={this.formatFilterData(types.dietary_restrictions)}
          onOptionSelect={value => this.handleFilterChange('dietaryRestrictions', value)}
          refreshOnChange={[area, shouldReset]}
          returnValue="array"
          multi
        />
      </div>
    ) : null;

    const DressCodeField = () => custom_filters.dress_code ? (
      <div className="form_group">
        <label className="form_label">{copy.create.dress_code}</label>
        <SelectBox
          defaultValue={defaultSelectValue}
          options={this.formatDressCodesFilterData(parsedTypes.dress_codes)}
          onOptionSelect={value => this.handleFilterChange('dressCode', value)}
          refreshOnChange={[area, shouldReset]}
        />
      </div>
    ) : null;

    const CostTypeField = () => custom_filters.cost ? (
      <div className="form_group">
        <label className="form_label">{copy.filter.cost_type}</label>
        <SelectBox
          defaultValue={defaultSelectValue}
          options={this.formatFilterData(types.cost)}
          onOptionSelect={value => this.handleFilterChange('cost', value)}
          refreshOnChange={[area, shouldReset]}
        />
      </div>
    ) : null;

    return (
      <div className={`${className} ot_form`}>
        <div className="filters_header">
          <h2>{copy.filter.title}</h2>
          <p>{copy.filter.subtitle}</p>

          {this.hasFiltersSelected() && (
            <span
              className="clear_filters"
              onClick={this.handleClearFilters}
            >{copy.filter.clear}</span>
          )}
        </div>

        {!isSite(this.props.settings, 'jewishfoodfest') ? (
          <div className="form_group half_width">
            {/* select box label doesnt update correctly when rendered as a component */}
            {DateFields()}

            {showEventSubtype && !subTypePage && (custom_filters.event_type || custom_filters.event_subtype) ? (
              <div className={`form_group${custom_filters.event_type && custom_filters.event_subtype ? ' half_width' : ''}`}>
                {EventTypeField()}
                {EventSubTypeField()}
              </div>
            ) : (
              EventTypeField()
            )}

            {!virtualPage && (
              <>
                {allEventsPage ? (
                  <>
                    {AreaField()}

                    {!isSite(this.props.settings, 'hillel') && (custom_filters.neighborhood || custom_filters.subneighborhood) && (
                      <div
                        className={`form_group ${
                            custom_filters.neighborhood && custom_filters.subneighborhood && !isSite(this.props.settings, 'thegathering')
                              ? 'half_width'
                              : ''
                        }`}
                      >
                        {NeighborhoodField()}
                        {!isSite(this.props.settings, 'thegathering') && SubNeighborhoodField()}
                      </div>
                    )}
                  </>
                ) : (
                  !isSite(this.props.settings, 'hillel') ? (
                    <>
                      {NeighborhoodField()}
                      {!isSite(this.props.settings, 'thegathering') && SubNeighborhoodField()}
                    </>
                  ) : null
                )}

                {!isSite(this.props.settings, 'hillel') && AlcoholTypeField()}
                {DietTypeField()}

                {DressCodeField()}
                {paidEventsEnabled && CostTypeField()}
              </>
            )}

            <CustomFilters
              handleFilterChange={this.handleFilterChange}
              reset={{ area, shouldReset }}
              filters={this.state}
              updateFilterSettings={this.handleUpdateFilterSettings}
              urlFilters={urlFilters}
            />
          </div>
        ) : (
          <>
            <div className="form_group half_width">
              {DateFields()}
              {EventTypeField()}
              {paidEventsEnabled && CostTypeField()}
              <CustomFilters
                handleFilterChange={this.handleFilterChange}
                reset={{ area, shouldReset }}
                filters={this.state}
                updateFilterSettings={this.handleUpdateFilterSettings}
                urlFilters={urlFilters}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const StyledFilters = styled(Filters)`
  max-width: 900px;
  width: 100%;
  padding: 30px 20px 20px;
  margin: auto;

  .form_group {
    position: relative;

    .ot_tooltip {
        margin-bottom: 5px;
    }
  }

  .filters_header {
    position: relative;
    text-align: center;

    h2 {
      font-size: 18px;
      color: ${props => props.settings.theme.css.global.colors.header};
      margin: 0 0 15px;
    }
    p {
      font-size: 14px;
      color: ${props => props.settings.theme.css.global.colors.textLight};
      margin: 0 0 25px;
    }
  }

  .clear_filters {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 13px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    transition: color 250ms;
    cursor: pointer;

    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }

    @media (${breakpoints.tabletSmall}) {
      top: auto;
      bottom: -40px;
    }
  }

  .date_container {
    & > div {
      display: inline-block;
      vertical-align: middle;
      width: calc(50% - 30px);
    }
    & > span {
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      margin-bottom: 14px;
      text-align: center;
      font-size: 14px;
    }

    &.single {
      & > div {
        width: 100%;
      }
    }
  }

  &.ot_form {
    & > .form_group {
      &.half_width {
        & > div {
          width: calc(50% - 15px);

          @media (${breakpoints.tabletSmall}) {
            width: 100%;
          }

          &:nth-of-type(odd) {
            margin-right: 30px;

            @media (${breakpoints.tabletSmall}) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .form_group {
      label {
        &.form_label {
          display: block;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 5px;
          padding: 0;
          color: ${props => props.settings.theme.css.global.colors.header};
        }
      }
    }

    .dropdown_menu {
      li {
        span {
          padding: 3px 20px;
          font-size: 14px;
        }
      }
    }
  }

  @media (${breakpoints.tabletSmall}) {
    .date_to {
      .rw-popup-container {
        left: auto;
      }
    }
  }
`;

const FiltersWithSettings = withSettings(StyledFilters);

const FiltersWithAreas = props => (
  <SessionContext.Consumer>
    {session => <FiltersWithSettings {...props} session={session} />}
  </SessionContext.Consumer>
);

export { FiltersWithAreas as Filters };
