import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { usePaginatedEvents } from '../../hooks';
import { getScrollPos } from '../../libs';

import { SessionContext } from '../../Context';
import { FilterContextProvider } from './FilterContext';

import {
  EventSearchHeader,
  EventSearchList,
  EventSearchMap,
  Loader,
  withClient,
  withSettings
} from '../components';

import ROUTES from '../../constants/routes';

// import { SCROLLED_NAV_HEIGHT, NOT_SCROLLED_NAV_HEIGHT } from '../../styles/index';


const EventSearchPage = ({ client, settings }) => {
  // element references //
  const [listRef, setListEl] = useState(null);
  const [mapRef, setMapEl] = useState(null);
  const [scrolled, setScrolled] = useState(0);

  const location = useLocation();
  const params = useParams();

  const { theme: { display_search_map } } = settings;

  const { areas: sessionAreas, theme } = useContext(SessionContext);
  const { area: areaCode } = params;

  // selected area and dates passed from landing page or reloading search area //
  const { state: locationState = {} } = location;
  const { selectedArea, selectedDates } = locationState;

  const paginatedEvents = usePaginatedEvents(client, { variables: { areaCode } });

  // set page scrolled state //
  const handleSetScrolled = () => {
    const boundary = 100;

    if (!scrolled && getScrollPos().y > boundary) {
      setScrolled(true);
      // document.querySelector('.main_nav').style.height = `${SCROLLED_NAV_HEIGHT}px`;
    } else if (scrolled && getScrollPos().y <= boundary) {
      setScrolled(false);
      // document.querySelector('.main_nav').style.height = `${NOT_SCROLLED_NAV_HEIGHT}px`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSetScrolled);
    return () => {
      window.removeEventListener('scroll', handleSetScrolled);
    }
  }, [scrolled]);

  // redirect back to root if current url area doesnt exist //
  if (sessionAreas && !sessionAreas.find(a => a.url === areaCode))
    return <Redirect to={`/${ROUTES.ROOT}`} />;
  // redirect to correct landing page if site isnt using the search theme ui //
  if (theme.isVersion(2) === false)
    return <Redirect to={`/${ROUTES.LANDING}/${areaCode}`} />;

  if (paginatedEvents.loading) return <Loader />;
  else return (
    <FilterContextProvider>
      <Container>
        <EventSearchHeader
          areaCode={areaCode}
          selectedArea={selectedArea}
          selectedDates={selectedDates}
          eventCount={paginatedEvents.pagination.totalEventsCount}
          scrolled={scrolled}
        />
        <EventSearchList
          paginatedEvents={paginatedEvents}
          setListEl={setListEl}
          fullWidth={!display_search_map}
        />
        {display_search_map && (
          <EventSearchMap
            areaCode={areaCode}
            scrolled={scrolled}
            setMapEl={setMapEl}
            refs={{ listRef, mapRef }}
          />
        )}
      </Container>
    </FilterContextProvider>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 100vh;
`;

const EventSearchPageWithClient = withClient(EventSearchPage);
const EventSearchPageWithSettings = withSettings(EventSearchPageWithClient);
export { EventSearchPageWithSettings as EventSearchPage };
