import gql from 'graphql-tag';

export const reservationsFragment = gql`
  fragment Reservations on EventReservationConnection {
    edges {
      node {
        id
        participated
        profile {
          id
          firstName
          lastName
          facebookId
          showOnGuestList
          avatar {
            id
            photo {
              smallUrl
              mediumUrl
              largeUrl
              originalUrl
            }
          }
          user @include(if: $isHost) {
            id
            email
          }
        }
        additionalGuests {
          email
          name
          firstName
          lastName
          participated
        }
        state
        answers {
          edges {
            node {
              id
              response
              reservationQuestion {
                id
                text
              }
            }
          }
        }
        potluckItems {
          totalCount
          edges {
            node {
              id
              name
              quantity
              claimedQuantity
              freeQuantity
              position
            }
          }
        }
        tables {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      cursor
    }
    guestsAccepted
    guestsPending
    guestsRejected
    guestsTotal
    reservationsAccepted
    reservationsPending
    reservationsRejected
    reservationsTotal
  }
`;

export const invitationsFragment = gql`
  fragment Invitations on EventInvitationConnection {
    edges {
      node {
        id
        email
        invitee {
          id
          firstName
          lastName
        }
        status
      }
      cursor
    }
    invitationsAccepted
    invitationsPending
    invitationsDeclined
  }
`;

export const singleEventFragment = gql`
  fragment SingleEvent on Event {
    id
    alcoholPolicy
    dressCodeName
    dietaryRestrictions
    areaOther
    stateLocation
    area {
      id
      code
      url
      label
      timezone
      nourishmentLockDaysBeforeEvent
      newHostNourishmentDelayDays
      maxPaidAdditionalGuests
      maxUnpaidAdditionalGuests
      neighborhoods {
        code
        label
        subneighborhoods {
          code
          label
        }
      }
      campuses {
        id
        label
        subneighborhoods {
          code
          label
        }
      }
    }
    cover {
      originalUrl
      smallUrl
      mediumUrl
      largeUrl
    }
    canSubscribe
    communityDinner
    # deadlineAt
    effectiveRsvpDeadlineAt
    rsvpDeadlineAt
    duration
    eventType
    eventSubtype {
      id
      name
    }
    eventSubtypeOther
    privacySubtype {
      id
      name
      eventType
      position
      disabled
      virtual
      outdoors
      vaccinated
    }
    hideGuestList
    expectedGuests
    extraDetails
    fullAddress
    eventHosts {
      edges {
        node {
          creator
          effectiveEmail
          id
          profile {
            id
            firstName
            lastName
            bio
            avatar {
              id
              photo {
                smallUrl
                mediumUrl
                largeUrl
                originalUrl
              }
            }
          }
          reservation {
            id
            state
          }
        }
      }
    }
    neighborhood
    campus {
      id
      label
      subneighborhoods {
        code
        label
      }
    }
    subneighborhood
    numberOfGuestsMax
    notes
    nourishable
    nourishmentRestrictionExceptionExpiresAt
    nourishmentUnavailableExplanation
    partnerOrganizationLogo {
      originalUrl
      smallUrl
      mediumUrl
      largeUrl
    }
    partnerOrganizationName
    partnerOrganizationDescription
    catered
    potluck
    pricePerPerson
    pwyw
    pwywMinimum
    potluckItems {
      totalCount
      edges {
        node {
          id
          name
          quantity
          claimedQuantity
          freeQuantity
          position
          claims {
            id
            quantity
            reservation {
              id
              profile {
                id
                firstName
                lastName
                avatar {
                  id
                  photo {
                    smallUrl
                    mediumUrl
                    largeUrl
                    originalUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    reservationQuestions {
      edges {
        node {
          id
          answerType
          baseQuestion {
            id
            presenceType
          }
          disabled
          position
          text
        }
      }
    }
    scheduledAt
    shortUrl
    sponsorshipDeadline
    secondaryAddress
    sponsorships {
      id
      amount
      sponsor {
        id
        name
      }
      event {
        id
      }
    }
    state
    subneighborhood
    tags
    title
    timezone
    virtual
    virtualLocation
    virtualResourceUrl
    uuid
    closedCaptioningAvailable
    videosOnAsDefault
    bathroomAccessible
    recurrences {
      id
      scheduledAt
      state
    }
  }
`;

export const singleEventByUuid = gql`
  fragment SingleEventUuid on Event {
    id
    alcoholPolicy
    dressCodeName
    dietaryRestrictions
    area {
      id
      code
      url
      label
      timezone
      membership {
        id
        content
        header
        name
        products {
          id
          active
          description
          hint
          name
          prices {
            id
            active
            stripeId
            unitAmount
          }
        }
      }
      nourishmentLockDaysBeforeEvent
      newHostNourishmentDelayDays
      maxPaidAdditionalGuests
      maxUnpaidAdditionalGuests
      neighborhoods {
        code
        label
        subneighborhoods {
          code
          label
        }
      }
      campuses {
        id
        label
        subneighborhoods {
          code
          label
        }
      }
    }
    cover {
      originalUrl
      smallUrl
      mediumUrl
      largeUrl
    }
    canSubscribe
    communityDinner
    duration
    eventType
    eventSubtype {
      id
      name
    }
    eventSubtypeOther
    privacySubtype {
      id
      name
      eventType
      position
      disabled
      virtual
      outdoors
      vaccinated
    }
    expectedGuests
    hideGuestList
    fullAddress
    neighborhood
    campus {
      id
      label
      subneighborhoods {
        code
        label
      }
    }
    subneighborhood
    numberOfGuestsMax
    notes
    partnerOrganizationLogo {
      originalUrl
      smallUrl
      mediumUrl
      largeUrl
    }
    partnerOrganizationName
    partnerOrganizationDescription
    catered
    potluck
    pricePerPerson
    pwyw
    pwywMinimum
    potluckItems {
      totalCount
      edges {
        node {
          id
          name
          quantity
          claimedQuantity
          freeQuantity
          position
          claims {
            id
            quantity
            reservation {
              id
              profile {
                id
                firstName
                lastName
                avatar {
                  id
                  photo {
                    smallUrl
                    mediumUrl
                    largeUrl
                    originalUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    reservationQuestions {
      edges {
        node {
          id
          answerType
          baseQuestion {
            id
            presenceType
          }
          disabled
          position
          text
        }
      }
    }
    scheduledAt
    sponsorshipDeadline
    secondaryAddress
    sponsorships {
      id
      amount
      sponsor {
        id
        name
      }
      event {
        id
      }
    }
    state
    subneighborhood
    tags
    title
    timezone
    virtual
    virtualLocation @include(if: $isRegistered)
    virtualLocation @include(if: $isHost)
    virtualResourceUrl
    uuid
    accessible
    areaOther
    stateLocation
    # deadlineAt
    effectiveRsvpDeadlineAt
    rsvpDeadlineAt
    description
    donationsEnabled
    donationsDisabled
    eventAmount
    extraDetails
    hostAbsorbsTransactionFee
    numberOfGuestsMin
    parkingLots
    petsAllowed
    shortUrl
    eventHosts {
      edges {
        node {
          creator
          effectiveEmail
          id
          profile {
            id
            firstName
            lastName
            bio
            hostedEvents @include(if: $isLogged) {
              totalCount
            }
            avatar {
              id
              photo {
                originalUrl
                smallUrl
                mediumUrl
                largeUrl
              }
            }
            user @include(if: $isHost) {
              id
              email
            }
          }
        }
      }
    }
    tables {
      id
      event {
        id
      }
      maxSeats
      name
      reservations {
        nodes {
          id
          additionalGuests {
            email
            name
            firstName
            lastName
            participated
          }
          state
          profile {
            id
            firstName
            lastName
            avatar {
              id
              photo {
                smallUrl
                mediumUrl
                largeUrl
                originalUrl
              }
            }
          }
        }
      }
    }
    timezone
    timezoneOther
    closedCaptioningAvailable
    videosOnAsDefault
    bathroomAccessible
    recurrences {
      id
      scheduledAt
      state
    }
  }
`;

export const journeyResponseFragment = gql`
  fragment JourneyResponse on Event {
    journeyResponses {
      id
      answers {
        nodes {
          id
          question {
            id
            answerType
          }
          response {
            __typename
            ... on StringBoxed {
              string
            }
          }
        }
      }
      resources {
        edges {
          node {
            id
            description
            image {
              mediumUrl
              originalUrl
            }
            horizontalMobileImagePosition
            verticalMobileImagePosition
            title
            url
          }
        }
      }
    }
  }
`;
