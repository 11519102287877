import React, { useContext } from 'react';

import { UserMenu, UserAuthLinks } from '../components';
import { SessionContext } from '../../Context';
import { SettingsContext } from '../../SettingsContext';
import { RestrictionModalContext } from '../../RestrictionModalContext';

const UserSection = ({ overHero }) => {
  const { session } = useContext(SessionContext);
  const { settings } = useContext(SettingsContext);
  const restriction = useContext(RestrictionModalContext);

  if (session.viewer && session.viewer.user) {
    return (
      <UserMenu
        session={session}
        restriction={restriction}
        settings={settings}
        overHero={overHero}
      />
    );
  }
  return <UserAuthLinks session={session} overHero={overHero} />;
};

export default UserSection;
