import React from 'react';
import styled from 'styled-components';
import { AddCreditCardData } from './AddCreditCardData';
import { CreditCardsList } from './CreditCardsList';
import { alert } from '../Global';
import { Button, withSettings } from '../components';

const views = {
  addCreditCard: 'add-credit-card',
  creditCardsList: 'credit-cards-list'
}

class ProfilePaymentMethods extends React.Component {
  state = {
    selectedCard: null,
    currentView: views.creditCardsList
  }

  changeCardClicked = (card) => {
    this.setState({
      selectedCard: card,
      currentView: views.addCreditCard
    });
  }

  addCreditCardClicked = () => {
    this.setState({
      selectedCard: null,
      currentView: views.addCreditCard
    });
  }

  cancelAddCreditCardClicked = () => {
    this.setState({
      selectedCard: null,
      currentView: views.creditCardsList
    });
  }

  onCreateCardError = () => {
    alert.error('Error adding payment method');
  }

  onCreateCardSuccess = () => {
    this.props.onCreateCardSuccess();

    alert.success('Payment method added');

    this.setState({
      selectedCard: null,
      currentView: views.creditCardsList
    });
  }

  onDeleteCardError = () => {
    alert.error('Error deleting payment method');
  }

  onDeleteCardSuccess = () => {
    this.props.onDeleteCardSuccess();

    alert.success('Payment method deleted');

    this.setState({
      selectedCard: null,
      currentView: views.creditCardsList
    });
  }

  onUpdateCardError = () => {
    alert.error('Error modifying payment method');
  }

  onUpdateCardSuccess = () => {
    this.props.onUpdateCardSuccess();

    alert.success('Payment method modified');

    this.setState({
      selectedCard: null,
      currentView: views.creditCardsList
    });
  }

  render() {
    const { className, cards } = this.props;
    const { currentView, selectedCard } = this.state;
    const { settings: { copy } } = this.props;

    return (
      <div className={className}>
        <div className="row">
          <div className="ot-full-container">
            <h3>{copy.profile.card_pay_title}</h3>
          </div>
        </div>
        {currentView === views.addCreditCard ? (
          <AddCreditCardData
            selectedCard={selectedCard}
            cancelButtonHandler={this.cancelAddCreditCardClicked}
            onCreateCardError={this.onCreateCardError}
            onCreateCardSuccess={this.onCreateCardSuccess}
            onDeleteCardError={this.onDeleteCardError}
            onDeleteCardSuccess={this.onDeleteCardSuccess}
            onUpdateCardError={this.onUpdateCardError}
            onUpdateCardSuccess={this.onUpdateCardSuccess}
          />
          ) : (
            <CreditCardsList cards={cards}
            changeCardClicked={this.changeCardClicked}/>
          )
        }
        {currentView === views.addCreditCard
          ? null
          : <Button className="add-card-button" handleClick={this.addCreditCardClicked}>Add Credit Card</Button>
        }
      </div>
    );
  }
}

const StyledProfilePaymentMethods = styled(ProfilePaymentMethods)`
  margin-bottom: 60px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & h3 {
    padding-top: 20px;
    font-size: 20px;
    text-align: left;
  }

  & .add-card-button {
    float: right;
  }
`;

const StyledProfilePaymentMethodsWithSettings = withSettings(StyledProfilePaymentMethods);

export { StyledProfilePaymentMethodsWithSettings as ProfilePaymentMethods };
