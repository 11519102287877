import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Button } from '../Global';
import { Mutation } from 'react-apollo';
import { withSettings, withSession } from '../components';

import { EVENT_FILTER, EVENT_STATE } from '../../enums';

import {
  ManageEventsDetailsCancelModal,
  VaccinationDetailsModal,
  ErrorModal
} from '../components';

import {
  MANAGE_EVENTS,
  // MANAGE_EVENT_BY_UUID,
  VIEWER_EVENTS_COUNT
} from '../../queries';

import { EVENT_PUBLISH, SUBMIT_PENDING_APPROVAL } from '../../mutations';

import { isPast, isVaccinated } from '../../libs';
import { breakpoints } from '../../styles';
import { EventRecurrenceModal } from '../Event/views/EventRecurrenceModal';
import { RECURRENCE_UPDATE_TYPE, RECURRENCE_ACTION_TYPE } from '../../constants/recurrances';
import {
  getEventAction,
  publishRecurringEvents,
  withEventMutationHooksHOC
} from '../../libs/events';

class ManageEventsDetailsTitle extends React.Component {
  state = {
    openCancel: false,
    redirect: false,
    filterRedirect: null,
    loading: false,
    recurrenceModalOpen: false,
    showVaccinationDetailsModal: false,
    showVaccinationErrorModal: false,
    showHasMetMaxPublishedEventsModal: false,
    buttonRef: null
  };

  handleToggleCancelModal = (redirect) => {
    this.setState(
      (prevState) => ({ openCancel: !prevState.openCancel, redirect }),
      () => {
        if (redirect) document.body.classList.remove('modal-open');
      }
    );
  };

  handleToggleVaccinationModal = (modal, shouldCreateEvent) => {
    const { buttonRef } = this.state;

    if (modal === 'vaccination_date') {
      this.setState(prev => ({ showVaccinationDetailsModal: !prev.showVaccinationDetailsModal }), () => {
        if (shouldCreateEvent && buttonRef) buttonRef.click();
        document.body.classList.remove('modal-open');
      });
    } else {
      this.setState(prev => ({ showVaccinationErrorModal: !prev.showVaccinationErrorModal }), () => {
        document.body.classList.remove('modal-open');
      });
    }
  };

  handlePublishClick = (e, buttonRef, action, requireApproval) => {
    const { event, session: { viewer }, settings } = this.props;
    const { event: { max_upcoming_published_events_per_user } } = settings;

    const eventAction = getEventAction('publish', event);
    // check vaccination status for vaccinated events //
    const isVaccinatedEvent = event.privacySubtype && event.privacySubtype.vaccinated;
    const viewerProfile = viewer?.user?.profile;
    const requiresVaccinationDate = isVaccinatedEvent && viewerProfile && !viewerProfile.vaccinationDate;
    const showVaccinationError = isVaccinatedEvent && viewerProfile && !isVaccinated(event, viewerProfile.vaccinationDate);
    // check max published events //
    const maxPublishedEventsPerHost = !viewer.user.canCopyEvents && parseInt(max_upcoming_published_events_per_user);
    const hostedEventsCount = viewer?.hostedEvents?.edges.length || 0;

    if (requiresVaccinationDate) {
      this.setState({ buttonRef, showVaccinationDetailsModal: true });
    } else if (showVaccinationError) {
      this.setState({ showVaccinationErrorModal: true });
    } else if (maxPublishedEventsPerHost && hostedEventsCount >= maxPublishedEventsPerHost) {
      this.setState({ showHasMetMaxPublishedEventsModal: true });
    } else {
      eventAction === RECURRENCE_ACTION_TYPE.PUBLISH_RECURRING
        ? this.toggleRecurringModal()
        : this.handlePublish(action, requireApproval);
    }
  };

  handlePublish = (action, requireApproval) => {
    // const { event } = this.props;
    const redirectFilter = requireApproval
      ? EVENT_FILTER.PENDING_PUBLISH_APPROVAL
      : EVENT_FILTER.HOSTED;

    this.setState({ loading: true });

    action({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: MANAGE_EVENTS, variables: { filter: redirectFilter } },
        { query: MANAGE_EVENTS, variables: { filter: EVENT_FILTER.DRAFT } },
        // { query: MANAGE_EVENT_BY_UUID, variables: { uuid: event.uuid, isHost: true } },
        { query: VIEWER_EVENTS_COUNT, variables: {} }
      ],
      variables: {
        eventId: this.props.event.id
      }
    })
      .then(() => {
        const filterRedirect = EVENT_FILTER.getField(EVENT_FILTER.FIELDS.URL, redirectFilter);
        this.setState({
          loading: false,
          redirect: true,
          filterRedirect
        });
      })
      .catch(() => {
        // console.error(err);
        this.setState({ loading: false });
      });
  };

  getEventFilter = () => {
    const { URL } = EVENT_FILTER.FIELDS;
    const filter = this.props.match.params.filter.toUpperCase();
    return EVENT_FILTER.getField(URL, filter);
  };

  getRedirectTo = (filter = null) => {
    const eventFilter = filter || this.getEventFilter();
    return `/manage/${eventFilter}`;
  };

  toggleRecurringModal = () => {
    this.setState({ recurrenceModalOpen: !this.state.recurrenceModalOpen });
  };

  render() {
    const { className, event, canCopyEvents, settings } = this.props;
    const {
      openCancel,
      redirect,
      filterRedirect,
      loading,
      showVaccinationDetailsModal,
      showVaccinationErrorModal,
      showHasMetMaxPublishedEventsModal
    } = this.state;
    const {
      event: { require_publish_approval }
    } = settings;
    const hasPassed = isPast(event);
    const redirectTo = this.getRedirectTo(filterRedirect);
    const {
      settings: { copy }
    } = this.props;
    const isDraft = event.state === EVENT_STATE.DRAFT;
    const requireApproval = !!require_publish_approval;

    return (
      <div className={`${className} manage_events_details_title`}>
        {redirect ? <Redirect to={redirectTo} /> : null}

        <div className='cancel_duplicate_buttons'>
          {(!hasPassed || (hasPassed && event.state === 'DRAFT')) && (
            <Button
              className='cancel_button'
              handleClick={() => {
                this.handleToggleCancelModal(false);
              }}
              inverted
            >
              {copy.manage.action_cancel_event}
            </Button>
          )}

          {canCopyEvents && (
            <Link to={{ pathname: '/events', state: { duplicateEventUuid: event.uuid } }}>
              <Button disabled={loading} className='duplicate_button'>
                Duplicate
              </Button>
            </Link>
          )}
        </div>

        <ManageEventsDetailsCancelModal
          open={openCancel}
          toggle={this.handleToggleCancelModal}
          event={event}
        />

        <h2 className={hasPassed && event.state !== 'DRAFT' ? 'has_passed' : null}>
          {event.title}
        </h2>
        <div className='action_buttons'>
          {!hasPassed && (
            <Fragment>
              <Link to={`/events/${event.uuid}/edit`}>
                <Button disabled={loading}>Edit</Button>
              </Link>
              {isDraft && (
                <Mutation mutation={EVENT_PUBLISH}>
                  {(eventPublish) => (
                    <Mutation mutation={SUBMIT_PENDING_APPROVAL}>
                      {(requestEventPublishApproval) => {
                        const action = requireApproval ? requestEventPublishApproval : eventPublish;

                        return (
                          <>
                            <Button
                              className='publish_button'
                              handleClick={
                                (e, buttonRef) =>
                                  this.handlePublishClick(e, buttonRef, action, requireApproval)
                              }
                              loading={loading}
                              disabled={loading}
                            >
                              {requireApproval
                                ? copy.create.submit_pending_publish_approval
                                : copy.create.submit}
                            </Button>
                            <EventRecurrenceModal
                              isPublish={true}
                              open={this.state.recurrenceModalOpen}
                              toggle={this.toggleRecurringModal}
                              save={() => {
                                this.toggleRecurringModal();
                                publishRecurringEvents(
                                  RECURRENCE_UPDATE_TYPE.ALL_EVENTS,
                                  action,
                                  event,
                                  this.props.publishEventMutation,
                                  this.handlePublish
                                );
                              }}
                            />
                            <VaccinationDetailsModal
                              show={showVaccinationDetailsModal}
                              toggle={val => this.handleToggleVaccinationModal('vaccination_date', val)}
                              action="create"
                            />
                            <ErrorModal
                              show={showVaccinationErrorModal}
                              toggle={() => this.handleToggleVaccinationModal('vaccination_error')}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: copy.create.host_requires_vaccination_error_message
                                }}
                              />
                            </ErrorModal>
                            <ErrorModal
                              show={showHasMetMaxPublishedEventsModal}
                              toggle={() => this.setState(prev => ({
                                ...prev,
                                showHasMetMaxPublishedEventsModal: !prev.showHasMetMaxPublishedEventsModal
                              }))}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: settings.copy.create.publish_limit_met_publish_message
                                    || 'You\'re a shining Shabbat star! You can only have three dinners posted at a time, but you can create and save future dinners as drafts. Way to plan ahead!'
                                }}
                              />
                            </ErrorModal>
                          </>
                        );
                      }}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const StyledManageEventsDetailsTitle = styled(ManageEventsDetailsTitle)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 60px;

  @media (${breakpoints.manageEventDetailsTablet}) {
    display: block !important;
    margin-bottom: 0;

    .cancel_button {
      position: absolute;
      bottom: 30px;
      left: 0;
    }

    .action_buttons {
      position: absolute;
      bottom: 30px;
      right: 0;
    }
  }

  * {
    align-self: center;
  }

  h2 {
    text-transform: capitalize;

    @media (${breakpoints.mobile}) {
      font-size: 26px;
    }

    &.has_passed {
      width: 100%;
      text-align: center;
    }

    &:not(.has_passed) {
      @media (${breakpoints.manageEventDetailsTablet}) {
        text-align: center;
        padding-bottom: 80px;
        margin: 0;
      }

      @media (${breakpoints.tabletSmall}) {
        padding-bottom: 120px;
      }
    }
  }

  div {
    display: flex;
  }

  .publish_button,
  .duplicate_button {
    margin-left: 10px;
  }
`;

const StyledManageEventsDetailsTitleWithRouter = withRouter(StyledManageEventsDetailsTitle);
const StyledManageEventsDetailsTitleWithRouterAndSettings = withSettings(
  StyledManageEventsDetailsTitleWithRouter
);
const StyledManageEventsDetailsTitleWithSession = withSession(
  StyledManageEventsDetailsTitleWithRouterAndSettings
);
const StyledManageEventsDetailsTitleWithMutationHook = withEventMutationHooksHOC(
  StyledManageEventsDetailsTitleWithSession
);

export { StyledManageEventsDetailsTitleWithMutationHook as ManageEventsDetailsTitle };
