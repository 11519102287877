import gql from 'graphql-tag';

const reservationNodeFields = `
  id
  participated
  state
  profile {
    id
    firstName
    lastName
    facebookId
    showOnGuestList
    avatar {
      id
      photo {
        mediumUrl
        originalUrl
      }
    }
  }
`;

const hostNodeFields = `
  id
  firstName
  lastName
  avatar {
    id
    photo {
      smallUrl
      mediumUrl
      originalUrl
    }
  }
`;

export const EVENTS = gql`
  query events(
    $numberOfItems: Int!,
    $cursor: String,
    $query: String,
    $areaCode: String,
    $dateFrom: ISO8601Date,
    $dateTo: ISO8601Date,
    $eventType: [EventType!],
    $eventSubtypeId: Int,
    $alcoholPolicy: [AlcoholPolicy!],
    $dietaryRestrictions: [DietaryRestriction!],
    $cost: Cost,
    $neighborhood: String,
    $subneighborhood: String
    $tag: String
    $order: EventOrder,
    $dressCode: String,
    $virtual: Boolean,
    $accessible: Boolean,
    $catered: Boolean,
    $maxPrice: Int,
    $minPrice: Int,
    $numberOfGuestsMax: Int,
    $parkingLots: Boolean,
    $potluck: Boolean,
    $stateLocation: String,
    $timezone: String,
    $petsAllowed: Boolean,
    $pwyw: Boolean,
    $scheduledAtRelativeToNow: TimeRelativeToNow,
    $closedCaptioningAvailable: Boolean,
    $videosOnAsDefault: Boolean,
    $bathroomAccessible: Boolean
  ){
    events(
      first: $numberOfItems,
      after: $cursor,
      query: $query,
      areaCode: $areaCode,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      eventType: $eventType,
      eventSubtypeId: $eventSubtypeId,
      alcoholPolicy: $alcoholPolicy,
      dietaryRestrictions: $dietaryRestrictions,
      cost: $cost,
      neighborhood: $neighborhood,
      subneighborhood: $subneighborhood,
      tag: $tag,
      order: $order,
      dressCode: $dressCode,
      virtual: $virtual,
      accessible: $accessible,
      catered: $catered,
      maxPrice: $maxPrice,
      minPrice: $minPrice,
      maxGuests: $numberOfGuestsMax,
      parkingLots: $parkingLots,
      potluck: $potluck,
      stateLocation: $stateLocation,
      timezone: $timezone,
      petsAllowed: $petsAllowed,
      pwyw: $pwyw,
      scheduledAtRelativeToNow: $scheduledAtRelativeToNow,
      closedCaptioningAvailable: $closedCaptioningAvailable,
      videosOnAsDefault: $videosOnAsDefault,
      bathroomAccessible: $bathroomAccessible
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          uuid
          areaOther
          stateLocation
          area {
            id
            code
            label
            neighborhoods {
              code
              label
              subneighborhoods {
                code
                label
              }
            }
          }
          cover {
            mediumUrl
            originalUrl
          }
          canSubscribe
          communityDinner
          duration
          eventType
          eventSubtype {
            id
            name
          }
          privacySubtype {
            id
            name
            eventType
            position
            disabled
            virtual
            outdoors
          }
          eventSubtypeOther
          expectedGuests
          fullAddress
          pricePerPerson
          eventHosts {
            edges {
              node {
                id
                creator
                profile {
                  ${hostNodeFields}
                }
              }
            }
          }
          neighborhood
          campus {
            id
            label
            subneighborhoods {
              code
              label
            }
          }
          subneighborhood
          numberOfGuestsMax
          partnerOrganizationLogo {
            smallUrl
            mediumUrl
            originalUrl
          }
          partnerOrganizationName
          partnerOrganizationDescription
          scheduledAt
          sponsorshipDeadline
          secondaryAddress
          state
          tags
          title
          virtual
          pwyw
          pwywMinimum
          timezone
          hideGuestList
          closedCaptioningAvailable
          videosOnAsDefault
          bathroomAccessible
          reservations(filterByState: ACCEPTED, first: 3) {
            guestsAccepted
            guestsPending
            reservationsAccepted
            edges {
              node {
                ${reservationNodeFields}
              }
            }
          }
        }
      }
    }
  }
`;
