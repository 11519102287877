import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

import { stringInsert } from '../../../libs';

const EventCardFooter = ({ className, eventsHiddenNumber, weekDate, settings: { copy }, type = 'private' }) => {
  if (eventsHiddenNumber <= 0) return null;

  return (
    <div className={className}>
      {stringInsert((copy[`${type}_card`] ? copy[`${type}_card`] : copy.private_card).footer_text, weekDate)}
    </div>
  );
};

const StyledEventCardFooter = styled(EventCardFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  background: ${props => props.settings.theme.css.global.colors.backgroundLight};
  font-size: 12px;
  color: ${props => props.settings.theme.css.global.colors.heading};
  border-top: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
  display: flex;
  justify-content: center;
  padding: 5px 10px;
`;

const StyledEventCardFooterWithSettings = withSettings(StyledEventCardFooter);

export { StyledEventCardFooterWithSettings as EventCardFooter };
