import gql from 'graphql-tag';

export const SPONSORS = gql`
  query {
    sponsors(first: 1000) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          photo {
            mediumUrl
          }
          url
          category
          description
          areas {
            id
            label
          }
        }
      }
    }
  }
`;
