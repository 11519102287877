import React from 'react';
import { Input, Button } from '../';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { CREATE_CREDIT_CARD, DESTROY_CREDIT_CARD, UPDATE_CREDIT_CARD } from '../../mutations';

import {
  Checkbox,
  withSettings
} from '../components';

import { breakpoints } from '../../styles';

const { Stripe } = window;


class AddCreditCardData extends React.Component {
  constructor(props) {
    super(props);

    const selectedCard = this.props.selectedCard;
    const expirationMonth = this.getCreditCardExpirationMonth(selectedCard);
    const expirationYear = this.getCreditCardExpirationYear(selectedCard);

    this.state = {
      id: selectedCard ? selectedCard.id : '',
      fullName: selectedCard ? selectedCard.owner : '',
      number: selectedCard ? `**** **** **** ${selectedCard.number}` : '',
      expirationMonth: selectedCard ? expirationMonth : '',
      expirationYear: selectedCard ? expirationYear : '',
      securityCode: selectedCard ? '***' : '',
      favorite: selectedCard ? selectedCard.favorite : false
    };
  }

  getCreditCardExpirationMonth = (creditCard) => {
    if (!creditCard || !creditCard.expiration || creditCard.expiration.constructor.name !== 'String') { return ''; }
    const components = creditCard.expiration.split('/');
    if (components.length !== 2) { return ''; }
    return components[0];
  }

  getCreditCardExpirationYear = (creditCard) => {
    if (!creditCard || !creditCard.expiration || creditCard.expiration.constructor.name !== 'String') { return ''; }
    const components = creditCard.expiration.split('/');
    if (components.length !== 2) { return ''; }
    return components[1];
  }

  handleInputValidation = () => {}

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  deleteCardClicked = (destroyCreditCard) => {
    const { id } = this.state;
    const { onDeleteCardSuccess, onDeleteCardError } = this.props;

    const variables = { id: id }

    destroyCreditCard({ variables }).then((response) => {
      if (response.error) {
        onDeleteCardError();
      } else {
        onDeleteCardSuccess(id);
      }
    });
  }

  isEditing = () => {
    const selectedCardId = this.state.id;
    return (selectedCardId !== undefined && selectedCardId > 0);
  }

  updateCard = (updateCreditCard) => {
    const variables = { id: this.state.id, favorite: this.state.favorite };

    updateCreditCard({ variables }).then((response) => {
      if (response.error) {
        this.props.onUpdateCardError();
      } else {
        this.props.onUpdateCardSuccess();
      }
    });
  }

  createCard = (createCreditCard) => {
    const { fullName, number, securityCode, expirationMonth, expirationYear } = this.state;
    const { onCreateCardSuccess, onCreateCardError } = this.props;

    const card = {
      number: number,
      name: fullName,
      cvc: securityCode,
      exp_month: expirationMonth,
      exp_year: expirationYear
    }

    Stripe.card.createToken(card, (status, stripeResponse) => {
      if (stripeResponse.error) {
        onCreateCardError();
      } else {
        const variables = { stripeToken: stripeResponse.id, favorite: this.state.favorite };

        createCreditCard({ variables }).then((response) => {
          if (response.error) {
            onCreateCardError();
          } else {
            const newCard = response.data.createCard.card
            onCreateCardSuccess(newCard);
          }
        });
      }
    });
  }

  saveCardClicked = (createCreditCard, updateCreditCard) => {
    if (this.isEditing()) {
      this.updateCard(updateCreditCard);
    } else {
      this.createCard(createCreditCard);
    }
  }

  render() {
    const { fullName, number, expirationMonth, expirationYear, securityCode, favorite } = this.state;
    const { className, cancelButtonHandler } = this.props;
    const { settings: { copy } } = this.props;

    const creditCardElement = (createCreditCard, destroyCreditCard, updateCreditCard) => {
      return (
        <div className={className}>
          <div className="row">
            <div className="question ot-third-container">
              <label>{copy.profile.card_name_label}</label>
            </div>
            <div className="ot-twothirds-container">
              <Input readOnly={this.isEditing()} type="text" name="fullName" placeholder={copy.profile.full_name_placeholder} value={fullName} onInputChange={this.handleInputChange} onValidate={this.handleInputValidation} required />
            </div>
          </div>
          <div className="row">
            <div className="question ot-third-container">
              <label>{copy.profile.card_number_label}</label>
            </div>
            <div className="ot-twothirds-container">
              <Input readOnly={this.isEditing()} type="text" name="number" placeholder={copy.profile.card_number_label} value={number} onInputChange={this.handleInputChange} onValidate={this.handleInputValidation} required />
            </div>
          </div>
          <div className="row">
            <div className="question ot-third-container">
              <label>{copy.profile.card_expiration}</label>
            </div>
            <div className="ot-third-container">
              <Input
                name="expirationMonth"
                readOnly={this.isEditing()}
                type="number"
                content="integer"
                min={1}
                max={12}
                placeholder={copy.profile.card_month_placeholder}
                value={expirationMonth}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                required
              />
            </div>
            <div className="ot-third-container">
              <Input
                name="expirationYear"
                readOnly={this.isEditing()}
                type="number"
                content="integer"
                min={new Date().getFullYear()}
                max={new Date().getFullYear() + 50}
                placeholder={copy.profile.card_year_placeholder}
                value={expirationYear}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="question ot-third-container">
              <label>{copy.profile.card_cvv}</label>
            </div>
            <div className="ot-third-container">
              <Input readOnly={this.isEditing()} type="text" name="securityCode" placeholder={copy.profile.card_cvv_placeholder} value={securityCode} onInputChange={this.handleInputChange} onValidate={this.handleInputValidation} required />
            </div>
            <div className="ot-third-container">
              <p>{copy.profile.card_cvv_text}</p>
            </div>
          </div>
          <div className="row">
            <div className="ot-third-container"></div>
            <div className="ot-third-container"></div>
            <div className="ot-third-container">
              <Checkbox
                name="favorite"
                float="right"
                options={[
                  {
                    label: copy.profile.card_default_text,
                    checked: favorite
                  }
                ]}
                onInputChange={this.handleInputChange}
              />
            </div>
          </div>
          <hr />
          <div className="row actions">
            <div className="ot-third-container">
              <Button buttonStyle="primary_outline" handleClick={cancelButtonHandler}>{copy.profile.card_cancel_button}</Button>
            </div>
            <div className="ot-twothirds-container">
              <Button className="action-button" handleClick={() => { this.saveCardClicked(createCreditCard, updateCreditCard); }}>{copy.profile.submit_card}</Button>
              {this.isEditing() &&
              <Button className="action-button" handleClick={() => { this.deleteCardClicked(destroyCreditCard); }}>{copy.profile.delete_card}</Button>
              }
            </div>
          </div>
        </div>
      )
    };

    return (
      <Mutation mutation={CREATE_CREDIT_CARD}>
        {(createCreditCard) => {
          return (
            <Mutation mutation={DESTROY_CREDIT_CARD}>
              {(destroyCreditCard) => {
                return (
                  <Mutation mutation={UPDATE_CREDIT_CARD}>
                    {(updateCreditCard) => {
                      return (creditCardElement(createCreditCard, destroyCreditCard, updateCreditCard));
                    }}
                  </Mutation>
                );
              }
            }
            </Mutation>
          );
        }
      }
      </Mutation>
    );
  }

}

const StyledAddCreditCardData = styled(AddCreditCardData)`
  p {
    color: #555;
    font-size: 12px;
  }

  input[type=checkbox] {
    margin-right: 5px;
  }

  .action-button {
    float: right;
    margin-left: 10px;
  }

  input {
    margin: 0;

    &[name="expirationMonth"] {
      @media (${breakpoints.bootstrap}) {
        margin-bottom: 15px;
      }
    }
  }

  input[type=checkbox] {
    position: relative;
  }

  .checkbox__label {
    padding-left: 0 !important;
  }

  .row.actions {
    display: block;

    @media (${breakpoints.bootstrap}) {
      &::after {
        content: '';
        display: block;
        clear: both;
      }

      .ot-third-container {
        float: left;
        width: auto;
      }

      .ot-twothirds-container {
        float: right;
        width: auto;
      }
    }
  }
`;

const StyledAddCreditCardDataWithSettings = withSettings(StyledAddCreditCardData);

export { StyledAddCreditCardDataWithSettings as AddCreditCardData };
