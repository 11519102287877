import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Card,
  Avatar,
  EventViewHeaderDetails,
  Button,
  RsvpButtonDumb,
  RsvpButton,
  VaccinationDetailsModal,
  withSettings
} from '../components';

import { EVENT_STATE, RSVP_CAPABILITY, EVENT_TYPE } from '../../enums';

import { getReservationStatus, formatPrice, stringInsert } from '../../libs';
import { breakpoints } from '../../styles';

const EventViewHeader = props => {
  const {
    className,
    settings,
    event,
    viewer,
    areas,
    restriction,
    isHostOrReservedGuest,
    showRsvpModal,
    showCancelReservationModal,
    showInviteFriendsModal,
    openDeclineInvitationModal,
    toggleLoginModal
  } = props;

  const [showVaccinationDetailsModal, setShowVaccinationDetailsModal] = useState(false)
  const handleToggleVaccinationModal = () => {
    // shouldContinue arg? //
    setShowVaccinationDetailsModal(prev => !prev);
  };

  const { copy, theme: { link_attendee_avatars_to_profile } } = settings;
  const { event: { pay_what_you_want_label, partnership_tag_message, hosted_by } } = copy;
  const images = settings.theme.assets.images;
  const hosts = event.eventHosts.edges.sort(a => a.node.creator ? -1 : 1);
  const eventIsFull = event.reservations.guestsAccepted >= event.numberOfGuestsMax;
  const loggedIn = !!(viewer && viewer.user);
  const hasRestriction = restriction.content !== null;
  const organizationLogo = event.partnerOrganizationLogo;
  const reservationStatus = getReservationStatus(event, viewer);

  const getBackgroundStyle = () => {
    if (event.cover) return { backgroundImage: `url(${event.cover.largeUrl || event.cover.originalUrl})` };
    return { backgroundImage: `url(${images.default_cover})` }
  };

  const hasPartnershipTag = () => {
    // Show message if `partnership` tag and copy message exists
    return event.tags && (event.tags.findIndex(tag => tag.toLowerCase() === 'partnership') !== -1) &&
      partnership_tag_message;
  };

  const RestrictedRsvpButton = ({ onClick }) => {
    return (
      <div>
        <Button
          className="event_rsvp_button"
          handleClick={onClick}
          mainButtonName={copy.event.rsvp}
          fullWidth
        >
          { event.eventType === EVENT_TYPE.MANUAL_APPROVAL ? copy.event.request_a_seat : copy.event.rsvp }
        </Button>
      </div>
    );
  };

  const isMaxGuestReached = () => {
    const totalGuests = event.reservations.reservationsAccepted + event.reservations.guestsPending
    const checkPendingMaxSeats = settings && settings.features && settings.features.check_pending_max_seats;
    return totalGuests >= event.numberOfGuestsMax && checkPendingMaxSeats;
  };

  return (
    <Card className={className} noPadding>
      <div
        className="cover_photo"
        style={getBackgroundStyle()}
      />
      <div className="header_details">
        <p className="hosted_by">{hosted_by}</p>
        <div className="hosts_container">
          {event.communityDinner && organizationLogo ? (
            <div className="community_host">
              <div
                className="partnership_logo"
                style={{ backgroundImage: `url(${organizationLogo.smallUrl || organizationLogo.originalUrl})` }}
              />
              {event.partnerOrganizationName && <span className="partnership_name">{event.partnerOrganizationName}</span>}
            </div>
          ) : null}

            <div className="avatar_container">
              {hosts && hosts.map((edge, i) => {
                const { node: { profile } } = edge;
                return profile ? (
                  <Avatar
                    key={i}
                    profile={profile}
                    layout="vertical"
                    linked={link_attendee_avatars_to_profile}
                    inline
                  />
              ) : null;
              })}
            </div>
        </div>
        <h1>{event.title}</h1>
        <EventViewHeaderDetails
          event={event}
          areas={areas}
          viewer={viewer}
          isHostOrReservedGuest={isHostOrReservedGuest}
        />

        <i className="divider" />

          <div className="event_price">
            {event.pwyw ? (
              <span className="pwyw">
                {stringInsert(
                  pay_what_you_want_label || 'Pay What You Want (Min $%1$s)',
                  formatPrice(event.pwywMinimum)
                )}
              </span>
            ) : (
              event.pricePerPerson
                ? <span>${formatPrice(event.pricePerPerson)}</span>
                : <span>Free</span>
            )}
          </div>

        {isMaxGuestReached(event) && event.state === EVENT_STATE.PUBLISHED && (
          <>
            {
              (reservationStatus === RSVP_CAPABILITY.RSVP_ACCEPTED ||  reservationStatus === RSVP_CAPABILITY.INVITE_ACCEPTED || reservationStatus === RSVP_CAPABILITY.HOST_VIEWER || reservationStatus === RSVP_CAPABILITY.RSVP_PENDING) ?
                <RsvpButton
                  event={event}
                  viewer={viewer ? viewer : null}
                  areas={areas}
                  openRsvpModal={showRsvpModal}
                  openDeclineInvitationModal={openDeclineInvitationModal}
                  handleCancelReservation={showCancelReservationModal}
                  handleInviteFriends={showInviteFriendsModal}
                />
              :
              <RsvpButtonDumb
                buttonStyle="primary no_seats"
                mainButtonName={copy.event.no_available_seats}
                true
                />
            }
          </>
        )}

        {!isMaxGuestReached(event) && event.canSubscribe && event.state === EVENT_STATE.DRAFT && (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName="DRAFT"
            disabled
          />
        )}

        {!isMaxGuestReached(event) && event.canSubscribe && event.state === EVENT_STATE.PENDING_PUBLISH_APPROVAL && (
          <RsvpButtonDumb
            buttonStyle="primary"
            mainButtonName="PENDING APPROVAL"
            disabled
          />
        )}

        {!isMaxGuestReached(event) && event.canSubscribe && event.state === EVENT_STATE.PUBLISHED && (
          <>
            {/*
              options:
                1. logged in with restriction and a non-full dinner (get log in button)
                2. logged in without restriction (get rsvp button) or restricted on full dinner (get dinner is full button)
                3. not logged in full dinner (get dinner is full button)
                4. not logged in non-full dinner (get log in button)
            */}
            {loggedIn ? (
              hasRestriction && !eventIsFull
                ? <RestrictedRsvpButton onClick={restriction.showRestriction} />
                : (
                <RsvpButton
                  event={event}
                  viewer={viewer ? viewer : null}
                  areas={areas}
                  openRsvpModal={showRsvpModal}
                  openDeclineInvitationModal={openDeclineInvitationModal}
                  handleCancelReservation={showCancelReservationModal}
                  handleInviteFriends={showInviteFriendsModal}
                />
              )
            ) : (
              eventIsFull ? (
                <RsvpButton
                  event={event}
                  viewer={viewer ? viewer : null}
                  areas={areas}
                  openRsvpModal={showRsvpModal}
                  openDeclineInvitationModal={openDeclineInvitationModal}
                  handleCancelReservation={showCancelReservationModal}
                  handleInviteFriends={showInviteFriendsModal}
                />
              ) : <RestrictedRsvpButton onClick={toggleLoginModal} event={event} />
            )}
            {hasPartnershipTag() && <p className="partnership">{partnership_tag_message}</p>}
          </>
        )}

        <VaccinationDetailsModal
          show={showVaccinationDetailsModal}
          toggle={val => handleToggleVaccinationModal(val)}
          action="create"
        />
      </div>
    </Card>
  );
}

const StyledEventViewHeader = styled(EventViewHeader)`
  min-height: 480px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .event_price {
    margin-bottom: 30px;

    span {
      &.pwyw {
        font-size: 15px;
      }
    }
  }

  .cover_photo {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .no_seats {
    background-color: #878787;
    border-color: #878787;
    &.button {
      background-color: #878787;
      color: #DDDDDD;

      &:hover {
        background-color: #878787;
        border-color: #878787;
      }
    }
  }

  .header_details {
    float: right;
    width: 50%;
    padding: 30px 25px;
    text-align: center;

    @media (${breakpoints.mobile}) {
      padding-bottom: 40px;
    }

    .hosted_by {
      margin: 0 0 15px;
    }

    .user_name {
      font-weight: bold;
      display: inline-block;
      padding-top: 10px;
    }

    & > h1 {
      font-size: 30px;
      margin: 25px 0 20px;
    }

    .hosts_container {
      display: flex;
      justify-content: center;

      .community_host,
      .avatar_container > div {
        min-width: 80px;
      }
    }

    .community_host {
      margin: 0 10px;

      @media (${breakpoints.mobile}) {
        margin: 0;
      }
    }

    .avatar_container {
      & > div {
        display: inline-block;
        margin: 0 10px;

        @media (${breakpoints.mobile}) {
          margin: 0;
        }
      }

      & > form {
        display: inline-block;
      }
    }
  }

  .partnership {
    font-size: 12px;
    margin: 30px 0 5px;

    &_logo {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);
      margin: auto;
      background-position: center;
      background-size: cover;
    }
    &_name {
      font-size: 12px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-weight: bold;
      padding-top: 10px;
      display: inline-block;
    }
  }

  @media (${breakpoints.tablet}) {
    .cover_photo {
      position: static;
      width: 100%;
      padding-top: 65%;
      max-height: 420px;
      overflow: hidden;
    }
    .header_details {
      float: none;
      width: 100%;
    }
  }

  @media (${breakpoints.mobile}) {
    .cover_photo {
      padding-top: 80%;
    }
  }
  .event_rsvp_button {
    width: 100%;
    max-width: 250px;
    margin: 30px auto 0;
  }
`;

const EventViewHeaderWithSettings = withSettings(StyledEventViewHeader);

export { EventViewHeaderWithSettings as EventViewHeader };
