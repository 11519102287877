import _ from 'underscore';
import { getEventDeadlineAt, getEventDuration } from '../libs';

export const getUpdatedFields = (savedEventData = {}, eventData = {}) => {
  // console.log(savedEventData, eventData);
  return _.reduce(_.keys(eventData), (diff, key) => {
    const value = eventData[key];
    const savedValue = savedEventData[key];
    
    // formatted vars //
    const cohosts = value && key === 'cohosts' && value.map(ch => ch.email);
    const notes = value && key === 'notes' && JSON.parse(value);
    
    // add changed values //
    switch (key) {
      case 'eventCreationResponses':
      case 'shouldSkipSponsorshipLimits':
        break;
        
      case 'accessible':
      case 'bathroomAccessible':
      case 'closedCaptioningAvailable':
      case 'parkingLots':
      case 'videosOnAsDefault':
        if (savedValue !== undefined && savedValue.toString() !== value.toString()) diff[key] = value;
        break;
        
      case 'areaId':
        if (savedEventData.area && savedEventData.area.id !== value) diff[key] = value;
        break;
        
      case 'cohosts':
        if (savedValue !== undefined && cohosts && !_.isEqual(savedValue, cohosts)) diff[key] = value;
        break;
        
      case 'coverUrl':
        if (savedEventData.cover !== value) diff[key] = value;
        break;
        
      case 'deadlineAt':
        if (value !== (
          savedEventData.rsvpDeadline && savedEventData.rsvpDeadlineDate && savedEventData.rsvpDeadlineTime
            ? getEventDeadlineAt(savedEventData.rsvpDeadlineDate, savedEventData.rsvpDeadlineTime)
            : null
        )) diff[key] = value;
        break;
        
      case 'notes':
        if (
            !_.isEqual(savedEventData.notes_first, notes.first) || 
            !_.isEqual(savedEventData.notes_second, notes.second) || 
            !_.isEqual(savedEventData.notes_third, notes.third)
          ) diff[key] = value;
        break;
        
      case 'createReservationQuestions':
      case 'destroyReservationQuestions':
      case 'updateReservationQuestions':
      case 'createPotluckItemsObj':
      case 'destroyPotluckItems':
        if (value && value.length) diff[key] = value;
        break;
        
      case 'fullAddress':
      case 'secondaryAddress':
        if (savedEventData[key.replace('Address', 'Dress')] !== value) diff[key] = value;
        break;
      
      case 'pricePerPerson':
        if (typeof value === 'number' && (
            (!savedEventData.paid && value > 0) ||
            (savedEventData.paid && savedEventData.price !== (value / 100).toString())
          )) diff[key] = value;
        break;
        
      case 'pwywMinimum':
        if (eventData.pwyw && savedValue !== value) diff[key] = value;
        break;
        
      case 'communityDinner':
        if (value) diff[key] = value;
        break;
        
      case 'duration':
        if (value !== (
            getEventDuration(savedEventData.date, savedEventData.startTime, savedEventData.endTime)
          )) diff[key] = value;
        break;
        
      case 'partnerOrganizationDescription':
      case 'partnerOrganizationLogoUrl':
      case 'partnerOrganizationName':
        if (eventData.communityDinner && savedValue !== value) diff[key] = value;
        break;
        
      case 'scheduledAt':
        if (value !== (
            getEventDeadlineAt(savedEventData.date, savedEventData.startTime)
          )) diff[key] = value;
        break;
        
      case 'timezoneOther':
        if (value !== (
            savedEventData.area && !savedEventData.area.timezone ? savedEventData.timezone : null
          )) diff[key] = value;
        break;
        
      case 'privacySubtypeId':
        if (savedEventData.privacySubtype && savedEventData.privacySubtype.id !== value) 
          diff[key] = value;
        break;
        
      case 'eventSubtypeId':
        if (savedEventData.eventSubtype !== value) diff[key] = value;
        break;
        
      default:
        if (!_.isEqual(savedValue, value)) diff[key] = value;
    }

    return diff;
  }, {});
};
