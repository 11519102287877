import React, { useState } from 'react';
import styled from 'styled-components';
import { EventPotluckModal, FAIcon } from '../../components';
import {
  getPotluckReservedItemsCount,
  getPotluckTotalItemsCount,
  getReservations,
  whitelabel,
  stringInsert
} from '../../../libs';
import { withSettings } from '../../components';
import { useSession } from '../../../hooks';

const EventPotluck = (props) => {
    const { className, event, settings } = props;
    const { viewer } = useSession();
    const { copy } = settings;

    const [open, setOpen] = useState(false);

    const totalPotluckItems = getPotluckTotalItemsCount(event);
    const totalPotluckItemsReserved = getPotluckReservedItemsCount(event);

    const potluckIcon = whitelabel.hasFeature(settings, 'hidePotluckIcons')
        ? ['fal', 'calendar']
        : 'utensils';
    const potluckLabel = stringInsert(
        copy.manage.number_of_potluck_items_reserved || '%1$s of %2$s potluck items reserved',
        [totalPotluckItemsReserved, totalPotluckItems]
    );


    const handleToggleModal = () => setOpen(prev => !prev)

    const renderBringing = () => {
        if (viewer) {
            for (const reservation of getReservations(event)) {
                if (reservation.profile.id === viewer.user.profile.id
                    && reservation.potluckItems.edges.length > 0) {
                    return (
                        <div className="bringing">
                            {`I'm Bringing ${reservation.potluckItems.edges[0].node.name}`}
                        </div>
                    );
                }
            }
        }
        return null;
    }

    return (
        <div className={`${className} event_potluck`}>
            <div onClick={handleToggleModal}>
                <FAIcon name={potluckIcon}/>
                {potluckLabel}
            </div>
            {renderBringing()}
            <EventPotluckModal event={event} open={open} toggle={handleToggleModal}/>
        </div>
    );
}


const StyledEventPotluck = styled(EventPotluck)`
    div {
        margin-left: 0 !important;
    }
    div:first-child {
        color: ${props => props.settings.theme.css.global.colors.primary};
        font-weight: bold;
        cursor: pointer;
        font-size: 11px;
    }
    div:first-child:hover {
        text-decoration: underline;
    }
    svg {
        margin-right: 6px;
    }
    .bringing {
        background: #f5b15f;
        padding: 5px 6px 4px;
        letter-spacing: 1px;
        font-size: 8px;
        line-height: 8px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }
`;

const StyledEventPotluckWithSettings = withSettings(StyledEventPotluck);

export { StyledEventPotluckWithSettings as EventPotluck }
