import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SelectBox, Input } from '../../../components';
import { getSiteSubNameFromSettings } from '../../../../libs';

const VALUE_OTHER = 'other';

const ProfileFieldsExtra = ({ className, questions, settings, data: { answers: dataAnswers }, handleInputChange }) => {
  const [answers, setAnswers] = useState({});
  const siteLabel = settings.theme.site_label || 'Michigan';
  const siteSubName = getSiteSubNameFromSettings(settings);
  const isHillelInternational = siteSubName === 'international';

  const iAm = (isHillelInternational ? answers.iAmHillel : answers.iAm) || {};
  const iAmOther = (isHillelInternational ? answers.iAmHillelOther : answers.iAmOther) || {};
  const iAmKey = isHillelInternational ? 'iAmHillel' : 'iAm';

  const iAmQuestion = questions.find(q => q.name === iAmKey);
  const iAmAltQuestion = questions.find(q => q.name === 'iAmAlt');
  const campusQuestion = questions.find(q => q.name === 'iAmCampus');
  const gradYearQuestion = questions.find(q => q.name === 'gradYear');

  const {
    iAmAlt = {},
    iAmCampus = {},
  } = answers;

  const undergradOrGrad = ans => ans === 'a' || ans === 'b'; // under grad || grad student

  const showIAmFields = undergradOrGrad(iAm.answer);
  const showIAmAltFields = undergradOrGrad(iAmAlt.answer);
  const showIAmOther = iAm.answer === VALUE_OTHER || iAmAlt.answer === VALUE_OTHER;
  const showCampusOther = iAmCampus.answer === VALUE_OTHER;

  useEffect(() => {
    // for hga users who signed up before iAmAlt was added //
    if (dataAnswers && !dataAnswers.iAmAlt && siteSubName === 'hga') {
      dataAnswers.iAmAlt = dataAnswers.iAm;
      delete dataAnswers.iAm;
    }
    setAnswers(dataAnswers || {});
  }, [dataAnswers, siteSubName]);

  const getOptions = name => {
    let question;

    switch (name) {
      case 'iAm':
      case 'iAmHillel':
        question = iAmQuestion;
        break;
      case 'iAmAlt':
        question = iAmAltQuestion;
        break;
      case 'iAmCampus':
        question = campusQuestion;
        break;
      case 'gradYear':
        question = gradYearQuestion;
        break;
      default:
    }

    if (question) {
      return question.choices.map(choice => ({
        id: choice.id,
        value: choice.id,
        label: choice.label.replace('{{siteLabel}}', siteLabel)
      }));
    }
    return [];
  };

  const getDefaultValue = (q, name) => {
    const options = q ? q.choices : [];
    const data = answers && answers[name];

    if (!data || !options || !data.answer) return false;

    const choice = typeof data.answer === 'string'
      // legacy string answer values
      ? options.find(o => o.id === data.answer)
      // `data.answer` is formatted { a: true }
      // looking for the `data.answer` key name to match with the option id
      : options.find(o => Object.keys(data.answer).reduce((k, v) => v === o.id ? v : k, ''));

    return choice ? {
      id: choice.id,
      value: choice.id,
      label: choice.label.replace('{{siteLabel}}', siteLabel)
    } : false;
  };

  const handleCampusInputChange = (name, value) => {
    const values = [{ name, value }];
    // clear iAmCampusOther when iAmCampus is not `other` //
    if (value !== VALUE_OTHER) values.push({ name: 'iAmCampusOther', value: '' });
    handleInputChange(values);
  };

  return (
    <div className={className}>
      <div className="row">
        <div className="ot-third-container">
          <label>I am a</label>
        </div>
        <div className="ot-twothirds-container">
          {answers.iAmAlt ? (
            <SelectBox
              name="iAmAlt"
              options={getOptions('iAmAlt')}
              onOptionSelect={value => handleInputChange('iAmAlt', value)}
              placeholder="---"
              defaultValue={getDefaultValue(iAmAltQuestion, 'iAmAlt')}
            />
          ) : (
            <SelectBox
              name="iAm"
              options={getOptions(iAmKey)}
              onOptionSelect={value => handleInputChange(iAmKey, value)}
              placeholder="---"
              defaultValue={getDefaultValue(iAmQuestion, iAmKey)}
            />
          )}
        </div>
      </div>

      {showIAmOther && (
        <div className="row">
          <div className="ot-third-container">
            <label>I am (other)</label>
          </div>
          <div className="ot-twothirds-container">
            <Input
              type="text"
              name="iAmOther"
              value={iAmOther.answer || ''}
              onInputChange={handleInputChange}
            />
          </div>
        </div>
      )}

      {(showIAmFields || showIAmAltFields) && (
        <>
          {showIAmAltFields && (
            <>
              <div className="row">
                <div className="ot-third-container">
                  <label>Campus</label>
                </div>
                <div className="ot-twothirds-container">
                  <SelectBox
                    name="iAmCampus"
                    options={getOptions('iAmCampus')}
                    onOptionSelect={value => handleCampusInputChange('iAmCampus', value)}
                    placeholder="---"
                    defaultValue={getDefaultValue(campusQuestion, 'iAmCampus')}
                  />
                </div>
              </div>
              {showCampusOther && (
                <div className="row">
                  <div className="ot-third-container">
                    <label>Other Campus</label>
                  </div>
                  <div className="ot-twothirds-container">
                    <Input
                      type="text"
                      name="iAmCampusOther"
                      value={answers.iAmCampusOther ? answers.iAmCampusOther.answer : ''}
                      onInputChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <div className="row">
            <div className="ot-third-container">
              <label>Graduation Year</label>
            </div>
            <div className="ot-twothirds-container">
              <SelectBox
                name="gradYear"
                options={getOptions('gradYear')}
                onOptionSelect={value => handleInputChange('gradYear', value)}
                placeholder="---"
                defaultValue={getDefaultValue(gradYearQuestion, 'gradYear')}
              />
            </div>
          </div>
          <div className="row">
            <div className="ot-third-container">
              <label>Student ID</label>
            </div>
            <div className="ot-twothirds-container">
              <Input
                type="text"
                name="studentId"
                placeholder="Student ID"
                value={answers.studentId ? answers.studentId.answer : ''}
                onInputChange={handleInputChange}
              />
            </div>
          </div>
          <hr />
          <h3>Parent/Guardian Contact Info</h3>
          <div className="row">
            <div className="ot-third-container">
              <label>Name</label>
            </div>
            <div className="ot-third-container">
              <Input
                type="text"
                name="parentFirst"
                placeholder="First Name"
                value={answers.parentFirst ? answers.parentFirst.answer : ''}
                onInputChange={handleInputChange}
              />
            </div>
            <div className="ot-third-container">
              <Input
                type="text"
                name="parentLast"
                placeholder="Last Name"
                value={answers.parentLast ? answers.parentLast.answer : ''}
                onInputChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="ot-third-container">
              <label>Phone Number</label>
            </div>
            <div className="ot-twothirds-container">
              <Input
                type="text"
                name="parentPhone"
                placeholder="Phone Number"
                value={answers.parentPhone ? answers.parentPhone.answer : ''}
                onInputChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="ot-third-container">
              <label>Email</label>
            </div>
            <div className="ot-twothirds-container">
              <Input
                type="email"
                name="parentEmail"
                placeholder="Email"
                value={answers.parentEmail ? answers.parentEmail.answer : ''}
                onInputChange={handleInputChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const StyledProfileFieldsExtra = styled(ProfileFieldsExtra)`
  .select_box {
    background-color: #fff;
  }
`;
export { StyledProfileFieldsExtra as ProfileFieldsExtra };
