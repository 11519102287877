import React from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  Input,
  Button,

} from '..';

import { setToken } from '../../libs/token';
import { LOG_IN_WITH_PASSWORD } from '../../mutations';
import { OtpContext } from '../../OtpContext';
import { authViewStateValues } from '../../libs/auth';


const initialState = {
  email: '',
  password: '',
  emailValid: false,
  passwordValid: false,
  hasLoginError: false,
  isLoading: false
};

class LogInWithEmailAndPassword extends React.Component {
  state = { ...initialState };
  static contextType = OtpContext;


  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  formIsInvalid = () => {
    const { emailValid, passwordValid } = this.state;
    return !emailValid || !passwordValid;
  };

  handleFormSubmit = (event, logInWithPassword) => {
    const { shouldRedirect, afterLogin } = this.props;
    event.preventDefault();

    this.setState({ isLoading: true });
    logInWithPassword()
      .then(async ({ data }) => {
        const { user, token, errors, status } = data.logInWithPassword;

        if (user) {
          setToken(this.props.settings, token);

          await this.props.refetch();

          if (shouldRedirect) this.props.history.push('/landing');
          if (afterLogin) afterLogin();

        } else if (Array.isArray(errors) && errors.length) {
          this.setState({
            isLoading: false,
            hasLoginError: true
          });
        } else if (status === 'must_request_otp') {
          this.context.otpSettings.setOtpSettings({
            status: data.logInWithPassword.status,
            userId: data.logInWithPassword.userId,
            userMessage: data.logInWithPassword.userMessage,
            userPhone: data.logInWithPassword.userPhone,
          });
          this.props.setViewState(authViewStateValues.OTP_VIEW)
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { className, setViewState } = this.props;
    const { email, password, isLoading } = this.state;

    return (
        <Mutation mutation={LOG_IN_WITH_PASSWORD} variables={{ email, password }} >
            {(logInWithPassword) => {
            return (
                <div className={className}>
                <form
                    className="ot_form"
                    onSubmit={event => this.handleFormSubmit(event, logInWithPassword)}
                >
                    {this.state.hasLoginError && (
                    <div className="form_submit_error">
                        {'The email and password you entered did not match our records. If you haven\'t created a new account, please sign up now.'}
                    </div>
                    )}
                    <Input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onInputChange={this.handleInputChange}
                    onValidate={this.handleInputValidation}
                    content="email"
                    required
                    />
                    <div className="password_wrapper">
                    <Input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        required
                    />
                    <span
                        className="forgot_password"
                        onClick={() => setViewState(authViewStateValues.FORGOT_PASSWORD_VIEW)}
                    >{'Forgot?'}</span>
                    </div>
                    <Button
                      type="submit"
                      disabled={this.formIsInvalid() || isLoading}
                      loading={isLoading}
                      fullWidth
                      capitalize
                    >Log In With Email</Button>
                </form>
                </div>
            );
            }}
        </Mutation>
    );
  }
}

const StyledLogInWithEmailAndPassword = styled(LogInWithEmailAndPassword)`
  .password_wrapper {
    position: relative;
    input {
      padding-right: 55px;
    }
  }
  .forgot_password {
    position: absolute;
    right: 14px;
    top: 12px;
    // transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primary};
    cursor: pointer;
    transition: color 250ms;
    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }
`;

const LogInWithEmailAndPasswordWithRouter = withRouter(StyledLogInWithEmailAndPassword);
const LogInWithEmailAndPasswordWithRouterWithSettings = withSettings(LogInWithEmailAndPasswordWithRouter);

export { LogInWithEmailAndPasswordWithRouterWithSettings as LogInWithEmailAndPassword };
