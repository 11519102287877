import React from 'react';
import { withRouter } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { media } from '../../styles';

import { FAIcon } from '../components';


const JourneyResourcesButtons = ({
  className,
  handleRedirect,
  handleCreationRedirect,
  journeyAction,
  eventUuid,
  history,
  toggleShareModal
}) => {

  const FindDinnerButton = ({ children }) => (
    <ResourcesButton onClick={handleRedirect} marker>
      <FAIcon name={['fas', 'map-marker-alt']} />
      {children}
    </ResourcesButton>
  );

  const CreateDinnerButton = ({ children }) => (
    <ResourcesButton onClick={() => handleCreationRedirect()} utensils>
      <FAIcon name={['fal', 'utensils']} />
      {children}
    </ResourcesButton>
  );

  const ViewDinnerButton = ({ children }) => (
    <ResourcesButton onClick={() => history.push({ pathname: `/events/${eventUuid}` })} calendar>
      <FAIcon name={['fal', 'calendar-alt']} />
      {children}
    </ResourcesButton>
  );

  const ManageDinnerButton = ({ children }) => (
    <ResourcesButton onClick={() => history.push({ pathname: `/manage/hosted/${eventUuid}` })} manage>
      <FAIcon name={['fal', 'calendar-edit']} />
      {children}
    </ResourcesButton>
  );

  const ResourcesDinnerButton = ({ children }) => (
    <ResourcesButton onClick={() => history.push({ pathname: '/journey' })} resources>
      <FAIcon name={['fas', 'repeat-alt']} />
      {children}
    </ResourcesButton>
  );

  const ResourcesShareButton = ({ children }) => (
    <ResourcesButton onClick={toggleShareModal} share>
      <FAIcon name={['fal', 'share']} />
      {children}
    </ResourcesButton>
  );

  switch (journeyAction) {
    case 'host':
      return (
        <div className={className}>
          <ViewDinnerButton>View My Dinner</ViewDinnerButton><br />
          <ManageDinnerButton>Manage My Dinner</ManageDinnerButton><br />
          <ResourcesShareButton>Share Resources</ResourcesShareButton>
        </div>
      );
    case 'share':
      return (
        <div className={[className, 'share'].join(' ')}>
          <ResourcesDinnerButton>Find My Own Resources</ResourcesDinnerButton>{false && <><br />
          <ResourcesShareButton>Share Resources</ResourcesShareButton></>}
          {/* hide share until mutation doesnt expect an event to be present */}
        </div>
      );
    case 'find':
    case 'landing':
    default:
      return (
        <div className={className}>
          <FindDinnerButton>Find a dinner near me</FindDinnerButton><br />
          <CreateDinnerButton>Create a dinner</CreateDinnerButton>
        </div>
      );
  }
}

const StyledJourneyResourcesButtons = styled(JourneyResourcesButtons)`
  margin: 60px 0 0;

  @media (max-height: 750px) {
    margin: 40px 0 0;
  }

  &.share {
    padding-top: 60px;

    @media (max-height: 750px) {
      padding-top: 0;
      position: absolute;
      bottom: -160px;
      left: 0;
    }
  }
`;
const JourneyResourcesButtonsWithRouter = withRouter(StyledJourneyResourcesButtons);
export { JourneyResourcesButtonsWithRouter as JourneyResourcesButtons };

const ResourcesButton = styled.p`
  font-family: 'Avenir-Bold' !important;
  font-size: 24px;
  margin: 0 0 20px;
  color: #fff;
  cursor: pointer;
  transition: opacity 250ms;
  display: inline-block;
  position: relative;
  padding-left: 36px;

  @media (max-height: 750px) {
    margin: 0 0 15px;
  }

  ${props => props.resources && css`
    font-size: 22px;

    ${media.journeyLg`
      font-size: 18px;
    `}
  `}

  ${media.journeyLg`
    font-size: 20px;
  `}

  &:hover {
    opacity: 0.7;
  }

  svg {
    position: absolute;
    left: 0;
    top: 2px;
  }

  ${props => props.marker && css`
    svg {
      left: 3px;
    }
  `}
`;
