import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSettings } from '../../hooks';
import { breakpoints } from '../../styles';
import { FAIcon } from '../components';

export const BackHelper = () => {
  const history = useHistory();
  const { colors } = useSettings();

  return (
    <StyledBackHelper onClick={() => history.goBack()} colors={colors}>
      <FAIcon name={['far', 'arrow-left']} />
    </StyledBackHelper>
  );
};

const StyledBackHelper = styled.div`
  position: fixed;
  z-index: 100000;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: #fff;
  top: 55px;
  left: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: ${({ colors }) => colors.primary};
    transition: color 250ms;
    font-size: 24px;
  }

  &:hover {
    svg {
      color: ${({ colors }) => colors.primaryHover};
    }
  }

  @media (${breakpoints.tablet}) {
    top: 20px;
    left: 15px;
    width: 44px;
    height: 44px;

    svg {
      font-size: 20px;
    }
  }
`;
