import React from 'react';
import styled from 'styled-components';

import { FAIcon, SearchFilter } from '../../components';


export const MoreFiltersButton = ({ onClick }) => (
  <SearchFilter icon="left" onClick={onClick}>
    <FilterIcon><FAIcon name={[ 'fas', 'bars' ]} /></FilterIcon>
    More Filters
  </SearchFilter>
);


const FilterIcon = styled.span`
  .fa-bars {
    position: absolute;
    top: 50%;
    left: 16px;
    color: #000;
    font-size: 15px;
    margin-top: -8px;
  }
`;
