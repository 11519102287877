import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import { withSettings } from '../../components';

moment.locale('en');
momentLocalizer();

// react-widgets defaults
const defaultDates = {
  min: new Date(1900, 0, 1),
  max: new Date(2099, 11, 31)
};

class TimePicker extends React.Component {
  state = { valid: true };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  getTimeMin = () => {
    const { min } = this.props;
    if (min) return min;
    return defaultDates.min;
  };

  getTimeMax = () => {
    const { max } = this.props;
    if (max) return max;
    return defaultDates.max;
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  handleTimeChange = value => {
    const { onTimeChange, onValidate, required, name } = this.props;

    onTimeChange(value);

    if (!required) return;

    if (this.timeIsValid(value)) {
      this.setState({ valid: true });
      onValidate(name, true);
    } else {
      this.setState({ valid: false });
      onValidate(name, false);
    }
  };

  timeIsValid = value => {
    return value !== null;
  };

  render() {
    const { valid } = this.state;
    const { className, value, disabled, name } = this.props;

    return (
      <div
        className={`${className} ${!valid ? 'invalid' : ''}`}
      >
        <DateTimePicker
          min={this.getTimeMin()}
          max={this.getTimeMax()}
          value={value}
          onChange={inputValue => this.handleTimeChange(inputValue)}
          date={false}
          disabled={disabled}
          name={name}
        />
        {!this.state.valid && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}


const StyledTimePicker = styled(TimePicker)`
  margin: 0 0 14px;

  .rw-popup {
    padding: 8px 0;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.12);

    &-container {
      max-width: 90vw;
    }

    &-transition {
      transition: none !important;
    }
  }

  .rw-popup-container {
    max-width: 90vw;
  }

  .rw-widget-picker {
    display: inline-table;
    padding: 5px 0 5px 12px;

    input[type="text"] {
      padding: 0;
      border: 0;
      box-shadow: none !important;
      height: auto;
      margin: 0;
      display: block;
    }

    .rw-select {
      border: 0;

      button {
        appearance: none;
        width: 35px;
        color: #000;

        .rw-i-clock-o {
          font-size: 16px;
        }

        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      &-bordered {
        &:hover,
        &:active {
          box-shadow: none;
          background: none;
        }
      }
    }
  }

  .rw-list {
    .rw-list-option {
      font-size: 14px;
      transition: background-color 250ms, border-color 250ms;

      &:hover {
        border-color: transparent;
        background-color: ${props => props.settings.theme.css.global.colors.menuHover};
      }
    }

    .rw-state-focus {
      color: #fff;
      border-color: ${props => props.settings.theme.css.global.colors.primary};
      background-color: ${props => props.settings.theme.css.global.colors.primary};
      transition: background-color 250ms, border-color 250ms;

      &:hover {
        border-color: ${props => props.settings.theme.css.global.colors.primaryHover};
        background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .rw-state-disabled,
  input[disabled].rw-input,
  .rw-state-disabled>.rw-widget-container,
  .rw-state-disabled .rw-select-bordered {
    cursor: default;
  }

  &.invalid {
    .rw-widget-picker {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }
  }

  .validation_error {
    font-size: 11px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
`;

const StyledTimePickerWithSettings = withSettings(StyledTimePicker);

export { StyledTimePickerWithSettings as TimePicker };
