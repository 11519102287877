import React from 'react';
import styled from 'styled-components';

import { withSettings } from '../components';

export const LandingSectionHeader = ({ heading, content, expanded, onViewAll }) => (
  <>
    <H2>
      {heading}
      {onViewAll && (
        <ViewAllButton onClick={onViewAll}>
          {expanded ? '- view less' : '+ view all'}
        </ViewAllButton>
      )}
    </H2>
    {content && <P dangerouslySetInnerHTML={{ __html: content }} />}
  </>
);

const H2 = styled.h2`
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;
  font-size: 28px;
  margin: 0;
`;

const P = styled.p`
  color: #83817b;
  margin: 0.75rem 0 0;
`;

const ViewAllButtonWithoutSettings = styled.span`
  color: #83817b;
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  transition: color 250ms;
  cursor: pointer;

  &:hover {
    color: ${props => props.settings.theme.css.global.pspanh1h2h3h4h5_color};
  }
`;
const ViewAllButton = withSettings(ViewAllButtonWithoutSettings);
