import React from 'react';
import styled, { css } from 'styled-components';

import { FieldErrorMessage as ErrorMessage, FAIcon } from '../../components';
import { useSettings } from '../../../hooks';


const InputField = ({
  type = 'text',
  name,
  value = '',
  placeholder,
  onChange,
  onFocus,
  onBlur,
  errorMessage,
  valid = true,
  max,
  min,
  disabled,
  icon,
  id = ''
}) => {
  const { colors } = useSettings();
  const handleInputChange = ({ target: { value: val } }) => onChange(name, val);

  return (
    <Container icon={icon}>
      {icon && <FAIcon name={icon} />}
      <Input
        id={id}
        className="input_field"
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        valid={valid}
        max={max}
        min={min}
        colors={colors}
        disabled={disabled}
      />
      {!valid && <ErrorMessage>{errorMessage || `Enter a valid ${name}.`}</ErrorMessage>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  ${({ icon }) => icon && css`
    input {
      padding-left: 45px !important;
    }

    svg {
      position: absolute;
      left: 26px;
      top: 17px;
      transform: translateX(-50%);
    }
  `}
`;

const Input = styled.input`
  padding: 13px 20px 11px !important;
  border: 1px solid #ceccc7 !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  line-height: 1.8 !important;
  transition: border-color 250ms !important;
  box-shadow: none !important;

  &:not([disabled]):hover,
  &:focus {
    ${({ valid, colors: { error } }) => valid ? css`
      border-color: #000 !important;
    ` : css`
      border-color: ${error} !important;
    `}
  }

  ${({ valid, colors: { error } }) => !valid && css`
    border-color: ${error} !important;
    margin-bottom: 0 !important;
  `}
`;

export { InputField, Input as InputElement };
