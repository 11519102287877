import React from 'react';
import styled from 'styled-components';

import { Button } from './';

const defaultButtons = [
  { label: 'No', value: false },
  { label: 'Yes', value: true }
];

const ButtonToggle = ({ className, labels, activeButton, onToggleButton, halfWidth, capitalize, disabled }) => {
  const handleSelectTab = value => onToggleButton(value);
  const buttonLabels = labels && Array.isArray(labels) ? labels : defaultButtons;

  return (
    <div className={['button_toggle', className].join(' ')}>
      {buttonLabels.map(button => (
        <Button
          key={button.value}
          buttonStyle={button.value === activeButton ? 'primary' : 'secondary'}
          handleClick={() => handleSelectTab(button.value)}
          halfWidth={halfWidth}
          capitalize={capitalize}
          disabled={disabled}
        >{button.label}</Button>
      ))}
    </div>
  );
}

const StyledButtonToggle = styled(ButtonToggle)`
  & > .button {
    border-radius: 0;
    padding: 8px !important;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
`;

export { StyledButtonToggle as ButtonToggle };
