import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ProfileViewQuestions = ({ className, questions }) => {
  if (!questions || questions.length === 0) {
    return null
  }

  const displayableQuestions = questions.filter(question => question.display);

  if (!displayableQuestions || displayableQuestions.length === 0) {
    return null;
  }

  const displayableAndAnsweredQuestions = displayableQuestions.filter((question) => {
    if (question.choices) {
      const selectedChoices = question.choices.filter(choice => choice.value === true);
      return (selectedChoices.length > 0);
    } else {
      return (question.value && question.value.trim().length > 0);
    }
  });

  if (!displayableAndAnsweredQuestions || !displayableAndAnsweredQuestions.length) return null;

  return (
    <div className={className}>
      {displayableAndAnsweredQuestions.map((question, index) => {
        const choices = (question.choices ? question.choices : [{ value: true, label: question.value }]);
        const selectedChoices = choices.filter(choice => choice.value);

        return (
          <div key={index} className="question-container">
            <Question>{question.viewLabel}</Question>
            <ul>
              {selectedChoices.map((choice, qIndex) => (
                <QuestionAnswer key={qIndex}>
                  {choice.id === 'other' && question.otherOptional
                    ? (question.otherAnswer || choice.label)
                    : (choice.label || choice.value)}
                </QuestionAnswer>
              ))}
            </ul>
          </div>
        );
      })
    }
    </div>
  )
};

ProfileViewQuestions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.array
};

const ProfileViewQuestionsStyled = styled(ProfileViewQuestions)`
  box-shadow: 0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06);
  background-color: #fff;
  padding: 35px 60px 60px;
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;

  .question-container {
    width: 50%;

    :nth-child(odd) {
      padding-right: 40px;
    }

    :nth-child(2n) {
      padding-left: 20px;
    }

    @media (max-width: 850px) {
      width: 100%;

      :nth-child(odd) {
        padding-right: 0px;
      }

      :nth-child(2n) {
        padding-left: 0px;
      }
    }
  }

  ul {
    padding-left: 25px;
  }
`;

const Question = styled.h2`
  font-size: 16px;
  margin: 25px 0 10px;
  line-height: 1.6;
`;

const QuestionAnswer = styled.li`

`;

export default ProfileViewQuestionsStyled;
