import React, { useContext } from 'react';
import { SelectBox, withSettings } from '../../components';
import { defaultSelectValue } from '../Filters';

import { SessionContext } from '../../../Context';
import { appendTimezoneOffset } from '../../../libs';


const TimezoneSelect = ({ name, handleFilterChange, reset, settings: { global: { display_timezone_offsets } } }) => {
  const { timezones } = useContext(SessionContext);

  return (
    <SelectBox
      defaultValue={defaultSelectValue}
      options={[
        defaultSelectValue,
        ...timezones.map(zone => ({
          id: zone.name,
          label: appendTimezoneOffset(zone, display_timezone_offsets),
          value: zone.name,
        }))
      ]}
      onOptionSelect={value => handleFilterChange(name, value)}
      refreshOnChange={[reset.area, reset.shouldReset]}
    />
  );
};

const TimezoneSelectWithSettings = withSettings(TimezoneSelect);
export { TimezoneSelectWithSettings as TimezoneSelect };
