import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { EventList, EventHero, withSettings, withClient } from '../components';
// import { EVENT_ORDER } from '../../enums';
import { stringInsert, isSite } from '../../libs';
import { usePaginatedEvents } from '../../hooks';


const getPageTitle = (tag, location, copy, archive) => {
  const customMenuItems = JSON.parse(copy.menu.additional_area_menu_items || '[]');
  const tagTitle = tag.toLowerCase().replace(/-/g, ' ');
  const archiveTitle = copy.landing.archive_page_title || 'Archive: %1$s';

  if (Array.isArray(customMenuItems)) {
    const item = customMenuItems.find(i => i.route === location.pathname);
    if (item && item.title) return archive
      ? stringInsert(archiveTitle, item.title)
      : item.title;
  }

  return archive ? stringInsert(archiveTitle, tagTitle) : tagTitle;
};

const formatTag = (tag, settings) => (
  (isSite(settings, 'climatefest') ? tag.replace(/-&-/g, '&') : tag)
);

const EventListTagPage = ({ className, settings, client, location, archive, match: { params: { tag: urlTag } } }) => {
  const { copy, theme: { assets: { images } } } = settings;
  const title = getPageTitle(decodeURIComponent(urlTag), location, copy, archive);
  const altText = settings.theme.site.tag_events_page_hero_alt;
  const tag = formatTag(decodeURIComponent(urlTag), settings);
  
  const [loadingMore, setLoadingMore] = useState(false);
  const variables = { tag, scheduledAtRelativeToNow: archive ? 'PAST' : 'UPCOMING' /*, order: EVENT_ORDER.AREA_CODE*/ };

  const { loading: eventsLoading, /*error,*/ fetchMore, pagination } = usePaginatedEvents(client, { variables });
  const { events, pageInfo, totalEventsCount, hasMoreEvents, numberOfItems } = pagination;

  // if (error) console.error(error);

  const hasEvents = () => Array.isArray(events) && events.length > 0;

  const handleLoadMoreEvents = () => {
    setLoadingMore(true);

    return fetchMore({
      variables: { ...variables, numberOfItems, cursor: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEvents = fetchMoreResult.events;
        const prevEvents = previousResult.events;
        pageInfo.endCursor = newEvents.pageInfo.endCursor;

        return {
          events: {
            pageInfo,
            edges: [...prevEvents.edges, ...newEvents.edges],
            __typename: prevEvents.__typename,
          }
        };
      }
    }).then(() => setLoadingMore(false));
  };

  return (
    <div className={className}>
      <EventHero 
        id='tags_view_header' 
        title={title} 
        headerImg={images.tag_default_header} 
        headerAltText={altText}
        capitalize={true}
      />
      <p dangerouslySetInnerHTML={{ __html: copy.tags.page_description }} />
      <i className="divider" />
      <div>
        {eventsLoading || hasEvents() ? (
          <EventList
            events={events}
            loading={eventsLoading}
            loadingMore={loadingMore}
            showArea={true}
            hasMoreEvents={hasMoreEvents}
            totalEventsCount={totalEventsCount}
            onLoadMore={() => handleLoadMoreEvents()}
            archive={archive}
          />
        ) : (
          <div className="no-items">
            {stringInsert(copy.tags.no_events_found_with_tag, tag)}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledEventListTagPage = styled(EventListTagPage)`
  & > .header {
    position: relative;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    padding: 170px 0;

    h1 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      margin: 0;
      text-transform: capitalize;
    }
  }
  & > p {
    max-width: 1100px;
    padding: 0 30px;
    text-align: center;
    margin: 60px auto 40px;
    font-size: 14px;

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
        text-decoration: none;
      }
    }
  }
  .no-items {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  .area_banner__container {
    margin-top: 40px;
  }
`;

const EventListTagPageWithRouter = withRouter(StyledEventListTagPage);
const EventListTagPageWithSettings = withSettings(EventListTagPageWithRouter);
const EventListTagPageWithClient = withClient(EventListTagPageWithSettings);

export { EventListTagPageWithClient as EventListTagPage };
