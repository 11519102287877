import React from 'react';
import styled from 'styled-components';
import { Button } from '../components';
import { withSettings } from '../components';

const EventSocialShare = (props) => {
  const { className, shortUrl } = props;
  const { settings: { copy } } = this.props;

  return (
    <div className={className}>
      <h3>{copy.manage.share}</h3>
      <div className="row">
        <div className="social-accounts-buttons">
          <Button buttonStyle="facebookShare"></Button>
          <Button className="twitter-share-button" buttonStyle="twitterShare"></Button>
        </div>
        <div className="bitly-button">
          <Button
            className="bitly-share-button"
            link={shortUrl}
            buttonStyle="clearBackground"
          >{shortUrl}
          </Button>
        </div>
      </div>
    </div>
  );
}

const StyledEventSocialShare = styled(EventSocialShare)`
  text-align: center;

  & h3 {
    font-size: 14px;
    margin: 30px 0;
  }

  & .social-accounts-buttons {
    float: left;
  }

  & .twitter-share-button {
    margin-left: 20px;
  }

  & .bitly-button {
    float: right;
  }

  & .bitly-share-button {

  }
`;

const StyledEventSocialShareWithSettings = withSettings(StyledEventSocialShare);

export { StyledEventSocialShareWithSettings as EventSocialShare };