import React from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';

const LoaderGeneral = ({ className }) => {
  const renderContent = () => {
    const lines = (window.innerHeight * 0.7) / 22;
    const content = [];
    for (let i = 0; i < lines; i++) {
      content.push(<Placeholder.Line key={i}/>);
    }
    return content;
  };

  return (
    <div className={`${className} semantic`}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          {renderContent()}
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
  );
};


const StyledLoaderGeneral = styled(LoaderGeneral)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px !important;
  div.segment {
    color: transparent;
    height: 70vh;
  }
  .ui.placeholder,
  .ui.placeholder .image.header:after,
  .ui.placeholder .line,
  .ui.placeholder .line:after,
  .ui.placeholder > :before {
    background-color: #fbfaf9 !important;
  }
`

export { StyledLoaderGeneral as LoaderGeneral };
