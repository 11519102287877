import {
  minifyHTML,
  generators,
  getImageUrlFromPhotoObject,
  getLocationWithArea,
  getScheduledAtDate,
  getEventTimeSpan,
  getEventEtPtTimes,
  isPublicEvent,
  formatPrice
} from './';


export const fallbackCopyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copyToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};


export const getCardEmbedHtml = (settings, event, mode) => {
  const images = settings.theme.assets.images;
  const bgImageUrl = event.cover
    ? (event.cover.mediumUrl ? event.cover.mediumUrl : event.cover.originalUrl)
    : images.default_cover;

  const host = event.eventHosts && event.eventHosts.edges && event.eventHosts.edges
    .map(({ node }) => node)
    .find(({ creator }) => creator);

  const profile = host && host.profile;

  const hasCommunityImg = event.communityDinner && event.partnerOrganizationLogo;
  const hasHostImg = profile && profile.avatar && profile.avatar.photo;

  const avatarStyle = profile ? `
    margin: auto !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    border: 1px solid #fff;
    background-size: cover;
    background-position: center;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(2,3,4,0.15);
    ${hasCommunityImg || hasHostImg ? `
      background-image: url(${getImageUrlFromPhotoObject(
        hasHostImg ? profile.avatar.photo : event.partnerOrganizationLogo,
        'medium',
        ''
      )});
    ` : `background: ${generators.randomColor(settings)}`}
  ` : '';

  const avatar = hasCommunityImg || hasHostImg ? `
    <div style="${avatarStyle}"></div>
  ` : `
    <table style="${avatarStyle}" width="60" height="60">
      <tr>
        <td style="vertical-align: middle; color: #fff;">
          ${(profile.firstName || '').charAt(0).toUpperCase()}${(profile.lastName || '').charAt(0).toUpperCase()}
        </td>
      </tr>
    </table>
  `;

  const location = event.areaOther
    ? event.areaOther
    : getLocationWithArea(event);

  const html = minifyHTML`
    <div
      class="ot_card"
      style="
        display: inline-block;
        vertical-align: top;
        padding: 15px;
        width: 100%;
        max-width: 300px;
      "
    >
      <table
        style="
          font-family: 'Open Sans', sans-serif;
          text-align: center;
          position: relative;
          width: 100%;
          min-height: 500px;
          box-shadow: 0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06);
          border-radius: 5px;
          transition: box-shadow 250ms;
          border-collapse: collapse;
        "
      >
        <tr>
          <td
            style="
              background-image: url(${bgImageUrl});
              position: relative;
              z-index: 1;
              border-radius: 5px 5px 0 0;
              height: 170px;
              padding: 15px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              vertical-align: top;
            "
          >
            ${event.virtual ? `
                <div
                  style="
                    font-size: 9px;
                    color: #fff !important;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    padding: 3px 0;
                    margin-bottom: 40px;
                    background-color: rgba(118,210,118,0.9);
                  "
                >
                    ${settings.copy.event.virtual_banner_text}
                </div>
            ` : ''}
          </td>
        </tr>
        <tr>
          <td
            style="
              vertical-align: top;
              padding: 20px 15px 20px;
              background-color: #fff;
              border-radius: 0 0 5px 5px;
              border: 1px solid #e9e9e9;
              border-top: none;
              position: relative;
              text-align: center;
            ">
            ${avatar}
            <p style="font-size: 12px; margin: 12px 0 0;">
              <span style="color: #8d1b37;font-weight: bold;">
                ${profile.firstName || ''}
              </span> is hosting
            </p>

            <h4
              style="
                font-size: 16px;
                margin: 10px 0 0;
                word-break: break-word;
                line-height: 1.4;
              "
            >${event.title}</h4>

            <i
              style="
                display: block;
                width: 50px;
                margin: 20px auto;
                height: 1px;
                background-color: #e5e5e5;
              "
            ></i>

            <ul
              style="
                list-style-type: none;
                padding: 0;
                margin: 0 0 15px;
                font-size: 12px;
                color: #4d4d4d;
              "
            >
              <li style="margin-bottom: 6px">
                <svg style="overflow: visible;width: 0.875em;margin-right: 6px;color: #8c8c8c;align-self: center;" focusable="false" data-prefix="far" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
                ${getScheduledAtDate(event)}
              </li>
              <li style="margin-bottom: 6px">
                <svg style="overflow: visible;width: 1em;margin-right: 6px;color: #8c8c8c;align-self: center;" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                ${mode === 'general' ? getEventTimeSpan(event) : getEventEtPtTimes(event)}
              </li>
              ${mode === 'general' && `
                <li style="margin-bottom: 6px">
                  <svg style="overflow: visible;width: 0.75em;margin-right: 6px;color: #8c8c8c;align-self: center;" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                  ${location}
                </li>
                <li style="margin-bottom: 6px">
                  <svg style="overflow: visible;width: 1em;margin-right: 6px;color: #8c8c8c;align-self: center;" focusable="false" data-prefix="fas" data-icon="globe-americas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z"></path></svg>
                  ${event.timezone}
                </li>
              `}
            </ul>

            ${mode === 'general' && (
              isPublicEvent(event) ? `
                <p
                  style="
                    font-size: 16px;
                    margin: 15px 0 60px;
                    color: #8d1b37;
                  "
                >${event.pricePerPerson ? formatPrice(event.pricePerPerson) : 'Free'}</p>
              ` : '<div style="height: 31px; margin-top: 35px;"></div>'
            )}

            <a
              href="${event.shortUrl}"
              target="_blank"
              style="
                display: block;
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                text-decoration: none;
                margin: 0;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                padding: 13px 10px;
                max-height: 42px;
                font-size: 12px;
                font-style: normal;
                background-color: #8d1b37;
                border-color: #8d1b37;
                color: #fff;
                transition: background-color 250ms;
                line-height: 1;
                margin-top: ${mode === 'general' ? '20' : '10'}px;
              "
            >
                ${settings.copy.event.about_title}
            </a>
          </td>
        </tr>
      </table>
    </div>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
    <style>.ot_card, .ot_card * {box-sizing: border-box;}</style>
  `;

  return html;
};
