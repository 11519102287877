import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Logo
} from '../components';

import { withSettings } from '../components';

const EventViewOrganization = (props) => {
  const { className } = props;
  const { settings: { copy } } = props;

  return (
    <Card className={className}>
      <Logo layout="vertical" />
      <p dangerouslySetInnerHTML={{ __html: copy.event.about_ot_card_body }} />
    </Card>
  );
}

const StyledEventViewOrganization = styled(EventViewOrganization)`
  .logo {
    width: 147px;
    margin: 0 auto 35px;
  }

  p {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
`;

const StyledEventViewOrganizationWithSettings = withSettings(StyledEventViewOrganization);

export { StyledEventViewOrganizationWithSettings as EventViewOrganization };
