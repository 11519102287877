import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { breakpoints } from '../../styles';
import { isPast } from '../../libs/eventHelpers';
import { ManageEventsDetailsChangeParticipation } from './';
import { RESERVATION_FILTER } from '../../enums';

const ManageEventsDetailsAdditionalGuests = (props) => {
  const { className, enumerated, event, reservation, updateReservationParticipation } = props;

  const guests = reservation.additionalGuests;

  const actions = (additionalGuest) => {
    const isPastEvent = isPast(event);

    if (isPastEvent && enumerated === RESERVATION_FILTER.ATTENDING) {
      return (
        <ManageEventsDetailsChangeParticipation
          reservation={reservation}
          updateReservationParticipation={updateReservationParticipation}
          participated={additionalGuest.participated}
          additionalGuestEmail={additionalGuest.email}
        >
        </ManageEventsDetailsChangeParticipation>
      );
    } else {
      return null;
    }
  }

  return (
    <ul className={className}>
      {guests.map((item, index) => (
        <li key={index}>
          <table className="">
            <thead />
            <tbody>
              <tr>
                <td className="spacer begin" />

                <td className="name">
                  <strong>{`Guest ${index + 1}:`}</strong>
                  <span>{item.name}</span>
                </td>

                <td className="email">
                  <a href={`mailto:${item.email}`}>
                    {item.email}
                  </a>
                </td>

                <td className="icons">
                  
                </td>

                <td className="host_actions">
                  {actions(item)}
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      ))}
    </ul>
  );
}


const StyledManageEventsDetailsAdditionalGuests = styled(ManageEventsDetailsAdditionalGuests)`
  margin: 5px 0 0;

  @media (${breakpoints.manageEventDetailsTablet}) {
    margin: 10px 0 0;
  }

  & > li {
    position: relative;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 13px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 75px;
      right: 0;
      height: 1px;
      background-color: ${props => props.settings.theme.css.global.colors.borderColor};
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin: 0;
      padding: 0;

      td {
      &.avatar_container {
        width: 75px;

        .avatar {
          position: relative;
          transform: translateY(2px);
          margin: 0;
        }

        a {
          display: block;
        }
      }

      &.name {
        width: calc(35% - 75px);
      }

      &.email {
        width: 30%;
        padding-left: 10px;
      }

      &.icons  {
        width: 15%;
        padding-left: 10px;

        svg {
          padding-top: 2px;
          margin-right: 8px;
          font-size: large;
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      &.host_actions {
        width: 20%;
        font-size: 13px;

        .label {
          margin-bottom: 0;
        }

        .dropdown_menu {
          min-width: 200px;
        }
      }

      @media (${breakpoints.manageEventDetailsTablet}) {
        display: block;
        padding-bottom: 5px;

        &.avatar_container {
          position: absolute;
          top: 0;
          left: 0;
        }

        &:not(.avatar_container) {
          width: 100% !important;
          padding-left: 75px;
        }

        &.host_actions {
          padding-bottom: 0;
        }
      }
    }

    .spacer {
      &.begin {
        width: 75px;
      }
      &.end {
        width: 35%;
      }
    }

    a {
      color: ${props => props.settings.theme.css.global.colors.primary} !important;
      font-weight: 600 !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}`;

const ManageEventsDetailsAdditionalGuestsEithSettings = withSettings(StyledManageEventsDetailsAdditionalGuests);

export { ManageEventsDetailsAdditionalGuestsEithSettings as ManageEventsDetailsAdditionalGuests }
