import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  EventLocation,
  EventNourishment,
  EventPotluck,
  FAIcon,
  withSettings
} from '../components';

import {
  EVENT_TYPE,
  EVENT_STATE
} from '../../enums';

import { breakpoints } from '../../styles';
import { getEventCoverImageUrl } from '../../libs';

class ManageEventsHostingCard extends React.Component {
  state = { sponsorshipsEnabled: null };

  componentDidMount = () => {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features } } = this.props;
    const sponsorshipsEnabled = features && (features.sponsorships === true || features.sponsorships === 'true');

    this.setState({ sponsorshipsEnabled: sponsorshipsEnabled });
  }

  render() {
    const { className, event: { node: event }, active, settings, showArea, showEventYear } = this.props;
    const { sponsorshipsEnabled } = this.state;
    const { LABEL } = EVENT_TYPE.FIELDS;
    const { copy } = settings;
    const images = settings.theme.assets.images;

    return (
      <div className={className}>
        <div className="column col-one">
          <strong>{copy.manage.event_table_header}</strong>
          <div>
            <div
              className="event_cover"
              style={{
                backgroundImage: `url(${getEventCoverImageUrl(event, 'medium', images.default_cover)})`
              }}
            />
            <p>
              <Link to={`/events/${event.uuid}`}>
                <strong>{event.title}</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="column col-two">
          <strong>{copy.manage.details_table_header}</strong>
          <EventLocation event={event} showArea={showArea} showCatered={event.catered} showEventYear={showEventYear} />
          {event.potluckItems.totalCount > 0
            ? <EventPotluck event={event} />
            : null
          }
        </div>

        <div className="column col-three">
          <strong>{copy.manage.type_table_header}</strong>
          <p>{`${EVENT_TYPE.getField(LABEL, event.eventType)}${event.virtual ? ' - Virtual' : ''}`}</p>
        </div>

        {event.state !== EVENT_STATE.DRAFT && event.state !== EVENT_STATE.PENDING_PUBLISH_APPROVAL ? (
            <div className="column col-four">
              <strong>{copy.manage.attendance_table_header}</strong>
              <p><strong>{event.numberOfGuestsMax} Max Guests</strong></p>
              <div>
                <p>{event.reservations.guestsAccepted} Attending</p>
                {event.eventType === EVENT_TYPE.MANUAL_APPROVAL
                  ? <p className="red">{event.reservations.guestsPending} Pending</p>
                  : null
                }
              </div>
              <p><strong>{event.numberOfGuestsMax - event.reservations.guestsAccepted} Available</strong></p>
            </div>
          ) : null
        }

        {sponsorshipsEnabled && (
          <div className="column col-five">
            <strong>{copy.manage.nourishment_table_header}</strong>
            <EventNourishment event={event}/>
          </div>
        )}

        <div>
          <p className="more">
            <Link to={`/manage/${active.toLowerCase()}/${event.uuid}`}>
              <strong className="red">More</strong>
              <strong className="red go"><FAIcon name="chevron-right"/></strong>
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

const StyledManageEventsHostingCard = styled(ManageEventsHostingCard)`
  background-color: white;
  box-shadow: ${props => props.settings.theme.css.global.card.shadowLight};
  padding: 15px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  @media (${breakpoints.manageEventsListMobile}) {
    display: block;
    text-align: center;
    padding: 40px 20px;

    .event_potluck {
      margin-top: 10px;
    }
  }

  * {
    align-self: center;
  }

  & > div {
    margin-right: 5px;

    p {
      margin-bottom: 0;
    }

    // div {
    //   margin-top: 3px;
    //   margin-bottom: 3px;
    // }
  }

  .col-one {
    width: 28%;

    & > div {
      img {
        width: 110px;
        display: inline-block;
        veritcal-align: middle;
      }

      .event_cover {
        display: inline-block;
        vertical-align: middle;
        width: 90px;
        height: 82px;
        border-radius: 4px;
        background-size: cover;
        background-position: center;

        @media (${breakpoints.manageEventsListMobile}) {
          margin: auto;
          width: 200px;
          height: 150px;
          margin-bottom: 10px;
        }
      }

      p {
        width: calc(100% - 105px);
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;

        @media (${breakpoints.manageEventsListMobile}) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }

  .col-two {
    width: 20%;
  }

  .col-three {
    width: 10%;

    p {
      @media (${breakpoints.manageEventsListMobile}) {
        margin: 5px 0;
      }
    }
  }

  .col-four {
    width: 11%;

    p {
      @media (${breakpoints.manageEventsListMobile}) {
        margin: 5px 0;
      }
    }
  }

  .col-five {
    width: 10%;
  }

  .column {
    @media (${breakpoints.manageEventsListMobile}) {
      width: 100% !important;
      margin-bottom: 30px;
    }
  }

  .red {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  .more {
    margin-bottom: 4px;
  }

  .go {
    font-size: large;
    position: relative;
    top: 3px;
    left: 6px;
    margin-bottom: 3px;
  }
`;

const ManageEventsHostingCardWithSettings = withSettings(StyledManageEventsHostingCard);

export { ManageEventsHostingCardWithSettings as ManageEventsHostingCard };
