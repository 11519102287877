const helpers = {
  objAreEqual: (a, b) => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) return false;

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) return false;
    }

    return true;
  },

  arraysOfObjAreEqual: (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (!helpers.objAreEqual(a[i], b[i])) return false;
    }

    return true;
  },

  arraysAreEqual: (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  },

  getObjArrayIndex: (arr, obj) => {
    for (let i = 0; i < arr.length; i++) {
      if (helpers.objAreEqual(arr[i], obj)) return i;
    }

    return false;
  },

  indexOfObj: (arr, prop, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) return i;
    }

    return -1;
  },

  handlify: string => {
    return string.replace(' ', '-').toLowerCase();
  },

  getUniqueEventsFromEdges: (edges) => {
    const unique = edges.map(e => e.node.id)
                        .map((e, i, final) => final.indexOf(e) === i && i)
                        .filter(e => edges[e]).map(e => edges[e]);

    return unique;
  },

  shuffleArray: (array, shufflerNumber) => {
    const newArray = [...array];

    for (let i = newArray.length - 1; i > 0; i--) {
      const base = shufflerNumber / 1000 || Math.random();
      const j = Math.floor(base * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    return newArray;
  },

  getRandom: array => {
    if (Array.isArray(array)) {
      return array[array.length * Math.random() | 0];
    }

    return false;
  },

  breakArrayIntoPages: (array, numberPerPage = 2) => {
    let count = 1;
    let page = [];
    const pages = [];

    array.forEach((el, i) => {
      if (count <= numberPerPage) {
        page.push(el);
        count++;

        if (i === array.length - 1) pages.push(page);
      } else {
        pages.push(page);
        page = [el];
        count = 1;
      }
    });

    return pages;
  },

  snakeCaseToCamelCase: snake => snake.replace(
    /([-_][a-z])/g,
    group => group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  ),

  formatUrlParams: (search, excludeLogin) => {
    let params = search.replace('?', '').split('&');

    if (excludeLogin) params = params.filter(p => !p.includes('login='));

    return params.map(p => ({
      filter: p.split('=')[0] === 'event_category' ? 'event_subtype' : p.split('=')[0],
      val: p.split('=')[1] === 'true' ? true : p.split('=')[1]
    }));
  },

  getUrlParamFilters: (search, filters, excludeLogin = true) => {
    let urlFilters = helpers.formatUrlParams(search, excludeLogin);
    urlFilters = urlFilters.filter(f => f.filter in filters);

    return urlFilters.length ? urlFilters.reduce((filtersObj, f) => {
      filtersObj[f.filter] = f.val;
      return filtersObj;
    }, {}) : false;
  },

  queryString: (search, params) => {
    const searchParams = search && new URLSearchParams(search);
    const query = {};

    if (Array.isArray(params) && params.length && searchParams) {
      params.forEach(param => {
        query[param] = searchParams.get(param);
      });
    } else if (params && searchParams) {
      query[params] = searchParams.get(params);
    }
    
    return { params: searchParams, ...query };
  },

  stripLeadingSlash: str => str.replace(/^\//, '')

};

const {
  objAreEqual,
  arraysOfObjAreEqual,
  arraysAreEqual,
  getObjArrayIndex,
  indexOfObj,
  handlify,
  getUniqueEventsFromEdges,
  shuffleArray,
  getRandom,
  breakArrayIntoPages,
  snakeCaseToCamelCase,
  formatUrlParams,
  getUrlParamFilters,
  queryString,
  stripLeadingSlash
} = helpers;

export {
  helpers,
  objAreEqual,
  arraysOfObjAreEqual,
  arraysAreEqual,
  getObjArrayIndex,
  indexOfObj,
  handlify,
  getUniqueEventsFromEdges,
  shuffleArray,
  getRandom,
  breakArrayIntoPages,
  snakeCaseToCamelCase,
  formatUrlParams,
  getUrlParamFilters,
  queryString,
  stripLeadingSlash
};
