import React from 'react';
import { withApollo } from '@apollo/react-hoc';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { withSettings, SponsorsList, Loader } from '../components';
import { useSponsors } from '../../hooks';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import { NATIONAL_SPONSORS_ID } from './SponsorsList';


const SponsorsPage = ({ className, settings: { copy }, client }) => {
  const { sponsors, error, loading, sponsorAreas } = useSponsors(client);

  if (!sponsors || loading) return <Loader />;
  if (error) return <Redirect to="/" />;

  const getAnchorLink = area => {
    const id = area.label.toLowerCase().replace(' ', '_');
    // anchor to #national_sponsors section if area has no sponsors
    return `#${area.sponsored ? id : NATIONAL_SPONSORS_ID}`;
  };

  const areasWithSponsors = sponsorAreas.filter(a => a.sponsored).map(a => a.label);

  return (
    <div className={className}>
      <div className="card">
        <Title>{copy.sponsors && copy.sponsors.sponsors_page_title ? copy.sponsors.sponsors_page_title : 'Sponsors'}</Title>
        {copy.sponsors && copy.sponsors.sponsors_page_description && (
          <Description dangerouslySetInnerHTML={{ __html: copy.sponsors.sponsors_page_description }} />
        )}
        {sponsorAreas.length > 0 && (
          <AreaLinkList>
            {sponsorAreas.map((area, i) => (
              <li key={i}>
                <AnchorLink href={getAnchorLink(area)} offset="120">{area.label}</AnchorLink>
                {i < sponsorAreas.length - 1 && <span className="bull">&bull;</span>}
              </li>
            ))}
          </AreaLinkList>
        )}
        <hr className="separator" />
        <SponsorsList sponsors={sponsors} sponsorAreas={areasWithSponsors} />
      </div>
    </div>
  );
};

const StyledSponsorsPage = styled(SponsorsPage)`
  padding: 0 20px;

  .card {
    margin: 60px auto;
    text-align: center;
    padding: 60px 5%;
    background-color: #fff;
    box-shadow: ${props => props.settings.theme.css.global.card.shadow};
    border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    max-width: 1192px;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 30px;
`;

const DescriptionWithoutSettings = styled.p`
  font-size: 14px;
  margin-bottom: 25px;

  a {
    font-size: 15px;
    color: ${props => props.settings.theme.css.global.colors.primary};

    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }
`;
const Description = withSettings(DescriptionWithoutSettings);

const AreaLinkListWithoutSettings = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  max-width: 900px;

  li {
    position: relative;
    display: inline-block;
    margin: 5px 10px;

    a {
      font-size: 15px;
      color: ${props => props.settings.theme.css.global.colors.primary};

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }

    .bull {
      display: inline-block;
      position: relative;
      transform: translateX(10px);
    }
  }
`;
const AreaLinkList = withSettings(AreaLinkListWithoutSettings);

const SponsorsPageWithSettings = withSettings(StyledSponsorsPage);
const SponsorsPageWithApollo = withApollo(SponsorsPageWithSettings);
export { SponsorsPageWithApollo as SponsorsPage };
