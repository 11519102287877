import React from 'react';
import styled from 'styled-components';
import { EventTypeIcon } from '../../components';


export const EventTypeIcons = ({ eventData: { alcoholPolicy, dietaryRestrictions, dressCodeName } }) => (
  <IconList>
    {alcoholPolicy && <EventTypeIcon name={alcoholPolicy} type="alcohol_policy" />}
    {dietaryRestrictions && dietaryRestrictions.map((diet, i) => (
      <EventTypeIcon key={i} name={diet} type="dietary_restrictions" />
    ))}
    {dressCodeName && <EventTypeIcon name={dressCodeName} type="dress_codes" />}
  </IconList>
);


const IconList = styled.div`
  margin-bottom: -10px;
  .type_icon {
    margin: 0 20px 10px 0;
  }
  span {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 10px;
  }
`;
