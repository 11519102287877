import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from '../../styles';

import { TweenMax, Power3 } from 'gsap';

import * as userJourney from '../../libs/userJourney';


const JourneyMadlibLanding = ({ className, startJourney, children }) => {
  const name = userJourney.getItem('name');
  const contentRef = useRef(null);
  const nameContainerRef = useRef(null);
  const started = useRef(false);

  useEffect(() => {
    if (contentRef.current && !started.current) {
      const nameContainer = contentRef.current.querySelector('.username');
      if (nameContainer) nameContainerRef.current = nameContainer;

      TweenMax.to(contentRef.current, 1.5, {
        opacity: 1,
        y: -5,
        delay: 1,
        ease: Power3.easeOut
      });

      setTimeout(startJourney, 5000);
      started.current = true;
    }
  }, [contentRef, startJourney, started]);

  const UserName = () => {
    if (nameContainerRef.current && name) {
      return ReactDOM.createPortal(
        <span dangerouslySetInnerHTML={{ __html: name }} />,
        nameContainerRef.current
      );
    }
    return null;
  }

  return (
    <div className={className} ref={contentRef}>
      <UserName />
      {children}
    </div>
  );
};

const StyledJourneyMadlibLanding = styled(JourneyMadlibLanding)`
  position: relative;
  display: block;
  width: calc(100% - 60px);
  width: 80%;
  max-width: 1600px;
  z-index: 2;
  margin: auto;
  // text-align: center;
  // opacity: 0;
  top: 5px;
  opacity: 0;
  color: #fff;

  ${media.md`
    width: 90%;
  `}

  ${media.sm`
    width: 100%;
    padding: 0 30px;
  `}

  @media (max-width: 840px) {
    margin-bottom: 100px;
  }

  p {
    span {
      color #fff;
      text-shadow: 0 0 6px rgba(0,0,0,0.8);
      font-family: 'Avenir-Light' !important;
    }

    &.h1 {
      margin: 0 0 50px;

      span {
        -webkit-text-stroke: 1px #fff;
        font-size: 90px;
        font-family: 'Avenir-Bold' !important;
        line-height: 0.9;
        letter-spacing: -0.025em;
        color: transparent;
        text-shadow: none;

        ${media.journeyLg`
          font-size: 80px;
        `}

        ${media.md`
          font-size: 70px;
        `}

        ${media.journeyMd`
          font-size: 50px;
        `}

        ${media.sm`
          font-size: 44px;
        `}

        strong,
        strong span {
          color: #fff;
        }
      }
    }

    &.h2 {
      letter-spacing: 0.02em;
      // max-width: 800px;
      margin: auto;
      line-height: 1.4;
      font-size: 30px;
      transform: scale(0.99);
      color: #fff !important;
      font-weight: 300;

      ${media.journeyLg`
        font-size: 26px;
      `}

      ${media.md`
        font-size: 24px;
      `}

      ${media.journeyMd`
        font-size: 20px;
      `}
    }

    &.h3 {
      font-weight: 400;
      letter-spacing: 0.02em;
      margin: 50px auto 0;
      line-height: 1.4;
      font-size: 26px;
      transform: scale(0.99);
      color: #fff !important;

      ${media.journeyLg`
        font-size: 22px;
      `}

      ${media.md`
        font-size: 20px;
      `}

      ${media.journeyMd`
        font-size: 18px;
      `}
    }
  }
`;
export { StyledJourneyMadlibLanding as JourneyMadlibLanding };
