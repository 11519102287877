import React, { useRef, useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { SessionContext } from '../../../Context';
import { withSettings } from '../../components';
import { randomColor } from '../../../libs';

const Avatar = props => {
  const {
    className,
    history,
    size,
    layout,
    noName,
    fullName,
    profile,
    linked,
    inline,
    email,
    handleFileSelected,
    base64Image,
    editable,
    disabled,
    sessionProfile,
    addPic,
    settings
  } = props;

  const { session, theme } = useContext(SessionContext);
  const [avatarBackgroundColor, setAvatarBackgroundColor] = useState('');

  const { randomProfileColor: userLoggedColor } = theme;

  const getAvatarProfile = () => {
    const viewerProfile = (session && session.viewer && session.viewer.user) ? session.viewer.user.profile : null;
    return sessionProfile ? viewerProfile : (profile ? profile : { email: !!email });
  }

  const profileInfo = getAvatarProfile();
  const avatar = profileInfo ? profileInfo.avatar : null;
  const loggedUserId = (session && session.viewer && session.viewer.user && session.viewer.user.profile && session.viewer.user.profile.id) || -1;
  const isUserLogged = profileInfo.id === loggedUserId;

  useEffect(() => {
    setAvatarBackgroundColor(isUserLogged ? userLoggedColor : randomColor(settings));
  }, [isUserLogged, userLoggedColor, settings, setAvatarBackgroundColor]);

  const getBackgroundStyleFromAvatar = (randomProfileColor) => {
    if (avatar && avatar.photo) {
      return { backgroundImage: `url(${avatar.photo.largeUrl})` };
    } else {
      return { backgroundColor: randomProfileColor };
    }
  };

  const getBackgroundStyleFromBase64Image = (image, randomProfileColor) => {
    if (image) {
      return { backgroundImage: `url(${image})` };
    } else {
      return { backgroundColor: randomProfileColor };
    }
  };

  // const getHoverBackgroundStyleFromAvatar = (ramdomProfileColor) => {
  //   if (avatar && avatar.photo) {
  //     return { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${avatar.photo.largeUrl})` }
  //   } else {
  //     return { backgroundColor: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ${ramdomProfileColor}` };
  //   }
  // }
  //
  // const getHoverBackgroundStyleFromBase64Image = (image, randomProfileColor) => {
  //   if (image) {
  //     return { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})` };
  //   } else {
  //     return { backgroundColor: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ${randomProfileColor}` };
  //   }
  // };

  const bgStyle = base64Image ? getBackgroundStyleFromBase64Image(base64Image, avatarBackgroundColor) : getBackgroundStyleFromAvatar(avatarBackgroundColor);
  // const bgHoverStyle = base64Image ? getHoverBackgroundStyleFromBase64Image(base64Image, avatarBackgroundColor) : getHoverBackgroundStyleFromAvatar(avatarBackgroundColor);

  const avatarClassNames = [
    size ? size : 'sm',
    layout ? layout : 'horizontal',
    linked ? 'linked' : ''
  ];

  const getUserInitials = () => {
    if (!profileInfo) return null;

    if (profileInfo.email && email) {
      return email.charAt(0).toUpperCase();
    } else {
      const firstName = profileInfo.firstName || '';
      const firstNameFirstCharacter = firstName.length > 0 ? firstName.charAt(0).toUpperCase() : '';
      const lastName = profileInfo.lastName || '';
      const lastNameFirstCharacter = lastName.length > 0 ? lastName.charAt(0).toUpperCase() : '';
      return firstNameFirstCharacter + lastNameFirstCharacter;
    }
  };

  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onFileSelected = (event) => {
    const file = event.target.files[0];
    handleFileSelected(file);
  }

  const handleAvatarClick = () => {
    if (!linked || !profileInfo || profileInfo.id === undefined) return;
    history.push(`/user_profile/${profileInfo.id}`);
  };

  const containerClasses = ['avatar-container'];
  editable && containerClasses.push('editable');

  const AvatarEl = () => (
    <div className={`${className} ${avatarClassNames.join(' ')}`} >
      {(!noName && (!layout || layout === 'horizontal')) && (
        <span className="user_name">{`${profileInfo.firstName}${fullName ? ' ' + profileInfo.lastName : ''}`}</span>
      )}
      <div className={containerClasses.join(' ')}>
        <div
          className="avatar avatar-view"
          style={bgStyle}
          onClick={() => {
            if (disabled) { return; }

            if (handleFileSelected) {
              onButtonClick();
            } else {
              handleAvatarClick(session);
            }
          }}
        >
          {!avatar && !addPic && (
            <span className="user_initials">{getUserInitials(profileInfo)}</span>
          )}
          {addPic && <span className="plus"></span>}
        </div>
        <div
          className="avatar avatar-edit"
          style={bgStyle}
          onClick={() => {
            if (disabled) { return; }

            if (handleFileSelected) {
              onButtonClick();
            } else {
              handleAvatarClick(session);
            }
          }}
        >
          <span className="plus"></span>
        </div>
      </div>
      {(!noName && layout === 'vertical') && (
        <span onClick={() => handleAvatarClick(session)} className="user_name">{`${profileInfo.firstName}${fullName ? ' ' + profileInfo.lastName : ''}`}</span>
      )}
    </div>
  );

  return (
    <div className="avatar_container" style={{ display: inline ? 'inline-block' : 'block' }}>
      {editable ? (
        <form>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={onFileSelected}
            accept="image/x-png,image/gif,image/jpeg"
          />
          <AvatarEl />
        </form>
      ) : (
        <AvatarEl />
      )}
    </div>
  );
};

const StyledAvatar = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin: auto;
    width: 24px;
    height: 24px;
  }

  .plus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      background-color: #fff;
      transform: translateY(-50%);
    }

    &::after {
      transform: translateY(-50%) rotate(-90deg);
    }
  }

  &.sm {
    flex-direction: row;

    & .avatar {
      width: 44px;
      height: 44px;

      .user_initials {
        font-size: 14px;
      }
    }

    &.vertical {
      display: inline-block;
      margin: 0 15px;

      .avatar {
        margin: 0 auto;
      }

      .user_name {
        font-size: 12px;
      }
    }

    .plus {
      width: 15px;
      height: 15px;

      &::before,
      &::after {
        height: 3px;
      }
    }
  }

  &.md {
    & .avatar {
      width: 60px;
      height: 60px;
    }

    .plus {
      width: 20px;
      height: 20px;

      &::before,
      &::after {
        height: 4px;
      }
    }
  }

  &.lg {
    & .avatar {
      width: 80px;
      height: 80px;

      .user_initials {
        font-size: 25px;
      }
    }

    &.vertical {
      .user_name {
        font-size: 18px;
      }
    }

    .plus {
      width: 25px;
      height: 25px;

      &::before,
      &::after {
        height: 5px;
      }
    }
  }

  &.xl {
    & .avatar {
      width: 160px;
      height: 160px;

      .user_initials {
        font-size: 34px;
      }
    }

    &.vertical {
      .user_name {
        font-size: 20px;
      }
    }

    .plus {
      width: 35px;
      height: 35px;

      &::before,
      &::after {
        height: 5px;
      }
    }
  }

  &.horizontal {
    flex-direction: row;

    & .user_name {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
    }

    & .avatar {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.vertical {
    flex-direction: column;

    & .avatar {
      margin: 0 auto 10px;
    }
  }

  & .avatar {
    display: flex;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);

    .user_initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      line-height: 1;
    }
  }

  &.linked {
    cursor: pointer;

    .user_name {
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;
    }

    &:hover {
      .user_name {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
        text-decoration: underline;
      }
    }
  }

  & .avatar.avatar-view {
    display: block;
  }

  & .avatar-container.editable:hover > .avatar.avatar-view {
    display: none;
  }

  & .avatar-edit {
    cursor: pointer;
  }

  & .avatar.avatar-edit {
    display: none;
  }

  & .avatar-container.editable:hover > .avatar.avatar-edit {
    display: block;
  }
`;

const AvatarWithRouter = withRouter(StyledAvatar);
const AvatarWithRouterWithSettings = withSettings(AvatarWithRouter);

export { AvatarWithRouterWithSettings as Avatar };
