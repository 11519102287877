import React from 'react';

import { useSettings, useSession, useEventPageData } from '../../hooks';
import { hasReservations, hasTables } from '../../libs';

import {
  PageSubheader as Header,
  Attendees,
  Divider
} from '../components';


export const EventAttendees = () => {
  const { eventData } = useEventPageData();
  const { copy } = useSettings();
  const { viewer, theme } = useSession();

  if (
    !eventData ||
    eventData.hideGuestList ||
    !viewer ||
    !hasReservations(eventData) ||
    hasTables(eventData)
  ) return null;

  return (
    <>
      <Divider />
      <Header>{copy.event.attendants_title}</Header>
      <Attendees
        className="attendees"
        event={eventData}
        reservations={(eventData.reservations.edges || []).map(edge => edge.node)}
        totalGuestsAccepted={eventData.reservations.guestsAccepted}
        size="xl"
        position="left"
        version={theme.version}
      />
    </>
  );
};
