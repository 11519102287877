import React from 'react';
import styled, { keyframes } from 'styled-components';

const ButtonLoader = ({ className }) => (
  <div className={['button_loader', className].join(' ')}>
    <i /><i /><i />
  </div>
);

const bounce = keyframes`
  0%, 100% {
    transform: scale(1.0);
    opacity: 1;
  } 50% {
    transform: scale(0.6);
    opacity: 0.7;
  }
`;

const StyledButtonLoader = styled(ButtonLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;

  i {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    margin-top: -5px;

    &:nth-of-type(1) {
      right: 0;
      animation: ${bounce} 0.6s infinite ease-in-out;
    }

    &:nth-of-type(2) {
      left: 50%;
      margin-left: -5px;
      animation: ${bounce} 0.6s 0.2s infinite ease-in-out;
    }

    &:nth-of-type(3) {
      left: 0;
      animation: ${bounce} 0.6s 0.4s infinite ease-in-out;
    }
  }
`;

export { StyledButtonLoader as ButtonLoader };
