import React from 'react';
import { useProfilePageData, useSettings } from '../../hooks';

import {
  ProfileEdit,
  PageSubheader as Header,
  ProfileCoverImage,
  Divider
} from '../components';


export const ProfileSettings = () => {
  const { user, refetch } = useProfilePageData();
  const { copy } = useSettings();

  return user ? (
    <div>
      <Header>{copy.profile.profile_title}</Header>
      <Divider />
      <ProfileCoverImage user={user} refetch={refetch} />
      <ProfileEdit user={user} refetch={refetch} />
      <Divider bottom />
    </div>
  ) : null;
};
