import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, RsvpPaymentModalCreditCard, withSettings } from '../components';

class RsvpPaymentModalCardsManagementView extends React.Component {

  render() {
    const {
      className,
      changeButtonClicked,
      addCardButtonClicked,
      selectedCard,
      settings
    } = this.props;
    const { copy } = settings;
    const images = settings.theme.assets.images;

    return (
      <div className={className}>
        <h2>{copy.rsvp.card_pay_title}</h2>
        {selectedCard && (
          <RsvpPaymentModalCreditCard
            creditCard={selectedCard}
            editing={false}
            changeButtonClicked={changeButtonClicked}
          />
        )}
        {!selectedCard && (
          <div className="row add-card-row">
            <div className="ot-quarter-container">
              <label>
                <div>
                  <span className="add-card-icon">+</span>
                  <img className="credit-card-icon" src={images.credit_card} alt="Credit Card Icon" />
                </div>
              </label>
            </div>
            <div className="add-card-button-container">
              <Button buttonStyle="clearBackground" handleClick={addCardButtonClicked}>{copy.profile.card_new_button}</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

RsvpPaymentModalCardsManagementView.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCardsManagementView = styled(RsvpPaymentModalCardsManagementView)`
  text-align: left;

  & h2 {
    text-align: left;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & .add-card-row {
    align-items: center;
  }

  & .add-card-button-container {
    width: 75%;
  }

  & .add-card-button-container button {
    float: right;
    font-weight: bold;
    font-size: 13px;
  }

  & .credit-card-icon {
    width: 30px;
    top: -2px;
    position: relative;
    margin: 3px 0 0 5%;
  }

  & label {
    font-size: 13px;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    display: block;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  & label span {
    padding: 2px 0 0;
  }

  & .primary-card-label {
    font-size: 12px;
    padding-top: 3px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & .change-button {
    font-weight: bold;
    font-size: 13px;
  }
`;

const RsvpPaymentModalCardsManagementViewWithSettings = withSettings(StyledRsvpPaymentModalCardsManagementView);

export { RsvpPaymentModalCardsManagementViewWithSettings as RsvpPaymentModalCardsManagementView };
