import React from 'react';
import styled from 'styled-components';

import { PageHeader } from '../components';
import { useSettings, useRsvpPageData } from '../../hooks';
import RSVP_PAGES from '../../constants/rsvpPages';


export const RsvpPageHeader = () => {
  const { pageIndex, changePage, pages, currentPage } = useRsvpPageData();
  const { copy } = useSettings();
  const shouldLink = currentPage !== RSVP_PAGES.CHECKOUT ||
    (currentPage === RSVP_PAGES.CHECKOUT && !pages.includes(RSVP_PAGES.MEMBERSHIP));

  return (
    <PageHeader
      onBackClick={() => changePage(false)}
      heading={copy.rsvp.rsvp_page_title || 'RSVP'}
      shouldLink={shouldLink}
    >
      <Steps>{`Step ${pageIndex + 1} of ${pages.length}`}</Steps>
    </PageHeader>
  );
};

const Steps = styled.span`
  color: #83817b;
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 3px;
`;
