import React from 'react';
import styled from 'styled-components';
import { DollarInput, SelectBox, Html, withSettings } from '../components';

const defaultSelection = {
  id: null,
  label: 'Select Amount',
  value: 'ALL'
};

class EventSponsorshipAmountSelector extends React.Component {
  render() {
    const { className, name, photoUrl, description, url, disabled, amount, allowedAmounts } = this.props;
    const { settings: { copy } } = this.props;

    const onInputChange = (_name, value) => {
      const { id, onAmountChanged } = this.props;
      onAmountChanged(id, value);
    }

    let sponsorshipHeader = null;

    if (photoUrl) {
      const backgroundImageUrl = `url(${photoUrl})`;
      sponsorshipHeader = (<div className="ot-logo-sponsor" style={{ backgroundImage: backgroundImageUrl }}></div>);
    } else {
      sponsorshipHeader = (<div className="ot-name-sponsor">{name}</div>);
    }

    const sponsorshipDescription = description ? (<Html content={description}/>) : null;
    const sponsorshipUrl = url ? (<a target="_blank" rel="noopener noreferrer" href={url}>{copy.event.nourished_read_more}</a>) : null;

    let sponsorshipAmountInput = null;

    if (allowedAmounts && allowedAmounts.length > 0) {
      const options = allowedAmounts
        .map(allowedAmount => allowedAmount / 100)
        .map(allowedAmount => {
          return { id: allowedAmount, label: `$${allowedAmount}`, value: (allowedAmount) }
        });

      const optionsList = (new Array(defaultSelection)).concat(options);
      const selection = options.filter(option => option.value === amount);

      sponsorshipAmountInput = (
        <SelectBox
          className="dollar-input"
          disabled={disabled}
          leftLabels
          defaultValue={selection.length ? selection : defaultSelection}
          options={optionsList}
          onOptionSelect={value => onInputChange('', value)}
        />
      )
    } else {
      sponsorshipAmountInput = (
        <DollarInput
          className="dollar-input"
          name="price"
          min="0"
          disabled={disabled}
          value={amount}
          onInputChange={onInputChange}
          errorPlacement="bottom"
          errorMessage="Enter a valid amount."
        />
      )
    }

    return (
      <div className={className}>
        {sponsorshipHeader}
        {sponsorshipDescription}
        {sponsorshipUrl}
        {sponsorshipAmountInput}
      </div>
    );
  }
}

const StyledEventSponsorshipAmountSelector = styled(EventSponsorshipAmountSelector)`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0,0,0,.04),0 2px 10px rgba(0,0,0,.05);

  & p {
    min-height: 70px;
    margin: 0 0 10px;
  }

  & a {
    color: ${props => props.settings.theme.css.global.colors.primary};
    margin-bottom: 8px;
  }

  & .ot-logo-sponsor {
    height: 80px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  & .ot-name-sponsor {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 80px;
    margin: 20px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  & .dollar-input {
    width: 140px;
    margin: auto auto 0 auto;
  }

  .dropdown_menu  {
    span {
      text-align: left;
    }
  }

`;

const StyledEventSponsorshipAmountSelectorWithSettings = withSettings(StyledEventSponsorshipAmountSelector);

export { StyledEventSponsorshipAmountSelectorWithSettings as EventSponsorshipAmountSelector };
