import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { alert, SelectBox, Textarea } from '../Global';
import { Modal, Button } from '../components';

import { MANAGE_EVENTS, VIEWER_EVENTS_COUNT } from '../../queries';

import { EVENT_CANCEL } from '../../mutations';

import { withSettings } from '../components';

import { withCancelEventQueryHooksHOC } from '../../libs/events';

class ManageEventsDetailsCancelModal extends React.Component {
  state = {
    loading: false,
    cancelReason: '',
    cancelReasonText: '',
    cancelReasons: null,
    requireText: false
  };

  componentDidUpdate() {
    if (!this.props.getCancelEventLoading && !this.state.cancelReasons && this.props.getCancelEventData) {
      this.setState({ cancelReasons: this.props.getCancelEventData.eventCancelReasons });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
    if (name === 'cancelReason') {
      const reason = this.state.cancelReasons.find((r) => r.cancelReason === value);
      this.setState({ requireText: reason.requireText, cancelReasonText: '' });
    }
  };

  formValid = () => {
    const { settings: { event: { cancel_reason_enabled } } } = this.props;
    if (!cancel_reason_enabled) return true;
    if (!this.state.cancelReasons) return false;
    const reason = this.state.cancelReasons.find((r) => r.cancelReason === this.state.cancelReason);
    if (!reason) return false;
    return reason.requireText ? this.state.cancelReasonText : true;
  };

  formatSelectData = (options) => {
    if (!options) return [];

    const data = options.map((key, i) => ({
      id: i + 1,
      label: key.cancelReason,
      value: key.cancelReason
    }));

    return data;
  };

  handleCancel = (eventCancel) => {
    const {
      event,
      toggle,
      settings: { copy }
    } = this.props;
    this.setState({ loading: true });

    const filter = this.props.match.params.filter.toUpperCase();

    // const { URL } = EVENT_FILTER.FIELDS;
    // const eventFilter = EVENT_FILTER.getField(URL, filter);
    // const isHost = eventFilter !== EVENT_FILTER.RESERVED && eventFilter !== EVENT_FILTER.ATTENDED;

    eventCancel({
      awaitRefetchQueries: true,
      refetchQueries: () => [
        { query: MANAGE_EVENTS, variables: { filter } },
        { query: VIEWER_EVENTS_COUNT, variables: {} }
      ],
      variables: {
        eventId: event.id,
        cancelReason: this.state.cancelReason,
        cancelReasonText: this.state.cancelReasonText
      }
    }).then(() => {
      alert.success(copy.event.confirm_delete_success);

      this.setState({ loading: false }, () => {
        toggle(true);
      });
    });
  };

  render() {
    const { loading } = this.state;
    const { className, open, toggle } = this.props;
    const {
      settings: { copy, event: { cancel_reason_enabled } }
    } = this.props;

    return (
      <Modal size='sm' show={open} toggle={toggle}>
        <div className={className}>
          <header>
            <title>{copy.event.confirm_delete_title}</title>
            <p>{copy.event.confirm_delete_title2}</p>
          </header>
          {cancel_reason_enabled && (
             <div>
               <label>{copy.event.confirm_delete_cancel_event_reason}</label>
               <SelectBox
                 name='cancelReason'
                 options={this.formatSelectData(this.state.cancelReasons)}
                 onOptionSelect={(value) => this.handleChange('cancelReason', value)}
               />
             </div>
           )}
          {this.state.requireText && (
            <div className=''>
              <label>{copy.event.confirm_delete_cancel_event_reason_text}</label>
              <Textarea
                name='cancelReasonText'
                value={this.state.cancelReasonText}
                onInputChange={this.handleChange}
                placeholder={copy.event.confirm_delete_cancel_event_reason_text_placeholder}
              />
            </div>
          )}
          <div className='actions'>
            <Mutation mutation={EVENT_CANCEL}>
              {(eventCancel) => {
                return (
                  <Button
                    handleClick={() => this.handleCancel(eventCancel)}
                    loading={loading}
                    disabled={loading || !this.formValid()}
                  >
                    {copy.event.confirm_delete_submit}
                  </Button>
                );
              }}
            </Mutation>
            <Button
              inverted
              handleClick={() => {
                this.setState({ cancelReason: null });
                toggle();
              }}
            >
              {copy.event.confirm_delete_cancel}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const StyledManageEventsDetailsCancelModal = styled(ManageEventsDetailsCancelModal)`
  padding: 0px;
  width: 120%;
  margin-left: -10%;
  header {
    text-align: center;
    margin-bottom: 30px;
    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
  .actions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    button {
      margin-top: 20px;
    }
  }
  label {
    font-size: 14px;
    font-weight: bold;
  }
`;

const StyledManageEventsDetailsCancelModalWithRouter = withRouter(
  StyledManageEventsDetailsCancelModal
);
const StyledManageEventsDetailsCancelModalWithRouterWithSettings = withSettings(
  StyledManageEventsDetailsCancelModalWithRouter
);

const StyledManageEventsDetailsCancelModalWithRouterEventQueryHooks = withCancelEventQueryHooksHOC(
  StyledManageEventsDetailsCancelModalWithRouterWithSettings
);

export { StyledManageEventsDetailsCancelModalWithRouterEventQueryHooks as ManageEventsDetailsCancelModal };
