import * as Sentry from '@sentry/browser';

const errorMgt = {
  logError: (actionName, message, extraData) => {
    Sentry.withScope((scope) => {
      scope.setLevel('info');
      scope.setTag('action_name', actionName);

      if (extraData) {
        Object.keys(extraData).forEach(key => {
          if (extraData[key] != null) {
            scope.setExtra(key, extraData[key]);
          }
        });
      }

      Sentry.captureMessage(message);
    });
  },

  getErrorFromServerResponse: (gqlErrors, copy) => {
    const extensionsProblems = errorMgt.getExtensionsProblems(gqlErrors);
    const extensionProblem = errorMgt.getErrorFromExtensionsProblems(extensionsProblems, copy);

    if (extensionProblem && !extensionProblem.isGeneric) {
      return extensionProblem;
    }

    const extensionsErrors = errorMgt.getExtensionsErrors(gqlErrors);
    const extensionError = errorMgt.getErrorFromExtensionsErrors(extensionsErrors);

    if (extensionError && !extensionError.isGeneric) {
      return extensionError;
    }

    if (extensionProblem) {
      return extensionProblem;
    }

    if (extensionError) {
      return extensionError;
    }

    return {
      userMessage: null,
      explanation: null,
      isGeneric: true
    };
  },

  getExtensionsProblems: gqlErrors => {
    if (!gqlErrors || !Array.isArray(gqlErrors)) {
      return [];
    }

    const extensions = gqlErrors.map(error => error.extensions);
    const problems = extensions.reduce((accumulator, extension) => {
      if (!extension.problems) {
        return accumulator;
      }

      return [...accumulator, ...extension.problems];
    }, []);

    return problems;
  },

  getErrorFromExtensionsProblems: (problems, copy) => {
    const knownExplanations = ['User not found.', 'User is not a host.'];

    const knownProblems = (problems.filter(problem => {
      if (!problem || !problem.explanation) {
        return false;
      }

      const isKnownExplanation = (knownExplanations.findIndex(knownExplanation =>  knownExplanation === problem.explanation) >= 0);

      return isKnownExplanation;
    }));

    const unknownProblems = (problems.filter(problem => {
      if (!problem || !problem.explanation) {
        return false;
      }

      const isKnownExplanation = (knownExplanations.findIndex(knownExplanation =>  knownExplanation === problem.explanation) >= 0);

      return !isKnownExplanation;
    }));

    if (knownProblems.length > 0) {
      const knownProblem = knownProblems[0];
      const knownProblemExplanation = knownProblem.explanation;
      const knownProblemUserError = errorMgt.getCopyForExplanation(knownProblemExplanation, copy);

      return {
        userMessage: knownProblemUserError,
        explanation: knownProblemExplanation,
        isGeneric: !knownProblemUserError || knownProblemUserError.length === 0
      };
    }

    if (unknownProblems.length > 0) {
      return {
        userMessage: null,
        explanation: null,
        isGeneric: true
      };
    }

    return null;
  },

  getExtensionsErrors: gqlErrors => {
    if (!gqlErrors || !Array.isArray(gqlErrors)) {
      return [];
    }

    const extensions = gqlErrors.map(error => error.extensions);
    const errs = extensions.reduce((accumulator, extension) => {
      if (!extension.errors) {
        return accumulator;
      }

      return [...accumulator, ...extension.errors];
    }, []);

    return errs;
  },

  getErrorFromExtensionsErrors: extensionsErrors => {
    const userErrors = extensionsErrors.filter(e => (
      e.userError && e.message && e.message.length > 0
    ));

    // temp for disabling accountless cohosts //
    const cohostErrors = extensionsErrors.filter(e => (
      Array.isArray(e.path) && e.path.includes('profileId') && e.message === 'not found'
    ));

    const genericErrors = extensionsErrors.filter(e => (
      !e.userError || !e.message || e.message.length === 0
    ));

    if (userErrors.length > 0) {
      const userError = userErrors[0].message;

      return {
        userMessage: userError,
        explanation: null,
        isGeneric: false
      };
    }

    if (cohostErrors.length > 0) {
      return {
        userMessage: 'Cohosts must have an account.',
        explanation: null,
        isGeneric: false
      };
    }

    if (genericErrors.length > 0) {
      return {
        userMessage: null,
        explanation: null,
        isGeneric: true
      };
    }

    return null;
  },

  getCopyForExplanation: (explanation, copy) => {
    if (!copy) return null;

    switch (explanation) {
      case 'User not found.':
        return (copy.create.error_co_host_user_not_found);
      case 'User is not a host.':
        return (copy.create.error_co_host_need);
      default:
        return null;
    }
  },

  getErrorCode: (errors) => {
    const codeError = errors.map(error => error.extensions);
    if (!codeError.length) return false;
    return codeError[0].code;
  }
};

export { errorMgt };
