const storage = window.localStorage;

const load = (storageId) => {
  return JSON.parse(storage.getItem(storageId)) || {};
};

const store = (config, storageId) => {
  const current = load(storageId);
  if (Object.keys(config).length > 0) {
    storage.setItem(storageId, JSON.stringify({
      ...current,
      ...config
    }));
  }
};

const getItem = (item, storageId) => {
  const config = load(storageId);
  return config && config[item];
};

const setItem = (item, value, storageId) => {
  const config = load(storageId);
  config[item] = value;
  store(config, storageId);
};

const resetAll = (storageId) => {
  storage.removeItem(storageId);
};

const resetItem = (item, storageId) => {
  setItem(item, null, storageId);
};


export const localStorage = {
  store,
  load,
  resetAll,
  resetItem,
  getItem,
  setItem
};
