import React from 'react';
import styled from 'styled-components';
import { Button, withSettings, withSession } from '../components';
import PropTypes from 'prop-types';
import { isRegularOneTable } from '../../libs';

const ProfileButtons = ({ className, sendMessageClicked, profileId, session, settings }) => {
  const userIsLogged = (session && session.viewer && session.viewer.user && session.viewer.user.profile)
  if (!userIsLogged) { return (<div></div>) }

  const isLoggedUser = (userIsLogged && session.viewer.user.profile.id === parseInt(profileId));

  return (
    <div className={className}>
      {isLoggedUser && (<Button link="/profile" buttonStyle="primary_outline" horizontalMargin="5px">Edit My Profile</Button>)}
      {!isLoggedUser && isRegularOneTable(settings) && (
        <Button handleClick={sendMessageClicked} buttonStyle="primary" horizontalMargin="5px">Send Message</Button>
      )}
    </div>
  );
};

ProfileButtons.propTypes = {
  className: PropTypes.string,
  sendMessageClicked: PropTypes.func,
  profileId: PropTypes.string
};

const StyledProfileButtons = styled(ProfileButtons)`
  margin: 40px;
`;

const ProfileButtonsWithSession = withSession(StyledProfileButtons);
const ProfileButtonsWithSettings = withSettings(ProfileButtonsWithSession);

export default ProfileButtonsWithSettings;
