import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Query } from 'react-apollo';

import styled from 'styled-components';

import {
  DropDownMenu,
  HoverMenu,
  FAIcon,
  CustomMenuItems,
  withSettings
} from '../components';

import { AREAS } from '../../queries';
import { sort, dom } from '../../libs';


const AreaMenu = ({ className, title = 'Find A Dinner', settings, useMobileMenu }) => {
  const [displayOnMobile, setDisplayOnMobile] = useState(false);
  const isMobile = dom.isMobile(settings) || useMobileMenu;
  const { copy, event: { virtual } } = settings;
  const customMenuItems = JSON.parse(copy.menu.additional_area_menu_items || '[]');

  const toggleMobileMenu = (e, propagateEvent) => {
    if (!propagateEvent) e.stopPropagation();
    if (isMobile) setDisplayOnMobile(!displayOnMobile);
  };

  return (
    <Query query={AREAS} errorPolicy="all">
      {({ data, error }) => {
        if (error) {
          // console.error(error);
          return null;
        }

        const areas = data && data.areas;
        const sortedAreas = areas ? sort.byObjKey(areas, 'position') : [];

        return (
          <div className={className}>
            <HoverMenu useMobileMenu={useMobileMenu}>
              <div className={`dropdown_menu_wrapper ${displayOnMobile ? 'display' : ''}`}>
                {isMobile
                  ? <span className="mobile_nav_link" onClick={toggleMobileMenu}>{title}</span>
                  : <NavLink to="/" className="dropdown_link">{title}</NavLink>
                }
                <FAIcon name={[ 'fas', 'caret-down' ]} />
              </div>
              {sortedAreas.length > 1 && (
                <DropDownMenu className={displayOnMobile ? 'display' : ''} fullHeight>
                  {/* virtual events page */}
                  {virtual.display_virtual_menu_link && (
                    <li onClick={e => toggleMobileMenu(e, true)} key="virtual">
                      <NavLink to="/virtual">{copy.menu.virtual_area_link_text}</NavLink>
                    </li>
                  )}
                  {/* custom links */}
                  {customMenuItems && (
                    <CustomMenuItems
                      items={customMenuItems.filter(item => item.pos === 'top')}
                      onToggleMenu={e => toggleMobileMenu(e, true)}
                      className="top"
                    />
                  )}
                  {/* area links */}
                  {sortedAreas && sortedAreas.map(area => (
                    <li key={area.id} onClick={e => toggleMobileMenu(e, true)} className="area">
                      <NavLink to={`/landing/${area.url}`}>{area.label}</NavLink>
                    </li>
                  ))}
                  {/* custom links */}
                  {customMenuItems && (
                    <CustomMenuItems
                      items={customMenuItems.filter(item => item.pos !== 'top')}
                      onToggleMenu={e => toggleMobileMenu(e, true)}
                    />
                  )}
                </DropDownMenu>
              )}
            </HoverMenu>
          </div>
        );
      }}
    </Query>
  );
}


const StyledAreaMenu = styled(AreaMenu)`
  position: relative;

  .dropdown_menu {
    top: 100%;
    left: 0;
  }

  @media (max-width: ${({ settings: { theme } }) => theme.css.breakpoints.nav.mobile}px) {
    .display {
      & > .dropdown_menu {
        display: block !important;
      }

      .fa-caret-down {
        transform: rotate(180deg);
      }
    }
  }
`;

const AreaMenuWithSettings = withSettings(StyledAreaMenu);
export { AreaMenuWithSettings as AreaMenu };
