import { useState, useCallback, useEffect } from 'react';

import { youTubeRegex, spotifyRegex } from '../libs';

export const useJourneyAnswerState = (journeyState, claimData) => {
  const [answers, setAnswers] = useState([]);
  const [madlibAnswers, setMadlibAnswers] = useState([]);
  const [resources, setResources] = useState([]);
  const [videos, setVideos] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  const [claimedResources, setClaimedResources] = useState([]);
  const [claimedVideos, setClaimedVideos] = useState([]);
  const [claimedPlaylists, setClaimedPlaylists] = useState([]);


  useEffect(() => {
    const response = claimData && claimData.journeyResponseByClaimToken;
    const claimedResourcesList = [];
    const claimedVideosList = [];
    const claimedPlaylistsList = [];

    if (response && response.resources) {

      response.resources.edges.forEach(r => {
        const { node: resource } = r;
        if (!resource) return;

        if (resource.url && resource.url.match(youTubeRegex)) {
          claimedVideosList.push({ resource });
        } else if (resource.url && resource.url.match(spotifyRegex)) {
          claimedPlaylistsList.push({ resource });
        } else {
          claimedResourcesList.push({ resource });
        }
      });

      setClaimedResources(prev => ([
        ...prev,
        claimedResourcesList
      ]));

      setClaimedVideos(prev => ([
        ...prev,
        claimedVideosList
      ]));

      setClaimedPlaylists(prev => ([
        ...prev,
        claimedPlaylistsList
      ]));
    }

    if (response && response.answers && response.answers.nodes) {
      setMadlibAnswers(prev => ([
        ...prev,
        ...response.answers.nodes
      ]));
    }
  }, [claimData]);

  const saveAnswer = useCallback((id, value, label, prefill, multiple /*, questionIds = false*/) => {
    const filteredAnswers = answers.filter(a => a.id !== id);
    filteredAnswers.push({ id, value, label, prefill, multiple });

    // remove answers that were taken off the page due to conditions //
    // questionIds is false when setting default questions for the first time //

    // removing this for now.. questions wont be conditional in this experience //
    // const conditionallyFilteredAnswers = questionIds === false
    //   ? filteredAnswers
    //   : filteredAnswers.filter(a => questionIds.includes(a.id));

    setAnswers(filteredAnswers);
  }, [answers]);

  const removeAnswer = id => {
    const filteredAnswers = answers.filter(a => a.id !== id);
    setAnswers(filteredAnswers);
  };

  const flattenResourcesArray = array => {
    return array.reduce((list, res) => {
      return list.concat(Array.isArray(res) ? res.map(r => r.resource) : res.resource);
    }, []);
  };

  const prepareResources = array => {
    const flattened = flattenResourcesArray(array);
    const resourcesList = [];
    flattened.forEach(r => {
      const resourceExists = resourcesList.find(re => re.id === r.id);
      if (!resourceExists) resourcesList.push(r);
    });
    return resourcesList;
  };

  const saveResources = () => {
    const currentConditions = journeyState ? journeyState.currentConditions : [];

    let newResources = resources.slice();
    let newVideos = videos.slice();
    let newPlaylists = playlists.slice();

    currentConditions.forEach(({
      conditions,
      resources: conditionResources,
      videos: conditionVideos,
      playlists: conditionPlaylist
    }) => {
      const meetConditions = conditions.reduce((meet, {
        questionId,
        answers: conditionAnswers,
        responseQuestionId,
        responseAnswers
      }) => {
        const userAnswer = answers.find(a => a.id === questionId);
        const madlibAnwer = madlibAnswers.find(a => a.question.id === responseQuestionId);
        const answerResponse = madlibAnwer && madlibAnwer.response && madlibAnwer.response.string;

        if (!userAnswer) return false;

        const includesUserAnswer = meet && conditionAnswers.includes(userAnswer.value)

        // check answers from madlib questions //
        // conditions where responseQuestionId === 0 are specific for the platform //
        // ie going through dinner creation //
        // conditions where responseQuestionId === null are global //

        if (madlibAnswers.length && responseQuestionId === 0) return false;
        if (!madlibAnswers.length && responseQuestionId) return false;

        if (answerResponse)
          return includesUserAnswer && responseAnswers.includes(answerResponse);

        // otherwise just consider current answer //
        return includesUserAnswer;
      }, true);

      if (!meetConditions) return;

      if (conditionResources && conditionResources.length)
        newResources = [...newResources, ...conditionResources];

      if (conditionVideos && conditionVideos.length)
        newVideos = [...newVideos, ...conditionVideos];

      if (conditionPlaylist && conditionPlaylist.length)
        newPlaylists = [...newPlaylists, ...conditionPlaylist];
    });

    setResources(newResources);
    setVideos(newVideos);
    setPlaylists(newPlaylists);

    return {
      resources: prepareResources(newResources),
      videos: prepareResources(newVideos),
      playlists: prepareResources(newPlaylists)
    };
  };

  const removeResources = () => {
    setResources(prev => prev.slice(resources.length - 1));
  };

  const getResources = () => ({
    resources: [...prepareResources(resources), ...prepareResources(claimedResources)],
    videos: [...prepareResources(videos), ...prepareResources(claimedVideos)],
    playlists: [...prepareResources(playlists), ...prepareResources(claimedPlaylists)]
  });

  const getNewReources = () => ({
    resources: prepareResources(resources),
    videos: prepareResources(videos),
    playlists: prepareResources(playlists)
  });

  return {
    answers,
    saveAnswer,
    removeAnswer,
    getResources,
    saveResources,
    removeResources,
    getNewReources
  };
};
