import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { EventList, EventHero, SearchAndFilter, withSettings, withClient } from '../components';
import { stringInsert } from '../../libs';
import { usePaginatedEvents } from '../../hooks';

import { EVENT_SUBTYPES } from '../../queries';

import Html from 'html-to-react';
const htmlParser = new Html.Parser();


const EventListVirtualPage = ({ className, settings, client, archive }) => {
  const { copy, theme: { assets: { images } } } = settings;
  const virtualPageTitle = copy.event.virtual_page_title;
  const archiveTitle = copy.landing.archive_page_title || 'Archive: %1$s';
  const pageTitle = archive ? stringInsert(archiveTitle, virtualPageTitle) : virtualPageTitle;
  const altText = settings.theme.site.virtual_events_page_hero_alt || pageTitle;

  const [filters, setFilters] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);

  const variables = {
    virtual: true,
    scheduledAtRelativeToNow: archive ? 'PAST' : 'UPCOMING',
    ...filters
    // order: EVENT_ORDER.AREA_CODE
  };

  const { loading: eventsLoading, /* error,*/ fetchMore, pagination } = usePaginatedEvents(client, { variables });
  const { events, pageInfo, totalEventsCount, hasMoreEvents, numberOfItems } = pagination;

  const { data: subtypesData } = useQuery(EVENT_SUBTYPES);
  const subtypes = subtypesData && subtypesData.eventSubtypes;

  // if (error) console.error(error);
  // const eventCount = `${totalEventsCount !== 1 ? 'are' : 'is'} ${totalEventsCount || '...'}`;
  // const plural = totalEventsCount !== 1 ? 's' : '';

  const hasEvents = () => Array.isArray(events) && events.length > 0;

  const handleAddFilters = f => setFilters(f);

  const handleLoadMoreEvents = () => {
    setLoadingMore(true);

    return fetchMore({
      variables: { ...variables, numberOfItems, cursor: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEvents = fetchMoreResult.events;
        const prevEvents = previousResult.events;
        pageInfo.endCursor = newEvents.pageInfo.endCursor;

        return {
          events: {
            pageInfo,
            edges: [...prevEvents.edges, ...newEvents.edges],
            __typename: prevEvents.__typename
          }
        };
      }
    }).then(() => setLoadingMore(false));
  };


  return (
    <div className={className}>
      <EventHero
        id='virtual_view_header'
        title={pageTitle}
        headerImg={images.virtual_default_header || images.tag_default_header}
        headerAltText={altText}
      />

      <SearchAndFilter addFiltersToQuery={handleAddFilters} subtypes={subtypes} virtualPage />
      {copy.event.virtual_page_description ? (
        <div className="area_banner__container">
          {htmlParser.parse(copy.event.virtual_page_description)}
        </div>
      ) : <i className="divider" />}
      <div>
        {eventsLoading || hasEvents() ? (
          <div>
            <EventList
              events={events}
              loading={eventsLoading}
              loadingMore={loadingMore}
              showArea={true}
              hasMoreEvents={hasMoreEvents}
              totalEventsCount={totalEventsCount}
              onLoadMore={() => handleLoadMoreEvents()}
              archive={archive}
            />
          </div>
        ) : (
          <div className="no-items">
            {copy.event.virtual_no_events_found}
          </div>
        )}
      </div>
    </div>
  );
};


const StyledEventListVirtualPage = styled(EventListVirtualPage)`
  & > .header {
    position: relative;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    padding: 170px 0;

    h1 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      margin: 0;
      text-transform: capitalize;
    }
  }
  & > p {
    max-width: 1100px;
    padding: 0 30px;
    text-align: center;
    margin: 60px auto 40px;
    font-size: 14px;
  }

  .event_count {
    font-size: 14px;
    text-align: center;
    margin: 30px 0 -10px;
  }

  .no-items {
    display: flex;
    justify-content: center;
  }

  .area_banner__container {
    margin-top: 40px;
  }

  .divider {
    margin-top: 50px;
  }
`;

const EventListVirtualPageWithRouter = withRouter(StyledEventListVirtualPage);
const EventListVirtualPageWithSettings = withSettings(EventListVirtualPageWithRouter);
const EventListVirtualPageWithClient = withClient(EventListVirtualPageWithSettings);

export { EventListVirtualPageWithClient as EventListVirtualPage };
