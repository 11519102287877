import React from 'react';
import styled from 'styled-components';

import {
  DropdownSearchFilter,
  MultiButtonSearchFilter,
  ToggleSearchFilter,
  withSettings
} from '../../components';


const SearchFilters = props => {
  const { settings, filterKey } = props;
  const { copy } = settings;

  switch (filterKey) {
    case 'event_type':
      return (
        <FilterContainer>
          <label>{copy.filter.event_type}</label>
          <div>
            <DropdownSearchFilter
              name="eventType"
              label="All"
              options={[
                { label: 'Open', value: 'Open' },
                { label: 'Host Approval', value: 'Host Approval' },
                { label: 'Invite Only', value: 'Invite Only' },
                { label: 'Solo', value: 'Solo' }
              ]}
              fullWidth
            />
          </div>
        </FilterContainer>
      );

    case 'cost':
      return (
        <FilterContainer>
          <label>{copy.filter.cost_type}</label>
          <div>
            <MultiButtonSearchFilter
              name="cost"
              options={['All', 'Free', 'Paid']}
            />
          </div>
        </FilterContainer>
      );

    case 'virtual':
      return (
        <FilterContainer>
          <label>{copy.filter.virtual || 'Virtual'}</label>
          <div>
            <ToggleSearchFilter name="virtual" />
          </div>
        </FilterContainer>
      );

    case 'potluck':
      return (
        <FilterContainer>
          <label>{copy.filter.potluck || 'Potluck'}</label>
          <div>
            <ToggleSearchFilter name="potluck" />
          </div>
        </FilterContainer>
      );

    case 'timezone':
      return (
        <FilterContainer>
          <label>{copy.filter.timezone || 'Timezone'}</label>
          <div>
            <DropdownSearchFilter
              name="timezone"
              label="All"
              options={[
                { label: 'Hawaii', value: 'Hawaii' },
                { label: 'Alaska', value: 'Alaska' },
                { label: 'Pacific Time (US & Canada)', value: 'Pacific Time (US & Canada)' },
                { label: 'Arizona', value: 'Arizona' },
                { label: 'Mountain Time (US & Canada)', value: 'Mountain Time (US & Canada)' },
                { label: 'Central Time (US & Canada)', value: 'Central Time (US & Canada)' },
                { label: 'Eastern Time (US & Canada)', value: 'Eastern Time (US & Canada)' },
              ]}
              fullWidth
            />
          </div>
        </FilterContainer>
      );

    case 'dress_code':
      return (
        <FilterContainer>
          <label>{copy.filter.dress_code || 'Dress Code'}</label>
          <div>
            <DropdownSearchFilter
              name="dressCode"
              label="All"
              options={[
                { label: 'PJ\'s', value: 'PJS' },
                { label: 'Casual (Jeans OK)', value: 'CASUAL' },
                { label: 'Dressy (No Jeans)', value: 'DRESSY' },
                { label: 'Black Tie', value: 'BLACK_TIE' },
                { label: 'Themed', value: 'THEMED' },
              ]}
              fullWidth
            />
          </div>
        </FilterContainer>
      );

    case 'alcohol_policy':
      return (
        <FilterContainer>
          <label>{copy.filter.alcohol_policy || 'Alcohol Policy'}</label>
          <div>
            <DropdownSearchFilter
              name="alcoholPolicy"
              label="All"
              options={[
                { label: 'Host Provides', value: 'HOST_PROVIDES' },
                { label: 'Alcohol Free', value: 'ALCOHOL_FREE' },
                { label: 'BYOB', value: 'BYOB' }
              ]}
              fullWidth
              multi
            />
          </div>
        </FilterContainer>
      );

    default:
      return null;
  }
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin: 0;
    width: 140px;
    font-family: 'Avenir-Medium' !important;
  }

  & > div {
    flex: 1 0;

    & > div {
      margin-right: 0;
    }
  }
`;

const SearchFiltersWithSettings = withSettings(SearchFilters);
export { SearchFiltersWithSettings as SearchFilters };
