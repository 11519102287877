import React from 'react';
import styled, { css } from 'styled-components';

import { useSettings, useSession } from '../../hooks';
import {
  PageSubheader,
  ProfileSettingsSubscriptions,
  ProfileSettingsSubscriptionNotifications,
  Divider
} from '../components';

export const ProfileSettingsMembership = () => {
  const { copy } = useSettings();
  const { theme } = useSession();
  const Header = ({ children }) => theme.isVersion(2)
    ? <PageSubheader>{children}</PageSubheader>
    : <h3>{children}</h3>

  return (
    <MembershipContainer theme={theme}>
      <Header>{copy.profile.membership_title || 'Membership Settings'}</Header>
      {theme.isVersion(2) && <Divider />}
      <ProfileSettingsSubscriptions />
      {false && <Divider />}
      {false && <ProfileSettingsSubscriptionNotifications />}
      {theme.isVersion(2) && <Divider bottom />}
    </MembershipContainer>
  );
};

const MembershipContainer = styled.div`
  ${({ theme }) => !theme.isVersion(2) && css`
    padding-top: 20px;

    h3 {
      font-size: 20px;
      text-align: center;
    }

    h2 {
      font-size: 18px;
    }
  `}
`;
