import React from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';
import { Loader, LogInModal, HostApplicationModal, FormAssembly } from '../../components';
import { VIEWER_HOST_FORM } from '../../queries';
import { HOST_REQUEST } from '../../mutations';
import { SessionContext } from '../../Context';
import { Redirect } from 'react-router-dom';
import { withSettings } from '../components';

class HostApplication extends React.Component {
  state = { showForm: true, showLogInModal: true, modalContent: 'login', closeLogInModal: false, showApplicationSentMessage: false };

  afterSubmit = (session, hostRequest) => {
    return () => {
      hostRequest()
        .then(() => {
          session.refetch()
                 .then(() => {
                   this.setState({ showForm: false, showApplicationSentMessage: true });
                 });
        });
    };
  };

  toggleLoginModal = () => {
    this.setState({
      closeLogInModal: true
    });
  };

  updateLoginModalContent = (content, openModal) => {
    const newState = { modalContent: content };

    if (openModal) {
      newState['showLogInModal'] = true;
    }

    this.setState(newState);
  };

  handleAfterLogin = (refetch) => {
    this.setState({
      showLogInModal: false
    });

    refetch();
  };

  render() {
    const { className, settings } = this.props;
    const { showForm, showLogInModal, closeLogInModal, showApplicationSentMessage } = this.state;

    if (closeLogInModal) {
      return <Redirect to="/landing"/>;
    }

    return (
      <SessionContext.Consumer>
        {({ session }) => {
          const { viewer } = session;
          const user = viewer ? viewer.user : null;
          const isHost = user && user.state === 'host';

          if (showApplicationSentMessage) {
            const subtitleHTML = { __html: settings.copy.host_promotion.subtitle };

            return (
              <div className={className}>
                <div className="application-sent-container">
                  <h3>{settings.copy.host_promotion.title}</h3>
                  <h4 dangerouslySetInnerHTML={subtitleHTML}></h4>
                </div>
              </div>
            );
          }

          if (isHost) {
            return (
              <div className={className}>
                <div className="application-sent-container">
                  <h3>{settings.copy.host_promotion.title}</h3>
                  <h4>You are already a host</h4>
                </div>
              </div>
            );
          }

          return (
            <Mutation mutation={HOST_REQUEST}>
              {(hostRequest) => (

                <Query query={VIEWER_HOST_FORM}>
                  {({ data, loading, refetch }) => {
                    // if (error) console.error('Form loading error: ', error);

                    if (!loading) {
                      const hostRequestFormIsAvailable = data && data.viewer && data.viewer.hostRequestForm;

                      if (!hostRequestFormIsAvailable) {
                        return (
                          <LogInModal
                            show={showLogInModal}
                            toggle={this.toggleLoginModal}
                            updateModalContent={this.updateLoginModalContent}
                            content={this.state.modalContent}
                            shouldRedirect={false}
                            afterLogin={() => {
                              this.handleAfterLogin(refetch);
                            }}
                          />
                        );
                      } else {
                        return (
                          <div className={className}>
                            <header>
                              <h1>Become A Host</h1>
                              <p dangerouslySetInnerHTML={{ __html: settings.copy.host_promotion.become_host_sub }} />
                            </header>
                            {showForm ? (
                              <FormAssembly
                                form={data.viewer.hostRequestForm}
                                afterSubmit={this.afterSubmit(session, hostRequest)}
                              />
                            ) : <HostApplicationModal/>
                            }
                          </div>
                        );
                      }
                    } else {
                      return <Loader/>;
                    }
                  }}
                </Query>
              )}
            </Mutation>
          );
        }}
      </SessionContext.Consumer>
    );
  }
}

const StyledHostApplication = styled(HostApplication)`
  max-width: 1192px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .ot-full-container > div {
    text-align: center;
    margin-top: 5em;
  }

  & > .application-sent-container {
    text-align: center;
  }

  & > .application-sent-container > h3 {
    margin: 2em;
  }

  & > .application-sent-container > h4 {
    margin: 2em;
  }

  header {
    margin-top: 50px;
    text-align: center;

    h1 {
      font-size: 28px;
      margin: 0 0 10px;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    a {
      color: ${({ colors: { primary } }) => primary};
    }
  }
`;

const StyledHostApplicationWithSettings = withSettings(StyledHostApplication);

export { StyledHostApplicationWithSettings as HostApplication };
