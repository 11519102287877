import React from 'react';
import styled, { keyframes } from 'styled-components';
import { EventCreationCoverUploader } from '../../components';
import { withSettings } from '../../components';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

import { breakpoints } from '../../../styles';

class EventCreationCoverSelector extends React.Component {

  state = {
    stockCovers: [],
    activeIndex: 0,
    newCustomCover: null
  };

  animating = false;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.stockCovers && nextProps.stockCovers.length && !prevState.stockCovers.length) {
      return { stockCovers: nextProps.stockCovers };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.stockCovers.length && this.state.stockCovers.length) {
      const eventCover = this.props.eventCover;
      const newState = {};

      if (eventCover) newState['activeIndex'] = 0;

      this.setState(newState, () => {
        this.handleCoverSelect();
      });
    }
  }

  handleCoverSelect = () => {
    const carouselCovers = this.getCarouselCovers();
    const { activeIndex } = this.state;
    const { onInputChange, eventCover } = this.props;
    const activeCover = carouselCovers[activeIndex];

    if (activeCover && (eventCover !== activeCover.url)) {
      onInputChange({ newCover: activeCover.url });
    } else {
      onInputChange({ newCover: null });
    }
  };

  navigateToNewCustomCover = () => {
    if (this.state.newCustomCover) {
      this.goToIndex(0);
    }
  };

  handleUploadImage = imageUrl => {
    this.setState({ newCustomCover: imageUrl }, () => {
      this.navigateToNewCustomCover();
    });
  };

  nextSlide = () => {
    const carouselCovers = this.getCarouselCovers();
    const { activeIndex } = this.state;

    if (this.animating) return;

    const nextIndex = activeIndex === carouselCovers.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex }, () => this.handleCoverSelect());
  };

  previousSlide = () => {
    const carouselCovers = this.getCarouselCovers();
    const { activeIndex } = this.state;

    if (this.animating) return;

    const nextIndex = activeIndex === 0 ? carouselCovers.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex }, () => this.handleCoverSelect());
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex }, () => this.handleCoverSelect());
  }

  renderSlides = () => {
    const carouselCovers = this.getCarouselCovers();

    return carouselCovers.map(cover => (
      <CarouselItem
        key={cover.id || cover.url}
        onExiting={() => { this.animating = true; }}
        onExited={() => { this.animating = false; }}
      >
        <div
          className="slide"
          style={{ backgroundImage: `url(${cover.url})` }}
        />
      </CarouselItem>
    ));
  };

  getCarouselCovers = (stock, event, custom) => {
    const { journeyCoverImage } = this.props;
    const stockCovers = stock ? stock : this.props.stockCovers || [];
    const eventCover = event ? event : this.props.eventCover;
    const newCustomCover = custom ? custom : this.state.newCustomCover;

    const insertCovers = [];

    if (journeyCoverImage && !newCustomCover) {
      insertCovers.push({ url: journeyCoverImage });
    } else if (journeyCoverImage && newCustomCover) {
      insertCovers.push({ url: newCustomCover }, { url: journeyCoverImage });
    } else if (newCustomCover) {
      insertCovers.push({ url: newCustomCover });
    } else if (eventCover) {
      insertCovers.push({ url: eventCover });
    }

    return [...insertCovers, ...stockCovers];
  }

  render() {
    const { activeIndex } = this.state;
    const { className, loading, settings: { features } } = this.props;

    const covers = this.getCarouselCovers();

    if (loading) {
      return <div className={`${className} loader`} />;
    }

    return (
      <div className={className}>
        {features.custom_event_cover_photos && (
          <EventCreationCoverUploader
            onUploadImage={this.handleUploadImage}
          />
        )}
        <Carousel
          activeIndex={activeIndex}
          next={this.nextSlide}
          previous={this.previousSlide}
          interval={false}
          keyboard={false}
        >
          <CarouselIndicators
            items={covers}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {this.renderSlides()}
          <CarouselControl direction="prev" onClickHandler={this.previousSlide} />
          <CarouselControl direction="next" onClickHandler={this.nextSlide} />
        </Carousel>
      </div>
    );
  }
}

const load = keyframes`
  0% {
    opacity: 1;
    right: 100%;
  }
  40% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 0;
  }
`;

const StyledEventCreationCoverSelector = styled(EventCreationCoverSelector)`
  background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
  height: 100%;

  @media (${breakpoints.tablet}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.loader {
    background-color: #f0f0f0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background-color: #ececec;
      animation: ${load} 1.4s infinite ease-in-out;
    }
  }

  .slider {
    &::after {
      content: '';
      display: block;
      clear: both;
    }

    &__container {
      position: relative;
      overflow-x: hidden;
    }
  }

  .slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;

    .carousel-inner,
    .carousel-item {
      height: 100%;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    cursor: pointer;
    background-repeat: repeat-x;
  }

  .carousel-control-prev {
    background-image: linear-gradient(90deg,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001));
  }

  .carousel-control-next {
    background-image: linear-gradient(90deg,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5));
  }

  .carousel-indicators {
    li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      margin-left: 4px;
      text-indent: -999px;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid #fff;
      opacity: 1;

      &.active {
        background-color: #fff;
      }
    }
  }
`;

const StyledEventCreationCoverSelectorWithSettings = withSettings(StyledEventCreationCoverSelector);

export { StyledEventCreationCoverSelectorWithSettings as EventCreationCoverSelector };
