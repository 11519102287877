import validators from './StatusValidators';
import { stringInsert, whitelabel } from '../../libs';

const notificationStatusMap = (email, copy) => ({
  mandatory_email_zip_and_bio: {
    message: copy.notification_bar.mandatory_email_zip_and_bio || 'A valid email, zip code and bio is required. Please',
    isPersistent: true
  },
  mandatory_email_and_zip: {
    message: copy.notification_bar.mandatory_email_and_zip,
    isPersistent: true
  },
  mandatory_zip: {
    message: copy.notification_bar.mandatory_zip,
    isPersistent: true
  },
  email_unable: {
    message: copy.notification_bar.email_unable,
    isPersistent: true
  },
  change_email: {
    message: stringInsert(copy.notification_bar.change_email, email),
    isPersistent: false
  },
  none: {
    message: null,
    isPersistent: false
  }
});

const notificationStatus = (session, settings, show) => {
  const { email, profile, mustChangeEmail } = session.viewer.user;
  const { zipCode, emailStatus /*, bio */ } = profile;

  const requiresEmailChange = validators.requiresEmailChange(email, mustChangeEmail);
  const possibleEmailChange = validators.possibleEmailChange(mustChangeEmail, show);
  const requiresZip = whitelabel.hasFeature(settings, 'hideZipCode')
    ? false
    : validators.requiresZip(zipCode);

  // const requiresBio = validators.requiresBio(bio);
  const noneStatus = validators.noneStatus(emailStatus);

  let status = 'none';

  // (bio isnt allways required) //

  // if (requiresEmailChange && requiresZip && requiresBio) {
  //   status = 'mandatory_email_zip_and_bio';
  // } else

  if (requiresEmailChange && requiresZip) {
    status = 'mandatory_email_and_zip';
  } else if (requiresZip) {
    status = 'mandatory_zip';
  } else if (requiresEmailChange) {
    status = 'email_unable';
  } else if (possibleEmailChange) {
    status = 'change_email';
  } else if (noneStatus) {
    status = 'none'
  }

  return status;
};

const notificationMessage = (session, settings, show) => {
  const { copy } = settings;
  const { email } = session.viewer.user;
  const status = notificationStatus(session, settings, show);
  const notification = notificationStatusMap(email, copy)[status];

  notification.hasNotification = status !== 'none';
  notification.clickHere = copy.notification_bar.click_here;
  notification.update = copy.notification_bar.update;
  notification.dismiss = copy.notification_bar.dismiss;

  return notification;
};

export default notificationMessage;
