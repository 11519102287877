const uploadToS3 = (s3Credentials, file) => {
  return new Promise(function(resolve, reject) {
    const formData = new FormData();
    const {
      policy,
      signature,
      credential,
      uri,
      key,
      acl,
      date,
      algorithm
    } = s3Credentials;

    formData.append('acl', acl);
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('x-amz-algorithm', algorithm);
    formData.append('x-amz-credential', credential);
    formData.append('x-amz-date', date);
    formData.append('x-amz-signature', signature);
    formData.append('success_action_status', 201);
    formData.append('file', file);

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const responseDoc = xhr.responseXML;
        const imageUrlMatches = responseDoc.documentElement.outerHTML.match(/<Location>(.+?)<\/Location>/);

        if (imageUrlMatches && imageUrlMatches[1]) {
          const imageUrl = imageUrlMatches[1];
          resolve(imageUrl);
        } else {
          reject(Error('S3 upload error'));
        }
      }
    };

    xhr.open('POST', uri, true);
    xhr.send(formData);
  });
}

export { uploadToS3 };
