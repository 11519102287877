import React from 'react';
import styled from 'styled-components';

const CommunityAvatar = ({ className, logo, size }) => {
  const avatarSize = size ? size : 'md';

  return (
    <div
      className={`${className} ${avatarSize}`}
      style={{ backgroundImage: `url(${logo})` }}
    />
  );
}

const StyledCommunityAvatar= styled(CommunityAvatar)`
  margin: 0 auto 10px;
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);

  &.sm {
    width: 44px;
    height: 44px;
  }

  &.md {
    width: 60px;
    height: 60px;
  }
`;

export { StyledCommunityAvatar as CommunityAvatar };
