import gql from 'graphql-tag';

export const CREATE_EVENT_CREATION_TRACKING = gql`
  mutation createEventCreationTrackingEvent (
    $eventId: Int,
    $trackingEventType: EventCreationTrackingEventType!,
    $dateSelected: ISO8601Date,
    $nextDateSelected: ISO8601Date,
    $nextActionTaken: String
  ) {
    createEventCreationTrackingEvent (input: {
      eventId: $eventId,
      trackingEventType: $trackingEventType,
      dateSelected: $dateSelected,
      nextDateSelected: $nextDateSelected,
      nextActionTaken: $nextActionTaken
    }) {
      id
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_EVENT_CREATION_TRACKING = gql`
  mutation updateEventCreationTrackingEvent (
    $id: Int!,
    $eventId: Int,
    $trackingEventType: EventCreationTrackingEventType,
    $dateSelected: ISO8601Date,
    $nextDateSelected: ISO8601Date,
    $nextActionTaken: String
  ) {
    updateEventCreationTrackingEvent (input: {
      id: $id,
      eventId: $eventId,
      trackingEventType: $trackingEventType,
      dateSelected: $dateSelected,
      nextDateSelected: $nextDateSelected,
      nextActionTaken: $nextActionTaken
    }) {
      errors {
        message
        path
      }
    }
  }
`;

export const DESTROY_EVENT_CREATION_TRACKING = gql`
  mutation (
    $id: Int!
  ) {
    destroyEventCreationTrackingEvent (input: {
      id: $id
    }) {
      errors {
        message
        path
      }
    }
  }
`;
