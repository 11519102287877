import React from 'react';
import styled from 'styled-components';
import { WYSIWYGEditor } from '../components';

const EventInvitationMessage = (props) => {
  const { className, placeholder, message, onInputChange } = props;

  return (
    <div className={className}>
      <WYSIWYGEditor
        name='message'
        value={message}
        onInputChange={onInputChange}
        placeholder={placeholder}
      />
    </div>
  );
}

const StyledEventInvitationMessage = styled(EventInvitationMessage)`
  // .wysiwyg-editor {
  //   margin-bottom: 14px;
  // }
`;

export { StyledEventInvitationMessage as EventInvitationMessage };
