import React, { useContext } from 'react';
import styled from 'styled-components';

import {
  SearchFilters
} from '../components';

import { FilterContext } from './FilterContext';


const EventSearchFilterModalFilters = ({ className }) => {
  const { currentModalFilters } = useContext(FilterContext);

  return (
    <div className={className}>
      {Array.isArray(currentModalFilters)
        ? currentModalFilters.map((f, i) => (
          <FilterContainer key={i}>
            <SearchFilters filterKey={f} />
          </FilterContainer>
        ))
        : null}
    </div>
  );
};

const StyledEventSearchFilterModalFilters = styled(EventSearchFilterModalFilters)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  row-gap: 0;
`;

const FilterContainer = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #e3e3e1;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    padding-top: 0;
  }

  &:nth-last-of-type(1),
  &:nth-last-of-type(2) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export { StyledEventSearchFilterModalFilters as EventSearchFilterModalFilters };
