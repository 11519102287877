const ShareTwitter = {
    openModal: (url) => {
        const encodedUrl = encodeURIComponent(url);
        const twitterBaseUrl = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
        const newWindow = window.open(twitterBaseUrl, 'name', 'height=397,width=550');
        if (window.focus) {newWindow.focus()}
    }
}

export { ShareTwitter };
