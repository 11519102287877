import React from 'react';

import { JourneyRadioButtons, JourneyHoverOptions } from '../components';

export const JourneyPageField = ({
  widget,
  value,
  label,
  multiple,
  data,
  pageAction,
  hasAnswered,
  hasConfirmed,
  handleGoToNextPage,
  updateQuestionsState
}) => {
  const {
    // id: widgetId,
    __typename: widgetType,
    // default: defaultOptionValue,
    // placeholder,
    options,
    // size,
    question: { id, prefill }
  } = widget;

  // console.log('widget', widget);

  // const handleFieldUpdate = e => updateQuestionsState(id, e.target.value, '', prefill, multiple);
  const handleSelectUpdate = (val, lab, hover) => updateQuestionsState(id, val, lab, prefill, multiple, hover);

  switch (widgetType) {
    // TEXT //

    case 'RadioWidget-old':
      return (
        <JourneyRadioButtons
          options={options}
          value={value}
          label={label}
          onChange={handleSelectUpdate}
        />
      );

    case 'RadioWidget':
      return (
        <JourneyHoverOptions
          options={options}
          value={value}
          label={label}
          onChange={handleSelectUpdate}
          data={data}
          goToNextPage={handleGoToNextPage}
          pageAction={pageAction}
          hasAnswered={hasAnswered}
          hasConfirmed={hasConfirmed}
        />
      );

    default:
      return null;
  }
};
