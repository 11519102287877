import React, { useContext } from 'react';
import { DropDownMenu, FAIcon, withSettings } from '../components';

import { SessionContext } from '../../Context';


const AreaDropdown = ({ settings, area, onSelectArea, onToggleDropdown }) => {
  const { copy, event: { virtual } } = settings;

  const { session } = useContext(SessionContext);
  const areas = session && session.areas;

  if (areas && areas.length > 0) return (
    <DropDownMenu
      toggle={onToggleDropdown}
      stopPropagation
      backdrop
    >
      {virtual.display_virtual_menu_link && (
        <li key="virtual" onClick={() => onSelectArea('virtual')}>
          <span>{copy.menu.virtual_area_link_text}</span>
          {area === 'virtual' && <FAIcon name={['fas', 'check']} />}
        </li>
      )}
      {Array.isArray(areas) && areas.map(a => (
        <li
          key={a.id}
          onClick={() => onSelectArea(a)}
        >
          <span>{a.label}</span>
          {area.id === a.id && <FAIcon name={['fas', 'check']} />}
        </li>
      ))}
    </DropDownMenu>
  );

  else return null;
};

const AreaDropdownWithSettings = withSettings(AreaDropdown);
export { AreaDropdownWithSettings as AreaDropdown };
