import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const HistoryContext = React.createContext();

const HistoryContextProvider = ({ children }) => {
  const history = useHistory();
  const [stack, setStack] = useState([]);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      setStack(prev => [...prev, pathname]);
    });
    return unlisten;
  });

  const value = {
    stack,
    getLastPage: () => stack[stack.length - 2] // -2 to not count current page //
  };

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};

export { HistoryContext, HistoryContextProvider };
