import { isSite } from './settings';
import { EventCreationFormHeader } from '../components/EventCreation/views/EventCreationFormHeader';
import { EventCreationFormLocation } from '../components/EventCreation/views/EventCreationFormLocation';
import { EventCreationFormLocation as JewishFoodFestEventCreationFormLocation } from '../components/WhiteLabels/jewishfoodfest/EventCreation/EventCreationFormLocation';
import { EventCardLocation, ProfileFieldsExtra } from '../components';
import { EventCardLocation as hineniEventCardLocation } from '../components/WhiteLabels/hineni/EventCard/EventCardLocation';
import { ProfileFieldsExtra as hillelProfileFieldsExtra } from '../components/WhiteLabels/hillel/Profile/ProfileFieldsExtra';

/***
*
* Should move away from this!
*
***/

const whitelabel = {
    event: {
        create: {
            form: {
                header: () => {
                    return EventCreationFormHeader;
                },
                location: (settings) => {
                    if (isSite(settings, 'jewishfoodfest')) {
                        return JewishFoodFestEventCreationFormLocation;
                    }
                    else {
                        return EventCreationFormLocation;
                    }
                }
            }
        },

        card: {
            body: {
                location: (settings) => {
                    if (isSite(settings, 'hineni')) {
                        return hineniEventCardLocation;
                    }
                    else {
                        return EventCardLocation;
                    }
                }
            }
        }
    },

    profile: {
        extraQuestions: (settings) => {
          if (isSite(settings, 'hillel')) {
              return hillelProfileFieldsExtra;
          } else {
              return ProfileFieldsExtra;
          }
        }
    }
}

export default whitelabel;
