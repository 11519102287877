import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useSettings } from '../../hooks';
import { stringInsert, isValidEmail } from '../../libs';

import { on, off } from '../../libs/customEvent';

import {
  FirstLastNameField,
  InputField,
  UnderlineButton as Button,
  withSettings
} from '../components';


export const RsvpPageAdditionalGuest = ({
  guest,
  guestNumber,
  numberOfGuests,
  onUpdateGuest,
  onRemoveGuest
}) => {
  const { firstName, lastName, email } = guest;
  const { copy } = useSettings();

  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);

  const handleInputChange = (name, value) => {
    onUpdateGuest(guestNumber - 1, name, value);
    if (name === 'firstName' && value) setValidFirstName(true);
    if (name === 'lastName' && value) setValidLastName(true);
    if (name === 'email' && isValidEmail(value)) setValidEmail(true);
  };

  const invalidateField = ({ detail = {} }) => {
    const { index, name, key } = detail;
    if (name !== 'additionalGuests' || index !== guestNumber - 1) return;
    if (key === 'firstName') setValidFirstName(false);
    if (key === 'lastName') setValidLastName(false);
    if (key === 'email') setValidEmail(false);
  };

  useEffect(() => {
    on('rsvpPage:validateGuest', invalidateField);
    return () => off('rsvpPage:validateGuest', invalidateField);
  }, []);

  return (
    <Container>
      <GuestHeader top={guestNumber === 1}>
        <h3>{stringInsert(copy.rsvp.guest_number || 'Guest %1$s', guestNumber)}</h3>
        {(guestNumber > 1 || numberOfGuests > 1) && (
          <Button onClick={() => onRemoveGuest(guestNumber - 1)} icon="remove">
            {copy.rsvp.remove_guest || 'Remove'}
          </Button>
        )}
      </GuestHeader>
      <FirstLastNameField
        value={{ firstName, lastName }}
        valid={{ firstName: validFirstName, lastName: validLastName }}
        errorMessages={{ firstName: 'Enter guest\'s first name.', lastName: 'Enter guest\'s last name.' }}
        onChange={handleInputChange}
      />
      <InputField
        type="email"
        name="email"
        value={email}
        placeholder="Email"
        valid={validEmail}
        onChange={handleInputChange}
      />
    </Container>
  );
};

const Container = styled.div`max-width: 550px;`;

const GuestHeaderWithoutSettings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  ${props => props.top ? css`
    margin-top: 30px;
  ` : css`
    margin-top: 20px;
  `}

  h3 {
    font-size: 16px;
  }
`;
const GuestHeader = withSettings(GuestHeaderWithoutSettings);
