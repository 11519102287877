import { Enumerated } from './Enumerated';

export class RSVP_CAPABILITY extends Enumerated {
  static CAN_RSVP = 'CAN_RSVP';
  static HOST_VIEWER = 'HOST_VIEWER';
  static RSVP_PENDING = 'RSVP_PENDING';
  static RSVP_ACCEPTED = 'RSVP_ACCEPTED';
  static RSVP_REJECTED = 'RSVP_REJECTED';
  static RSVP_CANCELED = 'RSVP_CANCELED';
  static EVENT_FULL = 'EVENT_FULL';
  static INVITE_PENDING = 'INVITE_PENDING';
  static INVITE_REJECTED = 'INVITE_REJECTED';
  static INVITE_ACCEPTED = 'INVITE_ACCEPTED';
  static INVITE_CANCELED = 'INVITE_CANCELED';
}
