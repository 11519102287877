import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import { HistoryContextProvider } from './HistoryContext';
import { Header, Footer, Loader, PageContainer } from './components';
import { useFacebookInit, useHeapInit, useAppcuesInit, useGlobalUrlParams } from './hooks';

const App = () => {
  useFacebookInit();
  useHeapInit();
  useAppcuesInit();
  
  // It needs to be execute before rendering all auth routes since some clients can login
  // using an oauth token
  const { isLoadingOauthTokenLogin } = useGlobalUrlParams();

  return (
    <>
      <Header />
      <PageContainer>
        { isLoadingOauthTokenLogin ? <Loader /> : <Routes /> }
        <Footer />
      </PageContainer>
    </>
  );
};

const AppWithRouter = props => (
  <Router>
    <HistoryContextProvider>
      <App {...props} />
    </HistoryContextProvider>
  </Router>
);

export default AppWithRouter;
