import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Avatar, WYSIWYGEditor, Spinner, withSettings } from '../components';
import { breakpoints } from '../../styles';

class EventGuestsReservationItem extends React.Component {

  state = {
    approveMessage: '',
    declineMessage: ''
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  renderActionForm = () => {
    const {
      cancelActionButtonClicked,
      sendApprovalButtonClicked,
      sendDeclineReservationButtonClicked,
      showApproveForm,
      showDeclineForm,
      settings: { copy },
      loading,
      reservation
    } = this.props;

    if (!showApproveForm && !showDeclineForm) return null;

    const fieldName = showApproveForm ? 'approveMessage' : 'declineMessage';
    const message = this.state[fieldName];
    const sendFn = showApproveForm ? sendApprovalButtonClicked : sendDeclineReservationButtonClicked;
    const sendBtnCopy = showApproveForm ? copy.manage.action_approve2 : copy.manage.action_deny2;

    return (
      <div className="approve-form float_clear">
        <WYSIWYGEditor
          name={fieldName}
          value={message}
          onInputChange={this.handleInputChange}
          placeholder={copy.create.message}
        />
        <div className="approve-form__buttons">
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <span
                className="actionable-span"
                onClick={() => cancelActionButtonClicked(reservation.id)}
              >{copy.manage.cancel_message}</span>
              <span className="divider">|</span>
              <span
                className="actionable-span"
                onClick={() => sendFn(reservation.id, message)}
              >{sendBtnCopy}</span>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      className,
      reservation,
      showGuestsList,
      reservationGuestsButtonClicked,
      actionButtonClicked,
      settings
    } = this.props;

    const isPastEvent = false;
    const profile = reservation ? reservation.profile : null;
    const hasFacebookProfileAssociated = (profile && profile.facebookId && profile.facebookId.length > 0);
    const email = (profile && profile.user && profile.user.email) ? profile.user.email : '';

    let fullName = (profile && profile.firstName) ? profile.firstName : '';
    fullName = (profile && profile.lastName) ? (fullName.length > 0 ? `${fullName} ${profile.lastName}` : profile.lastName) : fullName;

    const additionalGuests = reservation && reservation.additionalGuests ? reservation.additionalGuests : [];

    const { copy } = settings;
    const images = settings.theme.assets.images;

    return (
      <div className={className}>
        <div className="ot-inner-container">
          <Avatar profile={profile} size="sm" layout="vertical" linked noName ></Avatar>
          <div className="ot-guest-info">
            <div className="item">
              <h4>{fullName}</h4>
            </div>
            {hasFacebookProfileAssociated && (
              <div className="item facebook-item">
                <a
                  className="facebook-icon"
                  href={`https://www.facebook.com/${profile.facebookId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundImage: `url(${images.social.facebook_circle})`
                  }}
                ><span/></a>
              </div>
            )}
            {email && (
              <div className="item">
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
            <div className="item guests">
              {additionalGuests.length > 0 && (
                <span onClick={() => reservationGuestsButtonClicked(reservation.id)}> + {additionalGuests.length} seats</span>
              )}
            </div>
            {!isPastEvent && (
              <div className="item last reservation-actions">
                {reservation.state === 'PENDING' && (
                  <Fragment>
                    <span
                      className="actionable-span"
                      onClick={() => actionButtonClicked(reservation.id, 'show-approve-form')}
                    >{copy.manage.action_approve}</span>
                    <span className="divider">|</span>
                    <span
                      className="actionable-span"
                      onClick={() => actionButtonClicked(reservation.id, 'show-decline-form')}
                    >{copy.manage.action_deny}</span>
                  </Fragment>
                )}

                {reservation.state === 'ACCEPTED' && (
                  <span>{copy.manage.guest_attending}</span>
                )}
              </div>
            )}
          </div>
        </div>
        {showGuestsList && (
          <div className="guests-container">
            {additionalGuests.map((guest, i) => {
              return (
                <div key={i}>
                  <h4 className="user-name">{guest.name}</h4>
                  <h5><a className="user-mail-bis" href="mailto:{{guest.email}}">{guest.email}</a></h5>
                </div>
              )
            })}
          </div>
        )}
        {this.renderActionForm()}
      </div>
    );
  }
}

const StyledEventGuestsReservationItem = styled(EventGuestsReservationItem)`
  text-align: center;
  width: 100%;

  & .row {
    margin: 0;
  }

  & .ot-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1em;

    @media (${breakpoints.modal}) {
      display: block;
    }
  }

  & .ot-guest-info {
    display: flex;
    align-items: center;
    width: 100%;

    @media (${breakpoints.modal}) {
      display: block;
    }

    h4 {
      color: ${props => props.settings.theme.css.global.colors.primaryDark};
      font-size: 13px;
      margin: 0;
    }

    .item {
      display: flex;
      flex-basis: 0;
      flex-grow: 20;
      margin: 0 5px;

      @media (${breakpoints.modal}) {
        display: inline-block;
        vertical-align: middle;
        margin: 3px 5px;

        &.guests,
        &.last {
          display: block;
        }
      }
    }
  }

  & .ot-guest-info a {
    cursor: pointer;
  }

  & .ot-guest-info a,
  & .ot-guest-info span {
    font-size: 13px;
    color: #555;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & a:hover {
    text-decoration: underline;
  }

  & .reservation-actions a {
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-weight: bold;
    font-size: 12px;
  }

  & .approve-form a {
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }

  & .ot-guest-info span {
    font-size: 12px;
  }

  & .guests-container {
    background-color: white;
    margin: 1em;
    padding: 1em;
    text-align: left;
    border: 1px solid #EEE;
  }

  & .guests-container h4 {
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    font-size: 13px;
    display: inline-block;
  }

  & .guests-container h5 {
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-size: 13px;
    display: inline-block;
    margin-left: 1em;
  }

  & .guests-container a {
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-size: 13px;
  }

  & .approve-form {
    padding: 0em 2em;
  }

  & textarea {
    height: 90px;
  }

  & .approve-form__buttons {
    float: right;
    display: block;
    margin-bottom: 1em;
  }

  & .approve-form__buttons p {
    margin: 0 1em;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    display: inline-block;
  }

  & .actionable-span {
    cursor: pointer;

    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primaryHover}
    }
  }

  & .approve-form__buttons .actionable-span,
  & .reservation-actions .actionable-span {
    margin: 0 10px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    display: inline-block;

    &.divider {
      color: ${props => props.settings.theme.css.global.colors.textLight};
      cursor: default;
      margin: 0;
    }
  }

  & .approve-form__buttons .actionable-span {
    font-size: 13px;
  }

  & .facebook-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  & .item.last {
    justify-content: flex-end;
  }

`;

const EventGuestsReservationItemWithSettings = withSettings(StyledEventGuestsReservationItem);

export { EventGuestsReservationItemWithSettings as EventGuestsReservationItem };
