const currentYear = 2020; // new Date().getFullYear();

export const registrationQuestions = [
  {
    type: 'select',
    name: 'howDidYouHear::seder',
    other: true,
    choices: {
      a: 'Jewish Grandparents Network',
      b: 'Federation',
      c: 'Synagogue',
      d: 'JCC',
      e: 'Media/Blog/News',
      f: 'Friend/Family',
      g: 'Haggadot.com',
      h: 'OneTable',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'howDidYouHear::jewishfoodfest',
    other: true,
    choices: {
      a: 'Friend/family',
      b: 'Social Media',
      c: 'Local Jewish Organization',
      d: 'National Jewish Organization',
      e: 'Event Presenter',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'howDidYouHear::onetable',
    other: true,
    choices: {
      a: 'A friend',
      b: 'An event I attended',
      c: 'Another organization',
      d: 'OneTable staff member',
      e: 'Online',
      f: 'Glowing Up podcast',
      g: 'The Study podcast',
      h: 'PAUSE episode',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'howDidYouHearOrganization::onetable',
    other: true,
    choices: {
      a: '18Doors',
      b: 'AEPi',
      c: 'Avodah',
      d: 'BBYO',
      e: 'Birthright',
      f: 'BRI Excel',
      g: 'Bring Israel Home',
      h: 'Challah for Hunger',
      i: 'Combined Jewish Philanthropies (CJP Boston)',
      j: 'Congregation Ahavas Israel, Grand Rapids',
      k: 'Congregation Beth Israel, Houston',
      l: 'Conversation Shabbat',
      m: 'EDCJCC Shabbat Clusters',
      n: 'FIDF',
      o: 'Honeymoon Israel',
      p: 'IKAR',
      q: 'JDC Entwine',
      r: 'Jewish Federation of Grand Rapids',
      s: 'Jewish Federation of Greater Los Angeles/NuRoots',
      t: 'Jewish Federation of Greater Seattle',
      u: 'Jewish Federation of Metropolitan Detroit',
      v: 'Jewish Federation of Orange County',
      w: 'Jewish Federation of San Antonio',
      x: 'Masa Israel',
      y: 'Mishkan',
      z: 'Moishe House',
      aa: 'My Camp/Foundation for Jewish Camp',
      ab: 'NEXTGen Detroit',
      ac: 'Onward',
      ad: 'OU NextDor',
      ae: 'REALITY',
      af: 'Reboot',
      ag: 'Repair the World',
      ah: 'Revolve',
      ai: 'ROI',
      aj: 'The Network at the Greater Miami Jewish Federation',
      ak: 'The Well',
      al: 'Union for Reform Judaism (URJ)',
      am: 'Urban Adamah',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'iAm::hillel',
    restrictFromSite: ['hga', 'international'],
    other: true,
    choices: {
      a: '{{siteLabel}} Undergrad Student',
      b: '{{siteLabel}} Graduate Student',
      c: '{{siteLabel}} Alumni',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'iAmAlt::hillel',
    restrictToSite: ['hga'],
    other: true,
    choices: {
      a: 'Undergrad Student',
      b: 'Graduate Student',
      c: 'Alumni',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'iAmHillel::hillel',
    restrictToSite: ['international'],
    other: true,
    choices: {
      a: 'Undergraduate Student',
      b: 'Graduate Student',
      c: 'Young Adult',
      d: 'Hillel Staff',
      e: 'Prospective Student',
      f: 'Community Member',
      g: 'Parent',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'iAmCampus::hillel',
    other: true,
    choices: {
      h: 'Agnes Scott',
      a: 'Emory University',
      f: 'Georgia College',
      c: 'Georgia State',
      g: 'Georgia Southern',
      b: 'Georgia Tech',
      d: 'Kennesaw State',
      i: 'Oglethorpe',
      e: 'University of Georgia',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'gradYear::hillel',
    // other: true,
    choices: {
      // leaving currentYear as is to keep the the shown years up to date but not change the keys
      // for existing gradYears
      // a: String(currentYear),
      // b: String(currentYear + 1),
      c: String(currentYear + 2),
      d: String(currentYear + 3),
      e: String(currentYear + 4),
      f: String(currentYear + 5),
      g: String(currentYear + 6),
      h: String(currentYear + 7),
      i: String(currentYear + 8),
      j: String(currentYear + 9),
      k: String(currentYear + 10),
      l: String(currentYear + 11),
      m: String(currentYear + 12)
    }
  },
  {
    type: 'select',
    name: 'jewish::hillel',
    restrictFromSite: ['cornell', 'international'],
    other: true,
    choices: {
      a: 'Reform',
      b: 'Conservative',
      c: 'Orthodox',
      d: 'Traditional Egalitarian',
      e: 'Reconstructionist',
      f: 'Renewal',
      g: 'Just Jewish',
      h: 'Not Jewish',
      other: 'Other'
    }
  },
  {
    type: 'select',
    name: 'are_you_jewish::hillel',
    // other: true,
    restrictToSite: ['cornell', 'international'],
    choices: {
      a: 'Yes',
      b: 'No',
      c: 'It\'s Complicated',
      d: 'Prefer Not to Answer'
    }
  },
  {
    type: 'checkbox',
    name: 'acceptTerms::dinner-party',
    choices: {}
  },
  {
    type: 'checkbox',
    name: 'acceptValues::dinner-party',
    choices: {}
  },
];
