import gql from 'graphql-tag';

export const pageSections = `
  pageSections {
    __typename
    id
    title
    content
    links {
      id
      url
      label
      style
      position
    }
    position
    visibility
  }
`;

export const LANDING_PAGE = gql`
  query landingPage($url: String!) {
    landingPage(url: $url) {
      id
      title
      ${pageSections}
      areas {
        id
        url
        label
        photo { largeUrl }
      }
    }
  }
`;
