import styled from 'styled-components';

export const OpacityHover = styled.span`
  opacity: 0.6;
  transition: opacity 250ms;

  &:hover {
    opacity: 1;
  }
`;
