import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Card,
  EventTypeIcon,
  Attendees,
  Html
} from '../components';

import { withSettings } from '../components';

import {
  hasDescription,
  hasExtraDetails,
  hasTypes,
  hasTags,
  hasReservations,
  hasTables,
  replaceUrlsWithAnchor,
  isSite
} from '../../libs';

import { breakpoints } from '../../styles';


const EventViewDetails = props => {
  const { className, display, event, history, viewer, isHostOrReservedGuest, settings, toggleLoginModal, showAttendeesModal } = props;
  const { settings: { copy, event: eventSettings } } = props;

  const handleTagClick = tag => history.push(`/tags/${tag.toLowerCase().replace('/', '%2F')}`);

  const reservations = (event.reservations.edges || []).map(edge => edge.node);
  const guestsAccepted = event.reservations.guestsAccepted;

  const hasPermissionToViewExtraDetails = isSite(settings, ['climatefest']) || isHostOrReservedGuest;

  return display
    ? (
      <Card className={className} id="details">
        <h2 className="card_title">{copy.event.about_title}</h2>
        <i className="divider" />

        {/* Description */}
        {hasDescription(event) && (
          <div className="event_description">
            <Html content={event.description} />
          </div>
        )}

        {/* Extra Details */}
        {eventSettings.extra_details_enabled && hasExtraDetails(event) && hasPermissionToViewExtraDetails && (
          <div className="event_description">
            <h4 className="sub_heading">{copy.event.extra_details_title}</h4>
            <Html content={event.extraDetails} />
          </div>
        )}

        {/* Virtual Location */}
        {event.virtual && event.virtualLocation && (
          <Fragment>
            <h4 className="sub_heading">{copy.event.virtual_event_details_title}</h4>
            <p className="virtual_location" dangerouslySetInnerHTML={{ __html: replaceUrlsWithAnchor(event.virtualLocation) }} />
          </Fragment>
        )}

        {/* Virtual Resource */}
        {(eventSettings.always_show_resource_url_field || event.virtual) && event.virtualResourceUrl && (
          <Fragment>
            <h4 className="sub_heading">{copy.event.virtual_resource_title}</h4>
            <p className="virtual_location">{event.virtualResourceUrl}</p>
          </Fragment>
        )}

        {/* Tags */}
        {hasTags(event) && (
          <Fragment>
            <h4 className="sub_heading">{copy.event.tag_title}</h4>
            <ul className="tags_list">
              {event.tags.map((tag, i) => (
                <li
                  key={i}
                  onClick={() => handleTagClick(tag)}
                >{tag.toLowerCase()}</li>
              ))}
            </ul>
          </Fragment>
        )}

        {/* Type Icons */}
        {hasTypes(event) && (
          <div className="event_type_icons">
            <h4 className="sub_heading">{copy.event.type_title}</h4>
            {event.alcoholPolicy && <EventTypeIcon name={event.alcoholPolicy} type="alcohol_policy" />}
            {event.dietaryRestrictions.length > 0 && event.dietaryRestrictions.map((diet, i) => (
              <EventTypeIcon key={i} name={diet} type="dietary_restrictions" />
            ))}
            {event.dressCodeName && <EventTypeIcon name={event.dressCodeName} type="dress_codes" />}
          </div>
        )}

        {/* Attendees */}
        {(!event.hideGuestList && viewer && hasReservations(event) && !hasTables(event)) && (
          <Fragment>
            <h4 className="sub_heading">{copy.event.attendants_title}</h4>
            <Attendees
              className="attendees"
              event={event}
              reservations={reservations}
              totalGuestsAccepted={guestsAccepted}
              toggleLoginModal={toggleLoginModal}
              showAttendeesModal={showAttendeesModal}
              size="lg"
            />
          </Fragment>
        )}
      </Card>
    ) : null;
};


const StyledEventViewDetails = styled(EventViewDetails)`
  .event_description {
    font-size: 14px;
    text-align: center;
    margin: 0;
    word-break: break-word;

    ul, ol {
      list-style-position: inside;
      padding: 0;

      br {
        display: none;
      }
    }

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      text-decoration: underline;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .virtual_location {
    font-size: 14px;
    text-align: center;
    margin: 0;

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      text-decoration: underline;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .tags_list {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      background-color: ${props => props.settings.theme.css.global.colors.primaryLight};
      border: 1px solid ${props => props.settings.theme.css.global.colors.primaryLightBorder};
      border-radius: 3px;
      margin: 3px;
      font-size: 13px;
      text-transform: capitalize;
      padding: 3px 10px 2px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: ${props => props.settings.theme.css.global.colors.primary};
      }
    }
  }

  .event_type_icons {
    text-align: center;

    .sub_heading {
      @media (${breakpoints.mobile}) {
        margin-bottom: 3px;
      }
    }

    .type_icon {
      @media (${breakpoints.mobile}) {
        width: 45%;
        margin: 15px 0 0;
      }
    }
  }

  .attendees {
    margin: 0;
  }
`;

const EventViewDetailsWithRouter = withRouter(StyledEventViewDetails);
const EventViewDetailsWithRouterWithSettings = withSettings(EventViewDetailsWithRouter);

export { EventViewDetailsWithRouterWithSettings as EventViewDetails };
