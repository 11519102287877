import React from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '../../components';
import { useSettings } from '../../../hooks';

export const ToggleButton = ({ children, tooltip, ...rest }) => {
  const { colors } = useSettings();

  return (
    <Button
      className="toggle_button"
      colors={colors}
      {...rest}
    >
      {children}
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </Button>
  );
};

const Button = styled.span`
  position: relative;
  display: inline-block;
  padding: 13px 20px;
  border: 1px solid #ceccc7;
  font-size: 15px;
  line-height: 1.8;
  transition: all 250ms;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    border-color: #000;

    .ot_tooltip {
      display: block;
    }
  }

  ${({ active, colors: { primary } }) => active && css`
    background-color: ${primary};
    border-color: ${primary} !important;
    color: #fff;

    .ot_tooltip {
      color: #221f20;
    }
  `}

  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}

  .ot_tooltip {
    right: -25px;
    left: -25px;
    padding: 8px 10px;
    font-size: 12px;
    bottom: calc(100% + 16px);
    text-align: center;
    display: none;
  }
`;
