import React from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { SIGN_UPLOAD } from '../../mutations';
import { FAIcon, withSettings } from '../components';
import { uploadToS3 } from '../../libs/uploaders';


class LogoUploader extends React.Component {
  state = {
    hovered: false,
    base64Image: null
  };

  componentDidMount() {
    const { isUpdate, logo, onInputChange } = this.props;

    if (isUpdate && logo) {
      this.setState({ base64Image: logo });
      onInputChange('uploadPartnerOrganizationLogo', false);
    }

    this.inputFile = React.createRef();
  }

  handleOnMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleFileChange = (event, signUpload) => {
    const { onInputChange } = this.props;
    const reader = new FileReader();
    const file = event.target.files[0];

    if (!file) return;

    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base64Image = e.target.result;
      this.setState({ base64Image });
    }

    signUpload()
      .then(response => {
        const s3Credentials = response.data.signUpload.uploadSignature;

        uploadToS3(s3Credentials, file, null)
          .then(imageUrl => onInputChange({
            partnerOrganizationLogo: imageUrl,
            uploadPartnerOrganizationLogo: true
          }))
          .catch(() => {
            // console.error(error);
            this.setState({ base64Image: null });
          });
      });
  };

  handleLogoClick = () => {
    this.inputFile.current.click();
  };

  getBackgroundImage = () => {
    const { base64Image } = this.state;
    const { settings } = this.props;
    const images = settings.theme.assets.images;
    return base64Image ? base64Image : images.default_logo;
  };


  render() {
    const { hovered } = this.state;
    const { className, label } = this.props;

    return (
      <Mutation mutation={SIGN_UPLOAD}>
        {(signUpload) => {
          return (
            <div className={className}>
              <input
                type="file"
                ref={this.inputFile}
                style={{ display: 'none' }}
                onChange={event => this.handleFileChange(event, signUpload)}
                accept="image/x-png,image/gif,image/jpeg"
              />
              <div
                className="logo__container"
                onClick={this.handleLogoClick}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
              >
                <div
                  className={`logo ${hovered ? 'hovered' : ''}`}
                  style={{ backgroundImage: `url(${this.getBackgroundImage()})` }}
                >
                  <FAIcon name={[ 'fas', 'pencil' ]} />
                </div>
                {label && <label className="logo__label">{label}</label>}
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  }
}

const StyledLogoUploader = styled(LogoUploader)`
  padding-top: 22px;
  text-align: center;

  .logo {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 44px;
    border: 1px solid #e4e4e4;
    border-radius: 50%;
    background-color: #f6f6f6;
    vertical-align: middle;
    background-position: center;
    background-size: cover;
    box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.03);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      opacity: 0;
      transition: opacity 250ms;
    }

    &.hovered {
      svg {
        opacity: 1;
      }
    }

    &__container {
      display: inline-block;
      cursor: pointer;
    }

    &__label {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 10px !important;
      padding: 0 !important;
      cursor: pointer;
    }
  }
`;

const LogoUploaderWithSettings = withSettings(StyledLogoUploader);

export { LogoUploaderWithSettings as LogoUploader };
