import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Checkbox } from '../';
import PropTypes from 'prop-types';
import {
  FAIcon,
  SelectBox,
  withSettings,
  withSession,
  alert,
  RsvpModalAddOtherGuests,
  WYSIWYGEditor
} from '../components';
import {
  getScheduledAtDate,
  getDeadlineAtDate,
  getEventTimeSpan,
  getDeadlineAtTime,
  imHost,
  imReserved,
  isSite,
  needsManualApproval,
  renderAddressLabel,
  renderSecondaryAddressLabel,
  isPotluck,
  getTablesOptions,
  getEventMaxAdditionalGuests,
  getBackgroundStyle,
  getGuestSelectorEligibility,
  isVaccinatedEvent,
  whitelabel
} from '../../libs';
import { DollarInput } from '../Global';
import { isValidName, isValidEmail } from '../../libs/validations';


const emptyGuestObj = { firstName: '', lastName: '', email: '' };

class RsvpMainModal extends React.Component {

  constructor(props) {
    super(props);

    const tablesOptions = getTablesOptions(props.event);

    this.state = {
      selectedTableId: (tablesOptions.length > 0 ? tablesOptions[0].id : null),
      willBeBringingOtherGuests: false,
      wouldLikeToMakeDonation: false,
      donationValue: 0,
      hostMessage: '',
      paidEventsEnabled: null,
      guests: [{ ...emptyGuestObj }],
      guestsErrors: [{ ...emptyGuestObj }],
      guestsVaccinated: false,
      codeOfConductTerm: false,
    }
  }

  componentDidMount() {
    this.setupFeatures();
  }

  setupFeatures = () => {
    const { settings: { features } } = this.props;
    const paidEventsEnabled = (features && (features.paid_events === true || features.paid_events === 'true'));
    this.setState({ paidEventsEnabled });
  }

  handleInputChange = (name, value) => {
    const { guestsErrors } = this.state;
    let sanitizedValue = value;

    if (name === 'donationValue' && value < 0) {
      sanitizedValue = 0;
    }

    const newState = { [name]: sanitizedValue };

    if (name === 'willBeBringingOtherGuests' && value && !guestsErrors.length) {
      newState.guestsErrors = [{ ...emptyGuestObj }];
    }

    this.setState(newState);
  };

  handleGuestInputChange = (index, name, value) => {
    const guests = [...this.state.guests];
    if (guests[index]) guests[index][name] = value;

    this.setState({ guests });
  };

  handleGuestInputFocus = (index, name) => {
    const guestsErrors = [...this.state.guestsErrors];
    if (guestsErrors[index]) guestsErrors[index][name] = '';

    this.setState({ guestsErrors });
  };

  addAnotherGuest = () => {
    const guests =  [...this.state.guests, { firstName: '', lastName: '', email: '' }];
    const guestsErrors = [...this.state.guestsErrors, { firstName: '', lastName: '', email: '' }];

    this.setState({ guests, guestsErrors });
  };

  removeGuest = (index) => {
    const guests = [...this.state.guests];
    guests.splice(index, 1);

    const guestsErrors = [...this.state.guestsErrors];
    guestsErrors.splice(index, 1);

    this.setState({ guests, guestsErrors });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  validateData = ({ wouldLikeToMakeDonation, donationValue, willBeBringingOtherGuests, guestsVaccinated }) => {
    const { event, settings } = this.props;
    const { copy } = settings;

    let errors = [];

    if (wouldLikeToMakeDonation) {
      if (donationValue <= 0 || isNaN(donationValue)) {
        errors = [...errors, copy.rsvp.invalid_donation_amount || 'Invalid donation amount'];
      }
    }

    const shouldBeVaccinated = isVaccinatedEvent(event);
    if (shouldBeVaccinated) {
      if (willBeBringingOtherGuests && !guestsVaccinated) {
        errors.push(copy.rsvp.no_guest_vaccinated_modal_message || 'Guests should be vaccinated')
      }
    }
    return errors;
  }

  validateGuests = (guests) => {
    const { settings, session: { viewer: { user } } } = this.props;
    const { copy } = settings;

    const guestsErrors = guests.map(guest => {
      const firstNameError = { text: copy.rsvp.invalid_guest_first_name, show: !isValidName(guest.firstName) }
      const lastNameError = { text: copy.rsvp.invalid_guest_last_name, show: !isValidName(guest.lastName) }
      let emailError = { text: copy.rsvp.invalid_guest_email, show: !isValidEmail(guest.email) }

      if (guest.email.toLowerCase() === user.email.toLowerCase()) emailError = {
        text: copy.rsvp.cant_invite_yourself,
        show: true 
      };

      return {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError
      }
    })

    return guestsErrors;
  }

  rsvpButtonClicked = () => {
    const { rsvpButtonClicked, onChangeIsRsvpingState } = this.props;
    const {
      paidEventsEnabled,
      selectedTableId,
      wouldLikeToMakeDonation,
      donationValue,
      hostMessage,
      guests,
      willBeBringingOtherGuests,
      guestsVaccinated
    } = this.state;

    const validationErrors = this.validateData({ wouldLikeToMakeDonation, donationValue, willBeBringingOtherGuests, guestsVaccinated });

    if (validationErrors.length > 0) {
      alert.error(validationErrors[0]);
      return;
    }

    const guestsErrors = willBeBringingOtherGuests ? this.validateGuests(guests) : [];

    this.setState({ guestsErrors });

    if (guestsErrors.some(e => e.firstName.show || e.lastName.show || e.email.show)) return;

    let tables = null;

    onChangeIsRsvpingState(true);

    if (selectedTableId) {
      const table = {
        tableId: selectedTableId,
        reservedSeats: willBeBringingOtherGuests ? 2 : 1
      }

      tables = [table];
    }

    const donationAmount = paidEventsEnabled && wouldLikeToMakeDonation && donationValue > 0 ? donationValue : 0;

    const additionalGuests = willBeBringingOtherGuests ? guests.map(guest => {
      return { ...guest, name: `${guest.firstName} ${guest.lastName}` }
    }) : [];

    const data = {
      guests: additionalGuests,
      hostMessage: hostMessage,
      donation: donationAmount,
      tables: tables
    };

    this.setState({ hostMessage: '' });

    rsvpButtonClicked(data);
  }

  formIsIncomplete = () => {
    const { willBeBringingOtherGuests, wouldLikeToMakeDonation, donationValue, guestsVaccinated, codeOfConductTerm, guests } = this.state;
    const { event, settings } = this.props;
    const { event: { enable_code_of_conduct_plus_one_rsvp } } = settings;
    const shouldBeVaccinated = isVaccinatedEvent(event);

    let isIncomplete = false;

    if (willBeBringingOtherGuests) {
      const isInvalidGuestFields = guests.some(guest => !isValidName(guest.firstName)
        || !isValidName(guest.lastName)
        || !isValidEmail(guest.email))

      isIncomplete = (shouldBeVaccinated && !guestsVaccinated)
        || (enable_code_of_conduct_plus_one_rsvp && !codeOfConductTerm)
        || isInvalidGuestFields;
    }

    if (wouldLikeToMakeDonation && (donationValue <= 0 || isNaN(donationValue))) {
      isIncomplete = true;
    }

    return isIncomplete;
  };

  render() {
    const { className, show, toggle, event, viewer, isRsvping, settings, hasMembership } = this.props;
    const { willBeBringingOtherGuests, wouldLikeToMakeDonation, guests, donationValue, hostMessage, selectedTableId, paidEventsEnabled, guestsVaccinated, codeOfConductTerm } = this.state;
    const { guestsErrors } = this.state;
    const { copy, features: { check_pending_max_seats }, event: { enable_code_of_conduct_plus_one_rsvp } } = settings;
    const viewerReservations = viewer && viewer.reservations ? viewer.reservations.nodes : [];
    const hosts = event.eventHosts.edges;
    const canViewAddress = (imHost(hosts, viewer) || imReserved(event, viewerReservations, 'ACCEPTED'));
    const tablesOptions = getTablesOptions(event);
    const shouldBeVaccinated = isVaccinatedEvent(event);
    const pricePerPersonInDollars = (event && event.pricePerPerson > 0) ? `$${event.pricePerPerson / 100}` : '';
    const isIncomplete = this.formIsIncomplete();
    const eventMaxAdditionalGuests = getEventMaxAdditionalGuests(event);
    const showGuestSelector = getGuestSelectorEligibility(event, check_pending_max_seats);
    const shouldHidePotluck = whitelabel.hasFeature(settings, 'hidePotluckIcons');
    const getCreatorFirstName = () => Array.isArray(hosts)
      ? hosts.reduce((creator, { node }) => node.creator && node.profile ? node.profile.firstName : creator, '')
      : '';

    return (
      <Modal show={show} toggle={toggle} size="lg" className={className}>
        <div className="row">
          <div className="ot-half-container left-section-container">
            <div className="ot-event-info-container">
              <div className="ot-banner" style={getBackgroundStyle(event, null, settings)} />
              <div className="header_details">
                <h4 className="hosted_by">
                  <span className="host-name">{getCreatorFirstName()}</span>
                  <span> is hosting</span>
                </h4>
                <h3>{event.title}</h3>
              </div>
              <div className="ot-event-icons">
                <span className="info-text">
                  <FAIcon name={[ 'far', 'calendar-alt' ]} />
                  {getScheduledAtDate(event)}
                </span>
                <span className="info-text">
                  <FAIcon name={[ 'far', 'clock' ]} />
                  {getEventTimeSpan(event)}
                </span>
                <span className="info-text">
                  <FAIcon name={[ 'far', 'calendar-times' ]} />
                  {copy.event.rsvp_deadline}
                  {getDeadlineAtDate(event)} - {getDeadlineAtTime(event)}
                </span>
                {renderAddressLabel(event, canViewAddress) && (
                  <span className="info-text">
                    <FAIcon name={[ 'fas', 'map-marker-alt' ]} /> {renderAddressLabel(event, canViewAddress)}
                  </span>
                )}
                {renderSecondaryAddressLabel(event, canViewAddress) && (
                  <span className="info-text">
                    <FAIcon name={[ 'fas', 'map-marker-alt' ]} /> {renderSecondaryAddressLabel(event, canViewAddress)}
                  </span>
                )}
                <span className="info-text"><FAIcon name={[ 'fas', 'globe-americas' ]} />{event.timezone}</span>
                {!event.virtual && (
                  <span className="info-text">
                    <FAIcon name={[ 'far', 'car-alt' ]} />{event.parkingLots ? copy.event.parking : copy.event.no_parking}
                  </span>
                )}
                {event.accessible && (
                  <span className="info-text">
                    <FAIcon name={[ 'far', 'wheelchair' ]} />{copy.event.wheelchair}
                  </span>
                )}
                {isPotluck(event) && !shouldHidePotluck && (
                  <span className="info-text">
                    <FAIcon name={[ 'far', 'utensils' ]} />Potluck
                  </span>
                )}
                {event.virtual && !isSite(settings, 'jewishfoodfest') && (
                  <span className="info-text">
                    <FAIcon name={[ 'far', 'phone-laptop' ]} />Virtual
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="ot-half-container right-section-container ot-rsvp-guests-modal">
            <h2>RSVP</h2>
            {pricePerPersonInDollars && !event.pwyw && (
              <p>
                <span className="ot-price">{pricePerPersonInDollars}</span>
              </p>
            )}
            <div className="ot-guests-picker container-fluid">
              {tablesOptions.length > 0 && (
                <div>
                  <p>{copy.rsvp.select_table}</p>
                  <SelectBox
                    defaultValue={tablesOptions.find(table => table.id === selectedTableId)}
                    options={tablesOptions}
                    onOptionSelect={value => this.handleInputChange('selectedTableId', value)}
                  />
                </div>
              )}
              {showGuestSelector && (
                <Checkbox
                  name="willBeBringingOtherGuests"
                  options={[
                    {
                      label: copy.rsvp.bringing_guest,
                      checked: willBeBringingOtherGuests
                    }
                  ]}
                  onInputChange={this.handleInputChange}
                />
              )}
              {willBeBringingOtherGuests && (
                <RsvpModalAddOtherGuests
                  guests={guests}
                  guestsErrors={guestsErrors}
                  maxAdditionalGuestsNumber={eventMaxAdditionalGuests}
                  handleGuestInputChange={this.handleGuestInputChange}
                  handleGuestInputFocus={this.handleGuestInputFocus}
                  addAnotherGuest={this.addAnotherGuest}
                  removeGuest={this.removeGuest}
                />
              )}
              {willBeBringingOtherGuests && shouldBeVaccinated &&
                (
                  <div className="guests-vaccinated-checkbox-container">
                    <Checkbox
                      name="guestsVaccinated"
                      options={[
                        {
                          label: guests.length > 1 ? copy.rsvp.other_guests_vaccinated : copy.rsvp.other_guest_vaccinated,
                          checked: guestsVaccinated
                        }
                      ]}
                      required
                      layout="flex"
                      onInputChange={this.handleInputChange}
                    />
                    <hr />
                  </div>
                )
              }
              {willBeBringingOtherGuests && enable_code_of_conduct_plus_one_rsvp &&
                (
                  <div className="terms-plus-one-guest-checkbox-container">
                    <Checkbox
                      name="codeOfConductTerm"
                      options={[
                        {
                          label: copy.rsvp.code_of_conduct_plus_one_rsvp_label,
                          checked: codeOfConductTerm
                        }
                      ]}
                      required
                      layout="flex"
                      onInputChange={this.handleInputChange}
                    />
                    <hr />
                  </div>
                )
              }
            </div>
            <form className="container-fluid">
              {paidEventsEnabled && event.donationsEnabled && !event.donationsDisabled && (
                <div>
                  <div className="donations">
                    <Checkbox
                      name="wouldLikeToMakeDonation"
                      options={[
                        {
                          label: copy.rsvp.dontation_option_label,
                          checked: wouldLikeToMakeDonation
                        }
                      ]}
                      onInputChange={this.handleInputChange}
                    />
                    {wouldLikeToMakeDonation && (
                      <DollarInput
                        name="donationValue"
                        min="0"
                        value={donationValue}
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        invalidate={() => { return false; }}
                        errorMessage="Enter a price."
                      />
                    )}
                  </div>
                  <hr />
                </div>
              )}
              {!event.virtual && copy.rsvp.non_virtual_disclaimer && (
                <>
                  <p className="disclaimer">
                    <FAIcon name={[ 'fas', 'info-circle' ]} />
                    <span dangerouslySetInnerHTML={{ __html: copy.rsvp.non_virtual_disclaimer }} />
                  </p>
                  <hr />
                </>
              )}
              <div>
                <WYSIWYGEditor
                    name="hostMessage"
                    value={hostMessage}
                    onInputChange={this.handleInputChange}
                    placeholder={copy.rsvp.message_placeholder}
                    centered
                />
              </div>
              {needsManualApproval(event) && (
                <p className="ot-manual-approval-warning">{copy.rsvp.manual_approval_warning}</p>
              )}
              <Button
                fullWidth
                capitalizeWords
                buttonStyle="primary"
                handleClick={this.rsvpButtonClicked}
                loading={isRsvping}
                disabled={isIncomplete || isRsvping}
              >{hasMembership ? 'Continue' : copy.rsvp.submit}</Button>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

RsvpMainModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledRsvpMainModal = styled(RsvpMainModal)`
  text-align: center;

  & .ot-event-info-container {
    display: block;
    text-align: center;
  }

  & .ot-banner {
    margin: 0 auto;
    height: 270px;
    width: 270px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  & .modal-body {
    padding: 0;
  }

  & .row {
    padding: 0;
    margin: 0;
  }

  & .host-name {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  & h4 {
    font-size: 13px;
    font-weight: normal !important;
    margin-top: 30px;
    margin-bottom: 18px;
  }

  & h3 {
    margin-top: 0;
    margin-bottom: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    font-size: 18px;
  }

  & h2 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 10px;
    margin-bottom: 20px;
  }

  & .ot-price {
    color: ${props => props.settings.theme.css.global.colors.primary};
    font-size: 24px;
  }

  & svg {
    margin-right: 4px;
  }

  & .info-text {
    padding-top: 2px;
    white-space: nowrap;
    color: ${props => props.settings.theme.css.global.colors.heading};
    font-size: 12px !important;
    display: block;
  }

  & .ot-event-icons span {
    margin-right: 10px;
    margin-left: 10px;
    display: inline-block;
  }

  & .left-section-container {
    padding: 70px 20px;
    display: flex;
    flex-direction: column;
    background: ${props => props.settings.theme.css.global.colors.backgroundColor};
    border-right: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
  }

  & .right-section-container {
    padding: 40px 10px;
  }

  & input {
    position: relative;
  }

  & .email-input {
    margin-bottom: 25px;
  }

  & .checkbox span {
    font-weight: 700;
    font-size: 14px;
  }

  & .guests-vaccinated-checkbox-container::after {
    content: '';
    display: block;
    clear: both;
  }
  & .terms-plus-one-guest-checkbox-container span {
    font-size: 13px;
    font-style: italic;
    font-weight: normal;
  }

  & input[type="text"] {
    margin: 0;
  }

  & input[type="checkbox"] {
    margin-right: 10px;
  }

  & .ot-manual-approval-warning {
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    padding: 0 60px;
    margin: 0 auto 20px;
    text-align: center;
  }

  .wysiwyg-editor {
    margin-bottom: 14px;

    .trix-button-group--history-tools {
      display: none;
    }
  }

  .disclaimer {
    font-size: 13px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    text-align: center;
    font-style: italic;

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
      }
    }
  }

  .checkbox__label {
    padding-left: 0 !important;
  }
`;

const RsvpMainModalWithSettings = withSettings(StyledRsvpMainModal);
const RsvpMainModalWithSession = withSession(RsvpMainModalWithSettings);
export { RsvpMainModalWithSession as RsvpMainModal };
