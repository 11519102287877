import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

const Textarea = props => {
  const [valid, setValid] = useState(true);
  const [charCount, setCharCount] = useState(0);

  const {
    className,
    name,
    value,
    minCharCount,
    invalidate,
    required,
    maxlength = false,
    onInputChange,
    onValidate,
    errorMessage,
    errorPlacement,
    placeholder,
    rows,
    onInputFocus,
    onInputBlur,
  } = props;

  useEffect(() => {
    if (required && invalidate && invalidate()) setValid(false);
    else setValid(true);
  }, [invalidate, required]);

  useEffect(() => {
    if (maxlength && value && !charCount) setCharCount(value.length);
    if (minCharCount && value && !charCount) setCharCount(value.length);
  }, [value, maxlength, minCharCount, charCount]);


  const inputIsValid = val => {
    return val !== '' && val.length > 0;
  };

  const handleChange = e => {
    const { value: eValue } = e.target;

    onInputChange(name, eValue);
    setCharCount(eValue.length);

    if (required && inputIsValid(eValue)) {
      setValid(true);
      onValidate(name, true);
    }
  };

  const handleValidation = e => {
    const { value: eValue } = e.target;

    if (!required) return;

    if ((!minCharCount && !inputIsValid(eValue)) || (minCharCount && eValue.length < minCharCount)) {
      setValid(false);
      onValidate(name, false);
    }
  };

  const getErrorMessage = () => {
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  const getErrorClass = () => {
    return !valid
      ? (errorPlacement === 'top' ? 'error_top' : 'error_bottom')
      : '';
  };

  return (
    <div className={`
        ${className}
        ${getErrorClass()}
        ${minCharCount ? 'has_char_count' : ''}
    `}>
      {!valid && errorPlacement === 'top' && (
        <div className="validation_error">{getErrorMessage()}</div>
      )}
      <textarea
        placeholder={placeholder ? placeholder : ''}
        name={name ? name : ''}
        value={value ? value : ''}
        className={!valid ? 'invalid' : ''}
        onChange={handleChange}
        onFocus={onInputFocus}
        onBlur={event => { handleValidation(event); onInputBlur && onInputBlur(); }}
        rows={rows ? rows : 6}
        maxLength={maxlength || ''}
      />
      {!valid && errorPlacement !== 'top' && (
        <div className="validation_error">{getErrorMessage()}</div>
      )}
      {minCharCount && (
        <p className="char_count">{charCount}/{minCharCount} minimum</p>
      )}
      {maxlength !== false && (
        <p className="char_count">{charCount}/{maxlength}</p>
      )}
    </div>
  );
};


const StyledTextarea = styled(Textarea)`
  position: relative;

  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-botton: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }

  .invalid {
    border-color: ${props => props.settings.theme.css.global.colors.error};

    &:focus {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }
  }

  textarea {
    margin-bottom: 14px;
  }

  &.error_bottom {
    textarea {
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 5px;
    }

    .char_count {
      bottom: -3px;
    }
  }

  &.has_char_count {
    position: relative;
    border: 1px solid transparent;
  }

  .char_count {
    position: absolute;
    bottom: -19px;
    right: 0;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    font-size: 13px;
    text-align: right;
    margin: 0;
  }
`;

const StyledTextareaWithSettings = withSettings(StyledTextarea);

export { StyledTextareaWithSettings as Textarea };
