import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, Loader } from '../';
import { SendMessageModal } from './SendMessageModal';
import ProfileViewSocialButtons from './ProfileViewSocialButtons';
import ProfileViewActionButtons from './ProfileViewActionButtons';
import ProfileViewBio from './ProfileViewBio';
import ProfileEvents from './ProfileEvents';
import { Query } from 'react-apollo';
import { PROFILE } from '../../queries';
import { getProfileQuestions } from '../../constants/profileQuestions';
import { fillQuestionsWithSettings, fillQuestionsWithAnswers } from '../../libs/questions';
import { withSettings } from '../components';
import {
  GET_USER_HOSTED_EVENTS,
  GET_MORE_USER_HOSTED_EVENTS,
  GET_USER_ATTENDED_EVENTS,
  GET_MORE_USER_ATTENDED_EVENTS
} from '../../queries';

import { RestrictionModal, RestrictionModalStatus } from '../Notification';
import { SessionContext } from '../../Context';

class ProfileView extends React.Component {
  state = {
    showSendMessageModal: false,
    hasRestriction: null,
    showRestrictionModal: false,
    questions: []
  }

  componentDidMount() {
    this.fillQuestions();
  }

  fillQuestions() {
    const { settings } = this.props;
    const profileQuestions = getProfileQuestions(settings);
    const questions = fillQuestionsWithSettings(profileQuestions, settings);

    this.setState({ questions });
  }

  sendMessageClicked = () => {
    if (this.shouldShowRestrictionModal()) {
      this.showRestrictionModal();
      return;
    }

    this.setState({ showSendMessageModal: true });
  };

  shouldShowRestrictionModal = () => {
    return (this.state.hasRestriction === true);
  }

  handleRestrictionModalToggle = () => {
    this.setState({ showRestrictionModal: !this.state.showRestrictionModal })
  }

  showRestrictionModal = () => {
    this.setState({ showRestrictionModal: true });
  }

  closeSendMessageModal = () => {
    this.setState({ showSendMessageModal: false });
  }

  loadRestrictionContent = (copy, session, event) => {
    const restrictionContent = RestrictionModalStatus(copy, session, event, this.props.settings);
    if (this.state.hasRestriction === null && restrictionContent) {
      this.setState({ hasRestriction: true });
    }
    return restrictionContent;
  }

  render() {
    const { className, match } = this.props;
    const { settings: { copy } } = this.props;
    const { id } = match.params;
    const parsedId = parseInt(id);
    const profileViewElement = (
      <SessionContext.Consumer>
        {({ session }) => (
          <Query query={PROFILE} variables={{ id: parsedId }} errorPolicy='all'>
            {({ data, loading }) => {
              if (loading) return <Loader />;

              if (!data || !data.profile) {
                return (<div></div>);
              }

              const profile = data.profile;
              if (typeof profile?.answers === 'string') profile.answers = JSON.parse(profile.answers);

              const questions = profile.answers ? fillQuestionsWithAnswers(this.state.questions, profile.answers) : [];

              const restrictionContent = this.loadRestrictionContent(copy, session, null);

              return (
                <Fragment>
                  <RestrictionModal
                    show={this.state.showRestrictionModal}
                    toggle={this.handleRestrictionModalToggle}
                    content={restrictionContent}
                  />
                  <div className={`${className} body_container`}>
                    <Avatar size="xl" layout="vertical" noName profile={profile}/>
                    <div className="details-container">
                      <h2 className="hello">{`Hey, I'm ${profile.firstName} ${profile.lastName}!`}</h2>
                      <ProfileViewSocialButtons
                        twitter={profile.twitter}
                        instagram={profile.instagram}
                        facebook={profile.facebook}
                        snapchat={profile.snapchat}
                      />
                      <ProfileViewActionButtons sendMessageClicked={this.sendMessageClicked} profileId={id} />
                      <ProfileViewBio showAge={profile.showAge} dob={profile.dateOfBirth} bio={profile.bio} questions={questions} />
                    </div>
                  </div>
                  {profile && profile.eventHostedVisible && profile.hostedEvents && profile.hostedEvents.totalCount > 0 && (
                    <ProfileEvents
                      profileId={profile.id}
                      name="hosted"
                      initialQuery={GET_USER_HOSTED_EVENTS}
                      fetchMoreQuery={GET_MORE_USER_HOSTED_EVENTS}
                      totalNumberOfItems={profile.hostedEvents.totalCount}
                      itemsPerPage={4}
                      getHasMoreEventsFromResponse={ (response) => {
                        return (response && response.profile && response.profile.hostedEvents && response.profile.hostedEvents.pageInfo && response.profile.hostedEvents.pageInfo.hasNextPage);
                      }}
                      getEventsFromResponse={ (response) => {
                        return (response && response.profile && response.profile.hostedEvents && response.profile.hostedEvents.edges) ? response.profile.hostedEvents.edges : [];
                      }}
                      getCursorFromResponse={ (response) => {
                        if (!response || !response.profile || !response.profile.hostedEvents || !response.profile.hostedEvents.pageInfo || !response.profile.hostedEvents.pageInfo.hasNextPage) {
                          return '';
                        } else {
                          return response.profile.hostedEvents.pageInfo.endCursor;
                        }
                      }}
                    />
                  )}
                  <hr/>
                  {profile && profile.eventAttendedVisible && profile.attendedEvents && profile.attendedEvents.totalCount > 0 && (
                    <ProfileEvents
                      profileId={profile.id}
                      name="attended"
                      initialQuery={GET_USER_ATTENDED_EVENTS}
                      fetchMoreQuery={GET_MORE_USER_ATTENDED_EVENTS}
                      totalNumberOfItems={profile.attendedEvents.totalCount}
                      itemsPerPage={4}
                      getHasMoreEventsFromResponse={ (response) => {
                        return (response && response.profile && response.profile.attendedEvents && response.profile.attendedEvents.pageInfo && response.profile.attendedEvents.pageInfo.hasNextPage);
                      }}
                      getEventsFromResponse={ (response) => {
                        return (response && response.profile && response.profile.attendedEvents && response.profile.attendedEvents.edges) ? response.profile.attendedEvents.edges : [];
                      }}
                      getCursorFromResponse={ (response) => {
                        if (!response || !response.profile || !response.profile.attendedEvents || !response.profile.attendedEvents.pageInfo || !response.profile.attendedEvents.pageInfo.endCursor) {
                          return '';
                        } else {
                          return response.profile.attendedEvents.pageInfo.endCursor;
                        }
                      }}
                    />
                  )}
                  <SendMessageModal
                    show={this.state.showSendMessageModal}
                    toggle={this.closeSendMessageModal}
                    profile={profile}
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    content={this.state.modalContent}
                    updateModalContent={this.updateModalContent}
                  />
                </Fragment>
              );
            }}
          </Query>
        )}
      </SessionContext.Consumer>
    );

    return profileViewElement;
  }
}

const StyledProfileView = styled(ProfileView)`
  text-align: center;

  .details-container {
    padding: 40px 10px 0;

    h2 {
      &.hello {
        font-size: 36px;
        margin-bottom: 15px;
        text-align: center;
        font-weight: normal;
        color: ${props => props.settings.theme.css.global.colors.primaryDark};
      }
    }
  }
`;

const ProfileViewWithRouter = withRouter(StyledProfileView);
const ProfileViewWithRouterWithSettings = withSettings(ProfileViewWithRouter);

export { ProfileViewWithRouterWithSettings as ProfileView };
