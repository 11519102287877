import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useEventPageData } from '../../hooks';
import { stringInsert } from '../../libs';

import ROUTES from '../../constants/routes';

import {
  PageSubheader as Header,
  EventDetailCard
} from '../components';


export const EventSuggestions = () => {
  const history = useHistory();
  const { eventData } = useEventPageData();

  if (!eventData || !eventData.area) return null;

  const suggestedEvents = eventData.suggestedEvents.nodes;

  const handleViewAllClick = () =>
    history.push(`/${ROUTES.SEARCH}/${eventData.area.url}`);

  return suggestedEvents && suggestedEvents.length > 0 ? (
    <>
      <Header>
        {stringInsert('Find more dinners in %1$s', eventData.area.label)}
        <ViewAll onClick={handleViewAllClick}>+ view all</ViewAll>
      </Header>
      <Suggestions>
        {suggestedEvents.map(event => <EventDetailCard key={event.id} event={event} />)}
      </Suggestions>
    </>
  ) : null;
};

const ViewAll = styled.span`
  font-size: 14px;
  color: #83817b;
  font-weight: normal;
  font-family: 'Avenir-Medium';
  margin-left: 12px;
  cursor: pointer;
`;

const Suggestions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 35px;
`;
