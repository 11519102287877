import React, { useState } from 'react';
import styled from 'styled-components';

import { AreaDropdown, withSettings } from '../components';

import { FieldLabel, FieldValue } from './LandingHeroSearchField';


const LandingHeroSearchAreaSelector = ({ className, area, settings, onSelectArea }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { copy } = settings;

  const handleSelectArea = (value) => {
    onSelectArea(value);
    setShowDropdown(false);
  };

  return (
    <div className={[className, 'areas'].join(' ')}>
      <div onClick={() => setShowDropdown(true)}>
        <FieldLabel>Location</FieldLabel>
        {area && <FieldValue>{area === 'virtual' ? copy.menu.virtual_area_link_text : area.label}</FieldValue>}
        {showDropdown && (
          <AreaDropdown
            area={area}
            onSelectArea={handleSelectArea}
            onToggleDropdown={() => setShowDropdown(prev => !prev)}
          />
        )}
      </div>
    </div>
  );
};


const StyledLandingHeroSearchAreaSelector = styled(LandingHeroSearchAreaSelector)`
  cursor: pointer;

  .dropdown_menu {
    left: 0;
    top: calc(100% + 8px);
  }

  li {
    position: relative;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    padding-bottom: 15px !important;
  }
`;
const LandingHeroSearchAreaSelectorWithSettings = withSettings(StyledLandingHeroSearchAreaSelector);
export { LandingHeroSearchAreaSelectorWithSettings as LandingHeroSearchAreaSelector };
