import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '../';
import {
  RsvpPaymentModalCardsManagement,
  RsvpPaymentModalPaymentSummary,
  RsvpPaymentModalCoupon
} from './';
import { DollarInput, withSettings } from '../components';

class RsvpPaymentModal extends React.Component {

  constructor(props) {
    super(props);

    const { viewer, event, settings: { event: { pwyw_minimum } } } = props;
    const creditCards = viewer && viewer.cards ? viewer.cards : [];
    const selectedCreditCard = creditCards.find(creditCard => creditCard.favorite);
    const selectedCreditCardId = (selectedCreditCard) ? selectedCreditCard.id : null;
    const pwywMinimum = (event.pwywMinimum || pwyw_minimum) / 100;

    this.state = {
      coupon: null,
      selectedCreditCardId: selectedCreditCardId,
      cardsManagementMode: 'show-cards',
      newCard: {
        fullName: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        useAsDefaultPayment: false
      },
      customAmount: pwywMinimum,
      customMinimum: pwywMinimum
    };
  }

  handleCustomAmountChange = (name, customAmount) => this.setState({ customAmount });

  calculateDiscountGivenPriceAndCoupon = (price, coupon) => {
    if (!coupon || !coupon.discountType) {
      return 0;
    }

    if (coupon.discountType === 'DOLLAR') {
      const discountAmount = coupon.amount * 100;
      if (discountAmount > price) {
        return price;
      } else {
        return discountAmount;
      }
    } else if (coupon.discountType === 'PERCENTAGE') {
      const discountPercentage = (coupon.amount / 100);
      return (price * discountPercentage);
    }
  }

  couponSelected = (coupon) => {
    this.setState({ coupon });
  }

  creditCardSelected = (creditCardId) => {
    this.setState({
      selectedCreditCardId: creditCardId
    });
  }

  isCreditCardSelected = () => {
    return (this.state.selectedCreditCardId && this.state.cardsManagementMode !== 'add-card');
  }

  isNewCardInfoCompleted = () => {
    if (this.state.cardsManagementMode === 'add-card') {
      return (this.isValidCard(this.state.newCard));
    } else {
      return false;
    }
  }

  shouldEnablePaymentButton = () => {
    return (this.isCreditCardSelected() || this.isNewCardInfoCompleted());
  }

  isValidCard = (card) => {
    if (!card) { return false }

    const validFullName = card.fullName && card.fullName.length > 0;
    const validCardNumber = card.cardNumber && card.cardNumber.length > 0;
    const validExpirationMonth = card.expirationMonth && card.expirationMonth.length > 0;
    const validExpirationYear = card.expirationYear && card.expirationYear.length > 0;
    const validSecurityCode = card.securityCode && card.securityCode.length > 0;

    return (validFullName && validCardNumber && validExpirationMonth && validExpirationYear && validSecurityCode);
  }

  newCardInputChanged = (name, value) => {
    const newCard = {
      ...this.state.newCard,
      [name]: value
    }

    this.setState({
      newCard: newCard
    });
  }

  changeButtonClicked = () => {
    this.setState({
      cardsManagementMode: 'modify-cards'
    });
  }

  setAddCardMode = () => {
    this.setState({
      cardsManagementMode: 'add-card'
    });
  }

  setShowCardsMode = () => {
    this.setState({
      cardsManagementMode: 'show-cards'
    });
  }

  payButtonClicked = totalPrice => {
    const { event } = this.props;
    const { coupon, customAmount, selectedCreditCardId, newCard } = this.state;
    const couponId = coupon ? coupon.id : null;

    const input = { totalPrice };
    if (event.pwyw) input.customAmount = customAmount;
    if (couponId) input.couponId = couponId;
    if (this.isCreditCardSelected()) input.cardId = selectedCreditCardId;
    if (this.isNewCardInfoCompleted()) input.newCard = newCard;

    this.props.payButtonClicked(input);
  }

  render() {
    const { className, show, toggle, event, donation, additionalGuests, areas, viewer, isPaying, ccError, ccErrorObj } = this.props;
    const { settings: { copy } } = this.props;
    const { coupon, selectedCreditCardId, newCard, cardsManagementMode, customAmount, customMinimum } = this.state;

    if (!event) return null
    const eventAreaId = event.area ? event.area.id : '';
    const isPwyw = event.pwyw;

    const couponCode = coupon && coupon.code ? coupon.code : '';
    const eventArea = areas.find(area => area.id === eventAreaId);

    const isEventFree = !event.pricePerPerson || event.pricePerPerson === 0;
    const numberOfGuests = 1 + (additionalGuests.length);
    const pricePerPerson = isPwyw ? customAmount * 100 : event.pricePerPerson;
    const guestsPrice = numberOfGuests * pricePerPerson;

    const discountAmount = this.calculateDiscountGivenPriceAndCoupon(guestsPrice, coupon);

    const totalPriceWithoutTransactionFee = guestsPrice - discountAmount;
    const transactionFee = (totalPriceWithoutTransactionFee <= 0)
      ? 0
      : (event.hostAbsorbsTransactionFee || !eventArea || !eventArea.transactionFeeAmount)
        ? 0
        : eventArea.transactionFeeAmount / 100;

    const donationAmount = (donation <= 0) ? 0 : donation;
    const totalPrice = totalPriceWithoutTransactionFee + transactionFee + donationAmount;

    const creditCards = viewer ? viewer.cards : [];

    const shouldEnablePayment = this.shouldEnablePaymentButton();

    return (
      <Modal show={show} toggle={toggle} size="lg" className={className}>
        <div className="rsvp_payment_modal">
          <h2>{copy.rsvp.payment_title}</h2>
          <div className="row">
            <div className="ot-half-container">
            {(totalPrice > 0 || isPwyw) &&
              <RsvpPaymentModalCardsManagement
                mode={cardsManagementMode}
                viewer={viewer}
                newCard={newCard}
                newCardInputChanged={this.newCardInputChanged}
                creditCards={creditCards}
                selectedCreditCardId={selectedCreditCardId}
                creditCardSelected={this.creditCardSelected}
                changeButtonClicked={this.changeButtonClicked}
                setAddCardMode={this.setAddCardMode}
                setShowCardsMode={this.setShowCardsMode}
                ccError={ccError}
                ccErrorObj={ccErrorObj}
              />
            }
            {isPwyw && (
              <div className="pay_what_you_want">
                <hr />
                <h2>{copy.rsvp.pay_what_you_want_title}</h2>
                <p>{copy.rsvp.pay_what_you_want_description}</p>
                <DollarInput
                  name="customAmount"
                  min={customMinimum}
                  value={customAmount}
                  onInputChange={this.handleCustomAmountChange}
                />
              </div>
            )}
            <RsvpPaymentModalCoupon
              couponSelected={this.couponSelected}
            />
            </div>
            <div className="ot-half-container">
              <RsvpPaymentModalPaymentSummary
                event={event}
                donation={donation}
                numberOfGuests={numberOfGuests}
                isEventFree={isEventFree}
                guestsPrice={guestsPrice}
                couponCode={couponCode}
                discountAmount={discountAmount}
                transactionFee={transactionFee}
                pricePerPerson={pricePerPerson}
                totalPrice={totalPrice}
                isPaying={isPaying}
                shouldEnablePaymentButton={shouldEnablePayment}
                payButtonClicked={this.payButtonClicked}
                ccError={ccError}
                ccErrorObj={ccErrorObj}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

RsvpPaymentModal.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModal = styled(RsvpPaymentModal)`
  text-align: center;

  & h2 {
    font-size: 21px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 35px;
    margin-bottom: 30px;
  }

  .pay_what_you_want {
    margin-bottom: 20px;

    h2 {
      text-align: left;
      font-size: 14px;
      color: ${props => props.settings.theme.css.global.colors.primary};
      margin: 0 0 10px;
    }

    p {
      font-size: 13px;
      margin: 0 0 10px;
      text-align: left;
    }
  }
`;

const StyledRsvpPaymentModalWithSettings = withSettings(StyledRsvpPaymentModal);

export { StyledRsvpPaymentModalWithSettings as RsvpPaymentModal };
