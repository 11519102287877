import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../styles';

import { TweenMax, Power3 } from 'gsap';


const JourneyExperience = ({ className, children, answered }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    TweenMax.to(contentRef.current, .8, {
      opacity: 1,
      top: -5,
      delay: 1.4,
      ease: Power3.easeOut
    });
  }, [contentRef]);

  const classNames = [
    className,
    answered ? 'answered' : 'not_answered'
  ];

  return (
    <div className={classNames.join(' ').trim()} ref={contentRef}>
      {children}
    </div>
  );
};

const StyledJourneyExperience = styled(JourneyExperience)`
  position: relative;
  display: block;
  width: calc(100% - 40px);
  max-width: 80%;
  z-index: 3;
  margin: auto;
  // text-align: center;
  opacity: 0;
  top: 5px;
  color: #fff;

  ${media.md`
    max-width: 90%;
  `}

  ${media.sm`
    max-width: 100%;
  `}

  // border: 2px solid red;

  p {
    span {
      color #fff;
      font-family: 'Avenir-Light' !important;
      letter-spacing: -0.025em;

      strong {
        font-family: 'Avenir-Bold' !important;
      }
    }

    &.h2 {
      font-size: 45px;

      ${media.md`
        font-size: 42px;
        line-heigh: 1;
      `}

      ${media.journeyMd`
        font-size: 36px;
      `}

      ${media.sm`
        font-size: 24px;
      `}
    }

    &.h3 {
      font-size: 22px;
      margin-bottom: 10px;

      ${media.journeyMd`
        font-size: 20px;
      `}

      ${media.sm`
        font-size: 16px;
        text-transform: none;
      `}
    }
  }

  &.not_answered {
    ${media.journeyMd`
      .hover_option {
        span {
          &.visible {
            // color: #fff;
          }
        }
      }
    `}
  }

  .quote_container {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-30%);
    width: 45%;
    text-align: center;

    ${media.journeyMd`
      display: none;
    `}

    & > span {
      // HACK - a second portal is created before going to resources page? //
      &:nth-child(3) {
        display: none;
      }
    }

    .quote {
      font-family: 'IM FELL English PRO Italic', serif;
      color: #fff;
      letter-spacing: -0.025em;
      font-size: 40px;
      line-height: 1.2;
      font-weight: normal;
      font-style: italic;

      ${media.md`
        font-size: 34px;
      `}
    }

    .cite {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 16px;
      color: #fff;
      display: block;
      margin-top: 20px;

      ${media.sm`
        font-size: 14px;
      `}
    }
  }
`;
export { StyledJourneyExperience as JourneyExperience };
