import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { on, off } from '../../libs/customEvent';
import { useSettings, useRsvpPageData } from '../../hooks';

import { NumberField } from '../components';


export const RsvpPagePotluckItem = ({ item, first, last }) => {
  const [valid, setValid] = useState(true);
  const { getField, setField } = useRsvpPageData();
  const { event: { max_reservation_potluck_items } } = useSettings();
  const maxClaimable = max_reservation_potluck_items && parseInt(max_reservation_potluck_items);
  const claimedItems = getField('claimedPotluckItems');
  const { id, name, freeQuantity } = item;

  const invalidateField = ({ detail = {} }) => {
    const { id: invalidId } = detail;
    if (id === invalidId) setValid(false);
  };

  useEffect(() => {
    on('rsvpPage:validatePotluck', invalidateField);
    return () => off('rsvpPage:validatePotluck', invalidateField);
  }, []);

  const maxClaimableItems = maxClaimable
    ? Math.min(maxClaimable, freeQuantity)
    : freeQuantity;

  const claimedCount = (other = false) => {
    let claimed = 0;
    if (!claimedItems) return claimed;
    for (const i in claimedItems) {
      if (other && parseInt(i) !== id) claimed = claimed + claimedItems[i];
      else if (!other && parseInt(i) === id) claimed = claimed + claimedItems[i];
    }
    return claimed;
  };

  const totalClaimedCount = () => {
    let count = 0;
    if (!claimedItems) return count;
    for (const i in claimedItems) {
      count = count + claimedItems[i];
    }
    return count;
  };

  const maxItems = () => {
    const claimed = claimedCount();
    const claimedOther = claimedCount(true);
    if (!claimedItems) return maxClaimableItems;
    return !claimed || !maxClaimable ? maxClaimableItems
      : Math.max(Math.min(claimed, maxClaimableItems), maxClaimableItems - claimedOther);
  };

  const maxRemainingItems = () => {
    const remaining = Math.min(Math.min(maxItems(), maxClaimableItems - claimedCount(true)), maxItems());
    if (!maxClaimable) return maxClaimableItems;
    return remaining > 0 ? remaining : maxClaimableItems;
  };

  const disabled = () => (
    (maxClaimable && claimedItems && !claimedItems[id] && totalClaimedCount() >= maxClaimable) ||
    !freeQuantity
  );

  const handleOnChange = v => {
    let val = parseInt(v);
    if (isNaN(val) || val < 0) val = 0;
    setField('claimedPotluckItems', [id, val]);
    if (val <= maxItems()) setValid(true);
  };

  return (
    <Container first={first} last={last}>
      <Label>{name}</Label>
      <NumberField
        value={claimedItems[id] || 0}
        max={maxRemainingItems()}
        onChange={(n, v) => handleOnChange(v)}
        disabled={disabled()}
        valid={valid}
        errorMessage={`Select up to ${maxRemainingItems()}.`}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;

  ${({ first }) => first && css`
    margin-top: 40px;
  `}

  ${({ last }) => last ? css`
    input {
      margin-bottom: 0 !important;
    }
  ` : css`
    margin-bottom: 5px;
  `}

  & > div {
    width: 100px;
  }
`;

const Label = styled.label`
  display: block;
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;
  font-size: 18px;
`;
