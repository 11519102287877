import styled, { css } from 'styled-components';
import { withSettings } from '../components';


const PageContainerWithoutSettings = styled.div`
  ${props => props.fullPad ? css`
    padding: 5rem 60px 6rem;

    @media (max-width: 700px) {
      padding: 3rem 20px 5rem;
    }
  ` : css`
    padding-left: 60px;
    padding-right: 60px;

    @media (max-width: 700px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  `}
`;

export const PageContainer = withSettings(PageContainerWithoutSettings);
