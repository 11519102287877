import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import defaultImg from '../../assets/images/ot-emblem.png';
import { useSession } from '../../hooks';

const LoaderWithImage = () => {
  const session = useSession();
  const settingsName = document.querySelector('body').dataset.sitename;
  const siteName = settingsName && settingsName.includes('.') ? settingsName.split('.')[0] : settingsName;
  const siteLabel = settingsName && settingsName.includes('.') ? settingsName.split('.')[1] : '';
  const classNames = ['whitelabel_loader', siteName, siteLabel];

  const loaderClass = siteName && siteName !== 'onetable' ? classNames.join(' ').trim() : null;
  return (
    <StyledLoader
      loaderImageUrl={defaultImg}
      className={loaderClass}
      theme={session.theme}
    />
  );
};

const Loader = ({ className }) => {
  return (
    <div className={className}>
      <div className="loader">
        <div className="emblem"></div>
        <div className="emblem offset"></div>
      </div>
    </div>
  );
};

const bounce = keyframes`
  0%, 100% {
    transform: scale(0.0);
    opacity: 0;
  } 50% {
    transform: scale(1.0);
    opacity: 1;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(360deg);
  }
`;

const loaderColorCss = color => css`
  & > .loader {
    border-top-color: ${color};
  }
`;

const buildLoaderColorsCss = sites => sites.reduce((sitesCss, site) => css`
  ${sitesCss}
  &.${site[0]} {
    ${loaderColorCss(site[1])}
  }
`, '');

const StyledLoader = styled(Loader)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  ${({ theme }) => theme.isVersion && theme.isVersion(2) ? css`
    background-color: #fff;
  ` : css`
    background-color: #fbfaf9;
  `}

  &.whitelabel_loader {
    ${({ theme }) => theme.isVersion && theme.isVersion(2) ? css`
      background-color: #fff;
    ` : css`
      background-color: #f2f0eb;
    `}

    & > .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 15px solid rgba(255,255,255,0.7);
      border-top-color: #8d1b37;
      animation: ${rotate} 0.9s linear infinite;
    }

    ${() => buildLoaderColorsCss([
      ['climatefest', '#3d74ba'],
      ['jewishfoodfest', '#ee6155'],
      ['hineni', '#404a50'],
      ['thegathering', '#00587C'],
      ['signaturefd', '#133156'],
      ['dinner-party', '#1D2C4C'],
      ['careerpassportonwardisrael', '#0067ac'],
      ['homecoming', '#088ca4'],
      ['haggadot', '#E9545C'],
      ['centralsynagogue', '#22386a'],
    ])}


    &.hillel {
      ${loaderColorCss('#00274c')}

      ${() => buildLoaderColorsCss([
        ['USC', '#990000'],
        ['texas', '#bf5700'],
        ['cornell', '#f15d60'],
        ['hga', '#00a0df'],
        ['international', '#3366FF'],
        ['sandbox', '#009acd'],
      ])}
    }
  }

  &:not(.whitelabel_loader) {
    & > .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 120px;
      border-radius: 50%;


      & > .emblem {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        background-image: url(${props => props.loaderImageUrl});
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 0 10px rgba(0,0,0,0.14);
        animation: ${bounce} 1.8s infinite ease-in-out;

        &.offset {
          animation-delay: -0.9s;
        }
      }
    }
  }
`;

export { LoaderWithImage as Loader };
