import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Button,
  ButtonToggle,
  EventCreationCommunityTable,
  Textarea,
  Input
} from '../../components';

import { invalidateNumber } from '../../../libs';
import { withSettings } from '../../components';

const emptyTable = {
  id: null,
  name: '',
  nameValid: false,
  maxSeats: '',
  maxSeatsValid: false
};

const createNewTable = () => {
  return Object.assign({}, emptyTable);
};

const EventCreationFormCommunityDetails = (props) => {

  const { className, eventData, onInputChange, onValidate, invalidate } = props;
  const { settings: { copy } } = props;

  const handleToggleTables = value => {
    
    const newState = { hasTables: value };

    if (value && eventData.tables.length < 1) newState['tables'] = new Array(createNewTable());
    if (!value) newState['tables'] = [];

    onInputChange({ ...newState });
  };

  const handleAddTable = () => {
    const tables = eventData.tables.slice();
    tables.push(createNewTable());
    onInputChange('tables', tables);
  };

  const handleRemoveTable = i => {
    const tables = eventData.tables.slice();
    const tablesToDestroy = eventData.tablesToDestroy.slice();
    const tableId = eventData.tables[i].id;

    tables.splice(i, 1);
    onInputChange({ tables });

    if (tableId) {
      tablesToDestroy.push(tableId);
      onInputChange({ tablesToDestroy });
    }
  };

  const handleTableInputChange = (i, name, value) => {
    const tables = eventData.tables.slice();
    tables[i][name] = value;
    onInputChange('tables', tables);
  };

  const handleTableValidation = (i, name, value) => {
    const tables = eventData.tables.slice();
    tables[i][`${name}Valid`] = value;
    onInputChange('tables', tables);
  };

  return (
    <div className={className}>
      <Card>
        <h2 className="card_title">{copy.create.tables}</h2>
        <i className="divider" />
        <div className="table_toggle__container">
          <p>{copy.create.community_tables_question}</p>
          <ButtonToggle
            name="hasTables"
            className="table_toggle"
            activeButton={eventData.hasTables}
            onToggleButton={value => handleToggleTables(value)}
          />
        </div>

        {/* Total Max Seats Without Tables */}
        {!eventData.hasTables && (
          <div className="form_group">
            <div className="total_max_seats">
              <label>{copy.create.community_max_seats}</label>
              <Input
                type="number"
                name="maxSeats"
                value={eventData.maxSeats}
                onInputChange={onInputChange}
                onValidate={onValidate}
                invalidate={() => invalidate && !eventData.hasTables && invalidateNumber(eventData.maxSeats)}
                errorMessage="Enter a valid number of guests."
                required={!eventData.hasTables}
              />
            </div>
          </div>
        )}

        {/* Tables */}
        {eventData.hasTables && (
          <div className="tables__container">
            {eventData.tables.map((table, i) => (
              <EventCreationCommunityTable
                key={i}
                table={table}
                index={i}
                eventData={eventData}
                onRemoveTable={handleRemoveTable}
                onTableInputChange={handleTableInputChange}
                onValidate={handleTableValidation}
                invalidate={invalidate}
              />
            ))}
            <div className="tables__add_button">
              <Button
                handleClick={handleAddTable}
                buttonStyle="primary_outline"
                capitalize
              >
                Add Another Table
              </Button>
            </div>
          </div>
        )}
      </Card>

      <Card>
        <h2 className="card_title">{copy.create.organization_description_title}</h2>
        <i className="divider" />
        <Textarea
          name="partnerOrganizationDescription"
          value={eventData.partnerOrganizationDescription}
          onInputChange={onInputChange}
          placeholder={copy.create.organization_description_placeholder}
        />
      </Card>
    </div>
  );

}

const StyledEventCreationFormCommunityDetails = styled(EventCreationFormCommunityDetails)`
  .table_toggle {
    display: inline-block;
    vertical-align: middle;

    &__container {
      text-align: center;

      p {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 20px 0 0;
      }
    }
  }

  .total_max_seats {
    max-width: 300px;
    margin: 30px auto 0;
  }

  .tables {
    &__container {
      margin: 30px auto 0;
      max-width: 700px;
    }

    &__add_button {
      text-align: center;
      margin-top: 10px;
    }
  }
`;

const StyledEventCreationFormCommunityDetailsWithSettings = withSettings(StyledEventCreationFormCommunityDetails);

export { StyledEventCreationFormCommunityDetailsWithSettings as EventCreationFormCommunityDetails };
