import React from 'react';
import styled from 'styled-components';
import { FAIcon } from '../../components';
import { withSettings } from '../../components';

class SelectBoxOption extends React.Component {
  state = { selected: false };

  componentDidMount() {
    const { selection, value } = this.props;
    let isSelected = false;

    if (value === 'ALL') return;

    if (Array.isArray(selection) && selection[0]) {
      selection.forEach(s => {
        if (s.value === value) isSelected = true;
      });
    } else if (typeof selection === 'string') {
      if (selection === value) isSelected = true;
    } else {
      if (selection.value === value) isSelected = true;
    }

    if (isSelected) this.setState({ selected: true });
  }

  handleOptionClick = () => {
    const { onOptionClick } = this.props;
    this.setState(prevState => ({ selected: !prevState.selected }), () => onOptionClick());
  };

  render() {
    const { selected } = this.state;
    const { className, label, multi } = this.props;

    if (!label) return null;

    return (
      <li
        className={`${className} ${multi ? 'multi' : ''}`}
        onClick={this.handleOptionClick}
      >
        <span>{label}</span>
        {selected && <FAIcon name={['fas', 'check']} />}
      </li>
    );
  }
}

const StyledSelectBoxOption = styled(SelectBoxOption)`
  position: relative;

  span {
    text-transform: capitalize;
  }

  &.multi {
    & > span {
      padding-right: 30px !important;
    }
  }

  svg {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.primary};
  }
`;

const StyledSelectBoxOptionWithSettings = withSettings(StyledSelectBoxOption);

export { StyledSelectBoxOptionWithSettings as SelectBoxOption };
