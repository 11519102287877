import React from 'react';
import styled from 'styled-components';
import { Input, FAIcon } from '../../components';
import { withSettings } from '../../components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ value, cantRemove, handleRemoveItem, i, showQuantities, onQuantityChange }) => {
  const name = typeof value === 'object' ? value.name : value;
  const quantity = typeof value === 'object' ? value.quantity : null;

  return (
    <li className="item">
        <span className="item__bars">
          <FAIcon name="bars" />
        </span>
        <span>{name}</span>
        {showQuantities && (
          <Input
            type="number"
            content="integer"
            min="1"
            name="quantity"
            value={quantity}
            onInputChange={(_, v) => onQuantityChange(i, v)}
          />
        )}
      {(!cantRemove || value.id === undefined) && (
        <span
          className="item__remove"
          onClick={() => handleRemoveItem(i)}
        >
          <FAIcon name="times" />
        </span>
      )}
    </li>
  );
});

const SortableList = SortableContainer(({ items, cantRemove, handleRemoveItem, showQuantities, onQuantityChange }) => {
  return (
    <ul className="item__list">
      {items.map((value, index) => {
        const key = (typeof value === 'object') ? value.name : value;

        return (
          <SortableItem
            key={key}
            index={index}
            value={value}
            i={index}
            showQuantities={showQuantities}
            cantRemove={cantRemove}
            handleRemoveItem={handleRemoveItem}
            onQuantityChange={onQuantityChange}
          />
        )
      })}
    </ul>
  );
});

class SortableComponent extends React.Component {

  render() {
    const { items, cantRemove, onSortEnd, handleRemoveItem, showQuantities, onQuantityChange } = this.props;

    return (
      <SortableList
        distance={5}
        helperContainer={document.getElementsByClassName('item__list')[0]}
        cantRemove={cantRemove}
        items={items || []}
        onSortEnd={onSortEnd}
        handleRemoveItem={handleRemoveItem}
        showQuantities={showQuantities}
        onQuantityChange={onQuantityChange}
      />
    );
  }
  
}

class AddItemInput extends React.Component {
  state = {
    inputValue: '',
    itemList: [],
    valid: true
  };

  componentDidMount() {
    const { itemList } = this.props;
    this.setState({ itemList });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { itemList, invalidate, required } = this.props;

    if (itemList !== nextProps.itemList) {
      this.setState({ itemList: nextProps.itemList });
    }

    if (invalidate !== nextProps.invalidate && nextProps.invalidate && required) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleQuantityChange = (index, value) => {
    const { itemList, onQuantityChange } = this.props;

    const list = Array.isArray(itemList) ? itemList.slice() : [];
    
    list[index].quantity = value;

    this.setState({ itemList: list, valid: true }, () => {
      onQuantityChange(this.state.itemList);
    });
  }

  handleAddItem = () => {
    const { inputValue, itemList } = this.state;
    const { onAddItem, showQuantities } = this.props;

    if (!inputValue) {
      return;
    }

    const newValue = inputValue.trim();
    
    if (newValue === '') {
      return;
    }

    const list = Array.isArray(itemList) ? itemList.slice() : [];

    const newItem = {
      name: newValue
    }

    if (showQuantities) {
      newItem['quantity'] = 1
    }

    list.push(newItem);

    this.setState({ itemList: list, valid: true }, () => {
      onAddItem(this.state.itemList);
      this.setState({ inputValue: '' });
    });
  };

  handleRemoveItem = i => {
    const { itemList, onAddItem, onRemoveItem, removeItemList } = this.props;

    const list = itemList.slice();
    const removeList = removeItemList ? removeItemList.slice() : [];
    const itemId = list[i].id;
    list.splice(i, 1);

    this.setState({ itemList: list }, () => {
      onAddItem(this.state.itemList);
    });

    if (itemId && onRemoveItem) {
      removeList.push(itemId);
      onRemoveItem(removeList);
    }
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onSortItem } = this.props;

    const sortedItems = arrayMove(this.props.itemList, oldIndex, newIndex);

    this.setState({ items: sortedItems }, () => {
      onSortItem(sortedItems);
    });
  };

  render() {
    const { inputValue, itemList, valid } = this.state;
    const { className, placeholder, cantRemove, sortableItems, showQuantities } = this.props;

    return (
      <div className={`${className} ${!valid ? 'invalid' : ''}`}>
        <div className="input_container">
          <Input
            name="inputValue"
            value={inputValue}
            onInputChange={this.handleInputChange}
            placeholder={placeholder}
            onEnter={this.handleAddItem}
          />
          <button
            type="button"
            className="add_item"
            onClick={this.handleAddItem}
          >Add</button>
          {!valid && (
            <div className="validation_error">{this.getErrorMessage()}</div>
          )}
        </div>

        {sortableItems &&
          <SortableComponent
            cantRemove={cantRemove}
            items={itemList}
            handleRemoveItem={this.handleRemoveItem}
            onSortEnd={this.onSortEnd}
            showQuantities={showQuantities}
            onQuantityChange={this.handleQuantityChange}
          />
        }

        {!sortableItems && Array.isArray(itemList) && itemList.length > 0 && (
          <ul className="item__list">
            {itemList.map((item, i) => (
              <li key={i} className="item">
                {typeof item === 'object' ? item.name : item}
                {(!cantRemove || item.id === undefined) && (
                  <span
                    className="item__remove"
                    onClick={() => this.handleRemoveItem(i)}
                  >
                    <FAIcon name="times" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

const StyledAddItemInput = styled(AddItemInput)`
  & > .input_container {
    position: relative;
    max-width: 70%;
    margin: auto;

    input {
      padding-right: 55px !important;
    }
  }

  .add_item {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    width: 50px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    border: none;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    transition: color 250ms;

    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }

  .item {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    padding: 3px 0;
    list-style-type: none;
    align-items: center;
    justify-content: ${props => props.showQuantities ? 'left' : 'center'};

    div {
      margin-left: auto;
    }

    input {
      margin: 0;
      width: 40px;
      text-align: center;
      padding: 2px;
    }

    &__list {
      margin: 15px 0 0;
      padding: 0;
      text-align: center;
      max-width: 70%;
      margin: auto;
    }

    &__bars {
      margin-right: 7px;
      cursor: pointer;

      svg {
        font-size: 13px;
        cursor: pointer;
        color: #bbb;
        transition: color 250ms;
      }
    }

    &__remove {
      display: inline-block;
      vertical-align: middle;
      margin-left: 7px;

      svg {
        font-size: 13px;
        cursor: pointer;
        color: #bbb;
        transition: color 250ms;
      }

      &:hover {
        svg {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }
    }
  }

  &.invalid {
    input {
      border-color: ${props => props.settings.theme.css.global.colors.error};
      margin-bottom: 0;
    }

    .validation_error {
      margin-bottom: 5px;
    }
  }

  .validation_error {
    font-size: 11px;
    text-align: left;
    margin-bottom: 3px;
    color: ${props => props.settings.theme.css.global.colors.error};
  }
`;

const StyledAddItemInputWithSettings = withSettings(StyledAddItemInput);

export { StyledAddItemInputWithSettings as AddItemInput };
