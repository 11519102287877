import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, withSettings } from '../components';
import { formatPriceWithCents } from '../../libs';

class RsvpPaymentModalPaymentSummary extends React.Component {
  payButtonClicked = () => {
    const { totalPrice, payButtonClicked } = this.props;

    payButtonClicked(totalPrice);
  }

  isCCError = type => {
    const { ccError, ccErrorObj } = this.props;
    return ccError && ccErrorObj.param === type;
  };

  getCCErrorMessage = () => {
    const { ccErrorObj } = this.props;

    switch (ccErrorObj.param) {
      case 'number':
        return 'The card number entered is invalid.';
      case 'exp_month':
        return 'The card expiration month entered is invalid.';
      case 'exp_year':
        return 'The card expiration year entered is invalid.';
      case 'cvc':
        return 'The card CVC entered is invalid.';
      default:
        return 'There is a problem with you credit card information.';
    }
  };

  render() {
    const { className, event, donation, numberOfGuests, isEventFree, guestsPrice, pricePerPerson } = this.props;
    const { couponCode, discountAmount, transactionFee, totalPrice, settings, ccError } = this.props;
    const { isPaying, shouldEnablePaymentButton } = this.props;
    const { copy, theme: { site } } = settings;
    const images = settings.theme.assets.images;

    const guestsDescriptionString = `${numberOfGuests} seat${numberOfGuests === 1 ? '' : 's'}` +
      ` @ ${formatPriceWithCents(pricePerPerson)}`;
    const guestsPriceString = formatPriceWithCents(guestsPrice);
    const discountString = couponCode ? `Coupon ${couponCode}` : 'Coupon';
    const discountAmountString = formatPriceWithCents(discountAmount);
    const transactionFeeString = formatPriceWithCents(transactionFee);
    const donationString = formatPriceWithCents(donation);
    const totalPriceString = totalPrice === 0 ? 'Free' : formatPriceWithCents(totalPrice);

    const bgImageUrl = event.cover ? event.cover.originalUrl : images.default_cover;

    return (
      <div className={className}>
        <div className="ot-banner" style={{ backgroundImage: `url(${bgImageUrl})` }}></div>
        <div>
          <h3>{event.title}</h3>
          <hr/>
          {(!isEventFree || event.pwyw) && (
            <div className="row">
              <p className="ot-data-row">
                <span>{guestsDescriptionString}</span>
                <span>{guestsPriceString}</span>
              </p>
            </div>
          )}
          {(!isEventFree || event.pwyw) && discountAmount > 0 && (
            <div className="row">
              <p className="ot-data-row">
                <span>{discountString}</span>
                <span>-{discountAmountString}</span>
              </p>
            </div>
          )}
          {(transactionFee > 0) && (
            <div className="row">
              <p className="ot-data-row">
                <span>{copy.rsvp.transaction_fee}</span>
                <span>{transactionFeeString}</span>
              </p>
            </div>
          )}
          {(donation > 0) && (
            <div className="row">
              <p className="ot-data-row">
                <span>{copy.rsvp.donation}</span>
                <span>{donationString}</span>
              </p>
            </div>
          )}
          <hr/>
          <div className="row">
            <p className="ot-data-row">
              <span>{copy.rsvp.total}</span>
              <span>{totalPriceString}</span>
            </p>
          </div>
          <div className="row submit-payment">
            {ccError && (
              <div className="cc_error">{this.getCCErrorMessage()}</div>
            )}
            <Button
              fullWidth
              capitalizeWords
              handleClick={this.payButtonClicked}
              loading={isPaying}
              disabled={isPaying || (totalPrice > 0 && !shouldEnablePaymentButton)}
            >
              {totalPrice === 0 ? copy.rsvp.submit : copy.rsvp.payment_submit}
            </Button>
          </div>
          <div className="row">
            <p className="ot-terms">
              <span>{copy.rsvp.you_agree_to}</span>
              <a href={site.terms_url} target="_blank" rel="noopener noreferrer">{copy.rsvp.terms}</a>
              <span>{copy.rsvp.you_will_receive}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

RsvpPaymentModalPaymentSummary.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalPaymentSummary = styled(RsvpPaymentModalPaymentSummary)`
  text-align: left;

  & .row {
    margin: 0;
  }

  & .ot-banner {
    background-color: #EEE;
    background-size: cover;
    background-position: center;
    display: inline-block;
    border-radius: 3px;
    height: 80px;
    width: 100%;
    vertical-align: top;
    margin: 30px 0;
  }

  & h3 {
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin: 0;
  }

  & .ot-data-row>span:last-child {
    flex-grow: 1;
    text-align: right;
    font-weight: bold;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
  }

  & .ot-terms {
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
  }

  & .ot-terms p {
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
  }

  & .ot-terms a {
    color: ${props => props.settings.theme.css.global.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }

  & .ot-data-row {
    font-size: 14px;
    margin-bottom: 2px;
    display: flex;
    width: 100%;
  }

  & .submit-payment {
    margin: 1em 0;
  }

  & .ot-terms span {
    color: #8c8c8c;
  }

  .cc_error {
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.error};
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const RsvpPaymentModalPaymentSummaryWithSettings = withSettings(StyledRsvpPaymentModalPaymentSummary);

export { RsvpPaymentModalPaymentSummaryWithSettings as RsvpPaymentModalPaymentSummary };
