import React, { useState } from 'react';
import { Modal } from '../../components';
import styled from 'styled-components';
import { Button, withSettings } from '../../components';
import { RECURRENCE_UPDATE_TYPE } from '../../../constants/recurrances';

const EventRecurrenceModal = (props) => {
  const { className, open, toggle, save, isPublish } = props;
  const [eventEditType, setEventEditType] = useState(RECURRENCE_UPDATE_TYPE.THIS_EVENT);
  
  const handleChange = (event) => {
    const { value } = event.target;
    setEventEditType(value);
  };

  return (
    <Modal size='sm' show={open} toggle={toggle} noPadding>
      <div className={className}>
        <header>
          <title>{isPublish ? 'Publish' : 'Edit'} Recurring Event</title>
        </header>
        <div className='radioGroupContainer'>
          {isPublish ? (
            <div className='message'>This will publish all events in the series.</div>
          ) : (
            Object.keys(RECURRENCE_UPDATE_TYPE).map((t) => {
              return (
                <div key={t}>
                  <input
                    type='radio'
                    value={RECURRENCE_UPDATE_TYPE[t]}
                    name='recurringEventUpdateType'
                    onChange={handleChange}
                    checked={eventEditType === RECURRENCE_UPDATE_TYPE[t]}
                  />
                  <label
                    className='radioGroupLabel'
                    onClick={() => handleChange({ target: { value: RECURRENCE_UPDATE_TYPE[t] } })}
                  >{RECURRENCE_UPDATE_TYPE[t]}</label>
                </div>
              );
            })
          )}

          <div className='modalButtonContainer'>
            <Button
              className='primary_outline left-button'
              handleClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
            <Button
              className='primary'
              handleClick={() => {
                save(eventEditType);
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const StyledEventRecurrenceModal = styled(EventRecurrenceModal)`
  padding: 30px;

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .radioGroupContainer {
    display: flex;
    flex-direction: column;

    input {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      transform: translateY(-1px);
      width: 14px;
      height: 14px;
      appearance: none;
      border: 1px solid #ccc;
      border-radius: 50%;
      margin-right: 7px;
      cursor: pointer;

      &:checked {
        background-color: ${props => props.settings.theme.css.global.colors.primary};
        border-color: ${props => props.settings.theme.css.global.colors.primary};

        &::after {
          content: '';
          width: 4px;
          height: 4px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -2px 0 0 -2px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }

    .radioGroupLabel {
      margin-left: 0.25rem;
      cursor: pointer;
    }
  }
  .modalButtonContainer {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    .left-button {
      margin-right: 0.25rem;
    }
  }
  header {
    text-align: center;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 20px;
    }

    p {
      font-size: 14px;
      margin: 0 0 20px;
    }
  }
`;

const StyledEventRecurrenceModalWithSettings = withSettings(StyledEventRecurrenceModal);

export { StyledEventRecurrenceModalWithSettings as EventRecurrenceModal };
