import React from 'react';
import styled from 'styled-components';

import {
  Input,
  Button,
  FAIcon
} from '../components';

import { withSettings } from '../components';
import { breakpoints } from '../../styles';

const defaultState = { query: '' };

class SearchBar extends React.Component {
  state = { ...defaultState };

  componentDidUpdate(prevProps) {
    const { area, addQuery } = this.props;
    if (prevProps.area && prevProps.area.code !== area.code) {
      this.setState({ ...defaultState }, () => addQuery(this.state.query));
    }
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.addQuery(this.state.query);
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { query } = this.state;
    const { className, toggleFilters, filtersOpen } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <div className={`${className} searchbar`}>
        <form
          className="ot_form"
          onSubmit={event => this.handleFormSubmit(event)}
        >
          <div className="search_box">
            <FAIcon name={['fal', 'search']} />
            <Input
              type="text"
              name="query"
              value={query}
              onInputChange={this.handleInputChange}
              placeholder={copy.search.placeholder}
            />
          </div>
          <Button type="submit">Search</Button>
          <Button
            type="button"
            buttonStyle="secondary"
            handleClick={toggleFilters}
          >
            <FAIcon name={['fas', 'filter']} />
            {filtersOpen ? 'Hide' : 'Show'} Filters
          </Button>
        </form>
      </div>
    );
  }
}

const StyledSearchBar = styled(SearchBar)`
  position: absolute;
  left: 0;
  right: 0;
  top: -88px;
  height: 88px;
  background-color: rgba(255,255,255,0.6);
  text-align: center;

  & > .ot_form {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .search_box {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 400px;
  }

  .fa-search {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  }

  input[type="text"] {
    margin: 0;
    border-radius: 3px 0 0 3px;
    padding-left: 40px;
  }

  button {
    &.primary {
      border-radius: 0 3px 3px 0;
    }

    &.secondary {
      margin-left: 15px;
      width: 140px;
      text-align: center;

      svg {
        font-size: 12px;
        margin-right: 4px;
        color: #888;
      }
    }
  }

  @media (${breakpoints.tabletSmall}) {
    top: -110px;
    height: 110px;

    .ot_form {
      padding: 0 20px;
    }

    .search_box {
      display: block;
      max-width: 100%;
      margin-bottom: 10px;

      input {
        border-radius: 3px;
      }
    }

    button {
      &.primary {
        border-radius: 3px;
      }
    }
  }
`;

const SStyledSearchBarWithSettings = withSettings(StyledSearchBar);

export { SStyledSearchBarWithSettings as SearchBar };
