import React, { useContext } from 'react';
import styled from 'styled-components';

import { FAIcon, SearchFilter } from '../../components';

import { FilterContext } from '../FilterContext';


export const ToggleButtonSearchFilter = ({ name, label }) => {
  const { getValue, setValue } = useContext(FilterContext);

  return (
    <SearchFilter active={getValue(name)} icon inline onClick={() => setValue(name, !getValue(name) || null)}>
      {label}
      <FilterIcon>{getValue(name) ? <X /> : <FAIcon name={[ 'fas', 'filter' ]} />}</FilterIcon>
    </SearchFilter>
  );
};

const FilterIcon = styled.span`
  .fa-filter {
    position: absolute;
    top: 50%;
    right: 14px;
    color: #97958f;
    font-size: 14px;
    margin-top: -7px;
    opacity: 0.25;
  }
`;

const X = styled.span`
  position: absolute;
  top: 50%;
  right: 14px;
  margin-top: -8px;
  width: 14px;
  height: 14px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #97958f;
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
