const fetchWordpressMenu = async settings => {
  if (!settings || !settings.global) return [];
  const wpRestApi = settings.global.wordpress_host;
  const wpMenuId = settings.global.wordpress_menu_id;
  if (!wpRestApi || !wpMenuId) return [];
  const response = await fetch(
    `${wpRestApi}/wp-json/menus/v1/menus/${wpMenuId}`
  );
  return await response.json();
};

export default fetchWordpressMenu;
