import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { stringInsert, isSite } from '../../libs';

const P = styled.p`
  margin: 0 0 20px;
  text-align: center;
  font-size: 14px;
`;

const EventListTotalCount = ({ settings, loading, totalEventsCount, archive }) => {
  const eventType = archive ? 'archived' : 'upcoming';

  const getSiteCopy = copy => {
    // settings.copy.landing.summary_all was added later on //
    // some whitelabel sites are still using the below hardcode //
    if (isSite(settings, ['hillel', 'dinner-party'])) {
      return copy || `There are %1$s ${eventType} experiences.`;
    } else if (isSite(settings, ['thegathering', 'signaturefd', 'homecoming'])) {
      return copy || `There are %1$s ${eventType} gatherings.`;
    } else if (isSite(settings, 'limitedwhitelabel')) {
      return copy || `There are %1$s ${eventType} events.`;
    } else if (isSite(settings, 'climatefest')) {
      return copy || 'There are %1$s archived events.'; // `There are %1$s ${eventType} events.`;
    } else {
      return copy || `There are %1$s ${eventType} dinners.`;
    }
  };

  const countCopy = archive
    ? getSiteCopy(settings.copy.landing.archive_summary_all)
    : getSiteCopy(settings.copy.landing.summary_all);

  return (
    <P>
      {stringInsert(
        countCopy,
        loading ? '..' : totalEventsCount
      )}
    </P>
  );
}

const EventListTotalCountWithSettings = withSettings(EventListTotalCount);
export { EventListTotalCountWithSettings as EventListTotalCount };
