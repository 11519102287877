import React, { useState } from 'react';
import styled from 'styled-components';
import { EmailSignUp, FacebookLogIn, withSettings } from '../components';
import { useSession } from '../../hooks';

const SignUp = ({
  className,
  shouldRedirect,
  afterLogin,
  settings: { copy, features }
}) => {
  const [showEmailSignUp, setShowEmailSignup] = useState(!features.facebook_login);
  const [showEmailSignupElements, setShowEmailSignupElements] = useState(true);

  const { refetch } = useSession();

  return (
    <div className={className}>
      <hr />
      {features.facebook_login && (
        <FacebookLogIn
          refetch={refetch}
          shouldRedirect={shouldRedirect}
          afterLogin={afterLogin}
          toggleEmailLogin={() => setShowEmailSignupElements(false)}
        />
      )}
      {showEmailSignupElements && (
        <>
          {features.facebook_login && <hr className="email_signup" onClick={() => setShowEmailSignup(prev => !prev)} />}
          {showEmailSignUp && <EmailSignUp refetch={refetch} shouldRedirect={shouldRedirect} afterLogin={afterLogin} />}
        </>
      )}
      <p className="terms" dangerouslySetInnerHTML={{ __html: copy.login.terms }} />
    </div>
  );
};

const StyledSignUp = styled(SignUp)`
  & > hr {
    margin: 30px 0;

    &.email_signup {
      position: relative;

      &::after {
        content: 'OR SIGN UP USING EMAIL';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        padding: 0 10px;
        background-color: #fff;
        color: ${({ colors }) => colors.primary};
        font-size: 10px;
        font-weight: bold;
        white-space: nowrap;
        cursor: pointer;
        transition: color 250ms;
      }

      &:hover {
        &::after {
          color: ${({ colors }) => colors.primaryHover};
        }
      }
    }
  }

  & > .terms {
    color: ${({ colors }) => colors.textLight};
    font-size: 12px;
    text-align: center;
    // max-width: 240px;
    margin: 20px auto 0;

    a {
      color: ${({ colors }) => colors.primary};
      text-decoration: underline;

      &:hover {
        color: ${({ colors }) => colors.primaryHover};
      }
    }
  }
`;

const StyledSignUpWithSettings = withSettings(StyledSignUp);

export { StyledSignUpWithSettings as SignUp };
