import validators from './StatusValidators';
import { whitelabel, validateProfileQuestionsAnswered } from '../../libs';
import { getProfileQuestions } from '../../constants/profileQuestions';

const restrictionModalStatusMap = (copy) => {
  return {
    mandatory_email_zip_and_bio: {
      header: copy.error.email_zip_bio_change_required_title,
      text: copy.error.email_zip_bio_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.change_required_button_update,
      action: 'goToProfile'
    },
    mandatory_email_and_zip: {
      header: copy.error.email_zip_change_required_title,
      text: copy.error.email_zip_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.change_required_button_update,
      action: 'goToProfile'
    },
    mandatory_email_and_bio: {
      header: copy.error.email_bio_change_required_title,
      text: copy.error.email_bio_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.change_required_button_update,
      action: 'goToProfile'
    },
    mandatory_zip_and_bio: {
      header: copy.error.bio_zip_change_required_title,
      text: copy.error.bio_zip_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.change_required_button_update,
      action: 'goToProfile'
    },
    mandatory_zip: {
      header: copy.error.zip_change_required_title,
      text: copy.error.zip_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.zip_change_required_button_update,
      action: 'goToProfile'
    },
    mandatory_email: {
      header: copy.error.email_change_required_title,
      text: copy.error.email_change_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.email_change_required_button_update,
      action: 'goToProfile'
    },
    bio: {
      header: copy.error.bio_required_title,
      text: copy.error.bio_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.bio_required_button_update,
      action: 'updateBio'
    },
    bio_rsvp: {
      header: copy.error.bio_required_title_rsvp,
      text: copy.error.bio_required_message_rsvp,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.bio_required_button_update_rsvp,
      action: 'updateBio'
    },
    avatar: {
      header: copy.error.avatar_required_title,
      text: copy.error.avatar_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.update_profile_button,
      action: 'updateAvatar'
    },
    avatar_bio: {
      header: copy.error.avatar_bio_required_title,
      text: copy.error.avatar_bio_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.update_profile_button,
      action: 'updateAvatarBio'
    },
    required_profile: {
      header: copy.error.profile_required_title,
      text: copy.error.profile_required_message,
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.update_profile_button,
      action: 'goToProfile'
    },
    none: null
  }
}

function RestrictionModalStatus(copy, session, event = null, settings, action = '') {
  if (!session || !session.viewer || !session.viewer.user) {
    return null;
  }

  const { email, profile, mustChangeEmail /* state */ } = session.viewer.user;
  const { zipCode, bio, answers, avatar } = profile;
  const hideZip = whitelabel.hasFeature(settings, 'hideZipCode');

  const requiresEmailChange = validators.requiresEmailChange(email, mustChangeEmail);
  const requiresZip = hideZip ? false : validators.requiresZip(zipCode);
  const requiresBio = validators.requiresBio(bio);

  const { profile: { required_profile_questions } } = settings;
  const requiredProfileQuestionsFromSettings = required_profile_questions ? JSON.parse(required_profile_questions) : [];
  const requiresCompleteProfile = settings?.event.require_profile_for_creation_and_rsvp;
  const profileQuestions = getProfileQuestions(settings)
    .reduce((qs, q) => q.required ? [...qs, q] : qs, [])
    .map(q => ({ value: q.name, message: settings?.copy.error.profile_required_message }));
  const requiredProfileQuestions = requiresCompleteProfile
    ? [...requiredProfileQuestionsFromSettings, ...profileQuestions]
    : requiredProfileQuestionsFromSettings;
  const profileAnswers = JSON.parse(answers);
  const questionsNotAnswered = validateProfileQuestionsAnswered(requiredProfileQuestions, profileAnswers, session.viewer.user);

  const isEventManualApproval = event && event.eventType === 'MANUAL_APPROVAL';
  const requiresBioForRsvp = action === 'rsvp' && settings?.event.require_bio_for_approval_rsvp;
  const requiresBioForCreation = action === 'creation' && settings?.event.require_bio_for_event_creation;
  const requiresAvatarForCreation = action === 'creation' && settings?.event.require_avatar_for_event_creation;
  let status = 'none';

  if (requiresBioForCreation && requiresBio) status = 'bio';
  if (requiresAvatarForCreation && !avatar) status = 'avatar';
  if (requiresBioForCreation && requiresBio && status === 'avatar') status = 'avatar_bio';
  if (requiresEmailChange) status = 'mandatory_email';
  if (requiresZip) status = 'mandatory_zip';
  if (requiresEmailChange && requiresZip) status = 'mandatory_email_and_zip';

  // if (action !== 'rsvp' || (requiresBioForRsvp && isEventManualApproval)) {
  // only add bio check to manual aproval rsvps! //
  if (requiresBioForRsvp && isEventManualApproval) {
    if (requiresBio) status = 'bio_rsvp';
    if (requiresBio && requiresEmailChange) status = 'mandatory_email_and_bio';
    if (requiresBio && !hideZip && requiresZip) status = 'mandatory_zip_and_bio';
    if (requiresBio && !hideZip && requiresZip && requiresEmailChange) status = 'mandatory_email_zip_and_bio';
  }

  // overrides the above restrictions for all event types //
  if (requiresCompleteProfile && questionsNotAnswered.length) status = 'required_profile';

  const modalData = restrictionModalStatusMap(copy)[status];

  const getRestrictionText = () => questionsNotAnswered.reduce((text, answer) => {
    if (!answer || !answer.message) return text;
    return text.includes(answer.message) ? text : `${text} ${answer.message}`;
  }, '');

  // combine questionsNotAnswered && profileQuestionsNotAnswered //

  if (!modalData && questionsNotAnswered.length) {
    return {
      header: copy.error.profile_question_change_required_title,
      text: getRestrictionText(),
      cancelLabel: copy.error.email_change_required_button_cancel,
      submitLabel: copy.error.change_required_button_update,
      action: 'goToProfile'
    };
  }

  return modalData;
}

export { RestrictionModalStatus };
