import React from 'react';
import styled from 'styled-components';
import { AddItemInput } from '../../components';
import { withSettings } from '../../components';
import { invalidateArray } from '../../../libs';

const EventCreationPotluckSelector = (props) => {
  const { className, eventData, onInputChange, invalidate } = props;
  const { settings: { copy } } = props;

  return (
    <div className={className}>
      <p className="potluck_instructions">
        {copy.event.potluck_instructions}
        <i>{copy.event.potluck_instructions_editable_options}</i>
      </p>
      <div className="items_wrapper">
        <AddItemInput
          name="potluckItems"
          itemList={eventData.potluckItems}
          placeholder={copy.event.add_potluck_placeholder}
          onAddItem={value => onInputChange('potluckItems', value)}
          onSortItem={value => onInputChange('potluckItems', value)}
          onRemoveItem={value => onInputChange('potluckItemsToDestroy', value)}
          onQuantityChange={value => onInputChange('potluckItems', value)}
          removeItemList={eventData.potluckItemsToDestroy || []}
          invalidate={() => invalidate && invalidateArray(eventData.potluckItems)}
          errorMessage={copy.event.potluck_no_item_error_message}
          required={eventData.potluck}
          showQuantities={true}
          sortableItems
          cantRemove
        />
      </div>
    </div>
  );
}


const StyledEventCreationPotluckSelector = styled(EventCreationPotluckSelector)`
  .potluck_instructions {
    font-size: 14px;
    text-align: center;

    i {
      display: block;
      color: ${props => props.settings.theme.css.global.colors.textLight};
      margin-top: 1px;
    }
  }

  .items_wrapper {
    max-width: 330px;
    margin: 30px auto 0;
  }
`;

const StyledEventCreationPotluckSelectorWithSettings = withSettings(StyledEventCreationPotluckSelector);

export { StyledEventCreationPotluckSelectorWithSettings as EventCreationPotluckSelector };
