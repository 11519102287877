import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import RestrictionModalManager from './components/Navbar/RestrictionModalManager';
import UserSection from './components/Navbar/UserSection';

import { useFacebookInit } from './hooks';
import { wordpressStickyUserNavDomRoot } from './libs/wordpress'

// for the sticky version of the menu
const StickyUserSection = () => {
  useEffect(() => {
    // portals don't seem to replace all of their children like
    // ReactDOM.render() does, so we have to do it ourselves
    // const loader = document.getElementById('app-wordpress-user-nav-2-loader');
    const loaders = Array.prototype.slice.call(document.querySelectorAll('.nav_loader'));
    if (loaders && loaders.length) {
      loaders.forEach(loader => loader && loader.remove());
    }
  }, []);

  return ReactDOM.createPortal(
    <UserSection />,
    wordpressStickyUserNavDomRoot()
  );
};

const WordPressApp = () => {
  useFacebookInit();

  console.log('wordpress app')
  return (
    <Router>
      <RestrictionModalManager>
        <StickyUserSection />
        <UserSection />
      </RestrictionModalManager>
    </Router>
  );
};

export default WordPressApp;
