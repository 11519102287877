export const mapData = [
  {
    area: 'atlanta',
    lat: 33.739919,
    lng: -84.383082,
    zoom: 10,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 33.672229, lng: -84.598939 },
      { id: 'b', name: 'Dinner 2', lat: 33.841527, lng: -84.418165 },
      { id: 'c', name: 'Dinner 3', lat: 33.715314, lng: -84.333294 },
      { id: 'd', name: 'Dinner 4', lat: 33.740565, lng: -84.383158 }
    ]
  },
  {
    area: 'beer_sheva',
    lat: 31.252375,
    lng: 34.792281,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 31.251073, lng: 34.788038 },
      { id: 'b', name: 'Dinner 2', lat: 31.260760, lng: 34.796382 }
    ]
  },
  {
    area: 'boston',
    lat: 42.361721,
    lng: -71.061185,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 42.353190, lng: -71.078206 },
      { id: 'b', name: 'Dinner 2', lat: 42.373957, lng: -71.061458 }
    ]
  },
  {
    area: 'chicago',
    lat: 41.852063,
    lng: -87.683562,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 41.889005, lng: -87.658591 },
      { id: 'b', name: 'Dinner 2', lat: 41.869578, lng: -87.629156 }
    ]
  },
  {
    area: 'colorado',
    lat: 39.689263,
    lng: -105.001658,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 39.694150, lng: -105.027842 },
      { id: 'b', name: 'Dinner 2', lat: 39.720694, lng: -104.969397 }
    ]
  },
  {
    area: 'dc_metro_area',
    lat: 38.919114,
    lng: -77.019384,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 38.938744, lng: -77.030977 },
      { id: 'b', name: 'Dinner 2', lat: 38.943684, lng: -77.014417 }
    ]
  },
  {
    area: 'detroit',
    lat: 42.343047,
    lng: -83.077062,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 42.353450, lng: -83.039474 },
      { id: 'b', name: 'Dinner 2', lat: 42.352181, lng: -83.008065 }
    ]
  },
  {
    area: 'los-angeles',
    lat: 34.061411,
    lng: -118.230658,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 34.038939, lng: -118.242594 },
      { id: 'b', name: 'Dinner 2', lat: 34.063544, lng: -118.264658 }
    ]
  },
  {
    area: 'new_york',
    lat: 40.791563,
    lng: -73.948102,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 40.798321, lng: -74.015227 },
      { id: 'b', name: 'Dinner 2', lat: 40.768166, lng: -73.925797 }
    ]
  },
  {
    area: 'orange-county',
    lat: 33.767248,
    lng: -117.812044,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 33.798637, lng: -117.839344 },
      { id: 'b', name: 'Dinner 2', lat: 33.809050, lng: -117.818149 }
    ]
  },
  {
    area: 'pittsburgh',
    lat: 40.444413,
    lng: -79.995608,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 40.462439, lng: -80.012865 },
      { id: 'b', name: 'Dinner 2', lat: 40.459827, lng: -79.944034 }
    ]
  },
  {
    area: 'Portland',
    lat: 45.524654,
    lng: -122.655359,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 45.531869, lng: -122.690126 },
      { id: 'b', name: 'Dinner 2', lat: 45.554832, lng: -122.682836 }
    ]
  },
  {
    area: 'san_francisco',
    lat: 37.765837,
    lng: -122.444038,
    zoom: 11,
    dinners: [
      { id: 'a', name: 'Dinner 1', lat: 37.770654, lng: -122.429044 },
      { id: 'b', name: 'Dinner 2', lat: 37.783815, lng: -122.484067 }
    ]
  }
];
