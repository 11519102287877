import gql from 'graphql-tag';

export const DATE_POPUPS = gql`
  query datePopups {
    datePopups {
      id
      date
      content
      label
      url
    }
  }
`;
