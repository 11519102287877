import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isPast } from '../../libs';

import {
    HostAvatar,
    EventLocation,
    Avatar,
    withSettings
} from '../components';

import { INVITATION_STATUS } from '../../enums';

import { breakpoints } from '../../styles';


class ManageEventsAttendingCard extends React.Component {

  handleCardClick = () => {
    const { history, event } = this.props;
    history.push(`/events/${event.node.uuid}`);
  };

  getAttendeesLabel = () => {
    const { event } = this.props;
    const isPastEvent = isPast(event.node);
    return isPastEvent ? 'Attended: ' : 'Attending: ';
  };

  isPendingApprovalEvent = (event, viewer) => {
    return viewer.currentReservations.nodes.filter(node => node.event.id === event.id && node.state === INVITATION_STATUS.PENDING).length;
  };

  render() {
    const { className, event: { node: event }, viewer, settings, showArea } = this.props;
    const images = settings.theme.assets.images;
    const { copy } = settings;
    const { eventHosts } = event;
    const eventHost = eventHosts && eventHosts.edges && eventHosts.edges.reduce(
      (host, { node: { creator, profile } }) => creator ? profile : host, {}
    );
    const isPendingApproval = this.isPendingApprovalEvent(event, viewer);
    return (
      <div className={className} onClick={this.handleCardClick}>
        <div className="col-one">
          <h2 className="mobile_title">{copy.manage.event_table_header}</h2>
          <img src={event.cover ? event.cover.mediumUrl : images.default_cover} alt={event.title} />
          <div>
            <p className="event_title">
              <strong>{event.title}</strong>
            </p>
            <EventLocation
              event={event}
              showPotluck={event.potluck}
              showCatered={event.catered}
              viewer={viewer}
              showArea={showArea}
              showTimezone
            />
          </div>
        </div>
        <div className="col-two">
          {eventHost && (
            <div className="host" onClick={e => e.stopPropagation()}>
              <Link to={`/user_profile/${eventHost.id}`}>
                <p className="name">{[eventHost.firstName, eventHost.lastName].join(' ').trim()}</p>
              </Link>
              <Link to={`/user_profile/${eventHost.id}`}>
                <div className="avatar">
                  <HostAvatar event={event} />
                </div>
              </Link>
            </div>
          )}
          {event.reservationsAccepted.nodes.length > 0 ? (
              <div className="attending" onClick={e => e.stopPropagation()}>
                <span className="attending_label">{this.getAttendeesLabel()}</span>
                {event.reservationsAccepted.nodes.map((r) => (
                  <Avatar
                    profile={r.profile}
                    size="sm"
                    layout="vertical"
                    linked
                    noName
                    key={r.id}
                  />
                ))}
                {event.reservationsAccepted.reservationsAccepted > 2 && (
                  <span className="more_label">
                    {`+${event.reservationsAccepted.reservationsAccepted - 2} More`}
                  </span>
                )}
              </div>
            ) : null
          }
          {isPendingApproval ? (
            <div className="pending">
              <span className="pending_label">{copy.manage.pending_approval}</span>
            </div>
          ) : null
          }
        </div>
      </div>
    );
  }
}

const StyledManageEventsAttendingCard = styled(ManageEventsAttendingCard)`
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.05);
  padding: 0;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  @media (${breakpoints.manageEventsListMobile}) {
    display: block;
    padding: 40px 20px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.settings.theme.css.global.card.shadowHover};
    transition: box-shadow 250ms;

    p, a {
      text-decoration: none !important;
    }
  }

  * {
    align-self: center;
  }

  .event_title {
    margin: 0 0 10px;

    a {
      color: ${props => props.settings.theme.css.global.colors.heading} !important;
    }

    @media (${breakpoints.manageEventsListMobile}) {
      margin-bottom: 15px;
    }
  }

  .mobile_title {
    display: none;
    font-size: 20px;
    margin: 0 0 10px;
    text-align: center;

    @media (${breakpoints.manageEventsListMobile}) {
      display: block;
    }
  }

  .col-one {
    display: flex;

    img {
      width: 256px;
    }

    div {
      margin-left: 30px;

      strong {
        font-size: 18px;
        margin-bottom: 10px;
      }
      div {
        margin-left: 20px;

        @media (${breakpoints.manageEventsListMobile}) {
          margin-left: 0;
        }
      }
    }

    @media (${breakpoints.manageEventsListMobile}) {
      display: block;

      & > div {
        margin: 0;
        text-align: center;
      }

      img {
        display: block;
        margin: 20px auto;
        width: 200px;
        height: 150px;
      }
    }
  }

  .col-two {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: stretch;

    & > div {
      display: flex;
      align-self: flex-end;
      margin: 20px;
    }

    @media (${breakpoints.manageEventsListMobile}) {
      display: block;

      & > div {
        display: block;
        text-align: center;
      }
    }

    .host {
      @media (${breakpoints.manageEventsListMobile}) {
        margin: 25px 20px;
      }

      .avatar {
        width: 46px;
        height: 46px;

        & > div {
          width: 46px;
          height: 46px;
        }

        @media (${breakpoints.manageEventsListMobile}) {
          margin: auto;
        }
      }

      & > a {
        display: block;

        & > p {
          color: ${props => props.settings.theme.css.global.colors.heading};
          font-weight: bold;
          margin: 0 25px 0 0;
          font-size: 14px;

          @media (${breakpoints.manageEventsListMobile}) {
            margin: 0 0 5px;
          }
        }
      }
    }

    .attending {
      position: relative;
      z-index: 10;

      & > div {
        margin: 0 0 0 5px !important;
      }

      p {
        margin: 0;
      }

      .sm.vertical {
        margin: 0;
      }

      .avatar {
        width: 36px;
        height: 36px;
        margin: 0;

        &-container {
          margin-right: 0;
        }
      }

      & > span {
        color: ${props => props.settings.theme.css.global.colors.primary};
        font-size: 11px;
        font-weight: bold;

        &.attending_label {
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
        }

        &.more_label {
          margin-left: 10px;
        }
      }

      .sm.vertical {
        vertical-align: middle;
      }
    }

    .pending {
      background-color: #C9ECF7;
      justify-content: center;
      align-items: center;
      padding: 0px 8px;
      border-radius: 10px;
      .pending_label {
        color: #036A8A;
        font-weight: 600;
        font-size: 10px;
      }
      svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }

  .avatar {
    margin-left: 6px;

    div {
      position: relative;
      width: 50px;
      height: 50px;
      bottom: 0px;
      left: 0px;
      margin-left: 0px;
    }
  }
`;

const ManageEventsAttendingCardWithRouter = withRouter(StyledManageEventsAttendingCard);
const ManageEventsAttendingCardWithSettings = withSettings(ManageEventsAttendingCardWithRouter);

export { ManageEventsAttendingCardWithSettings as ManageEventsAttendingCard };
