const modalsStyle = `
  .modal_content__simple {
    padding: 20px 15px;
  }

  .modal_content__simple h2 {
    font-size: 22px;
    margin: 0 0 20px;
    text-align: center;
  }

  .modal_content__simple p {
    font-size: 14px;
    line-height: 1.6;
    margin: 0 0 25px;
  }
`;

export default modalsStyle;
