import React, { useState } from 'react';
import styled from 'styled-components';
import { EventGuestsReservationItem } from '.';
import { withSettings } from '../components';
import { alert } from '../Global';

const EventGuestsReservations = (props) => {
  const { className, reservations, acceptReservationMutation, declineReservationMutation } = props;
  const [state, setState] = useState({ reservationsMode: {}, accepting: {}, declining: {}, });
  const { reservationsMode, accepting, declining } = state;

  const reservationGuestsButtonClicked = (reservationId) => {
    const newReservationMode = reservationsMode[reservationId] === 'show-guests' ? undefined : 'show-guests';

    const newReservationsMode = {
      ...reservationsMode,
      [reservationId]: newReservationMode,
    }

    setState({ ...state, reservationsMode: newReservationsMode });
  }

  const actionButtonClicked = (reservationId, form) => {
    const newReservationsMode = {
      ...reservationsMode,
      [reservationId]: form
    }

    setState({ ...state, reservationsMode: newReservationsMode });
  }

  const cancelActionButtonClicked = (reservationId) => {
    const newReservationsMode = {
      ...reservationsMode,
      [reservationId]: undefined,
    }

    setState({ ...state, reservationsMode: newReservationsMode });
  }

  const sendApprovalButtonClicked = async (reservationId, message) => {
    let newAccepting = { ...accepting, [reservationId]: true }
    setState({ ...state, accepting: newAccepting });

    const mutationInput = { id: reservationId, message: message };
    const response = await acceptReservationMutation({ variables: mutationInput });

    if (!response.errors) {
      alert.success('Reservation approved.');
    } else {
      alert.error('Reservation could not be approved.');
    }

    const newReservationsMode = {
      ...reservationsMode,
      [reservationId]: undefined,
    }

    newAccepting = { ...accepting, [reservationId]: false }
    setState({ ...state, accepting: newAccepting, reservationsMode: newReservationsMode });
  }

  const sendDeclineReservationButtonClicked = async (reservationId, message) => {
    let newDeclining = { ...declining, [reservationId]: true }
    setState({ ...state, declining: newDeclining });

    const mutationInput = { id: reservationId, message: message };
    const response = await declineReservationMutation({ variables: mutationInput });

    if (!response.errors) {
      alert.success('Reservation declined.');
    } else {
      alert.error('Reservation could not be declined.');
    }

    const newReservationsMode = {
      ...reservationsMode,
      [reservationId]: undefined,
    }

    newDeclining = { ...declining, [reservationId]: false }
    setState({ ...state, declining: newDeclining, reservationsMode: newReservationsMode });
  }


  return (
    <div className={className}>
      <div className="row reservations-list">
        { reservations.map(reservation => {
          return (
            <EventGuestsReservationItem
              key={reservation.id}
              reservation={reservation}
              showGuestsList={state.reservationsMode[reservation.id] === 'show-guests'}
              showApproveForm={state.reservationsMode[reservation.id] === 'show-approve-form'}
              showDeclineForm={state.reservationsMode[reservation.id] === 'show-decline-form'}
              reservationGuestsButtonClicked={reservationGuestsButtonClicked}
              actionButtonClicked={actionButtonClicked}
              cancelActionButtonClicked={cancelActionButtonClicked}
              sendApprovalButtonClicked={sendApprovalButtonClicked}
              sendDeclineReservationButtonClicked={sendDeclineReservationButtonClicked}
              loading={state.accepting[reservation.id] || state.declining[reservation.id]}
            />
          );
        })}
      </div>
    </div>
  );
}

const StyledEventGuestsReservations = styled(EventGuestsReservations)`
  background-color: ${props => props.settings.theme.css.global.colors.backgroundColor};
  border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
  // max-height: 300px;
  overflow-y: auto;
`;

const StyledEventGuestsReservationsWithSettings = withSettings(StyledEventGuestsReservations);

export { StyledEventGuestsReservationsWithSettings as EventGuestsReservations };
