import React from 'react';

import {
  EventCreationPage,
  EventCreationCommunityPage,
  EventCreationResourcesPage,
  EventCreationResourcesPageWl,
  LandingPage,
  EventListPage,
  EventSearchPage,
  EventListTagPage,
  EventListVirtualPage,
  EventListAllPage,
  EventListEventSubTypePage,
  EventSponsorshipsGuestsNumberSelectorPage,
  EventSponsorshipsSelectorPage,
  EventPage,
  EventViewPage,
  UserJourney,
  ManageEvents,
  ManageEventsDetails,
  ProfileEditPage,
  HostApplication,
  ProfileView,
  SponsorsPage,
  EventEmbedCodeSelector,
  RsvpPage,
  ProfileSettingsPage
} from '../components';


const archive = Component => props => <Component {...props} archive />;

export const routeConfig = theme => {
  const routeComponents = {
    journey: UserJourney,
    landing: theme.isVersion(2) ? LandingPage : EventListPage,
    landing_archive: archive(theme.isVersion(2) ? LandingPage : EventListPage),
    search: EventSearchPage,
    search_archive: archive(EventSearchPage),
    tag: EventListTagPage,
    tag_archive: archive(EventListTagPage),
    virtual: EventListVirtualPage,
    virtual_archive: archive(EventListVirtualPage),
    subtype: EventListEventSubTypePage,
    subtype_archive: archive(EventListEventSubTypePage),
    all_events: EventListAllPage,
    all_events_archive: archive(EventListAllPage),
    event_create: EventCreationPage,
    community_create: EventCreationCommunityPage,
    event: theme.isVersion(2) ? EventPage : EventViewPage,
    rsvp: RsvpPage,
    event_embed: EventEmbedCodeSelector,
    sponsors: SponsorsPage,
    sponsors_guests: EventSponsorshipsGuestsNumberSelectorPage,
    sponsors_selector: EventSponsorshipsSelectorPage,
    resources: theme.isCore ? EventCreationResourcesPage : EventCreationResourcesPageWl,
    manage: ManageEvents,
    manage_details: ManageEventsDetails,
    profile: ProfileView,
    profile_edit: theme.isVersion(2) ? ProfileSettingsPage : ProfileEditPage,
    host_promote: HostApplication
  };

  return { get: key => routeComponents[key] || null };
};
