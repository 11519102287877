import React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { withSettings } from '../../components';

import {
  Card,
  Button,
  EventCreationRsvpQuestion
} from '../../components';

import { GLOBAL_RESERVATION_QUESTIONS } from '../../../queries';

const emptyQuestion = {
    id: null,
    position: null,
    answerType: '',
    text: '',
    defaultLabel: 'Select a question',
    baseQuestionId: null,
    customQuestion: false,
    validatesQuestion: true,
    validatesAnswer: true
};

class EventCreationFormRsvpQuestions extends React.Component {
  state = { questions: [], loaded: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loaded } = this.state;
    const { eventData, hasUpdated } = this.props;

    if (eventData.reservationQuestions !== nextProps.eventData.reservationQuestions) {
      const reservationQuestions = nextProps.eventData.reservationQuestions;

      if (!loaded && Array.isArray(reservationQuestions)) {
        const questions = [];

        reservationQuestions.forEach(q => {
          const question = Object.assign({}, emptyQuestion);

          question.answerType = q.answerType;
          question.baseQuestionId = q.baseQuestion ? q.baseQuestion.id : null;
          question.customQuestion = q.baseQuestion === null;
          question.id = q.id;
          question.position = q.position;
          question.text = q.text;

          questions.push(question);
        });

        this.setState({ questions, loaded: true });
      }
    }

    if (hasUpdated !== nextProps.hasUpdated && nextProps.hasUpdated) {
      this.handUpdateQuestions();
    }
  }

  saveRsvpQuestions = () => {
    const { questions } = this.state;
    const { onInputChange } = this.props;
    onInputChange('reservationQuestions', questions);
  }

  handleAddQuestion = () => {
    const questions = this.state.questions.slice();

    questions.push(Object.assign({}, emptyQuestion));
    questions.forEach((question, i) => { question.position = i + 1; });

    this.setState({ questions }, () => this.saveRsvpQuestions());
  };

  handleRemoveQuestion = index => {
    const { onInputChange, eventData } = this.props;
    const questions = this.state.questions.slice();
    const deletedQuestion = questions[index];

    if (deletedQuestion && deletedQuestion.id) {
      const toDelete = eventData.reservationQuestionsToDelete.slice();
      toDelete.push(deletedQuestion.id);
      onInputChange('reservationQuestionsToDelete', toDelete);
    }

    questions.splice(index, 1);
    questions.forEach((question, i) => { question.position = i + 1; });

    this.setState({ questions }, () => this.saveRsvpQuestions());
  };

  handleSelectQuestion = (index, selection, questionId) => {
    const questions = this.state.questions.slice();

    questions[index].baseQuestionId = questionId;
    questions[index].text = selection;

    if (questionId !== null) questions[index].customQuestion = false;
    else questions[index].customQuestion = true;

    this.setState({ questions }, () => this.saveRsvpQuestions());
  };

  handleSelectAnswer = (value, index) => {
    const questions = this.state.questions.slice();

    questions[index].answerType = value;

    this.setState({ questions }, () => this.saveRsvpQuestions());
  }

  handleCreateCustomQuestion = (value, index) => {
    const questions = this.state.questions.slice();

    questions[index].text = value;

    this.setState({ questions }, () => this.saveRsvpQuestions());
  }

  handUpdateQuestions = () => {
    const { questions } = this.state;
    const filteredQuestions = questions.filter(q => q.text !== '' && q.answerType !== '');
    this.setState({ questions: filteredQuestions });
  };

  render() {
    const { questions } = this.state;
    const { className, invalidate, isUpdate } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <Query query={GLOBAL_RESERVATION_QUESTIONS}>
        {({ data, error }) => {
          if (error) {
            // console.error('GLOBAL_RESERVATION_QUESTIONS: ', error);
            return null;
          }

          const reservationQuestions = data && data.globalReservationQuestions;
          const suggestions = reservationQuestions && reservationQuestions.nodes;

          if (!suggestions) return null;

          return (
            <Card className={className}>
              <h2 className="card_title">{copy.event.rsvp_questions_edit_title}</h2>
              <i className="divider" />
              <p className="instruction">
                {copy.event.rsvp_questions_instruction} <strong>{copy.event.rsvp_questions_instruction_optional}</strong>
              </p>
              <div className="questions__container">
                {questions.length > 0 && questions.map((question, i) => (
                  <EventCreationRsvpQuestion
                    key={i}
                    index={i}
                    question={question}
                    suggestions={suggestions}
                    onRemoveQuestion={this.handleRemoveQuestion}
                    onSelectQuestion={this.handleSelectQuestion}
                    onCreateCustomQuestion={this.handleCreateCustomQuestion}
                    onSelectAnswer={this.handleSelectAnswer}
                    invalidate={invalidate}
                    isUpdate={isUpdate}
                  />
                ))}
                  <Button
                    className={questions.length > 0 ? 'has_questions' : ''}
                    handleClick={this.handleAddQuestion}
                    buttonStyle="primary_outline"
                    capitalize
                  >
                    Add{questions.length ? ' Another' : ''} Question
                  </Button>
              </div>
            </Card>
          );
        }}
      </Query>
    );
  }
}

const StyledEventCreationFormRsvpQuestions = styled(EventCreationFormRsvpQuestions)`
  & > .instruction {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .questions {
    &__container {
      margin: 30px auto 0;
      max-width: 700px;
    }
  }

  .button {
    display: block;
    width: auto;
    margin: auto;

    &.has_questions {
      margin-top: 10px;
    }
  }
`;

const StyledEventCreationFormRsvpQuestionsWithSettings = withSettings(StyledEventCreationFormRsvpQuestions);

export { StyledEventCreationFormRsvpQuestionsWithSettings as EventCreationFormRsvpQuestions };
