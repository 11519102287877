import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { useSettings } from '../../hooks';

import { 
  LandingPageContext,
  PageContentWrapper
} from '../components';


const LandingAnnouncement = () => {
  const { colors, settings } = useSettings();
  const { area } = useContext(LandingPageContext);

  const [show, setShow] = useState(true);
  const banner = area?.areaBanner;

  return show && banner ? (
    <Banner colors={colors}>
      <PageContentWrapper>
        {banner.label && <Tag colors={colors}>{banner.label}</Tag>}
        {banner.content && <P colors={colors} dangerouslySetInnerHTML={{ __html: banner.content }} />}
      </PageContentWrapper>
      <Closer onClick={() => setShow(false)} colors={settings.theme.css.global}></Closer>
    </Banner>
  ) : null;
};


const Banner = styled.div`
  background-color: ${({ colors }) => colors.announcementBackground};
  padding: 2.5rem 0 3rem;
  position: relative;
  border-bottom: 1px solid #ddd;

  @media (max-width: 650px) {
    padding: 2.5rem 0 3rem;
  }
`;

const Tag = styled.h4`
  color: ${({ colors }) => colors.announcementTag || colors.primary};
  background-color: ${({ colors }) => colors.announcementTagBackground};
  text-transform: uppercase;
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;
  font-size: 10px;
  letter-spacing: 0.12em;
  padding: 7px 7px 6px;
  border-radius: 4px;
  line-height: 1;
  margin: 0 0 1rem;
  display: inline-block;
`;

const P = styled.p`
  color: #222222;
  font-size: 14px;
  line-height: 1.8;
  margin: 0;

  a {
    color: ${({ colors }) => colors.primary};

    &:hover {
      color: ${({ colors }) => colors.primaryHover};
      text-decoration: none;
    }
  }
`;

const Closer = styled.i`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    top: 50%;
    left: 0;
    margin-top: -1px;
    transform-origin: center;
    transition: background-color 250ms;
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${({ colors }) => colors.pspanh1h2h3h4h5_color};
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export { LandingAnnouncement as LandingAnnouncement };
