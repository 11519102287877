import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles';

import { FAIcon } from '../components';


const JourneyPageIndicators = ({ className, step }) => {
  switch (step) {
    case 'music':
      return (
        <div className={[className, 'music'].join(' ')}>
          <span>
            Click play to set the mood
            <svg version="1.1"className="arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 369.915 369.914">
            <g>
            	<path d="M364.507,268.292c-33.048-8.567-63.647-26.315-97.92-29.988c-6.731-0.611-7.956,7.956-4.896,11.629
            		c4.283,4.896,9.792,12.239,15.912,18.359c-17.137,1.225-33.049,7.344-50.185,11.017c-15.912,3.672-31.824,1.224-46.511-4.284
            		c3.672-3.672,6.731-7.956,10.404-12.24c24.479-34.884,39.779-149.328-23.868-157.284c-36.108-4.896-69.156,41.004-70.992,73.44
            		c-2.448,42.84,23.256,78.948,58.14,100.368c-14.688,9.792-32.436,14.688-51.408,15.911c-39.78,1.837-67.932-24.479-81.396-59.976
            		C-2.692,170.372,18.116,81.632,87.884,56.54c3.06-1.224,3.06-6.12-0.612-6.12C-0.244,51.644-12.484,182,9.548,242.588
            		C34.64,311.744,117.26,326.433,169.28,286.04c4.284,1.836,9.18,4.284,14.076,5.508c33.659,11.628,63.647-4.896,96.083-8.567
            		c-4.284,7.956-8.568,16.523-14.688,23.868c-4.284,5.508-0.612,14.688,7.344,12.239c33.048-9.792,66.708-17.136,94.86-37.332
            		C371.24,278.084,371.24,269.517,364.507,268.292z M167.444,268.292c-7.344-4.284-14.688-9.18-20.808-14.688
            		c-42.228-35.496-48.348-101.592,4.284-130.968c24.48-13.464,38.555,16.524,43.452,35.496c7.344,27.54,3.06,59.364-8.568,85.068
            		C180.296,252.992,174.176,260.948,167.444,268.292z M299.635,276.248L299.635,276.248c0.612,0,1.225-1.224,1.225-1.836
            		c-1.836-6.732-7.345-12.24-13.464-17.748c19.584,5.508,37.943,14.076,57.527,20.196c-18.359,9.792-38.556,15.912-59.363,22.032
            		c3.672-6.732,6.731-13.465,12.239-18.973C299.024,278.696,299.635,277.472,299.635,276.248z" />
            </g>
            </svg>
          </span>
        </div>
      );
    default:
      return (
        <div className={className}>
          <span>
            <FAIcon name={['fal', 'mouse-pointer']} />
            Hover to view
          </span>
          <span>
            <FAIcon name={['fas', 'mouse-pointer']} />
            Click to select
          </span>
        </div>
      );
  }
};

const StyledJourneyPageIndicators = styled(JourneyPageIndicators)`
  position: fixed;
  right: 60px;
  top: 150px;

  ${media.journeyMd`
    display: none;
  `}

  &.music {
    right: 150px;
  }

  span {
    display: inline-block;
    color: #fff;
    font-family: 'Avenir-Light' !important;
    font-size: 14px;
    letter-spacing: 0.05em;
    padding: 0 20px;

    svg {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .arrow {
    width: 30px;
    margin: 0 0 0 10px;

    path {
      fill: #fff;
    }
  }
`;

export { StyledJourneyPageIndicators as JourneyPageIndicators };
