import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { SessionContext } from '../../../../Context';

import {
  Card,
  AreaSelector,
  Input,
  InfoTooltip,
  withSettings
} from '../../../components';

import {
  sort,
  invalidateNull
} from '../../../../libs';

import { breakpoints } from '../../../../styles';


const EventCreationFormLocation = props => {
  const {
    className,
    eventData,
    onInputChange,
    onValidate,
    invalidate,
    isUpdate,
    settings,
    areasFetched,
    embed = false
  } = props;
  const { copy } = settings;

  const [area, setArea] = useState(null);

  useEffect(() => {
    if (eventData) {
      setArea(eventData.area);
    }
  }, [eventData]);

  const handleAreaSelect = (name, value) => {
    onInputChange({
      [name]: value,
      timezone: value.timezone,
      neighborhood: null,
      subneighborhood: null
    });

    setArea(value);
  };

  const renderContent = sortedAreas => (
    <>
      {!embed && (
        <>
          <h2 className="card_title">{copy.event.place_title}</h2>
          <i className="divider"/>
        </>
      )}

      {/* temp for WL */}
      
        <div className="form_group half_width">
          <div>
            {/* City */}
            <label>{copy.create.city}</label>
            <AreaSelector
              name="area"
              areas={sortedAreas}
              eventData={eventData}
              onInputChange={(name, value) => handleAreaSelect(name, value)}
              invalidate={() => invalidate && invalidateNull(eventData.area)}
              onValidate={onValidate}
              errorMessage="Select an area."
              isUpdate={isUpdate}
              disabled={isUpdate}
            />
          </div>
          {!eventData.hideLocation &&
          <div>
            {/* Virtual Location  */}
            <label className="with_tooltip">
              {copy.create.virtual_location}
              <InfoTooltip content={copy.event.virtual_location_tooltip}/>
            </label>
            <Input
              type="text"
              name="virtualLocation"
              value={eventData.virtualLocation}
              onInputChange={onInputChange}
            />
          </div>
          }
        </div>
      
    </>
  );

  const { session } = useContext(SessionContext);
  const areas = session.areas;
  if (!areas) console.error('Error loading Areas.');
  const sortedAreas = sort.byObjKey(areas, 'position');

  if (!isUpdate && !area) {
    areasFetched(sortedAreas);
  }

  if (embed) return <div className="embedded_location">{renderContent(sortedAreas)}</div>;
  else return <Card className={className}>{renderContent(sortedAreas)}</Card>;
};

const StyledEventCreationFormLocation = styled(EventCreationFormLocation)`
  @media (${breakpoints.tabletSmall}) {
    .radio_wrapper {
      margin: 0 !important;
    }
  }

  @media (${breakpoints.mobile}) {
    .form_group {
      &.radios {
        .one_half {
          &:first-of-type {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
`;

const EventCreationFormLocationWithSettings = withSettings(StyledEventCreationFormLocation);

export { EventCreationFormLocationWithSettings as EventCreationFormLocation };
