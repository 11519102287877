import React from 'react';
import styled from 'styled-components';

import { PageContentWrapper as Wrapper } from './';

export const PageContent = ({ children }) => <Wrapper><Content>{children}</Content></Wrapper>;

const Content = styled.div`
  padding: 40px 0 140px;

  @media (max-width: 700px) {
    padding: 40px 0 100px;
  }
`;
