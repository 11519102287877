import gql from 'graphql-tag';

export const UPDATE_EVENT_SPONSORSHIPS = gql`
  mutation ($eventId: Int!, $sponsorships: [SponsorshipInput!]!, $notifyCreator: Boolean) {
    updateSponsorships(input: {
      eventId: $eventId,
      sponsorships: $sponsorships,
      notifyCreator: $notifyCreator
    }) {
      sponsorships {
        id
        amount
        sponsor {
          id
          name
        }
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_EVENT_EXPECTED_GUESTS = gql`
  mutation ($eventId: Int!, $expectedGuests: Int!) {
    updateEvent(input: {
      id: $eventId,
      expectedGuests: $expectedGuests
    }) {
      event {
        id
        expectedGuests
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_EVENT_SPONSOR_FORM_SUBMITTED = gql`
  mutation ($eventId: Int!, $sponsorFormSubmitted: Boolean!) {
    updateEvent(input: {
      id: $eventId,
      sponsorFormSubmitted: $sponsorFormSubmitted
    }) {
      event {
        id
        sponsorFormSubmitted
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_EVENT_COHOSTS = gql`
  mutation ($id: Int!, $cohosts: [CohostInput!]) {
    updateEvent(input: {
      id: $id,
      cohosts: $cohosts
    }) {
      event {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const SEND_RSVP_REMINDER = gql`
    mutation sendRsvpReminder(
        $eventId: Int!
        $message: Html!
    ) {
        sendRsvpReminder(
            input: {
                eventId: $eventId
                message: $message
            }
        ) {
            clientMutationId
        }
    }
`;

export const SEND_MESSAGE_TO_PAST_GUESTS = gql`
  mutation sendPostEventMessage(
    $eventId: Int!
    $message: Html!
  ) {
    sendPostEventMessage(
      input: {
          eventId: $eventId
          message: $message
      }
    ) {
      errors {
        message
      }
    }
  }
`;

export const SEND_EVENT_REMINDER = gql`
    mutation sendEventReminder(
        $eventId: Int!
        $message: Html!
    ) {
      sendEventReminder(
            input: {
                eventId: $eventId
                message: $message
            }
        ) {
            clientMutationId
        }
    }
`;

export const EVENT_PUBLISH = gql`
    mutation EventPublish(
        $eventId: Int!
    ) {
        publishEvent(
            input: {
                id: $eventId
            }
        ) {
            clientMutationId
        }
    }
`;

export const SUBMIT_PENDING_APPROVAL = gql`
  mutation SubmitPendingApproval(
      $eventId: Int!
  ) {
    requestEventPublishApproval(
      input: {
        id: $eventId
      }
    ) {
      clientMutationId
      event {
        id
        state
      }
    }
  }
`;

export const EVENT_CANCEL = gql`
    mutation EventCancel(
        $eventId: Int!,
        $cancelReason: String,
        $cancelReasonText: String
    ) {
        cancelEvent(
      input: { id: $eventId, cancelReason: $cancelReason, cancelReasonText: $cancelReasonText }
    ) {
      clientMutationId
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent (
    $eventType: EventType!,
    $eventSubtypeId: Int,
    $eventSubtypeOther: String,
    $privacySubtypeId: Int,
    $scheduledAt: ISO8601DateTime!,
    $duration: Int,
    $fullAddress: String,
    $secondaryAddress: String,
    $areaId: Int!,
    $areaOther: String,
    $stateLocation: String,
    $timezoneOther: String,
    $neighborhood: String,
    $neighborhoodId: Int,
    $subneighborhood: String,
    $title: String!,
    $description: Html!,
    $notes: String,
    $petsAllowed: Boolean,
    $alcoholPolicy: AlcoholPolicy,
    $numberOfGuestsMin: Int,
    $numberOfGuestsMax: Int!,
    $pricePerPerson: Int,
    $hostAbsorbsTransactionFee: Boolean,
    $explainPayment: String,
    $dietaryRestrictions: [DietaryRestriction!],
    $coverUrl: String,
    $dressCode: String,
    $accessible: Boolean,
    $parkingLots: Boolean,
    $cohosts: [CohostInput!],
    $deadlineAt: ISO8601DateTime,
    $communityDinner: Boolean,
    $partnerOrganizationName: String,
    $partnerOrganizationDescription: String,
    $partnerOrganizationLogoUrl: String,
    $tables: [TableInput!],
    $tags: [String!],
    $catered: Boolean,
    $potluck: Boolean,
    $potluckItemsObj: [CreatePotluckItemObjectInput!],
    $eventCreationResponses: [Int!],
    $sponsorFormSubmitted: Boolean,
    $reservationQuestions: [ReservationQuestionInput!],
    $publish: Boolean,
    $virtual: Boolean,
    $virtualLocation: String,
    $virtualResourceUrl: String,
    $extraDetails: Html,
    $pwyw: Boolean,
    $pwywMinimum: Int,
    $hideGuestList: Boolean,
    $journeyResponseIds: [Int!],
    $closedCaptioningAvailable: Boolean,
    $videosOnAsDefault: Boolean,
    $bathroomAccessible: Boolean,
    $recurrenceDates: [ISO8601Date!]
  ) {
    createEvent(input: {
      eventType: $eventType,
      eventSubtypeId: $eventSubtypeId,
      eventSubtypeOther: $eventSubtypeOther,
      privacySubtypeId: $privacySubtypeId,
      scheduledAt: $scheduledAt,
      duration: $duration,
      fullAddress: $fullAddress,
      secondaryAddress: $secondaryAddress,
      areaId: $areaId,
      areaOther: $areaOther,
      stateLocation: $stateLocation,
      timezoneOther: $timezoneOther,
      neighborhood: $neighborhood,
      neighborhoodId: $neighborhoodId,
      subneighborhood: $subneighborhood,
      title: $title,
      description: $description,
      notes: $notes,
      petsAllowed: $petsAllowed,
      alcoholPolicy: $alcoholPolicy,
      numberOfGuestsMin: $numberOfGuestsMin,
      numberOfGuestsMax: $numberOfGuestsMax,
      pricePerPerson: $pricePerPerson,
      hostAbsorbsTransactionFee: $hostAbsorbsTransactionFee,
      explainPayment: $explainPayment,
      dietaryRestrictions: $dietaryRestrictions,
      coverUrl: $coverUrl,
      dressCode: $dressCode,
      accessible: $accessible,
      parkingLots: $parkingLots,
      cohosts: $cohosts,
      deadlineAt: $deadlineAt,
      communityDinner: $communityDinner,
      partnerOrganizationName: $partnerOrganizationName,
      partnerOrganizationDescription: $partnerOrganizationDescription,
      partnerOrganizationLogoUrl: $partnerOrganizationLogoUrl,
      tables: $tables,
      tags: $tags,
      catered: $catered,
      potluck: $potluck,
      potluckItemsObj: $potluckItemsObj,
      eventCreationResponses: $eventCreationResponses,
      sponsorFormSubmitted: $sponsorFormSubmitted,
      reservationQuestions: $reservationQuestions,
      publish: $publish,
      virtual: $virtual,
      virtualLocation: $virtualLocation,
      virtualResourceUrl: $virtualResourceUrl,
      extraDetails: $extraDetails,
      pwyw: $pwyw,
      pwywMinimum: $pwywMinimum,
      hideGuestList: $hideGuestList,
      journeyResponseIds: $journeyResponseIds,
      closedCaptioningAvailable: $closedCaptioningAvailable,
      videosOnAsDefault: $videosOnAsDefault,
      bathroomAccessible: $bathroomAccessible,
      recurrenceDates: $recurrenceDates
    }) {
      event {
        id
        uuid
        nourishable
        eventType
        recurrences { id }
      }
      errors { message, path }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent (
    $id: Int!,
    $eventType: EventType,
    $eventSubtypeId: Int,
    $eventSubtypeOther: String,
    $privacySubtypeId: Int,
    $scheduledAt: ISO8601DateTime,
    $duration: Int,
    $fullAddress: String,
    $secondaryAddress: String,
    $areaId: Int,
    $areaOther: String,
    $stateLocation: String,
    $timezoneOther: String,
    $neighborhood: String,
    $neighborhoodId: Int,
    $subneighborhood: String,
    $title: String,
    $description: Html,
    $notes: String,
    $petsAllowed: Boolean,
    $alcoholPolicy: AlcoholPolicy,
    $numberOfGuestsMin: Int,
    $numberOfGuestsMax: Int,
    $pricePerPerson: Int,
    $hostAbsorbsTransactionFee: Boolean,
    $explainPayment: String,
    $dietaryRestrictions: [DietaryRestriction!],
    $coverUrl: String,
    $dressCode: String,
    $accessible: Boolean,
    $parkingLots: Boolean,
    $cohosts: [CohostInput!],
    $deadlineAt: ISO8601DateTime,
    $communityDinner: Boolean,
    $partnerOrganizationName: String,
    $partnerOrganizationDescription: String,
    $partnerOrganizationLogoUrl: String,
    $createTables: [TableInput!],
    $updateTables: [UpdateTableInput!],
    $destroyTables: [Int!],
    $tags: [String!],
    $catered: Boolean,
    $potluck: Boolean,
    $createPotluckItemsObj: [CreatePotluckItemObjectInput!],
    $updatePotluckItems: [UpdatePotluckItemInput!],
    $destroyPotluckItems: [Int!],
    $sponsorFormSubmitted: Boolean,
    $createReservationQuestions: [ReservationQuestionInput!],
    $updateReservationQuestions: [UpdateReservationQuestionInput!],
    $destroyReservationQuestions: [Int!],
    $virtual: Boolean,
    $virtualLocation: String,
    $virtualResourceUrl: String,
    $extraDetails: Html,
    $pwyw: Boolean,
    $pwywMinimum: Int,
    $hideGuestList: Boolean,
    $closedCaptioningAvailable: Boolean,
    $videosOnAsDefault: Boolean,
    $bathroomAccessible: Boolean,
    $expectedGuests: Int
  ) {
    updateEvent(input: {
      id: $id,
      eventType: $eventType,
      eventSubtypeId: $eventSubtypeId,
      eventSubtypeOther: $eventSubtypeOther,
      privacySubtypeId: $privacySubtypeId,
      scheduledAt: $scheduledAt,
      duration: $duration,
      fullAddress: $fullAddress,
      secondaryAddress: $secondaryAddress,
      areaId: $areaId,
      areaOther: $areaOther,
      stateLocation: $stateLocation,
      timezoneOther: $timezoneOther,
      neighborhood: $neighborhood,
      neighborhoodId: $neighborhoodId,
      subneighborhood: $subneighborhood,
      title: $title,
      description: $description,
      notes: $notes,
      petsAllowed: $petsAllowed,
      alcoholPolicy: $alcoholPolicy,
      numberOfGuestsMin: $numberOfGuestsMin,
      numberOfGuestsMax: $numberOfGuestsMax,
      pricePerPerson: $pricePerPerson,
      hostAbsorbsTransactionFee: $hostAbsorbsTransactionFee,
      explainPayment: $explainPayment,
      dietaryRestrictions: $dietaryRestrictions,
      coverUrl: $coverUrl,
      dressCode: $dressCode,
      accessible: $accessible,
      parkingLots: $parkingLots,
      cohosts: $cohosts,
      deadlineAt: $deadlineAt,
      communityDinner: $communityDinner,
      partnerOrganizationName: $partnerOrganizationName,
      partnerOrganizationDescription: $partnerOrganizationDescription,
      partnerOrganizationLogoUrl: $partnerOrganizationLogoUrl,
      createTables: $createTables,
      updateTables: $updateTables,
      destroyTables: $destroyTables,
      tags: $tags,
      catered: $catered,
      potluck: $potluck,
      createPotluckItemsObj: $createPotluckItemsObj,
      updatePotluckItems: $updatePotluckItems,
      destroyPotluckItems: $destroyPotluckItems,
      sponsorFormSubmitted: $sponsorFormSubmitted,
      createReservationQuestions: $createReservationQuestions,
      updateReservationQuestions: $updateReservationQuestions,
      destroyReservationQuestions: $destroyReservationQuestions,
      virtual: $virtual,
      virtualLocation: $virtualLocation,
      virtualResourceUrl: $virtualResourceUrl,
      extraDetails: $extraDetails,
      pwyw: $pwyw,
      pwywMinimum: $pwywMinimum,
      hideGuestList: $hideGuestList,
      closedCaptioningAvailable: $closedCaptioningAvailable,
      videosOnAsDefault: $videosOnAsDefault,
      bathroomAccessible: $bathroomAccessible,
      expectedGuests: $expectedGuests
    }) {
      event {
        id,
        uuid,
        nourishable
      }
      errors { message }
    }
  }
`;
