import React from 'react';
import styled, { css } from 'styled-components';
import { isRestrictedDay, getExceptionSelectors } from '../../../libs';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import { withSettings } from '../../components';

moment.locale('en');
momentLocalizer();

// react-widgets defaults
const defaultDates = {
  min: new Date(1900, 0, 1),
  max: new Date(2099, 11, 31)
};

class DatePicker extends React.Component {
  state = { valid: true };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { invalidate, required } = this.props;

    if (invalidate !== nextProps.invalidate && nextProps.invalidate) {
      if (nextProps.invalidate()) {
        this.setState({ valid: false });
      }
    }

    if (required !== nextProps.required && !nextProps.required) {
      this.setState({ valid: true });
    }
  }

  getDateMin = () => {
    const { min } = this.props;
    if (min) return min;
    return defaultDates.min
  };

  getDateMax = () => {
    const { max } = this.props;
    if (max) return max;
    return defaultDates.max
  };

  parseDate = str => {
    const date = new Date(str);
    if (isNaN(date.getTime())) return null;
    return date;
  };

  getRestrictionClasses = () => {
    const { dayRestrictions } = this.props;
    if (typeof dayRestrictions === 'undefined') return '';

    const restriction = dayRestrictions ? dayRestrictions.replace(/,/g, ' ') : '';
    return `restricted ${restriction.toLowerCase()}`;
  };

  getErrorMessage = () => {
    const { errorMessage, name } = this.props;
    return errorMessage ? errorMessage : `Please enter a valid ${name}.`;
  };

  handleDateChange = value => {
    const { onDateChange, onValidate, required, name, dayRestrictions, dayExceptions } = this.props;

    if (value && isRestrictedDay(value, dayRestrictions, dayExceptions)) return;

    onDateChange(value);

    if (!required) return;

    if (this.dateIsValid(value)) {
      this.setState({ valid: true });
      onValidate(name, true);
    } else {
      this.setState({ valid: false });
      onValidate(name, false);
    }
  };

  dateIsValid = value => {
    return value !== null;
  };

  render() {
    const { valid } = this.state;
    const { className, value, disabled, format, name, open, style: userStyle, stopPropagation, themed } = this.props;
    const dateFormat = format ? format : 'MMM D, YYYY';
    const classes = [
      'date_picker',
      className,
      this.getRestrictionClasses(),
      ...(!valid ? ['invalid'] : []),
      ...(themed ? ['themed'] : []),
    ];

    return (
      <div
        className={classes.join(' ').trim()}
        style={userStyle || null}
        onClick={e => stopPropagation && e.stopPropagation()}
      >
        <DateTimePicker
          min={this.getDateMin()}
          max={this.getDateMax()}
          value={value ? value : null}
          parse={str => this.parseDate(str)}
          format={dateFormat}
          onChange={inputValue => this.handleDateChange(inputValue)}
          time={false}
          disabled={disabled}
          name={name}
          open={open ? 'date' : undefined}
          onToggle={() => {}}
        />
        {!this.state.valid && (
          <div className="validation_error">{this.getErrorMessage()}</div>
        )}
      </div>
    );
  }
}


const getStyles = (props, type) => {
  switch (type) {
    case 'notDisabledStyles':
      return `
        color: #000 !important;
        cursor: pointer !important;
        opacity: 1 !important;
      `;
    case 'disabledStyles':
      return `
        color: #999;
        opacity: 0.7;
        border-color: transparent;
        border-radius: 0;
        cursor: not-allowed;
        font-size: 14px;

        &:hover {
          background-color: ${props.settings.theme.css.global.colors.backgroundDisabled};
        }
      `;
    default:
      return `
        font-size: 14px;
        border-radius: 0;
        transition: border 250ms, background-color 250ms, color 250ms;

        &:hover {
          background-color: ${props.settings.theme.css.global.colors.menuHover};
          border: 1px solid transparent;
        }

        &.rw-state-selected {
          background-color: ${props.settings.theme.css.global.colors.primary};
          border-color: ${props.settings.theme.css.global.colors.primary};
          color: #fff !important;

          &:hover {
            background-color: ${props.settings.theme.css.global.colors.primaryHover} !important;
            border-color: ${props.settings.theme.css.global.colors.primaryHover} !important;
          }
        }

        &.rw-state-focus {
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }
        }
      `;
  }
};


const StyledDatePicker = styled(DatePicker)`
  margin: 0 0 14px;

  .rw-popup {
    padding: 8px 0;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.12);

    &-container {
      max-width: 90vw;
    }

    &-transition {
      transition: none !important;
    }
  }

  .rw-popup-container {
    max-width: 90vw;
  }

  .rw-widget-picker {
    display: inline-table;
    padding: 6px 0 6px 12px;

    input[type="text"] {
      padding: 0;
      border: 0;
      box-shadow: none !important;
      height: auto;
      margin: 0;
      display: block;
    }

    .rw-select {
      border: 0;

      button {
        appearance: none;
        width: 40px;
        color: #000;

        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      &-bordered {
        &:hover,
        &:active {
          box-shadow: none;
          background: none;
        }
      }
    }
  }

  .rw-now {
    color: ${props => props.settings.theme.css.global.colors.primary};
  }

  .rw-state-disabled {
    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.backgroundDisabled} !important;
    }
  }

  .rw-cell.rw-state-selected {
    background-color: ${props => props.settings.theme.css.global.colors.primaryDisabled} !important;
    border-color: ${props => props.settings.theme.css.global.colors.primaryDisabled} !important;
  }

  &:not(.restricted) {
    .rw-cell:not(.rw-state-disabled) {
      ${props => getStyles(props, 'cellStyles')}
    }
  }

  &.restricted {
    .rw-cell {
      ${props => getStyles(props, 'disabledStyles')}
    }

    &.sun {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(1) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.mon {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(2) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.tue {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(3) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.wed {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(4) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.thu {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(5) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.fri {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(6) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    &.sat {
      .rw-cell:not(.rw-state-disabled) {
        &:nth-of-type(7) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
    .rw-cell {
      ${props => getExceptionSelectors(props.settings.event.date_exceptions)} {
        &:not(.rw-state-disabled) {
          ${props => getStyles(props, 'cellStyles')}
          ${props => getStyles(props, 'notDisabledStyles')}
        }
      }
    }
  }

  .rw-btn-primary {
    transition: background-color 250ms;
    font-size: 14px;

    &:hover {
      background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
    }
  }

  .rw-head-cell {
    font-size: 12px;
  }

  &.invalid {
    .rw-widget-container {
      border-color: ${props => props.settings.theme.css.global.colors.error};
    }

    .validation_error {
      font-size: 11px;
      color: ${props => props.settings.theme.css.global.colors.error};
    }
  }

  input[readonly] {
    cursor: default;
  }

  .rw-state-disabled,
  input[disabled].rw-input,
  .rw-state-disabled>.rw-widget-container,
  .rw-state-disabled .rw-select-bordered {
    cursor: default;
  }

  ${props => props.noInput && css`
    .rw-widget-picker {
      display: none;
    }
  `}
`;

const StyledDatePickerWithSettings = withSettings(StyledDatePicker);

export { StyledDatePickerWithSettings as DatePicker };
