import React from 'react';
import styled from 'styled-components';
import { SelectBox } from '../../components';

class AreaSelector extends React.Component {
  getAreaOptions = areas => {
    const { addAllDefault } = this.props;
    const areaList = areas.slice();

    areaList.forEach((area, i) => {
      areaList[i]['value'] = areaList[i].url;
    });

    if (addAllDefault) areaList.unshift({ label: 'All', value: 'All' });

    return areaList;
  };

  handleSelectArea = (areas, value) => {
    const { onInputChange, name, returnKey } = this.props;
    const area = areas.find(a => a.value === value);
    let returnValue;

    if (area) {
      returnValue = returnKey ? area[returnKey] : area;
      onInputChange(name, returnValue);
    } else if (value === 'All') {
      onInputChange(name, null);
    }
  };

  getDefaultAreaValue = () => {
    const { eventData = {}, defaultValue } = this.props;
    const area = eventData.area;

    if (area) {
      return {
        label: area.label,
        value: area.url
      };
    } else if (defaultValue) {
      // filters //
      return defaultValue;
    } else {
      // new event or no selection //
      return false;
    }
  };

  render() {
    const {
      className,
      placeholder,
      invalidate,
      onValidate,
      errorMessage,
      name,
      disabled,
      areas,
      refreshOnChange = []
    } = this.props;

    const options = this.getAreaOptions(areas);

    return (
      <div className={className}>
        <SelectBox
          name={name}
          defaultValue={this.getDefaultAreaValue()}
          options={options}
          onOptionSelect={value => this.handleSelectArea(areas, value)}
          placeholder={placeholder}
          invalidate={invalidate}
          onValidate={onValidate}
          errorMessage={errorMessage}
          disabled={disabled}
          refreshOnChange={refreshOnChange}
        />
      </div>
    );
  }

}

const StyledAreaSelector = styled(AreaSelector)`

`;

export { StyledAreaSelector as AreaSelector };
