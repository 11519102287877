export const RECURRANCE = {
  NONE: 'Does not repeat',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually'
};

export const RECURRENCE_UPDATE_TYPE = {
  THIS_EVENT: 'This event',
  THIS_EVENT_AND_FOLLOWING: 'This and following events',
  ALL_EVENTS: 'All events'
};

export const RECURRENCE_ACTION_TYPE = {
  PUBLISH_RECURRING: 'publish_recurring_event',
  PUBLISH: 'publish_event',
  UPDATE_RECURRING: 'update_recurring_event',
  UPDATE: 'update_event'
};
