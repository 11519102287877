const isWordpress = () => !!window.APP_WORDPRESS_INTEGRATION;
const wordpressUserNavDomRoot = () =>
  document.getElementById('app-wordpress-user-nav-1');
const wordpressStickyUserNavDomRoot = () =>
  document.getElementById('app-wordpress-user-nav-2');
const appHost = () => window.APP_FRONTEND_HOST;

export {
  appHost,
  isWordpress,
  wordpressUserNavDomRoot,
  wordpressStickyUserNavDomRoot
};
