import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { useSession, useSettings } from '../hooks';
import { stripLeadingSlash } from '../libs';
import { routeConfig, AuthRoute, LandingAreaRoute } from './';

import ROUTES from '../constants/routes';


export const Routes = () => {
  const { theme } = useSession();
  const { theme: { load_on_user_journey, site: { all_events_page_url } } } = useSettings();
  const components = routeConfig(theme);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const allEventsUrl = stripLeadingSlash(all_events_page_url) || ROUTES.ALL;


  return (
    <Switch>
      <LandingAreaRoute path={`/${ROUTES.LANDING}`} exact/>
      <Route
        path={`/${ROUTES.JOURNEY}`}
        component={components.get('journey')}
      />
      <Route
        path={`/${ROUTES.LANDING}/:area`}
        component={components.get('landing')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}/${ROUTES.LANDING}/:area`}
        component={components.get('landing_archive')}
      />
      <Route
        path={`/${ROUTES.SEARCH}/:area`}
        component={components.get('search')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}/${ROUTES.SEARCH}/:area`}
        component={components.get('search_archive')}
      />
      <Route
        path={`/${ROUTES.TAGS}/:tag`}
        component={components.get('tag')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}/${ROUTES.TAGS}/:tag`}
        component={components.get('tag_archive')}
      />
      <Route
        path={`/${ROUTES.VIRTUAL}`}
        component={components.get('virtual')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}/${ROUTES.VIRTUAL}`}
        component={components.get('virtual_archive')}
      />
      <Route
        path={`/${ROUTES.CATEGORIES}/:slug`}
        component={components.get('subtype')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}/${ROUTES.CATEGORIES}/:slug`}
        component={components.get('subtype_archive')}
      />
      <Route
        path={`/${allEventsUrl}`}
        component={components.get('all_events')}
      />
      <Route
        path={`/${ROUTES.ARCHIVE}`}
        component={components.get('all_events_archive')}
        exact
      />
      <AuthRoute
        path={`/${ROUTES.EVENTS}`}
        component={components.get('event_create')}
        noHostRedirect="/host/promote"
        host
        exact
      />
      <AuthRoute
        path={`/${ROUTES.COMMUNITY}`}
        component={components.get('community_create')}
        host
        exact
      />
      <AuthRoute
        path={`/${ROUTES.EVENTS}/:uuid/edit`}
        component={components.get('event_create')}
        cohosts
        update
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:id(\\d+)`}
        component={components.get('event')}
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:uuid`}
        component={components.get('event')}
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:uuid/rsvp`}
        component={components.get('rsvp')}
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:id(\\d+)/rsvp`}
        component={components.get('event')}
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:uuid/embed`}
        component={components.get('event_embed')}
        exact
      />
      <Route
        path={`/${ROUTES.NOURISHMENT}`}
        component={components.get('sponsors')}
        exact
      />
      <AuthRoute
        path={`/${ROUTES.EVENTS}/:uuid/guests`}
        component={components.get('sponsors_guests')}
        cohosts
        exact
      />
      <AuthRoute
        path={`/${ROUTES.EVENTS}/:uuid/nourished`}
        component={components.get('sponsors_selector')}
        cohosts
        exact
      />
      <Route
        path={`/${ROUTES.EVENTS}/:uuid/resources`}
        component={components.get('resources')}
        cohosts
        exact
      />
      <AuthRoute
        path={`/${ROUTES.MANAGE}/:filter`}
        component={components.get('manage')}
        exact
      />
      <AuthRoute
        path={`/${ROUTES.MANAGE}/:filter/:uuid`}
        component={components.get('manage_details')}
        cohosts
      />
      <AuthRoute
        path={`/${ROUTES.PROFILE}`}
        component={components.get('profile_edit')}
        exact
      />
      <AuthRoute
        path={`/${ROUTES.USER_PROFILE}/:id`}
        component={components.get('profile')}
      />
      <Route
        path={`/${ROUTES.HOST_PROMOTE}`}
        component={components.get('host_promote')}
        exact
        notHost
      />
      <Redirect
        to={{
          pathname: load_on_user_journey ? `/${ROUTES.JOURNEY}` : `/${ROUTES.LANDING}`,
          state: { fromMadlib: urlParams.has('showJourneyResources') },
          search
        }}
      />
    </Switch>
  );
};
