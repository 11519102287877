import { registrationQuestions } from '../constants/registrationQuestions';

const settingsURI = window.useHostName === true ? '/settings' : `${process.env.REACT_APP_API_DOMAIN}/settings`;

export const fetchSettings = callback => {
  console.log(settingsURI);
  fetch(settingsURI)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .then(settings => {
      return callback(settings);
    });
};

export const parseTypesSettings = types => {
  const parsedTypes = {};
  const keys = Object.keys(types);
  keys.forEach(key => {
    if (typeof types[key] === 'string') parsedTypes[key] = JSON.parse(types[key]);
    else parsedTypes[key] = types[key];
  });
  return parsedTypes;
};


export const getSiteNameFromSettings = settings => {
  const settingsName = settings && settings.env_vars && settings.env_vars.site;
  return settingsName.includes('.') ? settingsName.split('.')[0] : settingsName;
};

export const getSiteSubNameFromSettings = settings => {
  const settingsName = settings && settings.env_vars && settings.env_vars.site;
  return settingsName.includes('.') ? settingsName.split('.')[1] : settingsName;
};

export const getFullSiteNameFromSettings = settings => {
  return settings && settings.env_vars && settings.env_vars.site;
};

export const isSite = (settings, siteName, useFull = false) => {
  const settingsName = useFull
    ? getFullSiteNameFromSettings(settings)
    : getSiteNameFromSettings(settings);

  if (Array.isArray(siteName)) {
    let isThisSite = false;
    siteName.forEach(name => { if (settingsName === name) isThisSite = true; });
    return isThisSite;
  } else {
    return settingsName === siteName;
  }
};

export const isRegularOneTable = settings => {
  return isSite(settings, 'onetable');
};

export const getSiteNames = settings => {
  const name = getSiteNameFromSettings(settings);
  const subName = getSiteSubNameFromSettings(settings);
  return { name, subName };
};

export const getSiteRestriction = (fieldName, settings, type) => {
  const site = getSiteNames(settings);
  const field = registrationQuestions.find(q => q.name === `${fieldName}::${site.name}`);

  if (!field) return false;

  const restriction = field[type];
  return Array.isArray(restriction) && restriction.includes(site.subName);
};

export const isRestrictedToSite = (fieldName, settings) => {
  return getSiteRestriction(fieldName, settings, 'restrictToSite');
};

export const isRestrictedFromSite = (fieldName, settings) => {
  return getSiteRestriction(fieldName, settings, 'restrictFromSite');
};

export const addFavicon = settings => {
  const href = (settings && settings.theme.assets.images.favicon) || '/favicon.ico';
  const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = href;
  document.getElementsByTagName('head')[0].appendChild(link);
};
