import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  EVENT_RESERVATIONS_BY_ID
} from '../queries';

import { errorMgt } from '../libs';

const RESERVATIONS_PER_PAGE = 8;


export const usePaginatedAttendees = id => {
  const [reservationPages, setReservationPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(false);

  // all queries with Event are failing to bee stored in cache for some reason //
  // need to figure out / optimize caching //
  const { data = {}, loading, error, fetchMore } = useQuery(EVENT_RESERVATIONS_BY_ID, {
    errorPolicy: 'all',
    variables: { id, isHost: true }
  });

  if (error) {
    console.error(error);
    errorMgt.logError('query_errors', 'Paginated Attendees Query Error', { error });
  }

  const fetch = () => {
    const lastPage = reservationPages[reservationPages.length - 1];
    const lastReservation = lastPage && lastPage[lastPage.length - 1];

    if (lastReservation) {
      setFetchLoading(true);

      fetchMore({
        variables: { id, isHost: true, after: lastReservation.cursor },
        updateQuery: (prev, { fetchMoreResult }) => {
          setFetchLoading(false);

          if (fetchMoreResult) {
            setReservationPages(prevRes => [...prevRes, fetchMoreResult.event.reservations.edges]);
            setPageIndex(i => i + 1);
            // return fetchMoreResult;
          }
          // return prev;
        }
      });
    }
  };

  useEffect(() => {
    // console.log('data', data);
    if (data && data.event && data.event.reservations) {
      setReservationPages(prev => [...prev, data.event.reservations.edges]);
      setTotalCount(data.event.reservations.reservationsAccepted);
    }
  }, [data, setReservationPages]);


  const moreToLoad = () => reservationPages.length * RESERVATIONS_PER_PAGE < totalCount;
  const notOnLastPage = () => pageIndex < reservationPages.length - 1;

  const hasNextPage = () => {
    return moreToLoad() || notOnLastPage();
  };

  const hasPrevPage = () => {
    return pageIndex > 0;
  };

  const handleGoToNextPage = () => {
    if (loading) return;
    if (notOnLastPage()) setPageIndex(i => i + 1);
    else fetch();
  };

  const handleGoToPrevPage = () => {
    if (loading) return;
    setPageIndex(i => i > 0 ? i - 1 : 0);
  };

  return {
    reservations: reservationPages[pageIndex] || [],
    loading,
    pagination: {
      hasNextPage,
      hasPrevPage,
      goToNextPage: handleGoToNextPage,
      goToPrevPage: handleGoToPrevPage,
      loading: fetchLoading
    },
  }
};
