import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSettings } from '../components';
import { SessionContext } from '../../Context';

import queryString from 'query-string';

import { RestrictionModalContext } from '../../RestrictionModalContext';

import {
  PasswordResetModal
} from '../components';

import { RestrictionModal, RestrictionModalStatus } from '../Notification';

class RestrictionModalManager extends React.Component {
  state = {
    showPasswordResetModal: false,
    resetPasswordToken: '',
    showRestrictionModal: false
  };

  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);

    if (qs.changePassword) {
      this.setState({
        showPasswordResetModal: true,
        resetPasswordToken: qs.changePassword
      });
    }
  }

  handleModalToggle = () => {
    this.setState(prevState => ({
      showPasswordResetModal: !prevState.showPasswordResetModal
    }));
  };

  showRestriction = () => {
    this.setState({ showRestrictionModal: true });
  };

  handleRestrictionModalToggle = () => {
    this.setState(prevState => ({
      showRestrictionModal: !prevState.showRestrictionModal
    }));
  };

  render() {
    const {
      showPasswordResetModal,
      resetPasswordToken,
      showRestrictionModal
    } = this.state;
    const { settings, history } = this.props;
    const { copy } = settings;
    const restriction = {
      content: null,
      showRestriction: this.showRestriction
    };
    return (
      <RestrictionModalContext.Provider value={restriction}>
        <SessionContext.Consumer>
          {({ session, refetch }) => {
            restriction.content =
              session.viewer && RestrictionModalStatus(copy, session, null, settings, 'creation');
            return (
              <Fragment>
                {session.viewer && restriction.content && (
                  <RestrictionModal
                    show={showRestrictionModal}
                    toggle={this.handleRestrictionModalToggle}
                    content={restriction.content}
                    onClose={() => history.push('/events')}
                  />
                )}
                {this.props.children}
                <PasswordResetModal
                  show={showPasswordResetModal}
                  toggle={this.handleModalToggle}
                  resetPasswordToken={resetPasswordToken}
                  refetch={refetch}
                />
              </Fragment>
            );
          }}
        </SessionContext.Consumer>
      </RestrictionModalContext.Provider>
    );
  }
}

const RestrictionModalManagerWithRouter = withRouter(RestrictionModalManager);
const RestrictionModalManagerWithRouterWithSettings = withSettings(
  RestrictionModalManagerWithRouter
);

export default RestrictionModalManagerWithRouterWithSettings;
