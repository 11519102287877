import { Enumerated } from './Enumerated';

class EVENT_TYPE extends Enumerated {
  static PUBLIC = 'PUBLIC';
  static MANUAL_APPROVAL = 'MANUAL_APPROVAL';
  static PRIVATE = 'PRIVATE';
  static SOLO = 'SOLO';
  static FIELDS = {
    LABEL: 'LABEL'
  };
  static INFO = {
    PUBLIC: {
      LABEL: 'Public'
    },
    MANUAL_APPROVAL: {
      LABEL: 'Host Approval'
    },
    PRIVATE: {
      LABEL: 'Private'
    },
    SOLO: {
      LABEL: 'Solo'
    }
  };
}

export { EVENT_TYPE };
