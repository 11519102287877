import gql from 'graphql-tag';

export const SEND_INVITATIONS = gql`
  mutation sendInvitations (
    $eventId: Int!,
    $emails: [String!]!,
    $text: Html!
  ) {
    sendInvitations(input: {
      eventId: $eventId,
      emails: $emails,
      text: $text
    }) {
      invitations {
        id
        status
        email
        invitee {
          firstName
          lastName
        }
      }
      errors { message }
    }
  }
`;

export const REJECT_INVITATION = gql`
  mutation RejectInvitation(
    $invitationId: Int!,
    $message: Html
  ) {
    rejectInvitation(
      input: {
        id: $invitationId,
        declinationMessage: $message
      }
    ) {
      invitation {
        id
        event {
          id
        }
        status
      }
      errors { message }
    }
  }
`;

export const UPDATE_INVITATION = gql`
    mutation UpdateInvitation(
      $id: Int!
      $status: InvitationStatus!
    ) {
      updateInvitation(
        input: {
          id: $id
          status: $status
        }
      ) {
        clientMutationId
      }
    }
`;
