import React, { useEffect } from 'react';
import styled from 'styled-components';
import { dateFromDOBString, whitelabel, isSite } from '../../libs';
import wl from '../../libs/whitelabels';
import * as otp from '../../libs/otp';

import { useSession, useSettings } from '../../hooks';
import {
  Input,
  Textarea,
  DatePicker,
  InfoTooltip,
  Button,
  ProfileSettingsVersion,
  UnderlineButton,
  ProfileEditQuestions
} from '../components';

const ProfileFields = (props) => {
  const {
    className,
    data,
    user,
    handleInputChange,
    handleInputValidation,
    handleExtraQuestionsInputChange,
    handleMyProfileAnswersInputChange,
    handleMyProfileDisplayToggleChange,
    changePasswordClicked,
    changePhoneClicked,
    myProfileQuestions = []
  } = props;

  const { settings, copy } = useSettings();
  const { theme } = useSession();

  const ExtraQuestions = wl.profile.extraQuestions(settings);

  const {
    firstName,
    lastName,
    email,
    bio,
    dateOfBirth,
    address,
    secondaryAddress,
    city,
    zipCode,
    vaccinationDate,
    phone,
    country,
    cityState
  } = data;

  useEffect(() => {
    window.heap?.addUserProperties({
      email,
      dateOfBirth,
      firstName,
      lastName,
      zipCode,
      state: user.state,
      bio
    });
  }, [data]);

  const Label = ({ children, className: labelClassName }) => theme.isVersion(2)
    ? <StyledLabel className={labelClassName}>{children}</StyledLabel>
    : <label className={labelClassName}>{children}</label>;
  const PasswordButton = theme.isVersion(2) ? UnderlineButton : Button;

  /// ??? ///
  const labelContainerClass = theme.isVersion(2) ? 'ot-third-container label' : 'ot-third-container';
  const fieldContainerClass = theme.isVersion(2) ? 'ot-twothirds-container' : 'ot-twothirds-container';
  const fieldHalfContainerClass = theme.isVersion(2) ? 'ot-third-container half_width_padding' : 'ot-third-container';

  const renderPhoneField = () => {
    // hide phone field for dinner party //
    if (isSite(settings, ['dinner-party'])) return null;

    return (
      <div className='row'>
        <div className={labelContainerClass}>
          <Label>{copy.profile.phone_label}</Label>
        </div>
        <div className={fieldContainerClass}>
          <Input
            type='text'
            content='phone'
            name='phone'
            placeholder={copy.profile.phone_placeholder}
            value={phone}
            onInputChange={handleInputChange}
            onValidate={handleInputValidation}
            errorMessage={`Please enter a valid ${copy.profile.phone_placeholder.toLowerCase()}`}
          />
        </div>
      </div>
    );
  };

  const renderPhoneModalLink = () => {
    const requiresOtp = otp.getItem('requiresOtp');

    return requiresOtp ? (
      <div className='row'>
        <div className={labelContainerClass}>
          <Label>{copy.profile.phone_label}</Label>
        </div>
        <div className={fieldContainerClass}>
          <Button
            className='change-password-button'
            handleClick={changePhoneClicked}
            buttonStyle='clearBackground'
          >
            {copy.profile.password_change_label}
          </Button>
        </div>
      </div>
    ) : (
      renderPhoneField()
    );
  };

  const dob = dateFromDOBString(dateOfBirth);

  return (
    <ProfileSettingsVersion>
      <div className={[className, 'profile_fields'].join(' ')}>
        <div className="row span_two">
          <div className={labelContainerClass}>
            <Label>{copy.profile.name_label}</Label>
          </div>
          <div className={fieldHalfContainerClass}>
            <Input
              type="text"
              name="firstName"
              placeholder={copy.profile.first_name_placeholder}
              value={firstName}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.first_name_placeholder.toLowerCase()}`}
              required
            />
          </div>
          <div className={fieldHalfContainerClass}>
            <Input
              type="text"
              name="lastName"
              placeholder={copy.profile.last_name_placeholder}
              value={lastName}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.last_name_placeholder.toLowerCase()}`}
              required
            />
          </div>
        </div>

        {isSite(settings, 'dinner-party') && (
          <ProfileEditQuestions
            questions={myProfileQuestions.filter(q => q.name === 'pronouns')}
            handleInputChange={handleMyProfileAnswersInputChange}
            handleQuestionDisplayToggleChange={handleMyProfileDisplayToggleChange}
          />
        )}

        <div className="row">
          <div className={labelContainerClass}>
            <Label>{copy.profile.email_label}</Label>
          </div>
          <div className={fieldContainerClass}>
            <Input
              type="email"
              content="email"
              name="email"
              placeholder={copy.profile.email_placeholder}
              value={email}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.email_placeholder.toLowerCase()}`}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className={labelContainerClass}>
            <Label>{copy.profile.password_label}</Label>
          </div>
          <div className={[fieldContainerClass, 'password_button_container'].join(' ')}>
            <PasswordButton
              className={!theme.isVersion(2) && 'change-password-button'}
              handleClick={changePasswordClicked}
              onClick={changePasswordClicked}
              buttonStyle="clearBackground"
            >
              {copy.profile.password_change_label}
            </PasswordButton>
          </div>
        </div>

        {isSite(settings, 'dinner-party') && (
          <ProfileEditQuestions
            questions={[
              ...myProfileQuestions.filter(q => q.name === 'whoBringsYou'),
              ...myProfileQuestions.filter(q => q.name === 'lossExperiences'),
              ...myProfileQuestions.filter(q => q.name === 'tellUsMore')
            ]}
            handleInputChange={handleMyProfileAnswersInputChange}
            handleQuestionDisplayToggleChange={handleMyProfileDisplayToggleChange}
          />
        )}

        <div className="row">
          <div className={labelContainerClass}>
            <Label>{copy.profile.bio_label}</Label>
          </div>
          <div className={fieldContainerClass}>
            <Textarea
              type="text"
              name="bio"
              placeholder={copy.profile.bio_placeholder}
              value={bio}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.bio_placeholder.toLowerCase()}`}
              required
            />
          </div>
        </div>

        {isSite(settings, ['onetable', 'dinner-party']) && (
          <div className="row">
            <div className={labelContainerClass}>
              <label>{copy.profile.birth_date_label}</label>
            </div>
            <div className={fieldContainerClass}>
              <DatePicker
                className="date_from"
                value={dob}
                format="MM/DD/YYYY"
                onDateChange={(date) => {
                  handleInputChange('dateOfBirth', date);
                }}
              />
            </div>
          </div>
        )}

        {!isSite(settings, ['dinner-party']) && (
          <>
            <div className="row">
              <div className={labelContainerClass}>
                <label>{copy.profile.address_label}</label>
              </div>
              <div className={fieldContainerClass}>
                <Input
                  type="text"
                  name="address"
                  placeholder={copy.profile.address_placeholder}
                  value={address}
                  onInputChange={handleInputChange}
                  onValidate={handleInputValidation}
                  errorMessage={`Please enter a valid ${copy.profile.address_placeholder.toLowerCase()}`}
                />
              </div>
            </div>

            <div className="row">
              <div className={labelContainerClass}>
                <label>{copy.profile.address_secondary_label}</label>
              </div>
              <div className={fieldContainerClass}>
                <Input
                  type="text"
                  name="secondaryAddress"
                  placeholder={copy.profile.address_secondary_placeholder}
                  value={secondaryAddress}
                  onInputChange={handleInputChange}
                  onValidate={handleInputValidation}
                  errorMessage={`Please enter a valid ${copy.profile.address_secondary_placeholder.toLowerCase()}`}
                />
              </div>
            </div>
          </>
        )}

        <div className="row profile_city">
          <div className={labelContainerClass}>
            <label>{copy.profile.city_label}</label>
          </div>
          <div className={fieldContainerClass}>
            <Input
              type="text"
              name="city"
              placeholder={copy.profile.city_placeholder}
              value={city}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.city_placeholder.toLowerCase()}`}
              required={isSite(settings, ['dinner-party'])}
            />
          </div>
        </div>

        <div className="row">
          <div className={labelContainerClass}>
            <label>{copy.profile.state_label}</label>
          </div>
          <div className={fieldContainerClass}>
            <Input
              type="text"
              name="cityState"
              placeholder={copy.profile.state_placeholder}
              value={cityState}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={`Please enter a valid ${copy.profile.state_placeholder.toLowerCase()}`}
              required={isSite(settings, ['dinner-party'])}
            />
          </div>
        </div>

        {isSite(settings, ['dinner-party']) && (
          <div className="row">
            <div className={labelContainerClass}>
              <label>{copy.profile.country_label || 'Country *'}</label>
            </div>
            <div className={fieldContainerClass}>
              <Input
                type="text"
                name="country"
                placeholder={copy.profile.country_placeholder || 'Country'}
                value={country}
                onInputChange={handleInputChange}
                onValidate={handleInputValidation}
                errorMessage={`Please enter a valid ${(copy.profile.country_placeholder || 'country').toLowerCase()}`}
                required
              />
            </div>
          </div>
        )}

        {!isSite(settings, ['signaturefd']) && (
          <div className="row">
            <div className={labelContainerClass}>
              <Label>{copy.profile.zip_code_label}</Label>
            </div>
            <div className={fieldContainerClass}>
              <Input
                type="text"
                name="zipCode"
                placeholder={copy.profile.zip_code_placeholder}
                value={zipCode}
                onInputChange={handleInputChange}
                onValidate={handleInputValidation}
                errorMessage={`Please enter a valid ${copy.profile.zip_code_placeholder.toLowerCase()}`}
                required={!whitelabel.hasFeature(settings, 'hideZipCode')}
              />
            </div>
          </div>
        )}

        {isSite(settings, 'dinner-party') && (
          <ProfileEditQuestions
            questions={[
              ...myProfileQuestions.filter(q => q.name === 'sexualOrientation'),
              ...myProfileQuestions.filter(q => q.name === 'ethnicity'),
              ...myProfileQuestions.filter(q => q.name === 'religiousIdentification'),
              ...myProfileQuestions.filter(q => q.name === 'howDidYouHear')
            ]}
            handleInputChange={handleMyProfileAnswersInputChange}
            handleQuestionDisplayToggleChange={handleMyProfileDisplayToggleChange}
          />
        )}

        {isSite(settings, ['whitelabel', 'limitedwhitelabel'])
          ? renderPhoneField()
          : renderPhoneModalLink()}

        {!isSite(settings, 'signaturefd') && (
          <div className="row">
            <div className={labelContainerClass}>
              <Label className="with_tooltip">
                {copy.profile.vaccination_date || 'Vaccination Date'}
                <InfoTooltip content={copy.profile.vaccination_date_tooltip}/>
              </Label>
            </div>
            <div className={fieldContainerClass}>
              <DatePicker
                className="date_from"
                value={vaccinationDate}
                format="MM/DD/YYYY"
                max={new Date()}
                onDateChange={(date) => {
                  handleInputChange('vaccinationDate', date);
                }}
              />
            </div>
          </div>
        )}

        {isSite(settings, ['onetable', 'hillel']) && (
          <ExtraQuestions
            questions={myProfileQuestions}
            settings={settings}
            data={data}
            handleInputChange={handleExtraQuestionsInputChange}
          />
        )}
      </div>
    </ProfileSettingsVersion>
  );
};

const StyledLabel = styled.label`

`;

const StyledProfileFields = styled(ProfileFields)`
  & .change-password-button {
    font-size: 13px;
    margin-left: -18px;
  }
`;

export { StyledProfileFields as ProfileFields };
