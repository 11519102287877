import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ProfileEditSocialAccount } from './ProfileEditSocialAccount';
import { withSettings } from '../components';

const ProfileEditSocialAccounts = (props) => {
  const {
    facebook,
    instagram,
    twitter,
    snapchat,
    handleInputChange,
    handleInputValidation
  } = props;

  const { settings: { copy } } = props;

  return (
    <Fragment>
      <ProfileEditSocialAccount label={copy.profile.facebook} name="facebook" value={facebook} handleInputChange={handleInputChange} handleInputValidation={handleInputValidation}/>
      <ProfileEditSocialAccount label={copy.profile.twitter} name="twitter" value={twitter} handleInputChange={handleInputChange} handleInputValidation={handleInputValidation}/>
      <ProfileEditSocialAccount label={copy.profile.instagram} name="instagram" value={instagram} handleInputChange={handleInputChange} handleInputValidation={handleInputValidation}/>
      <ProfileEditSocialAccount label={copy.profile.snapchat} name="snapchat" value={snapchat} handleInputChange={handleInputChange} handleInputValidation={handleInputValidation}/>
    </Fragment>
  );
}

const StyledProfileEditSocialAccounts = styled(ProfileEditSocialAccounts)`

`;

const StyledProfileEditSocialAccountsWithSettings = withSettings(StyledProfileEditSocialAccounts);

export { StyledProfileEditSocialAccountsWithSettings as ProfileEditSocialAccounts };