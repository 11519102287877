import React, { useContext } from 'react';

import { withRouter } from 'react-router-dom';

import {
  LandingHero,
  LandingNeighborhoods,
  LandingCategories,
  LandingBanner,
  LandingDiscover,
  LandingAnnouncement,
  LandingPageContextProvider,
  LandingPageContext
} from '../components';

import { useSession } from '../../hooks';

import VISIBILITY from '../../constants/visibility';


const LandingPage = () => {
  const { page } = useContext(LandingPageContext);
  const { viewer: { user } } = useSession();

  const renderPageSections = () => page.pageSections.map(({ id, __typename, visibility, ...rest }) => {
    const Section = ({
      BannerPageSection: LandingBanner,
      DiscoveriesPageSection: LandingDiscover,
      CategoriesPageSection: LandingCategories,
      NeighborhoodsPageSection: LandingNeighborhoods
    })[__typename];

    const visible = [
      visibility !== VISIBILITY.HOST || user?.hostApprovalAt,
      visibility !== VISIBILITY.GUEST || (user && !user.hostApprovalAt),
      visibility !== VISIBILITY.NOT_LOGGED || !user,
    ].every(v => v);

    return Section && visible ? <Section key={id} {...rest} /> : null;
  });

  return (
    <div>
      <LandingHero />
      <LandingAnnouncement />
      {page?.pageSections.length > 0 && renderPageSections()}
      <style>{'body { padding-top: 0; }'}</style>
    </div>
  );
};

const LandingPageWithContext = ({ match, ...rest }) => (
  <LandingPageContextProvider url={match.params.area}>
    <LandingPage {...rest} />
  </LandingPageContextProvider>
);

const LandingPageWithRouter = withRouter(LandingPageWithContext);
export { LandingPageWithRouter as LandingPage };
