import React from 'react';
import styled from 'styled-components';

import { SearchFilterContainer, AreaSearchFilter, DatesSearchFilter } from './';


const AreaAndDatesSearchFilter = ({
  className,
  data: { area, dates, setArea, setDates },
  handleSearchButtonClick
}) => (
  <SearchFilterContainer hover={false}>
    <div className={className}>
      <div>
        <AreaSearchFilter
          area={area}
          dates={dates}
          onSelectArea={(a) => setArea(a)}
        />
        <DatesSearchFilter
          dates={dates}
          onSearch={handleSearchButtonClick}
          onSelectDates={(a) => setDates([a])/* temp */}
        />
      </div>
    </div>
  </SearchFilterContainer>
);


const StyledAreaAndDatesSearchFilter = styled(AreaAndDatesSearchFilter)`
  & > div {
    display: flex;
    flex-direction: row;

    & > div {
      position: relative;
  }
`;
export { StyledAreaAndDatesSearchFilter as AreaAndDatesSearchFilter };
