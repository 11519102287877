import React from 'react';

import {
  RadioButtons,
  InfoTooltip,
  withSettings
} from '../../components';

const EventCreationFormDetailsOptionsVirtual = ({ settings, eventData, onInputChange }) => {
  const { copy } = settings;
  
  return (
    <div className="form_group radios break_on_mobile">
      <div className="one_half">
        {/* Closed Captioning */}
        <label className="with_tooltip">
          {copy.create.closed_captioning}
          <InfoTooltip content={copy.create.closed_captioning_tooltip}/>
        </label>
        <RadioButtons
          name="closedCaptioningAvailable"
          value={eventData.closedCaptioningAvailable}
          selected={eventData.closedCaptioningAvailable}
          onInputChange={onInputChange}
          align="left"
        />
      </div>
      <div className="one_half">
        {/* Videos on/off */}
        <label>{copy.create.videos_on_off}</label>
        <RadioButtons
          name="videosOnAsDefault"
          value={eventData.videosOnAsDefault}
          selected={eventData.videosOnAsDefault}
          onInputChange={onInputChange}
          align="left"
        />
      </div>
    </div>
  );
};

const EventCreationFormDetailsOptionsVirtualWithSettings = withSettings(EventCreationFormDetailsOptionsVirtual);
export { EventCreationFormDetailsOptionsVirtualWithSettings as EventCreationFormDetailsOptionsVirtual };