import React from 'react';
import styled from 'styled-components';

import { useCheckoutData } from '../../hooks';

import { PaymentMethod } from '../components';


export const RsvpPageViewPaymentMethod = ({ setChoose, onNew }) => {
  const { cardData: { card, cards } } = useCheckoutData();
  return (
    <Container>
      <PaymentMethod card={card} cards={cards} onChange={setChoose} onNew={onNew} />
    </Container>
  );
};

const Container = styled.div`margin-top: 20px;`;
