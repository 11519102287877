import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

import {
  getScheduledAtDate,
  getEventTimeSpan,
  getEventFormattedAreaAndNeighborhood,
  getLocationWithoutArea,
  getEventLocationWithSubtype,
  isPast,
  whitelabel,
  isSite
} from '../../../libs';

import {
  FAIcon
} from '../../components';

import { breakpoints } from '../../../styles';

const EventLocation = ({
  className,
  event,
  viewer,
  showPotluck,
  showCatered,
  showArea,
  settings,
  showTimezone = false,
  showEventYear
}) => {
  const shouldHidePotluck = whitelabel.hasFeature(settings, 'hidePotluckIcons');
  const shouldShowAreaSubtype = whitelabel.hasFeature(settings, 'showAreaSubtypeLabel');
  const useAddressAreaLabel = whitelabel.hasFeature(settings, 'useAddressAreaLabel');

  const potluckItemClaimed = (claim, viewerId) => (
    claim.reservation && claim.reservation.profile && claim.reservation.profile.id === viewerId
  );

  const getPotluckItemsImBringing = (timeSlot = false) => {
    const viewerId = viewer.user && viewer.user.profile ? viewer.user.profile.id : null;
    const potluckItems = event.potluckItems.edges.length ? event.potluckItems.edges.map(edge => edge.node) : [];

    if (!potluckItems.length) return null;
    const claimedItems = [];

    potluckItems.forEach(item => {
      if (!item.claims.length) return null;
      item.claims.forEach(claim => { if (potluckItemClaimed(claim, viewerId)) claimedItems.push(item.name); });
    });

    if (claimedItems.length && !timeSlot) {
      const bringingText = isPast(event) ? 'I brought' : (
        settings.copy.manage.potluck_im_bringing || 'I\'m Bringing:'
      );

      return (
        <span className="im_bringing">
          {`${bringingText}: ${claimedItems.join(', ')}`}
        </span>
      );
    }

    if (claimedItems.length && timeSlot) {
      return (
        <span className="timeslot">
          {claimedItems.join(', ')}
        </span>
      );
    }

    return null;
  };

  const getAreaLabel = () => (
    shouldShowAreaSubtype ? (
      <p>{getEventLocationWithSubtype(event, isSite(settings, 'hillel.international', true), settings)}</p>
    ) : (
      showArea
        ? <p>{getEventFormattedAreaAndNeighborhood(event)}</p>
        : <p>{getLocationWithoutArea(event)}</p>
    )
  );

  const addressIcon = shouldShowAreaSubtype
    ? [ 'fas', 'users' ]
    : [ 'fas', 'map-marker-alt' ];

  return (
    <div className={className}>
      <span>
        <FAIcon name={['far', 'calendar-alt']} />
        <p>{getScheduledAtDate(event, showEventYear)}</p>
      </span>
      <span>
        <FAIcon name={['far', 'clock']} />
        <p>{getEventTimeSpan(event)}</p>
      </span>
      {showTimezone && (event.timezone || event.timezoneOther) && (
        <span>
          <FAIcon name={[ 'far', 'globe-americas' ]} />
          <p>
            {event.timezone ? event.timezone : event.timezoneOther}
          </p>
        </span>
      )}
      {useAddressAreaLabel ? (
        event.fullAddress && (
          <span>
            <FAIcon name={addressIcon} />
            {event.fullAddress}
          </span>
        )
      ) : (
        <span>
          <FAIcon name={addressIcon} />
          {getAreaLabel()}
        </span>
      )}
      {showPotluck && (!shouldHidePotluck ? (
        <span>
          <FAIcon name={[ 'far', 'utensils' ]} />
          <p>
            Potluck
            {getPotluckItemsImBringing()}
          </p>
        </span>
      ) : (
        <span>
          <FAIcon name={[ 'far', 'calendar' ]} />
          {settings.copy.manage.potluck_im_bringing || 'Location / Time Slot:'}
          {getPotluckItemsImBringing(true)}
        </span>
      ))}
      {showCatered && (
        <span>
          <FAIcon name={[ 'far', 'utensils' ]} />
          <p>Catered</p>
        </span>
      )}
    </div>
  );
};

const StyledEventLocation = styled(EventLocation)`
  & > span {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (${breakpoints.manageEventsListMobile}) {
      display: block;
    }

    * {
      align-self: center;
    }

    svg {
      margin-right: 6px;
      color: #8c8c8c;
    }
    p {
      padding-top: 2px;
      margin-bottom: 0px;
        display: inline-block;

      // @media (${breakpoints.manageEventsListMobile}) {
      //   display: inline-block;
      // }
    }
  }

  .im_bringing {
    margin-left: 8px;
    display: inline-block;
    height: auto;
    background: ${props => props.settings.theme.css.global.colors.bannerSmall};
    padding: 4px;
    letter-spacing: 1px;
    font-size: 8px !important;
    text-transform: uppercase;
    line-height: normal;
    color: #fff;
    font-weight: 700;
  }

  .timeslot {
    margin-left: 5px;
  }
`;

const StyledEventLocationWithSettings = withSettings(StyledEventLocation);

export { StyledEventLocationWithSettings as EventLocation }
