import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { EventPageContextProvider } from './EventPageContext';
import ROUTES from '../../constants/routes';

import {
  PageContent,
  SidebarLayout as Layout,
  PageHeader,
  EventHeader,
  EventDetails,
  EventMenu,
  EventAttendees,
  EventHosts,
  EventSuggestions,
  RsvpSuccessModal,
  ReservationCanceledModal,
  Divider,
  Loader
} from '../components';

import { useEventPageData, useSettings, useSession, useHistoryStack } from '../../hooks';
import { addToHeapData } from '../../libs';

import { EVENT_TYPE } from '../../enums';


const EventPage = () => {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { copy } = useSettings();
  const { theme } = useSession();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReservationCanceledModal, setShowReservationCanceledModal] = useState(false);
  const [afterMembershipSuccess, setAfterMembershipSuccess] = useState(false);
  const { eventData, loading, error, isHost } = useEventPageData();
  const { getLastPage } = useHistoryStack();

  useEffect(() => {
    if (locationState && eventData) {
      const {
        stripeCancelRedirect,
        reservationFailedRedirect,
        reservationSuccessRedirect,
        afterMembershipSuccess: afterMembership
      } = locationState;
      stripeCancelRedirect && setShowCancelModal(true);
      reservationFailedRedirect && setShowReservationCanceledModal(true);
      reservationSuccessRedirect && setShowSuccessModal(true);
      afterMembership && setAfterMembershipSuccess(true);
      // clear localStorage so result modals dont continue to show //
      if (stripeCancelRedirect || reservationFailedRedirect || reservationSuccessRedirect)
        history.replace(history.location.pathname, {});
      addToHeapData(eventData);
    }
  }, [locationState, eventData]);

  if (loading) return <Loader />;
  if (error || (
    eventData && eventData.eventType === EVENT_TYPE.SOLO && !isHost)
  ) console.error('error', error); // history.push('/');

  const handleBackClick = () => {
    const lastPage = getLastPage();
    const pathname = lastPage && !lastPage.includes('/rsvp')
      ? lastPage
      // temporarily removing the search/landing pages //
      : (`/${theme.isVersion(3) ? ROUTES.SEARCH : ROUTES.LANDING}/${eventData.area.url}`)

    history.push(pathname);
  };

  return eventData ? (
    <div>
      <PageContent>
        <PageHeader heading={eventData.title} onBackClick={handleBackClick} />
        <EventHeader />
        <Layout>
          <div>
            <EventDetails />
            <EventMenu />
            <EventAttendees />
            <EventHosts />
          </div>
        </Layout>
        <Divider />
        <EventSuggestions />
      </PageContent>
      <RsvpSuccessModal
        show={showSuccessModal}
        toggle={() => setShowSuccessModal(prev => !prev)}
        afterMembershipSuccess={afterMembershipSuccess}
      />
      <ReservationCanceledModal
        show={showCancelModal}
        toggle={() => setShowCancelModal(prev => !prev)}
        title={copy.membership?.cancel_modal_title || 'Reservation Canceled'}
        content={copy.membership?.cancel_modal_message || 'Some info about your recently canceled reservation/membership.'}
      />
      <ReservationCanceledModal
        show={showReservationCanceledModal}
        toggle={() => setShowReservationCanceledModal(prev => !prev)}
        title={copy.rsvp.no_membership_modal_title || 'Reservation Canceled'}
        content={copy.rsvp.no_membership_modal_message || 'A membership is required to RSVP to this event.'}
      />
    </div>
  ) : null;
};


const EventPageWithContext = props => (
  <EventPageContextProvider><EventPage {...props} /></EventPageContextProvider>
);
export { EventPageWithContext as EventPage };
