import React, { useState, useRef } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';

import {
  ErrorModal
} from '../../components';

import { SIGN_UPLOAD } from '../../../mutations';
import { withSettings } from '../../components';
import { uploadToS3 } from '../../../libs';

const EventCreationCoverUploader = (props) => {
  const { onUploadImage, className } = props; // from parent
  const { settings: { copy } } = props; // from withSettings
  const [state, setState] = useState({ showErrorModal: false });
  const inputFile = useRef();

  const handleFileChange = (event, signUpload) => {
    const file = event.target.files[0];

    if (!file) return;

    // Check file is less than 2.5MB //
    if (file.size > 2621440) {
      setState({ showErrorModal: true });
      return;
    }

    signUpload()
      .then(response => {
        const s3Credentials = response.data.signUpload.uploadSignature;

        uploadToS3(s3Credentials, file, null)
          .then(imageUrl => onUploadImage(imageUrl))
          .catch(error => console.error(error));
      });
  };

  const handleUploadClick = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleModalToggle = () => {
    setState(prevState => ({
      showErrorModal: !prevState.showErrorModal
    }));
  };

  const renderErrorModal = () => {
    const { showErrorModal } = state;
    
    return (
      <ErrorModal
        show={showErrorModal}
        toggle={handleModalToggle}
      >
        <h2>{copy.event.image_too_large_title}</h2>
        <p>{copy.event.image_too_large_subtitle}</p>
      </ErrorModal>
    );
  }


  return (
    <Mutation mutation={SIGN_UPLOAD}>
      {(signUpload) => (
        <div className={className}>
          {renderErrorModal()}
          <input
            type="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={event => handleFileChange(event, signUpload)}
            accept="image/x-png,image/gif,image/jpeg"
          />

          <span>{copy.create.select_image}</span>
          <span
            className="upload"
            onClick={handleUploadClick}
          >{copy.create.upload_image}</span>
          <span>{copy.create.smaller_than}</span>
        </div>
      )}
    </Mutation>
  );
}

const StyledEventCreationCoverUploader = styled(EventCreationCoverUploader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 18px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;

  span {
    display: inline-block;
    vertical-align: middle;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    border-bottom: 1px solid transparent;
    margin: 0 2px;
    font-size: 14px;

    &.upload {
      color: #fff;
      border-bottom: 1px dashed #fff;
      cursor: pointer;
    }
  }
`;

const StyledEventCreationCoverUploaderWithSettings = withSettings(StyledEventCreationCoverUploader);

export { StyledEventCreationCoverUploaderWithSettings as EventCreationCoverUploader };
