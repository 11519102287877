import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { generators } from '../../libs';
import { withSettings } from '../components';

const CounterBadge = ({ className, size, number }) => {
  const avatarClassNames = `
    ${size ? size : 'sm'}
  `;

  return (
    <div className={`${className} ${avatarClassNames}`} >
      <div className="avatar-container">
        <div className="avatar avatar-view">
          <span className="user_initials">{number}</span>
        </div>
      </div>
    </div>
  );
};

const StyledCounterBadge = styled(CounterBadge)`
  display: flex;
  justify-content: center;
  align-items: center;

  & .avatar-view {
    background-color: ${props => generators.randomColor(props.settings)};
  }

  .icon {
    margin: auto;
    width: 24px;
    height: 24px;
  }

  &.sm {
    flex-direction: row;

    & .avatar {
      width: 30px;
      height: 30px;

      .user_initials {
        font-size: 10px;
      }
    }
  }

  &.md {
    & .avatar {
      width: 60px;
      height: 60px;
    }
  }

  &.lg {
    & .avatar {
      width: 80px;
      height: 80px;

      .user_initials {
        font-size: 25px;
      }
    }
  }

  &.xl {
    & .avatar {
      width: 160px;
      height: 160px;

      .user_initials {
        font-size: 34px;
      }
    }
  }

  &.horizontal {
    flex-direction: row;

    & .user_name {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
    }

    & .avatar {
      display: inline-block;
      vertical-align: middle;
    }
  }

  & .avatar {
    display: flex;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2px 5px rgba(0,0,0,0.07), 0 2px 10px rgba(0,0,0,0.08);

    .user_initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
`;

const CounterBadgeWithRouter = withRouter(StyledCounterBadge);
const CounterBadgeWithSettings = withSettings(CounterBadgeWithRouter);
export { CounterBadgeWithSettings as CounterBadge };
