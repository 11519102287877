import React from 'react';

import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import { Input, Button } from '..';

import { addSignUpAnswersToProfile, errorMgt } from '../../libs';
import { setToken } from '../../libs/token';

import { OtpContext } from '../../OtpContext';
import { withOtpMutationHooksHOC } from '../../libs/auth';
import { InputWithButton } from '../Global/Forms/InputWithButton';

const initialState = {
  phone: '',
  code: '',
  phoneValid: false,
  codeValid: false,
  errorMessage: null,
  message: null,
  codeRequested: false,
  isSubmitLoading: false,
  isSendCodeLoading: false
};

const OTP_DEFAULT_MESSAGES = {
  invalid_phone: 'You have entered an invalid phone number. Please enter a valid phone number and try again.',
  too_many_attempts: 'Too many attempts have been made from this IP address. Please wait and try again.',
  sms_service_down: 'An unknown error has occurred. Please contact the system administrator if the problem persists.',
  unknown_error: 'An unknown error has occurred. Please contact the system administrator if the problem persists.',
  success_code_sent: 'Your code has been sent.'
};

class Otp extends React.Component {
  static contextType = OtpContext;
  state = { ...initialState, sendCodeText: 'Send Code' };

  componentDidMount() {
    const { otpSettings: { settings } } = this.context;

    if (settings.userPhone) {

      this.setState({ phone: settings.userPhone , phoneValid: true });
    }
  }

  handleInputChange = (name, value) => {
    if (name === 'phone') {
      const isPhoneValid = !!value.match(/^[0-9+.\-\s]+$/);
      // removed US Number validation
      // this.setState({ [`${name}Valid`]: (str[0] !== '1' && str.length === 10) || (str[0] === '1' && str.length === 11) })
      this.setState({ [`${name}Valid`]: isPhoneValid })
    }
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };


  formIsInvalid = () => {
    const { phoneValid, codeValid } = this.state;
    return !phoneValid || !codeValid;
  };

  phoneIsInvalid = () => {
    const { phoneValid } = this.state;
    return !phoneValid;
  };

  handleCodeClick = async () => {
    const { profile } = this.props.settings.copy
    this.setState({ isSendCodeLoading: true });
    const {
      settings: { userMessage, userId }
    } = this.context.otpSettings;

    const validationMessages = {
      phoneAccountDuplicate: profile.phone_account_duplicate,
      phoneVerificationInvalidCountry: profile.phone_verification_invalid_country
    }

    try {
      let phoneStr = this.state.phone.replace(/\D/g, '');
      phoneStr = phoneStr.length === 10 ? `+1${phoneStr}` : `+${phoneStr}`;

      const { data } = await this.props.sendUserOtpMutation({
        variables: {
          userMessage,
          userId,
          userPhone: phoneStr
        }
      });
      this.setState({ isSendCodeLoading: false, sendCodeText: 'Resend Code' });
      const { errors, status } = data.sendUserOtp;
      if (Array.isArray(errors) && errors.length) {
        this.setState({
          errorMessage: errors[0].message,
          message: null,
        });
        errorMgt.logError(
          'send_otp_error',
          'sendUserOtp mutation data errors',
          { errors }
        );
      } else {
        switch (status) {
          case 'success':
            this.setState({ errorMessage: null, message: OTP_DEFAULT_MESSAGES.success_code_sent })
            break;
          case 'phone_country_invalid':
          case 'voip_phone':
            this.setState({
              errorMessage: validationMessages.phoneVerificationInvalidCountry,
              message: null
            })
            break;
          case 'phone_duplicate':
            this.setState({
              errorMessage: validationMessages.phoneAccountDuplicate,
              message: null
            })
            break;
          case 'invalid_phone':
            this.setState({
              errorMessage: OTP_DEFAULT_MESSAGES.invalid_phone,
              message: null,
            });
            break;
          case 'too_many_attempts':
            this.setState({
              errorMessage: OTP_DEFAULT_MESSAGES.too_many_attempts,
              message: null,
            });
            break;
          case 'sms_service_down':
          default:
            this.setState({
              errorMessage: OTP_DEFAULT_MESSAGES.sms_service_down,
              message: null,
            });
            errorMgt.logError(
              'send_otp_error',
              'sendUserOtp mutation error status',
              { status }
            );
            break;
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: OTP_DEFAULT_MESSAGES.unknown_error,
        message: null,
        isSendCodeLoading: false
      });
      errorMgt.logError(
        'send_otp_error',
        'sendUserOtp mutation error',
        { error }
      );
    }
  };

  handleFormSubmit = async (event, logInWithOtp) => {
    const { shouldRedirect, afterLogin, updateProfileMutation, settings, toggle } = this.props;
    const {
      settings: { userMessage, signupAnswers }
    } = this.context.otpSettings;
    event.preventDefault();

    this.setState({ isSubmitLoading: true });

    try {
      const { data } = await logInWithOtp({
        variables: {
          userMessage,
          otp: this.state.code
        }
      });

      const { user, token, errors } = data.logInWithOtp;
      if (user) {

        setToken(this.props.settings, token);

        const updateProfileResponse = addSignUpAnswersToProfile(
          settings,
          updateProfileMutation,
          signupAnswers || {}
        );

        if (updateProfileResponse && updateProfileResponse.errors) {
          errorMgt.logError(
            'update_profile_errors',
            'updateProfile mutation errors',
            { errors: updateProfileResponse.errors }
          );
        }

        await this.props.refetch();

        if (shouldRedirect) this.props.history.push('/landing');
        if (afterLogin) afterLogin();
        else toggle();
      } else if (Array.isArray(errors) && errors.length) {
        this.setState({
          isSubmitLoading: false,
          errorMessage: 'The code you entered did not match our records.',
          message: null,
        });
      }
    } catch (error) {
      this.setState({ isSubmitLoading: false });
    }
  };

  render() {
    const { className, logInWithOtpMutation, allowEditPhoneNumber, settings: { copy } } = this.props;
    const { phone, code, isSubmitLoading, isSendCodeLoading } = this.state;

    return (
      <div className={className}>
        <div className="login_message">{copy.login.otp_login_message || 'We’ve updated our login experience to make finding your Friday even more safe and secure. Please enter your phone number below. Your number will only be used for a one-time verification code.'}</div>
        <form className='ot_form' onSubmit={(event) => this.handleFormSubmit(event, logInWithOtpMutation)}>
          {this.state.errorMessage && (
            <div className='form_submit_error'>{this.state.errorMessage}</div>
          )}
           {this.state.message && (
            <div className='form_submit_sucess'>{this.state.message}</div>
          )}
          <InputWithButton
            placeholder='999-999-9999'
            name='phone'
            value={phone}
            onInputChange={this.handleInputChange}
            content='phone'
            required
            autoComplete="tel"
            disabled={allowEditPhoneNumber ? false : this.context.otpSettings.settings.userPhone}

          >
            <Button
              type='button'
              disabled={this.phoneIsInvalid() || isSendCodeLoading}
              loading={isSendCodeLoading}
              capitalize
              handleClick={this.handleCodeClick}
              primary
            >
              {this.state.sendCodeText}
            </Button>
          </InputWithButton>
          <Input
            type='text'
            placeholder='Code'
            name='code'
            value={code}
            onInputChange={this.handleInputChange}
            onValidate={this.handleInputValidation}
            required
            autoComplete="off"
          />
          <Button
            type='submit'
            disabled={this.formIsInvalid() || isSubmitLoading}
            loading={isSubmitLoading}
            capitalize
            fullWidth
          >
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

const StyledOtp = styled(Otp)`
  .code_wrapper {
    position: relative;
    input {
      padding-right: 55px;
    }
  }
  .button_wraper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .login_message {
    margin-bottom: 0.8rem;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    font-size: 13px;
  }
  .forgot_code {
    position: absolute;
    right: 14px;
    top: 12px;
    // transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    color: ${(props) => props.settings.theme.css.global.colors.primary};
    cursor: pointer;
    transition: color 250ms;
    &:hover {
      color: ${(props) => props.settings.theme.css.global.colors.primaryHover};
    }
  }
`;

const LogInWithRouter = withRouter(StyledOtp);
const LogInWithRouterWithSettings = withSettings(LogInWithRouter);
const LogInWithRouterWithSettingsWithOptHooks = withOtpMutationHooksHOC(
  LogInWithRouterWithSettings
);

export { LogInWithRouterWithSettingsWithOptHooks as Otp };
