import React from 'react';
import styled from 'styled-components';

import { Textarea } from '../../components';


export const TextareaField = props => (
  <Container>
    <Textarea {...props} onInputChange={props.onChange || props.onInputChange} />
  </Container>
);

const Container = styled.div`
  textarea {
    padding: 12px 20px !important;
    border: 1px solid #ceccc7 !important;
    font-size: 15px !important;
    line-height: 1.8 !important;
    border-radius: 5px !important;
    transition: border-color 250ms !important;

    &:hover,
    &:focus,
    &:active {
      border-color: #000 !important;
      box-shadow: none !important;
    }
  }
`;
