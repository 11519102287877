import { isSite } from '../libs';

const socialDistancePolicy = {
  getVirtualEventFeildsByPolicy: (policy, settings, eventTags) => {
    const hideVirtualFields = isSite(settings, 'jewishfoodfest');
    const data = {};
    const tags = eventTags ? eventTags.filter(t => t !== 'virtual') : [];

    switch (policy) {
      case 'mandatory':
        data.virtual = true;
        data.virtualDisabled = true;
        data.virtualHide = false;
        break;
        case 'disabled':
          data.virtual = false;
          data.virtualHide = true;
          break;
      default:
    }

    if (data.virtual && !hideVirtualFields) tags.push('virtual');

    data.tags = tags;

    if (hideVirtualFields) data.virtualHide = true;

    return data;
  },

  getPrivacySubtypePolicyFields: () => {
    return {};
  }
};

const {
  getVirtualEventFeildsByPolicy,
  getPrivacySubtypePolicyFields
} = socialDistancePolicy;

export {
  socialDistancePolicy,
  getVirtualEventFeildsByPolicy,
  getPrivacySubtypePolicyFields
};
