import React from 'react';
import styled from 'styled-components';
import { Input, Button } from '../';
import { withSettings } from '../components';

class RsvpModalAddOtherGuests extends React.Component {

  render() {
    const { className, settings, guests, guestsErrors, handleGuestInputChange, handleGuestInputFocus, addAnotherGuest, removeGuest, maxAdditionalGuestsNumber } = this.props;
    const { copy } = settings;

    const numberOfGuests = guests.length;

    return (
      <div className={className}>
        {guests.map((guest, index) => {

          const firstNameError = guestsErrors.length > index ? guestsErrors[index].firstName : '';
          const lastNameError = guestsErrors.length > index ? guestsErrors[index].lastName : '';
          const emailError = guestsErrors.length > index ? guestsErrors[index].email : '';

          return (
            <div key={index} className="ot_form">
              <label htmlFor="">Guest {index + 1}</label>
              <div className="form_group break_on_tablet_small name-container">
                <div className="form_group one_half">
                  <Input
                    name="firstName"
                    placeholder={copy.rsvp.guest_first_name_placeholder}
                    value={guest.firstName}
                    errorMessage={firstNameError.text || 'Please enter a valid first name'}
                    forceShowErrorMessage={firstNameError.show}
                    valid={!firstNameError.show}
                    onInputChange={(name, value) => { handleGuestInputChange(index, name, value) }}
                    onInputFocus={(event) => { handleGuestInputFocus(index, event.currentTarget.name) }}
                    required
                  />
                </div>
                <div className="form_group one_half">
                  <Input
                    name="lastName"
                    placeholder={copy.rsvp.guest_last_name_placeholder}
                    value={guest.lastName}
                    errorMessage={lastNameError.text || 'Please enter a valid last name'}
                    forceShowErrorMessage={lastNameError.show}
                    valid={!lastNameError.show}
                    onInputChange={(name, value) => { handleGuestInputChange(index, name, value) }}
                    onInputFocus={(event) => { handleGuestInputFocus(index, event.currentTarget.name) }}
                    required
                  />
                </div>
              </div>
              <Input
                name="email"
                placeholder={copy.rsvp.guest_email_placeholder}
                value={guest.email}
                errorMessage={emailError.text}
                forceShowErrorMessage={emailError.show}
                valid={!emailError.show}
                onInputChange={(name, value) => { handleGuestInputChange(index, name, value) }}
                onInputFocus={(event) => { handleGuestInputFocus(index, event.currentTarget.name) }}
                className="email-input"
                content="email"
                required
              />
              {numberOfGuests > 1 && (
                <div className="remove_button">
                  <Button buttonStyle="clearBackground" handleClick={() => { removeGuest(index) }}>Remove</Button>
                </div>
              )}
            </div>
          )
        })}
        {maxAdditionalGuestsNumber > guests.length && (
          <div className="add_button">
            <Button buttonStyle="clearBackground" handleClick={addAnotherGuest}>Add another guest</Button>
          </div>
        )}
      </div>
    );
  }
}

const StyledRsvpModalContainer = styled(RsvpModalAddOtherGuests)`
  text-align: left;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  // & > div {
  //   text-align: left;
  // }
  //
  // & > div > div {
  //   text-align: right;
  // }

  .ot_form {
    position: relative;

    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }

  .form_group {
    .one_half {
      &:first-child {
        padding-right: 0 !important;

        input {
          border-radius: 3px 0 0 3px;
        }
      }

      &:last-child {
        padding-left: 0 !important;

        input {
          border-radius: 0 3px 3px 0;
          border-left: 0;
        }
      }
    }

    input {
      margin-bottom: 8px !important;
    }

    .error_bottom {
      input {
        margin-bottom: 0 !important;
      }
    }
  }

  .add_button {
    float: left;
  }

  .remove_button {
    float: right;
  }

  .button.clearBackground {
    padding: 0 !important;
    min-height: 0 !important;
    font-size: 12px;
    font-weight: bold;
  }

  & label {
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    font-size: 13px;
  }

  & .email-input {
    margin-bottom: 3px;

    input {
      margin-bottom: 0 !important;
    }
  }
`;

const StyledRsvpModalContainerWithSettings = withSettings(StyledRsvpModalContainer);

export { StyledRsvpModalContainerWithSettings as RsvpModalAddOtherGuests };
