import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  LandingPageContainer,
  LandingSectionHeader
} from '../components';

const neighborhoodsData = [
  {
    id: 1,
    label: 'Virtual',
    image: 'https://onetable.org/assets/ui/landing/neighborhood-virtual.jpg',
    url: '/virtual'
  },
  {
    id: 2,
    label: 'Downtown',
    image: 'https://onetable.org/assets/ui/landing/neighborhood-downtown.jpg',
    url: '/search/chicago'
  },
  {
    id: 3,
    label: 'Westside',
    image: 'https://onetable.org/assets/ui/landing/neighborhood-westside.jpg',
    url: '/search/chicago'
  },
  {
    id: 4,
    label: 'Southbay',
    image: 'https://onetable.org/assets/ui/landing/neighborhood-southbay.jpg',
    url: '/search/chicago'
  },
];

export const LandingNeighborhoods = ({ area }) => {
  return (
    <LandingPageContainer top>
      <LandingSectionHeader
        title="Dinners near you"
        subtext="View dinners by neighborhood:"
        viewAllRoute={`/search/${area ? area.url : ''}`}
      />
      <NeighborhoodLinks>
        {Array.isArray(neighborhoodsData) && (
          neighborhoodsData.map(n => <NeighborhoodLink key={n.id} data={n} />)
        )}
      </NeighborhoodLinks>
    </LandingPageContainer>
  );
};

const NeighborhoodLinkWithoutStyles = ({ className, data, history }) => (
  <li className={className} onClick={() => history.push(data.url)}>
    <NeighborhoodImage image={data.image} />
    <label>{data.label}</label>
  </li>
);
const NeighborhoodLinkWithRouter = withRouter(NeighborhoodLinkWithoutStyles);
const NeighborhoodLink = styled(NeighborhoodLinkWithRouter)`
  list-style-type: none;
  border: 1px solid #ceccc7;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 350ms;

  &:hover {
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.18);
  }

  label {
    padding: 0 1.5rem;
    margin: 0;
    font-family: 'Avenir-Medium' !important;
    width: 64%;
    line-height: 1.3;
    cursor: pointer;
  }
`;

const NeighborhoodImage = styled.div`
  width: 36%;
  background-color: #545454;
  position: relative;
  border-radius: 6px 0 0 6px;
  overflow: hidden;

  ${({ image }) => image && css`
    background-image: url(${image});
    background-position: center;
    background-size: cover;
  `}

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const NeighborhoodLinks = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 1.5rem 0 0;
  padding: 0;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;
