import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import {
  useAdditionalGuests,
  useReservationQuestions,
  useClaimedPotluckItems,
  useSession
} from '../../hooks';
import {
  queryString,
  localStorage,
  formatClaimedPotluckItemsForSubmit
} from '../../libs';

import ROUTES from '../../constants/routes';
import RSVP_PAGES from '../../constants/rsvpPages';
import { CREATE_RESERVATION } from '../../mutations';

const RsvpPageContext = React.createContext();

const emptyAdditionalGuest = { firstName: '', lastName: '', email: '' };
export const getEmptyAdditionalGuest = () => Object.assign({}, emptyAdditionalGuest);


const RsvpPageContextProvider = ({ children, eventData }) => {
  const history = useHistory();
  const { uuid } = useParams();
  const { search } = useLocation();
  const { viewer, refetch: refetchSession, theme } = useSession();
  const { stripe_redirect, stripe_session_id } = queryString(search, ['stripe_redirect', 'stripe_session_id']);

  const [createReservationMutation] = useMutation(CREATE_RESERVATION);

  const [event, setEvent] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pages, setPages] = useState([RSVP_PAGES.MESSAGE_AND_GUESTS]);
  const [currentPage, setCurrentPage] = useState(RSVP_PAGES.MESSAGE_AND_GUESTS);
  const [readyToRsvp, setReadyToRsvp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [hostMessage, setHostMessage] = useState('');
  const [donation, setDonation] = useState(0);
  const [membership, setMembership] = useState(null);
  const [priceSelection, setPriceSelection] = useState(null);
  const [inHonor, setInHonor] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);

  const {
    additionalGuests,
    setAdditionalGuests,
    validateAdditionalGuests
  } = useAdditionalGuests();
  const {
    questions,
    answers,
    setAnswers,
    setAnswersAll,
    validateAnswers
  } = useReservationQuestions(event);
  const {
    claimedPotluckItems,
    setClaimedPotluckItems,
    setClaimedPotluckItemsAll,
    validateClaimedPotluckItems
  } = useClaimedPotluckItems(event);

  const loadDataFromStorage = () => {
    const data = localStorage.load('reservation_data') || {};
    console.log('data', data);
    data.hostMessage && setHostMessage(data.hostMessage);
    data.donation && setDonation(data.donation);
    data.additionalGuests && setAdditionalGuests(data.additionalGuests);
    data.answers && setAnswersAll(data.answers);
    data.claimedPotluckItems && setClaimedPotluckItemsAll(data.claimedPotluckItems);
    data.inHonor && setInHonor(data.inHonor);
  };

  const saveDataToStorage = () => {
    localStorage.store({
      hostMessage,
      donation,
      additionalGuests,
      answers,
      claimedPotluckItems,
      inHonor,
      afterMembershipSuccess: true
    }, 'reservation_data');
  };

  const createReservation = async (checkoutData) => {
    const { cardId, coupon, totalPrice } = checkoutData || {};
    const reservationData = localStorage.load('reservation_data') || {};
    const paidEvent = eventData.pricePerPerson || eventData.pwyw;
    const potluckItems = formatClaimedPotluckItemsForSubmit(
      reservationData.claimedPotluckItems || claimedPotluckItems
    );

    const variables = {
      eventId: eventData.id,
      additionalGuests: reservationData.additionalGuests || additionalGuests,
      rsvpNote: reservationData.hostMessage || hostMessage,
      answers: reservationData.answers || answers,
      // tables: [],
      // donation: donationAmount,
    };
    if (eventData.potluck && potluckItems.length) variables.claimedPotluckItems = potluckItems;
    if (paidEvent && coupon) variables.couponId = coupon.id;
    if (paidEvent && totalPrice) variables.payment = { cardId, totalPrice };
    // if (eventData.pwyw) variables.pwywAmount = customAmount * 100;

    localStorage.resetAll('reservation_data');

    setLoading(true);
    const { errors } = await createReservationMutation({ variables, errorPolicy: 'all' });
    await refetchSession();
    setLoading(false);

    if (errors) {
      errors.forEach(({ extensions: { errors: ers } }) => {
        ers && ers.forEach(({ code }) => {
          if (code === 'MEMBERSHIP_REQUIRED') history.push({
            pathname: `/${ROUTES.EVENTS}/${eventData.uuid}`,
            state: { reservationFailedRedirect: true, code }
          });

          // need to handle createReservation errors! //
        })
      })
    } else {
      history.push({
        pathname: `/${ROUTES.EVENTS}/${eventData.uuid}`,
        state: {
          reservationSuccessRedirect: true,
          afterMembershipSuccess: reservationData.afterMembershipSuccess
        }
      });
    }
  };

  // handle redirect back from stripe //
  useEffect(() => {
    if (!stripe_redirect || !membership || !uuid || pages.length <= 1) return;
    const useRsvpPage = theme.isVersion(2);
    const storedDonation = localStorage.getItem('donation', 'reservation_data') || 0;
    const membershipPaymentAmount = localStorage.getItem('membershipPaymentAmount', 'reservation_data');

    stripe_redirect === 'success' && window.dataLayer?.push({
      event: 'rsvp_modal_payment_success',
      rsvp_modal_payment_success_amount: membershipPaymentAmount
    });

    useRsvpPage && loadDataFromStorage();

    if (stripe_redirect === 'cancel') {
      history.push({ pathname: `/${ROUTES.EVENTS}/${uuid}`, state: { stripeCancelRedirect: true } });

    } else if (pages.includes(RSVP_PAGES.CHECKOUT) || donation > 0 || storedDonation > 0) { // go to payment modal/page
      if (useRsvpPage) {
        setPageIndex(pages.indexOf(RSVP_PAGES.CHECKOUT));
        setPageLoading(false);
      } else {
        history.push({
          pathname: `/${ROUTES.EVENTS}/${uuid}`,
          state: { stripeSuccessRedirect: true, shouldShowPaymentModal: true }
        });
      }

    } else if (stripe_redirect === 'success' && stripe_session_id && !loading) { // create reservation
      if (useRsvpPage) {
        setPageIndex(pages.length - 2);
        createReservation();
      } else {
        loadDataFromStorage();
        createReservation();
      }
    }
  }, [stripe_redirect, stripe_session_id, membership, uuid, pages]);

  useEffect(() => {
    if (pageLoading && !stripe_redirect) setPageLoading(false);
  }, [stripe_redirect, pageLoading]);

  // change page on pageIndex change //
  useEffect(() => {
    pages[pageIndex] && setCurrentPage(pages[pageIndex]);
  }, [pageIndex]);

  // load data from queried event //
  useEffect(() => {
    const pageOrder = pages.slice();

    if (!event && eventData) {
      const {
        reservationQuestions,
        potluck,
        potluckItems,
        pricePerPerson,
        pwyw,
        area
      } = eventData;

      if (reservationQuestions && reservationQuestions.edges && reservationQuestions.edges.length) {
        pageOrder.push(RSVP_PAGES.RESERVATION_QUESTIONS);
      }
      if (potluck && potluckItems.edges && potluckItems.edges.length) {
        pageOrder.push(RSVP_PAGES.POTLUCK);
      }
      if (area?.membership?.products) {
        const areaMembershipProducts = area.membership.products.filter(({ active, prices }) =>
            active && prices.filter(({ active: activePrice }) => activePrice)
        );
        if (areaMembershipProducts.length && (!viewer.memberships.length || stripe_redirect === 'success')) {
        // if (areaMemberships) {
          setMembership(area.membership);
          pageOrder.push(RSVP_PAGES.MEMBERSHIP);
        }
      }
      if (pricePerPerson || pwyw || donation > 0) {
        pageOrder.push(RSVP_PAGES.CHECKOUT);
      }

      setPages(pageOrder);
      setEvent(eventData);
    }
  }, [event, eventData]);

  const getField = name => {
    switch (name) {
      case 'hostMessage':
        return hostMessage;
      case 'additionalGuests':
        return additionalGuests;
      case 'questions':
        return questions;
      case 'answers':
        return answers;
      case 'claimedPotluckItems':
        return claimedPotluckItems;
      case 'donation':
        return donation;
      case 'membership':
        return membership;
      case 'priceSelection':
        return priceSelection;
      case 'inHonor':
        return inHonor;
      default:
    }
  };

  const setField = (name, val) => {
    switch (name) {
      case 'hostMessage':
        setHostMessage(val);
        break;
      case 'additionalGuests':
        setAdditionalGuests(val);
        break;
      case 'answers':
        setAnswers(val);
        break;
      case 'claimedPotluckItems':
        setClaimedPotluckItems(val);
        break;
      case 'donation':
        setDonation(val);
        break;
      case 'priceSelection':
        setPriceSelection(val);
        break;
      case 'inHonor':
        setInHonor(val);
        break;
      default:
    }
  };

  const nextPage = () => pages[pageIndex + 1];

  const validatePage = () => {
    switch (currentPage) {
      case RSVP_PAGES.MESSAGE_AND_GUESTS:
        if (additionalGuests.length) return validateAdditionalGuests();
        break;
      case RSVP_PAGES.POTLUCK:
        return validateClaimedPotluckItems();
      default:
    }
    return true;
  };

  const changePage = (forward = true) => {
    if (forward) {
      if (validatePage()) {
        if (nextPage() === RSVP_PAGES.MEMBERSHIP) {
          saveDataToStorage();
          setShowMembershipModal(true);
          return;
        }
        if (pageIndex === pages.length - 2) setReadyToRsvp(true);
        if (pageIndex === pages.length - 1) {
          createReservation();
        } else {
          setPageIndex(prev => prev + 1);
          window.scrollTo(0, 0);
        }
      }
    } else {
      if (pageIndex <= 0) history.push(`/${ROUTES.EVENTS}/${uuid}`);
      else {
        setPageIndex(prev => prev - 1);
        window.scrollTo(0, 0);
      }
    }
  };

  const value = {
    event,
    pageIndex,
    pages,
    getField,
    setField,
    changePage,
    currentPage,
    validateAnswers,
    membershipModal: {
      show: showMembershipModal,
      toggle: () => setShowMembershipModal(prev => !prev)
    },
    readyToRsvp,
    createReservation,
    loading,
    setLoading,
    pageLoading
  };

  return <RsvpPageContext.Provider value={value}>{children}</RsvpPageContext.Provider>;
};

export { RsvpPageContext, RsvpPageContextProvider };
