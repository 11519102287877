import { useState, useEffect, useRef } from 'react';

export const useTemplateState = (data, saveAnswer) => {
  const [questionsState, setQuestionsState] = useState([]);
  const setData = useRef(false);

  useEffect(() => {
    if (data && !setData.current) {
      // console.log(data);
      const questionData = data.map(w => {
        const { default: defaultValue , options, question: { id, prefill, multiple } } = w;
        const defaultOption = defaultValue && options
          ? options.find(o => o.value && o.value.string === defaultValue.string)
          : false;

        if (defaultOption) {
          saveAnswer(
            id,
            defaultOption.value.string,
            defaultOption.label,
            prefill,
            multiple
          );
        }

        return { id, value: '', label: '', prefill, multiple };
      });

      setQuestionsState(questionData);
      setData.current = true;
    }
  }, [data, saveAnswer]);

  const updateQuestionsState = (id, value, label, prefill, multiple) => {
    const newState = questionsState.slice();

    for (const i in newState) {
      if (newState[i].id === id)
        newState[i] = { id, value, label, prefill, multiple };
    }

    setQuestionsState(newState);
  };

  const shouldBeShown = ({ conditional: { condition } }) => {
    const { question: rawQ, matches } = condition;
    const questionId = parseInt(rawQ.replace('q', ''), 10);
    const question = questionsState.find(q => q.id === questionId);

    return question && question.value === matches;
  };

  return {
    questionsState,
    updateQuestionsState,
    shouldBeShown
  };
};
