import React from 'react';
import styled from 'styled-components';

import { useSettings } from '../../hooks';
import {
  formatPriceWithCents,
  // getDiscountPrice,
  // getTransactionFee
} from '../../libs';
// import { trigger } from '../../libs/customEvent';

import { Divider } from '../components';

export const EventDetailCardCheckout = ({ event, checkoutData = {} }) => {
  const { copy } = useSettings();
  const {
    pricePerPerson,
    pwyw
  } = event;
  const {
    // coupon,
    // additionalGuests,
    discountAmount,
    transactionFee,
    donation,
    numberOfGuests,
    guestsPrice,
    totalPrice,
    couponCode
  } = checkoutData;

  const guestsDescriptionString = `${numberOfGuests} seat${numberOfGuests === 1 ? '' : 's'}` +
    ` @ ${formatPriceWithCents(pricePerPerson)}`;
  const guestsPriceString = formatPriceWithCents(guestsPrice);
  const discountString = couponCode ? `Coupon ${couponCode}` : 'Coupon';
  const discountAmountString = formatPriceWithCents(discountAmount);
  const transactionFeeString = formatPriceWithCents(transactionFee);
  const donationString = formatPriceWithCents(donation);
  const totalPriceString = totalPrice === 0 ? 'Free' : formatPriceWithCents(totalPrice);

  // useEffect(() => {
  //   if (totalPrice === 0) trigger('rsvpPage:freeByCoupon');
  // }, [totalPrice]);

  return (
    <>
      <Hr />
      <Details>
        {(pricePerPerson || pwyw) && (
          <Row>
            <span>{guestsDescriptionString}</span>
            <span>{guestsPriceString}</span>
          </Row>
        )}
        {(pricePerPerson || pwyw) && discountAmount > 0 && (
          <Row>
            <span>{discountString}</span>
            <span>-{discountAmountString}</span>
          </Row>
        )}
        {transactionFee > 0 && (
          <Row>
            <span>{copy.rsvp.transaction_fee}</span>
            <span>{transactionFeeString}</span>
          </Row>
        )}
        {donation > 0 && (
          <Row>
            <span>{copy.rsvp.donation}</span>
            <span>{donationString}</span>
          </Row>
        )}
      </Details>
      <Hr />
      <Row>
        <span>{copy.rsvp.total}</span>
        <span className="total">{totalPriceString}</span>
      </Row>
    </>
  );
};

const Details = styled.div`
  & > div {
    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-directon: row;
  justify-content: space-between;

  span {
    &:last-child {
      font-family: 'Avenir-Demi';
    }

    &.total {
      font-size: 20px;
    }
  }
`;

const Hr = () => <Divider margin="20px 0" />;
