import React, { useState } from 'react';
import styled from 'styled-components';
import { EmailLogIn, FacebookLogIn, withSettings } from '../components';
import { useSession } from '../../hooks';

const LogIn = ({
  className,
  shouldRedirect,
  afterLogin,
  allowEditPhoneNumber,
  settings: { features }
}) => {
  const [showEmailLogin, setShowEmailLogin] = useState(true);

  const { refetch } = useSession();

  return (
    <div className={className}>
      <hr />
      {features.facebook_login && (
        <FacebookLogIn
          afterLogin={afterLogin}
          shouldRedirect={shouldRedirect}
          refetch={refetch}
          allowEditPhoneNumber={allowEditPhoneNumber}
          toggleEmailLogin={() => setShowEmailLogin(false)}
        />
      )}
      {showEmailLogin && (
        <>
          {features.facebook_login && <hr className="or" />}
          <EmailLogIn
            afterLogin={afterLogin}
            shouldRedirect={shouldRedirect}
            refetch={refetch}
            allowEditPhoneNumber={allowEditPhoneNumber}
          />
        </>
      )}
    </div>
  );
};

const StyledLogIn = styled(LogIn)`
  & > hr {
    margin: 30px 0;

    &.or {
      position: relative;

      &::after {
        content: 'OR';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        padding: 0 10px;
        background-color: #fff;
        color: #8c8c8c;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }
`;

const StyledLogInWithSettings = withSettings(StyledLogIn);

export { StyledLogInWithSettings as LogIn };
