import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../styles';

import {
  Card,
  Button,
  SelectBox,
  InfoTooltip
} from '../../components';

import { withSettings } from '../../components';
import {
  getVirtualEventFeildsByPolicy,
  formatPrivacySubtypes,
  invalidateNumber
} from '../../../libs';
import { EVENT_TYPE } from '../../../enums';


import fieldsConfig from '../const/FieldsConfiguration';

const EventCreationTypeSelector = props => {
  const {
    className,
    currentArea,
    eventType,
    eventTypes,
    eventTags,
    onTypeSelectorChange,
    onValidate,
    invalidate,
    settings,
    areas,
    privacySubtype,
    privacySubtypeDisabled,
    eventPrivacySubtypes,
    stateSocialDistancePolicies,
    stateLocation
  } = props;

  const { copy, event: { virtual }, features } = settings;

  const [allPrivacySubtypes, setAllPrivacySubtypes] = useState([]);
  const [privacySubtypes, setPrivacySubtypes] = useState([]);

  useEffect(() => {
    if (eventPrivacySubtypes) {
      const subtypes = formatPrivacySubtypes(eventPrivacySubtypes);
      setAllPrivacySubtypes(subtypes);
      eventType && setPrivacySubtypes(subtypes[eventType]);
    }
  }, [eventPrivacySubtypes, eventType]);

  const eventArea = areas && areas.find(a => currentArea ? a.code === currentArea.code : false);

  const setFields = useCallback((value = eventType) => {
    const isSoloEvent = value === EVENT_TYPE.SOLO;

    // vaccination policy //
    const eventVaccinatedPolicy = eventArea && eventArea.socialDistancePolicy
      ? eventArea.socialDistancePolicy[`${value === 'MANUAL_APPROVAL' ? 'manualApproval' : value.toLowerCase()}VaccinatedCreationPolicy`]
      : false;

    // update virtual policy depending on area //
    let eventVirtualPolicy = eventArea && eventArea.socialDistancePolicy
      ? eventArea.socialDistancePolicy[`${value === 'MANUAL_APPROVAL' ? 'manualApproval' : value.toLowerCase()}VirtualCreationPolicy`]
      : false;

    // globall fallback //
    const globalVirtualPolicy = virtual[
      `${value === 'MANUAL_APPROVAL' ? 'manual_approval' : value.toLowerCase()}_creation_policy`
    ];

    // also update when a state social distance policy is set //
    if (eventType && !eventType.timezone && stateLocation && stateSocialDistancePolicies) {
      const newStatePolicy = stateSocialDistancePolicies.find(p => p.state === stateLocation);
      const stateVirtualPolicy = newStatePolicy && newStatePolicy.socialDistancePolicy
        ? newStatePolicy.socialDistancePolicy[
            `${value === 'MANUAL_APPROVAL' ? 'manualApproval' : value.toLowerCase()}VirtualCreationPolicy`
          ]
        : false;

      if (newStatePolicy) eventVirtualPolicy = stateVirtualPolicy;
    }

    const virtualPolicy = eventVaccinatedPolicy !== 'mandatory' ? eventVirtualPolicy || globalVirtualPolicy : false;
    const virtualFields = getVirtualEventFeildsByPolicy(virtualPolicy, settings, eventTags);

    // const filteredTags = eventTags.filter(tag => /* tag !== 'virtual' && */ tag !== 'solo');
    const filteredTags = eventTags.filter(tag => tag !== 'outdoors' && tag !== 'virtual' && tag !== 'solo');

    const soloFields = Object.assign({}, isSoloEvent
      ? fieldsConfig.SOLO.enabled
      : fieldsConfig.SOLO.disabled
    );

    const privacySubtypeFields = { privacySubtypeDisabled: false };

    // dont overwrite any non-`virtual`/`solo` tags
    if (filteredTags.length > 0) {
      if (isSoloEvent) soloFields.tags = soloFields.tags.concat(filteredTags);
      // else virtualFields.tags = virtualFields.tags.concat(filteredTags);
    }

    if (virtualFields.hasOwnProperty('virtual') && virtualFields.virtual === false) {
      virtualFields.virtualLocation = '';
    }

    privacySubtypeFields.privacySubtype = allPrivacySubtypes
      ? (allPrivacySubtypes[value]
          ? allPrivacySubtypes[value].find(s =>
              // if vaccinated, virtual or outdoors or matching subtype names, switch to correct privacySubtype per the eventType //
              // need to account for privacy subtypes marked as both vaccinated AND outdoors and distinguish which arent //
              privacySubtype && (
                (privacySubtype.vaccinated && privacySubtype.outdoors && s.vaccinated && s.outdoors) ||
                (privacySubtype.vaccinated && !privacySubtype.outdoors && s.vaccinated) ||
                (privacySubtype.virtual && s.virtual) ||
                (privacySubtype.outdoors && !privacySubtype.vaccinated && s.outdoors) ||
                (privacySubtype.name === s.name)
              )
              // otherwise clear privacySubtype //
            ) || null
          : null)
      : null;

    if (eventVaccinatedPolicy === 'mandatory' || virtualPolicy === 'mandatory') {
      // when mandatory, disable privacySubtype field and set it to correct virtual privacySubtype //
      privacySubtypeFields.privacySubtypeDisabled = true;
      privacySubtypeFields.privacySubtype = allPrivacySubtypes
        ? (allPrivacySubtypes[value]
            ? allPrivacySubtypes[value].find(s => (
              eventVaccinatedPolicy === 'mandatory' ? s.vaccinated : s.virtual
            ))
            : null)
        : null;
      // set or clear virtual flag based on mandatory policy //
      privacySubtypeFields.virtual = virtualPolicy === 'mandatory';
    }

    onTypeSelectorChange({
      eventType: value,
      tags: filteredTags,
      ...virtualFields,
      ...soloFields,
      ...privacySubtypeFields
    });
  }, [
    allPrivacySubtypes,
    privacySubtype,
    eventArea,
    eventTags,
    onTypeSelectorChange,
    settings,
    virtual,
    eventType,
    stateLocation,
    stateSocialDistancePolicies
  ]);

  const onInputChange = e => {
    const target = e.target;
    const button = target.matches('.button') ? target : target.parentNode;
    const { value } = button;

    setFields(value);
  };

  const getPrivacySubtypeTooltip = () => {
    return privacySubtype && privacySubtype.vaccinated
      ? copy.create.privacy_subtype_vaccinated_tooltip || 'Vaccinated Event Information'
      : copy.create.privacy_subtype_tooltip;
  }

  useEffect(() => {
    if (currentArea) setFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentArea]);

  let confirmationDetails = '';

  switch (eventType) {
    case EVENT_TYPE.PRIVATE:
      confirmationDetails = privacySubtype && privacySubtype.socialDistancePolicy 
        ? privacySubtype.socialDistancePolicy.typeSelectConfirmDetailsPrivate
        : (eventArea && eventArea.socialDistancePolicy
          ? eventArea.socialDistancePolicy.typeSelectConfirmDetailsPrivate
          : '');
      break;
    case EVENT_TYPE.PUBLIC:
      confirmationDetails = privacySubtype && privacySubtype.socialDistancePolicy 
        ? privacySubtype.socialDistancePolicy.typeSelectConfirmDetailsPublic
        : (eventArea && eventArea.socialDistancePolicy
          ? eventArea.socialDistancePolicy.typeSelectConfirmDetailsPublic
          : '');
      break;
    case EVENT_TYPE.MANUAL_APPROVAL:
      confirmationDetails = privacySubtype && privacySubtype.socialDistancePolicy 
        ? privacySubtype.socialDistancePolicy.typeSelectConfirmDetailsManualApproval
        : (eventArea && eventArea.socialDistancePolicy
          ? eventArea.socialDistancePolicy.typeSelectConfirmDetailsManualApproval
          : '');
      break;
    default:
  }

  if (!confirmationDetails && eventType)
    confirmationDetails = copy.event[`type_select_confirm_details_${eventType.toLowerCase()}`];

  let split = 'full';

  switch (eventTypes.length) {
    case 1:
      split = 'third centered';
      break;
    case 2:
      split = 'half';
      break;
    case 3:
      split = 'third';
      break;
    case 4:
      split = 'quarter';
      break;
    default:
  }

  return (
    <Card className={className}>
      <h2 className="card_title">{copy.create.event_type}</h2>
      <i className="divider"/>
      <ul className={`event_type_buttons float_clear ${split}`}>
        {eventTypes.map((type, i) => (
          <li style={{ position: 'relative', zIndex: eventTypes.length - i }} key={i}>
            <Button
              name="eventType"
              value={type.value}
              buttonStyle={eventType === type.value ? 'primary' : 'primary_outline'}
              handleClick={onInputChange}
              tooltip={type.tooltip}
            >{type.label}</Button>
          </li>
        ))}
      </ul>
      <div className="form_group break_on_tablet_small">
        <div className={features.privacy_subtypes ? 'one_half' : 'centered_text'}>
          {eventType
            ? (
              <p
                className={features.privacy_subtypes ? 'padding_right' : ''}
                dangerouslySetInnerHTML={{ __html: confirmationDetails }}
              />
            ) : <p className="spacer"/>
          }
        </div>
        {features.privacy_subtypes && Array.isArray(privacySubtypes) && (
          <div className="one_half">
            {/* Sub Privacy Type */}
            <label className="with_tooltip">
              {copy.create.privacy_subtype || 'Gathering Type'}
              <InfoTooltip content={getPrivacySubtypeTooltip()}/>
            </label>
            <SelectBox
              name="privacySubtype"
              options={privacySubtypes.map(type => ({
                id: type.id,
                label: type.name,
                value: type.id
              }))}
              onOptionSelect={value => onTypeSelectorChange('privacySubtype', privacySubtypes.find(t => t.id === value))}
              placeholder="---"
              defaultValue={{
                id: privacySubtype ? privacySubtype.id : null,
                label: privacySubtype ? privacySubtype.name : null,
                value: privacySubtype ? privacySubtype.id : null
              }}
              onValidate={onValidate}
              invalidate={value => invalidate && invalidateNumber(value)}
              errorMessage={'Select a gathering type.'}
              disabled={privacySubtypeDisabled || !privacySubtypes.length}
              required
            />
          </div>
        )}
      </div>
    </Card>
  );
};


const StyledEventCreationTypeSelector = styled(EventCreationTypeSelector)`
  // padding: 25px 0 20px !important;
  text-align: center;
  position: relative;
  z-index: 30;

  .invalid {
    color: ${props => props.settings.theme.css.global.colors.error};
  }

  .event_type_selector {
    display: inline-block;
  }

  .event_type_buttons {
    width: calc(100% + 10px);
    list-style-type: none;
    margin: 30px -5px 0;
    padding: 0;
    position: relative;

    li {
      width: 100%;
      padding: 0 5px;
      float: left;

      .button {
        width: 100%;
      }
    }

    &.half {
      li {
        width: 50%;
      }
    }

    &.third {
      li {
        width: 33.3333%;
      }

      &.centered {
        text-align: center;

        li {
          float: none;
          display: inline-block;
        }
      }
    }

    &.quarter {
      li {
        width: 25%;
      }
    }

    .ot_tooltip {
      left: 50%;
      transform: translateX(-50%);
      bottom: auto;
      top: 150%;
      margin-bottom: 0;
    }

    @media (max-width: 750px) {
      li {
        margin-bottom: 7px;
        width: 100% !important;
      }

      .ot_tooltip {
        display: none !important;
      }
    }
  }

  .form_group {
    margin-top: 30px;
    padding-top: 30px;
    text-align: left;
    border-top: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};

    p {
      font-size: 14px;
      color: ${props => props.settings.theme.css.global.colors.textLight};

      &.padding_right {
        padding-right: 10%;
      }
    }

    label {
      padding-top: 0 !important;
    }

    @media (${breakpoints.tabletSmall}) {
      .one_half:first-of-type {
        padding-bottom: 10px;
      }
    }
  }
`;

const StyledEventCreationTypeSelectorWithSettings = withSettings(StyledEventCreationTypeSelector);

export { StyledEventCreationTypeSelectorWithSettings as EventCreationTypeSelector };
