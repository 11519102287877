import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import { breakpoints } from '../../styles';


const Card = ({ className, id, children }) => (
  <div id={id} className={`${className} ot_card`}>
    {children}
  </div>
);


const StyledCard = styled(Card)`
  position: relative;
  max-width: 1192px;
  margin: 20px auto;
  background-color: ${props => props.backgroundColor || '#fff'};
  box-shadow: ${props => props.boxShadow || props.settings.theme.css.global.card.shadow};
  padding: ${props => props.noPadding ? 0 : '60px 100px'};

  @media (${breakpoints.tablet}) {
    padding: ${props => props.noPadding ? 0 : '60px'};
  }

  @media (${breakpoints.mobile}) {
    padding: ${props => props.noPadding ? 0 : '60px 30px'};
  }
`;

const CardWithSettings = withSettings(StyledCard);

export { CardWithSettings as Card };
