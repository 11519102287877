import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';
import ProfileViewQuestions from './ProfileViewQuestions';
import PropTypes from 'prop-types';
import { userHelpers } from '../../libs/userHelpers';
import { isRegularOneTable } from '../../libs';

const ProfileViewBio = (props) => {
  const { showAge, dob, bio, questions, settings } = props;

  return (
    <ProfileViewInfo>
      {showAge && dob && isRegularOneTable(settings) && (
        <ProfileViewField name='Age' value={userHelpers.getAgeFromDOB(dob)} />
      )}
      <ProfileViewField name='Bio' value={bio} />
      <ProfileViewQuestions questions={questions} />
    </ProfileViewInfo>
  );
}

ProfileViewBio.propTypes = {
  bio: PropTypes.string,
  questions: PropTypes.array
};

const ProfileViewField = ({ className, name, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div className={className}>
      <BioTitle>{name}</BioTitle>
      <BioValue>{value}</BioValue>
    </div>
  );
};

ProfileViewField.propTypes = {
  className: PropTypes.string,
  bio: PropTypes.string
};

const ProfileViewInfo = styled.div`
  text-align: left;
`;

const BioTitle = styled.h2`
  font-size: 18px;
  margin: 25px 0 10px;
  line-height: 1.6;
`;

const BioValue = styled.p`
  font-size: 16px;
`;


export default withSettings(ProfileViewBio);
