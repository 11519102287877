import gql from 'graphql-tag';
import { pageSections } from './Page';

export const areasQuery = `
  areas {
    id
    code
    areaBanner {
      id
      content
    }
    areaPopups {
      nodes {
        content
        id
        label
        url
      }
    }
    campuses {
      id
      label
      subneighborhoods {
        code
        label
      }
    }
    code
    label
    url
    timezone
    feeValue
    transactionFeeAmount
    neighborhoods {
      code
      label
      subneighborhoods {
        code
        label
      }
    }
    position
    sponsors {
      totalCount
    }
    maxedWeeklySponsorshipLimits {
      edges {
        node {
          area {
            id
            code
            nourishmentLockDaysBeforeEvent
            newHostNourishmentDelayDays
            maxPaidAdditionalGuests
            maxUnpaidAdditionalGuests
          }
          beginningOn
        }
      }
    }
    nourishmentLockDaysBeforeEvent
    limitOneNourishmentPerMonth
    newHostNourishmentDelayDays
    eventPostSuccessMessageWithNourishment
    eventPostSuccessMessageWithoutNourishment
    eventPostSuccessMessageWithoutSponsors
    limitOneNourishmentPerMonth
    newHostNourishmentDelayDays
    nourishmentLockDaysBeforeEvent
    maxedWeeklySponsorshipLimits {
      edges {
        node {
          id
          beginningOn
          limit
        }
      }
    }

    socialDistancePolicy {
      ...Policy
    }
  }
`;


export const socialDistancePolicyFragment = `
  fragment Policy on SocialDistancePolicy {
    id
    nonVirtualDisclaimer

    manualApprovalEventAllowed
    privateEventAllowed
    publicEventAllowed
    soloEventAllowed

    eventTypeManualApprovalHelp
    eventTypePrivateHelp
    eventTypePublicHelp

    manualApprovalNonVirtualMaxSeatsCap
    privateNonVirtualMaxSeatsCap
    publicNonVirtualMaxSeatsCap

    manualApprovalVirtualCreationPolicy
    privateVirtualCreationPolicy
    publicVirtualCreationPolicy

    manualApprovalVaccinatedCreationPolicy
    privateVaccinatedCreationPolicy
    publicVaccinatedCreationPolicy

    typeSelectConfirmDetailsManualApproval
    typeSelectConfirmDetailsPrivate
    typeSelectConfirmDetailsPublic
  }
`;

export const STATE_SOCIAL_DISTANCE_POLICIES = gql`
  ${socialDistancePolicyFragment}
  query stateSocialDistancePolicies {
    stateSocialDistancePolicies {
      id
      state
      socialDistancePolicy {
        ...Policy
      }
    }
  }
`;

export const AREAS = gql`
  ${socialDistancePolicyFragment}
  query areas {
    ${areasQuery}
  }
`;

export const AREA_BY_URL = gql`
  query areaByUrl($url: String!) {
    areaByUrl(url: $url) {
      id
      altText
      areaBanner {
        id
        content
      }
      page {
        id
        title
        ${pageSections}
      }
      code
      url
      collapsePrivateEventDisplay
      collapseSoloEventDisplay
      label
      feeValue
      transactionFeeAmount
      neighborhoods {
        code
        label
        subneighborhoods {
          code
          label
        }
      }
      photo {
        smallUrl
        mediumUrl
        largeUrl
        originalUrl
        smallUrl
        mediumUrl
        largeUrl
      }
    }
  }
`;

export const AREA = gql`
  query area($id: ID!) {
    area(id: $id) {
      id
      altText
      areaBanner {
        id
        content
      }
      code
      label
      feeValue
      transactionFeeAmount
      neighborhoods {
        code
        label
        subneighborhoods {
          code
          label
        }
      }
      photo {
        smallUrl
        mediumUrl
        largeUrl
        originalUrl
      }
    }
  }
`;
