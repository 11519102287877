import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  MANAGE_EVENT_INVITATIONS_PENDING,
  MANAGE_EVENT_INVITATIONS_DECLINED
} from '../queries';

export const INVITATIONS_PER_PAGE = 10;


export const usePaginatedInvitations = (event, queryVars) => {
  const pendingInvitations = useQuery(MANAGE_EVENT_INVITATIONS_PENDING, queryVars);
  const rejectedInvitations = useQuery(MANAGE_EVENT_INVITATIONS_DECLINED, queryVars);

  const [invitations, setInvitations] = useState({
    pending: [],
    rejected: []
  });

  useEffect(() => {
    setInvitations(prevState => ({
      pending: pendingInvitations.data && pendingInvitations.data.event.pendingInv
        ? pendingInvitations.data.event.pendingInv.edges
        : prevState.pending,
      rejected: rejectedInvitations.data && rejectedInvitations.data.event.rejectedInv
        ? rejectedInvitations.data.event.rejectedInv.edges
        : prevState.rejected
    }));
  }, [pendingInvitations, rejectedInvitations]);

  const [totalCounts, setTotalCounts] = useState({
    pending: 0,
    rejected: 0
  });

  useEffect(() => {
    setTotalCounts(prevState => ({
      pending: event ? event.invitations.invitationsPending : prevState.pending,
      rejected: event ? event.invitations.invitationsDeclined : prevState.rejected
    }));
  }, [event, pendingInvitations]);

  const [pageIndexes, setPageIndexes] = useState({
    pending: 0,
    rejected: 0
  });

  const [fetchMoreLoading, setFetchMoreLoading] = useState({
    pending: false,
    rejected: false
  });

  const updateLoading = (state, value) => {
    setFetchMoreLoading(prevLoading => ({
      ...prevLoading,
      [state]: value
    }))
  };

  const fetchMore = (state, fetch) => {
    const inv = invitations[state];
    const lastInv = inv && inv[inv.length - 1];
    const currentIndex = pageIndexes[state];

    if (lastInv) {
      updateLoading(state, true);

      fetch({
        variables: {
          uuid: queryVars.variables.uuid,
          isHost: true,
          after: lastInv.cursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          updateLoading(state, false);

          if (fetchMoreResult) {
            const newEdges = fetchMoreResult.event[`${state}Inv`].edges;
            const prevEdges = prev.event[`${state}Inv`].edges;

            fetchMoreResult.event[`${state}Inv`].edges = [...prevEdges, ...newEdges];

            setPageIndexes(prevIndexes => ({
              ...prevIndexes,
              [state]: currentIndex + INVITATIONS_PER_PAGE
            }));

            return fetchMoreResult;
          }
          return prev;
        },
      })
    }
  };

  const fetchInvitations = {
    pending: pendingInvitations ? () => fetchMore('pending', pendingInvitations.fetchMore) : null,
    rejected: rejectedInvitations ? () => fetchMore('rejected', rejectedInvitations.fetchMore) : null,
  };

  const hasNextPage = state => {
    const currentIndex = pageIndexes[state];
    const totalCount = totalCounts[state];
    const currentInvitations = invitations[state];
    const currentCount = Array.isArray(currentInvitations) ? currentInvitations.length : 0;
    return totalCount > currentCount || currentIndex + INVITATIONS_PER_PAGE < totalCount;
  };

  const hasPrevPage = state => {
    const currentIndex = pageIndexes[state];
    return currentIndex > 0;
  };

  const handleGoToNextPage = state => {
    const inv = invitations[state];
    const currentIndex = pageIndexes[state];
    const fetch = fetchInvitations[state];
    const totalCount = totalCounts[state];

    if (fetchMoreLoading[state]) return;

    if (currentIndex + INVITATIONS_PER_PAGE <= inv.length && inv.length < totalCount) {
      fetch && fetch();
    } else {
      setPageIndexes(prevIndexes => ({
        ...prevIndexes,
        [state]: currentIndex + INVITATIONS_PER_PAGE
      }));
    }
  };

  const handleGoToPrevPage = state => {
    const newIndex = pageIndexes[state] - INVITATIONS_PER_PAGE;

    if (fetchMoreLoading[state]) return;

    setPageIndexes(prevIndexes => ({
      ...prevIndexes,
      [state]: newIndex >= 0 ? newIndex : 0
    }));
  };

  const refreshInvitations = data => {
    const { id, action } = data;
    let pendingInvites = invitations.pending.slice();
    let rejectedInvites = invitations.rejected.slice();
    let invitation;
    let pendingTotal;
    let rejectedTotal;

    switch (action) {
      case 'pending':
        invitation = invitations.rejected.find(i => i.node.id === id);
        invitation && pendingInvites.unshift(invitation);
        rejectedInvites = rejectedInvites.filter(i => i.node.id !== id);
        pendingTotal = totalCounts.pending + 1;
        rejectedTotal = totalCounts.rejected - 1;
        break;
      case 'rejected':
        invitation = invitations.pending.find(i => i.node.id === id);
        invitation && rejectedInvites.unshift(invitation);
        pendingInvites = pendingInvites.filter(i => i.node.id !== id);
        pendingTotal = totalCounts.pending - 1;
        rejectedTotal = totalCounts.rejected + 1;
        break;
      default:
    }

    if (!pendingInvites.length && pendingTotal > 0) {
      fetchInvitations.pending();
    }

    if (!rejectedInvites.length && rejectedTotal > 0) {
      fetchInvitations.rejected();
    }

    setInvitations({
      pending: pendingInvites,
      rejected: rejectedInvites
    });

    setTotalCounts({
      pending: pendingTotal,
      rejected: rejectedTotal
    });

    setPageIndexes({
      pending: 0,
      rejected: 0
    });
  };

  const getInvitationPages = () => ({
    pending: invitations.pending.slice(pageIndexes.pending, pageIndexes.pending + INVITATIONS_PER_PAGE),
    rejected: invitations.rejected.slice(pageIndexes.rejected, pageIndexes.rejected + INVITATIONS_PER_PAGE)
  });

  return {
    invitations: getInvitationPages(),
    pagination: {
      hasNextPage,
      hasPrevPage,
      goToNextPage: handleGoToNextPage,
      goToPrevPage: handleGoToPrevPage,
    },
    loading: {
      pending: pendingInvitations.loading,
      rejected: rejectedInvitations.loading
    },
    fetchMoreLoading,
    refreshInvitations,
    refetchPendingInvitations: pendingInvitations.refetch
  };
};
