import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  EventList,
  EventHero,
  SearchAndFilter,
  withSettings,
  withClient
} from '../components';

import { stringInsert } from '../../libs';
import { usePaginatedEvents } from '../../hooks';

import { EVENT_SUBTYPES } from '../../queries';

import Html from 'html-to-react';
const htmlParser = new Html.Parser();


const EventListEventSubTypePage = ({ className, settings, client, archive, match: { params: { slug } } }) => {
  const { copy, theme: { assets: { images } } } = settings;

  const { data: subtypesData, loading: subtypesLoading } = useQuery(EVENT_SUBTYPES, { client, errorPolicy: 'all' });
  const subtypes = subtypesData && subtypesData.eventSubtypes;
  const subtype = subtypes
    ? subtypesData.eventSubtypes.find(st => st.slug === slug)
    : null;

  // use subtype name as page title if exists, otherwise format slug //
  const slugTitle = (subtypes && subtypes.reduce((title, st) => {
    if (st.slug === slug) return st.name;
    return title;
  }, '')) || slug.replace(/-/g, ' ');
  const archiveTitle = settings.theme.site.subtype_events_page_hero_alt || 'Archive: %1$s';
  const pageTitle = archive ? stringInsert(archiveTitle, slugTitle) : slugTitle;
  const altText = settings.theme.site.subtype_events_page_hero_alt ? settings.theme.site.subtype_events_page_hero_alt : pageTitle;
  const [filters, setFilters] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const variables = { scheduledAtRelativeToNow: archive ? 'PAST' : 'UPCOMING', ...filters };
  if (subtype) variables.eventSubtypeId = subtype.id;

  // console.log('variables', variables);

  const { loading: eventsLoading, /*error,*/ fetchMore, pagination } = usePaginatedEvents(client, { variables });
  const { events, pageInfo, totalEventsCount, hasMoreEvents, numberOfItems } = pagination;

  // if (error) console.error(error);

  const handleAddFilters = f => setFilters(f);

  const handleLoadMoreEvents = () => {
    setLoadingMore(true);

    return fetchMore({
      variables: { ...variables, numberOfItems, cursor: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEvents = fetchMoreResult.events;
        const prevEvents = previousResult.events;
        pageInfo.endCursor = newEvents.pageInfo.endCursor;

        return {
          events: {
            pageInfo,
            edges: [...prevEvents.edges, ...newEvents.edges],
            __typename: prevEvents.__typename,
          }
        };
      }
    }).then(() => setLoadingMore(false));
  };

  return (
    <div className={className}>
      <EventHero 
        id='tags_view_header' 
        title={pageTitle} 
        headerImg={images.event_subtypes_header || images.tag_default_header} 
        headerAltText={altText}
      />
      <SearchAndFilter addFiltersToQuery={handleAddFilters} subTypePage />
      {copy.landing.event_subtypes_list_description ? (
        <div className="area_banner__container">
          {htmlParser.parse(copy.landing.event_subtypes_list_description)}
          <i className="divider" />
        </div>
      ) : <i className="divider" />}
      <div>
        <EventList
          events={events}
          loading={subtypesLoading || eventsLoading}
          loadingMore={loadingMore}
          showArea={true}
          hasMoreEvents={hasMoreEvents}
          totalEventsCount={totalEventsCount}
          onLoadMore={() => handleLoadMoreEvents()}
          archive={archive}
        />
      </div>
    </div>
  );
};

const StyledEventListEventSubTypePage = styled(EventListEventSubTypePage)`
  & > .header {
    position: relative;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    padding: 170px 0;

    h1 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      margin: 0;
      text-transform: capitalize;
    }
  }
  & > p {
    max-width: 1100px;
    padding: 0 30px;
    text-align: center;
    margin: 60px auto 40px;
    font-size: 14px;

    a {
      color: ${props => props.settings.theme.css.global.colors.primary};
      transition: color 250ms;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primaryHover};
        text-decoration: none;
      }
    }
  }
  .no-items {
    display: flex;
    justify-content: center;
  }

  .divider {
    margin-top: 50px;
  }

  .area_banner__container {
    margin: 50px 0 40px;

    p {
      max-width: 1100px;
      padding: 0px 30px;
      text-align: center;
      margin: 0 auto 20px;
      font-size: 14px;
    }

    .divider {
      margin: 40px auto;
    }
  }
`;

const EventListEventSubTypePageWithRouter = withRouter(StyledEventListEventSubTypePage);
const EventListEventSubTypePageWithSettings = withSettings(EventListEventSubTypePageWithRouter);
const EventListEventSubTypePageWithClient = withClient(EventListEventSubTypePageWithSettings);

export { EventListEventSubTypePageWithClient as EventListEventSubTypePage };
