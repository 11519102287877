import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RsvpPaymentModalCardsManagementView, RsvpPaymentModalCardsManagementNew, RsvpPaymentModalCardsManagementModify } from './';

class RsvpPaymentModalCardsManagement extends React.Component {

  render() {
    const { className, selectedCreditCardId, creditCardSelected, creditCards, newCard, ccError, ccErrorObj  } = this.props;
    const { mode, changeButtonClicked,  setAddCardMode, setShowCardsMode, newCardInputChanged } = this.props;

    const selectedCard = (selectedCreditCardId) ? creditCards.find(creditCard => creditCard.id === selectedCreditCardId) : null;

    return (
      <div className={className}>
        {mode === 'show-cards' && (
          <RsvpPaymentModalCardsManagementView
            selectedCard={selectedCard}
            changeButtonClicked={changeButtonClicked}
            addCardButtonClicked={setAddCardMode}
          />
        )}
        {mode === 'modify-cards' && (
          <RsvpPaymentModalCardsManagementModify
            creditCards={creditCards}
            closeButtonClicked={setShowCardsMode}
            addCardButtonClicked={setAddCardMode}
            selectedCreditCardId={selectedCreditCardId}
            creditCardSelected={creditCardSelected}
          />
        )}
        {mode === 'add-card' && (
          <RsvpPaymentModalCardsManagementNew
            newCard={newCard}
            newCardInputChanged={newCardInputChanged}
            closeButtonClicked={setShowCardsMode}
            ccError={ccError}
            ccErrorObj={ccErrorObj}
          />
        )}
      </div>
    );
  }
}

RsvpPaymentModalCardsManagement.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCardsManagement = styled(RsvpPaymentModalCardsManagement)`
  text-align: left;

  & p {
    font-size: 14px;
    margin-bottom: 2px;
  }
`;

export { StyledRsvpPaymentModalCardsManagement as RsvpPaymentModalCardsManagement };
