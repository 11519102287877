const statusValidators = {
  requiresEmailChange: (email, mustChangeEmail) => {
    // return emailStatus === 'NEED_TO_CHANGE' || /@facebook\.com/i.test(email);
    return mustChangeEmail;
  },

  possibleEmailChange: (mustChangeEmail, newUser) => {
    return !mustChangeEmail && newUser;
  },

  noneStatus: emailStatus => {
    return emailStatus === 'NOT_SET';
  },

  requiresZip: zipCode => {
    return !zipCode;
  },

  requiresBio: bio => {
    return typeof bio !== 'string' || !bio.trim().length;
  }
};

const {
  requiresEmailChange,
  possibleEmailChange,
  noneStatus,
  requiresZip,
  requiresBio
} = statusValidators;

export default {
  statusValidators,
  requiresEmailChange,
  possibleEmailChange,
  noneStatus,
  requiresZip,
  requiresBio
};
