import React from 'react';
import styled from 'styled-components';

import {
  HostAvatar,
  EventCardNotifications,
  withSettings
} from '../../components';

const EventCardHeader = ({ className, event, viewer, viewerReservations, settings }) => {
  const images = settings.theme.assets.images;
  const bgImageUrl = event.cover
    ? (event.cover.mediumUrl ? event.cover.mediumUrl : event.cover.originalUrl)
    : images.default_cover;

  return (
    <div
      className={[className, 'ot_event_card_header'].join(' ')}
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <EventCardNotifications
        event={event}
        viewer={viewer}
        viewerReservations={viewerReservations}
      />
      <HostAvatar event={event} />
    </div>
  );
};

const StyledEventCardHeader = styled(EventCardHeader)`
  position: relative;
  z-index: 1;
  border-radius: 5px 5px 0 0;
  height: 170px;
  padding: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const EventCardHeaderWithSettings = withSettings(StyledEventCardHeader);

export { EventCardHeaderWithSettings as EventCardHeader };
