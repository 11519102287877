export const getDiscountPrice = (price, coupon) => {
  if (!price || !coupon) return 0;

  const { discountType, amount } = coupon;
  const discountAmount = amount * 100;

  switch (discountType) {
    case 'DOLLAR':
      if (discountAmount > price) return price;
      return discountAmount;
    case 'PERCENTAGE':
      return price * amount / 100;
    default:
      return 0;
  }
};

export const getTransactionFee = ({ area, hostAbsorbsTransactionFee }, totalPriceWithoutTransactionFee) => {
  if (
    totalPriceWithoutTransactionFee <= 0 ||
    hostAbsorbsTransactionFee ||
    !area ||
    !area.transactionFeeAmount
  ) return 0;
  return area.transactionFeeAmount / 100;
};

export const formatClaimedPotluckItemsForSubmit = items => {
  const itemIds = [];
  for (const i in items) {
    if (items[i]) itemIds.push(items[i].id);
  }
  return itemIds;
}

export const formatAnswersForSubmit = answers => {
  const formattedAnsers = [];
  for (const i in answers) {
    formattedAnsers.push({
      questionId: parseInt(i),
      response: answers[i]
    });
  }
  return formattedAnsers;
};
