export const isHostById = (matched, edges, uuid = true) => {
  if (Array.isArray(edges)) {
    return !!edges.filter(edge => edge.node && edge.node[uuid ? 'uuid' : 'id'] === matched).length;
  }
  return false;
};

export const isRegisteredById = (matched, nodes, uuid = true) => {
  if (Array.isArray(nodes)) {
    return !!nodes
      .filter(node => node.state === 'ACCEPTED')
      // pammie recieving weird error where node.event seems to be null //
      .filter(node => node && node.event)
      .filter(node => node.event[uuid ? 'uuid' : 'id'] === matched).length;
  }
  return false;
}
