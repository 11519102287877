import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Loader } from '../components';

import L from 'leaflet';
import 'leaflet-providers';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { SCROLLED_NAV_HEIGHT, NOT_SCROLLED_NAV_HEIGHT } from '../../styles/index';
import { SCROLLED_HEADER_HEIGHT, NOT_SCROLLED_HEADER_HEIGHT, SCROLLED_HEADER_OFFSET } from './EventSearchHeader';

import { mapData } from './mapData';

const markerIcon = L.icon({
  iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41]
});

const MapSetView = ({ loaded, areaCode: pageAreaCode }) => {
  const [areaCode, setAreaCode] = useState(pageAreaCode);
  const map = useMap();

  useEffect(() => {
    const area = mapData.find(a => a.area === pageAreaCode);

    // reset map center coordinates when areaCode changes //
    if (loaded && area && areaCode !== pageAreaCode) {
      map.setView({ lat: area.lat, lng: area.lng }, map.getZoom());
      setAreaCode(pageAreaCode);
    }
  }, [areaCode, pageAreaCode, loaded]);

  return null;
};


const EventSearchMap = ({ className, areaCode, scrolled, setMapEl, refs }) => {
  const mapRef = useCallback(node => node !== null && setMapEl(node), [setMapEl]); // ref={mapRef}
  const mapLoaded = useRef(false);
  const [map, setMap] = useState(null);
  const [dinners, setDinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [top, setTop] = useState(0);

  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
    zoom: 0
  });

  const getOffset = () => scrolled
    ? SCROLLED_NAV_HEIGHT + SCROLLED_HEADER_HEIGHT - SCROLLED_HEADER_OFFSET
    : NOT_SCROLLED_NAV_HEIGHT + NOT_SCROLLED_HEADER_HEIGHT;

  useEffect(() => {
    if (map) {
      L.tileLayer.provider('CartoDB.Voyager').addTo(map);

      setTimeout(() => {
        map.invalidateSize(true);

        setTimeout(() => {
          setLoading(false);
        }, 300);
      }, 10);
    }
  }, [map, mapData, areaCode]);

  useEffect(() => {
    const area = mapData.find(a => a.area === areaCode);
    if (!area) return;

    if (!mapLoaded.current) {
      // initial position used for instantiating the map //
      setPosition({
        lat: area.lat,
        lng: area.lng,
        zoom: area.zoom
      });
      mapLoaded.current = true;
    }

    setDinners(area.dinners);
  }, [mapData, setPosition, areaCode, mapLoaded]);

  const handleSetMapPosition = useCallback(() => {
    if (!refs.listRef || !refs.mapRef) return;

    // alternate between fixed and absolute positioning when scrolled to bottom of page //
    if (refs.listRef.getBoundingClientRect().bottom <= window.innerHeight) {
      Object.assign(refs.mapRef.style, {
        position: 'absolute',
        height: `${window.innerHeight - getOffset()}px`,
        top: ''
      });
    } else {
      Object.assign(refs.mapRef.style, {
        position: 'fixed',
        height: '',
        top: `${getOffset()}px`
      });
    }
  }, [refs]);

  useEffect(() => {
    handleSetMapPosition();

    if (scrolled) setTop(getOffset());
    else setTop(getOffset());

    window.addEventListener('scroll', handleSetMapPosition);
    window.addEventListener('resize', handleSetMapPosition);
    return () => {
      window.removeEventListener('scroll', handleSetMapPosition);
      window.removeEventListener('resize', handleSetMapPosition);
    }
  }, [scrolled]);

  const { lat, lng, zoom } = position;

  if (!lat) return <Loader />;
  else return (
    <>
      {loading && <Loader />}
      <div
        ref={mapRef}
        className={className}
        style={{ top: `${top}px` }}
      >
        <MapContainer
          center={[lat, lng]}
          zoom={zoom}
          zoomControl={false}
          scrollWheelZoom={false}
          whenCreated={mapInstance => setMap(mapInstance)}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          />
          {dinners.length && dinners.map(d => (
            <Marker key={d.id} position={[d.lat, d.lng]} icon={markerIcon}>
              <Popup>
                {d.name}
              </Popup>
            </Marker>
          ))}
          <ZoomControl position="topright" />
          <MapSetView loaded={mapLoaded.current} areaCode={areaCode} />
        </MapContainer>
      </div>
    </>
  );
};


const StyledEventSearchMap = styled(EventSearchMap)`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 35vw;
  background-color: #ddd;
  border-left: 1px solid #ddd;
  transition: top 200ms;

  .leaflet-container {
    width: 100%;
    height: 100%;

    .leaflet-top, .leaflet-bottom {
      z-index: 800;
    }
  }
`;
export { StyledEventSearchMap as EventSearchMap };
