import React, { useContext, useRef, useEffect, useState, forwardRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { media, isJourneyMobile as isMobile } from '../../styles';
import {
  JourneyContext,
  JourneyNode,
  JourneyPageField,
  JourneyExperience,
  JourneyResourcesPage,
  JourneyLanding,
  JourneyMadlibLanding,
  UserJourneyProgress,
  JourneyPageIndicators,
  withSettings
} from '../components';
import { useTemplateState } from '../../hooks';
import { getNextAction, getRandom, getAnsweredQuestion, stripLeadingSlash } from '../../libs';
import { SessionContext } from '../../Context';
import { TweenMax, Power3 } from 'gsap';

import * as userJourney from '../../libs/userJourney';

import ROUTES from '../../constants/routes';


const UserJourneyPage = props => {
  const {
    className,
    data,
    questionIds,
    animating,
    handleCreationRedirect,
    settings,
    progress: { startJourney, goToNextPage, goToPrevPage  },
    titles: { pagesTitles, updatePageTitles },
    chosen: { choices, setChoices },
    currentTitleIndex,
    forwardedRef: ref,
    analytics: { fromMadlib, fromCreation }
  } = props;
  const { nodes, widgets, backgroundImages } = data;
  const { theme: { site: { all_events_page_url } } } = settings;
  const allEventsUrl = stripLeadingSlash(all_events_page_url) || ROUTES.ALL;

  // const [bgImages, setBgImages] = useState([]);
  const [bgImage, setBgImage] = useState('');
  const [randomBgImage, setRandomBgImage] = useState('');
  const [hasBgCarousel, setHasBgCarousel] = useState(false);
  // const [playlistUrl, setPlaylistUrl] = useState(null);
  const [hasAnswered, setHasAnswered] = useState(0); // chosen answer (hover for desktop, first click for mobile) //
  const [hasConfirmed, setHasConfirmed] = useState(0); // confirmed answer (click for next page) //
  const renderTemplate = data.renderTemplate ? data.renderTemplate.toLowerCase() : false;
  const pageAction = data.action ? data.action.toLowerCase() : false;
  // const name = userJourney.getItem('name');
  const city = userJourney.getItem('city');
  const journeyAction = userJourney.getItem('action');

  const hasSetDefaultImage = useRef(false);
  const containerRef = useRef(null);
  const quoteContainerRef = useRef(null);

  const startBgCycle = useCallback(() => {
    // carousel depricated for now in favor of random images //
    const randomImage = getRandom(backgroundImages);

    if (randomImage) {
      setHasBgCarousel(true);
      setRandomBgImage(randomImage.url);
    }
  }, [backgroundImages, setHasBgCarousel, setRandomBgImage]);

  useEffect(() => {
    if (backgroundImages && backgroundImages.length && !hasSetDefaultImage.current) {
      // setBgImages(backgroundImages);
      setBgImage(backgroundImages[0].url);

      if (backgroundImages.length > 1) startBgCycle();

      hasSetDefaultImage.current = true;
    }
  }, [backgroundImages, startBgCycle]);

  useEffect(() => {
    TweenMax.to(containerRef.current, .6, {
      opacity: 1,
      ease: Power3.easeIn,
    });
  }, [containerRef]);

  useEffect(() => {
    const quoteContainer = document.querySelector('.quote_container');
    if (quoteContainer) quoteContainerRef.current = quoteContainer;
  }, [quoteContainerRef]);


  // used for hover options //
  // const bgFadeOverlay = useRef(false);
  // const [transitioning, setTransitioning] = useState(false);
  // useEffect(() => {
  //   if (transitioning && bgFadeOverlay.current) {
  //     TweenMax.to(bgFadeOverlay.current, 0, { opacity: .2 });
  //
  //     TweenMax.to(bgFadeOverlay.current, .3, {
  //       opacity: 0,
  //       ease: Power3.easeIn,
  //     });
  //   }
  // }, [transitioning, bgFadeOverlay]);

  const { session: { viewer: { user } } } = useContext(SessionContext);

  const { journeyState, answerState } = useContext(JourneyContext);
  const { answers, saveAnswer, removeAnswer, getResources, removeResources } = answerState;

  const { questionsState, updateQuestionsState, shouldBeShown } = useTemplateState(widgets, saveAnswer);


  const onSelectQuestion = (id, value, label, prefill, multiple) => {
    const isImmersive = renderTemplate === 'immersive';
    const delay = isImmersive ? 250 : 0;
    const nextAction = getNextAction(value, widgets);

    if (isImmersive) {
      TweenMax.to(containerRef.current, delay / 1000, {
        opacity: 0,
        ease: Power3.easeIn,
      });

      TweenMax.to(containerRef.current, delay / 1000, {
        opacity: 1,
        delay: delay / 1000,
        ease: Power3.easeIn,
      });
    }

    setTimeout(() => {
      updateQuestionsState(id, value, label, multiple);
      saveAnswer(id, value, label, prefill, multiple, questionIds);
      setHasAnswered(1);
      nextAction && nextAction.backgroundImage && setBgImage(nextAction.backgroundImage.url);
      // nextAction && nextAction.songUrl && setPlaylistUrl(nextAction.songUrl);
    }, delay);
  };

  const onHoverQuestion = (id, value, label, prefill, multiple) => {
    const nextAction = getNextAction(value, widgets);

    // console.log('animating', animating);

    if (animating /* || (!isMobile() && hasAnswered)*/) return;

    setHasAnswered(1);
    // setTransitioning(true);
    updateQuestionsState(id, value, label, multiple);
    saveAnswer(id, value, label, prefill, multiple, questionIds);

    if (nextAction) {
      nextAction.backgroundImage && setBgImage(nextAction.backgroundImage.url);

      // save choices for resource page //
      switch (pageAction) {
        case 'music':
          nextAction.songUrl &&
            setChoices(cs => ({ ...cs, playlistUrl: nextAction.songUrl }));
          break;
        case 'image':
          nextAction.backgroundImage &&
            setChoices(cs => ({ ...cs, bgImage: nextAction.backgroundImage.url }));
          break;
        case 'quote':
          nextAction.quote &&
            setChoices(cs => ({ ...cs, quote: nextAction.quote }));
          break;
        default:
      }
    }
    // setTimeout(() => setTransitioning(false), 300);
  };

  const handleSelectQuestion = (id, value, label, prefill, multiple, hover) => {
    if (hover) onHoverQuestion(id, value, label, prefill, multiple);
    else onSelectQuestion(id, value, label, prefill, multiple);
  };

  const handleGoToNextPage = label => {
    const currentAnswer = answers[answers.length - 1];
    const currentResults = journeyState.currentConditions;
    let currentResult = null;

    if (!isMobile() && !hasAnswered) return;

    setHasConfirmed(true);

    currentResults.forEach(results => {
      const { conditions } = results;

      conditions.forEach(condition => {
        const { questionId, answers: resultAnswers } = condition;
        if (questionId === currentAnswer.id && resultAnswers.includes(currentAnswer.value)) {
          currentResult = results;
        }
      });
    });

    if (currentResult) {
      const { currentPage } = journeyState;
      const pageName = currentPage && currentPage.name;
      const question = getAnsweredQuestion(currentPage, currentAnswer.id, currentAnswer.value);

      pageName && question && window.dataLayer && window.dataLayer.push({
        event: 'uj_select_answer',
        uj_page: pageName,
        uj_question: question.name,
        uj_answer: question.answer,
        uj_answer_value: currentAnswer.value,
        uj_action: fromMadlib ? 'from_madlib' : (fromCreation ? 'from_creation' : 'from_landing'),
        uj_madlib_action: fromMadlib ? journeyAction : ''
      });

      goToNextPage(currentResult);
    }
    if (label) updatePageTitles(label, currentTitleIndex);
  };

  const handleGoToPrevPage = () => {
    const currentAnswer = answers[answers.length - 1];
    goToPrevPage();

    if (hasAnswered) {
      removeAnswer(currentAnswer.id);
      removeResources();
    }
  };

  const handleJourneyRedirect = () => {
    const origin = window.location.origin;
    const area = city || (
      user && user.profile && user.profile.area ? user.profile.area.url : false
    );
    const redirectUrl = area ? `${origin}/${ROUTES.LANDING}/${area}` : `${origin}/${allEventsUrl}`;

    if (!journeyAction || journeyAction === 'landing') window.open(redirectUrl, '_blank');
  };

  const getSpanClass = text => {
    const firstChar = String(text).trim()[0];
    switch (firstChar) {
      case '.':
        return 'period';
      case ',':
        return 'comma';
      default:
        return null;
    }
  };

  const renderNode = (node, i) => {
    // get questions embedded in this node //
    const questions = node.questions.map(id => widgets.find(w => w.question.id === id));
    const nodeData = [];

    const isConditional = node.type === 'div' && node.conditional !== false;

    // insert questions into embed position //
    node.text.forEach((text, j) => {
      nodeData.push({ type: 'text', text });

      if (typeof questions[j] !== 'undefined') {
        nodeData.push({ type: 'question', widget: questions[j] });
      }
    });

    if (isConditional && shouldBeShown(node)) {
      return node.conditional.nodes.map((n, j) => renderNode(n, j));
    }

    // console.log(journeyState.journeyResources);

    return (
      <JourneyNode type={node.type} key={i} className={node.className}>
        {nodeData.map((n, k) => {
          let questionId = null;
          let questionState = null;

          switch (n.type) {
            case 'text':
              return (
                <span
                  key={k}
                  className={getSpanClass(n.text)}
                  dangerouslySetInnerHTML={{ __html: n.text }}
                />
              );
            case 'question':
              questionId = n.widget.question.id;
              questionState = questionsState.find(q => q.id === questionId);

              // console.log('hasAnswered', hasAnswered);
              // console.log('hasConfirmed', hasConfirmed);

              return (
                <JourneyPageField
                  key={k}
                  updateQuestionsState={handleSelectQuestion}
                  handleGoToNextPage={handleGoToNextPage}
                  widget={n.widget}
                  value={questionState ? questionState.value : null}
                  label={questionState ? questionState.label : null}
                  multiple={questionState ? questionState.multiple : false}
                  answer={answers.find(a => a.id === questionId)}
                  data={{
                    playlistUrl: pageAction === 'music' && choices && choices.playlistUrl,
                    quoteText: pageAction === 'quote' && choices && choices.quote
                  }}
                  pageAction={pageAction}
                  hasAnswered={isMobile() || hasAnswered}
                  hasConfirmed={hasConfirmed}
                />
              );
            default:
              return null;
          }
        })}
      </JourneyNode>
    );
  };


  const QuoteContainer = () => {
    if (quoteContainerRef.current && choices && choices.quote) {
      return ReactDOM.createPortal(
        <span dangerouslySetInnerHTML={{ __html: choices.quote }} />,
        quoteContainerRef.current
      );
    }
    return null;
  }

  // const isNotFirstPage = () => journeyState.currentPage && journeyState.currentPage.id !== journeyState.initialPageId;

  let RenderTemplate; // renderTemplate === 'madlib' ? null : JourneyExperience;

  switch (renderTemplate) {
    case 'immersive':
      RenderTemplate = JourneyExperience;
      break;
    case 'resources':
      RenderTemplate = JourneyResourcesPage;
      break;
    case 'landing':
    case 'non-demo':
      RenderTemplate = JourneyLanding;
      break;
    case 'madlib-landing':
      RenderTemplate = JourneyMadlibLanding;
      break;
    default:
      RenderTemplate = null;
  }

  const pageBgImage = hasBgCarousel && randomBgImage && (!choices || !choices.bgImage)
    ? randomBgImage
    : (choices && choices.bgImage) || bgImage;

  return (
    <div
      className={[className, renderTemplate === 'resources' ? 'resources' : ''].join(' ').trim()}
      style={{ backgroundImage: `url(${pageBgImage})` }}
      ref={containerRef}
    >
      {renderTemplate === 'immersive' && (
        <JourneyPageIndicators step={currentTitleIndex < 1 ? 'hover' : 'music'} />
      )}
      <QuoteContainer />
      {renderTemplate === 'resources' ? (
        <RenderTemplate
          resources={getResources()}
          journeyAction={journeyAction}
          handleRedirect={handleJourneyRedirect}
          handleCreationRedirect={handleCreationRedirect}
          choices={choices}
          bgImage={pageBgImage}
        >
          <div>
            {nodes.map((node, i) => renderNode(node, i))}
          </div>
        </RenderTemplate>

      ) : (
        <RenderTemplate
          nodes={nodes}
          startJourney={startJourney}
          journeyState={journeyState}
          shouldBeShown={shouldBeShown}
          answered={hasAnswered}
        >
          <div ref={ref}>
            {nodes.map((node, i) => renderNode(node, i))}
          </div>
        </RenderTemplate>
      )}

      {(renderTemplate === 'immersive' || renderTemplate === 'madlib-landing') && (
        <div className="progress_container">
          {currentTitleIndex > 0 && (
            <ProgressButton
              className="left"
              onClick={() => handleGoToPrevPage()}
            >Back</ProgressButton>
          )}
          <UserJourneyProgress
            pagesTitles={pagesTitles}
            currentTitleIndex={currentTitleIndex}
          />
          <ProgressButton
            className="right"
            onClick={() => hasAnswered && handleGoToNextPage()}
            disabled={!hasAnswered}
          >Next</ProgressButton>
      </div>
      )}
    </div>
  );
};

const StyledUserJourneyPage = styled(UserJourneyPage)`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 100px 0 80px;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;

  ${media.sm`
    padding: 100px 0 60px;
  `}

  &.resources {
    padding: 0;
    background: #0d0d0d !important;
  }

  ${media.sm`
    // padding: 100px 0 40px;
  `}

  .bg_fade_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0d0d0d;
    z-index: 2;
    opacity: 0;
  }

  .prev_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;

    &.transition {
      z-index: 2;
    }

    &:not(.transition) {
      z-index: -1;
    }
  }

  .playlist {
    display: block;
    // margin: 60px auto 0;

    ${media.sm`
      margin: 0 auto 40px;
    `}
  }

  .progress_container {
    ${media.journeyMd`
      margin: 100px auto 0;
      position: relative;
    `}
  }
`;
const UserJourneyPageWithSettings = withSettings(StyledUserJourneyPage);
const UserJourneyPageWithRefComponent = (props, ref) => <UserJourneyPageWithSettings {...props} forwardedRef={ref} />;
const UserJourneyPageWithRef = forwardRef(UserJourneyPageWithRefComponent);
export { UserJourneyPageWithRef as UserJourneyPage };

const ProgressButton = styled.span`
  position: absolute;
  bottom: 45px;
  color: #fff;
  cursor: pointer;
  display: none;

  ${media.journeyMd`
    display: block;
    bottom: -16px;
  `}

  ${media.sm`
    // bottom: 25px;
  `}

  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  ${props => props.className === 'left' ? (
    css`
      left: 50px;

      ${media.sm`
        left: 25px;
      `}
  `) : (
    css`
      right: 60px;

      ${media.sm`
        right: 25px;
      `}
  `)}
`;

///////////////////////


///////////////////////


// const UserNameContainer = styled.div`
//   position: absolute;
//   left: 50px;
//   bottom: 50px;
//
//   ${media.sm`
//     left: 20px;
//     bottom: 20px;
//   `}
// `;

// const ProgressButton = styled.button`
//   border: 1px solid #fff;
//   border-radius: 50%;
//   display: inline-block;
//   vertical-align: middle;
//   background-color: transparent;
//   text-transform: uppercase;
//   color: #fff;
//   letter-spacing: 0.1em;
//   cursor: pointer;
//   transition: background-color 250ms, opacity 150ms;
//   z-index: 5;
//
//   &:hover {
//     background-color: #ffffff20;
//   }
//
//   &.back {
//     width: 70px;
//     height: 70px;
//     font-size: 11px;
//
//     ${media.sm`
//       width: 60px;
//       height: 60px;
//     `}
//   }
//
//   &.next {
//     width: 80px;
//     height: 80px;
//     font-size: 13px;
//     position: absolute;
//     right: 80px;
//     bottom: 50%;
//     margin-top: 40px;
//
//     ${media.md`
//       width: 70px;
//       height: 70px;
//       font-size: 11px;
//       right: 50px;
//       bottom: 50px;
//       margin-top: 0;
//     `}
//
//     ${media.sm`
//       width: 60px;
//       height: 60px;
//       right: 20px;
//       bottom: 20px;
//     `}
//   }
//
//   ${props => props.disabled && css`
//     opacity: 0.6;
//     cursor: default;
//
//     &:hover {
//       background-color: transparent;
//     }
//   `}
// `;

// const UserName = styled.span`
//   display: inline-block;
//   vertical-align: middle;
//   color: #fff;
//   font-size: 18px;
//   margin-left: 30px;
//   letter-spacing: 0.01em;
// `;
