import React from 'react';
import styled from 'styled-components';
import { withSettings, withSession } from '../components';

import {
  AnchorLink,
  Button,
  RsvpButtonDumb,
  RsvpButton
} from '../components';

import { formatPrice } from '../../libs';

import { EVENT_STATE } from '../../enums';


class EventViewSubNav extends React.Component {
  state = { showSubNav: false,  /*, throttle: false*/ };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // const { throttle } = this.state;
    const scrollPos = window.scrollY;

    // Not worrying about throttling for now //
    if (/*!throttle && */ scrollPos > 420) {
      // this.setState({ showSubNav: true, throttle: true }, () => {
      //   setTimeout(() => this.setState({ throttle: false }), 200);
      // });
      this.setState({ showSubNav: true });
    } else if (/*!throttle && */ scrollPos <= 420) {
      // this.setState({ showSubNav: false, throttle: true }, () => {
      //   setTimeout(() => this.setState({ throttle: false }), 200);
      // });
      this.setState({ showSubNav: false });
    }
  };

  restrictedRsvpButton = (onClick) => {
    return (
      <div>
        <Button
          className="rsvp_button"
          handleClick={onClick}
          fullWidth
        >RSVP</Button>
      </div>
    );
  }

  render() {
    const { showSubNav } = this.state;
    const {
      className,
      event,
      displayDetails,
      displayTables,
      displayMenu,
      restriction,
      showRsvpModal,
      showCancelReservationModal,
      showInviteFriendsModal,
      session,
      toggleLoginModal
    } = this.props;
    const { openDeclineInvitationModal } = this.props;
    const hasRestriction = restriction.content !== null;
    const viewer = session && session.viewer;

    return (
      <div className={`${className} ${showSubNav ? 'show' : ''}`}>
        <div>
          <h2>{event.title}</h2>
          <ul>
            {displayDetails && <li><AnchorLink href="#details" offset="180">Details</AnchorLink></li>}
            {displayTables && <li><AnchorLink href="#tables" offset="180">Tables</AnchorLink></li>}
            {displayMenu && <li><AnchorLink href="#menu" offset="180">Menu</AnchorLink></li>}
            <li><AnchorLink href="#host" offset="180">Host</AnchorLink></li>
          </ul>

          <div className="rsvp_container">
            <div className="event_price">
              {event.pricePerPerson
                ? <span>${formatPrice(event.pricePerPerson)}</span>
                : <span>Free</span>
              }
            </div>

            <div className="rsvp_button_container">
              {event.canSubscribe && event.state === EVENT_STATE.DRAFT && (
                <RsvpButtonDumb
                  buttonStyle="primary"
                  mainButtonName="DRAFT"
                  disabled
                />
              )}

              {event.canSubscribe && event.state === EVENT_STATE.PENDING_PUBLISH_APPROVAL && (
                <RsvpButtonDumb
                  buttonStyle="primary"
                  mainButtonName="PENDING APPROVAL"
                  disabled
                />
              )}

              {event.canSubscribe && event.state === EVENT_STATE.PUBLISHED && (
                viewer && viewer.user
                  ? hasRestriction
                    ? this.restrictedRsvpButton(restriction.showRestriction)
                    : (
                      <RsvpButton
                        className="rsvp_button"
                        event={event}
                        viewer={session && session.viewer ? session.viewer : null}
                        openRsvpModal={showRsvpModal}
                        openDeclineInvitationModal={openDeclineInvitationModal}
                        handleCancelReservation={showCancelReservationModal}
                        handleInviteFriends={showInviteFriendsModal}
                      />
                    )
                  : this.restrictedRsvpButton(toggleLoginModal)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const StyledEventViewSubNav = styled(EventViewSubNav)`
  position: fixed;
  top: ${({ session: { layout } }) => layout.removeHeaderFooter ? '-85px' : '0'};
  left: 0;
  right: 0;
  padding: 25px 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.04), 0 2px 10px rgba(0,0,0,0.05);
  z-index: 900;
  transition: top 300ms;

  & .rsvp_button_container {
    min-width: 200px;
  }

  &.show {
    top: ${({ session: { layout } }) => layout.removeHeaderFooter ? '0' : '85px'};
  }

  & > div {
    max-width: 1232px;
    margin: auto;
    padding: 0 20px;
    position: relative;

    & > h2 {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 30px 0 0;

      @media (max-width: 1100px) {
        margin-right: 20px;
      }
    }

    & > ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0 10px;

        a {
          display: block;
          position: relative;
          transform: translateY(-2px);
          color: ${props => props.settings.theme.css.global.colors.primaryDark};
          font-size: 14px;
          transition: color 250ms;

          &:hover {
            color: ${props => props.settings.theme.css.global.colors.primary};
          }
        }
      }
    }

    & > .rsvp_container {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;

      .event_price {
        display: inline-block;
        vertical-align: middle;
        margin-right: 40px;
        font-size: 16px;

        @media (max-width: 1100px) {
          margin-right: 20px;
        }

        span {
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      .rsvp_button {
        display: inline-block;
        vertical-align: middle;
        width: 230px;
        margin: 0 !important;
      }
    }
  }

  @media (max-width: 970px) {
    display: none;
  }
`;

const StyledEventViewSubNavWithSettings = withSettings(StyledEventViewSubNav);
const EventViewSubNavWithSession = withSession(StyledEventViewSubNavWithSettings);
export { EventViewSubNavWithSession as EventViewSubNav };
