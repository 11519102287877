import React, { useContext } from 'react';
import styled from 'styled-components';

import { LandingPageContext, FAIcon, withSettings } from '../components';
import { stringInsert } from '../../libs';


const LandingHeroCount = ({ className, settings: { copy } }) => {
  const { area } = useContext(LandingPageContext);
  const count = 246;

  return (
    <div className={className}>
      <FAIcon name={[ 'fas', 'map-marker-alt' ]} />
      <span>
        {stringInsert(copy.landing.event_count, [
            count === 1 ? 'has' : 'have',
            count,
            count === 1 ? '' : 's',
            area ? `in ${area.label} ` : ''
          ]
        )}
      </span>
    </div>
  );
};


const StyledLandingHeroCount = styled(LandingHeroCount)`
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  z-index: 2;
  color: #fff;

  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    bottom: 20px;
  }

  span {
    font-family: 'Avenir-Regular' !important;
    color: #fff;
    font-size: 13px;
  }

  svg {
    margin-right: 8px;
    font-size: 12.5px;
  }
`;
const LandingHeroCountWithSettings = withSettings(StyledLandingHeroCount);
export { LandingHeroCountWithSettings as LandingHeroCount };
