import React from 'react';
import styled, { css } from 'styled-components';

import { PageContentWrapper } from '../components';

export const LandingPageContainer = ({ children, top, bottom }) => (
  <Container top={top} bottom={bottom}>
    <PageContentWrapper>
      {children}
    </PageContentWrapper>
  </Container>
);

const Container = styled.section`
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* background-color: #fff; */

  ${props => props.top && (
    css`
      padding-top: 5rem;
    `
  )}

  ${props => props.bottom && (
    css`
      padding-bottom: 6rem;
    `
  )}

  @media (max-width: 650px) {
    ${props => props.top && (
      css`
        padding-top: 4rem !important;
      `
    )}

    ${props => props.bottom && (
      css`
        padding-bottom: 6rem !important;
      `
    )}
  }
`;
