import React from 'react';
import styled from 'styled-components';

import { Button } from '../';

const SwitchButtons = ({ className, selectedOption, handleSwitchChange, capitalize, name }) => {
  const handleSelectTab = value => handleSwitchChange(name, value);

  const buttons = [{ name: 'Yes', value: true }, { name: 'No', value: false }];

  return (
    <div className={className}>
      {buttons.map(button => (
        <Button
          key={button.value}
          buttonStyle={button.value === selectedOption ? 'primary' : 'secondary'}
          handleClick={() => handleSelectTab(button.value)}
          capitalize={capitalize}
        >{button.name}</Button>
      ))}
    </div>
  );
};

const StyledSwitchButtons = styled(SwitchButtons)`
  & > button {
    border-radius: 0;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
`;

export { StyledSwitchButtons as SwitchButtons };
