import React from 'react';
import styled from 'styled-components';

import {
  Icon,
  AddItemInput,
  withSettings
} from '../../components';

import { breakpoints } from '../../../styles';

const courses = [
  { label: 'first_course', icon: 'APPETIZERS', data: 'first' },
  { label: 'second_course', icon: 'MAIN_COURSE', data: 'second' },
  { label: 'dessert', icon: 'DESSERTS', data: 'third' }
];


const EventCreationProvidingSelector = (props) => {
  const { className, eventData, onInputChange } = props;
  const { settings: { copy } } = props;

  return (
    <div className={className}>
      <p className="description">{copy.event.menu_desc_1} <strong>{copy.event.menu_desc_2}</strong></p>
      <div className="course__container">
        {courses.map(course => (
          <div className="course" key={course.label}>
            <Icon name={course.icon} />
            <h4>{copy.event[`menu_${course.label}`]}</h4>
            <i className="divider" />
            <AddItemInput
              itemList={eventData[`notes_${course.data}`]}
              placeholder={copy.event[`menu_${course.label}_placeholder`]}
              onAddItem={value => onInputChange(`notes_${course.data}`, value)}
              showQuantities={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}


const StyledEventCreationProvidingSelector = styled(EventCreationProvidingSelector)`
  & > .description {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .course {
    float: left;
    width: 33.3333%;
    padding: 0 15px;

    @media (${breakpoints.tablet}) {
      float: none;
      width: 100%;
      padding: 0 0 20px;

      &:last-of-type {
        padding: 0;
      }

      .input_container {
        max-width: 300px;
      }
    }

    &__container {
      margin-top: 30px;

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .icon {
      width: 100px;
      display: block;
      margin: 0 auto 5px;
    }

    h4 {
      text-align: center;
      font-weight: normal !important;
      font-size: 18px;
      margin: 0;
      color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }
`;

const StyledEventCreationProvidingSelectorWithSettings = withSettings(StyledEventCreationProvidingSelector);

export { StyledEventCreationProvidingSelectorWithSettings as EventCreationProvidingSelector };
