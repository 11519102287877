import React, { useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import {
  Card,
  Icon,
  EventCreationResource,
  EventCreationSuccessModal,
  alert,
  Button,
  Loader,
  withClient
} from '../components';
import { SessionContext } from '../../Context';
import {
  EVENT_CREATION_RESOURCES,
  USER_JOURNEY_RESOURCES,
  UJ_BACKGROUNDS,
  UJ_RESPONSE_BY_CLAIM_TOKEN
} from '../../queries';
import { setCardHeights, getEventIdFromPath } from '../../libs';
import { withSettings } from '../components';
import { imEventHost } from '../../libs';
import * as userJourney from '../../libs/userJourney';

import ReactPlayer from 'react-player';

const EventCreationResourcesPage = (props) => {
  const { client, className, computedMatch, location, settings  } = props;
  const { copy, theme: { load_on_user_journey: showJourneyResources } } = settings;

  const [state, setState] = useState({ showSuccessModal: false, claimedResources: [] });


  const loadResourcesFromClaims = claimToken => {
    return client.query({
      query: UJ_RESPONSE_BY_CLAIM_TOKEN,
      variables: { claimToken }
    });
  };

  const getResourcesFromClaims = claimData => {
    const response = claimData && claimData.data && claimData.data.journeyResponseByClaimToken;
    const claimedResources = [];

    if (response && response.resources) {

      response.resources.edges.forEach(r => {
        const { node: resource } = r;
        if (resource) claimedResources.push(resource);
      });
    }

    return claimedResources;
  };

  useEffect(() => {
    const isAfterCreate = location.state && location.state.isAfterCreate;
    const isSoloEvent = location.state && location.state.isSoloEvent;

    const showUserJourneyResources = userJourney.getItem('showJourneyResources');
    const resourceToken = userJourney.getItem('resourceToken');

    if (isAfterCreate && !isSoloEvent) {
      setState({ showSuccessModal: true });
    }

    if (showUserJourneyResources && resourceToken) {
      loadResourcesFromClaims(resourceToken)
        .then(data => {
          const claimedResources = data ? getResourcesFromClaims(data) : [];
          if (claimedResources.length) setState({ claimedResources });
        });
    }
  }, []);


  const renderResources = (resources, showNewDesign) => {
    if (!resources) return 'Loading...';

    setTimeout(() => setCardHeights('.resource_card .resource__details'), 10);

    return (
      resources.map((resource, i) => (
        <EventCreationResource resource={resource} key={i} newDesign={showNewDesign}/>
      ))
    );
  };

  const toggleSuccessModal = () => {
    setState(prevState => ({
      showSuccessModal: !prevState.showSuccessModal
    }));
  };

  const handleInviteFriendsSuccess = () => {
    alert.success('Your friends have been successfully invited.');
    toggleSuccessModal();
  }; 

  let uuid = '';
  if (computedMatch && computedMatch.params && computedMatch.params.uuid) {
    uuid = computedMatch.params.uuid;
  } else {
    uuid = getEventIdFromPath(location.pathname);
  }

  return (
    <SessionContext.Consumer>
      {({ session }) => (
        <Query query={USER_JOURNEY_RESOURCES}>
          {({ data: userJourneyResourcesData, error: userJourneyResourcesError }) => (
            <Query query={EVENT_CREATION_RESOURCES} variables={{ uuid }}>
              {({ data: eventCreationResourcesData, error: eventCreationResourcesError }) => {
                if (eventCreationResourcesError) {
                  console.error('EVENT_CREATION_RESOURCES: ', eventCreationResourcesError);
                  return <Redirect to="/"/>;
                }

                if (userJourneyResourcesError) {
                  console.error('USER_JOURNEY_RESOURCES: ', userJourneyResourcesError);
                  return <Redirect to="/"/>;
                }

                const eventByUuid = eventCreationResourcesData && eventCreationResourcesData.eventByUuid;
                const viewer = session ? session.viewer : null;

                if (eventByUuid && !imEventHost(eventByUuid, viewer)) {
                  return <Redirect to="/"/>;
                }

                const resources = eventByUuid ? eventByUuid.unifiedEventCreationResources : [];
                const userJourneyJourneyResources =
                  (userJourneyResourcesData &&
                  userJourneyResourcesData.viewer &&
                  userJourneyResourcesData.viewer.journeyResponses &&
                  userJourneyResourcesData.viewer.journeyResponses.nodes) || [];

                const userJourneyResources = userJourneyJourneyResources.length
                  ? userJourneyJourneyResources[0].resources.nodes || []
                  : [];

                const allResources = userJourney.filterResources(showJourneyResources && state.claimedResources.length
                  ? userJourneyResources.concat(state.claimedResources)
                  : (showJourneyResources ? userJourneyResources : resources));

                const youTubeRegex = new RegExp(/youtu\.be|youtube\.com/);
                const videoResources = allResources.filter(r => r.__typename === 'Resource' && r.url && r.url.match(youTubeRegex));
                const nonVideoResources = allResources.filter(r => r.__typename !== 'Resource' || !r.url || !r.url.match(youTubeRegex));

                return (
                  <div className={`${className} ${showJourneyResources ? 'new-design' : ''}`}>
                    {showJourneyResources
                      ? (
                        <Query query={UJ_BACKGROUNDS}>
                          {({ data, loading }) => {
                            const background = data && data.journeys
                              ? userJourney.getResourceBackground(data.journeys)
                              : null;
                            if (loading) {
                              return <Loader style={{ 'z-index': 1000 }} />;
                            } else {
                              return (
                                <div
                                  id="new-design-background"
                                  style={{ backgroundImage: `url(${background})` }}
                                />
                              );
                            }
                          }}
                        </Query>
                      ) : <Card className="header" noPadding>
                        <Icon name="calendar-success"/>
                        <span>{copy.event.creation_r_intro_title}</span>
                        <Link to={`/events/${uuid}`}>{copy.event.creation_r_intro_link}</Link>
                      </Card>}
                    <h2>{copy.event.creation_r_title}</h2>
                    {videoResources.length > 0 && (
                      videoResources.map((video, i) => (
                        <ReactPlayer
                          key={i}
                          style={{ margin: '0 auto 30px', zIndex: 10 }}
                          url={video.url}
                          controls
                          width={400}
                          height={226}
                        />
                      ))
                    )}
                    <ul>{renderResources(nonVideoResources, showJourneyResources)}</ul>


                    {showJourneyResources && (
                      <div className="button-container">
                        <Button link={`/events/${uuid}`} style={{ padding: '13px 28px' }}>View My Dinner</Button>
                      </div>
                    )}

                    <EventCreationSuccessModal
                      show={state.showSuccessModal}
                      toggle={toggleSuccessModal}
                      event={eventByUuid}
                      viewer={viewer}
                      onInviteFriendsSuccess={handleInviteFriendsSuccess}
                      areas={session.areas}
                    />
                  </div>
                );
              }}
            </Query>
          )}
        </Query>
      )}
    </SessionContext.Consumer>
  );
}

const StyledEventCreationResourcesPage = styled(EventCreationResourcesPage)`
  padding: 0 20px 20px;
  position: relative;

  #new-design-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-size: cover;
    background-position: center;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: rgb(41,23,31,0.8);
    //   z-index: 1;
    // }
  }

  .button-container {
    text-align: center;
    margin-top: 40px;
  }

  &.new-design {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    & > h2 {
      color: white;
      margin-top: 0;
      font-size: 22px;
    }

    ul {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .ot_card {
    &.header {
      padding: 25px !important;
      border-radius: 5px;
      text-align: center;

      .calendar_success {
        width: 33px;
        height: 33px;

        path {
          fill: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      span {
        display: inline-block;
        font-size: 14px;
        padding: 0 15px 0 25px;
      }

      a {
        display: inline-block;
        font-size: 13px;
        color: ${props => props.settings.theme.css.global.colors.primary};
        border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.primary};

        &:hover {
          color: ${props => props.settings.theme.css.global.colors.primaryHover};
          text-decoration: none;
          border-bottom-color: ${props => props.settings.theme.css.global.colors.primaryHover};
        }
      }
    }
  }

  & > h2 {
    font-size: 18px;
    text-align: center;
    margin: 70px 0 40px;
    padding: 0 20px;
    z-index: 999;
    position: relative;
  }

  & > ul {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
`;
const EventCreationResourcesPageWithRouter = withRouter(StyledEventCreationResourcesPage);
const EventCreationResourcesPageWithRouterWithSettings = withSettings(EventCreationResourcesPageWithRouter);
const EventCreationResourcesPageWithClient = withClient(EventCreationResourcesPageWithRouterWithSettings);
export { EventCreationResourcesPageWithClient as EventCreationResourcesPageWl };
