import React from 'react';

import { useSettings, useRsvpPageData } from '../../hooks';
import { getGuestSelectorEligibility } from '../../libs';

import {
  PageSubheader as Heading,
  WYSIWYGEditor,
  Divider,
  RsvpPageAdditionalGuests,
  RsvpPageFooterButtons as FooterButtons
} from '../components';


export const RsvpPageMessageAndGuests = () => {
  const { getField, setField, event, changePage, readyToRsvp, loading } = useRsvpPageData();
  const { copy, features: { check_pending_max_seats } } = useSettings();

  return (
    <>
      <Heading>{copy.rsvp.host_message_title || 'Write a message to the host'}</Heading>
      <WYSIWYGEditor
        name="hostMessage"
        value={getField('hostMessage')}
        onInputChange={setField}
        placeholder={copy.rsvp.host_message_placeholder || 'Introduce yourself (optional). Share with your host a bit about who you are, what interested you in their dinner, ask if there\'s anything they\'d like you to bring...'}
      />
      <Divider />
      {event && getGuestSelectorEligibility(event, check_pending_max_seats) && <RsvpPageAdditionalGuests />}
      <FooterButtons
        onContinue={() => changePage()}
        continueText={readyToRsvp ? 'RSVP' : 'Continue'}
        loading={loading}
      />
    </>
  );
};
