import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  EventDetailCardLocation,
  EventDetailCardCheckout,
  HostAvatar,
  EventDetailCardFooter
} from '../components';

import ROUTES from '../../constants/routes';
import { useSettings } from '../../hooks';


const EventDetailCard = ({
  className,
  event,
  link = true,
  stack,
  showLocationHost,
  checkout,
  checkoutData
}) => {
  const history = useHistory();
  const { theme } = useSettings();
  const coverUrl = event.cover ? event.cover.originalUrl : theme.assets.images.default_cover;

  return (
    <div
      className={className}
      onClick={() => link && history.push(`/${ROUTES.EVENTS}/${event.uuid}`)}
    >
      <Image src={coverUrl} alt={event.title} title={event.title} />
      <div>
        <HostAvatar event={event} position="lefttop" size="sm" />
        <H2 stack={stack}>{event.title}</H2>
        {checkout ? (
          <EventDetailCardCheckout event={event} checkoutData={checkoutData} />
        ) : (
          <EventDetailCardLocation event={event} stack={stack} showHost={showLocationHost} />
        )}
      </div>
      {!checkout && <EventDetailCardFooter event={event} hideHost={showLocationHost} />}
    </div>
  );
};

const StyledEventDetailCard = styled(EventDetailCard)`
  position: relative;
  border: 1px solid #ceccc7;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  transition: box-shadow 400ms;

  ${({ link = true }) => link && css`
    cursor: pointer;

    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    }
  `}

  & > div {
    position: relative;

    ${({ showLocationHost }) => showLocationHost ? css`
      padding: 50px 30px 100px;
    ` : css`
      padding: 50px 30px 85px;
    `}

    ${({ checkout }) => checkout && css`
      padding: 50px 30px 40px;
    `}
  }
`;
export { StyledEventDetailCard as EventDetailCard };

const Image = styled.img`
  width: 100%;
  height: 13vw;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const H2 = styled.h2`
  font-family: 'Avenir-Demi' !important;
  margin: 0;

  ${({ stack }) => stack ? css`
    font-size: 18px;
  ` : css`
    font-size: 16px;
  `}
`;
