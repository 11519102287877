import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Logo, MainMenu, withSettings } from '../components';
import RestrictionModalManager from '../Navbar/RestrictionModalManager';
import UserSection from '../Navbar/UserSection';

import { isSite } from '../../libs';


const UserJourneyNav = ({ className, settings }) => {
  const [scrolled, setScrolled] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = () => setOpenMobileNav(prev => !prev);

  const handleScrollClass = () => {
    const page = document.querySelector('.uj_page');
    if (page && page.scrollTop > 5) setScrolled(true);
    else setScrolled(false);
  };

  useEffect(() => {
    const page = document.querySelector('.uj_page');
    page && page.addEventListener('scroll', handleScrollClass);
    return () => {
      page && page.removeEventListener('scroll', handleScrollClass);
    }
  }, []);

  let logoUrl = settings.theme.site.nav_url;
  if (!logoUrl && isSite(settings, 'jewishfoodfest')) {
    logoUrl = 'https://www.jewishfoodfest.org/';
  }

  const link = logoUrl ? null : '/';
  const url = logoUrl || null;

  return (
    <RestrictionModalManager>
      <nav className={[className, scrolled ? 'scrolled' : ''].join(' ').trim()}>
        {openMobileNav && <Underlay onClick={() => setOpenMobileNav(false)} />}
        <div className="underlay" />
        <MainMenu
          openMobileNav={openMobileNav}
          toggleMobileNav={toggleMobileNav}
          overHero
          hamburger
        />
        <Logo className="desktop" color="white" link={link} url={url}/>
        <Logo className="main_mobile" layout="emblem" color="white" link={link} url={url}/>
        <UserSection overHero />
      </nav>
    </RestrictionModalManager>
  );
};

const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const StyledUserJourneyNav = styled(UserJourneyNav)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100px;
  transition: background 250ms;

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: -moz-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(0,0,0,0)), color-stop(70%,rgba(0,0,0,1)));
    background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
    background: -o-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
    background: -ms-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
    background: linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 70%);
    opacity: 0;
    transition: opacity 200ms;
  }

  &.scrolled {
    .underlay {
      opacity: 1;
    }
  }

  .logo {
    &.white {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 200px;

      &.main_mobile {
        display: none;
      }

      @media (max-width: 700px) {
        &.desktop {
          display: none;
        }
        &.main_mobile {
          display: block;
          width: 60px;
          left: 30px;
          transform: translate(0, -50%);
          margin: 0;
        }
      }
    }

    &.mobile {
      display: block;
      width: 170px;
      height: 24px;
      margin: 10px auto 40px;

      ${props => isSite(props.settings, 'hillel') && (
        css`
          height: 95px !important;
      `)}
    }
  }

  .main_menu {
    & > li {
      display: block;
      padding: 5px 0;

      .dropdown_link,
      .restricted_link,
      .mobile_nav_link,
      & > a {
        display: block;
        text-align: center;
        padding: 8px 10px;
        font-size: 14px;
      }

      .fa-caret-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        transition: transform 250ms;
      }

      .dropdown_menu_wrapper {
        position: relative;
      }
    }

    .dropdown_menu {
      position: static !important;
      box-shadow: none !important;
      max-height: 100% !important;
      border: none !important;
      padding: 0 !important;
      text-align: center;
      width: 100%;
      margin-top: 5px;
      background-color: #f6f6f6 !important;

      a {
        &:hover {
          background-color: transparent !important;
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      li {
        &.hide_on_desktop {
          display: block;
        }
      }
    }
  }

  .display {
    & > .dropdown_menu {
      display: block !important;
    }

    .fa-caret-down {
      transform: rotate(180deg);
    }
  }
`;
const UserJourneyNavWithSettings = withSettings(StyledUserJourneyNav);
export { UserJourneyNavWithSettings as UserJourneyNav };
