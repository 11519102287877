import { Enumerated } from './Enumerated';

class RESERVATION_STATUS extends Enumerated {
    static PENDING = 'PENDING'
    static ACCEPTED = 'ACCEPTED'
    static REJECTED = 'REJECTED'
    static CANCELED = 'CANCELED'
}

export { RESERVATION_STATUS }
