import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { SearchFilter } from '../../components';

import { FilterContext } from '../FilterContext';


export const MultiButtonSearchFilter = ({ name, options, defaultValue = 'All', removeOn = 'All' }) => {
  const [activeVal, setActiveVal] = useState(null);
  const { getValue, setValue } = useContext(FilterContext);

  useEffect(() => {
    if (defaultValue === null || getValue(name) !== undefined) return;
    if (defaultValue !== removeOn) setValue(name, defaultValue);
    setActiveVal(defaultValue);
  }, [defaultValue]);

  const handleSetValue = val => {
    if (val === removeOn) setValue(name, null);
    else setValue(name, val);
    setActiveVal(val);
  };

  return (
    <ButtonContainer>
      {Array.isArray(options) && options.map((o, i) => (
        <SearchFilter primaryActive={activeVal === o} key={i} onClick={() => handleSetValue(o)}>
          <span>{o}</span>
        </SearchFilter>
      ))}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: inline-block;

  & > div {
    &:not(:last-child) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
`;
