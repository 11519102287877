import { getWeekDateAsString } from '../libs';

export const dynamicSort = (key, direction) => {
  const sortOrder = direction === 'desc' ? -1 : 1;

  return (a, b) => {
    const aVal = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const bVal = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    if (sortOrder < 0) {
      return typeof bVal === 'string'
        ? bVal.localeCompare(aVal)
        : (bVal > aVal ? 1 : -1);
    } else {
      return typeof aVal === 'string'
        ? aVal.localeCompare(bVal)
        : (aVal > bVal ? 1 : -1);
    }
  };
};

export const sort = {
  byObjKey: (arr, key, direction) => {
    if (!Array.isArray(arr)) return null;
    return arr.slice().sort(dynamicSort(key, direction));
  },

  moveObjToEnd: (arr, key, value) => {
    if (!Array.isArray(arr)) return [];
    let array = arr.slice();

    const moveToEnd = val => {
      const foundItem = array.find(item => item[key] === val);
      if (foundItem) array = array.filter(item => item[key] !== val).concat(foundItem);
    };

    if (Array.isArray(value)) {
      value.forEach(val => {
        moveToEnd(val);
      });
    } else {
      moveToEnd(value);
    }

    return array;
  },

  moveToFront: (arr, key, value) => {
    if (!Array.isArray(arr)) return [];
    let array = arr.slice();

    const moveToFront = val => {
      const foundItem = array.filter(item => item[key] === val);
      if (foundItem) array = foundItem.concat(array.filter(item => item[key] !== val));
    };

    if (Array.isArray(value)) {
      value.forEach(val => {
        moveToFront(val);
      });
    } else {
      moveToFront(value);
    }

    return array;
  },

  movePrivateEventsToEndOfDate: eventsData => {
    const events = eventsData ? eventsData.edges : [];
    const eventsByDate = {};
    let sortedEvents = [];

    if (!events.length) return [];

    events.forEach(e => {
      const weekString = getWeekDateAsString(e.node);

      if (Object.keys(eventsByDate).includes(weekString)) {
        eventsByDate[weekString] = [...eventsByDate[weekString], e];
      } else {
        eventsByDate[weekString] = [e];
      }
    });

    for (const i in eventsByDate) {
      const eventsArr = eventsByDate[i];
      const nonPrivateEvents = eventsArr.filter(event => event && event.node.eventType !== 'PRIVATE');
      sortedEvents = [
        ...sortedEvents,
        ...nonPrivateEvents,
        ...eventsArr.filter(event => event && event.node.eventType === 'PRIVATE')
      ];
    }

    return sortedEvents;
  },

  sponsorsByArea: sponsors => {
    const sponsorsByArea = {};

    if (Array.isArray(sponsors)) {
      sponsors.forEach(sponsor => {
        const { areas } = sponsor;
        areas && areas.forEach(({ label }) => {
          const areaLabel = label === 'Other' ? 'Other Cities' : label;
          if (!(areaLabel in sponsorsByArea)) sponsorsByArea[areaLabel] = [];
          sponsorsByArea[areaLabel].push(sponsor);
        });
      });
    }

    return sponsorsByArea;
  }
};
