import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSettings, useSession, useCookieBanner } from '../../hooks';

import { PageContentWrapper, CookieBanner } from '../components';

export const Footer = () => {
  const { theme: { site: { footer_html }, js: { footer_fetch_url: footerFetchURL } } } = useSettings();
  const { theme, layout } = useSession();
  const { pathname } = useLocation();
  const cookieBanner = useCookieBanner();
  const Wrapper = theme.isVersion(2)
    ? PageContentWrapper
    : ({ children }) => <div>{children}</div>;
  const [footerFetchedHTML, setFooterFetchedHTML] = useState('');
  
  const fetchFooterHTML = useCallback(() => {
    if (footerFetchURL)
      fetch(footerFetchURL)
        .then(response => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(html => {
          setFooterFetchedHTML(html)
        });
  }, [footerFetchURL])

  useEffect(() => {
    fetchFooterHTML();
  }, [fetchFooterHTML]);

  return !layout.removeHeaderFooter && !layout.hideHeaderFooter(pathname) ? (
    <>
      <FooterContainer theme={theme}>
        <Wrapper>
          {footer_html &&
            <div dangerouslySetInnerHTML={{ __html: footer_html }} />}
          {footerFetchedHTML &&
            <div dangerouslySetInnerHTML={{ __html: footerFetchedHTML }} />}
        </Wrapper>
      </FooterContainer>
      {!layout.removeHeaderFooter && !layout.hideHeaderFooter(pathname) && cookieBanner.show &&
        <CookieBanner closeBanner={() => cookieBanner.close()}/>}
    </>
  ) : null;
};

const FooterContainer = styled.div`
  ${({ theme }) => theme.isVersion(2) ? css`
    background-color: #f4f3f2;

    footer {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  ` : css`
    background-color: #fff;
  `}
`;
