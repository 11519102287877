import gql from 'graphql-tag';

export const CREATE_JOURNEY_RESPONSE = gql`
  mutation (
    $journeyId: Int!,
    $stringAnswers: [StringAnswerInput!],
    $stringArrayAnswers: [StringArrayAnswerInput!],
    $booleanAnswers: [BooleanAnswerInput!],
    $resources: [Int!],

  ) {
    createJourneyResponse(input: {
      journeyId: $journeyId,
      stringAnswers: $stringAnswers,
      stringArrayAnswers: $stringArrayAnswers,
      booleanAnswers: $booleanAnswers,
      resources: $resources
    }) {
      journeyResponse {
        id
        claimToken
      }
      errors {
        message
      }
    }
  }
`;

export const CLAIM_JOURNEY_RESOURCES = gql`
  mutation (
    $claimToken: String!
  ) {
    claimJourneyResponse(input: {
      claimToken: $claimToken
    }) {
      journeyResponse {
        id
        resources {
          nodes {
            id
            description
            title
            url
            image {
              mediumUrl
            }
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;
