import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { VIEWER_PROFILE_DATA } from '../../queries';

const ProfileSettingsContext = React.createContext();


const ProfileSettingsContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cards, setCards] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const { data, loading, refetch } = useQuery(VIEWER_PROFILE_DATA, { errorPolicy: 'all' });
  const queriedViewerData = data && data.viewer;

  useEffect(() => {
    if (queriedViewerData && queriedViewerData.user) {
      setUser(queriedViewerData.user);
    }
    if (queriedViewerData && queriedViewerData.cards) {
      setCards(queriedViewerData.cards);
    }
    if (queriedViewerData && queriedViewerData.subscriptions) {
      setSubscriptions(queriedViewerData.subscriptions);
    }
  }, [queriedViewerData, loading]);

  const value = {
    user,
    cards,
    subscriptions,
    loading,
    refetch
  };

  return <ProfileSettingsContext.Provider value={value}>{children}</ProfileSettingsContext.Provider>;
};

export { ProfileSettingsContext, ProfileSettingsContextProvider };
