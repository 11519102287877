import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../../components';

const EventCardStack = ({ className, eventsHiddenNumber }) => {
  return (
    <div className={className}>
      { eventsHiddenNumber >= 1 && <div></div> }
      { eventsHiddenNumber >= 2 && <div></div> }
    </div>
  );
};

const StyledEventCardStack = styled(EventCardStack)`
  & > div:nth-child(1) {
    background: ${props => props.settings.theme.css.global.colors.backgroundLight};
    position: absolute;
    bottom: -5px;
    left: 5px;
    right: 5px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06);
    z-index: -1;
  }

  & > div:nth-child(2) {
    background: ${props => props.settings.theme.css.global.colors.backgroundLight};
    position: absolute;
    bottom: -10px;
    left: 10px;
    right: 10px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,.06), 0 2px 10px rgba(0,0,0,.06);
    z-index: -2;
  }
`;

const EventCardStackWithSettings = withSettings(StyledEventCardStack);

export { EventCardStackWithSettings as EventCardStack };
