import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import {
  Loader,
  Button,
  Icon,
  FAIcon,
  ButtonToggle,
  withClient
} from '../components';

import { EVENT_BY_UUID_FOR_EMBED } from '../../queries';
import { getCardEmbedHtml, copyToClipboard } from '../../libs';
import { SettingsContext } from '../../SettingsContext';


const EventEmbedCodeSelector = ({ className, match, client }) => {
  document.querySelector('body').style.padding = '30px';

  const [selected, setSelected] = useState(false);
  const [mode, setMode] = useState('general');

  const { settings } = useContext(SettingsContext);

  const { data, loading, error } = useQuery(EVENT_BY_UUID_FOR_EMBED, {
    client,
    errorPolicy: 'all',
    variables: {
      uuid: match.params.uuid,
      isRegistered: false,
      isHost: false,
      isLogged: false
    }
  });

  if (error) console.error(error);
  if (loading) return <Loader />;

  const embedeCode = data ? getCardEmbedHtml(settings, data.event, mode) : '';

  const handleButtonClick = () => {
    setSelected(true);
    setTimeout(() => setSelected(false), 1300);
    copyToClipboard(embedeCode);
  };

  const classList = [className];
  selected && classList.push('selected');

  // remove banner from custom js //
  const styleEl = document.createElement('style');
  styleEl.textContent = '#new_banner_hack { display: none !important }';
  document.getElementsByTagName('head')[0].appendChild(styleEl);

  return (
    <div className={classList.join(' ').trim()}>
      <textarea value={embedeCode} rows={8} readOnly />
      <div>
        <ButtonToggle
          name="rsvpDeadline"
          activeButton={mode}
          labels={[
            { label: 'Full', value: 'general' },
            { label: 'Email/WP', value: 'email' }
          ]}
          onToggleButton={value => setMode(value)}
        />
        <Button handleClick={handleButtonClick}>
          Copy Code <Icon name="copy-to-clipboard"/><FAIcon name={['fas', 'check']}/>
        </Button>
      </div>
    </div>
  );
};

const StyledEventEmbedCodeSelector = styled(EventEmbedCodeSelector)`
  svg {
    transition: opacity 150ms;

    &.fa-check {
      position: absolute;
      top: 11px;
      right: 18px;
      opacity: 0;
    }

    &.copy_to_clipboard {

    }
  }

  &.selected {
    svg {
      &.fa-check {
        opacity: 1;
      }

      &.copy_to_clipboard {
        opacity: 0;
      }
    }
  }

  & > div {
    & > div,
    & > .button {
      display: inline-block;
    }

    .button {
      // height: 39px;
    }

    & > div {
      margin-right: 20px;

      .button {
        padding: 8px 18px !important;
      }
    }
  }
`;
const EventEmbedCodeSelectorWithRouter = withRouter(StyledEventEmbedCodeSelector);
const EventEmbedCodeSelectorWithClient = withClient(EventEmbedCodeSelectorWithRouter);
export { EventEmbedCodeSelectorWithClient as EventEmbedCodeSelector };
