import React from 'react';
import styled from 'styled-components';
import { ChangePasswordModal } from './ChangePasswordModal';
import { withSettings, withSession } from '../components';
import { LogInModal } from '..';
import { withOtpMutationHooksHOC } from '../../libs/auth';
import { alert } from '../Global';

import {
  Button,
  ProfileEditForm
} from '../components';

class ProfileEdit extends React.Component {
  state = {
    showChangePasswordModal: false,
    showChangePhoneModal: false,
    modalContent: 'login',
  };

  changePasswordClicked = () => {
    this.setState({ showChangePasswordModal: true });
  }

  closeChangePasswordModal = () => {
    this.setState({ showChangePasswordModal: false });
  }

  changePhoneClicked = () => {
    this.setState({ showChangePhoneModal: true })
  }


  toggleLogInModal = () => {
    this.setState({
      showChangePhoneModal: !this.state.showChangePhoneModal
    });
  }

  updateLoginModalContent = (content, openModal) => {
    const newState = { modalContent: content };

    if (openModal) {
      newState['showChangePhoneModal'] = true;
    }

    this.setState(newState);
  };

  handleAfterLogin = () => {
    this.toggleLogInModal();
    alert.success('Profile updated');
  }

  render() {
    const {
      className,
      user,
      refetch,
      settings: { copy },
      session: { theme }
    } = this.props;

    return (
      <div className={className}>
        {!theme.isVersion(2) && (
          <div className="title_container">
            <h3>{copy.profile.profile_title}</h3>
            <Button link={`/user_profile/${user.profile.id}`}>View My Profile</Button>
          </div>
        )}
        <ChangePasswordModal show={this.state.showChangePasswordModal} toggle={this.closeChangePasswordModal}></ChangePasswordModal>
        <LogInModal
          toggle={this.toggleLogInModal}
          updateModalContent={this.updateLoginModalContent}
          content={this.state.modalContent}
          shouldRedirect={false}
          afterLogin={this.handleAfterLogin}
          show={this.state.showChangePhoneModal}
          allowEditPhoneNumber={true}
        />

        <ProfileEditForm
          user={user}
          changePasswordClicked={this.changePasswordClicked}
          changePhoneClicked={this.changePhoneClicked}
          updateProfileClicked={this.updateProfileClicked}
          refetch={refetch}
        />
      </div>
    );
  }
}

const StyledProfileEdit = styled(ProfileEdit)`
  padding: 20px 0 40px;

  .title_container {
    position: relative;

    h3 {
      font-size: 20px;
      text-align: left;
    }

    a {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const StyledProfileEditWithSettings = withSettings(StyledProfileEdit);
 const ProfileEditWithSession = withSession(StyledProfileEditWithSettings);
const StyledProfileEditWithSettingsWithOptHooks = withOtpMutationHooksHOC(
  ProfileEditWithSession
);
export { StyledProfileEditWithSettingsWithOptHooks as ProfileEdit };
