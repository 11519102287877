import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  EventSearchHeaderFilters,
  AreaAndDatesSearchFilter
} from '../components';

import ROUTES from '../../constants/routes';


export const EventSearchHeaderSearch = ({ area: pageArea, dates: selectedDates }) => {
  const history = useHistory();
  const [area, setArea] = useState(pageArea);
  const [dates, setDates] = useState(selectedDates);

  const handleSearchButtonClick = () => {
    // console.log('area', area);
    // console.log('dates', dates);

    if (area.id !== pageArea.id) history.push(`/${ROUTES.SEARCH}/${area.url}`, {
      selectedArea: area,
      selectedDates: dates
    });
  };

  return (
    <HeaderSearch>
      <AreaAndDatesSearchFilter
        data={{ area, dates, setArea, setDates }}
        handleSearchButtonClick={handleSearchButtonClick}
      />
      <Divider />
      <EventSearchHeaderFilters

      />
    </HeaderSearch>
  );
};


const HeaderSearch = styled.div`
  display: flex;
  flex-direct: row;
  align-items: center;
`;

const Divider = styled.div`
  background-color: #e3e3e1;
  height: 40px;
  width: 1px;
  margin: 0 18px;
`;
