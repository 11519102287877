import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Logo, MainMenu, PageContentWrapper, withSettings } from '../components';
import RestrictionModalManager from '../Navbar/RestrictionModalManager';
import UserSection from './UserSection';

import { isSite } from '../../libs';
import { useSession, usePageScroll } from '../../hooks';

import { NOT_SCROLLED_NAV_HEIGHT, SCROLLED_NAV_HEIGHT } from '../../styles/index';


const HamburgerNav = ({ className, settings, overHero }) => {
  const { theme } = useSession();
  const { scrolled } = usePageScroll();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = () => setOpenMobileNav(prev => !prev);

  let logoUrl = settings.theme.site.nav_url;
  if (!logoUrl && isSite(settings, 'jewishfoodfest')) {
    logoUrl = 'https://www.jewishfoodfest.org/';
  }

  const link = logoUrl ? null : '/';
  const url = logoUrl || null;

  const classNames = [
    'main_nav',
    className,
    ...(overHero ? ['over_hero'] : []),
    ...(theme.isVersion(2) ? ['fixed'] : []),
    ...(scrolled ? ['scrolled'] : []),
  ];

  return (
    <RestrictionModalManager>
      <nav className={classNames.join(' ')}>
        {openMobileNav && <Underlay onClick={() => setOpenMobileNav(false)} />}
        <PageContentWrapper fullHeight>
          <MainMenu
            openMobileNav={openMobileNav}
            toggleMobileNav={toggleMobileNav}
            overHero={overHero}
            hamburger
          />
          <Logo className="desktop" color={overHero ? 'white' : ''} link={link} url={url}/>
          <Logo className="main_mobile" layout="emblem" color={overHero ? 'white' : ''} link={link} url={url}/>
          <UserSection overHero={overHero} />
        </PageContentWrapper>
      </nav>
    </RestrictionModalManager>
  );
};

const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const StyledHamburgerNav = styled(HamburgerNav)`
  position: ${props => props.overHero ? 'absolute' : 'relative'};
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: ${NOT_SCROLLED_NAV_HEIGHT}px;
  transition: background 250ms, height 300ms;

  &.scrolled {
    height: ${SCROLLED_NAV_HEIGHT}px;
  }

  @media (max-height: 840px) {
    height: ${SCROLLED_NAV_HEIGHT}px;
  }

  &.fixed {
    position: fixed;
    background-color: #fff;
  }

  .logo {
    &:not(.mobile) {
      position: absolute;
      top: 50%;
      left: 140px;
      transform: translateY(-50%);
      width: 170px;

      &.main_mobile {
        display: none;
      }

      @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
        // left: 120px;
      }

      @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {
        // left: 90px;
      }

      @media (max-width: 700px) {
        &.desktop {
          display: none;
        }
        &.main_mobile {
          display: block;
          width: 60px;
          left: 30px;
          transform: translate(0, -50%);
          margin: 0;
        }
      }
    }

    &.mobile {
      display: block;
      width: 170px;
      height: 24px;
      margin: 10px auto 40px;

      ${props => isSite(props.settings, 'hillel') && (
        css`
          height: 95px !important;
      `)}
    }
  }

  .main_menu {
    & > li {
      display: block;
      padding: 5px 0;

      .dropdown_link,
      .restricted_link,
      .mobile_nav_link,
      & > a {
        display: block;
        text-align: center;
        padding: 8px 10px;
        font-size: 14px;
      }

      .fa-caret-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        transition: transform 250ms;
      }

      .dropdown_menu_wrapper {
        position: relative;
      }
    }

    .dropdown_menu {
      position: static !important;
      box-shadow: none !important;
      max-height: 100% !important;
      border: none !important;
      padding: 0 !important;
      text-align: center;
      width: 100%;
      margin-top: 5px;
      background-color: #f6f6f6 !important;

      a {
        &:hover {
          background-color: transparent !important;
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      li {
        &.hide_on_desktop {
          display: block;
        }
      }
    }
  }

  .display {
    & > .dropdown_menu {
      display: block !important;
    }

    .fa-caret-down {
      transform: rotate(180deg);
    }
  }
`;
const HamburgerNavWithSettings = withSettings(StyledHamburgerNav);
export { HamburgerNavWithSettings as HamburgerNav, SCROLLED_NAV_HEIGHT, NOT_SCROLLED_NAV_HEIGHT };
