import React, { useEffect } from 'react';
import { withSettings } from '../components';

import { isSite } from '../../libs';

import {
  YesNoSelect,
  NumberInput,
  StateSelect,
  TimezoneSelect
} from './CustomFilters/index';


const CustomFiltersWithoutSettings = props => {
  const {
    filters,
    filter,
    handleFilterChange,
    reset,
    settings,
    urlFilters
  } = props;

  const { copy } = settings;
  const accessibleLabel = isSite(settings, 'thegathering') ? 'Wheelchair Accessible' : 'Accessible';

  switch (filter) {
    case 'accessible':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.accessible || accessibleLabel}</label>
          <YesNoSelect name="accessible" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'catered':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.catered || 'Catered'}</label>
          <YesNoSelect name="catered" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'max_price':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.max_price || 'Max Price'}</label>
          <NumberInput
            name="maxPrice"
            handleFilterChange={handleFilterChange}
            reset={reset}
            min={filters.minPrice}
          />
        </div>
      );

    case 'min_price':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.min_price || 'Min Price'}</label>
          <NumberInput
            name="minPrice"
            handleFilterChange={handleFilterChange}
            reset={reset}
            max={filters.maxPrice}
          />
        </div>
      );

    case 'pwyw':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.pwyw || 'Pay What You Want'}</label>
          <YesNoSelect name="pwyw" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'max_guests':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.number_of_guests_max || 'Max Number of Guests'}</label>
          <NumberInput
            name="numberOfGuestsMax"
            handleFilterChange={handleFilterChange}
            reset={reset}
          />
        </div>
      );

    case 'parking_lots':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.parking_lots || 'Parking Lots'}</label>
          <YesNoSelect name="parkingLots" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'pets_allowed':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.pets_allowed || 'Pets Allowed'}</label>
          <YesNoSelect name="petsAllowed" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'potluck':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.potluck || 'Potluck'}</label>
          <YesNoSelect name="potluck" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'virtual':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.virtual || 'Virtual'}</label>
          <YesNoSelect 
            name="virtual" 
            handleFilterChange={handleFilterChange} 
            reset={reset}
            filter={filters.virtual}
            defaultValue={urlFilters && urlFilters.virtual}
          />
        </div>
      );

    case 'state_location':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.state_location || 'State'}</label>
          <StateSelect name="stateLocation" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    case 'timezone':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.timezone || 'Timezone'}</label>
          <TimezoneSelect name="timezone" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );
      
    case 'closed_captioning_available':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.closed_captioning || 'Closed Captioning Available'}</label>
          <YesNoSelect name="closedCaptioningAvailable" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );
      
    case 'videos_on_as_default':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.videos_on_off || 'Videos On/Off By Default'}</label>
          <YesNoSelect name="videosOnAsDefault" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );
      
    case 'bathroom_accessible':
      return (
        <div className="form_group">
          <label className="form_label">{copy.filter.bathroom_accessible || 'Bathroom Accessible'}</label>
          <YesNoSelect name="bathroomAccessible" handleFilterChange={handleFilterChange} reset={reset} />
        </div>
      );

    default:
      return null;
  }
};

const CustomFilters = withSettings(CustomFiltersWithoutSettings);

const CustomFiltersContainer = ({ settings, filters, reset, handleFilterChange, updateFilterSettings, urlFilters }) => {
  const { custom_filters } = settings;

  useEffect(() => {
    if (custom_filters && custom_filters.event_subtype) {
      updateFilterSettings('showEventSubtype', true);
    }
  }, [custom_filters, updateFilterSettings]);

  if (!custom_filters || typeof custom_filters !== 'object') return null;

  return (
    <>
      {Object.keys(custom_filters).map(filter => (
        custom_filters[filter]
          ? (
            <CustomFilters
              key={filter}
              filters={filters}
              filter={filter}
              reset={reset}
              handleFilterChange={handleFilterChange}
              urlFilters={urlFilters}
            />
          ) : null
      ))}
    </>
  );
};

const CustomFiltersWithSettings = withSettings(CustomFiltersContainer);
export { CustomFiltersWithSettings as CustomFilters };
