import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { alert } from '../Global';
import { withSettings } from '../components';

import {
  Button,
  Input,
  Modal
} from '../components';

import PropTypes from 'prop-types';

import { UPDATE_USER } from '../../mutations';

const initialState = {
  password: '',
  confirmPassword: '',
  changePasswordError: '',
  passwordErrorMessage: 'Password must have at least 4 characters',
  confirmPasswordErrorMessage: 'Confirm Password must match Password'
};

class ChangePasswordModal extends React.Component {
  state = { ...initialState };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  isFormDataValid = () => {
    const { password, confirmPassword } = this.state;
    const { passwordValid, confirmPasswordValid } = this.state;
    const formIsValid = passwordValid && confirmPasswordValid && (password === confirmPassword);

    return formIsValid;
  };

  handleFormSubmit = (event, updatePassword) => {
    const { toggle, settings: { copy } } = this.props;
    event.preventDefault();

    updatePassword()
      .then(async ({ data }) => {
        if (data && data.updateUser && data.updateUser.errors) {
          alert.error(copy.change_password.wrong_password);
        } else {
          alert.success(copy.change_password.change_done);
        }

        toggle();
      });
  };

  shouldShowPasswordsDoNotMatchError = () => {
    const shouldShow = (this.state.password.length > 0 && (this.state.password !== this.state.confirmPassword));
    return shouldShow;
  }

  render() {
    const { className, show, toggle, settings: { copy } } = this.props;
    const { password, confirmPassword, passwordErrorMessage, confirmPasswordErrorMessage } = this.state;

    return (
      <Mutation mutation={UPDATE_USER} variables={{ password }} >
        {(updatePassword) => {
          return (
            <Modal show={show} toggle={toggle} size="sm" noPadding>
              <form className="ot_form" onSubmit={event => this.handleFormSubmit(event, updatePassword)}>
                <div className={className}>
                  <h2>{copy.change_password.title}</h2>
                  <h3>{copy.change_password.description}</h3>
                  <div className="form__container">
                    <Input
                      type="password"
                      content="password"
                      placeholder={copy.change_password.password_placeholder}
                      name="password"
                      value={password}
                      onInputChange={this.handleInputChange}
                      onValidate={this.handleInputValidation}
                      errorMessage={passwordErrorMessage}
                      required
                    />
                    <Input
                      type="password"
                      content="password"
                      placeholder={copy.change_password.password_confirm_placeholder}
                      name="confirmPassword"
                      value={confirmPassword}
                      onInputChange={this.handleInputChange}
                      onValidate={this.handleInputValidation}
                      errorMessage={confirmPasswordErrorMessage}
                      forceShowErrorMessage={this.shouldShowPasswordsDoNotMatchError()}
                      required
                    />
                    <Button buttonStyle="primary" type="submit" disabled={!this.isFormDataValid()} fullWidth>
                      {copy.change_password.submit}
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          );
        }}
      </Mutation>
    );
  }
}

ChangePasswordModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func,
  userId: PropTypes.number
};

const StyledChangePasswordModal = styled(ChangePasswordModal)`
  position: relative;
  text-align: center;
  padding: 30px 40px 40px;

  & > h2 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin: 0 0 20px;
  }

  & > h3 {
    color: ${props => props.settings.theme.css.global.colors.heading};
    font-size: 12px;
    margin: 0 0 30px;
    line-height: 1.6em;
    font-weight: normal !important;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & > .separator_line {
    height: 1px;
    background-color: ${props => props.settings.theme.css.global.colors.borderColor};
    width: 33.33333%;
    margin: 10px auto;
    flex-grow: 1;
  }

  & > .logo_background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-size: cover;
    background-position: top;
    border-radius: 4px 0 0 4px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51,30,40,0.3);
      z-index: 1;
    }
  }

  & > .logo_content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding: 50px 40px;

    & > .logo {
      width: 140px;
      margin: 0 auto;
    }

    & > p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin: 40px 0 0;
    }
  }

  & > .form_section {
    float: right;
    width: 50%;
    padding: 50px 40px;
  }

  .form__container {
    padding-top: 30px;
    border-top: 1px solid #ccc;
  }
`;

const StyledChangePasswordModalWithSettings = withSettings(StyledChangePasswordModal);

export { StyledChangePasswordModalWithSettings as ChangePasswordModal };
