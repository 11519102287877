import styled, { css } from 'styled-components';
import { withSettings } from '../components';

// navbar menu that displays a submenu when hovering over the menu item
// and stays displayed when hovering over the menu item or children
const HoverMenu = styled.div`
  position: relative;

  .dropdown_menu {
    top: 100%;
    left: 0;
  }

  ${props => !props.useMobileMenu ? (
    css`
      &:not(:hover) .dropdown_menu {
        display: none;
      }

      @media (${props.settings.theme.css.breakpoints ? `max-width: ${props.settings.theme.css.breakpoints.nav.mobile}px` : 'max-width: 1075px'}) {
        &:hover .dropdown_menu {
          display: none;
        }
      }
  `) : (
    css`
      .dropdown_menu {
        display: none;
      }
    `
  )}
`;

const HoverMenuWithSettings = withSettings(HoverMenu);

export { HoverMenuWithSettings as HoverMenu };
