import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';

import {
  EventCreationActionsMenu,
  EventCreationTypeSelector,
  EventCreationFormHero,
  EventCreationFormDetails,
  EventCreationFormMeal,
  EventCreationFormRsvpQuestions,
  EventCreationFormCommunityDetails,
  EventCreationPopupsContainer,
  EventCreationPopupsRecurrance,
  VaccinationDetailsModal,
  SubmitWarningModal,
  ErrorModal,
  alert,
  withSettings,
  withClient,
  withSession,
} from '../../components';

import {
  eventDataDefaultState,
  buildEventDataDefaultState,
  validateEventData,
  buildEventUpdateState,
  hasSponsorships,
  isSite,
  getVirtualEventFeildsByPolicy,
  getMaxSeatLimit
} from '../../../libs';

import ROUTES from '../../../constants/routes';
import { EVENT_TYPE, EVENT_STATE } from '../../../enums';
import { breakpoints } from '../../../styles';
import { UPDATE_EVENT_SPONSORSHIPS } from '../../../mutations';
import { ALL_USER_RESERVATIONS } from '../../../queries';
import fieldsConfig, { getForcedFieldFor } from '../const/FieldsConfiguration';
import whitelabel from '../../../libs/whitelabels';

import { RECURRANCE } from '../../../constants/recurrances';

class EventCreationForm extends React.Component {
  state = {
    ...eventDataDefaultState,

    eventTypes: [],
    disabledEventTypes: [],
    paidEventsEnabled: null,
    sponsorshipsEnabled: null,
    shouldRemoveSponsorships: false,
    shouldRemoveSponsorshipsPerVirtual: false,
    isSponsored: false,
    stockCoversShufflerNumber: new Date().getUTCMilliseconds(),
    hasUpdated: false,
    minDescriptionLength: null,
    maxDescriptionLength: null,
    numberOfGuestsMaxLimit: undefined
  };

  componentDidMount() {
    this.setupForm();
    this.setupFeatures();
  }

  setupForm = () => {
    const {
      defaults,
      settings,
      creationAnswers,
      isCommunity,
      isUpdate,
      isDuplicate,
      eventData = {},
      eventPrivacySubtypes,
      journey,
      viewer,
      areas,
      location,
      session
    } = this.props;
    const filteredEventTypes = this.getEventTypes(areas.length === 1 && areas[0]);
    const { event: eventSettings, features: { enable_area_user_connection } } = settings;

    let newState = {};

    if (location.state?.messageOnLoad) {
      newState = {
        showErrorModal: true,
        errorModalMessage: location.state.messageOnLoad
      };
    }

    if (eventData && eventData.eventType === 'SOLO') {
      newState = { ...newState, ...fieldsConfig.SOLO.enabled };
    }

    if (journey && journey.journeyResponseIds) {
      newState.journeyResponseIds = journey.journeyResponseIds;
    }
    if (journey && journey.choices && journey.choices.bgImage) {
      newState.journeyCoverImage = journey.choices.bgImage;
    }

    if ((isUpdate || isDuplicate) && eventData) {
      // Load event data for update //
      const eventState = buildEventUpdateState(settings, eventData, areas, eventPrivacySubtypes);

      newState = {
        ...newState,
        ...eventState,
        savedEventData: eventState,
        eventTypes: filteredEventTypes,
        invalidate: false,
        isSponsored: hasSponsorships(eventData),
        cachedDate: eventState.date,
        minDescriptionLength: eventSettings.description_min_length || 150,
        maxDescriptionLength: eventSettings.description_max_length
      };

      if (isDuplicate) {
        newState.title = `${newState.title} (copy)`;
        // force to accept agreement again? //
        newState.virtualConfirmation = false;
        // remove host from cohosts array //
        newState.cohosts = newState.cohosts.filter((c) => viewer && viewer.email !== c);
        // clear event date //
        newState.date = null;
      }
    } else {
      // Auto select when only 1 event type exists //
      if (filteredEventTypes.length === 1) defaults.defaultType = filteredEventTypes[0].value;
      // Auto select when only 1 area exists //
      if (areas.length === 1) defaults.defaultArea = areas[0];
      // set default event type if nothing yet has been chosen //
      if (!defaults.defaultType)
        defaults.defaultType = settings.event.default_event_type || EVENT_TYPE.PRIVATE;

      // Add defaults / creation question answers / community prop to state //
      const params = {
        ...defaults,
        creationAnswers,
        isCommunity,
        minDescriptionLength: eventSettings.description_min_length || 150,
        maxDescriptionLength: eventSettings.description_max_length,
        creator: viewer.profile,
        cover: newState.journeyCoverImage || null
      };
      const eventState = buildEventDataDefaultState(
        settings,
        params,
        viewer,
        areas,
        eventPrivacySubtypes
      );

      newState = {
        ...newState,
        ...eventState,
        eventTypes: filteredEventTypes,
        invalidate: false
      };
    }

    const areaProfile = session?.viewer?.user?.profile?.areaProfile ?? [];
    if (enable_area_user_connection && !!areaProfile.length) {
      const nextArea = areas.find(item => item.id === areaProfile[0].id)
      if(nextArea) {
        newState.area = nextArea;
      }
    }


    // TEMP : need to figure out what is clearing eventType when user has valid zipcode //
    // setting state at end of js queue fixes it for now //
    setTimeout(() => this.setState(newState), 0);
  };

  getCurrentArea = () => {
    const { area: eventArea } = this.state;
    const { viewer, areas } = this.props;
    const viewerArea = areas.find((area) =>
      viewer.profile && viewer.profile.area ? area.code === viewer.profile.area.code : false
    );
    return eventArea ? eventArea : viewerArea;
  };

  getEventTypeTooltip = (type, area) => {
    const { settings: { copy } } = this.props;
    const { privacySubtype, eventType } = this.state;

    let tooltip = '';

    switch (type) {
      case EVENT_TYPE.PRIVATE:
        tooltip = eventType === EVENT_TYPE.PRIVATE &&
          privacySubtype && privacySubtype.socialDistancePolicy
            ? privacySubtype.socialDistancePolicy.eventTypePrivateHelp
            : (area && area.socialDistancePolicy ? area.socialDistancePolicy.eventTypePrivateHelp : '');
        break;
      case EVENT_TYPE.PUBLIC:
        tooltip = eventType === EVENT_TYPE.PUBLIC &&
          privacySubtype && privacySubtype.socialDistancePolicy
            ? privacySubtype.socialDistancePolicy.eventTypePublicHelp
            : (area && area.socialDistancePolicy ? area.socialDistancePolicy.eventTypePublicHelp : '');
        break;
      case EVENT_TYPE.MANUAL_APPROVAL:
        tooltip = eventType === EVENT_TYPE.MANUAL_APPROVAL &&
          privacySubtype && privacySubtype.socialDistancePolicy
            ? privacySubtype.socialDistancePolicy.eventTypeManualApprovalHelp
            : (area && area.socialDistancePolicy
              ? area.socialDistancePolicy.eventTypeManualApprovalHelp
              : '');
        break;
      default:
    }

    if (!tooltip) tooltip = copy.create[`event_type_${type.toLowerCase()}_help`];

    return tooltip;
  };

  getEventTypes = (chosenArea, state = false) => {
    const { privacySubtype } = this.state;
    const { settings } = this.props;
    const eventTypes = settings.types.event;
    const eventTypeData = [];
    const area = chosenArea || this.getCurrentArea();
    const socialDistancePolicy = privacySubtype && privacySubtype.socialDistancePolicy;

    for (const i in eventTypes) {
      eventTypeData.push({
        value: i,
        label: eventTypes[i],
        tooltip: this.getEventTypeTooltip(i, state ? chosenArea : area)
        // tooltip: settings.copy.create[`event_type_${i.toLowerCase()}_help`]
      });
    }
    // to be able to remove event type options from cp settings // <~ old
    // const filteredEventTypes = eventTypeData.filter(type => type.label !== '');
    // instead use new cp settings //
    const filteredEventTypes = eventTypeData.filter((type) => {
      const allowedSetting = `${
        type.value === 'MANUAL_APPROVAL' ? 'manualApproval' : type.value.toLowerCase()
      }EventAllowed`;
      /*
        manualApprovalEventAllowed
        privateEventAllowed
        publicEventAllowed
        soloEventAllowed
      */
      if (state) return chosenArea[allowedSetting];

      return socialDistancePolicy
        ? socialDistancePolicy[allowedSetting]
        : area && area.socialDistancePolicy && area.socialDistancePolicy[allowedSetting]
    });

    return filteredEventTypes.length
      ? filteredEventTypes
      : eventTypeData.filter((t) => t.label !== '');
  };

  areasFetched = (areas) => {
    if (areas.length === 1) {
      const areaSelected = areas[0];

      this.setState({
        area: areaSelected,
        timezone: areaSelected.timezone
      });
    }
  };

  setupFeatures = () => {
    const {
      settings: { features }
    } = this.props;

    const paidEventsEnabled =
      features && (features.paid_events === true || features.paid_events === 'true');
    const sponsorshipsEnabled =
      features && (features.sponsorships === true || features.sponsorships === 'true');

    this.setState({ paidEventsEnabled, sponsorshipsEnabled });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
  };
  
  handleEventDataValidation = (action, buttonRef) => {
    const { settings, eventData, viewer, isUpdate } = this.props;
    const { shouldContinue, isSponsored } = this.state;
    const validationResult = validateEventData(this.state, settings, eventData, viewer, isUpdate);
    const validateVaccination = action !== 'save_draft' && action !== 'update_event';
    const { event: { max_upcoming_published_events_per_user } } = settings;
    const maxPublishedEventsPerHost = !viewer?.canCopyEvents && parseInt(max_upcoming_published_events_per_user);
    const hostedEventsCount = viewer?.hostedEvents?.edges.length || 0;
    let errorState = {};

    if (maxPublishedEventsPerHost && hostedEventsCount >= maxPublishedEventsPerHost && action === 'publish_event') {
      errorState = {
        showErrorModal: true,
        errorModalMessage: settings.copy.create.publish_limit_met_publish_message
          || 'You\'re a shining Shabbat star! You can only have three dinners posted at a time, but you can create and save future dinners as drafts. Way to plan ahead!'
      };
      validationResult.hasMetMaxPublishedEvents = true;
    } else if (validateVaccination && validationResult.requiresProfileUpdate) {
      errorState = { showVaccinationDetailsModal: true, actionAfterVaccination: action, buttonRef };
    } else if (validateVaccination && validationResult.showVaccinationError) {
      errorState = {
        showErrorModal: true,
        errorModalMessage: this.getVaccinationErrorMessage(validationResult.action)
      };
    // `shouldContinue` set to true after clicking the 'continue' button on the SubmitWarningModal //
    } else if (validationResult.showSubmitWarning && !shouldContinue && isSponsored) {
      errorState = { showSubmitWarningModal: true, submitWarning: validationResult.action, buttonRef };
      validationResult.isSponsored = true;
    } else if (validationResult.errors.length > 0) {
      this.setState({ invalidate: true }, () => {
        // temporarily used to trigger invalidation //
        // set back to false so only triggered on handleCreateEvent //
        setTimeout(() => this.setState({ invalidate: false }), 50);
      });
      return validationResult;
    }

    this.setState(errorState);
    return validationResult;
  };

  handleToggleVaccinationModal = shouldCreateEvent => {
    const { actionAfterVaccination, buttonRef } = this.state;

    this.setState(prev => ({ showVaccinationDetailsModal: !prev.showVaccinationDetailsModal }), () => {
      if (shouldCreateEvent && actionAfterVaccination && buttonRef) buttonRef.click();
      document.body.classList.remove('modal-open');
    });
  };

  handleToggleErrorModals = prop => {
    this.setState(prev => ({ [prop]: !prev[prop] }), () => {
      document.body.classList.remove('modal-open');
    });
  };

  handleWarningContinue = () => {
    const { buttonRef, submitWarning, isSponsored } = this.state;
    let shouldRemoveSponsorships = false;

    switch (submitWarning) {
      case 'numberOfGuestsMax_decrease':
        if (isSponsored) {
          shouldRemoveSponsorships = true;
        }
        break;
      default:
    }

    this.setState({ shouldContinue: true, shouldRemoveSponsorships }, () => {
      buttonRef && buttonRef.click();
    });
  };

  getMaxSeatsLimit = policy => {
    const { settings } = this.props;
    const { eventType, virtual: isVirtual } = this.state;
    const {
      event: { virtual }
    } = settings;

    if (!eventType || isVirtual) return undefined;

    // const maxGuestCap = parseInt(virtual[`${eventType.toLowerCase()}_non_virtual_max_seats_cap`], 10);
    const maxGuestCap = policy
      ? policy[
          `${
            eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : eventType.toLowerCase()
          }NonVirtualMaxSeatsCap`
        ]
      : null;

    const globalSeatCap =
      virtual[
        `${
          eventType === 'MANUAL_APPROVAL' ? 'manual_approval' : eventType.toLowerCase()
        }_non_virtual_max_seats_cap`
      ];

    if (maxGuestCap === null) return parseInt(globalSeatCap) || undefined;

    return maxGuestCap;
  };

  afterInputChange = async (name, valueIfNotObject) => {
    const { areas, settings, stateSocialDistancePolicies, viewer } = this.props;
    const { eventType, tags, privacySubtypeDisabled } = this.state;
    const {
      event: { virtual }
    } = settings;

    //TODO: It'd be a setting. Something like fixedTag or forcedTag 🤷
    // if (isSite(this.props.settings, 'hineni') && !this.state.tags.includes('allholidays')) {
    //   this.setState({ tags: ['allholidays', ...this.state.tags] });
    // }

    // globall virtual policy fallback //
    const globalVirtualPolicy = eventType
      ? virtual[
          `${
            eventType === 'MANUAL_APPROVAL' ? 'manual_approval' : eventType.toLowerCase()
          }_creation_policy`
        ]
      : false;

    if (name && name.area) {
      const eventArea = areas.find(a => a.code === name.area.code);
      // vaccinated policy //
      const eventVaccinatedPolicy =
        eventArea && eventArea.socialDistancePolicy
          ? eventArea.socialDistancePolicy[
                `${eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : eventType.toLowerCase()
              }VaccinatedCreationPolicy`
            ]
          : false;

      let virtualFields = {};

      if (eventVaccinatedPolicy !== 'mandatory') {
        // virtual policy, overridden by vaccination policy //
        const eventVirtualPolicy =
          eventArea && eventArea.socialDistancePolicy
            ? eventArea.socialDistancePolicy[
                  `${eventType === 'MANUAL_APPROVAL' ? 'manualApproval' : eventType.toLowerCase()
                }VirtualCreationPolicy`
              ]
            : false;

        virtualFields = getVirtualEventFeildsByPolicy(
          eventVirtualPolicy || globalVirtualPolicy,
          settings
        );
      }

      this.setState({
        eventTypes: this.getEventTypes(name.area),
        numberOfGuestsMaxLimit: getMaxSeatLimit(this.state, areas, settings, viewer),
        ...virtualFields
      });
    }

    if (name === 'stateLocation') {
      const statePolicy =
        stateSocialDistancePolicies &&
        stateSocialDistancePolicies.find((p) => p.state === valueIfNotObject);
      const eventTypeFields = {};

      if (statePolicy) {
        const virtualFields = getVirtualEventFeildsByPolicy(
          statePolicy.socialDistancePolicy || globalVirtualPolicy,
          settings
        );
        const newEventTypes = this.getEventTypes(statePolicy.socialDistancePolicy, true);

        if (newEventTypes && !newEventTypes.find((type) => type.value === eventType)) {
          // eventTypeFields.eventType = newEventTypes[0].value;

          // const newEventVirtualPolicy = statePolicy && statePolicy.socialDistancePolicy
          //   ? statePolicy.socialDistancePolicy[
          //       `${eventTypeFields.eventType === 'MANUAL_APPROVAL'
          //         ? 'manualApproval'
          //         : eventTypeFields.eventType.toLowerCase()}VirtualCreationPolicy`
          //     ]
          //   : false;
          //
          // if (newEventVirtualPolicy !== 'mandatory') eventTypeFields.privacySubtypeDisabled = false;

          eventTypeFields.eventType = null;
          eventTypeFields.privacySubtype = null;
          eventTypeFields.privacySubtypeDisabled = false;
        }

        this.setState({
          eventTypes: newEventTypes,
          numberOfGuestsMaxLimit: this.getMaxSeatsLimit(statePolicy.socialDistancePolicy),
          ...virtualFields,
          ...eventTypeFields
        });
      } else {
        // reset policy fields //
        this.setState({
          eventTypes: this.getEventTypes(name.area),
          numberOfGuestsMaxLimit: getMaxSeatLimit(this.state, areas, settings, viewer),
          privacySubtypeDisabled: false
        });
      }
    }

    // if event type or virtual status changes, reset numberOfGuestsMaxLimit //
    if (name && (name.hasOwnProperty('eventType') || name.hasOwnProperty('virtual'))) {
      this.setState({
        numberOfGuestsMaxLimit: getMaxSeatLimit(this.state, areas, settings, viewer)
      });
    }

    if (name && name.hasOwnProperty('eventType')) {
      const eventTypeState = {
        eventTypes: this.getEventTypes(),
        numberOfGuestsMaxLimit: getMaxSeatLimit(this.state, areas, settings, viewer)
      };
      // if event type changes, reset privacySubtype //
      // when `privacySubtypeDisabled` is true, the new `privacySubtype` has already been set by EventCreationTypeSelector //
      if (name.eventType !== eventType && !privacySubtypeDisabled) eventTypeState.privacySubtype = null;

      this.setState(eventTypeState);
    }

    // if privacySubtype changes, set virtual status //
    if (name === 'privacySubtype' && valueIfNotObject && typeof valueIfNotObject === 'object') {
      const {
        virtual: isVirtual,
        outdoors: isOutdoors,
        vaccinated: isVaccinated
      } = valueIfNotObject;

      const privacyProps = {
        eventTypes: this.getEventTypes(),
        virtual: isVirtual,
        outdoors: isOutdoors,
        tags: tags.slice().filter((tag) => tag !== 'outdoors' && tag !== 'virtual')
      };

      if (valueIfNotObject.virtual) {
        // clear address fields on virtual events //
        privacyProps.fullDress = '';
        privacyProps.secondaryDress = '';
      }

      if (valueIfNotObject.virtual && !privacyProps.tags.includes('virtual')) {
        privacyProps.tags.push('virtual');
      } else if (valueIfNotObject.outdoors && !privacyProps.tags.includes('outdoors')) {
        privacyProps.tags.push('outdoors');
      }

      // clear cohosts if vaccinated privacy subtype is selected //
      if (isVaccinated) {
        privacyProps.cohosts = [];
      }

      this.setState(privacyProps, () => {
        this.setState({
          numberOfGuestsMaxLimit: getMaxSeatLimit(
            this.state,
            areas,
            settings,
            viewer,
            null,
            isVirtual
          )
        });
      });
    }

    if (name === 'recurrance') {
      if (valueIfNotObject !== RECURRANCE.NONE) {
        this.setState({
          potluck: false,
          potluckItems: [],
          hidePotluck: true
        });
      } else {
        this.setState({ hidePotluck: false });
      }
    }
  };

  handleInputChange = (name, valueIfNotObject) => {
    const { potluck, potluckItems } = this.state;
    const fields = {};

    // field update warnings //
    if (name === 'recurrance' && potluck && potluckItems.length) {
      this.setState({
        showRecurrenceWarningModal: true,
        recurrenceWarning: 'potluck',
        savedRecurrance: valueIfNotObject
      });
      return false;
    }

    if (typeof name !== 'object') {
      Object.assign(fields, getForcedFieldFor(name, valueIfNotObject));
      Object.assign(fields, { [name]: valueIfNotObject });
    } else {
      Object.entries(name).forEach(([field, value]) => {
        if (this.state[field] !== value) {
          Object.assign(fields, getForcedFieldFor(field, value));
        }
      });
      Object.assign(fields, name);
    }

    this.setState(fields, () => this.afterInputChange(name, valueIfNotObject));
    return true;
  };

  handleRecurrancePopupContinue = () => {
    const { savedRecurrance, recurrenceWarning } = this.state;

    switch (recurrenceWarning) {
      case 'potluck':
        this.setState(
          {
            recurrance: savedRecurrance,
            recurrenceWarning: '',
            showRecurrenceWarningModal: false,
            savedRecurrance: null
          },
          () => this.afterInputChange('recurrance', savedRecurrance)
        );
        break;

      default:
    }
  };

  handleRecurrancePopupCancel = () => {
    this.setState({ showRecurrenceWarningModal: false });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  handleCancelClick = () => {
    const { history, isUpdate, eventData, tracking, sponsorshipTrackingId } = this.props;

    if (sponsorshipTrackingId)
      tracking.update({
        id: sponsorshipTrackingId,
        nextActionTaken: 'Event creation canceled.'
      });

    if (isUpdate) {
      if (eventData.state === 'PUBLISHED') {
        history.push(`/${ROUTES.MANAGE}/hosted/${eventData.uuid}`);
      } else {
        history.push(`/${ROUTES.MANAGE}/draft/${eventData.uuid}`);
      }
    } else {
      history.push(`/${ROUTES.MANAGE}/hosted`);
    }
  };

  handleDraftComplete = (response) => {
    const { history, tracking, creationTrackingId, sponsorshipTrackingId } = this.props;
    const {
      settings: { copy }
    } = this.props;

    const eventId = this.getSavedEventData(response, 'uuid');

    alert.success(copy.create.draft_saved);

    if (creationTrackingId)
      tracking.update({
        id: creationTrackingId,
        nextActionTaken: 'Saved As Draft'
      });
    if (sponsorshipTrackingId) this.updateSponsorshipTrackingOnSave(response);

    if (eventId) history.push(`/${ROUTES.EVENTS}/${eventId}/edit`);
  };

  handleSaveComplete = (response, publishLimitMet) => {
    const { history, tracking, creationTrackingId, sponsorshipTrackingId, settings } = this.props;
    const { sponsorshipsEnabled } = this.state;
    const eventUuid = this.getSavedEventData(response, 'uuid');
    const isSoloEvent = this.getSavedEventData(response, 'eventType') === EVENT_TYPE.SOLO;
    const nourishableEvent =
      response && response.data && response.data.createEvent && response.data.createEvent.event
        ? response.data.createEvent.event.nourishable
        : false;

    // Remove Creation tracking if event is saved //
    if (creationTrackingId) tracking.destroy(creationTrackingId);
    // Update Sponsorship tracking //
    if (sponsorshipTrackingId) this.updateSponsorshipTrackingOnSave(response);

    // const userJourneyConfig = userJourney.load();

    if (eventUuid) {
      if (publishLimitMet) {
        history.push({
          pathname: `/${ROUTES.EVENTS}/${eventUuid}/edit`,
          state: {
            messageOnLoad: settings.copy.create.publish_limit_met_creation_message
              || 'You\'re a shining Shabbat star! You can only have three dinners posted at a time, but you can create and save future dinners as drafts. Way to plan ahead!'
          }
        });
      } else if (sponsorshipsEnabled && nourishableEvent) {
        history.push({
          pathname: `/${ROUTES.EVENTS}/${eventUuid}/guests`,
          state: { isAfterCreate: true, isSoloEvent }
        });
      } else {
        const shouldShowResourcesPage =
          settings && settings.features && settings.features.event_creation_questions;
        const pathName = shouldShowResourcesPage /*&& !userJourneyConfig.skipResources*/
          ? `/${ROUTES.EVENTS}/${eventUuid}/resources`
          : `/${ROUTES.EVENTS}/${eventUuid}`;

        // userJourney.resetItem('skipResources');

        history.push({
          pathname: pathName,
          state: { isAfterCreate: true, isSoloEvent }
        });
      }
    }
  };

  handleSubmitPendingComplete = (response) => {
    const {
      history,
      sponsorshipTrackingId,
      creationTrackingId,
      tracking,
      settings: { copy }
    } = this.props;

    // Remove Creation tracking if event is saved //
    if (creationTrackingId) tracking.destroy(creationTrackingId);
    // Update Sponsorship tracking //
    if (sponsorshipTrackingId) this.updateSponsorshipTrackingOnSave(response);

    alert.success(
      copy.create.event_submitted_for_approval || 'Event has been submitted for approval.'
    );

    history.push({
      pathname: `/${ROUTES.MANAGE}/pending`,
      state: { isAfterCreate: true }
    });
  };

  handlePublishComplete = (response) => {
    const { history, eventData, sponsorshipTrackingId, settings } = this.props;
    const { sponsorshipsEnabled } = this.state;
    const isSoloEvent = this.getSavedEventData(response, 'eventType') === EVENT_TYPE.SOLO;

    if (sponsorshipTrackingId) this.updateSponsorshipTrackingOnSave(response);

    if (sponsorshipsEnabled) {
      history.push({
        pathname: `/${ROUTES.EVENTS}/${eventData.uuid}/guests`,
        state: { isAfterCreate: true, isSoloEvent }
      });
    } else {
      const shouldShowResourcesPage =
        settings && settings.features && settings.features.event_creation_questions;
      const pathName = shouldShowResourcesPage
        ? `/${ROUTES.EVENTS}/${eventData.uuid}/resources`
        : `/${ROUTES.EVENTS}/${eventData.uuid}`;

      history.push({
        pathname: pathName,
        state: { isAfterCreate: true, isSoloEvent }
      });
    }
  };

  handleUpdateComplete = (response) => {
    const {
      sponsorshipTrackingId,
      settings: { copy }
    } = this.props;

    if (sponsorshipTrackingId) this.updateSponsorshipTrackingOnSave(response);
    // this.setupForm();

    this.setState({ hasUpdated: true }, () => {
      setTimeout(this.setState({ hasUpdated: false }), 50);
    });

    alert.success(copy.create.event_updated);
  };

  handleSetSponsorshipRemoval = (value, virtual) => {
    const { isSponsored, date, cachedDate } = this.state;
    const { isUpdate } = this.props;

    if (!isUpdate || !isSponsored) return;

    if (virtual) {
      this.setState({ shouldRemoveSponsorshipsPerVirtual: value });
    } else {
      // Dont remove sponsorships if date remains the same //
      // In case a limit is reached after sponsorships were added //
      if (!moment(date).isSame(cachedDate)) this.setState({ shouldRemoveSponsorships: value });
    }
  };

  handleRemoveSponsorships = () => {
    const { client } = this.props;
    const { id: eventId } = this.state;

    if (!eventId) return false;

    return client.mutate({
      mutation: UPDATE_EVENT_SPONSORSHIPS,
      variables: {
        eventId,
        sponsorships: [],
        notifyCreator: false
      }
    });
  };

  updateSponsorshipTrackingOnSave = (response) => {
    const { tracking, sponsorshipTrackingId, hostHasRechosenAfterLimit } = this.props;
    const trackingProps = { id: sponsorshipTrackingId };
    const eventId = this.getSavedEventData(response, 'id');

    // Add event id to Sponsorship tracking //
    if (eventId) trackingProps.eventId = eventId;
    // Update nextActionTaken if another date/area wasnt chosen //
    if (!hostHasRechosenAfterLimit)
      trackingProps.nextActionTaken = 'Chose to not receive nourishment.';

    tracking.update(trackingProps);
  };

  getSavedEventData = (response, field) => {
    return (
      response &&
      response.data &&
      response.data.createEvent &&
      response.data.createEvent.event &&
      response.data.createEvent.event[field]
    );
  };

  getTitle = () => {
    const {
      isUpdate,
      isCommunity,
      settings: { copy }
    } = this.props;

    if (isUpdate) {
      return isCommunity ? copy.create.edit_title_community : copy.create.edit_title;
    }

    return isCommunity ? copy.create.title_community : copy.create.title;
  };

  getVaccinationErrorMessage = (vaccinationError) => {
    const { settings: { copy } } = this.props;

    switch (vaccinationError) {
      case 'date':
        return copy.create.host_requires_vaccination_error_message;
      case 'change':
        return copy.create.cant_switch_to_vaccination_error_message ||
          'Sorry, we\'re unable to allow you to switch to a vaccinated dinner. We are ensuring all guests that may have previously RSVP\'d to your dinner have entered their final COVID-19 vaccination date. Questions? Please reach out to <a href="mailto:help@onetable.org">help@onetable.org</a>.';
      default:
        return '';
    }
  };

  render() {
    const {
      eventType,
      eventTypes,
      area,
      date,
      tags,
      virtual,
      communityDinner,
      privacySubtype,
      privacySubtypeDisabled,
      state,
      invalidate,
      hasUpdated,
      isSponsored,
      shouldRemoveSponsorships,
      shouldRemoveSponsorshipsPerVirtual,
      shouldSkipSponsorshipLimits,
      stockCoversShufflerNumber,
      numberOfGuestsMaxLimit,
      stateLocation,
      showVaccinationDetailsModal,
      showErrorModal,
      errorModalMessage,
      showSubmitWarningModal,
      submitWarning,
      shouldContinue,
      savedEventData,
      hidePotluck,
      showRecurrenceWarningModal,
      recurrenceWarning
    } = this.state;
    const {
      className,
      tracking,
      sponsorshipTrackingId,
      hostHasRechosenAfterLimit,
      isUpdate,
      isDuplicate,
      isCommunity,
      eventData,
      specialDates,
      specialAreas,
      areas,
      viewer,
      wasPaidEventBeforeEditing,
      eventPrivacySubtypes,
      stateSocialDistancePolicies,
      settings
    } = this.props;
    const isDraft = state === EVENT_STATE.DRAFT;

    const EventCreationFormHeader = whitelabel.event.create.form.header(settings);
    const EventCreationFormLocation = whitelabel.event.create.form.location(settings);

    return (
      <Query query={ALL_USER_RESERVATIONS} variables={{ profileId: viewer.profile.id }}>
        {({ data, refetch: refetchAllUserReservations }) => {
          const allUserReservations = data?.allUserReservations
          return (
            <form
              className={`${className} ot_form`}
              onSubmit={(event) => this.handleFormSubmit(event)}
              autoComplete='off'
            >
              <EventCreationFormHero />

              <VaccinationDetailsModal
                show={showVaccinationDetailsModal}
                toggle={val => this.handleToggleVaccinationModal(val)}
                action="create"
              />
              <ErrorModal
                show={showErrorModal}
                toggle={() => this.handleToggleErrorModals('showErrorModal')}
              >
                <p dangerouslySetInnerHTML={{ __html: errorModalMessage }} />
              </ErrorModal>
              <SubmitWarningModal
                show={showSubmitWarningModal}
                toggle={() => this.handleToggleErrorModals('showSubmitWarningModal')}
                action={submitWarning}
                onContinue={this.handleWarningContinue}
              />

              {/* Sponsorship Limit & Special Date Popups */}
              {/* using `eventData` instead of this.state may be buggy here */}
              <EventCreationPopupsContainer
                area={area}
                date={date}
                virtual={virtual}
                eventData={eventData}
                isUpdate={isUpdate}
                isSponsored={isSponsored}
                isDuplicate={isDuplicate}
                onRemoveSponsorships={this.handleSetSponsorshipRemoval}
                shouldSkipSponsorshipLimits={shouldSkipSponsorshipLimits}
                specialDates={specialDates}
                specialAreas={specialAreas}
                tracking={tracking}
                sponsorshipTrackingId={sponsorshipTrackingId}
                hostHasRechosenAfterLimit={hostHasRechosenAfterLimit}
              />
              <EventCreationPopupsRecurrance
                showModal={showRecurrenceWarningModal}
                recurrenceWarning={recurrenceWarning}
                onContinue={this.handleRecurrancePopupContinue}
                onCancel={this.handleRecurrancePopupCancel}
              />

              {/* Event Actions */}
              <EventCreationActionsMenu
                eventData={eventData ? { ...this.state, uuid: eventData.uuid } : this.state}
                allUserReservations={allUserReservations}
                refetchAllUserReservations={refetchAllUserReservations}
                onCancel={this.handleCancelClick}
                onEventDataValidation={(action, buttonRef) => this.handleEventDataValidation(action, buttonRef)}
                onDraftComplete={this.handleDraftComplete}
                onSaveComplete={this.handleSaveComplete}
                onSubmitPendingComplete={this.handleSubmitPendingComplete}
                onPublishComplete={this.handlePublishComplete}
                onUpdateComplete={this.handleUpdateComplete}
                onRemoveSponsorships={this.handleRemoveSponsorships}
                shouldRemoveSponsorships={isUpdate && shouldRemoveSponsorships}
                shouldRemoveSponsorshipsPerVirtual={isUpdate && shouldRemoveSponsorshipsPerVirtual}
                isCommunity={communityDinner}
                isUpdate={isUpdate}
                isDraft={isDraft}
                isDuplicate={isDuplicate}
                shouldContinue={shouldContinue}
                viewer={viewer}
                savedEventData={savedEventData}
              />

              {/* Mobile Page Title */}
              <h1 className='mobile_title'>{this.getTitle()}</h1>

              {/* Event Type Selector */}
              <EventCreationTypeSelector
                currentArea={this.getCurrentArea()}
                eventType={eventType}
                eventTypes={eventTypes}
                areas={areas}
                disabledEventTypes={this.state.disabledEventTypes}
                eventTags={tags}
                eventPrivacySubtypes={eventPrivacySubtypes}
                privacySubtype={privacySubtype}
                privacySubtypeDisabled={privacySubtypeDisabled}
                stateSocialDistancePolicies={stateSocialDistancePolicies}
                stateLocation={stateLocation}
                onTypeSelectorChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                invalidate={invalidate}
                required
              />
              {/* Event Location */}
              <EventCreationFormLocation
                eventData={this.state}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                invalidate={invalidate}
                isUpdate={isUpdate}
                areasFetched={this.areasFetched}
              />
              {/* Event Details Header */}
              <EventCreationFormHeader
                eventData={this.state}
                paidEventsEnabled={this.state.paidEventsEnabled}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                isCommunity={communityDinner}
                invalidate={invalidate}
                isUpdate={isUpdate}
                stockCoversShufflerNumber={stockCoversShufflerNumber}
                wasPaidEventBeforeEditing={wasPaidEventBeforeEditing}
                maxSeatLimit={numberOfGuestsMaxLimit}
                areasFetched={this.areasFetched}
                session={{ areas, viewer }}
              />
              {/* Event Details */}
              <EventCreationFormDetails
                eventData={this.state}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                invalidate={invalidate}
                minDescriptionLength={this.state.minDescriptionLength}
                maxDescriptionLength={this.state.maxDescriptionLength}
                isUpdate={isUpdate}
                isDuplicate={isDuplicate}
              />
              {/* Community Dinner Cards */}
              {communityDinner && (
                <EventCreationFormCommunityDetails
                  eventData={this.state}
                  onInputChange={this.handleInputChange}
                  onValidate={this.handleInputValidation}
                  invalidate={invalidate}
                  isUpdate={isUpdate}
                />
              )}
              {/* What's For Dinner */}
              {!virtual &&
                !this.state.hideMeal &&
                ((isSite(settings, 'hineni') &&
                  this.state.neighborhood &&
                  this.state.neighborhood.toLowerCase() === 'meal') ||
                  !isSite(settings, ['hineni', 'climatefest'])) && (
                  <EventCreationFormMeal
                    eventData={this.state}
                    onInputChange={this.handleInputChange}
                    onValidate={this.handleInputValidation}
                    invalidate={invalidate}
                    isCommunity={isCommunity}
                    isUpdate={isUpdate}
                    isDraft={isDraft}
                    hidePotluck={hidePotluck}
                  />
                )}
              {/* RSVP Questions */}
              {!this.state.hideGuestQuestions && (
                <EventCreationFormRsvpQuestions
                  eventData={this.state}
                  onInputChange={this.handleInputChange}
                  onValidate={this.handleInputValidation}
                  invalidate={invalidate}
                  hasUpdated={hasUpdated}
                  isUpdate={isUpdate}
                />
              )}
            </form>
          )
        }
      }
    </Query>
    );
  }
}

const StyledEventCreationForm = styled(EventCreationForm)`
  padding: 75px 20px 40px;

  @media (${breakpoints.tablet}) {
    // padding: 120px 20px 40px;
  }

  .mobile_title {
    display: none;
    text-align: center;
    font-size: 30px;
    margin: 30px 0;
    position: relative;

    @media (${breakpoints.tablet}) {
      display: block;
      color: #fff;
      text-shadow: 0 0 11px rgba(0, 0, 0, 0.56);
    }
  }

  &.ot_form {
    .form_group {
      label {
        padding: 0 0 5px;
      }
    }
  }

  .card_title {
    color: ${(props) => props.settings.theme.css.global.colors.primary};
    text-align: center;
    font-size: 21px;
    font-weight: normal !important;
    margin: 0;
  }
`;

const EventCreationFormWithSession = withSession(StyledEventCreationForm);
const EventCreationFormWithRouter = withRouter(EventCreationFormWithSession);
const EventCreationFormWithRouterWithSettings = withSettings(EventCreationFormWithRouter);
const EventCreationFormWithClient = withClient(EventCreationFormWithRouterWithSettings);

export { EventCreationFormWithClient as EventCreationForm };
