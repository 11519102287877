import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useSettings, useEventPageData } from '../../hooks';
import ROUTES from '../../constants/routes';

import {
  hasDescription,
  hasExtraDetails,
  hasTags,
  hasTypes,
  replaceUrlsWithAnchor,
  isSite
} from '../../libs';

import {
  PageSubheader as Header,
  EventPageSubheader as Subheader,
  Html,
  TagsList,
  EventTypeIcons
} from '../components';

export const EventDetails = () => {
  const { eventData, isHost, isRegistered } = useEventPageData();
  const history = useHistory();
  const { settings, copy, event: { extra_details_enabled }, colors } = useSettings();
  const hasPermissionToViewExtraDetails = isSite(settings, ['climatefest']) || isHost || isRegistered;
  const handleTagClick = tag => history.push(`/${ROUTES.TAGS}/${tag.toLowerCase().replace('/', '%2F')}`);

  return eventData ? (
    <>
      <Header>{copy.event.about_the_event_title || 'About the event'}</Header>

      {/* Description */}
      {hasDescription(eventData) && (
        <Container colors={colors}><Html content={eventData.description} /></Container>
      )}

      {/* Extra Details */}
      {hasExtraDetails(eventData) && extra_details_enabled && hasPermissionToViewExtraDetails && (
        <Container colors={colors}>
          <Subheader>{copy.event.extra_details_title}</Subheader>
          <Html content={eventData.extraDetails} />
        </Container>
      )}

      {/* Virtual Location */}
      {eventData.virtual && eventData.virtualLocation && (
        <Container colors={colors}>
          <Subheader>{copy.event.virtual_event_details_title}</Subheader>
          <Container
            colors={colors}
            dangerouslySetInnerHTML={{ __html:
              replaceUrlsWithAnchor(eventData.virtualLocation)
            }}
          />
        </Container>
      )}

      {/* Tags */}
      {hasTags(eventData) && (<TagsList tags={eventData.tags} onTagClick={handleTagClick} />)}

      {/* Type Icons */}
      {hasTypes(eventData) && (<EventTypeIcons eventData={eventData} />)}
    </>
  ) : null;
};

const Container = styled.div`
  font-size: 16px;
  word-break: break-word;
  margin-bottom: 40px;

  & > h3 {
    margin: 0 0 20px;
  }

  p {
    line-height: 1.7;
  }

  ul, ol {
    list-style-position: inside;
    padding: 0;

    br { display: none; }
  }

  a {
    color: ${({ colors }) => colors.primary};
    text-decoration: underline;

    &:hover {
      color: ${({ colors }) => colors.primaryHover};
    }
  }
`;
