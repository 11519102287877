import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getEventIdFromPath, stringInsert } from '../../libs';
import { EventSponsorshipsSkipModal } from '../';
import { Input, Button } from '../Global';
import { withSettings } from '../components';
import { containersWidths, breakpoints } from '../../styles';

class EventSponsorshipsGuestsNumberSelector extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      expectedGuests: props.expectedGuests,
      showSkipModal: false,
      loading: false
    }
  }

  closeSkipModal = () => {
    this.setState({ showSkipModal: false });
  };

  skipThisStepButtonClicked = () => {
    this.setState({ showSkipModal: true });
  };

  chooseLaterButtonClicked = () => {
    const { history, isAfterCreate, event, settings } = this.props;

    if (isAfterCreate) {
      const shouldShowResourcesPage = settings && settings.features && settings.features.event_creation_questions;
      const pathName = shouldShowResourcesPage ? `/events/${event.uuid}/resources` : `/events/${event.uuid}`;

      history.push({
        pathname: pathName,
        state: { isAfterCreate: true }
      });
    } else {
      history.push(`/manage/hosted/${event.uuid}`);
    }
  }

  calculateNourishmentsButtonClicked = () => {
    const { history, location, eventId, isAfterCreate } = this.props;
    const { expectedGuests } = this.state;
    const eventUuid = getEventIdFromPath(location.pathname);

    const mutationInput = {
      eventId: eventId,
      expectedGuests: expectedGuests
    };

    const historyProps = { pathname: `/events/${eventUuid}/nourished` };

    if (isAfterCreate) {
      historyProps.state = { isAfterCreate: true };
    }

    this.setState({ loading: true }, async () => {
      await this.props.updateEventMutation({ variables: mutationInput });
      this.setState({ loading: false }, () => history.push(historyProps));
    });
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  validExpectedGuestsNumber = () => {
    const { minGuestsNumberToQualifyForSponsorships, numberOfGuestsMax } = this.props;
    const { expectedGuests } = this.state;

    return (expectedGuests >= minGuestsNumberToQualifyForSponsorships && expectedGuests <= numberOfGuestsMax);
  }

  render() {
    const { className, eventTitle, perPersonPrice, totalSponsorshipsLimit, eventNourishableDeadlineDate, minGuestsNumberToQualifyForSponsorships, numberOfGuestsMax } = this.props;
    const { showSkipModal, expectedGuests, loading } = this.state;
    const { settings: { copy } } = this.props;

    const description = stringInsert(copy.event.nourished_guests_description, [perPersonPrice, totalSponsorshipsLimit]);
    const descriptionDeadline = eventNourishableDeadlineDate ? stringInsert(copy.event.nourished_guests_description_deadline, [eventNourishableDeadlineDate]) : null;

    return (
      <div className={className}>
        <div className="section-header box-with-shadow ot-fixed-subheader">
          <div className='section-header__body'>
            <h2>{copy.create.get_nourished}</h2>
            <div className='section-header__button-container'>
              <Button
                className="skip-button"
                handleClick={this.skipThisStepButtonClicked}
                buttonStyle="primary_outline">{copy.create.skip_nourished}
              </Button>
            </div>
          </div>
        </div>
        <div className='page_container'>
          <div className='row card center-card guests-card'>
            <h3>{eventTitle ? eventTitle : 'Event Sponsorships'}</h3>
            <p>
              <span>{description}</span>
              <span>{descriptionDeadline}</span>
            </p>
            <p className="bold-font">
              {copy.event.nourished_guests_description_2}
            </p>
            <div className="guests-number-container">
              <Input
                type="number"
                content="integer"
                min={minGuestsNumberToQualifyForSponsorships}
                max={numberOfGuestsMax}
                placeholder=""
                name="expectedGuests"
                value={expectedGuests}
                onInputChange={this.handleInputChange}
                onValidate={this.handleInputValidation}
                required
                errorMessage="Please enter a valid number of expected guests."
              />
              <p className="guests-label">{copy.rsvp.seats}</p>
            </div>
            <Button
              className="calculate-button"
              handleClick={this.calculateNourishmentsButtonClicked}
              type="submit"
              loading={loading}
              disabled={loading || !this.validExpectedGuestsNumber()}
            >
              {copy.event.nourished_guests_submit}
            </Button>
          </div>
        </div>
        <EventSponsorshipsSkipModal
          show={showSkipModal}
          toggle={this.closeSkipModal}
          chooseLaterButtonClicked={this.chooseLaterButtonClicked}
        />
      </div>
    );
  }

}

const StyledEventSponsorshipsGuestsNumberSelector = styled(EventSponsorshipsGuestsNumberSelector)`
  .row {
    justify-content: center;
    align-items: center;
  }

  .calculate-button {
    margin-top: 5px;
  }

  .ot-title-card {
    margin-top: 130px;
    text-align: center;
    max-width: ${containersWidths.default};
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
  }

  .box-with-shadow {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.04), 0 2px 10px rgba(0,0,0,.05);
    border: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    margin-bottom: 20px;
  }

  .section-header {
    width: 100%;
    font-size: 13px;
    height: 66px;
    position: fixed;
    top: 85px;
    display: block;
    z-index: 999;
    visibility: visible;
    transition: all 400ms ease;
    transform: translate(0);

    h2 {
      font-size: 30px;
      margin: 0;

      @media (${breakpoints.tablet}) {
        font-size: 18px;
        padding-top: 5px;
      }
    }

    @media (${breakpoints.tablet}) {
      height: auto;
    }
  }

  .guests-card {
    h3 {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  .section-header__body {
    height: 100%;
    max-width: ${containersWidths.default};
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${breakpoints.tablet}) {
      display: block;
      height: 95px;

      h2 {
        text-align: center;
        margin-top: 13px;
      }
    }
  }

  .section-header__button-container {
    position: absolute;
    height: 66px;
    right: 15px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${breakpoints.tablet}) {
      top: auto;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }

  .card {
    text-align: center;
    color: #4d4d4d;
    padding-bottom: 50px;
    padding-top: 50px;
    margin: 140px 0 40px;
    min-height: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  p {
    font-size: 14px;
  }

  .guests-number-container {
    position: relative;
    width: 120px;
    margin: 20px auto 0;
  }

  input[type="number"] {
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 68px !important;
    text-align: right !important;
  }

  .guests-label {
    position: absolute;
    right: 20px;
    top: 8px;
    // bottom: 0;
    // align-items: center;
    // display: flex;
  }

`;

const StyledEventSponsorshipsGuestsNumberSelectorWithRouter = withRouter(StyledEventSponsorshipsGuestsNumberSelector);
const StyledEventSponsorshipsGuestsNumberSelectorWithRouterWithSettings = withSettings(StyledEventSponsorshipsGuestsNumberSelectorWithRouter);

export { StyledEventSponsorshipsGuestsNumberSelectorWithRouterWithSettings as EventSponsorshipsGuestsNumberSelector };
