import React from 'react';
import styled from 'styled-components';
import { Button, Input, Textarea, Modal } from '../';
import PropTypes from 'prop-types';
import { withSettings } from '../components';


class RsvpQuestionsModal extends React.Component {
  constructor(props) {
    super(props);

    const { settings: { event: { global_reservation_question_answers = '[]' } } } = props;

    let questions = props.event.reservationQuestions.edges.map(question => question.node);

    questions = questions.map(question => {
      if (question.baseQuestion && question.baseQuestion.presenceType && question.baseQuestion.presenceType === 'ALWAYS') {
        const alwaysAnswers = JSON.parse(global_reservation_question_answers);
        const answers = alwaysAnswers && alwaysAnswers.find(a => a.id === question.baseQuestion.id);
        question.options = answers ? answers.options : [];
      }

      return question;
    });

    const answers = questions.reduce((accumulator, question) => {
      const tempAnswers = { ...accumulator };
      tempAnswers[question.id] = '';
      return tempAnswers;
    }, {});

    if (questions.length > 0) {
      this.state = { answers, questions };
    } else {
      this.state = { answers: {}, questions: [] };
    }
  }

  handleInputChange = (name, value) => {
    const answers = {
      ...this.state.answers,
      [name]: value
    }

    this.setState({ answers: answers });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  shouldNextButtonBeDisabled = () => {
    const questionsResponses = this.getQuestionsResponses();
    const unansweredQuestions = questionsResponses.filter(question => question.response.length === 0);
    return (unansweredQuestions.length > 0);
  };

  getQuestionsResponses = () => {
    const { answers } = this.state;

    return Object.keys(answers).map((key) => {
      const answer = answers[key];
      const trimmedAnswer = answer && typeof answer === 'string' ? answer.trim() : '';
      return { questionId: parseInt(key), response: trimmedAnswer };
    });
  };

  nextButtonClicked = () => {
    const { nextButtonClicked } = this.props;

    const questionsResponses = this.getQuestionsResponses();
    nextButtonClicked(questionsResponses);
  };

  getNotDisabledQuestions = (questions) => {
    return questions.filter(question => !question.disabled);
  }

  questionSortFunction = (question1, question2) => {
    if (!question1 && !question2) { return 0; }
    if (!question1 && question2) { return -1; }
    if (question1 && !question2) { return 1; }
    if (question1.position < question2.position) { return -1; }
    if (question1.position > question2.position) { return 1; }
    if (question1.position === question2.position) { return 0; }
  }

  sortQuestions = (questions) => {
    const alwaysQuestions = questions.filter(question => (question && (question.baseQuestion && question.baseQuestion.presenceType === 'ALWAYS')));
    const notAlwaysQuestions = questions.filter(question => (question && (!question.baseQuestion || !question.baseQuestion.presenceType || question.baseQuestion.presenceType !== 'ALWAYS')));
    const sortedAlwaysQuestions = alwaysQuestions.sort(this.questionSortFunction);
    const sortedNotAlwaysQuestions = notAlwaysQuestions.sort(this.questionSortFunction);
    return [...sortedAlwaysQuestions, ...sortedNotAlwaysQuestions];
  }

  render() {
    const { className, show, toggle, backButtonClicked } = this.props;
    const { settings: { copy } } = this.props;
    const { questions, answers } = this.state;
    const notDisabledQuestions = this.getNotDisabledQuestions(questions);
    const sortedQuestions = this.sortQuestions(notDisabledQuestions);
    const disableNextButton = this.shouldNextButtonBeDisabled();

    return (
      <Modal show={show} toggle={toggle} size="md" noPadding>
        <div className={className}>
          <h2>{copy.rsvp.question_modal_title}</h2>
          <p>{copy.rsvp.question_modal_instructions}</p>
          <ul className="rsvp_questions">
            {sortedQuestions.map(question => {
              const name = question.id;
              if (question && question.baseQuestion && question.baseQuestion.presenceType === 'ALWAYS') {
                return (
                  <li key={name} className="always">
                    <label>{question.text}</label>
                    <div className="ot-rsvp-question-options">
                      {question.options.map((option, i) => (
                        <label key={i} className={answers[name] === option.name ? 'selected' : ''} onClick={() => this.handleInputChange(question.id, option.name) }>
                          <span>{option.name}</span>
                          {option.tooltip && <div className="ot-tooltip">{option.tooltip}</div>}
                        </label>
                      ))}
                    </div>
                  </li>
                );
              } else {
                if (question.answerType === 'STRING') {
                  return (
                    <li key={name}>
                      <label>{question.text}</label>
                      <Input
                        name={name}
                        value={answers[name]}
                        placeholder="Your answer"
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        errorMessage="Please enter a valid answer."
                        required
                      />
                    </li>
                  );
                } else if (question.answerType === 'TEXT') {
                  return (
                    <li key={name}>
                      <label>{question.text}</label>
                      <Textarea
                        name={name}
                        value={answers[name]}
                        placeholder="Your answer"
                        onInputChange={this.handleInputChange}
                        onValidate={this.handleInputValidation}
                        errorMessage="Please enter a valid answer."
                        required
                      />
                    </li>
                  );
                } else {
                  return null;
                }
              }
            })}
          </ul>
          <div className="ot-bottom-buttons">
            <Button buttonStyle="primary_outline" handleClick={backButtonClicked} className="float-left">Back</Button>
            <Button buttonStyle="primary" handleClick={this.nextButtonClicked} className="float-right" disabled={disableNextButton}>Continue</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

RsvpQuestionsModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledRsvpQuestionsModal = styled(RsvpQuestionsModal)`
  text-align: center;
  padding: 40px 30px 30px;

  & h2 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 0;
    margin-bottom: 20px;
  }

  & p {
    text-align: center;
    margin: 0;
    // color: #424242;
    font-size: 14px;
  }

  & ul {
    margin: 30px 0 25px;
    padding: 0;
    list-style-type: none;
    text-align: left;
  }

  & li {
    margin-bottom: 15px;
  }

  & label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  & .ot-rsvp-question-options label {
    position: relative;
    cursor: pointer;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-right: 7px;
    transition: background-color 250ms;

    span {
      padding: 10px 15px;
      font-size: 14px;
      line-height: 1.3;
      text-transform: capitalize;
      font-size: 12px;
      display: block;
      transition: color 250ms;
    }
  }

  & .selected {
    background-color: ${props => props.settings.theme.css.global.colors.primary};

    span {
      color: white;
    }
  }

  & .selected .ot-tooltip {
    color: black;
  }

  & .ot-tooltip {
    position: absolute;
    bottom: 100%;
    margin-bottom: -3px;
    font-size: 10px;
    background-color: #fff;
    padding: 7px 5px;
    border: 1px solid #b9b9b9;
    border-radius: 3px;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.13);
  }

  & .ot-rsvp-question-options label:hover .ot-tooltip {
    display: block;
  }

  & .ot-rsvp-question-options label .ot-tooltip {
    right: -20px;
    left: -20px;
    bottom: calc(100% + 7px);
    display: none;
    z-index: 1000;
    text-transform: none;
    font-weight: 400;
    text-align: center;
  }

  & .ot-tooltip:before {
    border: 8px solid;
    border-color: #fff transparent transparent;
    z-index: 1;
  }

  & .ot-tooltip:after, .ot-tooltip:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
  }

  & .ot-tooltip:after {
    border: 9px solid;
    border-color: #b9b9b9 transparent transparent;
    z-index: -1;
  }

  & .float-left {
    float: left;
  }

  & .float-right {
    float: right;
  }

  .ot-bottom-buttons {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ccc;

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
`;

const StyledRsvpQuestionsModalWithSettings = withSettings(StyledRsvpQuestionsModal);

export { StyledRsvpQuestionsModalWithSettings as RsvpQuestionsModal };
