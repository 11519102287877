import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../styles';
import {
  withSettings,
  withSession,
  DonationLink,
  Button,
  Modal,
  PageSubheader as Header,
  ShareFacebook
} from '../components';
import { withRouter } from 'react-router-dom';
import { FacebookProvider, Initialize } from 'react-facebook';
import { generateAddToCalendarUrl } from '../../libs';


class RsvpSuccessModal extends React.Component {

  addToGoogleCalButtonClicked = () => {
    const { event, areas } = this.props;
    const url = generateAddToCalendarUrl(event, areas, 'google');
    window.open(url, '_blank');
  }

  addToICalButtonClicked = () => {
    const { event, areas } = this.props;
    const url = generateAddToCalendarUrl(event, areas, 'ical');
    window.open(url, '_blank');
  }

  shareOnFacebookButtonClicked = () => {
    const { event, settings } = this.props;
    ShareFacebook.openModal(event, settings);
  }

  browseMoreDinnersButtonClicked = () => {
    const { history } = this.props;

    this.closeModal();
    history.push('/');
  }

  closeModal = () => {
    const { toggle } = this.props;

    toggle();
  }

  returnToDinnerButtonClicked = () => {
    this.closeModal();
  }

  render() {
    const {
      className,
      show,
      toggle,
      successMessage: creationSuccessMessage,
      afterMembershipSuccess,
      settings: { copy, theme: themeSettings },
      session: { theme }
    } = this.props;

    const ModalTitle = ({ children }) => theme.isVersion(2)
      ? <Header>{children}</Header>
      : <h2>{children}</h2>;

    const successMessage = afterMembershipSuccess
      ? copy.membership?.membership_success_message || creationSuccessMessage
      : creationSuccessMessage;

    return (
      <FacebookProvider appId={themeSettings.site.facebook_app_id}>
        <Initialize>
          {({ isReady }) => {
            return (
              <Modal show={show} toggle={toggle} size="md" className={className}>
                <Fragment>
                  <div className="success_message">
                    <ModalTitle>{copy.event.success_title}</ModalTitle>
                    {successMessage && <p>{successMessage}</p>}
                  </div>

                  {themeSettings.display_rsvp_donation && (
                    <DonationLink
                      text={copy.rsvp.donation_text}
                      url={copy.rsvp.donation_button_url}
                      buttonText={copy.rsvp.donation_button_text}
                    />
                  )}

                  <div className="ot_form">
                    {isReady && (
                      <div className="form_group">
                        <div>
                          <Button
                            buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                            handleClick={this.shareOnFacebookButtonClicked}
                            fullWidth
                            themed={theme.isVersion(2)}
                          >
                            {copy.event.success_share}
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="form_group">
                      <div className="one_half">
                        <Button
                          buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                          handleClick={this.addToGoogleCalButtonClicked}
                          fullWidth
                          themed={theme.isVersion(2)}
                        >{copy.event.google_cal}
                        </Button>
                      </div>
                      <div className="one_half">
                        <Button
                          buttonStyle={theme.isVersion(2) ? 'outline' : 'primary_outline'}
                          handleClick={this.addToICalButtonClicked}
                          fullWidth
                          themed={theme.isVersion(2)}
                        >
                          {copy.event.ical}
                        </Button>
                      </div>
                    </div>

                    <div className="form_group">
                      <div className="one_half">
                        <Button
                          buttonStyle="primary"
                          handleClick={this.browseMoreDinnersButtonClicked}
                          fullWidth
                          themed={theme.isVersion(2)}
                        >
                          {copy.event.success_browse}
                        </Button>
                      </div>
                      <div className="one_half">
                        <Button
                          buttonStyle="primary"
                          handleClick={this.returnToDinnerButtonClicked}
                          fullWidth
                          themed={theme.isVersion(2)}
                        >
                          {copy.event.success_back}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              </Modal>
            )
          }}
        </Initialize>
      </FacebookProvider>
    );
  }
}

RsvpSuccessModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledRsvpSuccessModal = styled(RsvpSuccessModal)`
  text-align: center;

  h2 {
    ${({ session: { theme }, colors }) => theme.isVersion(2) ? css`
      margin: 0;
    ` : css`
      font-size: 18px;
      color: ${colors.primaryDark};
      margin-top: 0;
      margin-bottom: 30px;
    `}
  }

  p {
    ${({ session: { theme } }) => theme.isVersion(2) ? css`
      margin: 20px 0 0;
    ` : css`
      text-align: center;
      margin: 0;
      color: #424242;
      font-size: 13px;
    `}
  }

  .flex {
    display: flex;
  }

  .ot-button-row {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
  }

  .ot_form {
    margin-top: 40px;
  }

  .form_group {
    &:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }

  .success_message {
    border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  @media (${breakpoints.modal}) {
    .ot_form {
      .form_group {
        .one_half {
          width: 100%;
          padding: 0;

          &:first-child {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
`;

const RsvpSuccessModalWithRouter = withRouter(StyledRsvpSuccessModal);
const RsvpSuccessModalWithRouterWithSettings = withSettings(RsvpSuccessModalWithRouter);
const RsvpSuccessModalWithSession = withSession(RsvpSuccessModalWithRouterWithSettings);
export { RsvpSuccessModalWithSession as RsvpSuccessModal };
