import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../styles';

import { FAIcon, withSettings } from '../components';


const JourneyResource = ({
  className,
  settings,
  url,
  title,
  type,
  image: { image } // pulling position out in css //
}) => {
  const { theme: { assets: { images: { default_resource_background } } } } = settings;

  const ActionText = () => {
    switch (type) {
      case 'playlist':
        return (
          <a href={url} className="text_link" target="_blank" rel="noopener noreferrer">
            <FAIcon name={['fab', 'spotify']} />
            Open playlist in Spotify
          </a>
        );
      case 'resource':
        return (
          <a href={url} className="text_link" target="_blank" rel="noopener noreferrer">
            <FAIcon name={['far', 'book-open']} />
            View Now
          </a>
        );
      case 'video':
        return (
          <a href={url} className="text_link" target="_blank" rel="noopener noreferrer">
            <FAIcon name={['far', 'play']} />
            Watch Now
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <li
      className={className}
      style={{ backgroundImage: `url(${image ? image.originalUrl : default_resource_background})` }}
    >
      <span className="underlay" />
      <div>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <ActionText />
      </div>
      <a
        className="cover_link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      ><span style={{ display: 'none' }}>View Resource</span></a>
    </li>
  );
}

const StyledJourneyResource = styled(JourneyResource)`
  position: relative;
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-position: center;



  ${media.journeyMd`
    height: 45vh;

    ${props => {
      const { horizontalMobilePosition, verticalMobilePosition } = props.image || {};

      if (horizontalMobilePosition === 'START' && verticalMobilePosition === 'START') {
        return css`
          background-position: left top;
        `;
      } else if (horizontalMobilePosition === 'START' && verticalMobilePosition === 'CENTER') {
        return css`
          background-position: left center;
        `;
      } else if (horizontalMobilePosition === 'START' && verticalMobilePosition === 'END') {
        return css`
          background-position: left bottom;
        `;
      } else if (horizontalMobilePosition === 'CENTER' && verticalMobilePosition === 'START') {
        return css`
          background-position: center top;
        `;
      } else if (horizontalMobilePosition === 'CENTER' && verticalMobilePosition === 'CENTER') {
        return css`
          background-position: center center;
        `;
      } else if (horizontalMobilePosition === 'CENTER' && verticalMobilePosition === 'END') {
        return css`
          background-position: center bottom;
        `;
      } else if (horizontalMobilePosition === 'END' && verticalMobilePosition === 'START') {
        return css`
          background-position: right top;
        `;
      } else if (horizontalMobilePosition === 'END' && verticalMobilePosition === 'CENTER') {
        return css`
          background-position: right center;
        `;
      } else if (horizontalMobilePosition === 'END' && verticalMobilePosition === 'END') {
        return css`
          background-position: right bottom;
        `;
      }
    }}
  `}

  & > div {
    position: relative;
    box-shadow: inset 2px 6px 17px -2px rgba(0,0,0,0.45);
    padding: 30px;
    height: 100%;
    z-index: 2;
  }

  h2,
  .text_link {
    position: absolute;
    letter-spacing: -0.025em;
    color: #fff;
    left: 10%;
    right: 10%;
  }

  h2 {
    font-family: 'Avenir-Bold' !important;
    top: 50%;
    font-size: 48px;
    line-height: 1;
    transform: translateY(-50%);
    text-shadow: 0 0 6px rgba(0,0,0,0.4);

    ${media.journeyLg`
      font-size: 42px;
    `}

    ${media.resourcesMd`
      font-size: 38px;
    `}

    ${media.md`
      font-size: 30px;
    `}

    ${media.journeyMd`
      font-size: 40px;
    `}
  }

  .text_link {
    font-family: 'Avenir-Light' !important;
    bottom: 8%;
    font-size: 20px;
    font-weight: normal;
    text-shadow: 0 0 6px rgba(0,0,0,0.4);
    text-decoration: none;

    ${media.journeyLg`
      font-size: 18px;
    `}

    ${media.journeyMd`
      font-size: 22px;
    `}

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      transform: translateY(-2px);
    }
  }

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background-color: #0d0d0d;
    transition: opacity 250ms;
  }

  &:hover {
    .underlay {
      opacity: 0.1;
    }
  }

  .cover_link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    ${media.journeyMd`
      display: none;
    `}
  }
`;
const JourneyResourceWithSettings = withSettings(StyledJourneyResource);
export { JourneyResourceWithSettings as JourneyResource };
