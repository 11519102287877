import React from 'react';
import { SettingsContext } from '../SettingsContext';

const withSettings = Component => props => (
  <SettingsContext.Consumer>
    {({ settings }) => (
      <Component
        {...props}
        settings={settings}
        colors={settings.theme.css.global.colors}
        images={settings.theme.assets.images} 
      />
    )}
  </SettingsContext.Consumer>
);

export { withSettings };
