import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { UPDATE_INVITATION } from '../../mutations';
import { INVITATION_STATUS } from '../../enums';
import { withSettings, Spinner } from '../components';

class ManageEventsDetailsChangeInvitation extends React.Component {
  state = { loading: false };

  cancelInvitationButtonClicked = (updateInvitationMutation) => {
    this.updateInvitationStatus(updateInvitationMutation, INVITATION_STATUS.REJECTED, 'rejected');
  }

  resendInvitationButtonClicked = (updateInvitationMutation) => {
    this.updateInvitationStatus(updateInvitationMutation, INVITATION_STATUS.PENDING, 'pending');
  }

  updateInvitationStatus = async (updateInvitationMutation, status, action) => {
    const { invitation, refetch } = this.props;

    if (!invitation.invitationId) return;

    const id = invitation.invitationId;

    this.setState({ loading: true });
    await updateInvitationMutation({ variables: { id, status } })
      .catch(error => console.error(error));
    this.setState({ loading: false });

    refetch({ id, action, type: 'invitation' });
  }

  render() {
    const { loading } = this.state;
    const { action } = this.props;

    return (
      <Mutation mutation={UPDATE_INVITATION}>
        {(updateInvitation) => {
          const { settings: { copy } } = this.props;

          if (loading) return <Spinner />;

          return (
            <div>
              {action === 'pending' && (
                <CancelResendInvitationButton
                  onClick={() => this.cancelInvitationButtonClicked(updateInvitation)}>
                  {copy.manage.cancel_invitation}
                </CancelResendInvitationButton>
              )}
              {action === 'rejected' && (
                <CancelResendInvitationButton
                  onClick={() => this.resendInvitationButtonClicked(updateInvitation)}>
                  {copy.manage.resend_invitation}
                </CancelResendInvitationButton>
              )}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

const StyledManageEventsDetailsChangeInvitation = styled(ManageEventsDetailsChangeInvitation)``;

const ManageEventsDetailsChangeInvitationWithSettings = withSettings(StyledManageEventsDetailsChangeInvitation);

export { ManageEventsDetailsChangeInvitationWithSettings as ManageEventsDetailsChangeInvitation };

const CancelResendInvitationButton = withSettings(styled.span`
  margin: 0;
  color: ${props => props.settings.theme.css.global.colors.primary};
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    color: ${props => props.settings.theme.css.global.colors.primaryHover}
  }
`);
