import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { SessionContext } from '../../../Context';
import { EVENT_SUBTYPES } from '../../../queries';

import stateLocationOptions from '../../../constants/stateLocationOptions';
import canadianStateLocationOptions from '../../../constants/canadianStateLocationOptions';

import {
  AreaSelector,
  Card,
  InfoTooltip,
  Input,
  SelectBox,
  withClient,
  withSession,
  withSettings
} from '../../components';

import {
  appendTimezoneOffset,
  getSiteSubNameFromSettings,
  invalidateNull,
  invalidateNumber,
  invalidateText,
  isSite,
  sort,
  whitelabel
} from '../../../libs';

import { breakpoints } from '../../../styles';

const defaultSubtype = { other: false };

const EventCreationFormLocation = props => {
  const {
    className,
    eventData,
    onInputChange,
    onValidate,
    invalidate,
    isUpdate,
    settings,
    session: { timezones },
    areasFetched,
    embed = false,
    client
  } = props;
  const {
    copy,
    event,
    global: { display_timezone_offsets, enable_canadian_provinces },
    features: { enable_area_user_connection }
  } = settings;
  const siteSubName = getSiteSubNameFromSettings(settings);

  const { data: subtypesData, loading: subtypesLoading } = useQuery(EVENT_SUBTYPES, { client, errorPolicy: 'all' });
  const eventSubtypes = subtypesData && subtypesData.eventSubtypes;
  const eventSubtype = eventSubtypes && eventSubtypes.find(type => type.id === eventData.eventSubtype);

  const shouldRequireState = event.require_state_location_for_other_areas;

  const [area, setArea] = useState(null);
  const [subtype, setSubtype] = useState(defaultSubtype);
  const [neighborhood, setNeighborhood] = useState(null);
  const [campus, setCampus] = useState(null);

  const areaNeighborhoodsProperty = enable_area_user_connection ? 'campuses' : 'neighborhoods'
  const neighborhoodState = enable_area_user_connection ? campus : neighborhood

  const { session } = useContext(SessionContext);
  const areas = session.areas ?? [];
  const sortedAreas = sort.byObjKey(areas, 'position');

  if (!isUpdate && !area) {
    areasFetched(sortedAreas);
  }

  useEffect(() => {
    if (eventData) {
      setArea(eventData.area);
      setNeighborhood(eventData.neighborhood);
      setCampus(eventData.campus);
    }
  }, [eventData]);

  useEffect(() => {
    setSubtype(eventSubtype);
  }, [eventSubtype]);

  const handleAreaSelect = (name, value) => {
    onInputChange({
      [name]: value,
      timezone: value.timezone,
      neighborhood: null,
      campus: null,
      subneighborhood: null
    });

    setArea(value);
  };

  const handleNeighborhoodSelect = (name, value) => {
    onInputChange({
      [name]: value,
      subneighborhood: null
    });
    if (enable_area_user_connection) {
      setCampus(value);
    } else {
      setNeighborhood(value);
    }
  };

  const handleSubtypeSelect = (name, value) => {
    const newSubtype = eventSubtypes.find(type => type.id === value);

    onInputChange({
      [name]: value,
      [`${name}Other`]: '',
      [`${name}HasOther`]: newSubtype && newSubtype.other
    });
    setSubtype(newSubtype || defaultSubtype);
  };

  const getTimezones = () => {
    return timezones.map(zone => ({
      id: zone.name,
      label: appendTimezoneOffset(zone, display_timezone_offsets),
      value: zone.name
    }));
  };

  const getEventSubtypes = () => {
    return eventSubtypes.filter(type => !type.disabled).map(type => ({
      id: type.id,
      label: type.name,
      value: type.id
    }));
  };

  const getEventSubtype = () => {
    return {
      id: eventSubtype.id,
      label: eventSubtype.name,
      value: eventSubtype.id
    };
  };

  const getNeighborhoods = () => {
    if (enable_area_user_connection) {
      const neighborhoods = (area && area.campuses) || [];

      return neighborhoods
        ? neighborhoods.map(hood => ({
            id: hood.id,
            label: hood.label,
            value: hood
          }))
        : null;
    } else {
      const areaWithNeighborhoods = areas.find(a => a.neighborhoods && a.neighborhoods.length);
      const neighborhoods = (isSite(settings, 'thegathering')
        ? areaWithNeighborhoods && areaWithNeighborhoods.neighborhoods
        : area && area.neighborhoods) || [];

      return neighborhoods
        ? neighborhoods.map((hood, i) => ({
            id: i,
            label: hood.label,
            value: hood.code
          }))
        : null;
    }
  };

  const getSubNeighborhoods = () => {
    if (area && neighborhood) {
      const filteredHood = area.neighborhoods.filter(hood => hood.code === neighborhood);
      const hood = filteredHood.length ? filteredHood[0] : null;

      if (hood && Array.isArray(hood.subneighborhoods) && hood.subneighborhoods.length) {
        return hood.subneighborhoods.map((subhood, i) => ({
          id: i,
          label: subhood.label,
          value: subhood.code
        }));
      }
    }

    return null;
  };

  const getDefaultNeighborhoodValue = () => {
    const defaultNeighborhood = { label: null, value: null };

    if (!neighborhoodState) {
      return defaultNeighborhood;
    }

    const neighborhoods = getNeighborhoods();
    const selectedNeighborhood = neighborhoods.find(n => enable_area_user_connection
      ? n.value.id == neighborhoodState.id
      : n.value == neighborhoodState
    );

    return selectedNeighborhood || defaultNeighborhood;
  };

  const getDefaultSubneighborhoodValue = () => {
    const subneighborhood = eventData ? eventData.subneighborhood : null;
    const defaultSubNeighborhood = { label: null, value: null };
    const subneighborhoods = getSubNeighborhoods();

    if (!area || !subneighborhoods || subneighborhoods.length === 0) {
      return null;
    }

    const selectedSubneighborhood = subneighborhoods.find(item => item.value === subneighborhood);

    if (selectedSubneighborhood) {
      return selectedSubneighborhood;
    } else {
      return defaultSubNeighborhood;
    }
  };

  const isOtherCity = () => {
    // no (other) areas in climate fest //
    return isSite(settings, ['signaturefd']) ||
      (eventData.area && !eventData.area.timezone && !isSite(settings, ['climatefest'])) ||
        eventData?.area?.code === 'OTHER';
  };

  const hasNeighborhoods = () => {
    const areaWithNeighborhoods = areas.find(a => a[areaNeighborhoodsProperty] && a[areaNeighborhoodsProperty].length);

    const neighborhoods = (isSite(settings, 'thegathering')
      ? areaWithNeighborhoods && areaWithNeighborhoods[areaNeighborhoodsProperty]
      : eventData && eventData.area && eventData.area[areaNeighborhoodsProperty]) || [];

    return !!neighborhoods.length;
  };

  const getAreaClassName = () => {
    if (isSite(settings, ['hillel', 'whitelabel', 'thegathering', 'limitedwhitelabel', 'hineni'])) {
      return subtype && subtype.other ? 'one_third' : 'one_half';
    } else {
      return 'one_half';
    }
  };

  const areaProfile = session?.viewer?.user?.profile?.areaProfile ?? [];
  const disableByAreaProfile = enable_area_user_connection && !!areaProfile.length;

  const AreaField = () => (
    <div className={getAreaClassName()}>
      {/* City */}
      <label>{copy.create.city}</label>
      <AreaSelector
        name="area"
        areas={sortedAreas}
        eventData={eventData}
        onInputChange={(name, value) => handleAreaSelect(name, value)}
        invalidate={() => invalidate && invalidateNull(eventData.area)}
        onValidate={onValidate}
        errorMessage={
          isSite(settings, ['hillel', 'whitelabel'])
            ? (siteSubName === 'hga' ? 'Select a campus.' : 'Select a category.')
            : copy.create.area_field_error_message
        }
        isUpdate={isUpdate}
        disabled={disableByAreaProfile || isUpdate}
      />
    </div>
  );

  const OtherAreaField = () => (
    <div className={isSite(settings, ['climatefest', 'signaturefd']) ? 'one_third' : 'one_half'}>
      {/* Other City Name */}
      <label>{copy.create.city_other}</label>
      <Input
        name="areaOther"
        value={eventData.areaOther}
        onInputChange={onInputChange}
        onValidate={onValidate}
        invalidate={() => !eventData.virtual && invalidate && invalidateText(eventData.stateLocation)}
        errorMessage="Enter a city."
        required={isOtherCity() && !eventData.virtual && !isSite(settings, ['signaturefd'])}
      />
    </div>
  );

  const stateOptions = [
    ...stateLocationOptions,
    ...(enable_canadian_provinces ? canadianStateLocationOptions : [])
  ];
  const selectedState = stateOptions.find(s => s[0] === eventData.stateLocation);

  const getStateLocationLabel = () => (isSite(settings, 'homecoming')
    ? 'State/Province (US & Canada Only)'
    : copy.create.state_location
  ) || 'State';

  const StateLocationField = () => (
    <div className={isSite(settings, ['climatefest', 'signaturefd']) ? 'one_third' : 'one_half'}>
      <label>{getStateLocationLabel()}</label>
      <SelectBox
        name="stateLocation"
        defaultValue={{
          label: selectedState ? selectedState[1] : null,
          value: selectedState ? selectedState[0] : null
        }}
        options={stateOptions.map(state => ({
          id: state[0],
          label: state[1],
          value: state[0],
        }))}
        onOptionSelect={value => onInputChange('stateLocation', value)}
        placeholder="---"
        onValidate={onValidate}
        invalidate={() => invalidate && invalidateText(eventData.stateLocation)}
        errorMessage="Select a state."
        required={isOtherCity() && shouldRequireState}
      />
    </div>
  );

  const timezone = Array.isArray(timezones) && timezones.find(tz => tz.name === eventData.timezone);

  const TimezoneField = halfWidth => (
    <div className={isSite(settings, ['climatefest', 'whitelabel', 'limitedwhitelabel', 'signaturefd', 'haggadot']) && !halfWidth ? 'one_third' : 'one_half'}>
      {/* Timezone */}
      <label>{copy.create.timezone}</label>
      {isOtherCity() ? (
        <SelectBox
          name="timezone"
          options={!timezones ? null : getTimezones()}
          onOptionSelect={value => onInputChange('timezone', value)}
          placeholder="---"
          defaultValue={eventData.timezone}
          onValidate={onValidate}
          invalidate={() => invalidate && isOtherCity() && invalidateText(eventData.timezone)}
          disabled={!timezones}
          required
        />
      ) : (
        <Input
          placeholder="---"
          value={timezone ? appendTimezoneOffset(timezone, display_timezone_offsets) : (eventData.timezone || null)}
          disabled
        />
      )}
    </div>
  );
  const siteWithoutSubNeighborhoodField = ['hillel.sandbox', 'hillel.michigan', 'hillel.international'];
  const isSiteWithoutSubNeighborhoods = isSite(settings, siteWithoutSubNeighborhoodField, true);

  const NeighborhoodField = () => {
    const neighborhoodFieldName = enable_area_user_connection ? 'campus' : 'neighborhood';
    const neighborhoodIsRequired = settings.event.mandatory_neighborhood;
    const neighborhoodClass = isSite(settings, 'signaturefd')
      ? 'one_third'
      : (whitelabel.hasFeature(settings, 'neighborhoodOnly') || isSiteWithoutSubNeighborhoods ? '' : 'one_half');

    return (
      <div className={neighborhoodClass}>
        {/* Neighborhood */}
        <label>{copy.create.neighborhood}</label>
        <SelectBox
          name={neighborhoodFieldName}
          defaultValue={getDefaultNeighborhoodValue()}
          options={getNeighborhoods()}
          onOptionSelect={value => handleNeighborhoodSelect(neighborhoodFieldName, value)}
          placeholder="---"
          invalidate={() => invalidate && neighborhoodIsRequired && !neighborhoodState}
          disabled={isSite(settings, 'signaturefd') && !hasNeighborhoods()}
          required={!isSite(settings, 'signaturefd') && neighborhoodIsRequired && hasNeighborhoods()}
        />
      </div>
    );
  };

  const NeighborhoodFields = () => {
    const subNeighborhoods = getSubNeighborhoods();
    return (
      <div className="form_group break_on_tablet_small">
        {NeighborhoodField()}
        {!whitelabel.hasFeature(settings, 'neighborhoodOnly') && !isSite(settings, siteWithoutSubNeighborhoodField, true) &&
          (subNeighborhoods && subNeighborhoods.length > 0) && (
          <div className="one_half">
            {/* SubNeighborhood */}
            <label>{copy.create.sub_neighborhood}</label>
            <SelectBox
              name="subneighborhood"
              defaultValue={getDefaultSubneighborhoodValue()}
              options={subNeighborhoods}
              onOptionSelect={value => onInputChange('subneighborhood', value)}
              placeholder="---"
            />
          </div>
        )}
      </div>
    );
  }

  const SubTypeField = () => (
    <div className={subtype && subtype.other && !isSite(settings, 'dinner-party') ? 'one_third' : 'one_half'}>
      {/* Sub Type */}
      <label>{copy.create.subtype || 'Sub Type'}</label>
      <SelectBox
        name="eventSubtype"
        options={subtypesLoading || !eventSubtypes ? null : getEventSubtypes()}
        onOptionSelect={value => handleSubtypeSelect('eventSubtype', value, eventSubtypes)}
        placeholder="---"
        defaultValue={eventSubtype && getEventSubtype()}
        onValidate={onValidate}
        invalidate={() => invalidate && invalidateNumber(eventData.eventSubtype)}
        errorMessage={siteSubName === 'hga' ? 'Select an experience type.' : 'Select a sub category.'}
        required
      />
    </div>
  );

  const renderContent = () => (
    <>
      {!embed && (
        <>
          <h2 className="card_title">{copy.event.place_title}</h2>
          <i className="divider"/>
        </>
      )}

      {/* temp for WL */}
      <Fragment>
        <div className="form_group break_on_tablet_small">

          {AreaField()}

          {isSite(settings, [
            'hillel',
            'whitelabel',
            'climatefest',
            'thegathering',
            'limitedwhitelabel',
            'hineni',
            'signaturefd',
            'haggadot',
            'centralsynagogue'
          ]) ? (
            <>
              {SubTypeField()}
              {subtype && subtype.other && (
                <div className="one_third">
                  {/* Sub Type */}
                  <label>{copy.create.subtype_other || 'Other'}</label>
                  <Input
                    name="eventSubtypeOther"
                    value={eventData.eventSubtypeOther}
                    onInputChange={onInputChange}
                    onValidate={onValidate}
                    invalidate={() => invalidate && subtype.other && invalidateText(eventData.eventSubtypeOther)}
                    errorMessage="Enter an other sub category."
                    required
                  />
                </div>
              )}
            </>
          ) : TimezoneField()}
        </div>

        {isOtherCity() &&
          (!isSite(settings, ['climatefest', 'whitelabel', 'limitedwhitelabel', 'hineni', 'signaturefd']) ||
          (isSite(settings, ['climatefest', 'hineni']) && !eventData.virtual)) && (
          <div className="form_group break_on_tablet_small">
            {OtherAreaField()}
            {StateLocationField()}
            {(isSite(settings, ['climatefest']) || isSite(settings, ['hillel.international'], true)) && TimezoneField()}
          </div>
        )}

        {!isSite(settings, 'signaturefd') && hasNeighborhoods() && NeighborhoodFields()}

        {isSite(settings, ['signaturefd']) && !eventData.virtual && (
          <div className="form_group break_on_tablet_small">
            {NeighborhoodField()}
            {OtherAreaField()}
            {StateLocationField()}
          </div>
        )}

        {eventData.virtual
          ? (!eventData.hideLocation && (
            <div className="form_group break_on_tablet_small">
              <div className={isSite(settings, ['climatefest', 'whitelabel', 'limitedwhitelabel', 'haggadot']) ? 'two_thirds' : ''}>
                {/* Virtual Location  */}
                <label className="with_tooltip">
                  {copy.create.virtual_location}
                  <InfoTooltip content={copy.event.virtual_location_tooltip}/>
                </label>
                <Input
                  type="text"
                  name="virtualLocation"
                  value={eventData.virtualLocation}
                  onInputChange={onInputChange}
                />
              </div>
              {isSite(settings, ['climatefest', 'whitelabel', 'limitedwhitelabel', 'haggadot']) && TimezoneField()}
            </div>
          )) : (!eventData.hideLocation && !isSite(settings, ['climatefest']) && (
            <Fragment>
              <div className="form_group break_on_tablet_small">
                <div className="one_half">
                  {/* Address */}
                  <label className="has_info">
                    {copy.create.address}
                    <span className="info">{copy.create.only_confirmed}</span>
                  </label>
                  <Input
                    type="text"
                    name="fullDress"
                    value={eventData.fullDress}
                    onInputChange={onInputChange}
                  />
                </div>
                {isSite(settings, ['whitelabel', 'limitedwhitelabel']) ? (
                  OtherAreaField()
                ) : (
                  isSite(settings, ['haggadot'])
                    ? TimezoneField()
                    : (
                    <div className="one_half">
                      {/* Secondary Address */}
                      <label>{copy.create.address_secondary}</label>
                      <Input
                        type="text"
                        name="secondaryDress"
                        value={eventData.secondaryDress}
                        onInputChange={onInputChange}
                      />
                    </div>
                  )
                )}
              </div>

              {isSite(settings, ['whitelabel', 'limitedwhitelabel']) && (
                <div className="form_group break_on_tablet_small">
                  {StateLocationField()}
                  {TimezoneField(true)}
                </div>
              )}
            </Fragment>
          ))}

          {isSite(settings, ['dinner-party']) && (
            <div className="form_group break_on_tablet_small">
              {SubTypeField()}
            </div>
          )}
      </Fragment>
    </>
  );

  if (embed) return <div className="embedded_location">{renderContent()}</div>;
  else return <Card className={className}>{renderContent()}</Card>;
};

const StyledEventCreationFormLocation = styled(EventCreationFormLocation)`
  position: relative;
  z-index: 20;

  @media (${breakpoints.tabletSmall}) {
    .radio_wrapper {
      margin: 0 !important;
    }
  }

  @media (${breakpoints.mobile}) {
    .form_group {
      &.radios {
        .one_half {
          &:first-of-type {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
`;

const EventCreationFormLocationWithSettings = withSettings(StyledEventCreationFormLocation);
const EventCreationFormLocationWithClient = withClient(EventCreationFormLocationWithSettings);
const EventCreationFormLocationWithSession = withSession(EventCreationFormLocationWithClient);
export { EventCreationFormLocationWithSession as EventCreationFormLocation };
