import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { isEmail } from 'react-multi-email';

import { SHARE_EVENT_RESOURCES, SHARE_EVENT_RESOURCES_WITH_GUESTS } from '../../mutations';

import {
  Modal,
  UserEmailSelector,
  WYSIWYGEditor,
  ButtonToggle,
  Button,
  withSettings
} from '../components';


const ShareResourcesModal = ({ className, show, toggle, event, hasReservations, settings }) => {
  const { copy } = settings;
  const [shareEventResources] = useMutation(SHARE_EVENT_RESOURCES);
  const [shareEventResourcesWithGuests] = useMutation(SHARE_EVENT_RESOURCES_WITH_GUESTS);
  const [showShareConfirm, setShowShareConfirm] = useState(false);
  const [message, setMessage] = useState('');
  const [emails, setEmails] = useState([]);
  const [mode, setMode] = useState(hasReservations ? 'guests' : 'manual');
  const [valid, setValid] = useState(hasReservations);
  const [loading, setLoading] = useState(false);

  const eventId = event && event.id;

  const clearFields = () => {
    setMessage('');
    setEmails([]);
    setMode(hasReservations ? 'guests' : 'manual');
  };

  const handleToggleButton = value => {
    setMode(value);
    setValid(value === 'manual' && !emails.length ? false : true);
  };

  const handleSetEmails = value => {
    setEmails(value);
    setValid(value.length > 0);
  };

  const handleShareResources = async () => {
    const variables = { eventId, message };
    let shareResources = shareEventResourcesWithGuests;

    if (mode !== 'guests') {
      shareResources = shareEventResources;
      variables.emailAddresses = emails;
    }

    setLoading(true);
    await shareResources({ variables });
    setLoading(false);
    toggle();
    clearFields();
    setShowShareConfirm(true);
  };

  const handleToggleShareConfirm = () => {
    setShowShareConfirm(prev => !prev);
  };

  return (
    <>
      <Modal
        show={show}
        toggle={toggle}
        size="md"
        noPadding
      >
        <div className={className}>
          <h2>{copy.create.share_resources_title || 'Share My Resources'}</h2>
          <i className="divider"/>
          {hasReservations && (
            <ButtonToggle
              name="mode"
              activeButton={mode}
              onToggleButton={handleToggleButton}
              labels={[
                { label: 'Send To My Guests', value: 'guests' },
                { label: 'Send To My Friends', value: 'manual' }
              ]}
              halfWidth
            />
          )}
          {mode === 'manual' && (
            <UserEmailSelector
              name="emails"
              allowMultipleEmails={true}
              emails={emails}
              onAddEmail={handleSetEmails}
              onValidate={isEmail}
              errorMessage="Enter a valid email."
              placeholder="friend@domain.com"
            />
          )}
          <WYSIWYGEditor
            name="message"
            value={message}
            onInputChange={(name, value) => setMessage(value)}
            placeholder={copy.manage.share_resources_message_placeholder || ''}
            dontShowInvalid
          />
          <Button
            fullWidth
            disabled={!valid || loading}
            loading={loading}
            handleClick={handleShareResources}
          >Share Resources</Button>
        </div>
      </Modal>
      <Modal
        toggle={handleToggleShareConfirm}
        show={showShareConfirm}
        size="sm"
        noPadding
      >
        <div style={{ padding: '40px 20px 30px', textAlign: 'center' }}>
          <p style={{ marginBottom: '20px' }}>Your resources have been successfully shared.</p>
          <Button handleClick={handleToggleShareConfirm} style={{ width: '80px' }}>
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
};

const StyledShareResourcesModal = styled(ShareResourcesModal)`
  padding: 40px 30px 30px;

  h2 {
    font-size: 22px;
    margin: 0 0 20px;
    text-align: center;
  }

  .button_toggle,
  .wysiwyg-editor {
    margin-bottom: 14px;
  }
`;
const ShareResourcesModalWithSettings = withSettings(StyledShareResourcesModal);
export { ShareResourcesModalWithSettings as ShareResourcesModal };
