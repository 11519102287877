import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { SessionContext } from '../../Context';
import { DropDownMenu } from '../components';
import { stringInsert, sort, isSite } from '../../libs';
import { CustomMenuItems, withSettings } from '../components';


class EventListAreaSelector extends React.Component {
  state = { showMenu: false };

  updateAreaSelectorState = () => {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }));
  };

  handleAreaClick = (e, area) => {
    const { archive, history } = this.props;
    e.stopPropagation();

    this.setState({ showMenu: false }, () => {
      history.push(`/${archive ? 'archive/' : ''}landing/${area.url}`);
    });
  };

  handleRedirect = (e, path) => {
    const { history } = this.props;
    e.stopPropagation();
    this.setState({ showMenu: false }, () => history.push(path));
  };

  handleCustomAreaClick = e => {
    e.stopPropagation();
    this.setState({ showMenu: false });
  };

  renderAreaDropdown = areas => {
    const { area: currentArea, settings: { copy, event: { virtual } }, archive } = this.props;
    const customMenuItems = JSON.parse(copy.menu.additional_area_menu_items || '[]');

    return (
      <DropDownMenu className="selector_dropdown" backdrop fullHeight>
        {/* virtual events page */}
        {!archive && virtual.display_virtual_menu_link && (
          <li onClick={e => this.handleRedirect(e, '/virtual')} key="virtual">
            <span>{copy.menu.virtual_area_link_text}</span>
          </li>
        )}
        {/* custom links */}
        {!archive && customMenuItems && (
          <CustomMenuItems
            items={customMenuItems.filter(item => item.pos === 'top')}
            onToggleMenu={e => this.handleCustomAreaClick(e)}
            className="top"
          />
        )}
        {/* all archive page */}
        {archive && (
          <li onClick={e => this.handleRedirect(e, '/archive')} key="archive">
            <span>{copy.menu.all_archive_link_text || 'All'}</span>
          </li>
        )}
        {/* area links */}
        {areas && areas.map(area => {
          if (currentArea.id === area.id) return null;

          return (
            <li
              key={area.id}
              onClick={(e) => this.handleAreaClick(e, area)}
              className="area"
            >
              <span>{area.label}</span>
            </li>
          );
        })}
        {/* custom links */}
        {!archive && customMenuItems && (
          <CustomMenuItems
            items={customMenuItems.filter(item => item.pos !== 'top')}
            onToggleMenu={e => this.handleCustomAreaClick(e)}
          />
        )}
      </DropDownMenu>
    );
  };

  getLabelCopy = count => {
    const { archive, settings } = this.props;
    const { copy } = settings;

    const getSiteCopy = c => {
      // temp until setting is added //
      if (isSite(settings, 'hillel')) {
        return c || 'There are %1$s archived experiences in ';
      } else if (isSite(settings, 'thegathering')) {
        return c || 'There are %1$s archived gatherings in ';
      } else if (isSite(settings, 'limitedwhitelabel')) {
        return c || 'There are %1$s archived events in ';
      } else if (isSite(settings, 'climatefest')) {
        return c || 'There are %1$s archived events in ';
      } else {
        return c || 'There are %1$s archived dinners in ';
      }
    };

    const countCopy = archive
      ? getSiteCopy(copy.landing.archive_summary)
      : copy.landing.summary;

    return stringInsert(countCopy, count);
  };

  render() {
    const { className, totalEventsCount, area, loading } = this.props;
    const { showMenu } = this.state;
    const placeholderCopy = this.getLabelCopy('..');
    const summaryCopy = this.getLabelCopy(totalEventsCount);

    return (
      <SessionContext.Consumer>
        {({ session }) => {
          const { areas } = session;
          const sortedAreas = sort.byObjKey(areas, 'position');
          const showAreasDropdownLink = sortedAreas.length > 1;

          return (
            <div className={className}>
              <div>
                {areas ? (
                  <Fragment>
                    {loading ? placeholderCopy : summaryCopy}
                    <span
                      className="dropdown_container"
                      onClick={this.updateAreaSelectorState}
                    >
                      {!showAreasDropdownLink && area.label}
                      {showAreasDropdownLink && <label>{area.label}</label>}
                      {showAreasDropdownLink && showMenu && this.renderAreaDropdown(sortedAreas)}
                    </span>.
                  </Fragment>
                ) : (
                  <Fragment>
                    {loading ? placeholderCopy : summaryCopy}
                    <span className="dropdown_container">
                      <label className="no_pointer">{area.label}</label>
                    </span>.
                  </Fragment>
                )}
              </div>
            </div>
          );
        }}
      </SessionContext.Consumer>
    );
  }
}

const StyledEventListAreaSelector = styled(EventListAreaSelector)`
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;

  & > div {
    display: inline-block;
  }

  .dropdown_container {
    position: relative;
    z-index: 100;

    label {
      color: ${({ colors }) => colors.primary};
      text-decoration: underline;
      transition: color 250ms;

      &:not(.no_pointer) {
        cursor: pointer;

        &:hover {
          color: ${({ colors }) => colors.primaryHover};
        }
      }
    }
  }

  .selector_dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 5;
    text-align: left;

    ul > li > span,
    ul > li > a {
      font-size: 12px;
    }

    @media (max-width: 800px) {
      .dropdown_menu {
        right: 0;
      }
    }
  }
`;

const EventListAreaSelectorWithRouter = withRouter(StyledEventListAreaSelector);
const EventListAreaSelectorWithRouterWithSettings = withSettings(EventListAreaSelectorWithRouter);

export { EventListAreaSelectorWithRouterWithSettings as EventListAreaSelector };
