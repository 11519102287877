import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { withSettings } from '../../components';

import { FilterContext } from '../FilterContext';


export const ToggleSearchFilter = ({ name }) => {
  const { getValue, setValue } = useContext(FilterContext);

  return (
    <ToggleContainer active={getValue(name)} onClick={() => setValue(name, !getValue(name) || null)}>
      <ToggleHandle active={getValue(name)} />
    </ToggleContainer>
  );
};

const ToggleContainerWithoutSettings = styled.div`
  position: relative;
  width: 65px;
  height: 42px;
  background-color: #ceccc7;
  /* background-color: #e3e3e1; */
  border-radius: 20px;
  transition: background-color 250ms;
  cursor: pointer;

  ${props => props.active && css`
    background-color: ${props.settings.theme.css.global.colors.primary};
  `}
`;
const ToggleContainer = withSettings(ToggleContainerWithoutSettings);

const ToggleHandle = styled.div`
  position: absolute;
  top: 50%;
  left: 3px;
  margin-top: -18px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  transition: left 250ms;

  ${props => props.active && css`
    left: calc(100% - 39px);
  `}
`;
