import React, { useState } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { Modal, Button, withSettings, alert, WYSIWYGEditor } from '../components';
import { ACCEPT_RESERVATION, DECLINE_RESERVATION } from '../../mutations';

import { RESERVATION_ACTIONS as ACTIONS } from '../../hooks/usePaginatedReservations';

const ManageEventsDetailsAcceptDenyReservationModal = (props) => {
  const { className, show, toggle, reservation, action, refetchData } = props;
  const { settings: { copy } } = props;

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleMessageChange = (name, value) => {
    if (name === 'message') setMessage(value);
  };

  const approveOrDenyResevation = async (mutation) => {
    const id = reservation.reservationId;
    const variables = { id, message };

    setLoading(true);
    const response = await mutation({ variables, errorPolicy: 'all' });

    if (response.errors) {
      setLoading(false);
      const code = response.errors[0]?.extensions.code;
      const errorMessage = response.errors[0]?.message;
      alert.error(copy.rsvp[`accept_${code.toLowerCase()}`] || errorMessage);
      return;
    }

    await refetchData({ id, action, type: 'reservation' });

    if (action === ACTIONS.ACCEPTED) {
      setMessage('');
    }

    alert.success(`Reservation ${action === ACTIONS.ACCEPTED ? 'approved' : 'declined'}.`);
    setLoading(false);
    toggle();
  };

  if (!reservation) { return null }

  const title = `${action === ACTIONS.ACCEPTED ? copy.manage.action_approve : copy.manage.action_deny} ${reservation.firstName}'s Reservation`;

  return (
    <Modal size="md" show={show} toggle={toggle} noPadding>
      <Mutation mutation={action === ACTIONS.ACCEPTED ? ACCEPT_RESERVATION : DECLINE_RESERVATION}>
        {(approveOrDeclineReservationMutation) => {
          return (
            <div className={className}>
              <header>
                <title>{title}</title>
              </header>
              <WYSIWYGEditor
                name="message"
                value={message}
                onInputChange={handleMessageChange}
                placeholder={copy.manage_event.modal_reminder_message}
              />
              <div className="actions">
                <Button inverted handleClick={toggle} disabled={loading}>
                  {copy.manage_event.modal_reminder_cancel}
                </Button>
                {action === ACTIONS.ACCEPTED && (
                  <Button
                    handleClick={() => approveOrDenyResevation(approveOrDeclineReservationMutation)}
                    disabled={loading}
                    loading={loading}
                  >{copy.manage.action_approve}</Button>
                )}
                {action === ACTIONS.REJECTED && (
                  <Button
                    handleClick={() => approveOrDenyResevation(approveOrDeclineReservationMutation)}
                    disabled={loading}
                    loading={loading}
                  >{copy.manage.action_deny}</Button>
                )}
              </div>
            </div>
          );
        }}
      </Mutation>
    </Modal>
  );
}

const StyledManageEventsDetailsAcceptDenyReservationModal = styled(ManageEventsDetailsAcceptDenyReservationModal)`
  padding: 40px 30px 30px;

  header {
    text-align: center;
    margin-bottom: 30px;

    title {
      display: block;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  textarea {
    font-size: 14px;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledManageEventsDetailsAcceptDenyReservationModalWithSettings = withSettings(StyledManageEventsDetailsAcceptDenyReservationModal);

export { StyledManageEventsDetailsAcceptDenyReservationModalWithSettings as ManageEventsDetailsAcceptDenyReservationModal }
