import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Input, SelectBox, Checkbox } from '../components';
import {
  whitelabel,
  isSite,
  isRegularOneTable,
  isRestrictedToSite,
  isRestrictedFromSite,
  getSiteNameFromSettings,
  sort
} from '../../libs';

import { useSettings, useSession } from '../../hooks';

import { registrationQuestions } from '../../constants/registrationQuestions';

const VALUE_OTHER = 'other';

const EmailSignUpExtraQuestions = ({
  className,
  values,
  handleInputChange,
  handleInputValidation
}) => {
  const { areas } = useSession();
  const sortedAreas = sort.byObjKey(areas, 'position');

  const {
    settings,
    copy,
    theme: { site_label },
    features: { enable_area_user_connection }
  } = useSettings();

  const siteName = getSiteNameFromSettings(settings);

  const {
    howDidYouHear,
    howDoYouCategorize,
    iAm,
    iAmAlt,
    iAmHillel,
    iAmCampus,
    gradYear,
    acceptTerms,
    acceptValues,
    userAreaId,
    campus
  } = values;

  const availableCampuses = (areaId) => sortedAreas.find(({ id }) => id === areaId)?.campuses || []

  const undergradOrGrad = (answers) => Array.isArray(answers)
    ? answers.reduce((result, ans) => ans === 'a' || ans === 'b' ? true : result, false)
    : answers === 'a' || answers === 'b';

  const getQuestionOptions = field => {
    const question = registrationQuestions.find(q => q.name === `${field}::${siteName}`);
    const thisYear = new Date().getFullYear();
    if (!question) return false;

    const entries = Object.entries(question.choices)
    return entries.map(choice => {
      const key = choice[0];
      let value = choice[1];

      if (value && value.includes('{{siteLabel}}')) {
        value = value.replace('{{siteLabel}}', site_label);
      }

      return { id: key, value: key, label: value };
    }).filter(({ label }) => {
      if (field === 'gradYear' && parseInt(label) < thisYear) return false;
      return true;
    });
  };

  const getQuestionSelectedOption = field => {
    const options = getQuestionOptions(field);
    const selectedOption = options.find(item => item.value === values[field]);
    let label = '';

    switch (field) {
      case 'iAm':
      case 'iAmAlt':
      case 'iAmHillel':
        label = 'I am...';
        break;
      case 'iAmCampus':
        label = 'Campus...';
        break;
      case 'gradYear':
        label = 'Graduation year...';
        break;
      case 'jewish':
        label = 'How do you identify?';
        break;
      case 'are_you_jewish':
        label = 'Do you identify as Jewish?';
        break;
      case 'howDidYouHear':
        label = copy.profile.how_did_you_hear_placeholder;
        break;
      case 'howDidYouHearFriend':
        label = copy.profile.how_did_you_hear_friend_placeholder || 'Which friend?';
        break;
      case 'howDidYouHearEvent':
        label = copy.profile.how_did_you_hear_event_placeholder || 'Which event?';
        break;
      case 'howDidYouHearOrganization':
        label = copy.profile.how_did_you_hear_organization_placeholder || 'Which organization?';
        break;
      default:
        label = '---'
    }

    if (selectedOption) return selectedOption;
    return { id: 0, label, value: 'ALL' };
  };

  const handleSelectChange = (name, value) => {
    const altRegex = RegExp('Alt$');

    handleInputChange(name, value);
    handleInputValidation(name, true);

    if (altRegex.test(name)) {
      // if alt field, also save the normal version of the field //
      handleInputChange(name.slice(0, -3), value);
      handleInputValidation(name.slice(0, -3), true);
    } else {
      // otherwise validate the non-existent alt field //
      handleInputValidation(`${name}Alt`, true);
    }

    const question = registrationQuestions.find(q => q.name === `${name}::${siteName}`);
    question && handleInputChange(`${name}Value`, question.choices[value]);

    // validate associated 'other' field
    const otherFieldName = `${name}Other`;
    const otherFieldValue = values[otherFieldName] || '';
    handleInputValidation(otherFieldName, !(value === VALUE_OTHER && !otherFieldValue));

    if (['iAm', 'iAmAlt', 'iAmHillel'].includes(name)) {
      // update `gradYear` validation for undergrads/grads
      handleInputValidation('gradYear', !(undergradOrGrad(value) && !gradYear));
      // update `iAmCampus` validation for undergrads/grads
      // `iAmCampus` is only added for sites where `iAmAlt` is used
      // `iAmCampusValid` needs to be set to true otherwise for sites with iAm type questions
      handleInputValidation('iAmCampus',
        !(name === 'iAmAlt' && undergradOrGrad(value) && !iAmCampus)
      );
    }

    if (isSite(settings, 'hillel') && name === 'are_you_jewish' && value !== '') {
      // update `jewish` validation if a `are_you_jewish` selection is made
      handleInputValidation('jewish', true);
    }

    if (isRegularOneTable(settings) && name === 'howDidYouHear') {
      if (value !== 'a') handleInputChange('howDidYouHearFriend', '');
      if (value !== 'b') handleInputChange('howDidYouHearEvent', '');
      if (value !== 'c') handleInputChange('howDidYouHearOrganization', null);
    }

    if (enable_area_user_connection && name === 'userAreaId') {
      handleInputChange('campus', '');
      handleInputValidation('campus', availableCampuses(value).length === 0);
    }
  };

  const getUserAreaValue = () => userAreaId
    ? { id: userAreaId, label: sortedAreas.find(({ id }) => id === userAreaId)?.label || '', value: userAreaId }
    : { id: 0, label: 'Hillel...', value: 'ALL' };

  const getCampusValue = () => campus
    ? { id: campus.id, label: campus.label, value: campus }
    : { id: 0, label: 'Campus...', value: 'ALL' };

  const classNames = [className, 'form_group'];
  if (isSite(settings, 'dinner-party')) classNames.push('checkboxes');

  return (
    <div className={classNames.join(' ')}>
      {getQuestionOptions('howDidYouHear') !== false && (
        <Fragment>
          {!isRegularOneTable(settings) && (
            <label>{copy.profile.how_did_you_hear_placeholder}</label>
          )}
          <SelectBox
            name="howDidYouHear"
            options={getQuestionOptions('howDidYouHear')}
            onOptionSelect={option => handleSelectChange('howDidYouHear', option)}
            defaultValue={getQuestionSelectedOption('howDidYouHear')}
            placeholder={isRegularOneTable(settings) ? copy.profile.how_did_you_hear_placeholder : ' '}
          />
          {howDidYouHear === VALUE_OTHER && (
            <Input
              placeholder={copy.profile.how_did_you_hear_other_placeholder}
              name="howDidYouHearOther"
              value={values['howDidYouHearOther']}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={copy.profile.how_did_you_hear_other_placeholder}
              required
            />
          )}
          {isRegularOneTable(settings) && (
            <>
              {howDidYouHear === 'a' && (
                <Input
                  placeholder={copy.profile.how_did_you_hear_friend_placeholder}
                  name="howDidYouHearFriend"
                  value={values['howDidYouHearFriend']}
                  onInputChange={handleInputChange}
                  onValidate={handleInputValidation}
                  errorMessage={copy.profile.how_did_you_hear_friend_placeholder}
                  required
                />
              )}
              {howDidYouHear === 'b' && (
                <Input
                  placeholder={copy.profile.how_did_you_hear_event_placeholder}
                  name="howDidYouHearEvent"
                  value={values['howDidYouHearEvent']}
                  onInputChange={handleInputChange}
                />
              )}
              {howDidYouHear === 'c' && (
                <SelectBox
                  name="howDidYouHearOrganization"
                  options={getQuestionOptions('howDidYouHearOrganization')}
                  onOptionSelect={option => handleSelectChange('howDidYouHearOrganization', option)}
                  defaultValue={getQuestionSelectedOption('howDidYouHearOrganization')}
                  placeholder={copy.profile.how_did_you_hear_organization_placeholder}
                />
              )}
            </>
          )}
        </Fragment>
      )}
      {getQuestionOptions('howDoYouCategorize') !== false && (
        <Fragment>
          <label>{copy.profile.how_do_you_categorize_placeholder}</label>
          <SelectBox
            name="howDoYouCategorize"
            options={getQuestionOptions('howDoYouCategorize')}
            onOptionSelect={option => handleSelectChange('howDoYouCategorize', option)}
            defaultValue={getQuestionSelectedOption('howDoYouCategorize')}
            placeholder=" "
          />
          {howDoYouCategorize === VALUE_OTHER && (
            <Input
              placeholder={copy.profile.how_do_you_categorize_other_placeholder}
              name="howDoYouCategorizeOther"
              value={values['howDoYouCategorizeOther']}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={copy.profile.how_do_you_categorize_other_placeholder}
              required
            />
          )}
        </Fragment>
      )}
      {whitelabel.hasFeature(settings, 'addPhoneToSignup') && (
        <Input
          placeholder={copy.profile.phone_label}
          type="tel"
          name="phone"
          value={values['phone']}
          onInputChange={handleInputChange}
          onValidate={handleInputValidation}
          valid={values.phoneValid !== false}
          errorMessage="Please enter a valid phone number."
          required
        />
      )}

      {enable_area_user_connection && (
        <>
          <SelectBox
            name="userAreaId"
            options={sortedAreas.map(({ id, label }) => ({ id, value: id, label }))}
            onOptionSelect={option => handleSelectChange('userAreaId', option)}
            defaultValue={getUserAreaValue()}
          />
          {userAreaId && availableCampuses(userAreaId).length > 0 && (
            <SelectBox
              name="campus"
              options={availableCampuses(userAreaId).map((c) => ({ label: c.label, value: c }))}
              onOptionSelect={option => handleSelectChange('campus', option)}
              defaultValue={getCampusValue()}
            />
          )}
        </>
      )}

      {getQuestionOptions('iAm') !== false && (
        <Fragment>
          {!isRestrictedFromSite('iAm', settings) && (
            <>
              <SelectBox
                name="iAm"
                options={getQuestionOptions('iAm')}
                onOptionSelect={option => handleSelectChange('iAm', option)}
                defaultValue={getQuestionSelectedOption('iAm')}
              />
              {iAm === VALUE_OTHER && (
                <Input
                  placeholder={copy.profile.how_do_you_categorize_other_placeholder}
                  name="iAmOther"
                  value={values['iAmOther']}
                  onInputChange={handleInputChange}
                  onValidate={handleInputValidation}
                  errorMessage={copy.profile.how_do_you_categorize_other_placeholder}
                  required
                />
              )}
            </>
          )}
          {isRestrictedToSite('iAmAlt', settings) && (
            <>
              <SelectBox
                name="iAmAlt"
                options={getQuestionOptions('iAmAlt')}
                onOptionSelect={option => handleSelectChange('iAmAlt', option)}
                defaultValue={getQuestionSelectedOption('iAmAlt')}
              />
              {undergradOrGrad(iAmAlt) && (
                <>
                  <SelectBox
                    name="iAmCampus"
                    options={getQuestionOptions('iAmCampus')}
                    onOptionSelect={option => handleSelectChange('iAmCampus', option)}
                    defaultValue={getQuestionSelectedOption('iAmCampus')}
                  />
                  {iAmCampus === VALUE_OTHER && (
                    <Input
                      placeholder={copy.profile.campus_other_placeholder || 'Other Campus'}
                      name="iAmCampusOther"
                      value={values['iAmCampusOther']}
                      onInputChange={handleInputChange}
                      onValidate={handleInputValidation}
                      errorMessage={copy.profile.campus_other_error || 'Enter a campus name.'}
                      required
                    />
                  )}
                </>
              )}
            </>
          )}
          {isRestrictedToSite('iAmHillel', settings) && (
            <SelectBox
              name="iAmHillel"
              options={getQuestionOptions('iAmHillel')}
              onOptionSelect={option => handleSelectChange('iAmHillel', option)}
              defaultValue={getQuestionSelectedOption('iAmHillel')}
            />
          )}
          {iAmHillel === VALUE_OTHER && (
            <Input
              placeholder={copy.profile.how_do_you_categorize_other_placeholder}
              name="iAmHillelOther"
              value={values['iAmHillelOther']}
              onInputChange={handleInputChange}
              onValidate={handleInputValidation}
              errorMessage={copy.profile.how_do_you_categorize_other_placeholder}
              required
            />
          )}
          {undergradOrGrad([iAm, iAmAlt, iAmHillel]) && (
            <SelectBox
              name="gradYear"
              options={getQuestionOptions('gradYear')}
              onOptionSelect={option => handleSelectChange('gradYear', option)}
              defaultValue={getQuestionSelectedOption('gradYear')}
            />
          )}
          {isRestrictedToSite('are_you_jewish', settings) ? (
            <SelectBox
              name="are_you_jewish"
              options={getQuestionOptions('are_you_jewish')}
              onOptionSelect={option => handleSelectChange('are_you_jewish', option)}
              defaultValue={getQuestionSelectedOption('are_you_jewish')}
            />
          ) : (
            <SelectBox
              name="jewish"
              options={getQuestionOptions('jewish')}
              onOptionSelect={option => handleSelectChange('jewish', option)}
              defaultValue={getQuestionSelectedOption('jewish')}
            />
          )}
        </Fragment>
      )}
      {getQuestionOptions('acceptTerms') !== false && copy.profile.accept_terms && (
        <Checkbox
          name="acceptTerms"
          options={[
            {
              label: copy.profile.accept_terms || 'Agree to Terms and Conditions',
              checked: acceptTerms,
            },
          ]}
          onInputChange={handleInputChange}
        />
      )}
      {getQuestionOptions('acceptValues') !== false && copy.profile.accept_values && (
        <Checkbox
          name="acceptValues"
          options={[
            {
              label: copy.profile.accept_values,
              checked: acceptValues,
            },
          ]}
          onInputChange={handleInputChange}
        />
      )}
    </div>
  );
};

const StyledEmailSignUpExtraQuestions = styled(EmailSignUpExtraQuestions)`
  label {
    margin-top: -4px !important;
    padding: 0 0 4px !important;
    font-size: 13px !important;
  }

  .dropdown_menu,
  .dropdown_menu ul {
    max-height: 361px !important;
  }

  input[type="checkbox"] {
    top: 7px;
  }

  &.checkboxes {
    margin-bottom: 14px;
  }
`;

export { StyledEmailSignUpExtraQuestions as EmailSignUpExtraQuestions };
