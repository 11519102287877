import React, { useState } from 'react';
import * as otp from './libs/otp';

const OtpContext = React.createContext();
const initialState = {
  status: null,
  userId: null,
  userMessage: null,
  userPhone: null,
  signupAnswers: {}
};

const OtpContextProvider = ({ children }) => {
  const [otpSettingsValue, setOtpSettingsValue] = useState(initialState);

  const setOtpSettings = val => {
    otp.setItem('requiresOtp', true);
    setOtpSettingsValue(val)
  };

  const otpSettings = { settings: otpSettingsValue, setOtpSettings };

  return <OtpContext.Provider value={{ otpSettings }}>{children}</OtpContext.Provider>;
};

export { OtpContext, OtpContextProvider };
