import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import {
  FAIcon,
  DatePicker,
  Underlay,
  withSettings
} from '../components';

import { FieldLabel, FieldValue } from './LandingHeroSearchField';


const LandingHeroSearchDateSelector = ({ className, dates, onSelectDates, onSearch, settings }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const { event: { date_restrictions, date_exceptions } } = settings;

  const handleDateSelect = (value) => {
    setShowPicker(prev => !prev);
    onSelectDates(value);
    setSelectedDate(value);
  };

  return (
    <div className={className}>
      <div onClick={() => setShowPicker(true)} className="field">
        <FieldLabel>When</FieldLabel>
        {dates.length ? (
          <FieldValue>{dates.map(date => moment(date).format('ddd MMM Do')).join(', ')}</FieldValue>
        ) : (
          <FieldValue notSelected>Add dates</FieldValue>
        )}
      </div>
      {showPicker && <Underlay onClick={() => setShowPicker(false)} />}
      <DatePicker
        name="date"
        value={selectedDate}
        open={showPicker}
        onDateChange={handleDateSelect}
        dayRestrictions={date_restrictions}
        dayExceptions={date_exceptions}
        style={{ margin: 0 }}
        stopPropagation
        noInput
      />
      <SearchButton onClick={onSearch}>
        <FAIcon name={[ 'far', 'search' ]} />
      </SearchButton>
    </div>
  );
};


const SearchButtonWithoutSettings = styled.button`
  appearance: none;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 40px;
  z-index: 1;
  background-color: transparent;
  border: none;
  padding: 4px 0 0;
  transition: color 250ms;
  color: ${props => props.settings.theme.css.global.colors.primary};

  &:hover {
    color: ${props => props.settings.theme.css.global.colors.primaryHover};
  }

  svg {
    /* transition: color 25ms; */
    font-size: 24px;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    top: 15px;
  }
`;
const SearchButton = withSettings(SearchButtonWithoutSettings);

const StyledLandingHeroSearchDateSelector = styled(LandingHeroSearchDateSelector)`
  border-left: 1px solid #e3e3e1;

  .field {
    padding-right: 35px;
    cursor: pointer;

    &:hover {
      span {
        color: ${props => props.settings.theme.css.global.pspanh1h2h3h4h5_color};
      }
    }
  }

  .rw-popup-container {
    top: calc(100% + 8px);
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints.nav.micro || 700}px) {
    border-left: none;
    border-top: 1px solid #e3e3e1;
    padding-top: 15px !important;
  }
`;
const LandingHeroSearchDateSelectorWithSettings = withSettings(StyledLandingHeroSearchDateSelector);
export { LandingHeroSearchDateSelectorWithSettings as LandingHeroSearchDateSelector };
