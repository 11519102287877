import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, withSettings, RsvpPaymentModalCreditCard } from '../components';

class RsvpPaymentModalCardsManagementModify extends React.Component {

  render() {
    const {
      className,
      closeButtonClicked,
      addCardButtonClicked,
      creditCards,
      selectedCreditCardId,
      creditCardSelected,
      settings
    } = this.props;
    const { copy } = settings;
    const images = settings.theme.assets.images;

    return (
      <div className={className}>
        <div className="row title-row">
          <div className="ot-twothirds-container">
            <h2>{copy.rsvp.card_pay_title}</h2>
          </div>
          <div className="ot-third-container">
            <Button
              className="close-button"
              buttonStyle="clearBackground"
              handleClick={closeButtonClicked}>
                {copy.profile.card_close_button}
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="ot-twothirds-container">
            <label>
              <span className="title-list-card">{copy.profile.card_your}</span>
            </label>
          </div>
          <div className="ot-third-container">
            <label>
              <span className="expire-list-card">{copy.profile.card_expire}</span>
            </label>
          </div>
        </div>
        <div>
          {creditCards.map(creditCard => {
            return (
              <RsvpPaymentModalCreditCard
                key={creditCard.id}
                creditCard={creditCard}
                selected={creditCard.id === selectedCreditCardId}
                editing={true}
                favorite={creditCard.favorite}
                creditCardSelected={creditCardSelected}
              />
            )}
          )}
        </div>
        <div className="row ot-credit-cards-list">
        </div>
        <div className="row add-card-row">
          <div className="ot-quarter-container">
            <label>
              <div>
                <span className="add-card-icon">+</span>
                <img className="credit-card-icon" src={images.credit_card} alt="Credit Card Icon" />
              </div>
            </label>
          </div>
          <div className="add-card-button-container">
            <Button buttonStyle="clearBackground" handleClick={addCardButtonClicked}>{copy.profile.card_new_button}</Button>
          </div>
        </div>
      </div>
    );
  }
}

RsvpPaymentModalCardsManagementModify.propTypes = {
  className: PropTypes.string
};

const StyledRsvpPaymentModalCardsManagementModify = styled(RsvpPaymentModalCardsManagementModify)`
  text-align: left;

  & .title-row {
    align-items: center;
  }

  & h2 {
    text-align: left;
    font-size: 14px;
    color: ${props => props.settings.theme.css.global.colors.primary};
    margin: 0;
  }

  & label {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    display: block;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  & .title-list-card {
    font-weight: bold;
    font-size: 15px;
    padding: 0;
  }

  & .expire-list-card {
    font-size: 12px;
  }

  & .ot-quarter-container-payments {
    width: 25%;
  }

  & .add-card-row {
    align-items: center;
  }

  & .add-card-button-container {
    width: 75%;
  }

  & .add-card-button-container button {
    float: right;
    font-weight: bold;
    font-size: 13px;
  }

  & .add-card-icon {
    font-size: 20px;
    color: ${props => props.settings.theme.css.global.colors.textLight};
    margin-right: 5px;
  }

  & .credit-card-icon {
    width: 30px;
    top: -2px;
    position: relative;
    margin: 3px 0 0 5%;
  }

  & .close-button {
    font-weight: bold;
  }
`;

const RsvpPaymentModalCardsManagementModifyWithSettings = withSettings(StyledRsvpPaymentModalCardsManagementModify);

export { RsvpPaymentModalCardsManagementModifyWithSettings as RsvpPaymentModalCardsManagementModify };
