import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { withSettings, UserJourney } from '../components';

import { isSite } from '../libs';

class UserJourneyRoute extends Route {
  render() {
    const { settings } = this.props;
    const doUserJourney = isSite(settings, ['onetable']);

    if (doUserJourney) {
      return <UserJourney/>;
    } else {
      return <Redirect to="/landing"/>;
    }
  }
}

const UserJourneyRouteWithSettings = withSettings(UserJourneyRoute);

export { UserJourneyRouteWithSettings as UserJourneyRoute };
