import React from 'react';

import { useSettings, useSession } from '../../../hooks';

import {
  getEventLocationWithSubtype,
  getFormattedNeighborhood,
  getSubNeighborhoodLabel,
  getNeighborhoodLabel,
  getFormattedSubneighborhood,
  getScheduledAtDate,
  getEventTimeSpan,
  getDeadlineAtTime,
  getDeadlineAtDate,
  getProfileLanguages,
  stringInsert,
  whitelabel,
  isSite
} from '../../../libs';

import {
  FAIcon,
  LocationDetail as Span
} from '../../components';


export const EventDetailsList = ({ eventData, isHost, isRegistered }) => {
  const { areas, viewer } = useSession();
  const { settings, copy } = useSettings();

  const isPotluck = eventData.potluck && eventData.potluckItems.edges && eventData.potluckItems.edges.length > 0;
  const isCatered = eventData.catered;
  const privacySubtype = eventData.privacySubtype;
  const eventSubtype = eventData.eventSubtype;
  const shouldHidePotluck = whitelabel.hasFeature(settings, 'hidePotluckIcons');

  const profile = viewer.user && viewer.user.profile;
  const showLanguageOnEvents = profile && profile.showLanguageOnEvents;
  const languages = profile ? getProfileLanguages(profile) : [];

  const renderNeighborhoodLabel = () => {
    const shouldShowAreaSubtype = whitelabel.hasFeature(settings, 'showAreaSubtypeLabel') &&
      !isSite(settings, 'hillel.michigan', true);
    const neighborhoodOnly = whitelabel.hasFeature(settings, 'neighborhoodOnly');
    let label = '';

    if (shouldShowAreaSubtype) {
      label = getEventLocationWithSubtype(eventData, isSite(settings, 'hillel.international', true), settings);
    } else {
      if (isSite(settings, 'hillel.michigan', true)) {
        label = eventData.neighborhood ? `RSVPs ${eventData.neighborhood}` : false;
      } else if (eventData.areaOther) {
        label = eventData.areaOther;
      } else {
        const shouldShowArea = areas && areas.length > 1;
        const areaLabel = eventData.area.label;
        const neighborhood = eventData.neighborhood === 'OTHER' ? eventData.areaOther : getNeighborhoodLabel(eventData);
        const neighborhoodLabel = getFormattedNeighborhood(neighborhood);
        const subneighborhood = getSubNeighborhoodLabel(eventData);
        const subneighborhoodLabel = getFormattedSubneighborhood(subneighborhood);

        if (shouldShowArea && areaLabel && !neighborhoodOnly) label = areaLabel;
        if (neighborhoodLabel) {
          if (label.length > 0) label = label + ', ';
          label = label + neighborhoodLabel;
        }
        if (subneighborhoodLabel && !neighborhoodOnly) {
          if (label.length > 0) label = label + ', ';
          label = label + subneighborhoodLabel;
        }
      }
    }

    if ((isHost || isRegistered) && eventData.fullAddress && !neighborhoodOnly && !isSite(settings, 'hillel.michigan', true)) {
      label += ' | ' + eventData.fullAddress;
    }
    return label;
  };

  const renderAddressLabel = () => {
    const fullAddress = eventData.fullAddress;
    const secondaryAddress = eventData.secondaryAddress;
    return fullAddress && (fullAddress + (secondaryAddress ? `, ${secondaryAddress}` : ''));
  };

  const getAddressIcon = () => {
    if (isSite(settings, 'hillel.michigan', true)) {
      return [ 'far', 'calendar-alt' ];
    } else if (isSite(settings, ['hillel'])) {
      return [ 'fas', 'users' ];
    } else {
      return [ 'far', 'map-marker-alt' ];
    }
  };

  return (
    <div>
      <Span><FAIcon name={[ 'far', 'calendar-alt' ]} /> {getScheduledAtDate(eventData)}</Span>
      <Span><FAIcon name={[ 'far', 'clock' ]} /> {getEventTimeSpan(eventData)}</Span>
      <Span>
        <FAIcon name={[ 'far', 'calendar-times' ]} />
        <span
          dangerouslySetInnerHTML={{
            __html: stringInsert(
              copy.event.rsvp_deadline_label || 'RSVP by %1$s <br>@ %2$s',
              [getDeadlineAtDate(eventData), getDeadlineAtTime(eventData)]
            )
          }}
        />
      </Span>
      {renderNeighborhoodLabel() && (
        <Span><FAIcon name={getAddressIcon()} /> {renderNeighborhoodLabel()}</Span>
      )}
      {(isHost || isRegistered) && renderAddressLabel() && (
        <Span><FAIcon name={[ 'far', 'map-marker-alt' ]} /> {renderAddressLabel()}</Span>
      )}
      {!whitelabel.hasFeature(settings, 'hideTimezone') && !isSite(settings, 'hillel.michigan', true) && (
        <Span><FAIcon name={[ 'fas', 'globe-americas' ]} /> {eventData.timezone}</Span>
      )}
      {!eventData.virtual && (
        <Span><FAIcon name={[ 'far', 'car-alt' ]} /> {eventData.parkingLots ? copy.event.parking : copy.event.no_parking}</Span>
      )}
      {eventData.accessible && <Span><FAIcon name={[ 'far', 'wheelchair' ]} /> {copy.event.wheelchair}</Span>}
      {privacySubtype && (
        <Span>
          <FAIcon name={[ 'fas', privacySubtype.virtual ? 'phone-laptop' : 'users' ]} /> {privacySubtype.name}
        </Span>
      )}
      {isPotluck && !shouldHidePotluck && <Span><FAIcon name={[ 'far', 'utensils' ]} /> Potluck</Span>}
      {isCatered && <Span><FAIcon name={[ 'far', 'utensils' ]} /> Catered</Span>}
      {/* legacy events? */}
      {!privacySubtype && eventData.virtual && !isSite(settings, 'jewishfoodfest') && (
        <Span><FAIcon name={[ 'far', 'phone-laptop' ]} /> Virtual</Span>
      )}
      {whitelabel.hasFeature(settings, 'showAreaLabel') && eventData.area && (
        <Span><FAIcon name={[ 'fas', 'users' ]} /> {eventData.area.label}</Span>
      )}
      {whitelabel.hasFeature(settings, 'showEventSubtype') && eventSubtype && (
        <Span>
          <FAIcon name={[ 'fas', 'users' ]} /> {eventSubtype.name}
        </Span>
      )}
      {eventData.closedCaptioningAvailable && (
        <Span><FAIcon name={[ 'far', 'closed-captioning' ]} /> {copy.create.closed_captioning}</Span>
      )}
      {eventData.videosOnAsDefault && (
        <Span><FAIcon name={[ 'far', 'video' ]} /> {copy.event.videos_on || 'Videos On By Default'}</Span>
      )}
      {eventData.bathroomAccessible && (
        <Span><FAIcon name={[ 'fas', 'restroom' ]} /> {copy.create.bathroom_accessible}</Span>
      )}
      {showLanguageOnEvents && languages.length > 0 && (
        <Span><FAIcon name={[ 'far', 'comment-dots' ]} /> {languages.join(', ')}</Span>
      )}
    </div>
  );
};
