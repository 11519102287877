import gql from 'graphql-tag';

export const CREATE_RESERVATION = gql`
  mutation createReservation (
    $eventId: Int!,
    $additionalGuests: [AdditionalGuestInput!],
    $rsvpNote: Html,
    $couponId: Int,
    $donation: Int,
    $tables: [ReservationTableInput!],
    $claimedPotluckItems: [ReservationClaimedPotluckItemInput!],
    $answers: [AnswerInput!],
    $pwywAmount: Int,
    $payment: ReservationPaymentInput
  ) {
    createReservation(input: {
      eventId: $eventId,
      additionalGuests: $additionalGuests,
      rsvpNote: $rsvpNote,
      couponId: $couponId,
      donation: $donation,
      tables: $tables,
      claimedPotluckItems: $claimedPotluckItems,
      answers: $answers,
      pwywAmount: $pwywAmount,
      payment: $payment
    }) {
      reservation {
        id
        state
        profile {
          id
        }
      }
      errors { message }
    }
  }
`;

export const ACCEPT_RESERVATION = gql`
  mutation acceptReservation (
    $id: Int!,
    $message: Html
  ) {
    acceptReservation(input: {
      id: $id,
      message: $message
    }) {
      reservation {
        id
        state
        profile {
          id
        }
        event {
          id
          reservations {
            guestsAccepted
            guestsPending
            guestsRejected
            guestsTotal
            reservationsAccepted
            reservationsPending
            reservationsRejected
            reservationsTotal
            totalCount
          }
        }
      }
      errors { message }
    }
  }
`;

export const DECLINE_RESERVATION = gql`
  mutation declineReservation (
    $id: Int!,
    $message: Html
  ) {
    rejectReservation(input: {
      id: $id,
      message: $message
    }) {
      reservation {
        id
        state
        profile {
          id
        }
        event {
          id
          reservations {
            guestsAccepted
            guestsPending
            guestsRejected
            guestsTotal
            reservationsAccepted
            reservationsPending
            reservationsRejected
            reservationsTotal
            totalCount
          }
        }
      }
      errors { message }
    }
  }
`;

export const CANCEL_RESERVATION = gql`
  mutation cancelReservation($id: Int!, $cancelReason: String, $cancelReasonText: String) {
    cancelReservation(
      input: { id: $id, cancelReason: $cancelReason, cancelReasonText: $cancelReasonText }
    ) {
      reservation {
        id
        state
      }
      errors { message }
    }
  }
`;

export const UPDATE_RESERVATION_PARTICIPATION = gql`
  mutation updateReservationParticipation(
    $reservationId: Int!,
    $participated: Boolean,
    $additionalGuestsParticipation: [AdditionalGuestParticipationInput!]
  ) {
    updateReservationParticipation(
      input: {
        id: $reservationId,
        participated: $participated,
        additionalGuestsParticipation: $additionalGuestsParticipation
      }
    ) {
      reservation {
        id
        participated
      }
      errors { message, path }
    }
  }
`;

export const RESERVATION_TO_COHOST = gql`
  mutation reservationIntoCohost($id: Int!) {
    reservationIntoCohost(input: { id: $id }) {
      cohost {
        id
        profile {
          id
          user {
            email
          }
        }
      }
      errors { message, path }
    }
  }
`;
