import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { EVENT_BY_CATEGORY } from '../../queries';

import {
  LandingPageContainer,
  LandingSectionHeader,
  LandingImageLinks
} from '../components';


export const LandingCategories = ({ title, content }) => {
  const [categories, setCategories] = useState();
  const { data: result, loading } = useQuery(EVENT_BY_CATEGORY);
  const [count, setCount] = useState(3);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!loading && result?.eventSubtypes.length) {
      setCategories(
        result.eventSubtypes.reduce((subtypes, { slug, ...rest }, i) => [
          ...subtypes,
          ...(i < count ? [{ url: `/categories/${slug}`, ...rest }] : [])
        ], [])
      );
    }
  }, [result, loading, count]);

  return (
    <LandingPageContainer>
      <LandingSectionHeader
        heading={title}
        content={content}
        expanded={expanded}
        onViewAll={result && result.eventSubtypes.length > 3 ? () => {
          setExpanded(!expanded);
          setCount(!expanded ? result.eventSubtypes.length : 3);
        } : null}
      />
      <LandingImageLinks links={categories} />
    </LandingPageContainer>
  );
};
