import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import styled, { css } from 'styled-components';
import { ProfileEditQuestions } from './ProfileEditQuestions';
import { ProfileEditSocialAccounts } from './ProfileEditSocialAccounts';
import { ProfileEditPrivacy } from './ProfileEditPrivacy';
import { getProfileQuestions } from '../../constants/profileQuestions';
import PropTypes from 'prop-types';
import { fillQuestionsWithSettings, fillQuestionsWithAnswers, buildAnswersJSONFromQuestions, checkAnswered } from '../../libs/questions';
import { withSettings } from '../components';
import { ProfileConfirmUpdateModal } from './ProfileConfirmUpdateModal';
import { alert } from '../Global';
import {
  stringFromDOBDate,
  isValidEmail,
  isFacebookEmail,
  isSite,
  isRegularOneTable,
  validateProfileQuestionsAnswered
} from '../../libs';

import moment from 'moment-timezone';

import {
  ProfileFields,
  Button,
  withSession
} from '../components';

import { UPDATE_PROFILE, UPDATE_USER } from '../../mutations';

class ProfileEditForm extends React.Component {
  state = {
    showConfirmUpdateModal: false,
    showAlert: false,
    firtName: '',
    lastName: '',
    email: '',
    bio: '',
    dateOfBirth: new Date(),
    address: '',
    secondaryAddress: '',
    city: '',
    cityState: '',
    zipCode: '',
    phone: '',
    facebook: '',
    instagram: '',
    twitter: '',
    snapchat: '',
    showAge: '',
    eventHostedVisible: false,
    eventAttendedVisible: false,
    questions: [],
    myProfileQuestions: [],
    answers: {}
  };

  componentDidMount() {
    const { email } = this.props.user;
    const { profile } = this.props.user;
    const { settings } = this.props;

    const profileQuestions = getProfileQuestions(settings);
    const answers = JSON.parse(profile.answers);

    let questions = fillQuestionsWithSettings(profileQuestions, settings, isRegularOneTable(settings));
    questions = fillQuestionsWithAnswers(questions, answers);

    // select questions that should show in the 'my profile' page section
    const myProfileQuestions = questions.filter(q => q.myProfile);
    // select questions that should show in the 'profile questions' page section
    const filteredProfileQuestions = questions.filter(q => !q.myProfile);

    this.setState({
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: email,
      bio: profile.bio,
      dateOfBirth: profile.dateOfBirth,
      address: profile.address,
      secondaryAddress: profile.secondaryAddress,
      city: profile.city,
      cityState: profile.cityState,
      zipCode: profile.zipCode,
      country: profile.country,
      phone: profile.phone,
      facebook: profile.facebook,
      instagram: profile.instagram,
      twitter: profile.twitter,
      snapchat: profile.snapchat,
      showAge: !profile.showAge || !isRegularOneTable(settings) ? false : profile.showAge,
      eventHostedVisible: isRegularOneTable(settings) ? profile.eventHostedVisible : true,
      eventAttendedVisible: isRegularOneTable(settings) ? profile.eventAttendedVisible : true,
      showLanguageOnEvents: profile.showLanguageOnEvents,
      showOnGuestList: profile.showOnGuestList,
      vaccinationDate: profile.vaccinationDate ? this.getVaccinationDateValue(profile.vaccinationDate) : null,
      questions: filteredProfileQuestions,
      myProfileQuestions,
      answers
    });
  }

  getVaccinationDateValue = (d) => {
    // const date = typeof d === 'string' ? d : moment(d).format('YYYY-MM-DD');
    // const vaccinationDate = new Date();
    // const dateParts = date.split('-');
    // vaccinationDate.setHours(0, 0, 0);
    // vaccinationDate.setYear(dateParts[0]);
    // vaccinationDate.setMonth(parseInt(dateParts[1]) - 1); // months start at 0 //
    // vaccinationDate.setDate(dateParts[2]);
    return moment(d).startOf('day').toDate();
  };

  validateProfileData = () => {
    const { settings: { copy } } = this.props;
    const { vaccinationDate } = this.state;

    if (vaccinationDate > new Date()) {
      alert.error(
        copy.profile.vaccination_date_error_message || 'You must have already received your final COVID-19 vaccine before entering the date.'
      );
      this.setState({ showConfirmUpdateModal: false });
      return false;
    }

    return true;
  };

  handleFormSubmit = (updateProfile, updateUser) => {
    const { refetch } = this.props;

    this.validateProfileData() && updateUser()
      .then(async ({ data }) => {
        if (data && data.updateUser && data.updateUser.errors) {
          this.setState({ showConfirmUpdateModal: false });

          alert.error('Error updating profile');
          return;
        }

        updateProfile().then(async ({ updateProfileData }) => {
          refetch();

          if (updateProfileData && updateProfileData.updateUser && updateProfileData.updateUser.errors) {
            this.setState({ showConfirmUpdateModal: false });

            alert.error('Error updating profile');
            return;
          }
          const { answers } = this.state;
          if(answers && answers['iAm']) {
            const iAmChoice = answers['iAm']['choices']?.find(selectedChoice => selectedChoice.value === true);
            if(iAmChoice) {
              window.heap?.addUserProperties({
                iAm: iAmChoice?.label
              })
            }
          }
          this.setState({ showConfirmUpdateModal: false });

          alert.success('Profile updated');
        });
      });
  };

  handleQuestionDisplayToggleChange = (event) => {
    const questions = this.state.questions.map(question => (
      question.name === event.target.name
        ? { ...question, display: !question.display }
        : question
    ));
    const answers = buildAnswersJSONFromQuestions(questions, this.state.answers);
    this.setState({ questions, answers });
  }

  handleAnswersInputChange = ({ target: { name, value, type } }, changeOther = false, useMyProfile = false) => {
    const { answers: stateAnswers, myProfileQuestions, questions: stateQuestions } = this.state;
    const questionsList = useMyProfile ? myProfileQuestions : stateQuestions;

    const questions = questionsList.map(question => {
      if (changeOther && `${question.name}Other` === name) {
        question.otherAnswer = value;

      } else if (question.name === name) {
        if (question.type === 'radio') {
          question.choices = question.choices.map(choice => {
            choice.value = (choice.id === value)
            return choice;
          })
        } else if (question.type === 'checkbox') {
          question.choices = question.choices.map(choice => {
            if (choice.id === 'freeChoice' && type === 'text') choice.value = value;
            if (choice.id === value) choice.value = !choice.value;
            return choice;
          })
        } else if (question.type === 'text' || question.type === 'textarea') {
          question.value = value;
        }
      }

      return question;
    });

    const answers = buildAnswersJSONFromQuestions(questions, stateAnswers);

    this.setState({
      questions: questions,
      answers: answers
    });
  }

  handleInputChange = (name, value) => {
    if (name === 'dateOfBirth') {
      const dob = stringFromDOBDate(value);
      this.setState({ [name]: dob });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleExtraQuestionsInputChange = (name, value) => {
    const { answers } = this.state;
    const newAnswers = Object.assign({}, answers);

    const addAnswer = (n, v) => {
      if (newAnswers.hasOwnProperty(n)) {
        newAnswers[n].answer = v;
      } else {
        newAnswers[n] = {
          answer: v,
          display: false
        }
      }
    };

    if (Array.isArray(name)) name.forEach(input => addAnswer(input.name, input.value));
    else addAnswer(name, value);

    this.setState({ answers: newAnswers });
  };

  handleInputValidation = (name, value) => {
    this.setState({ [`${name}Valid`]: value });
  };

  isFormDataValid = () => {
    const { settings: { profile: { required_profile_questions } } } = this.props;
    const { answers: stateAnswers, questions, myProfileQuestions } = this.state;

    const requiredProfileQuestions = required_profile_questions ? JSON.parse(required_profile_questions) : [];
    const questionsNotAnswered = validateProfileQuestionsAnswered(requiredProfileQuestions, stateAnswers, { profile: this.state });

    if (questionsNotAnswered.length) return false;

    for (var key in this.state) {
      if (this.state.hasOwnProperty(key) && (this.state[`${key}Valid`] === false)) {
         return false;
      }
    }

    const answers = buildAnswersJSONFromQuestions(
      isSite(this.props.settings, 'dinner-party') ? myProfileQuestions : questions,
      stateAnswers
    );
    let validProfileQuestions = true;

    if (answers) {
      for (const i in answers) {
        const answer = answers[i];
        if (answer && answer.required && !checkAnswered(answer)) {
          validProfileQuestions = false;
        }
      }

      return validProfileQuestions;
    } else {
      return true;
    }
  }

  updateProfileClicked = () => {
    this.setState({ showConfirmUpdateModal: true });
  }

  closeConfirmUpdateModal = () => {
    this.setState({ showConfirmUpdateModal: false });
  }

  getEmailStatusForEmail = email => {
    if (isValidEmail(email)) {
      if (isFacebookEmail(email)) {
        return 'NEED_TO_CHANGE';
      } else {
        return 'NOT_SET';
      }
    } else {
      return 'NEED_TO_CHANGE';
    }
  }

  render() {
    const {
      className,
      changePasswordClicked,
      closeChangePasswordModal,
      changePhoneClicked,
      closeChangePhoneModal,
      settings,
      session: { theme },
      user
    } = this.props;
    const { copy } = settings;

    const {
      firstName,
      lastName,
      bio,
      dateOfBirth,
      email,
      address,
      secondaryAddress,
      city,
      cityState,
      zipCode,
      country,
      phone,
      questions,
      myProfileQuestions,
      facebook,
      instagram,
      twitter,
      snapchat,
      answers,
      showAge,
      eventHostedVisible,
      eventAttendedVisible,
      showOnGuestList,
      showLanguageOnEvents,
      vaccinationDate: vaccDate
    } = this.state;

    return (
      <Mutation mutation={UPDATE_USER} variables={{ email }}>
        {(updateUser) => {
          const emailStatus = this.getEmailStatusForEmail(email);
          const vaccinationDate = vaccDate ? this.getVaccinationDateValue(vaccDate) : vaccDate;

          const profileVariables = {
            firstName,
            lastName,
            bio,
            dateOfBirth,
            emailStatus,
            address,
            secondaryAddress,
            city,
            cityState,
            zipCode,
            phone,
            facebook,
            instagram,
            twitter,
            snapchat,
            showAge,
            eventHostedVisible,
            eventAttendedVisible,
            showOnGuestList,
            showLanguageOnEvents,
            vaccinationDate: moment(vaccinationDate).toISOString()
          };

          if (answers) profileVariables.answers = JSON.stringify(answers);
          if (country) profileVariables.country = country;

          return(
            <Mutation mutation={UPDATE_PROFILE} variables={profileVariables}>
              {(updateProfile, { loading }) => {
                return (
                  <div className={className}>
                    <ProfileConfirmUpdateModal
                      show={this.state.showConfirmUpdateModal}
                      toggle={this.closeConfirmUpdateModal}
                      loading={loading}
                      cancelClicked={this.closeConfirmUpdateModal}
                      updateClicked={() => { this.handleFormSubmit(updateProfile, updateUser) }}
                    />

                    <form className="ot_form" onSubmit={event => this.handleFormSubmit(event, updateProfile, updateUser)}>
                      <ProfileFields
                        data={this.state}
                        user={user}
                        handleInputChange={this.handleInputChange}
                        handleInputValidation={this.handleInputValidation}
                        handleExtraQuestionsInputChange={this.handleExtraQuestionsInputChange}
                        handleMyProfileAnswersInputChange={this.handleAnswersInputChange}
                        handleMyProfileDisplayToggleChange={this.handleQuestionDisplayToggleChange}
                        changePasswordClicked={changePasswordClicked}
                        closeChangePasswordModal={closeChangePasswordModal}
                        myProfileQuestions={isSite(settings, 'dinner-party') ? questions : myProfileQuestions}
                        changePhoneClicked={changePhoneClicked}
                        closeChangePhoneModal={closeChangePhoneModal}
                      />
                      {isSite(this.props.settings, ['onetable', 'hillel']) && (
                        <Fragment>
                          <hr />
                          <h3>{copy.profile.questions_title}</h3>
                          <ProfileEditQuestions
                            questions={questions}
                            handleInputChange={this.handleAnswersInputChange}
                            handleQuestionDisplayToggleChange={this.handleQuestionDisplayToggleChange}
                            data={this.state}
                          />
                        </Fragment>
                      )}
                      {!isSite(settings, 'signaturefd') && (
                        <Fragment>
                          <hr />
                          <h3>{copy.profile.social_title}</h3>
                          <ProfileEditSocialAccounts
                            facebook={facebook}
                            instagram={instagram}
                            twitter={twitter}
                            snapchat={snapchat}
                            handleInputChange={this.handleInputChange}
                            handleInputValidation={this.handleInputValidation}
                          />
                        </Fragment>
                      )}

                      {!isSite(settings, 'jewishfoodfest') && (
                        <Fragment>
                          <hr />
                          <h3>{copy.profile.privacy_title}</h3>
                          <ProfileEditPrivacy
                            handleSwitchChange={this.handleInputChange}
                            showAge={showAge}
                            eventHostedVisible={eventHostedVisible}
                            eventAttendedVisible={eventAttendedVisible}
                            showOnGuestList={showOnGuestList}
                            showLanguageOnEvents={showLanguageOnEvents}
                          />
                        </Fragment>
                      )}
                      <div className="button_container">
                        <Button
                          handleClick={this.updateProfileClicked}
                          disabled={!this.isFormDataValid()}
                          loading={loading}
                          themed={theme.isVersion(2)}
                        >{copy.profile.submit}</Button>
                      </div>
                    </form>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

ProfileEditForm.propTypes = {
  className: PropTypes.string,
  changePasswordClicked: PropTypes.func,
  closeChangePasswordModal: PropTypes.func,
  user: PropTypes.object
};

const StyledProfileEditForm = styled(ProfileEditForm)`
  ${({ session: { theme } }) => theme.isVersion(2) ? css`
    margin-top: 40px;
  ` : css`
    margin-top: 60px;
  `}

  .ot_form {
    text-align: left;

    h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    hr {
      margin: 30px 0 40px;
    }

    .button_container {
      margin-top: 40px;

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      button {
        float: right;
      }
    }
  }
`;

const StyledProfileEditFormWithSettings = withSettings(StyledProfileEditForm);
const ProfileEditFormWithSession = withSession(StyledProfileEditFormWithSettings);
export { ProfileEditFormWithSession as ProfileEditForm };
