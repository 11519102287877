import gql from 'graphql-tag';

export const VALIDATE_COUPON_CODE = gql`
  query (
    $couponCode: String!,
  ) {
    viewer {
      coupon(code: $couponCode) {
        amount
        code
        conditions
        description
        discountType
        id
        validFrom
        validTo
      }
    }
  }
`;
