import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Accordion } from 'semantic-ui-react';
import { isPast, whitelabel } from '../../libs';

import {
  FAIcon,
  Button,
  Avatar,
  Spinner,
  ManageEventsDetailsReminderGuestModal,
  ManageEventsDetailsReminderRsvpModal,
  ManageEventsDetailsAcceptDenyReservationModal,
  ManageEventsDetailsChangeInvitation,
  ManageEventsDetailsAdditionalGuests,
  ManageEventsDetailsCSVButton,
  InfoTooltip,
  PaginationButtons,
  withSettings,
  ManageEventsDetailsChangeParticipation,
  UserActionMenu
} from '../components';

import { RESERVATION_FILTER } from '../../enums';
import { RESERVATION_ACTIONS as ACTIONS } from '../../hooks/usePaginatedReservations';
import { breakpoints } from '../../styles';

class ManageEventsDetailsList extends React.Component {
  state = {
    activeIndex: 0,
    openRsvp: false,
    openGuest: false,
    showPotluckTooltip: false,
    showAcceptDenyReservationModal: false,
    showAcceptDenyReservationModalAction: '',
    reservation: null
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleToggleReminderRsvpModal = () => {
    this.setState(prevState => ({ openRsvp: !prevState.openRsvp }));
  };

  handleToggleReminderGuestModal = () => {
    this.setState(prevState => ({ openGuest: !prevState.openGuest }));
  };

  handleTogglePotluckTooltip = () => {
    this.setState(prevState => ({ showPotluckTooltip: !prevState.showPotluckTooltip }));
  };

  getPotluckTooltipContent = items => (
    <div>
      <h2>{this.props.settings.copy.manage.potluck_im_bringing || 'I\'m Bringing:'}</h2>
      <ul>
        {items.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </div>
  );

  getAnswerTooltipContent = answers => (
    <ul className="answers_tooltip">
      {answers.map((answer, i) => (
        <li key={i}>
          <strong>{answer.node.reservationQuestion.text}</strong>
          {answer.node.response}
        </li>
      ))}
    </ul>
  );

  closeAcceptDenyReservationModal = () => {
    this.setState({
      showAcceptDenyReservationModal: false,
      showAcceptDenyReservationModalAction: '',
      reservation: null
    });
  };

  showAcceptDeclineReservationModal = (reservation, action) => {
    this.setState({
      showAcceptDenyReservationModal: true,
      showAcceptDenyReservationModalAction: action,
      reservation: reservation
    });
  };

  updateReservationParticipation = (updateReservationParticipationMutation, reservationId, participated, additionalGuestsParticipation) => {
    const variables = {
      reservationId
    };

    if (participated != null) {
      variables.participated = participated;
    }

    if (additionalGuestsParticipation != null) {
      variables.additionalGuestsParticipation = additionalGuestsParticipation;
    }

    updateReservationParticipationMutation({ variables, errorPolicy: 'all' });
  };

  render() {
    const { activeIndex, openGuest, openRsvp, showAcceptDenyReservationModal, reservation } = this.state;
    const { showAcceptDenyReservationModalAction } = this.state;
    const {
      className,
      enumerated,
      hasList,
      list,
      filter,
      show,
      event,
      refetch,
      refetchEvent,
      fetchMoreLoading,
      loading,
      pagination,
      listState,
      settings: { copy }
    } = this.props;

    const SHOW = RESERVATION_FILTER;
    const { FIELDS: { LABEL, PAST_EVENT_LABEL } } = RESERVATION_FILTER;
    const filterLower = filter.toLowerCase();
    const listFiltered = Array.isArray(list) && list.length > 0 ? list.filter((item) => {
      return (item.name && item.name.toLowerCase().includes(filterLower))
        || (item.firstName && item.firstName.toLowerCase().includes(filterLower))
        || (item.lastName && item.lastName.toLowerCase().includes(filterLower))
        || (item.email && item.email.toLowerCase().includes(filterLower));
    }) : [];

    const isPastEvent = isPast(event);
    const { guestsAccepted } = event.reservations;

    const shouldHidePotluck = whitelabel.hasFeature(this.props.settings, 'hidePotluckIcons');

    const hasPrevPage = pagination && pagination.hasPrevPage(listState);
    const hasNextPage = pagination && pagination.hasNextPage(listState);
    const goToNextPage = () => pagination.goToNextPage(listState);
    const goToPrevPage = () => pagination.goToPrevPage(listState);

    const actionElements = (item) => {
      if (isPastEvent) {
        if (enumerated === SHOW.ATTENDING) {
          return (
            <ManageEventsDetailsChangeParticipation
              reservation={item}
              participated={item.participated}
              updateReservationParticipation={this.updateReservationParticipation}
            >
            </ManageEventsDetailsChangeParticipation>
          );
        } else {
          return (SHOW.getField(PAST_EVENT_LABEL, enumerated));
        }
      } else {
        if (enumerated === SHOW.INVITED || enumerated === SHOW.INVITATION_DECLINED) {
          return (
            <ManageEventsDetailsChangeInvitation
              invitation={item}
              refetch={refetch}
              action={enumerated === SHOW.INVITED ? 'pending' : 'rejected'}
            />
          );
        } else if (enumerated === SHOW.PENDING) {
          const reservationGuestCount = 1 + (Array.isArray(item.additionalGuests) ? item.additionalGuests.length : 0);

          if (guestsAccepted >= event.numberOfGuestsMax) {
            return (
              <span className="approval_event_full">Event is full</span>
            );
          } else if (guestsAccepted + reservationGuestCount > event.numberOfGuestsMax) {
            return (
              <span className="approval_event_full">Too few seats left</span>
            );
          } else {
            return (
              <div>
                <AcceptAndDenyReservationsButton
                  onClick={() => this.showAcceptDeclineReservationModal(item, ACTIONS.ACCEPTED)}
                >
                  {copy.manage.action_approve}
                </AcceptAndDenyReservationsButton>
                <AcceptAndDenyReservationsButtonsDivider>|</AcceptAndDenyReservationsButtonsDivider>
                <AcceptAndDenyReservationsButton
                  onClick={() => this.showAcceptDeclineReservationModal(item, ACTIONS.REJECTED)}
                >
                  {copy.manage.action_deny}
                </AcceptAndDenyReservationsButton>
              </div>
            );
          }
        } else {
          return (
            <>
              <span>{SHOW.getField(LABEL, enumerated)}</span>
              {enumerated === SHOW.ATTENDING ? (
                <UserActionMenu
                  user={item}
                  actions={['promote_cohost']}
                  refetchEvent={refetchEvent}
                  cohosts={event.eventHosts ? event.eventHosts.edges : []}
                />
            ) : <span className="spacer" />}
            </>
          );
        }
      }
    };

    if ((show === SHOW.ALL || show === enumerated) && hasList) {
      return (
        <div className={className + ' semantic'}>
          <ManageEventsDetailsAcceptDenyReservationModal
            show={showAcceptDenyReservationModal}
            action={showAcceptDenyReservationModalAction}
            toggle={this.closeAcceptDenyReservationModal}
            reservation={reservation}
            refetchData={refetch}
          />
          <Accordion>
            <Accordion.Title
              className="list_title"
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <strong>{SHOW.getField(isPastEvent ? PAST_EVENT_LABEL : LABEL, enumerated, isPastEvent)}</strong>
              {enumerated === SHOW.ATTENDING && (
                <span className="attending_count">({guestsAccepted} of {event.numberOfGuestsMax} total guests)</span>
              )}
              <FAIcon name='chevron-right'/>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {loading ? (
                <div className="loading_container">
                  {/* copy.manage_event.no_items */}
                  <Spinner/>
                </div>
              ) : (
                listFiltered.length > 0 ? (
                  <Fragment>
                    <ul className="guest_list">
                      {listFiltered.map((item, index) => {
                        const guestLink = item.id ? `/user_profile/${item.id}` : `mailto:${item.email}`;

                        return (
                          <li key={index}>
                            <table className="list_item">
                              <thead />
                              <tbody>
                                <tr>
                                  <td className="avatar_container">
                                    <Link to={guestLink}>
                                      <Avatar profile={item} size="sm" layout="vertical" noName />
                                    </Link>
                                  </td>

                                  <td className="name">
                                    <Link to={guestLink}>
                                      {item.firstName} {item.lastName}
                                    </Link>
                                  </td>

                                  <td className="email">
                                    <a href={`mailto:${item.email}`}>
                                      {item.email}
                                    </a>
                                  </td>

                                  <td className="icons">
                                    <a href={`mailto:${item.email}`}>
                                      <FAIcon name="envelope" />
                                    </a>
                                    {item.potluckItems && item.potluckItems.length > 0 ? (
                                      <span>
                                        <InfoTooltip
                                          className="potluck_info"
                                          content={() => this.getPotluckTooltipContent(item.potluckItems)}
                                          position="top"
                                          icon={shouldHidePotluck ? 'calendar' : 'utensils'}
                                        />
                                      </span>
                                    ) : (
                                      <span className="hidden">
                                        <FAIcon name="utensils" />
                                      </span>
                                    )}
                                    {item.answers && item.answers.length > 0 ? (
                                      <InfoTooltip
                                        className="rsvp_answers"
                                        content={() => this.getAnswerTooltipContent(item.answers)}
                                        position="top"
                                      />
                                    ) : (
                                      <span className="hidden">
                                        <FAIcon name={[ 'fas', 'question-circle' ]} />
                                      </span>
                                    )}
                                  </td>

                                  <td className="host_actions">
                                    {actionElements(item)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {item.additionalGuests && item.additionalGuests.length > 0
                              ? <ManageEventsDetailsAdditionalGuests
                                  event={event}
                                  reservation={item}
                                  updateReservationParticipation={this.updateReservationParticipation}
                                />
                              : null
                            }
                          </li>
                        );
                      })}
                    </ul>
                  </Fragment>
                ) : (
                  <div className="not_found_container">
                    <h2>Not found.</h2>
                  </div>
                )
              )}

              {listFiltered.length > 0 && (
                <div className="buttons">
                  {(hasPrevPage || hasNextPage) && (
                    <PaginationButtons
                      hasPrevPage={hasPrevPage}
                      hasNextPage={hasNextPage}
                      goToPrevPage={goToPrevPage}
                      goToNextPage={goToNextPage}
                      loading={fetchMoreLoading}
                    />
                  )}

                  {false && (
                    <div className="pagination">
                      {hasPrevPage && (
                        <span onClick={goToPrevPage} className="prev button">
                          <FAIcon name={['fas', 'chevron-left']}/> Prev
                        </span>
                      )}
                      {hasNextPage && (
                        <span onClick={goToNextPage} className={`next button ${fetchMoreLoading ? 'loading' : ''}`}>
                          Next <FAIcon name={['fas', 'chevron-right']}/>
                      </span>
                      )}
                      {fetchMoreLoading && <Spinner/>}
                    </div>
                  )}

                  {enumerated === SHOW.ATTENDING ? (
                    <>
                      <Button inverted={!isPastEvent} handleClick={() => this.handleToggleReminderRsvpModal()}>
                        <FAIcon name="envelope"/>
                        {isPastEvent ? copy.manage.open_message_past_event : copy.manage.open_message_attending}
                      </Button>
                      <ManageEventsDetailsReminderRsvpModal
                        open={openRsvp}
                        toggle={this.handleToggleReminderRsvpModal}
                        type={isPastEvent ? copy.manage.past : SHOW.getField(LABEL, enumerated)}
                        isPastEvent={isPastEvent}
                        event={event}
                      />
                    </>
                  ) : null}

                  {enumerated === SHOW.ATTENDING ? (
                    <ManageEventsDetailsCSVButton
                      event={event}
                      enumerated={enumerated}
                    />
                  ) : <div/>}

                  {enumerated === SHOW.INVITED ? (
                    <>
                      <Button inverted handleClick={() => this.handleToggleReminderGuestModal()}>
                        <FAIcon name="envelope"/>
                        Message All Invitees
                      </Button>
                      <ManageEventsDetailsReminderGuestModal
                        open={openGuest}
                        toggle={this.handleToggleReminderGuestModal}
                        type={SHOW.getField(LABEL, enumerated)}
                        event={event}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </Accordion.Content>
          </Accordion>
        </div>
      );
    } else {
      return null;
    }
  }
}

const StyledManageEventsDetailsList = styled(ManageEventsDetailsList)`
  margin-top: 30px;
  margin-bottom: 10px;

  strong {
    margin-right: 6px;
  }

  ul {
    list-style: none;
    padding: 0;

    &.guest_list {
      margin-bottom: 30px;
    }
  }

  .list_title {
    strong {
      font-size: 14px;
    }

    svg {
      color: ${props => props.settings.theme.css.global.colors.primary};
      font-size: 14px;
      margin-left: 5px;
    }

    .attending_count {
      font-size: 13px;
      margin: 0 7px 0 5px;
    }
  }

  .content {
    & > ul {
      & > li {
        padding: 5px;
        border-top: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};

        &:last-of-type {
          border-bottom: 1px solid ${props => props.settings.theme.css.global.colors.borderColor};
        }

        @media (${breakpoints.manageEventDetailsTablet}) {
          padding: 10px 0;
        }
      }
    }
  }

  .list_item {
    position: relative;
    padding: 0;
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;

    td {
      &.avatar_container {
        width: 75px;

        .avatar {
          position: relative;
          transform: translateY(2px);
          margin: 0;
        }

        a {
          display: block;
        }
      }

      &.name {
        width: calc(35% - 75px);

        a {
          font-size: 14px;
          color: ${props => props.settings.theme.css.global.colors.primaryDark} !important;
        }
      }

      &.email {
        width: 30%;
        padding-left: 10px;
      }

      &.icons  {
        width: 15%;
        padding-left: 10px;

        svg {
          padding-top: 2px;
          margin-right: 13px;
          font-size: 17px;
          color: ${props => props.settings.theme.css.global.colors.primary};
        }
      }

      &.host_actions {
        width: 20%;
        font-size: 13px;
        text-align: right;

        @media (${breakpoints.manageEventDetailsTablet}) {
          text-align: left;

          .user_action_menu {
            position: absolute;
            top: 0;
            right: 9px;
          }
        }

        .label {
          margin-bottom: 0;
        }

        .dropdown_menu {
          min-width: 200px;
        }

        .select_box {
          text-align: left;
        }

        & > .spacer {
          display: inline-block;
          width: 13px;
          margin-left: 25px;
        }
      }

      @media (${breakpoints.manageEventDetailsTablet}) {
        display: block;
        padding-bottom: 7px;

        &.avatar_container {
          position: absolute;
          top: 0;
          left: 0;
        }

        &:not(.avatar_container) {
          width: 100% !important;
          padding-left: 75px;
        }

        &.host_actions {
          padding-bottom: 0;
        }
      }
    }


    a {
      color: ${props => props.settings.theme.css.global.colors.primary} !important;
      font-weight: 600 !important;
      font-size: 13px;

      &:hover {
        color: ${props => props.settings.theme.css.global.colors.primary};
        text-decoration: underline;

        p {
          color: ${props => props.settings.theme.css.global.colors.primary};
          text-decoration: underline;
        }
      }

      p {
        color: ${props => props.settings.theme.css.global.colors.primary} !important;
        font-weight: 600 !important;
        font-size: 13px;
        margin: 0;
        display: inline-block;
      }
    }
  }

  .loading_container,
  .not_found_container {
    // font-size: 12px;
    // font-weight: bold;
    margin-bottom: 25px !important;
    // justify-content: center;

    h2 {
      font-weight: normal !important;
      font-size: 14px;
    }
  }

  .buttons {
    display: block !important;

    @media (max-width: 1000px) {
      text-align: center;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    svg {
      font-size: large;
      padding-top: 4px;
      margin-right: 6px;
    }

    @media (max-width: 790px) {
      display: block !important;

      .button {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    & > .button {
      float: right;
      margin-left: 10px;

      @media (max-width: 1000px) {
        float: none;
      }
    }

    .pagination {
      display: block !important;
      float: left;

      @media (max-width: 1000px) {
        float: none;
        margin-bottom: 20px;
      }
    }
  }

  .potluck_info {
    white-space: pre-line;
    text-align: center;

    .ot_tooltip {
      left: 8px;
      bottom: calc(100% + 17px);
      width: 170px;

      h2 {
        font-size: 11px;
        margin: 0 0 2px;
      }

      ul {
        text-align: left;
        list-style-type: disc;
        padding-left: 20px;
      }
    }
  }
  .hidden {
    visibility: hidden;
  }

  .button {
    font-size: 14px !important;
  }

  .answers_tooltip {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 3px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    strong {
      display: block;
    }
  }

  .rsvp_answers {
    .ot_tooltip {
      left: 8px;
      bottom: calc(100% + 17px);
      width: 340px;
    }
  }
`;

const StyledManageEventsDetailsListWithSettings = withSettings(StyledManageEventsDetailsList);

export { StyledManageEventsDetailsListWithSettings as ManageEventsDetailsList };

const AcceptAndDenyReservationsButton = withSettings(styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.settings.theme.css.global.colors.primary};
  display: inline-block;

  &:hover {
    color: ${props => props.settings.theme.css.global.colors.primaryHover};
    text-decoration: underline;
    cursor: pointer;
  }
`);

const AcceptAndDenyReservationsButtonsDivider = withSettings(styled.span`
  color: ${props => props.settings.theme.css.global.colors.textLight};
  cursor: default;
  margin: 0 0.5em;
`);
