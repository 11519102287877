import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from '../../styles';
import { Accordion } from 'semantic-ui-react';

import { FAIcon, Avatar, UserActionMenu, withSettings } from '../components';

import { SessionContext } from '../../Context';


const ManageEventsDetailsCohosts = ({ className, cohosts, refetchEvent, eventId }) => {
  const [active, setActive] = useState(true);
  const { session: { viewer: { user: { profile: viewerProfile } } } } = useContext(SessionContext);
  const filteredCohosts = cohosts.filter(c => !c.node.profile || (viewerProfile && viewerProfile.id !== c.node.profile.id));

  return filteredCohosts.length > 0 ? (
    <div className={`${className} semantic`}>
      <Accordion>
        <Accordion.Title className="list_title" active={active} onClick={() => setActive(prev => !prev)}>
          <strong>Cohosts</strong>
          <FAIcon name="chevron-right" />
        </Accordion.Title>
        <Accordion.Content active={active}>
          <ul>
            {filteredCohosts.map(({ node: { profile, effectiveEmail, reservation } }, i) => {
              const guestLink = profile && profile.id ? `/user_profile/${profile.id}` : `mailto:${effectiveEmail}`;
              const cohostName = profile ? [profile.firstName, profile.lastName].join(' ').trim() : effectiveEmail;

              return (
                <li key={i}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="avatar_container">
                          <Avatar profile={profile} email={effectiveEmail} noName inline />
                        </td>
                        <td className="name_container">
                          <Link to={guestLink}>{cohostName}</Link>
                        </td>
                        <td className="email_container">
                          {profile ? (
                            <Link to={`mailto:${effectiveEmail}`}>{effectiveEmail}</Link>
                          ) : null}
                        </td>
                        <td className="actions_container">
                          {reservation && (
                            <UserActionMenu
                              user={profile}
                              cohosts={cohosts}
                              actions={['demote_cohost']}
                              refetchEvent={refetchEvent}
                              effectiveEmail={effectiveEmail}
                              eventId={eventId}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              );
            })}
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  ) : null;
};

const StyledManageEventsDetailsCohosts = styled(ManageEventsDetailsCohosts)`
  .list_title {
    font-size: 14px !important;

    .fa-chevron-right {
      margin-left: 11px;
      color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }

  .content {
    & > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      & > li {
        padding: 5px;
        border-top: 1px solid #e5e5e5;

        &:last-of-type {
          border-bottom: 1px solid #e5e5e5;
        }

        @media (${breakpoints.manageEventDetailsTablet}) {
          position: relative;
          min-height: 64px;
          padding: 10px 0;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    .avatar_container {
      width: 75px;
    }

    .name_container {
      width: calc(27%);
      font-weight: 600;

      a {
        color: ${props => props.settings.theme.css.global.colors.primaryDark};
        font-size: 14px;
      }

      @media (${breakpoints.manageEventDetailsTablet}) {
        padding-right: 30px;
      }
    }

    .email_container {
      font-weight: 600;

      a {
        color: ${props => props.settings.theme.css.global.colors.primary};
        font-size: 13px;
      }
    }

    .actions_container {
      width: 50px;
      font-size: 13px;
      text-align: right;

      .dropdown_menu {
        min-width: 200px;
      }

      .user_action_menu {
        @media (${breakpoints.manageEventDetailsTablet}) {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }

    td {
      @media (${breakpoints.manageEventDetailsTablet}) {
        display: block;

        &.avatar_container {
          position: absolute;
          top: 8px;
          left: 0;
          height: 75px;
        }

        &:not(.avatar_container) {
          width: 100% !important;
          padding-left: 75px;
        }
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
const ManageEventsDetailsCohostsWithSettings = withSettings(StyledManageEventsDetailsCohosts);
export { ManageEventsDetailsCohostsWithSettings as ManageEventsDetailsCohosts };
