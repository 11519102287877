import React from 'react';

import {
  LandingPageContainer,
  LandingSectionHeader,
  LandingImageLinks
} from '../components';


const linksData = [
  {
    id: 1,
    label: 'PAUSE + Ponder June',
    host: 'Yael Shy',
    image: 'https://onetable.org/assets/ui/landing/discover-event-1.jpg',
    url: '/search/chicago',
    virtual: true
  },
  {
    id: 2,
    label: 'IG Shabbat Ritual',
    host: 'Miki',
    image: 'https://onetable.org/assets/ui/landing/discover-event-2.jpg',
    url: '/search/chicago',
    virtual: true
  },
  {
    id: 3,
    label: 'IG Shabbat Ritual',
    host: 'Miki',
    image: 'https://onetable.org/assets/ui/landing/discover-event-3.jpg',
    url: '/search/chicago',
    virtual: true
  }
];


export const LandingDiscover = () => (
  <LandingPageContainer>
    <LandingSectionHeader
      title="Discover experiences"
      subtext="Lorem ipsum dolor amet sit."
      viewAllRoute="/all-events"
    />
    <LandingImageLinks
      links={linksData}
    />
  </LandingPageContainer>
);
