import React from 'react';
import styled from 'styled-components';
import { EventList } from '../components';
import { withSettings } from '../components';

const EventViewSuggested = (props) => {
  const { className, suggestedEvents } = props;
  const { settings: { copy } } = props;

  return (
    <div className={className}>
      <i className="divider with_text">
        <span>{copy.event.might_like}</span>
      </i>
      <EventList events={suggestedEvents} />
    </div>
  );
}

const StyledEventViewSuggested = styled(EventViewSuggested)`
  & > i.divider.with_text {
    max-width: 1192px;
    margin-top: 60px;
  }
`;

const StyledEventViewSuggestedWithSettings = withSettings(StyledEventViewSuggested);

export { StyledEventViewSuggestedWithSettings as EventViewSuggested };
