import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, FAIcon } from '../';
import { LogInModal, OpacityHover, withSettings } from '../components';
import { SessionContext } from '../../Context';


const UserAuthLinks = (props) => {
  const { className, overHero, location, viewer } = props;
  const [state, setState] = useState({
    showModal: false,
    modalContent: 'login'
  });
  
  const updateModalContent = (content, openModal) => {
    const newState = { ...state, modalContent: content };
    
    if (openModal) newState['showModal'] = true;
    
    setState(newState);
  };
  
  const handleToggle = () => {
    setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }; 

  useEffect(() => {
    const showLogInModal = (!viewer || !viewer.user) && location.search.includes('login=true');
    if (showLogInModal) setState({ ...state, showModal: showLogInModal });
  }, [viewer, location]);

  const Wrapper = ({ children }) => overHero ? <OpacityHover>{children}</OpacityHover> : <span>{children}</span>;

  const classList = [className];
  const loginWrapperClasses = ['icon_wrapper'];

  if (overHero) {
    classList.push('over_uj');
    loginWrapperClasses.push('white');
  }

  return (
    <>
      <div className={classList.join(' ')}>
        <Wrapper>
          <span className={loginWrapperClasses.join(' ')} onClick={() => updateModalContent('login', true)}>
            <FAIcon name={['fal', 'user-circle']} />
            Log In
          </span>
        </Wrapper>
        <Wrapper>
          <Button
            handleClick={() => updateModalContent('signup', true)}
            buttonStyle={overHero ? 'outline' : ''}
            capitalize
          >Sign Up</Button>
        </Wrapper>
      </div>
      <LogInModal
        afterLogin={() => {
          const locationState = location.state || {};
          const urlParams = new URLSearchParams(location.search);

          const redirectTo = locationState.redirectAfterLoginTo || urlParams.get('redirectAfterLoginTo');

          if (redirectTo) {
            window.location.href = redirectTo;
          }
        }}
        show={state.showModal}
        toggle={handleToggle}
        content={state.modalContent}
        updateModalContent={updateModalContent}
        shouldRedirect
      />
    </>
  );
}

const StyledUserAuthLinks = styled(UserAuthLinks)`
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;

  .icon_wrapper {
    display: inline-block;
    margin-right: 20px;
    font-size: 12px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    svg {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      font-size: 15px;
      color: #8c8c8c;
    }

    &.white {
      color: #fff;

      svg {
        color: #fff;
      }
    }
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
    right: 40px;
  }

  @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.mobile : 1075}px) {

    &.over_uj {
      right: 30px;
    }

    &:not(.over_uj) {
      right: 90px;
    }
  }

  &.over_uj {
    @media (max-width: 700px) {
      right: 90px;
    }
  }
`;

const UserAuthLinksWithRouter = withRouter(StyledUserAuthLinks);
const UserAuthLinksWithRouterWithSettings = withSettings(UserAuthLinksWithRouter);

const UserAuthLinksWithViewer = props => {
  const { session: { viewer } } = useContext(SessionContext);
  return <UserAuthLinksWithRouterWithSettings viewer={viewer} {...props} />;
};

export { UserAuthLinksWithViewer as UserAuthLinks };
