import React, { useContext } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { SessionContext } from '../../Context';
import {
  getUniqueEventsFromEdges,
  getWeekDateAsString,
  isPublicEvent,
  isSoloEvent,
  stringInsert,
  getUrlParamFilters
} from '../../libs';
import { withSettings } from '../components';

import * as userJourney from '../../libs/userJourney';

import {
  Loader,
  EventList,
  EventHero,
  SearchAndFilter
} from '../components';

import { AREA_BY_URL, EVENT_SUBTYPES } from '../../queries';
import { EVENTS } from '../../queries/EventListPage';

import Html from 'html-to-react';

const htmlParser = new Html.Parser();

const NUMBER_OF_EVENTS_PER_PAGE = 24;
const NUMBER_OF_EVENTS_PER_PAGE_WHEN_PRIVATE_ONES_REACHED = 44;

class EventListPage extends React.Component {
  state = { filters: null, loadingMore: false };

  componentDidMount() {
    const { location, settings } = this.props;
    const filterParams = getUrlParamFilters(location.search, settings.custom_filters);
    const newState = {};

    if (filterParams) {
      newState.urlFilters = filterParams;
      newState.search = location.search;
      newState.filters = { ...filterParams };
    }

    this.setState(newState);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { search, filters } = prevState;
    const { location, settings } = nextProps;

    if (location && search !== location.search) {
      if (!location.search) return {
        search: '',
        filters: {},
        urlFilters: {}
      };

      const filterParams = getUrlParamFilters(location.search, settings.custom_filters);

      if (filterParams) {
        return {
          urlFilters: filterParams,
          search: location.search,
          filters: { ...filters, ...filterParams }
        };
      }
    }

    return null;
  }

  handleAddFilters = filters => {
    this.setState({ filters });
  };

  handleGetMoreEvents = (data, variables, numberOfEventsPerPage, fetchMore) => {
    this.setState({ loadingMore: true }, () => {
      fetchMore({
        variables: { ...variables, numberOfItems: numberOfEventsPerPage, cursor: data.events.pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.events.edges;
          const totalCount = fetchMoreResult.events.totalCount;
          const mergedEdges = getUniqueEventsFromEdges([...previousResult.events.edges, ...newEdges]);
          const pageInfo = fetchMoreResult.events.pageInfo;

          if (mergedEdges.length > 0) {
            pageInfo.endCursor = mergedEdges[mergedEdges.length - 1].cursor;
          }

          const mergedResult = {
            events: {
              totalCount,
              pageInfo,
              edges: mergedEdges,
              __typename: previousResult.events.__typename
            }
          };

          return newEdges.length ? mergedResult : previousResult;
        }
      }).then(() => this.setState({ loadingMore: false }));
    });
  };

  getCursorFromResponse = response => {
    if (!response || !response.profile || !response.profile.attendedEvents || !response.profile.attendedEvents.pageInfo ||
      !response.profile.attendedEvents.edges || response.profile.attendedEvents.edges.length === 0) {
      return '';
    } else {
      return response.profile.attendedEvents.edges[response.profile.attendedEvents.edges.length - 1].cursor;
    }
  };

  getEventsFromResponse = response => {
    return response && response.events && response.events.edges ? response.events.edges : [];
  };

  getTotalEventsCountFromResponse = response => {
    return response && response.events && response.events.totalCount ? response.events.totalCount : 0;
  };

  render() {
    const { className, match, location, settings, archive = false } = this.props;
    const { filters, loadingMore, urlFilters } = this.state;

    const showJourneyResources = userJourney.shouldShow(location, settings);
    if (showJourneyResources) return <Redirect to="/journey" />;

    return (
      <Query query={AREA_BY_URL} variables={{ url: match.params.area }}>
        {({ data: areaData, loading: areaLoading }) => {
          if (areaLoading) return <Loader />;
          // if (areaError) console.error('AREA_BY_URL: ' + areaError);

          const area = areaData && areaData.areaByUrl;

          if (!area) return <Redirect to="/"/>;

          return (
            <Query query={EVENT_SUBTYPES}>
              {({ data: subtypesData }) => {
                const headerImage = area.photo && area.photo.originalUrl;
                const altText = area.altText ? area.altText : '';
                const variables = {
                  numberOfItems: NUMBER_OF_EVENTS_PER_PAGE,
                  areaCode: area.code,
                  scheduledAtRelativeToNow: archive ? 'PAST' : 'UPCOMING',
                  ...filters
                };

                // add subtype filter from url //
                const subtypes = subtypesData && subtypesData.eventSubtypes;
                const subtype = urlFilters && 'event_subtype' in urlFilters && subtypes
                  ? subtypes.find(st => st.slug === urlFilters.event_subtype)
                  : null;
                if (subtype) variables.eventSubtypeId = subtype.id;
                if (filters && filters.eventSubtypeId === null) variables.eventSubtypeId = null;

                for (const i in variables) {
                  if (variables[i] === null) delete variables[i];
                }

                const heroTitle = archive
                  ? stringInsert(settings.copy.landing.archive_page_title || 'Archive: %1$s', area.label)
                  : area.label;

                return (
                  <div className={`${className}`}>
                    <EventHero
                      id='event_view_header'
                      title={heroTitle}
                      headerImg={headerImage}
                      headerAltText={altText}
                    />
                    <SearchAndFilter
                      area={area}
                      urlFilters={urlFilters}
                      subtypes={subtypes}
                      addFiltersToQuery={this.handleAddFilters}
                      archive
                    />
                    <div>
                      {area.areaBanner && (
                        <div className="area_banner__container">
                          {htmlParser.parse(area.areaBanner.content)}
                        </div>
                      )}
                      <Query query={EVENTS} variables={variables} errorPolicy="all" fetchPolicy="network-only" notifyOnNetworkStatusChange>
                        {({ data, loading: eventsLoading, fetchMore }) => {
                          // if (eventsError) console.error('EVENTS: ' + eventsError);

                          const events = this.getEventsFromResponse(data);
                          const totalEventsCount = this.getTotalEventsCountFromResponse(data);
                          const hasMoreEvents = data && data.events && data.events.pageInfo && data.events.pageInfo.hasNextPage;

                          var index = -1;
                          const eventsByWeek = events.reduce((acc, event) => {
                            const eventNode = event.node;
                            const weekDateAsString = getWeekDateAsString(eventNode);

                            if (!acc[index] || acc[index].date !== weekDateAsString) {
                              index += 1;

                              acc[index] = {
                                date: weekDateAsString,
                                events: {
                                  private: [],
                                  nonPrivate: [],
                                  solo: []
                                }
                              };
                            }

                            if (isPublicEvent(eventNode) && !isSoloEvent(eventNode)) {
                              acc[index].events.nonPrivate = [...acc[index].events.nonPrivate, eventNode];
                            } else if (isSoloEvent(eventNode)) {
                              acc[index].events.solo = [...acc[index].events.solo, eventNode];
                            } else {
                              acc[index].events.private = [...acc[index].events.private, eventNode];
                            }

                            return acc;
                          }, []);

                          const lastWeek = eventsByWeek[eventsByWeek.length - 1];
                          const numberOfEventsPerPage = lastWeek &&
                              (lastWeek.events.private.length > 0 || lastWeek.events.solo.length > 0)
                            ? NUMBER_OF_EVENTS_PER_PAGE_WHEN_PRIVATE_ONES_REACHED
                            : NUMBER_OF_EVENTS_PER_PAGE;

                          return (
                            <div>
                              <EventList
                                eventsByWeek={eventsByWeek}
                                collapsePrivateEvents={area.collapsePrivateEventDisplay}
                                collapseSoloEvents={area.collapseSoloEventDisplay}
                                area={area}
                                loading={eventsLoading}
                                loadingMore={loadingMore}
                                hasMoreEvents={hasMoreEvents}
                                totalEventsCount={totalEventsCount}
                                onLoadMore={() => this.handleGetMoreEvents(data, variables, numberOfEventsPerPage, fetchMore)}
                                archive={archive}
                                showArea={false/* explicit `false` instead of relying on `undefined` so `true` defaults work */}
                              />
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const StyledEventListPage = styled(EventListPage)`
  .area_banner__container {
    margin-top: 40px;
  }
`;

const EventListPageWithRouter = withRouter(StyledEventListPage);
const EventListPageWithSettings = withSettings(EventListPageWithRouter);

const EventListPageWithViewer = props => {
  const { session: { viewer } } = useContext(SessionContext);
  return <EventListPageWithSettings viewer={viewer} {...props} />;
};

export { EventListPageWithViewer as EventListPage };
