import React from 'react';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  Modal,
  Button
} from '../components';

class EventSponsorshipsSkipModal extends React.Component {
  render() {
    const { className, show, toggle, chooseLaterButtonClicked } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <Modal
        show={show}
        toggle={toggle}
        className={className}
        size="sm"
        noPadding
      >
        <div>
          <h3>{copy.nourishment_popup.title}</h3>
          <p>{copy.nourishment_popup.description}</p>
          <div className="buttons-container">
            <Button className="choose-later-button" buttonStyle='primary_outline' handleClick={chooseLaterButtonClicked} halfWidth>
              {copy.nourishment_popup.cancel_button}
            </Button>
            <Button className="choose-now-button" buttonStyle='primary' handleClick={toggle} halfWidth>
              {copy.nourishment_popup.confirm_button}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const StyledEventSponsorshipsSkipModal = styled(EventSponsorshipsSkipModal)`
  text-align: center;

  .modal-content {
    text-align: center;
    padding: 40px 30px 30px;
    border: 0;
  }

  h3 {
    font-size: 18px;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 0;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
  }

  .choose-later-button {
    margin-right: 10px;
  }

  .choose-now-button {
    margin-left: 10px;
  }

  .buttons-container {
    display: flex;
    margin-top: 20px;
  }
`;

const StyledEventSponsorshipsSkipModalWithSettings = withSettings(StyledEventSponsorshipsSkipModal);

export { StyledEventSponsorshipsSkipModalWithSettings as EventSponsorshipsSkipModal };
