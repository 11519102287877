import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withSettings } from '../components';

import {
  Modal,
  Button,
  FormAssembly
} from '../components';

import { mixins } from '../../styles/mixins';


class EventSponsorsFormModal extends React.Component {


  afterSubmit = () => {
    const { eventId,
      toggle,
      updateFormSubmittedBooleanMutation,
      onSubmitSuccess
    } = this.props;

    const mutationInput = {
      eventId,
      sponsorFormSubmitted: true
    };

    updateFormSubmittedBooleanMutation({ variables: mutationInput }).then(() => {
      toggle();
      onSubmitSuccess();
    });
  }

  getCSS = () => {
    const { colors } = this.props.settings.theme.css.global;

    return `
    .wFormContainer {
      width: 100%;
    }

     .wFormContainer {
      border: 0;
    }
     .wForm {
      padding: 0;
    }
     .wForm form .errFld {
      border: 0;
    }
     .wForm form .errFld input {
       border-color: ${colors.error};
    }
     .wForm form .errFld .errMsg {
      color: ${colors.error};
    }
     .wForm .inline::after {
      ${mixins.clearFloat};
    }
     .wForm .inline > div {
      margin: 0;
      padding: 0;
      display: block;
      width: calc(50% - 10px) !important;
      float: left;
    }
     .wForm .inline > div:first-of-type {
      /* margin-right: 20px; */
    }
     .wForm .inline > div#tfa_2307-D {
      float: none;
      width: 100% !important;
      margin-right: 0;
    }
     .wForm .label {
      color: #424242;
      font-size: 13px;
      vertical-align: middle;
      font-weight: normal;
    }
     .wForm .label.reqMark::after {
      color: ${colors.error};
    }
     .wForm legend {
      font-size: 16px;
      /* color: ${colors.heading}; */
      padding: 0;
      margin: 20px 0 10px;
      text-align: left;
      border: none;
      font-weight: normal;
    }
     .wForm fieldset {
      border: none;
      margin: 0 0 20px;
      padding: 0;
    }
     .wForm fieldset > div {
      padding: 0 !important;
      margin: 0 !important;
      border: 0 !important;
    }
     .wForm .inputWrapper {
      display: block;
    }
     .wForm .oneField {
      /* margin-bottom: 20px !important; */
      margin: 0 !important;
    }
     .wForm .htmlSection {
      margin-bottom: 30px !important;
    }
     .wForm .preField {
      display: block;
      text-align: left;
      margin: 0 0 5px;
      padding: 0;
    }
     .wForm .preField + br {
      display: none;
    }
     .wForm .preField > span {
      display: inline-block;
      text-align: left;
      font-size: 14px !important;
      font-weight: bold !important;
      color: ${colors.primaryDark};
    }
     .wForm input[type=text],
     .wForm input[type=email],
     .wForm input[type=tel],
     .wForm textarea {
      width: 100% !important;
      height: 38px;
      padding: 6px 12px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color 150ms, box-shadow 150ms;
    }
     .wForm input[type=text]:focus,
     .wForm input[type=email]:focus,
     .wForm input[type=tel]:focus,
     .wForm textarea:focus {
      border-color: #8a8a8a !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.15) !important;
    }
     .wForm .actions {
      padding: 0;
      margin: 0;
    }
     .wForm .actions .primaryAction {
      ${mixins.appearenceNone};
      background-image: none;
      background-color: ${colors.primary};
      box-shadow: none;
      border: 1px solid ${colors.primary};
      font-size: 14px;
      color: #fff;
      height: 40px;
      line-height: 1;
      border-radius: 3px;
      margin-top: 0;
      transition: background-color 250ms, border-color 250ms;
    }
     .wForm .actions .primaryAction:hover {
      background-color: ${colors.primaryHover};
      border-color: ${colors.primaryHover};
    }
     .wFormFooter,
     .supportInfo {
      display: none;
    }

     .wForm legend {
       padding-left: 0 !important;
     }

     .wForm div.field-container-D {
       padding: 0 !important;
     }

    .wForm input {
      margin-bottom: 14px !important;
    }

    .wForm form .oneChoice label, .wForm form .oneChoice .label {
      vertical-align: top;
    }

    .wForm div.field-container-D input[type="checkbox"] {
      margin-bottom: 0 !important;
      vertical-align: top;
    }

     #submit_button {
      width: 100%;
      color: white !important;
    }`;
  }

  render() {
    const { className, show, toggle, formHtml, formAlreadySubmitted, onSubmitSuccess } = this.props;
    const { settings: { copy } } = this.props;

    return (
      <Modal
        show={show}
        toggle={toggle}
        size="md"
        className={className}
      >
        <Fragment>
          {formAlreadySubmitted ?
            <div className="form-already-submitted">
              <p>{copy.event.sponsor_form_submitted_description}</p>
            </div> :
            null
          }
          <FormAssembly form={formHtml} css={this.getCSS()} afterSubmit={this.afterSubmit}></FormAssembly>
          <div className="bottom-buttons-container">
            <Button buttonStyle="clearBackground" handleClick={onSubmitSuccess} halfWidth>
              Skip for now
            </Button>
          </div>
        </Fragment>
      </Modal>
    );
  }
}

const StyledEventSponsorsFormModal = styled(EventSponsorsFormModal)`
  & .form-already-submitted {
      text-align: center;
      margin-top: 3rem;
    }

  & .bottom-buttons-container {
      text-align: center;
  }
`;

const EventSponsorsFormModalWithRouter = withRouter(StyledEventSponsorsFormModal);
const EventSponsorsFormModalWithRouterWithSettings = withSettings(EventSponsorsFormModalWithRouter);

export { EventSponsorsFormModalWithRouterWithSettings as EventSponsorsFormModal };
