import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { SessionContext } from '../../Context';

import {
  ManageEventsDetailsFilters,
  ManageEventsDetailsTitle,
  ManageEventsDetailsCard,
  ManageEventsDetailsInvite,
  ManageEventsDetailsListBox,
  Loader,
  withClient
} from '../components';

import {
  MANAGE_EVENT_BY_UUID,
  VIEWER_EVENTS_COUNT
} from '../../queries';

import { EVENT_TYPE, EVENT_STATE } from '../../enums';

import { styles, breakpoints } from '../../styles';
import { isPast } from '../../libs';
import { usePaginatedGuests } from '../../hooks';


const ManageEventsDetails = ({ className, client, match }) => {
  const uuid = match.params.uuid;

  const queryVars = {
    client,
    errorPolicy: 'all',
    // notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { uuid, isHost: true }
  };

  const viewerEventsCount = useQuery(VIEWER_EVENTS_COUNT, { client });
  const eventByUuid = useQuery(MANAGE_EVENT_BY_UUID, {
    ...queryVars,
    variables: { uuid },
    errorPolicy: 'all'
  });

  const counts = viewerEventsCount.data && viewerEventsCount.data.viewer;
  const isEventLoading = eventByUuid.loading;
  const event = eventByUuid.data && eventByUuid.data.event;

  const {
    reservations,
    invitations,
    refreshReservations,
    refreshInvitations,
    pagination,
    fetchMoreLoading,
    loading,
    refetchPendingInvitations
  } = usePaginatedGuests(event, queryVars);

  const { session } = useContext(SessionContext);
  const areas = session.areas;
  const viewer = session.viewer;
  const showArea = areas && areas.length > 1;
  const showInviteBar = event && !isPast(event) && event.state === EVENT_STATE.PUBLISHED && event.eventType !== EVENT_TYPE.SOLO;
  const canCopyEvents = () => viewer.user && viewer.user.hostApprovalAt !== null && viewer.user.canCopyEvents;

  const refetch = async (data = {}) => {
    await viewerEventsCount.refetch();
    await eventByUuid.refetch();
    data.type === 'reservation' && refreshReservations(data);
    data.type === 'invitation' && refreshInvitations(data);
  }

  if (eventByUuid.error) console.error(eventByUuid.error);

  if (viewerEventsCount.error || eventByUuid.error) return <Redirect to="/" />;


  if (!isEventLoading && counts && event) {
    return (
      <div className={className}>
        <ManageEventsDetailsFilters counts={counts} event={event} />
        <div className="content__container">
          <div className="content">
            <ManageEventsDetailsTitle event={event} canCopyEvents={canCopyEvents()} />
            <div>
              <ManageEventsDetailsCard event={event} showArea={showArea} />
              <span>
                {showInviteBar && (
                  <ManageEventsDetailsInvite
                    event={event}
                    reservations={reservations}
                    invitations={invitations}
                    refetch={refetch}
                    refetchPendingInvitations={refetchPendingInvitations}
                  />
                )}
                <ManageEventsDetailsListBox
                  event={event}
                  reservations={reservations}
                  invitations={invitations}
                  pagination={pagination}
                  loading={loading}
                  fetchMoreLoading={fetchMoreLoading}
                  refetch={refetch}
                  refetchEvent={eventByUuid && eventByUuid.refetch}
                  showInviteBar={showInviteBar}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};


const StyledManageEventsDetails = styled(ManageEventsDetails)`
  padding-top: 66px;
  padding-bottom: 40px;

  @media (${breakpoints.tablet}) {
    padding-top: 63px;
  }

  @media (${breakpoints.tabletSmall}) {
    padding-top: 60px;
  }

  & > div {
    padding: 0 20px;
  }

  .content {
    max-width: ${styles.containersWidths.default};
    margin: auto;

    & > div {
      display: flex;
      // margin-top: 30px;

      @media (${breakpoints.tabletSmall}) {
        display: block;
      }

      & > span:not(.button_wrapper) {
        display: block;
        width: 100%;
        padding-left: 40px;

        @media (${breakpoints.manageEventDetailsTablet}) {
          width: 65%;
        }

        @media (${breakpoints.tabletSmall}) {
          width: 100%;
          padding-left: 0;
          margin-top: 30px;
        }
      }
    }

    &__container {
      padding-top: 30px;
    }
  }
`;

const ManageEventsDetailsWithRouter = withRouter(StyledManageEventsDetails);
const ManageEventsDetailsWithClient = withClient(ManageEventsDetailsWithRouter);

export { ManageEventsDetailsWithClient as ManageEventsDetails }
