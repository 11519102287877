import { useQuery } from '@apollo/react-hooks';
// import { useApolloClient } from '@apollo/react-common';
import { EVENTS } from '../queries/EventListPage';
import { sort } from '../libs';

const NUMBER_OF_EVENTS_PER_PAGE = 24;

export const usePaginatedEvents = (client, { variables = {}, ...rest } = {}, sortPrivateAtEnd = true) => {
  const options = {
    client,
    errorPolicy: 'all',
    variables: {
      numberOfItems: NUMBER_OF_EVENTS_PER_PAGE,
      ...variables
    },
    ...rest
  };

  const { data, loading, error, fetchMore } = useQuery(EVENTS, options);

  const events = data && data.events;
  const pageInfo = events && events.pageInfo;
  const totalEventsCount = (events && events.totalCount) || 0;
  const hasMoreEvents = events && events.edges.length < totalEventsCount;
  const sortedEvents = sortPrivateAtEnd ? sort.movePrivateEventsToEndOfDate(events) : events;

  return {
    data,
    loading,
    error,
    fetchMore,
    pagination: {
      pageInfo,
      totalEventsCount,
      hasMoreEvents,
      numberOfItems: options.variables.numberOfItems,
      events: sortedEvents
    }
  };
};
