import React, { useState } from 'react';
import styled from 'styled-components';

// import { useMutation } from '@apollo/react-hooks';
import { withApollo } from '@apollo/react-hoc';
import {
  RESERVATION_TO_COHOST,
  UPDATE_EVENT_COHOSTS
} from '../../mutations';

import { FAIcon, DropDownMenu, Modal, Button, withSettings } from '../components';
import { stringInsert } from '../../libs';


const UserActionMenu = props => {
  const {
    className,
    client,
    actions = [],
    refetchEvent,
    user,
    cohosts = [],
    eventId,
    effectiveEmail = '',
    settings: { copy }
  } = props;

  const [currentAction, setCurrentAction] = useState({});
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(prev => !prev);
  const toggleModal = () => setModalOpen(prev => !prev);

  const options = {
    demote_cohost: {
      label: copy.manage.demote_cohost_label || 'Demote to guest',
      message: copy.manage.demote_cohost_message || 'Are you sure you want to demote %1$s?',
      mutation: UPDATE_EVENT_COHOSTS,
      variables: {
        id: eventId,
        cohosts: cohosts
          .map(c => ({ email: c.node ? c.node.effectiveEmail : '', visible: true }))
          .filter(c => c.email !== effectiveEmail)
      }
    },
    promote_cohost: {
      label: copy.manage.promote_cohost_label || 'Promote to cohost',
      message: copy.manage.promote_cohost_message || 'Are you sure you want to promote %1$s?',
      mutation: RESERVATION_TO_COHOST,
      variables: { id: user.reservationId },
      hideWhenCohost: true
    }
  };

  const handleOpenConfirmationModal = action => {
    setCurrentAction(action);
    toggleMenu();
    toggleModal();
  };

  const handleActionClick = async () => {
    setLoading(true);

    await client.mutate({
      mutation: currentAction.mutation,
      variables: currentAction.variables
    });

    refetchEvent && await refetchEvent();
    setLoading(false);
    toggleModal();
  };

  // remove cohost promote action (maybe more in the future) when already a cohost //
  const cohostEmails = cohosts.map(c => c.node.effectiveEmail);
  const filteredActions = actions.reduce((list, action) => {
    const option = options[action];
    if (option && option.hideWhenCohost && cohostEmails.includes(user.email)) return list;
    return [...list, action];
  }, []);

  return (
    <div className={`${className} user_action_menu`}>
      {filteredActions.length > 0 ? (
        <>
          <span onClick={()=> setMenuOpen(prev => !prev)}><FAIcon name='cog' /></span>
          {menuOpen && (
            <DropDownMenu toggle={toggleMenu} backdrop>
              {filteredActions.map((action, i) => {
                return options[action]
                  ? (
                    <li key={i}>
                      <span onClick={() => handleOpenConfirmationModal(options[action])}>
                        {options[action].label}
                      </span>
                    </li>
                  ) : null;
              })}
            </DropDownMenu>
          )}
          <Modal
            show={modalOpen}
            toggle={toggleModal}
            size="sm"
            backdrop="static"
            closer={false}
            noPadding
          >
            <ModalContent>
              <p>{stringInsert(currentAction.message, [user.firstName, user.lastName].join(' ').trim())}</p>
              <div className="button_container">
                <Button
                  buttonStyle="primary_outline"
                  handleClick={toggleModal}
                  disabled={loading}
                >Cancel</Button>
                <Button
                  className="continue_button"
                  handleClick={handleActionClick}
                  disabled={loading}
                  loading={loading}
                >Continue</Button>
              </div>
            </ModalContent>
          </Modal>
        </>
      ) : <span className="spacer" />}
    </div>
  );
};

const StyledUserActionMenu = styled(UserActionMenu)`
  display: inline-block;
  margin-left: 25px;
  position: relative;

  .spacer {
    display: inline-block;
    width: 13px;
  }

  .fa-cog {
    transition: color 250ms;
    cursor: pointer;

    &:hover {
      color: ${props => props.settings.theme.css.global.colors.primary};
    }
  }

  .dropdown_menu {
    left: auto;
    right: 0;
    min-width: 170px;
    text-align: left;

    li {
      span {
        padding: 0;
        display: block;
        padding: 6px 20px;
      }
    }
  }
`;

const ModalContent = styled.div`
  padding: 30px;

  p {
    font-size: 15px;
    text-align: center;
    margin-bottom: 25px;
  }

  .button_container {
    &::after {
      content: '';
      clear: both;
      display: block;
    }
  }

  .continue_button {
    float: right;
  }
`;

const UserActionMenuWithSettings = withSettings(StyledUserActionMenu);
const UserActionMenuWithApollo = withApollo(UserActionMenuWithSettings);
export { UserActionMenuWithApollo as UserActionMenu };
