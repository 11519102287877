import gql from 'graphql-tag';
import { singleEventByUuid, reservationsFragment, journeyResponseFragment } from './optimized';
import { socialDistancePolicyFragment } from './Area';

// NEED TO CLEAN UP ALL THESE QUERIES, SOME ARENT BEING USED ANYMORE, OTHERS LOADING WAAYY TOO MUCH INFORMATION //

export const EVENT_CREATION_QUESTIONS = gql`
  query eventCreationQuestions {
    eventCreationQuestions {
      id
      answers {
        id
        description
        eventDescription
        eventType
        image {
          smallUrl
          mediumUrl
          largeUrl
          originalUrl
        }
        position
        title
        eventCreationQuestion {
          id
        }
      }
      background {
        smallUrl
        mediumUrl
        largeUrl
        originalUrl
      }
      description
      position
      required
      title
    }
  }
`;

const resourcesFragment = `
  unifiedEventCreationResources {
    id
    attachment
    description
    image {
      smallUrl
      mediumUrl
      largeUrl
      originalUrl
    }
    position
    title
    type
    url
  }
`;

export const EVENT_CREATION_RESOURCES = gql`
  ${journeyResponseFragment}
  query eventCreationResources($uuid: String!) {
    eventByUuid(uuid: $uuid) {
      id
      eventType
      shortUrl
      scheduledAt
      sponsorshipDeadline
      area {
        id
        nourishmentLockDaysBeforeEvent
        newHostNourishmentDelayDays
        maxPaidAdditionalGuests
        maxUnpaidAdditionalGuests
      }
      sponsorships {
        id
      }
      eventHosts {
        edges {
          node {
            profile {
              id
            }
            id
          }
        }
      }
      reservations {
        guestsAccepted
      }
      ${resourcesFragment}
      ...JourneyResponse
    }
  }
`;

export const EVENT_CREATION_JOURNEY_RESPONSE_IDS = gql`
  query eventCreationJourneyResponseId($uuid: String!) {
    eventByUuid(uuid: $uuid) {
      id
      journeyResponses {
        id
      }
    }
  }
`;

export const GLOBAL_RESERVATION_QUESTIONS = gql`
  query globalReservationQuestions {
    globalReservationQuestions {
      nodes {
        answerType
        disabled
        id
        position
        presenceType
        text
      }
    }
  }
`;

// export const EVENT_TIMEZONES = gql`
//   query timezones {
//     timezones
//   }
// `;

export const timezonesQuery = `
  timezonesFull {
    disabled
    ianaName
    name
  }
`;

export const EVENT_TIMEZONES = gql`
  query timezonesFull {
    ${timezonesQuery}
  }
`;

export const EVENT_SUBTYPES = gql`
  query eventSubtypes {
    eventSubtypes {
      id
      name
      disabled
      position
      other
      slug
    }
  }
`;

export const EVENT_BY_CATEGORY = gql`
  query eventSubtypes {
    eventSubtypes {
      id
      name
      disabled
      slug
      backgroundImageUrl
    }
  }
`;

export const DRESS_CODES = gql`
  query dressCodes {
    dressCodes {
      id
      name
    }
  }
`;

export const EVENT_COVERS = gql`
  query eventCovers {
    eventCovers {
      nodes {
        id
        url
        position
      }
    }
  }
`;

const reservationNodeFields = `
  id
  participated
  profile {
    id
    firstName
    lastName
    facebookId
    avatar {
      id
      photo {
        smallUrl
        mediumUrl
        largeUrl
        originalUrl
      }
    }
  }
  additionalGuests {
    email
    name
    firstName
    lastName
    participated
  }
  state
  answers {
    edges {
      node {
        id
        response
        reservationQuestion {
          id
          text
        }
      }
    }
  }
  potluckItems {
    totalCount
    edges {
      node {
        id
        name
        quantity
        claimedQuantity
        freeQuantity
        position
      }
    }
  }
  tables {
    edges {
      node {
        id
        name
      }
    }
  }
`;

const reservationNodesFragment = `
  fragment ReservationNodes on EventReservationConnection {
    nodes {
      ${reservationNodeFields}
    }
    edges {
      node {
        ${reservationNodeFields}
      }
    }
    guestsAccepted
  }
`;

// const invitationNodesFragment = `
//   fragment InvitationNodes on EventInvitationConnection {
//     nodes {
//       id
//       email
//       invitee {
//         id
//         firstName
//         lastName
//       }
//       status
//     }
//   }
// `;

const eventFields = `
  id
  alcoholPolicy
  dressCodeName
  dietaryRestrictions
  areaOther
  stateLocation
  area {
    id
    code
    url
    label
    timezone
    nourishmentLockDaysBeforeEvent
    newHostNourishmentDelayDays
    maxPaidAdditionalGuests
    maxUnpaidAdditionalGuests
    neighborhoods {
      code
      label
      subneighborhoods {
        code
        label
      }
    }
    campuses {
      id
      label
      subneighborhoods {
        code
        label
      }
    }
  }
  cover {
    originalUrl
    smallUrl
    mediumUrl
    largeUrl
  }
  canSubscribe
  communityDinner
  duration
  eventType
  eventSubtype {
    id
    name
  }
  eventSubtypeOther
  expectedGuests
  extraDetails
  fullAddress
  eventHosts {
    edges {
      node {
        creator
        id
        effectiveEmail
        profile {
          id
          firstName
          lastName
          bio
          avatar {
            id
            photo {
              smallUrl
              mediumUrl
              largeUrl
              originalUrl
            }
          }
        }
      }
    }
  }
  neighborhood
  campus {
    id
    label
    subneighborhoods {
      code
      label
    }
  }
  subneighborhood
  numberOfGuestsMax
  notes
  nourishable
  partnerOrganizationLogo {
    originalUrl
    smallUrl
    mediumUrl
    largeUrl
  }
  partnerOrganizationName
  partnerOrganizationDescription
  potluck
  pricePerPerson
  potluckItems {
    totalCount
    edges {
      node {
        id
        name
        quantity
        claimedQuantity
        freeQuantity
        position
        claims {
          id
          quantity
          reservation {
            profile {
              id
              firstName
              lastName
              avatar {
                id
                photo {
                  smallUrl
                  mediumUrl
                  largeUrl
                  originalUrl
                }
              }
            }
          }
        }
      }
    }
  }
  reservationQuestions {
    edges {
      node {
        id
        answerType
        baseQuestion {
          id
          presenceType
        }
        disabled
        position
        text
      }
    }
  }
  scheduledAt
  sponsorshipDeadline
  secondaryAddress
  sponsorships {
    id
    amount
    sponsor {
      id
      name
    }
    event {
      id
    }
  }
  state
  subneighborhood
  tags
  title
  timezone
  virtual
  virtualLocation
  virtualResourceUrl
  uuid
  recurrences {
    id
    scheduledAt
    state
  }
`;

export const EVENTS = gql`
  ${reservationNodesFragment}
  query events(
    $query: String,
    $areaCode: String,
    $dateFrom: ISO8601Date,
    $dateTo: ISO8601Date,
    $eventType: [EventType!],
    $alcoholPolicy: [AlcoholPolicy!],
    $dietaryRestrictions: [DietaryRestriction!],
    $cost: Cost,
    $neighborhood: String,
    $subneighborhood: String
    $tag: String
    $order: EventOrder,
    $dressCode: String,
    $virtual: Boolean
  ){
    events(
      query: $query,
      areaCode: $areaCode,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      eventType: $eventType,
      alcoholPolicy: $alcoholPolicy,
      dietaryRestrictions: $dietaryRestrictions,
      cost: $cost,
      neighborhood: $neighborhood,
      subneighborhood: $subneighborhood,
      tag: $tag,
      order: $order,
      dressCode: $dressCode,
      virtual: $virtual
    ) {
      nodes {
        ${eventFields}
        reservations {
          ...ReservationNodes
        }
      }
    }
  }
`;

export const EVENT_BY_UUID_RESERVATIONS = gql`
  query eventByUuid($uuid: String!, $numberOfItems: Int!, $cursor: String, $isHost: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      id
      reservations: reservations(first: $numberOfItems, after: $cursor) {
        totalCount
        guestsAccepted
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            participated
            profile {
              id
              user @include(if: $isHost) {
                id
                email
              }
              firstName
              lastName
              facebookId
              avatar {
                id
                photo {
                  smallUrl
                  mediumUrl
                  largeUrl
                  originalUrl
                }
              }
            }
            additionalGuests {
              email
              name
              firstName
              lastName
              participated
            }
            state
            answers {
              edges {
                node {
                  id
                  response
                  reservationQuestion {
                    id
                    text
                  }
                }
              }
            }
            potluckItems {
              totalCount
              edges {
                node {
                  id
                  name
                  quantity
                  claimedQuantity
                  freeQuantity
                  position
                }
              }
            }
            tables {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const EVENT_BY_ID_RESERVATIONS = gql`
  query eventById($id: Int!, $numberOfItems: Int!, $cursor: String, $isHost: Boolean!) {
    event: eventById(id: $id) {
      id
      reservations: reservations(first: $numberOfItems, after: $cursor) {
        totalCount
        guestsAccepted
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            participated
            profile {
              id
              user @include(if: $isHost) {
                id
                email
              }
              firstName
              lastName
              facebookId
              avatar {
                id
                photo {
                  smallUrl
                  mediumUrl
                  largeUrl
                  originalUrl
                }
              }
            }
            additionalGuests {
              email
              name
              firstName
              lastName
              participated
            }
            state
            answers {
              edges {
                node {
                  id
                  response
                  reservationQuestion {
                    id
                    text
                  }
                }
              }
            }
            potluckItems {
              totalCount
              edges {
                node {
                  id
                  name
                  quantity
                  claimedQuantity
                  freeQuantity
                  position
                }
              }
            }
            tables {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const suggestedEventsQuery = `
  suggestedEvents {
    nodes {
        id
        uuid
        scheduledAt
        duration
        title
        neighborhood
        timezone
        areaOther
        closedCaptioningAvailable
        videosOnAsDefault
        bathroomAccessible
        area {
          id
          label
          url
          neighborhoods {
            code
            label
            subneighborhoods {
              code
              label
            }
          }
        }
        cover {
            originalUrl
            smallUrl
            mediumUrl
            largeUrl
        }
        eventHosts {
            edges {
              node {
                creator
                id
                profile {
                  id
                  firstName
                  lastName
                  avatar {
                    id
                    photo {
                      mediumUrl
                      originalUrl
                    }
                  }
                }
              }
            }
        }
    }
  }
`;

export const EVENT_BY_UUID = gql`
  ${singleEventByUuid}
  ${reservationsFragment}
  ${journeyResponseFragment}
  query eventByUuid($uuid: String!, $isHost: Boolean!, $isRegistered: Boolean!, $isLogged: Boolean) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      ...SingleEventUuid
      reservations(filterByState: ACCEPTED, first: 4) {
        ...Reservations
      }
      ...JourneyResponse
      ${suggestedEventsQuery}
    }
  }
`;

export const EVENT_BY_ID = gql`
  ${singleEventByUuid}
  ${reservationsFragment}
  ${journeyResponseFragment}
  query eventById($id: Int!, $isHost: Boolean!, $isRegistered: Boolean!, $isLogged: Boolean) {
    event: eventById(id: $id) {
      __typename
      ...SingleEventUuid
      reservations(filterByState: ACCEPTED, first: 4) {
        ...Reservations
      }
      ...JourneyResponse
      ${suggestedEventsQuery}
    }
  }
`;


export const EVENT_BY_UUID_FOR_EMBED = gql`
  ${singleEventByUuid}
  query eventByUuid($uuid: String!, $isHost: Boolean!, $isRegistered: Boolean!, $isLogged: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      ...SingleEventUuid
    }
  }
`;

export const EVENT_BY_UUID_FOR_UPDATE = gql`
  ${singleEventByUuid}
  query eventByUuid($uuid: String!, $isHost: Boolean!, $isRegistered: Boolean!, $isLogged: Boolean!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      ...SingleEventUuid
    }
  }
`;

export const EVENT_BY_UUID_FOR_RSVP = gql`
  query eventByUuid($uuid: String!) {
    event: eventByUuid(uuid: $uuid) {
      __typename
      id
      area {
        id
        maxPaidAdditionalGuests
        maxUnpaidAdditionalGuests
        membership {
          id
          content
          header
          name
          products {
            id
            active
            description
            hint
            name
            prices {
              id
              active
              stripeId
              unitAmount
            }
          }
        }
      }
      cover {
        originalUrl
      }
      privacySubtype {
        id
        vaccinated
      }
      canSubscribe
      duration
      neighborhood
      subneighborhood
      potluck
      pricePerPerson
      pwyw
      pwywMinimum
      potluckItems {
        totalCount
        edges {
          node {
            id
            name
            quantity
            claimedQuantity
            freeQuantity
            position
          }
        }
      }
      reservationQuestions {
        edges {
          node {
            id
            answerType
            baseQuestion {
              id
              presenceType
            }
            disabled
            position
            text
          }
        }
      }
      scheduledAt
      title
      virtual
      uuid
      areaOther
      stateLocation
      effectiveRsvpDeadlineAt
      rsvpDeadlineAt
      donationsEnabled
      donationsDisabled
      eventAmount
      hostAbsorbsTransactionFee
      eventHosts {
        edges {
          node {
            creator
            effectiveEmail
            id
            profile {
              id
              firstName
              lastName
              bio
              avatar {
                id
                photo {
                  originalUrl
                  smallUrl
                  mediumUrl
                  largeUrl
                }
              }
            }
          }
        }
      }
      timezone
      timezoneOther
      closedCaptioningAvailable
      videosOnAsDefault
      bathroomAccessible
    }
  }
`;

export const EVENT_BY_UUID_FOR_SPONSORSHIP_SELECTION = gql`
  query eventByUuid($uuid: String!) {
    eventByUuid(uuid: $uuid) {
      __typename
      id
      title
      numberOfGuestsMax
      uuid
      scheduledAt
      sponsorshipDeadline
      eventType
      expectedGuests
      nourishable
      nourishmentRestrictionExceptionEnabled
      nourishmentRestrictionExceptionExpiresAt
      eventHosts {
        edges {
          node {
            profile {
              id
            }
            id
          }
        }
      }
    }
  }
`;

export const EVENT_SPONSORSHIPS_BY_UUID = gql`
  query eventByUuid($uuid: String!) {
    eventByUuid(uuid: $uuid) {
      __typename
      id
      uuid
      title
      eventType
      sponsorFormSubmitted
      expectedGuests
      nourishable
      nourishmentUnavailableExplanation
      availableSponsors(first: 100) {
        edges {
          node {
            allowedAmounts
            areas {
              id
              code
            }
            description
            displayFormAssemblyQuestionsForm
            id
            name
            photo {
              smallUrl
              mediumUrl
              largeUrl
              originalUrl
            }
            url
            category
          }
        }
      }
      area {
        id
        nourishmentLockDaysBeforeEvent
        newHostNourishmentDelayDays
        maxPaidAdditionalGuests
        maxUnpaidAdditionalGuests
        sponsors {
          edges {
            node {
              allowedAmounts
              areas {
                id
                code
              }
              description
              displayFormAssemblyQuestionsForm
              id
              name
              photo {
                smallUrl
                mediumUrl
                largeUrl
                originalUrl
              }
              url
            }
          }
        }
      }
      sponsorships {
        id
        amount
        sponsor {
          id
          name
        }
        event {
          id
        }
      }
      eventHosts {
        edges {
          node {
            profile {
              id
            }
            id
          }
        }
      }
    }
  }
`;

export const eventTypeSponsorshipSettingsQuery = `
  eventTypeSponsorshipSettings {
    id
    eventType
    priceMax
    pricePerHead
  }
`;

export const EVENT_SPONSORSHIPS_SETTINGS = gql`
  query eventTypeSponsorshipSettings {
    ${eventTypeSponsorshipSettingsQuery}
  }
`;

export const EVENT_PRIVACY_SUBTYPES = gql`
  ${socialDistancePolicyFragment}
  query privacySubtypes {
    privacySubtypes {
      id
      eventType
      name
      position
      disabled
      virtual
      outdoors
      vaccinated
      socialDistancePolicy {
        ...Policy
      }
    }
  }
`;

export const EVENT_SPONSORS_QUESTIONS_FORMS = gql`
  query eventByUuid($uuid: String!, $sponsorIds: [Int!]!) {
    eventByUuid(uuid: $uuid) {
      __typename
      id
      sponsorsQuestionForm(sponsorIds: $sponsorIds) {
        body
        head
      }
    }
  }
`;

export const GET_USER_HOSTED_EVENTS = gql`
  ${reservationNodesFragment}
  query($id: Int!, $first: Int!) {
    profile(id: $id) {
      id
      hostedEvents(first: $first) {
      	totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${eventFields}
            reservations {
              ...ReservationNodes
            }
          }
          cursor
        }
    	}
    }
  }
`;

export const GET_USER_ATTENDED_EVENTS = gql`
  ${reservationNodesFragment}
  query($id: Int!, $first: Int!) {
    profile(id: $id) {
      id
      attendedEvents(first: $first) {
      	totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${eventFields}
            reservations {
              ...ReservationNodes
            }
          }
          cursor
        }
    	}
    }
  }
`;

export const GET_MORE_USER_HOSTED_EVENTS = gql`
  ${reservationNodesFragment}
  query($id: Int!, $first: Int!, $cursor: String) {
    profile(id: $id) {
      id
      hostedEvents(first: $first, after: $cursor) {
      	totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${eventFields}
            reservations {
              ...ReservationNodes
            }
          }
          cursor
        }
    	}
    }
  }
`;

export const GET_MORE_USER_ATTENDED_EVENTS = gql`
  ${reservationNodesFragment}
  query($id: Int!, $first: Int!, $cursor: String) {
    profile(id: $id) {
      id
    	attendedEvents(first: $first, after: $cursor) {
      	totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${eventFields}
            reservations {
              ...ReservationNodes
            }
          }
          cursor
        }
    	}
    }
  }
`;

export const EVENT_RESERVATIONS_BY_ID = gql`
  ${reservationsFragment}
  query eventById($id: Int!, $isHost: Boolean!, $after: String) {
    event: eventById(id: $id) {
      __typename
      id
      reservations(first: 8, filterByState: ACCEPTED, after: $after) {
        ...Reservations
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_EVENT_CANCEL_REASONS = gql`
  query eventCancelReasons {
    eventCancelReasons {
      cancelReason
      requireText
    }
  }
`;
