import { useState, useEffect, useRef } from 'react';
import { SPONSORS } from '../queries';
import { useQuery } from '@apollo/react-hooks';
import { sort } from '../libs';
import { useSession } from './';

const sponsorsCategories = { DONATION: [], NATIONALSPONSORS: [], BOOKS: [], BY_AREA: [], OTHER: [] };


export const useSponsors = () => {
  const loaded = useRef(false);
  const [sponsors, setSponsors] = useState(sponsorsCategories);
  const [sponsorAreas, setSponsorAreas] = useState([]);
  const { data, loading, error } = useQuery(SPONSORS);
  const { areas } = useSession();

  useEffect(() => {
    if (data && !loaded.current) {
      const formattedSponsors = Object.assign({}, sponsors);
      const sponsorEdges = data.sponsors && data.sponsors.edges;

      sponsorEdges && sponsorEdges.forEach(({ node }) => {
        const category = node && node.category in sponsors ? node.category : 'OTHER';
        node && node.areas.length && formattedSponsors[category].push(node);
      });

      formattedSponsors.BY_AREA = sort.sponsorsByArea(sponsors.OTHER);
      formattedSponsors.DONATION = sort.moveToFront(formattedSponsors.DONATION, 'id', 24);
      formattedSponsors.NATIONALSPONSORS = sort.moveToFront(formattedSponsors.NATIONALSPONSORS, 'id', 194);

      const areasWithSponsors = Object.keys(formattedSponsors.BY_AREA);
      // start with all areas for the anchor links but mark them as having sponsors or not
      const sponsoredAreas = sort.byObjKey(areas, 'label').map(({ label }) => ({
        label,
        sponsored: areasWithSponsors.includes(label)
      }));
      // move 'Other Cities' area to end of areas list
      const sortedSponsorAreas = sort.moveObjToEnd(sponsoredAreas, 'label', 'Other Cities');

      setSponsors(formattedSponsors);
      setSponsorAreas(sortedSponsorAreas);
      loaded.current = true;
    }
  }, [data, sponsors]);

  return {
    sponsors,
    loading,
    error,
    sponsorAreas
  };
};
