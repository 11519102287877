import React from 'react';

import {
  ProfileSettings,
  ProfileSettingsPaymentMethods,
  ProfileSettingsMembership
} from '../components';

export const ProfileSettingsBody = ({ view }) => {
  switch (view) {
    case 'profile':
      return <ProfileSettings />;
    case 'payment':
      return <ProfileSettingsPaymentMethods />;
    case 'membership':
      return <ProfileSettingsMembership />;
    default:
      return null;
  }
};
