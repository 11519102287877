import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import {
    Modal,
    Button,
    WYSIWYGEditor,
    alert
} from '../components';

import { withSettings } from '../components';

import { SEND_RSVP_REMINDER } from '../../mutations';

class ManageEventsDetailsReminderGuestModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            sending: false
        }
    }

    handleChangeMessage = (name, value) => {
        this.setState({ [name]: value });
    }

    handleConfirm = async (sendMessage) => {
        const { toggle, event, settings: { copy } } = this.props;
        const { message } = this.state;

        this.setState({ sending: true });

        await sendMessage({
            variables: {
                eventId: event.id,
                message: message
            }
        });

        this.setState({ message: '', sending: false }, () => {
          alert.success(copy.manage.message_success);
          toggle();
        });
    }

    render() {
        const { className, open, toggle, type } = this.props;
        const { settings: { copy } } = this.props;
        const { message, sending } = this.state;
        return (
            <Modal size="md" show={open} toggle={toggle} noPadding>
                <Mutation mutation={SEND_RSVP_REMINDER}>
                    {(sendMessage) => {
                        return (
                            <div className={className}>
                                <header>
                                    <title>
                                        {`${copy.manage_event.modal_reminder_title_send} ${type} ${copy.manage_event.modal_reminder_title_list}`}
                                    </title>
                                </header>
                                <WYSIWYGEditor
                                    name="message"
                                    value={message}
                                    onInputChange={this.handleChangeMessage}
                                    placeholder={copy.manage_event.modal_reminder_message}
                                    fullToolbar
                                />
                                <div className="actions">
                                    <Button inverted handleClick={() => { toggle() }}>
                                        {copy.manage_event.modal_reminder_cancel}
                                    </Button>
                                    <Button disabled={message.length > 0 && !sending ? false : true}
                                        loading={sending}
                                        handleClick={() => { this.handleConfirm(sendMessage) }}>
                                        {false && copy.manage_event.modal_reminder_confirm}
                                        Send
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </Mutation>

            </Modal>
        );
    }

}

const StyledManageEventsDetailsReminderGuestModal = styled(ManageEventsDetailsReminderGuestModal)`
    padding: 40px 30px 30px;

    header {
        text-align: center;
        margin-bottom: 30px;

        title {
            display: block;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
    .actions {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    textarea {
      font-size: 14px;
    }
`;

const SStyledManageEventsDetailsReminderGuestModalWithSettings = withSettings(StyledManageEventsDetailsReminderGuestModal);

export { SStyledManageEventsDetailsReminderGuestModalWithSettings as ManageEventsDetailsReminderGuestModal }
