import React from 'react';
import { Redirect } from 'react-router-dom';
import { EventCreationPage } from '../../components';

const EventCreationCommunityPage = ({ loggedUser }) => {
  if (loggedUser && loggedUser.communityDinnerCreator) {
    return <EventCreationPage loggedUser={loggedUser} isCommunity />;
  } else {
    return <Redirect to="/events" />;
  }
}

export { EventCreationCommunityPage };
