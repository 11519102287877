import React, { useState } from 'react';
import styled from 'styled-components';

import { SearchFilterValue, AreaDropdown } from '../../components';


const AreaSearchFilter = ({ className, area, onSelectArea }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelectArea = (value) => {
    onSelectArea(value);
    setShowDropdown(false);
  };

  return (
    <div className={className}>
      <div onClick={() => setShowDropdown(true)}>
        <SearchFilterValue className="value">{area.label}</SearchFilterValue>
        {showDropdown && (
          <AreaDropdown
            area={area}
            onSelectArea={handleSelectArea}
            onToggleDropdown={() => setShowDropdown(prev => !prev)}
          />
        )}
      </div>
    </div>
  );
};

const StyledAreaSearchFilter = styled(AreaSearchFilter)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    bottom: 10px;
    background-color: #ceccc785;
    width: 1px;
  }

  .value {
    min-width: 170px;
  }
`;
export { StyledAreaSearchFilter as AreaSearchFilter };
