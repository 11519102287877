import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useSettings, useSession, useEventPageData } from '../../hooks';
import {
  stringInsert,
  formatPrice,
  imEventHost,
  isVaccinated
} from '../../libs';

import {
  CardBoarder,
  HostAvatar,
  LocationDetail as Span,
  EventDetailsList,
  RsvpActionsButton as RsvpButton,
  RestrictionModalStatus,
  RestrictionModal,
  LogInModal,
  VaccinationDetailsModal,
  ErrorModal
} from '../components';

import ROUTES from '../../constants/routes';


export const EventHeaderDetails = () => {
  const history = useHistory();
  const { eventData, isHost, isRegistered, reservation } = useEventPageData();
  const { session, viewer } = useSession();
  const { settings, copy } = useSettings();

  const [restriction, setRestriction] = useState({});
  const [afterLogin, setAfterLogin] = useState(false);
  const [showRestriction, setShowRestriction] = useState(false);
  const [showVaccinationModal, setShowVaccinationModal] = useState(false);
  const [showVaccinationErrorModal, setShowVaccinationErrorModal] = useState(false);
  const [showLogInModal, setShowLogInModal] = useState(false);
  const [modalContent, setModalContent] = useState('login');

  const handleToggleVaccinationModal = (redirect, vaccinationDate) => {
    setShowVaccinationModal(prev => !prev);
    // if vacc date isnt before limit show error //
    if (redirect && !isVaccinated(eventData, vaccinationDate, true)) {
      setShowVaccinationErrorModal(true);
      return;
    }
    // otherwise redirect to rsvp page //
    redirect && history.push(`/${ROUTES.EVENTS}/${eventData.uuid}/rsvp`, {
      event: eventData
    });
  };

  useEffect(() => {
    if (!afterLogin || !eventData) return;
    if (restriction.content) {
      setShowRestriction(true);
      setAfterLogin(false);
    } else if (afterLogin) {
      !(imEventHost(eventData)) &&
        history.push(`/${ROUTES.EVENTS}/${eventData.uuid}/rsvp`, { event: eventData });
    }
  }, [afterLogin, restriction, eventData]);

  useEffect(() => {
    viewer && eventData && setRestriction({
      content: RestrictionModalStatus(copy, session, eventData, settings, 'rsvp'),
      showRestriction: ({ isAfterLogin }) => {
        setShowRestriction(true);
        isAfterLogin && setAfterLogin(true);
      }
    });
  }, [viewer, eventData]);

  if (!eventData) return null;

  const { eventHosts: { edges: hosts } } = eventData;
  const host = hosts && hosts.find(({ node }) => node.creator);
  const hostName = host && host.node && host.node.profile && host.node.profile.firstName;

  return (
    <CardBoarder>
      <Container>
        <Header>
          <HostAvatar event={eventData} position="inline" size="xs" />
          <Span>{copy.event.hosted_by} <strong>{hostName}</strong></Span>
        </Header>
        <EventDetailsList eventData={eventData} isHost={isHost} isRegistered={isRegistered} />
        <Footer>
          {eventData.pwyw ? (
            <Price small>
              {stringInsert(
                copy.event.pay_what_you_want_label,
                formatPrice(eventData.pwywMinimum || 500)
              )}
            </Price>
          ) : (
            <Price>{eventData.pricePerPerson ? `$${formatPrice(eventData.pricePerPerson)}` : 'Free'}</Price>
          )}
          <RsvpButton
            eventData={eventData}
            restriction={restriction}
            reservation={reservation}
            showRestriction={showRestriction}
            showLoginModal={() => setShowLogInModal(true)}
            showVaccinationModal={() => setShowVaccinationModal(true)}
            showVaccinationErrorModal={() => setShowVaccinationErrorModal(true)}
            fullWidth
          >RSVP</RsvpButton>

          <RestrictionModal
            show={showRestriction}
            toggle={() => setShowRestriction(prev => !prev)}
            content={restriction.content}
            onClose={() => setAfterLogin(true)}
          />
          <LogInModal
            show={showLogInModal}
            toggle={() => setShowLogInModal(prev => !prev)}
            updateModalContent={(content, openModal) => {
              setModalContent(content);
              openModal && setShowLogInModal(true);
            }}
            content={modalContent}
            shouldRedirect={false}
            afterLogin={() => {
              setShowLogInModal(false);
              setAfterLogin(true);
            }}
          />
          <VaccinationDetailsModal
            show={showVaccinationModal}
            toggle={handleToggleVaccinationModal}
            action="rsvp"
            size="md"
          />
          <ErrorModal
            show={showVaccinationErrorModal}
            toggle={() => setShowVaccinationErrorModal(prev => !prev)}
          >
            <p>{copy.rsvp.guest_requires_vaccination_error_message}</p>
          </ErrorModal>
        </Footer>
      </Container>
    </CardBoarder>
  )
};

const Container = styled.div`padding: 30px;`;

const Header = styled.header`
  border-bottom: 1px solid #deddda;
  padding-bottom: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .card_avatar {
    box-shadow: none;
    margin-right: 15px;
  }

  span { padding: 0; }
`;

const Footer = styled.footer`
  border-top: 1px solid #deddda;
  padding-top: 25px;
  margin-top: 13px;
  text-align: center;

  button { margin-top: 15px; }
`;

const Price = styled.h3`
  color #221f20;
  font-family: 'Avenir-Medium' !important;
  font-weight: 400 !important;

  ${({ small }) => small ? css`
    font-size: 18px;
    padding: 5px 0;
  ` : css`
    font-size: 26px;
  `}
`;
