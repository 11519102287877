import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { Modal, Button, withSettings, WYSIWYGEditor } from '../components';
import { SEND_EVENT_REMINDER, SEND_MESSAGE_TO_PAST_GUESTS } from '../../mutations';
import { alert } from '../Global';

class ManageEventsDetailsReminderRsvpModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      sending: false
    }
  }

  handleChangeMessage = (name, value) => {
    this.setState({ [name]: value });
  }

  handleConfirm = async (sendMessage) => {
    const { toggle, event, isPastEvent } = this.props;
    const { settings: { copy } } = this.props;
    const { message } = this.state;

    this.setState({ sending: true });

    const response = await sendMessage({
      variables: {
        eventId: event.id,
        message: message
      }
    });

    const mutationData = this.getDataFromMutationResponse(isPastEvent, response);
    const errors = mutationData.errors;

    if (errors) {
      alert.error(copy.manage.message_error);
      this.setState({ sending: false });
    } else {
      alert.success(copy.manage.message_success);
      toggle();
      this.setState({
        message: '',
        sending: false
      });
    }
  }

  getDataFromMutationResponse = (isPastEvent, response) => {
    const mutationName = this.getMutation(isPastEvent).name;
    return response.data[mutationName];
  }

  getMutation = (isPastEvent) => {
    return isPastEvent ? {
      name: 'sendPostEventMessage',
      fun: SEND_MESSAGE_TO_PAST_GUESTS
    } : {
      name: 'sendEventReminder',
      fun: SEND_EVENT_REMINDER
    };
  }

  render() {
    const { className, open, toggle, type, isPastEvent } = this.props;
    const { settings: { copy } } = this.props;
    const { message, sending } = this.state;
    const mutation = this.getMutation(isPastEvent);

    return (
      <Modal size="md" show={open} toggle={toggle} noPadding>
        <Mutation mutation={mutation.fun}>
          {(sendMessage) => {
            return (
              <div className={className}>
                <header>
                  <title>
                    {`Send Message To ${type} Guests`}
                  </title>
                </header>
                <WYSIWYGEditor
                  name="message"
                  value={message}
                  onInputChange={this.handleChangeMessage}
                  placeholder="Write a Message"
                  fullToolbar
                />
                <div className="actions">
                  <Button inverted handleClick={() => { toggle() }}>
                    {copy.manage.cancel_message}
                  </Button>
                  <Button
                    disabled={(message.length > 0 && !sending) ? false : true}
                    handleClick={() => { this.handleConfirm(sendMessage) }}
                    loading={sending}
                  >
                    {false && copy.manage.send_message}
                    Send
                  </Button>
                </div>
              </div>
            );
          }}
        </Mutation>
      </Modal>
    );
  }

}

const StyledManageEventsDetailsReminderRsvpModal = styled(ManageEventsDetailsReminderRsvpModal)`
    padding: 40px 30px 30px;

    header {
        text-align: center;
        margin-bottom: 30px;

        title {
            display: block;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

    .actions {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    textarea {
      font-size: 14px;
    }
`;

const StyledManageEventsDetailsReminderRsvpModalWithSettings = withSettings(StyledManageEventsDetailsReminderRsvpModal);

export { StyledManageEventsDetailsReminderRsvpModalWithSettings as ManageEventsDetailsReminderRsvpModal };
