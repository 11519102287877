import React, { useState, useEffect } from 'react';

import { useSettings, useRsvpPageData } from '../../hooks';
import { getEventMaxAdditionalGuests } from '../../libs';

import { getEmptyAdditionalGuest } from './RsvpPageContext';

import {
  PageSubheader as Heading,
  RsvpPageAdditionalGuest as AdditionalGuest,
  UnderlineButton as Button,
  Checkbox,
  Divider
} from '../components';


export const RsvpPageAdditionalGuests = () => {
  const [bringingGuests, setBringingGuests] = useState(false);

  const { getField, setField, event } = useRsvpPageData();
  const additionalGuests = getField('additionalGuests') || [];
  const additionalGuestsMax = getEventMaxAdditionalGuests(event);

  const { copy } = useSettings();

  useEffect(() => {
    if (!bringingGuests && additionalGuests.length) setBringingGuests(true);
  }, [additionalGuests]);

  const handleSetBringingGuests = value => {
    setBringingGuests(value);
    if (!value) setField('additionalGuests', []);
    if (value && !additionalGuests.length)
      setField('additionalGuests', [getEmptyAdditionalGuest()]);
  };

  const handleAddGuest = () => {
    const guests = additionalGuests.slice();
    guests.push(getEmptyAdditionalGuest());
    setField('additionalGuests', guests);
  };

  const handleRemoveGuest = i => {
    const guests = additionalGuests.slice();
    if (i > -1) guests.splice(i, 1);
    setField('additionalGuests', guests);
  };

  const handleUpdateGuest = (i, name, value) => {
    const guests = additionalGuests.slice();
    guests[i][name] = value;
    setField('additionalGuests', guests);
  };

  return (
    <>
      <Heading>{copy.rsvp.additional_guests_title || 'Guests'}</Heading>
      <Checkbox
        name="bringingGuests"
        options={[
          {
            label: copy.rsvp.bringing_guest,
            checked: bringingGuests,
          },
        ]}
        onInputChange={(n, v) => handleSetBringingGuests(v)}
      />
      {bringingGuests && additionalGuests.map((guest, i) => (
        <AdditionalGuest
          key={i}
          guest={guest}
          guestNumber={i + 1}
          numberOfGuests={additionalGuests.length}
          onUpdateGuest={handleUpdateGuest}
          onAddGuest={handleAddGuest}
          onRemoveGuest={handleRemoveGuest}
        />
      ))}
      {bringingGuests && additionalGuests.length < additionalGuestsMax && (
        <Button onClick={handleAddGuest} icon="add">
          {copy.rsvp.add_guest || 'Add another guest'}
        </Button>
      )}
      <Divider />
    </>
  );
};
