import React, { useRef } from 'react';
import styled from 'styled-components';

import { useImageUpload } from '../../hooks';

import {
  HostAvatar,
  UnderlineButton as Button
} from '../components';

export const ProfileCoverImage = ({ user }) => {
  const inputField = useRef(null);
  const { selectFile, loading } = useImageUpload();

  return (
    <div>
      {user && (
        <AvatarContainer>
          <HostAvatar
            host={user}
            size="lg"
            position="inline"
          />
          <Button onClick={() => inputField.current?.click()}>
            {`${user.profile?.avatar ? 'Edit' : 'Add'} Profile Picture`}
          </Button>
          <input
            type="file"
            ref={inputField}
            style={{ display: 'none' }}
            onChange={({ target: { files } }) => files && selectFile(files[0])}
            accept="image/x-png,image/gif,image/jpeg"
          />
          {loading && <p>loading...</p>}
        </AvatarContainer>
      )}
    </div>
  );
};


const AvatarContainer = styled.div`
  .underline_button {
    margin-top: 15px;
  }
`;
