import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal } from '../';
import { withSettings, Input } from '../components';
import { getEventPotluckEdges } from '../../libs';

class RsvpPotluckModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      potluckQuantities: {}
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleQuantityChange = (name, value) => {
    const potluckQuantities = { ...this.state.potluckQuantities, [name]: value };

    this.setState({
      potluckQuantities
    })
  };

  handleInputValidation = () => {};

  nextButtonClicked = () => {
    const { nextButtonClicked } = this.props;
    nextButtonClicked(this.state.potluckQuantities);
  };


  isFieldDisabled = (item, maxItems) => {
    const { potluckQuantities } = this.state;
    const hasValue = Object.keys(potluckQuantities).includes(item.id.toString()) && potluckQuantities[item.id] > 0;
    const totalValue = Object.values(potluckQuantities).reduce((acc, val) => val ? acc + val : acc, 0);

    if (item.freeQuantity === 0) {
      return true;
    } else if (maxItems && !hasValue && totalValue >= maxItems) {
      return true;
    } else {
      return false;
    }
  };

  getMaxValue = maxVal => {
    const { potluckQuantities } = this.state;
    const totalValue = Object.values(potluckQuantities).reduce((acc, val) => val ? acc + val : acc, 0);
    return maxVal - totalValue + 1;
  };

  render() {
    const { className, show, toggle, event, backButtonClicked } = this.props;
    const { settings: { copy, event: { max_reservation_potluck_items } } } = this.props;
    const maxReservationItems = max_reservation_potluck_items ? parseInt(max_reservation_potluck_items) : false;

    const potluckAvailable = event.potluck;
    const potluckEdges = getEventPotluckEdges(event);

    return (
      <Modal show={show} toggle={toggle} size="sm" className={className}>
        <Fragment>
          <h2>{copy.rsvp.potluck_selection_title}</h2>
          {potluckAvailable && potluckEdges.length > 0 && (
          <div>
            <p>
              <span>{copy.rsvp.potluck_selection_instructions}</span>
              <span className="bold"> {copy.rsvp.potluck_selection_instructions_bold}</span>
            </p>
            <ul className="ot-potluck-selector">
              {potluckEdges.map(potluckEdge => {
                const potluckItem = potluckEdge.node;

                if (!potluckItem) { return null }

                const quantity = this.state.potluckQuantities[potluckItem.id] || 0;
                const maxReservationValue = maxReservationItems
                  ? Math.min(maxReservationItems, potluckItem.freeQuantity)
                  : potluckItem.freeQuantity;

                return (
                  <li key={potluckItem.id}>
                    <div className="potluck-name">
                      <span className="ot-potluck-selector-label">{potluckItem.name}</span>
                    </div>
                    <div className="potluck-buttons">
                      <Input
                        disabled={this.isFieldDisabled(potluckItem, maxReservationItems)}
                        type="number"
                        content="integer"
                        placeholder="0"
                        min="0"
                        max={this.getMaxValue(maxReservationValue)}
                        name={potluckItem.id.toString()}
                        value={quantity}
                        onInputChange={this.handleQuantityChange}
                        removeValidation
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          )}
          <hr></hr>
          <div className="ot-bottom-buttons">
            <Button buttonStyle="primary_outline" handleClick={backButtonClicked} className="float-left">Back</Button>
            <Button buttonStyle="primary" handleClick={this.nextButtonClicked} className="float-right">Continue</Button>
          </div>
        </Fragment>
      </Modal>
    );
  }
}

RsvpPotluckModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  toggle: PropTypes.func
};

const StyledRsvpPotluckModal = styled(RsvpPotluckModal)`
  text-align: center;

  & h2 {
    font-size: 18px;
    font-weight: normal !important;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    margin-top: 0;
    margin-bottom: 20px;
  }

  & p {
    text-align: center;
    margin: 0;
    color: ${props => props.settings.theme.css.global.colors.primaryDark};
    font-size: 12px;
  }

  & .bold {
    font-weight: bold;
  }

  & .ot-potluck-selector {
    margin: 30px 0 15px;
    padding: 0;
    list-style-type: none;
  }

  & .ot-potluck-selector li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 50px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
  }

  & input {
    width: 60px !important;
    margin: 0 5px !important;
    text-align: center;
  }

  & .potluck-buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .ot-potluck-selector li.selected {
    background-color: ${props => props.settings.theme.css.global.colors.primary};
    color: white;
  }

  & .ot-potluck-selector li.reserved {
    background-color: #eee;
  }

  & .ot-potluck-selector li.reserved:hover {
    background-color: #eee;
  }

  & li.selected span {
    color: white;
  }

  & .ot-potluck-selector li:hover {
    background-color: ${props => props.settings.theme.css.global.colors.secondaryHover};
  }

  & .ot-potluck-selector li.selected:hover {
    background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
    border-color: #440d1b;
    color: white;
  }

  & .ot-potluck-selector-label {

  }

  & .float-left {
    float: left;
  }

  & .float-right {
    float: right;
  }
`;

const StyledRsvpPotluckModalWithSettings = withSettings(StyledRsvpPotluckModal);

export { StyledRsvpPotluckModalWithSettings as RsvpPotluckModal };
