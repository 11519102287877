import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withSettings } from '../components';

const ProfileSocial = ({ className, facebook, twitter, instagram, snapchat }) => (
  <div className={className}>
    { facebook && facebook.length > 0 && <SocialButton name="facebook" href={`https://www.facebook.com/${facebook}`}></SocialButton> }
    { twitter && twitter.length > 0 && <SocialButton name="twitter" href={`https://www.twitter.com/${twitter}`}></SocialButton> }
    { instagram && instagram.length > 0 && <SocialButton name="instagram" href={`https://www.instagram.com/${instagram}`}></SocialButton> }
    { snapchat && snapchat.length > 0 && <SocialButton name="snapchat" href={`https://www.snapchat.com/add/${snapchat}`}></SocialButton> }
  </div>
);

ProfileSocial.propTypes = {
  className: PropTypes.string
};

const ProfileViewSocialButtons = styled(ProfileSocial)`
  display: flex;
  justify-content: center;
`;


const getSocialImage = ({ name, settings: { theme: { assets: { images } } } }) => images.social[`${name}_circle`];

const SocialBtn = ({ className, name, href }) => {
  return <a target="_blank" className={className} href={href} rel="noopener noreferrer" aria-label={name} />;
};

const StyledSocialButton = styled(SocialBtn)`
  background-image: ${props => 'url(' + getSocialImage(props) + ');'};
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
`;

const SocialButton = withSettings(StyledSocialButton);

export default ProfileViewSocialButtons;
