import React from 'react';
import styled from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react'

class LoaderModal extends React.Component {
    render(){
        const { className } = this.props;
        return(
            <div className={`${className} semantic`}>
                <Dimmer active inverted>
                    <Loader inverted/>
                </Dimmer>
            </div>
        );
    }
}

const StyledLoaderModal = styled(LoaderModal)`
    height: 50vh;
`;

export { StyledLoaderModal as LoaderModal }
