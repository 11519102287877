import React, { useState, useContext  } from 'react';
import styled from 'styled-components';

import { SessionContext } from '../../Context';

import RestrictionModalManager from './RestrictionModalManager';

import {
  Logo,
  MainMenu,
  Hotdogs,
  withSettings
} from '../components';

import UserSection from './UserSection';

import { isSite, getSiteSubNameFromSettings } from '../../libs';

const Navbar = ({ className, settings }) => {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = () => setOpenMobileNav(!openMobileNav);

  let logoUrl = settings.theme.site.nav_url;
  if (!logoUrl && isSite(settings, 'jewishfoodfest')) {
    logoUrl = 'https://www.jewishfoodfest.org/';
  }

  const link = logoUrl ? null : '/';
  const url = logoUrl || null;
  const siteName = getSiteSubNameFromSettings(settings);

  const { theme } = useContext(SessionContext);

  return (
    <RestrictionModalManager>
      <nav className={[className, siteName].join(' ')} id="main_navbar">
        <Logo className="desktop" link={link} url={url}/>
        <Logo className="mobile" layout="emblem" link={link} url={url}/>
        <MainMenu
          openMobileNav={openMobileNav}
          toggleMobileNav={toggleMobileNav}
        />
        <UserSection/>
        <Hotdogs onClick={toggleMobileNav} theme={theme} />
      </nav>
    </RestrictionModalManager>
  );
};

const StyledNavbar = styled(Navbar)`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  background-color: ${props => props.settings.theme.css.global.colors.navbarBackgroundColor || '#fff'};
  border-bottom: 1px solid #eee;
  box-shadow: 0 2px 5px rgba(0,0,0,0.04), 0 2px 10px rgba(0,0,0,0.05);

  & > .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.desktop {
      width: 145px;
      height: auto;
      left: 60px;
      margin-top: -3px;

      @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
        left: 30px;
      }

      @media (max-width: 550px) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      width: 60px;
      left: 30px;

      @media (max-width: 550px) {
        display: block;
      }
    }
  }

  &.hga {
    @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) {
      & > .logo {
        width: 110px !important;
      }
    }

    @media (max-width: ${props => props.settings.theme.css.breakpoints ? props.settings.theme.css.breakpoints.nav.tablet : 1300}px) and
      (min-width: ${props => props.settings.theme.css.breakpoints ? parseInt(props.settings.theme.css.breakpoints.nav.mobile) + 1 : 1076}px) {
      .main_menu_container {
        left: 170px !important;
      }
    }
  }
`;

const NavbarWithSettings = withSettings(StyledNavbar);

export { NavbarWithSettings as Navbar };
