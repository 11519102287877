import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { FAIcon, LocationDetail as StackedSpan } from '../components';

import {
  getScheduledAtDate,
  getEventTimeSpan,
  getLocationWithArea
} from '../../libs';
import { useSettings } from '../../hooks';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';


const UnstackedSpan = styled.span`
  &:not(:last-child) {
    margin-right: 20px;
  }

  display: inline-block;
  font-size: 13.5px;
  padding-bottom: 10px;
  color: #6d6e71;

  svg {
    margin-right: 5px;
    font-size: 15px;
  }
`;


export const EventDetailCardLocation = ({ event, stack, showHost }) => {
  const [closed, setClosed] = useState(true);
  const settings = useSettings();
  const { copy } = settings;
  const testMoreSlider = false;

  const handleToggleClosed = e => {
    e.stopPropagation();
    setClosed(prev => !prev);
  };

  const getEventHostLabel = () => {
    const { eventHosts: { edges: hosts } } = event;
    const host = hosts && hosts.find(({ node }) => node.creator);
    const hostName = host && host.node && host.node.profile && host.node.profile.firstName;
    return hostName && `${copy.event.hosted_by} ${hostName}`;
  };

  const Span = stack ? StackedSpan : UnstackedSpan;
  const iconWeight = stack ? 'far' : 'fal';

  return (
    <Container>
      {showHost && getEventHostLabel() && (
        <div>
          <Span><FAIcon name={[ iconWeight, 'user' ]} /> {getEventHostLabel()}</Span>
        </div>
      )}
      <div>
        <Span><FAIcon name={[ iconWeight, 'calendar' ]} /> {getScheduledAtDate(event)}</Span>
        <Span><FAIcon name={[ iconWeight, 'clock' ]} /> {getEventTimeSpan(event, true)}</Span>
      </div>
      <div>
        <Span><FAIcon name={[ iconWeight, 'map-marker-alt' ]} /> {event.areaOther || getLocationWithArea(event)}</Span>
        {!stack && !showHost && <Span><FAIcon name={[ 'fas', 'globe-americas' ]} /> {event.timezone}</Span>}
      </div>
      {(testMoreSlider || event.closedCaptioningAvailable || event.videosOnAsDefault || event.bathroomAccessible) && (
        <>
          <SlideDown closed={closed}>
            {(stack || showHost) && <Span><FAIcon name={[ 'fas', 'globe-americas' ]} /> {event.timezone}</Span>}
            {(testMoreSlider || event.bathroomAccessible) && (
              <Span><FAIcon name={[ 'fas', 'restroom' ]} /> {copy.create.bathroom_accessible}</Span>
            )}
            {(testMoreSlider || event.videosOnAsDefault) && (
              <Span><FAIcon name={[ iconWeight, 'video' ]} /> {copy.event.videos_on || 'Videos On By Default'}</Span>
            )}
            {(testMoreSlider || event.closedCaptioningAvailable) && (
              <Span><FAIcon name={[ iconWeight, 'closed-captioning' ]} /> {copy.create.closed_captioning}</Span>
            )}
          </SlideDown>
          <MoreButton onClick={handleToggleClosed} settings={settings} stack={stack}>+ {closed ? 'more' : 'less'}</MoreButton>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-top: 15px;
  padding-right: 45px;

  .react-slidedown {
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }
`;

const MoreButton = styled.p`
  position: absolute;
  right: 0;
  bottom: 10px;
  font-size: 13px;
  color: ${props => props.settings.theme.css.global.colors.primary};
  cursor: pointer;
  transition: color 250ms;
  font-family: 'Avenir-Medium' !important;
  line-height: 1.2;
  margin: 0;

  ${({ stack }) => stack ? css`
    font-size: 15px;
  ` : css`
    font-size: 13px;
  `}

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${props => props.settings.theme.css.global.colors.primary};
  }

  &:hover {
    color: ${props => props.settings.theme.css.global.colors.primaryHover};

    &::after {
      background-color: ${props => props.settings.theme.css.global.colors.primaryHover};
    }
  }
`;
