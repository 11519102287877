import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  EventActionButton,
  RestrictionModalStatus,
  RestrictionModal,
  withSession,
  withSettings
} from '../../components';
import { breakpoints } from '../../../styles';
import { getEventAction } from '../../../libs/events';
import { usePageScroll } from '../../../hooks';

import { NOT_SCROLLED_NAV_HEIGHT, SCROLLED_NAV_HEIGHT } from '../../../styles/index';

const EventCreationActionsMenu = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);
  const [clickedButton, setClickedButton] = useState(false);

  const {
    className,
    eventData,
    onCancel,
    onEventDataValidation,
    onDraftComplete,
    onSaveComplete,
    onSubmitPendingComplete,
    onPublishComplete,
    onUpdateComplete,
    onRemoveSponsorships,
    shouldRemoveSponsorships,
    shouldRemoveSponsorshipsPerVirtual,
    isCommunity,
    isUpdate,
    isDraft,
    isDuplicate,
    shouldContinue,
    session,
    settings,
    viewer,
    savedEventData,
  } = props;

  const {
    copy,
    event: { require_publish_approval }
  } = settings;
  const isNew = (!isDraft && !isUpdate) || isDuplicate;
  const requireApproval = !!require_publish_approval;

  const restriction = viewer && RestrictionModalStatus(copy, session, eventData, settings, 'creation');

  const getTitle = () => {
    if (isUpdate) return isCommunity ? copy.create.edit_title_community : copy.create.edit_title;
    else return isCommunity ? copy.create.title_community : copy.create.title;
  };

  const handleRestrictedActionClick = (buttonRef, cb) => {
    if(isNew) {
      const result = onEventDataValidation('publish', buttonRef);
      if(result.errors.length > 0) {
        cb();
        return;
      }
    }
    if (restriction) {
      setShowRestrictionModal(true);
      setClickedButton(buttonRef);
    } else {
      cb();
    }
  };

  const handleRestrictionModalToggle = () => setShowRestrictionModal(prev => !prev);

  const { scrolled } = usePageScroll();

  const classNames = [
    className,
    'create_action_menu',
    ...(scrolled ? ['scrolled'] : [])
  ];

  return (
    <div className={classNames.join(' ')}>
      <div>
        {/* Cancel */}
        <Button buttonStyle='primary_outline' handleClick={onCancel}>
          {copy.create.cancel}
        </Button>

        {/* Create Event */}
        {isNew && (
          <Fragment>
            {/* Publish Event / Submit for Approval */}
            <EventActionButton
              className={[
                'publish_button',
                requireApproval ? 'require_approval' : ''
              ].join(' ').trim()}
              action={requireApproval ? 'submit_pending' : 'save_event'}
              eventData={eventData}
              handleValidation={(action, buttonRef) => onEventDataValidation(action, buttonRef)}
              onRestrictedClick={handleRestrictedActionClick}
              onComplete={requireApproval ? onSubmitPendingComplete : onSaveComplete}
              disabled={disabled}
              setGroupDisabled={(val) => setDisabled(val)}
              shouldContinue={shouldContinue}
              viewer={viewer}
            >
              <span className="desktop">{requireApproval ? copy.create.submit_pending_publish_approval : copy.create.submit}</span>
              {/* the long 'Submit For Approval' button breaks mobile layout (copy.create.submit is for publishing) */}
              {requireApproval && <span className="mobile">Submit</span>}
            </EventActionButton>

            {/* Save Draft */}
            <EventActionButton
              className='draft_button'
              action='save_draft'
              eventData={eventData}
              handleValidation={(action, buttonRef) => onEventDataValidation(action, buttonRef)}
              onRestrictedClick={handleRestrictedActionClick}
              onComplete={onDraftComplete}
              disabled={disabled}
              setGroupDisabled={(val) => setDisabled(val)}
              viewer={viewer}
            >
              {copy.create.submit_draft}
            </EventActionButton>
          </Fragment>
        )}

        {/* update Event */}
        {isUpdate && (
          <Fragment>
            {/* Publish Draft / Submit or Approval */}
            {isDraft && (
              <EventActionButton
                className='publish_draft_button'
                action={
                  requireApproval ? 'submit_draft_pending' : getEventAction('publish', eventData)
                }
                eventData={eventData}
                handleValidation={(action, buttonRef) => onEventDataValidation(action, buttonRef)}
                onRestrictedClick={handleRestrictedActionClick}
                onComplete={requireApproval ? onSubmitPendingComplete : onPublishComplete}
                viewer={viewer}
                disabled={disabled}
                setGroupDisabled={(val) => setDisabled(val)}
                shouldContinue={shouldContinue}
                isUpdate
              >
                {requireApproval
                  ? copy.create.submit_pending_publish_approval || 'Submit for Approval'
                  : copy.create.submit}
              </EventActionButton>
            )}

            {/* Update Event */}
            <EventActionButton
              className='update_button'
              action={getEventAction('update', eventData)}
              eventData={eventData}
              handleValidation={(action, buttonRef) => onEventDataValidation(action, buttonRef)}
              onRestrictedClick={handleRestrictedActionClick}
              onComplete={onUpdateComplete}
              secondaryAction={onRemoveSponsorships}
              secondaryActionCondition={shouldRemoveSponsorships}
              preValidationCondition={shouldRemoveSponsorshipsPerVirtual}
              viewer={viewer}
              disabled={disabled}
              setGroupDisabled={val => setDisabled(val)}
              shouldContinue={shouldContinue}
              savedEventData={savedEventData}
              isUpdate
            >
              {copy.create.update}
            </EventActionButton>
          </Fragment>
        )}

        <h1>{getTitle()}</h1>
      </div>
      <RestrictionModal
        show={showRestrictionModal}
        toggle={handleRestrictionModalToggle}
        content={restriction}
        onClose={() => {
          document.body.classList.remove('modal-open');
          clickedButton && clickedButton.click()
        }}
      />
    </div>
  );
};

const StyledEventCreationActionsMenu = styled(EventCreationActionsMenu)`
  position: fixed;
  left: 0;
  right: 0;
  padding: 17px 0 15px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 900;

  ${({ session: { theme, layout } }) => theme.isVersion(2) ? css`
    top: ${NOT_SCROLLED_NAV_HEIGHT}px;
    transition: top 300ms;
    &.scrolled {
      top: ${SCROLLED_NAV_HEIGHT}px;
    }
    @media (max-height: 840px) {
      top: ${SCROLLED_NAV_HEIGHT}px;
    }
  ` : css`
    top: ${layout.removeHeaderFooter ? '0' : '85px'};
  `}

  @media (${breakpoints.tablet}) {
    // padding: 65px 0 15px;
  }

  & > div {
    max-width: 1232px;
    margin: auto;
    padding: 0 20px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      font-size: 30px;

      @media (${breakpoints.tablet}) {
        // top: 30%;
        // font-size: 20px;
        display: none;
      }
    }
  }

  .draft_button,
  .update_button,
  .publish_button,
  .publish_draft_button {
    float: right;
  }

  .draft_button {
    margin-right: 15px;
  }

  .publish_draft_button {
    margin-left: 15px;
  }

  .publish_button.require_approval {
    span { color: #fff; }
    .mobile { display: none; }
    
    @media (max-width: 500px) {
      .mobile { display: block; }
      .desktop { display: none; }
    }
  }
`;

const StyledEventCreationActionsMenuWithSettings = withSettings(StyledEventCreationActionsMenu);
const StyledEventCreationActionsMenuWithSession = withSession(StyledEventCreationActionsMenuWithSettings);
export { StyledEventCreationActionsMenuWithSession as EventCreationActionsMenu };
